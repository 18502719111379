import { NetworkQuality } from 'agora-rtc-sdk-ng'
import {
  ICustomLocalCameraTrack,
  ICustomLocalMicrophoneTrack,
  ICustomLocalScreenVideoTrack,
  ICustomLocalScreenAudioTrack,
  UILayout,
  MessageCategory,
  DrawerContentTypes,
  ModalContentTypes,
} from 'common/types/zoomout'
import zoomoutSettings from 'common/utils/custom/Zoomout/settings'
import {
  PlainTextMessage,
  RaiseHandMessage,
  RAISE_HAND_STATES,
} from 'common/utils/custom/Zoomout/rtm/messages'
import { Reducer } from 'redux'
import { UserID } from 'common/types/user'
import { ZoomoutInternalActions } from './Internal.actions'
import {
  INIT_INTERNAL_STATE,
  SET_CAMERA,
  SET_MICROPHONE,
  UPDATE_DEVICE,
  UPDATE_CALL_STAGE,
  UPDATE_USER_INTERFACE,
  CREATE_PEER,
  UPDATE_PEER,
  REMOVE_PEER,
  UPDATE_SCREEN_SHARING,
  UPDATE_NETWORK_QUALITY,
  ADD_PLAIN_TEXT_MESSAGE,
  UPDATE_LEAVE_MEETING_REASON,
  UPDATE_PLAIN_TEXT_MESSAGES,
  UPDATE_RAISE_HAND_MESSAGES,
  UPDATE_MICROPHONE_AND_SPEAKER,
  UPDATE_CAMERA,
} from './Internal.types'

export enum CALL_STAGES {
  STANDBY_BEFORE_CALL,
  CONNECTING_FIRST_TIME,
  LIVE_IN_CALL,
  RECONNECTING_IN_CALL,
  CALL_ENDED,
}

export type clientTypes = 'main' | 'screen'
export type remoteTrackStates =
  | 'NOT_PUBLISHED'
  | 'PUBLISHED_AND_NOT_SUBSCRIBED'
  | 'PUBLISHED_AND_SUBSCRIBED'

export interface ZoomoutInternalState {
  joinedAt: number | null
  callStage: CALL_STAGES
  leaveMeetingReason: string
  hasSubmittedFeedback: boolean
  supportsDualStream: boolean
  quality: {
    uplink: NetworkQuality['uplinkNetworkQuality']
    downlink: NetworkQuality['downlinkNetworkQuality']
  }
  localTracksDetails: {
    camera?: ICustomLocalCameraTrack
    microphone?: ICustomLocalMicrophoneTrack
    screenVideo?: ICustomLocalScreenVideoTrack
    screenAudio?: ICustomLocalScreenAudioTrack
  }
  remotePeerState: Record<
    number,
    {
      [key in 'audio' | 'video']: remoteTrackStates
    }
  >
  userInterface: {
    loader:
      | 'joining_meeting'
      | 'start_screen_sharing'
      | 'stop_screen_sharing'
      | 'reconnecting'
      | null
    layout: UILayout
    pinnedUserUID: number | null
    currentPage: number
    totalPages: number
    videosPerPage: number
    modal: ModalContentTypes
    drawer: DrawerContentTypes
    detachedWindow: DrawerContentTypes
    showSelfScreenVideo: boolean
  }
  localDevices: {
    current: {
      microphone: MediaDeviceInfo | null
      camera: MediaDeviceInfo | null
      speaker: MediaDeviceInfo | null
    }
    all: {
      microphone: MediaDeviceInfo[]
      camera: MediaDeviceInfo[]
      speaker: MediaDeviceInfo[]
    }
  }
  messages: {
    [MessageCategory.PLAIN_TEXT_MESSAGE]: {
      isEnabled: boolean
      unreadCount: number
      payloads: PlainTextMessage[]
    }
    [MessageCategory.RAISE_HAND]: {
      isEnabled: boolean
      // uid => raise hand message
      isHandRaised: Record<number, boolean>
    }
  }
}

const initialState: ZoomoutInternalState = {
  joinedAt: null,
  callStage: CALL_STAGES.STANDBY_BEFORE_CALL,
  leaveMeetingReason: 'You have left this session',
  hasSubmittedFeedback: true,
  supportsDualStream: false,
  quality: {
    uplink: 0,
    downlink: 0,
  },
  localTracksDetails: {},
  remotePeerState: {},
  userInterface: {
    loader: null,
    layout: UILayout.gallery,
    pinnedUserUID: null,
    modal: null,
    drawer: null,
    detachedWindow: null,
    currentPage: 1,
    totalPages: 1,
    videosPerPage: 9, // must be >= 2
    showSelfScreenVideo: true,
  },
  localDevices: {
    current: {
      microphone: null,
      camera: null,
      speaker: null,
    },
    all: {
      microphone: [],
      camera: [],
      speaker: [],
    },
  },
  messages: {
    [MessageCategory.PLAIN_TEXT_MESSAGE]: {
      isEnabled: false,
      unreadCount: 0,
      payloads: [],
    },
    [MessageCategory.RAISE_HAND]: {
      isEnabled: false,
      isHandRaised: {},
    },
  },
}

const zoomoutReducer: Reducer<ZoomoutInternalState, ZoomoutInternalActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case INIT_INTERNAL_STATE: {
      const { supportsDualStream, localDevices } = action.payload
      return {
        ...state,
        supportsDualStream,
        localDevices,
        joinedAt: Date.now(),
      }
    }

    case UPDATE_MICROPHONE_AND_SPEAKER: {
      const { track, devices } = action.payload

      const currentMicrophone = devices
        ? devices.currentMic
        : state.localDevices.current.microphone

      const allMicrophones = devices
        ? devices.allMics
        : state.localDevices.all.microphone

      const currentSpeaker = devices
        ? devices.currentSpeaker
        : state.localDevices.current.speaker

      const allSpeakers = devices
        ? devices.allSpeakers
        : state.localDevices.all.speaker

      return {
        ...state,
        localTracksDetails: {
          ...state.localTracksDetails,
          microphone: track,
        },
        localDevices: {
          ...state.localDevices,
          current: {
            ...state.localDevices.current,
            microphone: currentMicrophone,
            speaker: currentSpeaker,
          },
          all: {
            ...state.localDevices.all,
            microphone: allMicrophones,
            speaker: allSpeakers,
          },
        },
      }
    }

    case UPDATE_CAMERA: {
      const { track, devices } = action.payload
      const currentCamera = devices
        ? devices.current
        : state.localDevices.current.camera
      const allCameras = devices ? devices.all : state.localDevices.all.camera

      return {
        ...state,
        localTracksDetails: {
          ...state.localTracksDetails,
          camera: track,
        },
        localDevices: {
          ...state.localDevices,
          current: {
            ...state.localDevices.current,
            camera: currentCamera,
          },
          all: {
            ...state.localDevices.all,
            camera: allCameras,
          },
        },
      }
    }

    case SET_CAMERA:
      if (!state.localTracksDetails.camera) return state
      return {
        ...state,
        localTracksDetails: {
          ...state.localTracksDetails,
          camera: {
            ...state.localTracksDetails.camera,
            latestPublishedAt:
              action.payload && state.callStage === CALL_STAGES.LIVE_IN_CALL
                ? new Date()
                : state.localTracksDetails.camera.latestPublishedAt,
            isEnabled: action.payload,
          },
        },
      }
    case SET_MICROPHONE:
      if (!state.localTracksDetails.microphone) return state
      return {
        ...state,
        localTracksDetails: {
          ...state.localTracksDetails,
          microphone: {
            ...state.localTracksDetails.microphone,
            latestPublishedAt:
              action.payload && state.callStage === CALL_STAGES.LIVE_IN_CALL
                ? new Date()
                : state.localTracksDetails.microphone.latestPublishedAt,
            isEnabled: action.payload,
          },
        },
      }
    case UPDATE_DEVICE: {
      const { deviceType, selectedDevice, allDevices } = action.payload

      return {
        ...state,
        localDevices: {
          ...state.localDevices,
          current: {
            ...state.localDevices.current,
            [deviceType]: selectedDevice,
          },
          all: {
            ...state.localDevices.all,
            [deviceType]: allDevices || state.localDevices.all[deviceType],
          },
        },
      }
    }
    case UPDATE_CALL_STAGE: {
      return {
        ...state,
        callStage: action.payload,
      }
    }

    case UPDATE_USER_INTERFACE: {
      const newUserInterface = action.payload

      const newUnreadCount =
        newUserInterface.drawer !== 'chat' &&
        state.userInterface.drawer === 'chat'
          ? 0
          : state.messages[MessageCategory.PLAIN_TEXT_MESSAGE].unreadCount

      return {
        ...state,
        userInterface: {
          ...state.userInterface,
          ...newUserInterface,
        },
        messages: {
          ...state.messages,
          [MessageCategory.PLAIN_TEXT_MESSAGE]: {
            ...state.messages[MessageCategory.PLAIN_TEXT_MESSAGE],
            unreadCount: newUnreadCount,
          },
        },
      }
    }
    case CREATE_PEER: {
      const { uid } = action.payload
      return {
        ...state,
        remotePeerState: {
          ...state.remotePeerState,
          [uid]: {
            audio: 'NOT_PUBLISHED' as remoteTrackStates,
            video: 'NOT_PUBLISHED' as remoteTrackStates,
          },
        },
      }
    }

    case UPDATE_PEER: {
      const { uid } = action.payload

      if (state.remotePeerState[uid]) {
        const {
          audio = state.remotePeerState[uid].audio,
          video = state.remotePeerState[uid].video,
        } = action.payload
        return {
          ...state,
          remotePeerState: {
            ...state.remotePeerState,
            [uid]: {
              video,
              audio,
            },
          },
        }
      }
      return state
    }

    case REMOVE_PEER: {
      const { uid } = action.payload
      const replica = { ...state }
      delete replica.remotePeerState[uid]
      return replica
    }

    case UPDATE_SCREEN_SHARING: {
      const { videoTrack, audioTrack } = action.payload

      const newState = {
        ...state,
        localTracksDetails: {
          ...state.localTracksDetails,
          screenVideo: videoTrack,
          screenAudio: audioTrack,
        },
      }
      return newState
    }

    case UPDATE_PLAIN_TEXT_MESSAGES: {
      const messages: PlainTextMessage[] = action.payload.messages.map(
        message => new PlainTextMessage(message)
      )
      return {
        ...state,
        messages: {
          ...state.messages,
          [MessageCategory.PLAIN_TEXT_MESSAGE]: {
            ...state.messages[MessageCategory.PLAIN_TEXT_MESSAGE],
            payloads: [
              ...state.messages[MessageCategory.PLAIN_TEXT_MESSAGE].payloads,
              ...messages,
            ],
          },
        },
      }
    }

    case ADD_PLAIN_TEXT_MESSAGE: {
      const { message, isOwnMessage } = action.payload
      const newUnreadCount =
        isOwnMessage || state.userInterface.drawer === 'chat'
          ? state.messages[MessageCategory.PLAIN_TEXT_MESSAGE].unreadCount
          : state.messages[MessageCategory.PLAIN_TEXT_MESSAGE].unreadCount + 1

      return {
        ...state,
        messages: {
          ...state.messages,
          [MessageCategory.PLAIN_TEXT_MESSAGE]: {
            ...state.messages[MessageCategory.PLAIN_TEXT_MESSAGE],
            unreadCount: newUnreadCount,
            payloads: [
              ...state.messages[MessageCategory.PLAIN_TEXT_MESSAGE].payloads,
              message,
            ],
          },
        },
      }
    }

    case UPDATE_RAISE_HAND_MESSAGES: {
      const handStates = action.payload

      const parsedHandStates = handStates.reduce(
        (acc, message) => ({
          ...acc,
          [message.sender]: message.content === RAISE_HAND_STATES.RAISED,
        }),
        {}
      )
      return {
        ...state,
        messages: {
          ...state.messages,
          [MessageCategory.RAISE_HAND]: {
            ...state.messages[MessageCategory.RAISE_HAND],
            isHandRaised: {
              ...state.messages[MessageCategory.RAISE_HAND].isHandRaised,
              ...parsedHandStates,
            },
          },
        },
      }
    }

    case UPDATE_NETWORK_QUALITY: {
      return {
        ...state,
        quality: {
          uplink: action.payload.uplink,
          downlink: action.payload.downlink,
        },
      }
    }

    case UPDATE_LEAVE_MEETING_REASON: {
      return {
        ...state,
        leaveMeetingReason: action.payload,
      }
    }

    default:
      return state
  }
}

export default zoomoutReducer
