import React from 'react'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import styles from './DialogMain.module.css'

export interface Props extends DialogProps {}

const EnhancedDialogMain = ({ children, className, ...props }: Props) => (
  <Dialog
    fullWidth
    className={className}
    {...props}
    classes={{
      container: styles.container,
      ...props.classes,
    }}
  >
    {children}
  </Dialog>
)

export default EnhancedDialogMain
