import {
  put,
  cancelled,
  take,
  takeEvery,
  call,
  select,
  all,
} from 'redux-saga/effects'
import {
  SUBMIT_COMMENTS,
  SUBMIT_COMMENTS_CANCEL,
} from './SubmissionComments.types'
import { cancelable, apiCall } from '../../../../common/utils'
import { submitComments } from '.'
import {
  batchUploadFile,
  batchRemoveFile,
  FilesState,
} from '../../FilesProvider'
import {
  BATCH_UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  BATCH_UPLOAD_FILE_FAILURE,
} from '../../FilesProvider/Files.types'
import {
  submitCommentsFailure,
  submitCommentsSuccess,
  submitCommentsCancel,
} from './SubmissionComments.actions'
import { FileID } from '../../../../common/types'
import { getAssignmentSubmission } from '../ModuleItemsProvider/Assignment/AssignmentSubmissionsProvider'
import { showAlertMessage } from '../../AlertsProvider'

export async function submitCommentsAPI(
  action: ReturnType<typeof submitComments>,
  fileUploadIds: Number[],
  signal: AbortSignal
) {
  const { contentId, courseId, comments, groupComment, userId } = action.payload
  const body = JSON.stringify({
    comment: {
      text_comment: comments,
      file_ids: fileUploadIds,
      group_comment: groupComment,
    },
  })
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/assignments/${contentId}/submissions/${userId}`,
    params: {
      signal,
      method: 'PUT',
      body,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

function* submitCommentsHandler(action: ReturnType<typeof submitComments>) {
  const abortController = new AbortController()
  try {
    let fileUploadIds = []
    const filesState: FilesState = yield select(state => state.files)

    const fileContent = filesState.byContent[action.payload.dataKey]
    const fileIdArray: FileID[] | undefined = fileContent
      ? fileContent.data
      : undefined

    if (fileIdArray)
      fileUploadIds = yield all(
        fileIdArray.map((fileId: FileID) => {
          const file = filesState.data.byId[fileId]
          return file ? file.upload_id : undefined
        })
      )
    const data = yield call(
      submitCommentsAPI,
      action,
      fileUploadIds,
      abortController.signal
    )
    yield put(submitCommentsSuccess(data, action.meta))
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'Comment submitted successfully',
      })
    )
    yield put(getAssignmentSubmission(action.payload, action.meta))
    yield put(batchRemoveFile(action.payload))
  } catch (e) {
    yield put(submitCommentsFailure(e, action.meta))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Comment submission failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitFilesForCommentsHandler(
  action: ReturnType<typeof submitComments>
) {
  const abortController = new AbortController()
  try {
    if (action.payload.filesList.length) {
      yield put(batchUploadFile(action.payload))
      yield take(BATCH_UPLOAD_FILE_SUCCESS)
      yield submitCommentsHandler(action)
    } else {
      const data = yield call(
        submitCommentsAPI,
        action,
        [],
        abortController.signal
      )
      yield put(submitCommentsSuccess(data, action.meta))
      yield put(
        showAlertMessage({
          variant: 'success',
          message: 'Comment submitted successfully',
        })
      )
      yield put(getAssignmentSubmission(action.payload, action.meta))
    }
  } catch (e) {
    yield put(submitCommentsFailure(e, action.meta))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Comment submission failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
      yield put(submitCommentsCancel({}, { itemId: action.payload.itemId }))
    }
  }
}

export function* submitCommentsMiddleware() {
  yield takeEvery(
    SUBMIT_COMMENTS,
    cancelable(submitFilesForCommentsHandler, [
      SUBMIT_COMMENTS_CANCEL,
      UPLOAD_FILE_FAILURE,
      BATCH_UPLOAD_FILE_FAILURE,
    ])
  )
}

export default ([] as any).concat(submitCommentsMiddleware())
