import { Reducer } from 'redux'
import { CommunityData, FeedsType } from 'common/types/Community'
import { groupBy } from 'common/utils'
import { CommunityTypes } from './Community.actions'
import * as types from './Community.types'

export interface CommunityState {
  data: CommunityData
  loading: boolean
  error: false | Error | Response
}

const initialStateModules: CommunityState = {
  data: {
    notifications: 0,
    similarQuestions: [],
    newQuestions: 0,
    communityJwtToken: '',
    userQuestions: {
      data: {},
      loading: false,
      error: false,
    },
    userDetails: null,
    videoQuestionIds: {},
    preAskedQuestions: {
      data: [],
      loading: false,
      error: false,
    },
    newTopic: {
      data: { id: '', name: '' },
      error: false,
    },
    trendingQuestions: {
      data: [],
      loading: false,
      error: false,
    },
    articles: {
      data: [],
      loading: false,
      error: false,
    },
    groupFeeds: {
      data: [],
      loading: false,
      error: false,
    },
    alumniGroupDetails: {
      data: [],
      loading: false,
      error: false,
    },
    alumniGroupMembers: {
      data: [],
      loading: false,
      error: false,
    },
    showLoader: false,
  },
  loading: false,
  error: false,
}

const filterQuestionsData = (questions: any[]) => {
  if (questions && questions.length) {
    const filteredQuestions = questions.map((question: any) => {
      return {
        id: question._id,
        relativeUrl: `${question.in?.relativeUrl}/post/${question.entityId}`,
        title: question.title,
      }
    })
    return filteredQuestions
  }
  return []
}
const filterTopicsData = (topics: any[]) => {
  if (topics && topics.length) {
    const filteredTopics = topics.map((topic: any) => {
      return {
        id: topic.id,
        name: topic.title,
      }
    })
    return filteredTopics
  }
  return []
}

const filterQuestionsDataWithTopics = (questions: any[]) => {
  if (questions && questions.length) {
    const filteredQuestionsData = questions.map((question: any) => {
      return {
        id: question._id,
        title: question.title,
        topics: question.topics,
        relativeUrl: question.relativeUrl,
        createdAt: question.createdAt,
      }
    })
    return filteredQuestionsData
  }
  return []
}

const filterArticlesContent = (articles: any[]) => {
  if (articles && articles.length) {
    const articlesData = articles.map((article: any) => {
      return {
        id: article._id,
        title: article.title,
        content: article.content,
        relativeUrl: article.relativeUrl,
      }
    })
    return articlesData
  }
  return []
}

const filterUsersQuestionsData = (questions: any[]) => {
  if (questions && questions.length) {
    const filteredUsersQuestionsData = questions.map((question: any) => {
      return {
        id: question._id,
        title: question.title,
        relativeUrl: question.relativeUrl,
        topics: question.topics,
        createdAt: question.createdAt,
      }
    })
    const groupByQuestionIds = groupBy(filteredUsersQuestionsData, 'id')
    return groupByQuestionIds
  }
  return {}
}

const filterPreAskedQuestionsData = (questions: any[]) => {
  if (questions && questions.length) {
    const filteredQuestions = questions.map((question: any) => {
      const topics =
        question.tags && question.tags.length
          ? question.tags.map((topic: { id: any; title: any }) => {
              return {
                id: topic.id,
                name: topic.title,
              }
            })
          : []
      return {
        id: question.id,
        title: question.title,
        relativeUrl: `${question.in?.relativeUrl}/post/${question.entityId}`,
        counts: question.counts,
        topics,
      }
    })
    return filteredQuestions
  }
  return []
}

const filterGroupFeedsData = (allGroupFeeds: any) => {
  
  if (allGroupFeeds.length > 0) {
    const filterFeedsData = allGroupFeeds.map((groupFeed: any) => {
      if (groupFeed.post) {
        let postedByUser = ''
        let userAvatar = ''
        if (groupFeed.post.user && groupFeed.post.user.profile) {
          postedByUser = groupFeed.post.user.profile.name
          userAvatar = groupFeed.post.user.profile.picture
        }

        return {
          title: groupFeed.post.title,
          updatedAt: groupFeed.post.updatedAt,
          postId: groupFeed.post.id,
          postedByUser,
          feedType: groupFeed.type,
          userAvatar,
          topics: groupFeed.post.topics,
          relativeUrl: groupFeed.post.relativeUrl || '',
        }
      }
    })

    return filterFeedsData
  }
  return []
}

const filterNewQuestionData = (newPost: any) => {
  let postedByUser = ''
  let userAvatar = ''
  if (newPost.user && newPost.user.profile) {
    postedByUser = newPost.user.profile.name
    userAvatar = newPost.user.profile.picture
  }

  return {
    title: newPost.title,
    updatedAt: newPost.updatedAt,
    postId: newPost.id,
    postedByUser,
    feedType: 'Post',
    userAvatar,
    topics: newPost.topics,
    id: newPost.id,
  }
}

const filterGroupDetailsData = (groupDetails: any) => {
  if (groupDetails.length > 0) {
    const filterAlumniGroupData = groupDetails.map(
      (topic: any) => {
        return {
          id: topic.id,
          name: topic.name,
        }
      }
    )
    return filterAlumniGroupData
  }
  return []
}

const filterAlumniGroupMembersData = (groupMembers: any) => {
  if (groupMembers.length > 0) {
    const filterGroupMembersData = groupMembers.map((memberDetails: any) => {
      return {
        id: memberDetails.id,
        name: memberDetails.profile.name,
        picture: memberDetails.profile.picture,
        username: memberDetails.profile.username,
        title: memberDetails.profile.title,
        score: memberDetails.profile.score,
        url: memberDetails.profile.url,
      }
    })
    return filterGroupMembersData
  }
  return []
}

export const communityReducer: Reducer<CommunityState, CommunityTypes> = (
  state = initialStateModules,
  action
): CommunityState => {
  switch (action.type) {
    case types.FETCH_NOTIFICATIONS:
    case types.FETCH_SIMILAR_QUESTIONS:
    case types.FETCH_USER_QUESTIONS:
    case types.FETCH_NEW_QUESTIONS:
    case types.FETCH_USER_DETAILS:
    case types.FETCH_TOPICS:
      return {
        ...state,
        loading: true,
      }

    case types.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          notifications: action.payload,
        },
        loading: false,
        error: false,
      }
    case types.UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          communityJwtToken: action.payload,
        },
        loading: false,
        error: false,
      }
    case types.FETCH_NOTIFICATIONS_FAILURE:
    case types.FETCH_SIMILAR_QUESTIONS_FAILURE:
    case types.FETCH_USER_QUESTIONS_FAILURE:
    case types.FETCH_NEW_QUESTIONS_FAILURE:
    case types.FETCH_USER_DETAILS_FAILURE:
    case types.RENEW_COMMUNITY_JWT_TOKEN_FAILURE:
    case types.FETCH_VIDEO_QUESTIONS_IDS_FAILURE:
    case types.FETCH_TOPICS_FAILURE:
    case types.FETCH_GROUP_FEEDS_FAILURE:
    case types.FETCH_ALUMNI_GROUP_MEMBERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case types.FETCH_NOTIFICATIONS_CANCEL:
    case types.FETCH_SIMILAR_QUESTIONS_CANCEL:
    case types.FETCH_USER_QUESTIONS_CANCEL:
    case types.FETCH_USER_DETAILS_CANCEL:
    case types.FETCH_NEW_QUESTIONS_CANCEL:
    case types.RENEW_COMMUNITY_JWT_TOKEN_CANCEL:
    case types.FETCH_TOPICS_CANCEL:
    case types.FETCH_GROUP_FEEDS_CANCEL:
    case types.FETCH_ALUMNI_GROUP_MEMBERS_CANCEL:
      return {
        ...state,
        loading: false,
      }

    case types.FETCH_SIMILAR_QUESTIONS_SUCCESS: {
      const filteredQuestions = filterQuestionsData(action.payload)
      return {
        ...state,
        data: {
          ...state.data,
          similarQuestions: filteredQuestions,
        },
        loading: false,
        error: false,
      }
    }

    case types.FETCH_USER_QUESTIONS_SUCCESS: {
      const filteredQuestions = filterUsersQuestionsData(action.payload)
      return {
        ...state,
        data: {
          ...state.data,
          userQuestions: {
            data: filteredQuestions,
            loading: false,
            error: false,
          },
        },
      }
    }

    case types.FETCH_USER_DETAILS_SUCCESS: {
      const user = action.payload.usersData

      return {
        ...state,
        data: {
          ...state.data,
          userDetails: {
            id: user._id,
            profileImage: user.profile.picture,
            badge:
              user.profile.badge.text && user.profile.badge.text != ''
                ? user.profile.badge.type
                : null,
            reputation: parseInt(user.profile.score),
          },
        },
        loading: false,
        error: false,
      }
    }

    case types.POST_QUESTION_UNDER_VIDEO: {
      return {
        ...state,
        data: {
          ...state.data,
          userQuestions: {
            ...state.data.userQuestions,
            loading: true,
            error: false,
          },
        },
      }
    }

    case types.POST_QUESTION_UNDER_VIDEO_FAILURE: {
      return {
        ...state,
        data: {
          ...state.data,
          userQuestions: {
            ...state.data.userQuestions,
            error: action.payload,
            loading: false,
          },
        },
      }
    }

    case types.POST_QUESTION_UNDER_VIDEO_SUCCESS: {
      const { videoId, communityQuestionData } = action.payload
      const newQuestion = filterUsersQuestionsData([communityQuestionData])
      const newVideoQuestionIds = { ...state.data.videoQuestionIds }
      newVideoQuestionIds[videoId] = [
        communityQuestionData._id,
        ...(state.data.videoQuestionIds[videoId] || []),
      ]
      return {
        ...state,
        data: {
          ...state.data,
          userQuestions: {
            data: { ...newQuestion, ...state.data.userQuestions.data },
            loading: false,
            error: false,
          },
          videoQuestionIds: newVideoQuestionIds,
        },
      }
    }

    case types.FETCH_VIDEO_QUESTIONS_IDS_SUCCESS: {
      const newVideoQuestionIds = { ...state.data.videoQuestionIds }
      newVideoQuestionIds[action.payload.videoId] = action.payload.questionIds
      return {
        ...state,
        data: {
          ...state.data,
          videoQuestionIds: newVideoQuestionIds,
        },
        loading: false,
        error: false,
      }
    }

    case types.FETCH_NEW_QUESTIONS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          newQuestions: action.payload,
        },
        loading: false,
        error: false,
      }
    }

    case types.FETCH_TOPICS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          allTopics: filterTopicsData(action.payload),
        },
      }

    case types.FETCH_PRE_ASKED_SIMILAR_QUESTIONS:
      return {
        ...state,
        data: {
          ...state.data,
          preAskedQuestions: {
            ...state.data.preAskedQuestions,
            loading: true,
          },
        },
      }

    case types.FETCH_PRE_ASKED_SIMILAR_QUESTIONS_CANCEL:
      return {
        ...state,
        data: {
          ...state.data,
          preAskedQuestions: {
            ...state.data.preAskedQuestions,
            loading: false,
          },
        },
      }

    case types.FETCH_PRE_ASKED_SIMILAR_QUESTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          preAskedQuestions: {
            data: filterPreAskedQuestionsData(action.payload),
            loading: false,
            error: false,
          },
        },
      }

    case types.FETCH_PRE_ASKED_SIMILAR_QUESTIONS_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          preAskedQuestions: {
            data: state.data.preAskedQuestions.data,
            loading: false,
            error: action.payload,
          },
        },
      }
    case types.FETCH_TRENDING_QUESTIONS:
      return {
        ...state,
        data: {
          ...state.data,
          trendingQuestions: {
            ...state.data.trendingQuestions,
            loading: true,
            error: false,
          },
        },
      }

    case types.FETCH_TRENDING_ARTICLES:
      return {
        ...state,
        data: {
          ...state.data,
          articles: {
            ...state.data.articles,
            loading: true,
            error: false,
          },
        },
      }

    case types.FETCH_TRENDING_QUESTIONS_CANCEL:
      return {
        ...state,
        data: {
          ...state.data,
          trendingQuestions: {
            ...state.data.trendingQuestions,
            loading: false,
            error: false,
          },
        },
      }

    case types.FETCH_TRENDING_ARTICLES_CANCEL:
      return {
        ...state,
        data: {
          ...state.data,
          articles: {
            ...state.data.articles,
            loading: false,
            error: false,
          },
        },
      }

    case types.FETCH_TRENDING_QUESTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          trendingQuestions: {
            data: filterQuestionsDataWithTopics(action.payload),
            loading: false,
            error: false,
          },
        },
      }

    case types.FETCH_TRENDING_ARTICLES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          articles: {
            data: filterArticlesContent(action.payload),
            loading: false,
            error: false,
          },
        },
      }

    case types.FETCH_TRENDING_QUESTIONS_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          trendingQuestions: {
            data: state.data.trendingQuestions.data,
            loading: false,
            error: action.payload,
          },
        },
      }

    case types.FETCH_TRENDING_ARTICLES_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          articles: {
            data: state.data.articles.data,
            loading: false,
            error: action.payload,
          },
        },
      }

    case types.ADD_NEW_TOPIC_TO_QUESTION:
      return {
        ...state,
        data: {
          ...state.data,
          newTopic: {
            data: { id: '', name: '' },
            error: false,
          },
        },
      }

    case types.ADD_NEW_TOPIC_TO_QUESTION_CANCEL:
      return {
        ...state,
        data: {
          ...state.data,
          newTopic: {
            data: { id: '', name: '' },
            error: false,
          },
        },
      }

    case types.ADD_NEW_TOPIC_TO_QUESTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          newTopic: {
            data: action.payload,
            error: false,
          },
        },
      }

    case types.ADD_NEW_TOPIC_TO_QUESTION_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          newTopic: {
            data: { id: '', name: '' },
            error: action.payload,
          },
        },
      }

    case types.RESET_ADD_NEW_TOPIC_TO_QUESTION:
      return {
        ...state,
        data: {
          ...state.data,
          newTopic: {
            data: { id: '', name: '' },
            error: false,
          },
        },
      }

    case types.FETCH_GROUP_FEEDS:
      return {
        ...state,
        data: {
          ...state.data,
          groupFeeds: {
            ...state.data.groupFeeds,
            loading: true,
          },
        },
      }

    case types.FETCH_GROUP_FEEDS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          groupFeeds: {
            loading: false,
            data: filterGroupFeedsData(action.payload),
            error: false,
          },
        },
      }

    case types.FETCH_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          alumniGroupDetails: {
            loading: false,
            data: filterGroupDetailsData(action.payload),
            error: false,
          },
        },
      }

    case types.FETCH_ALUMNI_GROUP_MEMBERS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          alumniGroupMembers: {
            loading: false,
            data: filterAlumniGroupMembersData(action.payload),
            error: false,
          },
        },
      }

    case types.POST_QUESTION:
      return {
        ...state,
        data: {
          ...state.data,
          showLoader: true,
        },
      }

    case types.POST_QUESTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          showLoader: false,
        },
      }

    case types.INSERT_NEW_QUESTION_INTO_FEEDS:
      const newQuestion = filterNewQuestionData(action.payload)

      return {
        ...state,
        data: {
          ...state.data,
          groupFeeds: {
            loading: false,
            error: false,
            data: [newQuestion, ...state.data.groupFeeds.data],
          },
        },
      }

    default:
      return state
  }
}
