import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchUserProfileDetails } from 'web/providers/User/UserDetailsProvider'
import { FeatureLinksData } from 'common/types/dashboard'
import { mixpanel } from 'common/utils/mixpanel'
import { isUserAcadOps } from 'common/utils'
import {
  fetchOverdueNotifications,
  UserNotificationsState,
} from 'web/providers/User/UserNotificationsProvider'
import { useIsNewUIEnabled } from 'web/hooks'
import { ProgramData, ProgramID } from '../../../common/types/programs'
import AsyncDOM from '../../components/Utils/AsyncDOM'
import {
  programSelectors,
  updateActiveProgram,
  fetchCommunitySsoToken,
} from '../../providers/Dashboard/ProgramsProvider'
import { feedbackPopupStatus } from '../../providers/FeedbackProvider'
import {
  fetchAdmissionStatus,
  UserAdmissionState,
} from '../../providers/User/UserAdmissionProvider'
import {
  loginUserSuccess,
  updateUserDetails,
  UserDetailsState,
} from '../../providers/User/UserDetailsProvider'
import {
  fetchUserOnboardingDetails,
  UserOnboardingState,
  updateUserOnboardingRedirectUrl,
} from '../../providers/User/UserOnboardingProvider'
import { AppState } from '../../store'
import ErrorIllustrations from '../Utils/ErrorIllustrations'
import AppContentsHandler from './AppContentsHandler'
import './AppContents.css'

interface OwnProps {
  children: React.ReactNode
}

interface StateProps {
  userDetails: UserDetailsState
  admission: UserAdmissionState
  notifications: UserNotificationsState
  programData: ProgramData | null
  onboarding: UserOnboardingState
  activeProgramId: ProgramID | null
  glAcademyId: ProgramID | null
  enrolledPrograms: ProgramID[] | null
  featureLinks: FeatureLinksData
  communitySsoToken: string | null

  isActiveCommunityBatch: boolean
  isNewProgramSupportEnabled: boolean
}

interface DispatchProps {
  fetchUserOnboardingDetails: typeof fetchUserOnboardingDetails
  fetchAdmissionStatus: typeof fetchAdmissionStatus
  updateUserDetails: typeof updateUserDetails
  loginUserSuccess: typeof loginUserSuccess
  feedbackPopupStatus: typeof feedbackPopupStatus
  updateActiveProgram: typeof updateActiveProgram
  fetchUserProfileDetails: typeof fetchUserProfileDetails
  fetchCommunitySsoToken: typeof fetchCommunitySsoToken
  updateUserOnboardingRedirectUrl: typeof updateUserOnboardingRedirectUrl
  fetchOverdueNotifications: typeof fetchOverdueNotifications
}

type Props = StateProps & OwnProps & DispatchProps

const NO_TRANSLATE_DISABLED_BATCH_IDS = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return [379, 962]
    case 'staging':
      return [2415, 2544]
    case 'production':
      return [14032, 15808]
    default:
      return []
  }
}

const AppContents = (props: Props) => {
  const no_translate_disabled_batch =
    props.programData &&
    NO_TRANSLATE_DISABLED_BATCH_IDS().includes(Number(props.programData.id))
  const initializeWeglot = () => {
    if (
      (window as any).Weglot &&
      props.programData &&
      props.programData.translation_enabled
    ) {
      ;(window as any).Weglot.initialize({
        api_key: process.env.REACT_APP_WEGLOT_API_KEY,
        // Hide original text before loading translated one, true by default
        wait_transition: true,
        auto_switch: true,
        // If auto switch is true, but you don't have visitor's language, redirect on this language code, otherwise original language
        auto_switch_fallback: 'en',
        // Use front cache between pages, speed up your page loading!
        cache: true,
        // hide_switcher: true,
        // Exclude some blocks from translation
        excluded_blocks: no_translate_disabled_batch
          ? []
          : [
              {
                value: '.no-translate', // All elements with this class won't be translated
              },
            ],
      })
      ;(window as any).Weglot.on('initialized', () => {
        mixpanel.track('Language Translator - Weglot - initialized', {})
        if (
          (window as any).Weglot &&
          props.programData &&
          props.programData.translation_enabled
        ) {
          mixpanel.track('Language Translator - Weglot - language_switched', {})
          ;(window as any).Weglot.switchTo(
            props.programData.translation_language || 'en'
          )
        }
      })
    }
  }

  const initialCalls = () => {
    props.fetchAdmissionStatus()
  }

  useEffect(() => {
    setInterval(() => {
      initialCalls()
    }, 28800000)
    initializeWeglot()
  }, [])

  useEffect(() => {
    if (props.activeProgramId && props.userDetails.appSource !== 'mobile_app') {
      props.fetchUserProfileDetails({ include_feature_detail: true })
    }
  }, [props.activeProgramId])

  const isAcadOps = isUserAcadOps()
  const toFetchUserOnboardingDetails =
    props.userDetails.appSource !== 'mobile_app' &&
    props.programData &&
    props.programData.access_type === 'basic' &&
    !props.programData.digital_campus_program &&
    !props.programData.disable_onboarding &&
    props.programData.program_type !== 'career_track' &&
    !isAcadOps

  const isNewUIEnabled = useIsNewUIEnabled()

  useEffect(() => {
    if (isNewUIEnabled) {
      props.fetchOverdueNotifications()
    }
  }, [props.activeProgramId, isNewUIEnabled])

  useEffect(() => {
    initialCalls()
    if (toFetchUserOnboardingDetails) {
      props.fetchUserOnboardingDetails()
      if (!props.communitySsoToken) {
        if (
          props.programData &&
          props.programData.program_type !== 'semester_program'
        ) {
          props.fetchCommunitySsoToken()
        }
      }
    }
  }, [props.activeProgramId])

  const hasData =
    props.admission.data &&
    (toFetchUserOnboardingDetails ? props.onboarding.data.content : true) &&
    (props.userDetails.appSource === 'mobile_app'
      ? true
      : props.userDetails.profile && props.userDetails.profile.data)

  const iframeURLs: string[] = []
  if (props.communitySsoToken) {
    iframeURLs.push(
      `${process.env.REACT_APP_COMMUNITY_V2_URL}api/auth/sso?jwt=${props.communitySsoToken}&redirect=/`
    )
  }

  const appContentsHandlerComp = (
    <AppContentsHandler
      admission={props.admission}
      onboarding={props.onboarding}
      programData={props.programData}
      feedbackPopupStatus={props.feedbackPopupStatus}
      userDetails={props.userDetails}
      notifications={props.notifications}
      updateActiveProgram={props.updateActiveProgram}
      updateUserOnboardingRedirectUrl={props.updateUserOnboardingRedirectUrl}
      glAcademyId={props.glAcademyId}
      enrolledPrograms={props.enrolledPrograms}
      isCareerSchool={
        (props.programData &&
          props.programData!.program_type === 'career_track') ||
        false
      }
      featureLinks={props.featureLinks}
      isNewProgramSupportEnabled={props.isNewProgramSupportEnabled}
    >
      {props.children}
    </AppContentsHandler>
  )

  return (
    <AsyncDOM
      loading={
        props.admission.loading ||
        (!props.onboarding.completed && props.onboarding.loading) ||
        (props.userDetails.profile && props.userDetails.profile.loading)
      }
      data={!!hasData}
      error={
        !!(
          props.admission.error ||
          (!props.onboarding.data.content && props.onboarding.error) ||
          (props.userDetails.profile && props.userDetails.profile.error)
        )
      }
    >
      <AsyncDOM.Error
        error={
          props.admission.error ||
          props.onboarding.error ||
          (props.userDetails.profile ? props.userDetails.profile.error : false)
        }
      >
        <ErrorIllustrations type="500" />
      </AsyncDOM.Error>
      <AsyncDOM.Content>
        <>
          {appContentsHandlerComp}
          {props.isActiveCommunityBatch && (
            <div className="displayNone">
              {iframeURLs.map(url => (
                <iframe
                  src={url}
                  frameBorder="0"
                  width="100%"
                  height="100%"
                  title="notifications"
                />
              ))}
            </div>
          )}
        </>
      </AsyncDOM.Content>
    </AsyncDOM>
  )
}

const mapStateToProps = (state: AppState): StateProps => ({
  admission: state.user.admission,
  onboarding: state.user.onboarding,
  userDetails: state.user.details,
  programData: programSelectors.getActiveProgramDetails()(state),
  activeProgramId: programSelectors.getProgramId()(state),
  glAcademyId: programSelectors.getGlAcademyId()(state),
  enrolledPrograms: programSelectors.getAllProgramIds()(state),
  featureLinks: state.dashboard.data ? state.dashboard.data!.feature_links : {},
  communitySsoToken: state.programs.data.communitySsoToken,
  isActiveCommunityBatch: state.programs.data.isActiveCommunityBatch,
  isNewProgramSupportEnabled: state.programs.data.isNewProgramSupportEnabled,
  notifications: state.user.notifications,
})

export default connect(mapStateToProps, {
  fetchAdmissionStatus,
  fetchUserOnboardingDetails,
  loginUserSuccess,
  updateUserDetails,
  feedbackPopupStatus,
  updateActiveProgram,
  fetchUserProfileDetails,
  fetchCommunitySsoToken,
  updateUserOnboardingRedirectUrl,
  fetchOverdueNotifications,
})(AppContents)
