import * as types from './QuizActivity.types'
import {
  QuizAttemptNo,
  QuizQuestionID,
  QuizID,
  QuestionID,
  DynamicQuestionData,
  QuizSubmissionID,
} from '../../../../../../common/types/courses/quiz'
import { ItemID } from '../../../../../../common/types/courses/moduleItem'
import { CourseID } from '../../../../../../common/types/courses'

interface MetaParams {
  attemptNo: QuizAttemptNo
  itemId: ItemID
}

export const updateActiveQuizQuestion = (
  payload: { id: QuizQuestionID },
  meta: MetaParams
) => ({
  type: types.QUIZ_UPDATE_ACTIVE_QUESTION as typeof types.QUIZ_UPDATE_ACTIVE_QUESTION,
  payload,
  meta,
})

interface QuizSubmissionServerTimeMetaParams {
  quizId: QuizID
  itemId: ItemID
  courseId: CourseID
}

export const getQuizSubmissionServerTime = (
  payload: null,
  meta: QuizSubmissionServerTimeMetaParams
) => ({
  type: types.GET_QUIZ_SUBMISSION_SERVER_TIME as typeof types.GET_QUIZ_SUBMISSION_SERVER_TIME,
  payload,
  meta,
})

export const getQuizSubmissionServerTimeSuccess = (
  payload: { time_left: number },
  meta: QuizSubmissionServerTimeMetaParams
) => ({
  type: types.GET_QUIZ_SUBMISSION_SERVER_TIME_SUCCESS as typeof types.GET_QUIZ_SUBMISSION_SERVER_TIME_SUCCESS,
  payload,
  meta,
})

export const getQuizSubmissionServerTimeFailure = (
  payload: Error,
  meta: QuizSubmissionServerTimeMetaParams
) => ({
  type: types.GET_QUIZ_SUBMISSION_SERVER_TIME_FAILURE as typeof types.GET_QUIZ_SUBMISSION_SERVER_TIME_FAILURE,
  payload,
  meta,
})

export const fetchQuizSubmissionDynamicQA = (
  payload: {
    embedCode: string
    attempt: number
    questionId: QuizQuestionID
    submissionId: QuizSubmissionID
    modelId?: number
    threadId?: string
    answer?: string
  },
  meta: { questionId: QuestionID; itemId: ItemID; answer?: string }
) => ({
  type: types.QUIZ_SUBMISSION_DYNAMIC_QA_FETCH as typeof types.QUIZ_SUBMISSION_DYNAMIC_QA_FETCH,
  payload,
  meta,
})

export const fetchQuizSubmissionDynamicQASuccess = (
  payload: {
    thread_id?: string
    chat_data: string
    chat_id: string
    model_id: number
    input_mode: string
  },
  meta: { questionId: QuestionID; itemId: ItemID; answer?: string }
) => ({
  type: types.QUIZ_SUBMISSION_DYNAMIC_QA_FETCH_SUCCESS as typeof types.QUIZ_SUBMISSION_DYNAMIC_QA_FETCH_SUCCESS,
  payload,
  meta,
})

export const fetchQuizSubmissionDynamicQAListSuccess = (
  payload: { quesData: DynamicQuestionData[]; ansData: string[] },
  meta: { questionId: QuestionID; itemId: ItemID }
) => ({
  type: types.QUIZ_SUBMISSION_DYNAMIC_QA_LIST_FETCH_SUCCESS as typeof types.QUIZ_SUBMISSION_DYNAMIC_QA_LIST_FETCH_SUCCESS,
  payload,
  meta,
})

export const fetchQuizSubmissionDynamicQAFailure = (
  payload: Error,
  meta: { questionId: QuestionID }
) => ({
  type: types.QUIZ_SUBMISSION_DYNAMIC_QA_FETCH_FAILURE as typeof types.QUIZ_SUBMISSION_DYNAMIC_QA_FETCH_FAILURE,
  payload,
  meta,
})

export const updateQuizSubmissionDynamicQAQuestionIndex = (
  payload: { index: number },
  meta: { questionId: QuestionID; itemId: ItemID }
) => ({
  type: types.QUIZ_SUBMISSION_DYNAMIC_QA_UPDATE_QUESTION_INDEX as typeof types.QUIZ_SUBMISSION_DYNAMIC_QA_UPDATE_QUESTION_INDEX,
  payload,
  meta,
})

export type QuizActivityActionTypes =
  | ReturnType<typeof updateActiveQuizQuestion>
  | ReturnType<typeof getQuizSubmissionServerTime>
  | ReturnType<typeof getQuizSubmissionServerTimeSuccess>
  | ReturnType<typeof getQuizSubmissionServerTimeFailure>
  | ReturnType<typeof fetchQuizSubmissionDynamicQA>
  | ReturnType<typeof fetchQuizSubmissionDynamicQASuccess>
  | ReturnType<typeof fetchQuizSubmissionDynamicQAListSuccess>
  | ReturnType<typeof fetchQuizSubmissionDynamicQAFailure>
  | ReturnType<typeof updateQuizSubmissionDynamicQAQuestionIndex>
