import { apiCall, getUserDetails } from 'common/utils'
import { getGlLabAssignmentAndSubmissionData } from './GlLabs.action'

export async function getGlLabAssignmentDataAPI(
  action: ReturnType<typeof getGlLabAssignmentAndSubmissionData>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { glLabAssignmentId } = action.payload

  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/gl_labs/${glLabAssignmentId}/users/${id}/details`,

    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
