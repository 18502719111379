import { TaxInfo } from 'common/types/payment'
import {
  FETCH_USER_PAYMENT,
  FETCH_USER_PAYMENT_FAILURE,
  FETCH_USER_PAYMENT_SUCCESS,
  MAKE_FEE_PAYMENT,
  UPDATE_RAZORPAY_STATUS,
  UPDATE_TAX_INFO,
} from './Payments.types'
import {
  PaymentGatewayInfo,
  UserPaymentsData,
} from '../../../../common/types/user'

export const fetchUserPayment = () => ({
  type: FETCH_USER_PAYMENT as typeof FETCH_USER_PAYMENT,
})

export const fetchUserPaymentSuccess = (payload: UserPaymentsData) => ({
  type: FETCH_USER_PAYMENT_SUCCESS as typeof FETCH_USER_PAYMENT_SUCCESS,
  payload,
})

export const fetchUserPaymentFailure = (payload: Error) => ({
  type: FETCH_USER_PAYMENT_FAILURE as typeof FETCH_USER_PAYMENT_FAILURE,
  payload,
})

export const updateRazorPayStatus = (payload: boolean) => ({
  type: UPDATE_RAZORPAY_STATUS as typeof UPDATE_RAZORPAY_STATUS,
  payload,
})
export const updateTaxInfo = (payload: TaxInfo) => ({
  type: UPDATE_TAX_INFO as typeof UPDATE_TAX_INFO,
  payload,
})

export const makeFeePayment = (
  paymentGatewayInfo: PaymentGatewayInfo,
  isInternational: boolean,
  degreeProgram: boolean,
  is_razorpay: boolean,
  closePayNow: () => void,
  paymentMethod: boolean // this will be true for paypal and false for stripe
) => ({
  type: MAKE_FEE_PAYMENT as typeof MAKE_FEE_PAYMENT,
  paymentGatewayInfo,
  isInternational,
  degreeProgram,
  is_razorpay,
  closePayNow,
  paymentMethod,
})

export type PaymentsActions =
  | ReturnType<typeof fetchUserPayment>
  | ReturnType<typeof fetchUserPaymentSuccess>
  | ReturnType<typeof fetchUserPaymentFailure>
  | ReturnType<typeof makeFeePayment>
  | ReturnType<typeof updateRazorPayStatus>
  | ReturnType<typeof updateTaxInfo>
