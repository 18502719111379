import { Reducer } from 'redux'
import {
  CareerPath,
  CareerPathCourse,
  CareerPathID,
  EnrolledCareerPath,
  GLAUserCareerPathDetails,
} from 'common/types/careerPath'
import { CourseID } from 'common/types/courses'
import { CareerPathActions } from './CareerPath.actions'
import {
  FETCH_CAREER_PATH_CONTENT,
  FETCH_CAREER_PATH_CONTENT_SUCCESS,
  FETCH_CAREER_PATH_CONTENT_FAILURE,
  FETCH_CAREER_PATH_CONTENT_CANCEL,
} from './CareerPath.types'

interface CareerPathsStateData {
  byId: {
    [key in CareerPathID]: CareerPath
  }
  byCourseIds: {
    [key in CourseID]: CareerPathID[]
  }
  byDomains: {
    [key in string]: CareerPathID[]
  }
  enrolledCareerPaths: EnrolledCareerPath[]
  allPathsLink: string
}

export type CareerPathsState = Readonly<{
  data: CareerPathsStateData
  loading: boolean
  loaded: boolean
  error: boolean | Error
}>

const initialState: CareerPathsState = {
  data: {
    byId: {},
    byCourseIds: {},
    byDomains: {},
    enrolledCareerPaths: [],
    allPathsLink: '',
  },
  loading: false,
  loaded: false,
  error: false,
}

const careerPathDataHandler = (
  data: GLAUserCareerPathDetails
): CareerPathsStateData => {
  const careerPathData: CareerPathsStateData = { ...initialState.data }

  if (data) {
    careerPathData.byDomains = data.career_paths_domains
    careerPathData.byId = data.career_paths_data
    careerPathData.allPathsLink = data.all_career_paths_link

    if (data.enrolled_career_paths.length) {
      careerPathData.enrolledCareerPaths = data.enrolled_career_paths
      data.enrolled_career_paths.forEach((careerPath: EnrolledCareerPath) => {
        careerPath.courses.forEach((course: CareerPathCourse) => {
          if (!(course.course_id in careerPathData.byCourseIds)) {
            careerPathData.byCourseIds[course.course_id] = []
          }
          careerPathData.byCourseIds[course.course_id]!.push(careerPath.id)
        })
      })
    }
  }

  return careerPathData
}

const careerPathsReducer: Reducer<CareerPathsState, CareerPathActions> = (
  state = initialState,
  action
): CareerPathsState => {
  switch (action.type) {
    case FETCH_CAREER_PATH_CONTENT: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      }
    }
    case FETCH_CAREER_PATH_CONTENT_SUCCESS: {
      const processedData = careerPathDataHandler(action.payload)
      return {
        ...state,
        data: processedData,
        loading: false,
        loaded: true,
        error: false,
      }
    }
    case FETCH_CAREER_PATH_CONTENT_CANCEL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false,
      }
    }
    case FETCH_CAREER_PATH_CONTENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      }
    }

    default:
      return state
  }
}

export default careerPathsReducer
