export const ADD_FILE = 'ADD_FILE'
export const REMOVE_FILE = 'REMOVE_FILE'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE'
export const UPLOAD_FILE_CANCEL = 'UPLOAD_FILE_CANCEL'
export const BATCH_UPLOAD_FILE = 'BATCH_UPLOAD_FILE'
export const BATCH_UPLOAD_FILE_SUCCESS = 'BATCH_UPLOAD_FILE_SUCCESS'
export const BATCH_UPLOAD_FILE_FAILURE = 'BATCH_UPLOAD_FILE_FAILURE'
export const BATCH_UPLOAD_FILE_CANCEL = 'BATCH_UPLOAD_FILE_CANCEL'
export const BATCH_REMOVE_FILE = 'BATCH_REMOVE_FILE'
export const BATCH_REMOVE_FILE_SUCCESS = 'BATCH_REMOVE_FILE_SUCCESS'
export const BATCH_REMOVE_FILE_FAILURE = 'BATCH_REMOVE_FILE_FAILURE'
export const BATCH_REMOVE_FILE_CANCEL = 'BATCH_REMOVE_FILE_CANCEL'
