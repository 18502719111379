import { CourseGrades, CourseID } from './courses'
import {
  MentorshipRecordingSessionID,
  MentorSessionID,
} from './courses/mentorshipRecording'
import { UserAccessType } from './user'
import { ModuleID } from '.'
import { AssignmentID } from './courses/assignment'

/* Dashboard */
export type ResidencyId = number
export interface FeatureLinksData {
  attendance?: string
  career_support?: string
  eportfolio?: string
  fee_payment?: string
  feed?: string
  industry_focus?: string
  refer_earn?: string
  switch_old_ui_path?: string
  program_support?: string
  certificate_transcript?: string
  certificates_only?: string
  pre_enrolment_path?: string
  grade_sheet?: string
  career_prep?: string
  support_email?: string
  career_boost_pro_link?: string
  gl_connect?: string
  career_school?: string
  career_school_jobs?: string
  peer_reviews?: string
}

export interface BatchData {
  account_id: number
  created_at: string
  id: number
  name: string
  sis_id: string
  sub_account_id: number
  updated_at: string
  workflow_state: string
  end_date: string | null
  start_date: string
  enrolled_at: string | null
  is_engagement_batch: boolean
  is_content_restricted: boolean
  is_b2b_batch: boolean
}

export interface PreviewWistiaVideoData {
  video_provider: 'Wistia'
  wistia_id: string
  body: string
}

export interface PreviewHLSVideoData {
  video_provider: 'GL_HLS'
  olympus_token: string
  body: string
}

export interface CourseCurriculumData {
  name: string
  quizzes_count: number
  projects: number
  lms_course_id: null | CourseID
  description: string
  concepts?: string[]
  is_locked?: boolean
  preview_video_details?: PreviewWistiaVideoData | PreviewHLSVideoData
}

export interface CurriculumData {
  category_name: string
  description?: string
  courses: CourseCurriculumData[]
}

export interface OrientationData {
  pending: boolean
  course_id: CourseID | null
  in_progress: boolean
}

export interface SnackbarDetails {
  title: string
  subtitle?: string[]
  action_name?: string
  action_url?: string
  logo_url?: string
  key?: string
}

export interface UnlockedModule {
  module_id: number
  content_id: number
  item_id: number
  url: string
  has_watched: boolean
}

export type SampleCertificate = {
  title: string
  link: string
}

export interface DemoTestimonial {
  name: string
  designation: string
  company_logo: string
  logo_alt_text: string
  user_image: string
  content: string
}
export interface DemoVideoData {
  title: string
  preview_img?: string
  description: string
  video_details: PreviewWistiaVideoData | PreviewHLSVideoData
}
export interface DemoFacultyData {
  name: string
  designation: string
  description: string
  image_url: string
  company_image?: string
}
export interface DemoProjectData {
  image: string
  title: string
  subtitle: string
  details: string
  code: string
}
export interface DemoToolData {
  image: string
  alt_text: string
}
export interface DemoTransitionData {
  name: string
  user_img?: string
  increment_percentage: number
  post_placement_role: string
  post_placement_company: string
  post_placement_company_logo?: string
  pre_placement_role: string
  pre_placement_company: string
}

export type DemoSection =
  | PreviewSection
  | MentorSection
  | ProjectsSection
  | ProgramSection
  | CareerSection
  | CertificatesSection
  | TestimonialsSection

export interface DemoSectionBase {
  type:
    | 'preview_section'
    | 'mentor_section'
    | 'projects_section'
    | 'program_support_section'
    | 'career_support_section'
    | 'certificates_section'
    | 'testimonials_section'
  title: string
  description: string
  label: string
  overline: string
  videos: DemoVideoData[]
}

export interface PreviewSection extends DemoSectionBase {
  type: 'preview_section'
  faculties: DemoFacultyData[]
}

export interface MentorSection extends DemoSectionBase {
  type: 'mentor_section'
  mentors: DemoFacultyData[]
}

export interface ProjectsSection extends DemoSectionBase {
  type: 'projects_section'
  projects: DemoProjectData[]
  tools: DemoToolData[]
}

export interface ProgramSection extends DemoSectionBase {
  type: 'program_support_section'
}

export interface CareerSection extends DemoSectionBase {
  type: 'career_support_section'
  transitions: DemoTransitionData[]
}
export interface CertificatesSection extends DemoSectionBase {
  type: 'certificates_section'
}
export interface TestimonialsSection extends DemoSectionBase {
  type: 'testimonials_section'
  testimonials: DemoTestimonial[]
}
export interface ColorDetails {
  primary_colour: string
  cta_colour: string
  cta_text: string
}
export interface OfferDetails {
  download_link: string
  payment_link: string
  remaining_deadline_days: string
  status: string
}
export interface DemoV3Data {
  schedule_a_call_url: string
  lc_phone_number: string
  program_contact_no: string
  partner_image: string
  colour_scheme: ColorDetails
  program_name: string
  program_mode?: string
  program_duration?: string
  program_working_days?: string
  career_transitions_url: string
  linkedin_resume_url: string
  sections: DemoSection[]
  lc_name: string
}
export interface TrialDetailsData {
  trial_started_at: string
  trial_ends_at: string
  trial_remaining_days: number
  enrolled: boolean
  app_status: 'enrolled' | 'offered' | 'applied' | 'selected' | 'lead'
  access_blocked: boolean
  inaugural_video: string | null
  extension_given: boolean
  extension_popup_shown: boolean
  payment_options: {
    standard?: string
    premium?: string
  }
  offer_details: OfferDetails
  registration_url?: string
  program_curriculum: CurriculumData[]
  certificate_info: SampleCertificate[]
  dashboard_viewed: boolean
  eporfolio_info: {
    title: string
    img: string
    link: string
  }
  get_started: {
    type: 'Course'
    course_id: number
  }
  unlocked_modules: {
    [course_id: number]: UnlockedModule
  }
  unlocked_courses: number[]
  display_enrolment_popup: boolean
  alum_connect_filled?: boolean | null
  group_enrollment?: string | null
  collaboration_text: string
  self_checkout_link?: string
  v3_data?: DemoV3Data
}

export interface AvailableProgramData {
  program_name: string
  program_page_url: string
  landing_page_url: string
  mode: string
  duration: string
  working_days: string
  partners: {
    partner_image: string
    partner_image_text: string
    partner_card_image: string
    partner: string
  }[]
  popularity: number
  image_url: string
  bubble_text: string
}

export interface DashboardBaseData {
  batch: BatchData
  feature_links: FeatureLinksData
  program_name: string
  program_code: string
  program_id: number
  is_degree_program: boolean
  leaderboard_enabled: boolean
  user_access_type: UserAccessType
  orientation: OrientationData
  hackathons_applicable: boolean
  company_name?: string
  muted_resubmission_assignments: AssignmentID[]
  ai_teacher_item: {
    enabled: boolean
    course_id?: number
    module_item?: { id: number; title: string }
  }
}

export interface BasicUserDashboardData extends DashboardBaseData {
  user_access_type: 'basic'
  excelerate_v2_enabled?: boolean
  career_prep_courses_mapped?: boolean
  gift_course_available?: boolean
  is_international: boolean
  upsell_banner_enabled: boolean
  community_invite_count: number
  community_invite_enabled: boolean
}

interface GLAUserReferralDetails {
  referral_code: string | null
  gl_coins: number | null
  referral_source?: number | null
  referral_title?: string | null
  referral_phone?: string | null
  gift_course_consent_required?: boolean
}

export interface CareerSchoolUserDashboardData extends DashboardBaseData {
  user_access_type: 'basic'
  checklist?: string[]
  card_background_color?: string
  school_onboarding_completed?: boolean
  school_job_onboarded: boolean
  is_cs_user_info_collected: boolean
  onboarding_video_embed_code: string
}

export interface GLAUserDashboardBaseData extends DashboardBaseData {
  available_programs: {
    [domain: string]: AvailableProgramData[]
  }
}

export interface FreemiumUserDashboardData extends DashboardBaseData {
  user_access_type: 'freemium'
  program_curriculum: CurriculumData[]
  certificate_info: {
    title: string
    link: string
  }
  trial_remaining_days: number
  program_fee_paid: boolean
  fee_payment_link: string
  program_description: string
}
export interface FreeUserDashboardData
  extends GLAUserDashboardBaseData,
    GLAUserReferralDetails {
  user_access_type: 'free'
  summer_program_homepage?: string
  is_user_info_collected?: boolean
  user_info_collected_at?: Date | string
  isNotLead?: boolean
}

export interface CorporateUserDashboardData extends GLAUserDashboardBaseData {
  user_access_type: 'corporate'
  corporate_info?: {
    company_name: string | null
    company_logo_full_path: string | null
  }
}

export interface FreeInternationalUserDashboardData
  extends GLAUserDashboardBaseData,
    GLAUserReferralDetails {
  user_access_type: 'free_international'
  summer_program_homepage?: string
  is_user_info_collected?: boolean
  user_info_collected_at?: Date | string
  isNotLead?: boolean
}

export interface TrialUserDashboardData extends DashboardBaseData {
  user_access_type: 'trial'
  trial_details: TrialDetailsData
}

export type GlaDashboardData =
  | CorporateUserDashboardData
  | FreeUserDashboardData
  | FreeInternationalUserDashboardData

export type DashboardData =
  | BasicUserDashboardData
  | CorporateUserDashboardData
  | FreemiumUserDashboardData
  | FreeUserDashboardData
  | TrialUserDashboardData
  | FreeInternationalUserDashboardData
  | CareerSchoolUserDashboardData

export const isTrialUserDashboardData = (
  obj: DashboardData
): obj is TrialUserDashboardData => obj.user_access_type === 'trial'

export interface AssessmentActivityData {
  assessment_id: number
  assessment_name: string
  course_id: number
  course_name: string
  course_url: string
  evaluation_pending: boolean
  // is_active: boolean
  is_practice: boolean
  lms_assessment_url: string
  published: boolean
  student_marks?: number
  total_marks: number
  start_date: string
  end_date: string
  type: 'assessment'
  assessment_title: string | null
}

export interface ResidencyCoursesData {
  course_name: string
  course_url: string
  duration_in_hours: string
  faculty_name: string
  item_id: number
  topics: string | null
}

export interface ResidencyActivityData {
  courses: ResidencyCoursesData[]
  end_date: string
  published: boolean
  start_date: string
  type: 'residency'
  residency_id: number
}

export interface OnlineSessionData {
  course_name: string
  course_url: string
  duration_in_hours: string
  end_date: null | string
  faculty_name: null | string
  session_join_url?: string
  online_session_type: string
  published: boolean
  mentored_group_session_id: MentorSessionID
  mentored_learning_id: MentorshipRecordingSessionID
  session_start_datetime: string
  session_start_time: string
  start_date: string
  topic: string
  type: 'online_session'
  hide_recording?: boolean
}

export type ActivityData =
  | AssessmentActivityData
  | ResidencyActivityData
  | OnlineSessionData

export interface ResidencyItemTimetableData {
  start_time: string
  end_time: string
}

export interface ResidencyItemData {
  timetable_item: ResidencyItemTimetableData
  item_name: string
  item_template_name: string
  faculty_name?: string
  topics?: string | null
}

export interface ResidencyData {
  displayName: string
  formattedDate: string
  scheduled_items: ResidencyItemData[]
}

export interface AttendanceHistoryData {
  date: string
  full_date: string
  status: string
  type: string | null
  mandatory_session: boolean
  duration_in_hours?: number
  attended_mins?: number
  course_name: string | null
  faculty_name: string | null
  topic: string | null
  session_id?: number
  duration_attended_text: string
  today_session: boolean
  status_tooltip_text: boolean | null
}

export interface AttendanceData {
  attendance_percentage: number
  enrolment_status: string
  message: string
  past_attendances: AttendanceHistoryData[]
  program_type: string
  status: string
  present_threshold_percentage: number | null
}

export interface CourseProgressData {
  display_name: string
  course_name: string
  last_access: string
  type: string
  link: string
  course_id: CourseID
  module_id: ModuleID
  percentage_watched?: number
  duration?: number
  module_item_id?: number
}

export interface GradebookCourseAssessmentsData {
  assessment_type: string
  assignment_name: string
  due_at: string | null
  evaluation_pending: boolean
  score: number
  sort_by_value: string
  submitted: boolean
  submitted_at: string | null
  total_score: number
  url: string
  state: 'locked' | 'unsubmitted' | 'evaluation_pending' | 'graded' | null
  weighted_score?: number | null
  weighted_total?: number | null
}

export interface GradebookCoursesData {
  course_id: number
  course_name: string
  course_state: 'completed' | 'In progress'
  grade: CourseGrades
  score: number
  sort_by_value: string
  total_score: number
  assessments: GradebookCourseAssessmentsData[]
  max?: number
  median?: number
  percentile_90?: number
  grade_point?: number
  credits?: number
  weighted_average?: number
  weighted_total?: number
}

export interface GradebookData {
  failed_courses_count: number
  inprogress_courses_count: number
  passed_course_count: number
  at_risk_courses: number
  excellent_courses: number
  gpa_grading_scheme: boolean
  show_weighted_grades: boolean
  gpa_details?: GPADetails
}

export interface GPADetails {
  credits_earned: number
  gpa: number
  total_possible_credits: number
  passing_score: number
  grading_scheme_items: GradingSchemeItem[] | null
}

export interface GradingSchemeItem {
  grade: string
  grade_point: number
  min_percentage: number
  max_percentage: number
}

export interface CollegeData {
  id: number
  name: string
}

export interface UniversityData {
  id: number
  name: string
}

export interface CityData {
  city_id: number
  state_id: number
  name: string
}

export interface percentageCompletedSchema {
  percentage: string
  remainingSeconds: number
}

export type CoursePercentageCompletedData = {
  [key in CourseID]?: percentageCompletedSchema
}

export interface IUserGiftCourse {
  course_id: number
  receiver_name: string
  receiver_email: string
  receiver_phone: string
  is_referee: boolean | null
  is_international_user: boolean
}
