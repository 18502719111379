import { Box } from '@material-ui/core'
import React from 'react'
import Lottie from 'react-lottie'
import { useSelector } from 'react-redux'
import { Typography } from 'common/elements'
import cx from 'classnames'
import GLLoaderAnimation from '../../../common/lotties/GLLoaderAnimation.json'

export function GlLoader() {
  const uiFlags = useSelector(state => state.common.ui)

  const heading = uiFlags.glLoader?.heading || ''
  const description = uiFlags.glLoader?.description || ''

  return uiFlags.glLoader?.show ? (
    <Box
      bgcolor="white"
      position="absolute"
      height="100%"
      top={0}
      right={0}
      left={0}
      zIndex={1101}
    >
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          gridGap={24}
          justifyContent="center"
          alignItems="center"
        >
          <Lottie
            width={150}
            height={150}
            options={{
              autoplay: true,
              loop: true,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
              animationData: GLLoaderAnimation,
            }}
          />
          <Typography className={cx('h4')}>{heading}</Typography>
          <Typography className={cx('overline')} variant="overline">
            {description}
          </Typography>
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  )
}
