import { apiCall, getUserDetails } from 'common/utils'
import { fetchGlaCertificatePayment } from './GlaCertificatePaymentProvider.actions'

export async function fetchGlaCertificatePaymentAPI(
  action: ReturnType<typeof fetchGlaCertificatePayment>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${process.env.REACT_APP_SHINE_BACKEND_URL}api/v1/fee_codes/course_payment_details`,
    params: { signal },
    query: { user_id: id, ...action.payload },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
