import { cancelable } from 'common/utils'
import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import {
  fetchAssessmentScheduleDetailsFailure,
  fetchAssessmentScheduleDetailsSuccess,
  fetchAssessmentScheduleDetails,
  fetchInvigilationDetails,
  fetchInvigilationDetailsSuccess,
  fetchInvigilationDetailsFailure,
  updateInvigilationMessages,
  updateInvigilationMessagesSuccess,
  updateInvigilationMessagesFailure,
  approveOrRejectOnboardingPhotoSuccess,
  approveOrRejectOnboardingPhotoFailure,
  approveOrRejectOnboardingPhoto,
} from './Invigilation.actions'
import {
  FETCH_ASSESSMENT_SCHEDULE_DETAILS,
  FETCH_ASSESSMENT_SCHEDULE_DETAILS_CANCEL,
  FETCH_INVIGILATION_DETAILS,
  FETCH_INVIGILATION_DETAILS_CANCEL,
  UPDATE_INVIGILATION_MESSAGES,
  UPDATE_INVIGILATION_MESSAGES_CANCEL,
  APPROVE_OR_REJECT_ONBOARDING_PHOTO,
  APPROVE_OR_REJECT_ONBOARDING_PHOTO_CANCEL,
} from './Invigilation.types'
import * as APIs from './Invigilation.api'
import { showAlertMessage } from '../AlertsProvider'

function* getAssessmentScheduleDetails(
  action: ReturnType<typeof fetchAssessmentScheduleDetails>
) {
  const abortController = new AbortController()
  try {
    const data =
      'assessmentScheduleId' in action.meta
        ? yield call(
            APIs.getAssessmentScheduleDetailsAPI,
            action.meta.assessmentScheduleId,
            abortController.signal
          )
        : yield call(
            APIs.getAssessmentScheduleDetailsByKeyAPI,
            action.meta.proctorKey,
            abortController.signal
          )
    yield put(fetchAssessmentScheduleDetailsSuccess(data, action.meta))
  } catch (e) {
    yield put(fetchAssessmentScheduleDetailsFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
function* getInvigilationDetails(
  action: ReturnType<typeof fetchInvigilationDetails>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.getInvigilationDetailsAPI,
      action.meta.assessmentScheduleId,
      abortController.signal
    )
    const newMessagesData = data.messages
    const newMessagesDataKeys = Object.keys(newMessagesData)
    newMessagesDataKeys.sort((key1: string, key2: string) => {
      const messagesData = newMessagesData[key1]
      const messagesData2 = newMessagesData[key2]
      if (messagesData.length) {
        if (messagesData2.length) {
          const lastMessage1 = messagesData[messagesData.length - 1]
          const lastMessage2 = messagesData2[messagesData2.length - 1]
          return (
            new Date(lastMessage2.timestamp).getTime() -
            new Date(lastMessage1.timestamp).getTime()
          )
        }
        return -1
      }
      return 1
    })
    data.messagesOrder = newMessagesDataKeys
    yield put(fetchInvigilationDetailsSuccess(data, action.meta))
  } catch (e) {
    yield put(fetchInvigilationDetailsFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* updateInvigilationMessagesHandler(
  action: ReturnType<typeof updateInvigilationMessages>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.updateInvigilationMessagesAPI,
      action.payload,
      action.meta.assessmentScheduleId,
      action.meta.source,
      abortController.signal
    )
    yield put(updateInvigilationMessagesSuccess(data, action.meta))
  } catch (e) {
    yield put(updateInvigilationMessagesFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
function* approveOrRejectOnboardingPhotoHandler(
  action: ReturnType<typeof approveOrRejectOnboardingPhoto>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.approveOrRejectOnboardingPhotoHandlerAPI,
      action.payload,
      abortController.signal
    )
    yield put(approveOrRejectOnboardingPhotoSuccess(data, action.meta))

    yield put(
      showAlertMessage({
        variant: 'success',
        message:
          action.payload.action === 'reject'
            ? 'Image is rejected'
            : 'Image is approved',
      })
    )
  } catch (e) {
    yield put(approveOrRejectOnboardingPhotoFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* invigilationMiddleware() {
  yield takeLatest(
    FETCH_ASSESSMENT_SCHEDULE_DETAILS,
    cancelable(
      getAssessmentScheduleDetails,
      FETCH_ASSESSMENT_SCHEDULE_DETAILS_CANCEL
    )
  )
  yield takeLatest(
    FETCH_INVIGILATION_DETAILS,
    cancelable(getInvigilationDetails, FETCH_INVIGILATION_DETAILS_CANCEL)
  )
  yield takeLatest(
    UPDATE_INVIGILATION_MESSAGES,
    cancelable(
      updateInvigilationMessagesHandler,
      UPDATE_INVIGILATION_MESSAGES_CANCEL
    )
  )
  yield takeLatest(
    APPROVE_OR_REJECT_ONBOARDING_PHOTO,
    cancelable(
      approveOrRejectOnboardingPhotoHandler,
      APPROVE_OR_REJECT_ONBOARDING_PHOTO_CANCEL
    )
  )
}

export default ([] as any).concat(invigilationMiddleware())
