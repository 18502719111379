import { Reducer } from 'redux'
import { PrivateCertificate } from 'common/types/privateCertificate'
import { UserDetailsState } from 'web/providers/User/UserDetailsProvider'
import { PrivateCertificatesActions } from './PrivateCertificate.actions'
import {
  FETCH_PRIVATE_CERTIFICATES_CONTENT,
  FETCH_PRIVATE_CERTIFICATES_CONTENT_SUCCESS,
  FETCH_PRIVATE_CERTIFICATES_CONTENT_FAILURE,
  FETCH_PRIVATE_CERTIFICATES_CONTENT_CANCEL,
} from './PrivateCertificate.types'

export interface PrivateCertificatesState {
  data?: PrivateCertificate
  loading: boolean
  error: false | Error | Response
}

const initialState: PrivateCertificatesState = {
  loading: false,
  error: false,
}

const privateCertificatesReducer: Reducer<
  PrivateCertificatesState,
  PrivateCertificatesActions
> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRIVATE_CERTIFICATES_CONTENT:
      return { ...state, loading: true, error: false }

    case FETCH_PRIVATE_CERTIFICATES_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      }

    case FETCH_PRIVATE_CERTIFICATES_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case FETCH_PRIVATE_CERTIFICATES_CONTENT_CANCEL:
      return {
        ...state,
        loading: false,
        error: false,
      }

    default:
      return state
  }
}

export default privateCertificatesReducer
