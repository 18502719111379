import { getUserDetails, apiCall } from 'common/utils'
import { PeerRatingSubmissionData } from 'common/types/peerRatings'

export async function getPendingPeerRatingsAPI(signal: AbortSignal) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/peer_ratings/pending`,
    query: {
      user_id: id,
    },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function submitPeerRatingsAPI(
  data: PeerRatingSubmissionData,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/peer_ratings`,
    query: {
      user_id: id,
    },
    params: { method: 'POST', body: JSON.stringify(data), signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
