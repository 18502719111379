import {
  AppBar,
  Badge,
  Box,
  Link as MuiLink,
  Popover,
  Toolbar,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import cx from 'classnames'
import { Button, IconButton } from 'common/elements'
import { useBreakpoint, useMedia } from 'common/elements/hooks'
import {
  AlignJustify,
  Bell,
  Briefcase,
  Calendar,
  HeartHandshake,
  HelpCircle,
  Home,
  MoreHorizontal,
  Puzzle,
  Search,
  Tv,
  Users,
} from 'lucide-react'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch, Link } from 'react-router-dom'
import { AppLogo } from 'web/components/AppLogo/AppLogo'
import { useFeatureLinksData, useIsNewUIEnabled } from 'web/hooks'
import { AppState } from 'web/store'
import { fetchUserGroups } from 'web/providers/User/UserGroupsProvider'
import withStyles from '@material-ui/styles/withStyles'
import { LocationDescriptorObject } from 'history'
import { DashboardDrawer } from './dashboard-drawer'
import { ProfileMenu, TargetEnum } from './profile-menu'
import { CourseSearch } from './search'
import { SwitchBatch } from './switch-batch'
import { apiCall, generateURL } from '../../../common/utils'

const useStyles = makeStyles({
  appBar: {
    borderBottom: '2px solid #75768029',
    backgroundColor: 'white',
    position: 'relative',
  },
})

const useMoreMenuItemStyles = makeStyles({
  container: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    padding: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f3f7',
    },
    '&:active': {
      backgroundColor: '#0054d614',
    },
  },
  badge: {
    '& .MuiBadge-badge': {
      backgroundColor: '#ba1a17',
    },
  },
})

const AppBarTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 12,
    backgroundColor: '#000000',
    borderRadius: 8,
  },
  arrow: {
    color: '#000000',
  },
}))(Tooltip)

const useUnreadAnnouncementCount = () => {
  const dispatch = useDispatch()
  const user_id = useSelector(state => state.user.details.id)
  const courses = useSelector(state => state.courses)
  const groups = useSelector(state => state.user.groups)
  const [count, setCount] = useState(0)

  const contextIds = useMemo(() => {
    let queryParams = ''
    const courseIds = [
      ...courses.data.activeIds,
      ...courses.data.completedIds,
      ...courses.data.failedIds,
    ]
    if (courseIds.length) {
      queryParams += courseIds.map(e => `course_ids[]=${e}`).join('&')
    }

    const groupString = Object.values(groups.data.byId || {})
      .map((e: any) => `group_ids[]=${e.id}`)
      .join('&')
    if (groupString) {
      queryParams += `&${groupString}`
    }

    return queryParams
  }, [courses, groups])

  const getCount = async () => {
    try {
      const response = await apiCall({
        url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/announcements/count?${contextIds}&user_id=${user_id}`,
        params: {
          method: 'GET',
        },
      })

      const result = await response.json()

      if (result) {
        const allCount =
          (result.course?.count || 0) + (result.group?.count || 0)

        setCount(allCount)
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('useUnreadAnnouncementCount - api error', err)
    }
  }

  useEffect(() => {
    dispatch(fetchUserGroups())
  }, [])

  useEffect(() => {
    if (contextIds.length) getCount()
  }, [contextIds])

  return count
}

function MoreMenuItem({ menu }) {
  const handlePress = () => {
    if (typeof menu.onPress === 'function') {
      menu.onPress()
    }
  }

  return menu.badge ? (
    <Badge badgeContent={menu.badge}>
      <MenuItemLinkButton menu={menu} onPress={handlePress} />
    </Badge>
  ) : (
    <MenuItemLinkButton menu={menu} onPress={handlePress} />
  )
}

function MenuItemLinkButton({
  menu,
  onPress,
}: {
  menu: {
    title: string
    to?: string | LocationDescriptorObject
    icon: React.ComponentType<any> | React.ReactNode
    onPress?: () => void
    badge?: number
    target?: TargetEnum
  }
  onPress: () => void
}) {
  const match = useRouteMatch(
    (typeof menu?.to === 'string' ? menu.to : menu?.to?.pathname) || ''
  )

  return menu.to ? (
    <>
      {typeof menu?.to === 'string' && menu?.to?.includes('http') ? (
        <MuiLink href={menu.to} target={menu.target}>
          <Button
            className={cx({ selected: match?.isExact })}
            disableRipple
            startIcon={menu.icon}
            variant="text"
          >
            {menu.title}
          </Button>
        </MuiLink>
      ) : (
        <Link to={menu.to}>
          <Button
            className={cx({ selected: match?.isExact })}
            disableRipple
            startIcon={menu.icon}
            variant="text"
          >
            {menu.title}
          </Button>
        </Link>
      )}
    </>
  ) : (
    <Button
      className={cx({ selected: match?.isExact })}
      disableRipple
      startIcon={menu.icon}
      variant="text"
      onClick={onPress}
    >
      {menu.title}
    </Button>
  )
}

function MenuItem({
  menu,
}: {
  menu: {
    title: string
    to?: string | LocationDescriptorObject
    icon: React.ComponentType<any> | React.ReactNode
    onPress?: () => void
    badge?: number
    target?: TargetEnum
  }
}) {
  const handlePress = () => {
    if (menu.onPress) {
      menu.onPress()
    }
  }

  return menu.badge ? (
    <Badge badgeContent={menu.badge}>
      <MenuItemLinkButton menu={menu} onPress={handlePress} />
    </Badge>
  ) : (
    <MenuItemLinkButton menu={menu} onPress={handlePress} />
  )
}

export function MoreMenu({ menus = [] }) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        onClick={handleClick}
        display="flex"
        bgcolor={open && '#0054d629'}
        borderRadius={8}
      >
        <IconButton className={{ selected: open }} disableRipple>
          <MoreHorizontal {...(open && { color: '#0054d6' })} />
        </IconButton>
      </Box>
      <Popover
        style={{ marginTop: '16px' }}
        transitionDuration={0}
        elevation={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          maxHeight={500}
          borderRadius={8}
          paddingX={2}
          paddingY={1}
          gridGap={8}
          maxWidth={248}
        >
          {menus.map(menu => {
            return <MoreMenuItem key={menu.title} menu={menu} />
          })}
        </Box>
      </Popover>
    </>
  )
}

export function HomeAppBar({ children, ...props }) {
  const count = useUnreadAnnouncementCount()
  const moreMenuStyles = useMoreMenuItemStyles()

  const styles = useStyles()
  const media = useMedia()
  const history = useHistory()
  const isNewUIEnabled = useIsNewUIEnabled()
  const featureLinks = useFeatureLinksData()
  const [showDrawer, toggleShowDrawer] = useState(false)
  const [openSearch, toggleOpenSearch] = useState(false)
  const [isAccessBlocked, setIsAccessBlocked] = useState(false)
  const disableDashboard = !featureLinks?.learner_dashboard_page?.url
  const programSupportLocation = generateURL('PROGRAM_SUPPORT', null)
  const announcementLocation = generateURL('ANNOUNCEMENTS', null)
  const programSupportMatch = useRouteMatch(
    programSupportLocation.pathname || ''
  )
  const announcementMatch = useRouteMatch(announcementLocation.pathname || '')

  const communityNotificationCount = useSelector((state: AppState) =>
    featureLinks?.community?.url ? state.community.data.notifications : 0
  )

  const { gt: menuCount = 5 } = useBreakpoint({ gtXXs: 4, gtLg: 5 })
  const { menus, moreMenu } = useMemo(() => {
    const menuLocal = [
      !disableDashboard && {
        title: 'Dashboard',
        to: generateURL('LEARNER_DASHBOARD', null),
        icon: <Home />,
      },
      ...(isNewUIEnabled && !disableDashboard
        ? [
            {
              title: 'Activities',
              to: featureLinks?.activities?.url,
              icon: <Calendar />,
            },
          ]
        : []),
      ...(featureLinks?.courses_page?.url
        ? [
            {
              title: 'Courses',
              to: isNewUIEnabled
                ? featureLinks?.courses_page?.url
                : generateURL('COURSES', null),
              icon: <Tv />,
            },
          ]
        : []),
      ...(featureLinks?.visa?.url
        ? [
            {
              title: 'Visa',
              to: featureLinks?.visa?.url,
              icon: <Users />,
            },
          ]
        : []),
      {
        title: featureLinks?.excelerate?.url?.includes?.('digital-excelerate')
          ? 'CareerBoost'
          : 'Excelerate',
        to: featureLinks?.excelerate?.url,
        icon: <Briefcase />,
      },
      {
        title: 'Gallery',
        to: featureLinks?.peer_reviews?.url && generateURL('PEER_REVIEW', null),
        icon: <Briefcase />,
      },
      {
        title: 'Hackathons',
        to: featureLinks?.hackathons?.url && generateURL('HACKATHONS', null),
        icon: <Puzzle />,
      },
      {
        title: 'Connect',
        to: featureLinks?.gl_connect?.url && generateURL('GL_CONNECT', null),
        icon: <HeartHandshake />,
      },
      ...(featureLinks?.community?.url
        ? [
            {
              title: 'Community',
              to: featureLinks?.community?.url,
              target: '_blank',
              icon: <Users />,
              badge: communityNotificationCount,
            },
          ]
        : []),
    ].filter(m => !!m && (m.to || m.onPress))
    return {
      menus: menuLocal.slice(0, menuCount) || [],
      moreMenu: menuLocal.slice(menuCount) || [],
    }
  }, [featureLinks, menuCount, communityNotificationCount, isNewUIEnabled])

  useEffect(() => {
    if (media.gtLg) {
      toggleShowDrawer(false)
    }
  }, [media.gtLg])
  useEffect(() => {
    setIsAccessBlocked(props.admission?.data?.access_blocked)
  }, [props.admission?.data?.access_blocked])

  return (
    <>
      <AppBar
        className={cx(styles.appBar)}
        elevation={0}
        {...props}
        key="home-app-bar"
      >
        <Toolbar style={{ paddingLeft: 16, paddingRight: 16, height: 64 }}>
          <Box
            gridGap={4}
            display="flex"
            flexDirection="row"
            alignItems="center"
            flex={1}
          >
            <Box
              gridGap={16}
              flex={1}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Box display={media.gtLg ? 'none' : 'block'}>
                <IconButton
                  disableRipple
                  onClick={() => toggleShowDrawer(sd => !sd)}
                >
                  <AlignJustify />
                </IconButton>
              </Box>

              <AppLogo />
              <Box display={media.lg ? 'none' : 'block'}>
                <SwitchBatch />
              </Box>
              {!isAccessBlocked && (
                <Box
                  gridGap={8}
                  display={media.lg ? 'none' : 'flex'}
                  flexDirection="row"
                  alignItems="center"
                >
                  {menus.map(menu => {
                    return menu.to || menu.onPress ? (
                      <MenuItem menu={menu} />
                    ) : null
                  })}
                  {!!moreMenu.length && <MoreMenu menus={moreMenu} />}
                </Box>
              )}
            </Box>
            <Box
              gridGap={16}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              {!isAccessBlocked && (
                <AppBarTooltip title="Search" arrow>
                  <IconButton
                    disableRipple
                    onClick={() => toggleOpenSearch(true)}
                  >
                    <Search {...(openSearch && { color: '#0054d6' })} />
                  </IconButton>
                </AppBarTooltip>
              )}
              {featureLinks?.program_support?.url && (
                <Box
                  display={media.lg ? 'none' : 'flex'}
                  flexDirection="row"
                  justifyContent="center"
                >
                  {isNewUIEnabled ? (
                    <MuiLink href={featureLinks?.program_support?.url}>
                      <AppBarTooltip title="Support" arrow>
                        <IconButton disableRipple>
                          <HelpCircle
                            {...(programSupportMatch?.isExact && {
                              color: '#0054d6',
                            })}
                          />
                        </IconButton>
                      </AppBarTooltip>
                    </MuiLink>
                  ) : (
                    <Link to={programSupportLocation}>
                      <AppBarTooltip title="Support" arrow>
                        <IconButton disableRipple>
                          <HelpCircle
                            {...(programSupportMatch?.isExact && {
                              color: '#0054d6',
                            })}
                          />
                        </IconButton>
                      </AppBarTooltip>
                    </Link>
                  )}
                </Box>
              )}
              {!isAccessBlocked && featureLinks?.notifications?.url && (
                <Box
                  display={media.gtSm ? 'flex' : 'none'}
                  flexDirection="row"
                  justifyContent="center"
                >
                  {/* <Link to={featureLinks?.notifications?.url}>
                    <IconButton disableRipple>
                      <Badge
                        variant="dot"
                        badgeContent={count}
                        className={cx(moreMenuStyles.badge)}
                      >
                        <Bell
                          {...(announcementMatch?.isExact && {
                            color: '#0054d6',
                          })}
                        />
                      </Badge>
                    </IconButton>
                  </Link> */}
                  <MuiLink href={featureLinks?.notifications?.url}>
                    <AppBarTooltip title="Notifications" arrow color="red">
                      <IconButton disableRipple>
                        <Badge
                          variant="dot"
                          badgeContent={count}
                          className={cx(moreMenuStyles.badge)}
                        >
                          <Bell />
                        </Badge>
                      </IconButton>
                    </AppBarTooltip>
                  </MuiLink>
                </Box>
              )}
              <ProfileMenu isAccessBlocked={isAccessBlocked} />
            </Box>
            {children}
          </Box>
        </Toolbar>
      </AppBar>
      <CourseSearch open={openSearch} onClose={() => toggleOpenSearch(false)} />
      <DashboardDrawer
        open={showDrawer}
        onClose={() => toggleShowDrawer(false)}
        isAccessBlocked={isAccessBlocked}
      />
    </>
  )
}
