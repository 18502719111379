import React, { ErrorInfo } from 'react'
import { Context, getRollbarFromContext } from '@rollbar/react'
import Rollbar from 'rollbar'
import ErrorIllustrations from '../../../containers/Utils/ErrorIllustrations'
import { mixpanel } from '../../../../common/utils/mixpanel'
import {
  CustomError,
  isNetworkError,
  stringMatchAgainstArrayOfStrings,
} from '../../../../common/utils'
import NetworkDetector from './NetworkDetector'
import Loader from '../Loader'
import ServiceUnavailable from './ServiceUnavailable'

interface Props {
  children: React.ReactNode
}

interface State {
  error: false | Error | Response | CustomError<any>
}

class ErrorBoundary extends React.Component<Props, State> {
  state: State = { error: false }

  rollbar: Rollbar | undefined

  componentDidMount(): void {
    this.rollbar = getRollbarFromContext(this.context)
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  static contextType = Context

  componentDidCatch(error: Error, info: ErrorInfo) {
    if (
      !(
        (this.state.error instanceof Error &&
          isNetworkError(this.state.error.message)) ||
        this.state.error instanceof Response
      )
    ) {
      if (this.rollbar) this.rollbar.error(error)
      mixpanel.track('LMS - Errors', {
        error_message: error.message,
        error_stack: info,
      })
    }
  }

  render() {
    if (this.state.error) {
      if (
        (this.state.error instanceof Response &&
          this.state.error.status === 401) ||
        (this.state.error instanceof Error &&
          this.state.error.message.includes('Invalid access token'))
      )
        return <ErrorIllustrations type="401" />
      if (
        this.state.error instanceof Error &&
        isNetworkError(this.state.error.message)
      ) {
        return <NetworkDetector error={this.state.error} />
      }
      if (
        this.state.error instanceof Response &&
        this.state.error.status === 503
      ) {
        return <ServiceUnavailable error={this.state.error} />
      }
      if (
        this.state.error instanceof Error &&
        stringMatchAgainstArrayOfStrings(
          this.state.error.message.toLowerCase(),
          ['loading', 'chunk', 'failed']
        )
      )
        return <Loader />

      return <ErrorIllustrations type="error" />
    }
    return this.props.children
  }
}

export default ErrorBoundary
