import { cancelled, put, takeLatest, call } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import * as types from './PeerReviews.types'
import * as APIs from './PeerReviews.api'
import DQAPIs from '../Courses/ModuleItemsProvider/Discussion/Discussion.api'
import { showAlertMessage } from '../AlertsProvider'

import {
  fetchPeerReviews,
  fetchPeerReviewsSuccess,
  fetchPeerReviewsFailure,
  fetchPeerReviewsDQ,
  fetchPeerReviewsDQSuccess,
  fetchPeerReviewsDQFailure,
  fetchPeerReviewsDQComments,
  fetchPeerReviewsDQCommentsSuccess,
  fetchPeerReviewsDQCommentsFailure,
  updatePeerReviewCommentRating,
  updatePeerReviewCommentRatingSuccess,
  updatePeerReviewCommentRatingFailure,
  postPeerReviewComment,
  postPeerReviewCommentSuccess,
  postPeerReviewCommentFailure,
  postPeerReviewCommentReply,
  postPeerReviewCommentReplySuccess,
  postPeerReviewCommentReplyFailure,
  updatePeerReviewComment,
  updatePeerReviewCommentSuccess,
  updatePeerReviewCommentFailure,
  reactToPeerReview,
  reactToPeerReviewSuccess,
  reactToPeerReviewFailure,
  markCommentAsRead,
  markCommentAsReadSuccess,
  markCommentAsReadFailure,
  fetchPeerReviewFilters,
  fetchPeerReviewFiltersSuccess,
} from './PeerReviews.actions'

function* fetchPeerReviewsHandler(action: ReturnType<typeof fetchPeerReviews>) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      APIs.getPeerReviewsAPI,
      action,
      abortController.signal
    )

    yield put(fetchPeerReviewsSuccess(data))
  } catch (e) {
    yield put(fetchPeerReviewsFailure(e))
  }
}

function* fetchPeerReviewsDQHandler(
  action: ReturnType<typeof fetchPeerReviewsDQ>
) {
  const abortController = new AbortController()

  try {
    const commentsData = yield call(
      DQAPIs.getDiscussionEntry,
      {
        discussionId: action.topicId,
        courseId: action.courseId,
      },
      abortController.signal
    )

    const likesMetaData = yield call(
      APIs.getPeerReviewReactionData,
      {
        peerReviewId: action.peerReviewId,
      },
      abortController.signal
    )

    const data = { commentsData, likesMetaData }

    yield put(fetchPeerReviewsDQSuccess(data))
  } catch (e) {
    yield put(fetchPeerReviewsDQFailure(e))
  }
}

function* fetchPeerReviewsDQCommentsHandler(
  action: ReturnType<typeof fetchPeerReviewsDQComments>
) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      DQAPIs.getDiscussionEntries,
      {
        courseId: action.courseId,
        discussionId: action.topicId,
      },
      abortController.signal
    )
    yield put(fetchPeerReviewsDQCommentsSuccess(data))
  } catch (e) {
    yield put(fetchPeerReviewsDQCommentsFailure(e))
    yield put(
      showAlertMessage({
        message: 'Sorry something went wrong',
        variant: 'error',
      })
    )
  }
}

function* updatePeerReviewCommentRatingHandler(
  action: ReturnType<typeof updatePeerReviewCommentRating>
) {
  const abortController = new AbortController()

  try {
    yield call(
      DQAPIs.updateDiscussionEntryRating,
      {
        discussionId: action.topicId,
        courseId: action.courseId,
        discussionEntryId: action.discussionEntry.id,
        rating: action.rating,
      },
      abortController.signal
    )

    yield put(
      updatePeerReviewCommentRatingSuccess({
        discussionEntry: action.discussionEntry,
        rating: action.rating,
      })
    )
  } catch (e) {
    yield put(updatePeerReviewCommentRatingFailure(e))
  }
}

function* postPeerReviewCommentHandler(
  action: ReturnType<typeof postPeerReviewComment>
) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      DQAPIs.postDiscussionEntry,
      {
        discussionId: action.payload.topicId,
        courseId: action.payload.courseId,
        message: action.payload.message,
      },
      abortController.signal
    )

    yield put(
      showAlertMessage({
        message: 'Posted comment successfully',
        variant: 'success',
      })
    )

    yield put(postPeerReviewCommentSuccess(data))
  } catch (e) {
    yield put(postPeerReviewCommentFailure(e))
    yield put(
      showAlertMessage({
        message: 'Sorry something went wrong',
        variant: 'error',
      })
    )
  }
}

function* updatePeerReviewCommentHandler(
  action: ReturnType<typeof updatePeerReviewComment>
) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      DQAPIs.updateDiscussionEntry,
      {
        discussionId: action.payload.topicId,
        courseId: action.payload.courseId,
        message: action.payload.message,
        discussionEntryId: action.payload.entryId,
      },
      abortController.signal
    )

    yield put(
      showAlertMessage({
        message: 'Updated comment successfully',
        variant: 'success',
      })
    )

    yield put(updatePeerReviewCommentSuccess(data))
  } catch (e) {
    yield put(updatePeerReviewCommentFailure(e))
    yield put(
      showAlertMessage({
        message: 'Sorry something went wrong',
        variant: 'error',
      })
    )
  }
}

function* postPeerReviewCommentReplyHandler(
  action: ReturnType<typeof postPeerReviewCommentReply>
) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      DQAPIs.postDiscussionEntryReply,
      {
        discussionId: action.payload.topicId,
        courseId: action.payload.courseId,
        message: action.payload.message,
        parentDiscussionEntryId: action.payload.parentId,
      },
      abortController.signal
    )

    yield put(
      showAlertMessage({
        message: 'Posted reply successfully',
        variant: 'success',
      })
    )

    yield put(postPeerReviewCommentReplySuccess(data))
  } catch (e) {
    yield put(postPeerReviewCommentReplyFailure(e))
    yield put(
      showAlertMessage({
        message: 'Sorry something went wrong',
        variant: 'error',
      })
    )
  }
}

function* reactToPeerReviewHandler(
  action: ReturnType<typeof reactToPeerReview>
) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      APIs.reactToPeerReviewApi,
      {
        peerReviewId: action.peerReviewId,
        isLiked: action.isLiked,
      },
      abortController.signal
    )

    yield put(
      reactToPeerReviewSuccess(
        action.peerReviewId,
        action.isLiked,
        data.likes_meta
      )
    )
  } catch (e) {

    yield put(reactToPeerReviewFailure(e))
  }
}

function* markCommentAsReadHandler(
  action: ReturnType<typeof markCommentAsRead>
) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      DQAPIs.markEntryAsRead,
      {
        courseId: action.payload.courseId,
        discussionId: action.payload.discussionTopicId,
        discussionEntryId: action.payload.discussionEntryId,
      },
      abortController.signal
    )

    yield put(markCommentAsReadSuccess(action.payload.discussionEntryId))
  } catch (e) {
    yield put(markCommentAsReadFailure(e))
  }
}

function* peerReviewFiltersHandler(
  action: ReturnType<typeof fetchPeerReviewFilters>
) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      APIs.getPeerReviewFilters,
      {},
      abortController.signal
    )
    yield put(fetchPeerReviewFiltersSuccess(data))
  } catch (e) {
    yield put(markCommentAsReadFailure(e))
  }
}

export function* peerReviewsMiddleware() {
  yield takeLatest(types.FETCH_PEER_REVIEWS, fetchPeerReviewsHandler)
}
export function* peerReviewsDQMiddleware() {
  yield takeLatest(types.FETCH_PEER_REVIEWS_DQ, fetchPeerReviewsDQHandler)
}
export function* peerReviewsDQCommentsMiddleware() {
  yield takeLatest(
    types.FETCH_PEER_REVIEWS_DQ_COMMENTS,
    fetchPeerReviewsDQCommentsHandler
  )
}
export function* updatePeerReviewCommentRatingMiddleware() {
  yield takeLatest(
    types.UPDATE_PEER_REVIEWS_COMMENT_RATING,
    updatePeerReviewCommentRatingHandler
  )
}
export function* postPeerReviewCommentMiddleware() {
  yield takeLatest(types.POST_PEER_REVIEW_COMMENT, postPeerReviewCommentHandler)
}
export function* updatePeerReviewCommentMiddleware() {
  yield takeLatest(
    types.UPDATE_PEER_REVIEW_COMMENT,
    updatePeerReviewCommentHandler
  )
}
export function* postPeerReviewCommentReplyMiddleware() {
  yield takeLatest(
    types.POST_PEER_REVIEW_COMMENT_REPLY,
    postPeerReviewCommentReplyHandler
  )
}

export function* reactToPeerReviewMiddleware() {
  yield takeLatest(types.REACT_TO_PEER_REVIEW, reactToPeerReviewHandler)
}

export function* markCommentAsReadMiddleware() {
  yield takeLatest(types.MARK_COMMENT_AS_READ, markCommentAsReadHandler)
}

export function* peerReviewFiltersMiddleware() {
  yield takeLatest(types.FETCH_PEER_REVIEW_FILTERS, peerReviewFiltersHandler)
}

export default ([] as any).concat(
  peerReviewsMiddleware(),
  peerReviewsDQMiddleware(),
  peerReviewsDQCommentsMiddleware(),
  updatePeerReviewCommentRatingMiddleware(),
  postPeerReviewCommentMiddleware(),
  postPeerReviewCommentReplyMiddleware(),
  updatePeerReviewCommentMiddleware(),
  reactToPeerReviewMiddleware(),
  markCommentAsReadMiddleware(),
  peerReviewFiltersMiddleware()
)
