import { Reducer } from 'redux'
import { SnackbarActions } from './Snackbar.actions'
import { SnackbarDetails } from '../../../../common/types/dashboard'
import {
  FETCH_SNACKBAR_DETAILS,
  FETCH_SNACKBAR_DETAILS_CANCEL,
  FETCH_SNACKBAR_DETAILS_FAILURE,
  FETCH_SNACKBAR_DETAILS_SUCCESS,
} from './Snackbar.types'

export interface SnackbarState {
  data?: SnackbarDetails[]
  loading: boolean
  error: false | Error | Response
}

const initialState: SnackbarState = {
  loading: false,
  error: false as false,
}

const snackbarReducer: Reducer<SnackbarState, SnackbarActions> = (
  state = initialState,
  action
): SnackbarState => {
  switch (action.type) {
    case FETCH_SNACKBAR_DETAILS: {
      return { ...state, loading: true, error: false }
    }
    case FETCH_SNACKBAR_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload || [],
      }
    }
    case FETCH_SNACKBAR_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case FETCH_SNACKBAR_DETAILS_CANCEL:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
export default snackbarReducer
