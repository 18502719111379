import React, { ReactNode } from 'react'
import { CustomError, isNetworkError } from '../../../../common/utils'
import styles from './ErrorComponent.module.css'
import ErrorIllustrations from '../../../containers/Utils/ErrorIllustrations'

export interface ErrorComponentProps {
  error: boolean | Error | Response | CustomError<any>
  children: ReactNode
}

const ErrorComponent = (props: ErrorComponentProps) => {
  if (props.error instanceof Error && isNetworkError(props.error.message))
    throw props.error
  if (props.error instanceof Response) {
    if (props.error.status === 401) {
      throw props.error
    }
    if (props.error.status === 404) return <ErrorIllustrations type="404" />
  }
  if (
    props.error instanceof Error &&
    props.error.message.includes('Invalid access token')
  ) {
    throw props.error
  }
  return <div className={styles.children}>{props.children}</div>
}

export default ErrorComponent
