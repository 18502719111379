import {
  FETCH_PROGRAM_CONTENT,
  FETCH_PROGRAM_CONTENT_SUCCESS,
  FETCH_PROGRAM_CONTENT_FAILURE,
  FETCH_PROGRAM_CONTENT_CANCEL,
  UPDATE_PROGRAM_DETAILS,
  UPDATE_ACTIVE_PROGRAM,
  FETCH_COMMUNITY_SSO_TOKEN,
  FETCH_COMMUNITY_SSO_TOKEN_SUCCESS,
  FETCH_COMMUNITY_SSO_TOKEN_FAILURE,
  FETCH_COMMUNITY_SSO_TOKEN_CANCEL,
  UPDATE_USER_CONSENT,
  UPDATE_USER_CONSENT_SUCCESS,
  UPDATE_USER_CONSENT_FAILURE,
  UPDATE_USER_CONSENT_CANCEL,
  FETCH_MOUTHSHUT_SUCCESS,
  GET_BATCHES,
  GET_BATCHES_SUCCESS,
  GET_BATCHES_FAILURE,
} from './Programs.types'
import {
  EnrolledProgramsData,
  ProgramID,
} from '../../../../common/types/programs'

export const fetchProgramContent = () => ({
  type: FETCH_PROGRAM_CONTENT as typeof FETCH_PROGRAM_CONTENT,
})

export const fetchProgramContentSuccess = (payload: any) => ({
  type: FETCH_PROGRAM_CONTENT_SUCCESS as typeof FETCH_PROGRAM_CONTENT_SUCCESS,
  payload,
})

export const fetchProgramContentFailure = (payload: Error) => ({
  type: FETCH_PROGRAM_CONTENT_FAILURE as typeof FETCH_PROGRAM_CONTENT_FAILURE,
  payload,
})

export const fetchProgramContentCancel = () => ({
  type: FETCH_PROGRAM_CONTENT_CANCEL as typeof FETCH_PROGRAM_CONTENT_CANCEL,
})

export const updateProgramDetails = (payload: {
  enrolledPrograms: EnrolledProgramsData[]
  overrideSelected: boolean
  isNewUIPreferred?: boolean
  isExcelerateCoursePage?: number[]
}) => ({
  type: UPDATE_PROGRAM_DETAILS as typeof UPDATE_PROGRAM_DETAILS,
  payload,
})
export const updateActiveProgram = (payload: {
  programId: ProgramID
  redirect?: boolean
}) => ({
  type: UPDATE_ACTIVE_PROGRAM as typeof UPDATE_ACTIVE_PROGRAM,
  payload,
})

export const fetchCommunitySsoToken = () => ({
  type: FETCH_COMMUNITY_SSO_TOKEN as typeof FETCH_COMMUNITY_SSO_TOKEN,
})

export const fetchCommunitySsoTokenSuccess = (payload: any) => ({
  type: FETCH_COMMUNITY_SSO_TOKEN_SUCCESS as typeof FETCH_COMMUNITY_SSO_TOKEN_SUCCESS,
  payload,
})

export const fetchCommunitySsoTokenFailure = (payload: Error) => ({
  type: FETCH_COMMUNITY_SSO_TOKEN_FAILURE as typeof FETCH_COMMUNITY_SSO_TOKEN_FAILURE,
  payload,
})

export const fetchCommunitySsoTokenCancel = () => ({
  type: FETCH_COMMUNITY_SSO_TOKEN_CANCEL as typeof FETCH_COMMUNITY_SSO_TOKEN_CANCEL,
})

export const updateUserConsent = (payload: string) => ({
  type: UPDATE_USER_CONSENT as typeof UPDATE_USER_CONSENT,
  payload,
})

export const updateUserConsentSuccess = (payload: any) => ({
  type: UPDATE_USER_CONSENT_SUCCESS as typeof UPDATE_USER_CONSENT_SUCCESS,
  payload,
})

export const updateUserConsentFailure = (payload: Error) => ({
  type: UPDATE_USER_CONSENT_FAILURE as typeof UPDATE_USER_CONSENT_FAILURE,
  payload,
})

export const getBatches = () => ({
  type: GET_BATCHES as typeof GET_BATCHES,
})

export const getBatchesSuccess = (payload: any) => ({
  type: GET_BATCHES_SUCCESS as typeof GET_BATCHES_SUCCESS,
  payload,
})

export const getBatchesFailure = (payload: Error) => ({
  type: GET_BATCHES_FAILURE as typeof GET_BATCHES_FAILURE,
  payload,
})

export const updateUserConsentCancel = () => ({
  type: UPDATE_USER_CONSENT_CANCEL as typeof UPDATE_USER_CONSENT_CANCEL,
})

export const fetchMouthShutSuccess = (payload: any) => ({
  type: FETCH_MOUTHSHUT_SUCCESS as typeof FETCH_MOUTHSHUT_SUCCESS,
  payload,
})

export type ProgramActions =
  | ReturnType<typeof fetchProgramContent>
  | ReturnType<typeof fetchProgramContentSuccess>
  | ReturnType<typeof fetchProgramContentFailure>
  | ReturnType<typeof fetchProgramContentCancel>
  | ReturnType<typeof updateProgramDetails>
  | ReturnType<typeof updateActiveProgram>
  | ReturnType<typeof fetchCommunitySsoToken>
  | ReturnType<typeof fetchCommunitySsoTokenSuccess>
  | ReturnType<typeof fetchCommunitySsoTokenFailure>
  | ReturnType<typeof fetchCommunitySsoTokenCancel>
  | ReturnType<typeof updateUserConsent>
  | ReturnType<typeof updateUserConsentSuccess>
  | ReturnType<typeof updateUserConsentFailure>
  | ReturnType<typeof updateUserConsentCancel>
  | ReturnType<typeof fetchMouthShutSuccess>
  | ReturnType<typeof getBatchesSuccess>
