import {
  MentorSessionID,
  MentorshipRecordingSessionItemID,
} from '../../../../common/types/courses/mentorshipRecording'
import { apiCall, CustomError } from '../../../../common/utils'
import { CourseID } from '../../../../common/types/courses'
import { UserID } from '../../../../common/types/user'

export async function getSessionMentorshipRecordingAPI({
  sessionId,
  userId,
}: {
  sessionId: MentorSessionID
  userId: UserID
}) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/sessions/${sessionId}/recordings`,
    query: {
      user_id: userId,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getCourseMentorshipRecordingsAPI({
  courseId,
  userId,
}: {
  courseId: CourseID
  userId: UserID
}) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/courses/${courseId}/session_recordings`,
    query: {
      user_id: userId,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getMentorshipRecordingDetailAPI({
  recordingId,
  userId,
}: {
  recordingId: MentorshipRecordingSessionItemID
  userId: UserID
}) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/recordings/${recordingId}/details`,
    query: {
      user_id: userId,
    },
  })
  if (response.ok) {
    return response.json()
  }
  if (response.status === 403 || response.status === 404) {
    const res = await response.json()

    if ('message' in res) {
      throw new CustomError(res.message, res)
    }

    if ('reason' in res) {
      throw new CustomError(res.reason, {
        ...res,
        customErrMessage: 'Specified recording is not available for you',
      })
    }
  }
  throw response
}
