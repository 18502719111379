import { Reducer } from 'redux'
import {
  SupportTicketData,
  SupportTicketDetails,
  SupportTicketId,
} from 'common/types/programSupport'
import {
  ProgramActions,
  UPDATE_ACTIVE_PROGRAM,
} from 'web/providers/Dashboard/ProgramsProvider'
import { UserSupportTicketActions } from './UserSupportTickets.actions'
import {
  FETCH_USER_PROGRAM_SUPPORT_TICKETS,
  FETCH_USER_PROGRAM_SUPPORT_TICKETS_SUCCESS,
  FETCH_USER_PROGRAM_SUPPORT_TICKETS_FAILURE,
  FETCH_USER_PROGRAM_SUPPORT_TICKETS_CANCEL,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKETS,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_CANCEL,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_SUCCESS,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_FAILURE,
  FETCH_PROGRAM_SUPPORT_TICKET_DETAILS,
  FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_SUCCESS,
  FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_FAILURE,
  FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_CANCEL,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_CANCEL,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_SUCCESS,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_FAILURE,
  REOPEN_USER_PROGRAM_SUPPORT_TICKET,
  REOPEN_USER_PROGRAM_SUPPORT_TICKET_CANCEL,
  REOPEN_USER_PROGRAM_SUPPORT_TICKET_SUCCESS,
  REOPEN_USER_PROGRAM_SUPPORT_TICKET_FAILURE,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_CANCEL,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_SUCCESS,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_FAILURE,
} from './UserSupportTickets.types'

export interface UserSupportTicketsState {
  data: SupportTicketData[]
  byTicket: {
    [s in SupportTicketId]: {
      loading: boolean
      error: false | Error | Response
      details: SupportTicketDetails
    }
  }
  loading: boolean
  error: false | Error | Response
}

const initialState: UserSupportTicketsState = {
  data: [],
  byTicket: {},
  loading: false,
  error: false,
}

const userSupportTicketsReducer: Reducer<
  UserSupportTicketsState,
  | UserSupportTicketActions
  | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
> = (state = initialState, action): UserSupportTicketsState => {
  switch (action.type) {
    case SUBMIT_USER_PROGRAM_SUPPORT_TICKETS:
    case FETCH_USER_PROGRAM_SUPPORT_TICKETS:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case FETCH_USER_PROGRAM_SUPPORT_TICKETS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_FAILURE:
    case FETCH_USER_PROGRAM_SUPPORT_TICKETS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_SUCCESS:
      const {
        title,
        description,
        id,
        workflow_state,
        created_at,
      } = action.payload
      return {
        ...state,
        loading: false,
        data: [
          { title, description, id, workflow_state, created_at },
          ...state.data,
        ],
      }
    case SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_CANCEL:
    case FETCH_USER_PROGRAM_SUPPORT_TICKETS_CANCEL:
      return {
        ...state,
        loading: false,
      }
    case FETCH_PROGRAM_SUPPORT_TICKET_DETAILS:
      return {
        ...state,
        byTicket: {
          ...state.byTicket,
          [action.payload]: {
            ...state.byTicket[action.payload],
            loading: true,
            error: false,
          },
        },
      }

    case SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION:
    case REOPEN_USER_PROGRAM_SUPPORT_TICKET:
    case SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK:
      return {
        ...state,
        byTicket: {
          ...state.byTicket,
          [action.payload.ticketId]: {
            ...state.byTicket[action.payload.ticketId],
            loading: true,
            error: false,
          },
        },
      }
    case FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_SUCCESS:
      return {
        ...state,
        byTicket: {
          ...state.byTicket,
          [action.payload.ticket.id]: {
            loading: false,
            error: false,
            details: action.payload,
          },
        },
      }

    case SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const conversationAttachments = {
        ...state.byTicket[action.payload.support_ticket_id].details
          .conversationAttachments,
      }

      if (action.payload.attachments && action.payload.attachments.length) {
        conversationAttachments[action.payload.id] = action.payload.attachments
      }

      return {
        ...state,
        byTicket: {
          ...state.byTicket,
          [action.payload.support_ticket_id]: {
            loading: false,
            error: false,
            details: {
              ...state.byTicket[action.payload.support_ticket_id].details,
              conversations: [
                {
                  id: action.payload.id,
                  description: action.payload.description,
                  created_at: action.payload.created_at,
                  created_by: action.payload.created_by,
                },
                ...state.byTicket[action.payload.support_ticket_id].details
                  .conversations,
              ],
              transitions: [
                {
                  transition_type: 'ConversationAdded',
                  new_value: true,
                  created_by: action.payload.created_by,
                  created_at: action.payload.created_at,
                },
                ...state.byTicket[action.payload.support_ticket_id].details
                  .transitions,
              ],
              conversationAttachments,
            },
          },
        },
      }

    case REOPEN_USER_PROGRAM_SUPPORT_TICKET_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const reopenConversationAttachments = {
        ...state.byTicket[action.payload.support_ticket_id].details
          .conversationAttachments,
      }

      if (action.payload.attachments && action.payload.attachments.length) {
        reopenConversationAttachments[action.payload.id] =
          action.payload.attachments
      }
      return {
        ...state,
        byTicket: {
          ...state.byTicket,
          [action.payload.support_ticket_id]: {
            loading: false,
            error: false,
            details: {
              ...state.byTicket[action.payload.support_ticket_id].details,
              conversations: [
                {
                  id: action.payload.id,
                  description: action.payload.description,
                  created_at: action.payload.created_at,
                  created_by: action.payload.created_by,
                },
                ...state.byTicket[action.payload.support_ticket_id].details
                  .conversations,
              ],
              transitions: [
                {
                  transition_type: 'WorkFlowStatusChanged',
                  new_value: '3',
                  created_by: action.payload.created_by,
                  created_at: action.payload.created_at,
                },
                ...state.byTicket[action.payload.support_ticket_id].details
                  .transitions,
              ],
              ticket: {
                ...state.byTicket[action.payload.support_ticket_id].details
                  .ticket,
                workflow_state: '3',
              },
              conversationAttachments: reopenConversationAttachments,
            },
          },
        },
      }

    case SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_SUCCESS:
      return {
        ...state,
        byTicket: {
          ...state.byTicket,
          [action.payload.support_ticket_id]: {
            loading: false,
            error: false,
            details: {
              ...state.byTicket[action.payload.support_ticket_id].details,
              ticket: {
                ...state.byTicket[action.payload.support_ticket_id].details
                  .ticket,
                student_feedback_available: true,
              },
            },
          },
        },
      }
    case FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_FAILURE:
    case REOPEN_USER_PROGRAM_SUPPORT_TICKET_FAILURE:
    case SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_FAILURE:
    case SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_FAILURE:
      return {
        ...state,
        byTicket: {
          ...state.byTicket,
          [action.payload.ticketId]: {
            ...state.byTicket[action.payload.ticketId],
            loading: false,
            error: action.payload.error,
          },
        },
      }

    case FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_CANCEL:
    case REOPEN_USER_PROGRAM_SUPPORT_TICKET_CANCEL:
    case SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_CANCEL:
    case SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_CANCEL:
      return {
        ...state,
        byTicket: {
          ...state.byTicket,
          [action.payload.ticketId]: {
            ...state.byTicket[action.payload.ticketId],
            loading: false,
          },
        },
      }
    case UPDATE_ACTIVE_PROGRAM:
      return initialState
    default:
      return state
  }
}
export default userSupportTicketsReducer
