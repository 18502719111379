import React, { ReactNode } from 'react'
import Navigation from 'web/containers/LearnAndDev/Navigation'
import PageBase from 'web/components/LearnAndDev/PageBase'
import { Grid } from '@material-ui/core'

const LearnAndDevLayout = ({ children }: { children: ReactNode }) => (
  <Grid container spacing={0}>
    <Grid container item xs={12} md={3}>
      <Navigation />
    </Grid>
    <Grid container item xs={12} md={9}>
      <PageBase>{children}</PageBase>
    </Grid>
  </Grid>
)

export default LearnAndDevLayout
