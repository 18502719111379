import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import {
  fetchReferralsAndRewardsSuccess,
  fetchReferralsAndRewardsFailure,
  claimReward,
  claimRewardSuccess,
  claimRewardFailure,
} from './ReferralsAndRewards.actions'
import * as APIs from './ReferralsAndRewards.api'
import * as types from './ReferralsAndRewards.types'
import { showAlertMessage } from '../AlertsProvider'
import { fetchDashboardContent } from '../Dashboard/DashboardProvider'

function* getReferralsAndRewardsData() {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.fetchReferralsAndRewardsData,
      abortController.signal
    )
    yield put(fetchReferralsAndRewardsSuccess(data))
  } catch (e) {
    yield put(fetchReferralsAndRewardsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitClaimReward(action: ReturnType<typeof claimReward>) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.submitClaimReward,
      action.payload.rewardId,
      abortController.signal
    )

    // #refetch dashboard content to update gl_coins status
    yield put(fetchDashboardContent())

    yield put(claimRewardSuccess(data))
    yield put(
      showAlertMessage({
        variant: 'success',
        message:
          'Congratulations on claiming the reward!! You will receive an email with detailed instructions on the reward in the next 24 hours.',
      })
    )
  } catch (e) {
    yield put(claimRewardFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Something went wrong. Claim reward failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* referralsAndRewardsMiddleware() {
  yield takeLatest(
    types.FETCH_REFERRALS_REWARDS_DETAILS,
    cancelable(
      getReferralsAndRewardsData,
      types.FETCH_REFERRALS_REWARDS_DETAILS_CANCEL
    )
  )

  yield takeLatest(
    types.CLAIM_REWARD,
    cancelable(submitClaimReward, types.CLAIM_REWARD_CANCEL)
  )
}

export default ([] as any).concat(referralsAndRewardsMiddleware())
