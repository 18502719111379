import { all } from 'redux-saga/effects'
import { utilsMiddleware } from 'web/providers/UtilsProvider'
import { excelerateTestsMiddleware } from 'web/providers/DigitalExcelerate/TestsProvider'
import { programCategoryMiddleware } from 'web/providers/ProgramCategory'
import { careerSchoolMiddleware } from 'web/providers/Dashboard/CareerSchoolDashboard'
import { userConsentMiddleware } from 'web/providers/User/UserConsentProvider'
import { userTestimonialMiddleware } from 'web/providers/User/UserTestimonialProvider'
import { userSupportTicketsMiddleware } from 'web/providers/User/UserSupportTicketsProvider'
import { fslDepartmentsMiddleware } from 'web/providers/LearnAndDev/DepartmentsProvider'
import { zoomoutQAMiddleware } from 'web/providers/Zoomout/QA'
import { newCourseItemMiddleware } from 'web/providers/Dashboard/NewCourseItemProvider'
import { peerRatingsMiddleware } from 'web/providers/PeerRatingsProvider'
import { upcomingWebinarsMiddleware } from 'web/providers/UpcomingWebinarsProvider'
import { snackbarMiddleware } from 'web/providers/Dashboard/SnackbarProvider'
import { openAiProxyProviderMiddleware } from 'web/providers/OpenAiProxyProvider'
import CurriculumAiMentorMiddleware from 'web/providers/CurriculumAiMentor/CurriculumAiMentor.middleware'
import { glLabsMiddleware } from 'web/providers/GlLabs'
import { userOverdueNotificationsMiddleware } from 'web/providers/User/UserNotificationsProvider'
import { certificatesMiddleware } from '../providers/Certificates/CertificatesProvider'
import { hackathonsMiddleware } from '../providers/HackathonsProvider'
import { userDetailsMiddleware } from '../providers/User/UserDetailsProvider'
import { userGroupsMiddleware } from '../providers/User/UserGroupsProvider'
import { pageDataMiddleware } from '../providers/PageDataProvider'
import { userSettingsMiddleware } from '../providers/User/UserSettingsProvider'
import { specializationCoursesMiddleware } from '../providers/SpecializationCoursesProvider'
import { dashboardMiddleware } from '../providers/Dashboard/DashboardProvider'
import { proctoringMiddleware } from '../providers/ProctoringProvider'
/** Courses */
import { coursesMiddleware } from '../providers/Courses/CoursesProvider'
import { modulesMiddleware } from '../providers/Courses/ModulesProvider'
import { courseCompletionCardsMiddleware } from '../providers/Courses/CourseCompletionCardsProvider'
import { moduleItemsMiddleware } from '../providers/Courses/ModuleItemsProvider'
import { notesMiddleware } from '../providers/Courses/NotesProvider'
import { communityMiddleware } from '../providers/CommunityProvider'
import { programSupportFaqsMiddleware } from '../providers/ProgramSupportFaqs'
import { codingLabsMiddleware } from '../providers/CodingLabs'
import { quizCodingLabsMiddleware } from '../providers/QuizCodingLabs'
import { jupyterLabsMiddleware } from '../providers/JupyterLabs'
import { completedAssessmentsMiddleware } from '../providers/CompletedAssessments'
import { mentorshipRecordingsMiddleware } from '../providers/Courses/MentorshipRecordingsProvider'
import { filesMiddleware } from '../providers/FilesProvider'
import { submissionCommentsMiddleware } from '../providers/Courses/SubmissionCommentsProvider'
import { attendanceMiddleware } from '../providers/Dashboard/AttendanceProvider'
import { courseProgressMiddleware } from '../providers/Dashboard/CourseProgressProvider'
import { activityMiddleware } from '../providers/Dashboard/ActivityProvider'
import { gradebookMiddleware } from '../providers/Dashboard/GradebookProvider'
import { announcementsMiddleware } from '../providers/AnnouncementsProvider'
import { userAdmissionMiddleware } from '../providers/User/UserAdmissionProvider'
import { userOnboardingMiddleware } from '../providers/User/UserOnboardingProvider'
import { feedbackMiddleware } from '../providers/FeedbackProvider'
import { errorsMiddleware } from '../providers/ErrorsProvider'
import { leaderboardMiddleware } from '../providers/Dashboard/LeaderboardProvider'
import { programsMiddleware } from '../providers/Dashboard/ProgramsProvider'
import { wikiPageMiddleware } from '../providers/Courses/ModuleItemsProvider/WikiPage'
import { referralsAndRewardsMiddleware } from '../providers/ReferralsAndRewardsProvider'
import { firstUnlockedModulesMiddleware } from '../providers/FirstUnlockedModuleProvider'
import { paymentMiddleware } from '../providers/Payments/PaymentsProvider'
import { paymentHistoryMiddleware } from '../providers/Payments/PaymentHistoryProvider'
import { paymentStatusMiddleware } from '../providers/Payments/PaymentsStatusProvider'
import { alumniPortalMiddleware } from '../providers/AlumniPortalProvider'
import { freeUserInfoDialogMiddleware } from '../providers/Dashboard/FreeUserInfoDialogProvider'
import { coursePercentageCompletedMiddleware } from '../providers/Dashboard/CoursePercentageCompletedProvider'
import { careerPathMiddleware } from '../providers/Dashboard/CareerPathProvider'
import { excelerateCareerPrepMiddleware } from '../providers/Excelerate/CareerPrepProvider'
import { invigilationMiddleware } from '../providers/InvigilationProvider'
import { codingPlatformMiddleware } from '../providers/CodingPlatform'
import { allProgramsMiddleware } from '../providers/AllProgramsProvider'
import { zoomoutExternalMiddleware } from '../providers/Zoomout/External'
import { zoomoutInternalMiddleware } from '../providers/Zoomout/Internal'
import { fslLeaderboardMiddleware } from '../providers/LearnAndDev/LeaderboardProvider'
import { fslDashboardMiddleware } from '../providers/LearnAndDev/DashboardProvider'
import { ZoomoutPollsMiddleware } from '../providers/Zoomout/Polls'
import { EportfolioMiddleware } from '../providers/Eportfolio'

import { engagementMiddleware } from '../providers/Zoomout/Engagement'
import { privateCertificatesMiddleware } from '../providers/PrivateCertificateProvider'
import { courseAssignmentsMiddleware } from '../providers/ProgramSupport/AssignmentProvider'
import { courseQuizzesMiddleware } from '../providers/ProgramSupport/QuizProvider'
import { externalLabsMiddleware } from '../providers/ExternalLabs'
import { peerReviewsMiddleware } from '../providers/PeerReviewProvider'
import { programBannersMiddleware } from '../providers/ProgramBannersProvider'
import { glaNewOnboardingFormMiddleware } from '../providers/GlaNewOnBoardingFormProvider'
import { glaCertificatePaymentMiddleware } from '../providers/GlaCertificatePaymentProvider'

export default function* rootSaga() {
  yield all([
    ...userDetailsMiddleware,
    ...userGroupsMiddleware,
    ...userSettingsMiddleware,
    ...userAdmissionMiddleware,
    ...pageDataMiddleware,
    ...specializationCoursesMiddleware,
    ...dashboardMiddleware,
    ...snackbarMiddleware,
    ...activityMiddleware,
    ...attendanceMiddleware,
    ...courseProgressMiddleware,
    ...gradebookMiddleware,
    ...userOnboardingMiddleware,
    ...leaderboardMiddleware,
    ...programsMiddleware,
    ...userTestimonialMiddleware,
    ...userSupportTicketsMiddleware,
    /** Courses */
    ...coursesMiddleware,
    ...modulesMiddleware,
    ...courseCompletionCardsMiddleware,
    ...moduleItemsMiddleware,
    ...newCourseItemMiddleware,
    ...mentorshipRecordingsMiddleware,
    ...filesMiddleware,
    ...submissionCommentsMiddleware,
    ...announcementsMiddleware,
    ...notesMiddleware,
    ...communityMiddleware,
    ...programSupportFaqsMiddleware,
    ...codingLabsMiddleware,
    ...quizCodingLabsMiddleware,
    ...jupyterLabsMiddleware,
    ...glLabsMiddleware,
    ...completedAssessmentsMiddleware,
    ...coursePercentageCompletedMiddleware,
    /** Payments */
    ...paymentMiddleware,
    ...paymentHistoryMiddleware,
    ...paymentStatusMiddleware,
    /** Excelerate */
    ...excelerateTestsMiddleware,
    /** Others */
    ...feedbackMiddleware,
    ...errorsMiddleware,
    ...wikiPageMiddleware,
    ...proctoringMiddleware,
    ...hackathonsMiddleware,
    ...referralsAndRewardsMiddleware,
    ...utilsMiddleware,
    ...firstUnlockedModulesMiddleware,
    ...freeUserInfoDialogMiddleware,
    ...alumniPortalMiddleware,
    ...invigilationMiddleware,
    ...codingPlatformMiddleware,
    ...allProgramsMiddleware,
    /** Certificate */
    ...certificatesMiddleware,
    /** Zoomout */
    ...zoomoutExternalMiddleware,
    ...zoomoutInternalMiddleware,
    ...zoomoutQAMiddleware,
    ...ZoomoutPollsMiddleware,
    ...engagementMiddleware,
    ...privateCertificatesMiddleware,

    ...programCategoryMiddleware,
    ...upcomingWebinarsMiddleware,
    ...excelerateCareerPrepMiddleware,
    ...careerSchoolMiddleware,
    ...userConsentMiddleware,
    ...fslLeaderboardMiddleware,
    ...fslDepartmentsMiddleware,
    ...fslDashboardMiddleware,
    ...peerRatingsMiddleware,
    ...careerPathMiddleware,

    // for Eportfolio
    ...EportfolioMiddleware,

    // for program support
    ...courseAssignmentsMiddleware,
    ...courseQuizzesMiddleware,
    // for ExternalLab
    ...externalLabsMiddleware,
    // for Peer Review
    ...peerReviewsMiddleware,

    ...openAiProxyProviderMiddleware,
    ...CurriculumAiMentorMiddleware,
    ...programBannersMiddleware,
    ...glaCertificatePaymentMiddleware,
    ...glaNewOnboardingFormMiddleware,
    ...userOverdueNotificationsMiddleware,
  ])
}
