import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { parse } from 'query-string'
import { history } from 'web/store/history'
import { withRouter, RouteComponentProps, matchPath } from 'react-router-dom'
import PageNotFound from 'web/pages/PageNotFound'
import Loader from 'web/components/Utils/Loader'
import {
  useIsNewUIEnabled,
  useReloadPath,
  useShowNewNavigationBars,
} from 'web/hooks'
import routes from 'web/routes'
import { ProgramData } from '../../../common/types/programs'
import { AppSourceType, ResourceAccessType } from '../../../common/types/user'
import { mixpanel } from '../../../common/utils/mixpanel'
import {
  programSelectors,
  updateProgramDetails,
} from '../../providers/Dashboard/ProgramsProvider'
import {
  loginUserSuccess,
  updateUserDetails,
  UserDetailsState,
} from '../../providers/User/UserDetailsProvider'
import { AppState } from '../../store'
import ErrorIllustrations from '../Utils/ErrorIllustrations'
import { isMobileBrowser, isPublicRoute } from '../../../common/utils/index'

interface OwnProps {
  children: React.ReactNode
}

interface DispatchProps {
  loginUserSuccess: typeof loginUserSuccess
  updateUserDetails: typeof updateUserDetails
  updateProgramDetails: typeof updateProgramDetails
}

interface StateProps {
  userDetails: UserDetailsState
  programData: ProgramData | null
  digitalCampusUser: boolean
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps

const getCourseId = () => {
  let courseId = null
  const regex = /\/courses\/(\d+)\//
  const match = window.location.href.match(regex)
  if (match) {
    courseId = String(match[1])
  }
  return courseId
}

const UserPrerequisites = (props: Props) => {
  const searchParams = parse(history.location.search)

  const isRoutePublic = isPublicRoute(props.location.pathname)
  const isNewUIEnabled = useIsNewUIEnabled()
  const reloadPath = useReloadPath()
  const showNewNavigationBars = useShowNewNavigationBars()

  useEffect(() => {
    if (
      (!props.userDetails.id || !('accessToken' in props.userDetails.auth)) &&
      !isRoutePublic
    ) {
      let userId = null
      let accessToken = null
      let isMasquerading = false
      let enrolledPrograms = []
      let isExcelerateCoursePage = false
      let overrideSelected = false
      let resourceAccess: ResourceAccessType = null
      let learnAndDevUser = false
      let universityDetails = null
      let isNewUIPreferred = false
      let appSource: AppSourceType = isMobileBrowser()
        ? 'mobile_browser'
        : 'web_app'
      if (searchParams.seb) appSource = 'seb_browser'

      if (process.env.NODE_ENV === 'development') {
        if (process.env.REACT_APP_DOCKERIZED) {
          fetch(`/user_session.json`)
            .then(res => res.json())
            .then((data: any) => {
              if (data.user_id) {
                userId = data.user_id
                accessToken = data.access_token
                enrolledPrograms = data.enrolled_programs
                learnAndDevUser = data.learn_and_dev_user || false
                universityDetails = data.university_details
                props.loginUserSuccess({ accessToken })
                props.updateUserDetails({
                  id: Number(userId) || null,
                  masquerading: isMasquerading,
                  appSource,
                  resourceAccess,
                  learnAndDevUser,
                  universityDetails,
                })
                props.updateProgramDetails({
                  enrolledPrograms,
                  overrideSelected,
                })
              }
            })
            .catch(() => {
              userId = process.env.REACT_APP_USER_ID
              accessToken = process.env.REACT_APP_API_ACCESS_TOKEN
              enrolledPrograms =
                process.env.REACT_APP_ENROLLED_PROGRAMS &&
                process.env.REACT_APP_ENROLLED_PROGRAMS.length
                  ? JSON.parse(process.env.REACT_APP_ENROLLED_PROGRAMS!)
                  : []
            })
        } else {
          userId = process.env.REACT_APP_USER_ID
          accessToken = process.env.REACT_APP_API_ACCESS_TOKEN
          enrolledPrograms = JSON.parse(
            process.env.REACT_APP_ENROLLED_PROGRAMS!
          )
          learnAndDevUser = process.env.REACT_APP_LEARN_AND_DEV_USER === 'true'
          universityDetails = null
        }
      } else if (process.env.NODE_ENV === 'production') {
        const rootEl = document.getElementById('root')

        const data = rootEl ? JSON.parse(rootEl.dataset.user || '""') : null
        const courseId = getCourseId()
        if (rootEl && data) {
          userId = data.user_id
          accessToken = data.access_token
          isMasquerading = data.masquerading
          enrolledPrograms = data.enrolled_programs
          overrideSelected = data.override_selected
          resourceAccess = data.resource_access || null
          learnAndDevUser = data.learn_and_dev_user || false
          universityDetails = data.university_details
          isNewUIPreferred = data.is_new_ui_preferred === 1
          isExcelerateCoursePage = data.excelerate_course_ids.includes(courseId)
          if (data.src) appSource = data.src
          delete rootEl.dataset.user
        }
      }

      props.loginUserSuccess({ accessToken })
      props.updateUserDetails({
        id: Number(userId) || null,
        masquerading: isMasquerading,
        appSource,
        resourceAccess,
        learnAndDevUser,
        ...(universityDetails ? { universityDetails } : {}),
        isExcelerateCoursePage,
      })
      props.updateProgramDetails({
        enrolledPrograms,
        overrideSelected,
        isNewUIPreferred,
        isExcelerateCoursePage,
      })
    }
  }, [])

  useEffect(() => {
    if (props.userDetails.id && props.userDetails.accessType) {
      mixpanel.identify(props.userDetails.id)
      mixpanel.register({
        masqueraded: Number(!!props.userDetails.masquerading),
        /** LMS uses these keys */
        program_id: props.programData ? props.programData.id : null,
        program_group: props.programData ? props.programData.group : null,
        /** LTI uses these keys */
        custom_program_group_id: props.programData
          ? props.programData.id
          : null,
        custom_program_group_name: props.programData
          ? props.programData.group
          : null,
        custom_program_id: props.programData
          ? props.programData.program_id
          : null,
        digital_campus_user: props.digitalCampusUser,
        build_number: process.env.REACT_APP_BUILD_NUMBER,
        user_access_type: props.userDetails.accessType,
        access_from_web: true,
      })
      if (props.programData) {
        mixpanel.people.union({
          programs: props.programData.name,
          batches: props.programData.group,
        })
      }
    }
  }, [props.userDetails.id, props.programData, props.userDetails.accessType])

  const userEmail =
    props.userDetails.profile && props.userDetails.profile.data
      ? props.userDetails.profile.data.primary_email
      : null

  useEffect(() => {
    if (props.userDetails.profile && props.userDetails.profile.data) {
      mixpanel.people.set({
        $email: props.userDetails.profile.data.primary_email,
        $name: props.userDetails.profile.data.name,
        'LMS User ID': props.userDetails.profile.data.id,
      })
    }
  }, [userEmail])

  const shouldRedirectToNewUI = (
    pathname: string,
    exact: boolean = true
  ): boolean => {
    if (
      props.userDetails.isExcelerateCoursePage ||
      process.env.NODE_ENV === 'development'
    ) {
      return false
    }

    const ROUTES_ENABLED_BY_BATCH = [
      routes.LEARNER_DASHBOARD,
      routes.ACTIVITIES,
      routes.DASHBOARD,
      routes.COURSES,
      routes.COURSE,
      routes.MODULE_ITEM,
      routes.CONTENT,
      routes.NOTIFICATIONS,
      routes.MENTORSHIP_RECORDING_SESSION_ITEM,
      routes.GRADEBOOK,
      routes.SCORECARD,
      routes.PROGRAM_SUPPORT,
      routes.PROGRAM_SUPPORT_TICKET_DETAIL,
      routes.GLA_BROWSE_SCREEN,
    ]
    const ROUTES_NAVIGATE_TO_NEW_REACT_APP = [routes.NOTIFICATIONS]

    return (
      (isNewUIEnabled &&
        ROUTES_ENABLED_BY_BATCH.some(path =>
          matchPath(pathname, { path, exact })
        )) ||
      (showNewNavigationBars &&
        ROUTES_NAVIGATE_TO_NEW_REACT_APP.some(path =>
          matchPath(pathname, { path, exact })
        ))
    )
  }

  if (!props.userDetails.id && !isRoutePublic) {
    return null
  }

  if (shouldRedirectToNewUI(props.location.pathname)) {
    reloadPath()
    return <Loader />
  }

  if (
    props.userDetails.resourceAccess &&
    props.userDetails.resourceAccess !== 'accessible'
  ) {
    return <ErrorIllustrations type="404" />
  }

  return <Fragment>{props.children}</Fragment>
}

const mapStateToProps = (state: AppState): StateProps => ({
  userDetails: state.user.details,
  programData: programSelectors.getActiveProgramDetails()(state),
  digitalCampusUser: programSelectors.digitalCampusUser()(state),
})

export default withRouter(
  connect(mapStateToProps, {
    loginUserSuccess,
    updateUserDetails,
    updateProgramDetails,
  })(UserPrerequisites)
)
