import { Reducer } from 'redux'
import { GradebookActions } from './Gradebook.actions'
import {
  FETCH_GRADEBOOK_CONTENT,
  FETCH_GRADEBOOK_CONTENT_SUCCESS,
  FETCH_GRADEBOOK_CONTENT_FAILURE,
  FETCH_GRADEBOOK_CONTENT_CANCEL,
} from './Gradebook.types'
import {
  GradebookData,
  GradebookCoursesData,
} from '../../../../common/types/dashboard'
import { CourseID } from '../../../../common/types/courses'
import { ProgramActions, UPDATE_ACTIVE_PROGRAM } from '../ProgramsProvider'

export interface GradebookCoursesDataState {
  courses: {
    ids: number[]
    byId: { [s in string]: GradebookCoursesData }
  }
}

export interface GradebookState {
  data?: GradebookData & GradebookCoursesDataState
  loading: boolean
  error: false | Error | Response
}

const initialState: GradebookState = {
  loading: false,
  error: false as false,
}

const COURSES_ORDER: GradebookCoursesData['course_state'][] = [
  'In progress',
  'completed',
]

const getNormalizedData = (data: any) => {
  const finalObj: GradebookData & GradebookCoursesDataState = { ...data }
  let atRiskCount = 0
  let excellentCount = 0
  finalObj.courses = {
    ids: [],
    byId: {},
  }

  if (data.courses.length) {
    data.courses.forEach((course: GradebookCoursesData) => {
      finalObj.courses.byId[course.course_id] = { ...course }
      finalObj.courses.ids.push(course.course_id)
      if (course.grade === 'At Risk') {
        atRiskCount += 1
      } else if (
        (!course.credits && course.grade === 'E') ||
        ('credits' in course &&
          course.credits &&
          (course.score / course.total_score) * 100 >= 80)
      ) {
        excellentCount += 1
      }
    })
  }

  finalObj.courses.ids.sort((a: CourseID, b: CourseID) => {
    const courseA = finalObj.courses.byId[a]!
    const courseB = finalObj.courses.byId[b]!
    if (courseA.course_state !== courseB.course_state) {
      return (
        COURSES_ORDER.indexOf(courseA.course_state) -
        COURSES_ORDER.indexOf(courseB.course_state)
      )
    }
    return (
      new Date(courseB.sort_by_value).getTime() -
      new Date(courseA.sort_by_value).getTime()
    )
  })

  finalObj.at_risk_courses = atRiskCount
  finalObj.excellent_courses = excellentCount
  return finalObj
}

const gradebookReducer: Reducer<
  GradebookState,
  GradebookActions | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GRADEBOOK_CONTENT:
      return { ...state, loading: true, error: false }
    case FETCH_GRADEBOOK_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...getNormalizedData(action.payload) },
      }
    case FETCH_GRADEBOOK_CONTENT_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case FETCH_GRADEBOOK_CONTENT_CANCEL:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_ACTIVE_PROGRAM:
      return initialState
    default:
      return state
  }
}
export default gradebookReducer
