export const FETCH_COURSE_COMPLETION_CARDS = 'FETCH_COURSE_COMPLETION_CARDS'
export const FETCH_COURSE_COMPLETION_CARDS_SUCCESS =
  'FETCH_COURSE_COMPLETION_CARDS_SUCCESS'
export const FETCH_COURSE_COMPLETION_CARDS_FAILURE =
  'FETCH_COURSE_COMPLETION_CARDS_FAILURE'
export const FETCH_COURSE_COMPLETION_CARDS_CANCEL =
  'FETCH_COURSE_COMPLETION_CARDS_CANCEL'
export const UPDATE_COURSE_COMPLETION_CARDS = 'UPDATE_COURSE_COMPLETION_CARDS'
export const UPDATE_COURSE_COMPLETION_CARDS_SUCCESS =
  'UPDATE_COURSE_COMPLETION_CARDS_SUCCESS'
export const UPDATE_COURSE_COMPLETION_CARDS_FAILURE =
  'UPDATE_COURSE_COMPLETION_CARDS_FAILURE'
export const UPDATE_COURSE_COMPLETION_CARDS_CANCEL =
  'UPDATE_COURSE_COMPLETION_CARDS_CANCEL'
