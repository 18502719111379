import * as React from 'react'
import SnackbarStrip from 'web/components/Header/SnackbarStrip'
import { Box, Typography, IconButton } from '@material-ui/core'
import { AnnouncementIcon } from 'web/components/Utils/Icons/Icons'
import { Close } from '@material-ui/icons'

const NewUIMsgSnackbar = () => {
  const [show, setShow] = React.useState(true)

  return (
    <SnackbarStrip
      message={
        <span id="client-snackbar">
          <Box display="flex" alignItems="center">
            <AnnouncementIcon color="primary" />
            <Box pl={1}>
              <Typography>
                Exciting News: Olympus 2.0 - Your New Learning Experience is
                coming soon. Stay tuned!
              </Typography>
            </Box>
          </Box>
        </span>
      }
      open={show}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={() => {
            localStorage.setItem('newUiMsgSkipped', Date.now().toString())
            setShow(false)
          }}
        >
          <Close />
        </IconButton>,
      ]}
    />
  )
}

export default NewUIMsgSnackbar
