import {
  AssessmentScheduleID,
  InvigilationMessage,
} from 'common/types/invigilation'
import { apiCall, getUserDetails, objectToFormData } from 'common/utils'
import { OnboardingActionPayload } from './Invigilation.actions'

export async function getAssessmentScheduleDetailsAPI(
  assessmentScheduleId: AssessmentScheduleID,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${process.env.REACT_APP_LTI_API_URL}v1/assessment_schedules/${assessmentScheduleId}/assessment_details`,
    query: {
      user_id: id,
    },
    params: { signal },
    excludeProgramId: true,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getAssessmentScheduleDetailsByKeyAPI(
  proctorKey: string,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${process.env.REACT_APP_LTI_API_URL}v1/proctor_invigilation`,
    query: {
      user_id: id,
      key: proctorKey,
    },
    params: { signal },
    excludeProgramId: true,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getInvigilationDetailsAPI(
  assessmentScheduleId: AssessmentScheduleID,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${process.env.REACT_APP_LTI_API_URL}v1/proctoring/assessment_schedules/${assessmentScheduleId}/internal_details`,
    query: { user_id: id, include_users: false },
    params: { signal },
    excludeProgramId: true,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function updateInvigilationMessagesAPI(
  message: InvigilationMessage,
  assessmentScheduleId: AssessmentScheduleID,
  source: string,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${process.env.REACT_APP_LTI_API_URL}v1/proctoring/assessment_schedules/${assessmentScheduleId}/messages`,
    query: { user_id: id },
    params: {
      method: 'POST',
      body: objectToFormData({
        receiver_id: message.id,
        message: message.message,
        timestamp: message.timestamp,
        source,
      }),
      signal,
    },
    excludeProgramId: true,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function approveOrRejectOnboardingPhotoHandlerAPI(
  data: OnboardingActionPayload,
  signal: AbortSignal
) {
  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${process.env.REACT_APP_LTI_API_URL}v1/user_images/${data.id}/${data.action}`,
    query: { user_id: id },
    params: {
      method: 'POST',
      signal,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
