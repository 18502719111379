import { PeerRatingSubmissionData } from 'common/types/peerRatings'
import {
  FETCH_PENDING_PEER_RATINGS,
  FETCH_PENDING_PEER_RATINGS_SUCCESS,
  FETCH_PENDING_PEER_RATINGS_FAILURE,
  FETCH_PENDING_PEER_RATINGS_CANCEL,
  SUBMIT_PEER_RATINGS,
  SUBMIT_PEER_RATINGS_SUCCESS,
  SUBMIT_PEER_RATINGS_FAILURE,
  SUBMIT_PEER_RATINGS_CANCEL,
  TOGGLE_PEER_RATINGS_DIALOG,
} from './PeerRatings.types'

export interface SubmitPeerRatingsMeta {
  assignment_id: string
}

export const fetchPendingPeerRatings = () => ({
  type: FETCH_PENDING_PEER_RATINGS as typeof FETCH_PENDING_PEER_RATINGS,
})
export const fetchPendingPeerRatingsSuccess = (payload: any) => ({
  type: FETCH_PENDING_PEER_RATINGS_SUCCESS as typeof FETCH_PENDING_PEER_RATINGS_SUCCESS,
  payload,
})
export const fetchPendingPeerRatingsFailure = (payload: Error) => ({
  type: FETCH_PENDING_PEER_RATINGS_FAILURE as typeof FETCH_PENDING_PEER_RATINGS_FAILURE,
  payload,
})
export const fetchPendingPeerRatingsCancel = () => ({
  type: FETCH_PENDING_PEER_RATINGS_CANCEL as typeof FETCH_PENDING_PEER_RATINGS_CANCEL,
})

export const submitPeerRatings = (
  payload: PeerRatingSubmissionData,
  meta: SubmitPeerRatingsMeta
) => ({
  type: SUBMIT_PEER_RATINGS as typeof SUBMIT_PEER_RATINGS,
  payload,
  meta,
})
export const submitPeerRatingsSuccess = (
  payload: any,
  meta: SubmitPeerRatingsMeta
) => ({
  type: SUBMIT_PEER_RATINGS_SUCCESS as typeof SUBMIT_PEER_RATINGS_SUCCESS,
  payload,
  meta,
})
export const submitPeerRatingsFailure = (
  payload: Error,
  meta: SubmitPeerRatingsMeta
) => ({
  type: SUBMIT_PEER_RATINGS_FAILURE as typeof SUBMIT_PEER_RATINGS_FAILURE,
  payload,
  meta,
})
export const submitPeerRatingsCancel = () => ({
  type: SUBMIT_PEER_RATINGS_CANCEL as typeof SUBMIT_PEER_RATINGS_CANCEL,
})
export const togglePeerRatingsDialog = (payload: { open: boolean }) => ({
  type: TOGGLE_PEER_RATINGS_DIALOG as typeof TOGGLE_PEER_RATINGS_DIALOG,
  payload,
})

export type PeerRatingsActionTypes =
  | ReturnType<typeof fetchPendingPeerRatings>
  | ReturnType<typeof fetchPendingPeerRatingsSuccess>
  | ReturnType<typeof fetchPendingPeerRatingsFailure>
  | ReturnType<typeof fetchPendingPeerRatingsCancel>
  | ReturnType<typeof submitPeerRatings>
  | ReturnType<typeof submitPeerRatingsSuccess>
  | ReturnType<typeof submitPeerRatingsFailure>
  | ReturnType<typeof submitPeerRatingsCancel>
  | ReturnType<typeof togglePeerRatingsDialog>
