import { GlaCertificatePaymentDetail } from 'common/types/programs'
import { CourseID } from 'common/types/courses'
import {
  FETCH_GLA_CERTIFICATE_PAYMENT,
  FETCH_GLA_CERTIFICATE_PAYMENT_SUCCESS,
  FETCH_GLA_CERTIFICATE_PAYMENT_FAILURE,
  FETCH_GLA_CERTIFICATE_PAYMENT_CANCEL,
} from './GlaCertificatePaymentProvider.types'

export const fetchGlaCertificatePayment = (payload: {
  course_id: CourseID
}) => ({
  type: FETCH_GLA_CERTIFICATE_PAYMENT as typeof FETCH_GLA_CERTIFICATE_PAYMENT,
  payload,
})

export const fetchGlaCertificatePaymentSuccess = (
  payload: GlaCertificatePaymentDetail,
  meta: { course_id: CourseID }
) => ({
  type: FETCH_GLA_CERTIFICATE_PAYMENT_SUCCESS as typeof FETCH_GLA_CERTIFICATE_PAYMENT_SUCCESS,
  payload,
  meta,
})

export const fetchGlaCertificatePaymentFailure = (
  payload: Error,
  meta: { course_id: CourseID }
) => ({
  type: FETCH_GLA_CERTIFICATE_PAYMENT_FAILURE as typeof FETCH_GLA_CERTIFICATE_PAYMENT_FAILURE,
  payload,
  meta,
})

export const fetchGlaCertificatePaymentCancel = (meta: {
  course_id: CourseID
}) => ({
  type: FETCH_GLA_CERTIFICATE_PAYMENT_CANCEL as typeof FETCH_GLA_CERTIFICATE_PAYMENT_CANCEL,
  meta,
})

export type GlaCertificatePayment =
  | ReturnType<typeof fetchGlaCertificatePayment>
  | ReturnType<typeof fetchGlaCertificatePaymentSuccess>
  | ReturnType<typeof fetchGlaCertificatePaymentFailure>
  | ReturnType<typeof fetchGlaCertificatePaymentCancel>
