import {
  FETCH_ENROLLED_USER_DETAILS,
  FETCH_ENROLLED_USER_DETAILS_SUCCESS,
  FETCH_ENROLLED_USER_DETAILS_FAILURE,
  SEND_MESSAGE_TO_PEERS,
  SEND_MESSAGE_TO_PEERS_SUCCESS,
  SEND_MESSAGE_LIMIT_REACHED,
} from './AlumniPortal.types'

import {
  AlumniPortalData,
  SendMessageToPeersPayload,
} from '../../../common/types/alumni'

export const fetchUserEnrolledDetails = (
  filters: any,
  currentPage: number
) => ({
  type: FETCH_ENROLLED_USER_DETAILS as typeof FETCH_ENROLLED_USER_DETAILS,
  filters,
  currentPage,
})

export const fetchUserEnrolledDetailsSuccess = (payload: AlumniPortalData) => ({
  type: FETCH_ENROLLED_USER_DETAILS_SUCCESS as typeof FETCH_ENROLLED_USER_DETAILS_SUCCESS,
  payload,
})

export const fetchUserEnrolledDetailsFailure = (payload: Error) => ({
  type: FETCH_ENROLLED_USER_DETAILS_FAILURE as typeof FETCH_ENROLLED_USER_DETAILS_FAILURE,
  payload,
})

export const sendMessageToPeers = (payload: SendMessageToPeersPayload) => ({
  type: SEND_MESSAGE_TO_PEERS as typeof SEND_MESSAGE_TO_PEERS,
  payload,
})

export const sendMessageToPeerSuccess = () => ({
  type: SEND_MESSAGE_TO_PEERS_SUCCESS as typeof SEND_MESSAGE_TO_PEERS_SUCCESS,
})

export const sendMessageLimitReached = () => ({
  type: SEND_MESSAGE_LIMIT_REACHED as typeof SEND_MESSAGE_LIMIT_REACHED,
})

export type AlumniPortalActions =
  | ReturnType<typeof fetchUserEnrolledDetails>
  | ReturnType<typeof fetchUserEnrolledDetailsSuccess>
  | ReturnType<typeof fetchUserEnrolledDetailsFailure>
  | ReturnType<typeof sendMessageToPeers>
  | ReturnType<typeof sendMessageToPeerSuccess>
  | ReturnType<typeof sendMessageLimitReached>
