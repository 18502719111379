import { Reducer } from 'redux'
import { ProctoringActionTypes } from './Proctoring.actions'
import * as types from './Proctoring.types'
import {
  ProctoringData,
  LiveStreamData,
} from '../../../common/types/proctoring'

export interface ProctoringState {
  data: ProctoringData
  loading: Boolean
  error: Error | false
}

const initialState: ProctoringState = {
  data: {
    enabled: false,
    paused: false,
    currentProctorCount: 0,
    forceSubmit: false,
    anomalyDetection: false,
    openBook: false,
    onboarding: {
      required: false,
      complete: false,
      photo_taken: false,
      photo_verified: false,
      rejected: false,
      photo_url: '',
      loading: false,
      error: false,
      context_type: '',
      context_id: '',
      is_loaded: false,
      time_in_min: 0,
      id: 0,
    },
    messages: [],
  },
  loading: false,
  error: false,
}

const stopMediaStream = (mediaStream: MediaStream) => {
  mediaStream.getTracks().forEach((track: MediaStreamTrack) => {
    track.stop()
  })
}

const stopStream = (streamData: ProctoringState['data']['mediaStream']) => {
  if (streamData) {
    streamData.forEach((stream: LiveStreamData | MediaStream) => {
      if (stream instanceof MediaStream) stopMediaStream(stream)
      else if (stream.video) {
        stream.video.client.leave().then(() => {
          // Stop playing the local stream
          stream.video!.stream.stop()
          // Close the local stream
          stream.video!.stream.close()
        })
      }
    })
  }
}

const normalizeMessages = (data: any): ProctoringState['data']['messages'] => {
  return data.map((message: any) => ({
    id: message.sender_id,
    message: message.message,
    timestamp: message.created_at,
    isAnnouncement: message.receiver_id === -1,
  }))
}

export const proctoringReducer: Reducer<
  ProctoringState,
  ProctoringActionTypes
> = (state = initialState, action): ProctoringState => {
  switch (action.type) {
    case types.INITIATE_PROCTORING:
      return {
        ...initialState,
        data: {
          ...state.data,
          ...(action.payload.mediaStream
            ? { mediaStream: [action.payload.mediaStream] }
            : {}),
          anomalyDetection: action.payload.anomalyDetection,
          openBook: action.payload.openBook,
        },
        loading: true,
        error: false,
      }

    case types.INITIATE_PROCTORING_SUCCESS: {
      const { messages: payloadMessages, video: payloadVideo } = action.payload
      const messages = Array.isArray(payloadMessages)
        ? normalizeMessages(payloadMessages)
        : []
      const is_assignment = action.meta.type === 'Assignment'
      let video

      if (is_assignment) {
        video = payloadVideo === true || !!action.payload.rtm_token
      } else if (payloadVideo !== undefined) {
        // for quizzes to maintain the previous state since i don't know why it was done in the first place.
        video = payloadVideo
      } else {
        video = true
      }

      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          messages,
          options: {
            ...state.data.options,
            ...action.payload,
            video,
          },
        },
      }
    }

    case types.UPDATE_USER_PROCTOR_INFO:
      return {
        ...state,
        data: {
          ...state.data,
          currentProctorCount: action.payload.count
            ? state.data.currentProctorCount + action.payload.count
            : state.data.currentProctorCount,
        },
        loading: true,
        error: false,
      }

    case types.UPDATE_USER_PROCTOR_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          currentProctorCount: 0,
          options: {
            ...state.data.options,
            ...action.payload,
          },
        },
      }

    case types.UPDATE_USER_PROCTOR_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case types.START_PROCTORING:
      return {
        ...state,
        data: {
          ...state.data,
          enabled: true,
        },
      }

    case types.STOP_PROCTORING:
      if (state.data.mediaStream) stopStream(state.data.mediaStream)
      return {
        ...state,
        data: {
          ...state.data,
          enabled: false,
        },
      }

    case types.PAUSE_PROCTORING:
      return {
        ...state,
        data: {
          ...state.data,
          paused: true,
        },
      }

    case types.CONTINUE_PROCTORING:
      return {
        ...state,
        data: {
          ...state.data,
          paused: false,
        },
      }

    case types.SET_FORCE_SUBMIT:
      return {
        ...state,
        data: {
          ...state.data,
          forceSubmit: true,
        },
      }

    case types.SET_STREAM_INFO:
      if (!state.data.enabled) {
        stopStream([action.payload])
        return state
      }
      return {
        ...state,
        data: {
          ...state.data,
          mediaStream: [
            ...(state.data.mediaStream ? state.data.mediaStream : []),
            action.payload,
          ],
        },
      }

    case types.END_PROCTORING_SUCCESS:
    case types.END_PROCTORING_FAILURE:
      return {
        ...initialState,
      }

    case types.PROCTOR_CONTENT_FETCH_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          onboarding: { ...action.payload.onboarding, is_loaded: true },
        },
      }
    case types.SUBMIT_ONBOARDING_PHOTO:
      return {
        ...state,
        data: {
          ...state.data,
          onboarding: {
            ...state.data.onboarding,
            error: false,
            loading: true,
          },
        },
      }
    case types.SUBMIT_ONBOARDING_PHOTO_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          onboarding: {
            ...state.data.onboarding,
            error: false,
            loading: false,
            ...action.payload,
          },
        },
      }
    case types.SUBMIT_ONBOARDING_PHOTO_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          onboarding: {
            ...state.data.onboarding,
            error: true,
            loading: false,
          },
        },
      }
    case types.UPDATE_EXAM_ONBOARDING:
      return {
        ...state,
        data: {
          ...state.data,
          onboarding: {
            ...state.data.onboarding,
            ...action.payload,
          },
        },
      }
    default:
      return state
  }
}

export default proctoringReducer
