import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'web/store'
import {
  getCodingLabStatus,
  stopCodingLab,
  submitCodingLabAssignment,
} from 'web/providers/CodingLabs'
import {
  initiateProctoring,
  startProctoring,
} from 'web/providers/ProctoringProvider'
import {
  CodingLabProctoredCompletionType,
  LabStopReason,
} from 'web/providers/CodingLabs/CodingLabs.action'
import { useIsNewUIEnabled } from '../../../hooks'

export const useCodingLab = (
  codingLabAssignmentId: number,
  preview: boolean,
  assessment_id: number,
  submission_id: number,
  openBook: boolean,
  anomalyDetection: boolean,
  proctored: boolean,
  is_coding_lab_basic_proctoring_enabled: boolean
) => {
  const dispatch = useDispatch()
  const { service_task_state } = useSelector(
    (state: AppState) => state.codingLabs.data.submission
  )
  const isNewUIEnabled = useIsNewUIEnabled();

  useEffect(() => {
    if (
      codingLabAssignmentId &&
      !(service_task_state === 4 || service_task_state === 9)
    ) {
      dispatch(
        getCodingLabStatus({ codingLabAssignmentId, queryParams: { preview } })
      )
    }
  }, [codingLabAssignmentId, service_task_state])

  useEffect(() => {
    if (proctored || is_coding_lab_basic_proctoring_enabled) {
      if (service_task_state !== null && service_task_state <= 4) {
        dispatch(
          initiateProctoring({
            assessment_id,
            submission_uid: `${submission_id}_1`,
            startedAt: `${new Date().toISOString()}`,
            openBook: openBook || false,
            anomalyDetection: anomalyDetection || false,
            is_coding_lab_assignment: true,
          })
        )
        dispatch(startProctoring())
      }
    }
  }, [proctored, is_coding_lab_basic_proctoring_enabled])

  const updateStatus = () => {
    if (
      codingLabAssignmentId &&
      service_task_state !== null &&
      !(service_task_state === 4 || service_task_state === 9)
    ) {
      dispatch(
        getCodingLabStatus({ codingLabAssignmentId, queryParams: { preview } })
      )
    }
  }

  const stopCodingLabAction = (reason: LabStopReason) => {
    dispatch(
      stopCodingLab({
        codingLabAssignmentId,
        queryParams: { preview },
        proctored,
        is_coding_lab_basic_proctoring_enabled,
        lab_stop_reason: reason,
        is_new_ui_enabled: isNewUIEnabled,
      })
    )
  }

  const submitCodingLabAssignmentAction = (
    submissionId: number,
    completionType?: CodingLabProctoredCompletionType
  ) => {
    dispatch(
      submitCodingLabAssignment({
        codingLabAssignmentId,
        submissionId,
        completionType,
        is_coding_lab_basic_proctoring_enabled,
        proctored,
        is_new_ui_enabled: isNewUIEnabled,
      })
    )
  }

  return { updateStatus, stopCodingLabAction, submitCodingLabAssignmentAction }
}

export default useCodingLab
