import { Reducer } from 'redux'
import { SpecializationCoursesActions } from './SpecializationCourses.actions'
import {
  FETCH_SPECIALISATION_COURSES,
  FETCH_SPECIALISATION_COURSES_SUCCESS,
  FETCH_SPECIALISATION_COURSES_FAILURE,
  FETCH_SPECIALISATION_COURSES_CANCEL,
} from './SpecializationCourses.types'
import { SpecializationCoursesData } from '../../../common/types/specializationCourses'
import { CourseID } from '../../../common/types/courses'
import {
  UPDATE_ACTIVE_PROGRAM,
  ProgramActions,
} from '../Dashboard/ProgramsProvider'

interface SpecializationCoursesStateData {
  byId: { [key in CourseID]: SpecializationCoursesData }
  premiumIds: CourseID[]
  specializationIds: CourseID[]
}

export interface SpecializationCoursesState {
  data: SpecializationCoursesStateData
  loading: Boolean
  error: false | Error | Response
}

const initialState: SpecializationCoursesState = {
  data: {
    byId: {},
    premiumIds: [],
    specializationIds: [],
  },
  loading: false,
  error: false as false,
}

const courseListDataHandler = (
  data: [],
  state: SpecializationCoursesStateData
): SpecializationCoursesStateData => {
  const courses: SpecializationCoursesStateData = {
    byId: {},
    premiumIds: [],
    specializationIds: [],
  }

  data.forEach((c: SpecializationCoursesData) => {
    courses.byId[c.course_id] = { ...state.byId[c.course_id], ...c }
    if (c.academy_course) {
      courses.premiumIds.push(c.course_id)
    } else {
      courses.specializationIds.push(c.course_id)
    }
  })

  return courses
}

export const specializationCoursesReducer: Reducer<
  SpecializationCoursesState,
  | SpecializationCoursesActions
  | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SPECIALISATION_COURSES:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case FETCH_SPECIALISATION_COURSES_SUCCESS: {
      const processedData = courseListDataHandler(action.payload, state.data)
      return {
        ...state,
        data: processedData,
        loading: false,
        error: false,
      }
    }

    case FETCH_SPECIALISATION_COURSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case FETCH_SPECIALISATION_COURSES_CANCEL:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_ACTIVE_PROGRAM:
      return initialState
    default:
      return state
  }
}

export default specializationCoursesReducer
