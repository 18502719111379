import { ProctoredEventType } from 'common/types/codingLabs'
import * as types from './CodingLabs.types'

export type LabStopReason = 'inactivity' | 'manual'
export interface CodingLabPayload {
  codingLabAssignmentId: number
  queryParams?: any
  proctored?: boolean
  is_coding_lab_basic_proctoring_enabled?: boolean
  assignment_id?: number
  submission_id?: number
  anomalyDetection?: boolean
  openBook?: boolean
  lab_stop_reason?: LabStopReason
  is_new_ui_enabled?: boolean
}

export type CodingLabProctoredCompletionType =
  | 'manual'
  | 'auto_time_expired'
  | 'auto_violations_exceeded'
  | 'force_submit'

export interface SubmitCodingLabPayload extends CodingLabPayload {
  submissionId: number
  completionType?: CodingLabProctoredCompletionType
}

export const getCodingLabStatus = (payload: CodingLabPayload) => ({
  type: types.GET_CODING_LAB_STATUS as typeof types.GET_CODING_LAB_STATUS,
  payload,
})

export const getCodingLabStatusSuccess = (payload: any) => ({
  type: types.GET_CODING_LAB_STATUS_SUCCESS as typeof types.GET_CODING_LAB_STATUS_SUCCESS,
  payload,
})

export const getCodingLabStatusFailure = (payload: Error) => ({
  type: types.GET_CODING_LAB_STATUS_FAILURE as typeof types.GET_CODING_LAB_STATUS_FAILURE,
  payload,
})

export const getCodingLabStatusCancel = (payload: any) => ({
  type: types.GET_CODING_LAB_STATUS_CANCEL as typeof types.GET_CODING_LAB_STATUS_CANCEL,
  payload,
})

export const startCodingLab = (payload: CodingLabPayload) => ({
  type: types.START_CODING_LAB as typeof types.START_CODING_LAB,
  payload,
})

export const startCodingLabSuccess = (payload: any) => ({
  type: types.START_CODING_LAB_SUCCESS as typeof types.START_CODING_LAB_SUCCESS,
  payload,
})

export const startCodingLabFailure = (payload: Error) => ({
  type: types.START_CODING_LAB_FAILURE as typeof types.START_CODING_LAB_FAILURE,
  payload,
})

export const startCodingLabCancel = (payload: any) => ({
  type: types.START_CODING_LAB_CANCEL as typeof types.START_CODING_LAB_CANCEL,
  payload,
})

export const stopCodingLab = (payload: CodingLabPayload) => ({
  type: types.STOP_CODING_LAB as typeof types.STOP_CODING_LAB,
  payload,
})

export const stopCodingLabSuccess = (payload: any) => ({
  type: types.STOP_CODING_LAB_SUCCESS as typeof types.STOP_CODING_LAB_SUCCESS,
  payload,
})

export const stopCodingLabFailure = (payload: Error) => ({
  type: types.STOP_CODING_LAB_FAILURE as typeof types.STOP_CODING_LAB_FAILURE,
  payload,
})

export const stopCodingLabCancel = (payload: any) => ({
  type: types.STOP_CODING_LAB_CANCEL as typeof types.STOP_CODING_LAB_CANCEL,
  payload,
})

export const getCodingLabAssignmentAndSubmissionData = (
  payload: CodingLabPayload
) => ({
  type: types.GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA as typeof types.GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA,
  payload,
})

export const getCodingLabAssignmentAndSubmissionDataSuccess = (
  payload: any
) => ({
  type: types.GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_SUCCESS as typeof types.GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_SUCCESS,
  payload,
})

export const getCodingLabAssignmentAndSubmissionDataFailure = (
  payload: Error
) => ({
  type: types.GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_FAILURE as typeof types.GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_FAILURE,
  payload,
})

export const getCodingLabAssignmentAndSubmissionDataCancel = (
  payload: any
) => ({
  type: types.GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_CANCEL as typeof types.GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_CANCEL,
  payload,
})

export const submitCodingLabAssignment = (payload: SubmitCodingLabPayload) => ({
  type: types.SUBMIT_CODING_LAB_ASSIGNMENT as typeof types.SUBMIT_CODING_LAB_ASSIGNMENT,
  payload,
})

export const submitCodingLabAssignmentSuccess = (payload: any) => ({
  type: types.SUBMIT_CODING_LAB_ASSIGNMENT_SUCCESS as typeof types.SUBMIT_CODING_LAB_ASSIGNMENT_SUCCESS,
  payload,
})

export const submitCodingLabAssignmentFailure = (payload: Error) => ({
  type: types.SUBMIT_CODING_LAB_ASSIGNMENT_FAILURE as typeof types.SUBMIT_CODING_LAB_ASSIGNMENT_FAILURE,
  payload,
})

export const submitCodingLabAssignmentCancel = (payload: any) => ({
  type: types.SUBMIT_CODING_LAB_ASSIGNMENT_CANCEL as typeof types.SUBMIT_CODING_LAB_ASSIGNMENT_CANCEL,
  payload,
})

export const sendCodingLabProctorEvent = (payload: {
  codingLabAssignmentId: number
  event_name: ProctoredEventType
  data: any
  is_focused: boolean
}) => ({
  type: types.SEND_CODING_LAB_PROCTOR_EVENT as typeof types.SEND_CODING_LAB_PROCTOR_EVENT,
  payload,
})

export type CodingLabsActionTypes =
  | ReturnType<typeof getCodingLabStatus>
  | ReturnType<typeof getCodingLabStatusSuccess>
  | ReturnType<typeof getCodingLabStatusFailure>
  | ReturnType<typeof getCodingLabStatusCancel>
  | ReturnType<typeof startCodingLab>
  | ReturnType<typeof startCodingLabSuccess>
  | ReturnType<typeof startCodingLabFailure>
  | ReturnType<typeof startCodingLabCancel>
  | ReturnType<typeof stopCodingLab>
  | ReturnType<typeof stopCodingLabSuccess>
  | ReturnType<typeof stopCodingLabFailure>
  | ReturnType<typeof stopCodingLabCancel>
  | ReturnType<typeof getCodingLabAssignmentAndSubmissionData>
  | ReturnType<typeof getCodingLabAssignmentAndSubmissionDataSuccess>
  | ReturnType<typeof getCodingLabAssignmentAndSubmissionDataFailure>
  | ReturnType<typeof getCodingLabAssignmentAndSubmissionDataCancel>
  | ReturnType<typeof submitCodingLabAssignment>
  | ReturnType<typeof submitCodingLabAssignmentSuccess>
  | ReturnType<typeof submitCodingLabAssignmentFailure>
  | ReturnType<typeof submitCodingLabAssignmentCancel>
  | ReturnType<typeof sendCodingLabProctorEvent>
