import {
  Assignment,
  FlashOn,
  Link as LinkImg,
  PlayArrow,
  Forum,
  AttachFile,
  CheckCircle,
  Error as ErrorIcon,
  Warning,
  Info,
  Send,
  MoreVert,
  ForumOutlined,
  FeaturedPlayListOutlined
} from '@material-ui/icons'
import cx from 'classnames'
import React from 'react'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import styles from './CustomIcons.module.css'
import {
  ArticleIcon,
  OtherItemsIcon,
  LiveVideoIcon as LiveIcon,
} from '../Icons'
import { ModuleItemData } from '../../../../../common/types/courses/moduleItem'

interface IconProps {
  coloured?: boolean
  circular?: boolean
  className?: string
}

export const AssignmentIcon = (props: IconProps) => {
  return (
    <Assignment
      className={cx(
        props.className,
        props.coloured && styles.coloured,
        props.circular && styles.roundedIcon,
        styles.assignmentIcon
      )}
    />
  )
}

export const QuizIcon = (props: IconProps) => {
  return (
    <FlashOn
      className={cx(
        props.className,
        props.coloured && styles.coloured,
        props.circular && styles.roundedIcon,
        styles.quizIcon
      )}
    />
  )
}

export const DiscussionIcon = (props: IconProps) => {
  return (
    <Forum
      className={cx(
        props.className,
        props.coloured && styles.coloured,
        props.circular && styles.roundedIcon,
        styles.discussionIcon
      )}
    />
  )
}

export const LinkIcon = (props: IconProps) => {
  return (
    <LinkImg
      className={cx(
        props.className,
        props.coloured && styles.coloured,
        props.circular && styles.roundedIcon,
        styles.linkIcon
      )}
    />
  )
}

export const LiveVideoIcon = (props: IconProps) => {
  return (
    <LiveIcon
      className={cx(
        props.className,
        props.coloured && styles.coloured,
        props.circular && styles.roundedIcon,
        styles.liveVideoIcon
      )}
    />
  )
}

export const VideoIcon = (props: IconProps) => {
  return (
    <PlayArrow
      className={cx(
        props.className,
        props.coloured && styles.coloured,
        props.circular && styles.roundedIcon,
        styles.videoIcon
      )}
    />
  )
}

export const SendIcon = (props: IconProps) => {
  return (
    <Send
      className={cx(
        props.className,
        props.coloured && styles.coloured,
        props.circular && styles.roundedIcon,
        styles.sendIcon
      )}
    />
  )
}

export const SettingsIcon = (props: IconProps) => {
  return (
    <MoreVert
      className={cx(
        props.className,
        props.coloured && styles.coloured,
        props.circular && styles.roundedIcon,
        styles.settingsIcon
      )}
    />
  )
}

export const PageIcon = (props: IconProps) => {
  return (
    <ArticleIcon
      className={cx(
        props.className,
        props.coloured && styles.coloured,
        props.circular && styles.roundedIcon,
        styles.pageIcon
      )}
    />
  )
}

export const ResourceIcon = (props: IconProps) => {
  return (
    <AttachFile
      className={cx(
        props.className,
        props.coloured && styles.coloured,
        props.circular && styles.roundedIcon,
        styles.resourceIcon
      )}
    />
  )
}

export const AiPlaygroundIcon = (props: IconProps) => {
  return (
    <FeaturedPlayListOutlined
      className={cx(
        props.className,
        props.coloured && styles.coloured,
        props.circular && styles.roundedIcon,
        styles.assignmentIcon
      )}
    />
  )
}

export const AiMockInterviewIcon = (props: IconProps) => {
  return (
    <ForumOutlined
      className={cx(
        props.className,
        props.coloured && styles.coloured,
        props.circular && styles.roundedIcon,
        styles.assignmentIcon
      )}
    />
  )
}

export const MovingIcon = createSvgIcon(<path d="M19.71 9.71 22 12V6h-6l2.29 2.29-4.17 4.17c-.39.39-1.02.39-1.41 0l-1.17-1.17c-1.17-1.17-3.07-1.17-4.24 0L2 16.59 3.41 18l5.29-5.29c.39-.39 1.02-.39 1.41 0l1.17 1.17c1.17 1.17 3.07 1.17 4.24 0l4.19-4.17z"></path>, "Moving")


export const OtherModuleItemsIcon = (props: IconProps) => {
  return (
    <OtherItemsIcon
      className={cx(
        props.className,
        props.coloured && styles.coloured,
        props.circular && styles.roundedIcon,
        styles.othersIcon
      )}
    />
  )
}

type ModuleItemIconProps = IconProps &
  ({ type: ModuleItemData['type'] | 'Video' | 'LiveVideo' | 'MockInterview' | 'AiTeacher' | 'AiPlayground' })

export const ModuleItemIcon = ({ type, ...props }: ModuleItemIconProps) => {
  switch (type) {
    case 'Assignment':
      return <AssignmentIcon {...(props as IconProps)} />
    case 'Discussion':
      return <DiscussionIcon {...(props as IconProps)} />
    case 'Quiz':
      return <QuizIcon {...(props as IconProps)} />
    case 'ExternalUrl':
    case 'ExternalTool':
      return <LinkIcon {...(props as IconProps)} />
    case 'File':
      return <ResourceIcon {...(props as IconProps)} />
    case 'LiveVideo':
      return <LiveVideoIcon {...(props as IconProps)} />
    case 'Video':
      return <VideoIcon {...(props as IconProps)} />
    case 'Page':
      return <PageIcon {...(props as IconProps)} />
    case 'AiPlayground':
      return <AiPlaygroundIcon {...(props as IconProps)} />
    case 'SubHeader':
      return null
    case 'MockInterview':
      return <AiMockInterviewIcon {...(props as IconProps)} />
    case 'AiTeacher':
      return <VideoIcon {...(props as IconProps)} />
    default:
      return <OtherModuleItemsIcon {...(props as IconProps)} />
  }
}

type MessageTypeIcon = {
  type: 'error' | 'warning' | 'info' | 'success'
} & SvgIconProps

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: ErrorIcon,
  info: Info,
}

export const MessageTypeIcon = ({
  type,
  className,
  ...props
}: MessageTypeIcon) => {
  const Icon = variantIcon[type]
  return <Icon className={cx(className, styles[type])} {...props} />
}
