import * as types from './FirstUnlockedModule.types'

export interface UnlockedModulesFetchParams {
  course_ids: number[]
}

export const fetchUnlockedModules = (payload: UnlockedModulesFetchParams) => ({
  type: types.FETCH_FIRST_UNLOCKED_MODULES as typeof types.FETCH_FIRST_UNLOCKED_MODULES,
  payload,
})

export const fetchUnlockedModulesFailure = (payload: Error) => ({
  type: types.FETCH_FIRST_UNLOCKED_MODULES_FAILURE as typeof types.FETCH_FIRST_UNLOCKED_MODULES_FAILURE,
  payload,
})

export const fetchUnlockedModulesSuccess = (payload: any) => {
  return {
    type: types.FETCH_FIRST_UNLOCKED_MODULES_SUCCESS as typeof types.FETCH_FIRST_UNLOCKED_MODULES_SUCCESS,
    payload,
  }
}

export const fetchUnlockedModulesCancel = () => ({
  type: types.FETCH_FIRST_UNLOCKED_MODULES_CANCEL as typeof types.FETCH_FIRST_UNLOCKED_MODULES_CANCEL,
})

export type UnlockedModulesActions =
  | ReturnType<typeof fetchUnlockedModules>
  | ReturnType<typeof fetchUnlockedModulesSuccess>
  | ReturnType<typeof fetchUnlockedModulesFailure>
  | ReturnType<typeof fetchUnlockedModulesCancel>
