import {
  LearnAndDevCareerPathMetricsAPIData,
  fslDashboardAPIData,
  LearnAndDevDepartmentMetricsAPIData,
} from 'common/types/learnAndDev/dashboard'
import { Reducer } from 'redux'
import { DashboardActions } from './Dashboard.actions'
import {
  FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS,
  FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_CANCEL,
  FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_FAILURE,
  FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_SUCCESS,
  FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT,
  FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_CANCEL,
  FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_FAILURE,
  FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_SUCCESS,
  FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS,
  FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_CANCEL,
  FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_FAILURE,
  FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_SUCCESS,
} from './Dashboard.types'

export interface fslDashboardState {
  data: fslDashboardAPIData | null
  loading: boolean
  error: false | Error | Response
  departmentMetrics: {
    data: LearnAndDevDepartmentMetricsAPIData | null
    error: false | Error | Response
    loading: boolean
  }
  careerPathMetrics: {
    data: LearnAndDevCareerPathMetricsAPIData | null
    error: false | Error | Response
    loading: boolean
  }
}

const initialState: fslDashboardState = {
  data: null,
  loading: false,
  error: false,
  departmentMetrics: {
    data: null,
    loading: false,
    error: false,
  },
  careerPathMetrics: {
    data: null,
    loading: false,
    error: false,
  },
}

const fslDashboardReducer: Reducer<fslDashboardState, DashboardActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT:
      return { ...state, loading: true, error: false }

    case FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      }

    case FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_CANCEL:
      return {
        ...state,
        loading: false,
        error: false,
      }

    case FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS:
      return {
        ...state,
        departmentMetrics: {
          ...state.departmentMetrics,
          loading: true,
          error: false,
        },
      }

    case FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_SUCCESS:
      return {
        ...state,
        departmentMetrics: {
          ...state.departmentMetrics,
          loading: false,
          error: false,
          data: action.payload,
        },
      }

    case FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_FAILURE:
      return {
        ...state,
        departmentMetrics: {
          ...state.departmentMetrics,
          loading: false,
          error: action.payload,
        },
      }

    case FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_CANCEL:
      return {
        ...state,
        departmentMetrics: {
          ...state.departmentMetrics,
          loading: false,
          error: false,
        },
      }

    case FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS:
      return {
        ...state,
        careerPathMetrics: {
          ...state.careerPathMetrics,
          loading: true,
          error: false,
        },
      }

    case FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_SUCCESS:
      return {
        ...state,
        careerPathMetrics: {
          ...state.careerPathMetrics,
          loading: false,
          error: false,
          data: action.payload,
        },
      }

    case FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_FAILURE:
      return {
        ...state,
        careerPathMetrics: {
          ...state.careerPathMetrics,
          loading: false,
          error: action.payload,
        },
      }

    case FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_CANCEL:
      return {
        ...state,
        careerPathMetrics: {
          ...state.careerPathMetrics,
          loading: false,
          error: false,
        },
      }

    default:
      return state
  }
}

export default fslDashboardReducer
