export const START_EXTERNAL_LABS = 'START_EXTERNAL_LABS'
export const START_EXTERNAL_LABS_SUCCESS = 'START_EXTERNAL_LABS_SUCCESS'
export const START_EXTERNAL_LABS_FAILURE = 'START_EXTERNAL_LABS_FAILURE'
export const START_EXTERNAL_LABS_CANCEL = 'START_EXTERNAL_LABS_CANCEL'

export const STOP_EXTERNAL_LABS = 'STOP_EXTERNAL_LABS'
export const STOP_EXTERNAL_LABS_SUCCESS = 'STOP_EXTERNAL_LABS_SUCCESS'
export const STOP_EXTERNAL_LABS_FAILURE = 'STOP_EXTERNAL_LABS_FAILURE'
export const STOP_EXTERNAL_LABS_CANCEL = 'STOP_EXTERNAL_LABS_CANCEL'

export const PING_EXTERNAL_LABS_STATUS = 'PING_EXTERNAL_LABS_STATUS'
export const PING_EXTERNAL_LABS_STATUS_SUCCESS =
  'PING_EXTERNAL_LABS_STATUS_SUCCESS'
export const PING_EXTERNAL_LABS_STATUS_FAILURE =
  'PING_EXTERNAL_LABS_STATUS_FAILURE'
export const PING_EXTERNAL_LABS_STATUS_CANCEL =
  'PING_EXTERNAL_LABS_STATUS_CANCEL'

export const LAUNCH_EXTERNAL_LABS = 'LAUNCH_EXTERNAL_LABS'
export const LAUNCH_EXTERNAL_LABS_SUCCESS = 'LAUNCH_EXTERNAL_LABS_SUCCESS'
export const LAUNCH_EXTERNAL_LABS_FAILURE = 'LAUNCH_EXTERNAL_LABS_FAILURE'
export const LAUNCH_EXTERNAL_LABS_CANCEL = 'LAUNCH_EXTERNAL_LABS_CANCEL'

export const VALID_EXTERNAL_LABS = 'VALID_EXTERNAL_LABS'
export const VALID_EXTERNAL_LABS_SUCCESS = 'VALID_EXTERNAL_LABS_SUCCESS'
export const VALID_EXTERNAL_LABS_FAILURE = 'VALID_EXTERNAL_LABS_FAILURE'
export const VALID_EXTERNAL_LABS_CANCEL = 'VALID_EXTERNAL_LABS_CANCEL'
