import { GlaCertificatePaymentDetail } from 'common/types/programs'
import { Reducer } from 'redux'
import { CourseID } from 'common/types/courses'
import { GlaCertificatePayment } from './GlaCertificatePaymentProvider.actions'
import {
  FETCH_GLA_CERTIFICATE_PAYMENT,
  FETCH_GLA_CERTIFICATE_PAYMENT_SUCCESS,
  FETCH_GLA_CERTIFICATE_PAYMENT_CANCEL,
  FETCH_GLA_CERTIFICATE_PAYMENT_FAILURE,
} from './GlaCertificatePaymentProvider.types'

export interface GlaCertificatePaymentState {
  [courseId: CourseID]: {
    data: GlaCertificatePaymentDetail | null
    loading: boolean
    loaded: boolean
    error: false | Error | Response
  }
}

const initialState: GlaCertificatePaymentState = {}

export const glaCertificatePaymentReducer: Reducer<
  GlaCertificatePaymentState,
  GlaCertificatePayment
> = (state = initialState, action: any): GlaCertificatePaymentState => {
  switch (action.type) {
    case FETCH_GLA_CERTIFICATE_PAYMENT:
      return {
        ...state,
        [action.payload.course_id]: {
          ...state[action.payload.course_id],
          loading: true,
          loaded: false,
          error: false,
        },
      }

    case FETCH_GLA_CERTIFICATE_PAYMENT_SUCCESS:
      return {
        ...state,
        [action.meta.course_id]: {
          ...state[action.payload.course_id],
          data: action.payload,
          loading: false,
          loaded: true,
          error: false,
        },
      }

    case FETCH_GLA_CERTIFICATE_PAYMENT_FAILURE:
      return {
        ...state,
        [action.meta.course_id]: {
          ...state[action.payload.course_id],
          loading: false,
          loaded: true,
          error: action.payload,
        },
      }

    case FETCH_GLA_CERTIFICATE_PAYMENT_CANCEL:
      return {
        ...state,
        [action.meta.course_id]: {
          ...state[action.payload.course_id],
          loading: false,
          loaded: false,
        },
      }
    default:
      return state
  }
}
