import { Reducer } from 'redux'

import {
  CREATE_OPENAI_USER_TOKEN,
  CREATE_OPENAI_USER_TOKEN_SUCCESS,
  CREATE_OPENAI_USER_TOKEN_FAILURE,
  DELETE_OPENAI_USER_TOKEN,
  DELETE_OPENAI_USER_TOKEN_SUCCESS,
  DELETE_OPENAI_USER_TOKEN_FAILURE,
  FETCH_OPENAI_USER_TOKEN,
  FETCH_OPENAI_USER_TOKEN_SUCCESS,
  FETCH_OPENAI_USER_TOKEN_FAILURE,
} from './OpenAiProxyProvider.types'
import { OpenAiProxyProviderActionTypes } from './OpenAiProxyProvider.actions'

export interface OpenAiProxyProviderState {
  createToken: {
    loading: boolean
    error: Error | null
    data: {
      token: string
    } | null
  }
  deleteToken: {
    loading: boolean
    error: Error | null
    data: {
      message: string
    } | null
  }
  fetchToken: {
    loading: boolean
    error: Error | null
    data: { token: string | null } | null
  }
}

const initialState: OpenAiProxyProviderState = {
  createToken: {
    loading: false,
    error: null,
    data: null,
  },
  deleteToken: {
    loading: false,
    error: null,
    data: null,
  },
  fetchToken: {
    loading: false,
    error: null,
    data: null,
  },
}

const openAiProxyProviderReducer: Reducer<
  OpenAiProxyProviderState,
  OpenAiProxyProviderActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_OPENAI_USER_TOKEN:
      return {
        ...state,
        createToken: {
          ...state.createToken,
          loading: true,
          error: null,
        },
      }
    case CREATE_OPENAI_USER_TOKEN_SUCCESS:
      return {
        ...state,
        createToken: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case CREATE_OPENAI_USER_TOKEN_FAILURE:
      return {
        ...state,
        createToken: {
          ...state.createToken,
          loading: false,
          error: action.payload,
        },
      }
    case DELETE_OPENAI_USER_TOKEN:
      return {
        ...state,
        deleteToken: {
          ...state.deleteToken,
          loading: true,
          error: null,
        },
      }
    case DELETE_OPENAI_USER_TOKEN_SUCCESS:
      return {
        ...state,
        deleteToken: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case DELETE_OPENAI_USER_TOKEN_FAILURE:
      return {
        ...state,
        deleteToken: {
          ...state.deleteToken,
          loading: false,
          error: action.payload,
        },
      }
    case FETCH_OPENAI_USER_TOKEN:
      return {
        ...state,
        fetchToken: {
          ...state.fetchToken,
          loading: true,
          error: null,
        },
      }
    case FETCH_OPENAI_USER_TOKEN_SUCCESS:
      return {
        ...state,
        fetchToken: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case FETCH_OPENAI_USER_TOKEN_FAILURE:
      return {
        ...state,
        fetchToken: {
          ...state.fetchToken,
          loading: false,
          error: action.payload,
        },
      }
    default:
      return state
  }
}

export default openAiProxyProviderReducer
