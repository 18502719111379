import {
  apiCall,
  objectToFormData,
  getUserDetails,
} from '../../../../common/utils'
import {
  checkUserConsent,
  updateMouthShutEvent,
  userConsentAccept,
} from './UserConsent.actions'

export async function checkUserConsentAPI(
  action: ReturnType<typeof checkUserConsent>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/check_user_consent`,
    params: { method: 'GET', signal },
    query: { type: 'connect' },
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function userConsentAcceptAPI(
  action: ReturnType<typeof userConsentAccept>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const body = JSON.stringify({
    type: 'connect',
  })
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/consent`,
    params: {
      signal,
      method: 'POST',
      body,
    },
    excludeProgramId: true,
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function mouthShutEventAPI(
  action: ReturnType<typeof updateMouthShutEvent>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/events/${id}/mouthshut/${action.payload.event}`,
    params: {
      signal,
      method: 'PUT',
    },
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}
