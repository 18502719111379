import { combineReducers } from 'redux'

import { utilsReducer, UtilsState } from 'web/providers/UtilsProvider'
import {
  excelerateTestsReducer,
  DigitalExcelerateTestsState,
} from 'web/providers/DigitalExcelerate/TestsProvider'
import ZoomoutExternalReducer, {
  ZoomoutExternalState,
} from 'web/providers/Zoomout/External/Zoomout.reducer'
import { ZoomoutInternalState } from 'web/providers/Zoomout/Internal/Internal.reducer'
import { ZoomoutInternalReducer } from 'web/providers/Zoomout/Internal'
import { learnersEngagementScoreReducer } from 'web/providers/Zoomout/Engagement'
import { EngagementState } from 'web/providers/Zoomout/Engagement/Engagement.reducer'
import {
  programCategoryReducer,
  ProgramCategoryState,
} from 'web/providers/ProgramCategory'
import {
  careerSchoolReducer,
  CareerSchoolState,
} from 'web/providers/Dashboard/CareerSchoolDashboard'

import {
  userTestimonialReducer,
  UserTestimonialState,
} from 'web/providers/User/UserTestimonialProvider'
import {
  userSupportTicketsReducer,
  UserSupportTicketsState,
} from 'web/providers/User/UserSupportTicketsProvider'
import { zoomoutQAReducer, ZoomoutQAState } from 'web/providers/Zoomout/QA'
import {
  fslLeaderboardReducer,
  FslLeaderboardState,
} from 'web/providers/LearnAndDev/LeaderboardProvider'
import {
  fslDashboardReducer,
  fslDashboardState,
} from 'web/providers/LearnAndDev/DashboardProvider'
import {
  fslDepartmentsReducer,
  FslDepartmentState,
} from 'web/providers/LearnAndDev/DepartmentsProvider'
import {
  ZoomoutPollsReducer,
  ZoomoutPollState,
} from 'web/providers/Zoomout/Polls'
import {
  PeerRatingsState,
  peerRatingsReducer,
} from 'web/providers/PeerRatingsProvider'
import {
  upcomingWebinarsReducer,
  UpcomingWebinarsState,
} from 'web/providers/UpcomingWebinarsProvider'
import { privateCertificatesReducer } from 'web/providers/PrivateCertificateProvider'
import { PrivateCertificatesState } from 'web/providers/PrivateCertificateProvider/PrivateCertificate.reducer'
import {
  snackbarReducer,
  SnackbarState,
} from 'web/providers/Dashboard/SnackbarProvider'
import openAiProxyProviderReducer, {
  OpenAiProxyProviderState,
} from 'web/providers/OpenAiProxyProvider/OpenAiProxyProvider.reducer'
import curriculumAiMentorReducer, {
  CurriculumAiMentorState,
} from 'web/providers/CurriculumAiMentor/CurriculumAiMentor.reducer'
import {
  PeerReviewsReducer,
  PeerReviewState,
} from 'web/providers/PeerReviewProvider'
import { GlLabsReducer, GlLabsState } from 'web/providers/GlLabs'
import { jupyterLabsReducer, JupyterLabsState } from 'web/providers/JupyterLabs'
import {
  UserNotificationsState,
  userOverdueNotificationsReducer,
} from 'web/providers/User/UserNotificationsProvider'
import {
  CertificatesState,
  certificatesReducer,
} from '../providers/Certificates/CertificatesProvider'
import {
  HackathonsState,
  hackathonsReducer,
} from '../providers/HackathonsProvider'
import {
  coursesReducer,
  CoursesState,
} from '../providers/Courses/CoursesProvider'
import {
  ModulesState,
  modulesReducer,
} from '../providers/Courses/ModulesProvider'
import {
  moduleItemsReducer,
  ModuleItemsState,
} from '../providers/Courses/ModuleItemsProvider'
import {
  newCourseItemReducer,
  NewCourseItemState,
} from '../providers/Dashboard/NewCourseItemProvider'

import { notesReducer, NotesState } from '../providers/Courses/NotesProvider'
import {
  CourseCompletionCardsState,
  courseCompletionCardsReducer,
} from '../providers/Courses/CourseCompletionCardsProvider'
import {
  communityReducer,
  CommunityState,
} from '../providers/CommunityProvider'
import {
  programSupportFaqsReducer,
  ProgramSupportFaqsState,
} from '../providers/ProgramSupportFaqs'
import { codingLabsReducer, CodingLabsState } from '../providers/CodingLabs'
import {
  quizCodingLabsReducer,
  QuizCodingLabsState,
} from '../providers/QuizCodingLabs'
import {
  completedAssessmentsReducer,
  CompletedAssessmentsState,
} from '../providers/CompletedAssessments'
import {
  MentorshipRecordingsState,
  mentorshipRecordingsReducer,
} from '../providers/Courses/MentorshipRecordingsProvider'
import { filesReducer, FilesState } from '../providers/FilesProvider'
import {
  userDetailsReducer,
  UserDetailsState,
} from '../providers/User/UserDetailsProvider'
import {
  UserGroupsState,
  userGroupsReducer,
} from '../providers/User/UserGroupsProvider'
import {
  userSettingsReducer,
  UserSettingsState,
} from '../providers/User/UserSettingsProvider'
import pageDataReducer, {
  PageDataState,
} from '../providers/PageDataProvider/PageData.reducer'
import {
  specializationCoursesReducer,
  SpecializationCoursesState,
} from '../providers/SpecializationCoursesProvider'
import {
  dashboardReducer,
  DashboardState,
} from '../providers/Dashboard/DashboardProvider'
import {
  programReducer,
  ProgramState,
} from '../providers/Dashboard/ProgramsProvider'
import {
  activityReducer,
  ActivityState,
} from '../providers/Dashboard/ActivityProvider'
import {
  AttendanceState,
  attendanceReducer,
} from '../providers/Dashboard/AttendanceProvider'
import {
  CourseProgressState,
  courseProgressReducer,
} from '../providers/Dashboard/CourseProgressProvider'
import {
  GradebookState,
  gradebookReducer,
} from '../providers/Dashboard/GradebookProvider'
import {
  AnnouncementsState,
  announcementsReducer,
} from '../providers/AnnouncementsProvider'
import { alertsReducer, AlertsState } from '../providers/AlertsProvider'
import {
  networkDetectorReducer,
  NetworkState,
} from '../providers/NetworkDetectorProvider'
import {
  UserAdmissionState,
  userAdmissionReducer,
} from '../providers/User/UserAdmissionProvider'
import {
  UserOnboardingState,
  userOnboardingReducer,
} from '../providers/User/UserOnboardingProvider'
import { FeedbackState, feedbackReducer } from '../providers/FeedbackProvider'
import {
  LeaderboardState,
  leaderboardReducer,
} from '../providers/Dashboard/LeaderboardProvider'
import {
  ProctoringState,
  proctoringReducer,
} from '../providers/ProctoringProvider'
import {
  paymentsStatusReducer,
  PaymentStatusState,
} from '../providers/Payments/PaymentsStatusProvider'

import {
  AlumniPortalState,
  alumniPortalReducer,
} from '../providers/AlumniPortalProvider'

import {
  paymentsReducer,
  PaymentsState,
} from '../providers/Payments/PaymentsProvider'
import {
  paymentHistoryReducer,
  PaymentHistoryState,
} from '../providers/Payments/PaymentHistoryProvider'
import {
  referralsAndRewardsReducer,
  ReferralsAndRewardsState,
} from '../providers/ReferralsAndRewardsProvider'
import {
  careerPathsReducer,
  CareerPathsState,
} from '../providers/Dashboard/CareerPathProvider'

import {
  cityReducer,
  CityState,
  collegeReducer,
  CollegeState,
  universityReducer,
  UniversityState,
} from '../providers/Dashboard/FreeUserInfoDialogProvider'

import {
  allProgramsReducer,
  AllProgramsState,
} from '../providers/AllProgramsProvider'
import {
  invigilationReducer,
  InvigilationState,
} from '../providers/InvigilationProvider'

import {
  excelerateCareerPrepReducer,
  ExcelerateCareerPrepState,
} from '../providers/Excelerate/CareerPrepProvider'

import {
  codingPlatformReducer,
  CodingPlatformState,
} from '../providers/CodingPlatform'

import {
  coursePercentageCompletedReducer,
  CoursePercentageCompletedState,
} from '../providers/Dashboard/CoursePercentageCompletedProvider'

import {
  UserConsentState,
  userConsentReducer,
} from '../providers/User/UserConsentProvider'

import { EportfolioReducer, EportfolioState } from '../providers/Eportfolio'

import {
  courseAssignmentsReducer,
  CourseAssignmentState,
} from '../providers/ProgramSupport/AssignmentProvider'

import {
  courseQuizzesReducer,
  CourseQuizState,
} from '../providers/ProgramSupport/QuizProvider'

import {
  ExternalLabsState,
  externalLabsReducer,
} from '../providers/ExternalLabs'
import { commonReducer, CommonStateType } from '../providers/Common'

import {
  programBannersReducer,
  ProgramBannersState,
} from '../providers/ProgramBannersProvider'

import {
  glaCertificatePaymentReducer,
  GlaCertificatePaymentState,
} from '../providers/GlaCertificatePaymentProvider'

export interface StoreState {
  user: {
    details: UserDetailsState
    groups: UserGroupsState
    settings: UserSettingsState
    admission: UserAdmissionState
    onboarding: UserOnboardingState
    payments: {
      transactions: PaymentsState
      paymentHistory: PaymentHistoryState
      transactionStatus: PaymentStatusState
    }
    referralsAndRewards: ReferralsAndRewardsState
    connectConsent: UserConsentState
    testimonial: UserTestimonialState
    supportTickets: UserSupportTicketsState
    notifications: UserNotificationsState
  }
  eportfolio: EportfolioState
  pageData: PageDataState
  specializationCourses: SpecializationCoursesState
  /** Dashboard */
  dashboard: DashboardState
  programs: ProgramState
  activity: ActivityState
  attendance: AttendanceState
  courseProgress: CourseProgressState
  gradebook: GradebookState
  leaderboard: LeaderboardState
  careerPaths: CareerPathsState
  /** Courses */
  courses: CoursesState
  modules: ModulesState
  moduleItems: ModuleItemsState
  newCourseItem: NewCourseItemState
  notes: NotesState
  community: CommunityState
  programSupportFaqs: ProgramSupportFaqsState
  codingLabs: CodingLabsState
  quizCodingLabs: QuizCodingLabsState
  jupyterLabs: JupyterLabsState
  glLabs: GlLabsState
  completedAssessments: CompletedAssessmentsState
  mentorshipRecordings: MentorshipRecordingsState
  courseCompletionCards: CourseCompletionCardsState
  files: FilesState
  announcements: AnnouncementsState
  /* Excelerate */
  digitalExcelerate: {
    tests: DigitalExcelerateTestsState
  }
  /** Others */
  alerts: AlertsState
  snackbarDetails: SnackbarState
  network: NetworkState
  feedback: FeedbackState
  proctoring: ProctoringState
  hackathons: HackathonsState
  utils: UtilsState
  cities: CityState
  colleges: CollegeState
  universities: UniversityState
  certificates: CertificatesState
  zoomout: {
    external: ZoomoutExternalState
    internal: ZoomoutInternalState
    polls: ZoomoutPollState
    qa: ZoomoutQAState
    engagementScore: EngagementState
  }
  programCategories: ProgramCategoryState
  coursePercentageCompleted: CoursePercentageCompletedState
  upcomingWebinars: UpcomingWebinarsState
  alumniPortalDetails: AlumniPortalState
  excelerate: {
    careerPrep: ExcelerateCareerPrepState
  }
  invigilation: InvigilationState
  careerSchool: CareerSchoolState
  codingPlatform: CodingPlatformState
  openAiProxy: OpenAiProxyProviderState
  allPrograms: AllProgramsState
  fsl: {
    dashboard: fslDashboardState
    leaderboard: FslLeaderboardState
    departments: FslDepartmentState
  }
  peerRatings: PeerRatingsState
  privateCertificate: PrivateCertificatesState
  programSupport: {
    assignments: CourseAssignmentState
    quizzes: CourseQuizState
  }
  externalLab: ExternalLabsState
  peerReviewDetails: PeerReviewState
  curriculumAiMentor: CurriculumAiMentorState
  programBanners: ProgramBannersState
  glaCertificatePaymentDetail: GlaCertificatePaymentState

  common: CommonStateType
}

export default combineReducers<StoreState>({
  user: combineReducers({
    details: userDetailsReducer,
    groups: userGroupsReducer,
    settings: userSettingsReducer,
    admission: userAdmissionReducer,
    onboarding: userOnboardingReducer,
    payments: combineReducers({
      paymentHistory: paymentHistoryReducer,
      transactions: paymentsReducer,
      transactionStatus: paymentsStatusReducer,
    }),
    referralsAndRewards: referralsAndRewardsReducer,
    connectConsent: userConsentReducer,
    testimonial: userTestimonialReducer,
    supportTickets: userSupportTicketsReducer,
    notifications: userOverdueNotificationsReducer,
  }),
  eportfolio: EportfolioReducer,
  pageData: pageDataReducer,
  specializationCourses: specializationCoursesReducer,
  /** Dashboard */
  dashboard: dashboardReducer,
  programs: programReducer,
  activity: activityReducer,
  attendance: attendanceReducer,
  courseProgress: courseProgressReducer,
  gradebook: gradebookReducer,
  leaderboard: leaderboardReducer,
  careerPaths: careerPathsReducer,
  /** Courses */
  courses: coursesReducer,
  modules: modulesReducer,
  moduleItems: moduleItemsReducer,
  newCourseItem: newCourseItemReducer,
  notes: notesReducer,
  community: communityReducer,
  programSupportFaqs: programSupportFaqsReducer,
  codingLabs: codingLabsReducer,
  quizCodingLabs: quizCodingLabsReducer,
  jupyterLabs: jupyterLabsReducer,
  glLabs: GlLabsReducer,
  completedAssessments: completedAssessmentsReducer,
  mentorshipRecordings: mentorshipRecordingsReducer,
  files: filesReducer,
  announcements: announcementsReducer,
  /* Excelerate */
  digitalExcelerate: combineReducers({
    tests: excelerateTestsReducer,
  }),
  /** Others */
  alerts: alertsReducer,
  snackbarDetails: snackbarReducer,
  network: networkDetectorReducer,
  feedback: feedbackReducer,
  proctoring: proctoringReducer,
  hackathons: hackathonsReducer,
  utils: utilsReducer,
  cities: cityReducer,
  colleges: collegeReducer,
  universities: universityReducer,
  certificates: certificatesReducer,
  zoomout: combineReducers({
    external: ZoomoutExternalReducer,
    internal: ZoomoutInternalReducer,
    polls: ZoomoutPollsReducer,
    qa: zoomoutQAReducer,
    engagementScore: learnersEngagementScoreReducer,
  }),
  programCategories: programCategoryReducer,
  coursePercentageCompleted: coursePercentageCompletedReducer,
  upcomingWebinars: upcomingWebinarsReducer,
  alumniPortalDetails: alumniPortalReducer,
  excelerate: combineReducers({
    careerPrep: excelerateCareerPrepReducer,
  }),
  invigilation: invigilationReducer,
  careerSchool: careerSchoolReducer,
  codingPlatform: codingPlatformReducer,
  openAiProxy: openAiProxyProviderReducer,
  allPrograms: allProgramsReducer,
  fsl: combineReducers({
    dashboard: fslDashboardReducer,
    leaderboard: fslLeaderboardReducer,
    departments: fslDepartmentsReducer,
  }),
  peerRatings: peerRatingsReducer,
  privateCertificate: privateCertificatesReducer,
  courseCompletionCards: courseCompletionCardsReducer,
  programSupport: combineReducers({
    assignments: courseAssignmentsReducer,
    quizzes: courseQuizzesReducer,
  }),
  externalLab: externalLabsReducer,
  peerReviewDetails: PeerReviewsReducer,
  curriculumAiMentor: curriculumAiMentorReducer,
  programBanners: programBannersReducer,
  glaCertificatePaymentDetail: glaCertificatePaymentReducer,
  common: commonReducer,
})
