import React, { useState } from 'react'
import { IconButton, Typography, Box } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { SnackbarDetails } from 'common/types/dashboard'
import Link from '../../Utils/Link'
import Button from '../../Utils/Button'
import SnackbarStrip from '../SnackbarStrip'
import styles from './CommonReminder.module.css'

const CommonReminder = (props: { snackbarData: SnackbarDetails }) => {
  const [open, setOpen] = useState(true)
  const { snackbarData } = props

  const onClose = () => {
    if (snackbarData.key) {
      localStorage.setItem(snackbarData.key, '1')
    }

    setOpen(false)
  }

  return (
    <SnackbarStrip
      message={
        <Box id="client-snackbar">
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box display="flex" alignItems="center">
              <img
                src={snackbarData.logo_url}
                alt="Great Learning Logo"
                className={styles.snackbarLogo}
              />
              <Box>
                <Typography variant="subtitle2">
                  {snackbarData.title}
                </Typography>
                {snackbarData.subtitle && (
                  <Box className={styles.subtitleContainer}>
                    {snackbarData.subtitle.map(subtitle => (
                      <span>
                        <Typography variant="caption">{subtitle}</Typography>
                      </span>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
            <Link href={snackbarData.action_url} className={styles.actionBtn}>
              <Button
                variant="outlined"
                textTransform="uppercase"
                className={styles.button}
              >
                {snackbarData.action_name}
              </Button>
            </Link>
          </Box>
        </Box>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <Close />
        </IconButton>,
      ]}
      open={open}
    />
  )
}

export default CommonReminder
