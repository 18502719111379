import { Reducer } from 'redux'
import { CommonActionTypes } from './Common.actions'
import { SHOW_GL_LOADER } from './Common.types'

export type GLLoaderType = {
  heading?: string | null
  description?: string | null
  show: boolean
}

type UIType = {
  glLoader?: GLLoaderType
}

export type CommonStateType = {
  ui: UIType
}

const initialState: CommonStateType = {
  ui: {
    glLoader: {
      show: false,
    },
  },
}

const commonReducer: Reducer<CommonStateType, CommonActionTypes> = (
  state = initialState,
  action
): CommonStateType => {
  switch (action.type) {
    case SHOW_GL_LOADER:
      return {
        ...state,
        ui: {
          ...state.ui,
          glLoader: action.payload,
        },
      }
    default:
      return state
  }
}
export default commonReducer
