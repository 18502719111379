import { call, cancelled, put, takeLatest } from 'redux-saga/effects'

import { cancelable } from 'common/utils'
import {
  getGlLabAssignmentAndSubmissionData,
  getGlLabAssignmentAndSubmissionDataSuccess,
  getGlLabAssignmentAndSubmissionDataFailure,
} from './GlLabs.action'

import { getGlLabAssignmentDataAPI } from './GlLabs.api'
import {
  GET_GL_LAB_ASSIGNMENT_AND_SUBMISSION_DATA,
  GET_GL_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_CANCEL,
} from './GlLabs.types'

function* getGlLabsAssignmentAndSubmissionDataHandler(
  action: ReturnType<typeof getGlLabAssignmentAndSubmissionData>
) {
  const abortController = new AbortController()
  try {
    const status = yield call(
      getGlLabAssignmentDataAPI,
      action,
      abortController.signal
    )
    yield put(getGlLabAssignmentAndSubmissionDataSuccess(status))
  } catch (e) {
    yield put(getGlLabAssignmentAndSubmissionDataFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* glLabsMiddleware() {
  yield takeLatest(
    GET_GL_LAB_ASSIGNMENT_AND_SUBMISSION_DATA,
    cancelable(
      getGlLabsAssignmentAndSubmissionDataHandler,
      GET_GL_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_CANCEL
    )
  )
}

export default ([] as any).concat(glLabsMiddleware())
