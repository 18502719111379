import {
  LikeMetaData,
  PeerReviewData,
  PeerReviewSummary,
  PeerReviewID,
  PeerReviewFilters,
  PeerReviewMetaData,
} from 'common/types/peerReview'
import { Reducer } from 'redux'
import {
  DiscussionContentData,
  DiscussionCommentData,
  ParticipantData,
  DiscussionEntryID,
  DiscussionEntry,
} from 'common/types/courses/dq'
import {
  FETCH_PEER_REVIEWS,
  FETCH_PEER_REVIEWS_FAILURE,
  FETCH_PEER_REVIEWS_SUCCESS,
  FETCH_PEER_REVIEWS_DQ_SUCCESS,
  FETCH_PEER_REVIEWS_DQ,
  FETCH_PEER_REVIEWS_DQ_FAILURE,
  FETCH_PEER_REVIEWS_DQ_COMMENTS,
  FETCH_PEER_REVIEWS_DQ_COMMENTS_SUCCESS,
  FETCH_PEER_REVIEWS_DQ_COMMENTS_FAILURE,
  UPDATE_PEER_REVIEWS_COMMENT_RATING_SUCCESS,
  POST_PEER_REVIEW_COMMENT,
  POST_PEER_REVIEW_COMMENT_SUCCESS,
  POST_PEER_REVIEW_COMMENT_REPLY_SUCCESS,
  POST_PEER_REVIEW_COMMENT_REPLY,
  UPDATE_PEER_REVIEW_COMMENT,
  UPDATE_PEER_REVIEW_COMMENT_SUCCESS,
  REACT_TO_PEER_REVIEW_SUCCESS,
  MARK_COMMENT_AS_READ_SUCCESS,
  FETCH_PEER_REVIEW_FILTERS_SUCCESS,
} from './PeerReviews.types'

export interface PeerReviewState {
  peerReviews: PeerReviewSummary | null
  filters: PeerReviewFilters[]
  likedEntries: PeerReviewID[]
  discussionTopic: DiscussionContentData | null
  discussionTopicEntries: DiscussionCommentData | null
  likesMetaData: PeerReviewMetaData
  loading: boolean
  error: false | Error | Response
  isSubmitting?: boolean
}
const initialState: PeerReviewState = {
  peerReviews: null,
  filters: [],
  likedEntries: [],
  discussionTopic: null,
  likesMetaData: { like_results: [], submitted_time: null },
  discussionTopicEntries: null,
  loading: false,
  error: false,
}

const participantsById = (participantsArray: ParticipantData[]) => {
  const participantsMap = {}

  participantsArray.forEach(participant => {
    participantsMap[participant.id.toString()] = participant
  })

  return participantsMap
}

const overrideWithNewEntries = ({
  mapByParentId,
  newEntries,
}: {
  mapByParentId: { [parentId: string]: DiscussionEntry[] }
  newEntries: DiscussionEntry[]
}) => {
  if ((newEntries || []).length === 0) {
    return mapByParentId
  }

  newEntries.forEach(element => {
    const newEntryParentId = element.parent_id || 'root'
    const existingEntries = mapByParentId[newEntryParentId]

    if (existingEntries) {
      const index = existingEntries.findIndex(
        existingEntry => existingEntry.id === element.id
      )

      if (index !== -1) {
        existingEntries[index] = element
      } else {
        existingEntries.push(element)
      }
    } else {
      mapByParentId[newEntryParentId] = [element]
    }
  })
  return mapByParentId
}

const entryMappingByParentId = (
  replies: DiscussionEntry[],
  mapByParentId: { [parentId: string]: DiscussionEntry[] },
  unreadEntries: DiscussionEntryID[],
  newEntries: DiscussionEntry[]
) => {
  replies.forEach(({ replies: childReplies, ...item }) => {
    if (Array.isArray(childReplies)) {
      entryMappingByParentId(
        childReplies,
        mapByParentId,
        unreadEntries,
        newEntries
      )
    }

    if ((unreadEntries || []).includes(item.id)) {
      item.read_state = 'unread'
    }

    const parentId = item.parent_id || 'root'

    mapByParentId[parentId] = [...(mapByParentId[parentId] || []), item]
  })

  overrideWithNewEntries({ mapByParentId, newEntries })

  return mapByParentId
}

const peerReviewsReducer: Reducer<PeerReviewState> = (
  state = initialState,
  action
): PeerReviewState => {
  switch (action.type) {
    case FETCH_PEER_REVIEWS:
      return {
        ...state,
        peerReviews: action.page === 1 ? null : state.peerReviews,
        loading: true,
        error: false,
      }

    case FETCH_PEER_REVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        peerReviews: {
          ...state.peerReviews,
          total_count: action.payload.total_count,
          nextPage: action.payload.next_page,
          prevPage: action.payload.prev_page,
          page: action.payload.page,
          reviewsData: (state.peerReviews?.reviewsData ?? []).concat(
            action.payload.reviewsData
          ),
        },
        likedEntries: action.payload.liked_entries,
      }

    case FETCH_PEER_REVIEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case FETCH_PEER_REVIEWS_DQ:
      return {
        ...state,
        loading: true,
        discussionTopic: null,
        error: false,
      }
    case FETCH_PEER_REVIEWS_DQ_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        discussionTopic: action.payload.commentsData,
        likesMetaData: {
          like_results: action.payload.likesMetaData.like_results,
          submitted_time: action.payload.likesMetaData.submitted_time,
        },
      }
    case FETCH_PEER_REVIEWS_DQ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case FETCH_PEER_REVIEWS_DQ_COMMENTS:
      return {
        ...state,
        loading: true,
        discussionTopicEntries: null,
        error: false,
      }

    case FETCH_PEER_REVIEWS_DQ_COMMENTS_SUCCESS: {
      const { unread_entries, entry_ratings } = action.payload
      const mapByParentId: { [parentId: string]: DiscussionEntry[] } = {}

      const updatedDiscussionTopicEntries = {
        ...state.discussionTopicEntries,
        unreadEntries: unread_entries,
        participants: participantsById(action.payload.participants),
        entriesLiked: entry_ratings,
        parentChildEntryMapping: entryMappingByParentId(
          action.payload.view,
          mapByParentId,
          unread_entries,
          action.payload.new_entries
        ),
      }

      return {
        ...state,
        loading: false,
        error: false,
        discussionTopicEntries: updatedDiscussionTopicEntries,
      }
    }

    case FETCH_PEER_REVIEWS_DQ_COMMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case UPDATE_PEER_REVIEWS_COMMENT_RATING_SUCCESS: {
      const updatedEntriesLiked = {
        ...state.discussionTopicEntries?.entriesLiked,
      }

      const parentChildEntryMapping = {
        ...(state.discussionTopicEntries?.parentChildEntryMapping || {}),
      }

      const parentId = action.payload.discussionEntry.parent_id || 'root'
      const commentItemParent = parentChildEntryMapping[parentId]

      const commentItem = commentItemParent.find(
        item => item.id === action.payload.discussionEntry.id
      )

      if (action.payload.rating === 1) {
        updatedEntriesLiked[action.payload.discussionEntry.id] = 1
        if (commentItem) {
          commentItem.rating_sum = (commentItem.rating_sum || 0) + 1
        }
      } else {
        delete updatedEntriesLiked[action.payload.discussionEntry.id]
        if (commentItem) {
          commentItem.rating_sum = (commentItem.rating_sum || 0) - 1
        }
      }

      return {
        ...state,
        discussionTopicEntries: {
          ...state.discussionTopicEntries,
          entriesLiked: updatedEntriesLiked,
          [parentId]: commentItemParent,
        },
      }
    }

    case POST_PEER_REVIEW_COMMENT:
      return { ...state, isSubmitting: true }

    case POST_PEER_REVIEW_COMMENT_SUCCESS: {
      const { user, ...payloadWithoutUser } = action.payload

      const parentId = payloadWithoutUser.parent_id || 'root'
      const parentChildEntryMapping = {
        ...(state.discussionTopicEntries?.parentChildEntryMapping || {}),
      }
      parentChildEntryMapping[parentId] = [
        payloadWithoutUser,
        ...(parentChildEntryMapping[parentId] || []),
      ]

      return {
        ...state,
        isSubmitting: false,
        discussionTopicEntries: {
          ...(state.discussionTopicEntries || {}),
          parentChildEntryMapping,
          participants: {
            ...(state.discussionTopicEntries?.participants || {}),
            [user.id]:
              state.discussionTopicEntries?.participants?.[user.id] || user,
          },
        },
        discussionTopic: {
          ...state.discussionTopic,
          discussion_subentry_count:
            state.discussionTopic!.discussion_subentry_count + 1,
        },
      }
    }

    case UPDATE_PEER_REVIEW_COMMENT:
      return { ...state, isSubmitting: true }

    case UPDATE_PEER_REVIEW_COMMENT_SUCCESS: {
      const { user, ...payloadWithoutUser } = action.payload

      const parentId = payloadWithoutUser.parent_id || 'root'
      const parentChildEntryMapping = {
        ...(state.discussionTopicEntries?.parentChildEntryMapping || {}),
      }

      const indexToUpdate = parentChildEntryMapping[parentId]?.findIndex(
        entry => entry.id === payloadWithoutUser.id
      )

      if (indexToUpdate !== -1) {
        parentChildEntryMapping[parentId][indexToUpdate] = payloadWithoutUser
      }

      return {
        ...state,
        isSubmitting: false,
        discussionTopicEntries: {
          ...(state.discussionTopicEntries || {}),
          parentChildEntryMapping,
        },
      }
    }

    case POST_PEER_REVIEW_COMMENT_REPLY:
      return { ...state, isSubmitting: true }

    case POST_PEER_REVIEW_COMMENT_REPLY_SUCCESS: {
      const { user, ...payloadWithoutUser } = action.payload
      const parentId = payloadWithoutUser.parent_id || 'root'
      const parentChildEntryMapping = {
        ...(state.discussionTopicEntries?.parentChildEntryMapping || {}),
      }
      parentChildEntryMapping[parentId] = [
        payloadWithoutUser,
        ...(parentChildEntryMapping[parentId] || []),
      ]

      return {
        ...state,
        isSubmitting: false,
        discussionTopicEntries: {
          ...(state.discussionTopicEntries || {}),
          parentChildEntryMapping,
          participants: {
            ...(state.discussionTopicEntries?.participants || {}),
            [user.id]:
              state.discussionTopicEntries?.participants?.[user.id] || user,
          },
        },
        discussionTopic: {
          ...state.discussionTopic,
          discussion_subentry_count:
            state.discussionTopic!.discussion_subentry_count + 1,
        },
      }
    }

    case REACT_TO_PEER_REVIEW_SUCCESS: {
      const { peerReviewId, isLiked } = action
      const updatedPeerReviews = [...(state.peerReviews?.reviewsData || [])]
      const peerReviewIndex = updatedPeerReviews.findIndex(
        review => review.id === peerReviewId
      )

      const likedEntries = isLiked
        ? [...state.likedEntries, peerReviewId]
        : state.likedEntries.filter(id => id !== peerReviewId)

      if (peerReviewIndex !== -1) {
        const currentCount = updatedPeerReviews[peerReviewIndex].likes_count
        const updatedCount = isLiked
          ? currentCount + 1
          : Math.max(0, currentCount - 1)

        updatedPeerReviews[peerReviewIndex] = {
          ...updatedPeerReviews[peerReviewIndex],
          likes_count: updatedCount,
        }

        return {
          ...state,
          peerReviews: {
            ...state.peerReviews,
            reviewsData: updatedPeerReviews,
          },
          likedEntries,
          likesMetaData: {
            ...state.likesMetaData,
            like_results: action.likesMetaData,
          },
        }
      }

      return state
    }

    case MARK_COMMENT_AS_READ_SUCCESS: {
      const unread_entries = state.discussionTopicEntries?.unreadEntries || []
      const readDiscussionEntryId = action.discussionEntryId

      const updatedUnreadEntries = unread_entries.filter(
        (entryId: DiscussionEntryID) => entryId !== readDiscussionEntryId
      )

      return {
        ...state,
        discussionTopicEntries: {
          ...state.discussionTopicEntries,
          unreadEntries: updatedUnreadEntries,
        },
      }
    }

    case FETCH_PEER_REVIEW_FILTERS_SUCCESS: {
      return {
        ...state,
        filters: action.payload,
      }
    }

    default:
      return state
  }
}

export default peerReviewsReducer
