import { call, takeLatest, put, select, delay } from 'redux-saga/effects'
import { dynamicAnswerSorting } from 'common/utils/custom/courses/quiz'
import { apiCall, cancelable } from '../../../../../../common/utils'

import {
  getQuizSubmissionServerTime,
  getQuizSubmissionServerTimeSuccess,
  getQuizSubmissionServerTimeFailure,
  fetchQuizSubmissionDynamicQA,
  fetchQuizSubmissionDynamicQAFailure,
  fetchQuizSubmissionDynamicQASuccess,
  fetchQuizSubmissionDynamicQAListSuccess,
} from './QuizActivity.actions'
import * as types from './QuizActivity.types'
import {
  quizSubmissionTypes,
  resumeQuizSubmission,
  quizSubmissionSelectors,
} from '../QuizSubmissionsProvider'
import { startQuizSubmissionSuccess } from '../QuizSubmissionsProvider/QuizSubmissions.actions'
import { CourseID } from '../../../../../../common/types/courses'
import {
  QuizID,
  QuizSubmissionID,
  QuizSubmissionData,
  DynamicQuestionData,
} from '../../../../../../common/types/courses/quiz'
import { startProctoring } from '../../../../ProctoringProvider'
import {
  dynamicQuizQuestionThreadData,
  dynamicQuizQuestionAnswer,
  dynamicQuizQuestionThread,
} from './QuizActivity.api'

export async function getQuizSubmissionServerTimeAPI(
  data: null,
  {
    courseId,
    quizId,
    submissionId,
  }: { courseId: CourseID; quizId: QuizID; submissionId: QuizSubmissionID }
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/quizzes/${quizId}/submissions/${submissionId}/time`,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export function* getQuizSubmissionServerTimeHandler(
  action: ReturnType<typeof getQuizSubmissionServerTime>
) {
  try {
    const activeSubmission: QuizSubmissionData | null = yield select(
      quizSubmissionSelectors.getActiveQuizSubmission(action.meta.itemId)
    )
    if (!activeSubmission) {
      return
    }

    const data = yield call(getQuizSubmissionServerTimeAPI, null, {
      ...action.meta,
      submissionId: activeSubmission.id,
    })
    yield put(getQuizSubmissionServerTimeSuccess(data, action.meta))
    const timeLeft = data.time_left
    if (timeLeft > 30) {
      if (timeLeft < 330) {
        yield delay(timeLeft * 1000 - 30000)
      } else yield delay(300000)
      yield put(getQuizSubmissionServerTime(null, action.meta))
    }
  } catch (e) {
    yield put(getQuizSubmissionServerTimeFailure(e, action.meta))
  }
}

export function* dynamicQuestionsHandler(
  action: ReturnType<typeof fetchQuizSubmissionDynamicQA>
) {
  try {
    const activeSubmission: QuizSubmissionData | null = yield select(
      quizSubmissionSelectors.getActiveQuizSubmission(action.meta.itemId)
    )
    if (!activeSubmission) {
      return
    }

    let data = null
    if (!action.payload.threadId) {
      data = yield call(dynamicQuizQuestionThread, {
        embed_code: action.payload.embedCode,
        thread_name: `Curriculum-Quiz-${activeSubmission.quiz_id}-Attempt-${activeSubmission.attempt}`,
        attempt: action.payload.attempt,
        question_id: action.payload.questionId,
        submission_id: action.payload.submissionId,
      })
      yield put(fetchQuizSubmissionDynamicQASuccess(data, action.meta))
    } else if (
      action.payload.threadId &&
      action.payload.answer &&
      action.payload.modelId
    ) {
      data = yield call(dynamicQuizQuestionAnswer, {
        embed_code: action.payload.embedCode,
        answer: action.payload.answer,
        thread_id: action.payload.threadId,
        model_id: action.payload.modelId,
      })
      yield put(fetchQuizSubmissionDynamicQASuccess(data, action.meta))
    } else if (action.payload.threadId && action.payload.modelId) {
      data = yield call(dynamicQuizQuestionThreadData, {
        embed_code: action.payload.embedCode,
        thread_id: action.payload.threadId,
        model_id: action.payload.modelId,
      })
      if (Array.isArray(data)) {
        yield put(
          fetchQuizSubmissionDynamicQAListSuccess(
            dynamicAnswerSorting(data),
            action.meta
          )
        )
      }
    }
  } catch (e) {
    console.log('error is', e)
    yield put(fetchQuizSubmissionDynamicQAFailure(e, action.meta))
  }
}

export function* quizActivityHandler(
  action:
    | ReturnType<typeof startQuizSubmissionSuccess>
    | ReturnType<typeof resumeQuizSubmission>
) {
  if (action.meta.proctored || action.meta.basic_proctoring) {
    yield put(startProctoring())
  }
  if (action.meta.timeLimit) {
    yield takeLatest(
      types.GET_QUIZ_SUBMISSION_SERVER_TIME,
      getQuizSubmissionServerTimeHandler
    )
    yield put(getQuizSubmissionServerTime(null, action.meta))
  }
}

export function* quizActivityMiddleware() {
  yield takeLatest(
    [
      quizSubmissionTypes.QUIZ_SUBMISSION_RESUME_SUCCESS,
      quizSubmissionTypes.QUIZ_SUBMISSION_START_SUCCESS,
    ],
    cancelable(quizActivityHandler, [
      quizSubmissionTypes.QUIZ_SUBMISSION_RESET,
      quizSubmissionTypes.QUIZ_SUBMISSION_COMPLETE,
      quizSubmissionTypes.QUIZ_SUBMISSION_RESUME_FAILURE,
    ])
  )
  yield takeLatest(
    types.QUIZ_SUBMISSION_DYNAMIC_QA_FETCH,
    dynamicQuestionsHandler
  )
}

export default ([] as any).concat(quizActivityMiddleware())
