import { call, put, takeLatest, select } from 'redux-saga/effects'

import { showAlertMessage } from 'web/providers/AlertsProvider'
import {
  checkUserConsent,
  checkUserConsentSuccess,
  userConsentAccept,
} from './UserConsent.actions'

import * as APIs from './UserConsent.api'
import * as types from './UserConsent.types'

function* checkUserConsentHandler(action: ReturnType<typeof checkUserConsent>) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      APIs.checkUserConsentAPI,
      action,
      abortController.signal
    )

    yield put(checkUserConsentSuccess(data))
  } catch (e) {
    console.log('Something went wrong')
  }
}

function* userConsentAcceptHandler(
  action: ReturnType<typeof userConsentAccept>
) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      APIs.userConsentAcceptAPI,
      action,
      abortController.signal
    )
  } catch (e) {
    yield put(
      showAlertMessage({
        message: 'Sorry something went wrong!',
        variant: 'error',
      })
    )
  }
}

function* mouthShutEventHandler(action: ReturnType<typeof userConsentAccept>) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      APIs.mouthShutEventAPI,
      action,
      abortController.signal
    )
  } catch (e) {
    yield put(
      showAlertMessage({
        message: 'Sorry something went wrong!',
        variant: 'error',
      })
    )
  }
}

export function* checkUserConsentMiddleWare() {
  yield takeLatest(types.CHECK_USER_CONSENT, checkUserConsentHandler)
}

export function* userConsentAcceptMiddleWare() {
  yield takeLatest(types.USER_CONSENT_ACCEPT, userConsentAcceptHandler)
}
export function* mouthShutEventMiddleWare() {
  yield takeLatest(types.UPDATE_MOUTHSHUT_EVENT, mouthShutEventHandler)
}

export default ([] as any).concat([
  checkUserConsentMiddleWare(),
  userConsentAcceptMiddleWare(),
  mouthShutEventMiddleWare(),
])
