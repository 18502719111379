import {
  call,
  cancelled,
  put,
  select,
  take,
  takeLatest,
  retry,
} from 'redux-saga/effects'
import { UserTransactionStatusFailure } from 'web/providers/Payments/PaymentsStatusProvider/PaymentStatus.actions'
import { alumniMessageSwitch } from '.'
import { FileID } from '../../../../common/types'
import { cancelable, CustomError } from '../../../../common/utils'
import { showAlertMessage } from '../../AlertsProvider'
import {
  batchRemoveFile,
  batchUploadFile,
  FilesState,
} from '../../FilesProvider'
import {
  BATCH_UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
} from '../../FilesProvider/Files.types'
import {
  fetchCommunicationChannelDetails,
  fetchUserProfileDetails,
  logoutUser,
} from '../UserDetailsProvider'
import { USER_PROFILE_DETAILS_FETCH_SUCCESS } from '../UserDetailsProvider/UserDetails.types'
import {
  fetchTimeZonesFailure,
  fetchTimeZonesSuccess,
  submitMobileOTP,
  submitMobileOTPFailure,
  submitMobileOTPSuccess,
  submitPersonalDetails,
  submitPersonalDetailsFailure,
  submitPersonalDetailsSuccess,
  submitResetPassword,
  submitResetPasswordFailure,
  submitResetPasswordSuccess,
  submitTimeZone,
  submitTimeZoneFailure,
  submitTimeZoneSuccess,
  triggerMobileOTP,
  triggerMobileOTPFailure,
  triggerMobileOTPSuccess,
  updateProfessionalDetailsSuccess,
  updateProfessionalDetails,
  updateProfileImage,
  updateProfileImageFailure,
  updateProfileImageSuccess,
  updateProfessionalDetailsFailure,
} from './UserSettings.actions'
import {
  fetchTimeZonesAPI,
  fetchUserAvatarsAPI,
  submitPersonalDetailsAPI,
  submitResetPasswordAPI,
  submitTimeZoneAPI,
  triggerMobileOTPAPI,
  submitMobileOTPAPI,
  updateProfileImageAPI,
  updateProfessionalDetailAPI,
  alumniOptOutAPI,
} from './UserSettings.api'
import * as userSettingsTypes from './UserSettings.types'

function* submitPersonalDetailsHandler(
  action: ReturnType<typeof submitPersonalDetails>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      submitPersonalDetailsAPI,
      action,
      abortController.signal
    )
    yield put(
      submitPersonalDetailsSuccess({ ...data, initialData: action.payload })
    )
  } catch (e) {
    yield put(submitPersonalDetailsFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Personal details update failed',
      })
    )
    return
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }

  try {
    yield put(fetchUserProfileDetails())
    yield take(USER_PROFILE_DETAILS_FETCH_SUCCESS)
    yield put(fetchCommunicationChannelDetails())
  } catch (e) {
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Personal details update failed',
      })
    )
  }
}

function* updateProfileImageHandler() {
  const abortController = new AbortController()
  try {
    const filesState: FilesState = yield select(state => state.files)
    const fileId: FileID | undefined = filesState.byContent.profile
      ? filesState.byContent.profile.data[0]
      : undefined

    const file = fileId ? filesState.data.byId[fileId] : undefined
    const fileUploadId = fileId && file ? file.upload_id : undefined

    if (fileUploadId) {
      const avatarToken = yield retry(
        15,
        1000,
        fetchUserAvatarsAPI,
        abortController.signal,
        fileUploadId
      )
      if (avatarToken) {
        const resultData = yield call(
          updateProfileImageAPI,
          avatarToken,
          abortController.signal
        )
        yield put(updateProfileImageSuccess(resultData))
        yield put(
          showAlertMessage({
            variant: 'success',
            message: 'Profile image updated successfully',
          })
        )
        yield put(fetchUserProfileDetails())
        yield put(batchRemoveFile({ dataKey: 'profile' }))
      } else throw new Error('Avatar token not found')
    } else throw new Error('File upload id not found')
  } catch (e) {
    yield put(updateProfileImageFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Profile image update failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* saveProfileImageHandler(
  action: ReturnType<typeof updateProfileImage>
) {
  const abortController = new AbortController()

  try {
    yield put(batchUploadFile(action.payload))
    const res = yield take([BATCH_UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE])
    if (res.type === 'UPLOAD_FILE_FAILURE') {
      throw res.payload
    }
    yield updateProfileImageHandler()
  } catch (e) {
    yield put(updateProfileImageFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Profile image update failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitResetPasswordHandler(
  action: ReturnType<typeof submitResetPassword>
) {
  const abortController = new AbortController()
  try {
    const loginId: string = yield select(
      state => state.user.details.profile.data.login_id
    )
    const data = yield call(
      submitResetPasswordAPI,
      action,
      loginId,
      abortController.signal
    )
    yield put(logoutUser())
  } catch (e) {
    yield put(submitResetPasswordFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message:
          e instanceof CustomError ? e.message : 'Password update failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchTimeZonesHandler() {
  const abortController = new AbortController()
  try {
    const data = yield call(fetchTimeZonesAPI, abortController.signal)
    yield put(fetchTimeZonesSuccess(data))
  } catch (e) {
    yield put(fetchTimeZonesFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
function* submitTimeZoneHandler(action: ReturnType<typeof submitTimeZone>) {
  const abortController = new AbortController()
  try {
    const data = yield call(submitTimeZoneAPI, action, abortController.signal)
    yield put(submitTimeZoneSuccess(data))
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'Time zone updated successfully',
      })
    )
    yield put(fetchUserProfileDetails())
  } catch (e) {
    yield put(submitTimeZoneFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Time zone update failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* triggerMobileOTPHandler(action: ReturnType<typeof triggerMobileOTP>) {
  const abortController = new AbortController()
  try {
    const data = yield call(triggerMobileOTPAPI, action, abortController.signal)
    yield put(triggerMobileOTPSuccess())
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'OTP sent successfully',
      })
    )
  } catch (e) {
    yield put(triggerMobileOTPFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: e instanceof CustomError ? e.message : 'OTP sending failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitMobileOTPHandler(action: ReturnType<typeof submitMobileOTP>) {
  const abortController = new AbortController()
  try {
    const data = yield call(submitMobileOTPAPI, action, abortController.signal)
    yield put(submitMobileOTPSuccess())
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'OTP verified successfully',
      })
    )
  } catch (e) {
    yield put(submitMobileOTPFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message:
          e instanceof CustomError ? e.message : 'OTP verification failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* updateProfessionalDetailsHandler(
  action: ReturnType<typeof updateProfessionalDetails>
) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      updateProfessionalDetailAPI,
      action,
      action.payload,
      abortController.signal
    )
    yield put(
      updateProfessionalDetailsSuccess({
        loading: false,
        linkedIn_url: action.payload.linkedin_url,
      })
    )
    yield put(
      showAlertMessage({
        message: 'Personal Details Updated Successfully',
        variant: 'success',
      })
    )
  } catch (e) {
    yield put(updateProfessionalDetailsFailure())
    yield put(
      showAlertMessage({
        message: 'Sorry Something went wrong',
        variant: 'error',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* AlumniOptOutHandler(action: ReturnType<typeof alumniMessageSwitch>) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      alumniOptOutAPI,
      action,
      action.state,
      abortController.signal
    )

    yield put(
      showAlertMessage({
        message: 'Updated successfully',
        variant: 'success',
      })
    )
  } catch (e) {
    yield put(
      showAlertMessage({
        message: 'Sorry Something went wrong',
        variant: 'error',
      })
    )
  }
}

function* userSettingsMiddleware() {
  yield takeLatest(
    userSettingsTypes.SUBMIT_PERSONAL_DETAILS,
    cancelable(
      submitPersonalDetailsHandler,
      userSettingsTypes.SUBMIT_PERSONAL_DETAILS_CANCEL
    )
  )

  yield takeLatest(
    userSettingsTypes.UPDATE_PROFILE_IMAGE,
    cancelable(saveProfileImageHandler, [
      userSettingsTypes.UPDATE_PROFILE_IMAGE_CANCEL,
    ])
  )

  yield takeLatest(
    userSettingsTypes.SUBMIT_RESET_PASSWORD,
    cancelable(
      submitResetPasswordHandler,
      userSettingsTypes.SUBMIT_RESET_PASSWORD_CANCEL
    )
  )

  yield takeLatest(
    userSettingsTypes.FETCH_TIME_ZONES,
    cancelable(fetchTimeZonesHandler, userSettingsTypes.FETCH_TIME_ZONES_CANCEL)
  )

  yield takeLatest(
    userSettingsTypes.SUBMIT_TIME_ZONE,
    cancelable(submitTimeZoneHandler, userSettingsTypes.SUBMIT_TIME_ZONE_CANCEL)
  )

  yield takeLatest(
    userSettingsTypes.TRIGGER_MOBILE_OTP,
    cancelable(
      triggerMobileOTPHandler,
      userSettingsTypes.TRIGGER_MOBILE_OTP_CANCEL
    )
  )

  yield takeLatest(
    userSettingsTypes.SUBMIT_MOBILE_OTP,
    cancelable(
      submitMobileOTPHandler,
      userSettingsTypes.SUBMIT_MOBILE_OTP_CANCEL
    )
  )

  yield takeLatest(
    userSettingsTypes.UPDATE_PROFESSIONAL_DETAILS,
    cancelable(
      updateProfessionalDetailsHandler,
      userSettingsTypes.UPDATE_PROFESSIONAL_DETAILS_CANCEL
    )
  )

  yield takeLatest(userSettingsTypes.ALUMNI_MESSAGE_SWITCH, AlumniOptOutHandler)
}

export default userSettingsMiddleware()
