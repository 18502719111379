/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  CareerSchoolState,
  fetchCareerSchoolDetails,
} from 'web/providers/Dashboard/CareerSchoolDashboard'
import { updateActiveProgram } from 'web/providers/Dashboard/ProgramsProvider'
import CareerSchoolSnackBarComponent from 'web/components/CareerSchoolSnackBar'
import AsyncDOM from 'web/components/Utils/AsyncDOM'
import { AppState } from '../../store'

interface DispatchProps {
  fetchCareerSchoolDetails: typeof fetchCareerSchoolDetails
  updateActiveProgram: typeof updateActiveProgram
}
interface MapSateToProps {
  careerSchoolData: CareerSchoolState
}
interface OwnProps {
  isTrackPage: boolean
}

type Props = OwnProps & MapSateToProps & DispatchProps

const CareerSchoolSnackBar = (props: Props) => {
  const [showSnackBar, setShowSnackBar] = useState(true)
  const [enrollTrackPbId, setEnrollTrackPbId] = useState(0)

  useEffect(() => {
    if (!showSnackBar) {
      const root = document.documentElement
      root.style.setProperty('--header-height', '64px')
    }
  }, [showSnackBar])

  useEffect(() => {
    if (
      !props.careerSchoolData.loading &&
      !props.careerSchoolData.data.enrolled_schools.length &&
      !props.careerSchoolData.error
    ) {
      props.fetchCareerSchoolDetails()
    }

    if (props.careerSchoolData.data.enrolled_schools.length) {
      const trialTracks: {
        trialDays: number
        pb_id: number
      }[] = []
      props.careerSchoolData.data.enrolled_schools.map(school => {
        school.enrolled_tracks.map(track => {
          if (!track.trial_period && !track.payment_due_text) {
            setShowSnackBar(false)
          }
          const trialDaysText: string[] | null = track.payment_due_text
            ? track.payment_due_text.match(/\d+/g)
            : ['0']
          if (
            track.trial_period &&
            (track.access_blocked || (trialDaysText! && trialDaysText!.length))
          ) {
            trialTracks.push({
              // eslint-disable-next-line radix
              trialDays: track.access_blocked ? 0 : parseInt(trialDaysText![0]),
              pb_id: track.program_group_id,
            })
          }
        })
      })
      if (trialTracks.length) {
        trialTracks.sort((trackA, trackB) => {
          return trackA.trialDays - trackB.trialDays
        })
        setEnrollTrackPbId(trialTracks[0].pb_id)
      }
    }
  }, [props.careerSchoolData])

  return (
    <AsyncDOM
      data={!!props.careerSchoolData.data}
      loading={!!props.careerSchoolData.loading}
      error={!!props.careerSchoolData.error}
    >
      <AsyncDOM.Content>
        {showSnackBar && enrollTrackPbId ? (
          <CareerSchoolSnackBarComponent
            enrollTrackPbId={enrollTrackPbId}
            updateCurrentProgram={props.updateActiveProgram}
            isTrackPage={props.isTrackPage}
          />
        ) : null}
      </AsyncDOM.Content>
    </AsyncDOM>
  )
}

const mapStateToProps = (state: AppState) => ({
  careerSchoolData: state.careerSchool,
})

export default connect(
  mapStateToProps,
  {
    fetchCareerSchoolDetails,
    updateActiveProgram,
  }
)(CareerSchoolSnackBar)
