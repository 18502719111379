export const FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT =
  'FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT'
export const FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_SUCCESS =
  'FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_SUCCESS'
export const FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_FAILURE =
  'FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_FAILURE'
export const FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_CANCEL =
  'FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_CANCEL'

export const FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS =
  'FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS'
export const FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_SUCCESS =
  'FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_SUCCESS'
export const FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_FAILURE =
  'FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_FAILURE'
export const FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_CANCEL =
  'FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_CANCEL'

export const FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS =
  'FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS'
export const FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_SUCCESS =
  'FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_SUCCESS'
export const FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_FAILURE =
  'FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_FAILURE'
export const FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_CANCEL =
  'FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_CANCEL'
