import { AppState } from '../../store'
import { FeedbackContent, FeedbackData } from '../../../common/types/feedback'

export const getFeedbackContent = () => (
  state: AppState
): FeedbackContent | undefined => {
  const feedbackData: FeedbackData | undefined = state.feedback.data
  if (feedbackData && feedbackData.content) {
    return feedbackData.content
  }
  return undefined
}
export const getFeedbackData = () => (
  state: AppState
): FeedbackData | undefined => {
  return state.feedback.data
}
