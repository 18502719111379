export const FETCH_PENDING_PEER_RATINGS = 'FETCH_PENDING_PEER_RATINGS'
export const FETCH_PENDING_PEER_RATINGS_SUCCESS =
  'FETCH_PENDING_PEER_RATINGS_SUCCESS'
export const FETCH_PENDING_PEER_RATINGS_FAILURE =
  'FETCH_PENDING_PEER_RATINGS_FAILURE'
export const FETCH_PENDING_PEER_RATINGS_CANCEL =
  'FETCH_PENDING_PEER_RATINGS_CANCEL'

export const SUBMIT_PEER_RATINGS = 'SUBMIT_PEER_RATINGS'
export const SUBMIT_PEER_RATINGS_SUCCESS = 'SUBMIT_PEER_RATINGS_SUCCESS'
export const SUBMIT_PEER_RATINGS_FAILURE = 'SUBMIT_PEER_RATINGS_FAILURE'
export const SUBMIT_PEER_RATINGS_CANCEL = 'SUBMIT_PEER_RATINGS_CANCEL'

export const TOGGLE_PEER_RATINGS_DIALOG = 'TOGGLE_PEER_RATINGS_DIALOG'
