import { Reducer } from 'redux'
import { CoursePercentageCompletedActions } from './CoursePercentageCompleted.actions'
import {
  FETCH_COURSE_PERCENTAGE_COMPLETED,
  FETCH_COURSE_PERCENTAGE_COMPLETED_SUCCESS,
  FETCH_COURSE_PERCENTAGE_COMPLETED_FAILURE,
  FETCH_COURSE_PERCENTAGE_COMPLETED_CANCEL,
} from './CoursePercentageCompleted.types'

import { CoursePercentageCompletedData } from '../../../../common/types/dashboard'

export interface CoursePercentageCompletedState {
  data: CoursePercentageCompletedData
  loading: boolean
  error: false | Error | Response
}

const initialState: CoursePercentageCompletedState = {
  data: {},
  loading: false,
  error: false as false,
}

export const coursePercentageCompletedReducer: Reducer<
  CoursePercentageCompletedState,
  CoursePercentageCompletedActions
> = (state = initialState, action: any): CoursePercentageCompletedState => {
  switch (action.type) {
    case FETCH_COURSE_PERCENTAGE_COMPLETED:
      return { ...state, loading: true, error: false }
    case FETCH_COURSE_PERCENTAGE_COMPLETED_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        loading: false,
        error: false,
      }
    case FETCH_COURSE_PERCENTAGE_COMPLETED_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case FETCH_COURSE_PERCENTAGE_COMPLETED_CANCEL:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
