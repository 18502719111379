import { ProgramAdmissionData } from '../../types/admission'
import { getCurrencySymbol, isProduction } from '..'
import { readableShortDate } from '../time'
import { revisedAmount } from '../money'

export const paymentInfoMessage = (
  programAdmissionInfo: ProgramAdmissionData,
  installmentType: Exclude<keyof ProgramAdmissionData, 'payment_info'>
) => {
  let totalAmount = 0
  const installmentNamesData: string[] = []
  let dueDate = ''
  let gst = false
  let currency = ''
  currency = getCurrencySymbol(programAdmissionInfo.payment_info.currency)
  programAdmissionInfo[installmentType].forEach(installment => {
    // eslint-disable-next-line radix
    totalAmount += parseInt(installment.due_amount)
    installmentNamesData.push(installment.name)
    dueDate = installment.due_date
  })
  if (programAdmissionInfo.payment_info.gst_required) gst = true
  let intl_gst_value = programAdmissionInfo.payment_info.international_gst_value
  return `Payment of ${currency} ${revisedAmount(totalAmount, programAdmissionInfo.payment_info.bundle_program_percentage)}${gst ? ` + GST` : intl_gst_value ? ` + GST (${intl_gst_value}%)` : ``
    } for ${installmentNamesData.join(', ').replace(/, ([^,]*)$/, ' and $1')} ${installmentType === 'due_installments' ? `was` : `is`
    } due on ${readableShortDate(dueDate)}`
}

export const PAYMENT_VIA_OFFER_LETTER_ONLY_FEE_CODES = isProduction() ? 
['gl-opgdm-11-nongst-online-walsh',
'gl-opgdm-12-nongst-online-scsu',
'gl-opgdm-13-nongst-online-clark']
: ['pgpcc-o2']

