import { Reducer } from 'redux'
import { UserAdmissionActions } from './UserAdmission.actions'
import { AdmissionStatusData } from '../../../../common/types/admission'
import {
  FETCH_ADMISSION_STATUS,
  FETCH_ADMISSION_STATUS_SUCCESS,
  FETCH_ADMISSION_STATUS_FAILURE,
  FETCH_ADMISSION_STATUS_CANCEL,
} from './UserAdmission.types'
import {
  UPDATE_ACTIVE_PROGRAM,
  ProgramActions,
} from '../../Dashboard/ProgramsProvider'

export interface UserAdmissionState {
  data?: AdmissionStatusData
  loading: boolean
  error: false | Error | Response
}

const initialState: UserAdmissionState = {
  loading: false,
  error: false,
}

const userAdmissionReducer: Reducer<
  UserAdmissionState,
  | UserAdmissionActions
  | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
> = (state = initialState, action): UserAdmissionState => {
  switch (action.type) {
    case FETCH_ADMISSION_STATUS:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case FETCH_ADMISSION_STATUS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case FETCH_ADMISSION_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case FETCH_ADMISSION_STATUS_CANCEL:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_ACTIVE_PROGRAM:
      return initialState
    default:
      return state
  }
}
export default userAdmissionReducer
