export const FETCH_USER_GROUPS = 'FETCH_USER_GROUPS'
export const FETCH_USER_GROUPS_SUCCESS = 'FETCH_USER_GROUPS_SUCCESS'
export const FETCH_USER_GROUPS_FAILURE = 'FETCH_USER_GROUPS_FAILURE'
export const FETCH_USER_GROUPS_CANCEL = 'FETCH_USER_GROUPS_CANCEL'

export const FETCH_USER_COURSE_GROUPS = 'FETCH_USER_COURSE_GROUPS'
export const FETCH_USER_COURSE_GROUPS_SUCCESS =
  'FETCH_USER_COURSE_GROUPS_SUCCESS'
export const FETCH_USER_COURSE_GROUPS_FAILURE =
  'FETCH_USER_COURSE_GROUPS_FAILURE'
export const FETCH_USER_COURSE_GROUPS_CANCEL = 'FETCH_USER_COURSE_GROUPS_CANCEL'

// to fetch members of a particular group
export const FETCH_GROUP_MEMBERS = 'FETCH_GROUP_MEMBERS'
export const FETCH_GROUP_MEMBERS_SUCCESS = 'FETCH_GROUP_MEMBERS_SUCCESS'
export const FETCH_GROUP_MEMBERS_FAILURE = 'FETCH_GROUP_MEMBERS_FAILURE'
export const FETCH_GROUP_MEMBERS_CANCEL = 'FETCH_GROUP_MEMBERS_CANCEL'

// to fetch files of a particular group
export const FETCH_GROUP_FILES = 'FETCH_GROUP_FILES'
export const FETCH_GROUP_FILES_SUCCESS = 'FETCH_GROUP_FILES_SUCCESS'
export const FETCH_GROUP_FILES_FAILURE = 'FETCH_GROUP_FILES_FAILURE'
export const FETCH_GROUP_FILES_CANCEL = 'FETCH_GROUP_FILES_CANCEL'

// to upload files to a particular group
export const UPLOAD_GROUP_FILES = 'UPLOAD_GROUP_FILES'
export const UPLOAD_GROUP_FILES_SUCCESS = 'UPLOAD_GROUP_FILES_SUCCESS'
export const UPLOAD_GROUP_FILES_FAILURE = 'UPLOAD_GROUP_FILES_FAILURE'
export const UPLOAD_GROUP_FILES_CANCEL = 'UPLOAD_GROUP_FILES_CANCEL'

// to fetch quota info
export const FETCH_GROUP_FILES_QUOTA = 'FETCH_GROUP_FILES_QUOTA'
export const FETCH_GROUP_FILES_QUOTA_SUCCESS = 'FETCH_GROUP_FILES_QUOTA_SUCCESS'
export const FETCH_GROUP_FILES_QUOTA_FAILURE = 'FETCH_GROUP_FILES_QUOTA_FAILURE'
export const FETCH_GROUP_FILES_QUOTA_CANCEL = 'FETCH_GROUP_FILES_QUOTA_CANCEL'

// to delete a file
export const DELETE_GROUP_FILE = 'DELETE_GROUP_FILE'
export const DELETE_GROUP_FILE_SUCCESS = 'DELETE_GROUP_FILE_SUCCESS'
export const DELETE_GROUP_FILE_FAILURE = 'DELETE_GROUP_FILE_FAILURE'
export const DELETE_GROUP_FILE_CANCEL = 'DELETE_GROUP_FILE_CANCEL'
