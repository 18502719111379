import { apiCall, getUserDetails } from 'common/utils'
import {
  startExternalLabs,
  stopExternalLabs,
  pingExternalLabs,
  launchExternalLabs,
  validExternalLabs,
} from './ExternalLabs.action'

export async function pingExternalLabsAPI(
  action: ReturnType<typeof pingExternalLabs>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { ExternalLabId, queryParams } = action.payload

  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/external_lab/${ExternalLabId}/users/${id}/status_ping`,
    params: { signal },
    query: queryParams,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function startExternalLabAPI(
  action: ReturnType<typeof startExternalLabs>,
  signal: AbortSignal
) {
  const userData = getUserDetails()
  const { ExternalLabId } = action.payload

  const user_id =
    userData.profile && userData.profile.data ? userData.profile.data.id : ''

  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/external_lab/${ExternalLabId}/users/${user_id}/get_subscription`,
    params: {
      method: 'POST',
      signal,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function stopExternalLabAPI(
  action: ReturnType<typeof stopExternalLabs>,
  signal: AbortSignal
) {
  const userData = getUserDetails()
  const user_id =
    userData.profile && userData.profile.data ? userData.profile.data.id : ''

  const { ExternalLabId, queryParams } = action.payload

  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/external_lab/${ExternalLabId}/users/${user_id}/stop_lab`,
    params: {
      method: 'POST',
      signal,
    },
    query: queryParams,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function pingExternalLabAPI(
  action: ReturnType<typeof launchExternalLabs>,
  signal: AbortSignal
) {
  const userData = getUserDetails()
  const user_id =
    userData.profile && userData.profile.data ? userData.profile.data.id : ''

  const { ExternalLabId, queryParams } = action.payload

  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/external_lab/${ExternalLabId}/users/${user_id}/launch_lab`,
    params: {
      method: 'POST',
      signal,
    },
    query: queryParams,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function validExternalLabsAPI(
  action: ReturnType<typeof validExternalLabs>,
  signal: AbortSignal
) {
  const userData = getUserDetails()
  const { ExternalLabId } = action.payload

  const user_id =
    userData.profile && userData.profile.data ? userData.profile.data.id : ''

  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/external_lab/${ExternalLabId}/users/${user_id}/check_lab_code`,
    params: {
      method: 'GET',
      signal,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
