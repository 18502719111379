import React, { useEffect, useState } from 'react'
import {
  BookOpen,
  ChevronRight,
  ExternalLink,
  FileQuestion,
  FileText,
  GraduationCap,
  Link2,
  MessageSquare,
  Search,
  Video,
  X,
} from 'lucide-react'
import { useSelector } from 'react-redux'
import { apiCall, generateURL } from 'common/utils'
import { mixpanel } from 'common/utils/mixpanel'
import { useHistory, useLocation } from 'react-router-dom'
import {
  AppBar,
  Box,
  Dialog,
  Toolbar,
  makeStyles,
  TextField,
  InputAdornment,
  Slide,
} from '@material-ui/core'
import cx from 'classnames'
import { IconButton, Typography } from 'common/elements'
import { useMedia } from 'common/elements/hooks'
import { TransitionProps } from '@material-ui/core/transitions'
import { useDebounce } from 'web/components/Utils/Hooks'
import { CrossAppBar } from './cross-app-bar'
import { ContentLoader } from './content-loader'
import { NoDataFound } from './no-data-found'

enum SearchResultCategory {
  COURSE = 'Course',
  ATTACHMENT = 'Attachment',
  DISCUSSION_TOPIC = 'DiscussionTopic',
  EXTERNAL_URL = 'ExternalUrl',
  QUIZ = 'Quizzes::Quiz',
  VIDEO = 'Video',
  ASSIGNMENT = 'Assignment',
  WIKI_PAGE = 'WikiPage',
}

enum SearchResultContentType {
  PAGES = 'pages',
  FILES = 'files',
  ASSIGNMENT = 'assignments',
  QUIZZES = 'quizzes',
  DISCUSSION_TOPIC = 'discussion_topics',
}

const CATEGORY_TO_CONTENT_TYPE_MAP = {
  [SearchResultCategory.ATTACHMENT]: SearchResultContentType.FILES,
  [SearchResultCategory.VIDEO]: SearchResultContentType.PAGES,
  [SearchResultCategory.ASSIGNMENT]: SearchResultContentType.ASSIGNMENT,
  [SearchResultCategory.QUIZ]: SearchResultContentType.QUIZZES,
  [SearchResultCategory.DISCUSSION_TOPIC]:
    SearchResultContentType.DISCUSSION_TOPIC,
  [SearchResultCategory.EXTERNAL_URL]: SearchResultContentType.PAGES,
  [SearchResultCategory.WIKI_PAGE]: SearchResultContentType.PAGES,
}

const SEARCH_TEXT_LENGTH = 2

const useSearchStyles = makeStyles({
  container: {
    '& .MuiBackdrop-root': {
      background: 'rgba(26, 27, 30, 0.16)',
    },
  },
  appBar: {
    backgroundColor: 'white',
    borderBottom: '2px solid #75768029',
  },
  search: {
    '& .MuiFilledInput-root': {
      background: 'rgb(232, 241, 250)',
    },
    '&:hover': {
      backgroundColor: '#f4f3f7',
    },
    '&:focus': {
      backgroundColor: '#f4f3f7',
    },
  },
  searchFieldInput: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '0px',
    lineHeight: '24px',
    '&::placeholder': {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 400,
      letterSpacing: '0px',
      lineHeight: '24px',
    },
  },
  searchItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f3f7',
    },
    '&:active': {
      backgroundColor: '#efedf1',
    },
  },
})

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

function SearchResultGroupItemIcon({
  category,
}: {
  category: SearchResultCategory
}) {
  switch (category) {
    case SearchResultCategory.COURSE:
      return <BookOpen />
    case SearchResultCategory.ATTACHMENT:
      return <Link2 />
    case SearchResultCategory.DISCUSSION_TOPIC:
      return <MessageSquare />
    case SearchResultCategory.EXTERNAL_URL:
      return <ExternalLink />
    case SearchResultCategory.ASSIGNMENT:
      return <FileText />
    case SearchResultCategory.QUIZ:
      return <FileQuestion />
    case SearchResultCategory.VIDEO:
      return <Video />
    case SearchResultCategory.WIKI_PAGE:
      return <BookOpen />
    default:
      return <GraduationCap />
  }
}

function useCategoryToTitleMapping(category) {
  switch (category) {
    case SearchResultCategory.COURSE:
      return 'Course'
    case SearchResultCategory.ATTACHMENT:
      return 'Files'
    case SearchResultCategory.DISCUSSION_TOPIC:
      return 'Discussion Topics'
    case SearchResultCategory.EXTERNAL_URL:
      return 'External Links'
    case SearchResultCategory.ASSIGNMENT:
      return 'Assignments'
    case SearchResultCategory.QUIZ:
      return 'Quizzes'
    case SearchResultCategory.VIDEO:
      return 'Video'
    case SearchResultCategory.WIKI_PAGE:
      return 'Articles'
    default:
      return 'Others'
  }
}

function SearchResultGroupItem({
  item,
  contentType,
}: {
  item: any
  contentType: string
}) {
  const courses = useSelector(state => state.courses.data.byId)
  //   const user = useAppSelector(selectUser);

  const styles = useSearchStyles()
  const history = useHistory()
  const itemCourse = courses[item.context_id]
  function onPress() {
    if (item.category === SearchResultCategory.COURSE) {
      history.push(
        generateURL('COURSE', {
          path: {
            courseId: item.id,
          },
        })
      )
    } else if (item.category === SearchResultCategory.EXTERNAL_URL) {
      if (item.url) {
        window.open(item.url, '_blank')
      }
    } else {
      history.push(
        generateURL('CONTENT', {
          path: {
            courseId: item.context_id,
            contentId: item.content_id || item.id,
            contentType: CATEGORY_TO_CONTENT_TYPE_MAP[contentType],
          },
          search: {
            module_item_id: item.module_item_id,
          },
        })
      )
    }
  }

  return (
    <Box
      display="flex"
      className={cx(styles.searchItem)}
      onClick={onPress}
      padding={2}
      gridGap={4}
      border="1px solid #75768029"
      bgcolor="white"
      borderRadius={8}
    >
      <Box display="flex" alignItems="center" flex={1}>
        <SearchResultGroupItemIcon
          category={item.category as SearchResultCategory}
        />
        <Box display="flex" flexDirection="column" flex={1} marginLeft={2}>
          <Typography className={cx('subtitle2')}>
            {item.title || item.name}
          </Typography>
          {itemCourse?.course_name && (
            <Typography className={cx('caption')}>
              {itemCourse?.course_name}
            </Typography>
          )}
        </Box>
        <IconButton variant="text" disableRipple>
          <ChevronRight />
        </IconButton>
      </Box>
    </Box>
  )
}

function SearchResultGroup({
  contentType,
  data,
}: {
  contentType: string
  data: any[]
}) {
  const title = useCategoryToTitleMapping(contentType)
  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap={16}
      paddingY={1}
      paddingX={2}
    >
      <Typography className={cx('subtitle2')}>{title}</Typography>
      <Box display="flex" flexDirection="column" gridGap={8}>
        {data.map(item => (
          <SearchResultGroupItem
            key={item.id}
            item={item}
            contentType={contentType}
          />
        ))}
      </Box>
    </Box>
  )
}

export function CourseSearch({ open, onClose: onCLoseProp }: DialogProps) {
  const coursesData = useSelector(state => state.courses.data)
  const userDetails = useSelector(state => state.user.details)

  const media = useMedia()
  const location = useLocation()
  const [keyword, setKeyword] = useState('')
  const debouncedKeyword = useDebounce(keyword, 500)
  const [isApiLoading, setIsApiLoading] = useState(false)
  const [searchResults, updateSearchResults] = useState({})
  const courseIds = coursesData.completedIds.concat(
    coursesData.activeIds,
    coursesData.failedIds
  )
  const abortController = new AbortController()
  const [isTyping, setIsTyping] = useState(false)
  const showResults = !!(
    Object.keys(searchResults).length && keyword.length > SEARCH_TEXT_LENGTH
  )
  const isNoDataFound = !!(
    !Object.keys(searchResults).length &&
    keyword &&
    !isApiLoading &&
    !isTyping
  )
  const showSearchInitialState = !showResults && !isApiLoading && !isNoDataFound
  const styles = useSearchStyles()

  useEffect(() => {
    onClose()
  }, [location])

  useEffect(() => {
    if (debouncedKeyword.length > SEARCH_TEXT_LENGTH) {
      searchPG(debouncedKeyword, courseIds)
    }
  }, [debouncedKeyword])

  function onChangeText(event) {
    const text = event.target.value
    setKeyword(text)
    setIsTyping(!!text.length)
  }

  async function searchPG(keyword: string, courseIds: string[]) {
    if (keyword) {
      setIsTyping(false)
      setIsApiLoading(true)
      const response = await apiCall({
        url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/search`,
        params: {
          signal: abortController.signal,
        },
        query: {
          student_id: userDetails.id,
          keyword,
          course_ids: courseIds,
        },
      })
      if (response.ok) {
        const results = await response.json()
        updateSearchResults(results)
        let resultsLength = 0
        if (Object.keys(results).length) {
          Object.keys(results).forEach((contentType: string) => {
            resultsLength += results[contentType].length
          })
        }
        mixpanel.track('Global Search results', {
          keyword,
          numberOfResults: resultsLength,
          courseIdsLength: courseIds.length,
        })
      }
      setIsApiLoading(false)
    }
  }

  function onClose() {
    onCLoseProp()
    setKeyword('')
  }

  return (
    <Dialog
      {...(media.sm ? { TransitionComponent: Transition } : {})}
      fullScreen={media.sm}
      PaperProps={{ elevation: 0, style: { borderRadius: 8 } }}
      className={cx(styles.container)}
      onClose={onClose}
      open={open}
      fullWidth
    >
      <Box display="flex" flexDirection="column" overflow="hidden" flex={1}>
        <CrossAppBar position="relative" title="Search" onCross={onClose} />
        <AppBar elevation={0} className={cx(styles.appBar)} position="relative">
          <Toolbar
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          >
            <Box display="flex" flexDirection="column" flex={1}>
              <TextField
                variant="outlined"
                placeholder="Start typing to search..."
                value={keyword}
                autoFocus
                classes={{ root: styles.search }}
                onChange={onChangeText}
                InputProps={{
                  style: {
                    borderRadius: '8px',
                  },
                  classes: { input: styles.searchFieldInput },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search size={20} />
                    </InputAdornment>
                  ),
                  endAdornment: keyword && (
                    <InputAdornment position="end">
                      <X
                        cursor="pointer"
                        size={20}
                        onClick={() => setKeyword('')}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          display="flex"
          bgcolor="#fdfbff"
          justifyContent="center"
          overflow="auto"
          {...(media.gtSm ? { maxHeight: 600, minHeight: 392 } : {})}
          flex={1}
        >
          {showSearchInitialState && (
            <Box
              flexDirection="column"
              display="flex"
              gridGap={8}
              borderRadius={8}
              alignSelf="center"
              flex={1}
              justifyContent="center"
              {...(media?.sm ? { padding: '32px' } : {})}
              alignItems="center"
            >
              <img
                alt=""
                width={156}
                height={102}
                src={`${window.constants.REACT_APP_MAGNA_IMAGE_HOST}/images/search.svg`}
              />
              <Typography
                className={cx('body2')}
                style={{ textAlign: 'center' }}
              >
                Search for courses, videos, assessments & more from your program
              </Typography>
            </Box>
          )}
          {isApiLoading && <ContentLoader borderRadius="$1" />}
          {isNoDataFound && (
            <Box
              flex={1}
              height="100%"
              alignSelf="center"
              display="flex"
              padding={6}
              justifyContent="center"
              alignItems="center"
              borderRadius={8}
            >
              <NoDataFound />
            </Box>
          )}
          {showResults && (
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              borderRadius="$1"
              overflow="auto"
            >
              <Box display="flex" flexDirection="column" gridGap={8} flex={1}>
                {Object.keys(searchResults).map(contentType => (
                  <SearchResultGroup
                    key={contentType}
                    contentType={contentType}
                    data={searchResults?.[contentType]}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  )
}
