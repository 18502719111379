import { LocalFileData } from 'common/types'
import { ContentID } from 'common/types/courses/content'
import * as types from './Community.types'

interface fetchSimilarQuestionsParams {
  keywords: string[]
}

interface fetchUserQuestionsParams {
  userId: string
}

export const fetchSimilarQuestions = (
  payload: fetchSimilarQuestionsParams
) => ({
  type: types.FETCH_SIMILAR_QUESTIONS as typeof types.FETCH_SIMILAR_QUESTIONS,
  payload,
})

export const fetchSimilarQuestionsSuccess = (payload: any) => ({
  type: types.FETCH_SIMILAR_QUESTIONS_SUCCESS as typeof types.FETCH_SIMILAR_QUESTIONS_SUCCESS,
  payload,
})

export const fetchSimilarQuestionsFailure = (payload: Error) => ({
  type: types.FETCH_SIMILAR_QUESTIONS_FAILURE as typeof types.FETCH_SIMILAR_QUESTIONS_FAILURE,
  payload,
})

export const fetchSimilarQuestionsCancel = (payload: any) => ({
  type: types.FETCH_SIMILAR_QUESTIONS_CANCEL as typeof types.FETCH_SIMILAR_QUESTIONS_CANCEL,
  payload,
})

export const fetchUserQuestions = (payload: fetchUserQuestionsParams) => ({
  type: types.FETCH_USER_QUESTIONS as typeof types.FETCH_USER_QUESTIONS,
  payload,
})

export const fetchUserQuestionsSuccess = (payload: any) => ({
  type: types.FETCH_USER_QUESTIONS_SUCCESS as typeof types.FETCH_USER_QUESTIONS_SUCCESS,
  payload,
})

export const fetchUserQuestionsFailure = (payload: Error) => ({
  type: types.FETCH_USER_QUESTIONS_FAILURE as typeof types.FETCH_USER_QUESTIONS_FAILURE,
  payload,
})

export const fetchUserQuestionsCancel = (payload: any) => ({
  type: types.FETCH_USER_QUESTIONS_CANCEL as typeof types.FETCH_USER_QUESTIONS_CANCEL,
  payload,
})

export const fetchUserDetails = (payload: any = {}) => ({
  type: types.FETCH_USER_DETAILS as typeof types.FETCH_USER_DETAILS,
  payload,
})

export const fetchUserDetailsSuccess = (payload: any) => ({
  type: types.FETCH_USER_DETAILS_SUCCESS as typeof types.FETCH_USER_DETAILS_SUCCESS,
  payload,
})

export const fetchUserDetailsFailure = (payload: Error) => ({
  type: types.FETCH_USER_DETAILS_FAILURE as typeof types.FETCH_USER_DETAILS_FAILURE,
  payload,
})

export const fetchUserDetailsCancel = (payload: any) => ({
  type: types.FETCH_USER_DETAILS_CANCEL as typeof types.FETCH_USER_DETAILS_CANCEL,
  payload,
})

export const fetchNotifications = () => ({
  type: types.FETCH_NOTIFICATIONS as typeof types.FETCH_NOTIFICATIONS,
})

export const fetchNotificationsSuccess = (payload: number) => ({
  type: types.FETCH_NOTIFICATIONS_SUCCESS as typeof types.FETCH_NOTIFICATIONS_SUCCESS,
  payload,
})

export const fetchNotificationsFailure = (payload: Error) => ({
  type: types.FETCH_NOTIFICATIONS_FAILURE as typeof types.FETCH_NOTIFICATIONS_FAILURE,
  payload,
})

export const fetchNotificationsCancel = (payload: any) => ({
  type: types.FETCH_NOTIFICATIONS_CANCEL as typeof types.FETCH_NOTIFICATIONS_CANCEL,
  payload,
})

export const fetchTopics = (payload: any = {}) => ({
  type: types.FETCH_TOPICS as typeof types.FETCH_TOPICS,
  payload,
})

export const fetchTopicsSuccess = (payload: any) => ({
  type: types.FETCH_TOPICS_SUCCESS as typeof types.FETCH_TOPICS_SUCCESS,
  payload,
})

export const fetchTopicsFailure = (payload: Error) => ({
  type: types.FETCH_TOPICS_FAILURE as typeof types.FETCH_TOPICS_FAILURE,
  payload,
})

export const fetchTopicsCancel = (payload: any) => ({
  type: types.FETCH_TOPICS_CANCEL as typeof types.FETCH_TOPICS_CANCEL,
  payload,
})

export const postQuestion = (payload: {
  title: string
  topicIds: string[]
  attachments?: LocalFileData[]
  source?: string
  description?: string
}) => ({
  type: types.POST_QUESTION as typeof types.POST_QUESTION,
  payload,
})

export const postQuestionSuccess = (payload: any) => ({
  type: types.POST_QUESTION_SUCCESS as typeof types.POST_QUESTION_SUCCESS,
  payload,
})

export const postQuestionFailure = (payload: Error) => ({
  type: types.POST_QUESTION_FAILURE as typeof types.POST_QUESTION_FAILURE,
  payload,
})

export const postQuestionCancel = (payload: any) => ({
  type: types.POST_QUESTION_CANCEL as typeof types.POST_QUESTION_CANCEL,
  payload,
})

export const postQuestionUnderVideo = (payload: {
  title: string
  topicIds: string[]
  videoId: ContentID
}) => ({
  type: types.POST_QUESTION_UNDER_VIDEO as typeof types.POST_QUESTION_UNDER_VIDEO,
  payload,
})

export const postQuestionUnderVideoSuccess = (payload: any) => ({
  type: types.POST_QUESTION_UNDER_VIDEO_SUCCESS as typeof types.POST_QUESTION_UNDER_VIDEO_SUCCESS,
  payload,
})

export const postQuestionUnderVideoFailure = (payload: Error) => ({
  type: types.POST_QUESTION_UNDER_VIDEO_FAILURE as typeof types.POST_QUESTION_UNDER_VIDEO_FAILURE,
  payload,
})

export const postQuestionUnderVideoCancel = (payload: any) => ({
  type: types.POST_QUESTION_UNDER_VIDEO_CANCEL as typeof types.POST_QUESTION_UNDER_VIDEO_CANCEL,
  payload,
})

export const postAnswer = (payload: {
  content: string
  questionId: string
}) => ({
  type: types.POST_ANSWER as typeof types.POST_ANSWER,
  payload,
})

export const postAnswerSuccess = (payload: any) => ({
  type: types.POST_ANSWER_SUCCESS as typeof types.POST_ANSWER_SUCCESS,
  payload,
})

export const postAnswerFailure = (payload: Error) => ({
  type: types.POST_ANSWER_FAILURE as typeof types.POST_ANSWER_FAILURE,
  payload,
})

export const postAnswerCancel = (payload: any) => ({
  type: types.POST_ANSWER_CANCEL as typeof types.POST_ANSWER_CANCEL,
  payload,
})
export const deleteQuestion = (payload: { id: string }) => ({
  type: types.DELETE_QUESTION as typeof types.DELETE_QUESTION,
  payload,
})

export const deleteQuestionSuccess = (payload: any) => ({
  type: types.DELETE_QUESTION_SUCCESS as typeof types.DELETE_QUESTION_SUCCESS,
  payload,
})

export const deleteQuestionFailure = (payload: Error) => ({
  type: types.DELETE_QUESTION_FAILURE as typeof types.DELETE_QUESTION_FAILURE,
  payload,
})

export const deleteQuestionCancel = (payload: any) => ({
  type: types.DELETE_QUESTION_CANCEL as typeof types.DELETE_QUESTION_CANCEL,
  payload,
})

export const deleteAnswer = (payload: { id: string }) => ({
  type: types.DELETE_ANSWER as typeof types.DELETE_ANSWER,
  payload,
})

export const deleteAnswerSuccess = (payload: any) => ({
  type: types.DELETE_ANSWER_SUCCESS as typeof types.DELETE_ANSWER_SUCCESS,
  payload,
})

export const deleteAnswerFailure = (payload: Error) => ({
  type: types.DELETE_ANSWER_FAILURE as typeof types.DELETE_ANSWER_FAILURE,
  payload,
})

export const deleteAnswerCancel = (payload: any) => ({
  type: types.DELETE_ANSWER_CANCEL as typeof types.DELETE_ANSWER_CANCEL,
  payload,
})
export const updateQuestion = (payload: { id: string; title: string }) => ({
  type: types.UPDATE_QUESTION as typeof types.UPDATE_QUESTION,
  payload,
})

export const updateQuestionSuccess = (payload: any) => ({
  type: types.UPDATE_QUESTION_SUCCESS as typeof types.UPDATE_QUESTION_SUCCESS,
  payload,
})

export const updateQuestionFailure = (payload: Error) => ({
  type: types.UPDATE_QUESTION_FAILURE as typeof types.UPDATE_QUESTION_FAILURE,
  payload,
})

export const updateQuestionCancel = (payload: any) => ({
  type: types.UPDATE_QUESTION_CANCEL as typeof types.UPDATE_QUESTION_CANCEL,
  payload,
})

export const updateAnswer = (payload: { id: string; content: string }) => ({
  type: types.UPDATE_ANSWER as typeof types.UPDATE_ANSWER,
  payload,
})

export const updateAnswerSuccess = (payload: any) => ({
  type: types.UPDATE_ANSWER_SUCCESS as typeof types.UPDATE_ANSWER_SUCCESS,
  payload,
})

export const updateAnswerFailure = (payload: Error) => ({
  type: types.UPDATE_ANSWER_FAILURE as typeof types.UPDATE_ANSWER_FAILURE,
  payload,
})

export const updateAnswerCancel = (payload: any) => ({
  type: types.UPDATE_ANSWER_CANCEL as typeof types.UPDATE_ANSWER_CANCEL,
  payload,
})

export const fetchNewQuestions = () => ({
  type: types.FETCH_NEW_QUESTIONS as typeof types.FETCH_NEW_QUESTIONS,
})

export const fetchNewQuestionsSuccess = (payload: any) => ({
  type: types.FETCH_NEW_QUESTIONS_SUCCESS as typeof types.FETCH_NEW_QUESTIONS_SUCCESS,
  payload,
})

export const fetchNewQuestionsFailure = (payload: Error) => ({
  type: types.FETCH_NEW_QUESTIONS_FAILURE as typeof types.FETCH_NEW_QUESTIONS_FAILURE,
  payload,
})

export const fetchNewQuestionsCancel = (payload: any) => ({
  type: types.FETCH_NEW_QUESTIONS_CANCEL as typeof types.FETCH_NEW_QUESTIONS_CANCEL,
  payload,
})

export const updateCommunityJwtTokenSuccess = (payload: string) => ({
  type: types.UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS as typeof types.UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS,
  payload,
})

export const renewCommunityJwtToken = () => ({
  type: types.RENEW_COMMUNITY_JWT_TOKEN as typeof types.RENEW_COMMUNITY_JWT_TOKEN,
})

export const renewCommunityJwtTokenCancel = (payload: any) => ({
  type: types.RENEW_COMMUNITY_JWT_TOKEN_CANCEL as typeof types.RENEW_COMMUNITY_JWT_TOKEN_CANCEL,
  payload,
})

export const renewCommunityJwtTokenFailure = (payload: Error) => ({
  type: types.RENEW_COMMUNITY_JWT_TOKEN_FAILURE as typeof types.RENEW_COMMUNITY_JWT_TOKEN_FAILURE,
  payload,
})

export const fetchVideoQuestionIds = (payload: { videoId: ContentID }) => ({
  type: types.FETCH_VIDEO_QUESTIONS_IDS as typeof types.FETCH_VIDEO_QUESTIONS_IDS,
  payload,
})

export const fetchVideoQuestionIdsSuccess = (payload: any) => ({
  type: types.FETCH_VIDEO_QUESTIONS_IDS_SUCCESS as typeof types.FETCH_VIDEO_QUESTIONS_IDS_SUCCESS,
  payload,
})

export const fetchVideoQuestionIdsFailure = (payload: Error) => ({
  type: types.FETCH_VIDEO_QUESTIONS_IDS_FAILURE as typeof types.FETCH_VIDEO_QUESTIONS_IDS_FAILURE,
  payload,
})

export const fetchVideoQuestionIdsCancel = (payload: any) => ({
  type: types.FETCH_VIDEO_QUESTIONS_IDS_CANCEL as typeof types.FETCH_VIDEO_QUESTIONS_IDS_CANCEL,
  payload,
})

export const fetchPreAskedSimilarQuestions = (payload: string) => ({
  type: types.FETCH_PRE_ASKED_SIMILAR_QUESTIONS as typeof types.FETCH_PRE_ASKED_SIMILAR_QUESTIONS,
  payload,
})

export const fetchPreAskedSimilarQuestionsSuccess = (payload: any) => ({
  type: types.FETCH_PRE_ASKED_SIMILAR_QUESTIONS_SUCCESS as typeof types.FETCH_PRE_ASKED_SIMILAR_QUESTIONS_SUCCESS,
  payload,
})

export const fetchPreAskedSimilarQuestionsFailure = (payload: Error) => ({
  type: types.FETCH_PRE_ASKED_SIMILAR_QUESTIONS_FAILURE as typeof types.FETCH_PRE_ASKED_SIMILAR_QUESTIONS_FAILURE,
  payload,
})

export const fetchPreAskedSimilarQuestionsCancel = (payload: any) => ({
  type: types.FETCH_PRE_ASKED_SIMILAR_QUESTIONS_CANCEL as typeof types.FETCH_PRE_ASKED_SIMILAR_QUESTIONS_CANCEL,
  payload,
})

export const addNewTopicToQuestion = (payload: string) => ({
  type: types.ADD_NEW_TOPIC_TO_QUESTION as typeof types.ADD_NEW_TOPIC_TO_QUESTION,
  payload,
})

export const addNewTopicToQuestionSuccess = (payload: any) => ({
  type: types.ADD_NEW_TOPIC_TO_QUESTION_SUCCESS as typeof types.ADD_NEW_TOPIC_TO_QUESTION_SUCCESS,
  payload,
})

export const addNewTopicToQuestionFailure = (payload: Error) => ({
  type: types.ADD_NEW_TOPIC_TO_QUESTION_FAILURE as typeof types.ADD_NEW_TOPIC_TO_QUESTION_FAILURE,
  payload,
})

export const addNewTopicToQuestionCancel = (payload: any) => ({
  type: types.ADD_NEW_TOPIC_TO_QUESTION_CANCEL as typeof types.ADD_NEW_TOPIC_TO_QUESTION_CANCEL,
  payload,
})

export const resetAddNewTopicToQuestion = () => ({
  type: types.RESET_ADD_NEW_TOPIC_TO_QUESTION as typeof types.RESET_ADD_NEW_TOPIC_TO_QUESTION,
})

export const fetchTrendingQuestions = () => ({
  type: types.FETCH_TRENDING_QUESTIONS as typeof types.FETCH_TRENDING_QUESTIONS,
})

export const fetchTrendingQuestionsSuccess = (payload: any) => ({
  type: types.FETCH_TRENDING_QUESTIONS_SUCCESS as typeof types.FETCH_TRENDING_QUESTIONS_SUCCESS,
  payload,
})

export const fetchTrendingQuestionsFailure = (payload: Error) => ({
  type: types.FETCH_TRENDING_QUESTIONS_FAILURE as typeof types.FETCH_TRENDING_QUESTIONS_FAILURE,
  payload,
})

export const fetchTrendingQuestionsCancel = (payload: any) => ({
  type: types.FETCH_TRENDING_QUESTIONS_CANCEL as typeof types.FETCH_TRENDING_QUESTIONS_CANCEL,
  payload,
})

export const fetchTrendingArticles = () => ({
  type: types.FETCH_TRENDING_ARTICLES as typeof types.FETCH_TRENDING_ARTICLES,
})

export const fetchTrendingArticlesSuccess = (payload: any) => ({
  type: types.FETCH_TRENDING_ARTICLES_SUCCESS as typeof types.FETCH_TRENDING_ARTICLES_SUCCESS,
  payload,
})

export const fetchTrendingArticlesFailure = (payload: Error) => ({
  type: types.FETCH_TRENDING_ARTICLES_FAILURE as typeof types.FETCH_TRENDING_ARTICLES_FAILURE,
  payload,
})

export const fetchTrendingArticlesCancel = (payload: any) => ({
  type: types.FETCH_TRENDING_ARTICLES_CANCEL as typeof types.FETCH_TRENDING_ARTICLES_CANCEL,
  payload,
})

export const fetchGroupFeeds = (payload: any = {}) => ({
  type: types.FETCH_GROUP_FEEDS as typeof types.FETCH_GROUP_FEEDS,
  payload,
})

export const fetchGroupFeedsSuccess = (payload: any) => ({
  type: types.FETCH_GROUP_FEEDS_SUCCESS as typeof types.FETCH_GROUP_FEEDS_SUCCESS,
  payload,
})

export const fetchGroupFeedsFailure = (payload: Error) => ({
  type: types.FETCH_GROUP_FEEDS_FAILURE as typeof types.FETCH_GROUP_FEEDS_FAILURE,
  payload,
})

export const fetchGroupFeedsCancel = (payload: any) => ({
  type: types.FETCH_GROUP_FEEDS_CANCEL as typeof types.FETCH_GROUP_FEEDS_CANCEL,
  payload,
})

export const fetchGroupDetails = () => ({
  type: types.FETCH_GROUP_DETAILS as typeof types.FETCH_GROUP_DETAILS,
})

export const fetchGroupDetailsSuccess = (payload: any) => ({
  type: types.FETCH_GROUP_DETAILS_SUCCESS as typeof types.FETCH_GROUP_DETAILS_SUCCESS,
  payload,
})

export const fetchGroupDetailsFailure = (payload: Error) => ({
  type: types.FETCH_GROUP_DETAILS_FAILURE as typeof types.FETCH_GROUP_DETAILS_FAILURE,
  payload,
})

export const fetchGroupDetailsCancel = (payload: any) => ({
  type: types.FETCH_GROUP_DETAILS_CANCEL as typeof types.FETCH_GROUP_DETAILS_CANCEL,
  payload,
})

export const fetchAlumniGroupMembers = () => ({
  type: types.FETCH_ALUMNI_GROUP_MEMBERS as typeof types.FETCH_ALUMNI_GROUP_MEMBERS,
})

export const fetchAlumniGroupMembersSuccess = (payload: any) => ({
  type: types.FETCH_ALUMNI_GROUP_MEMBERS_SUCCESS as typeof types.FETCH_ALUMNI_GROUP_MEMBERS_SUCCESS,
  payload,
})

export const fetchAlumniGroupMembersFailure = (payload: Error) => ({
  type: types.FETCH_ALUMNI_GROUP_MEMBERS_FAILURE as typeof types.FETCH_ALUMNI_GROUP_MEMBERS_FAILURE,
  payload,
})

export const fetchAlumniGroupMembersCancel = (payload: any) => ({
  type: types.FETCH_ALUMNI_GROUP_MEMBERS_CANCEL as typeof types.FETCH_ALUMNI_GROUP_MEMBERS_CANCEL,
  payload,
})

export const insertNewQuestionIntoFeeds = (payload: any) => ({
  type: types.INSERT_NEW_QUESTION_INTO_FEEDS as typeof types.INSERT_NEW_QUESTION_INTO_FEEDS,
  payload,
})

export type CommunityTypes =
  | ReturnType<typeof fetchSimilarQuestions>
  | ReturnType<typeof fetchSimilarQuestionsSuccess>
  | ReturnType<typeof fetchSimilarQuestionsFailure>
  | ReturnType<typeof fetchSimilarQuestionsCancel>
  | ReturnType<typeof fetchNotifications>
  | ReturnType<typeof fetchNotificationsSuccess>
  | ReturnType<typeof fetchNotificationsFailure>
  | ReturnType<typeof fetchNotificationsCancel>
  | ReturnType<typeof fetchTopics>
  | ReturnType<typeof fetchTopicsSuccess>
  | ReturnType<typeof fetchTopicsFailure>
  | ReturnType<typeof fetchTopicsCancel>
  | ReturnType<typeof postQuestion>
  | ReturnType<typeof postQuestionSuccess>
  | ReturnType<typeof postQuestionFailure>
  | ReturnType<typeof postQuestionCancel>
  | ReturnType<typeof postAnswer>
  | ReturnType<typeof postAnswerSuccess>
  | ReturnType<typeof postAnswerFailure>
  | ReturnType<typeof postAnswerCancel>
  | ReturnType<typeof deleteQuestion>
  | ReturnType<typeof deleteQuestionSuccess>
  | ReturnType<typeof deleteQuestionFailure>
  | ReturnType<typeof deleteQuestionCancel>
  | ReturnType<typeof deleteAnswer>
  | ReturnType<typeof deleteAnswerSuccess>
  | ReturnType<typeof deleteAnswerFailure>
  | ReturnType<typeof deleteAnswerCancel>
  | ReturnType<typeof updateQuestion>
  | ReturnType<typeof updateQuestionSuccess>
  | ReturnType<typeof updateQuestionFailure>
  | ReturnType<typeof updateQuestionCancel>
  | ReturnType<typeof updateAnswer>
  | ReturnType<typeof updateAnswerSuccess>
  | ReturnType<typeof updateAnswerFailure>
  | ReturnType<typeof updateAnswerCancel>
  | ReturnType<typeof fetchNewQuestions>
  | ReturnType<typeof fetchNewQuestionsSuccess>
  | ReturnType<typeof fetchNewQuestionsFailure>
  | ReturnType<typeof fetchNewQuestionsCancel>
  | ReturnType<typeof updateCommunityJwtTokenSuccess>
  | ReturnType<typeof renewCommunityJwtToken>
  | ReturnType<typeof renewCommunityJwtTokenCancel>
  | ReturnType<typeof renewCommunityJwtTokenFailure>
  | ReturnType<typeof fetchUserQuestions>
  | ReturnType<typeof fetchUserQuestionsSuccess>
  | ReturnType<typeof fetchUserQuestionsFailure>
  | ReturnType<typeof fetchUserQuestionsCancel>
  | ReturnType<typeof fetchUserDetails>
  | ReturnType<typeof fetchUserDetailsSuccess>
  | ReturnType<typeof fetchUserDetailsFailure>
  | ReturnType<typeof fetchUserDetailsCancel>
  | ReturnType<typeof postQuestionUnderVideo>
  | ReturnType<typeof postQuestionUnderVideoSuccess>
  | ReturnType<typeof postQuestionUnderVideoFailure>
  | ReturnType<typeof postQuestionUnderVideoCancel>
  | ReturnType<typeof fetchVideoQuestionIds>
  | ReturnType<typeof fetchVideoQuestionIdsSuccess>
  | ReturnType<typeof fetchVideoQuestionIdsFailure>
  | ReturnType<typeof fetchVideoQuestionIdsCancel>
  | ReturnType<typeof fetchPreAskedSimilarQuestions>
  | ReturnType<typeof fetchPreAskedSimilarQuestionsSuccess>
  | ReturnType<typeof fetchPreAskedSimilarQuestionsFailure>
  | ReturnType<typeof fetchPreAskedSimilarQuestionsCancel>
  | ReturnType<typeof addNewTopicToQuestion>
  | ReturnType<typeof addNewTopicToQuestionSuccess>
  | ReturnType<typeof addNewTopicToQuestionFailure>
  | ReturnType<typeof addNewTopicToQuestionCancel>
  | ReturnType<typeof resetAddNewTopicToQuestion>
  | ReturnType<typeof fetchTrendingQuestions>
  | ReturnType<typeof fetchTrendingQuestionsSuccess>
  | ReturnType<typeof fetchTrendingQuestionsFailure>
  | ReturnType<typeof fetchTrendingQuestionsCancel>
  | ReturnType<typeof fetchTrendingArticles>
  | ReturnType<typeof fetchTrendingArticlesSuccess>
  | ReturnType<typeof fetchTrendingArticlesFailure>
  | ReturnType<typeof fetchTrendingArticlesCancel>
  | ReturnType<typeof fetchGroupFeeds>
  | ReturnType<typeof fetchGroupFeedsSuccess>
  | ReturnType<typeof fetchGroupFeedsFailure>
  | ReturnType<typeof fetchGroupFeedsCancel>
  | ReturnType<typeof fetchGroupDetails>
  | ReturnType<typeof fetchGroupDetailsSuccess>
  | ReturnType<typeof fetchGroupDetailsFailure>
  | ReturnType<typeof fetchGroupDetailsCancel>
  | ReturnType<typeof fetchAlumniGroupMembers>
  | ReturnType<typeof fetchAlumniGroupMembersSuccess>
  | ReturnType<typeof fetchAlumniGroupMembersFailure>
  | ReturnType<typeof fetchAlumniGroupMembersCancel>
  | ReturnType<typeof insertNewQuestionIntoFeeds>
