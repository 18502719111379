import React, { useEffect, useRef } from 'react'
import { Snackbar, SnackbarContent } from '@material-ui/core'
import { SnackbarProps } from '@material-ui/core/Snackbar'
import styles from './SnackbarStrip.module.css'

interface SnackbarStripProps extends SnackbarProps {}

const SnackbarStrip = (props: SnackbarStripProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    return () => {
      const root = document.documentElement
      root.style.setProperty('--snackbar-height', '0px')
    }
  }, [])

  useEffect(() => {
    const root = document.documentElement
    const snackbarDiv = ref.current
    if (snackbarDiv) {
      if (props.open) {
        root.style.setProperty(
          '--snackbar-height',
          `${snackbarDiv.getBoundingClientRect().height +
            Number.parseInt(
              getComputedStyle(document.documentElement).getPropertyValue(
                '--snackbar-height'
              ),
              10
            )}px`
        )
      } else
        root.style.setProperty(
          '--snackbar-height',
          `${Number.parseInt(
            getComputedStyle(document.documentElement).getPropertyValue(
              '--snackbar-height'
            ),
            10
          ) - snackbarDiv.getBoundingClientRect().height}px`
        )
    }
  }, [props.open])

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={props.open}
      classes={{ root: styles.container }}
      className={props.className}
      ref={ref}
    >
    <SnackbarContent
        elevation={1}
        classes={{ root: styles.content, message: styles.message }}
        className={props.className}
        message={props.message}
        action={props.action}
      />
    </Snackbar>
  )
}
export default SnackbarStrip
