import {
  call,
  cancelled,
  put,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { cancelable } from '../../../../common/utils'
import { showAlertMessage } from '../../AlertsProvider'
import { batchRemoveFile, batchUploadFile } from '../../FilesProvider'
import {
  BATCH_UPLOAD_FILE_FAILURE,
  BATCH_UPLOAD_FILE_SUCCESS,
} from '../../FilesProvider/Files.types'
import {
  deleteGroupFile,
  deleteGroupFileFailure,
  deleteGroupFileSuccess,
  fetchGroupFiles,
  fetchGroupFilesFailure,
  fetchGroupFilesQuota,
  fetchGroupFilesQuotaFailure,
  fetchGroupFilesQuotaSuccess,
  fetchGroupFilesSuccess,
  fetchGroupMembers,
  fetchGroupMembersFailure,
  fetchGroupMembersSuccess,
  fetchUserCourseGroups,
  fetchUserCourseGroupsFailure,
  fetchUserCourseGroupsSuccess,
  fetchUserGroupsFailure,
  fetchUserGroupsSuccess,
  uploadGroupFiles,
  uploadGroupFilesFailure,
  uploadGroupFilesSuccess,
} from './UserGroups.actions'
import {
  deleteGroupFileHandlerAPI,
  fetchGroupFilesAPI,
  fetchGroupFilesQuotaAPI,
  fetchGroupMembersAPI,
  fetchUserCourseGroupsAPI,
  fetchUserGroupsAPI,
} from './UserGroups.api'
import {
  DELETE_GROUP_FILE,
  DELETE_GROUP_FILE_CANCEL,
  FETCH_GROUP_FILES,
  FETCH_GROUP_FILES_CANCEL,
  FETCH_GROUP_FILES_QUOTA,
  FETCH_GROUP_FILES_QUOTA_CANCEL,
  FETCH_GROUP_MEMBERS,
  FETCH_GROUP_MEMBERS_CANCEL,
  FETCH_USER_COURSE_GROUPS,
  FETCH_USER_COURSE_GROUPS_CANCEL,
  FETCH_USER_GROUPS,
  FETCH_USER_GROUPS_CANCEL,
  UPLOAD_GROUP_FILES,
} from './UserGroups.types'

function* fetchUserGroupsHandler() {
  const abortController = new AbortController()
  try {
    const data = yield call(fetchUserGroupsAPI, abortController.signal)
    yield put(fetchUserGroupsSuccess(data))
  } catch (e) {
    yield put(fetchUserGroupsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchUserCourseGroupsHandler(
  action: ReturnType<typeof fetchUserCourseGroups>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      fetchUserCourseGroupsAPI,
      action,
      abortController.signal
    )
    yield put(
      fetchUserCourseGroupsSuccess(data, { courseId: action.payload.courseId })
    )

    if (action.meta.groupCategoryId && data) {
      const groupAvailable: any = data.filter(
        (group: any) => group.group_category_id === action.meta.groupCategoryId
      )
      if (groupAvailable.length) {
        yield put(
          fetchGroupMembers(
            { groupId: groupAvailable[0].id },
            { groupId: groupAvailable[0].id }
          )
        )
      }
    }
  } catch (e) {
    yield put(
      fetchUserCourseGroupsFailure(e, { courseId: action.payload.courseId })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchGroupMembersHandler(
  action: ReturnType<typeof fetchGroupMembers>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      fetchGroupMembersAPI,
      action,
      abortController.signal
    )
    yield put(fetchGroupMembersSuccess(data, action.meta))
  } catch (e) {
    yield put(fetchGroupMembersFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchGroupFilesHandler(action: ReturnType<typeof fetchGroupFiles>) {
  const abortController = new AbortController()
  try {
    const data = yield call(fetchGroupFilesAPI, action, abortController.signal)
    yield put(
      fetchGroupFilesSuccess(
        { data, hasMoreData: !!data.length, page: action.payload.page },
        action.meta
      )
    )
  } catch (e) {
    yield put(fetchGroupFilesFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
function* fetchGroupFilesQuotaHandler(
  action: ReturnType<typeof fetchGroupFilesQuota>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      fetchGroupFilesQuotaAPI,
      action,
      abortController.signal
    )
    yield put(fetchGroupFilesQuotaSuccess(data, action.meta))
  } catch (e) {
    yield put(fetchGroupFilesQuotaFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
function* deleteGroupFileHandler(action: ReturnType<typeof deleteGroupFile>) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      deleteGroupFileHandlerAPI,
      action,
      abortController.signal
    )
    yield put(
      deleteGroupFileSuccess(data, {
        groupId: action.meta.groupId,
        fileId: action.payload.fileId,
      })
    )
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'File deleted successfully',
      })
    )
  } catch (e) {
    yield put(deleteGroupFileFailure(e, action.meta))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'File delete failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* uploadGroupFilesGroupsHandler(
  action: ReturnType<typeof uploadGroupFiles>
) {
  const abortController = new AbortController()
  try {
    yield put(batchUploadFile(action.payload))
    yield take(BATCH_UPLOAD_FILE_SUCCESS)
    yield put(uploadGroupFilesSuccess({}, action.meta))
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'Files uploaded successfully',
      })
    )
    yield put(batchRemoveFile(action.payload))
    yield put(
      fetchGroupFiles(
        { groupId: action.payload.groupId, page: 1 },
        { groupId: action.payload.groupId }
      )
    )
  } catch (e) {
    yield put(uploadGroupFilesFailure(e, action.meta))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Files upload failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fetchGroupMembersMiddleware() {
  yield takeEvery(
    FETCH_GROUP_MEMBERS,
    cancelable(fetchGroupMembersHandler, FETCH_GROUP_MEMBERS_CANCEL)
  )
}
export function* fetchGroupFilesMiddleware() {
  yield takeEvery(
    FETCH_GROUP_FILES,
    cancelable(fetchGroupFilesHandler, FETCH_GROUP_FILES_CANCEL)
  )
}
export function* fetchUserGroupsMiddleware() {
  yield takeLatest(
    FETCH_USER_GROUPS,
    cancelable(fetchUserGroupsHandler, FETCH_USER_GROUPS_CANCEL)
  )
}
export function* fetchUserCourseGroupsMiddleware() {
  yield takeLatest(
    FETCH_USER_COURSE_GROUPS,
    cancelable(fetchUserCourseGroupsHandler, FETCH_USER_COURSE_GROUPS_CANCEL)
  )
}
export function* deleteGroupFileMiddleware() {
  yield takeLatest(
    DELETE_GROUP_FILE,
    cancelable(deleteGroupFileHandler, DELETE_GROUP_FILE_CANCEL)
  )
}
export function* fetchGroupFilesQuotaMiddleware() {
  yield takeLatest(
    FETCH_GROUP_FILES_QUOTA,
    cancelable(fetchGroupFilesQuotaHandler, FETCH_GROUP_FILES_QUOTA_CANCEL)
  )
}

export function* uploadGroupFilesMiddleware() {
  yield takeLatest(
    UPLOAD_GROUP_FILES,
    cancelable(uploadGroupFilesGroupsHandler, [
      FETCH_USER_COURSE_GROUPS_CANCEL,
      BATCH_UPLOAD_FILE_FAILURE,
    ])
  )
}

export default ([] as any).concat(
  fetchUserGroupsMiddleware(),
  fetchGroupMembersMiddleware(),
  fetchUserCourseGroupsMiddleware(),
  fetchGroupFilesMiddleware(),
  uploadGroupFilesMiddleware(),
  fetchGroupFilesQuotaMiddleware(),
  deleteGroupFileMiddleware()
)
