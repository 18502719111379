import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { DashboardState } from 'web/providers/Dashboard/DashboardProvider'
import BrowseCoursesMenuComponent from '../../../components/Header/BrowseCoursesMenu'
import { UserDetailsState } from '../../../providers/User/UserDetailsProvider'
import { AppState } from '../../../store'
import { CoursesState } from '../../../providers/Courses/CoursesProvider'
import AsyncDOM from '../../../components/Utils/AsyncDOM'
import {
  AllProgramsState,
  fetchAllPrograms,
} from '../../../providers/AllProgramsProvider/index'
import {
  isAcademyUser,
  isAcademyInternationalUser,
  isFreeUser,
} from '../../../../common/utils/custom/user'

interface BrowseCoursesMenuProps {
  courses: CoursesState
  userDetails: UserDetailsState
  isDrawer: boolean
  dashboard: DashboardState
  allPrograms: AllProgramsState
}

interface DispatchProps {
  fetchAllPrograms: typeof fetchAllPrograms
}
type Props = BrowseCoursesMenuProps & DispatchProps

const BrowseCoursesMenu = (props: Props) => {
  useEffect(() => {
    if (
      (isFreeUser(props.userDetails.accessType) ||
        isAcademyInternationalUser(props.userDetails.accessType)) &&
      props.dashboard.data &&
      'is_user_info_collected' in props.dashboard.data &&
      props.dashboard.data.is_user_info_collected &&
      !props.allPrograms.loading &&
      !props.allPrograms.loaded
    ) {
      props.fetchAllPrograms()
    }
  }, [])

  return isAcademyUser(props.userDetails.accessType) ||
    isAcademyInternationalUser(props.userDetails.accessType) ? (
    <AsyncDOM
      data={props.dashboard.data && props.courses.data.hasAllCourses}
      loading={!!(props.courses.loading || props.dashboard.loading)}
      error={!!(props.courses.error || props.dashboard.error)}
    >
      <AsyncDOM.Content>
        {props.dashboard.data && props.courses.data ? (
          <BrowseCoursesMenuComponent
            userAccessType={props.userDetails.accessType}
            coursesData={props.courses.data}
            isDrawer={props.isDrawer}
            allPrograms={props.allPrograms.data}
          />
        ) : null}
      </AsyncDOM.Content>
    </AsyncDOM>
  ) : null
}

BrowseCoursesMenu.defaultProps = {
  isDrawer: false,
}

const mapStateToProps = (state: AppState) => ({
  courses: state.courses,
  userDetails: state.user.details,
  dashboard: state.dashboard,
  allPrograms: state.allPrograms,
})

export default connect(mapStateToProps, { fetchAllPrograms })(BrowseCoursesMenu)
