import {
  FETCH_LEARNERS_ENGAGEMENT_SCORE,
  FETCH_LEARNERS_ENGAGEMENT_SCORE_SUCCESS,
  FETCH_LEARNERS_ENGAGEMENT_SCORE_FAILURE,
} from './Engagement.types'

export interface fetchLearnersEngagementScoreParams {
  mentoredGroupSessionId: number
}

export const fetchLearnersEngagementScore = () => ({
  type: FETCH_LEARNERS_ENGAGEMENT_SCORE as typeof FETCH_LEARNERS_ENGAGEMENT_SCORE,
})

export const fetchLearnersEngagementScoreSuccess = (payload: any) => ({
  type: FETCH_LEARNERS_ENGAGEMENT_SCORE_SUCCESS as typeof FETCH_LEARNERS_ENGAGEMENT_SCORE_SUCCESS,
  payload,
})

export const fetchLearnersEngagementScoreFailure = (payload: any) => ({
  type: FETCH_LEARNERS_ENGAGEMENT_SCORE_FAILURE as typeof FETCH_LEARNERS_ENGAGEMENT_SCORE_FAILURE,
  payload,
})

export type FetchLearnersEngagementScore =
  | ReturnType<typeof fetchLearnersEngagementScore>
  | ReturnType<typeof fetchLearnersEngagementScoreSuccess>
  | ReturnType<typeof fetchLearnersEngagementScoreFailure>
