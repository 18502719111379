import React from 'react'
import { IconProps } from '@material-ui/core/Icon'
import cx from 'classnames'
import { ReactComponent as LinkinInBox } from 'common/images/icons/social/linkedin_box.svg'
import { ReactComponent as Skills } from 'common/images/Eportfolio/icons/skills.svg'
import { ReactComponent as SkillsEmpty } from 'common/images/Eportfolio/icons/skills_empty.svg'
import { ReactComponent as CapstoneProjectEmpty } from 'common/images/Eportfolio/icons/capstone_project_empty.svg'
import { ReactComponent as CourseProject } from 'common/images/Eportfolio/icons/course_project.svg'
import { ReactComponent as CourseProjectEmpty } from 'common/images/Eportfolio/icons/course_project_empty.svg'
import { ReactComponent as SuccessPhone } from '../../../../common/images/icons/phone.svg'
import styles from './Icons.module.css'
import { ReactComponent as Done } from '../../../../common/images/icons/completed.svg'
import { ReactComponent as PDF } from '../../../../common/images/icons/pdf.svg'
import { ReactComponent as Image } from '../../../../common/images/icons/imageFile.svg'
import { ReactComponent as Attendance } from '../../../../common/images/icons/attendance.svg'
import { ReactComponent as RightArrow } from '../../../../common/images/icons/RightArrow.svg'
import { ReactComponent as Article } from '../../../../common/images/icons/article.svg'
import { ReactComponent as OtherItems } from '../../../../common/images/icons/otherItems.svg'
import { ReactComponent as ZIPFile } from '../../../../common/images/icons/zipFile.svg'
import { ReactComponent as WordFile } from '../../../../common/images/icons/wordFile.svg'
import { ReactComponent as PPTFile } from '../../../../common/images/icons/pptFile.svg'
import { ReactComponent as JSONFile } from '../../../../common/images/icons/jsonFile.svg'
import { ReactComponent as PythonFile } from '../../../../common/images/icons/pythonFile.svg'
import { ReactComponent as SQLFile } from '../../../../common/images/icons/sqlFile.svg'
import { ReactComponent as VideoFile } from '../../../../common/images/icons/videoFile.svg'
import { ReactComponent as CSVFile } from '../../../../common/images/icons/csvFile.svg'
import { ReactComponent as CSSFile } from '../../../../common/images/icons/cssFile.svg'
import { ReactComponent as HTMLFile } from '../../../../common/images/icons/htmlFile.svg'
import { ReactComponent as JAVAFile } from '../../../../common/images/icons/javaFile.svg'
import { ReactComponent as EXCELFile } from '../../../../common/images/icons/excelFile.svg'
import { ReactComponent as ShellFile } from '../../../../common/images/icons/shellFile.svg'
import { ReactComponent as Support } from '../../../../common/images/icons/support.svg'
import { ReactComponent as IndustryArticles } from '../../../../common/images/icons/industryArticles.svg'
import { ReactComponent as Gift } from '../../../../common/images/icons/gift.svg'
import { ReactComponent as RatingStarBorder } from '../../../../common/images/ratingStarBorder.svg'
import { ReactComponent as RatingStarFilled } from '../../../../common/images/ratingStarFilled.svg'
import { ReactComponent as CloseDrawer } from '../../../../common/images/icons/angleDoubleRight.svg'
import { ReactComponent as Announcement } from '../../../../common/images/icons/announcement.svg'
import { ReactComponent as AnnouncementWhite } from '../../../../common/images/icons/announcementWhite.svg'
import { ReactComponent as Group } from '../../../../common/images/group.svg'
import { ReactComponent as Preferences } from '../../../../common/images/preferences.svg'
import { ReactComponent as PersonalInfo } from '../../../../common/images/personalInfo.svg'
import { ReactComponent as PlacementPolicy } from '../../../../common/images/placementPolicy.svg'
import { ReactComponent as Feedback } from '../../../../common/images/feedback.svg'
import { ReactComponent as PageNotFound } from '../../../../common/images/errorIllustrations/404Page.svg'
import { ReactComponent as Unauthorised } from '../../../../common/images/errorIllustrations/401Page.svg'
import { ReactComponent as ServerError } from '../../../../common/images/errorIllustrations/500Page.svg'
import { ReactComponent as ErrorPage } from '../../../../common/images/errorIllustrations/errorPage.svg'
import { ReactComponent as Move } from '../../../../common/images/icons/move.svg'
import { ReactComponent as Chrome } from '../../../../common/images/icons/browsers/chrome.svg'
import { ReactComponent as Firefox } from '../../../../common/images/icons/browsers/firefox.svg'
import { ReactComponent as Edge } from '../../../../common/images/icons/browsers/edge.svg'
import { ReactComponent as Safari } from '../../../../common/images/icons/browsers/safari.svg'
import { ReactComponent as Leaderboard } from '../../../../common/images/icons/leaderboard/leaderboard.svg'
import { ReactComponent as Rank1 } from '../../../../common/images/icons/leaderboard/rank1.svg'
import { ReactComponent as Rank2 } from '../../../../common/images/icons/leaderboard/rank2.svg'
import { ReactComponent as Rank3 } from '../../../../common/images/icons/leaderboard/rank3.svg'
import { ReactComponent as Rank4 } from '../../../../common/images/icons/leaderboard/rank4.svg'
import { ReactComponent as Rank5 } from '../../../../common/images/icons/leaderboard/rank5.svg'
import { ReactComponent as Rank1Filled } from '../../../../common/images/icons/leaderboard/rank1Filled.svg'
import { ReactComponent as Rank2Filled } from '../../../../common/images/icons/leaderboard/rank2Filled.svg'
import { ReactComponent as Rank3Filled } from '../../../../common/images/icons/leaderboard/rank3Filled.svg'
import { ReactComponent as Rank4Filled } from '../../../../common/images/icons/leaderboard/rank4Filled.svg'
import { ReactComponent as Rank5Filled } from '../../../../common/images/icons/leaderboard/rank5Filled.svg'
import { ReactComponent as Badge } from '../../../../common/images/icons/leaderboard/badge.svg'
import { ReactComponent as BadgeColored } from '../../../../common/images/icons/leaderboard/badgeColored.svg'
import { ReactComponent as NoInternet } from '../../../../common/images/errorIllustrations/noInternet.svg'
import { ReactComponent as List } from '../../../../common/images/icons/list.svg'
import { ReactComponent as WelcomeBanner } from '../../../../common/images/welcomeBanner.svg'
import { ReactComponent as WelcomeBannerCorporate } from '../../../../common/images/welcomeBannerCorporate.svg'
import { ReactComponent as InductionPrimary } from '../../../../common/images/inductionPrimary.svg'
import { ReactComponent as InductionSecondary } from '../../../../common/images/inductionSecondary.svg'
import { ReactComponent as LoudSpeaker } from '../../../../common/images/icons/loudSpeaker.svg'
import { ReactComponent as Calendar } from '../../../../common/images/icons/calendar.svg'
import { ReactComponent as Certificate } from '../../../../common/images/icons/certificate.svg'
import { ReactComponent as VimeoFile } from '../../../../common/images/icons/vimeoFile.svg'
import { ReactComponent as Facebook } from '../../../../common/images/icons/facebook.svg'
import { ReactComponent as Linkedin } from '../../../../common/images/icons/social/linkedin.svg'
import { ReactComponent as Quote } from '../../../../common/images/icons/quote.svg'
import { ReactComponent as AcademyPremium } from '../../../../common/images/academy_premium.svg'
import { ReactComponent as FacebookNew } from '../../../../common/images/facebooknew.svg'
import { ReactComponent as LinkinInNew } from '../../../../common/images/icons/social/linkedinnew.svg'
import { ReactComponent as LinkedinImage } from '../../../../common/images/icons/social/linkedinImage.svg'
import { ReactComponent as SuccessTick } from '../../../../common/images/icons/social/successTick.svg'
import { ReactComponent as Whatsapp } from '../../../../common/images/whatsappnew.svg'
import { ReactComponent as Twitter } from '../../../../common/images/icons/social/twitter.svg'
import { ReactComponent as Telegram } from '../../../../common/images/telegram.svg'

import { ReactComponent as ChristmasRefferal } from '../../../../common/images/icons/christmas_refferal.svg'
import { ReactComponent as NewYearRefferal } from '../../../../common/images/icons/newyear_refferal.svg'
import { ReactComponent as ContentCopy } from '../../../../common/images/icons/contentCopy.svg'
// for Eportfolio

interface Props extends IconProps {}

export interface SVGIconProps extends React.SVGProps<SVGSVGElement> {}

export const DoneIcon = ({ className }: Props) => <Done className={className} />
export const AnnouncementIcon = ({ className }: Props) => (
  <Announcement className={className} />
)
export const AnnouncementWhiteIcon = ({ className }: Props) => (
  <AnnouncementWhite className={className} />
)
export const PlacementPolicyIcon = ({ className }: Props) => (
  <PlacementPolicy className={className} />
)
export const PersonalInfoIcon = ({ className }: Props) => (
  <PersonalInfo className={className} />
)
export const PreferencesIcon = ({ className }: Props) => (
  <Preferences className={className} />
)
export const GroupIcon = ({ className }: Props) => (
  <Group className={className} />
)

export const GiftIcon = ({ className }: Props) => <Gift className={className} />

export const IndustryArticlesIcon = ({ className }: Props) => (
  <IndustryArticles className={className} />
)
export const SupportIcon = ({ className }: Props) => (
  <Support className={className} />
)

export const RatingStarBorderIcon = ({ className }: Props) => (
  <RatingStarBorder className={className} />
)
export const RatingStarFilledIcon = ({ className }: Props) => (
  <RatingStarFilled className={className} />
)

export const PdfIcon = ({ className }: Props) => <PDF className={className} />

export const EXCELIcon = ({ className }: Props) => (
  <EXCELFile className={className} />
)
export const ShellIcon = ({ className }: Props) => (
  <ShellFile className={className} />
)
export const JAVAIcon = ({ className }: Props) => (
  <JAVAFile className={className} />
)
export const HTMLIcon = ({ className }: Props) => (
  <HTMLFile className={className} />
)
export const CSSIcon = ({ className }: Props) => (
  <CSSFile className={className} />
)
export const PythonIcon = ({ className }: Props) => (
  <PythonFile className={className} />
)
export const JSONIcon = ({ className }: Props) => (
  <JSONFile className={className} />
)
export const VideoIcon = ({ className }: Props) => (
  <VideoFile className={className} />
)
export const LiveVideoIcon = ({ className }: Props) => (
  <VimeoFile className={className} />
)
export const SQLIcon = ({ className }: Props) => (
  <SQLFile className={className} />
)
export const CSVIcon = ({ className }: Props) => (
  <CSVFile className={className} />
)
export const PPTIcon = ({ className }: Props) => (
  <PPTFile className={className} />
)
export const WordIcon = ({ className }: Props) => (
  <WordFile className={className} />
)
export const ImageIcon = ({ className }: Props) => (
  <Image className={className} />
)
export const ZIPIcon = ({ className }: Props) => (
  <ZIPFile className={className} />
)
export const AttendanceIcon = ({ className }: Props) => (
  <Attendance className={className} />
)
export const RightArrowIcon = ({ className }: Props) => (
  <RightArrow className={className} />
)
export const ArticleIcon = (props: SVGIconProps) => <Article {...props} />

export const OtherItemsIcon = (props: SVGIconProps) => <OtherItems {...props} />

export const OpenDrawerIcon = ({ className }: Props) => (
  <CloseDrawer className={cx(styles.drawerIcon, styles.open, className)} />
)
export const CloseDrawerIcon = ({ className }: Props) => (
  <CloseDrawer className={cx(styles.drawerIcon, className)} />
)
export const FeedbackIcon = ({ className }: Props) => (
  <Feedback className={className} />
)
export const PageNotFoundIcon = ({ className }: Props) => (
  <PageNotFound className={className} />
)
export const UnauthorisedIcon = ({ className }: Props) => (
  <Unauthorised className={className} />
)
export const ServerErrorIcon = ({ className }: Props) => (
  <ServerError className={className} />
)
export const ErrorPageIcon = ({ className }: Props) => (
  <ErrorPage className={className} />
)
export const MoveIcon = ({ className }: Props) => <Move className={className} />

export const ChromeIcon = ({ className }: Props) => (
  <Chrome className={className} />
)
export const FirefoxIcon = ({ className }: Props) => (
  <Firefox className={className} />
)
export const EdgeIcon = ({ className }: Props) => <Edge className={className} />

export const SafariIcon = ({ className }: Props) => (
  <Safari className={className} />
)
export const LeaderboardIcon = ({ className }: Props) => (
  <Leaderboard className={className} />
)
export const Rank1Icon = ({ className }: Props) => (
  <Rank1 className={className} />
)
export const Rank2Icon = ({ className }: Props) => (
  <Rank2 className={className} />
)
export const Rank3Icon = ({ className }: Props) => (
  <Rank3 className={className} />
)
export const Rank4Icon = ({ className }: Props) => (
  <Rank4 className={className} />
)
export const Rank5Icon = ({ className }: Props) => (
  <Rank5 className={className} />
)
export const Rank1FilledIcon = ({ className }: Props) => (
  <Rank1Filled className={className} />
)
export const Rank2FilledIcon = ({ className }: Props) => (
  <Rank2Filled className={className} />
)
export const Rank3FilledIcon = ({ className }: Props) => (
  <Rank3Filled className={className} />
)
export const Rank4FilledIcon = ({ className }: Props) => (
  <Rank4Filled className={className} />
)
export const Rank5FilledIcon = ({ className }: Props) => (
  <Rank5Filled className={className} />
)
export const BadgeIcon = ({ className }: Props) => (
  <Badge className={className} />
)
export const BadgeColoredIcon = ({ className }: Props) => (
  <BadgeColored className={className} />
)
export const NoInternetIcon = ({ className }: Props) => (
  <NoInternet className={className} />
)
export const ListIcon = ({ className }: Props) => <List className={className} />
export const WelcomeBannerIcon = ({ className }: Props) => (
  <WelcomeBanner className={className} />
)
export const WelcomeBannerCorporateIcon = ({ className }: Props) => (
  <WelcomeBannerCorporate className={className} />
)
export const InductionPrimaryIcon = ({ className }: Props) => (
  <InductionPrimary className={className} />
)
export const InductionSecondaryIcon = ({ className }: Props) => (
  <InductionSecondary className={className} />
)
export const LoudSpeakerIcon = ({ className }: Props) => (
  <LoudSpeaker className={className} />
)
export const CalendarIcon = ({ className }: Props) => (
  <Calendar className={className} />
)
export const CertificateIcon = ({ className }: Props) => (
  <Certificate className={className} />
)
export const FacebookIcon = ({ className }: Props) => (
  <Facebook className={className} />
)
export const LinkedinIcon = ({ className }: Props) => (
  <Linkedin className={className} />
)
export const LinkinInBoxIcon = ({ className }: Props) => (
  <LinkinInBox className={className} />
)
export const QuoteIcon = ({ className }: Props) => (
  <Quote className={className} />
)
export const SuccessPhoneIcon = ({ className }: Props) => (
  <SuccessPhone className={className} />
)
export const FacebookNewIcon = ({ className }: Props) => (
  <FacebookNew className={className} />
)
export const LinkidinNewIcon = ({ className }: Props) => (
  <LinkinInNew className={className} />
)

export const LinkedinImageIcon = ({ className }: Props) => (
  <LinkedinImage className={className} />
)
export const SuccessTickIcon = ({ className }: Props) => (
  <SuccessTick className={className} />
)

export const WhatsAppIcon = ({ className }: Props) => (
  <Whatsapp className={className} />
)
export const TelegramIcon = ({ className }: Props) => (
  <Telegram className={className} />
)
export const TwitterIcon = ({ className }: Props) => (
  <Twitter className={className} />
)
export const AcademyPremiumIcon = ({ className }: Props) => (
  <AcademyPremium className={className} />
)
export const ChristmasRefferalIcon = ({ className }: Props) => (
  <ChristmasRefferal className={className} />
)
export const NewYearRefferalIcon = ({ className }: Props) => (
  <NewYearRefferal className={className} />
)
export const ContentCopyIcon = ({ className }: Props) => (
  <ContentCopy className={className} />
)
// For Eportfolio
export const SkillsIcon = ({ className }: Props) => (
  <Skills className={className} />
)
export const SkillsEmptyIcon = ({ className }: Props) => (
  <SkillsEmpty className={className} />
)
export const CapstoneProjectEmptyIcon = ({ className }: Props) => (
  <CapstoneProjectEmpty className={className} />
)
export const CourseProjectIcon = ({ className }: Props) => (
  <CourseProject className={className} />
)
export const CourseProjectEmptyIcon = ({ className }: Props) => (
  <CourseProjectEmpty className={className} />
)
