import { HackathonID } from 'common/types/hackathons'
import {
  FETCH_HACKATHON_LIST,
  FETCH_HACKATHON_LIST_FAILURE,
  FETCH_HACKATHON_LIST_SUCCESS,
  FETCH_HACKATHON_LIST_CANCEL,
  FETCH_HACKATHON_DETAILS,
  FETCH_HACKATHON_DETAILS_FAILURE,
  FETCH_HACKATHON_DETAILS_SUCCESS,
  FETCH_HACKATHON_DETAILS_CANCEL,
  REGISTER_HACKATHON_TEAM,
  REGISTER_HACKATHON_TEAM_FAILURE,
  REGISTER_HACKATHON_TEAM_SUCCESS,
  REGISTER_HACKATHON_TEAM_CANCEL,
  ADD_MEMBERS_HACKATHON_TEAM,
  ADD_MEMBERS_HACKATHON_TEAM_FAILURE,
  ADD_MEMBERS_HACKATHON_TEAM_SUCCESS,
  ADD_MEMBERS_HACKATHON_TEAM_CANCEL,
  SUBMIT_HACKATHON_SOLUTION,
  SUBMIT_HACKATHON_SOLUTION_FAILURE,
  SUBMIT_HACKATHON_SOLUTION_SUCCESS,
  SUBMIT_HACKATHON_SOLUTION_CANCEL,
  FETCH_HACKATHON_LEADERBOARD,
  FETCH_HACKATHON_LEADERBOARD_FAILURE,
  FETCH_HACKATHON_LEADERBOARD_SUCCESS,
  FETCH_HACKATHON_LEADERBOARD_CANCEL,
  FETCH_HACKATHON_TEAM_PERFORMANCE,
  FETCH_HACKATHON_TEAM_PERFORMANCE_FAILURE,
  FETCH_HACKATHON_TEAM_PERFORMANCE_SUCCESS,
  FETCH_HACKATHON_TEAM_PERFORMANCE_CANCEL,
} from './Hackathons.types'

interface HackathonPayload {
  hackathonId: HackathonID
}

interface RegisterHackathonPayload {
  name: string
  emails: string[]
}

interface AddMembersHackathonTeamPayload {
  id: string | number
  emails: string[]
}

interface SubmitHackathonSolutionPayload {
  team_id: number
  files: { submission_data: File; submission_code?: File }
}

interface HackathonTeamPerformancePayload {
  team_id: number
  hackathonId: HackathonID
}

export const fetchHackathonList = () => ({
  type: FETCH_HACKATHON_LIST as typeof FETCH_HACKATHON_LIST,
})

export const fetchHackathonListFailure = (payload: Error) => ({
  type: FETCH_HACKATHON_LIST_FAILURE as typeof FETCH_HACKATHON_LIST_FAILURE,
  payload,
})

export const fetchHackathonListSuccess = (payload: any) => ({
  type: FETCH_HACKATHON_LIST_SUCCESS as typeof FETCH_HACKATHON_LIST_SUCCESS,
  payload,
})

export const fetchHackathonListCancel = (payload: any) => ({
  type: FETCH_HACKATHON_LIST_CANCEL as typeof FETCH_HACKATHON_LIST_CANCEL,
  payload,
})

export const fetchHackathonDetails = (
  payload: HackathonPayload,
  meta: HackathonPayload
) => ({
  type: FETCH_HACKATHON_DETAILS as typeof FETCH_HACKATHON_DETAILS,
  payload,
  meta,
})

export const fetchHackathonDetailsFailure = (
  payload: Error,
  meta: HackathonPayload
) => ({
  type: FETCH_HACKATHON_DETAILS_FAILURE as typeof FETCH_HACKATHON_DETAILS_FAILURE,
  payload,
  meta,
})

export const fetchHackathonDetailsSuccess = (
  payload: any,
  meta: HackathonPayload
) => ({
  type: FETCH_HACKATHON_DETAILS_SUCCESS as typeof FETCH_HACKATHON_DETAILS_SUCCESS,
  payload,
  meta,
})

export const fetchHackathonDetailsCancel = (
  payload: any,
  meta: HackathonPayload
) => ({
  type: FETCH_HACKATHON_DETAILS_CANCEL as typeof FETCH_HACKATHON_DETAILS_CANCEL,
  payload,
  meta,
})

export const registerHackathonTeam = (
  payload: RegisterHackathonPayload,
  meta: HackathonPayload
) => ({
  type: REGISTER_HACKATHON_TEAM as typeof REGISTER_HACKATHON_TEAM,
  payload,
  meta,
})

export const registerHackathonTeamFailure = (
  payload: Error,
  meta: HackathonPayload
) => ({
  type: REGISTER_HACKATHON_TEAM_FAILURE as typeof REGISTER_HACKATHON_TEAM_FAILURE,
  payload,
  meta,
})

export const registerHackathonTeamSuccess = (
  payload: any,
  meta: HackathonPayload
) => ({
  type: REGISTER_HACKATHON_TEAM_SUCCESS as typeof REGISTER_HACKATHON_TEAM_SUCCESS,
  payload,
  meta,
})

export const registerHackathonTeamCancel = (
  payload: any,
  meta: HackathonPayload
) => ({
  type: REGISTER_HACKATHON_TEAM_CANCEL as typeof REGISTER_HACKATHON_TEAM_CANCEL,
  payload,
  meta,
})

export const addMembersHackathonTeam = (
  payload: AddMembersHackathonTeamPayload,
  meta: HackathonPayload
) => ({
  type: ADD_MEMBERS_HACKATHON_TEAM as typeof ADD_MEMBERS_HACKATHON_TEAM,
  payload,
  meta,
})

export const addMembersHackathonTeamFailure = (
  payload: Error,
  meta: HackathonPayload
) => ({
  type: ADD_MEMBERS_HACKATHON_TEAM_FAILURE as typeof ADD_MEMBERS_HACKATHON_TEAM_FAILURE,
  payload,
  meta,
})

export const addMembersHackathonTeamSuccess = (
  payload: any,
  meta: HackathonPayload
) => ({
  type: ADD_MEMBERS_HACKATHON_TEAM_SUCCESS as typeof ADD_MEMBERS_HACKATHON_TEAM_SUCCESS,
  payload,
  meta,
})

export const addMembersHackathonTeamCancel = (
  payload: any,
  meta: HackathonPayload
) => ({
  type: ADD_MEMBERS_HACKATHON_TEAM_CANCEL as typeof ADD_MEMBERS_HACKATHON_TEAM_CANCEL,
  payload,
  meta,
})

export const submitHackathonSolution = (
  payload: SubmitHackathonSolutionPayload,
  meta: HackathonPayload
) => ({
  type: SUBMIT_HACKATHON_SOLUTION as typeof SUBMIT_HACKATHON_SOLUTION,
  payload,
  meta,
})

export const submitHackathonSolutionFailure = (
  payload: Error,
  meta: HackathonPayload
) => ({
  type: SUBMIT_HACKATHON_SOLUTION_FAILURE as typeof SUBMIT_HACKATHON_SOLUTION_FAILURE,
  payload,
  meta,
})

export const submitHackathonSolutionSuccess = (
  payload: any,
  meta: HackathonPayload
) => ({
  type: SUBMIT_HACKATHON_SOLUTION_SUCCESS as typeof SUBMIT_HACKATHON_SOLUTION_SUCCESS,
  payload,
  meta,
})

export const submitHackathonSolutionCancel = (
  payload: any,
  meta: HackathonPayload
) => ({
  type: SUBMIT_HACKATHON_SOLUTION_CANCEL as typeof SUBMIT_HACKATHON_SOLUTION_CANCEL,
  payload,
  meta,
})

export const fetchHackathonLeaderboard = (
  payload: HackathonPayload,
  meta: HackathonPayload
) => ({
  type: FETCH_HACKATHON_LEADERBOARD as typeof FETCH_HACKATHON_LEADERBOARD,
  payload,
  meta,
})

export const fetchHackathonLeaderboardFailure = (
  payload: Error,
  meta: HackathonPayload
) => ({
  type: FETCH_HACKATHON_LEADERBOARD_FAILURE as typeof FETCH_HACKATHON_LEADERBOARD_FAILURE,
  payload,
  meta,
})

export const fetchHackathonLeaderboardSuccess = (
  payload: any,
  meta: HackathonPayload
) => ({
  type: FETCH_HACKATHON_LEADERBOARD_SUCCESS as typeof FETCH_HACKATHON_LEADERBOARD_SUCCESS,
  payload,
  meta,
})

export const fetchHackathonLeaderboardCancel = (
  payload: any,
  meta: HackathonPayload
) => ({
  type: FETCH_HACKATHON_LEADERBOARD_CANCEL as typeof FETCH_HACKATHON_LEADERBOARD_CANCEL,
  payload,
  meta,
})

export const fetchHackathonTeamPerformance = (
  payload: HackathonTeamPerformancePayload,
  meta: HackathonPayload
) => ({
  type: FETCH_HACKATHON_TEAM_PERFORMANCE as typeof FETCH_HACKATHON_TEAM_PERFORMANCE,
  payload,
  meta,
})

export const fetchHackathonTeamPerformanceFailure = (
  payload: Error,
  meta: HackathonPayload
) => ({
  type: FETCH_HACKATHON_TEAM_PERFORMANCE_FAILURE as typeof FETCH_HACKATHON_TEAM_PERFORMANCE_FAILURE,
  payload,
  meta,
})

export const fetchHackathonTeamPerformanceSuccess = (
  payload: any,
  meta: HackathonPayload
) => ({
  type: FETCH_HACKATHON_TEAM_PERFORMANCE_SUCCESS as typeof FETCH_HACKATHON_TEAM_PERFORMANCE_SUCCESS,
  payload,
  meta,
})

export const fetchHackathonTeamPerformanceCancel = (
  payload: any,
  meta: HackathonPayload
) => ({
  type: FETCH_HACKATHON_TEAM_PERFORMANCE_CANCEL as typeof FETCH_HACKATHON_TEAM_PERFORMANCE_CANCEL,
  payload,
  meta,
})

export type HackathonsActionTypes =
  | ReturnType<typeof fetchHackathonList>
  | ReturnType<typeof fetchHackathonListSuccess>
  | ReturnType<typeof fetchHackathonListFailure>
  | ReturnType<typeof fetchHackathonListCancel>
  | ReturnType<typeof fetchHackathonDetails>
  | ReturnType<typeof fetchHackathonDetailsSuccess>
  | ReturnType<typeof fetchHackathonDetailsFailure>
  | ReturnType<typeof fetchHackathonDetailsCancel>
  | ReturnType<typeof registerHackathonTeam>
  | ReturnType<typeof registerHackathonTeamSuccess>
  | ReturnType<typeof registerHackathonTeamFailure>
  | ReturnType<typeof registerHackathonTeamCancel>
  | ReturnType<typeof addMembersHackathonTeam>
  | ReturnType<typeof addMembersHackathonTeamSuccess>
  | ReturnType<typeof addMembersHackathonTeamFailure>
  | ReturnType<typeof addMembersHackathonTeamCancel>
  | ReturnType<typeof submitHackathonSolution>
  | ReturnType<typeof submitHackathonSolutionSuccess>
  | ReturnType<typeof submitHackathonSolutionFailure>
  | ReturnType<typeof submitHackathonSolutionCancel>
  | ReturnType<typeof fetchHackathonLeaderboard>
  | ReturnType<typeof fetchHackathonLeaderboardSuccess>
  | ReturnType<typeof fetchHackathonLeaderboardFailure>
  | ReturnType<typeof fetchHackathonLeaderboardCancel>
  | ReturnType<typeof fetchHackathonTeamPerformance>
  | ReturnType<typeof fetchHackathonTeamPerformanceSuccess>
  | ReturnType<typeof fetchHackathonTeamPerformanceFailure>
  | ReturnType<typeof fetchHackathonTeamPerformanceCancel>
