import { put, cancelled, call, takeLatest } from 'redux-saga/effects'
import {
  CREATE_OPENAI_USER_TOKEN,
  CREATE_OPENAI_USER_TOKEN_CANCEL,
  DELETE_OPENAI_USER_TOKEN,
  DELETE_OPENAI_USER_TOKEN_CANCEL,
  FETCH_OPENAI_USER_TOKEN,
  FETCH_OPENAI_USER_TOKEN_CANCEL,
} from './OpenAiProxyProvider.types'
import {
  createOpenAiUserTokenAPI,
  deleteOpenAiUserTokenAPI,
  fetchOpenAiUserTokenAPI,
} from './OpenAiProxyProvider.api'
import {
  createOpenAiUserTokenSuccess,
  createOpenAiUserTokenFailure,
  deleteOpenAiUserTokenSuccess,
  deleteOpenAiUserTokenFailure,
  fetchOpenAiUserTokenSuccess,
  fetchOpenAiUserTokenFailure,
  createOpenAiUserToken,
  deleteOpenAiUserToken,
  fetchOpenAiUserToken,
} from './OpenAiProxyProvider.actions'
import { cancelable } from '../../../common/utils'
import { showAlertMessage } from '../AlertsProvider'

function* createOpenAiUserTokenHandler(
  action: ReturnType<typeof createOpenAiUserToken>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      createOpenAiUserTokenAPI,
      action,
      abortController.signal
    )
    yield put(createOpenAiUserTokenSuccess(data, action.meta))
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'Successfully created token',
      })
    )
  } catch (e) {
    yield put(createOpenAiUserTokenFailure(e, action.meta))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Error occurred while creating token',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* deleteOpenAiUserTokenHandler(
  action: ReturnType<typeof deleteOpenAiUserToken>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      deleteOpenAiUserTokenAPI,
      action,
      abortController.signal
    )
    yield put(deleteOpenAiUserTokenSuccess(data, action.meta))
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'Successfully deleted token',
      })
    )
  } catch (e) {
    yield put(deleteOpenAiUserTokenFailure(e, action.meta))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Error occurred while deleting token',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchOpenAiUserTokenHandler(
  action: ReturnType<typeof fetchOpenAiUserToken>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      fetchOpenAiUserTokenAPI,
      action,
      abortController.signal
    )
    yield put(fetchOpenAiUserTokenSuccess(data, action.meta))
  } catch (e) {
    yield put(fetchOpenAiUserTokenFailure(e, action.meta))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Error occurred while fetching token',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* openAiProxyProviderMiddleware() {
  yield takeLatest(
    CREATE_OPENAI_USER_TOKEN,
    cancelable(createOpenAiUserTokenHandler, CREATE_OPENAI_USER_TOKEN_CANCEL)
  )
  yield takeLatest(
    DELETE_OPENAI_USER_TOKEN,
    cancelable(deleteOpenAiUserTokenHandler, DELETE_OPENAI_USER_TOKEN_CANCEL)
  )
  yield takeLatest(
    FETCH_OPENAI_USER_TOKEN,
    cancelable(fetchOpenAiUserTokenHandler, FETCH_OPENAI_USER_TOKEN_CANCEL)
  )
}

export default ([] as any).concat(openAiProxyProviderMiddleware())
