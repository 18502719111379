import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { programSelectors } from 'web/providers/Dashboard/ProgramsProvider'
import { ProgramData } from 'common/types/programs'
import { skipSessionFeedback } from 'web/providers/FeedbackProvider/Feedback.actions'
import { AppState } from '../../../store'
import {
  FeedbackState,
  removeFeedbackPopup,
  fetchFeedbackContent,
} from '../../../providers/FeedbackProvider'
import FeedbackDialogComponent from '../../../components/Feedback/FeedbackDialog'
import ErrorBoundary from '../../../components/Utils/ErrorBoundary'

interface FeedbackDialogProps {
  feedback: FeedbackState
  program: ProgramData | null
  removeFeedbackPopup: typeof removeFeedbackPopup
  fetchFeedbackContent: typeof fetchFeedbackContent
  skipSessionFeedback: typeof skipSessionFeedback
}

const FeedbackDialog = (props: FeedbackDialogProps) => {
  const [feedbackUrl, setFeedbackUrl] = useState()

  useEffect(() => {
    if (props.feedback.data && props.feedback.data.feedback_url)
      setFeedbackUrl(props.feedback.data.feedback_url)
  }, [props.feedback.data])

  useEffect(() => {
    if (feedbackUrl) {
      props.fetchFeedbackContent({ url: feedbackUrl })
    }
  }, [feedbackUrl])

  useEffect(() => {
    if (props.program && props.program.program_type === 'career_track') {
      props.removeFeedbackPopup()
    }
  }, [props.program])

  return (
    <ErrorBoundary>
      <FeedbackDialogComponent
        feedback={props.feedback}
        removeFeedbackPopup={props.removeFeedbackPopup}
        skipSessionFeedback={props.skipSessionFeedback}
      />
    </ErrorBoundary>
  )
}

const mapStateToProps = (state: AppState) => ({
  feedback: state.feedback,
  program: programSelectors.getActiveProgramDetails()(state),
})

export default connect(mapStateToProps, {
  removeFeedbackPopup,
  fetchFeedbackContent,
  skipSessionFeedback,
})(FeedbackDialog)
