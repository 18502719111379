import { mixpanel } from 'common/utils/mixpanel'
import React, { useState, useEffect } from 'react'
import ErrorIllustrations from '../../../containers/Utils/ErrorIllustrations'

const ServiceUnavailable = (props: { error: Response }) => {
  const [isMaintenance, updateIsMaintenance] = useState(false)

  const checkGatewayError = () => {
    return fetch(props.error.url || `${window.constants.REACT_APP_LMS_URL}`)
      .then((response: Response) => {
        if (response) {
          const xMaintenanceHeaderValue = response.headers.get('x-maintenance')
          if (xMaintenanceHeaderValue === 'true') {
            return true
          }
        }
        return false
      })
      .catch(e => {
        return false
      })
  }

  useEffect(() => {
    checkGatewayError().then((isUnderMaintenance: boolean) => {
      if (isUnderMaintenance) {
        updateIsMaintenance(true)
        mixpanel.track('LMS - Server Under Maintenance', {
          location_url: window.location.href,
        })
      } else {
        mixpanel.track('LMS - Server Unavailable', {
          location_url: window.location.href,
        })
      }
    })
  }, [])

  return isMaintenance ? (
    <ErrorIllustrations type="underMaintenance" />
  ) : (
    <ErrorIllustrations type="serverUnavailable" />
  )
}

export default ServiceUnavailable
