export const FETCH_EXCELERATE_CAREER_PREP_DETAILS =
  'FETCH_EXCELERATE_CAREER_PREP_DETAILS'
export const FETCH_EXCELERATE_CAREER_PREP_DETAILS_SUCCESS =
  'FETCH_EXCELERATE_CAREER_PREP_DETAILS_SUCCESS'
export const FETCH_EXCELERATE_CAREER_PREP_DETAILS_FAILURE =
  'FETCH_EXCELERATE_CAREER_PREP_DETAILS_FAILURE'
export const FETCH_EXCELERATE_CAREER_PREP_DETAILS_CANCEL =
  'FETCH_EXCELERATE_CAREER_PREP_DETAILS_CANCEL'
export const UPDATE_EXCELERATE_CAREER_PREP_ACTIVE_STATUS =
  'UPDATE_EXCELERATE_CAREER_PREP_ACTIVE_STATUS'

export const SUBMIT_EXCELERATE_FEEDBACK = 'SUBMIT_EXCELERATE_FEEDBACK'
export const SUBMIT_EXCELERATE_FEEDBACK_SUCCESS =
  'SUBMIT_EXCELERATE_FEEDBACK_SUCCESS'
export const SUBMIT_EXCELERATE_FEEDBACK_FAILURE =
  'SUBMIT_EXCELERATE_FEEDBACK_FAILURE'
export const SUBMIT_EXCELERATE_FEEDBACK_CANCEL =
  'SUBMIT_EXCELERATE_FEEDBACK_CANCEL'
