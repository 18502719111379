import { dashboardSelectors } from 'web/providers/Dashboard/DashboardProvider'
import { CourseID } from '../../../common/types/courses'
import { GroupID } from '../../../common/types/user'

import { apiCall } from '../../../common/utils'
import { AnnouncementData } from '../../../common/types/announcements'

export async function getAllAnnouncements({
  courseIds,
  page,
  per_page,
  signal,
  enrolled_at,
}: {
  courseIds: CourseID[]
  per_page: number
  page: number
  signal: AbortSignal
  enrolled_at: string
}): Promise<AnnouncementData[]> {
  const contextCodes = courseIds.map(id => `course_${id}`)

  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/announcements`,
    query: {
      context_codes: contextCodes,
      page,
      start_date: enrolled_at,
      end_date: new Date().toISOString().split('T')[0],
      per_page,
    },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getGroupAnnouncements({
  groupId,
  page,
  per_page,
  signal,
}: {
  groupId: GroupID
  per_page: number
  page: number
  signal: AbortSignal
}): Promise<AnnouncementData[]> {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/groups/${groupId}/discussion_topics`,
    query: {
      order_by: 'recent_activity',
      only_announcements: true,
      page,
      per_page,
    },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
