export const FETCH_PROGRAM_LEADERBOARD = 'FETCH_PROGRAM_LEADERBOARD'
export const FETCH_PROGRAM_LEADERBOARD_SUCCESS =
  'FETCH_PROGRAM_LEADERBOARD_SUCCESS'
export const FETCH_PROGRAM_LEADERBOARD_FAILURE =
  'FETCH_PROGRAM_LEADERBOARD_FAILURE'
export const FETCH_PROGRAM_LEADERBOARD_CANCEL =
  'FETCH_PROGRAM_LEADERBOARD_CANCEL'

export const FETCH_COURSE_LEADERBOARD = 'FETCH_COURSE_LEADERBOARD'
export const FETCH_COURSE_LEADERBOARD_SUCCESS =
  'FETCH_COURSE_LEADERBOARD_SUCCESS'
export const FETCH_COURSE_LEADERBOARD_FAILURE =
  'FETCH_COURSE_LEADERBOARD_FAILURE'
export const FETCH_COURSE_LEADERBOARD_CANCEL = 'FETCH_COURSE_LEADERBOARD_CANCEL'

export const USER_LEADERBOARD_ANONYMITY_UPDATE =
  'USER_LEADERBOARD_ANONYMITY_UPDATE'
export const USER_LEADERBOARD_ANONYMITY_UPDATE_SUCCESS =
  'USER_LEADERBOARD_ANONYMITY_UPDATE_SUCCESS'
export const USER_LEADERBOARD_ANONYMITY_UPDATE_FAILURE =
  'USER_LEADERBOARD_ANONYMITY_UPDATE_FAILURE'
export const USER_LEADERBOARD_ANONYMITY_UPDATE_CANCEL =
  'USER_LEADERBOARD_ANONYMITY_UPDATE_CANCEL'
