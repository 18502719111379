import { Reducer } from 'redux'
import {
  AnnouncementData,
  AnnouncementID,
} from '../../../common/types/announcements'
import { CourseID } from '../../../common/types/courses'
import { PartialObjectMap } from '../../../common/types/utils'
import { AnnouncementActions } from './Announcements.actions'
import * as types from './Announcements.types'
import { getCourseIDFromContextCode } from '../../../common/utils/custom/courses'
import { GroupID } from '../../../common/types/user'
import {
  ProgramActions,
  UPDATE_ACTIVE_PROGRAM,
} from '../Dashboard/ProgramsProvider'

export interface AnnouncementsState {
  data: {
    byId: PartialObjectMap<AnnouncementID, AnnouncementData>
    idMapping: PartialObjectMap<
      CourseID | 'ALL_COURSES' | GroupID,
      AnnouncementID[]
    >
    hasMoreData: boolean | null
  }
  success: boolean
  loading: boolean
  error: boolean | Error
}

const initialState: AnnouncementsState = {
  data: {
    byId: {},
    idMapping: {},
    hasMoreData: true,
  },
  success: false,
  loading: false,
  error: false,
}

const getNormalizedData = (
  state: AnnouncementsState['data'],
  data: AnnouncementData[],
  { allCourses }: { allCourses: boolean },
  groupId?: GroupID
): Pick<AnnouncementsState['data'], 'byId' | 'idMapping'> => {
  const announcements = { ...state.byId }
  const idMapping = { ...state.idMapping }

  data.forEach(item => {
    announcements[item.id] = item
    const id = item.context_code
      ? getCourseIDFromContextCode(item.context_code)
      : groupId
    if (allCourses) {
      if (!idMapping.ALL_COURSES) {
        idMapping.ALL_COURSES = []
      }
      idMapping.ALL_COURSES.push(item.id)
    }
    if (id) {
      if (!idMapping[id]) {
        idMapping[id] = []
      }
      idMapping[id]!.push(item.id)
    }
  })

  Object.keys(idMapping).forEach(id => {
    idMapping[id] = [...new Set(idMapping[id])]
  })

  return {
    byId: announcements,
    idMapping,
  }
}

const announcementsReducer: Reducer<
  AnnouncementsState,
  | AnnouncementActions
  | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
> = (state = initialState, action): AnnouncementsState => {
  switch (action.type) {
    case types.ANNOUNCEMENTS_FETCH:
    case types.GROUP_ANNOUNCEMENTS_FETCH:
      return {
        ...state,
        loading: action.payload.page === 1,
        error: false,
      }

    case types.ANNOUNCEMENTS_FETCH_FAILURE:
    case types.GROUP_ANNOUNCEMENTS_FETCH_FAILURE:
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload,
      }

    case types.ANNOUNCEMENTS_FETCH_CANCEL:
    case types.GROUP_ANNOUNCEMENTS_FETCH_CANCEL:
      return {
        ...state,
        loading: false,
        error: false,
      }

    case types.ANNOUNCEMENTS_FETCH_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...getNormalizedData(state.data, action.payload.data, {
            allCourses: !action.payload.courseId,
          }),
          hasMoreData: action.payload.hasMore,
        },
        success: true,
        loading: false,
        error: false,
      }

    case types.GROUP_ANNOUNCEMENTS_FETCH_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...getNormalizedData(
            state.data,
            action.payload.data,
            {
              allCourses: false,
            },
            action.meta.groupId
          ),
          hasMoreData: action.payload.hasMore,
        },
        success: true,
        loading: false,
        error: false,
      }

    case types.ANNOUNCEMENT_MARK_AS_READ:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.announcementId]: {
              ...state.data.byId[action.meta.announcementId],
              read_state: 'read',
            } as AnnouncementData,
          },
        },
      }

    case types.ANNOUNCEMENT_MARK_AS_READ_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.announcementId]: {
              ...state.data.byId[action.meta.announcementId],
              read_state: 'unread',
            } as AnnouncementData,
          },
        },
      }

    case UPDATE_ACTIVE_PROGRAM:
      return initialState
    default:
      return state
  }
}

export default announcementsReducer
