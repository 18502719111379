import {
  FETCH_CERTIFICATES_CONTENT,
  FETCH_CERTIFICATES_CONTENT_SUCCESS,
  FETCH_CERTIFICATES_CONTENT_FAILURE,
  FETCH_CERTIFICATES_CONTENT_CANCEL,
} from './Certificates.types'

export const fetchCertificatesContent = () => ({
  type: FETCH_CERTIFICATES_CONTENT as typeof FETCH_CERTIFICATES_CONTENT,
})

export const fetchCertificatesContentSuccess = (payload: any) => ({
  type: FETCH_CERTIFICATES_CONTENT_SUCCESS as typeof FETCH_CERTIFICATES_CONTENT_SUCCESS,
  payload,
})

export const fetchCertificatesContentFailure = (payload: Error) => ({
  type: FETCH_CERTIFICATES_CONTENT_FAILURE as typeof FETCH_CERTIFICATES_CONTENT_FAILURE,
  payload,
})

export const fetchCertificatesContentCancel = () => ({
  type: FETCH_CERTIFICATES_CONTENT_CANCEL as typeof FETCH_CERTIFICATES_CONTENT_CANCEL,
})

export type CertificatesActions =
  | ReturnType<typeof fetchCertificatesContent>
  | ReturnType<typeof fetchCertificatesContentSuccess>
  | ReturnType<typeof fetchCertificatesContentFailure>
  | ReturnType<typeof fetchCertificatesContentCancel>
