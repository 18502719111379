import { Reducer } from 'redux'
import { AttendanceActions } from './Attendance.actions'
import {
  FETCH_ATTENDANCE_CONTENT,
  FETCH_ATTENDANCE_CONTENT_FAILURE,
  FETCH_ATTENDANCE_CONTENT_SUCCESS,
  FETCH_ATTENDANCE_CONTENT_CANCEL,
} from './Attendance.types'
import { AttendanceData } from '../../../../common/types/dashboard'
import { ProgramActions, UPDATE_ACTIVE_PROGRAM } from '../ProgramsProvider'

export interface AttendanceState {
  data?: AttendanceData
  loading: boolean
  error: false | Error | Response
}

const initialState: AttendanceState = {
  loading: false,
  error: false as false,
}

const attendanceReducer: Reducer<
  AttendanceState,
  AttendanceActions | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_CONTENT:
      return { ...state, loading: true, error: false }
    case FETCH_ATTENDANCE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case FETCH_ATTENDANCE_CONTENT_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case FETCH_ATTENDANCE_CONTENT_CANCEL:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_ACTIVE_PROGRAM:
      return initialState
    default:
      return state
  }
}
export default attendanceReducer
