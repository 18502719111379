import { select, put, cancelled, takeLatest, call } from 'redux-saga/effects'
import { apiCall, cancelable, getCookie } from 'common/utils'
import { UserID } from '../../../../common/types/user'

import { AppState } from '../../../store'

import {
  fetchOverdueNotificationsSuccess,
  fetchOverdueNotificationsFailure,
} from './UserNotifications.actions'

import {
  FETCH_OVERDUE_NOTIFICATIONS,
  FETCH_OVERDUE_NOTIFICATIONS_CANCEL,
} from './UserNotifications.types'

export async function getUserNotificationsOverdueAPI(
  userId: UserID,
  signal: AbortSignal,
  bypassOverdueCheck: boolean
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/notifications/check`,
    params: {
      signal,
    },
    query: {
      user_id: userId,
      populate_types: [],
      bypass_overdue_check: bypassOverdueCheck,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

function* fetchNotificationsOverdueStatusHandler() {
  const abortController = new AbortController()
  try {
    const bypassOverdueCheck = getCookie('bypass_overdue_check')
    const userId = yield select((state: AppState) => state.user.details.id)
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data = yield call(
      getUserNotificationsOverdueAPI,
      userId,
      abortController.signal,
      bypassOverdueCheck === 'true'
    )
    yield put(fetchOverdueNotificationsSuccess(data))
  } catch (e) {
    yield put(fetchOverdueNotificationsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* notificationsOverdueStatusMiddleware() {
  yield takeLatest(
    FETCH_OVERDUE_NOTIFICATIONS,
    cancelable(fetchNotificationsOverdueStatusHandler, [
      FETCH_OVERDUE_NOTIFICATIONS_CANCEL,
    ])
  )
}

export default ([] as any).concat(notificationsOverdueStatusMiddleware())
