import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import React, { Fragment } from 'react'
import { GlaCertificatePayment } from 'common/types/programs'
import { mixpanel } from 'common/utils/mixpanel'
import {
  glaCertificateHighLights,
} from 'common/constants'
import { getPaymentUrl } from 'common/utils/custom/courses/payment'
import styles from './AcademyCertificatePaymentDialog.module.css'
import { ReactComponent as Tick } from '../../../common/images/icons/alumni/blue_check.svg'
import Button from '../Utils/Button'
import { currencySymbol } from '../../../common/utils/custom/user'

interface Props {
  onClose: () => void
  certificatePaymentDetails: GlaCertificatePayment
  trafficSource: string
  premiumFeatures?: string[]
}

const glaWithoutCertificateHighLights = ['Access to full course content']

const popupDetails = () => {
  return {
    heading: 'Choose any one',
    subTitle1: 'Course without certificate',
    subTitle2: 'Course with certificate',
    subTitle1Highlights: glaWithoutCertificateHighLights,
    subTitle2Highlights: glaCertificateHighLights,
    subTitle1ContinueBtn: 'Continue Without Certificate',
  }
}

const mixPanelEvents = (
  name: string,
  positionName: string,
  typeName: string
) => {
  mixpanel.track(name, {
    Position: positionName,
    Type: typeName,
  })
}
const AcademyCertificatePaymentDialog = (props: Props) => {
  const isMobile = useMediaQuery('(max-width: 768px)', { noSsr: true })

  const paymentLink = () => {
    mixPanelEvents('Button Click', 'GlaPaymentPopup', 'Continue Payment')
    const url = props.certificatePaymentDetails.payment_link
    if (url) {
      window.location.href = getPaymentUrl(url, props.trafficSource)
    }

    props.onClose()
  }

  const onClose = (close: boolean) => {
    if (close) {
      mixPanelEvents(
        'Button Click',
        'GlaPaymentPopup',
        'Paid Certificate popup close'
      )
    } else {
      mixPanelEvents(
        'Button Click',
        'GlaPaymentPopup',
        'Continue Without Certificate'
      )
    }
    props.onClose()
  }
  const popUpData = popupDetails()
  return (
    <Dialog
      open
      maxWidth="sm"
      fullScreen={isMobile}
      onClose={() => props.onClose()}
      classes={{ paper: styles.dialog }}
    >
      <DialogContent className={styles.academyCertificatePaymentWrapper}>
        <p>{popUpData.heading}</p>
        <Box className={styles.academyCertificatePayment}>
          <div className={styles.continueWithoutCertificate}>
            <Typography variant="h5">{popUpData.subTitle1}</Typography>
            {popUpData.subTitle1Highlights.map(object => (
              <div className={styles.pointersWrapper}>
                <Tick />
                <span>{object}</span>
              </div>
            ))}
            <Button
              variant="outlined"
              className={styles.continueWithoutCertificateBtn}
              color="primary"
              onClick={() => onClose(false)}
            >
              {popUpData.subTitle1ContinueBtn}
            </Button>
          </div>
          <div className={styles.continueWithCertificate}>
            <Typography variant="h5">{popUpData.subTitle2}</Typography>
              <Fragment>
                {popUpData.subTitle2Highlights.map(object => (
                  <div className={styles.pointersWrapper}>
                    <Tick />
                    <span>{object}</span>
                  </div>
                ))}

                <Typography className={styles.certificateAvailable} variant="h6">
                  Certificate available for{' '}
                  <span className={styles.slash}>
                    {currencySymbol(
                      props.certificatePaymentDetails.currency_code
                    )}
                    {props.certificatePaymentDetails.total_amount}
                  </span>{' '}
                  <b>
                    {currencySymbol(
                      props.certificatePaymentDetails.currency_code
                    )}
                    {props.certificatePaymentDetails.amount_payable}
                    {props.certificatePaymentDetails.gst_applicable && (
                      <sup className={styles.asterisk}>*</sup>
                    )}
                  </b>
                </Typography>
                {props.certificatePaymentDetails.gst_applicable && (
                  <div className={styles.gstText}>18% GST</div>
                )}
              </Fragment>
            <Button
              variant="contained"
              className={styles.continueToPayment}
              color="primary"
              onClick={() => paymentLink()}
            >
              Continue To Payment
            </Button>
          </div>
        </Box>
        <Box className={styles.closePopupDiv}>
          <IconButton
            className={styles.closePopup}
            onClick={() => onClose(true)}
          >
            &times;
          </IconButton>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AcademyCertificatePaymentDialog
