import React from 'react'
import cx from 'classnames'
import { Typography } from '@material-ui/core'
import styles from './LoaderBase.module.css'

export interface Props {
  children?: React.ReactChild
  style: object
  className?: string
  text?: string
}

const LoaderBase: React.FC<Props> = (props: Props) => (
  <div
    style={props.style}
    className={cx(styles.loader, props.className)}
    data-testid="loader"
  >
    <div className={styles.contentWrapper}>
      <div>{props.children}</div>
      {props.text ? (
        <Typography variant="body1">{props.text}</Typography>
      ) : null}
    </div>
  </div>
)

LoaderBase.defaultProps = {
  style: {},
}

export default LoaderBase
