import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects'
import { history } from 'web/store/history'
import { AppState } from 'web/store'

import { ProgramData } from 'common/types/programs'
import {
  getCodingLabStatus,
  getCodingLabStatusSuccess,
  getCodingLabStatusFailure,
  startCodingLab,
  startCodingLabSuccess,
  startCodingLabFailure,
  stopCodingLab,
  stopCodingLabSuccess,
  stopCodingLabFailure,
  getCodingLabAssignmentAndSubmissionData,
  getCodingLabAssignmentAndSubmissionDataSuccess,
  getCodingLabAssignmentAndSubmissionDataFailure,
  submitCodingLabAssignment,
  submitCodingLabAssignmentSuccess,
  submitCodingLabAssignmentFailure,
  sendCodingLabProctorEvent,
} from './CodingLabs.action'
import { cancelable, generateURL } from '../../../common/utils'
import {
  GET_CODING_LAB_STATUS,
  GET_CODING_LAB_STATUS_CANCEL,
  START_CODING_LAB,
  START_CODING_LAB_CANCEL,
  STOP_CODING_LAB,
  STOP_CODING_LAB_CANCEL,
  GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA,
  GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_CANCEL,
  SUBMIT_CODING_LAB_ASSIGNMENT,
  SUBMIT_CODING_LAB_ASSIGNMENT_CANCEL,
  SEND_CODING_LAB_PROCTOR_EVENT,
} from './CodingLabs.types'
import {
  getCodingLabStatusAPI,
  startCodingLabAPI,
  stopCodingLabAPI,
  getCodingLabAssignmentDataAPI,
  submitCodingLabAssignmentAPI,
  proctorCodingLabAPI,
} from './CodingLabs.api'
import { programSelectors } from '../Dashboard/ProgramsProvider'
import {
  endProctoring,
  initiateProctoring,
  pauseProctoring,
  startProctoring,
  stopProctoring,
} from '../ProctoringProvider'
import { showAlertMessage } from '../AlertsProvider'

const redirectToCodingLabAssignment = (
  isNinjaUser: boolean,
  course_id: number | null,
  lms_assignment_id: number | null,
  isNewUiEnabled: boolean,
) => {
  if (isNinjaUser) {
    window.location.href = `${process.env.REACT_APP_NINJA_URL}coding_labs`
  } else {
    if (isNewUiEnabled) {
      window.location.href =
        generateURL('CONTENT', {
          path: {
            courseId: course_id || 0,
            contentId: lms_assignment_id || 0,
            contentType: 'assignments',
          },
          search: {},
        }).pathname || ''
    }
    history.push(
      generateURL('CONTENT', {
        path: {
          courseId: course_id || 0,
          contentId: lms_assignment_id || 0,
          contentType: 'assignments',
        },
        search: {},
      })
    )
  }
}

function* getCodingLabsStatusHandler(
  action: ReturnType<typeof getCodingLabStatus>
) {
  const abortController = new AbortController()
  try {
    const status = yield call(
      getCodingLabStatusAPI,
      action,
      abortController.signal
    )
    yield put(getCodingLabStatusSuccess(status))
  } catch (e) {
    yield put(getCodingLabStatusFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* startCodingLabHandler(action: ReturnType<typeof startCodingLab>) {
  const abortController = new AbortController()
  try {
    const status = yield call(startCodingLabAPI, action, abortController.signal)
    if (status.error) {
      yield put(startCodingLabFailure(status.error))
    } else {
      yield put(startCodingLabSuccess(status))
      if (
        action.payload.proctored ||
        action.payload.is_coding_lab_basic_proctoring_enabled
      ) {
        if (action.payload.assignment_id && action.payload.submission_id) {
          yield put(
            initiateProctoring({
              assessment_id: action.payload.assignment_id,
              submission_uid: `${action.payload.submission_id}_1`,
              startedAt: `${new Date().toISOString()}`,
              openBook: action.payload.openBook || false,
              anomalyDetection: action.payload.anomalyDetection || false,
              is_coding_lab_assignment: true,
            })
          )
        }

        yield put(startProctoring())
      }
    }
  } catch (e) {
    yield put(startCodingLabFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* stopCodingLabHandler(action: ReturnType<typeof stopCodingLab>) {
  const abortController = new AbortController()
  try {
    const status = yield call(stopCodingLabAPI, action, abortController.signal)

    const programData: ProgramData | null = yield select(
      programSelectors.getActiveProgramDetails()
    )
    const isNinjaUser = programData === null
    const assignment = yield select(
      (state: AppState) => state.codingLabs.data.assignment
    )

    if (status.error) {
      yield put(stopCodingLabFailure(status.error))
    } else {
      yield put(stopCodingLabSuccess(status))
      if (
        action.payload.proctored ||
        action.payload.is_coding_lab_basic_proctoring_enabled
      ) {
        yield put(stopProctoring())
      }
      redirectToCodingLabAssignment(
        isNinjaUser,
        assignment.course_id,
        assignment.lms_assignment_id,
        action.payload.is_new_ui_enabled || false
      )
    }

    const stopReasons = {
      inactivity: 'Lab stopped due to inactivity',
      manual: 'Lab stopped successfully',
    }

    let message = 'Lab stopped' // Default message

    if (
      action.payload.lab_stop_reason &&
      Object.prototype.hasOwnProperty.call(
        stopReasons,
        action.payload.lab_stop_reason
      )
    ) {
      message = stopReasons[action.payload.lab_stop_reason]
    }

    yield put(
      showAlertMessage({
        message,
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        closeOnTimeout: false,
      })
    )
  } catch (e) {
    yield put(stopCodingLabFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitCodingLabAssignmentHandler(
  action: ReturnType<typeof submitCodingLabAssignment>
) {
  const abortController = new AbortController()
  try {
    const status = yield call(
      submitCodingLabAssignmentAPI,
      action,
      abortController.signal
    )

    const programData: ProgramData | null = yield select(
      programSelectors.getActiveProgramDetails()
    )

    const isNinjaUser = programData === null
    const assignment = yield select(
      (state: AppState) => state.codingLabs.data.assignment
    )
    yield put(submitCodingLabAssignmentSuccess(status))
    yield call(
      proctorCodingLabAPI,
      action.payload.codingLabAssignmentId,
      'submit',
      {},
      action.payload.completionType === 'manual'
    )

    redirectToCodingLabAssignment(
      isNinjaUser,
      assignment.course_id,
      assignment.lms_assignment_id,
      action.payload.is_new_ui_enabled || false
    )

    if (
      action.payload.proctored ||
      action.payload.is_coding_lab_basic_proctoring_enabled
    ) {
      yield put(stopProctoring())
      if (action.payload.completionType) {
        yield put(
          endProctoring({
            completionType: action.payload.completionType,
            assessment_type: 'Assignment',
          })
        )
      }
    }

    const submitReasons = {
      auto_time_expired: 'Lab submitted as due time over',
      auto_violations_exceeded:
        'Lab submitted on account of violations / prohibitive actions by you',
      force_submit: 'Lab has been submitted by the invigilator',
      manual: 'Lab submitted successfully',
    }

    let message = 'Lab Submitted' // Default message

    if (
      action.payload.completionType &&
      Object.prototype.hasOwnProperty.call(
        submitReasons,
        action.payload.completionType
      )
    ) {
      message = submitReasons[action.payload.completionType]
    }

    yield put(
      showAlertMessage({
        message,
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        closeOnTimeout: false,
      })
    )
  } catch (e) {
    yield put(submitCodingLabAssignmentFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* getCodingLabsAssignmentAndSubmissionDataHandler(
  action: ReturnType<typeof getCodingLabAssignmentAndSubmissionData>
) {
  const abortController = new AbortController()
  try {
    const status = yield call(
      getCodingLabAssignmentDataAPI,
      action,
      abortController.signal
    )
    yield put(getCodingLabAssignmentAndSubmissionDataSuccess(status))
  } catch (e) {
    yield put(getCodingLabAssignmentAndSubmissionDataFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* sendCodingLabProctorEventHandler(
  action: ReturnType<typeof sendCodingLabProctorEvent>
) {
  const abortController = new AbortController()
  try {
    yield call(
      proctorCodingLabAPI,
      action.payload.codingLabAssignmentId,
      action.payload.event_name,
      action.payload.data,
      action.payload.is_focused
    )
  } catch (e) {
    console.error(`Error in sendCodingLabProctorEventHandler: ${e}`)
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* codingLabsMiddleware() {
  yield takeLatest(
    GET_CODING_LAB_STATUS,
    cancelable(getCodingLabsStatusHandler, GET_CODING_LAB_STATUS_CANCEL)
  )

  yield takeLatest(
    START_CODING_LAB,
    cancelable(startCodingLabHandler, START_CODING_LAB_CANCEL)
  )

  yield takeLatest(
    STOP_CODING_LAB,
    cancelable(stopCodingLabHandler, STOP_CODING_LAB_CANCEL)
  )

  yield takeLatest(
    GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA,
    cancelable(
      getCodingLabsAssignmentAndSubmissionDataHandler,
      GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_CANCEL
    )
  )

  yield takeLatest(
    SUBMIT_CODING_LAB_ASSIGNMENT,
    cancelable(
      submitCodingLabAssignmentHandler,
      SUBMIT_CODING_LAB_ASSIGNMENT_CANCEL
    )
  )

  yield takeLatest(
    SEND_CODING_LAB_PROCTOR_EVENT,
    cancelable(
      sendCodingLabProctorEventHandler,
      SUBMIT_CODING_LAB_ASSIGNMENT_CANCEL
    )
  )
}
export default ([] as any).concat(codingLabsMiddleware())
