import { Reducer } from 'redux'
import * as types from './Outcomes.types'
import {
  OutcomesId,
  OutcomesData,
} from '../../../../../common/types/courses/assignment'
import { OutcomeActions } from './Outcomes.actions'

export interface OutcomesState {
  byId: {
    [s in OutcomesId]?: {
      data?: OutcomesData
      error: false | Error | Response
      loading: Boolean
    }
  }
}

const initialState: OutcomesState = {
  byId: {},
}

const outcomesDetailsReducer: Reducer<OutcomesState, OutcomeActions> = (
  state = initialState,
  action
): OutcomesState => {
  switch (action.type) {
    case types.GET_OUTCOME_DETAILS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.rubricOutcomeId]: {
            loading: true,
            error: false,
          },
        },
      }
    }

    case types.GET_OUTCOME_DETAILS_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.rubricOutcomeId]: {
            data: action.payload,
            loading: false,
            error: false,
          },
        },
      }
    }

    case types.GET_OUTCOME_DETAILS_FAILURE: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.rubricOutcomeId]: {
            loading: false,
            error: action.payload,
          },
        },
      }
    }

    default:
      return state
  }
}

export default outcomesDetailsReducer
