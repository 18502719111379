import React, { useState } from 'react'
import { NavHashLink } from 'react-router-hash-link'
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  SvgIconProps,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import {
  ExpandMore,
  ChevronRight,
  HelpOutline,
  PollOutlined,
  StarBorderOutlined,
  Menu,
  CloseOutlined,
} from '@material-ui/icons'
import { TreeItem, TreeItemProps, TreeView } from '@material-ui/lab'
import { ReactComponent as GLBusinessWhiteLogo } from 'common/images/business-logo-white.svg'
import { ReactComponent as GLBusinessLogo } from 'common/images/business-logo.svg'
import Link from 'web/components/Utils/Link'
import { ReactComponent as LogoutIcon } from 'common/images/icons/logout.svg'
import cx from 'classnames'
import { logoutUser } from 'web/providers/User/UserDetailsProvider'
import { NavLink } from 'react-router-dom'
import routes from 'web/routes'
import styles from './Navigation.module.css'

interface ParentTreeItemProps extends TreeItemProps {
  labelIcon: (props: SvgIconProps) => React.ReactElement
  url: string
}
interface SubTreeItemProps extends TreeItemProps {
  url: string
}

interface LearnAndDevNavigationProps {
  onLogout: typeof logoutUser
}

const ParentTreeItem = (props: ParentTreeItemProps) => {
  const { label, labelIcon: LabelIcon, url, ...other } = props

  return (
    <TreeItem
      label={
        <NavLink to={url} activeClassName={styles.activeLink}>
          <div className={styles.parentLabelRoot}>
            <LabelIcon className={styles.icon} />
            <Typography className={styles.labelText}>{label}</Typography>
          </div>
        </NavLink>
      }
      classes={{
        content: styles.treeContent,
        root: styles.treeRoot,
      }}
      {...other}
    />
  )
}

const SubTreeItem = (props: SubTreeItemProps) => {
  const { label, url, ...other } = props

  return (
    <TreeItem
      label={
        <NavHashLink smooth to={url} activeClassName={styles.activeLink}>
          <Typography className={styles.subTreeContent}>{label}</Typography>
        </NavHashLink>
      }
      classes={{
        root: styles.labelRoot,
      }}
      {...other}
    />
  )
}

const dashboardUrl = routes.LEARN_AND_DEV_DASHBOARD

const navigationItems = [
  {
    id: '1',
    icon: PollOutlined,
    label: 'Analytics',
    url: dashboardUrl,
    children: [
      {
        id: '2',
        label: 'Key Metrics',
        url: `${dashboardUrl}#key_metrics`,
      },
      {
        id: '3',
        label: 'User Metrics',
        url: `${dashboardUrl}#user_metrics`,
      },
      {
        id: '4',
        label: 'Department Metrics',
        url: `${dashboardUrl}#department_metrics`,
      },
      {
        id: '5',
        label: 'Course Metrics',
        url: `${dashboardUrl}#course_metrics`,
      },
      {
        id: '6',
        label: 'Domain Metrics',
        url: `${dashboardUrl}#domain_metrics`,
      },
      {
        id: '7',
        label: 'Career Path Metrics',
        url: `${dashboardUrl}#career_path_metrics`,
      },
    ],
  },
  {
    id: '8',
    icon: StarBorderOutlined,
    label: 'FSL Leaderboard',
    url: routes.LEARN_AND_DEV_LEADERBOARD,
  },
]

const renderNavigationItems = (handleDrawerToggle: () => void = () => {}) => (
  <TreeView
    defaultCollapseIcon={<ExpandMore />}
    defaultExpandIcon={<ChevronRight />}
    defaultExpanded={[navigationItems[0].label]}
  >
    {navigationItems.map(navigationItem => (
      <ParentTreeItem
        nodeId={navigationItem.label}
        labelIcon={navigationItem.icon}
        label={navigationItem.label}
        key={navigationItem.label}
        url={navigationItem.url}
        onClick={handleDrawerToggle}
      >
        {navigationItem.children && navigationItem.children.length
          ? navigationItem.children.map(subItem => (
              <SubTreeItem
                nodeId={subItem.label}
                label={subItem.label}
                key={subItem.label}
                url={subItem.url}
                onClick={handleDrawerToggle}
              />
            ))
          : null}
      </ParentTreeItem>
    ))}
  </TreeView>
)

const support = (onLogout: typeof logoutUser) => (
  <div className={styles.supportContainer}>
    <Box display="flex" marginBottom={2}>
      <HelpOutline className={styles.icon} />
      <div>
        <Typography>Support</Typography>
        <Typography>
          <Link
            href="mailto:business@greatlearning.in"
            className={styles.emailLink}
            target="_blank"
          >
            Drop us an email
          </Link>{' '}
          for specific analytics request.
        </Typography>
      </div>
    </Box>
    <Box onClick={onLogout} className={styles.logoutSection}>
      <LogoutIcon className={cx(styles.icon, styles.logoutIcon)} />
      <Typography>Logout</Typography>
    </Box>
  </div>
)

const MobileNavigation = (props: LearnAndDevNavigationProps) => {
  const [drawerToggle, setDrawerToggle] = useState(false)
  const handleDrawerToggle = () => {
    setDrawerToggle(!drawerToggle)
  }
  return (
    <>
      <AppBar position="static" className={styles.appBar}>
        <Toolbar>
          <IconButton onClick={handleDrawerToggle}>
            <Menu />
          </IconButton>
          <GLBusinessLogo className={styles.logo} />
          <Box flexGrow="1" />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={drawerToggle}
        onClose={handleDrawerToggle}
        classes={{
          paper: styles.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className={styles.drawerCloseIcon}>
          <IconButton onClick={handleDrawerToggle}>
            <CloseOutlined />
          </IconButton>
        </div>
        <div className={styles.drawerContainer}>
          {renderNavigationItems(handleDrawerToggle)}
          {support(props.onLogout)}
        </div>
      </Drawer>
    </>
  )
}

const DesktopNavigation = (props: LearnAndDevNavigationProps) => (
  <div className={styles.desktopNavigationContainer}>
    <GLBusinessWhiteLogo className={styles.logo} />
    {renderNavigationItems()}
    {support(props.onLogout)}
  </div>
)

const LearnAndDevNavigation = (props: LearnAndDevNavigationProps) => {
  const theme: Theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })

  return isMobile ? (
    <MobileNavigation {...props} />
  ) : (
    <DesktopNavigation {...props} />
  )
}

export default LearnAndDevNavigation
