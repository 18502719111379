/* eslint-disable react/jsx-no-duplicate-props */
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tooltip,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useState, Fragment, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import ClearIcon from '@material-ui/icons/Clear'
import { AcademyUserProfileData } from 'common/types/user'
import Dialog, { DialogContent, DialogTitle } from '../Utils/Dialog'
import {
  workExpData,
  SurveyKeys,
  SurveyData,
  SurveyTypeData,
  getSurveyTypeData,
  collegeStudentWorkEx,
  graduateWorkEx,
  collegeStudentSurveyData,
  lessThanOneYearWorkEx,
} from './FreeUserInfoData'
import styles from './FreeUserInfoDialog.module.css'
import Loader from '../Utils/Loader'
import Button from '../Utils/Button'
import { mixpanel } from '../../../common/utils/mixpanel'
import {
  UniversityData,
  CollegeData,
  CityData,
} from '../../../common/types/dashboard'
import {
  fetchColleges,
  fetchUniversities,
  resetColleges,
} from '../../providers/Dashboard/FreeUserInfoDialogProvider'

interface Props {
  isLoading: boolean
  onSubmit: (data: SurveyData | {}) => void
  cityData: CityData[]
  isInternationalUser: boolean
  universitiesData: UniversityData[]
  collegesData: CollegeData[]
  fetchColleges: typeof fetchColleges
  fetchUniversities: typeof fetchUniversities
  fromCareerPlusBanner: boolean
  resetColleges: typeof resetColleges
  workExperience: string
  userProfileData: AcademyUserProfileData | null | undefined
  isReferralGiftCourse: boolean
  isFormReinitiated: boolean
}

const useStyles = makeStyles({
  listbox: {
    '@media (min-width:1280px)': {
      maxHeight: 200,
    },
  },
})

const FreeUserInfoDialog = (props: Props) => {
  const classes = useStyles()
  const [surveyData, updateSurveyData] = useState<SurveyData | {}>({})
  const [canSubmit, updateCanSubmit] = useState(false)
  const [inputValues, updateInputValues] = useState<Record<string, string>>({})
  const [surveyType, updateSurveyType] = useState<SurveyTypeData | null>(null)
  const universityIdRef = useRef<HTMLInputElement>(null)
  const [getUniversityName, updateGetUniversityName] = useState(false)
  const universityNameRef = useRef<HTMLInputElement>(null)
  const collegeIdRef = useRef<HTMLInputElement>(null)

  const [getCollegeName, updateGetCollegeName] = useState(false)
  const collegeNameRef = useRef<HTMLInputElement>(null)
  const hasChangedWorkExp = !!(
    props.userProfileData &&
    props.userProfileData.work_experience !==
      (surveyData as SurveyData).work_experience
  )

  const currentCountryCode =
    (props.userProfileData && props.userProfileData.current_country_code) || ''

  const showWhatBroughtYouHere =
    !(props.userProfileData && props.userProfileData.career_option) ||
    hasChangedWorkExp

  let showDomain =
    !(props.userProfileData && props.userProfileData.domain) ||
    hasChangedWorkExp

  const showAreaOfStudy =
    !(props.userProfileData && props.userProfileData.area_of_study) ||
    hasChangedWorkExp
  const showStudyAbroadConsent =
    !(props.userProfileData && props.userProfileData.study_abroad_consent) ||
    hasChangedWorkExp

  let showHighestQualificationPercentage = true
  let showUniversity = true
  let showCollege = true
  let showHighestQualification = true

  if (props.fromCareerPlusBanner) {
    showHighestQualificationPercentage = false
    showUniversity = false
    showCollege = false
    showHighestQualification = false
    if ((surveyData as SurveyData).work_experience === lessThanOneYearWorkEx) {
      showDomain = false
    }
  }

  const showFieldsObject = {
    area_of_study: showAreaOfStudy,
    domain: showDomain,
    career_option: showWhatBroughtYouHere,
    highest_qualification_percentage: showHighestQualificationPercentage,
    highest_qualification: showHighestQualification,
    university_id: showUniversity,
    university_name: showUniversity,
    college_id: showCollege,
    college_name: showCollege,
    study_abroad_consent: showStudyAbroadConsent,
  }

  const updateWorkExperience = (workExperience: string) => {
    mixpanel.track('LMS - GLA-WorkEx', {
      'Work Experience': workExperience,
    })
    updateInputValues({})
    updateSurveyType(
      getSurveyTypeData(
        workExperience,
        props.isInternationalUser,
        props.isReferralGiftCourse,
        currentCountryCode
      )
    )
    const surveyDataObj = {
      work_experience: workExperience,
      year_of_study: '',
    }
    if (
      workExperience === collegeStudentWorkEx &&
      props.userProfileData &&
      props.userProfileData.year_of_study
    ) {
      const current_year = new Date().getFullYear()
      if (props.userProfileData.year_of_study === current_year - 4) {
        surveyDataObj.year_of_study = `Before ${current_year - 3}`
      } else if (props.userProfileData.year_of_study === current_year + 4) {
        surveyDataObj.year_of_study = `After ${current_year + 3}`
      } else {
        surveyDataObj.year_of_study = props.userProfileData.year_of_study.toString()
      }
    }
    updateSurveyData(surveyDataObj)
    if (
      !props.isInternationalUser &&
      [collegeStudentWorkEx, graduateWorkEx].includes(workExperience) &&
      showUniversity
    ) {
      if (!(props.universitiesData && props.universitiesData.length))
        props.fetchUniversities()
    }
    updateGetUniversityName(false)
    updateGetCollegeName(false)
  }

  useEffect(() => {
    if (Object.values(surveyData).length) {
      let tempCanSubmit = true
      if (
        'work_experience' in surveyData &&
        surveyData.work_experience != null
      ) {
        const surveyTypeForWorkEx = getSurveyTypeData(
          surveyData.work_experience as string,
          props.isInternationalUser,
          props.isReferralGiftCourse,
          currentCountryCode
        )
        if ('career_option' in surveyTypeForWorkEx) {
          surveyTypeForWorkEx.career_option.isMandatory = showWhatBroughtYouHere
        }
        if ('area_of_study' in surveyTypeForWorkEx) {
          surveyTypeForWorkEx.area_of_study.isMandatory = showAreaOfStudy
        }
        if ('domain' in surveyTypeForWorkEx) {
          surveyTypeForWorkEx.domain.isMandatory = showDomain
        }

        if ('college_id' in surveyTypeForWorkEx) {
          surveyTypeForWorkEx.college_id.isMandatory =
            showCollege && surveyTypeForWorkEx.college_id.isMandatory
        }

        if ('university_id' in surveyTypeForWorkEx) {
          surveyTypeForWorkEx.university_id.isMandatory =
            showUniversity && surveyTypeForWorkEx.university_id.isMandatory
        }

        if ('highest_qualification_percentage' in surveyTypeForWorkEx) {
          surveyTypeForWorkEx.highest_qualification_percentage.isMandatory =
            showHighestQualificationPercentage &&
            surveyTypeForWorkEx.highest_qualification_percentage.isMandatory
        }

        if ('highest_qualification' in surveyTypeForWorkEx) {
          surveyTypeForWorkEx.highest_qualification.isMandatory =
            showHighestQualification &&
            surveyTypeForWorkEx.highest_qualification.isMandatory
        }
        if ('study_abroad_consent' in surveyTypeForWorkEx) {
          surveyTypeForWorkEx.study_abroad_consent.isMandatory = showStudyAbroadConsent
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(surveyTypeForWorkEx)) {
          if (
            value.isMandatory &&
            !(
              key in surveyData &&
              (Array.isArray(surveyData[key as SurveyKeys])
                ? surveyData[key as SurveyKeys].length
                : surveyData[key as SurveyKeys])
            )
          ) {
            tempCanSubmit = false
            break
          }
        }
      } else {
        tempCanSubmit = false
      }
      updateCanSubmit(tempCanSubmit)
    }
  }, [surveyData])

  useEffect(() => {
    if (
      props.workExperience &&
      workExpData.work_experience.items.includes(props.workExperience)
    ) {
      updateWorkExperience(props.workExperience)
    }
  }, [props.workExperience])

  const selectItem = (
    data: { title: string; items: string[]; isMandatory: boolean },
    value: string,
    onChange: (event: React.ChangeEvent<{ value: unknown }>) => void
  ) => {
    return (
      <FormControl
        variant="outlined"
        className={styles.select}
        required={data.isMandatory}
      >
        <InputLabel
          classes={{
            root: data.title.length > 40 ? styles.multiLineSelectLabel : '',
          }}
        >
          {data.title}
        </InputLabel>
        <Select
          value={value}
          onChange={onChange}
          label={data.title}
          color="primary"
        >
          {data.items.map(item => (
            <MenuItem
              value={item}
              classes={{
                root: styles.listItemRoot,
              }}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const singleCheckboxItem = (
    id: string,
    data: { title: string; items: string[]; isMandatory: boolean },
    onChange: (event: any) => void
  ) => {
    return (
      <FormControlLabel
        control={<Checkbox id={id} onChange={onChange} defaultChecked />}
        label={data.title}
      />
    )
  }

  const selectSingleItems = (
    id: string,
    label: string,
    data: Record<string, any>[],
    onChange: (event: any, value: any | null) => void,
    onInputChange: (event: any, value: any | null) => void = () => {},
    inputRef: React.RefObject<HTMLInputElement> | null = null
  ) => (
    <div id={`${id}-autocomplete`}>
      <Autocomplete
        multiple={false}
        id={id}
        options={data}
        getOptionLabel={option => option.name}
        filterSelectedOptions
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            inputRef={inputRef}
          />
        )}
        onChange={onChange}
        className={styles.select}
        classes={{
          ...classes,
          noOptions: styles.noOptions,
        }}
        onInputChange={(event, newInputValue) => {
          updateInputValues({
            ...inputValues,
            [id]: newInputValue,
          })
          onInputChange(event, newInputValue)
        }}
        inputValue={inputValues[id] || ''}
        noOptionsText="No results found. Please search with full name or select 'Others' option and proceed."
        openOnFocus
      />
    </div>
  )

  const selectMultipleItems = (
    id: string,
    data: { title: string; items: string[]; isMandatory: boolean },
    onChange: (event: any, value: string[]) => void
  ) => (
    <Autocomplete
      multiple
      id={`${id}-multiselect`}
      options={data.items}
      getOptionLabel={(option: string) => option}
      value={
        id in surveyData
          ? ((surveyData as SurveyData)[id as SurveyKeys] as string[])
          : []
      }
      filterSelectedOptions
      renderInput={params => (
        <TextField {...params} variant="outlined" label={data.title} />
      )}
      onChange={onChange}
      className={styles.select}
      classes={classes}
    />
  )

  const isOthers = (value: string): boolean =>
    !!(value && value.toLowerCase() === 'others')

  const othersField = (
    id: string,
    data: { title: string; items: string[]; isMandatory: boolean },
    inputRef: React.RefObject<HTMLInputElement> | null = null,
    placeholder: string = '',
    onOthersClick: () => void = () => {}
  ) => (
    <TextField
      className={styles.select}
      required={data.isMandatory}
      label={data.title}
      placeholder={placeholder}
      variant="outlined"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        updateSurveyData({
          ...surveyData,
          [id]: (event.target.value as string) || '',
        })
      }}
      inputProps={{
        maxLength: 120,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Button
              variant="contained"
              endIcon={<ClearIcon fontSize="small" />}
              onClick={() => {
                if (id in surveyData) {
                  delete (surveyData as SurveyData)[id as SurveyKeys]
                }
                if (onOthersClick) {
                  onOthersClick()
                }
              }}
            >
              Others
            </Button>
          </InputAdornment>
        ),
      }}
      inputRef={inputRef}
    />
  )

  const fetchCollegesForUniversity = (university_id: number) => {
    props.fetchColleges({ university_id })
  }

  const content = () => {
    if (surveyType) {
      return Object.entries(surveyType).map(question => {
        const keyString: SurveyKeys = question[0] as SurveyKeys
        if (keyString in showFieldsObject) {
          if (showFieldsObject[keyString as keyof typeof showFieldsObject]) {
            if (keyString === 'university_id') {
              if (
                !getUniversityName &&
                props.universitiesData &&
                props.universitiesData.length
              ) {
                return selectSingleItems(
                  'university-list',
                  question[1].title,
                  props.universitiesData,
                  (event: any, value: any | null) => {
                    if (value) {
                      fetchCollegesForUniversity(value.id)
                      updateSurveyData({
                        ...surveyData,
                        [keyString]: value.id,
                        college_id: '',
                      })
                      if (isOthers(value.name)) {
                        updateGetUniversityName(true)
                        setTimeout(() => {
                          if (universityNameRef && universityNameRef.current) {
                            universityNameRef.current.focus()
                          }
                        })
                      }
                    }
                  },
                  (event: any, value: any | null) => {
                    updateInputValues({
                      ...inputValues,
                      'university-list': value,
                      'college-list': '',
                    })

                    if (!value) {
                      updateSurveyData({
                        ...surveyData,
                        [keyString]: '',
                        college_id: '',
                      })
                      props.resetColleges()
                      updateGetCollegeName(false)
                      if ('college_name' in surveyData) {
                        delete (surveyData as SurveyData)[
                          'college_name' as SurveyKeys
                        ]
                      }
                    }
                  },
                  universityIdRef
                )
              }
              return null
            }

            if (keyString === 'university_name') {
              if (getUniversityName) {
                return othersField(
                  keyString,
                  question[1],
                  universityNameRef,
                  'Enter university name',
                  () => {
                    updateGetUniversityName(false)
                    setTimeout(() => {
                      if (universityIdRef && universityIdRef.current) {
                        universityIdRef.current.focus()
                      }
                    })
                  }
                )
              }
              return null
            }

            if (keyString === 'college_id') {
              if (
                !getCollegeName &&
                props.collegesData &&
                props.collegesData.length
              ) {
                return selectSingleItems(
                  'college-list',
                  question[1].title,
                  props.collegesData,
                  (event: any, value: any | null) => {
                    if (value) {
                      updateSurveyData({
                        ...surveyData,
                        [keyString]: value.id,
                      })
                      if (isOthers(value.name)) {
                        updateGetCollegeName(true)
                        setTimeout(() => {
                          if (collegeNameRef && collegeNameRef.current) {
                            collegeNameRef.current.focus()
                          }
                        })
                      }
                    }
                  },
                  (event: any, value: any | null) => {
                    if (!value) {
                      updateSurveyData({
                        ...surveyData,
                        [keyString]: '',
                      })
                    }
                  },
                  collegeIdRef
                )
              }
              return null
            }

            if (keyString === 'college_name') {
              if (getCollegeName) {
                return othersField(
                  keyString,
                  question[1],
                  collegeNameRef,
                  'Enter college name',
                  () => {
                    updateGetCollegeName(false)
                    setTimeout(() => {
                      if (collegeIdRef && collegeIdRef.current) {
                        collegeIdRef.current.focus()
                      }
                    })
                  }
                )
              }
              return null
            }

            if (keyString === 'highest_qualification_percentage') {
              const { highest_qualification } = surveyData as SurveyData
              const highest_qualification_items =
                ('highest_qualification' in surveyType &&
                  surveyType.highest_qualification.items) ||
                []
              return highest_qualification &&
                highest_qualification_items.length ? (
                <TextField
                  className={styles.select}
                  required={question[1].isMandatory}
                  label={`${
                    surveyType === collegeStudentSurveyData ? 'Latest ' : ''
                  }${
                    highest_qualification === highest_qualification_items[0]
                      ? 'Undergrad'
                      : 'Postgrad'
                  } ${question[1].title}`}
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = (event.target.value as string) || ''
                    if (!value || event.target.validity.valid) {
                      updateSurveyData({
                        ...surveyData,
                        [keyString]: value,
                      })
                    } else {
                      event.preventDefault()
                    }
                  }}
                  type="number"
                  inputProps={{
                    min: 0,
                    max: 100,
                    step: '0.01',
                  }}
                  value={
                    (surveyData as SurveyData).highest_qualification_percentage
                  }
                  helperText="If entering CGPA, convert to percentage"
                  FormHelperTextProps={{
                    classes: {
                      root: styles.helperText,
                    },
                  }}
                />
              ) : null
            }

            return selectItem(
              question[1],
              keyString in surveyData
                ? ((surveyData as SurveyData)[keyString] as string)
                : '',
              (event: React.ChangeEvent<{ value: unknown }>) =>
                updateSurveyData({
                  ...surveyData,
                  [keyString]: event.target.value as string,
                })
            )
          }
          return null
        }

        if (keyString === 'pg_consent') {
          if (!('pg_consent' in surveyData)) {
            updateSurveyData({
              ...surveyData,
              [keyString]: 'true',
            })
          }
          return singleCheckboxItem('pg_consent', question[1], (event: any) => {
            updateSurveyData({
              ...surveyData,
              [keyString]: event.target.checked.toString(),
            })
          })
        }
        return selectItem(
          question[1],
          keyString in surveyData
            ? ((surveyData as SurveyData)[keyString] as string)
            : '',
          (event: React.ChangeEvent<{ value: unknown }>) =>
            updateSurveyData({
              ...surveyData,
              [keyString]: event.target.value as string,
            })
        )
      })
    }
    return null
  }

  const internationalContent = () => {
    if (surveyType) {
      return Object.entries(surveyType).map(question => {
        const keyString: SurveyKeys = question[0] as SurveyKeys
        if (keyString === 'areas_of_interest') {
          return selectMultipleItems(
            keyString,
            question[1],
            (event: any, value: string[] = []) => {
              updateSurveyData({
                ...surveyData,
                [keyString]: value,
              })
            }
          )
        }

        if (keyString === 'career_option') {
          return showWhatBroughtYouHere
            ? selectItem(
                question[1],
                keyString in surveyData
                  ? ((surveyData as SurveyData)[keyString] as string)
                  : '',
                (event: React.ChangeEvent<{ value: unknown }>) =>
                  updateSurveyData({
                    ...surveyData,
                    [keyString]: event.target.value as string,
                  })
              )
            : null
        }

        if (keyString === 'pg_consent') {
          if (!('pg_consent' in surveyData)) {
            updateSurveyData({
              ...surveyData,
              [keyString]: 'true',
            })
          }
          return singleCheckboxItem('pg_consent', question[1], (event: any) => {
            updateSurveyData({
              ...surveyData,
              [keyString]: event.target.checked.toString(),
            })
          })
        }

        return selectItem(
          question[1],
          keyString in surveyData
            ? ((surveyData as SurveyData)[keyString] as string)
            : '',
          (event: React.ChangeEvent<{ value: unknown }>) =>
            updateSurveyData({
              ...surveyData,
              [keyString]: event.target.value as string,
            })
        )
      })
    }
    return null
  }

  let formHeading = ''
  let formSubHeading = 'This will help us recommend you better courses'
  let formTimeLimitHeading = 'Estimated time: Less than 2 mins'
  let buttonText = 'Submit'
  if (!props.isLoading) {
    if (props.fromCareerPlusBanner) {
      formHeading = 'CareerBoost Enrollment'
      formSubHeading = 'Please provide the following details to proceed'
    } else if (props.isReferralGiftCourse) {
      formHeading = 'Personalised Your Dashboard'
      formSubHeading = 'Help us to make this a great experience for you.'
      formTimeLimitHeading = 'Estimated time: Less than a few seconds'
      buttonText = 'Get Started'
    } else if (props.isFormReinitiated) {
      formHeading = 'Please verify your details'
      if (props.userProfileData && props.userProfileData.name) {
        formHeading = `${props.userProfileData.name}, please verify your details`
      }
      formSubHeading =
        'Share your latest profile details for personalised course recommendations that align with your goals'
    } else {
      formHeading = 'Course Recommendation Form'
    }
  }

  return (
    <Dialog open classes={{ paper: styles.dialog }}>
      <DialogTitle>{formHeading}</DialogTitle>
      <DialogContent className={styles.dialogContent}>
        {props.isLoading ? (
          <Loader
            text="Personalising Course Recommendations..."
            style={{ marginTop: '15%' }}
          />
        ) : (
          <Fragment>
            <Typography>{formSubHeading}</Typography>
            <Typography
              variant="caption"
              className={styles.formTimeLimitHeading}
            >
              {formTimeLimitHeading}
            </Typography>
            {selectItem(
              workExpData.work_experience,
              'work_experience' in surveyData
                ? (surveyData.work_experience as string)
                : '',
              (event: React.ChangeEvent<{ value: unknown }>) => {
                const work_ex = event.target.value as string
                updateWorkExperience(work_ex)
              }
            )}
            {props.isInternationalUser ? internationalContent() : content()}
            <Button
              variant="contained"
              color="primary"
              textTransform="uppercase"
              onClick={() => props.onSubmit(surveyData)}
              className={styles.button}
              disabled={!canSubmit}
            >
              {buttonText}
            </Button>
          </Fragment>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default FreeUserInfoDialog
