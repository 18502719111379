import React from 'react'
import { Typography } from '@material-ui/core'
import styles from './UnSupportedBrowser.module.css'
import {
  FirefoxIcon,
  ChromeIcon,
  SafariIcon,
  EdgeIcon,
} from '../../components/Utils/Icons/Icons'
import Link from '../../components/Utils/Link'

const UnSupportedBrowser = () => (
  <div className={styles.container}>
    <Typography variant="h4" className={styles.title}>
      This browser is not supported
    </Typography>
    <Typography variant="subtitle1">
      Please try one of these options to continue your learning experience
    </Typography>
    <div className={styles.icons}>
      <Link href="https://www.google.com/chrome/" className={styles.link}>
        <ChromeIcon className={styles.icon} />
        <Typography variant="caption">Chrome</Typography>
      </Link>
      <Link href="https://mozilla.org" className={styles.link}>
        <FirefoxIcon className={styles.icon} />
        <Typography variant="caption">Firefox</Typography>
      </Link>
      <Link
        href="https://support.apple.com/kb/DL612?viewlocale=en_IN&locale=en_IN"
        className={styles.link}
      >
        <SafariIcon className={styles.icon} />
        <Typography variant="caption">Safari</Typography>
      </Link>
      <Link
        href="https://filehippo.com/download_edge-browser"
        className={styles.link}
      >
        <EdgeIcon className={styles.icon} />
        <Typography variant="caption">Edge</Typography>
      </Link>
    </div>
  </div>
)

export default UnSupportedBrowser
