import { UserID } from 'common/types/user'
import { apiCall } from 'common/utils'
import {
  fetchAnswers,
  submitQaAction,
  deleteQaAction,
  submitQuestionAnswer,
  deleteQuestionAnswer,
  updateQuestionAnswer,
} from './QA.actions'

export const ZOOMOUT_API_ENDPOINT = `${process.env.REACT_APP_ASPIRE_API_URL}v1/zoomout`

export interface commonAPIParams {
  mentoredGroupSessionId: number
  lmsUserId: UserID
}

export const submitQuestionAnswerAPI = async (
  params: ReturnType<typeof submitQuestionAnswer>['payload'] &
    commonAPIParams & { communityId: null | string },
  signal: AbortSignal
) => {
  const body = JSON.stringify({
    user_id: params.lmsUserId,
    message: params.message,
    mgsId: params.mentoredGroupSessionId,
    uid: params.uid,
    parentId: params.parentId,
    communityId: params.communityId,
  })
  const response = await apiCall({
    url: `${ZOOMOUT_API_ENDPOINT}/qa_entries`,
    params: {
      signal,
      method: 'POST',
      body,
    },
    query: {
      user_id: params.lmsUserId,
    },
  })
  if (response.status === 200) return response.json()
  throw await response.json()
}

export const deleteQuestionAnswerAPI = async (
  params: ReturnType<typeof deleteQuestionAnswer>['payload'] & commonAPIParams,
  signal: AbortSignal
) => {
  const response = await apiCall({
    url: `${ZOOMOUT_API_ENDPOINT}/qa_entries/${params.entryId}`,
    params: {
      signal,
      method: 'DELETE',
    },
    query: {
      user_id: params.lmsUserId,
      mgsId: params.mentoredGroupSessionId,
    },
  })
  if (response.status === 200) return response.json()
  throw await response.json()
}

export const updateQuestionAnswerAPI = async (
  params: ReturnType<typeof updateQuestionAnswer>['payload'] & commonAPIParams,
  signal: AbortSignal
) => {
  const body = JSON.stringify({
    user_id: params.lmsUserId,
    message: params.message,
    mgsId: params.mentoredGroupSessionId,
  })
  const response = await apiCall({
    url: `${ZOOMOUT_API_ENDPOINT}/qa_entries/${params.id}`,
    params: {
      signal,
      method: 'PUT',
      body,
    },
    query: {
      user_id: params.lmsUserId,
    },
  })
  if (response.status === 200) return response.json()
  throw await response.json()
}

export const fetchAnswersAPI = async (
  params: ReturnType<typeof fetchAnswers>['payload'] & commonAPIParams,
  signal: AbortSignal
) => {
  const response = await apiCall({
    url: `${ZOOMOUT_API_ENDPOINT}/qa_entries/${params.questionId}`,
    params: {
      signal,
      method: 'GET',
    },
    query: {
      user_id: params.lmsUserId,
      mgsId: params.mentoredGroupSessionId,
    },
  })
  if (response.status === 200) return response.json()
  throw await response.json()
}
export const submitQaActionAPI = async (
  params: ReturnType<typeof submitQaAction>['payload'] & commonAPIParams,
  signal: AbortSignal
) => {
  const body = JSON.stringify({
    user_id: params.lmsUserId,
    mgsId: params.mentoredGroupSessionId,
    uid: params.uid,
    entryId: params.entryId,
    action: params.action,
  })
  const response = await apiCall({
    url: `${ZOOMOUT_API_ENDPOINT}/qa_actions`,
    params: {
      signal,
      method: 'POST',
      body,
    },
    query: {
      user_id: params.lmsUserId,
    },
  })
  if (response.status === 200) return response.json()
  throw await response.json()
}
export const deleteQaActionAPI = async (
  params: ReturnType<typeof deleteQaAction>['payload'] & commonAPIParams,
  signal: AbortSignal
) => {
  const body = JSON.stringify({
    user_id: params.lmsUserId,
    mgsId: params.mentoredGroupSessionId,
    uid: params.uid,
    entryId: params.entryId,
    action: params.action,
  })
  const response = await apiCall({
    url: `${ZOOMOUT_API_ENDPOINT}/qa_actions`,
    params: {
      signal,
      method: 'DELETE',
      body,
    },
    query: {
      user_id: params.lmsUserId,
    },
  })
  if (response.status === 200) return response.json()
  throw await response.json()
}
