export const DISCUSSION_ENTRIES_FETCH = 'DISCUSSION_ENTRIES_FETCH'
export const DISCUSSION_ENTRIES_FETCH_SUCCESS =
  'DISCUSSION_ENTRIES_FETCH_SUCCESS'
export const DISCUSSION_ENTRIES_FETCH_FAILURE =
  'DISCUSSION_ENTRIES_FETCH_FAILURE'

export const DISCUSSION_ENTRY_POST = 'DISCUSSION_ENTRY_POST'
export const DISCUSSION_ENTRY_POST_SUCCESS = 'DISCUSSION_ENTRY_POST_SUCCESS'
export const DISCUSSION_ENTRY_POST_FAILURE = 'DISCUSSION_ENTRY_POST_FAILURE'

export const DISCUSSION_ENTRY_UPDATE = 'DISCUSSION_ENTRY_UPDATE'
export const DISCUSSION_ENTRY_UPDATE_SUCCESS = 'DISCUSSION_ENTRY_UPDATE_SUCCESS'
export const DISCUSSION_ENTRY_UPDATE_FAILURE = 'DISCUSSION_ENTRY_UPDATE_FAILURE'

export const DISCUSSION_ENTRY_MARK_AS_READ = 'DISCUSSION_ENTRY_MARK_AS_READ'
export const DISCUSSION_ENTRY_MARK_AS_READ_SUCCESS =
  'DISCUSSION_ENTRY_MARK_AS_READ_SUCCESS'
export const DISCUSSION_ENTRY_MARK_AS_READ_FAILURE =
  'DISCUSSION_ENTRY_MARK_AS_READ_FAILURE'

export const DISCUSSION_ENTRY_RATING_UPDATE = 'DISCUSSION_ENTRY_RATING_UPDATE'
export const DISCUSSION_ENTRY_RATING_UPDATE_SUCCESS =
  'DISCUSSION_ENTRY_RATING_UPDATE_SUCCESS'
export const DISCUSSION_ENTRY_RATING_UPDATE_FAILURE =
  'DISCUSSION_ENTRY_RATING_UPDATE_FAILURE'
