export const GET_JUPYTER_LAB_STATUS = 'GET_JUPYTER_LAB_STATUS'
export const GET_JUPYTER_LAB_STATUS_SUCCESS = 'GET_JUPYTER_LAB_STATUS_SUCCESS'
export const GET_JUPYTER_LAB_STATUS_FAILURE = 'GET_JUPYTER_LAB_STATUS_FAILURE'
export const GET_JUPYTER_LAB_STATUS_CANCEL = 'GET_JUPYTER_LAB_STATUS_CANCEL'

export const START_JUPYTER_LAB = 'START_JUPYTER_LAB'
export const START_JUPYTER_LAB_SUCCESS = 'START_JUPYTER_LAB_SUCCESS'
export const START_JUPYTER_LAB_FAILURE = 'START_JUPYTER_LAB_FAILURE'
export const START_JUPYTER_LAB_CANCEL = 'START_JUPYTER_LAB_CANCEL'

export const STOP_JUPYTER_LAB = 'STOP_JUPYTER_LAB'
export const STOP_JUPYTER_LAB_SUCCESS = 'STOP_JUPYTER_LAB_SUCCESS'
export const STOP_JUPYTER_LAB_FAILURE = 'STOP_JUPYTER_LAB_FAILURE'
export const STOP_JUPYTER_LAB_CANCEL = 'STOP_JUPYTER_LAB_CANCEL'

export const GET_JUPYTER_NOTEBOOK = 'GET_JUPYTER_NOTEBOOK'
export const GET_JUPYTER_NOTEBOOK_SUCCESS = 'GET_JUPYTER_NOTEBOOK_SUCCESS'
export const GET_JUPYTER_NOTEBOOK_FAILURE = 'GET_JUPYTER_NOTEBOOK_FAILURE'
export const GET_JUPYTER_NOTEBOOK_CANCEL = 'GET_JUPYTER_NOTEBOOK_CANCEL'

export const GET_JUPYTER_LAB_DETAILS = 'GET_JUPYTER_LAB_DETAILS'
export const GET_JUPYTER_LAB_DETAILS_SUCCESS = 'GET_JUPYTER_LAB_DETAILS_SUCCESS'
export const GET_JUPYTER_LAB_DETAILS_FAILURE = 'GET_JUPYTER_LAB_DETAILS_FAILURE'
export const GET_JUPYTER_LAB_DETAILS_CANCEL = 'GET_JUPYTER_LAB_DETAILS_CANCEL'
