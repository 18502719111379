import { takeEvery, put, cancelled, take } from 'redux-saga/effects'
import * as types from './ExternalUrl.types'
import * as actions from './ExternalUrl.actions'
import { cancelable } from '../../../../../common/utils'
import { fetchModuleItem, MODULE_ITEM_FETCH_SUCCESS } from '..'

function* updateLinkPageViewHandler(
  action: ReturnType<typeof actions.updateLinkPageView>
) {
  const abortController = new AbortController()
  try {
    const { courseId, moduleId, itemId , disableLogAssetAccess} = action.payload
    const result = yield put(
      fetchModuleItem(
        { courseId, moduleId, itemId, disableLogAssetAccess },
        { courseId, moduleId, itemId }
      )
    )
    yield take(MODULE_ITEM_FETCH_SUCCESS)
    if (!disableLogAssetAccess) {
      yield put(actions.updateLinkPageViewSuccess(result, action.meta))
    }
  } catch (e) {
    yield put(actions.updateLinkPageViewFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* externalUrlMiddleware() {
  yield takeEvery(
    types.UPDATE_LINK_PAGE_VIEW,
    cancelable(updateLinkPageViewHandler, types.UPDATE_LINK_PAGE_VIEW_CANCEL)
  )
}

export default ([] as any).concat(externalUrlMiddleware())
