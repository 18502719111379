import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import {
  fetchUpcomingWebinarsSuccess,
  fetchUpcomingWebinarsFailure,
} from './UpcomingWebinars.action'
import * as APIs from './UpcomingWebinars.api'
import * as types from './UpcomingWebinars.types'

function* getUpcomingWebinars() {
  const abortController = new AbortController()
  try {
    const data = yield call(APIs.fetchUpcomingWebinars, abortController.signal)
    yield put(fetchUpcomingWebinarsSuccess(data))
  } catch (error) {
    yield put(fetchUpcomingWebinarsFailure(error))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* upcomingWebinarsMiddleware() {
  yield takeLatest(
    types.FETCH_UPCOMING_WEBINARS_DETAILS,
    cancelable(
      getUpcomingWebinars,
      types.FETCH_UPCOMING_WEBINARS_DETAILS_CANCEL
    )
  )
}

export default ([] as any).concat(upcomingWebinarsMiddleware())
