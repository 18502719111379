import { apiCall, getUserDetails } from 'common/utils'
import { isAcademyInternationalUser } from 'common/utils/custom/user'

import { fetchAllPrograms } from './AllPrograms.actions'

export async function fetchAllProgramsAPI(
  action: ReturnType<typeof fetchAllPrograms>,
  signal: AbortSignal
) {
  const { id, accessType } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_URL}api/v1/programs`,
    params: { signal },
    query: {
      user_id: id,
      is_intl: isAcademyInternationalUser(accessType),
      ...action.payload,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
