import { mixpanel } from 'common/utils/mixpanel'
import { useRef, useEffect, useState, useCallback } from 'react'
import {
  ScriptFileProps,
  LinkFileProps,
} from '../../../../common/types/loadfiles'

function useIsMounted(): () => boolean {
  // create a new ref for each component mount
  const ref = useRef(false)

  useEffect(() => {
    ref.current = true
    return () => {
      ref.current = false
    }
  }, [])
  // using useCallback to prevent the function from being recreated on each render unless ref.current changes
  // This avoids the useEffect (to load files) to run twice on each render.
  return useCallback(() => ref.current, [])
}

type ErrorState = ErrorEvent | null

interface ScriptLoadResult {
  element: HTMLScriptElement | undefined
  script: string
  success: boolean
  error: ErrorState
}

interface LinkLoadResult {
  element: HTMLLinkElement | undefined
  link: string
  success: boolean
  error: ErrorState
}

// function to create element(script/link) and attach event listeners to it
// and return a promise for the same
function loadFile({
  src,
  ...attr
}: ScriptFileProps | LinkFileProps): Promise<
  ScriptLoadResult | LinkLoadResult
> {
  return new Promise<ScriptLoadResult | LinkLoadResult>(resolve => {
    if (attr && attr.type && attr.type === 'text/css') {
      const link = document.createElement('link')
      link.href = src
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(attr)) {
        ;(link as any)[key] = value
      }
      link.addEventListener('load', () =>
        resolve({
          element: link,
          link: src,
          success: true,
          error: null,
        })
      )

      link.addEventListener('error', e => {
        resolve({
          element: link,
          link: src,
          success: false,
          error: e,
        })
      })
      document.head.appendChild(link)
    }
    if (attr && attr.type && attr.type === 'text/javascript') {
      const script = document.createElement('script')
      script.src = src
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(attr)) {
        ;(script as any)[key] = value
      }
      script.addEventListener('load', () => {
        resolve({
          element: script,
          script: src,
          success: true,
          error: null,
        })
      })
      script.addEventListener('error', e => {
        resolve({
          element: script,
          script: src,
          success: false,
          error: e,
        })
      })
      document.body.appendChild(script)
    }
  })
}

function getScriptTag(script: string): HTMLScriptElement | null {
  if (typeof document === 'undefined') {
    return null
  }

  return document.querySelector(`script[src='${script}']`) as HTMLScriptElement
}

function getLinkTag(link: string): HTMLLinkElement | null {
  if (typeof document === 'undefined') {
    return null
  }

  return document.querySelector(`link[href='${link}']`) as HTMLLinkElement
}

export default function useScript(
  files: (ScriptFileProps | LinkFileProps)[]
): { loading: boolean; error: ErrorState } {
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState(() =>
    files.some(file => !getScriptTag(file.src) || !getLinkTag(file.src))
  )
  const [error, setError] = useState<ErrorState>(null)

  /* function to either start a promise for a file or return a resoved promise
   if the element is already available in the DOM */
  const loadNewFile = async (
    loadFileProps: ScriptFileProps | LinkFileProps
  ): Promise<ScriptLoadResult | LinkLoadResult> => {
    if (
      loadFileProps &&
      loadFileProps.type &&
      loadFileProps.type === 'text/css'
    ) {
      const css = getLinkTag(loadFileProps.src)

      if (css) {
        const result: LinkLoadResult = {
          element: css,
          link: css.href,
          success: true,
          error: null,
        }

        return Promise.resolve(result)
      }
    }
    if (
      loadFileProps &&
      loadFileProps.type &&
      loadFileProps.type === 'text/javascript'
    ) {
      const script = getScriptTag(loadFileProps.src)

      if (script) {
        const result: ScriptLoadResult = {
          element: script,
          script: script.src,
          success: true,
          error: null,
        }

        return Promise.resolve(result)
      }
    }
    return loadFile(loadFileProps)
  }

  useEffect(() => {
    async function loadFiles() {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        // if the component is removed it will not load the rest of the files
        if (!isMounted()) {
          return
        }
        let define = undefined;
        if (window && window.define) {
          define = window.define
          window.define = undefined
        }
        // eslint-disable-next-line no-await-in-loop
        const result = await loadNewFile(file);
        if (window && define) (window as any).define = define
        if (!result.success) {
          // setError only when the file is mandatory
          if (file.mandatory) {
            setError(result.error)
            mixpanel.track('LMS - Errors', {
              error_action: 'Load scripts',
              script_src: file.src,
              error: result.error,
            })
            return
          }
        }
      }

      setLoading(false)
    }

    // No await here intentionally because hooks cannot be async functions
    loadFiles()
  }, [files, isMounted])

  return { loading, error }
}
