export const FETCH_REFERRALS_REWARDS_DETAILS = 'FETCH_REFERRALS_REWARDS_DETAILS'
export const FETCH_REFERRALS_REWARDS_DETAILS_SUCCESS =
  'FETCH_REFERRALS_REWARDS_DETAILS_SUCCESS'
export const FETCH_REFERRALS_REWARDS_DETAILS_FAILURE =
  'FETCH_REFERRALS_REWARDS_DETAILS_FAILURE'
export const FETCH_REFERRALS_REWARDS_DETAILS_CANCEL =
  'FETCH_REFERRALS_REWARDS_DETAILS_CANCEL'

export const CLAIM_REWARD = 'CLAIM_REWARD'
export const CLAIM_REWARD_SUCCESS = 'CLAIM_REWARD_SUCCESS'
export const CLAIM_REWARD_FAILURE = 'CLAIM_REWARD_FAILURE'
export const CLAIM_REWARD_CANCEL = 'CLAIM_REWARD_CANCEL'
