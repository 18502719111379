import { apiCall, getUserDetails } from '../../../../../common/utils'
import {
  fetchAssessmentProctorContent,
  fetchItemContent,
  postModuleItemActivity,
} from './ItemContent.actions'

export async function getItemContentAPI(
  action: ReturnType<typeof fetchItemContent>,
  include: string[],
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { url, ...queryParams } = action.payload
  const response = await apiCall({
    url,
    query: { ...queryParams, include, user_id: id },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getAssessmentProctorContentAPI(
  action: ReturnType<typeof fetchAssessmentProctorContent>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { assessment_id, assessment_type } = action.payload
  const query = {
    user_id: id,
    assessment_type,
  }
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/proctoring/assessments/${assessment_id}`,
    query,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function postModuleItemActivityAPI(
  action: ReturnType<typeof postModuleItemActivity>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { itemId, courseId } = action.meta
  const query = {
    user_id: id,
  }
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/learning_activities/courses/${courseId}/module_items/${itemId}/track`,
    query,
    params: { signal, method: 'POST' },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
