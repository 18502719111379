import { Box, Typography, Popover } from '@material-ui/core'
import cx from 'classnames'
import React from 'react'
import { mixpanel } from 'common/utils/mixpanel'
import { MovingIcon } from 'web/components/Utils/Icons/CustomIcons'
import styles from './CommunityHeader.module.css'
import { getCommunityActiveSpaceUrl } from 'common/utils/custom/community'

interface CommunityHeaderProps {
  isDrawer: boolean
  communityNotifications: number
  communityNewQuestions: number
}

const CommunityHeader = (props: CommunityHeaderProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleNotificationClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    window.location.href = getCommunityActiveSpaceUrl()
  }

  const handleNotificationClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'notification-popover' : undefined

  return (
    <>
      {(props.communityNotifications > 0 || props.communityNewQuestions > 0) &&
      !props.isDrawer ? (
        <div className={styles.notificationsContainer}>
          <div
            className={cx(
              styles.button,
              styles.label,
              styles.label,
              styles.displayFlex,
              styles.communityText
            )}
          >
            <a
              href={getCommunityActiveSpaceUrl()}
              className={styles.anchorTag}
              target="_blank"
            >
              Community
            </a>
            {props.communityNotifications > 0 ? (
              <Typography
                className={styles.newHeaderCount}
                aria-describedby={id}
                onClick={handleNotificationClick}
              >
                {props.communityNotifications}
              </Typography>
            ) : (
              <Typography
                className={styles.communityBadgeParent}
                aria-describedby={id}
                onClick={handleNotificationClick}
              >
                <MovingIcon className={styles.communityBadgeIcon} />
              </Typography>
            )}
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleNotificationClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            className={styles.marginTop}
          >
            <div className={styles.resultsContainer}>
              {props.communityNewQuestions > 0 ? (
                <a
                  href={getCommunityActiveSpaceUrl()}
                  className={styles.badgeNotification}
                >
                  <Typography className={styles.badgeIconBox}>
                    <MovingIcon className={styles.badgeIcon} />
                  </Typography>
                  <div>
                    <Typography
                      className={styles.communityNotificationPrimaryRow}
                    >
                      New Questions
                    </Typography>
                    <Typography
                      className={styles.communityNotificationSecondaryRow}
                    >
                      Check them out
                    </Typography>
                  </div>
                </a>
              ) : null}
            </div>
          </Popover>
        </div>
      ) : (
        <Box
          onClick={() => mixpanel.track('community_header_link_clicked', {})}
        >
          <a
            href={getCommunityActiveSpaceUrl()}
            className={cx(styles.button, styles.label, styles.label)}
          >
            <span className={styles.anchorTag}>Community</span>
            <span className={styles.newHeader}>New</span>
          </a>
        </Box>
      )}
    </>
  )
}

export default CommunityHeader
