export const FETCH_EPORTFOLIO = 'FETCH_EPORTFOLIO'
export const FETCH_EPORTFOLIO_SUCCESS = 'FETCH_EPORTFOLIO_SUCCESS'
export const FETCH_EPORTFOLIO_FAILURE = 'FETCH_EPORTFOLIO_FAILURE'

export const TOGGLE_PROJECT_VISIBILITY = 'TOGGLE_PROJECT_VISIBILITY'
export const TOGGLE_PROJECT_VISIBILITY_SUCCESS =
  'TOGGLE_PROJECT_VISIBILITY_SUCCESS'
export const TOGGLE_PROJECT_VISIBILITY_FAILURE =
  'TOGGLE_PROJECT_VISIBILITY_FAILURE'

export const UPDATE_PROJECT_LEARNINGS = 'UPDATE_PROJECT_LEARNINGS'
export const UPDATE_PROJECT_LEARNINGS_SUCCESS =
  'UPDATE_PROJECT_LEARNINGS_SUCCESS'
export const UPDATE_PROJECT_LEARNINGS_FAILURE =
  'UPDATE_PROJECT_LEARNINGS_FAILURE'

export const FETCH_USER_BY_EPORTFOLIO_USERNAME = 'FETCH_USER_BY_EPORTFOLIO_USERNAME'
export const FETCH_USER_BY_EPORTFOLIO_USERNAME_SUCCESS = 'FETCH_USER_BY_EPORTFOLIO_USERNAME_SUCCESS'
export const FETCH_USER_BY_EPORTFOLIO_USERNAME_FAILURE = 'FETCH_USER_BY_EPORTFOLIO_USERNAME_FAILURE'
