import { Reducer } from 'redux'
import { ProgramCategoryData } from 'common/types/programCategory'
import * as types from './ProgramCategory.types'
import { ProgramCategoryActions } from './ProgramCategory.actions'

export type ProgramCategoryStateData = ProgramCategoryData['program_categories']

export type ProgramCategoryState = Readonly<{
  data: ProgramCategoryStateData
  loading: boolean
  loaded: boolean
  error: boolean | Error
}>

const initialState: ProgramCategoryState = {
  data: [],
  loading: false,
  loaded: false,
  error: false,
}

const programCategoryReducer: Reducer<
  ProgramCategoryState,
  ProgramCategoryActions
> = (state = initialState, action): ProgramCategoryState => {
  switch (action.type) {
    case types.FETCH_PROGRAM_CATEGORY_DETAILS: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      }
    }
    case types.FETCH_PROGRAM_CATEGORY_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.program_categories || [],
        loading: false,
        loaded: true,
        error: false,
      }
    }
    case types.FETCH_PROGRAM_CATEGORY_DETAILS_CANCEL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false,
      }
    }
    case types.FETCH_PROGRAM_CATEGORY_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      }
    }
    default:
      return state
  }
}

export default programCategoryReducer
