import { apiCall, getUserDetails } from '../../../common/utils'
import {
  fetchPeerReviewFilters,
  fetchPeerReviewReactionData,
  fetchPeerReviews,
  reactToPeerReview,
} from './PeerReviews.actions'

export async function getPeerReviewsAPI(
  action: ReturnType<typeof fetchPeerReviews>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const currentPage = action.page || 1
  const { assignment_id } = action
  const pageSize = 15

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/peer_reviews`,
    params: { signal },
    query: {
      user_id: id,
      page: currentPage,
      page_size: pageSize,
      assignment_id,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function reactToPeerReviewApi(
  action: ReturnType<typeof reactToPeerReview>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const peer_review_id = action.peerReviewId
  const peerReviewMetaParams = {
    is_liked: action.isLiked,
    user_id: id,
  }

  const body: string = JSON.stringify(peerReviewMetaParams)

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/peer_reviews/${peer_review_id}/meta`,
    params: { method: 'POST', body },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getPeerReviewReactionData(
  action: ReturnType<typeof fetchPeerReviewReactionData>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const peer_review_id = action.peerReviewId

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/peer_reviews/${peer_review_id}/meta_details`,
    params: { signal },
    query: { user_id: id, peer_review_id },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getPeerReviewFilters(
  action: ReturnType<typeof fetchPeerReviewFilters>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/peer_reviews/filters`,
    params: { signal },
    query: { user_id: id },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
