import { ModuleID } from 'common/types'
import { CourseID } from 'common/types/courses'
import { ItemID } from 'common/types/courses/moduleItem'
import { QuizID, QuizSubmissionData } from 'common/types/courses/quiz'
import { DigitalExcelerateTestData } from 'common/types/excelerate/tests'
import { mixpanel } from 'common/utils/mixpanel'
import {
  call,
  cancelled,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'
import { fetchItemContent } from 'web/providers/Courses/ModuleItemsProvider'
import {
  ITEM_CONTENT_FETCH_FAILURE,
  ITEM_CONTENT_FETCH_SUCCESS,
} from 'web/providers/Courses/ModuleItemsProvider/ItemContent'
import {
  fetchQuizSubmissions,
  resumeQuizSubmission,
  startQuizSubmission,
} from 'web/providers/Courses/ModuleItemsProvider/Quiz/QuizSubmissionsProvider'
import * as quizTypes from 'web/providers/Courses/ModuleItemsProvider/Quiz/QuizSubmissionsProvider/QuizSubmissions.types'
import { AppState } from 'web/store'
import { DigitalExcelerateTestsState } from '.'
import { BooleanFilter, cancelable } from '../../../../common/utils'
import {
  excelerateQuizTestSubmission,
  excelerateQuizTestSubmissionFailure,
  excelerateQuizTestSubmissionSuccess,
  fetchExcelerateTestDetails,
  fetchExcelerateTestDetailsFailure,
  fetchExcelerateTestDetailsSuccess,
} from './Tests.actions'
import {
  excelerateQuizTestSubmissionAPI,
  fetchExcelerateTestDetailsAPI,
} from './Tests.api'
import {
  EXCELERATE_QUIZ_TEST_SUBMISSION,
  EXCELERATE_QUIZ_TEST_SUBMISSION_CANCEL,
  FETCH_EXCELERATE_TEST_DETAILS,
  FETCH_EXCELERATE_TEST_DETAILS_CANCEL,
} from './Tests.types'

function* quizAssessmentHandler(quizData: {
  courseId: CourseID
  moduleId: ModuleID
  itemId: ItemID
  quizId: QuizID
}) {
  const { courseId, moduleId, itemId, quizId } = quizData
  yield put(
    fetchItemContent(
      {
        url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/quizzes/${quizId}`,
      },
      { itemId }
    )
  )
  const res = yield take([
    ITEM_CONTENT_FETCH_SUCCESS,
    ITEM_CONTENT_FETCH_FAILURE,
  ])
  if (res.type === ITEM_CONTENT_FETCH_FAILURE) {
    throw new Error()
  }
  yield put(fetchQuizSubmissions(null, { courseId, itemId, quizId }))
  const res2 = yield take([
    quizTypes.QUIZ_SUBMISSIONS_FETCH_SUCCESS,
    quizTypes.QUIZ_SUBMISSIONS_FETCH_FAILURE,
  ])
  if (res2.type === quizTypes.QUIZ_SUBMISSIONS_FETCH_FAILURE) {
    throw new Error()
  }
  const submissionData = yield select((state: AppState) => {
    const itemData = state.moduleItems.data.byId[itemId]
    if (itemData) {
      return itemData.itemActivity.submissions
        ? Object.values(itemData.itemActivity.submissions).filter(BooleanFilter)
        : []
    }
    return []
  })
  const activeSubmission = submissionData.find(
    (sub: QuizSubmissionData) =>
      sub.workflow_state === 'untaken' &&
      sub.overdue_and_needs_submission !== true
  )
  if (activeSubmission) {
    yield put(
      resumeQuizSubmission(null, {
        courseId,
        itemId,
        quizId,
        proctored: false,
        timeLimit: true,
        anomalyDetection: false,
        openBook: false,
      })
    )
    const res3 = yield take([
      quizTypes.QUIZ_SUBMISSION_RESUME_SUCCESS,
      quizTypes.QUIZ_SUBMISSION_RESUME_FAILURE,
    ])
    if (res3.type === quizTypes.QUIZ_SUBMISSION_RESUME_FAILURE) {
      throw new Error()
    }
  } else {
    yield put(
      startQuizSubmission(null, {
        courseId,
        itemId,
        quizId,
        moduleId,
        proctored: false,
        timeLimit: true,
        anomalyDetection: false,
        openBook: false,
      })
    )
    const res4 = yield take([
      quizTypes.QUIZ_SUBMISSION_START_SUCCESS,
      quizTypes.QUIZ_SUBMISSION_START_FAILURE,
    ])
    if (res4.type === quizTypes.QUIZ_SUBMISSION_START_FAILURE) {
      throw new Error()
    }
  }
}

function* fetchExcelerateTestDetailsHandler(
  action: ReturnType<typeof fetchExcelerateTestDetails>
) {
  const abortController = new AbortController()
  try {
    let data: DigitalExcelerateTestData = yield call(
      fetchExcelerateTestDetailsAPI,
      action,
      abortController.signal
    )
    try {
      if (data.status === 'locked') {
        window.location.href = data.return_to // redirect if test is locked
        return
      }
      data = {
        ...data,
        item_id: `excelerate_item_${action.meta.testId}`,
        module_id: `excelerate_module_${action.meta.testId}`,
      }
      if (data.assessment_type === 'Quiz') {
        yield quizAssessmentHandler({
          courseId: data.course_id,
          moduleId: data.module_id,
          itemId: data.item_id,
          quizId: data.assessment_id,
        })
      }
    } catch (e) {
      window.location.href = data.return_to
      throw new Error(e)
    }
    yield put(fetchExcelerateTestDetailsSuccess(data, action.meta))
  } catch (e) {
    yield put(fetchExcelerateTestDetailsFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
function* excelerateQuizTestSubmissionHandler(
  action: ReturnType<typeof excelerateQuizTestSubmission>
) {
  const abortController = new AbortController()
  try {
    const res = yield take([
      quizTypes.QUIZ_SUBMISSION_COMPLETE_SUCCESS,
      quizTypes.QUIZ_SUBMISSION_COMPLETE_FAILURE,
    ])
    const testData: DigitalExcelerateTestsState['byId']['x'] = yield select(
      (state: AppState) =>
        state.digitalExcelerate.tests.byId[action.meta.testId]
    )
    if (!testData) {
      throw new Error()
    }
    if (testData.data) {
      if (res.type === quizTypes.QUIZ_SUBMISSION_COMPLETE_SUCCESS) {
        try {
          yield call(
            excelerateQuizTestSubmissionAPI,
            action.payload.testId,
            {
              skill_assessment_test_id: action.meta.testId,
              quiz_submission_id: res.payload.id,
              attempt: res.payload.attempt,
            },
            abortController.signal
          )
          mixpanel.track('Excelerate test submission', {
            course_id:
              testData.data && 'course_id' in testData.data
                ? testData.data.course_id
                : '',
            quiz_id:
              testData.data && 'assessment_id' in testData.data
                ? testData.data.assessment_id
                : '',
            submission_id: res.payload.id,
            attempt: res.payload.attempt,
            test_id: action.payload.testId,
            test_type:
              testData.data && 'test_type' in testData.data
                ? testData.data.test_type
                : '',
          })
          window.location.href = testData.data.return_to
        } catch (e) {
          window.location.href = testData.data.return_to
          throw new Error(e)
        }
      }
    }
    yield put(excelerateQuizTestSubmissionSuccess(null, action.meta))
  } catch (e) {
    yield put(excelerateQuizTestSubmissionFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fetchExcelerateTestsMiddleware() {
  yield takeLatest(
    FETCH_EXCELERATE_TEST_DETAILS,
    cancelable(
      fetchExcelerateTestDetailsHandler,
      FETCH_EXCELERATE_TEST_DETAILS_CANCEL
    )
  )
  yield takeLatest(
    EXCELERATE_QUIZ_TEST_SUBMISSION,
    cancelable(
      excelerateQuizTestSubmissionHandler,
      EXCELERATE_QUIZ_TEST_SUBMISSION_CANCEL
    )
  )
}

export default ([] as any).concat(fetchExcelerateTestsMiddleware())
