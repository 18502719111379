import { UserID } from 'common/types/user'
import { cancelable } from 'common/utils'
import { call, cancelled, put, select, takeEvery } from 'redux-saga/effects'
import { AppState } from 'web/store'
import {
  fetchAllPollsFailure,
  fetchAllPollsSuccess,
  fetchPollById,
  fetchPollByIdFailure,
  fetchPollByIdSuccess,
  updatePollUnReadCount,
} from './Polls.actions'
import { fetchZoomoutPollAPI, fetchZoomoutPollByIdAPI } from './Polls.api'
import {
  FETCH_ALL_POLLS,
  FETCH_ALL_POLLS_CANCEL,
  FETCH_POLL_BY_ID,
  FETCH_POLL_BY_ID_CANCEL,
} from './Polls.types'

export function* fetchPollsHandler() {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )

    const mentoredGroupSessionId: number = yield select(
      (state: AppState) => state.zoomout.external.mentoredGroupSessionId
    )

    const data = yield call(
      fetchZoomoutPollAPI,
      userId,
      mentoredGroupSessionId,
      abortController.signal
    )

    yield put(fetchAllPollsSuccess(data))
  } catch (error) {
    yield put(fetchAllPollsFailure(error as Error))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fetchPollByIdHandler(
  action: ReturnType<typeof fetchPollById>
) {
  const abortController = new AbortController()
  try {
    const pollId = action.payload
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )

    const mentoredGroupSessionId: number = yield select(
      (state: AppState) => state.zoomout.external.mentoredGroupSessionId
    )

    const data = yield call(
      fetchZoomoutPollByIdAPI,
      pollId,
      userId,
      mentoredGroupSessionId,
      abortController.signal
    )

    yield put(fetchPollByIdSuccess(data, pollId))

    const unreadPolls: number = yield select(
      (state: AppState) => state.zoomout.polls.unreadCount
    )
    yield put(updatePollUnReadCount(unreadPolls + 1))
  } catch (error) {
    yield put(fetchPollByIdFailure(error as Error))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* PollsMiddleware() {
  yield takeEvery(
    FETCH_ALL_POLLS,
    cancelable(fetchPollsHandler, FETCH_ALL_POLLS_CANCEL)
  )

  yield takeEvery(
    FETCH_POLL_BY_ID,
    cancelable(fetchPollByIdHandler, FETCH_POLL_BY_ID_CANCEL)
  )
}

export default ([] as any).concat(PollsMiddleware())
