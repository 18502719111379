import { UserID } from 'common/types/user'
import * as types from './UserDetails.types'
import { UserDetailsState } from './UserDetails.reducer'

export const fetchUserProfileDetails = (payload?: {
  include_feature_detail?: boolean
}) => ({
  type: types.USER_PROFILE_DETAILS_FETCH as typeof types.USER_PROFILE_DETAILS_FETCH,
  payload,
})

export const fetchUserProfileDetailsSuccess = (payload: any) => ({
  type: types.USER_PROFILE_DETAILS_FETCH_SUCCESS as typeof types.USER_PROFILE_DETAILS_FETCH_SUCCESS,
  payload,
})

export const fetchUserProfileDetailsFailure = (payload: any) => ({
  type: types.USER_PROFILE_DETAILS_FETCH_FAILURE as typeof types.USER_PROFILE_DETAILS_FETCH_FAILURE,
  payload,
})

export const fetchUserProfileDetailsCancel = (payload: Error) => ({
  type: types.USER_PROFILE_DETAILS_FETCH_CANCEL as typeof types.USER_PROFILE_DETAILS_FETCH_CANCEL,
  payload,
})

export const fetchCommunicationChannelDetails = () => ({
  type: types.FETCH_COMMUNICATION_CHANNEL_DETAILS as typeof types.FETCH_COMMUNICATION_CHANNEL_DETAILS,
})

export const fetchCommunicationChannelDetailsSuccess = (payload: any) => ({
  type: types.FETCH_COMMUNICATION_CHANNEL_DETAILS_SUCCESS as typeof types.FETCH_COMMUNICATION_CHANNEL_DETAILS_SUCCESS,
  payload,
})

export const fetchCommunicationChannelDetailsFailure = (payload: any) => ({
  type: types.FETCH_COMMUNICATION_CHANNEL_DETAILS_FAILURE as typeof types.FETCH_COMMUNICATION_CHANNEL_DETAILS_FAILURE,
  payload,
})

export const fetchCommunicationChannelDetailsCancel = (payload: Error) => ({
  type: types.FETCH_COMMUNICATION_CHANNEL_DETAILS_CANCEL as typeof types.FETCH_COMMUNICATION_CHANNEL_DETAILS_CANCEL,
  payload,
})

export const updateEmailVerfiedStatus = () => ({
  type: types.UPDATE_EMAIL_VERIFIED_STATUS as typeof types.UPDATE_EMAIL_VERIFIED_STATUS,
})

export const resendEmailLink = () => ({
  type: types.RESEND_EMAIL_LINK as typeof types.RESEND_EMAIL_LINK,
})

export const resendEmailLinkSuccess = (payload: any) => ({
  type: types.RESEND_EMAIL_LINK_SUCCESS as typeof types.RESEND_EMAIL_LINK_SUCCESS,
  payload,
})

export const resendEmailLinkFailure = (payload: any) => ({
  type: types.RESEND_EMAIL_LINK_FAILURE as typeof types.RESEND_EMAIL_LINK_FAILURE,
  payload,
})

export const resendEmailLinkCancel = (payload: Error) => ({
  type: types.RESEND_EMAIL_LINK_CANCEL as typeof types.RESEND_EMAIL_LINK_CANCEL,
  payload,
})

export const updateUserDetails = (payload: Partial<UserDetailsState>) => ({
  type: types.UPDATE_USER_DETAILS as typeof types.UPDATE_USER_DETAILS,
  payload,
})

export const logoutUser = () => ({
  type: types.USER_LOGOUT as typeof types.USER_LOGOUT,
})

export const logoutUserSuccess = () => ({
  type: types.USER_LOGOUT_SUCCESS as typeof types.USER_LOGOUT_SUCCESS,
})

export const logoutUserFailure = (payload: Error) => ({
  type: types.USER_LOGOUT_FAILURE as typeof types.USER_LOGOUT_FAILURE,
  payload,
})

export const loginUserSuccess = (payload: { accessToken: string }) => ({
  type: types.USER_LOGIN_SUCCESS as typeof types.USER_LOGIN_SUCCESS,
  payload,
})

export type EngagementEventPayload = {
  user_email: string
  program: string
  user_stage: string
  type: 'course_show' | 'video_played' | 'video_progress'
  course_name: string
  video_name?: string
  video_progress?: number
  user_id: UserID
}

export const sendEngagementEvent = (payload: EngagementEventPayload) => ({
  type: types.UPDATE_PREBATCH_ENGAGEMENT_SCORE as typeof types.UPDATE_PREBATCH_ENGAGEMENT_SCORE,
  payload,
})

export type UserDetailsActions =
  | ReturnType<typeof fetchUserProfileDetails>
  | ReturnType<typeof fetchUserProfileDetailsSuccess>
  | ReturnType<typeof fetchUserProfileDetailsFailure>
  | ReturnType<typeof fetchUserProfileDetailsCancel>
  | ReturnType<typeof fetchCommunicationChannelDetails>
  | ReturnType<typeof fetchCommunicationChannelDetailsSuccess>
  | ReturnType<typeof fetchCommunicationChannelDetailsFailure>
  | ReturnType<typeof fetchCommunicationChannelDetailsCancel>
  | ReturnType<typeof updateEmailVerfiedStatus>
  | ReturnType<typeof resendEmailLink>
  | ReturnType<typeof resendEmailLinkSuccess>
  | ReturnType<typeof resendEmailLinkFailure>
  | ReturnType<typeof resendEmailLinkCancel>
  | ReturnType<typeof updateUserDetails>
  | ReturnType<typeof logoutUser>
  | ReturnType<typeof logoutUserSuccess>
  | ReturnType<typeof logoutUserFailure>
  | ReturnType<typeof loginUserSuccess>
  | ReturnType<typeof sendEngagementEvent>
