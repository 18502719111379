import {
  FormControl,
  Typography,
  Select,
  Tooltip,
  ListSubheader,
  MenuItem,
} from '@material-ui/core'
import { ProgramID, ProgramsData, ProgramData } from 'common/types/programs'
import React, { useEffect, useState } from 'react'
import styles from './ProgramSwitch.module.css'

const GL_CAREER_SCHOOL_KEY = 'Great Learning CareerSchool'

interface Props {
  handleDrawerToggle: () => void
  programsData: ProgramsData
  onProgramChange: (programId: ProgramID) => void
  isDrawer: boolean
}
const ProgramSwitch = (props: Props) => {
  const [programsInSelect, updateProgramsInSelect] = useState<
    Record<string, (ProgramData | { subHeader: string })[]>
  >({})

  useEffect(() => {
    const { programsData } = props
    let { enrolledProgramIDs } = programsData
    if (
      programsData.glcaAssessmentProgramId &&
      programsData.activeProgramID != programsData.glcaAssessmentProgramId
    ) {
      enrolledProgramIDs = enrolledProgramIDs.filter(
        id => id != programsData.glcaAssessmentProgramId
      )
    }

    if (enrolledProgramIDs.length > 1) {
      const programsList: Record<
        string,
        (ProgramData | { subHeader: string })[]
      > = enrolledProgramIDs.reduce(
        (acc, programId: ProgramID) => {
          const programIdData = programsData.byId[programId]
          if (programIdData) {
            let key = programIdData.degree_program_code || programIdData.code
            if (programIdData.program_type === 'career_track') {
              key = GL_CAREER_SCHOOL_KEY
              if (!acc[key])
                acc[key] = [
                  {
                    ...programIdData,
                  },
                ]
            } else if (acc[key]) acc[key].push(programIdData)
            else {
              acc[key] = [
                ...(programIdData.program_type === 'semester_program' &&
                programIdData.degree_program_display_code
                  ? [
                      {
                        subHeader: programIdData.degree_program_display_code,
                      },
                    ]
                  : []),
                programIdData,
              ]
            }
          }
          return acc
        },
        {} as Record<string, (ProgramData | { subHeader: string })[]>
      )
      const programsListKeys = Object.keys(programsList)
      if (
        programsListKeys.length > 1 ||
        (programsListKeys.length === 1 &&
          programsListKeys[0] !== GL_CAREER_SCHOOL_KEY)
      ) {
        updateProgramsInSelect(programsList)
      }
    }
  }, [props.programsData])

  const selectedProgramValue = (programsData: ProgramsData) => {
    if (
      programsData.activeProgramID &&
      programsData.byId[programsData.activeProgramID]
    ) {
      const programData = programsData.byId[programsData.activeProgramID]
      if (programData && programData.program_type === 'semester_program')
        return (
          <Typography variant="body2" noWrap className={styles.dashboardName}>
            {programData.degree_program_display_code}
            <span className={styles.semesterNumberText}>
              {programData!.semester_key_display_name} {programData!.semester_number}
            </span>
          </Typography>
        )
      return (
        <Typography variant="body2" noWrap className={styles.dashboardName}>
          {programData!.program_type === 'career_track'
            ? 'Great Learning CareerSchool'
            : `${programData!.display_code}`}
        </Typography>
      )
    }
    return <span></span>
  }

  return Object.keys(programsInSelect).length ? (
    <FormControl>
      <Select
        renderValue={() => (
          <Tooltip title="Switch your Dashboards">
            {selectedProgramValue(props.programsData)}
          </Tooltip>
        )}
        value={props.programsData.activeProgramID}
        displayEmpty
        onChange={event => {
          if (props.isDrawer) props.handleDrawerToggle()
          if (event.target.value !== props.programsData.activeProgramID)
            props.onProgramChange(event.target.value as ProgramID)
        }}
        disableUnderline
        classes={{ select: styles.selectedProgram, icon: styles.selectIcon }}
        className={styles.programSwitch}
        MenuProps={{ PopoverClasses: { paper: styles.popover } }}
      >
        {Object.keys(programsInSelect)
          .sort()
          .map((key: string) =>
            programsInSelect[key].map(
              (data: ProgramData | { subHeader: string }) => {
                if ('subHeader' in data)
                  return (
                    <ListSubheader className={styles.subHeader}>
                      <Typography variant="body2">{data.subHeader}</Typography>
                    </ListSubheader>
                  )
                if (data.program_type === 'semester_program')
                  return (
                    <MenuItem value={data.id} className={styles.semesterNumber}>
                      <Typography
                        variant="body2"
                        className={styles.listDashboardName}
                      >
                        {data.semester_key_display_name} {data.semester_number}
                      </Typography>
                    </MenuItem>
                  )
                return (
                  <MenuItem value={data.id}>
                    <Typography
                      variant="body2"
                      className={styles.listDashboardName}
                    >
                      {data.program_type === 'career_track'
                        ? 'Great Learning CareerSchool'
                        : `${data.display_code} (${data.group})`}
                    </Typography>
                  </MenuItem>
                )
              }
            )
          )}
      </Select>
    </FormControl>
  ) : null
}
export default ProgramSwitch
