import { mixpanel } from 'common/utils/mixpanel'
import { select, call, put, takeLatest, delay } from 'redux-saga/effects'
import { AppState } from 'web/store'
import { UserDetailsState } from '../User/UserDetailsProvider/UserDetails.reducer'
import { fetchCurrentTimeSuccess, removeCurrentTime } from './Utils.actions'
import * as APIs from './Utils.api'
import * as types from './Utils.types'

function* getCurrentTimeHandler() {
  try {
    const userId: UserDetailsState['id'] = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data = yield call(APIs.getCurrentTimeAPI, { userId })
    yield put(fetchCurrentTimeSuccess(data))
    yield delay(2000)
    yield put(removeCurrentTime())
  } catch (e) {
    mixpanel.track('LMS - Errors', {
      error_message: e.message,
      error_action: 'Current DateTime Error',
    })
  }
}

function* utilMiddleware() {
  yield takeLatest(types.FETCH_CURRENT_TIME, getCurrentTimeHandler)
}

export default ([] as any).concat(utilMiddleware())
