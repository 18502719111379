export const FETCH_CITIES = 'FETCH_CITIES'
export const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS'
export const FETCH_CITIES_FAILURE = 'FETCH_CITIES_FAILURE'
export const FETCH_CITIES_CANCEL = 'FETCH_CITIES_CANCEL'

export const FETCH_COLLEGES = 'FETCH_COLLEGES'
export const FETCH_COLLEGES_SUCCESS = 'FETCH_COLLEGES_SUCCESS'
export const FETCH_COLLEGES_FAILURE = 'FETCH_COLLEGES_FAILURE'
export const FETCH_COLLEGES_CANCEL = 'FETCH_COLLEGES_CANCEL'
export const RESET_COLLEGES = 'RESET_COLLEGES'

export const FETCH_UNIVERSITIES = 'FETCH_UNIVERSITIES'
export const FETCH_UNIVERSITIES_SUCCESS = 'FETCH_UNIVERSITIES_SUCCESS'
export const FETCH_UNIVERSITIES_FAILURE = 'FETCH_UNIVERSITIES_FAILURE'
export const FETCH_UNIVERSITIES_CANCEL = 'FETCH_UNIVERSITIES_CANCEL'
