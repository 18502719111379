import {
  ILocalAudioTrack,
  IMicrophoneAudioTrack,
  IRemoteAudioTrack,
} from 'agora-rtc-sdk-ng'
import {
  IPeerInfo,
  PARTICIPANT_ROLES,
  PeerDetails,
  ZoomoutUIEvents,
} from 'common/types/zoomout'
import { ZoomoutInternalState } from 'web/providers/Zoomout/Internal'
import { updateUserInterface } from 'web/providers/Zoomout/Internal/Internal.actions'
import rootStore from 'web/store'
import zoomoutSettings from './custom/Zoomout/settings'
import { mixpanel } from './mixpanel'

export const HARD_AGORA_HOST_LIMIT = 125
export const SOFT_AGORA_HOST_LIMIT = 30

export const getPeerIdentification = (uid?: number): IPeerInfo => {
  const { peerDetails } = rootStore.getState().zoomout.external
  if (
    peerDetails &&
    Object.keys(peerDetails).length > 0 &&
    uid &&
    uid in peerDetails &&
    typeof peerDetails[uid] !== 'undefined'
  ) {
    return peerDetails[uid]
  }

  return {
    uid,
    name: `Olympus User ${(Math.random() * 100).toFixed(0)}`,
    lmsUserId: 0,
    isSelf: false,
    role: PARTICIPANT_ROLES.LEARNER,
    isScreen: false,
  } as IPeerInfo
}

export const getPeerIdentificationFromLmsUserId = (
  lmsUserId?: number
): IPeerInfo => {
  const { peerDetails } = rootStore.getState().zoomout.external

  if (peerDetails && Object.keys(peerDetails).length > 0 && lmsUserId) {
    const peer = Object.values(peerDetails).find(p => p.lmsUserId === lmsUserId)
    if (peer) return peer
  }

  return {
    uid: lmsUserId,
    name: `Olympus User ${(Math.random() * 100).toFixed(0)}`,
    lmsUserId: 0,
    isSelf: false,
    role: PARTICIPANT_ROLES.LEARNER,
    isScreen: false,
  } as IPeerInfo
}

export const isHumanSpeaking = (
  audioTrack?: IMicrophoneAudioTrack | ILocalAudioTrack | IRemoteAudioTrack
) => {
  return !!(audioTrack && audioTrack.getVolumeLevel() > 0.05)
}

export const updateGallerySlots = (
  videosPerPage: number,
  totalTiles: number
) => {
  const requiredPages = Math.ceil(totalTiles / videosPerPage)

  return {
    requiredPages,
    videosPerPage,
  }
}

export const getAllParticipants = () => {
  if (window.zoomout) {
    const peerList = Object.keys(
      rootStore.getState().zoomout.internal.remotePeerState
    )
      .map(uid => getPeerIdentification(parseInt(uid as string, 10)))
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))

    const mainUid = rootStore.getState().zoomout.external.joiningDetails!.main!
      .uid
    const screenUid = (
      rootStore.getState().zoomout.external.joiningDetails!.screen || {}
    ).uid

    const isSelfSharingScreen = rootStore.getState().zoomout.internal
      .localTracksDetails.screenVideo

    const selfMainClientDetails = getPeerIdentification(mainUid)

    if (isSelfSharingScreen && screenUid) {
      const filteredList = peerList.filter(
        ({ uid }) => uid.toString() !== screenUid.toString()
      )
      const selfScreenClientDetails = getPeerIdentification(screenUid)

      return [
        {
          ...selfMainClientDetails,
          isSelf: true,
        },
        {
          ...selfScreenClientDetails,
          isSelf: true,
        },
        ...filteredList,
      ]
    }

    return [
      {
        ...selfMainClientDetails,
        isSelf: true,
      },
      ...peerList,
    ]
  }
  return []
}

export const getScreenSharingUserUIDs = (
  remotePeerState: ZoomoutInternalState['remotePeerState']
) => {
  const selfScreenUid = (
    (rootStore.getState().zoomout.external.joiningDetails || {}).screen || {}
  ).uid

  return Object.keys(remotePeerState)
    .filter(
      uid =>
        getPeerIdentification(parseInt(uid, 10)).isScreen &&
        parseInt(uid, 10) !== selfScreenUid
    )
    .map(Number)
}

export const getPinnedUID = () => {
  const { remotePeerState } = rootStore.getState().zoomout.internal

  const screenSharingUsers = getScreenSharingUserUIDs(remotePeerState)
  if (screenSharingUsers.length > 0) return screenSharingUsers[0]

  const mentorUID = Object.keys(remotePeerState).find(
    uid =>
      getPeerIdentification(parseInt(uid, 10)).role === PARTICIPANT_ROLES.MENTOR
  )
  if (mentorUID) return parseInt(mentorUID, 10)

  const pmUID = Object.keys(remotePeerState).find(
    uid =>
      getPeerIdentification(parseInt(uid, 10)).role ===
      PARTICIPANT_ROLES.PROGRAM_MANAGER
  )
  if (pmUID) return parseInt(pmUID, 10)

  const remoteUIDs = Object.keys(remotePeerState)
  return parseInt(remoteUIDs[Math.floor(Math.random() * remoteUIDs.length)], 10)
}

export const playAudio = (
  action: 'join' | 'leave' | 'raise_hand' | 'chat' | 'new_question'
) => {
  try {
    let url: string | undefined
    if (action === 'join')
      url = `${process.env.REACT_APP_CLOUDFRONT_LTI_URL}zoomout/user_join.wav`
    else if (action === 'leave')
      url = `${process.env.REACT_APP_CLOUDFRONT_LTI_URL}zoomout/user_leave.wav`
    else if (action === 'raise_hand' || action === 'new_question')
      url = `${process.env.REACT_APP_CLOUDFRONT_LTI_URL}zoomout/raise_hand.wav`
    else if (action === 'chat') {
      // TODO - add chat audio
      url = `${process.env.REACT_APP_CLOUDFRONT_LTI_URL}zoomout/raise_hand.wav`
    }

    if (!url) throw new Error('Invalid action, no audio url found')

    const audio = new Audio(url)
    audio.volume = 0.1
    audio.play()
  } catch (err) {
    const message = err instanceof Error ? err.message : JSON.stringify(err)

    mixpanel.track('Zoomout Errors', {
      feature: 'Play Audio',
      message,
    })
  }
}

export const requestFullScreen = async () => {
  try {
    if (
      !document.fullscreenElement && // alternative standard method
      // @ts-ignore
      !document.mozFullScreenElement &&
      // @ts-ignore
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        await document.documentElement.requestFullscreen()
        // @ts-ignore
      } else if (document.documentElement.mozRequestFullScreen) {
        // @ts-ignore
        await document.documentElement.mozRequestFullScreen()
        // @ts-ignore
      } else if (document.documentElement.webkitRequestFullscreen) {
        // @ts-ignore
        await document.documentElement.webkitRequestFullscreen(
          // @ts-ignore
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export const exitFullScreen = async () => {
  try {
    // @ts-ignore
    if (document.cancelFullScreen) {
      // @ts-ignore
      await document.cancelFullScreen()
      // @ts-ignore
    } else if (document.mozCancelFullScreen) {
      // @ts-ignore
      await document.mozCancelFullScreen()
      // @ts-ignore
    } else if (document.webkitCancelFullScreen) {
      // @ts-ignore
      await document.webkitCancelFullScreen()
    }
  } catch (e) {
    console.log(e)
  }
}

export const sortBy1Screen2Volume3Video = (a: PeerDetails, b: PeerDetails) => {
  // if isScreen is not same
  // sort by isScreen first
  if (a.details.isScreen !== b.details.isScreen) {
    return a.details.isScreen ? -1 : 1
  }

  const isAAdmin =
    a.details.role === PARTICIPANT_ROLES.MENTOR ||
    a.details.role === PARTICIPANT_ROLES.PROGRAM_MANAGER
  const isBAdmin =
    b.details.role === PARTICIPANT_ROLES.MENTOR ||
    b.details.role === PARTICIPANT_ROLES.PROGRAM_MANAGER

  // isScreen is same
  // sort by role
  if (isAAdmin !== isBAdmin) {
    return isAAdmin ? -1 : 1
  }

  // if remotePeer presence is not same
  if (!!a.remotePeer !== !!b.remotePeer) {
    return a.remotePeer ? -1 : 1
  }

  // if isScreen is same (both screen | both not screen)
  // and role is also same (both admin | both not admin)
  // if both remote peers are in the channel
  if (a.remotePeer && b.remotePeer) {
    // sort by difference in video presence
    if (a.remotePeer.hasVideo !== b.remotePeer.hasVideo) {
      return a.remotePeer.hasVideo ? -1 : 1
    }

    // if both have video | both don't have video
    // sort by difference in presence of audio
    if (a.remotePeer.hasAudio !== b.remotePeer.hasAudio) {
      return a.remotePeer.hasAudio ? -1 : 1
    }
  }

  // if both are totally identical in terms of isScreen, isAdmin, presence of audio and video
  // don't change their order
  return 0
}

export const getZoomoutEventId = (UIeventName: ZoomoutUIEvents) => {
  const serverMapping = rootStore.getState().zoomout.external.eventMapping

  switch (UIeventName) {
    case 'MUTE_MIC_BROADCAST':
      return serverMapping.MUTE_MIC_BROADCAST
    case 'MUTE_MIC':
      return serverMapping.MUTE_MIC
    case 'UNMUTE_MIC':
      return serverMapping.UNMUTE_MIC
    case 'MUTE_CAMERA':
      return serverMapping.MUTE_CAMERA
    case 'UNMUTE_CAMERA':
      return serverMapping.UNMUTE_CAMERA
    case 'MUTE_PRESENTATION':
      return serverMapping.MUTE_PRESENTATION
    case 'UNMUTE_PRESENTATION':
      return serverMapping.UNMUTE_PRESENTATION
    case 'SESSION_JOIN':
      return serverMapping.SESSION_JOIN
    case 'SESSION_PING':
      return serverMapping.SESSION_PING
    case 'SESSION_LEAVE':
      return serverMapping.SESSION_LEAVE
    case 'SESSION_END':
      return serverMapping.SESSION_END
    case 'RECORDING_START':
      return serverMapping.RECORDING_START
    case 'RECORDING_END':
      return serverMapping.RECORDING_END
    case 'VIEW_IS_GALLERY':
      return serverMapping.VIEW_IS_GALLERY
    case 'VIEW_IS_SPEAKER':
      return serverMapping.VIEW_IS_SPEAKER
    case 'RAISE_HAND':
      return serverMapping.RAISE_HAND
    case 'LOWER_HAND':
      return serverMapping.LOWER_HAND
    default:
      return -1
  }
}

export const closeZoomoutDrawer = () => {
  if (window.location.hash) window.history.replaceState('', document.title)
  rootStore.dispatch(updateUserInterface({ drawer: null }))
}

export const getCameraConfig = (
  supportsDualStream: boolean,
  cameraDeviceId: string
) => {
  return supportsDualStream
    ? {
        ...zoomoutSettings.camera,
        cameraId: cameraDeviceId,
      }
    : {
        ...zoomoutSettings.camera,
        cameraId: cameraDeviceId,
        encoderConfig: zoomoutSettings.lowQualityCamera,
      }
}
