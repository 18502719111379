import { useSelector } from 'react-redux'
import { programSelectors } from 'web/providers/Dashboard/ProgramsProvider'

export const useShouldDisableGradebook = (): boolean => {
  const programData = useSelector(programSelectors.getActiveProgramDetails())
  return !!programData?.disable_gradebook
}

export const useShouldDisableContentAutoNexItem = (): boolean => {
  const programData = useSelector(programSelectors.getActiveProgramDetails())
  return !!programData?.disable_auto_next_item
}

export const useShouldDisableFeedbackMandate = (): boolean => {
  const programData = useSelector(programSelectors.getActiveProgramDetails())
  return !!programData?.disable_session_feedback_mandate
}

export const useShouldReplaceGradesWithLabels = (): boolean => {
  const programData = useSelector(programSelectors.getActiveProgramDetails())
  return !!programData?.replace_grades_with_labels
}
