import { apiCall, getUserDetails } from 'common/utils'
import { fetchCareerSchoolDetails } from './CareerSchool.action'

export async function fetchCareerSchoolDetailsAPI(
  action: ReturnType<typeof fetchCareerSchoolDetails>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const query: Record<string, any> = {
    user_id: id,
  }

  const response = await apiCall({
    url: `${process.env.REACT_APP_LTI_API_URL}v1//users/${id}/career_schools`,
    params: {
      signal,
    },
    query,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
export async function updateCareerSchoolOnboardingAPI(signal: AbortSignal) {
  const { id } = getUserDetails()
  const query: Record<string, any> = {
    user_id: id,
  }

  const response = await apiCall({
    url: `${process.env.REACT_APP_LTI_API_URL}v1/users/${id}/career_schools/update_onboarding`,
    params: {
      signal,
      method: 'POST',
    },
    query,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
export async function updateCareerSchoolJobOnboardedAPI(signal: AbortSignal) {
  const { id } = getUserDetails()
  const query: Record<string, any> = {
    user_id: id,
  }

  const response = await apiCall({
    url: `${process.env.REACT_APP_LTI_API_URL}v1/users/${id}/career_schools/update_job_onboarding`,
    params: {
      signal,
      method: 'POST',
    },
    query,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}


