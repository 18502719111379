import { Reducer } from 'redux'
import { UtilsActions } from './Utils.actions'
import * as types from './Utils.types'

export interface UtilsState {
  currentDateTime?: string
}

const initialState: UtilsState = {}

export const utilsReducer: Reducer<UtilsState, UtilsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.FETCH_CURRENT_TIME_SUCCESS:
      return {
        ...state,
        currentDateTime: action.payload.date_time,
      }

    case types.REMOVE_CURRENT_TIME:
      return {
        ...state,
        currentDateTime: undefined,
      }

    default:
      return state
  }
}

export default utilsReducer
