import { CourseID } from 'common/types/courses'
import {
  FETCH_COURSE_PERCENTAGE_COMPLETED,
  FETCH_COURSE_PERCENTAGE_COMPLETED_SUCCESS,
  FETCH_COURSE_PERCENTAGE_COMPLETED_FAILURE,
  FETCH_COURSE_PERCENTAGE_COMPLETED_CANCEL,
} from './CoursePercentageCompleted.types'

interface PercentagePayload {
  courseIds: CourseID[]
}

export const fetchCoursePercentageCompleted = (payload: PercentagePayload) => ({
  type: FETCH_COURSE_PERCENTAGE_COMPLETED as typeof FETCH_COURSE_PERCENTAGE_COMPLETED,
  payload,
})

export const fetchCoursePercentageCompletedSuccess = (payload: any) => ({
  type: FETCH_COURSE_PERCENTAGE_COMPLETED_SUCCESS as typeof FETCH_COURSE_PERCENTAGE_COMPLETED_SUCCESS,
  payload,
})

export const fetchCoursePercentageCompletedFailure = (payload: Error) => ({
  type: FETCH_COURSE_PERCENTAGE_COMPLETED_FAILURE as typeof FETCH_COURSE_PERCENTAGE_COMPLETED_FAILURE,
  payload,
})

export const fetchCoursePercentageCompletedCancel = () => ({
  type: FETCH_COURSE_PERCENTAGE_COMPLETED_CANCEL as typeof FETCH_COURSE_PERCENTAGE_COMPLETED_CANCEL,
})

export type CoursePercentageCompletedActions =
  | ReturnType<typeof fetchCoursePercentageCompleted>
  | ReturnType<typeof fetchCoursePercentageCompletedSuccess>
  | ReturnType<typeof fetchCoursePercentageCompletedFailure>
  | ReturnType<typeof fetchCoursePercentageCompletedCancel>
