import { CareerSchoolData } from 'common/types/CareerSchool'
import { Reducer } from 'redux'
import { CareerSchoolActionTypes } from './CareerSchool.action'
import {
  FETCH_CAREER_SCHOOL_DETAILS,
  FETCH_CAREER_SCHOOL_DETAILS_FAILURE,
  FETCH_CAREER_SCHOOL_DETAILS_SUCCESS,
  UPDATE_CAREER_SCHOOL_ONBOARDING_COMPLETED,
  UPDATE_CAREER_SCHOOL_JOB_ONBOARDED,
  UPDATE_CAREER_SCHOOL_USER_INFO_COLLECTED,
} from './CareerSchool.types'

export interface CareerSchoolState {
  data: CareerSchoolData
  loading: Boolean
  error: false | Error | Response
}
const careerSchoolInitialState: CareerSchoolData = {
  enrolled_schools: [],
  other_schools: [],
  career_boost_pro_link: '',
  career_school_jobs_link: '',
  support_email: '',
  onboarding_completed: false,
  job_onboarded: false,
  is_cs_user_info_collected: false,
  onboarding_video_embed_code: '',
}

const initialState: CareerSchoolState = {
  data: careerSchoolInitialState,
  loading: false,
  error: false,
}

const careerSchoolReducer: Reducer<
  CareerSchoolState,
  CareerSchoolActionTypes
> = (state = initialState, action): CareerSchoolState => {
  switch (action.type) {
    case FETCH_CAREER_SCHOOL_DETAILS:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case FETCH_CAREER_SCHOOL_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case FETCH_CAREER_SCHOOL_DETAILS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        data: {
          ...state.data,
          ...action.payload,
        },
      }
    case UPDATE_CAREER_SCHOOL_ONBOARDING_COMPLETED:
      return {
        ...state,
        data: {
          ...state.data,
          onboarding_completed: true,
        },
      }
    case UPDATE_CAREER_SCHOOL_JOB_ONBOARDED:
      return {
        ...state,
        data: {
          ...state.data,
          job_onboarded: true,
        },
      }
    case UPDATE_CAREER_SCHOOL_USER_INFO_COLLECTED:
      return {
        ...state,
        data: {
          ...state.data,
          is_cs_user_info_collected: true,
        },
      }
    default:
      return {
        ...state,
      }
  }
}

export default careerSchoolReducer
