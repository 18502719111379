import { ContentID } from 'common/types/courses/content'
import * as types from './QuizSubmissions.types'
import { CourseID } from '../../../../../../common/types/courses'
import {
  QuizID,
  QuizSubmissionData,
  QuizAttemptNo,
  QuizCompletionType,
  QuestionID,
  QuizSubmissionID,
  QuizSubmissionEventType,
} from '../../../../../../common/types/courses/quiz'
import { ItemID } from '../../../../../../common/types/courses/moduleItem'
import { ModuleID } from '../../../../../../common/types'

interface QuizSubmissionStartMeta {
  courseId: CourseID
  quizId: QuizID
  itemId: ItemID
  moduleId: ModuleID
  proctored: boolean
  basic_proctoring: boolean
  mediaStream?: MediaStream
  timeLimit: boolean
  anomalyDetection: boolean
  openBook: boolean
}

export const startQuizSubmission = (
  payload: null,
  meta: QuizSubmissionStartMeta
) => ({
  type: types.QUIZ_SUBMISSION_START as typeof types.QUIZ_SUBMISSION_START,
  payload,
  meta,
})

export const startQuizSubmissionSuccess = (
  payload: QuizSubmissionData,
  meta: QuizSubmissionStartMeta
) => ({
  type: types.QUIZ_SUBMISSION_START_SUCCESS as typeof types.QUIZ_SUBMISSION_START_SUCCESS,
  payload,
  meta,
})

export const startQuizSubmissionFailure = (
  payload: Error,
  meta: QuizSubmissionStartMeta
) => ({
  type: types.QUIZ_SUBMISSION_START_FAILURE as typeof types.QUIZ_SUBMISSION_START_FAILURE,
  payload,
  meta,
})

interface ResumeQuizSubmissionMetaParams {
  itemId: ItemID
  quizId: QuizID
  courseId: CourseID
  proctored: boolean
  basic_proctoring: boolean
  mediaStream?: MediaStream
  timeLimit: boolean
  anomalyDetection: boolean
  openBook: boolean
  skipTracking?: boolean
}

export const resumeQuizSubmission = (
  payload: null,
  meta: ResumeQuizSubmissionMetaParams
) => ({
  type: types.QUIZ_SUBMISSION_RESUME as typeof types.QUIZ_SUBMISSION_RESUME,
  payload,
  meta,
})

export const resumeQuizSubmissionSuccess = (
  payload: { attemptNo: QuizAttemptNo },
  meta: ResumeQuizSubmissionMetaParams
) => ({
  type: types.QUIZ_SUBMISSION_RESUME_SUCCESS as typeof types.QUIZ_SUBMISSION_RESUME_SUCCESS,
  payload,
  meta,
})

export const resumeQuizSubmissionFailure = (
  payload: Error,
  meta: ResumeQuizSubmissionMetaParams
) => ({
  type: types.QUIZ_SUBMISSION_RESUME_FAILURE as typeof types.QUIZ_SUBMISSION_RESUME_FAILURE,
  payload,
  meta,
})

interface QuizSubmissionCompleteMetaParams {
  quizId: QuizID
  itemId: ItemID
  courseId: CourseID
  moduleId: ModuleID
  proctored_assessment: boolean
  basic_proctoring: boolean
  completionType: QuizCompletionType
  preventRefectchSubmissions?: boolean
}

interface QuizActivityMetaParams {
  itemId: ItemID
}
export interface GetQuizSubmissionCommentsParams {
  courseId: CourseID
  contentId: ContentID
  itemId: ItemID
}

export const completeQuizSubmission = (
  payload: null,
  meta: QuizSubmissionCompleteMetaParams
) => ({
  type: types.QUIZ_SUBMISSION_COMPLETE as typeof types.QUIZ_SUBMISSION_COMPLETE,
  payload,
  meta,
})

export const completeQuizSubmissionSuccess = (
  payload: QuizSubmissionData,
  meta: QuizSubmissionCompleteMetaParams
) => ({
  type: types.QUIZ_SUBMISSION_COMPLETE_SUCCESS as typeof types.QUIZ_SUBMISSION_COMPLETE_SUCCESS,
  payload,
  meta,
})

export const completeQuizSubmissionFailure = (
  payload: Error,
  meta: QuizSubmissionCompleteMetaParams
) => ({
  type: types.QUIZ_SUBMISSION_COMPLETE_FAILURE as typeof types.QUIZ_SUBMISSION_COMPLETE_FAILURE,
  payload,
  meta,
})

interface QuizSubmissionsFetchMetaParams {
  quizId: QuizID
  itemId: ItemID
  courseId: CourseID
  contentId?: ContentID
}

export const fetchQuizSubmissions = (
  payload: null,
  meta: QuizSubmissionsFetchMetaParams
) => ({
  type: types.QUIZ_SUBMISSIONS_FETCH as typeof types.QUIZ_SUBMISSIONS_FETCH,
  payload,
  meta,
})

export const fetchQuizSubmissionsSuccess = (
  payload: QuizSubmissionData[],
  meta: QuizSubmissionsFetchMetaParams
) => ({
  type: types.QUIZ_SUBMISSIONS_FETCH_SUCCESS as typeof types.QUIZ_SUBMISSIONS_FETCH_SUCCESS,
  payload,
  meta,
})

export const fetchQuizSubmissionsFailure = (
  payload: Error,
  meta: QuizSubmissionsFetchMetaParams
) => ({
  type: types.QUIZ_SUBMISSIONS_FETCH_FAILURE as typeof types.QUIZ_SUBMISSIONS_FETCH_FAILURE,
  payload,
  meta,
})

export const fetchQuizSubmissionsCancel = (
  payload: null,
  meta: QuizSubmissionsFetchMetaParams
) => ({
  type: types.QUIZ_SUBMISSIONS_FETCH_CANCEL as typeof types.QUIZ_SUBMISSIONS_FETCH_CANCEL,
  payload,
  meta,
})

export const resetQuizSubmission = (
  payload: null,
  meta: { itemId: ItemID }
) => ({
  type: types.QUIZ_SUBMISSION_RESET as typeof types.QUIZ_SUBMISSION_RESET,
  payload,
  meta,
})

export const clearQuizUploadedFiles = (
  payload: null,
  meta: { itemId: ItemID }
) => ({
  type: types.QUIZ_SUBMISSION_CLEAR_FILES as typeof types.QUIZ_SUBMISSION_CLEAR_FILES,
  payload,
  meta,
})

export const getQuizSubmissionComments = (
  payload: GetQuizSubmissionCommentsParams,
  meta: QuizActivityMetaParams
) => ({
  type: types.GET_QUIZ_SUBMISSION_COMMENTS as typeof types.GET_QUIZ_SUBMISSION_COMMENTS,
  payload,
  meta,
})

export const getQuizSubmissionCommentsFailure = (
  payload: Error,
  meta: QuizActivityMetaParams
) => ({
  type: types.GET_QUIZ_SUBMISSION_COMMENTS_FAILURE as typeof types.GET_QUIZ_SUBMISSION_COMMENTS_FAILURE,
  payload,
  meta,
})

export const getQuizSubmissionCommentsSuccess = (
  payload: any,
  meta: QuizActivityMetaParams
) => ({
  type: types.GET_QUIZ_SUBMISSION_COMMENTS_SUCCESS as typeof types.GET_QUIZ_SUBMISSION_COMMENTS_SUCCESS,
  payload,
  meta,
})

export const getQuizSubmissionCommentsCancel = (
  payload: any,
  meta: QuizActivityMetaParams
) => ({
  type: types.GET_QUIZ_SUBMISSION_COMMENTS_CANCEL as typeof types.GET_QUIZ_SUBMISSION_COMMENTS_CANCEL,
  payload,
  meta,
})

interface TrackQuizSubmissionEventPayload {
  quiz_submission_id: QuizSubmissionID
  question_id: QuestionID
  event_type: QuizSubmissionEventType
  in_focus: boolean
  attempt: QuizAttemptNo
  meta?: object
}

export const trackQuizSubmissionEvent = (
  payload: TrackQuizSubmissionEventPayload
) => ({
  type: types.TRACK_QUIZ_SUBMISSION_EVENT as typeof types.TRACK_QUIZ_SUBMISSION_EVENT,
  payload,
})

export const trackQuizSubmissionEventFailure = (
  payload: any,
  options: { [s in string]: any }
) => ({
  type: types.TRACK_QUIZ_SUBMISSION_EVENT_FAILURE as typeof types.TRACK_QUIZ_SUBMISSION_EVENT_FAILURE,
  payload,
  options,
})

export type QuizSubmissionsActionTypes =
  | ReturnType<typeof resumeQuizSubmission>
  | ReturnType<typeof resumeQuizSubmissionFailure>
  | ReturnType<typeof resumeQuizSubmissionSuccess>
  | ReturnType<typeof resetQuizSubmission>
  | ReturnType<typeof startQuizSubmission>
  | ReturnType<typeof startQuizSubmissionSuccess>
  | ReturnType<typeof startQuizSubmissionFailure>
  | ReturnType<typeof fetchQuizSubmissions>
  | ReturnType<typeof fetchQuizSubmissionsSuccess>
  | ReturnType<typeof fetchQuizSubmissionsFailure>
  | ReturnType<typeof fetchQuizSubmissionsCancel>
  | ReturnType<typeof completeQuizSubmission>
  | ReturnType<typeof completeQuizSubmissionFailure>
  | ReturnType<typeof completeQuizSubmissionSuccess>
  | ReturnType<typeof clearQuizUploadedFiles>
  | ReturnType<typeof getQuizSubmissionComments>
  | ReturnType<typeof getQuizSubmissionCommentsSuccess>
  | ReturnType<typeof getQuizSubmissionCommentsFailure>
  | ReturnType<typeof getQuizSubmissionCommentsCancel>
