import { apiCall } from '../../../../common/utils'
import {
  UserID,
  ExcelerateProfileData,
  UserOnboardingQuestionAnswer,
  UserIdentityDetails,
  ExtraDetailsForUniversity,
  USUniversityDetails,
} from '../../../../common/types/user'

export async function fetchUserOnboardingDataAPI({
  userId,
}: {
  userId: UserID
}) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/onboarding`,
    query: {
      user_id: userId,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function fetchPreferencesQuestionsAPI({
  userId,
}: {
  userId: UserID
}) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/user_onboarding_questions`,
    query: {
      user_id: userId,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function fetchExcelerateOnboardingDataAPI({
  userId,
}: {
  userId: UserID
}) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/excelerate/onboarding_data`,
    query: {
      user_id: userId,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function updateUserOnboardingDataAPI({
  userId,
  linkedin_auth_opted_out,
  linkedin_public_profile_url,
  policies_accepted,
  placement_policy_accepted,
  ip_protection_accepted,
  ip_digital_signature,
  has_user_seen_testimonials_consent,
  testimonials_consent_accepted,
  identity_source,
  identity_source_proof,
  identity_source_proof_password,
  identity_details,
  identification_completed,
  user_image,
  excelerate_profile_details,
  preference_answers,
  enrollment_details,
  participant_responsibility_accepted,
  participant_responsibility_digital_signature,
  trademark_and_copyright_accepted,
  us_university_details,
}: {
  userId: UserID
} & Partial<{
  linkedin_auth_opted_out: boolean
  policies_accepted: boolean
  placement_policy_accepted: boolean
  ip_protection_accepted: boolean
  ip_digital_signature: string
  linkedin_public_profile_url: string
  has_user_seen_testimonials_consent: boolean
  testimonials_consent_accepted: boolean
  identity_source: string
  identity_source_proof: File
  identity_source_proof_password?: string
  identity_details: UserIdentityDetails
  identification_completed: boolean
  user_image: string
  excelerate_profile_details: ExcelerateProfileData
  preference_answers: UserOnboardingQuestionAnswer[]
  enrollment_details: ExtraDetailsForUniversity
  participant_responsibility_accepted: boolean
  participant_responsibility_digital_signature?: string
  trademark_and_copyright_accepted: boolean
  us_university_details: USUniversityDetails
}>) {
  const formData = new FormData()
  // formData.append('user_id', userId.toString())
  if (policies_accepted) {
    formData.append('terms_accepted', policies_accepted.toString())
  }

  if (placement_policy_accepted) {
    formData.append(
      'placement_policy_accepted',
      placement_policy_accepted.toString()
    )
  }

  if (trademark_and_copyright_accepted) {
    formData.append(
      'trademark_and_copyright_accepted',
      trademark_and_copyright_accepted.toString()
    )
  }
  if (ip_protection_accepted && ip_digital_signature) {
    formData.append('ip_protection_accepted', ip_protection_accepted.toString())
    formData.append(
      'ip_protection_digital_signature',
      ip_digital_signature.toString()
    )
  }
  if (identity_source && identity_source_proof) {
    formData.append('identity_source', identity_source)
    formData.append('identity_source_proof', identity_source_proof)
    if (identity_source_proof_password)
      formData.append(
        'identity_source_proof_password',
        identity_source_proof_password
      )
  }
  if (identity_details) {
    formData.append('identity_details', JSON.stringify(identity_details))
  }
  if (identification_completed) {
    formData.append(
      'identification_completed',
      identification_completed.toString()
    )
  }
  if (user_image) {
    formData.append('user_image', user_image)
  }
  if (has_user_seen_testimonials_consent) {
    formData.append(
      'has_user_seen_testimonials_consent',
      has_user_seen_testimonials_consent.toString()
    )
    if (testimonials_consent_accepted) {
      formData.append(
        'testimonials_consent_accepted',
        testimonials_consent_accepted.toString()
      )
    }
  }
  if (linkedin_auth_opted_out) {
    formData.append(
      'linkedin_auth_opted_out',
      linkedin_auth_opted_out.toString()
    )
  }
  if (linkedin_public_profile_url) {
    formData.append('profile_url', linkedin_public_profile_url)
  }

  if (excelerate_profile_details) {
    formData.append(
      'excelerate_profile_details',
      JSON.stringify(excelerate_profile_details)
    )
  }

  if (preference_answers && preference_answers.length) {
    formData.append('preference_answers', JSON.stringify(preference_answers))
    formData.append('terms_accepted', 'true')
  }

  if (enrollment_details && enrollment_details.acknowledged) {
    formData.append('enrollment_details', JSON.stringify(enrollment_details))
    formData.append('terms_accepted', 'true')
  }

  if (
    participant_responsibility_accepted &&
    participant_responsibility_digital_signature
  ) {
    formData.append(
      'participant_responsibility_accepted',
      participant_responsibility_accepted.toString()
    )
    formData.append(
      'participant_responsibility_digital_signature',
      participant_responsibility_digital_signature.toString()
    )
  }

  if (us_university_details) {
    formData.append(
      'us_university_details',
      JSON.stringify(us_university_details)
    )
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/onboarding`,
    query: {
      user_id: userId,
    },
    params: {
      method: 'POST',
      body: formData,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
