import { Reducer } from 'redux'
import {
 CHECK_USER_CONSENT_SUCCESS
} from './UserConsent.types'

import { UserConsentActions } from './UserConsent.actions'

import { UserConsentData } from '../../../../common/types/user'

export interface UserConsentState {
  user_consent?: UserConsentData
  loading: boolean
  error: false | Error | Response
}
const initialState: UserConsentState = {
  loading: true,
  error: false,
}
const UserConsentReducer: Reducer<UserConsentState, UserConsentActions> = (
  state = initialState,
  action
): UserConsentState => {
  switch (action.type) {
    case CHECK_USER_CONSENT_SUCCESS:
      return {
        ...state,
        user_consent: action.payload,
        loading: false,
        error: false,
      }
    default:
      return state
  }
}
export default UserConsentReducer
