import {
  apiCall,
  getUserDetails,
  objectToFormData,
} from '../../../../common/utils'
import {
  CourseDetailsFetchParams,
  courseListFilterParam,
} from './Courses.actions'
import { CourseID } from '../../../../common/types/courses'

export async function getCourseListAPI({
  filters,
  signal,
}: {
  filters: courseListFilterParam
  signal: AbortSignal
}) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/courses/list`,
    params: { signal },
    query: { user_id: id, filters: JSON.stringify(filters) },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getCourseDetailsAPI(
  { courseId, include = [] }: CourseDetailsFetchParams,
  signal: AbortSignal
) {
  const query = {
    include,
  }
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}`,
    query,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getCourseTabs({ courseId }: { courseId: CourseID }) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/tabs`,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function submitCourseRating(
  courseId: CourseID,
  bodyObject: any,
  signal: AbortSignal
) {
  const body = objectToFormData(bodyObject)
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/courses/${courseId}/submit_user_rating`,
    params: { signal, method: 'POST', body },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
