import { call, cancelled, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { cancelable, CustomError } from '../../../common/utils'
import * as APIs from './Hackathons.api'
import * as types from './Hackathons.types'
import { showAlertMessage } from '../AlertsProvider'
import {
  fetchHackathonListSuccess,
  fetchHackathonListFailure,
  fetchHackathonDetails,
  fetchHackathonDetailsSuccess,
  fetchHackathonDetailsFailure,
  registerHackathonTeam,
  registerHackathonTeamSuccess,
  registerHackathonTeamFailure,
  addMembersHackathonTeam,
  submitHackathonSolution,
  addMembersHackathonTeamSuccess,
  addMembersHackathonTeamFailure,
  submitHackathonSolutionSuccess,
  submitHackathonSolutionFailure,
  fetchHackathonLeaderboard,
  fetchHackathonLeaderboardSuccess,
  fetchHackathonLeaderboardFailure,
  fetchHackathonTeamPerformance,
  fetchHackathonTeamPerformanceSuccess,
  fetchHackathonTeamPerformanceFailure,
} from './Hackathons.actions'

function* getHackathonList() {
  const abortController = new AbortController()
  try {
    const data = yield call(APIs.getHackathonListAPI, abortController.signal)
    yield put(fetchHackathonListSuccess(data))
  } catch (e) {
    yield put(fetchHackathonListFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* getHackathonDetails(
  action: ReturnType<typeof fetchHackathonDetails>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.getHackathonDetailsAPI,
      action.payload.hackathonId,
      abortController.signal
    )
    yield put(fetchHackathonDetailsSuccess(data, action.meta))
  } catch (e) {
    yield put(fetchHackathonDetailsFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* registerHackathonTeamHandler(
  action: ReturnType<typeof registerHackathonTeam>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.registerHackathonTeamAPI,
      action.meta.hackathonId,
      action,
      abortController.signal
    )
    yield put(registerHackathonTeamSuccess(data, action.meta))
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'Successfully registered team',
      })
    )
    yield put(fetchHackathonDetails(action.meta, action.meta))
  } catch (e) {
    yield put(registerHackathonTeamFailure(e, action.meta))
    yield put(
      showAlertMessage({
        variant: 'error',
        message:
          e instanceof CustomError
            ? e.message.split(';').join('<br/>')
            : 'Registering team failed',
        closeOnTimeout: false,
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* addMembersHackathonTeamHandler(
  action: ReturnType<typeof addMembersHackathonTeam>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.addMembersHackathonTeamAPI,
      action.meta.hackathonId,
      action,
      abortController.signal
    )
    yield put(addMembersHackathonTeamSuccess(data, action.meta))
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'Successfully added participants to the team',
      })
    )
    yield put(fetchHackathonDetails(action.meta, action.meta))
  } catch (e) {
    yield put(addMembersHackathonTeamFailure(e, action.meta))
    yield put(
      showAlertMessage({
        variant: 'error',
        message:
          e instanceof CustomError
            ? e.message.split(';').join('<br/>')
            : 'Adding participants to team failed',
        closeOnTimeout: false,
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitHackathonSolutionHandler(
  action: ReturnType<typeof submitHackathonSolution>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.submitHackathonSolutionAPI,
      action.meta.hackathonId,
      action,
      abortController.signal
    )
    yield put(submitHackathonSolutionSuccess(data, action.meta))
  } catch (e) {
    yield put(submitHackathonSolutionFailure(e, action.meta))
    yield put(
      showAlertMessage({
        variant: 'error',
        message:
          e instanceof CustomError
            ? e.message.split(';').join('<br/>')
            : 'Submission failed',
        closeOnTimeout: false,
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchHackathonLeaderboardHandler(
  action: ReturnType<typeof fetchHackathonLeaderboard>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.fetchHackathonLeaderboardAPI,
      action.meta.hackathonId,
      abortController.signal
    )
    yield put(fetchHackathonLeaderboardSuccess(data, action.meta))
  } catch (e) {
    yield put(fetchHackathonLeaderboardFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchHackathonTeamPerformanceHandler(
  action: ReturnType<typeof fetchHackathonTeamPerformance>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.fetchHackathonTeamPerformanceAPI,
      action,
      abortController.signal
    )
    yield put(fetchHackathonTeamPerformanceSuccess(data, action.meta))
  } catch (e) {
    yield put(fetchHackathonTeamPerformanceFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* hackathonsMiddleware() {
  yield takeLatest(
    types.FETCH_HACKATHON_LIST,
    cancelable(getHackathonList, types.FETCH_HACKATHON_LIST_CANCEL)
  )

  yield takeEvery(
    types.FETCH_HACKATHON_DETAILS,
    cancelable(getHackathonDetails, types.FETCH_HACKATHON_DETAILS_CANCEL)
  )

  yield takeLatest(
    types.REGISTER_HACKATHON_TEAM,
    cancelable(
      registerHackathonTeamHandler,
      types.REGISTER_HACKATHON_TEAM_CANCEL
    )
  )

  yield takeLatest(
    types.ADD_MEMBERS_HACKATHON_TEAM,
    cancelable(
      addMembersHackathonTeamHandler,
      types.ADD_MEMBERS_HACKATHON_TEAM_CANCEL
    )
  )

  yield takeLatest(
    types.SUBMIT_HACKATHON_SOLUTION,
    cancelable(
      submitHackathonSolutionHandler,
      types.SUBMIT_HACKATHON_SOLUTION_CANCEL
    )
  )

  yield takeLatest(
    types.FETCH_HACKATHON_LEADERBOARD,
    cancelable(
      fetchHackathonLeaderboardHandler,
      types.FETCH_HACKATHON_LEADERBOARD_CANCEL
    )
  )

  yield takeLatest(
    types.FETCH_HACKATHON_TEAM_PERFORMANCE,
    cancelable(
      fetchHackathonTeamPerformanceHandler,
      types.FETCH_HACKATHON_TEAM_PERFORMANCE_CANCEL
    )
  )
}

export default ([] as any).concat(hackathonsMiddleware())
