import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import * as APIs from './AllPrograms.api'
import * as types from './AllPrograms.types'

import {
  fetchAllPrograms,
  fetchAllProgramsSuccess,
  fetchAllProgramsFailure,
} from './AllPrograms.actions'

function* fetchAllProgramsHandler(action: ReturnType<typeof fetchAllPrograms>) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.fetchAllProgramsAPI,
      action,
      abortController.signal
    )
    yield put(fetchAllProgramsSuccess(data))
  } catch (e) {
    yield put(fetchAllProgramsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* allProgramsMiddleware() {
  yield takeLatest(
    types.FETCH_ALL_PROGRAMS,
    cancelable(fetchAllProgramsHandler, types.FETCH_ALL_PROGRAMS_CANCEL)
  )
}
export default ([] as any).concat(allProgramsMiddleware())
