import { ObjectWithStringValues } from '../types/utils'

const colors: ObjectWithStringValues = {
  // Standard Colors
  white: '#ffffff',
  black: '#000000',

  // Blue Variants
  blue10: '#BBDEFB',
  blue20: '#ecf8ff',
  blue40: '#c5daf8',
  blue50: '#4687e9',
  blue60: '#196ae5',
  blue70: '#0041b2',

  // Purple Variants
  purple40: '#d0c2ff',
  purple50: '#a186ff',
  purple60: '#6c00ff',
  purple70: '#2f09b3',

  // Green Variants
  green40: '#d5f2ea',
  green50: '#7ddfc3',
  green60: '#00c48c',
  green70: '#00925f',

  // Yellow Variants
  yellow40: '#fff8e7',
  yellow50: '#ffe29d',
  yellow60: '#ffcf5c',
  yellow70: '#c99e2a',

  // Red Variants
  red40: '#fbe4e8',
  red50: '#fdafbb',
  red60: '#ff647c',
  red70: '#c72e50',

  // Grey Variants
  grey10: '#e8e8e8',
  grey20: '#eaecef',
  grey30: '#e0e1e2',
  grey40: '#ccd0d8',
  grey60: '#67748a',
  grey70: '#344563',
  grey80: '#1b1c1d',

  // Misc
  warningBackground: 'rgba(255, 207, 92, 0.1)',
  indigo: '#0000C9',
  pink: '#E91F63',
  teal: '#02A393',
  lime: '#5009E8',
  lightGreen: '#4caf50',
  mediumGreen: 'rgba(47, 163, 53, 0.94)',
  orangePeel: '#ff9800',
  darkOrchid: '#9c27b0',
  snow: '#fafafa',
}

// Primary Colors
colors.primary20 = colors.blue20
colors.primary40 = colors.blue40
colors.primary50 = colors.blue50
colors.primary60 = colors.blue60
colors.primary70 = colors.blue70

// Secondary Colors
colors.secondary40 = colors.purple40
colors.secondary50 = colors.purple50
colors.secondary60 = colors.purple60
colors.secondary70 = colors.purple70

colors.assignment = colors.indigo
colors.quiz = colors.pink
colors.discussion = colors.teal
colors.resource = colors.darkOrchid
colors.video = colors.blue60
colors.page = colors.orangePeel
colors.link = colors.lightGreen
colors.others = colors.grey80

export default colors
