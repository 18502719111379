import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { FreemiumUserDashboardData } from '../../../../common/types/dashboard'
import Button from '../../Utils/Button'
import Link from '../../Utils/Link'
import SnackbarStrip from '../SnackbarStrip'
import styles from '../Header.module.css'

interface FreemiumPaymentReminderProps {
  dashboardData: FreemiumUserDashboardData
}

const FreemiumPaymentReminder = (props: FreemiumPaymentReminderProps) => {
  return (
    <SnackbarStrip
      message={
        <span id="client-snackbar">
          <Box display="flex" alignItems="center">
            <Typography>
              Your free trial ends in {props.dashboardData.trial_remaining_days}{' '}
              days. Please make the payment to enjoy uninterrupted learning.
            </Typography>
            <Link
              href={props.dashboardData.fee_payment_link}
              className={styles.link}
            >
              <Button color="primary" className={styles.innerLink}>
                <Typography>Upgrade Now</Typography>
              </Button>
            </Link>
          </Box>
        </span>
      }
      action={[]}
      open
    />
  )
}
export default FreemiumPaymentReminder
