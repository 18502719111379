import { CourseID } from 'common/types/courses'
import {
  GET_OUTCOME_DETAILS,
  GET_OUTCOME_DETAILS_SUCCESS,
  GET_OUTCOME_DETAILS_FAILURE,
} from './Outcomes.types'

import {
  OutcomesData,
  OutcomesId,
} from '../../../../../common/types/courses/assignment'

export interface GetOutcomeDetailsParams {
  courseId: CourseID
  rubricOutcomeId: OutcomesId
}

export interface GetOutcomeDetailsMeta {
  courseId: CourseID
  rubricOutcomeId: OutcomesId
}

export const getOutcomeDetails = (
  payload: GetOutcomeDetailsParams,
  meta: GetOutcomeDetailsMeta
) => ({
  type: GET_OUTCOME_DETAILS as typeof GET_OUTCOME_DETAILS,
  payload,
  meta,
})
export const getOutcomeDetailsSuccess = (
  payload: OutcomesData,
  meta: GetOutcomeDetailsMeta
) => ({
  type: GET_OUTCOME_DETAILS_SUCCESS as typeof GET_OUTCOME_DETAILS_SUCCESS,
  payload,
  meta,
})
export const getOutcomeDetailsFailure = (
  payload: Error,
  meta: GetOutcomeDetailsMeta
) => ({
  type: GET_OUTCOME_DETAILS_FAILURE as typeof GET_OUTCOME_DETAILS_FAILURE,
  payload,
  meta,
})

export type OutcomeActions =
  | ReturnType<typeof getOutcomeDetails>
  | ReturnType<typeof getOutcomeDetailsSuccess>
  | ReturnType<typeof getOutcomeDetailsFailure>
