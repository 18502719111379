import { MessageCategory } from 'common/types/zoomout'
import { getZoomoutEventId } from 'common/utils/zoomout'
import { updateRaiseHandMessages } from 'web/providers/Zoomout/Internal/Internal.actions'
import store from 'web/store'
import pushZoomoutEvent from '../pushZoomoutEvent'
import {
  RaiseHandMessage,
  RAISE_HAND_STATES,
  RemoteOverrideMessage,
  REMOTE_OVERRIDE_COMMANDS,
} from '../rtm/messages'

export const lowerHand = async () => {
  if (window.zoomout && window.zoomout.rtmChannel) {
    const selfMainUid =
      ((store.getState().zoomout.external.joiningDetails || {}).main || {})
        .uid || -999

    const isSelfHandRaised = !!store.getState().zoomout.internal.messages[
      MessageCategory.RAISE_HAND
    ].isHandRaised[selfMainUid]

    if (isSelfHandRaised) {
      const payload = new RaiseHandMessage({
        sender: selfMainUid,
        content: RAISE_HAND_STATES.LOWERED,
      })

      await window.zoomout.rtmChannel.sendMessage({
        text: JSON.stringify(payload),
      })
      pushZoomoutEvent({
        eventID: getZoomoutEventId('LOWER_HAND'),
      })

      store.dispatch(updateRaiseHandMessages([payload]))
    }
  }
}

export const raiseHand = async () => {
  if (window.zoomout && window.zoomout.rtmChannel) {
    const selfMainUid =
      ((store.getState().zoomout.external.joiningDetails || {}).main || {})
        .uid || -999

    const isSelfHandLowered = !store.getState().zoomout.internal.messages[
      MessageCategory.RAISE_HAND
    ].isHandRaised[selfMainUid]

    if (isSelfHandLowered) {
      const payload = new RaiseHandMessage({
        sender: selfMainUid,
        content: RAISE_HAND_STATES.RAISED,
      })

      await window.zoomout.rtmChannel.sendMessage({
        text: JSON.stringify(payload),
      })
      pushZoomoutEvent({
        eventID: getZoomoutEventId('RAISE_HAND'),
      })

      store.dispatch(updateRaiseHandMessages([payload]))
    }
  }
}

export const toggleRaiseHand = async () => {
  const selfMainUid =
    ((store.getState().zoomout.external.joiningDetails || {}).main || {}).uid ||
    -999

  const isSelfHandRaised = !!store.getState().zoomout.internal.messages[
    MessageCategory.RAISE_HAND
  ].isHandRaised[selfMainUid]

  if (isSelfHandRaised) {
    await lowerHand()
  } else {
    await raiseHand()
  }
}

export const sendSelfHandState = async (peerUID: number | 'all') => {
  if (window.zoomout) {
    const selfMainUid =
      ((store.getState().zoomout.external.joiningDetails || {}).main || {})
        .uid || -999

    const isSelfHandRaised = !!store.getState().zoomout.internal.messages[
      MessageCategory.RAISE_HAND
    ].isHandRaised[selfMainUid]

    if (isSelfHandRaised) {
      if (peerUID === 'all') {
        const payload = new RaiseHandMessage({
          sender: selfMainUid,
          content: RAISE_HAND_STATES.RAISED,
        })

        await window.zoomout.rtmChannel.sendMessage({
          text: JSON.stringify(payload),
        })
      } else {
        const payload = new RaiseHandMessage({
          sender: selfMainUid,
          receivers: [peerUID],
          content: RAISE_HAND_STATES.RAISED,
        })

        await window.zoomout.rtmClient.sendMessageToPeer(
          { text: JSON.stringify(payload) },
          peerUID.toString()
        )
      }
    }
  }
}

export const lowerRemotePeerHand = async (peerUID: number | 'all') => {
  if (window.zoomout) {
    const selfMainUid =
      ((store.getState().zoomout.external.joiningDetails || {}).main || {})
        .uid || -999

    if (peerUID === 'all') {
      const payload = new RemoteOverrideMessage({
        sender: selfMainUid,
        content: REMOTE_OVERRIDE_COMMANDS.LOWER_HAND,
      })

      await window.zoomout.rtmChannel.sendMessage({
        text: JSON.stringify(payload),
      })

      pushZoomoutEvent({
        eventID: getZoomoutEventId('LOWER_HAND'),
        extraInfo: {
          details: 'all hands remotely lowered',
        },
      })
    } else {
      const payload = new RemoteOverrideMessage({
        sender: selfMainUid,
        receivers: [peerUID],
        content: REMOTE_OVERRIDE_COMMANDS.LOWER_HAND,
      })

      await window.zoomout.rtmClient.sendMessageToPeer(
        { text: JSON.stringify(payload) },
        peerUID.toString()
      )

      pushZoomoutEvent({
        eventID: getZoomoutEventId('LOWER_HAND'),
        forUID: peerUID,
      })
    }
  }
}
