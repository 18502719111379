import React from 'react'
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import StudentLayout from 'web/layouts/student'
import { RouteNames } from '../../../routes'
import FallbackHandler from './FallbackHandler'

type ConditionType = (() => Boolean) | Boolean

interface CustomRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
  condition?: ConditionType
  fallback?: React.ComponentType<any>
  redirect?: RouteNames
  layout?: React.ComponentType<any>
}

/**
 *
 * Used to execute the condition passed and retrieve the result
 * whether the route has to be rendered
 */
const checkCondition = (condition: ConditionType) =>
  typeof condition === 'function' ? condition() : condition

/**
 * Render the route only based on some condition and
 * render a fallback component if the condition fails
 */
const CustomRoute = ({
  component: Component,
  fallback,
  redirect,
  condition = false,
  layout = StudentLayout,
  ...rest
}: CustomRouteProps) => {
  const Layout = layout

  return (
    <Layout>
      <Route
        {...rest}
        render={props =>
          checkCondition(condition) ? (
            <Component {...props} />
          ) : (
            <FallbackHandler
              fallback={fallback}
              redirect={redirect}
              {...props}
            />
          )
        }
      />
    </Layout>
  )
}

CustomRoute.defaultProps = {
  condition: false,
}

export default CustomRoute
