import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { matchPath, RouteComponentProps, withRouter } from 'react-router-dom'
import InductionDialog from '../../../components/InductionDialog'
import { DashboardState } from '../../../providers/Dashboard/DashboardProvider'
import { UserOnboardingState } from '../../../providers/User/UserOnboardingProvider'
import routes from '../../../routes'
import { AppState } from '../../../store'
import { UserDetailsState } from '../../../providers/User/UserDetailsProvider'

interface StateProps {
  dashboardData: DashboardState['data']
  onboardingData: UserOnboardingState['data']
  userDetails: UserDetailsState
}

type InductionHandlerProps = StateProps & RouteComponentProps

const InductionHandler = (props: InductionHandlerProps) => {
  const [showInductionDialog, setShowInductionDialog] = useState(false)
  const [courseInprogress, setCourseInprogress] = useState(false)

  useEffect(() => {
    const inductionShownTime = localStorage.getItem('onboardingInductionPopup')
    const pathName = props.location.pathname
    if (
      props.userDetails.appSource !== 'mobile_app' &&
      !props.onboardingData.currentPage &&
      props.dashboardData &&
      props.dashboardData.orientation &&
      props.dashboardData.orientation.pending &&
      !showInductionDialog
    ) {
      if (
        (matchPath(pathName, { path: routes.DASHBOARD, exact: true }) &&
          (!inductionShownTime ||
            inductionShownTime < new Date().toDateString())) ||
        (matchPath(pathName, { path: routes.COURSE }) &&
          !matchPath(pathName, {
            path: routes.COURSE_ANNOUNCEMENTS,
            exact: true,
          }) &&
          !pathName.includes(
            `/courses/${props.dashboardData.orientation.course_id}`
          ))
      ) {
        setShowInductionDialog(true)
        if (props.dashboardData.orientation.in_progress)
          setCourseInprogress(true)
      }
    } else if (
      showInductionDialog &&
      props.dashboardData &&
      props.dashboardData.orientation &&
      !props.dashboardData.orientation.pending
    ) {
      setShowInductionDialog(false)
      setCourseInprogress(false)
    }
  }, [props.dashboardData, props.onboardingData, props.location.pathname])

  const onDialogClose = () => {
    localStorage.setItem('onboardingInductionPopup', new Date().toDateString())
    setShowInductionDialog(false)
  }

  if (
    showInductionDialog &&
    props.dashboardData &&
    props.dashboardData.orientation &&
    props.dashboardData.orientation.course_id
  )
    return (
      <InductionDialog
        onClose={onDialogClose}
        courseId={props.dashboardData.orientation.course_id}
        courseInprogress={courseInprogress}
      />
    )
  return null
}

const mapStateToProps = (state: AppState): StateProps => ({
  dashboardData: state.dashboard.data,
  onboardingData: state.user.onboarding.data,
  userDetails: state.user.details,
})

export default withRouter(connect(mapStateToProps, {})(InductionHandler))
