import { call, cancelled, takeLatest } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import * as APIs from './GlaNewOnboardingForm.api'
import * as types from './GlaNewOnboardingForm.types'
import {
  updateOlympusLeadData
} from './GlaNewOnboardingForm.actions'

function* updateOlympusLeadDataHandler(
  action: ReturnType<typeof updateOlympusLeadData>
) {
  const abortController = new AbortController()
  try {
    const data: any = yield call(
      APIs.updateOlympusLeadDataAPI,
      action,
      abortController.signal
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* glaNewOnboardingFormMiddleware() {
  yield takeLatest(
    types.UPDATE_OLYMPUS_LEAD_DATA,
    cancelable(
      updateOlympusLeadDataHandler,
      types.UPDATE_OLYMPUS_LEAD_DATA_CANCEL
    )
  )
}
export default ([] as any).concat(glaNewOnboardingFormMiddleware())
