import { Reducer, combineReducers } from 'redux'
import {
  DiscussionModuleItemData,
  DiscussionEntriesData,
  DiscussionEntry,
} from '../../../../../common/types/courses/dq'
import { DiscussionActionTypes } from './Discussion.actions'
import * as types from './Discussion.types'
import { DiscussionEntryViewAPIData } from './Discussion.api'
import { convertArrayToObjectMap } from '../../../../../common/utils'

export type DiscussionState = Pick<
  DiscussionModuleItemData,
  'itemContent' | 'itemActivity'
>

const initialState: DiscussionState['itemActivity'] = {
  entries: {
    data: { byId: {}, unreadEntries: [], parentChildEntryMapping: {} },
    error: false,
    loading: false,
    byEntry: {},
  },
  participants: null,
}

const getNormalizedData = (
  res: DiscussionEntriesData,
  replies: DiscussionEntryViewAPIData['view'],
  data: DiscussionEntryViewAPIData
) => {
  res.unreadEntries = data.unread_entries || []
  replies.forEach(({ replies: childReplies, ...item }) => {
    const tempItemData = { ...item }

    if (Array.isArray(childReplies)) {
      getNormalizedData(res, childReplies, data)
    }

    res.byId[item.id] = {
      ...tempItemData,
      rating:
        data.entry_ratings && Number(data.entry_ratings[item.id]) === 1 ? 1 : 0,
    }

    const parentId = item.parent_id || 'root'
    res.parentChildEntryMapping[parentId] = [
      ...(res.parentChildEntryMapping[parentId] || []),
      item.id,
    ]
  })
}

const discussionActivityReducer: Reducer<
  DiscussionState['itemActivity'],
  DiscussionActionTypes
> = (state = initialState, action): DiscussionState['itemActivity'] => {
  switch (action.type) {
    case types.DISCUSSION_ENTRIES_FETCH:
      return {
        ...state,
        entries: {
          ...state.entries,
          loading: true,
          error: false,
        },
      }

    case types.DISCUSSION_ENTRIES_FETCH_SUCCESS: {
      const data: DiscussionEntriesData = {
        byId: {},
        unreadEntries: [],
        parentChildEntryMapping: {},
      }
      getNormalizedData(data, action.payload.view, action.payload)
      if (action.payload.unread_entries) {
        action.payload.unread_entries.forEach(entryId => {
          if (data.byId[entryId]) {
            data.byId[entryId]!.read_state = 'unread'
          }
        })
      }
      return {
        ...state,
        entries: {
          ...state.entries,
          data,
          loading: false,
          error: false,
        },
        participants: convertArrayToObjectMap(
          action.payload.participants,
          'id'
        ),
      }
    }

    case types.DISCUSSION_ENTRIES_FETCH_FAILURE:
      return {
        ...state,
        entries: {
          ...state.entries,
          loading: false,
          error: action.payload,
        },
      }

    case types.DISCUSSION_ENTRY_POST:
      return {
        ...state,
        entries: {
          ...state.entries,
          byEntry: {
            ...state.entries.byEntry,
            [action.meta.tempId]: {
              completed: false,
              loading: true,
              error: false,
            },
          },
        },
      }

    case types.DISCUSSION_ENTRY_POST_SUCCESS: {
      const currentEntryParentMapping =
        state.entries.data.parentChildEntryMapping[action.meta.parentId] || []

      if (action.meta.parentId === 'root') {
        currentEntryParentMapping.unshift(action.payload.id)
      } else {
        currentEntryParentMapping.push(action.payload.id)
      }

      return {
        ...state,
        entries: {
          ...state.entries,
          data: {
            ...state.entries.data,
            byId: {
              ...state.entries.data.byId,
              [action.payload.id]: action.payload,
            },
            parentChildEntryMapping: {
              ...state.entries.data.parentChildEntryMapping,
              [action.meta.parentId]: currentEntryParentMapping,
            },
          },
          byEntry: {
            ...state.entries.byEntry,
            [action.meta.tempId]: {
              completed: true,
              loading: false,
              error: false,
            },
          },
        },
      }
    }

    case types.DISCUSSION_ENTRY_POST_FAILURE:
      return {
        ...state,
        entries: {
          ...state.entries,
          byEntry: {
            ...state.entries.byEntry,
            [action.meta.tempId]: {
              completed: true,
              loading: false,
              error: action.payload,
            },
          },
        },
      }

    case types.DISCUSSION_ENTRY_UPDATE:
      return {
        ...state,
        entries: {
          ...state.entries,
          byEntry: {
            ...state.entries.byEntry,
            [action.meta.tempId]: {
              completed: false,
              loading: true,
              error: false,
            },
          },
        },
      }

    case types.DISCUSSION_ENTRY_UPDATE_SUCCESS:
      return {
        ...state,
        entries: {
          ...state.entries,
          data: {
            ...state.entries.data,
            byId: {
              ...state.entries.data.byId,
              [action.payload.id]: action.payload,
            },
          },
          byEntry: {
            ...state.entries.byEntry,
            [action.meta.tempId]: {
              completed: true,
              loading: false,
              error: false,
            },
          },
        },
      }

    case types.DISCUSSION_ENTRY_UPDATE_FAILURE:
      return {
        ...state,
        entries: {
          ...state.entries,
          byEntry: {
            ...state.entries.byEntry,
            [action.meta.tempId]: {
              completed: true,
              loading: false,
              error: action.payload,
            },
          },
        },
      }

    case types.DISCUSSION_ENTRY_RATING_UPDATE: {
      const currentEntry = state.entries.data.byId[
        action.meta.discussionEntryId
      ]!
      return {
        ...state,
        entries: {
          ...state.entries,
          data: {
            ...state.entries.data,
            byId: {
              ...state.entries.data.byId,
              [action.meta.discussionEntryId]: {
                ...currentEntry,
                rating_sum: Math.max(
                  (currentEntry.rating_sum || 0) +
                    (action.payload === 0 ? -1 : 1),
                  0
                ),
                rating: action.payload,
              } as DiscussionEntry,
            },
          },
        },
      }
    }
    case types.DISCUSSION_ENTRY_RATING_UPDATE_FAILURE: {
      const currentEntry = state.entries.data.byId[
        action.meta.discussionEntryId
      ]!
      return {
        ...state,
        entries: {
          ...state.entries,
          data: {
            ...state.entries.data,
            byId: {
              ...state.entries.data.byId,
              [action.meta.discussionEntryId]: {
                ...currentEntry,
                rating_sum: Math.max(
                  (currentEntry.rating_sum || 0) +
                    (action.payload === 0 ? -1 : 1),
                  0
                ),
                rating: Number(!currentEntry.rating),
              } as DiscussionEntry,
            },
          },
        },
      }
    }
    case types.DISCUSSION_ENTRY_MARK_AS_READ_SUCCESS:
      return {
        ...state,
        entries: {
          ...state.entries,
          data: {
            ...state.entries.data,
            byId: {
              ...state.entries.data.byId,
              [action.meta.discussionEntryId]: {
                ...state.entries.data.byId[action.meta.discussionEntryId],
                read_state: 'read',
              } as DiscussionEntry,
            },
            unreadEntries: state.entries.data.unreadEntries.filter(
              id => id != action.meta.discussionEntryId
            ),
          },
        },
      }
    default:
      return state
  }
}

const discussionContentReducer: Reducer<
  DiscussionState['itemContent'],
  DiscussionActionTypes
> = (state = null, action): DiscussionState['itemContent'] => {
  if (!state) {
    return state
  }
  switch (action.type) {
    case types.DISCUSSION_ENTRIES_FETCH_SUCCESS:
      return {
        ...state,
        user_can_see_posts: true,
      }
    case types.DISCUSSION_ENTRY_POST_SUCCESS:
      return {
        ...state,
        discussion_subentry_count: state.discussion_subentry_count + 1,
      }
    default:
      return state
  }
}

export default combineReducers<DiscussionState, DiscussionActionTypes>({
  itemActivity: discussionActivityReducer,
  itemContent: discussionContentReducer,
})
