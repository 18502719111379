import { Reducer } from 'redux'
import {
  PeerRatingsData,
  PeerRatingPendingAssignment,
} from 'common/types/peerRatings'
import { PeerRatingsActionTypes } from './PeerRatings.actions'
import {
  FETCH_PENDING_PEER_RATINGS_SUCCESS,
  SUBMIT_PEER_RATINGS,
  SUBMIT_PEER_RATINGS_SUCCESS,
  SUBMIT_PEER_RATINGS_FAILURE,
  TOGGLE_PEER_RATINGS_DIALOG,
} from './PeerRatings.types'

export interface PeerRatingsState {
  data: PeerRatingsData
  dialogOpen: boolean
  loading: boolean
  error: Error | false
}

const initialState: PeerRatingsState = {
  data: {
    parameters: [],
    pending_assignments: [],
    current_assignment: null,
    mandatory_rating: false,
  },
  dialogOpen: false,
  loading: false,
  error: false,
}

const twoDays = 2 * 24 * 60 * 60 * 1000
const getNormalizedData = (state: PeerRatingsState, data: PeerRatingsData) => {
  if (data.pending_assignments.length) {
    let current_assignment = data.pending_assignments[0]
    let dialogOpen = false
    let mandatory_rating = false
    data.pending_assignments.some((assignment: PeerRatingPendingAssignment) => {
      if (
        new Date(assignment.qc_due_date).getTime() - new Date().getTime() <
        twoDays
      ) {
        dialogOpen = true
        mandatory_rating = true
        current_assignment = assignment
        return true
      }
      return false
    })
    return {
      data: {
        ...data,
        current_assignment,
        mandatory_rating,
      },
      dialogOpen,
    }
  }
  return initialState
}

const peerRatingsReducer: Reducer<PeerRatingsState, PeerRatingsActionTypes> = (
  state = initialState,
  action
): PeerRatingsState => {
  switch (action.type) {
    case FETCH_PENDING_PEER_RATINGS_SUCCESS:
      return {
        ...state,
        ...getNormalizedData(state, action.payload),
      }
    case TOGGLE_PEER_RATINGS_DIALOG:
      return {
        ...state,
        dialogOpen: action.payload.open,
      }
    case SUBMIT_PEER_RATINGS:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case SUBMIT_PEER_RATINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...getNormalizedData(state, {
          ...state.data,
          pending_assignments: state.data.pending_assignments.filter(
            assignment => assignment.assignment_id !== action.meta.assignment_id
          ),
        }),
      }
    }
    case SUBMIT_PEER_RATINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export default peerRatingsReducer
