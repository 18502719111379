import { Reducer } from 'redux'
import { UpcomingWebinarsData } from 'common/types/upcomingWebinars'
import * as types from './UpcomingWebinars.types'
import { UpcomingWebinarsActions } from './UpcomingWebinars.action'

export type UpcomingWebinarsStateData = UpcomingWebinarsData['webinars']

export type UpcomingWebinarsState = Readonly<{
  data: UpcomingWebinarsStateData
  loading: boolean
  loaded: boolean
  error: boolean | Error
}>

const initialState: UpcomingWebinarsState = {
  data: [],
  loading: false,
  loaded: false,
  error: false,
}

const upcomingWebinarsReducer: Reducer<
  UpcomingWebinarsState,
  UpcomingWebinarsActions
> = (state = initialState, action): UpcomingWebinarsState => {
  switch (action.type) {
    case types.FETCH_UPCOMING_WEBINARS_DETAILS: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      }
    }
    case types.FETCH_UPCOMING_WEBINARS_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.webinars || [],
        loading: false,
        loaded: true,
        error: false,
      }
    }
    case types.FETCH_UPCOMING_WEBINARS_DETAILS_CANCEL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false,
      }
    }
    case types.FETCH_UPCOMING_WEBINARS_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      }
    }
    default:
      return state
  }
}

export default upcomingWebinarsReducer
