import { Reducer } from 'redux'
import {
  UserOnboardingAPIData,
  PreferencesQuestions,
  ExcelerateOnboardingData,
} from '../../../../common/types/user'
import { UserOnboardingActionsType } from './UserOnboarding.actions'
import * as types from './UserOnboarding.types'
import { userSettingsTypes } from '../UserSettingsProvider'
import { UserSettingsAction } from '../UserSettingsProvider/UserSettings.actions'
import { CustomError } from '../../../../common/utils'
import {
  ProgramActions,
  UPDATE_ACTIVE_PROGRAM,
} from '../../Dashboard/ProgramsProvider'

export type UserOnboardingStep =
  | { page: 'policy' }
  | { page: 'placementPolicy' }
  | { page: 'ipProtection' }
  | { page: 'testimonials' }
  | { page: 'identification' }
  | { page: 'identityConfirmation' }
  | { page: 'profile' }
  | { page: 'excelerateProfile' }
  | { page: 'imageCapture' }
  | { page: 'preferences' }
  | { page: 'enrollmentDetails' }
  | { page: 'participantResponsibility' }
  | {
      page: 'linkedin'
      canOptOut: boolean
    }

export type UserOnboardingState = {
  data: {
    isOnboardingCompleted: boolean | null
    currentPage: UserOnboardingStep | null
    isPrimaryOnboarding: boolean | null
    content: UserOnboardingAPIData | null
    questions: PreferencesQuestions[] | null
    excelerateOnboardingData: ExcelerateOnboardingData | null
    trademarkAndCopyrightAccepted: boolean
  }
  loading: boolean
  completed: boolean
  error:
    | boolean
    | Error
    | Response
    | CustomError<{
        user?: string | undefined
        user_email?: string | undefined
        time_zone?: string | undefined
      }>
  redirectUrl?: string
}

const isPolicyPageCompleted = (
  content: Exclude<UserOnboardingState['data']['content'], null>
): boolean =>
  content.skip_policies === true || content.policies_accepted === true

const isPlacementPolicyPageCompleted = (
  content: Exclude<UserOnboardingState['data']['content'], null>
): boolean =>
  content.skip_policies === true || content.placement_policy_accepted === true

const isIpProtectionPageCompleted = (
  content: Exclude<UserOnboardingState['data']['content'], null>
): boolean => content.ip_protection_accepted === true

const isParticipantsResponsibilityCompleted = (
  content: Exclude<UserOnboardingState['data']['content'], null>
): boolean => content.participant_responsibility_accepted === true

const isTestimonialsPageCompleted = (
  content: Exclude<UserOnboardingState['data']['content'], null>
): boolean =>
  content.testimonials_consent_accepted ||
  content.has_user_seen_testimonials_consent

const isIdentificationPageCompleted = (
  content: Exclude<UserOnboardingState['data']['content'], null>
): boolean => !!content.identification_submitted

const isIdentityConfirmationPageCompleted = (
  content: Exclude<UserOnboardingState['data']['content'], null>
): boolean =>
  content.identification_completed || content.identity_verification_pending

const isImageCapturePageCompleted = (
  content: Exclude<UserOnboardingState['data']['content'], null>
): boolean => !!content.image_captured

const isProfilePageCompleted = (
  content: Exclude<UserOnboardingState['data']['content'], null>
): boolean =>
  !!(
    content.user_access_type !== 'basic' ||
    (content.phone &&
      content.user_name &&
      content.email_address &&
      content.time_zone &&
      content.is_us_university_step_completed)
  )

const isLinkedinPageCompleted = (
  content: Exclude<UserOnboardingState['data']['content'], null>
): boolean =>
  !!(
    content.linkedin_auth_opted_out ||
    (content.linkedin_auth_completed && content.linkedin_public_profile_url)
  )

const showExcelerateProfileQuestions = (
  content: Exclude<UserOnboardingState['data']['content'], null>
): boolean => {
  if (content.show_excelerate_profile_questions) {
    return content.show_excelerate_profile_questions
  }
  return false
}

const showPreferenceQuestions = (
  content: Exclude<UserOnboardingState['data']['content'], null>
): boolean => {
  if (content.show_preferences_question) {
    return content.show_preferences_question
  }
  return false
}

const showExtraUserQuestionsForUniversity = (
  content: Exclude<UserOnboardingState['data']['content'], null>
): boolean => {
  if (content.ask_extra_details_for_university) {
    return content.ask_extra_details_for_university
  }
  return false
}

const isOnboardingCompleted = (
  content: Exclude<UserOnboardingState['data']['content'], null>
) =>
  isPolicyPageCompleted(content) &&
  isPlacementPolicyPageCompleted(content) &&
  isIpProtectionPageCompleted(content) &&
  isProfilePageCompleted(content) &&
  isIdentificationPageCompleted(content) &&
  isIdentityConfirmationPageCompleted(content) &&
  isImageCapturePageCompleted(content) &&
  isLinkedinPageCompleted(content) &&
  isTestimonialsPageCompleted(content) &&
  !showExcelerateProfileQuestions(content) &&
  !showPreferenceQuestions(content) &&
  isParticipantsResponsibilityCompleted(content) &&
  !showExtraUserQuestionsForUniversity(content)

const isPrimaryOnboarding = (data: UserOnboardingState['data']) => {
  if (data.isPrimaryOnboarding === null) {
    if (data.currentPage) {
      if (data.currentPage.page === 'policy') return true
      return false
    }
    return null
  }
  return data.isPrimaryOnboarding
}

const getNextOnboardingStep = (
  data: UserOnboardingState['data']
): UserOnboardingStep | null => {
  if (!data.content) {
    return null
  }
  // Policy and T&C Page
  if (!isPolicyPageCompleted(data.content)) {
    return { page: 'policy' }
  }

  // identification page
  if (!isIdentificationPageCompleted(data.content)) {
    return { page: 'identification' }
  }

  // identity confirmation page
  if (!isIdentityConfirmationPageCompleted(data.content)) {
    return { page: 'identityConfirmation' }
  }

  // Verify Profile Info Page
  if (
    (data.content.skip_degree_program_onboarding_steps &&
      isIpProtectionPageCompleted(data.content) &&
      isPlacementPolicyPageCompleted(data.content) &&
      (!isProfilePageCompleted(data.content) ||
        (data.currentPage &&
          ['ipProtection', 'placementPolicy'].includes(data.currentPage.page) &&
          data.isPrimaryOnboarding))) ||
    (!data.content.skip_degree_program_onboarding_steps &&
      (!isProfilePageCompleted(data.content) ||
        (data.currentPage &&
          (data.currentPage.page === 'identification' ||
            data.currentPage.page === 'identityConfirmation') &&
          !isImageCapturePageCompleted(data.content))))
  ) {
    return { page: 'profile' }
  }

  // image capture page
  if (!isImageCapturePageCompleted(data.content)) {
    return { page: 'imageCapture' }
  }

  // ip Policy Page
  if (!isIpProtectionPageCompleted(data.content)) {
    return { page: 'ipProtection' }
  }

  // Placement Policy
  if (!isPlacementPolicyPageCompleted(data.content)) {
    return { page: 'placementPolicy' }
  }

  // testimonials consent page
  if (!isTestimonialsPageCompleted(data.content)) {
    return { page: 'testimonials' }
  }

  // Preference questions for availability and work experience
  if (showPreferenceQuestions(data.content)) {
    return { page: 'preferences' }
  }

  // Set Participant Responsibility
  if (!isParticipantsResponsibilityCompleted(data.content)) {
    return { page: 'participantResponsibility' }
  }

  // excelerate v2 onboarding questions
  if (showExcelerateProfileQuestions(data.content)) {
    return { page: 'excelerateProfile' }
  }

  // LinkedIn Verification Page
  if (!isLinkedinPageCompleted(data.content)) {
    const linkedinOnboardingSkipDate = localStorage.getItem(
      'linkedinOnboarding'
    )
    if (!linkedinOnboardingSkipDate) {
      return { page: 'linkedin', canOptOut: false }
    }
    const timeSinceSkip = Date.now() - Number(linkedinOnboardingSkipDate)

    /**
     * Show LinkedIn onboarding step to user and make him mandatorily choose an option after 14 days
     * 14 Days === 1209600000 ms
     */
    if (timeSinceSkip > 1209600000) {
      return { page: 'linkedin', canOptOut: true }
    }
  }

  // Personal User Questions for universities to get the certificates
  if (showExtraUserQuestionsForUniversity(data.content)) {
    return { page: 'enrollmentDetails' }
  }
  return null
}

const initialState: UserOnboardingState = {
  data: {
    currentPage: null,
    isOnboardingCompleted: null,
    isPrimaryOnboarding: null,
    content: null,
    questions: null,
    excelerateOnboardingData: null,
    trademarkAndCopyrightAccepted: true,
  },
  loading: false,
  completed: false,
  error: false,
}

const userOnboardingReducer: Reducer<
  UserOnboardingState,
  | UserOnboardingActionsType
  | UserSettingsAction
  | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
> = (state = initialState, action): UserOnboardingState => {
  switch (action.type) {
    case types.USER_ONBOARDING_DETAILS_FETCH:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case types.USER_ONBOARDING_DETAILS_FETCH_SUCCESS: {
      const data = {
        ...state.data,
        content: action.payload,
        isOnboardingCompleted: isOnboardingCompleted(action.payload),
        trademarkAndCopyrightAccepted:
          action.payload.trademark_and_copyright_accepted,
      }
      return {
        ...state,
        data: {
          ...data,
          currentPage: getNextOnboardingStep(data),
        },
        completed: true,
        loading: false,
        error: false,
      }
    }

    case types.USER_ONBOARDING_DETAILS_FETCH_FAILURE:
      return {
        ...state,
        completed: true,
        loading: false,
        error: action.payload,
      }

    case types.PREFERENCES_QUESTIONS_FETCH:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case types.PREFERENCES_QUESTIONS_FETCH_SUCCESS: {
      const data = {
        ...state.data,
        questions: action.payload.questions,
      }
      return {
        ...state,
        data: {
          ...data,
        },
        completed: true,
        loading: false,
        error: false,
      }
    }

    case types.PREFERENCES_QUESTIONS_FETCH_FAILURE:
      return {
        ...state,
        completed: true,
        loading: false,
        error: action.payload,
      }

    case types.EXCELERATE_ONBOARDING_DATA_FETCH:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case types.EXCELERATE_ONBOARDING_DATA_FETCH_SUCCESS: {
      const data = {
        ...state.data,
        excelerateOnboardingData: action.payload,
      }
      return {
        ...state,
        data: {
          ...data,
        },
        completed: true,
        loading: false,
        error: false,
      }
    }

    case types.EXCELERATE_ONBOARDING_DATA_FETCH_FAILURE:
      return {
        ...state,
        completed: true,
        loading: false,
        error: action.payload,
      }

    case types.USER_ONBOARDING_DETAILS_UPDATE:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case types.USER_ONBOARDING_DETAILS_UPDATE_SUCCESS: {
      const content: UserOnboardingAPIData = {
        ...state.data.content,
        ...action.payload,
      } as UserOnboardingAPIData
      const data = {
        ...state.data,
        content,
        isOnboardingCompleted: isOnboardingCompleted(content),
        isPrimaryOnboarding: isPrimaryOnboarding(state.data),
        trademarkAndCopyrightAccepted:
          'trademark_and_copyright_accepted' in action.payload
            ? action.payload.trademark_and_copyright_accepted
            : state.data.trademarkAndCopyrightAccepted,
      }
      return {
        ...state,
        data: {
          ...data,
          currentPage: getNextOnboardingStep(data),
        },
        loading: false,
        error: false,
      }
    }
    case types.USER_ONBOARDING_DETAILS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case types.USER_ONBOARDING_PAGE_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          currentPage: action.payload,
        },
      }
    case types.USER_ONBOARDING_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload.url,
      }

    case userSettingsTypes.SUBMIT_PERSONAL_DETAILS: {
      if (
        !state.data.currentPage ||
        state.data.currentPage.page !== 'profile'
      ) {
        return state
      }
      return {
        ...state,
        loading: true,
        error: false,
      }
    }
    case userSettingsTypes.SUBMIT_PERSONAL_DETAILS_SUCCESS: {
      if (
        !state.data.currentPage ||
        state.data.currentPage.page !== 'profile'
      ) {
        return state
      }
      const initialContent = state.data.content
      if (initialContent) {
        const content: UserOnboardingAPIData = {
          ...initialContent,
          email_address: action.payload.initialData.email
            ? action.payload.initialData.email
            : initialContent.email_address,
          phone: action.payload.initialData.mobile
            ? action.payload.initialData.mobile
            : initialContent.phone,
          user_name: action.payload.initialData.fullName
            ? action.payload.initialData.fullName
            : initialContent.user_name,
          time_zone: action.payload.initialData.timeZone
            ? action.payload.initialData.timeZone
            : initialContent.time_zone,
        }
        const data = {
          ...state.data,
          content,
          isOnboardingCompleted: isOnboardingCompleted(content),
        }

        return {
          ...state,
          data: {
            ...data,
            currentPage: getNextOnboardingStep(data),
          },
          loading: false,
          error: false,
        }
      }
      return initialState
    }
    case userSettingsTypes.SUBMIT_PERSONAL_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_ACTIVE_PROGRAM:
      return initialState

    default:
      return state
  }
}

export default userOnboardingReducer
