import { apiCall, getUserDetails } from '../../../../common/utils'

import { fetchCourseQuizzes } from './Quiz.actions'

export async function getCourseQuizzesAPI(
  action: ReturnType<typeof fetchCourseQuizzes>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { courseId } = action.payload

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/courses/${courseId}/quizzes`,
    params: { signal },
    query: {
      user_id: id,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
