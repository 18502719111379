import React, { Fragment, ReactNode, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import AcademyPgpRecommendationPopup from 'web/containers/AcademyReferrals/AcademyPgpRecommendationPopup'
import AcademyCertificatePaymentPopup from 'web/containers/AcademyCertificatePayment/AcademyCertificatePaymentPopup'
import { showGLLoader as showGLLoaderAction } from 'web/providers/Common/Common.actions'
import AppContents from '../containers/AppContents'
import FeedbackDialog from '../containers/Feedback/FeedbackDialog'
import InductionHandler from '../containers/Onboarding/InductionHandler'
import FreeUserInfo from '../containers/FreeUserInfo'

const StudentBaseLayout = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch()
  const showGLLoader = useCallback(
    data => dispatch(showGLLoaderAction(data)),
    []
  )

  useEffect(() => {
    showGLLoader({ show: false })
  }, [])

  return (
    <Fragment>
      <AppContents>
        <InductionHandler />
        <FeedbackDialog />
        <FreeUserInfo />
        <AcademyPgpRecommendationPopup />
        <AcademyCertificatePaymentPopup />
        {children}
      </AppContents>
    </Fragment>
  )
}

export default StudentBaseLayout
