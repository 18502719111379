import { UserID } from 'common/types/user'
import {
  START_PROCTORING,
  STOP_PROCTORING,
  PAUSE_PROCTORING,
  CONTINUE_PROCTORING,
  INITIATE_PROCTORING,
  INITIATE_PROCTORING_SUCCESS,
  INITIATE_PROCTORING_FAILURE,
  UPDATE_USER_PROCTOR_INFO,
  UPDATE_USER_PROCTOR_INFO_SUCCESS,
  UPDATE_USER_PROCTOR_INFO_FAILURE,
  END_PROCTORING,
  END_PROCTORING_SUCCESS,
  END_PROCTORING_FAILURE,
  SET_FORCE_SUBMIT,
  SET_STREAM_INFO,
  UPDATE_PROCTORING_DETAILS,
  UPDATE_PROCTORING_DETAILS_SUCCESS,
  UPDATE_PROCTORING_DETAILS_FAILURE,
  SUBMIT_ONBOARDING_PHOTO,
  PROCTOR_CONTENT_FETCH_SUCCESS,
  SUBMIT_ONBOARDING_PHOTO_SUCCESS,
  SUBMIT_ONBOARDING_PHOTO_FAILURE,
  UPDATE_EXAM_ONBOARDING,
} from './Proctoring.types'
import { QuizID, QuizCompletionType } from '../../../common/types/courses/quiz'
import { LiveStreamData } from '../../../common/types/proctoring'
import { ItemContentMetaParams } from '../Courses/ModuleItemsProvider/ItemContent/ItemContent.actions'

interface proctoringPayload {
  assessment_id: QuizID
  submission_uid: string
  mediaStream?: MediaStream
  startedAt: string
  anomalyDetection: boolean
  openBook: boolean
  is_coding_lab_assignment?: boolean
}
export interface onboardingPayload {
  image: string
  user_id: UserID | null
  context_type: string
  context_id: string
}

export interface updateExamOnboardingPayload {
  complete?: boolean
  photo_verified?: boolean
  rejected?: boolean
  photo_taken?: boolean
}
interface UpdateProctoringInfoPayload {
  submission_uid: string
  student_uid: string | number
  status: 'started'
  assessment_id: QuizID
  assessment_type?: string
}
interface proctoringSuccessMeta {
  type?: 'Assignment' | 'Quiz'
}

export const submitOnboardingPhoto = (payload: onboardingPayload) => ({
  type: SUBMIT_ONBOARDING_PHOTO as typeof SUBMIT_ONBOARDING_PHOTO,
  payload,
})
export const submitOnboardingPhotoSuccess = (payload: onboardingPayload) => ({
  type: SUBMIT_ONBOARDING_PHOTO_SUCCESS as typeof SUBMIT_ONBOARDING_PHOTO_SUCCESS,
  payload,
})
export const submitOnboardingPhotoFailure = (payload: onboardingPayload) => ({
  type: SUBMIT_ONBOARDING_PHOTO_FAILURE as typeof SUBMIT_ONBOARDING_PHOTO_FAILURE,
  payload,
})
export const updateExamOnboardingData = (
  payload: updateExamOnboardingPayload
) => ({
  type: UPDATE_EXAM_ONBOARDING as typeof UPDATE_EXAM_ONBOARDING,
  payload,
})

export const fetchProctorContentSuccess = (
  payload: any,
  meta: ItemContentMetaParams
) => ({
  type: PROCTOR_CONTENT_FETCH_SUCCESS as typeof PROCTOR_CONTENT_FETCH_SUCCESS,
  payload,
  meta,
})

export const initiateProctoring = (payload: proctoringPayload) => ({
  type: INITIATE_PROCTORING as typeof INITIATE_PROCTORING,
  payload,
})
export const initiateProctoringSuccess = (
  payload: any,
  meta: proctoringSuccessMeta
) => ({
  type: INITIATE_PROCTORING_SUCCESS as typeof INITIATE_PROCTORING_SUCCESS,
  payload,
  meta,
})
export const initiateProctoringFailure = (payload: Error) => ({
  type: INITIATE_PROCTORING_FAILURE as typeof INITIATE_PROCTORING_FAILURE,
  payload,
})

export const startProctoring = () => ({
  type: START_PROCTORING as typeof START_PROCTORING,
})

export const updateUserProctorInfo = (payload: {
  count?: number
  assessment_type?: string
}) => ({
  type: UPDATE_USER_PROCTOR_INFO as typeof UPDATE_USER_PROCTOR_INFO,
  payload,
})
export const updateUserProctorInfoSuccess = (payload: any) => ({
  type: UPDATE_USER_PROCTOR_INFO_SUCCESS as typeof UPDATE_USER_PROCTOR_INFO_SUCCESS,
  payload,
})
export const updateUserProctorInfoFailure = (payload: Error) => ({
  type: UPDATE_USER_PROCTOR_INFO_FAILURE as typeof UPDATE_USER_PROCTOR_INFO_FAILURE,
  payload,
})

export const stopProctoring = () => ({
  type: STOP_PROCTORING as typeof STOP_PROCTORING,
})
export const pauseProctoring = (payload?: {
  type: 'coding_lab' | 'quiz'
  codingLabAssignmentId?: number
  is_focused?: boolean
}) => ({
  type: PAUSE_PROCTORING as typeof PAUSE_PROCTORING,
  payload,
})
export const continueProctoring = (payload?: {
  type?: 'coding_lab' | 'quiz'
  codingLabAssignmentId?: number
  is_focused?: boolean
}) => ({
  type: CONTINUE_PROCTORING as typeof CONTINUE_PROCTORING,
  payload,
})

export const endProctoring = (payload: {
  completionType: QuizCompletionType
  assessment_type?: string
}) => ({
  type: END_PROCTORING as typeof END_PROCTORING,
  payload,
})
export const endProctoringSuccess = (payload: any) => ({
  type: END_PROCTORING_SUCCESS as typeof END_PROCTORING_SUCCESS,
  payload,
})
export const endProctoringFailure = (payload: Error) => ({
  type: END_PROCTORING_FAILURE as typeof END_PROCTORING_FAILURE,
  payload,
})
export const setForceSubmit = () => ({
  type: SET_FORCE_SUBMIT as typeof SET_FORCE_SUBMIT,
})
export const setStreamInfo = (payload: LiveStreamData) => ({
  type: SET_STREAM_INFO as typeof SET_STREAM_INFO,
  payload,
})

export const updateProctoringDetails = (
  payload: UpdateProctoringInfoPayload
) => ({
  type: UPDATE_PROCTORING_DETAILS as typeof UPDATE_PROCTORING_DETAILS,
  payload,
})

export const updateProctoringDetailsSuccess = (payload: any) => ({
  type: UPDATE_PROCTORING_DETAILS_SUCCESS as typeof UPDATE_PROCTORING_DETAILS_SUCCESS,
  payload,
})

export const updateProctoringDetailsFailure = (payload: Error) => ({
  type: UPDATE_PROCTORING_DETAILS_FAILURE as typeof UPDATE_PROCTORING_DETAILS_FAILURE,
  payload,
})

export type ProctoringActionTypes =
  | ReturnType<typeof startProctoring>
  | ReturnType<typeof stopProctoring>
  | ReturnType<typeof pauseProctoring>
  | ReturnType<typeof continueProctoring>
  | ReturnType<typeof initiateProctoring>
  | ReturnType<typeof initiateProctoringSuccess>
  | ReturnType<typeof initiateProctoringFailure>
  | ReturnType<typeof updateUserProctorInfo>
  | ReturnType<typeof updateUserProctorInfoSuccess>
  | ReturnType<typeof updateUserProctorInfoFailure>
  | ReturnType<typeof endProctoring>
  | ReturnType<typeof endProctoringSuccess>
  | ReturnType<typeof endProctoringFailure>
  | ReturnType<typeof setForceSubmit>
  | ReturnType<typeof setStreamInfo>
  | ReturnType<typeof updateProctoringDetails>
  | ReturnType<typeof updateProctoringDetailsSuccess>
  | ReturnType<typeof updateProctoringDetailsFailure>
  | ReturnType<typeof fetchProctorContentSuccess>
  | ReturnType<typeof submitOnboardingPhoto>
  | ReturnType<typeof submitOnboardingPhotoSuccess>
  | ReturnType<typeof submitOnboardingPhotoFailure>
  | ReturnType<typeof updateExamOnboardingData>
