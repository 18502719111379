import {
  CHECK_USER_CONSENT,
  CHECK_USER_CONSENT_SUCCESS,
  UPDATE_MOUTHSHUT_EVENT,
  USER_CONSENT_ACCEPT,
} from './UserConsent.types'

export const checkUserConsent = () => ({
  type: CHECK_USER_CONSENT as typeof CHECK_USER_CONSENT,
})

export const checkUserConsentSuccess = (payload: any) => ({
  type: CHECK_USER_CONSENT_SUCCESS as typeof CHECK_USER_CONSENT_SUCCESS,
  payload,
})

export const userConsentAccept = () => ({
  type: USER_CONSENT_ACCEPT as typeof USER_CONSENT_ACCEPT,
})

export const updateMouthShutEvent = (payload: { event: string }) => ({
  type: UPDATE_MOUTHSHUT_EVENT as typeof UPDATE_MOUTHSHUT_EVENT,
  payload,
})

export type UserConsentActions =
  | ReturnType<typeof checkUserConsent>
  | ReturnType<typeof checkUserConsentSuccess>
  | ReturnType<typeof userConsentAccept>
  | ReturnType<typeof updateMouthShutEvent>
