import { AppState } from '../../../store'
import { UserPaymentsData } from '../../../../common/types/user'

export const getPaymentTransaction = () => (
  state: AppState
): UserPaymentsData['payment_transaction'] | null => {
  if (state.user.payments.transactions.paymentsInfo !== undefined) {
    const paymentTransaction: UserPaymentsData['payment_transaction'] =
      state.user.payments.transactions.paymentsInfo.payment_transaction
    const taxInfo = state.user.payments.transactions.taxInfo || {}
    if (paymentTransaction) {
      return { ...paymentTransaction, ...taxInfo }
    }
  }
  return null
}
