import {
  FETCH_LEADERBOARD_CONTENT,
  FETCH_LEADERBOARD_CONTENT_CANCEL,
  FETCH_LEADERBOARD_CONTENT_FAILURE,
  FETCH_LEADERBOARD_CONTENT_SUCCESS,
} from './Leaderboard.types'

export const fetchLeaderboardContent = (payload: any) => ({
  type: FETCH_LEADERBOARD_CONTENT as typeof FETCH_LEADERBOARD_CONTENT,
  payload,
})

export const fetchLeaderboardContentSuccess = (payload: any) => ({
  type: FETCH_LEADERBOARD_CONTENT_SUCCESS as typeof FETCH_LEADERBOARD_CONTENT_SUCCESS,
  payload,
})

export const fetchLeaderboardContentFailure = (payload: Error) => ({
  type: FETCH_LEADERBOARD_CONTENT_FAILURE as typeof FETCH_LEADERBOARD_CONTENT_FAILURE,
  payload,
})

export const fetchLeaderboardCancel = () => ({
  type: FETCH_LEADERBOARD_CONTENT_CANCEL as typeof FETCH_LEADERBOARD_CONTENT_CANCEL,
})

export type LeaderboardActions =
  | ReturnType<typeof fetchLeaderboardContent>
  | ReturnType<typeof fetchLeaderboardContentSuccess>
  | ReturnType<typeof fetchLeaderboardContentFailure>
  | ReturnType<typeof fetchLeaderboardCancel>
