import { Reducer } from 'redux'
import {
  FETCH_ENROLLED_USER_DETAILS,
  FETCH_ENROLLED_USER_DETAILS_SUCCESS,
  FETCH_ENROLLED_USER_DETAILS_FAILURE,
  SEND_MESSAGE_TO_PEERS_SUCCESS,
  SEND_MESSAGE_TO_PEERS,
} from './AlumniPortal.types'

import { AlumniPortalActions } from './AlumniPortal.actions'

import { AlumniPortalData } from '../../../common/types/alumni'

export interface AlumniPortalState {
  alumniUserData?: AlumniPortalData
  loading: boolean
  error: false | Error | Response
  message_sent: boolean
  sending_message: boolean
}
const initialState: AlumniPortalState = {
  loading: true,
  error: false,
  message_sent: false,
  sending_message: false
}
const AlumniPortalReducer: Reducer<AlumniPortalState, AlumniPortalActions> = (
  state = initialState,
  action
): AlumniPortalState => {
  switch (action.type) {
    case FETCH_ENROLLED_USER_DETAILS:
      return {
        ...state,
        loading: true,
        error: false,
        message_sent: false,
      }
    case FETCH_ENROLLED_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        alumniUserData: action.payload,
        message_sent: false,
      }
    case FETCH_ENROLLED_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        message_sent: false,
      }
    case SEND_MESSAGE_TO_PEERS:
      return {
        ...state,
        sending_message: true,
        message_sent: false,
      }
    case SEND_MESSAGE_TO_PEERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        sending_message: false,
        message_sent: true,
      }
    default:
      return state
  }
}
export default AlumniPortalReducer
