import { IconProps } from '@material-ui/core/Icon'
import {
  AvTimer,
  CenterFocusWeak,
  Close,
  Done,
  Grade,
  People,
  ThumbUp,
  Warning,
  Videocam,
  PriorityHigh,
} from '@material-ui/icons'
import cx from 'classnames'
import React from 'react'
import {
  AssignmentIcon,
  DiscussionIcon,
  LinkIcon,
  QuizIcon,
} from '../CustomIcons/CustomIcons'
import styles from './CircleIcons.module.css'

interface Props extends IconProps {
  containerClassName?: string
  iconClassName?: string
}

export const CircleThumbUpIcon = ({
  containerClassName,
  iconClassName,
}: Props) => (
  <div className={cx(styles.container, containerClassName, styles.thumbUp)}>
    <ThumbUp className={cx(styles.icon, styles.small, iconClassName)} />
  </div>
)
export const CircleWarningIcon = ({
  containerClassName,
  iconClassName,
}: Props) => (
  <div className={cx(styles.container, containerClassName, styles.warning)}>
    <Warning className={cx(styles.icon, styles.small, iconClassName)} />
  </div>
)
export const CircleErrorIcon = ({
  containerClassName,
  iconClassName,
}: Props) => (
  <div className={cx(styles.container, containerClassName, styles.error)}>
    <PriorityHigh className={cx(styles.icon, iconClassName)} />
  </div>
)
export const CircleAssignmentIcon = ({
  containerClassName,
  iconClassName,
}: Props) => (
  <div className={cx(styles.container, containerClassName, styles.assignment)}>
    <AssignmentIcon
      coloured
      className={cx(styles.big, styles.icon, iconClassName)}
    />
  </div>
)
export const CircleDQIcon = ({ containerClassName, iconClassName }: Props) => (
  <div className={cx(styles.container, containerClassName, styles.dq)}>
    <DiscussionIcon
      coloured
      className={cx(styles.big, styles.icon, iconClassName)}
    />
  </div>
)
export const CircleResidencyIcon = ({
  containerClassName,
  iconClassName,
}: Props) => (
  <div className={cx(styles.container, containerClassName, styles.residency)}>
    <People className={cx(styles.big, styles.icon, iconClassName)} />
  </div>
)
export const CircleQuizIcon = ({
  containerClassName,
  iconClassName,
}: Props) => (
  <div className={cx(styles.container, containerClassName, styles.quiz)}>
    <QuizIcon coloured className={cx(styles.big, styles.icon, iconClassName)} />
  </div>
)

export const CircleLinkIcon = ({
  containerClassName,
  iconClassName,
}: Props) => (
  <div className={cx(styles.container, containerClassName, styles.quiz)}>
    <LinkIcon coloured className={cx(styles.big, styles.icon, iconClassName)} />
  </div>
)

export const CircleOnlineSessionIcon = ({
  containerClassName,
  iconClassName,
}: Props) => (
  <div
    className={cx(styles.container, containerClassName, styles.onlineSession)}
  >
    <CenterFocusWeak className={cx(styles.big, styles.icon, iconClassName)} />
  </div>
)

export const CircleMentorshipIcon = ({
  containerClassName,
  iconClassName,
}: Props) => (
  <div
    className={cx(
      styles.container,
      containerClassName,
      styles.mentorshipRecording
    )}
  >
    <Videocam className={cx(styles.big, styles.icon, iconClassName)} />
  </div>
)

export const CircleGradedIcon = ({
  containerClassName,
  iconClassName,
}: Props) => (
  <div className={cx(styles.container, containerClassName, styles.graded)}>
    <Grade className={cx(styles.big, styles.icon, iconClassName)} />
  </div>
)
export const CirclePassedIcon = ({
  containerClassName,
  iconClassName,
}: Props) => (
  <div className={cx(styles.container, containerClassName, styles.passed)}>
    <Done className={cx(styles.big, styles.icon, iconClassName)} />
  </div>
)
export const CircleInProgressIcon = ({
  containerClassName,
  iconClassName,
}: Props) => (
  <div className={cx(styles.container, containerClassName, styles.inProgress)}>
    <AvTimer className={cx(styles.big, styles.icon, iconClassName)} />
  </div>
)
export const CircleIncompleteIcon = ({
  containerClassName,
  iconClassName,
}: Props) => (
  <div className={cx(styles.container, containerClassName, styles.incomplete)}>
    <Close className={cx(styles.big, styles.icon, iconClassName)} />
  </div>
)
