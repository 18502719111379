import {
  CLAS_WORKFLOW_STATES,
  SERVICE_TASK_STATES,
} from 'common/utils/custom/codingLab'
import { Reducer } from 'redux'
import { QuizID } from 'common/types/courses/quiz'
import { QuizCodingLabsActionTypes } from './QuizCodingLabs.action'
import * as types from './QuizCodingLabs.types'

interface IQuizCodingLab {
  coding_lab_url: string | null
  service_task_state: number | null
  workflow_state: number
  submission_id: number | null
  time_left: number
  service_setup_state: string | null
  due_at: string | null
}

export interface QuizCodingLabsState {
  data: {
    byId: {
      [key in QuizID]: {
        [key in number]: IQuizCodingLab
      }
    }
  } | null
  loading: boolean
  error: false | Error | Response
}

const initialStateModules: QuizCodingLabsState = {
  data: {
    byId: {},
  },
  loading: false,
  error: false,
}

export const quizCodingLabsReducer: Reducer<
  QuizCodingLabsState,
  QuizCodingLabsActionTypes
> = (state = initialStateModules, action): QuizCodingLabsState => {
  switch (action.type) {
    case types.GET_QUIZ_CODING_LAB_STATUS_SUCCESS: {
      const { quiz_id, coding_lab_id } = action.payload
      const newData = { ...state.data?.byId }
      newData[quiz_id] = newData[quiz_id] || {}
      newData[quiz_id][coding_lab_id] = action.payload

      return {
        ...state,
        data: {
          ...state.data,
          byId: newData,
        },
        loading: false,
        error: false,
      }
    }

    default:
      return state
  }
}
