import {
  SUBMIT_QUESTION_ANSWER,
  SUBMIT_QUESTION_ANSWER_SUCCESS,
  SUBMIT_QUESTION_ANSWER_FAILURE,
  FETCH_ANSWERS,
  FETCH_ANSWERS_FAILURE,
  FETCH_ANSWERS_SUCCESS,
  SUBMIT_QA_ACTION,
  SUBMIT_QA_ACTION_FAILURE,
  SUBMIT_QA_ACTION_SUCCESS,
  FETCH_QA_ENTRIES_SUCCESS,
  DELETE_QA_ACTION,
  DELETE_QA_ACTION_FAILURE,
  DELETE_QA_ACTION_SUCCESS,
  DELETE_QUESTION_ANSWER,
  DELETE_QUESTION_ANSWER_FAILURE,
  DELETE_QUESTION_ANSWER_SUCCESS,
  UPDATE_QUESTION_ANSWER,
  UPDATE_QUESTION_ANSWER_FAILURE,
  UPDATE_QUESTION_ANSWER_SUCCESS,
  UPDATE_UNREAD_COUNT,
} from './QA.types'

interface QAEntries {
  questions: any
}

// fetch QA entries
export const fetchQaEntriesSuccess = (payload: QAEntries) => ({
  type: FETCH_QA_ENTRIES_SUCCESS as typeof FETCH_QA_ENTRIES_SUCCESS,
  payload,
})

interface DeleteQuestionAnswerPayload {
  entryId: number
  parentId: null | number
  communityId: null | string
}

// delete question answer
export const deleteQuestionAnswer = (payload: DeleteQuestionAnswerPayload) => ({
  type: DELETE_QUESTION_ANSWER as typeof DELETE_QUESTION_ANSWER,
  payload,
})

export const deleteQuestionAnswerSuccess = (
  payload: DeleteQuestionAnswerPayload
) => ({
  type: DELETE_QUESTION_ANSWER_SUCCESS as typeof DELETE_QUESTION_ANSWER_SUCCESS,
  payload,
})

export const deleteQuestionAnswerFailure = (payload: Error) => ({
  type: DELETE_QUESTION_ANSWER_FAILURE as typeof DELETE_QUESTION_ANSWER_FAILURE,
  payload,
})

interface SubmitQuestionAnswerPayload {
  uid: number
  message: string
  parentId: null | number
  communityId: null | string
  topicIds?: string[]
}

// submit question answer
export const submitQuestionAnswer = (payload: SubmitQuestionAnswerPayload) => ({
  type: SUBMIT_QUESTION_ANSWER as typeof SUBMIT_QUESTION_ANSWER,
  payload,
})

export const submitQuestionAnswerSuccess = (payload: any) => ({
  type: SUBMIT_QUESTION_ANSWER_SUCCESS as typeof SUBMIT_QUESTION_ANSWER_SUCCESS,
  payload,
})

export const submitQuestionAnswerFailure = (payload: Error) => ({
  type: SUBMIT_QUESTION_ANSWER_FAILURE as typeof SUBMIT_QUESTION_ANSWER_FAILURE,
  payload,
})

interface UpdateQuestionAnswerPayload {
  id: number
  message: string
  parentId: null | number
  communityId: null | string
}

// update question answer
export const updateQuestionAnswer = (payload: UpdateQuestionAnswerPayload) => ({
  type: UPDATE_QUESTION_ANSWER as typeof UPDATE_QUESTION_ANSWER,
  payload,
})

export const updateQuestionAnswerSuccess = (payload: any) => ({
  type: UPDATE_QUESTION_ANSWER_SUCCESS as typeof UPDATE_QUESTION_ANSWER_SUCCESS,
  payload,
})

export const updateQuestionAnswerFailure = (payload: Error) => ({
  type: UPDATE_QUESTION_ANSWER_FAILURE as typeof UPDATE_QUESTION_ANSWER_FAILURE,
  payload,
})

interface FetchAnswersPayload {
  questionId: number
}

// fetch answers for question
export const fetchAnswers = (
  payload: FetchAnswersPayload,
  meta: FetchAnswersPayload
) => ({
  type: FETCH_ANSWERS as typeof FETCH_ANSWERS,
  payload,
  meta,
})

export const fetchAnswersSuccess = (
  payload: any,
  meta: FetchAnswersPayload
) => ({
  type: FETCH_ANSWERS_SUCCESS as typeof FETCH_ANSWERS_SUCCESS,
  payload,
  meta,
})

export const fetchAnswersFailure = (payload: Error) => ({
  type: FETCH_ANSWERS_FAILURE as typeof FETCH_ANSWERS_FAILURE,
  payload,
})

interface QaActionPayload {
  uid: number
  entryId: number
  action: string | null
}

// submit qa action
export const submitQaAction = (
  payload: QaActionPayload,
  meta: Pick<QaActionPayload, 'entryId'>
) => ({
  type: SUBMIT_QA_ACTION as typeof SUBMIT_QA_ACTION,
  payload,
  meta,
})

export const submitQaActionSuccess = (
  payload: any,
  meta?: { selfAction: boolean }
) => ({
  type: SUBMIT_QA_ACTION_SUCCESS as typeof SUBMIT_QA_ACTION_SUCCESS,
  payload,
  meta,
})

export const submitQaActionFailure = (payload: Error) => ({
  type: SUBMIT_QA_ACTION_FAILURE as typeof SUBMIT_QA_ACTION_FAILURE,
  payload,
})

// delete qa action
export const deleteQaAction = (
  payload: QaActionPayload,
  meta: Pick<QaActionPayload, 'entryId'>
) => ({
  type: DELETE_QA_ACTION as typeof DELETE_QA_ACTION,
  payload,
  meta,
})

export const deleteQaActionSuccess = (
  payload: any,
  meta?: { selfAction: boolean }
) => ({
  type: DELETE_QA_ACTION_SUCCESS as typeof DELETE_QA_ACTION_SUCCESS,
  payload,
  meta,
})

export const deleteQaActionFailure = (payload: Error) => ({
  type: DELETE_QA_ACTION_FAILURE as typeof DELETE_QA_ACTION_FAILURE,
  payload,
})

export const updateUnreadCount = (payload: { count: number }) => ({
  type: UPDATE_UNREAD_COUNT as typeof UPDATE_UNREAD_COUNT,
  payload,
})

export type ZoomoutQAActions =
  | ReturnType<typeof fetchQaEntriesSuccess>
  | ReturnType<typeof submitQuestionAnswer>
  | ReturnType<typeof submitQuestionAnswerSuccess>
  | ReturnType<typeof submitQuestionAnswerFailure>
  | ReturnType<typeof updateQuestionAnswer>
  | ReturnType<typeof updateQuestionAnswerSuccess>
  | ReturnType<typeof updateQuestionAnswerFailure>
  | ReturnType<typeof deleteQuestionAnswer>
  | ReturnType<typeof deleteQuestionAnswerSuccess>
  | ReturnType<typeof deleteQuestionAnswerFailure>
  | ReturnType<typeof fetchAnswers>
  | ReturnType<typeof fetchAnswersSuccess>
  | ReturnType<typeof fetchAnswersFailure>
  | ReturnType<typeof submitQaAction>
  | ReturnType<typeof submitQaActionSuccess>
  | ReturnType<typeof submitQaActionFailure>
  | ReturnType<typeof deleteQaAction>
  | ReturnType<typeof deleteQaActionSuccess>
  | ReturnType<typeof deleteQaActionFailure>
  | ReturnType<typeof updateUnreadCount>
