import { Reducer } from 'redux'
import { NotificationsActions } from './UserNotifications.actions'
import {
  FETCH_OVERDUE_NOTIFICATIONS,
  FETCH_OVERDUE_NOTIFICATIONS_SUCCESS,
  FETCH_OVERDUE_NOTIFICATIONS_FAILURE,
  FETCH_OVERDUE_NOTIFICATIONS_CANCEL,
} from './UserNotifications.types'

export interface GeneralNotification {
  id: number
  name: string
  title: string
  description: string
  opted_out: boolean
  read_at: string
  created_at: string
  due_at: string
  workflow_state: string
}

export interface NotificationsStateType {
  overdue?: Array<GeneralNotification>
  linkedin?: Array<GeneralNotification>
}

export interface UserNotificationsState {
  data?: NotificationsStateType
  loading: boolean
  error: false | Error | Response
}

const initialState: UserNotificationsState = {
  loading: false,
  error: false,
}

const userOverdueNotificationsReducer: Reducer<
  UserNotificationsState,
  NotificationsActions
> = (state = initialState, action): UserNotificationsState => {
  switch (action.type) {
    case FETCH_OVERDUE_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case FETCH_OVERDUE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, overdue: action.payload.overdue },
        loading: false,
      }
    case FETCH_OVERDUE_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case FETCH_OVERDUE_NOTIFICATIONS_CANCEL:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
export default userOverdueNotificationsReducer
