export const QUIZ_UPDATE_ACTIVE_QUESTION = 'QUIZ_UPDATE_ACTIVE_QUESTION'

export const GET_QUIZ_SUBMISSION_SERVER_TIME = 'GET_QUIZ_SUBMISSION_SERVER_TIME'
export const GET_QUIZ_SUBMISSION_SERVER_TIME_SUCCESS =
  'GET_QUIZ_SUBMISSION_SERVER_TIME_SUCCESS'
export const GET_QUIZ_SUBMISSION_SERVER_TIME_FAILURE =
  'GET_QUIZ_SUBMISSION_SERVER_TIME_FAILURE'

export const QUIZ_SUBMISSION_DYNAMIC_QA_FETCH =
  'QUIZ_SUBMISSION_DYNAMIC_QA_FETCH'
export const QUIZ_SUBMISSION_DYNAMIC_QA_FETCH_SUCCESS =
  'QUIZ_SUBMISSION_DYNAMIC_QA_FETCH_SUCCESS'
export const QUIZ_SUBMISSION_DYNAMIC_QA_FETCH_FAILURE =
  'QUIZ_SUBMISSION_DYNAMIC_QA_FETCH_FAILURE'

export const QUIZ_SUBMISSION_DYNAMIC_QA_LIST_FETCH_SUCCESS =
  'QUIZ_SUBMISSION_DYNAMIC_QA_LIST_FETCH_SUCCESS'

export const QUIZ_SUBMISSION_DYNAMIC_QA_UPDATE_QUESTION_INDEX =
  'QUIZ_SUBMISSION_DYNAMIC_QA_UPDATE_QUESTION_INDEX'
