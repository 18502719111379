import { RewardID } from 'common/types/referrralAndRewards'
import { apiCall, getUserDetails } from '../../../common/utils'
import { IReferralRequest } from 'common/types/referral'

export async function fetchReferralsAndRewardsData(signal: AbortSignal) {
  const { id: userId } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/referrals_rewards`,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function submitClaimReward(
  rewardId: RewardID,
  signal: AbortSignal
) {
  const { id: userId } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/rewards/${rewardId}/claim`,
    params: { signal, method: 'POST' },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}


export async function submitReferral(
  referralParams: IReferralRequest,
  signal: AbortSignal
) {
  const { id: userId } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/referrals/${userId}/create`,
    params: {
      signal,
      method: 'POST',
      body: JSON.stringify(referralParams),
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}