import { QuestionArgs, QuestionStdin } from 'common/types/codingPlatform'
import { Reducer } from 'redux'

import {
  CodingPlatformRuntimes,
  CodingPlatformActionTypes,
  CodeExecuteSuccess,
  CodeEvalQuestionId,
  TestCodeResults,
  CodingPlaygroundId,
  CodeExecuteSqlSuccess,
} from './CodingPlatform.action'
import {
  CODE_EXECUTE,
  CODE_EXECUTE_FAILURE,
  CODE_EXECUTE_SUCCESS,
  GET_PLAYGROUND,
  GET_PLAYGROUND_FAILURE,
  GET_PLAYGROUND_SUCCESS,
  LIST_PLAYGROUNDS,
  LIST_PLAYGROUNDS_FAILURE,
  LIST_PLAYGROUNDS_SUCCESS,
  UPDATE_PLAYGROUND,
  UPDATE_PLAYGROUND_SUCCESS,
  UPDATE_PLAYGROUND_FAILURE,
  CREATE_PLAYGROUND,
  CREATE_PLAYGROUND_SUCCESS,
  CREATE_PLAYGROUND_FAILURE,
  RENAME_PLAYGROUND,
  RENAME_PLAYGROUND_FAILURE,
  RENAME_PLAYGROUND_SUCCESS,
  UPDATE_PLAYGROUND_ARGS,
  UPDATE_PLAYGROUND_STDIN,
  DELETE_PLAYGROUND,
  DELETE_PLAYGROUND_FAILURE,
  DELETE_PLAYGROUND_SUCCESS,
  QUESTION_RUN_SQL_CODE,
  QUESTION_RUN_SQL_CODE_SUCCESS,
  QUESTION_RUN_SQL_CODE_FAILURE,
  QUESTION_TEST_SQL_CODE,
  QUESTION_TEST_SQL_CODE_SUCCESS,
  QUESTION_TEST_SQL_CODE_FAILURE,
  SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_SUCCESS,
  SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_FAILURE,
  SQL_QUESTION_GET_AI_CODE_SUGGESTIONS,
} from './CodingPlatform.types'
import {
  RUNTIMES_FETCH,
  RUNTIMES_FETCH_SUCCESS,
  RUNTIMES_FETCH_FAILURE,
  QUESTION_RUN_CODE,
  QUESTION_RUN_CODE_FAILURE,
  QUESTION_RUN_CODE_SUCCESS,
  QUESTION_TEST_CODE,
  QUESTION_TEST_CODE_FAILURE,
  QUESTION_TEST_CODE_SUCCESS,
  UPDATE_QUESTION_ARGS,
  UPDATE_QUESTION_STDIN,
  QUESTION_GET_AI_CODE_SUGGESTIONS,
  QUESTION_GET_AI_CODE_SUGGESTIONS_CANCEL,
  QUESTION_GET_AI_CODE_SUGGESTIONS_FAILURE,
  QUESTION_GET_AI_CODE_SUGGESTIONS_SUCCESS,
  QUESTION_GET_AI_CODE_HINTS,
  QUESTION_GET_AI_CODE_HINTS_CANCEL,
  QUESTION_GET_AI_CODE_HINTS_FAILURE,
  QUESTION_GET_AI_CODE_HINTS_SUCCESS,
} from './index'

export interface CodingQuestionState {
  runCode: {
    data: CodeExecuteSuccess | null
    loading: boolean
    error: boolean | string
  }
  testCode: {
    data: TestCodeResults | null
    loading: boolean
    error: boolean | string
  }
  stdin: QuestionStdin
  args: QuestionArgs

  runCodeSql: {
    data: CodeExecuteSqlSuccess | null
    loading: boolean
    error: boolean | string
  }

  testSqlCode: {
    data: CodeExecuteSqlSuccess | null
    loading: boolean
    error: boolean | string
  }

  aiCodeSuggestions: {
    data: {
      content: string
    }
    loading: boolean
    error: boolean
  }
  aiCodeHints: {
    data: {
      content: string
    }
    loading: boolean
    error: boolean
  }
}

type APIRes = 'updating' | 'updated' | Error | Response

export interface CodingPlaygroundData {
  code: string
  runCode: {
    data: CodeExecuteSuccess | null
    loading: boolean
    error: false | Error | Response
  }
  stdin: QuestionStdin
  args: QuestionArgs
  runCodeSql?: {
    data: CodeExecuteSqlSuccess | null
    loading: boolean
    error: false | Error | Response
  }
  uploadedToS3?: boolean
  loading: boolean
  error: boolean
  renamed?: APIRes
  deleted?: APIRes
}

export interface CodingPlaygroundState {
  id: number
  file_key: string
  file_name: string
  language: string
  created_at: string
  updated_at: string
}
export interface CodingPlaygroundsState {
  id: number
  file_name: string
  file_key?: string
  language?: string
  created_at?: string
  updated_at?: string
  children?: CodingPlaygroundState[]
}

export interface CreatePlaygroundResponse {
  message: string
  fileExists?: boolean
}

export interface RenamePlaygroundResponse {
  message: string
  fileExists?: boolean
}

export interface UpdatePlaygroundResponse {
  message: string
}
export interface DeletePlaygroundResponse {
  message: string
}

export interface CodingPlatformState {
  runtimes: {
    data: CodingPlatformRuntimes
    loading: boolean
    error: false | Error | Response
  }
  codingQuestions:
  | {
    [key in CodeEvalQuestionId]: CodingQuestionState
  }
  | null
  codingPlaygrounds: {
    data: CodingPlaygroundsState | null
    loading: boolean
    error: false | Error | Response
  }
  codingPlaygroundsDataById:
  | {
    [key in CodingPlaygroundId]: CodingPlaygroundData
  }
  | null
  createCodingPlayground: {
    loading: boolean
    error: false | Error | Response
    data: CreatePlaygroundResponse | null
  } | null
}

const initialState: CodingPlatformState = {
  runtimes: {
    data: {},
    loading: false,
    error: false,
  },
  codingQuestions: null,
  codingPlaygrounds: {
    data: null,
    loading: false,
    error: false,
  },
  codingPlaygroundsDataById: null,
  createCodingPlayground: null,
}

const codingPlatformReducer: Reducer<
  CodingPlatformState,
  CodingPlatformActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case RUNTIMES_FETCH:
      return {
        ...state,
        runtimes: {
          ...state.runtimes,
          loading: true,
          error: false,
        },
      }
    case RUNTIMES_FETCH_SUCCESS:
      return {
        ...state,
        runtimes: {
          data: action.payload,
          loading: false,
          error: false,
        },
      }
    case RUNTIMES_FETCH_FAILURE:
      return {
        ...state,
        runtimes: {
          ...state.runtimes,
          loading: false,
          error: action.payload,
        },
      }
    case CODE_EXECUTE: {
      const runCodeState: CodingPlaygroundData['runCode'] = {
        data: null,
        loading: true,
        error: false,
      }
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),
            runCode: runCodeState,
          },
        },
      }
    }

    case CODE_EXECUTE_SUCCESS: {
      const runCodeState: CodingPlaygroundData['runCode'] = {
        data: action.payload,
        loading: false,
        error: false,
      }
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),
            runCode: runCodeState,
          },
        },
      }
    }

    case CODE_EXECUTE_FAILURE: {
      const runCodeState: CodingPlaygroundData['runCode'] = {
        data: null,
        loading: false,
        error: action.payload,
      }
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),
            runCode: runCodeState,
          },
        },
      }
    }

    case QUESTION_RUN_CODE:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            runCode: {
              data: null,
              loading: true,
              error: false,
            },
          },
        },
      }

    case QUESTION_RUN_CODE_SUCCESS:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            runCode: {
              data: action.payload,
              loading: false,
              error: false,
            },
          },
        },
      }

    case QUESTION_RUN_CODE_FAILURE:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            runCode: {
              data: null,
              loading: false,
              error: action.payload,
            },
          },
        },
      }

    case QUESTION_TEST_CODE:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            testCode: {
              data: null,
              loading: true,
              error: false,
            },
          },
        },
      }

    case QUESTION_TEST_CODE_SUCCESS:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            testCode: {
              data: action.payload,
              loading: false,
              error: false,
            },
          },
        },
      }

    case QUESTION_TEST_CODE_FAILURE:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            testCode: {
              data: null,
              loading: false,
              error: action.payload,
            },
          },
        },
      }

    case QUESTION_TEST_SQL_CODE:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            testSqlCode: {
              data: null,
              loading: true,
              error: false,
            },
          },
        },
        QUESTION_TEST_SQL_CODE: action.payload,
      }

    case SQL_QUESTION_GET_AI_CODE_SUGGESTIONS:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            aiCodeSuggestions: {
              data: null,
              loading: true,
              error: false,
            },
          },
        },
        QUESTION_TEST_SQL_CODE: action.payload,
      }

    case QUESTION_TEST_SQL_CODE_SUCCESS:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            testSqlCode: {
              data: action.payload,
              loading: false,
              error: false,
            },
          },
        },
      }

    case SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            aiCodeSuggestions: {
              data: action.payload,
              loading: false,
              error: false,
            },
          },
        },
      }

    case QUESTION_TEST_SQL_CODE_FAILURE:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            testSqlCode: {
              data: null,
              loading: false,
              error: action.payload,
            },
          },
        },
      }

    case SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_FAILURE:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            aiCodeSuggestions: {
              data: null,
              loading: false,
              error: action.payload,
            },
          },
        },
      }

    case QUESTION_RUN_SQL_CODE:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            runCodeSql: {
              data: null,
              loading: true,
              error: false,
            },
          },
        },
      }

    case QUESTION_RUN_SQL_CODE_SUCCESS:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            runCodeSql: {
              data: action.payload,
              loading: false,
              error: false,
            },
          },
        },
      }

    case QUESTION_RUN_SQL_CODE_FAILURE:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            runCodeSql: {
              data: null,
              loading: false,
              error: action.payload,
            },
          },
        },
      }

    case QUESTION_GET_AI_CODE_SUGGESTIONS:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            aiCodeSuggestions: {
              data: null,
              loading: true,
              error: false,
            },
          },
        },
      }

    case QUESTION_GET_AI_CODE_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            aiCodeSuggestions: {
              data: action.payload,
              loading: false,
              error: false,
            },
          },
        },
      }

    case QUESTION_GET_AI_CODE_SUGGESTIONS_FAILURE:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            aiCodeSuggestions: {
              data: null,
              loading: false,
              error: action.payload,
            },
          },
        },
      }

    case QUESTION_GET_AI_CODE_HINTS:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : {}),
            aiCodeHints: {
              data: null,
              loading: true,
              error: false,
            },
          },
        },
      }

    case QUESTION_GET_AI_CODE_HINTS_SUCCESS:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : {}),
            aiCodeHints: {
              data: action.payload,
              loading: false,
              error: false,
            },
          },
        },
      }

    case QUESTION_GET_AI_CODE_HINTS_FAILURE:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : {}),
            aiCodeHints: {
              data: null,
              loading: false,
              error: action.payload,
            },
          },
        },
      }

    case UPDATE_QUESTION_STDIN:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            stdin: action.payload.questionStdin,
          },
        },
      }

    case UPDATE_QUESTION_ARGS:
      return {
        ...state,
        codingQuestions: {
          ...state.codingQuestions,
          [action.meta.ceQuestionId]: {
            ...(state.codingQuestions &&
              state.codingQuestions[action.meta.ceQuestionId]
              ? state.codingQuestions[action.meta.ceQuestionId]
              : null),
            args: action.payload.questionArgs,
          },
        },
      }

    case LIST_PLAYGROUNDS:
      return {
        ...state,
        codingPlaygrounds: {
          ...state.codingPlaygrounds,
          loading: true,
          error: false,
        },
      }

    case LIST_PLAYGROUNDS_SUCCESS:
      return {
        ...state,
        codingPlaygrounds: {
          ...state.codingPlaygrounds,
          data: action.payload,
          loading: false,
          error: false,
        },
      }
    case LIST_PLAYGROUNDS_FAILURE:
      return {
        ...state,
        codingPlaygrounds: {
          ...state.codingPlaygrounds,
          loading: false,
          error: action.payload,
        },
      }

    case GET_PLAYGROUND:
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),
            loading: true,
            error: false,
          },
        },
      }

    case GET_PLAYGROUND_SUCCESS:
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),
            code: action.payload,
            loading: false,
            error: false,
            uploadedToS3: true,
            renamed: undefined,
          },
        },
      }

    case GET_PLAYGROUND_FAILURE:
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),
            loading: false,
            error: action.payload,
          },
        },
      }

    case CREATE_PLAYGROUND:
      return {
        ...state,
        createCodingPlayground: {
          data: null,
          loading: true,
          error: false,
        },
      }

    case CREATE_PLAYGROUND_SUCCESS:
      return {
        ...state,
        createCodingPlayground: {
          data: action.payload,
          loading: false,
          error: false,
        },
      }
    case CREATE_PLAYGROUND_FAILURE:
      return {
        ...state,
        createCodingPlayground: {
          data: null,
          loading: false,
          error: action.payload,
        },
      }

    case RENAME_PLAYGROUND:
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),
            renamed: 'updating',
          },
        },
      }

    case RENAME_PLAYGROUND_SUCCESS:
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),
            renamed: 'updated',
          },
        },
      }
    case RENAME_PLAYGROUND_FAILURE:
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),
            renamed: action.payload,
          },
        },
      }

    case UPDATE_PLAYGROUND:
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),
            code: action.payload.data,
            loading: true,
            error: false,
            uploadedToS3: false,
          },
        },
      }
    case UPDATE_PLAYGROUND_SUCCESS:
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),
            code: action.payload.data,
            loading: false,
            error: false,
            uploadedToS3: action.meta.uploadToS3,
          },
        },
      }
    case UPDATE_PLAYGROUND_FAILURE:
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),
            loading: false,
            error: action.payload,
            uploadedToS3: action.meta.uploadToS3,
          },
        },
      }

    case UPDATE_PLAYGROUND_STDIN:
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),

            stdin: action.payload.playgroundStdin,
          },
        },
      }

    case UPDATE_PLAYGROUND_ARGS:
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),
            args: action.payload.questionArgs,
          },
        },
      }

    case DELETE_PLAYGROUND:
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),
            deleted: 'updating',
          },
        },
      }
    case DELETE_PLAYGROUND_SUCCESS:
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),
            deleted: 'updated',
          },
        },
      }
    case DELETE_PLAYGROUND_FAILURE:
      return {
        ...state,
        codingPlaygroundsDataById: {
          ...state.codingPlaygroundsDataById,
          [action.meta.playground_id]: {
            ...(state.codingPlaygroundsDataById &&
              state.codingPlaygroundsDataById[action.meta.playground_id]
              ? state.codingPlaygroundsDataById[action.meta.playground_id]
              : null),
            deleted: action.payload,
          },
        },
      }

    default:
      return state
  }
}

export default codingPlatformReducer
