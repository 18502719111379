import {
  takeLatest,
  takeLeading,
  takeEvery,
  call,
  put,
  select,
  take,
} from 'redux-saga/effects'
import * as types from './Discussion.types'
import * as actions from './Discussion.actions'
import APIs, { DiscussionEntryViewAPIData } from './Discussion.api'
import { moduleItemSelectors } from '..'
import { DiscussionModuleItemData } from '../../../../../common/types/courses/dq'
import { cancelable } from '../../../../../common/utils'
import { userDetailsSelectors } from '../../../User/UserDetailsProvider'
import { UserID } from '../../../../../common/types/user'
import { showAlertMessage } from '../../../AlertsProvider'

function* getDiscussionEntriesHandler(
  action: ReturnType<typeof actions.getDiscussionEntries>
) {
  try {
    const res: DiscussionEntryViewAPIData = yield call(
      APIs.getDiscussionEntries,
      {
        courseId: action.meta.courseId,
        discussionId: action.meta.discussionId,
      }
    )
    yield put(actions.getDiscussionEntriesSuccess(res, action.meta))
  } catch (e) {
    yield put(actions.getDiscussionEntriesFailure(e, action.meta))
  }
}

function* updateDiscussionEntryRatingHandler(
  action: ReturnType<typeof actions.updateDiscussionEntryRating>
) {
  try {
    yield call(APIs.updateDiscussionEntryRating, {
      courseId: action.meta.courseId,
      discussionId: action.meta.discussionId,
      discussionEntryId: action.meta.discussionEntryId,
      rating: action.payload,
    })
    yield put(
      actions.updateDiscussionEntryRatingSuccess(action.payload, action.meta)
    )
  } catch (e) {
    yield put(
      actions.updateDiscussionEntryRatingSuccess(
        action.payload === 1 ? 0 : 1,
        action.meta
      )
    )
  }
}

function* discussionEntryPost(
  action: ReturnType<typeof actions.postDiscussionEntry>
) {
  try {
    let data = null
    if (action.meta.parentId === 'root') {
      data = yield call(APIs.postDiscussionEntry, {
        courseId: action.meta.courseId,
        discussionId: action.meta.discussionId,
        message: action.payload.message,
        attachment: action.payload.attachment,
      })
    } else {
      data = yield call(APIs.postDiscussionEntryReply, {
        courseId: action.meta.courseId,
        discussionId: action.meta.discussionId,
        message: action.payload.message,
        attachment: action.payload.attachment,
        parentDiscussionEntryId: action.meta.parentId,
      })
    }
    const itemData: DiscussionModuleItemData = yield select(
      moduleItemSelectors.getItemData(action.meta.itemId)
    )
    const userId: UserID | null = yield select(userDetailsSelectors.getUserID())
    if (
      (itemData.itemContent &&
        itemData.itemContent.require_initial_post &&
        !itemData.itemContent.user_can_see_posts) ||
      (itemData.itemActivity &&
        itemData.itemActivity.participants &&
        userId &&
        !itemData.itemActivity.participants[userId])
    ) {
      yield put(
        actions.getDiscussionEntries(null, {
          courseId: action.meta.courseId,
          discussionId: action.meta.discussionId,
          itemId: action.meta.itemId,
        })
      )
      yield take(types.DISCUSSION_ENTRIES_FETCH_SUCCESS)
    }
    yield put(
      actions.postDiscussionEntrySuccess(data, {
        ...action.meta,
        discussionEntryId: data.id,
      })
    )
    yield put(
      showAlertMessage({
        message: 'Added comment successfully',
        variant: 'success',
      })
    )
  } catch (e) {
    yield put(actions.postDiscussionEntryFailure(e, action.meta))
    yield put(
      showAlertMessage({
        message: 'Failed to add the comment',
        variant: 'error',
      })
    )
  }
}

function* discussionEntryUpdate(
  action: ReturnType<typeof actions.updateDiscussionEntry>
) {
  try {
    const data = yield call(APIs.updateDiscussionEntry, {
      courseId: action.meta.courseId,
      discussionId: action.meta.discussionId,
      message: action.payload.message,
      discussionEntryId: action.meta.discussionEntryId,
    })
    yield put(actions.updateDiscussionEntrySuccess(data, action.meta))
    yield put(
      showAlertMessage({
        message: 'Updated comment successfully',
        variant: 'success',
      })
    )
  } catch (e) {
    yield put(actions.updateDiscussionEntryFailure(e, action.meta))
    yield put(
      showAlertMessage({
        message: 'Failed to update your comment',
        variant: 'error',
      })
    )
  }
}

function* discussionEntryMarkAsRead(
  action: ReturnType<typeof actions.markDiscussionEntryAsRead>
) {
  try {
    yield call(APIs.markEntryAsRead, {
      courseId: action.meta.courseId,
      discussionId: action.meta.discussionId,
      discussionEntryId: action.meta.discussionEntryId,
    })
    yield put(actions.markDiscussionEntryAsReadSuccess(null, action.meta))
  } catch (e) {
    yield put(actions.markDiscussionEntryAsReadFailure(e, action.meta))
  }
}

function* discussionMiddleware() {
  yield takeLatest(types.DISCUSSION_ENTRIES_FETCH, getDiscussionEntriesHandler)
  yield takeLeading(
    types.DISCUSSION_ENTRY_POST,
    cancelable(discussionEntryPost, [types.DISCUSSION_ENTRIES_FETCH_FAILURE])
  )
  yield takeLeading(types.DISCUSSION_ENTRY_UPDATE, discussionEntryUpdate)
  yield takeEvery(
    types.DISCUSSION_ENTRY_MARK_AS_READ,
    discussionEntryMarkAsRead
  )
  yield takeEvery(
    types.DISCUSSION_ENTRY_RATING_UPDATE,
    updateDiscussionEntryRatingHandler
  )
}

export default discussionMiddleware()
