import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { apiCall, cancelable, getUserDetails } from '../../../../common/utils'
import {
  fetchAttendanceContentFailure,
  fetchAttendanceContentSuccess,
} from './Attendance.actions'
import {
  FETCH_ATTENDANCE_CONTENT,
  FETCH_ATTENDANCE_CONTENT_CANCEL,
} from './Attendance.types'

export async function getAttendanceContentAPI(signal: AbortSignal) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/users/${id}/attendance_details`,
    params: { signal },
    query: { format: 'json' },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

function* fetchAttendanceContentHandler() {
  const abortController = new AbortController()
  try {
    const data = yield call(getAttendanceContentAPI, abortController.signal)
    yield put(fetchAttendanceContentSuccess(data))
  } catch (e) {
    yield put(fetchAttendanceContentFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fetchAttendanceContentMiddleware() {
  yield takeLatest(
    FETCH_ATTENDANCE_CONTENT,
    cancelable(fetchAttendanceContentHandler, FETCH_ATTENDANCE_CONTENT_CANCEL)
  )
}
export default ([] as any).concat(fetchAttendanceContentMiddleware())
