import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Button,
  Divider,
} from '@material-ui/core'
import React from 'react'
import freeCourseCertificate from 'common/images/free_course_certificate.png'
import { AllProgramData, AllProgramsData } from 'common/types/programs'
import { glaPgUrl, glaUtmParams } from 'common/utils/custom/user'
import Done from '@material-ui/icons/Done'
import styles from './CertificateProgramDialog.module.css'

interface Props {
  isIntl: boolean
  allProgramData: AllProgramsData
  onClick: (arg0: string) => void
}

const highLights = (isPaid: boolean): string[] => {
  return isPaid
    ? [
        'Global certificates',
        '50% Avg. salary hike',
        'Dedicated support',
        '1:1 Mentorship',
        'Hands-on Projects',
      ]
    : ['Earn Certificates', 'Lifetime access']
}
const getLinkUrl = (program: AllProgramData) => {
  return `${glaPgUrl(
    program.landing_page_url,
    program.program_page_url
  )}?${glaUtmParams(
    `onboarding_form_loggedin_dynamic_recommendations`,
    program.course_program_code,
    true
  )}`
}
const CertificateProgramDialog = (props: Props) => {
  const getImageUrlProgramNameAndUrl = () => {
    const certificateProgramDetails = {
      programUrl: '',
      cardImage: '',
      programName: '',
      partnerImage: '',
    }
    if (props.allProgramData && props.allProgramData.onboarding_form) {
      const programDetailId = props.allProgramData.onboarding_form.program
      if (programDetailId) {
        const programDetails = props.allProgramData.programs[programDetailId]
        let { image_url } = programDetails
        const pgUrl = getLinkUrl(programDetails)
        const programName = programDetails.program_name
        let partnerImage = ''
        const programPartnerId = programDetails.partners[0]
        if (programPartnerId) {
          const programPartnerDetails =
            props.allProgramData.program_partners[programPartnerId]
          if (programPartnerDetails.card_image) {
            image_url = programPartnerDetails.card_image
          }
          if (programPartnerDetails.partner_image) {
            partnerImage = programPartnerDetails.partner_image
          }
        }
        certificateProgramDetails.programUrl = pgUrl
        certificateProgramDetails.cardImage = image_url
        certificateProgramDetails.programName = programName
        certificateProgramDetails.partnerImage = partnerImage
      }
    }
    return certificateProgramDetails
  }
  const onButtonClick = (url: string | null = null) => {
    if (url) {
      props.onClick('Paid')
      window.open(url, '_blank')
    } else {
      props.onClick('learn-for-free')
    }
  }
  const getFreeVsPaidCard = (isPaid: boolean) => {
    if (isPaid) {
      const certificateProgramData = getImageUrlProgramNameAndUrl()
      return (
        <Box className={styles.paidSection}>
          <div className={styles.professional}>LEVEL UP</div>
          <div
            className={styles.imageWrapper}
            style={{
              backgroundImage: `url(${certificateProgramData.cardImage})`,
            }}
          >
            {certificateProgramData.partnerImage && (
              <Box className={styles.programCardPartnerImgContainer}>
                <img
                  src={certificateProgramData.partnerImage}
                  alt={certificateProgramData.programName}
                  className={styles.programCardPartnerImg}
                />
              </Box>
            )}
          </div>
          <div className={styles.paidProfessionalWrapper}>
            <Typography variant="h3" className={styles.programName}>
              {certificateProgramData.programName}
            </Typography>
            <Typography variant="h3" className={styles.salaryHike}>
              50% Avg. salary hike
            </Typography>
            {!props.isIntl ? (
              <Typography variant="h3" className={styles.startingAt}>
                Starting at
                <Typography variant="h3" className={styles.amt}>
                  ₹ 5,000/month
                </Typography>
              </Typography>
            ) : null}

            <Button
              variant="contained"
              className={styles.submitButton}
              onClick={() => onButtonClick(certificateProgramData.programUrl)}
              color="primary"
            >
              Explore Now
            </Button>
          </div>
          <Divider component="li" className={styles.divider} />
          <Box className={styles.programHighLightsWrapper}>
            {highLights(isPaid).map((object, i) => (
              <div key={i} className={styles.programHighLightsContents}>
                <Done className={styles.doneIcon} />
                <Typography variant="h3" className={styles.programHighLights}>
                  {object}
                </Typography>
              </div>
            ))}
          </Box>
        </Box>
      )
    }
    return (
      <Box className={styles.freeSection}>
        <div className={styles.free}>CURRENT</div>
        <div className={styles.professionalWrapper}>
          <img
            src={freeCourseCertificate}
            alt="Free Course Certificate"
            className={styles.certificateCardImg}
          />
          <Typography variant="h3" className={styles.programName}>
            Great Learning Academy
          </Typography>
          <Typography variant="h3" className={styles.freeHike}>
            Flexible · Online
          </Typography>
          {!props.isIntl ? (
            <Typography variant="h3" className={styles.startingAt}>
              Start for
              <Typography variant="h3" className={styles.amt}>
                Free
              </Typography>
            </Typography>
          ) : null}
          <Button
            variant="outlined"
            className={styles.submitButton}
            onClick={() => onButtonClick()}
            color="primary"
          >
            Continue for Free
          </Button>
        </div>
        <Divider component="li" className={styles.divider} />
        <Box className={styles.programHighLightsWrapper}>
          {highLights(isPaid).map((object, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className={styles.freeHighLightsContents}>
              <Done className={styles.doneIcon} />
              <Typography variant="h3" className={styles.programHighLights}>
                {object}
              </Typography>
            </div>
          ))}
        </Box>
      </Box>
    )
  }
  return (
    <Dialog open classes={{ paper: styles.dialog }}>
      <DialogContent className={styles.dialogContent}>
        <Typography variant="h3" className={styles.careerGoal}>
          Learn at top global universities
        </Typography>
        <Typography variant="h3" className={styles.certificateProgramHeadLine}>
          We highly recommend this program for you
        </Typography>

        <Box className={styles.freeVsPaidWrapper}>
          {getFreeVsPaidCard(false)}
          {getFreeVsPaidCard(true)}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
export default CertificateProgramDialog
