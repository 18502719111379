import { LinkModuleItemData } from 'common/types/courses/moduleItem'
import { Reducer } from 'redux'
import { ExternalUrlActions } from './ExternalUrl.actions'
import * as types from './ExternalUrl.types'

type ExternalUrlState = LinkModuleItemData | null

const externalUrlReducer: Reducer<ExternalUrlState, ExternalUrlActions> = (
  state = null,
  action
): ExternalUrlState => {
  if (!state) {
    return state
  }
  switch (action.type) {
    case types.UPDATE_LINK_PAGE_VIEW_SUCCESS: {
      return {
        ...state,
        viewed: true,
      }
    }
    default:
      return state
  }
}

export default externalUrlReducer
