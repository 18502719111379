import { DigitalExcelerateTestId } from 'common/types/excelerate/tests'
import { apiCall, getUserDetails, objectToFormData } from 'common/utils'
import { fetchExcelerateTestDetails } from './Tests.actions'

export async function fetchExcelerateTestDetailsAPI(
  action: ReturnType<typeof fetchExcelerateTestDetails>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${process.env.REACT_APP_LTI_API_URL}v1/digital-excelerate/skill_assessment_tests/${action.payload.testId}`,
    params: {
      signal,
    },
    query: {
      user_id: id,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function excelerateQuizTestSubmissionAPI(
  testId: DigitalExcelerateTestId,
  body: any,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${process.env.REACT_APP_LTI_API_URL}v1/digital-excelerate/skill_assessment_tests/${testId}/submit`,
    params: {
      method: 'POST',
      body: objectToFormData({ ...body, user_id: id }),
      signal,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
