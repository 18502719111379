import { AssignmentID } from '../../../../../common/types/courses/assignment'
import { CourseID } from '../../../../../common/types/courses'
import { apiCall } from '../../../../../common/utils'

export async function getAssignmentContentData({
  assignmentId,
  courseId,
}: {
  assignmentId: AssignmentID
  courseId: CourseID
}) {
  const query = {
    include: ['submission'],
  }
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/assignments/${assignmentId}`,
    query,
  })
  if (response.ok) {
    return response.json()
  }
  return null
}
