import { AcademyUserProfileData } from 'common/types/user'
import { UserDetailsState } from 'web/providers/User/UserDetailsProvider'
import {
  getUserAccessType,
  getUserProfileData,
} from 'web/providers/User/UserDetailsProvider/UserDetails.selectors'
import store from 'web/store'
import { getUserCompanyName } from 'web/providers/Dashboard/DashboardProvider/Dashboard.selectors'
import { isMobileBrowser } from '..'
import { glaCourseProgramCodeUtmMappings } from './courses'

export const isFreeUser = (userAccessType: UserDetailsState['accessType']) =>
  userAccessType === 'free'

export const isAcademyUser = (userAccessType: UserDetailsState['accessType']) =>
  userAccessType === 'free' || userAccessType === 'corporate'

export const isAcademyInternationalUser = (
  userAccessType: UserDetailsState['accessType']
) => userAccessType === 'free_international'

export const hasNoWorkEx = (): boolean => {
  const userProfileData = getUserProfileData()(store.getState())
  const userAccessType = getUserAccessType()(store.getState())

  if (userProfileData && userAccessType) {
    return !!(
      (isAcademyUser(userAccessType) ||
        isAcademyInternationalUser(userAccessType)) &&
      (userProfileData as AcademyUserProfileData).is_student
    )
  }
  return false
}

export const generateCourseUrl = (courseCode: string) => {
  const baseUrl = process.env.REACT_APP_WEBSITE_URL
  return `${baseUrl}academy/learn-for-free/courses/${courseCode}`
}

export const currencySymbol = (currencyCode: string): string => {
  switch (currencyCode.toLowerCase()) {
    case 'inr':
      return '₹'
    case 'usd':
      return '$'
    default:
      return '₹'
  }
}

export const glaSearchPageUrl = (searchKeyword: string = '', domainName: string = ''): string => {
  const companyName = getUserCompanyName()(store.getState()) || 'learn-for-free'
  let url = ''
  let params = ''
  if (searchKeyword.trim().length >= 2) {
    params = `?keyword=${encodeURIComponent(searchKeyword)}`
  }
  if (domainName) {
    params = `${params ? '&' : '?'}domain_name=${domainName}`
  }

  if (companyName === 'learn-for-free') {
    url = `${process.env.REACT_APP_WEBSITE_URL}academy/search`
  } else if (companyName === 'university') {
    url = `${process.env.REACT_APP_WEBSITE_URL}academy/university/search`
  } else {
    url = `${process.env.REACT_APP_WEBSITE_URL}fsl/${companyName}/search`
  }
  return `${url}${params}`
}

export const isFresher = (userProfileData: AcademyUserProfileData): boolean =>
  !!(
    userProfileData &&
    userProfileData.work_experience &&
    (userProfileData.work_experience === 'Fresher (Work Ex: 0-1 year)' ||
      userProfileData.work_experience === 'Fresher (< 1 year Work Ex)')
  )

export const isGraduate = (userProfileData: AcademyUserProfileData): boolean =>
  !!(
    userProfileData &&
    userProfileData.work_experience &&
    userProfileData.work_experience === 'I am a Graduate (No Work Ex)'
  )
export const isCollegeStudent = (
  userProfileData: AcademyUserProfileData
): boolean =>
  !!(
    userProfileData &&
    userProfileData.work_experience &&
    userProfileData.work_experience === 'I am currently a College Student'
  )

export const isSchoolStudent = (
  userProfileData: AcademyUserProfileData
): boolean =>
  !!(
    userProfileData &&
    userProfileData.work_experience &&
    userProfileData.work_experience === 'I am a School Student'
  )

export const isExperience1To5 = (
  userProfileData: AcademyUserProfileData
): boolean =>
  !!(
    userProfileData &&
    userProfileData.work_experience &&
    ['Work Ex: 1-2 years', 'Work Ex: 2-3 years', 'Work Ex: 3-5 years'].includes(
      userProfileData.work_experience
    )
  )

export const isExperienceMoreThan5 = (
  userProfileData: AcademyUserProfileData
): boolean =>
  !!(
    userProfileData &&
    userProfileData.work_experience &&
    [
      'Work Ex: 5-8 years',
      'Work Ex: 8-12 years',
      'Work Ex: Above 12 years',
    ].includes(userProfileData.work_experience)
  )

export const isExperienceMoreThan3Years = (
  userProfileData: AcademyUserProfileData
): boolean =>
  !!(
    userProfileData &&
    userProfileData.work_experience &&
    [
      'Work Ex: 3-5 years',
      'Work Ex: 5-8 years',
      'Work Ex: 8-12 years',
      'Work Ex: Above 12 years',
    ].includes(userProfileData.work_experience)
  )

export const isExperienceMoreThan2Years = (
  userProfileData: AcademyUserProfileData
): boolean =>
  !!(
    (userProfileData &&
      userProfileData.work_experience &&
      ['Work Ex: 2-3 years'].includes(userProfileData.work_experience)) ||
    isExperienceMoreThan3Years(userProfileData)
  )

export const isExperienceMoreThan1Years = (
  userProfileData: AcademyUserProfileData
): boolean =>
  !!(
    (userProfileData &&
      userProfileData.work_experience &&
      ['Work Ex: 1-2 years', 'Work Ex: 1-3 years'].includes(
        userProfileData.work_experience
      )) ||
    isExperienceMoreThan2Years(userProfileData)
  )

export const isCorporateUser = (
  userAccessType: UserDetailsState['accessType']
) => userAccessType === 'corporate'

const getSourceAndMedium = (userProfileData: AcademyUserProfileData) => {
  const isGraduateUser = isGraduate(userProfileData)
  const isSchoolUser = isSchoolStudent(userProfileData)

  if (isSchoolUser) {
    return { source: 'new_campaign_school', medium: 'school' }
  }

  if (isGraduateUser) {
    return { source: 'new_campaign_noworkex', medium: 'NoWorkEx' }
  }

  if (hasNoWorkEx()) {
    return { source: 'new_campaign_college', medium: 'college' }
  }

  return { source: 'new_campaign_workex', medium: 'WorkEx' }
}

export const glaUtmParams = (
  identifier = '',
  courseProgramCode = '',
  skipUtm = false
) => {
  const userProfileData = getUserProfileData()(store.getState())
  const moreThan1YearsExpUser = isExperienceMoreThan1Years(
    userProfileData as AcademyUserProfileData
  )
  const { source, medium } = getSourceAndMedium(
    userProfileData as AcademyUserProfileData
  )
  const deviceType = isMobileBrowser() ? 'olympus_mobile' : 'olympus_desktop'
  const campaign = identifier
    ? `utm_campaign=${deviceType}_${identifier}&gl_campaign=${deviceType}_${identifier}`
    : ''

  if (skipUtm) {
    let params = `gl_source=${source}`
    if (identifier) {
      params += `&gl_campaign=${deviceType}_${identifier}`
    }
    return params
  }
  if (
    moreThan1YearsExpUser &&
    courseProgramCode &&
    glaCourseProgramCodeUtmMappings[courseProgramCode]
  ) {
    const [maskedSource, maskedMedium] = glaCourseProgramCodeUtmMappings[
      courseProgramCode
    ]
    return `utm_source=${maskedSource}&utm_medium=${maskedMedium}&gl_source=${source}&${campaign}`
  }
  return `utm_source=${source}&utm_medium=${medium}&gl_source=${source}&${campaign}`
}

export const glaPgUrl = (
  landing_page_url = '',
  program_page_url = '',
  program_url = ''
) => {
  let pgUrl = ''
  if (landing_page_url) {
    pgUrl = landing_page_url.split('?')[0]
  } else if (program_page_url) {
    pgUrl = program_page_url.split('?')[0]
  } else {
    pgUrl = program_url
  }
  return pgUrl
}

export const isGlcaBatch = (programCode: string = '') =>
  programCode === 'ASSESSMENT-TEST'
