import {
  AudioEncoderConfiguration,
  AudioEncoderConfigurationPreset,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  ILocalAudioTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
  VideoEncoderConfiguration,
  VideoEncoderConfigurationPreset,
} from 'agora-rtc-sdk-ng'
import { COLOR_GRADIENT_TYPES } from 'common/utils/colorGradients'
import { remoteTrackStates } from 'web/providers/Zoomout/Internal/Internal.reducer'
import { UserID } from './user'

export interface IPeerInfo {
  uid: number
  isSelf: boolean
  name: string
  lmsUserId: UserID
  isScreen: boolean
  profilePictureUrl?: string
  role: PARTICIPANT_ROLES
  color: COLOR_GRADIENT_TYPES
}

export type DrawerContentTypes = 'chat' | 'people' | 'qa' | 'polls' | null

export type ModalContentTypes = 'micPermission' | 'cameraPermission' | null

export type ZoomoutApiStatuses = 'loading' | 'success' | 'error'

export type ZoomoutApiErrors =
  | 'authorizationError'
  | 'meetingEnded'
  | 'incorrectMeetingDetails'
  | 'meetingNotStarted'
  | 'unknownError'

// ensure that the values are same in the backend as well
export enum PARTICIPANT_ROLES {
  MENTOR = 'mentor',
  PROGRAM_MANAGER = 'pm',
  LEARNER = 'learner',
}

export enum UILayout {
  gallery,
  speaker,
}

export enum MessageCategory {
  PLAIN_TEXT_MESSAGE,
  REMOTE_OVERRIDE,
  POLL_CONTENT_MESSAGE,
  POLL_VOTE_MESSAGE,
  QA_MESSAGE,
  RAISE_HAND,
}

/**
 * receivers === undefined implies that the message is intended for everyone
 * in the channel
 */
export interface ZoomoutCoreMessage {
  sender: number
  receivers?: number[]
  category: MessageCategory
  content: any
  createdAt: Date
}

export interface ICustomStreamProperties {
  isEnabled: boolean
  latestPublishedAt?: Date
  encoderConfig:
    | AudioEncoderConfigurationPreset
    | VideoEncoderConfigurationPreset
    | AudioEncoderConfiguration
    | VideoEncoderConfiguration
    | null
}

export interface ICustomLocalCameraTrack extends ICustomStreamProperties {
  track: ICameraVideoTrack
  encoderConfig: VideoEncoderConfigurationPreset | VideoEncoderConfiguration
}

export interface ICustomLocalMicrophoneTrack extends ICustomStreamProperties {
  track: IMicrophoneAudioTrack
  encoderConfig: AudioEncoderConfigurationPreset | AudioEncoderConfiguration
}
export interface ICustomLocalScreenVideoTrack extends ICustomStreamProperties {
  track: ILocalVideoTrack
  encoderConfig: VideoEncoderConfigurationPreset | VideoEncoderConfiguration
}
export interface ICustomLocalScreenAudioTrack extends ICustomStreamProperties {
  track: ILocalAudioTrack
  encoderConfig: null
}

declare global {
  interface Window {
    zoomout?: {
      mainRtcClient: IAgoraRTCClient
      rtmClient: RtmClient
      rtmChannel: RtmChannel
      screenRtcClient?: IAgoraRTCClient
    }
  }
}

export type PeerDetails = {
  remotePeer?: IAgoraRTCRemoteUser
  state: {
    audio: remoteTrackStates
    video: remoteTrackStates
  }
  details: IPeerInfo
}

export interface QAData {
  id: number
  message: string
  parent_id: number | null
  created_by: number
  created_at: string
  community_id: string | null
  uid: number
  actionCount?: number
  selfAction?: boolean
  answerIds?: number[]
}

export type ZoomoutUIEvents =
  | 'MUTE_MIC_BROADCAST'
  | 'MUTE_MIC'
  | 'UNMUTE_MIC'
  | 'MUTE_CAMERA'
  | 'UNMUTE_CAMERA'
  | 'MUTE_PRESENTATION'
  | 'UNMUTE_PRESENTATION'
  | 'SESSION_JOIN'
  | 'SESSION_PING'
  | 'SESSION_LEAVE'
  | 'SESSION_END'
  | 'RECORDING_START'
  | 'RECORDING_END'
  | 'VIEW_IS_GALLERY'
  | 'VIEW_IS_SPEAKER'
  | 'RAISE_HAND'
  | 'LOWER_HAND'

export type ZoomoutPollWorkflowState = 'released' | 'closed' | 'deleted'
export interface IZoomoutPollOption {
  id: number
  title: string
  selectedBy: number[] // lms user ids of the user who select this option
}

export interface IZoomoutPollQuestion {
  id: number
  title: string
  options: IZoomoutPollOption[]
}

export interface IZoomoutPoll {
  id: number
  title?: string
  workflow_state: ZoomoutPollWorkflowState
  questions: IZoomoutPollQuestion[]
  created_by: UserID // lms user id
  created_at: Date
  updated_at: Date
}
