import { Reducer } from 'redux'
import { PageInfo } from '../../../common/types/pages'
import { PageDataActionTypes } from './PageData.actions'
import * as types from './PageData.types'

export type PageDataState = Readonly<PageInfo> | null

export const pageDataReducer: Reducer<PageDataState, PageDataActionTypes> = (
  state = null,
  action
) => {
  switch (action.type) {
    case types.UPDATE_PAGE_DATA:
      return action.meta.clearBeforeUpdate
        ? { ...action.payload }
        : { ...state, ...action.payload }

    case types.CLEAR_PAGE_DATA:
      return null

    default:
      return state
  }
}

export default pageDataReducer
