import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { DashboardState } from 'web/providers/Dashboard/DashboardProvider'
import GlaSearchComponent from 'web/components/GlaSearch'
import { UserDetailsState } from 'web/providers/User/UserDetailsProvider'
import { AppState } from 'web/store'
import { CoursesState } from 'web/providers/Courses/CoursesProvider'
import AsyncDOM from 'web/components/Utils/AsyncDOM'
import {
  isAcademyUser,
  isAcademyInternationalUser,
} from 'common/utils/custom/user'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import routes from 'web/routes'
import { CareerPathsState } from 'web/providers/Dashboard/CareerPathProvider'

interface GlaSearchProps extends RouteComponentProps {
  courses: CoursesState
  careerPathsData: CareerPathsState['data']
  userDetails: UserDetailsState
  dashboard: DashboardState
}

const GlaSearch = (props: GlaSearchProps) => {
  const [showSearch, setShowSearch] = useState(false)
  const internationalUser = isAcademyInternationalUser(
    props.userDetails.accessType
  )
  useEffect(() => {
    const isDashboard = [
      routes.ROOT,
      routes.DASHBOARD,
      routes.LEARNER_DASHBOARD,
    ].includes(props.location.pathname)

    if (
      isDashboard &&
      props.userDetails.accessType &&
      (isAcademyUser(props.userDetails.accessType) || internationalUser)
    ) {
      setShowSearch(true)
    } else {
      setShowSearch(false)
    }
  }, [props.location.pathname, props.userDetails.accessType])

  if (showSearch) {
    return (
      <AsyncDOM
        data={props.dashboard.data && props.courses.data.hasAllCourses}
        loading={!!(props.courses.loading || props.dashboard.loading)}
        error={!!(props.courses.error || props.dashboard.error)}
      >
        <AsyncDOM.Content>
          {props.dashboard.data && props.courses.data ? (
            <GlaSearchComponent
              coursesData={props.courses.data}
              careerPathsData={props.careerPathsData}
              internationalUser={internationalUser}
            />
          ) : null}
        </AsyncDOM.Content>
      </AsyncDOM>
    )
  }

  return null
}

const mapStateToProps = (state: AppState) => ({
  courses: state.courses,
  careerPathsData: state.careerPaths.data,
  userDetails: state.user.details,
  dashboard: state.dashboard,
})

export default withRouter(connect(mapStateToProps)(GlaSearch))
