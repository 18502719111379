import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import { ProgramsBanners } from 'common/types/programs'
import * as APIs from './ProgramBanners.api'
import * as types from './ProgramBanners.types'
import {
  fetchProgramsBanners,
  fetchProgramsBannersSuccess,
  fetchProgramsBannersFailure,
} from './ProgramBanners.actions'

function* fetchProgramsBannersHandler(
  action: ReturnType<typeof fetchProgramsBanners>
) {
  const abortController = new AbortController()
  try {
    const data: ProgramsBanners = yield call(
      APIs.fetchProgramsBannersAPI,
      action,
      abortController.signal
    )
    yield put(fetchProgramsBannersSuccess(data))
  } catch (e) {
    yield put(fetchProgramsBannersFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* programBannersMiddleware() {
  yield takeLatest(
    types.FETCH_PROGRAMS_BANNERS,
    cancelable(fetchProgramsBannersHandler, types.FETCH_PROGRAMS_BANNERS_CANCEL)
  )
}
export default ([] as any).concat(programBannersMiddleware())
