/* eslint-disable no-nested-ternary */
import React from 'react'
import { Typography, useMediaQuery } from '@material-ui/core'
import StickyBanner from 'web/components/Utils/StickyBanner'
import styles from './StickyAnnouncementBanner.module.css'
import Speaker from '../../../../common/images/Speaker.png'

const StickyAnnouncementBanner: React.FC = () => {
  const isTabAndAbove = useMediaQuery('(min-width: 768px)', { noSsr: true })
  const currentDate = new Date()
  const endDate = new Date('2025-01-20')

  const showMsg = currentDate <= endDate

  return (
    <>
      {showMsg ? (
        <StickyBanner
          wrapperClass={styles.paidAnnouncementBanner}
          minHeight={isTabAndAbove ? 60 : 80}
        >
          <img src={Speaker} alt="speaker" className={styles.speaker} />
          <Typography className={styles.stickyHeaderText}>
            Important Update: The GL Coins Program ends on January 20, 2025.
            Redeem your coins in the “Rewards” section before the deadline to
            avoid losing them.
          </Typography>
        </StickyBanner>
      ) : null}
    </>
  )
}

export default StickyAnnouncementBanner
