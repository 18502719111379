import React, { useEffect, useRef } from 'react'
import cx from 'classnames'
import styles from './RawHTML.module.css'

interface Props {
  className?: string
  html: string
  id?: string
  sanitize_html?: boolean
}

// This will ensure if there is a non html content 
// in the <> brackets will get printed
function sanitizeHtmlContent(content: string) {
  const htmlPattern = /<[a-z][\s\S]*>\w+[a-z/]*/i;
  const isHtmlData = htmlPattern.test(content);
  return isHtmlData ? content : content.replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

const RawHTML = (props: Props) => {
  const elRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (elRef.current) {
      elRef.current.querySelectorAll('a[href]').forEach(anchorTag => {
        if (!anchorTag.getAttribute('target')) {
          anchorTag.setAttribute('target', '_blank')
        }
        if (anchorTag.getAttribute('data-api-returntype') === 'File') {
          anchorTag.setAttribute('download', 'download')
        }
      })
    }
  }, [props.html])

  const sanitizedHtml = props.sanitize_html ? sanitizeHtmlContent(props.html) : props.html;
  return (
    <div
      ref={elRef}
      className={cx(props.className, styles.content)}
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      id={props.id}
    ></div>
  )
}

export default RawHTML
