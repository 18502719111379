export const START_QUIZ_CODING_LABS = 'START_QUIZ_CODING_LABS'
export const START_QUIZ_CODING_LABS_SUCCESS = 'START_QUIZ_CODING_LABS_SUCCESS'
export const START_QUIZ_CODING_LABS_FAILURE = 'START_QUIZ_CODING_LABS_FAILURE'
export const START_QUIZ_CODING_LABS_CANCEL = 'START_QUIZ_CODING_LABS_CANCEL'

export const GET_QUIZ_CODING_LAB_STATUS = 'GET_QUIZ_CODING_LAB_STATUS'
export const GET_QUIZ_CODING_LAB_STATUS_SUCCESS =
  'GET_QUIZ_CODING_LAB_STATUS_SUCCESS'
export const GET_QUIZ_CODING_LAB_STATUS_FAILURE =
  'GET_QUIZ_CODING_LAB_STATUS_FAILURE'
export const GET_QUIZ_CODING_LAB_STATUS_CANCEL =
  'GET_QUIZ_CODING_LAB_STATUS_CANCEL'
