import { LocalFileData } from 'common/types'
import {
  FETCH_USER_PROGRAM_TESTIMONIAL,
  FETCH_USER_PROGRAM_TESTIMONIAL_SUCCESS,
  FETCH_USER_PROGRAM_TESTIMONIAL_FAILURE,
  FETCH_USER_PROGRAM_TESTIMONIAL_CANCEL,
  SUBMIT_USER_PROGRAM_TESTIMONIAL,
  SUBMIT_USER_PROGRAM_TESTIMONIAL_CANCEL,
  SUBMIT_USER_PROGRAM_TESTIMONIAL_FAILURE,
  SUBMIT_USER_PROGRAM_TESTIMONIAL_SUCCESS,
  UPDATE_USER_PROGRAM_TESTIMONIAL,
  UPDATE_USER_PROGRAM_TESTIMONIAL_CANCEL,
  UPDATE_USER_PROGRAM_TESTIMONIAL_FAILURE,
  UPDATE_USER_PROGRAM_TESTIMONIAL_SUCCESS,
  CLEAR_USER_PROGRAM_TESTIMONIAL,
  UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE,
  UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_CANCEL,
  UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_FAILURE,
  UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_SUCCESS,
} from './UserTestimonial.types'

interface SubmitTestimonialParams {
  message: string | null
  link: string | null
}
interface UpdateTestimonialParams extends SubmitTestimonialParams {
  id: number
}
interface UpdateTestimonialImageParams {
  id: number
  attachment: LocalFileData
}

export const fetchUserProgramTestimonial = () => ({
  type: FETCH_USER_PROGRAM_TESTIMONIAL as typeof FETCH_USER_PROGRAM_TESTIMONIAL,
})
export const fetchUserProgramTestimonialSuccess = (payload: any) => ({
  type: FETCH_USER_PROGRAM_TESTIMONIAL_SUCCESS as typeof FETCH_USER_PROGRAM_TESTIMONIAL_SUCCESS,
  payload,
})
export const fetchUserProgramTestimonialFailure = (payload: Error) => ({
  type: FETCH_USER_PROGRAM_TESTIMONIAL_FAILURE as typeof FETCH_USER_PROGRAM_TESTIMONIAL_FAILURE,
  payload,
})
export const fetchUserProgramTestimonialCancel = (payload: any) => ({
  type: FETCH_USER_PROGRAM_TESTIMONIAL_CANCEL as typeof FETCH_USER_PROGRAM_TESTIMONIAL_CANCEL,
  payload,
})

export const submitUserProgramTestimonial = (
  payload: SubmitTestimonialParams
) => ({
  type: SUBMIT_USER_PROGRAM_TESTIMONIAL as typeof SUBMIT_USER_PROGRAM_TESTIMONIAL,
  payload,
})
export const submitUserProgramTestimonialSuccess = (payload: any) => ({
  type: SUBMIT_USER_PROGRAM_TESTIMONIAL_SUCCESS as typeof SUBMIT_USER_PROGRAM_TESTIMONIAL_SUCCESS,
  payload,
})
export const submitUserProgramTestimonialFailure = (payload: Error) => ({
  type: SUBMIT_USER_PROGRAM_TESTIMONIAL_FAILURE as typeof SUBMIT_USER_PROGRAM_TESTIMONIAL_FAILURE,
  payload,
})
export const submitUserProgramTestimonialCancel = (payload: any) => ({
  type: SUBMIT_USER_PROGRAM_TESTIMONIAL_CANCEL as typeof SUBMIT_USER_PROGRAM_TESTIMONIAL_CANCEL,
  payload,
})

export const updateUserProgramTestimonial = (
  payload: UpdateTestimonialParams
) => ({
  type: UPDATE_USER_PROGRAM_TESTIMONIAL as typeof UPDATE_USER_PROGRAM_TESTIMONIAL,
  payload,
})
export const updateUserProgramTestimonialSuccess = (payload: any) => ({
  type: UPDATE_USER_PROGRAM_TESTIMONIAL_SUCCESS as typeof UPDATE_USER_PROGRAM_TESTIMONIAL_SUCCESS,
  payload,
})
export const updateUserProgramTestimonialFailure = (payload: Error) => ({
  type: UPDATE_USER_PROGRAM_TESTIMONIAL_FAILURE as typeof UPDATE_USER_PROGRAM_TESTIMONIAL_FAILURE,
  payload,
})
export const updateUserProgramTestimonialCancel = (payload: any) => ({
  type: UPDATE_USER_PROGRAM_TESTIMONIAL_CANCEL as typeof UPDATE_USER_PROGRAM_TESTIMONIAL_CANCEL,
  payload,
})
export const clearUserProgramTestimonial = () => ({
  type: CLEAR_USER_PROGRAM_TESTIMONIAL as typeof CLEAR_USER_PROGRAM_TESTIMONIAL,
})

export const updateUserProgramTestimonialImage = (
  payload: UpdateTestimonialImageParams
) => ({
  type: UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE as typeof UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE,
  payload,
})
export const updateUserProgramTestimonialImageSuccess = (payload: any) => ({
  type: UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_SUCCESS as typeof UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_SUCCESS,
  payload,
})
export const updateUserProgramTestimonialImageFailure = (payload: Error) => ({
  type: UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_FAILURE as typeof UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_FAILURE,
  payload,
})
export const updateUserProgramTestimonialImageCancel = (payload: any) => ({
  type: UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_CANCEL as typeof UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_CANCEL,
  payload,
})

export type UserTestimonialActions =
  | ReturnType<typeof fetchUserProgramTestimonial>
  | ReturnType<typeof fetchUserProgramTestimonialSuccess>
  | ReturnType<typeof fetchUserProgramTestimonialFailure>
  | ReturnType<typeof fetchUserProgramTestimonialCancel>
  | ReturnType<typeof submitUserProgramTestimonial>
  | ReturnType<typeof submitUserProgramTestimonialSuccess>
  | ReturnType<typeof submitUserProgramTestimonialFailure>
  | ReturnType<typeof submitUserProgramTestimonialCancel>
  | ReturnType<typeof updateUserProgramTestimonial>
  | ReturnType<typeof updateUserProgramTestimonialSuccess>
  | ReturnType<typeof updateUserProgramTestimonialFailure>
  | ReturnType<typeof updateUserProgramTestimonialCancel>
  | ReturnType<typeof clearUserProgramTestimonial>
  | ReturnType<typeof updateUserProgramTestimonialImage>
  | ReturnType<typeof updateUserProgramTestimonialImageSuccess>
  | ReturnType<typeof updateUserProgramTestimonialImageFailure>
  | ReturnType<typeof updateUserProgramTestimonialImageCancel>
