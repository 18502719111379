import { apiCall } from '..'

export const SERVICE_TASK_STATES: { [key: string]: number } = {
  PROVISIONING_STARTED: 0,
  START_ENQUEUED: 1,
  START_FAILED: 2,
  RUNNING: 4,
  STOP_ENQUEUED: 5,
  STOP_FAILED: 6,
  STOPPED: 9,
}

export const CLAS_WORKFLOW_STATES: { [key: string]: number } = {
  lab_initiated: 0, // Lab is initiated for the user

  seeding_initiation_failed: 110, // Seeding lambda creation or invocation failed (place holder for future)
  seeding_initiated: 111, // Seeding lambda successfully invoked
  seeding_failed: 120, // Seeding lambda failed (place holder for future)
  seeding_completed: 121, // Seeding lambda successfully completed

  service_setup_initiated: 211, // Service launch is initiated (place holder for future)

  task_registration_failed: 220, // AWS ECS task definition registration failed (place holder for future)
  task_registered: 221, // AWS ECS task definition is registered
  service_creation_failed: 230, // AWS ECS service creation failed (place holder for future)
  service_created: 231, // AWS ECS service is created

  lab_creation_failed: 240, // State to track lab creation failure (place holder for future)
  lab_created: 241, // Once both task registration and service creation is completed, lab is created

  evaluation_pending: 311, // Submission is received and evaluation is pending

  evaluation_service_started: 321, // (old - evaluation_started) Cron has picked up the submission and updated the service to bring up task for evaluation

  evaluation_queued: 331, // Cron has picked up the submission after the service is up and running and enqueued the delayed job for evaluation
  evaluating: 341, // Delayed job is picked up and evaluation is in progress
  evaluation_failed: 350, // Evaluation failed, the intermediate state to track the failure of evaluation is tracked in latest_evaluation_status
  evaluation_completed: 351, // Evaluation is completed

  lms_submission_created: 361, // LMS submission is created for the user
  lms_report_uploaded: 371, // LMS report is uploaded as submission comment for the user

  deletion_failed: 990, // Lab deletion failed (place holder for future)
  deleted: 991, // Lab is deleted
}

export const CODING_LAB_PENDING_STATES = [
  CLAS_WORKFLOW_STATES.evaluation_service_started,
  CLAS_WORKFLOW_STATES.evaluation_pending,
  CLAS_WORKFLOW_STATES.evaluation_queued,
  CLAS_WORKFLOW_STATES.evaluating,
]

export const CODING_LAB_TYPES = {
  GRADED: 1,
  PREP: 2,
  PLAYGROUND: 3,
  TEMPLATE: 4
}
export const checkVsCodeStatus = async (codingLabUrl: string) => {
  const abortController = new AbortController()
  const response = await apiCall({
    url: codingLabUrl,
    params: {
      signal: abortController.signal,
    },
    excludeProgramId: true,
  })
  return response.ok
}

export const getCodingLabURL = (
  coding_lab_url: string | null,
  github_repo: string | null,
  branch_name: string | null
) =>
  github_repo && branch_name
    ? `${coding_lab_url}/second_parent?folder=/home/workspace/${github_repo}-${branch_name}`
    : `${coding_lab_url}}/second_parent?folder=/home/workspace/sample-stack-repo-main`
