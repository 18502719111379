import rootStore from 'web/store'
import { ZOOMOUT_API_ENDPOINT } from 'web/providers/Zoomout/External/Zoomout.api'
import { apiCall } from 'common/utils'
import { mixpanel } from 'common/utils/mixpanel'

const EVENT_API_ENDPOINT = `${ZOOMOUT_API_ENDPOINT}/events`

type PushEventParams = {
  byUID?: number
  forUID?: number
  eventID: number
  extraInfo?: any
}

const pushZoomoutEvent = async ({
  byUID,
  forUID,
  eventID,
  extraInfo,
}: PushEventParams) => {
  // don't make the api call if the time < softStartTime
  const { softStartTime = new Date() } =
    rootStore.getState().zoomout.external.timingDetails || {}
  if (new Date() < softStartTime) return

  const selfUID = (
    (rootStore.getState().zoomout.external.joiningDetails || {}).main || {}
  ).uid

  const { channel } = rootStore.getState().zoomout.external.joiningDetails || {}

  const lmsUserId = rootStore.getState().user.details.id

  const payload = {
    eventID: eventID || -1,

    byUID: byUID || selfUID,
    forUID: forUID || selfUID,

    sessionIdentifier: rootStore.getState().zoomout.external.sessionId,
    timestamp: new Date().toISOString(),
    user_id: lmsUserId,
    channel,
    mgsID: rootStore.getState().zoomout.external.mentoredGroupSessionId,
    role: (rootStore.getState().zoomout.external.joiningDetails || {}).role,
    extraInfo,
  }

  let response
  try {
    // if (process.env.NODE_ENV !== 'development') {
    response = await apiCall({
      url: EVENT_API_ENDPOINT,
      params: {
        method: 'POST',
        body: JSON.stringify(payload),
      },
      query: {
        user_id: lmsUserId,
      },
    })
    // }
  } catch (error) {
    mixpanel.track('Zoomout Errors', {
      feature: 'Zoomout Events API',
      code: error.code,
      message: error.message,
    })
  }

  return response
}

export default pushZoomoutEvent
