export const GET_CODING_LAB_STATUS = 'GET_CODING_LAB_STATUS'
export const GET_CODING_LAB_STATUS_SUCCESS = 'GET_CODING_LAB_STATUS_SUCCESS'
export const GET_CODING_LAB_STATUS_FAILURE = 'GET_CODING_LAB_STATUS_FAILURE'
export const GET_CODING_LAB_STATUS_CANCEL = 'GET_CODING_LAB_STATUS_CANCEL'

export const START_CODING_LAB = 'START_CODING_LAB'
export const START_CODING_LAB_SUCCESS = 'START_CODING_LAB_SUCCESS'
export const START_CODING_LAB_FAILURE = 'START_CODING_LAB_FAILURE'
export const START_CODING_LAB_CANCEL = 'START_CODING_LAB_CANCEL'

export const STOP_CODING_LAB = 'STOP_CODING_LAB'
export const STOP_CODING_LAB_SUCCESS = 'STOP_CODING_LAB_SUCCESS'
export const STOP_CODING_LAB_FAILURE = 'STOP_CODING_LAB_FAILURE'
export const STOP_CODING_LAB_CANCEL = 'STOP_CODING_LAB_CANCEL'

export const GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA =
  'GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA'
export const GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_SUCCESS =
  'GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_SUCCESS'
export const GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_FAILURE =
  'GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_FAILURE'
export const GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_CANCEL =
  'GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_CANCEL'

export const SUBMIT_CODING_LAB_ASSIGNMENT = 'SUBMIT_CODING_LAB_ASSIGNMENT'
export const SUBMIT_CODING_LAB_ASSIGNMENT_SUCCESS =
  'SUBMIT_CODING_LAB_ASSIGNMENT_SUCCESS'
export const SUBMIT_CODING_LAB_ASSIGNMENT_FAILURE =
  'SUBMIT_CODING_LAB_ASSIGNMENT_FAILURE'
export const SUBMIT_CODING_LAB_ASSIGNMENT_CANCEL =
  'SUBMIT_CODING_LAB_ASSIGNMENT_CANCEL'

export const SEND_CODING_LAB_PROCTOR_EVENT = 'SEND_CODING_LAB_PROCTOR_EVENT'
export const SEND_CODING_LAB_PROCTOR_EVENT_SUCCESS =
  'SEND_CODING_LAB_PROCTOR_EVENT_SUCCESS'
export const SEND_CODING_LAB_PROCTOR_EVENT_FAILURE =
  'SEND_CODING_LAB_PROCTOR_EVENT_FAILURE'
export const SEND_CODING_LAB_PROCTOR_EVENT_CANCEL =
  'SEND_CODING_LAB_PROCTOR_EVENT_CANCEL'
