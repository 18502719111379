import React from 'react'
import styled from 'styled-components'
import { Button as MUIButton } from '@material-ui/core'

export const Button = styled(MUIButton)(() => ({
  borderRadius: '8px',
  padding: '6px 15px 6px 15px',
  '& .MuiButton-startIcon': {
    width: '20px',
    height: '20px',
    padding: '2px',
    marginLeft: '0px',
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
  '& .MuiButton-label': {
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.4px',
    fontSize: '14px',
    fontFamily: 'Inter',
    textTransform: 'capitalize',
  },
  '&:hover': {
    backgroundColor: '#0054d614',
  },
  '&:active': {
    backgroundColor: '#0054d629',
  },
  '&.selected .MuiButton-label': {
    color: 'rgb(0, 84, 214)',
  },
}))
