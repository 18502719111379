import { Reducer } from 'redux'
import { ExternalLabsActionTypes } from './ExternalLabs.action'
import * as types from './ExternalLabs.types'

export interface ExternalLabsState {
  data: {
    externalLabsResponse: {
      subscription_id?: string
    }
    launchExternalLabsResponse: {
      lab_launch_url?: string
      lab_available?: boolean
      lab_launch_ongoing?: boolean
      subscription_id?: string
    }
    pingDataResponse: {
      remainingWeeklySecs?: number
      remainingOverallSecs?: number
      lab_stopped?: boolean
    }
    stopLabResponse: {
      success?: boolean
    }
    is_valid_lab: {
      valid_lab_code: boolean
      existing_lab_url: string
      subscription_id: string
      status_available: boolean
      weekly_remaining: number
      overall_remaining: number
    }
  }
  loading: boolean
  error: false | Error | Response
}

const initialStateModules: ExternalLabsState = {
  data: {
    externalLabsResponse: {
      subscription_id: '',
    },
    launchExternalLabsResponse: {
      lab_launch_url: '',
      lab_available: false,
      lab_launch_ongoing: false,
      subscription_id: '',
    },
    pingDataResponse: {
      remainingWeeklySecs: 0,
      remainingOverallSecs: 0,
      lab_stopped: false,
    },
    stopLabResponse: {
      success: false,
    },
    is_valid_lab: {
      valid_lab_code: false,
      existing_lab_url: '',
      subscription_id: '',
      status_available: false,
      weekly_remaining: 1,
      overall_remaining: 1,
    },
  },
  loading: false,
  error: false,
}

export const externalLabsReducer: Reducer<
  ExternalLabsState,
  ExternalLabsActionTypes
> = (state = initialStateModules, action): ExternalLabsState => {
  switch (action.type) {
    case types.START_EXTERNAL_LABS:
    case types.STOP_EXTERNAL_LABS:
    case types.PING_EXTERNAL_LABS_STATUS:
    case types.LAUNCH_EXTERNAL_LABS:
    case types.VALID_EXTERNAL_LABS:
      return {
        ...state,
        loading: true,
      }

    case types.START_EXTERNAL_LABS_FAILURE:
    case types.STOP_EXTERNAL_LABS_FAILURE:
    case types.PING_EXTERNAL_LABS_STATUS_FAILURE:
    case types.LAUNCH_EXTERNAL_LABS_FAILURE:
    case types.VALID_EXTERNAL_LABS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case types.START_EXTERNAL_LABS_CANCEL:
    case types.STOP_EXTERNAL_LABS_CANCEL:
    case types.PING_EXTERNAL_LABS_STATUS_CANCEL:
    case types.LAUNCH_EXTERNAL_LABS_CANCEL:
    case types.VALID_EXTERNAL_LABS_CANCEL:
      return {
        ...state,
        loading: false,
      }

    case types.START_EXTERNAL_LABS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          externalLabsResponse: action.payload,
          stopLabResponse: {
            success: false,
          },
        },
        loading: false,
        error: false,
      }
    }

    case types.STOP_EXTERNAL_LABS_SUCCESS: {
      return {
        ...state,
        data: {
          externalLabsResponse: {
            subscription_id: '',
          },
          launchExternalLabsResponse: {
            lab_launch_url: '',
            lab_available: false,
            lab_launch_ongoing: false,
            subscription_id: '',
          },
          pingDataResponse: {
            remainingWeeklySecs: 0,
            remainingOverallSecs: 0,
            lab_stopped: false,
          },
          stopLabResponse: {
            success: true,
          },
          is_valid_lab: {
            valid_lab_code: true,
            existing_lab_url: '',
            subscription_id: '',
            status_available: true,
            weekly_remaining: 1,
            overall_remaining: 1,
          },
        },
        loading: false,
        error: false,
      }
    }

    case types.PING_EXTERNAL_LABS_STATUS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          pingDataResponse: action.payload,
        },
        loading: false,
        error: false,
      }
    }

    case types.LAUNCH_EXTERNAL_LABS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          launchExternalLabsResponse: action.payload,
        },
        loading: false,
        error: false,
      }
    }

    case types.VALID_EXTERNAL_LABS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          is_valid_lab: action.payload,
        },
        loading: false,
        error: false,
      }
    }

    default:
      return state
  }
}
