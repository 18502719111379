import { useState, useEffect, useCallback, useRef } from 'react'
import { mixpanel } from 'common/utils/mixpanel'
import { ProctoredEventType } from 'common/types/codingLabs'
import { useDispatch } from 'react-redux'
import { sendCodingLabProctorEvent } from 'web/providers/CodingLabs/CodingLabs.action'

const GRACE_PERIOD_DURATION = 20

export type EventActionKey =
  | 'focusEvent'
  | 'blurEvent'
  | 'copyEvent'
  | 'pasteEvent'
  | 'mousemove'
  | 'keydown'
  | 'wheel'
  | 'DOMMouseScroll'
  | 'mousewheel'
  | 'mousedown'
  | 'touchstart'
  | 'touchmove'
  | 'MSPointerDown'
  | 'MSPointerMove'
  | 'visibilitychange'
  | 'contextmenu'
  | 'iframeLoaded'
  | 'cellExecutionStarted'
  | 'cellExecutionFinished'
  | 'glPing'

export const useIframeFocus = (
  lab_id: number,
  iframeName: string,
  trackCopy: boolean,
  trackPaste: boolean,
  trackLabSwitch: boolean,
  triggerIllegalAttempt:
    | ((type: string, isFocused: boolean) => void)
    | undefined,
  isPopupActive: boolean,
  isFullScreen: boolean
) => {
  const dispatch = useDispatch()
  const [iframeWindowFocused, setIframeWindowFocused] = useState(false)
  const [parentWindowFocused, setParentWindowFocused] = useState(false)
  const [focusTransitionGracePeriod, setFocusTransitionGracePeriod] = useState(
    false
  )
  const [isFocused, setIsFocused] = useState(false)
  const [activityCountInIframe, setActivityCountInIframe] = useState(0)

  const iframeLoadedRef = useRef(false)
  const blurTimeoutIdRef = useRef<NodeJS.Timeout | null>(null)
  const focusTimeoutIdRef = useRef<NodeJS.Timeout | null>(null)

  const handleIframeFocus = useCallback((focused: boolean) => {
    setIframeWindowFocused(focused)
    if (focused) setFocusTransitionGracePeriod(false)
  }, [])

  const handleFocusBlurEvents = useCallback(
    (event: string) => {
      const isIframeInFocus = event === 'focusEvent'
      handleIframeFocus(isIframeInFocus)
    },
    [handleIframeFocus]
  )

  const [isCellExecuting, setIsCellExecuting] = useState(false)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null

    // This is to handle the case when the iframe is focused and the parent window is not focused
    // isCellExecuting is used to check if the cell is executing in the iframe. this is used only in Jupiter lab
    if (!focusTransitionGracePeriod && !isCellExecuting) {
      if (!parentWindowFocused && !iframeWindowFocused) {
        timeoutId = setTimeout(() => {
          if (!parentWindowFocused && !iframeWindowFocused) {
            setIsFocused(false)
          }
        }, GRACE_PERIOD_DURATION)
      } else {
        setIsFocused(true)
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [
    parentWindowFocused,
    iframeWindowFocused,
    focusTransitionGracePeriod,
    isCellExecuting,
  ])
  const sendEventToServer = async (
    eventName: ProctoredEventType,
    data: any,
    isLabFocused: boolean
  ) => {
    if (iframeLoadedRef.current) {
      if (['lab_switch', 'copy', 'paste'].includes(eventName)) {
        if (
          eventName === 'lab_switch' &&
          triggerIllegalAttempt &&
          (!isPopupActive || !isFullScreen)
        ) {
          triggerIllegalAttempt('lab_switch', isFocused)
        }

        dispatch(
          sendCodingLabProctorEvent({
            codingLabAssignmentId: lab_id,
            event_name: eventName,
            data,
            is_focused: isLabFocused,
          })
        )
      }

      mixpanel.track(`IframeWithParentEvent - ${iframeName}`, {
        eventName,
        data,
      })
    }
  }

  type Meta = {
    message: string
    data: string
  }

  const logAndIncrementActivityCount = useCallback(
    (actionKey: EventActionKey, meta: Meta): void => {
      setActivityCountInIframe(prevCount => prevCount + 1)
      if (['copyEvent', 'pasteEvent'].includes(actionKey)) {
        if (
          (actionKey === 'copyEvent' && trackCopy) ||
          (actionKey === 'pasteEvent' && trackPaste)
        ) {
          const modifiedActionKey = actionKey.replace('Event', '') as
            | 'copy'
            | 'paste'

          // check if modifiedActionKey === 'copy' or 'paste'
          if (modifiedActionKey === 'copy' || modifiedActionKey === 'paste') {
            sendEventToServer(
              modifiedActionKey,
              {
                timestamp: Date.now(),
                message: meta.message,
                data: meta.data,
                isFocused,
              },
              isFocused
            )
          } else {
            console.error('Invalid modifiedActionKey:', modifiedActionKey)
          }
        }
      }
    },
    [trackCopy, trackPaste, isFocused]
  )

  useEffect(() => {
    if (trackLabSwitch) {
      sendEventToServer(
        'lab_switch',
        { timestamp: Date.now(), isFocused },
        isFocused
      )
    }
  }, [isFocused, trackLabSwitch])

  useEffect(() => {
    const eventMessages = {
      focusEvent: 'Iframe is focused',
      blurEvent: 'Iframe is not focused',
      copyEvent: 'Copy attempted in the iframe',
      pasteEvent: 'Paste attempted in the iframe',
      mousemove: 'Mouse moved in the iframe',
      keydown: 'Key pressed in the iframe',
      wheel: 'Mouse wheel event in the iframe',
      DOMMouseScroll: 'Mouse scroll event in the iframe',
      mousewheel: 'Mouse wheel event in the iframe',
      mousedown: 'Mouse down event in the iframe',
      touchstart: 'Touch start event in the iframe',
      touchmove: 'Touch move event in the iframe',
      MSPointerDown: 'MS Pointer down event in the iframe',
      MSPointerMove: 'MS Pointer move event in the iframe',
      visibilitychange: 'Visibility change event in the iframe',
      contextmenu: 'Context menu event in the iframe',
      iframeLoaded: 'Iframe is fully loaded',
      // These events are specific to only Jupyter lab
      cellExecutionStarted: 'Cell execution started in the iframe',
      cellExecutionFinished: 'Cell execution finished in the iframe',
      glPing: 'Ping event from Jupiter lab',
    }

    const receiveMessage = (event: MessageEvent) => {
      const { data } = event

      if (data.source === 'code_labs_parent') {
        const actionKey = data.message as EventActionKey
        const message = eventMessages[actionKey]
        if (message) {
          logAndIncrementActivityCount(actionKey, {
            message,
            data,
          })
          if (['focusEvent', 'blurEvent'].includes(actionKey)) {
            handleFocusBlurEvents(actionKey)
          }
          if (actionKey === 'iframeLoaded' && !iframeLoadedRef.current) {
            iframeLoadedRef.current = true
          }
        }
      } else if (data.source === 'jupyter_labs_parent') {
        const actionKey = data.message as EventActionKey
        const message = eventMessages[actionKey]
        if (message) {
          if (['focusEvent', 'blurEvent'].includes(actionKey)) {
            handleFocusBlurEvents(actionKey)
          }
          if (actionKey === 'iframeLoaded' && !iframeLoadedRef.current) {
            iframeLoadedRef.current = true
          }
          if (actionKey === 'glPing') {
            // Send glPong message to child iframe
            const iframe = document.getElementById(
              'jupyter-lab-iframe'
            ) as HTMLIFrameElement
            if (iframe && iframe.contentWindow) {
              console.log('Sending glPong message to child iframe')
              iframe.contentWindow.postMessage(
                { source: 'jupyter_labs_parent', message: 'glPong' },
                '*'
              )
              console.log('glPong message sent')
            } else {
              console.error('Failed to find iframe or iframe content window')
            }
          }

          if (actionKey === 'cellExecutionStarted') {
            setIsCellExecuting(true)
          }
          if (actionKey === 'cellExecutionFinished') {
            setIsCellExecuting(false)
          }
        }
      }
    }

    window.addEventListener('message', receiveMessage, false)

    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [handleFocusBlurEvents, logAndIncrementActivityCount])

  const handleBlur = useCallback(() => {
    setParentWindowFocused(false)
    setFocusTransitionGracePeriod(true)
    if (blurTimeoutIdRef.current) {
      clearTimeout(blurTimeoutIdRef.current)
    }
    blurTimeoutIdRef.current = setTimeout(
      () => setFocusTransitionGracePeriod(false),
      GRACE_PERIOD_DURATION
    )
  }, [])

  const handleFocus = useCallback(() => {
    setParentWindowFocused(true)
    setFocusTransitionGracePeriod(true)
    if (focusTimeoutIdRef.current) {
      clearTimeout(focusTimeoutIdRef.current)
    }
    focusTimeoutIdRef.current = setTimeout(
      () => setFocusTransitionGracePeriod(false),
      GRACE_PERIOD_DURATION
    )
  }, [])

  useEffect(() => {
    return () => {
      if (blurTimeoutIdRef.current) {
        clearTimeout(blurTimeoutIdRef.current)
      }
      if (focusTimeoutIdRef.current) {
        clearTimeout(focusTimeoutIdRef.current)
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('blur', handleBlur)
    window.addEventListener('focus', handleFocus)

    return () => {
      window.removeEventListener('blur', handleBlur)
      window.removeEventListener('focus', handleFocus)
    }
  }, [handleBlur, handleFocus])

  return {
    isFocused,
    activityCountInIframe,
    iframeLoaded: iframeLoadedRef.current,
    // iframeWindowFocused,
    isCellExecuting,
  }
}

export default useIframeFocus
