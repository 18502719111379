import { Reducer } from 'redux'
import { ProgramID } from 'common/types/programs'
import { CourseID } from 'common/types/courses'
import { CurriculumAiMentorUserDataAction } from './CurriculumAiMentor.action'
import {
  GET_CURRICULUM_AI_MENTOR_USER_DATA,
  GET_CURRICULUM_AI_MENTOR_USER_DATA_FAILURE,
  GET_CURRICULUM_AI_MENTOR_USER_DATA_SUCCESS,
} from './CurriculumAiMentor.types'

export interface CurriculumAiMentorUserData {
  iframeUrl: string
}

export interface CurriculumAiMentorState {
  data: Record<
    ProgramID,
    {
      byCourseId: Record<CourseID, CurriculumAiMentorUserData>
    }
  >
  loading: boolean
  error: false | Error | Response
}

const initialState: CurriculumAiMentorState = {
  data: {},
  loading: false,
  error: false,
}

const curriculumAiMentorReducer: Reducer<
  CurriculumAiMentorState,
  CurriculumAiMentorUserDataAction
> = (state = initialState, action): CurriculumAiMentorState => {
  switch (action.type) {
    case GET_CURRICULUM_AI_MENTOR_USER_DATA: {
      return {
        ...state,
        loading: true,
      }
    }
    case GET_CURRICULUM_AI_MENTOR_USER_DATA_SUCCESS: {
      const { batchId } = action.meta
      const courseId = action.meta.courseId || 'default'
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [batchId]: {
            ...state.data[batchId],
            byCourseId: {
              ...(state.data[batchId]?.byCourseId || {}),
              [courseId]: action.payload,
            },
          },
        },
      }
    }
    case GET_CURRICULUM_AI_MENTOR_USER_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }
    default:
      return state
  }
}

export default curriculumAiMentorReducer
