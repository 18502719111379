import { fetchPrivateCertificatesContent } from '.'
import { apiCall } from '../../../common/utils'
import { UserID } from '../../../common/types/user'

export async function getPrivateCertificatesContentAPI(
  action: ReturnType<typeof fetchPrivateCertificatesContent>,
  userId: UserID,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/private_certificate`,
    params: {
      method: 'GET',
      signal,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
