/* eslint-disable react/prop-types */
import { Toolbar, Box, Typography } from '@material-ui/core'
import { AcademyUserProfileData } from 'common/types/user'
import { getUserDetails } from 'common/utils'
import { mixpanel } from 'common/utils/mixpanel'
import { isTimeBefore24hrs } from 'common/utils/time'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, matchPath, withRouter } from 'react-router-dom'
import CommonReminder from 'web/components/Header/CommonReminder'
import GlaHeader from 'web/components/Header/GlaHeader'
import OnboardingVerificationPending from 'web/components/Header/OnboardingVerificationPending'
import ScholarshipBanner from 'web/components/Header/ScholarshipBanner'
import { MouthShutReview } from 'web/components/MouthShut'
import CareerSchoolSnackBar from 'web/containers/CareerSchoolSnackBar'
import {
  SnackbarState,
  fetchSnackbarDetails,
} from 'web/providers/Dashboard/SnackbarProvider'
import { showGLLoader } from 'web/providers/Common/Common.actions'
import {
  useIsExecutingInNativeApp,
  useShowNewNavigationBars,
  useIsNewUIEnabled,
  useIsNewUIEnabledForBatch,
} from 'web/hooks'
import {
  ProgramBannersState,
  fetchProgramsBanners,
} from 'web/providers/ProgramBannersProvider'
import StickyAnnouncementBanner from 'web/components/Header/StickyAnnouncementBanner'
import { FreemiumUserDashboardData } from '../../../common/types/dashboard'

import HeaderComponent from '../../components/Header'
import EmailVerificationReminder from '../../components/Header/EmailVerificationReminder/EmailVerificationReminder'
import FreemiumPaymentReminder from '../../components/Header/FreemiumPaymentReminder'
import LinkedInReminder from '../../components/Header/LinkedInReminder'
import UpcomingPaymentReminder from '../../components/Header/UpcomingPaymentReminder'
import UpsellPaymentReminder from '../../components/Header/UpsellPaymentReminder'
import HeaderBase from '../../components/HeaderBase'
import {
  fetchNewQuestions,
  fetchNotifications,
} from '../../providers/CommunityProvider'
import {
  CoursesState,
  courseSelectors,
} from '../../providers/Courses/CoursesProvider'
import {
  DashboardState,
  dashboardSelectors,
  fetchDashboardContent,
  fetchSchoolDashboardContent,
} from '../../providers/Dashboard/DashboardProvider'
import {
  ProgramState,
  programSelectors,
  updateActiveProgram,
} from '../../providers/Dashboard/ProgramsProvider'
import { UserAdmissionState } from '../../providers/User/UserAdmissionProvider'
import {
  UserDetailsState,
  fetchUserProfileDetails,
  resendEmailLink,
  userDetailsSelectors,
} from '../../providers/User/UserDetailsProvider'
import {
  UserOnboardingState,
  updateUserOnboardingPage,
} from '../../providers/User/UserOnboardingProvider'
import routes from '../../routes'
import { AppState } from '../../store'
import styles from './Header.module.css'
import ProfileMenu from './ProfileMenu'
import { HomeAppBar } from './home-app-bar'

import { ProgramID, ProgramData } from '../../../common/types/programs'
import ConfirmationDialog from '../../components/Utils/ConfirmationDialog'
import { ProctoringState } from '../../providers/ProctoringProvider'
import MobileAppBanner from '../../components/Dashboard/MobileAppBanner'
import {
  hasNoWorkEx,
  isAcademyInternationalUser,
  isAcademyUser,
  isFreeUser,
  isGraduate,
} from '../../../common/utils/custom/user'
import { UserID } from '../../../common/types/user'
import SnackbarStrip from 'web/components/Header/SnackbarStrip'
import { AnnouncementIcon } from 'web/components/Utils/Icons/Icons'
import NewUIMsgSnackbar from './new-ui-msg-snackbar'
import AiTeacherSnackbar from 'web/components/Header/AiTeacherSnackbar'

interface Props extends RouteComponentProps {
  fetchDashboardContent: typeof fetchDashboardContent
  fetchSnackbarDetails: typeof fetchSnackbarDetails
  updateUserOnboardingPage: typeof updateUserOnboardingPage
  resendEmailLink: typeof resendEmailLink
  updateActiveProgram: typeof updateActiveProgram
  fetchNotifications: typeof fetchNotifications
  fetchSchoolDashboardContent: typeof fetchSchoolDashboardContent
  fetchNewQuestions: typeof fetchNewQuestions
  fetchProgramsBanners: typeof fetchProgramsBanners
  dashboard: DashboardState
  snackbarDetails: SnackbarState
  admission: UserAdmissionState
  profileDetails: UserDetailsState['profile']
  userDetails: UserDetailsState
  courses: CoursesState['data']['byId']
  onboarding: UserOnboardingState
  programs: ProgramState
  proctoring: ProctoringState
  enrolledCourses: CoursesState['data']['activeIds']
  activeProgramData: ProgramData | null
  communityNotifications: number
  communityNewQuestions: number
  exceleratePageActive: boolean
  currentUserId: UserID | null
  showUpsellCard: boolean
  programsBanners: ProgramBannersState
}

const Header = React.memo((props: Props) => {
  if (
    props.userDetails.appSource === 'mobile_app' ||
    props.proctoring.data.enabled ||
    props.location.pathname.includes(routes.CODING_PLAYGROUND)
  ) {
    return null
  }

  const showNewNavigationBars = useShowNewNavigationBars()
  const [showPaymentReminder, setShowPaymentReminder] = useState(false)
  const [showLinkedInReminder, setShowLinkedInReminder] = useState(false)
  const [
    showVerificationPendingReminder,
    setShowVerificationPendingReminder,
  ] = useState(false)

  const [showConfirmation, updateShowConfirmation] = useState(false)
  const [switchProgramId, updateSwitchProgramId] = useState<null | ProgramID>(
    null
  )
  const [switchProgramName, updateSwitchProgramName] = useState<null | string>(
    null
  )
  const [showScholarshipBanner, updateShowScholarshipBanner] = useState(false)

  const [isStudyAbroadLinkEnabled, updateStudyAbroadLink] = useState(false)

  useEffect(() => {
    if (
      !props.dashboard.loading &&
      !props.dashboard.data &&
      !props.dashboard.error
    ) {
      if (
        props.activeProgramData &&
        props.activeProgramData.program_type === 'career_track'
      ) {
        props.fetchSchoolDashboardContent()
      } else {
        props.fetchDashboardContent()
      }
    }

    if (
      props.dashboard.data &&
      !props.snackbarDetails.data &&
      !props.snackbarDetails.loading
    ) {
      props.fetchSnackbarDetails()
    }
  }, [props.dashboard])

  useEffect(() => {
    if (
      isFreeUser(props.userDetails.accessType) &&
      !(props.programsBanners.loaded || props.programsBanners.loading)
    ) {
      props.fetchProgramsBanners()
    }
  }, [props.userDetails.accessType])

  useEffect(() => {
    const linkedInReminder = localStorage.getItem('linkedInReminder')
    if (!linkedInReminder || linkedInReminder < new Date().toDateString())
      setShowLinkedInReminder(true)

    const verificationPendingReminder = localStorage.getItem(
      'verificationPendingReminder'
    )
    if (
      props.onboarding.data.content &&
      props.onboarding.data.content.identity_verification_pending &&
      (!verificationPendingReminder ||
        verificationPendingReminder < new Date().toDateString())
    ) {
      setShowVerificationPendingReminder(true)
    }
  }, [props.onboarding.data])

  useEffect(() => {
    const paymentReminder = localStorage.getItem(
      `paymentReminder-${props.programs.data.activeProgramID}`
    )

    if (!paymentReminder || paymentReminder < new Date().toDateString())
      setShowPaymentReminder(true)
  }, [props.admission.data])

  useEffect(() => {
    if (
      props.userDetails.accessType &&
      props.userDetails.profile &&
      props.userDetails.profile.data &&
      props.programsBanners.loaded
    ) {
      if (
        isFreeUser(props.userDetails.accessType) &&
        (props.userDetails.profile.data as AcademyUserProfileData)
          .work_experience &&
        (!hasNoWorkEx() ||
          isGraduate(props.userDetails.profile.data as AcademyUserProfileData))
      ) {
        let bannerCookie = localStorage.getItem('ScholarshipBanner')
        if (bannerCookie && isTimeBefore24hrs(parseInt(bannerCookie, 10))) {
          localStorage.removeItem('ScholarshipBanner')
          bannerCookie = null
        }
        if (
          matchPath(props.location.pathname, {
            path: routes.COMMUNITY_ACCEPT_INVITE,
          }) ||
          matchPath(props.location.pathname, {
            path: routes.COMMUNITY_ACCEPT_REFERRAL,
          })
        ) {
          updateShowScholarshipBanner(false)
        } else {
          updateShowScholarshipBanner(!bannerCookie)
        }
      }

      // if (isFreeUser(props.userDetails.accessType) && !hasNoWorkEx()) {
      // updateStudyAbroadLink(true)
      // }
    } else {
      updateShowScholarshipBanner(false)
      // updateStudyAbroadLink(false)
    }
  }, [
    props.userDetails.accessType,
    props.userDetails.profile,
    props.programsBanners,
  ])

  useEffect(() => {
    if (
      !props.programs.loading &&
      !props.programs.error &&
      props.admission.data &&
      !props.admission.data.access_blocked &&
      props.programs.data.isActiveCommunityBatch
    ) {
      props.fetchNotifications()
      props.fetchNewQuestions()
    }
  }, [props.programs.data])

  const unreadAnnouncements: number = Math.max(
    Object.values(props.courses).reduce((total, currentCourse) => {
      const unreadAnnouncementsForCourse =
        currentCourse && 'unread_announcement_count' in currentCourse
          ? currentCourse.unread_announcement_count
          : 0
      return total + (unreadAnnouncementsForCourse || 0)
    }, 0),
    0
  )

  const upcomingPaymentSnackbar = () => {
    if (
      props.userDetails.accessType === 'basic' &&
      props.admission.data &&
      !props.admission.data.access_blocked &&
      props.dashboard.data &&
      props.admission.data.payment_status &&
      props.admission.data.payment_status[props.dashboard.data.batch.id] &&
      props.admission.data.payment_status[props.dashboard.data.batch.id]
        .upcoming_installments.length &&
      props.activeProgramData &&
      props.activeProgramData.program_type !== 'career_track' &&
      showPaymentReminder
    )
      return (
        <UpcomingPaymentReminder
          programAdmissionData={
            props.admission.data.payment_status[props.dashboard.data.batch.id]
          }
          activeProgramId={props.programs.data.activeProgramID}
        />
      )
    return null
  }

  const commonSnackbar = () => {
    if (
      props.userDetails.accessType === 'basic' &&
      props.snackbarDetails.data &&
      props.snackbarDetails.data.length
    ) {
      return (
        <>
          {props.snackbarDetails.data.map(snackbarData => {
            if (!snackbarData.key || !localStorage.getItem(snackbarData.key))
              return <CommonReminder snackbarData={snackbarData} />
            return null
          })}
        </>
      )
    }
    return null
  }

  const upsellPaymentSnackbar = (pathName: string) => {
    if (
      pathName === routes.DASHBOARD &&
      props.admission.data &&
      props.userDetails.profile &&
      props.userDetails.profile.data &&
      props.admission.data &&
      props.admission.data.upsell_payment_info
    ) {
      const timezone = props.userDetails.profile.data.time_zone
      return (
        <UpsellPaymentReminder
          upsell_payment_info={props.admission.data.upsell_payment_info}
          activeProgramId={props.programs.data.activeProgramID}
          timezone={timezone}
        ></UpsellPaymentReminder>
      )
    }
    return null
  }

  const aiTeacherSnackbar = () => {
    if (
      props.dashboard.data?.ai_teacher_item?.enabled &&
      props.dashboard.data?.ai_teacher_item?.course_id &&
      props.dashboard.data?.ai_teacher_item?.module_item
    )
      return (
        <AiTeacherSnackbar
          aiTeacherItem={props.dashboard.data.ai_teacher_item}
        />
      )
    return null
  }
  const emailVerificationSnackbar = () => {
    if (
      props.userDetails.accessType &&
      props.userDetails.accessType !== 'freemium' &&
      props.profileDetails &&
      props.profileDetails.data &&
      props.profileDetails.data.emailVerified &&
      props.profileDetails.data.emailVerified !== 'active' &&
      props.activeProgramData &&
      !props.activeProgramData.disable_modify_contact_details
    )
      return (
        <EmailVerificationReminder
          resendEmailLink={props.resendEmailLink}
          profileDetails={props.profileDetails}
        />
      )
    return null
  }
  const linkedInSnackbar = () => {
    if (
      props.userDetails.accessType === 'basic' &&
      props.onboarding.data &&
      props.onboarding.data.content &&
      !props.onboarding.data.content.linkedin_auth_opted_out &&
      !(
        props.onboarding.data.content.linkedin_auth_completed &&
        props.onboarding.data.content.linkedin_public_profile_url
      ) &&
      showLinkedInReminder
    )
      return (
        <LinkedInReminder
          updateUserOnboardingPage={props.updateUserOnboardingPage}
        />
      )
    return null
  }

  const skippedPaths = (path: string) =>
    matchPath(path, { path: routes.DASHBOARD, exact: true }) ||
    matchPath(path, { path: routes.PROFILE_SETTINGS, exact: true })

  const freemiumPaymentReminderSnackbar = (path: string) => {
    if (props.userDetails.accessType === 'freemium') {
      const dashboardData: FreemiumUserDashboardData | undefined = props
        .dashboard.data as FreemiumUserDashboardData
      if (
        dashboardData &&
        !dashboardData.program_fee_paid &&
        !skippedPaths(path)
      ) {
        return <FreemiumPaymentReminder dashboardData={dashboardData} />
      }
    }
    return null
  }
  const mouthShut = (pathName: string) => {
    if (
      props.userDetails.accessType === 'basic' &&
      props.programs.data.askMouthShutReview
    ) {
      if (pathName === routes.DASHBOARD || pathName === routes.COURSES)
        return (
          <MouthShutReview
            isInternationalUser={
              props.activeProgramData
                ? props.activeProgramData.is_international
                : false
            }
            askForMouthShutReview={
              props.programs.data.askMouthShutReview
                ? props.programs.data.askMouthShutReview
                : false
            }
          />
        )
    }
    return null
  }

  const newUIEnabledForBatch = useIsNewUIEnabledForBatch()
  const newUIUpcomingSnackbar = (pathName: string) => {
    if (
      !newUIEnabledForBatch &&
      props.activeProgramData &&
      props.activeProgramData.access_type === 'basic' &&
      !props.activeProgramData.digital_campus_program &&
      pathName === routes.DASHBOARD
    ) {
      const skippedTime = localStorage.getItem('newUiMsgSkipped')
      if (skippedTime && Date.now() - Number(skippedTime) < 43200000) {
        return null
      }
      return <NewUIMsgSnackbar />
    }
    return null
  }

  const isNewUIEnabled = useIsNewUIEnabled()
  const snackBars = (pathName: string) => {
    if (
      !isNewUIEnabled &&
      props.admission.data &&
      !props.admission.data.access_blocked &&
      pathName !== routes.ONBOARDING
    )
      return (
        <Fragment>
          {upcomingPaymentSnackbar()}
          {!props.showUpsellCard && commonSnackbar()}
          {upsellPaymentSnackbar(pathName)}
          {aiTeacherSnackbar()}
          {emailVerificationSnackbar()}
          {linkedInSnackbar()}
          {mouthShut(pathName)}
          {freemiumPaymentReminderSnackbar(pathName)}
          {showVerificationPendingReminder && <OnboardingVerificationPending />}
          {newUIUpcomingSnackbar(pathName)}
        </Fragment>
      )

    return null
  }

  const onProgramChange = (programId: ProgramID) => {
    updateShowConfirmation(true)

    const selectedBatch = props.programs.data.byId[programId]
    updateSwitchProgramName(
      // eslint-disable-next-line no-nested-ternary
      selectedBatch
        ? selectedBatch.career_track_id > 0
          ? 'Great Learning CareerSchool'
          : `${selectedBatch.group} program`
        : ''
    )
    updateSwitchProgramId(programId)

    const { id } = getUserDetails()
    mixpanel.track('Switch_Program_click', {
      userId: id,
    })
  }

  const onConfirmation = () => {
    if (switchProgramId) {
      props.updateActiveProgram({
        programId: switchProgramId,
        redirect: true,
      })
    }
    updateShowConfirmation(false)
  }

  const hideHeader = props.location.pathname === routes.ENROLLMENT_FORM
  return (
    <HeaderBase
      className={`${styles.container} ${hideHeader ? styles.hideHeader : null}`}
    >
      {props.activeProgramData &&
      props.activeProgramData.program_type === 'career_track' ? (
        <CareerSchoolSnackBar isTrackPage />
      ) : null}

      {/* {isFreeUser(props.userDetails.accessType) &&
      !showScholarshipBanner &&
      !props.programsBanners.loading ? (
        <div className={styles.mobileAppBanner}>
          <MobileAppBanner variant="sticky" />
        </div>
      ) : null}
      {showScholarshipBanner ? (
        <ScholarshipBanner
          programsBanners={props.programsBanners.data}
          userDetails={props.userDetails}
        />
      ) : null} */}

      {(isFreeUser(props.userDetails.accessType) ||
        isAcademyInternationalUser(props.userDetails.accessType)) &&
      props.location.pathname === routes.REFERRALS_AND_REWARDS ? (
        <StickyAnnouncementBanner />
      ) : null}

      {showNewNavigationBars ? (
        <HomeAppBar {...props} />
      ) : (
        <Toolbar className={styles.toolbar} data-testid="header-toolbar">
          {isAcademyUser(props.userDetails.accessType) ||
          isAcademyInternationalUser(props.userDetails.accessType) ? (
            <GlaHeader
              dashboardData={props.dashboard.data}
              userAccessType={props.userDetails.accessType}
              userProfileDetails={props.userDetails.profile}
              programs={props.programs}
              isStudyAbroadLinkEnabled={isStudyAbroadLinkEnabled}
              onProgramChange={onProgramChange}
            />
          ) : (
            <HeaderComponent
              dashboardData={props.dashboard.data}
              unreadAnnouncements={unreadAnnouncements}
              admissionData={props.admission.data}
              userAccessType={props.userDetails.accessType}
              profileDetails={props.profileDetails}
              programs={props.programs}
              onProgramChange={onProgramChange}
              enrolledCourses={props.enrolledCourses}
              coursesData={props.courses}
              communityNotifications={props.communityNotifications}
              communityNewQuestions={props.communityNewQuestions}
              isActiveCommunityBatch={
                props.programs.data.isActiveCommunityBatch || false
              }
              isNewProgramSupportEnabled={
                props.programs.data.isNewProgramSupportEnabled || false
              }
              exceleratePageActive={props.exceleratePageActive}
              currentUserId={props.currentUserId}
              activeProgramData={props.activeProgramData}
            />
          )}
          <ProfileMenu />
        </Toolbar>
      )}
      {snackBars(props.location.pathname)}
      {showConfirmation ? (
        <ConfirmationDialog
          dialogContent={{
            title: 'Switch Dashboard',
            body: `Do you want to switch to ${switchProgramName}?`,
            yesOption: 'Switch Dashboard',
            noOption: 'Cancel',
          }}
          onClickNo={() => {
            updateShowConfirmation(false)
          }}
          onClickYes={onConfirmation}
        />
      ) : null}
    </HeaderBase>
  )
})

function HeaderWithNativeApp(props) {
  const useOnboarding = useSelector(state => state.user.onboarding.data)
  const isExecutingInNativeApp = useIsExecutingInNativeApp()

  const dispatch = useDispatch()
  const updateGLLoader = useCallback(data => dispatch(showGLLoader(data)), [
    dispatch,
  ])

  useEffect(() => {
    if (
      props.userDetails.appSource === 'mobile_app' ||
      isExecutingInNativeApp ||
      !useOnboarding.isOnboardingCompleted
    ) {
      updateGLLoader({ show: false })
    }
  }, [
    props.userDetails.appSource,
    isExecutingInNativeApp,
    useOnboarding.isOnboardingCompleted,
  ])

  return isExecutingInNativeApp ? null : <Header {...props} />
}

const mapStateToProps = (state: AppState) => ({
  dashboard: state.dashboard,
  snackbarDetails: state.snackbarDetails,
  admission: state.user.admission,
  profileDetails: state.user.details.profile,
  courses: courseSelectors.getCoursesDataByID()(state),
  onboarding: state.user.onboarding,
  userDetails: state.user.details,
  programs: state.programs,
  proctoring: state.proctoring,
  enrolledCourses: state.courses.data.completedIds.concat(
    state.courses.data.activeIds,
    state.courses.data.failedIds
  ),
  activeProgramData: programSelectors.getActiveProgramDetails()(state),
  communityNotifications: state.community.data.notifications,
  communityNewQuestions: state.community.data.newQuestions,
  exceleratePageActive: state.excelerate.careerPrep.active,
  currentUserId: userDetailsSelectors.getUserID()(state),
  showUpsellCard: dashboardSelectors.showUpsellCard()(state),
  programsBanners: state.programBanners,
})

export default withRouter(
  connect(mapStateToProps, {
    fetchDashboardContent,
    fetchSnackbarDetails,
    resendEmailLink,
    updateUserOnboardingPage,
    updateActiveProgram,
    fetchNotifications,
    fetchSchoolDashboardContent,
    fetchNewQuestions,
    fetchProgramsBanners,
  })(HeaderWithNativeApp)
)
