import { fetchFeedbackContent, submitCourseFeedback } from '.'
import {
  apiCall,
  getUserDetails,
  objectToFormData,
} from '../../../common/utils'
import { UserID } from '../../../common/types/user'
import { skipSessionFeedback } from './Feedback.actions'

export const fetchFeedbackContentAPI = async (
  userId: UserID | null,
  action: ReturnType<typeof fetchFeedbackContent>,
  signal: AbortSignal
) => {
  const response = await apiCall({
    url: `${action.payload.url}`,
    params: { signal },
    query: {
      lms_user_id: userId,
      format: 'json',
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export const markMissedResidencySessionAPI = async (
  bodyObject: any,
  signal: AbortSignal
) => {
  const body = JSON.stringify(bodyObject)
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/user_not_attended_session`,
    params: { signal, method: 'POST', body },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export const submitFeedbackAPI = async (
  bodyObject: any,
  signal: AbortSignal
) => {
  const body = objectToFormData(bodyObject)
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/feedback/submit`,
    params: { signal, method: 'POST', body },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function skipSessionFeedbackAPI(
  action: ReturnType<typeof skipSessionFeedback>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()

  const mentoredSessionId = action.mentoredGroupSessionId

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/sessions/${mentoredSessionId}/skip_feedback`,
    params: { method: 'PUT' },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
