import { Box, IconButton, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import Button from '../../Utils/Button'
import { Close } from '@material-ui/icons'
import { ProgramState } from 'web/providers/Dashboard/ProgramsProvider'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import SnackbarStrip from 'web/components/Header/SnackbarStrip'
import { UpsellPaymentInfo } from 'common/types/admission'
import cx from 'classnames'
import { mixpanel } from 'common/utils/mixpanel'
import momentTimeZone from 'moment-timezone';
import styles from './UpsellPaymentReminder.module.css'
import { generateURL } from 'common/utils'
import { Link } from 'react-router-dom'

interface UpsellPaymentReminderProps {
  upsell_payment_info: UpsellPaymentInfo
  activeProgramId: ProgramState['data']['activeProgramID']
  timezone: string | null
}

const UpsellPaymentReminder = (props: UpsellPaymentReminderProps) => {
  const daysLeftForNormalMsg = 7
  const daysLeftForUrgentMsg = 1

  const [open, setOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState<string[]>([])
  const [isLastDay, setIsLastDay] = useState(false)

  const onClose = () => {
    const user_action: string = 'Deakin Scholarship Pay Now - close'
    mixpanel.track('Deakin Scholarship Pay Now', {user_action})
    
    setOpen(false)
  }

  const onPayNowClick = () => {
    const user_action: string = 'Deakin Scholarship Pay Now - click'
    mixpanel.track('Deakin Scholarship Pay Now', {user_action})

    setOpen(false)
  }

  useEffect(() => {
    setOpen(true)
      if (props.upsell_payment_info.days_left > daysLeftForNormalMsg) {
        setAlertMessage(['Pay now to avail the Deakin University scholarship of', 'This scholarship discount will expire on ', '.'])
      } else if (props.upsell_payment_info.days_left >= daysLeftForUrgentMsg) {
        setAlertMessage(['Only a few days left to grab your Deakin scholarship of', 'Pay before ', ' to retain scholarships benefits.'])
      } else {
        setAlertMessage(['Last day to secure Deakin scholarship of', 'Pay your fees now or lose your scholarship benefits starting tomorrow', '.'])
        setIsLastDay(true)
      }
  }, [])

  if (props.timezone && props.upsell_payment_info.due_at && props.upsell_payment_info.amount){
    const dueDate = momentTimeZone.utc(props.upsell_payment_info.due_at).tz(props.timezone).format('DD-MM-YYYY')

    const user_action: string = 'Deakin Scholarship Pay Now - view'
    mixpanel.track('Deakin Scholarship Pay Now', {user_action})

    return (
      <SnackbarStrip
        className={ props.upsell_payment_info.days_left <= daysLeftForNormalMsg ? styles.urgentAlert : '' }
        message={
          <Box display="flex" sx={{alignItems: 'center'}} className={ props.upsell_payment_info.days_left <= daysLeftForNormalMsg ? styles.urgentAlert : '' }>
            <ReportProblemIcon className={styles.reportProblemIcon}/>
            <Typography variant="caption">
              <>
                { alertMessage[0] }{' '}<strong>{props.upsell_payment_info.currency === 'usd' ? '$' : '₹'}{+props.upsell_payment_info.amount}</strong>{'. '}
                { alertMessage[1] }<strong>{ !isLastDay ? dueDate : '' }</strong>
                { alertMessage[2] }
              </>
            </Typography>
            <Link
              className={styles.link}
              to={generateURL('FEE_PAYMENT', null, {
                pb_id: props.upsell_payment_info.upsell_batch_id,
              })}
              style={{ marginLeft: '50px' }}
            >
              <Button
                color="primary"
                className={
                  props.upsell_payment_info.days_left <= daysLeftForNormalMsg
                    ? cx(styles.payNowBtn, styles.payNowBtnHovr)
                    : cx(styles.payNowBtn, styles.payNowBtnHovr)
                }
              >
                Pay Now
              </Button>
            </Link>
          </Box>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <Close />
          </IconButton>,
        ]}
        open={open}
      /> 
    )
  }
  return null
}

export default UpsellPaymentReminder
