export const FETCH_CAREER_SCHOOL_DETAILS = 'FETCH_CAREER_SCHOOL_DETAILS'
export const FETCH_CAREER_SCHOOL_DETAILS_SUCCESS =
  'FETCH_CAREER_SCHOOL_DETAILS_SUCCESS'
export const FETCH_CAREER_SCHOOL_DETAILS_FAILURE =
  'FETCH_CAREER_SCHOOL_DETAILS_FAILURE'
export const FETCH_CAREER_SCHOOL_DETAILS_CANCEL =
  'FETCH_CAREER_SCHOOL_DETAILS_CANCEL'
export const UPDATE_CAREER_SCHOOL_ONBOARDING = 'UPDATE_CAREER_SCHOOL_ONBOARDING'
export const UPDATE_CAREER_SCHOOL_ONBOARDING_COMPLETED =
  'UPDATE_CAREER_SCHOOL_ONBOARDING_COMPLETED'
export const UPDATE_CAREER_SCHOOL_JOB_ONBOARDED =
  'UPDATE_CAREER_SCHOOL_JOB_ONBOARDED'
export const UPDATE_CAREER_SCHOOL_USER_INFO_COLLECTED =
  'UPDATE_CAREER_SCHOOL_USER_INFO_COLLECTED'
