import { getActiveProgramId, isProduction } from '..'

export const communityGroupsProgramMapping: {
  [x in number]?: {
    id: string
    name: string
    path: string
    default: boolean
  }[]
} = isProduction()
  ? {
      9: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'artificial-intelligence',
          path: 'data',
          default: false,
        },
        {
          id: 'TgUbKvrcOpTo',
          name: 'machine-learning',
          path: 'data',
          default: true,
        },
      ],
      44: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'data-science',
          path: 'data',
          default: true,
        },
      ],
      20: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'artificial-intelligence',
          path: 'data',
          default: false,
        },
        {
          id: 'TgUbKvrcOpTo',
          name: 'machine-learning',
          path: 'data',
          default: true,
        },
      ],
      10: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'data-science',
          path: 'data',
          default: true,
        },
        // { id: '61a08a058c13d552dddc82db', name: 'data-science', default: true }, // test group in prod
      ],
      5131: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'data-science',
          path: 'data',
          default: true,
        },
      ],
      45: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'data-science',
          path: 'data',
          default: true,
        },
      ],
      46: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'artificial-intelligence',
          path: 'data',
          default: false,
        },
        {
          id: 'TgUbKvrcOpTo',
          name: 'machine-learning',
          path: 'data',
          default: true,
        },
      ],
      679: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'artificial-intelligence',
          path: 'data',
          default: false,
        },
        {
          id: 'TgUbKvrcOpTo',
          name: 'machine-learning',
          path: 'data',
          default: true,
        },
      ],
      23: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'data-science',
          path: 'data',
          default: true,
        },
      ],
      3545: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'data-science',
          path: 'data',
          default: true,
        },
      ],
      81: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'data-science',
          path: 'data',
          default: true,
        },
      ],
      4237: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'data-science',
          path: 'data',
          default: true,
        },
      ],
      4903: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'data-science',
          path: 'data',
          default: true,
        },
      ],
      8586: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'data-science',
          path: 'data',
          default: true,
        },
      ],
      8347: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'artificial-intelligence',
          path: 'data',
          default: false,
        },
        {
          id: 'TgUbKvrcOpTo',
          name: 'machine-learning',
          path: 'data',
          default: true,
        },
      ],
      8377: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'data-science',
          path: 'data',
          default: true,
        },
      ],
      8850: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'machine-learning',
          path: 'data',
          default: true,
        },
      ],
      8444: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'data-science',
          path: 'data',
          default: true,
        },
      ],
      7: [
        {
          id: 'IOmbmy6wuPvE',
          name: 'cloud-computing',
          path: 'cloud-computing-wuwgquzb',
          default: true,
        },
      ],
      2607: [
        {
          id: 'IOmbmy6wuPvE',
          name: 'cloud-computing',
          path: 'cloud-computing-wuwgquzb',
          default: true,
        },
      ],
      3828: [
        {
          id: 'hXMbpegrmYKR',
          name: 'developers-space',
          path: 'developers-space-ybkvuya1',
          default: true,
        },
      ],
      8443: [
        {
          id: 'hXMbpegrmYKR',
          name: 'developers-space',
          path: 'developers-space-ybkvuya1',
          default: true,
        },
      ],
      9128: [
        {
          id: 'hXMbpegrmYKR',
          name: 'developers-space',
          path: 'developers-space-ybkvuya1',
          default: true,
        },
      ],
      9375: [
        {
          id: 'hXMbpegrmYKR',
          name: 'developers-space',
          path: 'developers-space-ybkvuya1',
          default: true,
        },
      ],
      8166: [
        {
          id: '7RM9x4wwth4K',
          name: 'cyber-Security',
          path: 'cyber-security-apsd7jhr',
          default: true,
        },
      ],
      8184: [
        {
          id: '7RM9x4wwth4K',
          name: 'cyber-Security',
          path: 'cyber-security-apsd7jhr',
          default: true,
        },
      ],
      9994: [
        {
          id: 'TgUbKvrcOpTo',
          name: 'data-science',
          path: 'data',
          default: true,
        },
      ],
    }
  : {
      9: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'machine-learning',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      44: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'data-science',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      20: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'machine-learning',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      10: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'data-science',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      5131: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'data-science',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      45: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'data-science',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      46: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'machine-learning',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      679: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'machine-learning',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      23: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'data-science',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      3545: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'data-science',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      81: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'data-science',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      4237: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'data-science',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      4903: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'data-science',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      8347: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'machine-learning',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      8586: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'data-science',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      8377: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'data-science',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      8850: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'machine-learning',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      8444: [
        {
          id: 'Dqp6zcmVG3TI',
          name: 'data-science',
          path: 'data-ai-machine-learning-pma8ejfy',
          default: true,
        },
      ],
      7: [
        {
          id: 'rQwYCrPFXdtK',
          name: 'Cloud Computing Discussion',
          path: 'cloud-computing-discussion-aff05nf5',
          default: true,
        },
      ],
      2607: [
        {
          id: 'rQwYCrPFXdtK',
          name: 'cloud-computing-discussion-aff05nf5',
          path: 'cloud-computing-discussion-aff05nf5',
          default: true,
        },
      ],
      29: [
        {
          id: 'oI65ip569b3q',
          name: 'tech',
          path: 'coder-space-hzx7v83t',
          default: true,
        },
      ],
      6: [
        {
          id: 'rQwYCrPFXdtK',
          name: 'cloud-computing-discussion-aff05nf5',
          path: 'cloud-computing-discussion-aff05nf5',
          default: true,
        },
      ],
    }
// need to change this for production
export const communityGroupsPostTypeMapping: {
  [x in string]?: { id: string; name: string; context: string }
} = isProduction()
  ? {
      reply: {
        name: 'Comment',
        context: 'reply',
        id: '8a1M1Igwrp1pFsb',
      },
      post: {
        name: 'Discussion',
        context: 'post',
        id: 'PE6VUEjUJOL5nPq',
      },
    }
  : {
      reply: {
        name: 'Comment',
        context: 'reply',
        id: 'XZfKIsGcWf9zmLy',
      },
      post: {
        name: 'Discussion',
        context: 'post',
        id: 'pWIIrUsjX5YgNBO',
      },
    }

export const getCommunityPostTypeId = (key: string): string => {
  const mappingObj = communityGroupsPostTypeMapping[key]
  return mappingObj ? mappingObj.id : ''
}

export const communityAlumniGroupMapping: {
  id: string
  name: string
  path: string
} = isProduction()
  ? {
      id: '2lWqmYjYUlV6',
      path: 'gl-connect-shwc25bw',
      name: 'gl-connect',
    }
  : {
      id: 'LIYUuZO6h8x3',
      path: 'gl-connect-9507bq1w',
      name: 'gl-connect',
    }

export const glCommunityAlumniGroupBaseUrl = () => {
  const tribeGroupBaseUrl = `${process.env.REACT_APP_COMMUNITY_V2_URL}${communityAlumniGroupMapping.path}`
  return tribeGroupBaseUrl
}

export const alumniGroupTopics = () => {
  const topics = ['Askforhelp', 'Learningresources', 'Jobsbyalumni']
  return topics
}

export const communityAreaOfInterest: string[] = [
  'Data Science',
  'Machine Learning',
  'Artificial Intelligence',
]

export const getCommunityGroupIdByProgramId = (programId: number) => {
  const communityGroup =
    programId in communityGroupsProgramMapping
      ? communityGroupsProgramMapping[programId]
      : ''
  let communityGroupId = ''
  if (communityGroup && communityGroup.length) {
    communityGroup
      .filter(group => group.default)
      .forEach(group => {
        communityGroupId = group.id
      })
  }
  return communityGroupId
}

const getActiveCommunitySpace = () => {
  const communityMapping = communityGroupsProgramMapping[getActiveProgramId()]
  return communityMapping && communityMapping.length > 0
    ? communityMapping[0].path
    : ''
}

export const getCommunityActiveSpaceUrl = () => {
  return `${process.env.REACT_APP_COMMUNITY_V2_URL}${getActiveCommunitySpace()}`
}

export const getCommunityFullUrl = (relativeUrl: string | undefined | null) => {
  return `${process.env.REACT_APP_COMMUNITY_V2_URL}${relativeUrl?.replace(
    /\/*/,
    ''
  )}`
}

export const getCommunityMemberUrl = (memberId: string) => {
  return `${process.env.REACT_APP_COMMUNITY_V2_URL}member/${memberId}`
}

export const getCommunitySearchUrl = (searchValue: string) => {
  return `${
    process.env.REACT_APP_COMMUNITY_V2_URL
  }search?query=${searchValue.toLowerCase()}&type=post`
}

export const ALUMINI_BATCH = isProduction() ? 16147 : 2576
