import { cancelled, put, takeLatest, call } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import * as types from './PeerRatings.types'
import {
  fetchPendingPeerRatingsSuccess,
  fetchPendingPeerRatingsFailure,
  submitPeerRatingsSuccess,
  submitPeerRatingsFailure,
  submitPeerRatings,
} from './PeerRatings.actions'
import * as APIs from './PeerRatings.api'
import { showAlertMessage } from '../AlertsProvider'

function* fetchPendingPeerRatingsHandler() {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.getPendingPeerRatingsAPI,
      abortController.signal
    )
    yield put(fetchPendingPeerRatingsSuccess(data))
  } catch (e) {
    yield put(fetchPendingPeerRatingsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitPeerRatingsHandler(
  action: ReturnType<typeof submitPeerRatings>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.submitPeerRatingsAPI,
      action.payload,
      abortController.signal
    )
    yield put(submitPeerRatingsSuccess(data, action.meta))
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'Successfully submitted the peer ratings',
      })
    )
  } catch (e) {
    yield put(submitPeerRatingsFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* peerRatingsMiddleware() {
  yield takeLatest(
    types.FETCH_PENDING_PEER_RATINGS,
    cancelable(
      fetchPendingPeerRatingsHandler,
      types.FETCH_PENDING_PEER_RATINGS_CANCEL
    )
  )
  yield takeLatest(
    types.SUBMIT_PEER_RATINGS,
    cancelable(submitPeerRatingsHandler, types.SUBMIT_PEER_RATINGS_CANCEL)
  )
}
export default ([] as any).concat(peerRatingsMiddleware())
