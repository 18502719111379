import { ProgramsBanners } from 'common/types/programs'
import { Reducer } from 'redux'
import { ProgramBannersActions } from './ProgramBanners.actions'
import {
  FETCH_PROGRAMS_BANNERS,
  FETCH_PROGRAMS_BANNERS_SUCCESS,
  FETCH_PROGRAMS_BANNERS_CANCEL,
  FETCH_PROGRAMS_BANNERS_FAILURE,
} from './ProgramBanners.types'

export interface ProgramBannersState {
  data: ProgramsBanners
  loading: boolean
  loaded: boolean
  error: false | Error | Response
}

const initialState: ProgramBannersState = {
  data: {},
  loading: false,
  loaded: false,
  error: false as false,
}

export const programBannersReducer: Reducer<
  ProgramBannersState,
  ProgramBannersActions
> = (state = initialState, action: any): ProgramBannersState => {
  switch (action.type) {
    case FETCH_PROGRAMS_BANNERS:
      return { ...state, loading: true, loaded: false, error: false }

    case FETCH_PROGRAMS_BANNERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
        error: false,
      }

    case FETCH_PROGRAMS_BANNERS_FAILURE:
      return { ...state, loading: false, loaded: true, error: action.payload }

    case FETCH_PROGRAMS_BANNERS_CANCEL:
      return {
        ...state,
        loading: false,
        loaded: false,
      }
    default:
      return state
  }
}
