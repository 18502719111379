import { apiCall, getUserDetails } from '../../../../common/utils'
import { fetchTransactonStatus } from './PaymentStatus.actions'

export async function getUserPaymentStatusAPI(
  action: ReturnType<typeof fetchTransactonStatus>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/payments/transaction_details`,
    params: { signal },
    query: { user_id: id, txn_id: action.txn_id },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
