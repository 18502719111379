import React from 'react'
import cx from 'classnames'
import styles from './FooterBase.module.css'

interface FooterBase {
  className?: string
  children: React.ReactNode
}

const FooterBase = (props: FooterBase) => (
  <footer
    className={cx(styles.container, props.className)}
    data-testid="footer"
  >
    {props.children}
  </footer>
)

export default FooterBase
