import { Reducer } from 'redux'
import {
  ByLeaderboardType,
  ByLeaderboardTypeRankListData,
  PayloadData,
  PercentAddedPayloadData,
  PayloadDataV2,
} from '../../../../common/types/leaderboard'
import {
  BaseUserData,
  UserID,
  LeaderboardAnonymousUserData,
} from '../../../../common/types/user'
import { getMedian, getPercentage } from '../../../../common/utils'
import { LeaderboardActions } from './Leaderboard.actions'
import {
  FETCH_COURSE_LEADERBOARD,
  FETCH_COURSE_LEADERBOARD_FAILURE,
  FETCH_COURSE_LEADERBOARD_SUCCESS,
  FETCH_PROGRAM_LEADERBOARD,
  FETCH_PROGRAM_LEADERBOARD_FAILURE,
  FETCH_PROGRAM_LEADERBOARD_SUCCESS,
} from './Leaderboard.types'

export interface LeaderboardState {
  data: {
    byUserId: {
      [s in UserID]?: BaseUserData & LeaderboardAnonymousUserData
    }
    byLeaderboardType: {
      [s in ByLeaderboardType]?: {
        [s in string | number]?: {
          rankList: ByLeaderboardTypeRankListData[]
        }
      }
    }
  }
  byLeaderboardType: {
    [s in ByLeaderboardType]?: {
      [s in string | number]?: {
        loading: boolean
        error: false | Error | Response
      }
    }
  }
}

const initialState: LeaderboardState = {
  data: {
    byUserId: {},
    byLeaderboardType: {},
  },
  byLeaderboardType: {},
}

const normalizedData = (
  state: LeaderboardState['data'],
  type: ByLeaderboardType,
  id: string | number,
  data: PayloadData[]
): {
  byUserId: LeaderboardState['data']['byUserId']
  byLeaderboardType: LeaderboardState['data']['byLeaderboardType']
} => {
  const percentArray: PercentAddedPayloadData[] = data.map((x: PayloadData) => {
    return { ...x, percent: getPercentage(x.score, x.total_score) }
  })
  const sortedArray = percentArray.sort(
    (x: PercentAddedPayloadData, y: PercentAddedPayloadData) =>
      y.percent - x.percent
  ) // desc
  const percents = sortedArray.map((x: PercentAddedPayloadData) => x.percent)
  const uniquePercents = percents.filter(
    (x: any, i: number) => percents.indexOf(x) === i
  )
  const byUserIdData = { ...state.byUserId }
  const byTypeData = { ...state.byLeaderboardType }

  const listData: ByLeaderboardTypeRankListData[] = []

  // const highest = percents[0]
  // const median = highest ? getMedian(percents) : 0

  if (percents[0]) {
    sortedArray.forEach((x: PercentAddedPayloadData) => {
      listData.push({
        userId: x.user_id,
        rank: uniquePercents.indexOf(x.percent) + 1,
        score: x.score,
        total_score: x.total_score,
        percent: x.percent,
      })

      byUserIdData[x.user_id] = {
        id: x.user_id,
        avatar_image_url: x.avatar_image_url,
        display_name: x.name,
      }
    })
  }

  byTypeData[type] = {
    ...(byTypeData[type] ? byTypeData[type] : {}),
    [id]: {
      rankList: listData,
    },
  }

  return {
    byUserId: byUserIdData,
    byLeaderboardType: byTypeData,
  }
}

const normalizedDataV2 = (
  state: LeaderboardState['data'],
  type: ByLeaderboardType,
  id: string | number,
  data: PayloadDataV2[]
): {
  byUserId: LeaderboardState['data']['byUserId']
  byLeaderboardType: LeaderboardState['data']['byLeaderboardType']
} => {
  const byUserIdData = { ...state.byUserId }
  const byTypeData = { ...state.byLeaderboardType }

  const listData: ByLeaderboardTypeRankListData[] = []
  data.forEach((x: PayloadDataV2, index) => {
    const user_id = x.user_id || index + 1 // no user_id available for anonymous users on the leaderboard so proxying it with the index
    listData.push({
      userId: user_id,
      rank: x.rank,
      score: x.score,
      total_score: x.total_score,
      percent: x.percentage,
    })

    byUserIdData[user_id] = {
      id: user_id,
      avatar_image_url: x.avatar_image_url,
      display_name: x.name,
      leader_board_anonymity: x.leader_board_anonymity || undefined,
    }
  })

  byTypeData[type] = {
    ...(byTypeData[type] ? byTypeData[type] : {}),
    [id]: {
      rankList: listData,
    },
  }

  return {
    byUserId: byUserIdData,
    byLeaderboardType: byTypeData,
  }
}

const leaderboardReducer: Reducer<LeaderboardState, LeaderboardActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_PROGRAM_LEADERBOARD:
    case FETCH_COURSE_LEADERBOARD:
      return {
        ...state,
        byLeaderboardType: {
          ...state.byLeaderboardType,
          [action.meta.byLeaderboardType]: {
            ...state.byLeaderboardType[action.meta.byLeaderboardType],
            [action.meta.id]: {
              loading: true,
              error: false,
            },
          },
        },
      }

    case FETCH_PROGRAM_LEADERBOARD_SUCCESS: {
      const res = normalizedDataV2(
        state.data,
        action.meta.byLeaderboardType,
        action.meta.id,
        action.payload
      )
      return {
        ...state,
        data: {
          ...res,
        },
        byLeaderboardType: {
          ...state.byLeaderboardType,
          [action.meta.byLeaderboardType]: {
            ...state.byLeaderboardType[action.meta.byLeaderboardType],
            [action.meta.id]: {
              loading: false,
              error: false,
            },
          },
        },
      }
    }

    case FETCH_COURSE_LEADERBOARD_SUCCESS: {
      const res = normalizedData(
        state.data,
        action.meta.byLeaderboardType,
        action.meta.id,
        action.payload
      )
      return {
        ...state,
        data: {
          ...res,
        },
        byLeaderboardType: {
          ...state.byLeaderboardType,
          [action.meta.byLeaderboardType]: {
            ...state.byLeaderboardType[action.meta.byLeaderboardType],
            [action.meta.id]: {
              loading: false,
              error: false,
            },
          },
        },
      }
    }

    case FETCH_PROGRAM_LEADERBOARD_FAILURE:
    case FETCH_COURSE_LEADERBOARD_FAILURE:
      return {
        ...state,
        byLeaderboardType: {
          ...state.byLeaderboardType,
          [action.meta.byLeaderboardType]: {
            ...state.byLeaderboardType[action.meta.byLeaderboardType],
            [action.meta.id]: {
              loading: false,
              error: action.payload,
            },
          },
        },
      }

    default:
      return state
  }
}

export default leaderboardReducer
