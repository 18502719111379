import { Reducer, combineReducers } from 'redux'
import { FileID, FileContents } from 'common/types'
import {
  QuizSubmissionData,
  QuizAnswerData,
  FileUploadAnswer,
  DynamicQuestionsContent,
} from '../../../../../../common/types/courses/quiz'
import { QuizQAActionTypes } from './QuizQA.actions'
import * as types from './QuizQA.types'
import { convertArrayToObjectMap, pick } from '../../../../../../common/utils'

export type QuizQAState = Pick<
  QuizSubmissionData,
  'questions' | 'answers' | 'dynamicQuestions'
>

const quizQuestionsReducer: Reducer<
  QuizQAState['questions'],
  QuizQAActionTypes
> = (state = null, action): QuizQAState['questions'] => {
  switch (action.type) {
    case types.QUIZ_SUBMISSION_QA_FETCH:
      return null
    case types.QUIZ_SUBMISSION_QA_FETCH_SUCCESS:
      return convertArrayToObjectMap(action.payload, 'id')
    case types.QUIZ_SUBMISSION_QA_FETCH_FAILURE:
      return action.payload
    default:
      return state
  }
}

const quizDynamicQuestionsReducer: Reducer<
  QuizQAState['dynamicQuestions'],
  QuizQAActionTypes
> = (state = null, action): QuizQAState['dynamicQuestions'] => {
  switch (action.type) {
    case types.GET_DYNAMIC_QUESTION_ANSWERS_SUCCESS:
      return action.payload.reduce(
        (acc: DynamicQuestionsContent, data: DynamicQuestionsContent) => {
          return { ...acc, ...data }
        },
        {}
      )
    default:
      return state
  }
}

const initialState = {
  answer: null,
  flagged: false,
}

const addUrlToAttachment = (
  attachments: FileContents[],
  attachmentId: FileID,
  data: any
) => {
  attachments.forEach((attachment: FileContents) => {
    if (attachment.id === attachmentId && data) {
      // eslint-disable-next-line no-param-reassign
      attachment.url = data.url
    }
  })
  return attachments
}

const quizAnswersReducer: Reducer<QuizQAState['answers'], QuizQAActionTypes> = (
  state = null,
  action
): QuizQAState['answers'] => {
  switch (action.type) {
    case types.QUIZ_SUBMISSION_QA_FETCH:
      return null
    case types.QUIZ_SUBMISSION_QA_FETCH_SUCCESS:
      return convertArrayToObjectMap(
        action.payload.map(i =>
          'attachments' in i
            ? (pick(i, [
                'flagged',
                'id',
                'answer',
                'attachments',
              ]) as FileUploadAnswer)
            : (pick(i, ['flagged', 'id', 'answer']) as QuizAnswerData)
        ),
        'id'
      )
    case types.QUIZ_SUBMISSION_QA_FETCH_FAILURE:
      return action.payload
    case types.GET_FILE_DETAILS_SUCCESS: {
      return state instanceof Error
        ? state
        : {
            ...state,
            [action.meta.questionId]: {
              ...(state ? state[action.meta.questionId] : {}),
              ...(state &&
              state[action.meta.questionId] &&
              'attachments' in state[action.meta.questionId]! &&
              (state[action.meta.questionId] as FileUploadAnswer).attachments
                ? addUrlToAttachment(
                    (state[action.meta.questionId] as FileUploadAnswer)
                      .attachments as FileContents[],
                    action.meta.fileId,
                    action.payload
                  )
                : undefined),
            } as QuizAnswerData,
          }
    }
    case types.GET_FILE_DETAILS_FAILURE:
      return state

    case types.QUIZ_QUESTION_FLAG:
      if (state instanceof Error) return state
      return {
        ...state,
        [action.meta.questionId]: {
          ...initialState,
          ...(state ? state[action.meta.questionId] : {}),
          id: action.meta.questionId,
          flagged: true,
        },
      }
    case types.QUIZ_QUESTION_UNFLAG:
      return state instanceof Error
        ? state
        : {
            ...state,
            [action.meta.questionId]: {
              ...initialState,
              ...(state ? state[action.meta.questionId] : {}),
              id: action.meta.questionId,
              flagged: false,
            },
          }
    case types.QUIZ_SUBMISSION_ANSWERS_SAVE:
      if (state instanceof Error) {
        return state
      }
      if (action.meta.questionId !== -1) {
        return {
          ...state,
          [action.meta.questionId]: {
            ...initialState,
            ...(state ? state[action.meta.questionId] : {}),
            id: action.meta.questionId,
            answer: action.payload.answer,
            syncWithServer: false,
          } as QuizAnswerData,
        }
      }
      return state
    case types.QUIZ_SUBMISSION_ANSWERS_SAVE_SUCCESS:
      if (state instanceof Error) {
        return state
      }
      // eslint-disable-next-line no-case-declarations
      const tempState: QuizQAState['answers'] = { ...state }
      action.payload.answers.forEach((answer: QuizAnswerData) => {
        tempState[answer.id] = {
          ...initialState,
          id: answer.id,
          ...tempState[answer.id],
          syncWithServer: true,
        }
      })
      return tempState

    default:
      return state
  }
}

export default combineReducers<QuizQAState, QuizQAActionTypes>({
  answers: quizAnswersReducer,
  questions: quizQuestionsReducer,
  dynamicQuestions: quizDynamicQuestionsReducer,
})
