import { Reducer } from 'redux'
import {
  MODULES_FETCH,
  MODULES_FETCH_CANCEL,
  MODULES_FETCH_FAILURE,
  MODULES_FETCH_SUCCESS,
  UPDATE_MODULE_DATA,
} from './Modules.types'
import { ModulesActionTypes, fetchModulesSuccess } from './Modules.actions'
import { ModuleID } from '../../../../common/types'
import { ModuleData } from '../../../../common/types/courses/moduleItem'
import { CourseID } from '../../../../common/types/courses'

interface ModuleDataByID {
  byId: { [s in ModuleID]?: ModuleData }
  byCourse: {
    [c in CourseID]?: { list: ModuleID[]; completedPercent?: number }
  }
}

export interface ModulesState {
  data: ModuleDataByID
  byCourse: {
    [s in CourseID]?: {
      loading: boolean
      error: false | Error | Response
    }
  }
}

const initialStateModules: ModulesState = {
  data: { byId: {}, byCourse: {} },
  byCourse: {},
}

function getNormalizedData(
  state: ModulesState,
  action: ReturnType<typeof fetchModulesSuccess>
) {
  const courseModules = state.data.byCourse[action.meta.courseId]
  const modulesList: ModuleID[] = courseModules
    ? courseModules.list.slice()
    : []
  let completedPercent = 0
  let requiredItemsToComplete = 0
  let requiredItemsComplete = 0
  const moduleMap = action.payload.reduce(
    (acc: { [s in ModuleID]: ModuleData }, item: ModuleData) => {
      if (
        typeof item.itemsCompleted === 'number' &&
        typeof item.itemsRequiredToComplete === 'number'
      ) {
        requiredItemsToComplete += item.itemsRequiredToComplete
        requiredItemsComplete += item.itemsCompleted
      }
      modulesList.push(item.id)
      acc[item.id] = item
      return acc
    },
    {}
  )

  if (requiredItemsToComplete && requiredItemsComplete)
    completedPercent = Math.floor(
      (requiredItemsComplete / requiredItemsToComplete) * 100
    )

  const uniqueModulesList = [...new Set(modulesList)]
  return {
    data: moduleMap,
    list: uniqueModulesList,
    completedPercent,
  }
}

const modulesReducer: Reducer<ModulesState, ModulesActionTypes> = (
  state = initialStateModules,
  action
): ModulesState => {
  switch (action.type) {
    case MODULES_FETCH:
      return {
        ...state,
        byCourse: {
          ...state.byCourse,
          [action.meta.courseId]: {
            loading: true,
            error: false,
          },
        },
      }

    case MODULES_FETCH_FAILURE:
      return {
        ...state,
        byCourse: {
          ...state.byCourse,
          [action.meta.courseId]: {
            loading: false,
            error: action.payload,
          },
        },
      }
    case MODULES_FETCH_SUCCESS: {
      const res = getNormalizedData(state, action)
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            ...res.data,
          },
          byCourse: {
            ...state.data.byCourse,
            [action.meta.courseId]: {
              list: res.list,
              completedPercent: res.completedPercent,
            },
          },
        },
        byCourse: {
          ...state.byCourse,
          [action.meta.courseId]: {
            loading: false,
            error: false,
          },
        },
      }
    }
    case MODULES_FETCH_CANCEL:
      return {
        ...state,
        byCourse: {
          ...state.byCourse,
          [action.meta.courseId]: {
            error: false,
            loading: false,
          },
        },
      }

    case UPDATE_MODULE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.payload.id]: {
              ...state.data.byId[action.payload.id],
              ...action.payload,
            },
          },
        },
      }

    default:
      return state
  }
}
export default modulesReducer
