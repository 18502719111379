export const FETCH_COMPLETED_QUIZZES = 'FETCH_COMPLETED_QUIZZES'

export const FETCH_COMPLETED_QUIZZES_SUCCESS = 'FETCH_COMPLETED_QUIZZES_SUCCESS'
export const FETCH_COMPLETED_QUIZZES_FAILURE = 'FETCH_COMPLETED_QUIZZES_FAILURE'
export const FETCH_COMPLETED_QUIZZES_CANCEL = 'FETCH_COMPLETED_QUIZZES_CANCEL'

export const FETCH_COMPLETED_ASSIGNMENTS = 'FETCH_COMPLETED_ASSIGNMENTS'

export const FETCH_COMPLETED_ASSIGNMENTS_SUCCESS =
  'FETCH_COMPLETED_ASSIGNMENTS_SUCCESS'
export const FETCH_COMPLETED_ASSIGNMENTS_FAILURE =
  'FETCH_COMPLETED_ASSIGNMENTS_FAILURE'
export const FETCH_COMPLETED_ASSIGNMENTS_CANCEL =
  'FETCH_COMPLETED_ASSIGNMENTS_CANCEL'
