import { TypedUseSelectorHook } from 'react-redux'
import * as types from './Courses.types'
import { CourseID, CourseTabData } from '../../../../common/types/courses'
import { UserID } from '../../../../common/types/user'

export interface CourseDetailsFetchParams {
  courseId: CourseID
  include?: string[]
}
export interface CourseDetailsFetchMetaParams {
  courseId: CourseID
}
export interface CourseListFetchMetaParams {}
export interface SubmitCourseRatingPayload {
  courseId: CourseID
  user_id: UserID
  rating: number
  positiveFeedback: boolean
  newFeedbackOptions?: string[]
  comment: string
}
export interface SubmitCourseRatingMetaParams {
  courseId: CourseID
}

export interface courseListFilterParam {
  course_status?: String[]
  domains?: String[]
  course_types?: String[]
  popular_only?: boolean
}

export const fetchCourseList = (filters: courseListFilterParam) => ({
  type: types.COURSE_LIST_FETCH as typeof types.COURSE_LIST_FETCH,
  filters,
})

export const fetchCourseListFailure = (payload: Error) => ({
  type: types.COURSE_LIST_FETCH_FAILURE as typeof types.COURSE_LIST_FETCH_FAILURE,
  payload,
})

export const fetchCourseListSuccess = (payload: any) => ({
  type: types.COURSE_LIST_FETCH_SUCCESS as typeof types.COURSE_LIST_FETCH_SUCCESS,
  payload,
})

export const fetchCourseListCancel = (payload?: any) => ({
  type: types.COURSE_LIST_FETCH_CANCEL as typeof types.COURSE_LIST_FETCH_CANCEL,
  payload,
})

export const fetchCourseDetails = (
  payload: CourseDetailsFetchParams,
  meta: CourseDetailsFetchMetaParams
) => ({
  type: types.COURSE_DETAILS_FETCH as typeof types.COURSE_DETAILS_FETCH,
  payload,
  meta,
})

export const fetchCourseDetailsSuccess = (
  payload: any,
  meta: CourseDetailsFetchMetaParams
) => ({
  type: types.COURSE_DETAILS_FETCH_SUCCESS as typeof types.COURSE_DETAILS_FETCH_SUCCESS,
  payload,
  meta,
})
export const fetchCourseDetailsFailure = (
  payload: Error,
  meta: CourseDetailsFetchMetaParams
) => ({
  type: types.COURSE_DETAILS_FETCH_FAILURE as typeof types.COURSE_DETAILS_FETCH_FAILURE,
  payload,
  meta,
})
export const fetchCourseDetailsCancel = (
  payload: any,
  meta: CourseDetailsFetchMetaParams
) => ({
  type: types.COURSE_DETAILS_FETCH_CANCEL as typeof types.COURSE_DETAILS_FETCH_CANCEL,
  payload,
  meta,
})

interface CourseTabsFetchParams {
  courseId: CourseID
}
interface CourseTabsFetchMetaParams {
  courseId: CourseID
}

export const fetchCourseTabs = (
  payload: CourseTabsFetchParams,
  meta: CourseTabsFetchMetaParams
) => ({
  type: types.COURSE_TABS_FETCH as typeof types.COURSE_TABS_FETCH,
  payload,
  meta,
})

export const fetchCourseTabsSuccess = (
  payload: CourseTabData[],
  meta: CourseTabsFetchMetaParams
) => ({
  type: types.COURSE_TABS_FETCH_SUCCESS as typeof types.COURSE_TABS_FETCH_SUCCESS,
  payload,
  meta,
})
export const fetchCourseTabsFailure = (
  payload: Error,
  meta: CourseTabsFetchMetaParams
) => ({
  type: types.COURSE_TABS_FETCH_FAILURE as typeof types.COURSE_TABS_FETCH_FAILURE,
  payload,
  meta,
})

export const submitCourseRating = (
  payload: SubmitCourseRatingPayload,
  meta: SubmitCourseRatingMetaParams
) => ({
  type: types.COURSE_RATING_SUBMIT as typeof types.COURSE_RATING_SUBMIT,
  payload,
  meta,
})
export const submitCourseRatingSuccess = (
  payload: SubmitCourseRatingPayload,
  meta: SubmitCourseRatingMetaParams
) => ({
  type: types.COURSE_RATING_SUBMIT_SUCCESS as typeof types.COURSE_RATING_SUBMIT_SUCCESS,
  payload,
  meta,
})
export const submitCourseRatingFailure = (
  payload: Error,
  meta: SubmitCourseRatingMetaParams
) => ({
  type: types.COURSE_RATING_SUBMIT_FAILURE as typeof types.COURSE_RATING_SUBMIT_FAILURE,
  payload,
  meta,
})

export type CourseListActions =
  | ReturnType<typeof fetchCourseList>
  | ReturnType<typeof fetchCourseListFailure>
  | ReturnType<typeof fetchCourseListSuccess>
  | ReturnType<typeof fetchCourseListCancel>
  | ReturnType<typeof fetchCourseDetails>
  | ReturnType<typeof fetchCourseDetailsSuccess>
  | ReturnType<typeof fetchCourseDetailsFailure>
  | ReturnType<typeof fetchCourseDetailsCancel>
  | ReturnType<typeof fetchCourseTabs>
  | ReturnType<typeof fetchCourseTabsFailure>
  | ReturnType<typeof fetchCourseTabsSuccess>
  | ReturnType<typeof submitCourseRating>
  | ReturnType<typeof submitCourseRatingSuccess>
  | ReturnType<typeof submitCourseRatingFailure>
