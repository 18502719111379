import { takeLatest, put, call, cancelled } from 'redux-saga/effects'
import {
  FETCH_SPECIALISATION_COURSES,
  FETCH_SPECIALISATION_COURSES_CANCEL,
} from './SpecializationCourses.types'
import {
  fetchSpecializationCoursesSuccess,
  fetchSpecializationCoursesFailure,
} from './SpecializationCourses.actions'
import { cancelable, apiCall, getUserDetails } from '../../../common/utils'

export async function fetchSpecializationCoursesAPI(signal: AbortSignal) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/specialization`,
    params: {
      signal,
    },
    query: {
      user_id: id,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

function* fetchSpecializationCoursesMiddlewareHandler() {
  const abortController = new AbortController()
  try {
    const data = yield call(
      fetchSpecializationCoursesAPI,
      abortController.signal
    )
    yield put(fetchSpecializationCoursesSuccess(data))
  } catch (e) {
    yield put(fetchSpecializationCoursesFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fetchSpecializationCoursesMiddleware() {
  yield takeLatest(
    FETCH_SPECIALISATION_COURSES,
    cancelable(
      fetchSpecializationCoursesMiddlewareHandler,
      FETCH_SPECIALISATION_COURSES_CANCEL
    )
  )
}

export default ([] as any).concat(fetchSpecializationCoursesMiddleware())
