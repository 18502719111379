import { AssignmentID } from 'common/types/courses/assignment'
import { DashboardData } from 'common/types/dashboard'
import { AppState } from 'web/store'

export const careerPlusEnabled = () => (state: AppState): boolean => {
  const dashboardData: DashboardData | undefined = state.dashboard.data
  return !!(
    dashboardData &&
    dashboardData.feature_links &&
    dashboardData.feature_links.career_support &&
    dashboardData.feature_links.career_support.includes('digital-excelerate')
  )
}

export const batchStartDate = () => (state: AppState): string => {
  const dashboardData: DashboardData | undefined = state.dashboard.data
  return (
    (dashboardData && dashboardData.batch && dashboardData.batch.start_date) ||
    ''
  )
}

export const getActiveProgramBatchDetails = () => (
  state: AppState
): DashboardData['batch'] | null => {
  const batchData: DashboardData | undefined = state.dashboard.data
  if (batchData && batchData.batch) {
    return batchData.batch
  }
  return null
}

export const isExcelerateV2Enabled = () => (state: AppState): boolean => {
  const dashboardData: DashboardData | undefined = state.dashboard.data
  return !!(
    dashboardData &&
    'excelerate_v2_enabled' in dashboardData &&
    dashboardData.excelerate_v2_enabled
  )
}

export const showUpsellCard = () => (state: AppState): boolean => {
  const dashboardData: DashboardData | undefined = state.dashboard.data

  return !!(
    dashboardData &&
    'upsell_banner_enabled' in dashboardData &&
    dashboardData.upsell_banner_enabled
  )
}

export const isGiftCourseAvailable = (isGiftCourseOverride: boolean) => (
  state: AppState
): boolean => {
  const dashboardData: DashboardData | undefined = state.dashboard.data
  return (
    isGiftCourseOverride ||
    !!(
      dashboardData &&
      'gift_course_available' in dashboardData &&
      dashboardData.gift_course_available
    )
  )
}

export const isInternationalProgram = () => (state: AppState): boolean => {
  const dashboardData: DashboardData | undefined = state.dashboard.data
  return !!(
    dashboardData &&
    'is_international' in dashboardData &&
    dashboardData.is_international
  )
}

export const getUserCompanyName = () => (state: AppState): string | null =>
  (state.dashboard.data && state.dashboard.data.company_name) || null

export const getMutedResubmissionAssignments = () => (
  state: AppState
): AssignmentID[] => state.dashboard.data?.muted_resubmission_assignments ?? []
