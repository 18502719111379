import { Reducer } from 'redux'
import * as types from './Quiz.types'
import { CourseQuizzesActions } from './Quiz.actions'
import { CourseQuizState } from '../../../../common/types/programSupport'

const initialState: CourseQuizState = {
  data: [],
  loading: false,
  error: false as false,
}

export const courseQuizzesReducer: Reducer<
  CourseQuizState,
  CourseQuizzesActions
> = (state = initialState, action: any): CourseQuizState => {
  switch (action.type) {
    case types.FETCH_COURSE_QUIZZES:
      return { ...state, loading: true, error: false }
    case types.FETCH_COURSE_QUIZZES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      }
    case types.FETCH_COURSE_QUIZZES_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case types.FETCH_COURSE_QUIZZES_CANCEL:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
