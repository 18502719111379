import { Reducer } from 'redux'
import {
  ITEM_CONTENT_FETCH_SUCCESS,
  ITEM_CONTENT_FETCH_FAILURE,
  ITEM_CONTENT_FETCH,
  ASSESSMENT_PROCTOR_CONTENT_FETCH_SUCCESS,
} from './ItemContent.types'
import { ItemContentActionTypes } from './ItemContent.actions'
import { ModuleItemsState } from '../ModuleItems.reducer'
import { ModuleItemData } from '../../../../../common/types/courses/moduleItem'

const initialState: ModuleItemsState = {
  data: { byId: {}, byModule: {} },
  byModule: {},
  byItem: {},
}

const initialItemState = {}

const itemContentReducer: Reducer<ModuleItemsState, ItemContentActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ITEM_CONTENT_FETCH:
      return {
        ...state,
        byItem: {
          ...state.byItem,
          [action.meta.itemId]: {
            loading: true,
            error: false,
          },
        },
      }
    case ITEM_CONTENT_FETCH_SUCCESS: {
      const data = (state.data.byId[action.meta.itemId] ||
        initialItemState) as ModuleItemData
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.itemId]: {
              ...data,
              ...(data.type === 'File' ||
              (data.type === 'Page' && !data.contentMeta)
                ? { viewed: true }
                : {}),
              itemContent: {
                ...(state.data.byId[action.meta.itemId] || {}).itemContent,
                ...action.payload,
              },
            },
          },
        },
        byItem: {
          ...state.byItem,
          [action.meta.itemId]: {
            loading: false,
            error: false,
          },
        },
      }
    }
    case ITEM_CONTENT_FETCH_FAILURE: {
      return {
        ...state,
        byItem: {
          ...state.byItem,
          [action.meta.itemId]: {
            loading: false,
            error: action.payload,
          },
        },
      }
    }
    case ASSESSMENT_PROCTOR_CONTENT_FETCH_SUCCESS: {
      const data = (state.data.byId[action.meta.itemId] ||
        initialItemState) as ModuleItemData
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.itemId]: {
              ...data,
              itemContent: {
                ...(state.data.byId[action.meta.itemId] &&
                state.data.byId[action.meta.itemId]!.itemContent
                  ? state.data.byId[action.meta.itemId]!.itemContent
                  : {}),
                ...action.payload,
              },
            },
          },
        },
      }
    }
    default:
      return state
  }
}

export default itemContentReducer
