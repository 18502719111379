import {
  FETCH_OPENAI_USER_TOKEN,
  FETCH_OPENAI_USER_TOKEN_SUCCESS,
  FETCH_OPENAI_USER_TOKEN_FAILURE,
  FETCH_OPENAI_USER_TOKEN_CANCEL,
  CREATE_OPENAI_USER_TOKEN,
  CREATE_OPENAI_USER_TOKEN_SUCCESS,
  CREATE_OPENAI_USER_TOKEN_FAILURE,
  CREATE_OPENAI_USER_TOKEN_CANCEL,
  DELETE_OPENAI_USER_TOKEN,
  DELETE_OPENAI_USER_TOKEN_SUCCESS,
  DELETE_OPENAI_USER_TOKEN_FAILURE,
  DELETE_OPENAI_USER_TOKEN_CANCEL,
} from './OpenAiProxyProvider.types'

interface OpenAiTokenParams {}

interface OpenAiTokenMeta {}

export const fetchOpenAiUserToken = (
  payload: OpenAiTokenParams,
  meta: OpenAiTokenMeta
) => ({
  type: FETCH_OPENAI_USER_TOKEN as typeof FETCH_OPENAI_USER_TOKEN,
  payload,
  meta,
})

export const fetchOpenAiUserTokenSuccess = (
  payload: any,
  meta: OpenAiTokenMeta
) => ({
  type: FETCH_OPENAI_USER_TOKEN_SUCCESS as typeof FETCH_OPENAI_USER_TOKEN_SUCCESS,
  payload,
  meta,
})

export const fetchOpenAiUserTokenFailure = (
  payload: Error,
  meta: OpenAiTokenMeta
) => ({
  type: FETCH_OPENAI_USER_TOKEN_FAILURE as typeof FETCH_OPENAI_USER_TOKEN_FAILURE,
  payload,
  meta,
})

export const fetchOpenAiUserTokenCancel = () => ({
  type: FETCH_OPENAI_USER_TOKEN_CANCEL as typeof FETCH_OPENAI_USER_TOKEN_CANCEL,
})

export const createOpenAiUserToken = (
  payload: OpenAiTokenParams,
  meta: OpenAiTokenMeta
) => ({
  type: CREATE_OPENAI_USER_TOKEN as typeof CREATE_OPENAI_USER_TOKEN,
  payload,
  meta,
})

export const createOpenAiUserTokenSuccess = (
  payload: any,
  meta: OpenAiTokenMeta
) => ({
  type: CREATE_OPENAI_USER_TOKEN_SUCCESS as typeof CREATE_OPENAI_USER_TOKEN_SUCCESS,
  payload,
  meta,
})

export const createOpenAiUserTokenFailure = (
  payload: Error,
  meta: OpenAiTokenMeta
) => ({
  type: CREATE_OPENAI_USER_TOKEN_FAILURE as typeof CREATE_OPENAI_USER_TOKEN_FAILURE,
  payload,
  meta,
})

export const createOpenAiUserTokenCancel = () => ({
  type: CREATE_OPENAI_USER_TOKEN_CANCEL as typeof CREATE_OPENAI_USER_TOKEN_CANCEL,
})

export const deleteOpenAiUserToken = (
  payload: OpenAiTokenParams,
  meta: OpenAiTokenMeta
) => ({
  type: DELETE_OPENAI_USER_TOKEN as typeof DELETE_OPENAI_USER_TOKEN,
  payload,
  meta,
})

export const deleteOpenAiUserTokenSuccess = (
  payload: any,
  meta: OpenAiTokenMeta
) => ({
  type: DELETE_OPENAI_USER_TOKEN_SUCCESS as typeof DELETE_OPENAI_USER_TOKEN_SUCCESS,
  payload,
  meta,
})

export const deleteOpenAiUserTokenFailure = (
  payload: Error,
  meta: OpenAiTokenMeta
) => ({
  type: DELETE_OPENAI_USER_TOKEN_FAILURE as typeof DELETE_OPENAI_USER_TOKEN_FAILURE,
  payload,
  meta,
})

export const deleteOpenAiUserTokenCancel = () => ({
  type: DELETE_OPENAI_USER_TOKEN_CANCEL as typeof DELETE_OPENAI_USER_TOKEN_CANCEL,
})

export type OpenAiActions =
  | ReturnType<typeof fetchOpenAiUserToken>
  | ReturnType<typeof fetchOpenAiUserTokenSuccess>
  | ReturnType<typeof fetchOpenAiUserTokenFailure>
  | ReturnType<typeof fetchOpenAiUserTokenCancel>
  | ReturnType<typeof createOpenAiUserToken>
  | ReturnType<typeof createOpenAiUserTokenSuccess>
  | ReturnType<typeof createOpenAiUserTokenFailure>
  | ReturnType<typeof createOpenAiUserTokenCancel>
  | ReturnType<typeof deleteOpenAiUserToken>
  | ReturnType<typeof deleteOpenAiUserTokenSuccess>
  | ReturnType<typeof deleteOpenAiUserTokenFailure>
  | ReturnType<typeof deleteOpenAiUserTokenCancel>
