import { useEffect } from 'react'
import { ProgramID } from 'common/types/programs'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import {
  programSelectors,
  updateActiveProgram,
} from 'web/providers/Dashboard/ProgramsProvider'

interface Props extends RouteComponentProps {}
const ProgramSwitch = (props: Props) => {
  const dispatch = useDispatch()
  const programData = useSelector(programSelectors.getActiveProgramDetails())
  const enrolledPrograms = useSelector(programSelectors.getAllProgramIds())

  useEffect(() => {
    const routePbId: ProgramID | null = props.location.state
      ? props.location.state.pb_id
      : null
    if (
      routePbId &&
      programData &&
      routePbId !== programData.id &&
      enrolledPrograms &&
      enrolledPrograms.includes(routePbId)
    ) {
      dispatch(updateActiveProgram({ programId: routePbId }))
    }
  }, [props.location])
}
export default ProgramSwitch
