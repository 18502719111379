import { HackathonID } from 'common/types/hackathons'
import {
  apiCall,
  CustomError,
  fileExtension,
  getUserDetails,
} from '../../../common/utils'
import {
  addMembersHackathonTeam,
  fetchHackathonTeamPerformance,
  registerHackathonTeam,
  submitHackathonSolution,
} from './Hackathons.actions'

export async function getHackathonListAPI(signal: AbortSignal) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/hackathons`,
    query: { user_id: id },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getHackathonDetailsAPI(
  hackathonId: HackathonID,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/hackathons/${hackathonId}`,
    query: { user_id: id },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function fetchHackathonLeaderboardAPI(
  hackathonId: HackathonID,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/hackathons/${hackathonId}/leaderboard`,
    query: { user_id: id },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function fetchHackathonTeamPerformanceAPI(
  { payload }: ReturnType<typeof fetchHackathonTeamPerformance>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/hackathons/${payload.hackathonId}/teams/${payload.team_id}/performance`,
    query: { user_id: id },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function registerHackathonTeamAPI(
  hackathonId: HackathonID,
  { payload }: ReturnType<typeof registerHackathonTeam>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const body = JSON.stringify({
    team_name: payload.name,
    email_ids: payload.emails,
  })
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/hackathons/${hackathonId}/register_team`,
    query: { user_id: id },
    params: { method: 'POST', body, signal },
  })
  if (response.ok) {
    return response.json()
  }
  if (response.status === 400) {
    const data = await response.json()
    if ('message' in data) {
      throw new CustomError(data.message, data)
    }
  }
  throw response
}

export async function addMembersHackathonTeamAPI(
  hackathonId: HackathonID,
  { payload }: ReturnType<typeof addMembersHackathonTeam>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const body = JSON.stringify({
    email_ids: payload.emails,
  })
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/hackathons/${hackathonId}/teams/${payload.id}/add_members`,
    query: { user_id: id },
    params: { method: 'POST', body, signal },
  })
  if (response.ok) {
    return response.json()
  }
  if (response.status === 400) {
    const data = await response.json()
    if ('message' in data) {
      throw new CustomError(data.message, data)
    }
  }
  throw response
}

export async function submitHackathonSolutionAPI(
  hackathonId: HackathonID,
  { payload }: ReturnType<typeof submitHackathonSolution>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()

  const formData = new FormData()
  formData.append('submission_data', payload.files.submission_data)
  formData.append(
    'submission_data_file_extension',
    fileExtension(payload.files.submission_data.name)
  )
  if (payload.files.submission_code) {
    formData.append('submission_code', payload.files.submission_code)
    formData.append(
      'submission_code_file_extension',
      fileExtension(payload.files.submission_code.name)
    )
  }
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/hackathons/${hackathonId}/teams/${payload.team_id}/upload_hackathon_submission`,
    query: { user_id: id },
    params: { method: 'POST', body: formData, signal },
  })
  if (response.ok) {
    return response.json()
  }
  if (response.status === 400) {
    const data = await response.json()
    if ('message' in data) {
      throw new CustomError(data.message, data)
    }
  }
  throw response
}
