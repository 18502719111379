import { apiCall, getUserDetails } from '../../../../common/utils'
import {
  fetchUserPaymentHistory,
  fetchUserPaymentReceipt,
} from './PaymentHistory.actions'
import { ProgramID } from '../../../../common/types/programs'

export async function getUserPaymentHistoryAPI(
  action: ReturnType<typeof fetchUserPaymentHistory>,
  program_group_id: ProgramID,
  sibling_pb_id: number,
  signal: AbortSignal
) {
  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/payments/history`,
    params: { signal },
    query: { user_id: id, program_group_id, sibling_pb_id },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getUserPaymentReceiptAPI(
  action: ReturnType<typeof fetchUserPaymentReceipt>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/payments/receipt`,
    params: { signal },
    query: { user_id: id, txn_id: action.transaction_id },
  })
  if (response.ok) {
    const link = document.createElement('a')
    link.href = URL.createObjectURL(await response.blob())
    link.setAttribute(
      'download',
      `${action.user_name}_${action.transaction_id}.pdf`
    )
    link.setAttribute('id', 'paymentLink')
    document.body.appendChild(link)
    link.click()
    link.remove()
    return response.status
  }
  throw response
}
