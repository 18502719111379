import * as actions from './WikiPage.types'
import { ItemID } from '../../../../../common/types/courses/moduleItem'

interface WikiPageMetaParams {
  itemId: ItemID
}

export const updateVideoWatchData = (
  payload: { percentage: number; requirement_met: boolean | null },
  meta: WikiPageMetaParams
) => ({
  type: actions.UPDATE_VIDEO_WATCHED_PERCENTAGE as typeof actions.UPDATE_VIDEO_WATCHED_PERCENTAGE,
  payload,
  meta,
})

export const fetchVideoDetails = (
  payload: { url: string; courseId: number | string, is_iphone: boolean },
  meta: WikiPageMetaParams
) => ({
  type: actions.FETCH_VIDEO_DETAILS as typeof actions.FETCH_VIDEO_DETAILS,
  payload,
  meta,
})

export const fetchVideoDetailsSuccess = (
  payload: any,
  meta: WikiPageMetaParams
) => ({
  type: actions.FETCH_VIDEO_DETAILS_SUCCESS as typeof actions.FETCH_VIDEO_DETAILS_SUCCESS,
  payload,
  meta,
})
export const fetchVideoDetailsFailure = (
  payload: Error,
  meta: WikiPageMetaParams
) => ({
  type: actions.FETCH_VIDEO_DETAILS_FAILURE as typeof actions.FETCH_VIDEO_DETAILS_FAILURE,
  payload,
  meta,
})

export const fetchVideoDetailsCancel = (
  payload: any,
  meta: WikiPageMetaParams
) => ({
  type: actions.FETCH_VIDEO_DETAILS_CANCEL as typeof actions.FETCH_VIDEO_DETAILS_CANCEL,
  payload,
  meta,
})

export type WikiPageActions =
  | ReturnType<typeof updateVideoWatchData>
  | ReturnType<typeof fetchVideoDetails>
  | ReturnType<typeof fetchVideoDetailsSuccess>
  | ReturnType<typeof fetchVideoDetailsFailure>
  | ReturnType<typeof fetchVideoDetailsCancel>
