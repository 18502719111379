import {
  MentorSessionID,
  MentorshipRecordingSessionItemData,
  MentorshipRecordingSessionItemID,
} from '../../../../common/types/courses/mentorshipRecording'
import * as types from './MentorshipRecordings.types'
import { CourseID } from '../../../../common/types/courses'

interface CourseMentorshipRecordingsParams {
  courseId: CourseID
}

interface SessionMentorshipRecordingsParams {
  sessionId: MentorSessionID
}

interface MentorshipRecordingDetailParams {
  recordingId: MentorshipRecordingSessionItemID
}

export const fetchCourseMentorshipRecordings = (
  payload: CourseMentorshipRecordingsParams,
  meta: CourseMentorshipRecordingsParams
) => ({
  type: types.COURSE_MENTORSHIP_RECORDINGS_FETCH as typeof types.COURSE_MENTORSHIP_RECORDINGS_FETCH,
  payload,
  meta,
})

export const fetchCourseMentorshipRecordingsSuccess = (
  payload: MentorshipRecordingSessionItemData[],
  meta: CourseMentorshipRecordingsParams
) => ({
  type: types.COURSE_MENTORSHIP_RECORDINGS_FETCH_SUCCESS as typeof types.COURSE_MENTORSHIP_RECORDINGS_FETCH_SUCCESS,
  payload,
  meta,
})

export const fetchCourseMentorshipRecordingsFailure = (
  payload: Error,
  meta: CourseMentorshipRecordingsParams
) => ({
  type: types.COURSE_MENTORSHIP_RECORDINGS_FETCH_FAILURE as typeof types.COURSE_MENTORSHIP_RECORDINGS_FETCH_FAILURE,
  payload,
  meta,
})

export const fetchSessionMentorshipRecordings = (
  payload: SessionMentorshipRecordingsParams,
  meta: SessionMentorshipRecordingsParams
) => ({
  type: types.SESSION_MENTORSHIP_RECORDINGS_FETCH as typeof types.SESSION_MENTORSHIP_RECORDINGS_FETCH,
  payload,
  meta,
})

export const fetchSessionMentorshipRecordingsSuccess = (
  payload: MentorshipRecordingSessionItemData[],
  meta: SessionMentorshipRecordingsParams
) => ({
  type: types.SESSION_MENTORSHIP_RECORDINGS_FETCH_SUCCESS as typeof types.SESSION_MENTORSHIP_RECORDINGS_FETCH_SUCCESS,
  payload,
  meta,
})

export const fetchSessionMentorshipRecordingsFailure = (
  payload: Error,
  meta: SessionMentorshipRecordingsParams
) => ({
  type: types.SESSION_MENTORSHIP_RECORDINGS_FETCH_FAILURE as typeof types.SESSION_MENTORSHIP_RECORDINGS_FETCH_FAILURE,
  payload,
  meta,
})

export const fetchMentorshipRecordingDetail = (
  payload: MentorshipRecordingDetailParams,
  meta: MentorshipRecordingDetailParams
) => ({
  type: types.MENTORSHIP_RECORDING_DETAIL_FETCH as typeof types.MENTORSHIP_RECORDING_DETAIL_FETCH,
  payload,
  meta,
})

export const fetchMentorshipRecordingDetailSuccess = (
  payload: MentorshipRecordingSessionItemData,
  meta: MentorshipRecordingDetailParams
) => ({
  type: types.MENTORSHIP_RECORDING_DETAIL_SUCCESS as typeof types.MENTORSHIP_RECORDING_DETAIL_SUCCESS,
  payload,
  meta,
})

export const fetchMentorshipRecordingDetailFailure = (
  payload: Error,
  meta: MentorshipRecordingDetailParams
) => ({
  type: types.MENTORSHIP_RECORDING_DETAIL_FAILURE as typeof types.MENTORSHIP_RECORDING_DETAIL_FAILURE,
  payload,
  meta,
})

export const updateMentorshipRecordingVideoWatchData = (
  payload: { percentage: number; requirement_met: boolean | null },
  meta: MentorshipRecordingDetailParams
) => ({
  type: types.UPDATE_MENTORSHIP_RECORDING_VIDEO_WATCHED_PERCENTAGE as typeof types.UPDATE_MENTORSHIP_RECORDING_VIDEO_WATCHED_PERCENTAGE,
  payload,
  meta,
})

export type RecordingsActionTypes =
  | ReturnType<typeof fetchCourseMentorshipRecordings>
  | ReturnType<typeof fetchCourseMentorshipRecordingsSuccess>
  | ReturnType<typeof fetchCourseMentorshipRecordingsFailure>
  | ReturnType<typeof fetchSessionMentorshipRecordings>
  | ReturnType<typeof fetchSessionMentorshipRecordingsSuccess>
  | ReturnType<typeof fetchSessionMentorshipRecordingsFailure>
  | ReturnType<typeof fetchMentorshipRecordingDetail>
  | ReturnType<typeof fetchMentorshipRecordingDetailSuccess>
  | ReturnType<typeof fetchMentorshipRecordingDetailFailure>
  | ReturnType<typeof updateMentorshipRecordingVideoWatchData>
