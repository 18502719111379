import { DigitalExcelerateTestId } from 'common/types/excelerate/tests'
import {
  EXCELERATE_QUIZ_TEST_SUBMISSION,
  EXCELERATE_QUIZ_TEST_SUBMISSION_CANCEL,
  EXCELERATE_QUIZ_TEST_SUBMISSION_FAILURE,
  EXCELERATE_QUIZ_TEST_SUBMISSION_SUCCESS,
  FETCH_EXCELERATE_TEST_DETAILS,
  FETCH_EXCELERATE_TEST_DETAILS_CANCEL,
  FETCH_EXCELERATE_TEST_DETAILS_FAILURE,
  FETCH_EXCELERATE_TEST_DETAILS_SUCCESS,
} from './Tests.types'

interface ExcelerateTestParams {
  testId: DigitalExcelerateTestId
}

export const fetchExcelerateTestDetails = (
  payload: ExcelerateTestParams,
  meta: ExcelerateTestParams
) => ({
  type: FETCH_EXCELERATE_TEST_DETAILS as typeof FETCH_EXCELERATE_TEST_DETAILS,
  payload,
  meta,
})
export const fetchExcelerateTestDetailsSuccess = (
  payload: any,
  meta: ExcelerateTestParams
) => ({
  type: FETCH_EXCELERATE_TEST_DETAILS_SUCCESS as typeof FETCH_EXCELERATE_TEST_DETAILS_SUCCESS,
  payload,
  meta,
})
export const fetchExcelerateTestDetailsFailure = (
  payload: Error,
  meta: ExcelerateTestParams
) => ({
  type: FETCH_EXCELERATE_TEST_DETAILS_FAILURE as typeof FETCH_EXCELERATE_TEST_DETAILS_FAILURE,
  payload,
  meta,
})
export const fetchExcelerateTestDetailsCancel = (
  payload: any,
  meta: ExcelerateTestParams
) => ({
  type: FETCH_EXCELERATE_TEST_DETAILS_CANCEL as typeof FETCH_EXCELERATE_TEST_DETAILS_CANCEL,
  payload,
  meta,
})
export const excelerateQuizTestSubmission = (
  payload: ExcelerateTestParams,
  meta: ExcelerateTestParams
) => ({
  type: EXCELERATE_QUIZ_TEST_SUBMISSION as typeof EXCELERATE_QUIZ_TEST_SUBMISSION,
  payload,
  meta,
})
export const excelerateQuizTestSubmissionSuccess = (
  payload: any,
  meta: ExcelerateTestParams
) => ({
  type: EXCELERATE_QUIZ_TEST_SUBMISSION_SUCCESS as typeof EXCELERATE_QUIZ_TEST_SUBMISSION_SUCCESS,
  payload,
  meta,
})
export const excelerateQuizTestSubmissionFailure = (
  payload: Error,
  meta: ExcelerateTestParams
) => ({
  type: EXCELERATE_QUIZ_TEST_SUBMISSION_SUCCESS as typeof EXCELERATE_QUIZ_TEST_SUBMISSION_FAILURE,
  payload,
  meta,
})
export const excelerateQuizTestSubmissionCancel = (
  payload: any,
  meta: ExcelerateTestParams
) => ({
  type: EXCELERATE_QUIZ_TEST_SUBMISSION_CANCEL as typeof EXCELERATE_QUIZ_TEST_SUBMISSION_CANCEL,
  payload,
  meta,
})

export type DigitalExcelerateTestsActionTypes =
  | ReturnType<typeof fetchExcelerateTestDetails>
  | ReturnType<typeof fetchExcelerateTestDetailsSuccess>
  | ReturnType<typeof fetchExcelerateTestDetailsFailure>
  | ReturnType<typeof fetchExcelerateTestDetailsCancel>
  | ReturnType<typeof excelerateQuizTestSubmission>
  | ReturnType<typeof excelerateQuizTestSubmissionSuccess>
  | ReturnType<typeof excelerateQuizTestSubmissionFailure>
  | ReturnType<typeof excelerateQuizTestSubmissionCancel>
