import { call, cancelled, put, select, takeLeading } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import { AppState } from 'web/store'
import {
  LearnAndDevCareerPathMetricsAPIData,
  fslDashboardAPIData,
  LearnAndDevDepartmentMetricsAPIData,
} from 'common/types/learnAndDev/dashboard'
import {
  fetchLearnAndDevCareerPathMetrics,
  fetchLearnAndDevCareerPathMetricsFailure,
  fetchLearnAndDevCareerPathMetricsSuccess,
  fetchLearnAndDevDashboardContent,
  fetchLearnAndDevDashboardContentFailure,
  fetchLearnAndDevDashboardContentSuccess,
  fetchLearnAndDevDepartmentMetrics,
  fetchLearnAndDevDepartmentMetricsFailure,
  fetchLearnAndDevDepartmentMetricsSuccess,
} from './Dashboard.actions'
import {
  FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS,
  FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_CANCEL,
  FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT,
  FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_CANCEL,
  FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS,
  FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_CANCEL,
} from './Dashboard.types'
import { UserID } from '../../../../common/types/user'
import {
  getLearnAndDevDashboardContentAPI,
  getLearnAndDevDepartmentMetricsAPI,
  getLearnAndDevCareerPathMetricsAPI,
} from './Dashboard.api'

function* fetchLearnAndDevDashboardContentHandler(
  action: ReturnType<typeof fetchLearnAndDevDashboardContent>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('userId unavilable')
    }
    const data: fslDashboardAPIData = yield call(
      getLearnAndDevDashboardContentAPI,
      action,
      userId,
      abortController.signal
    )
    yield put(fetchLearnAndDevDashboardContentSuccess(data))
  } catch (e) {
    yield put(fetchLearnAndDevDashboardContentFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchLearnAndDevDepartmentMetricsHandler(
  action: ReturnType<typeof fetchLearnAndDevDepartmentMetrics>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('userId unavilable')
    }
    const data: LearnAndDevDepartmentMetricsAPIData = yield call(
      getLearnAndDevDepartmentMetricsAPI,
      action,
      userId,
      abortController.signal
    )
    yield put(fetchLearnAndDevDepartmentMetricsSuccess(data))
  } catch (e) {
    yield put(fetchLearnAndDevDepartmentMetricsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
function* fetchLearnAndDevCareerPathMetricsHandler(
  action: ReturnType<typeof fetchLearnAndDevCareerPathMetrics>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('userId unavilable')
    }
    const data: LearnAndDevCareerPathMetricsAPIData = yield call(
      getLearnAndDevCareerPathMetricsAPI,
      action,
      userId,
      abortController.signal
    )
    yield put(fetchLearnAndDevCareerPathMetricsSuccess(data))
  } catch (e) {
    yield put(fetchLearnAndDevCareerPathMetricsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fslDashboardMiddleware() {
  yield takeLeading(
    FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT,
    cancelable(
      fetchLearnAndDevDashboardContentHandler,
      FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_CANCEL
    )
  )

  yield takeLeading(
    FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS,
    cancelable(
      fetchLearnAndDevDepartmentMetricsHandler,
      FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_CANCEL
    )
  )

  yield takeLeading(
    FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS,
    cancelable(
      fetchLearnAndDevCareerPathMetricsHandler,
      FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_CANCEL
    )
  )
}

export default ([] as any).concat(fslDashboardMiddleware())
