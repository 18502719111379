import { Reducer } from 'redux'
import {
  ModuleItemsActionTypes,
  fetchModuleItemsSuccess,
} from './ModuleItems.actions'
import {
  MODULE_ITEMS_FETCH,
  MODULE_ITEMS_BULK_INSERT,
  MODULE_ITEMS_FETCH_CANCEL,
  MODULE_ITEMS_FETCH_FAILURE,
  MODULE_ITEMS_FETCH_SUCCESS,
  MODULE_ITEM_FETCH,
  MODULE_ITEM_FETCH_SUCCESS,
  MODULE_ITEM_FETCH_FAILURE,
  FETCH_FEEDBACK_QA,
  FETCH_FEEDBACK_QA_SUCCESS,
  FETCH_FEEDBACK_QA_FAILURE,
  SUBMIT_CONTENT_FEEDBACK,
  SUBMIT_CONTENT_FEEDBACK_SUCCESS,
  SUBMIT_CONTENT_FEEDBACK_FAILURE,
  MODULE_ITEMS_ACCESS_DATA,
} from './ModuleItems.types'
import {
  ITEM_CONTENT_FETCH_FAILURE,
  ITEM_CONTENT_FETCH_SUCCESS,
  itemContentReducer,
  ItemContentActionTypes,
  ITEM_CONTENT_FETCH,
} from './ItemContent'
import {
  QuizSubmissionsActionTypes,
  quizSubmissionTypes,
  quizSubmissionCommentReducer,
  initialSubmissionState,
} from './Quiz/QuizSubmissionsProvider'
import { QuizQAActionTypes, quizQAActionTypes } from './Quiz/QuizQAProvider'
import {
  quizActivityReducer,
  quizActivityTypes,
} from './Quiz/QuizActivityProvider'
import { pick } from '../../../../common/utils'
import { QuizActivityActionTypes } from './Quiz/QuizActivityProvider/QuizActivity.actions'
import {
  assignmentSubmissionReducer,
  assignmentActionTypes,
  AssignmentSubmissionActionTypes,
} from './Assignment/AssignmentSubmissionsProvider'
import {
  submissionCommentsTypes,
  SubmissionCommentsTypes,
  submissionCommentsReducer,
} from '../SubmissionCommentsProvider'
import {
  DiscussionActionTypes,
  discussionTypes,
  discussionReducer,
} from './Discussion'
import {
  ItemID,
  ModuleItemData,
  ModuleItemViewObj,
  PrerequisiteItem,
  ModuleData,
  CourseAssetCategory,
  LinkModuleItemData,
} from '../../../../common/types/courses/moduleItem'
import { ModuleID } from '../../../../common/types'
import { QuizModuleItemData } from '../../../../common/types/courses/quiz'
import { AssignmentModuleItemData } from '../../../../common/types/courses/assignment'
import { DiscussionModuleItemData } from '../../../../common/types/courses/dq'
import { wikiPageTypes, WikiPageActions, wikiPageReducer } from './WikiPage'
import { PageModuleItemData } from '../../../../common/types/courses/page'
import {
  ASSESSMENT_PROCTOR_CONTENT_FETCH,
  ASSESSMENT_PROCTOR_CONTENT_FETCH_SUCCESS,
  ASSESSMENT_PROCTOR_CONTENT_FETCH_FAILURE,
} from './ItemContent/ItemContent.types'
import {
  ExternalUrlActions,
  externalUrlReducer,
  externalUrlTypes,
} from './ExternalUrl'

interface ModuleItemDataByID {
  byId: { [s in ItemID]?: ModuleItemData }
  byModule: {
    [s in ModuleID]?: { list: ItemID[]; count: number }
  }
}

export interface ModuleItemsState {
  data: ModuleItemDataByID
  byModule: {
    [s in ModuleID]?: {
      loading: boolean
      error: false | Error | Response
    }
  }
  byItem: {
    [s in ItemID]?: {
      loading: boolean
      error: false | Error | Response
    }
  }
}

const initialStateModuleItems: ModuleItemsState = {
  data: {
    byId: {},
    byModule: {},
  },
  byModule: {},
  byItem: {},
}
const initialModuleAPIData: ModuleItemsState['byModule']['x'] = {
  loading: false,
  error: false,
}

function getItemLockInfo(
  moduleData: ModuleData,
  item: ModuleItemData
): ModuleItemViewObj {
  const isModuleLocked = moduleData.state === 'locked'
  if (isModuleLocked) {
    return {
      locked: true,
      prerequisiteType: 'module',
      prerequisiteIds: moduleData.prerequisite_module_ids,
    }
  }
  if (item.content_details && item.content_details.locked_for_user) {
    if (item.content_details.lock_info) {
      if (item.content_details.lock_info.context_module) {
        return {
          locked: true,
          prerequisiteType: 'item',
          prerequisiteIds: item.content_details.lock_info.context_module.completion_requirements.map(
            (obj: PrerequisiteItem) => obj.id
          ),
        }
      }
    }
  }
  return { locked: false }
}

function getNormalizedData(
  state: ModuleItemsState,
  action: Pick<ReturnType<typeof fetchModuleItemsSuccess>, 'meta' | 'payload'>
) {
  const { moduleData, items } = action.payload
  const currentModuleData = state.data.byModule[action.meta.moduleId]
  const moduleItemsList: ItemID[] =
    currentModuleData && Array.isArray(currentModuleData.list)
      ? currentModuleData.list
      : []

  const moduleItemsMap = items.reduce(
    (
      accumulator: { [s in ModuleID]: ModuleItemData },
      item: ModuleItemData
    ) => {
      const lockInfo: ModuleItemViewObj = getItemLockInfo(moduleData, item)
      accumulator[item.id] = {
        ...state.data.byId[item.id],
        ...item,
        lockInfo,
      } as ModuleItemData
      moduleItemsList.push(item.id)
      return accumulator
    },
    {}
  )

  const uniqueModuleItemsList = [...new Set(moduleItemsList)].sort((a, b) => {
    const aVal = state.data.byId[a] || moduleItemsMap[a]
    const bVal = state.data.byId[b] || moduleItemsMap[b]
    if (aVal && bVal) {
      return aVal.position - bVal.position
    }
    return 0
  })

  return {
    data: moduleItemsMap,
    list: uniqueModuleItemsList,
  }
}

const getNormalizedItemsAccessData = (
  state: ModuleItemsState['data']['byId'],
  data: any
): ModuleItemsState['data']['byId'] => {
  const wikiPageTitles: string[] = []
  const fileIds: string[] = []
  const linkIds: string[] = []
  data.forEach(
    (item: {
      asset_code: string
      asset_category: CourseAssetCategory
      display_name: string
    }) => {
      if (item.asset_category === 'wiki') {
        wikiPageTitles.push(item.display_name)
      } else if (item.asset_category === 'files') {
        fileIds.push(item.asset_code.split('_')[1])
      } else if (item.asset_category === 'external_urls') {
        linkIds.push(item.asset_code.split('_')[2])
      }
    }
  )
  Object.keys(state).forEach((itemId: string | number) => {
    const itemData = state[itemId]
    if (itemData) {
      if (itemData.type === 'Page') {
        if (!itemData.contentMeta && wikiPageTitles.includes(itemData.title)) {
          itemData.viewed = true
        }
      } else if (itemData.type === 'File') {
        if (fileIds.includes(itemData.content_id.toString())) {
          itemData.viewed = true
        }
      } else if (itemData.type === 'ExternalUrl') {
        if (linkIds.includes(itemData.id.toString())) {
          itemData.viewed = true
        }
      }
    }
  })
  return state
}

const moduleItemsReducer: Reducer<
  ModuleItemsState,
  | ModuleItemsActionTypes
  | ItemContentActionTypes
  | QuizSubmissionsActionTypes
  | QuizQAActionTypes
  | QuizActivityActionTypes
  | AssignmentSubmissionActionTypes
  | SubmissionCommentsTypes
  | DiscussionActionTypes
  | WikiPageActions
  | ExternalUrlActions
> = (state = initialStateModuleItems, action): ModuleItemsState => {
  switch (action.type) {
    case MODULE_ITEMS_FETCH: {
      const moduleAPIData =
        state.byModule[action.meta.moduleId] || initialModuleAPIData
      return {
        ...state,
        byModule: {
          ...state.byModule,
          [action.meta.moduleId]: {
            ...moduleAPIData,
            loading: true,
            error: false as false,
          },
        },
      }
    }
    case MODULE_ITEMS_FETCH_FAILURE: {
      const moduleAPIData =
        state.byModule[action.meta.moduleId] || initialModuleAPIData
      return {
        ...state,
        byModule: {
          ...state.byModule,
          [action.meta.moduleId]: {
            ...moduleAPIData,
            loading: false,
            error: action.payload,
          },
        },
      }
    }
    case MODULE_ITEMS_FETCH_SUCCESS: {
      const res = getNormalizedData(state, action)

      const moduleAPIData =
        state.byModule[action.meta.moduleId] || initialModuleAPIData
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            ...res.data,
          },
          byModule: {
            ...state.data.byModule,
            [action.meta.moduleId]: {
              list: res.list,
              count: res.list.length,
            },
          },
        },
        byModule: {
          ...state.byModule,
          [action.meta.moduleId]: {
            ...moduleAPIData,
            loading: false,
            error: false as false,
          },
        },
      }
    }
    case MODULE_ITEMS_FETCH_CANCEL: {
      const moduleAPIData =
        state.byModule[action.meta.moduleId] || initialModuleAPIData
      return {
        ...state,
        byModule: {
          ...state.byModule,
          [action.meta.moduleId]: {
            ...moduleAPIData,
            error: false,
            loading: false as false,
          },
        },
      }
    }
    case MODULE_ITEMS_BULK_INSERT: {
      let byId: ModuleItemsState['data']['byId'] = {}
      let byModule: ModuleItemsState['data']['byModule'] = {}
      Object.entries(action.payload).forEach(([moduleId, moduleObj]) => {
        const res = getNormalizedData(state, {
          payload: { moduleData: moduleObj.moduleData, items: moduleObj.items },
          meta: { moduleId },
        })
        byId = {
          ...byId,
          ...res.data,
        }
        byModule = {
          ...byModule,
          [moduleId]: {
            list: res.list,
            count: moduleObj.count,
          },
        }
      })
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            ...byId,
          },
          byModule: {
            ...state.data.byModule,
            ...byModule,
          },
        },
      }
    }

    case MODULE_ITEMS_ACCESS_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          byId: getNormalizedItemsAccessData(state.data.byId, action.payload),
        },
      }

    case MODULE_ITEM_FETCH: {
      return {
        ...state,
        byItem: {
          ...state.byItem,
          [action.meta.itemId]: {
            loading: true,
            error: false as false,
          },
        },
      }
    }
    case MODULE_ITEM_FETCH_SUCCESS: {
      const lockInfo = getItemLockInfo(
        action.payload.moduleData,
        action.payload.item
      )
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.itemId]: {
              ...state.data.byId[action.meta.itemId]!,
              ...action.payload.item,
              lockInfo,
            } as ModuleItemData,
          },
        },
        byItem: {
          ...state.byItem,
          [action.meta.itemId]: {
            loading: false,
            error: false as false,
          },
        },
      }
    }
    case MODULE_ITEM_FETCH_FAILURE: {
      return {
        ...state,
        byItem: {
          ...state.byItem,
          [action.meta.itemId]: {
            loading: false,
            error: action.payload,
          },
        },
      }
    }

    case FETCH_FEEDBACK_QA: {
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.itemId]: {
              ...state.data.byId[action.meta.itemId],
              itemFeedback: {
                status: 'fetching',
              },
            } as ModuleItemData,
          },
        },
      }
    }

    case FETCH_FEEDBACK_QA_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.itemId]: {
              ...state.data.byId[action.meta.itemId],
              itemFeedback: {
                status: 'fetched',
                feedbackData: {
                  ...action.payload,
                },
              },
            } as ModuleItemData,
          },
        },
      }
    }

    case FETCH_FEEDBACK_QA_FAILURE: {
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.itemId]: {
              ...state.data.byId[action.meta.itemId],
              itemFeedback: {
                status: 'error',
                feedbackData: action.payload,
              },
            } as ModuleItemData,
          },
        },
      }
    }

    case SUBMIT_CONTENT_FEEDBACK: {
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.itemId]: {
              ...state.data.byId[action.meta.itemId],
              itemFeedback: {
                ...(state.data.byId[action.meta.itemId]
                  ? state.data.byId[action.meta.itemId]!.itemFeedback
                  : {}),
                status: 'submitting',
              },
            } as ModuleItemData,
          },
        },
      }
    }

    case SUBMIT_CONTENT_FEEDBACK_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.itemId]: {
              ...state.data.byId[action.meta.itemId],
              itemFeedback: {
                status: 'submitted',
                feedbackData: {
                  feedback_rating: action.payload.feedback_rating,
                },
              },
            } as ModuleItemData,
          },
        },
      }
    }

    case SUBMIT_CONTENT_FEEDBACK_FAILURE: {
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.itemId]: {
              ...state.data.byId[action.meta.itemId],
              itemFeedback: {
                status: 'error',
                feedbackData: action.payload,
              },
            } as ModuleItemData,
          },
        },
      }
    }

    case ITEM_CONTENT_FETCH:
    case ITEM_CONTENT_FETCH_FAILURE:
    case ITEM_CONTENT_FETCH_SUCCESS:
    case ASSESSMENT_PROCTOR_CONTENT_FETCH:
    case ASSESSMENT_PROCTOR_CONTENT_FETCH_SUCCESS:
    case ASSESSMENT_PROCTOR_CONTENT_FETCH_FAILURE:
      return itemContentReducer(state, action)

    /**
     *
     * QUIZ
     *
     */
    case quizSubmissionTypes.QUIZ_SUBMISSIONS_FETCH:
    case quizSubmissionTypes.QUIZ_SUBMISSIONS_FETCH_SUCCESS:
    case quizSubmissionTypes.QUIZ_SUBMISSIONS_FETCH_FAILURE:
    case quizSubmissionTypes.QUIZ_SUBMISSIONS_FETCH_CANCEL:
    case quizSubmissionTypes.QUIZ_SUBMISSION_RESUME:
    case quizSubmissionTypes.QUIZ_SUBMISSION_RESUME_FAILURE:
    case quizSubmissionTypes.QUIZ_SUBMISSION_RESUME_SUCCESS:
    case quizSubmissionTypes.QUIZ_SUBMISSION_START:
    case quizSubmissionTypes.QUIZ_SUBMISSION_START_SUCCESS:
    case quizSubmissionTypes.QUIZ_SUBMISSION_START_FAILURE:
    case quizSubmissionTypes.QUIZ_SUBMISSION_COMPLETE:
    case quizSubmissionTypes.QUIZ_SUBMISSION_COMPLETE_SUCCESS:
    case quizSubmissionTypes.QUIZ_SUBMISSION_COMPLETE_FAILURE:
    case quizSubmissionTypes.QUIZ_SUBMISSION_RESET:
    case quizSubmissionTypes.QUIZ_SUBMISSION_CLEAR_FILES:
    case quizQAActionTypes.QUIZ_SUBMISSION_QA_FETCH:
    case quizQAActionTypes.QUIZ_SUBMISSION_QA_FETCH_SUCCESS:
    case quizQAActionTypes.QUIZ_SUBMISSION_QA_FETCH_FAILURE:
    case quizQAActionTypes.QUIZ_QUESTION_FLAG:
    case quizQAActionTypes.QUIZ_QUESTION_UNFLAG:
    case quizActivityTypes.QUIZ_UPDATE_ACTIVE_QUESTION:
    case quizActivityTypes.QUIZ_SUBMISSION_DYNAMIC_QA_FETCH:
    case quizActivityTypes.QUIZ_SUBMISSION_DYNAMIC_QA_FETCH_SUCCESS:
    case quizActivityTypes.QUIZ_SUBMISSION_DYNAMIC_QA_FETCH_FAILURE:
    case quizActivityTypes.QUIZ_SUBMISSION_DYNAMIC_QA_LIST_FETCH_SUCCESS:
    case quizActivityTypes.QUIZ_SUBMISSION_DYNAMIC_QA_UPDATE_QUESTION_INDEX:
    case quizQAActionTypes.QUIZ_SUBMISSION_ANSWERS_SAVE:
    case quizQAActionTypes.QUIZ_SUBMISSION_ANSWERS_SAVE_SUCCESS:
    case quizQAActionTypes.QUIZ_SUBMISSION_ANSWERS_SAVE_FAILURE:
    case quizQAActionTypes.QUIZ_QUESTION_FILE_UPLOAD:
    case quizActivityTypes.GET_QUIZ_SUBMISSION_SERVER_TIME_SUCCESS:
    case quizQAActionTypes.GET_FILE_DETAILS:
    case quizQAActionTypes.GET_FILE_DETAILS_SUCCESS:
    case quizQAActionTypes.GET_FILE_DETAILS_FAILURE:
    case quizQAActionTypes.GET_DYNAMIC_QUESTION_ANSWERS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.itemId]: {
              ...state.data.byId[action.meta.itemId]!,
              itemActivity: quizActivityReducer(
                pick(
                  (state.data.byId[action.meta.itemId] as QuizModuleItemData)
                    .itemActivity || {
                    activeAttempt: null,
                    submissions: null,
                    submission: initialSubmissionState,
                  },
                  ['activeAttempt', 'submissions', 'submission']
                ),
                action
              ),
            } as QuizModuleItemData,
          },
        },
      }
    }

    /**
     * Assignments
     *
     *
     */
    case assignmentActionTypes.SUBMIT_ASSIGNMENT:
    case assignmentActionTypes.SUBMIT_ASSIGNMENT_SUCCESS:
    case assignmentActionTypes.SUBMIT_ASSIGNMENT_FAILURE:
    case assignmentActionTypes.SUBMIT_ASSIGNMENT_CANCEL:
    case assignmentActionTypes.GET_ASSIGNMENT_SUBMISSION:
    case assignmentActionTypes.GET_ASSIGNMENT_SUBMISSION_SUCCESS:
    case assignmentActionTypes.GET_ASSIGNMENT_SUBMISSION_FAILURE:
    case assignmentActionTypes.GET_ASSIGNMENT_SUBMISSION_CANCEL:
    case assignmentActionTypes.GET_SUBMITTED_USER_DETAILS:
    case assignmentActionTypes.GET_SUBMITTED_USER_DETAILS_SUCCESS:
    case assignmentActionTypes.GET_SUBMITTED_USER_DETAILS_FAILURE:
    case assignmentActionTypes.GET_SUBMITTED_USER_DETAILS_CANCEL:
    case assignmentActionTypes.GET_EXTERNAL_TOOL_URL:
    case assignmentActionTypes.GET_EXTERNAL_TOOL_URL_SUCCESS:
    case assignmentActionTypes.GET_EXTERNAL_TOOL_URL_FAILURE:
    case assignmentActionTypes.GET_ASSIGNMENT_META_DATA:
    case assignmentActionTypes.GET_ASSIGNMENT_META_DATA_SUCCESS:
    case assignmentActionTypes.GET_ASSIGNMENT_META_DATA_FAILURE:
    case assignmentActionTypes.GET_ASSIGNMENT_AI_MENTOR_USER_DATA:
    case assignmentActionTypes.GET_ASSIGNMENT_AI_MENTOR_USER_DATA_SUCCESS:
    case assignmentActionTypes.GET_ASSIGNMENT_AI_MENTOR_USER_DATA_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.itemId]: {
              ...state.data.byId[action.meta.itemId]!,
              itemActivity: {
                ...assignmentSubmissionReducer(
                  (state.data.byId[
                    action.meta.itemId
                  ] as AssignmentModuleItemData)?.itemActivity,
                  action
                ),
              },
            } as AssignmentModuleItemData,
          },
        },
      }

    case submissionCommentsTypes.SUBMIT_COMMENTS:
    case submissionCommentsTypes.SUBMIT_COMMENTS_SUCCESS:
    case submissionCommentsTypes.SUBMIT_COMMENTS_FAILURE:
    case submissionCommentsTypes.SUBMIT_COMMENTS_CANCEL:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.itemId]: {
              ...state.data.byId[action.meta.itemId]!,
              itemActivity: {
                ...state.data.byId[action.meta.itemId]!.itemActivity,
                submission: {
                  ...submissionCommentsReducer(
                    (state.data.byId[action.meta.itemId] as ModuleItemData)
                      .itemActivity.submission,
                    action
                  ),
                },
              },
            } as ModuleItemData,
          },
        },
      }
    case discussionTypes.DISCUSSION_ENTRIES_FETCH:
    case discussionTypes.DISCUSSION_ENTRIES_FETCH_SUCCESS:
    case discussionTypes.DISCUSSION_ENTRIES_FETCH_FAILURE:
    case discussionTypes.DISCUSSION_ENTRY_POST:
    case discussionTypes.DISCUSSION_ENTRY_POST_FAILURE:
    case discussionTypes.DISCUSSION_ENTRY_POST_SUCCESS:
    case discussionTypes.DISCUSSION_ENTRY_UPDATE:
    case discussionTypes.DISCUSSION_ENTRY_UPDATE_SUCCESS:
    case discussionTypes.DISCUSSION_ENTRY_UPDATE_FAILURE:
    case discussionTypes.DISCUSSION_ENTRY_RATING_UPDATE:
    case discussionTypes.DISCUSSION_ENTRY_RATING_UPDATE_FAILURE:
    case discussionTypes.DISCUSSION_ENTRY_RATING_UPDATE_SUCCESS:
    case discussionTypes.DISCUSSION_ENTRY_MARK_AS_READ_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.itemId]: {
              ...state.data.byId[action.meta.itemId]!,
              ...discussionReducer(
                pick(
                  state.data.byId[
                    action.meta.itemId
                  ] as DiscussionModuleItemData,
                  ['itemActivity', 'itemContent']
                ),
                action
              ),
            } as DiscussionModuleItemData,
          },
        },
      }

    case wikiPageTypes.UPDATE_VIDEO_WATCHED_PERCENTAGE:
    case wikiPageTypes.FETCH_VIDEO_DETAILS:
    case wikiPageTypes.FETCH_VIDEO_DETAILS_SUCCESS:
    case wikiPageTypes.FETCH_VIDEO_DETAILS_FAILURE:
    case wikiPageTypes.FETCH_VIDEO_DETAILS_CANCEL:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.itemId]: {
              ...state.data.byId[action.meta.itemId]!,
              ...wikiPageReducer(
                state.data.byId[action.meta.itemId]! as PageModuleItemData,
                action
              ),
            } as PageModuleItemData,
          },
        },
      }

    case externalUrlTypes.UPDATE_LINK_PAGE_VIEW_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.itemId]: {
              ...state.data.byId[action.meta.itemId]!,
              ...externalUrlReducer(
                state.data.byId[action.meta.itemId]! as LinkModuleItemData,
                action
              ),
            } as PageModuleItemData,
          },
        },
      }

    case quizSubmissionTypes.GET_QUIZ_SUBMISSION_COMMENTS:
    case quizSubmissionTypes.GET_QUIZ_SUBMISSION_COMMENTS_CANCEL:
    case quizSubmissionTypes.GET_QUIZ_SUBMISSION_COMMENTS_FAILURE:
    case quizSubmissionTypes.GET_QUIZ_SUBMISSION_COMMENTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.itemId]: {
              ...state.data.byId[action.meta.itemId]!,
              itemActivity: {
                ...state.data.byId[action.meta.itemId]!.itemActivity,
                submission: {
                  ...quizSubmissionCommentReducer(
                    (state.data.byId[action.meta.itemId] as ModuleItemData)
                      .itemActivity.submission,
                    action
                  ),
                },
              },
            } as ModuleItemData,
          },
        },
      }

    default:
      return state
  }
}

export default moduleItemsReducer
