import AgoraRTC from 'agora-rtc-sdk-ng'

export const getMicrophones = async (skipPermission = false) => {
  try {
    const all = await AgoraRTC.getMicrophones(skipPermission)
    return {
      default:
        all.length > 0
          ? all.find(d => d.deviceId === 'default') || all[0]
          : null,
      all,
    }
  } catch (err) {
    return {
      default: null,
      all: [],
    }
  }
}

export const getCameras = async (skipPermission = false) => {
  try {
    const all = await AgoraRTC.getCameras(skipPermission)
    return {
      default: all.length > 0 ? all[0] : null,
      all,
    }
  } catch (err) {
    return {
      default: null,
      all: [],
    }
  }
}

export const getSpeakers = async (skipPermission = false) => {
  try {
    const all = await AgoraRTC.getPlaybackDevices(skipPermission)
    return {
      default:
        all.length > 0
          ? all.find(d => d.deviceId === 'default') || all[0]
          : null,
      all,
    }
  } catch (err) {
    return {
      default: null,
      all: [],
    }
  }
}

const getDevices = async (skipPermission = false) => {
  const [microphones, cameras, speakers] = await Promise.all([
    getMicrophones(skipPermission),
    getCameras(skipPermission),
    getSpeakers(skipPermission),
  ])

  return {
    current: {
      microphone: microphones.default,
      camera: cameras.default,
      speaker: speakers.default,
    },
    all: {
      microphone: microphones.all,
      speaker: speakers.all,
      camera: cameras.all,
    },
  }
}

export default getDevices
