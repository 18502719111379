import { FileInfo } from 'web/providers/FilesProvider'
import * as types from './QuizQA.types'
import { CourseID } from '../../../../../../common/types/courses'
import {
  QuizID,
  QuizAttemptNo,
  QuizQuestionData,
  QuizAnswerData,
  QuizQuestionID,
} from '../../../../../../common/types/courses/quiz'
import { ItemID } from '../../../../../../common/types/courses/moduleItem'
import { ModuleID, FileID } from '../../../../../../common/types'

interface QuizQABaseMetaParams {
  courseId: CourseID
  quizId: QuizID
  itemId: ItemID
  attemptNo: QuizAttemptNo
}

export const fetchQuizSubmissionQuestionsAndAnswers = (
  payload: null,
  meta: QuizQABaseMetaParams
) => ({
  type: types.QUIZ_SUBMISSION_QA_FETCH as typeof types.QUIZ_SUBMISSION_QA_FETCH,
  payload,
  meta,
})

export const fetchQuizSubmissionQuestionsAndAnswersSuccess = (
  payload: (QuizQuestionData & QuizAnswerData)[],
  meta: QuizQABaseMetaParams
) => ({
  type: types.QUIZ_SUBMISSION_QA_FETCH_SUCCESS as typeof types.QUIZ_SUBMISSION_QA_FETCH_SUCCESS,
  payload,
  meta,
})

export const fetchQuizSubmissionQuestionsAndAnswersFailure = (
  payload: Error,
  meta: QuizQABaseMetaParams
) => ({
  type: types.QUIZ_SUBMISSION_QA_FETCH_FAILURE as typeof types.QUIZ_SUBMISSION_QA_FETCH_FAILURE,
  payload,
  meta,
})

interface QuizQuestionMetaParams extends QuizQABaseMetaParams {
  questionId: QuizQuestionID
}

export const flagQuizQuestion = (
  payload: null,
  meta: QuizQuestionMetaParams
) => ({
  type: types.QUIZ_QUESTION_FLAG as typeof types.QUIZ_QUESTION_FLAG,
  payload,
  meta,
})

export const unFlagQuizQuestion = (
  payload: null,
  meta: QuizQuestionMetaParams
) => ({
  type: types.QUIZ_QUESTION_UNFLAG as typeof types.QUIZ_QUESTION_UNFLAG,
  payload,
  meta,
})

export const updateQuizQuestionAnswer = (
  payload: QuizAnswerData['answer'],
  meta: QuizQuestionMetaParams & { moduleId: ModuleID }
) => ({
  type: types.QUIZ_QUESTION_UPDATE_ANSWER as typeof types.QUIZ_QUESTION_UPDATE_ANSWER,
  payload,
  meta,
})

export const saveQuizAnswers = (
  payload: {
    onQuizComplete: boolean
    answer?: QuizAnswerData['answer']
  },
  meta: QuizQuestionMetaParams & { moduleId: ModuleID }
) => ({
  type: types.QUIZ_SUBMISSION_ANSWERS_SAVE as typeof types.QUIZ_SUBMISSION_ANSWERS_SAVE,
  payload,
  meta,
})

export const saveQuizAnswersSuccess = (
  payload: { answers: QuizAnswerData[] },
  meta: QuizQuestionMetaParams
) => ({
  type: types.QUIZ_SUBMISSION_ANSWERS_SAVE_SUCCESS as typeof types.QUIZ_SUBMISSION_ANSWERS_SAVE_SUCCESS,
  payload,
  meta,
})

export const saveQuizAnswersFailure = (
  payload: Error,
  meta: QuizQABaseMetaParams,
  options: { [s in string]: any }
) => ({
  type: types.QUIZ_SUBMISSION_ANSWERS_SAVE_FAILURE as typeof types.QUIZ_SUBMISSION_ANSWERS_SAVE_FAILURE,
  payload,
  meta,
  options,
})

export const quizQuestionFileUpload = (
  payload: {
    url: string
    dataKey: string
    files: FileInfo[]
  },
  meta: QuizQuestionMetaParams & { moduleId: ModuleID }
) => ({
  type: types.QUIZ_QUESTION_FILE_UPLOAD as typeof types.QUIZ_QUESTION_FILE_UPLOAD,
  payload,
  meta,
})

export const getFileDetails = (
  payload: {
    fileId: FileID
  },
  meta: QuizQuestionMetaParams & { fileId: FileID }
) => ({
  type: types.GET_FILE_DETAILS as typeof types.GET_FILE_DETAILS,
  payload,
  meta,
})

export const getFileDetailsSuccess = (
  payload: { data: any },
  meta: QuizQuestionMetaParams & { fileId: FileID }
) => ({
  type: types.GET_FILE_DETAILS_SUCCESS as typeof types.GET_FILE_DETAILS_SUCCESS,
  payload,
  meta,
})

export const getFileDetailsFailure = (
  payload: Error,
  meta: QuizQuestionMetaParams
) => ({
  type: types.GET_FILE_DETAILS_FAILURE as typeof types.GET_FILE_DETAILS_FAILURE,
  payload,
  meta,
})

export const getDynamicQuestionAnswersSuccess = (
  payload: any,
  meta: QuizQABaseMetaParams
) => ({
  type: types.GET_DYNAMIC_QUESTION_ANSWERS_SUCCESS as typeof types.GET_DYNAMIC_QUESTION_ANSWERS_SUCCESS,
  payload,
  meta,
})

export type QuizQAActionTypes =
  | ReturnType<typeof fetchQuizSubmissionQuestionsAndAnswers>
  | ReturnType<typeof fetchQuizSubmissionQuestionsAndAnswersSuccess>
  | ReturnType<typeof fetchQuizSubmissionQuestionsAndAnswersFailure>
  | ReturnType<typeof flagQuizQuestion>
  | ReturnType<typeof unFlagQuizQuestion>
  | ReturnType<typeof saveQuizAnswers>
  | ReturnType<typeof saveQuizAnswersSuccess>
  | ReturnType<typeof saveQuizAnswersFailure>
  | ReturnType<typeof quizQuestionFileUpload>
  | ReturnType<typeof updateQuizQuestionAnswer>
  | ReturnType<typeof getFileDetails>
  | ReturnType<typeof getFileDetailsSuccess>
  | ReturnType<typeof getFileDetailsFailure>
  | ReturnType<typeof getDynamicQuestionAnswersSuccess>
