import React, { useEffect, useRef } from 'react'

interface Props {
  hideBanner?: boolean
  wrapperClass?: string
  children: React.ReactNode
  minHeight?: number
}

const StickyBanner = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    return () => {
      const root = document.documentElement
      root.style.setProperty('--header-height', '64px')
    }
  }, [])

  useEffect(() => {
    const root = document.documentElement
    const bannerDiv = ref.current
    if (bannerDiv && !props.hideBanner) {
      const bannerHeight =
        bannerDiv.getBoundingClientRect().height || props.minHeight || 0
      const headerHeight = `${bannerHeight +
        Number.parseInt(
          getComputedStyle(document.documentElement).getPropertyValue(
            '--header-height'
          ),
          10
        )}px`
      root.style.setProperty('--header-height', headerHeight)
    } else {
      root.style.setProperty('--header-height', '64px')
    }
  }, [props.hideBanner])

  return props.hideBanner ? null : (
    <div className={props.wrapperClass} ref={ref}>
      {props.children}
    </div>
  )
}

StickyBanner.defaultProps = {
  hideBanner: false,
}

export default StickyBanner
