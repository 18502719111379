import { Reducer } from 'redux'
import {
  ReferralAndRewardsData,
  RewardType,
} from 'common/types/referrralAndRewards'
import * as types from './ReferralsAndRewards.types'

export interface ReferralsAndRewardsStateData
  extends Omit<ReferralAndRewardsData, 'rewards_types'> {
  rewardsTypesById: {
    [key in string]?: RewardType
  }
}

export type ReferralsAndRewardsState = Readonly<{
  data: ReferralsAndRewardsStateData | {}
  loading: boolean
  error: boolean | Error
}>

const initialState: ReferralsAndRewardsState = {
  data: {},
  loading: false,
  error: false,
}

const dataHandler = (
  data: ReferralAndRewardsData
): ReferralsAndRewardsStateData => {
  const rewardsTypesById = data.rewards_types.reduce(
    (acc: ReferralsAndRewardsStateData['rewardsTypesById'], el: RewardType) => {
      acc[el.id] = el
      return acc
    },
    {}
  )

  const response = {
    ...data,
    rewardsTypesById,
  }

  delete response.rewards_types

  return response
}

const referralsAndRewardsReducer: Reducer<ReferralsAndRewardsState> = (
  state = initialState,
  action
): ReferralsAndRewardsState => {
  switch (action.type) {
    case types.FETCH_REFERRALS_REWARDS_DETAILS: {
      return {
        ...state,
        loading: true,
        error: false,
      }
    }
    case types.FETCH_REFERRALS_REWARDS_DETAILS_SUCCESS: {
      return {
        ...state,
        data: dataHandler(action.payload),
        loading: false,
        error: false,
      }
    }
    case types.FETCH_REFERRALS_REWARDS_DETAILS_CANCEL: {
      return {
        ...state,
        loading: false,
        error: false,
      }
    }
    case types.FETCH_REFERRALS_REWARDS_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }

    case types.CLAIM_REWARD_SUCCESS: {
      return {
        ...state,
        data: dataHandler(action.payload),
        loading: false,
        error: false,
      }
    }
    default:
      return state
  }
}

export default referralsAndRewardsReducer
