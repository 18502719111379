import { ProgramID } from 'common/types/programs'
import {
  apiCall,
  getUserDetails,
  objectToFormData,
  getActiveProgramBatchId,
} from '../../../common/utils'
import { fetchEportfolio, toggleProjectVisibility, updateProjectLearnings, fetchUserByEportfolioUserName } from './Eportfolio.actions'

export async function getEportfolioAPI(
  action: ReturnType<typeof fetchEportfolio>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const programBatchId: ProgramID = getActiveProgramBatchId()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/portfolio`,
    params: { signal },
    query: { pb_id: programBatchId },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function toggleProjectVisibilityAPI(
  action: ReturnType<typeof toggleProjectVisibility>,
  projectId: string | number,
  visibility: boolean,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const programBatchId: ProgramID = getActiveProgramBatchId()
  const requestBody = { project_id: projectId, visibility }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/toggle_project`,
    params: { method: 'PUT', signal, body: JSON.stringify(requestBody) },
    query: { pb_id: programBatchId },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function updateProjectLearningsAPI(
  action: ReturnType<typeof updateProjectLearnings>,
  projectId: string | number,
  learnings: string,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const programBatchId: ProgramID = getActiveProgramBatchId()
  const requestBody = { project_id: projectId, learnings }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/update_project_learnings`,
    params: { method: 'PUT', signal, body: JSON.stringify(requestBody) },
    query: { pb_id: programBatchId },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getUserDetailsByUsername(
  action: ReturnType<typeof fetchUserByEportfolioUserName>,
  username: string | number,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${username}/user_details`,
    auth: false,
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}
