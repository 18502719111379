import * as types from './CompletedAssessments.types'

export const fetchCompletedQuizzes = () => ({
  type: types.FETCH_COMPLETED_QUIZZES as typeof types.FETCH_COMPLETED_QUIZZES,
})

export const fetchCompletedQuizzesSuccess = (payload: any) => ({
  type: types.FETCH_COMPLETED_QUIZZES_SUCCESS as typeof types.FETCH_COMPLETED_QUIZZES_SUCCESS,
  payload,
})

export const fetchCompletedQuizzesFailure = (payload: Error) => ({
  type: types.FETCH_COMPLETED_QUIZZES_FAILURE as typeof types.FETCH_COMPLETED_QUIZZES_FAILURE,
  payload,
})

export const fetchCompletedAssignments = () => ({
  type: types.FETCH_COMPLETED_ASSIGNMENTS as typeof types.FETCH_COMPLETED_ASSIGNMENTS,
})

export const fetchCompletedAssignmentsSuccess = (payload: any) => ({
  type: types.FETCH_COMPLETED_ASSIGNMENTS_SUCCESS as typeof types.FETCH_COMPLETED_ASSIGNMENTS_SUCCESS,
  payload,
})

export const fetchCompletedAssignmentsFailure = (payload: Error) => ({
  type: types.FETCH_COMPLETED_ASSIGNMENTS_FAILURE as typeof types.FETCH_COMPLETED_ASSIGNMENTS_FAILURE,
  payload,
})

export type CompletedAssessmentsTypes =
  | ReturnType<typeof fetchCompletedQuizzes>
  | ReturnType<typeof fetchCompletedQuizzesSuccess>
  | ReturnType<typeof fetchCompletedQuizzesFailure>
  | ReturnType<typeof fetchCompletedAssignments>
  | ReturnType<typeof fetchCompletedAssignmentsSuccess>
  | ReturnType<typeof fetchCompletedAssignmentsFailure>
