import {
  Badge,
  Box,
  Drawer,
  IconButton,
  Link,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Search, CloseOutlined } from '@material-ui/icons'
import HelpIcon from '@material-ui/icons/HelpOutline'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles } from '@material-ui/styles'
import cx from 'classnames'
import React, { Fragment, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { CourseID } from 'common/types/courses'
import { mixpanel } from 'common/utils/mixpanel'
import CommunityHeader from 'web/components/Header/Community'
import { isUserAcadOps } from 'common/utils'
import { UserKind } from 'common/types/alumni'
import { ALUMINI_BATCH } from 'common/utils/custom/community'
import Logo from '../../../common/images/logo.png'
import { AdmissionStatusData } from '../../../common/types/admission'
import { DashboardData } from '../../../common/types/dashboard'
import { ProgramData, ProgramID } from '../../../common/types/programs'
import { generateURL, getActiveProgramBatchId } from '../../../common/utils'
import { ProgramState } from '../../providers/Dashboard/ProgramsProvider'
import { UserDetailsState } from '../../providers/User/UserDetailsProvider'
import colors from '../../styles/colors'
import Notification from '../Utils/Icons/Notification'
import LinkButton from '../Utils/LinkButton'
import styles from './Header.module.css'
import { GlobalSearch } from '../GlobalSearch'
import { CoursesState } from '../../providers/Courses/CoursesProvider'
import { ProgramSwitch } from '../HeaderUtils'
import { UserID } from '../../../common/types/user'

const drawerWidth = 280

const useStyles = makeStyles((theme: Theme) => ({
  sectionDesktop: {
    display: 'none',
    flexGrow: 1,
    justifyContent: 'end',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
    marginLeft: 'auto',
  },
  pageLinks: {
    marginLeft: theme.spacing(5),
    alignItems: 'center',
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  button: {
    margin: theme.spacing(1),
    minWidth: 'auto',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: colors.grey60,
  },
  label: {
    textTransform: 'capitalize' as 'capitalize',
  },
  badge: {
    top: 12,
    right: -12,
  },
  icon: {
    width: '1em',
    height: '1em',
  },
  logo: {
    maxHeight: 36,
  },
  menuButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  search: {
    marginLeft: 'auto',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  searchDrawerPaper: {
    width: '100%',
  },
}))

export interface HeaderProps {
  onHelpClick: Function
  unreadAnnouncements: number
  dashboardData: DashboardData | undefined
  admissionData: AdmissionStatusData | undefined
  userAccessType: UserDetailsState['accessType']
  profileDetails: UserDetailsState['profile'] | undefined
  programs: ProgramState
  enrolledCourses: CourseID[]
  coursesData: CoursesState['data']['byId']
  onProgramChange: (programId: ProgramID) => void
  communityNotifications: number
  communityNewQuestions: number
  isActiveCommunityBatch: boolean
  exceleratePageActive: boolean
  currentUserId: UserID | null
  activeProgramData: ProgramData | null
  isNewProgramSupportEnabled: boolean
}

const Header = (props: HeaderProps) => {
  const [drawerToggle, setDrawerToggle] = useState(false)
  const [searchDrawerToggle, setSearchDrawerToggle] = useState(false)
  const isAcadOps = isUserAcadOps()

  const classes = useStyles()

  const { dashboardData, admissionData } = props

  const programsData = props.programs.data
  const isNotCareerSchool = !(programsData.byId[
    programsData!.activeProgramID || ''
  ]
    ? programsData.byId[programsData!.activeProgramID || '']!.program_type ===
      'career_track'
    : true)
  let supportLink: undefined | string
  let careerSupportLink: undefined | string
  let glConnectLink: undefined | string
  let peerReviewLink: undefined | string
  let careerSchoolJobsLink: undefined | string | null = null
  const basicUsers = props.userAccessType === 'basic'

  const isStanfordSDTProgram =
    props.activeProgramData && props.activeProgramData.stanford_sdt_program

  const enabledFreemiumUsers =
    props.userAccessType === 'freemium' &&
    dashboardData &&
    (('program_fee_paid' in dashboardData && dashboardData.program_fee_paid) ||
      ('trial_remaining_days' in dashboardData &&
        dashboardData.trial_remaining_days > 0))

  const enabledTrialUsers =
    props.userAccessType === 'trial' &&
    dashboardData &&
    'trial_details' in dashboardData &&
    !dashboardData.trial_details.access_blocked

  const trialUsers: boolean = props.userAccessType === 'trial'

  const allUsers = basicUsers || enabledFreemiumUsers || enabledTrialUsers

  const isUserFromBatch9027 = getActiveProgramBatchId() === 9027

  const disableDashboard =
    programsData.activeProgramID &&
    programsData.byId[programsData.activeProgramID] &&
    programsData.byId[programsData.activeProgramID]!.disable_dashboard

  const disableAnnouncements =
    programsData.activeProgramID &&
    programsData.byId[programsData.activeProgramID] &&
    programsData.byId[programsData.activeProgramID]!.disable_announcements

  const disableGrades =
    programsData.activeProgramID &&
    programsData.byId[programsData.activeProgramID] &&
    programsData.byId[programsData.activeProgramID]!.disable_grades

  if (dashboardData && dashboardData.feature_links) {
    if (dashboardData.feature_links.program_support) {
      supportLink = dashboardData.feature_links.program_support
    } else if (admissionData && admissionData.program_support_link) {
      supportLink = admissionData.program_support_link
    }
    careerSupportLink = dashboardData.feature_links.career_support

    if (!isNotCareerSchool && dashboardData.feature_links.support_email) {
      supportLink = `mailto:${dashboardData.feature_links.support_email}`
    }

    if (!isNotCareerSchool && dashboardData.feature_links.career_school_jobs) {
      careerSchoolJobsLink = dashboardData.feature_links.career_school_jobs
    }
    glConnectLink = dashboardData.feature_links.gl_connect
    peerReviewLink = dashboardData.feature_links.peer_reviews
  }

  if (programsData && props.isNewProgramSupportEnabled) {
    supportLink = `/program_support`
  }

  if (props.activeProgramData?.disable_program_support) {
    supportLink = undefined
  }

  const handleCsMixpanelEvent = (eventName: string) => {
    if (!isNotCareerSchool) {
      mixpanel.track(eventName, {})
    }
  }
  const helpOpen = () => {
    props.onHelpClick()
  }

  const handleDrawerToggle = () => {
    setDrawerToggle(!drawerToggle)
  }

  const handleSearchToggle = () => {
    setSearchDrawerToggle(!searchDrawerToggle)
  }

  const excelerateOrCareerPlusComp = (careerSupportUrl: string) => (
    <Link
      href={careerSupportUrl}
      className={cx(
        classes.button,
        classes.label,
        styles.label,
        props.exceleratePageActive && styles.activeLink
      )}
      rel="noreferrer"
      underline="none"
      onClick={() => {
        if (!careerSupportUrl.includes('digital-excelerate')) {
          mixpanel.track('Excelerate v2 header navigation button click', {})
        }
      }}
    >
      {careerSupportUrl.includes('digital-excelerate')
        ? 'CareerBoost'
        : 'Excelerate'}
    </Link>
  )

  const showConnectHeader = true
  const userKindMxProps =
    props.profileDetails &&
    props.profileDetails.data &&
    props.profileDetails.data.userKind &&
    props.profileDetails.data.userKind === UserKind.pgPotential
      ? { userKind: 'potential_state' }
      : {}

  const glConnectComp = () =>
    showConnectHeader ? (
      <Box>
        {programsData!.activeProgramID == ALUMINI_BATCH ? (
          <Link
            href={glConnectLink}
            className={cx(classes.button, classes.label, styles.label)}
            rel="noreferrer"
            underline="none"
            onClick={() => mixpanel.track('GLC Header Click', userKindMxProps)}
            target="_blank"
          >
            GL Connect
          </Link>
        ) : (
          <LinkButton
            onClick={() => mixpanel.track('GLC Header Click', userKindMxProps)}
            to={generateURL('GL_CONNECT', null)}
            className={cx(classes.button, classes.label, styles.label)}
            activeClassName={styles.activeLink}
          >
            GL Connect
          </LinkButton>
        )}
      </Box>
    ) : null

  const peerReviewComp = (isDrawer: boolean) => {
    return (
      <LinkButton
        onClick={() => {
          if (isDrawer) {
            handleDrawerToggle()
          }
          mixpanel.track('PeerReview Click', userKindMxProps)
        }}
        to={generateURL('PEER_REVIEW', null)}
        className={cx(classes.button, classes.label, styles.label)}
        activeClassName={styles.activeLink}
      >
        Gallery
        <span className={styles.badge}></span>
      </LinkButton>
    )
  }

  const links = (props: HeaderProps, isDrawer: boolean = false) => (
    <Fragment>
      {disableDashboard && !props.activeProgramData?.domain_name ? null : (
        <ProgramSwitch
          handleDrawerToggle={handleDrawerToggle}
          programsData={programsData}
          onProgramChange={props.onProgramChange}
          isDrawer={isDrawer}
        />
      )}

      {allUsers && !isUserFromBatch9027 && !disableDashboard ? (
        <LinkButton
          to={generateURL('DASHBOARD', null)}
          className={cx(classes.button, classes.label, styles.label)}
          key="Dashboard"
          activeClassName={styles.activeLink}
          disableRipple
          onClick={isDrawer ? handleDrawerToggle : () => {}}
        >
          Dashboard
        </LinkButton>
      ) : null}
      {(basicUsers || (isDrawer && !trialUsers)) &&
      isNotCareerSchool &&
      !isStanfordSDTProgram ? (
        <LinkButton
          to={generateURL('COURSES', null)}
          className={cx(classes.button, classes.label, styles.label)}
          activeClassName={props.exceleratePageActive ? '' : styles.activeLink}
          key="Courses"
          disableRipple
          onClick={isDrawer ? handleDrawerToggle : () => {}}
        >
          Courses
        </LinkButton>
      ) : null}

      {careerSchoolJobsLink && !isStanfordSDTProgram ? (
        <Link
          href={careerSchoolJobsLink}
          className={cx(classes.button, classes.label, styles.label)}
          rel="noreferrer"
          underline="none"
          onClick={() => handleCsMixpanelEvent('CS_Jobs_click')}
        >
          Jobs
        </Link>
      ) : null}

      {careerSupportLink &&
      isDrawer &&
      isNotCareerSchool &&
      !trialUsers &&
      !isStanfordSDTProgram
        ? excelerateOrCareerPlusComp(careerSupportLink)
        : null}

      {careerSupportLink &&
      !isDrawer &&
      isNotCareerSchool &&
      !trialUsers &&
      !isStanfordSDTProgram
        ? excelerateOrCareerPlusComp(careerSupportLink)
        : null}
      {glConnectLink && !isDrawer && !isStanfordSDTProgram
        ? glConnectComp()
        : null}
      {!isDrawer && peerReviewLink ? peerReviewComp(isDrawer) : null}

      {isDrawer &&
      (basicUsers || enabledTrialUsers) &&
      !isUserFromBatch9027 &&
      !isStanfordSDTProgram &&
      !trialUsers &&
      isNotCareerSchool ? (
        <Fragment>
          {!disableAnnouncements && (
            <LinkButton
              to={generateURL('ANNOUNCEMENTS', null)}
              className={cx(classes.button, classes.label, styles.label)}
              activeClassName={styles.activeLink}
              key="Announcements"
              disableRipple
              onClick={handleDrawerToggle}
            >
              <Badge
                badgeContent={props.unreadAnnouncements}
                classes={{ badge: classes.badge }}
              >
                <Typography>Announcements</Typography>
              </Badge>
            </LinkButton>
          )}
          {!disableGrades && (
            <LinkButton
              to={generateURL('GRADEBOOK', null)}
              className={cx(classes.button, classes.label, styles.label)}
              activeClassName={styles.activeLink}
              key="Gradebook"
              disableRipple
              onClick={isDrawer ? handleDrawerToggle : () => {}}
            >
              Gradebook
            </LinkButton>
          )}
          {dashboardData &&
          dashboardData.leaderboard_enabled &&
          !disableGrades ? (
            <LinkButton
              to={generateURL('LEADERBOARD', null)}
              className={cx(classes.button, classes.label, styles.label)}
              activeClassName={styles.activeLink}
              key="Leaderboard"
              disableRipple
              onClick={isDrawer ? handleDrawerToggle : () => {}}
            >
              Leaderboard
            </LinkButton>
          ) : null}
          {glConnectLink ? glConnectComp() : null}
        </Fragment>
      ) : null}
      {!trialUsers && isDrawer && supportLink && !isStanfordSDTProgram ? (
        props.isNewProgramSupportEnabled ? (
          <NavLink
            to={generateURL('PROGRAM_SUPPORT', null)}
            className={cx(classes.button, classes.label, styles.label)}
          >
            Support
          </NavLink>
        ) : (
          <Link
            href={supportLink}
            className={cx(classes.button, classes.label, styles.label)}
            rel="noreferrer"
            underline="none"
            onClick={() => handleCsMixpanelEvent('CS_Support_click')}
          >
            Support
          </Link>
        )
      ) : null}
      {isDrawer && peerReviewLink ? peerReviewComp(isDrawer) : null}
      {props.dashboardData &&
      props.dashboardData.hackathons_applicable &&
      isNotCareerSchool &&
      !isStanfordSDTProgram ? (
        <LinkButton
          to={generateURL('HACKATHONS', null)}
          className={cx(classes.button, classes.label, styles.label)}
          activeClassName={styles.activeLink}
          key="Hackathons"
          disableRipple
          onClick={isDrawer ? handleDrawerToggle : () => {}}
        >
          Hackathons
        </LinkButton>
      ) : null}
      {(basicUsers || enabledTrialUsers) &&
      programsData.activeProgramID &&
      dashboardData &&
      props.isActiveCommunityBatch &&
      !isStanfordSDTProgram ? (
        <CommunityHeader
          isDrawer={isDrawer}
          communityNewQuestions={props.communityNewQuestions}
          communityNotifications={props.communityNotifications}
        />
      ) : null}
    </Fragment>
  )

  const acadOpslinks = (props: HeaderProps, isDrawer: boolean = false) => (
    <ProgramSwitch
      handleDrawerToggle={handleDrawerToggle}
      programsData={programsData}
      onProgramChange={props.onProgramChange}
      isDrawer={isDrawer}
    />
  )

  const drawer = (
    <div className={styles.drawerContainer}>
      <div className={styles.drawerCloseIcon}>
        <CloseOutlined onClick={handleDrawerToggle} />
      </div>
      <div className={styles.drawerLinks}>
        {isAcadOps ? acadOpslinks(props, true) : links(props, true)}
      </div>
    </div>
  )

  const searchDrawer = (
    <div className={styles.drawerContainer}>
      <div className={styles.drawerCloseIcon}>
        <CloseOutlined onClick={handleSearchToggle} />
      </div>
      <GlobalSearch
        enrolledCourses={props.enrolledCourses}
        programName={
          props.activeProgramData
            ? props.activeProgramData!.display_short_name
            : ''
        }
        activeProgramData={props.activeProgramData}
        coursesData={props.coursesData}
        handleSearchToggle={handleSearchToggle}
      />
    </div>
  )

  const GLLogo = (
    <img
      className={classes.logo}
      src={
        (programsData.activeProgramID &&
          programsData.byId[programsData.activeProgramID] &&
          programsData.byId[programsData.activeProgramID]!.olympus_logo) ||
        Logo
      }
      alt="GreatLearning Logo"
    />
  )

  const showGlobalSearch =
    (basicUsers || enabledTrialUsers) &&
    !trialUsers &&
    props.activeProgramData &&
    dashboardData &&
    isNotCareerSchool &&
    !isStanfordSDTProgram

  return (
    <div className={styles.container}>
      {allUsers && !disableDashboard ? (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
      ) : null}

      <div>
        {disableDashboard ? (
          GLLogo
        ) : (
          <NavLink to={generateURL('ROOT', null)}>{GLLogo}</NavLink>
        )}
      </div>
      <div className={classes.pageLinks}>
        {isAcadOps ? acadOpslinks(props) : links(props)}
      </div>
      <div className={classes.sectionDesktop}>
        {showGlobalSearch ? (
          <>
            <div
              className={isAcadOps ? styles.widerSearchBar : styles.searchBar}
            >
              <GlobalSearch
                enrolledCourses={props.enrolledCourses}
                programName={props.activeProgramData!.display_short_name}
                coursesData={props.coursesData}
                activeProgramData={props.activeProgramData}
              />
            </div>
            <Drawer
              variant="temporary"
              open={searchDrawerToggle}
              onClose={handleSearchToggle}
              classes={{
                paper: classes.searchDrawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {searchDrawer}
            </Drawer>
          </>
        ) : null}
        {!trialUsers && supportLink && !isAcadOps ? (
          props.isNewProgramSupportEnabled ? (
            <NavLink to={generateURL('PROGRAM_SUPPORT', null)}>
              <Tooltip title="Support">
                <div>
                  <IconButton className={styles.helpIcon} onClick={helpOpen}>
                    <HelpIcon className={classes.icon} />
                  </IconButton>
                </div>
              </Tooltip>
            </NavLink>
          ) : (
            <Link
              href={supportLink}
              rel="noreferrer"
              underline="none"
              onClick={() => handleCsMixpanelEvent('CS_Support_click')}
            >
              <Tooltip title="Support">
                <div>
                  <IconButton className={styles.helpIcon} onClick={helpOpen}>
                    <HelpIcon className={classes.icon} />
                  </IconButton>
                </div>
              </Tooltip>
            </Link>
          )
        ) : null}
        {basicUsers &&
        !isUserFromBatch9027 &&
        !disableAnnouncements &&
        isNotCareerSchool &&
        !isAcadOps ? (
          <NavLink
            className={styles.notificationIconWrapper}
            to={generateURL('ANNOUNCEMENTS', null)}
          >
            <Tooltip title="Announcements">
              <div>
                <Notification count={props.unreadAnnouncements} />
              </div>
            </Tooltip>
          </NavLink>
        ) : null}
        {isAcadOps ? null : (
          <Drawer
            variant="temporary"
            open={drawerToggle}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        )}
      </div>
      {showGlobalSearch ? (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleSearchToggle}
          className={classes.search}
        >
          <Search />
        </IconButton>
      ) : null}
    </div>
  )
}

Header.defaultProps = {
  onHelpClick: () => null,
  onNotificationsClick: () => null,
}

export default Header
