import * as types from './ProgramSupportFaqs.types'

interface fetchFaqsParams {
  keyword?: string
  course_name?: string
  contextType?: string[]
}

interface fetchIndividualFaqAndRecommendationsParams {
  faqId: number
}

interface newFaqParams {
  question: string
  answer: string
  programCategoryCode: string
  courseName: string
  contentName: string
  contextType: string
}

interface editFaqParams extends newFaqParams {
  id: string | number
}

export const fetchFaqs = (payload: fetchFaqsParams) => ({
  type: types.FETCH_FAQS as typeof types.FETCH_FAQS,
  payload,
})

export const fetchNinjaFaqs = (payload: fetchFaqsParams) => ({
  type: types.FETCH_NINJA_FAQS as typeof types.FETCH_NINJA_FAQS,
  payload,
})

export const fetchIndividualFaqAndRecommendations = (
  payload: fetchIndividualFaqAndRecommendationsParams
) => ({
  type: types.FETCH_INDIVIDUAL_FAQ_AND_RECOMMENDATIONS as typeof types.FETCH_INDIVIDUAL_FAQ_AND_RECOMMENDATIONS,
  payload,
})

export const fetchFaqsSuccess = (payload: any) => ({
  type: types.FETCH_FAQS_SUCCESS as typeof types.FETCH_FAQS_SUCCESS,
  payload,
})

export const fetchFaqsFailure = (payload: Error) => ({
  type: types.FETCH_FAQS_FAILURE as typeof types.FETCH_FAQS_FAILURE,
  payload,
})

export const fetchIndividualFaqAndRecommendationsCancel = (payload: any) => ({
  type: types.FETCH_INDIVIDUAL_FAQ_AND_RECOMMENDATIONS_CANCEL as typeof types.FETCH_INDIVIDUAL_FAQ_AND_RECOMMENDATIONS_CANCEL,
  payload,
})

export const fetchFaqsCancel = (payload: any) => ({
  type: types.FETCH_FAQS_CANCEL as typeof types.FETCH_FAQS_CANCEL,
  payload,
})

export const updateFaqViewCount = (payload: { id: string; title: string }) => ({
  type: types.UPDATE_FAQ_VIEW_COUNT as typeof types.UPDATE_FAQ_VIEW_COUNT,
  payload,
})

export const updateFaqViewCountSuccess = (payload: any) => ({
  type: types.UPDATE_FAQ_VIEW_COUNT_SUCCESS as typeof types.UPDATE_FAQ_VIEW_COUNT_SUCCESS,
  payload,
})

export const updateFaqViewCountFailure = (payload: Error) => ({
  type: types.UPDATE_FAQ_VIEW_COUNT_FAILURE as typeof types.UPDATE_FAQ_VIEW_COUNT_FAILURE,
  payload,
})

export const updateFaqViewCountCancel = (payload: any) => ({
  type: types.UPDATE_FAQ_VIEW_COUNT_CANCEL as typeof types.UPDATE_FAQ_VIEW_COUNT_CANCEL,
  payload,
})

export const updateFaqResponseCount = (payload: {
  id: string
  content: string
}) => ({
  type: types.UPDATE_FAQ_RESPONSE_COUNT as typeof types.UPDATE_FAQ_RESPONSE_COUNT,
  payload,
})

export const updateFaqResponseCountSuccess = (payload: any) => ({
  type: types.UPDATE_FAQ_RESPONSE_COUNT_SUCCESS as typeof types.UPDATE_FAQ_RESPONSE_COUNT_SUCCESS,
  payload,
})

export const updateFaqResponseCountFailure = (payload: Error) => ({
  type: types.UPDATE_FAQ_RESPONSE_COUNT_FAILURE as typeof types.UPDATE_FAQ_RESPONSE_COUNT_FAILURE,
  payload,
})

export const updateFaqResponseCountCancel = (payload: any) => ({
  type: types.UPDATE_FAQ_RESPONSE_COUNT_CANCEL as typeof types.UPDATE_FAQ_RESPONSE_COUNT_CANCEL,
  payload,
})

export const addNewFaq = (payload: newFaqParams) => ({
  type: types.ADD_NEW_FAQ as typeof types.ADD_NEW_FAQ,
  payload,
})

export const addNewFaqSuccess = (payload: any) => ({
  type: types.ADD_NEW_FAQ_SUCCESS as typeof types.ADD_NEW_FAQ_SUCCESS,
  payload,
})

export const addNewFaqFailure = (payload: Error) => ({
  type: types.ADD_NEW_FAQ_FAILURE as typeof types.ADD_NEW_FAQ_FAILURE,
  payload,
})

export const addNewFaqCancel = (payload: any) => ({
  type: types.ADD_NEW_FAQ_CANCEL as typeof types.ADD_NEW_FAQ_CANCEL,
  payload,
})

export const editFaq = (payload: editFaqParams) => ({
  type: types.EDIT_FAQ as typeof types.EDIT_FAQ,
  payload,
})

export const editFaqSuccess = (payload: any) => ({
  type: types.EDIT_FAQ_SUCCESS as typeof types.EDIT_FAQ_SUCCESS,
  payload,
})

export const editFaqFailure = (payload: Error) => ({
  type: types.EDIT_FAQ_FAILURE as typeof types.EDIT_FAQ_FAILURE,
  payload,
})

export const editFaqCancel = (payload: any) => ({
  type: types.EDIT_FAQ_CANCEL as typeof types.EDIT_FAQ_CANCEL,
  payload,
})

export const deleteFaq = (payload: string | number) => ({
  type: types.DELETE_FAQ as typeof types.DELETE_FAQ,
  payload,
})

export const deleteFaqSuccess = (payload: any) => ({
  type: types.DELETE_FAQ_SUCCESS as typeof types.DELETE_FAQ_SUCCESS,
  payload,
})

export const deleteFaqFailure = (payload: Error) => ({
  type: types.DELETE_FAQ_FAILURE as typeof types.DELETE_FAQ_FAILURE,
  payload,
})

export const deleteFaqCancel = (payload: any) => ({
  type: types.DELETE_FAQ_CANCEL as typeof types.DELETE_FAQ_CANCEL,
  payload,
})

export type ProgramSupportFaqsTypes =
  | ReturnType<typeof fetchFaqs>
  | ReturnType<typeof fetchNinjaFaqs>
  | ReturnType<typeof fetchIndividualFaqAndRecommendations>
  | ReturnType<typeof fetchFaqsSuccess>
  | ReturnType<typeof fetchFaqsFailure>
  | ReturnType<typeof fetchFaqsCancel>
  | ReturnType<typeof fetchIndividualFaqAndRecommendationsCancel>
  | ReturnType<typeof updateFaqViewCount>
  | ReturnType<typeof updateFaqViewCountSuccess>
  | ReturnType<typeof updateFaqViewCountFailure>
  | ReturnType<typeof updateFaqViewCountCancel>
  | ReturnType<typeof updateFaqResponseCount>
  | ReturnType<typeof updateFaqResponseCountSuccess>
  | ReturnType<typeof updateFaqResponseCountFailure>
  | ReturnType<typeof updateFaqResponseCountCancel>
  | ReturnType<typeof addNewFaq>
  | ReturnType<typeof addNewFaqSuccess>
  | ReturnType<typeof addNewFaqFailure>
  | ReturnType<typeof addNewFaqCancel>
  | ReturnType<typeof editFaq>
  | ReturnType<typeof editFaqSuccess>
  | ReturnType<typeof editFaqFailure>
  | ReturnType<typeof editFaqCancel>
  | ReturnType<typeof deleteFaq>
  | ReturnType<typeof deleteFaqSuccess>
  | ReturnType<typeof deleteFaqFailure>
  | ReturnType<typeof deleteFaqCancel>
