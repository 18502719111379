import { UserID } from '../types/user'
import { getCurrentReferrer, getAppSource } from './index'

declare let mixpanel: any

const tracker = {
  track: (event: string, data: object) => {
    if ('mixpanel' in window)
      mixpanel.track(event, {
        ...data,
        ui: 'aspire',
        $referrer: getCurrentReferrer(),
        page_title: document.title,
        source: getAppSource(),
      })
  },

  track_links: (selector: string, event: string, data: object) => {
    if ('mixpanel' in window)
      mixpanel.track_links(selector, event, {
        ...data,
        ui: 'aspire',
        $referrer: getCurrentReferrer(),
        page_title: document.title,
      })
  },

  identify: (id: UserID) => {
    if ('mixpanel' in window) mixpanel.identify(id)
  },

  register: (data: object) => {
    if ('mixpanel' in window) mixpanel.register(data)
  },

  people: {
    set: (data: object) => {
      if ('mixpanel' in window) mixpanel.people.set(data)
    },
    union: (data: object) => {
      if ('mixpanel' in window) mixpanel.people.union(data)
    },
  },
}

export { tracker as mixpanel }
