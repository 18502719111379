import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { cancelable } from '../../../../common/utils'
import {
  fetchCourseDetails,
  fetchCourseDetailsFailure,
  fetchCourseDetailsSuccess,
  fetchCourseListFailure,
  fetchCourseListSuccess,
  fetchCourseTabsSuccess,
  fetchCourseTabsFailure,
  submitCourseRating,
  submitCourseRatingSuccess,
  submitCourseRatingFailure,
  fetchCourseList,
} from './Courses.actions'
import * as APIs from './Courses.api'
import * as types from './Courses.types'
import { fetchCourseTabs } from '.'
import { showAlertMessage } from '../../AlertsProvider'
import { getOuctomeDetailsMiddleware } from './OutcomesProvider'

function* getCourseList({ filters }: ReturnType<typeof fetchCourseList>) {
  const abortController = new AbortController()
  try {
    const data = yield call(APIs.getCourseListAPI, {
      filters,
      signal: abortController.signal,
    })
    yield put(fetchCourseListSuccess(data))
  } catch (e) {
    yield put(fetchCourseListFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* getCourseDetails({
  payload,
  meta,
}: ReturnType<typeof fetchCourseDetails>) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.getCourseDetailsAPI,
      payload,
      abortController.signal
    )
    yield put(fetchCourseDetailsSuccess(data, meta))
  } catch (e) {
    yield put(fetchCourseDetailsFailure(e, meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* getCourseTabs({ meta }: ReturnType<typeof fetchCourseTabs>) {
  const abortController = new AbortController()
  try {
    const data = yield call(APIs.getCourseTabs, { courseId: meta.courseId })
    yield put(fetchCourseTabsSuccess(data, meta))
  } catch (e) {
    yield put(fetchCourseTabsFailure(e, meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitUserRating(action: ReturnType<typeof submitCourseRating>) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.submitCourseRating,
      action.meta.courseId,
      action.payload,
      abortController.signal
    )

    yield put(submitCourseRatingSuccess(action.payload, action.meta))
    yield put(
      showAlertMessage({
        variant: 'success',
        message: data.message,
      })
    )
  } catch (e) {
    yield put(submitCourseRatingFailure(e, action.meta))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Course rating submission failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* coursesMiddleware() {
  yield takeLatest(
    types.COURSE_LIST_FETCH,
    cancelable(getCourseList, types.COURSE_LIST_FETCH_CANCEL)
  )

  yield takeLatest(
    types.COURSE_DETAILS_FETCH,
    cancelable(getCourseDetails, types.COURSE_DETAILS_FETCH)
  )
  yield takeLatest(types.COURSE_TABS_FETCH, getCourseTabs)

  yield takeLatest(
    types.COURSE_RATING_SUBMIT,
    cancelable(submitUserRating, types.COURSE_RATING_SUBMIT)
  )
}

export default ([] as any).concat(
  coursesMiddleware(),
  ...getOuctomeDetailsMiddleware
)
