import { Reducer } from 'redux'
import { EportfolioActionTypes } from './Eportfolio.actions'
import { EportfolioData } from '../../../common/types/eportfolio'

import {
  FETCH_EPORTFOLIO,
  FETCH_EPORTFOLIO_SUCCESS,
  FETCH_EPORTFOLIO_FAILURE,
  TOGGLE_PROJECT_VISIBILITY,
  TOGGLE_PROJECT_VISIBILITY_SUCCESS,
  TOGGLE_PROJECT_VISIBILITY_FAILURE,
  UPDATE_PROJECT_LEARNINGS,
  UPDATE_PROJECT_LEARNINGS_SUCCESS,
} from './Eportfolio.types'

export interface EportfolioState {
  eportfolioData?: EportfolioData
  loading: boolean
  error: false | Error | Response
}

const initialState: EportfolioState = {
  loading: false,
  error: false,
}

const updateProjectVisibility = (
  eportfolioData: EportfolioData | undefined,
  projectId: string | number
) => {
  if (
    eportfolioData &&
    eportfolioData.projects &&
    (eportfolioData.projects.projects || eportfolioData.projects.capstone)
  ) {
    const eportfolioProjects = eportfolioData.projects.projects

    const foundProject = eportfolioProjects.find(element => {
      return element.project_id === projectId
    })

    if (foundProject) {
      foundProject.visibility = !foundProject.visibility
    } else {
      const eportfolioCapstone = eportfolioData.projects.capstone
      const foundCapstoneProject = eportfolioCapstone.find(element => {
        return element.project_id === projectId
      })

      if (foundCapstoneProject) {
        foundCapstoneProject.visibility = !foundCapstoneProject.visibility
      }
    }
  }
  return eportfolioData
}

const updateProjectLearnings = (
  eportfolioData: EportfolioData | undefined,
  projectId: string | number,
  learnings: string
) => {
  if (
    eportfolioData &&
    eportfolioData.projects &&
    eportfolioData.projects.projects
  ) {
    const eportfolioProjects = eportfolioData.projects.projects

    const foundProject = eportfolioProjects.find(element => {
      return element.project_id === projectId
    })

    if (foundProject) {
      foundProject.learnings = learnings
    }
  }
  return eportfolioData
}

const EportfolioReducer: Reducer<EportfolioState, EportfolioActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_EPORTFOLIO:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case FETCH_EPORTFOLIO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        eportfolioData: action.payload,
      }
    case FETCH_EPORTFOLIO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case TOGGLE_PROJECT_VISIBILITY:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case TOGGLE_PROJECT_VISIBILITY_SUCCESS:
      const payload = updateProjectVisibility(
        state.eportfolioData,
        action.projectId
      )

      return {
        ...state,
        loading: false,
        error: false,
        eportfolioData: payload,
      }
    case TOGGLE_PROJECT_VISIBILITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UPDATE_PROJECT_LEARNINGS:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case UPDATE_PROJECT_LEARNINGS_SUCCESS:
      const eportfolioPayload = updateProjectLearnings(
        state.eportfolioData,
        action.projectId,
        action.learnings
      )

      return {
        ...state,
        loading: false,
        error: false,
        eportfolioData: eportfolioPayload,
      }
    default:
      return state
  }
}

export default EportfolioReducer
