import { Reducer } from 'redux'
import { LeaderboardActions } from 'web/providers/Dashboard/LeaderboardProvider/Leaderboard.actions'
import {
  ProgramActions,
  UPDATE_ACTIVE_PROGRAM,
} from 'web/providers/Dashboard/ProgramsProvider'
import { UserDetailsActions } from './UserDetails.actions'
import * as types from './UserDetails.types'
import * as userLeaderboardAnonimityTypes from '../../Dashboard/LeaderboardProvider/Leaderboard.types'
import {
  UserID,
  UserProfileData,
  UserCommunicationData,
  UserAccessType,
  AppSourceType,
  ResourceAccessType,
} from '../../../../common/types/user'
import { UPDATE_PROFESSIONAL_DETAILS_SUCCESS } from '../UserSettingsProvider/UserSettings.types'
import { UserSettingsAction } from '../UserSettingsProvider/UserSettings.actions'

export interface LeaderboardAnonimityApiState {
  loading: boolean
  error: Error | boolean
}
export interface UserDetailsState {
  id: UserID | null
  accessType: UserAccessType | null
  masquerading: boolean
  profile?: {
    data: null | UserProfileData
    loading: boolean
    error: Error | boolean
    leaderboardAnonimity?: LeaderboardAnonimityApiState
  }
  auth: { loading: boolean; error: false | Error | Response } & (
    | { isAuthenticated: false }
    | { isAuthenticated: true; accessToken: string }
  )
  communicationDetails?: {
    data: null | UserCommunicationData[]
    loading: boolean
    error: Error | boolean
  }
  appSource: AppSourceType | null
  resourceAccess: ResourceAccessType
  learnAndDevUser: boolean
  universityDetails: {
    name: string
    redirect_url: string
  } | null
  isExcelerateCoursePage: boolean
}

const initialProfileState: UserDetailsState['profile'] = {
  data: null,
  loading: false,
  error: false,
}

const isEmailVerified = (state: UserDetailsState) => {
  const loginId =
    state.profile && state.profile.data
      ? state.profile.data.primary_email
      : undefined
  const communicationData = state.communicationDetails
    ? state.communicationDetails.data
    : undefined
  if (loginId && communicationData) {
    const record = communicationData.find((obj: any) => obj.address === loginId)
    if (record) return record.workflow_state || null
    return null
  }
  return null
}

const userProfileReducer: Reducer<
  UserDetailsState['profile'],
  UserDetailsActions | LeaderboardActions
> = (state = initialProfileState, action): UserDetailsState['profile'] => {
  switch (action.type) {
    case types.USER_PROFILE_DETAILS_FETCH:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case types.USER_PROFILE_DETAILS_FETCH_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        loading: false,
        error: false,
      }

    case types.USER_PROFILE_DETAILS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case types.USER_PROFILE_DETAILS_FETCH_CANCEL:
      return {
        ...state,
        loading: false,
      }

    case types.RESEND_EMAIL_LINK:
      return {
        ...state,
        loading: true,
      }

    case types.RESEND_EMAIL_LINK_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          resentLinkSuccess: true,
        } as UserProfileData,
        loading: false,
        error: false,
      }
    }

    case types.RESEND_EMAIL_LINK_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          resentLinkSuccess: false,
        } as UserProfileData,
        loading: false,
        error: action.payload,
      }

    case types.RESEND_EMAIL_LINK_CANCEL:
      return {
        ...state,
        loading: false,
      }

    case userLeaderboardAnonimityTypes.USER_LEADERBOARD_ANONYMITY_UPDATE:
      return {
        ...state,
        leaderboardAnonimity: {
          loading: true,
          error: false,
        },
      }

    case userLeaderboardAnonimityTypes.USER_LEADERBOARD_ANONYMITY_UPDATE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          leader_board_anonymity: action.payload.leader_board_anonymity,
        } as UserProfileData,
        leaderboardAnonimity: {
          loading: false,
          error: false,
        },
      }

    case userLeaderboardAnonimityTypes.USER_LEADERBOARD_ANONYMITY_UPDATE_FAILURE:
      return {
        ...state,
        leaderboardAnonimity: {
          loading: false,
          error: action.payload,
        },
      }

    default:
      return state
  }
}

const initialCommunicationDetailsState: UserDetailsState['communicationDetails'] = {
  data: null,
  loading: false,
  error: false,
}

const communincationDetailsReducer: Reducer<
  UserDetailsState['communicationDetails'],
  UserDetailsActions
> = (
  state = initialCommunicationDetailsState,
  action
): UserDetailsState['communicationDetails'] => {
    switch (action.type) {
      case types.FETCH_COMMUNICATION_CHANNEL_DETAILS:
        return {
          ...state,
          // loading: true,
          error: false,
        }

      case types.FETCH_COMMUNICATION_CHANNEL_DETAILS_SUCCESS:
        return {
          ...state,
          data: [...action.payload],
          loading: false,
          error: false,
        }

      case types.FETCH_COMMUNICATION_CHANNEL_DETAILS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        }

      case types.FETCH_COMMUNICATION_CHANNEL_DETAILS_CANCEL:
        return {
          ...state,
          loading: false,
        }

      default:
        return state
    }
  }
const initialState: UserDetailsState = {
  id: null,
  accessType: null,
  masquerading: false,
  auth: {
    isAuthenticated: false,
    loading: false,
    error: false,
  },
  appSource: null,
  resourceAccess: null,
  learnAndDevUser: false,
  universityDetails: null,
}

const userAuthReducer: Reducer<UserDetailsState['auth'], UserDetailsActions> = (
  state = initialState.auth,
  action
): UserDetailsState['auth'] => {
  switch (action.type) {
    case types.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        accessToken: action.payload.accessToken,
      }
    case types.USER_LOGOUT:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case types.USER_LOGOUT_SUCCESS: {
      const newState = { ...state }
      if ('accessToken' in newState) {
        delete newState.accessToken
      }
      return {
        ...newState,
        loading: false,
        isAuthenticated: false,
      }
    }
    case types.USER_LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

const userDetailsReducer: Reducer<
  UserDetailsState,
  | UserDetailsActions
  | UserSettingsAction
  | LeaderboardActions
  | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
> = (state = initialState, action): UserDetailsState => {
  switch (action.type) {
    case types.UPDATE_USER_DETAILS:
      return {
        ...state,
        ...action.payload,
      }
    case types.USER_PROFILE_DETAILS_FETCH:
    case types.USER_PROFILE_DETAILS_FETCH_SUCCESS:
    case types.USER_PROFILE_DETAILS_FETCH_FAILURE:
    case types.USER_PROFILE_DETAILS_FETCH_CANCEL:
    case types.RESEND_EMAIL_LINK:
    case types.RESEND_EMAIL_LINK_SUCCESS:
    case types.RESEND_EMAIL_LINK_FAILURE:
    case types.RESEND_EMAIL_LINK_CANCEL:
    case userLeaderboardAnonimityTypes.USER_LEADERBOARD_ANONYMITY_UPDATE:
    case userLeaderboardAnonimityTypes.USER_LEADERBOARD_ANONYMITY_UPDATE_SUCCESS:
    case userLeaderboardAnonimityTypes.USER_LEADERBOARD_ANONYMITY_UPDATE_FAILURE:
    case userLeaderboardAnonimityTypes.USER_LEADERBOARD_ANONYMITY_UPDATE_CANCEL:
      return {
        ...state,
        profile: userProfileReducer(state.profile, action),
      }
    case types.USER_LOGOUT:
    case types.USER_LOGOUT_SUCCESS:
    case types.USER_LOGOUT_FAILURE:
    case types.USER_LOGIN_SUCCESS:
      return {
        ...state,
        auth: userAuthReducer(state.auth, action),
      }

    case types.FETCH_COMMUNICATION_CHANNEL_DETAILS:
    case types.FETCH_COMMUNICATION_CHANNEL_DETAILS_SUCCESS:
    case types.FETCH_COMMUNICATION_CHANNEL_DETAILS_FAILURE:
    case types.FETCH_COMMUNICATION_CHANNEL_DETAILS_CANCEL:
      return {
        ...state,
        communicationDetails: communincationDetailsReducer(
          state.communicationDetails,
          action
        ),
      }
    case types.UPDATE_EMAIL_VERIFIED_STATUS: {
      const workflowState = isEmailVerified(state)
      return {
        ...state,
        profile: {
          ...initialProfileState,
          ...state.profile,
          data: {
            ...(state.profile ? state.profile.data : {}),
            emailVerified: workflowState,
            resentLinkSuccess: false,
          } as UserProfileData,
        },
      }
    }

    case UPDATE_ACTIVE_PROGRAM:
      return {
        ...state,
        accessType: null,
      }
    case UPDATE_PROFESSIONAL_DETAILS_SUCCESS:
      return {
        ...state,
        profile: {
          ...initialProfileState,
          ...state.profile,

          data: {
            ...(state.profile ? state.profile.data : {}),
            linkedin_profile_url: action.payload.linkedIn_url,
          } as UserProfileData,
        },
      }

    default:
      return state
  }
}

export default userDetailsReducer
