import { Reducer } from 'redux'
import { FeedbackActionTypes } from './Feedback.actions'
import { FeedbackData } from '../../../common/types/feedback'
import {
  SHOW_FEEDBACK_POPUP,
  REMOVE_FEEDBACK_POPUP,
  FETCH_FEEDBACK_CONTENT,
  FETCH_FEEDBACK_CONTENT_SUCCESS,
  FETCH_FEEDBACK_CONTENT_FAILURE,
  FETCH_FEEDBACK_CONTENT_CANCEL,
  SUBMIT_COURSE_FEEDBACK,
  SUBMIT_COURSE_FEEDBACK_SUCCESS,
  SUBMIT_COURSE_FEEDBACK_FAILURE,
  SUBMIT_COURSE_FEEDBACK_CANCEL,
  CLEAR_FEEDBACK_SUBMITTED_STATUS,
  SUBMIT_PROGRAM_FEEDBACK_SUCCESS,
  SUBMIT_PROGRAM_FEEDBACK,
  SUBMIT_PROGRAM_FEEDBACK_FAILURE,
  SUBMIT_PROGRAM_FEEDBACK_CANCEL,
  SUBMIT_CAPSTONE_LEGACY_FEEDBACK,
  SUBMIT_CAPSTONE_LEGACY_FEEDBACK_SUCCESS,
  SUBMIT_CAPSTONE_LEGACY_FEEDBACK_FAILURE,
  SUBMIT_CAPSTONE_LEGACY_FEEDBACK_CANCEL,
  SUBMIT_CAPSTONE_SURVEY_FEEDBACK,
  SUBMIT_CAPSTONE_SURVEY_FEEDBACK_SUCCESS,
  SUBMIT_CAPSTONE_SURVEY_FEEDBACK_FAILURE,
  SUBMIT_CAPSTONE_SURVEY_FEEDBACK_CANCEL,
  MARK_MISSED_RESIDENCY_SESSION,
  MARK_MISSED_RESIDENCY_SESSION_FAILURE,
  MARK_MISSED_RESIDENCY_SESSION_CANCEL,
  MARK_MISSED_RESIDENCY_SESSION_SUCCESS,
  UPDATE_FEEDBACK_DATA,
  SKIP_SESSION_FEEDBACK_SUCCESS,
  SKIP_SESSION_FEEDBACK,
  SKIP_SESSION_FEEDBACK_CANCEL,
  SKIP_SESSION_FEEDBACK_FAILURE,
} from './Feedback.types'

export interface FeedbackState {
  isOpen: boolean
  data?: FeedbackData
  loading: boolean
  error: false | Error | Response
  status?: 'success' | 'skipped' | 'error' | null
  message?: string
  showReferral?: boolean
  showGiftCourse?: boolean
}

const intialStateFeedback = {
  isOpen: false,
  loading: false,
  error: false as false,
}

const normalizeContent = (state: FeedbackState, data: any) => {
  if (state.data && state.data.feedback_type === 'program_feedback') {
    if (data.questions) {
      const resultData = { ...data }
      resultData.questions.sort((a: any, b: any) => a.order - b.order)
      return resultData
    }
    return data
  }
  return data
}

const feedbackReducer: Reducer<FeedbackState, FeedbackActionTypes> = (
  state = intialStateFeedback,
  action
): FeedbackState => {
  switch (action.type) {
    case UPDATE_FEEDBACK_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      }

    case SHOW_FEEDBACK_POPUP:
      return {
        ...state,
        isOpen: true,
      }

    case REMOVE_FEEDBACK_POPUP:
      return {
        ...state,
        isOpen: false,
      }

    case FETCH_FEEDBACK_CONTENT:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case FETCH_FEEDBACK_CONTENT_SUCCESS: {
      const resultContent = normalizeContent(state, action.payload)
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          content: {
            ...resultContent,
          },
        },
      }
    }

    case FETCH_FEEDBACK_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case SKIP_SESSION_FEEDBACK_CANCEL:
    case FETCH_FEEDBACK_CONTENT_CANCEL:
      return {
        ...state,
        loading: false,
      }

    case SKIP_SESSION_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isOpen: false,
      }

    case SKIP_SESSION_FEEDBACK:
    case MARK_MISSED_RESIDENCY_SESSION:
    case SUBMIT_COURSE_FEEDBACK:
    case SUBMIT_PROGRAM_FEEDBACK:
    case SUBMIT_CAPSTONE_LEGACY_FEEDBACK:
    case SUBMIT_CAPSTONE_SURVEY_FEEDBACK:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case MARK_MISSED_RESIDENCY_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 'skipped',
      }

    case SUBMIT_COURSE_FEEDBACK_SUCCESS:
    case SUBMIT_PROGRAM_FEEDBACK_SUCCESS:
    case SUBMIT_CAPSTONE_LEGACY_FEEDBACK_SUCCESS:
    case SUBMIT_CAPSTONE_SURVEY_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        message: action.payload.message,
        showReferral: action.payload.show_referral,
        showGiftCourse: action.payload.show_gift_course,
      }

    case MARK_MISSED_RESIDENCY_SESSION_FAILURE:
    case SUBMIT_COURSE_FEEDBACK_FAILURE:
    case SUBMIT_PROGRAM_FEEDBACK_FAILURE:
    case SUBMIT_CAPSTONE_LEGACY_FEEDBACK_FAILURE:
    case SUBMIT_CAPSTONE_SURVEY_FEEDBACK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case SKIP_SESSION_FEEDBACK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isOpen: false,
      }

    case MARK_MISSED_RESIDENCY_SESSION_CANCEL:
    case SUBMIT_COURSE_FEEDBACK_CANCEL:
    case SUBMIT_PROGRAM_FEEDBACK_CANCEL:
    case SUBMIT_CAPSTONE_LEGACY_FEEDBACK_CANCEL:
    case SUBMIT_CAPSTONE_SURVEY_FEEDBACK_CANCEL:
      return {
        ...state,
        loading: false,
      }

    case CLEAR_FEEDBACK_SUBMITTED_STATUS:
      return {
        ...state,
        status: null,
      }

    default:
      return state
  }
}

export default feedbackReducer
