import {
  fetchUserCourseGroups,
  fetchGroupMembers,
  fetchGroupFiles,
  fetchGroupFilesQuota,
  deleteGroupFile,
} from './UserGroups.actions'
import { apiCall } from '../../../../common/utils'

const PAGE_SIZE = 20

export async function fetchUserGroupsAPI(signal: AbortSignal) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/users/self/groups`,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function fetchUserCourseGroupsAPI(
  action: ReturnType<typeof fetchUserCourseGroups>,
  signal: AbortSignal
) {
  const query = {
    only_own_groups: true,
  }
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${action.payload.courseId}/groups`,
    query,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function fetchGroupMembersAPI(
  action: ReturnType<typeof fetchGroupMembers>,
  signal: AbortSignal
) {
  const { groupId } = action.payload

  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/groups/${groupId}/users`,
    params: { signal },
    query: { include: ['avatar_url'], page: 1, per_page: 50 },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function fetchGroupFilesAPI(
  action: ReturnType<typeof fetchGroupFiles>,
  signal: AbortSignal
) {
  const { groupId } = action.payload

  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/groups/${groupId}/files`,
    params: { signal },
    query: {
      include: ['user'],
      sort: 'created_at',
      order: 'desc',
      page: action.payload.page,
      per_page: PAGE_SIZE,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
export async function fetchGroupFilesQuotaAPI(
  action: ReturnType<typeof fetchGroupFilesQuota>,
  signal: AbortSignal
) {
  const { groupId } = action.payload

  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/groups/${groupId}/files/quota`,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
export async function deleteGroupFileHandlerAPI(
  action: ReturnType<typeof deleteGroupFile>,
  signal: AbortSignal
) {
  const { fileId } = action.payload

  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/files/${fileId}`,
    params: { signal, method: 'DELETE' },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
