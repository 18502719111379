import { Reducer } from 'redux'
import {
  PageModuleItemData,
  VideoDetails,
} from '../../../../../common/types/courses/page'
import { WikiPageActions } from './WikiPage.actions'
import * as types from './WikiPage.types'

type WikiPageState = PageModuleItemData | null

const wikiPageReducer: Reducer<WikiPageState, WikiPageActions> = (
  state = null,
  action
): WikiPageState => {
  if (!state) {
    return state
  }
  switch (action.type) {
    case types.UPDATE_VIDEO_WATCHED_PERCENTAGE: {
      if (!state.contentMeta) {
        return state
      }
      return {
        ...state,
        contentMeta: {
          ...state.contentMeta,
          percentage_watched: action.payload.percentage,
        },
        // eslint-disable-next-line no-nested-ternary
        ...(state.completion_requirement
          ? action.payload.requirement_met &&
            !state.completion_requirement.completed
            ? {
                completion_requirement: {
                  ...state.completion_requirement,
                  completed: action.payload.requirement_met,
                },
              }
            : undefined
          : undefined),
      }
    }
    case types.FETCH_VIDEO_DETAILS: {
      return {
        ...state,
        itemActivity: {
          ...state.itemActivity,
          error: false,
          loading: true,
        },
      }
    }
    case types.FETCH_VIDEO_DETAILS_SUCCESS: {
      return {
        ...state,
        itemActivity: {
          ...state.itemActivity,
          error: false,
          loading: false,
        },
        itemContent: {
          ...state.itemContent,
          videoDetails: {
            ...state.itemContent.videoDetails,
            ...(action.payload as VideoDetails),
          },
        },
      }
    }
    case types.FETCH_VIDEO_DETAILS_FAILURE:
    case types.FETCH_VIDEO_DETAILS_CANCEL:
      return {
        ...state,
        itemActivity: {
          ...state.itemActivity,
          error: action.payload,
          loading: false,
        },
      }
    default:
      return state
  }
}

export default wikiPageReducer
