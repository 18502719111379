export const FETCH_FAQS = 'FETCH_FAQS'
export const FETCH_NINJA_FAQS = 'FETCH_NINJA_FAQS'

export const FETCH_FAQS_SUCCESS = 'FETCH_FAQS_SUCCESS'
export const FETCH_FAQS_FAILURE = 'FETCH_FAQS_FAILURE'
export const FETCH_FAQS_CANCEL = 'FETCH_FAQS_CANCEL'

export const UPDATE_FAQ_VIEW_COUNT = 'UPDATE_FAQ_VIEW_COUNT'
export const UPDATE_FAQ_VIEW_COUNT_SUCCESS = 'UPDATE_FAQ_VIEW_COUNT_SUCCESS'
export const UPDATE_FAQ_VIEW_COUNT_FAILURE = 'UPDATE_FAQ_VIEW_COUNT_FAILURE'
export const UPDATE_FAQ_VIEW_COUNT_CANCEL = 'UPDATE_FAQ_VIEW_COUNT_CANCEL'

export const UPDATE_FAQ_RESPONSE_COUNT = 'UPDATE_FAQ_RESPONSE_COUNT'
export const UPDATE_FAQ_RESPONSE_COUNT_SUCCESS =
  'UPDATE_FAQ_RESPONSE_COUNT_SUCCESS'
export const UPDATE_FAQ_RESPONSE_COUNT_FAILURE =
  'UPDATE_FAQ_RESPONSE_COUNT_FAILURE'
export const UPDATE_FAQ_RESPONSE_COUNT_CANCEL =
  'UPDATE_FAQ_RESPONSE_COUNT_CANCEL'
export const FETCH_INDIVIDUAL_FAQ_AND_RECOMMENDATIONS =
  'FETCH_INDIVIDUAL_FAQ_AND_RECOMMENDATIONS'
export const FETCH_INDIVIDUAL_FAQ_AND_RECOMMENDATIONS_CANCEL =
  'FETCH_INDIVIDUAL_FAQ_AND_RECOMMENDATIONS_CANCEL'

export const ADD_NEW_FAQ = 'ADD_NEW_FAQ'
export const ADD_NEW_FAQ_SUCCESS = 'ADD_NEW_FAQ_SUCCESS'
export const ADD_NEW_FAQ_FAILURE = 'ADD_NEW_FAQ_FAILURE'
export const ADD_NEW_FAQ_CANCEL = 'ADD_NEW_FAQ_CANCEL'

export const EDIT_FAQ = 'EDIT_FAQ'
export const EDIT_FAQ_SUCCESS = 'EDIT_FAQ_SUCCESS'
export const EDIT_FAQ_FAILURE = 'EDIT_FAQ_FAILURE'
export const EDIT_FAQ_CANCEL = 'EDIT_FAQ_CANCEL'

export const DELETE_FAQ = 'DELETE_FAQ'
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS'
export const DELETE_FAQ_FAILURE = 'DELETE_FAQ_FAILURE'
export const DELETE_FAQ_CANCEL = 'DELETE_FAQ_CANCEL'
