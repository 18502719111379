import React from 'react'
import { connect } from 'react-redux'
import AsyncDOM from '../../components/Utils/AsyncDOM'
import Loader from '../../components/Utils/Loader'
import { AppState } from '../../store'
import { UserDetailsState } from '../../providers/User/UserDetailsProvider'

interface Props {
  auth: UserDetailsState['auth']
}

const Auth = (props: Props) => {
  return (
    <AsyncDOM loading={props.auth.loading}>
      <AsyncDOM.Error error={props.auth.error}>
        Something went wrong
      </AsyncDOM.Error>
    </AsyncDOM>
  )
}

const mapStateToProps = (state: AppState) => ({
  auth: state.user.details.auth,
})

export default connect(mapStateToProps)(Auth)
