import * as types from './ProgramCategory.types'

export const fetchProgramCategory = () => ({
  type: types.FETCH_PROGRAM_CATEGORY_DETAILS as typeof types.FETCH_PROGRAM_CATEGORY_DETAILS,
})

export const fetchProgramCategoryFailure = (payload: Error) => ({
  type: types.FETCH_PROGRAM_CATEGORY_DETAILS_FAILURE as typeof types.FETCH_PROGRAM_CATEGORY_DETAILS_FAILURE,
  payload,
})

export const fetchProgramCategorySuccess = (payload: any) => {
  return {
    type: types.FETCH_PROGRAM_CATEGORY_DETAILS_SUCCESS as typeof types.FETCH_PROGRAM_CATEGORY_DETAILS_SUCCESS,
    payload,
  }
}

export const fetchProgramCategoryCancel = () => ({
  type: types.FETCH_PROGRAM_CATEGORY_DETAILS_CANCEL as typeof types.FETCH_PROGRAM_CATEGORY_DETAILS_CANCEL,
})

export type ProgramCategoryActions =
  | ReturnType<typeof fetchProgramCategory>
  | ReturnType<typeof fetchProgramCategorySuccess>
  | ReturnType<typeof fetchProgramCategoryFailure>
  | ReturnType<typeof fetchProgramCategoryCancel>
