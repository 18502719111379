import { Reducer } from 'redux'
import { isActiveBatchGLAorFSL } from 'common/utils'
import {
  ActiveCourseData,
  BaseCourseData,
  CompletedCourseData,
  CourseData,
  CourseID,
  CourseTabData,
  FailedCourseData,
  UpcomingCourseData,
} from '../../../../common/types/courses'
import { SpecializationCoursesData } from '../../../../common/types/specializationCourses'
import {
  AnnouncementActions,
  announcementTypes,
} from '../../AnnouncementsProvider'
import {
  DashboardActions,
  dashboardTypes,
} from '../../Dashboard/DashboardProvider'
import { CourseListActions } from './Courses.actions'
import * as types from './Courses.types'
import {
  ProgramActions,
  UPDATE_ACTIVE_PROGRAM,
} from '../../Dashboard/ProgramsProvider'
import { sortByTimeAsc } from '../../../../common/utils/time'
import { OutcomesState } from './OutcomesProvider/Outcomes.reducer'
import { outcomeActionTypes, outcomesDetailsReducer } from './OutcomesProvider'
import { OutcomeActions } from './OutcomesProvider/Outcomes.actions'
import {
  ExcelerateCareerPrepActions,
  excelerateCareerPrepActionTypes,
} from '../../Excelerate/CareerPrepProvider'

interface CoursesStateData {
  byId: {
    [key in CourseID]?: CourseData & { tabs?: CourseTabData[] } & {
      outcomes?: OutcomesState
    }
  }
  completedIds: CourseID[]
  upcomingIds: CourseID[]
  failedIds: CourseID[]
  activeIds: CourseID[]
  complementaryIds?: CourseID[]
  specializationIds?: CourseID[]
  hasAllCourses: boolean
}

export type CoursesState = Readonly<{
  data: CoursesStateData
  loading: boolean
  error: boolean | Error
  byCourse: {
    [key in CourseID]?: {
      data: CourseID[] | null
      loading: boolean
      error: boolean | Error
    }
  }
  byCourseTabs: {
    [key in CourseID]?: {
      loading: boolean
      error: boolean | Error
    }
  }
}>

const initialState: CoursesState = {
  data: {
    byId: {},
    activeIds: [],
    completedIds: [],
    failedIds: [],
    upcomingIds: [],
    hasAllCourses: false,
  },
  loading: false,
  error: false,
  byCourse: {},
  byCourseTabs: {},
}

/**
 * Active Course with nearest course end date first and then next course and so on.
 */
const activeCourseHandler = (data: ActiveCourseData[]) =>
  data.slice().sort((a, b) => sortByTimeAsc(a.end_date, b.end_date))

/**
 * Course with the nearest course start date first and then next course and so on.
 */
const upcomingCourseHandler = (data: UpcomingCourseData[]) =>
  data.slice().sort((a, b) => sortByTimeAsc(a.start_date, b.start_date))

/**
 * Course which is completed at the latest first and then next course and so on.
 */
const completedCourseHandler = (data: CompletedCourseData[]) =>
  data
    .slice()
    .sort((a, b) => sortByTimeAsc(a.start_date, b.start_date))
    .reverse()
    .filter(
      a => !(a.grade === 'IC' || ('grade_point' in a && a.grade_point === 0))
    )

/**
 * TODO: Yet to get proper order
 */
const failedCourseHandler = (data: FailedCourseData[]) =>
  data
    .slice()
    .sort((a, b) => sortByTimeAsc(a.end_date, b.end_date))
    .reverse()
    .filter(
      a => a.grade === 'IC' || ('grade_point' in a && a.grade_point === 0)
    )

const courseListDataHandler = (
  data: any,
  state: CoursesStateData
): CoursesStateData => {
  const completedCourses = completedCourseHandler(
    data.completed || data.completed_courses
  )
  const failedCourses = failedCourseHandler(
    data.completed || data.completed_courses
  )
  const activeCourses = activeCourseHandler(data.active || data.active_courses)
  const upcomingCourses = upcomingCourseHandler(
    data.upcoming || data.upcoming_courses
  )
  const specializationCourses = data.specialization_courses
    ? data.specialization_courses
    : null
  const complementaryCourses = data.complementary_courses
    ? data.complementary_courses
    : null

  const courses: CoursesStateData = {
    byId: {
      ...state.byId,
    },
    hasAllCourses: true,
    completedIds: [],
    failedIds: [],
    upcomingIds: [],
    activeIds: [],
  }

  if (specializationCourses) {
    const specializationCoursesList: CourseID[] = []
    specializationCourses.forEach((c: SpecializationCoursesData) => {
      courses.byId[c.course_id] = { ...state.byId[c.course_id], ...c }
      specializationCoursesList.push(c.course_id)
    })
    courses.specializationIds = specializationCoursesList
  }

  if (complementaryCourses) {
    const complementaryCourseIDList: CourseID[] = []
    complementaryCourses.forEach((c: ActiveCourseData) => {
      courses.byId[c.course_id] = { ...state.byId[c.course_id], ...c }
      complementaryCourseIDList.push(c.course_id)
    })
    courses.complementaryIds = complementaryCourseIDList
  }

  completedCourses.forEach(c => {
    courses.byId[c.course_id] = { ...state.byId[c.course_id], ...c }
    courses.completedIds.push(c.course_id)
  })

  failedCourses.forEach(c => {
    courses.byId[c.course_id] = { ...state.byId[c.course_id], ...c }
    courses.failedIds.push(c.course_id)
  })

  activeCourses.forEach(c => {
    courses.byId[c.course_id] = { ...state.byId[c.course_id], ...c }
    courses.activeIds.push(c.course_id)
  })

  upcomingCourses.forEach(c => {
    courses.byId[c.course_id] = { ...state.byId[c.course_id], ...c }
    courses.upcomingIds.push(c.course_id)
  })

  return courses
}

const fetchCourseData = (data: any): BaseCourseData => {
  const courseDetailsData: BaseCourseData = {
    course_name: data.name,
    course_id: data.id,
  }
  return courseDetailsData
}

const initialAPIState = {
  data: [],
  loading: false,
  error: false,
}

const coursesReducer: Reducer<
  CoursesState,
  | CourseListActions
  | DashboardActions
  | AnnouncementActions
  | OutcomeActions
  | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
  | Extract<
      ExcelerateCareerPrepActions,
      { type: 'FETCH_EXCELERATE_CAREER_PREP_DETAILS_SUCCESS' }
    >
> = (state = initialState, action): CoursesState => {
  switch (action.type) {
    case dashboardTypes.FETCH_DASHBOARD_CONTENT:
    case types.COURSE_LIST_FETCH: {
      return {
        ...state,
        loading: true,
        error: false,
      }
    }
    case dashboardTypes.FETCH_DASHBOARD_CONTENT_SUCCESS: {
      if (isActiveBatchGLAorFSL(action.payload.user_access_type)) {
        return { ...state }
      }

      const processedData = courseListDataHandler(action.payload, state.data)
      return {
        ...state,
        data: processedData,
        loading: false,
        error: false,
      }
    }
    case types.COURSE_LIST_FETCH_SUCCESS: {
      const processedData = courseListDataHandler(action.payload, state.data)
      return {
        ...state,
        data: processedData,
        loading: false,
        error: false,
      }
    }
    case dashboardTypes.FETCH_DASHBOARD_CONTENT_CANCEL:
    case types.COURSE_LIST_FETCH_CANCEL: {
      return {
        ...state,
        loading: false,
        error: false,
      }
    }
    case dashboardTypes.FETCH_DASHBOARD_CONTENT_FAILURE:
    case types.COURSE_LIST_FETCH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }
    case types.COURSE_DETAILS_FETCH: {
      return {
        ...state,
        byCourse: {
          [action.meta.courseId]: {
            ...initialAPIState,
            ...state.byCourse[action.meta.courseId],
            loading: true,
          },
        },
      }
    }
    case types.COURSE_DETAILS_FETCH_SUCCESS: {
      const courseProcessedData = fetchCourseData(action.payload)
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.courseId]: {
              ...state.data.byId[action.meta.courseId],
              ...courseProcessedData,
            } as CourseData,
          },
        },
        byCourse: {
          [action.meta.courseId]: {
            data: [action.payload.id],
            loading: false,
            error: false,
          },
        },
      }
    }
    case excelerateCareerPrepActionTypes.FETCH_EXCELERATE_CAREER_PREP_DETAILS_SUCCESS: {
      const { activeCourse } = action.payload

      return activeCourse
        ? {
            ...state,
            data: {
              ...state.data,
              byId: {
                ...state.data.byId,
                [activeCourse.course_id]: {
                  ...state.data.byId[activeCourse.course_id],
                  ...activeCourse,
                  excelerate_course: true,
                } as CourseData,
              },
            },
            byCourse: {
              [activeCourse.course_id]: {
                data: [activeCourse.course_id],
                loading: false,
                error: false,
              },
            },
          }
        : state
    }
    case types.COURSE_DETAILS_FETCH_FAILURE: {
      return {
        ...state,
        byCourse: {
          [action.meta.courseId]: {
            ...initialAPIState,
            ...state.byCourse[action.meta.courseId],
            loading: false,
            error: action.payload,
          },
        },
      }
    }
    case types.COURSE_DETAILS_FETCH_CANCEL: {
      return {
        ...state,
        byCourse: {
          [action.meta.courseId]: {
            ...initialAPIState,
            ...state.byCourse[action.meta.courseId],
            loading: false,
          },
        },
      }
    }
    case types.COURSE_TABS_FETCH:
      return {
        ...state,
        byCourseTabs: {
          ...state.byCourseTabs,
          [action.meta.courseId]: {
            loading: true,
            error: false,
          },
        },
      }

    case types.COURSE_TABS_FETCH_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.courseId]: {
              ...state.data.byId[action.meta.courseId]!,
              tabs: action.payload,
            },
          },
        },
        byCourseTabs: {
          ...state.byCourseTabs,
          [action.meta.courseId]: {
            loading: false,
            error: false,
          },
        },
      }
    case types.COURSE_TABS_FETCH_FAILURE:
      return {
        ...state,
        byCourseTabs: {
          ...state.byCourseTabs,
          [action.meta.courseId]: {
            loading: false,
            error: action.payload,
          },
        },
      }
    case announcementTypes.ANNOUNCEMENT_MARK_AS_READ:
      if ('courseId' in action.meta) {
        const currentCourse = state.data.byId[action.meta.courseId]
        const currentAnnouncementCount: number =
          currentCourse && 'unread_announcement_count' in currentCourse
            ? currentCourse.unread_announcement_count || 0
            : 0
        return {
          ...state,
          data: {
            ...state.data,
            byId: {
              ...state.data.byId,
              [action.meta.courseId]: {
                ...currentCourse,
                unread_announcement_count: Math.max(
                  currentAnnouncementCount - 1,
                  0
                ),
              } as CoursesState['data']['byId']['x'],
            },
          },
        }
      }
      return state

    case UPDATE_ACTIVE_PROGRAM:
      return initialState

    case types.COURSE_RATING_SUBMIT_SUCCESS:
    case types.COURSE_RATING_SUBMIT:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.courseId]: {
              ...state.data.byId[action.meta.courseId],
              user_rating: {
                is_rated: true,
                rating: action.payload.rating,
                comment: action.payload.comment,
              },
            } as CourseData,
          },
        },
      }

    case types.COURSE_RATING_SUBMIT_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.courseId]: {
              ...state.data.byId[action.meta.courseId],
              user_rating: {
                is_rated: false,
              },
            } as CourseData,
          },
        },
      }

    case outcomeActionTypes.GET_OUTCOME_DETAILS:
    case outcomeActionTypes.GET_OUTCOME_DETAILS_SUCCESS:
    case outcomeActionTypes.GET_OUTCOME_DETAILS_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.courseId]: {
              ...state.data.byId[action.meta.courseId],
              outcomes: {
                ...outcomesDetailsReducer(
                  state.data.byId[action.meta.courseId]!.outcomes,
                  action
                ),
              },
            } as CoursesStateData['byId']['x'],
          },
        },
      }

    default:
      return state
  }
}

export default coursesReducer
