import {
  getExternalToolUrl,
  getAssignmentMetaData,
  submitAssignment,
  getAssignmentAiMentorUserData,
} from './AssignmentSubmissions.actions'
import { apiCall, getUserDetails } from '../../../../../../common/utils'

export async function submitAssignmentAPI(
  action: ReturnType<typeof submitAssignment>,
  fileUploadIds: Number[],
  signal: AbortSignal
) {
  const {
    contentId,
    courseId,
    submissionType,
    textEntry,
    comments,
    websiteUrl,
  } = action.payload
  const body = JSON.stringify({
    submission: {
      submission_type: submissionType,
      file_ids: fileUploadIds,
      url: websiteUrl,
      body: textEntry,
    },
    comment: {
      text_comment: comments,
    },
  })
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/assignments/${contentId}/submissions`,
    params: {
      signal,
      method: 'POST',
      body,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getExternalToolAPI(
  action: ReturnType<typeof getExternalToolUrl>,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: action.payload.url,
    params: {
      signal,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getAssignmentMetaDataAPI(
  action: ReturnType<typeof getAssignmentMetaData>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/courses/${action.meta.courseId}/assignments/${action.meta.contentId}/meta_data`,
    params: {
      signal,
    },
    query: { user_id: id },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getAssignmentAiMentorDataAPI(
  action: ReturnType<typeof getAssignmentAiMentorUserData>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/courses/${action.meta.courseId}/assignments/${action.meta.contentId}/ai_mentor_user_data`,
    params: {
      signal,
    },
    query: { user_id: id },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
