import * as types from './ModuleItems.types'
import { CourseID } from '../../../../common/types/courses'
import { ContentID } from '../../../../common/types/courses/content'
import { ModuleID } from '../../../../common/types'
import {
  ModuleItemData,
  ItemID,
  ModuleData,
} from '../../../../common/types/courses/moduleItem'
import { UserID } from '../../../../common/types/user'

export interface ModuleItemsFetchParams {
  courseId: CourseID
  moduleId: ModuleID
  include?: string[]
  page?: number
  per_page?: number
  userId: UserID
}

export interface ModuleItemMetaParams {
  moduleId: ModuleID
}

export interface ModuleItemsPayloadParams {
  moduleData: ModuleData
  items: ModuleItemData[]
}

export const fetchModuleItems = (
  payload: ModuleItemsFetchParams,
  meta: { courseId: CourseID } & ModuleItemMetaParams
) => ({
  type: types.MODULE_ITEMS_FETCH as typeof types.MODULE_ITEMS_FETCH,
  payload,
  meta,
})

export const fetchModuleItemsFailure = (
  payload: Error,
  meta: ModuleItemMetaParams
) => ({
  type: types.MODULE_ITEMS_FETCH_FAILURE as typeof types.MODULE_ITEMS_FETCH_FAILURE,
  payload,
  meta,
})

export const fetchModuleItemsSuccess = (
  payload: ModuleItemsPayloadParams,
  meta: ModuleItemMetaParams
) => ({
  type: types.MODULE_ITEMS_FETCH_SUCCESS as typeof types.MODULE_ITEMS_FETCH_SUCCESS,
  payload,
  meta,
})

export const fetchModuleItemsCancel = (
  payload: any,
  meta: ModuleItemMetaParams
) => ({
  type: types.MODULE_ITEMS_FETCH_CANCEL as typeof types.MODULE_ITEMS_FETCH_CANCEL,
  payload,
  meta,
})

export type BulkModuleItemsInsertParams = {
  [s in ModuleID]: {
    items: ModuleItemData[]
    count: number
    moduleData: ModuleData
  }
}

export const moduleItemsBulkInsert = (
  payload: BulkModuleItemsInsertParams
) => ({
  type: types.MODULE_ITEMS_BULK_INSERT as typeof types.MODULE_ITEMS_BULK_INSERT,
  payload,
})

export const moduleItemsAccessData = (payload: any) => ({
  type: types.MODULE_ITEMS_ACCESS_DATA as typeof types.MODULE_ITEMS_ACCESS_DATA,
  payload,
})

export interface ModuleItemFetchParams {
  moduleId: ModuleID
  itemId: ItemID
  courseId: CourseID
  include?: string[]
  disableLogAssetAccess?: boolean
}

export interface ModuleItemFetchSuccessParams {
  moduleData: ModuleData
  item: ModuleItemData
}

interface ModuleItemFetchMetaParams {
  moduleId: ModuleID
  itemId: ItemID
  courseId: CourseID
}

export const fetchModuleItem = (
  payload: ModuleItemFetchParams,
  meta: ModuleItemFetchMetaParams
) => ({
  type: types.MODULE_ITEM_FETCH as typeof types.MODULE_ITEM_FETCH,
  payload,
  meta,
})

export const fetchModuleItemFailure = (
  payload: Error,
  meta: ModuleItemFetchMetaParams
) => ({
  type: types.MODULE_ITEM_FETCH_FAILURE as typeof types.MODULE_ITEM_FETCH_FAILURE,
  payload,
  meta,
})

export const fetchModuleItemCancel = (
  payload: null,
  meta: ModuleItemFetchMetaParams
) => ({
  type: types.MODULE_ITEMS_FETCH_CANCEL as typeof types.MODULE_ITEMS_FETCH_CANCEL,
  payload,
  meta,
})

export const fetchModuleItemSuccess = (
  payload: ModuleItemFetchSuccessParams,
  meta: ModuleItemFetchMetaParams
) => ({
  type: types.MODULE_ITEM_FETCH_SUCCESS as typeof types.MODULE_ITEM_FETCH_SUCCESS,
  payload,
  meta,
})

interface MarkItemAsReadParams {
  moduleId: ModuleID
  itemId: ItemID
  courseId: CourseID
}
interface MarkItemAsReadMetaParams {
  itemId: ItemID
}

export const markItemAsRead = (
  payload: MarkItemAsReadParams,
  meta: MarkItemAsReadMetaParams
) => ({
  type: types.MARK_ITEM_AS_READ as typeof types.MARK_ITEM_AS_READ,
  payload,
  meta,
})

export const markItemAsReadSuccess = (
  payload: any,
  meta: MarkItemAsReadMetaParams
) => ({
  type: types.MARK_ITEM_AS_READ_SUCCESS as typeof types.MARK_ITEM_AS_READ_SUCCESS,
  payload,
  meta,
})

export const markItemAsReadFailure = (
  payload: Error,
  meta: MarkItemAsReadMetaParams
) => ({
  type: types.MARK_ITEM_AS_READ_FAILURE as typeof types.MARK_ITEM_AS_READ_FAILURE,
  payload,
  meta,
})

export const markItemAsReadCancel = (
  payload: null,
  meta: MarkItemAsReadMetaParams
) => ({
  type: types.MARK_ITEM_AS_READ_CANCEL as typeof types.MARK_ITEM_AS_READ_CANCEL,
  payload,
  meta,
})

interface FeedbackQAFetchParams {
  courseId: CourseID
  contentId: ContentID
  contentType: 'WikiPage' | 'Assignment' | 'DiscussionTopic' | 'Quizzes::Quiz'
}

interface ContentFeedbackMetaParams {
  itemId: ItemID
}

export const fetchFeedbackQuestionsAndAnswers = (
  payload: FeedbackQAFetchParams,
  meta: ContentFeedbackMetaParams
) => ({
  type: types.FETCH_FEEDBACK_QA as typeof types.FETCH_FEEDBACK_QA,
  payload,
  meta,
})

export const fetchFeedbackQuestionsAndAnswersSuccess = (
  payload: any,
  meta: ContentFeedbackMetaParams
) => ({
  type: types.FETCH_FEEDBACK_QA_SUCCESS as typeof types.FETCH_FEEDBACK_QA_SUCCESS,
  payload,
  meta,
})

export const fetchFeedbackQuestionsAndAnswersFailure = (
  payload: Error,
  meta: ContentFeedbackMetaParams
) => ({
  type: types.FETCH_FEEDBACK_QA_FAILURE as typeof types.FETCH_FEEDBACK_QA_FAILURE,
  payload,
  meta,
})

export const fetchFeedbackQuestionsAndAnswersCancel = (
  payload: any,
  meta: ContentFeedbackMetaParams
) => ({
  type: types.FETCH_FEEDBACK_QA_CANCEL as typeof types.FETCH_FEEDBACK_QA_CANCEL,
  payload,
  meta,
})

interface SubmitContentFeedbackPayload {
  courseId: CourseID
  contentType: 'WikiPage' | 'Assignment' | 'DiscussionTopic' | 'Quizzes::Quiz'
  contentId: ContentID
  startRating: number | null
  optionsSelected: string[]
  comments: string
}

export const submitContentFeedback = (
  payload: SubmitContentFeedbackPayload,
  meta: ContentFeedbackMetaParams
) => ({
  type: types.SUBMIT_CONTENT_FEEDBACK as typeof types.SUBMIT_CONTENT_FEEDBACK,
  payload,
  meta,
})
export const submitContentFeedbackSuccess = (
  payload: any,
  meta: ContentFeedbackMetaParams
) => ({
  type: types.SUBMIT_CONTENT_FEEDBACK_SUCCESS as typeof types.SUBMIT_CONTENT_FEEDBACK_SUCCESS,
  payload,
  meta,
})
export const submitContentFeedbackFailure = (
  payload: Error,
  meta: ContentFeedbackMetaParams
) => ({
  type: types.SUBMIT_CONTENT_FEEDBACK_FAILURE as typeof types.SUBMIT_CONTENT_FEEDBACK_FAILURE,
  payload,
  meta,
})
export const submitContentFeedbackCancel = (
  payload: any,
  meta: ContentFeedbackMetaParams
) => ({
  type: types.SUBMIT_CONTENT_FEEDBACK_CANCEL as typeof types.SUBMIT_CONTENT_FEEDBACK_CANCEL,
  payload,
  meta,
})

export type ModuleItemsActionTypes =
  | ReturnType<typeof fetchModuleItems>
  | ReturnType<typeof fetchModuleItemsSuccess>
  | ReturnType<typeof fetchModuleItemsFailure>
  | ReturnType<typeof fetchModuleItemsCancel>
  | ReturnType<typeof moduleItemsBulkInsert>
  | ReturnType<typeof moduleItemsAccessData>
  | ReturnType<typeof fetchModuleItem>
  | ReturnType<typeof fetchModuleItemFailure>
  | ReturnType<typeof fetchModuleItemSuccess>
  | ReturnType<typeof fetchModuleItemCancel>
  | ReturnType<typeof markItemAsRead>
  | ReturnType<typeof markItemAsReadSuccess>
  | ReturnType<typeof markItemAsReadFailure>
  | ReturnType<typeof markItemAsReadCancel>
  | ReturnType<typeof fetchFeedbackQuestionsAndAnswers>
  | ReturnType<typeof fetchFeedbackQuestionsAndAnswersSuccess>
  | ReturnType<typeof fetchFeedbackQuestionsAndAnswersFailure>
  | ReturnType<typeof fetchFeedbackQuestionsAndAnswersCancel>
  | ReturnType<typeof submitContentFeedback>
  | ReturnType<typeof submitContentFeedbackSuccess>
  | ReturnType<typeof submitContentFeedbackFailure>
  | ReturnType<typeof submitContentFeedbackCancel>
