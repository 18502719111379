import React from 'react'
import styles from './PageBase.module.css'

interface PageBaseProps {
  className?: string
  children: React.ReactNode
}

const LearnAndDevPageBase = (props: PageBaseProps) => (
  <div id="main" className={styles.mainContainer}>
    {props.children}
  </div>
)

export default LearnAndDevPageBase
