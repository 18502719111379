import React from 'react'
import Snackbar, {
  SnackbarProps,
  SnackbarOrigin,
} from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'

import CloseIcon from '@material-ui/icons/Close'
import cx from 'classnames'
import styles from './Alert.module.css'
import { MessageTypeIcon } from '../Icons/CustomIcons'
import RawHTML from '../RawHTML'

export interface Props extends SnackbarProps {
  className?: string
  variant: 'error' | 'warning' | 'info' | 'success'
  onClose: () => void
  message: string
  closeOnTimeout?: boolean
  anchorOrigin?: SnackbarOrigin
}

const Alert = (props: Props) => {
  const {
    message,
    onClose,
    variant,
    className,
    closeOnTimeout,
    anchorOrigin,
    ...others
  } = props
  return (
    <Snackbar
      anchorOrigin={anchorOrigin || { vertical: 'top', horizontal: 'right' }}
      {...(closeOnTimeout === false ? {} : { onClose })}
      {...others}
    >
      <SnackbarContent
        aria-describedby="client-snackbar"
        className={cx(styles.alertContent, styles[variant], className)}
        classes={{ message: styles.alertMessage }}
        message={
          <span id="client-snackbar" className={styles.messageContainer}>
            <MessageTypeIcon
              className={styles.messageIcon}
              type={props.variant}
            />
            <RawHTML className={styles.message} html={message} />
            {/* <div className={styles.message}>{message}</div> */}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon className={styles.closeIcon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  )
}

Alert.defaultProps = {
  className: '',
  autoHideDuration: 4000,
}

export default Alert
