import { CourseID } from 'common/types/courses'
import { ItemID } from '../../../../../common/types/courses/moduleItem'
import {
  ITEM_CONTENT_FETCH,
  ITEM_CONTENT_FETCH_CANCEL,
  ITEM_CONTENT_FETCH_FAILURE,
  ITEM_CONTENT_FETCH_SUCCESS,
  ASSESSMENT_PROCTOR_CONTENT_FETCH,
  ASSESSMENT_PROCTOR_CONTENT_FETCH_FAILURE,
  ASSESSMENT_PROCTOR_CONTENT_FETCH_SUCCESS,
  ASSESSMENT_PROCTOR_CONTENT_FETCH_CANCEL,
  MODULE_ITEM_ACTIVITY_POST,
  MODULE_ITEM_ACTIVITY_CANCEL,
} from './ItemContent.types'

export interface ItemContentFetchParams {
  url: string
  disableLogAssetAccess?: boolean
}

export interface AssessmentProctorContentFetchParams {
  assessment_id: number
  assessment_type: 'Quiz' | 'Assignment' | 'Discussion'
}

export interface ItemContentMetaParams {
  itemId: ItemID
}

export interface ModuleItemTrackingMetaParams {
  itemId: ItemID
  courseId: CourseID
}

export const fetchItemContent = (
  payload: ItemContentFetchParams,
  meta: ItemContentMetaParams
) => ({
  type: ITEM_CONTENT_FETCH as typeof ITEM_CONTENT_FETCH,
  payload,
  meta,
})

export const fetchItemContentFailure = (
  payload: Error,
  meta: ItemContentMetaParams
) => ({
  type: ITEM_CONTENT_FETCH_FAILURE as typeof ITEM_CONTENT_FETCH_FAILURE,
  payload,
  meta,
})

export const fetchItemContentSuccess = (
  payload: any,
  meta: ItemContentMetaParams
) => ({
  type: ITEM_CONTENT_FETCH_SUCCESS as typeof ITEM_CONTENT_FETCH_SUCCESS,
  payload,
  meta,
})

export const fetchItemContentCancel = (
  payload: any,
  meta: ItemContentMetaParams
) => ({
  type: ITEM_CONTENT_FETCH_CANCEL as typeof ITEM_CONTENT_FETCH_CANCEL,
  payload,
  meta,
})

export const fetchAssessmentProctorContent = (
  payload: AssessmentProctorContentFetchParams,
  meta: ItemContentMetaParams
) => ({
  type: ASSESSMENT_PROCTOR_CONTENT_FETCH as typeof ASSESSMENT_PROCTOR_CONTENT_FETCH,
  payload,
  meta,
})

export const fetchAssessmentProctorContentFailure = (
  payload: Error,
  meta: ItemContentMetaParams
) => ({
  type: ASSESSMENT_PROCTOR_CONTENT_FETCH_FAILURE as typeof ASSESSMENT_PROCTOR_CONTENT_FETCH_FAILURE,
  payload,
  meta,
})

export const fetchAssessmentProctorContentSuccess = (
  payload: any,
  meta: ItemContentMetaParams
) => ({
  type: ASSESSMENT_PROCTOR_CONTENT_FETCH_SUCCESS as typeof ASSESSMENT_PROCTOR_CONTENT_FETCH_SUCCESS,
  payload,
  meta,
})

export const fetchAssessmentProctorContentCancel = (
  payload: any,
  meta: ItemContentMetaParams
) => ({
  type: ASSESSMENT_PROCTOR_CONTENT_FETCH_CANCEL as typeof ASSESSMENT_PROCTOR_CONTENT_FETCH_CANCEL,
  payload,
  meta,
})

export const postModuleItemActivity = (
  payload: any,
  meta: ModuleItemTrackingMetaParams
) => ({
  type: MODULE_ITEM_ACTIVITY_POST as typeof MODULE_ITEM_ACTIVITY_POST,
  payload,
  meta,
})

export const postModuleItemActivityCancel = (
  payload: any,
  meta: ModuleItemTrackingMetaParams
) => ({
  type: MODULE_ITEM_ACTIVITY_CANCEL as typeof MODULE_ITEM_ACTIVITY_CANCEL,
  payload,
  meta,
})

export type ItemContentActionTypes =
  | ReturnType<typeof fetchItemContent>
  | ReturnType<typeof fetchItemContentSuccess>
  | ReturnType<typeof fetchItemContentFailure>
  | ReturnType<typeof fetchItemContentCancel>
  | ReturnType<typeof fetchAssessmentProctorContent>
  | ReturnType<typeof fetchAssessmentProctorContentSuccess>
  | ReturnType<typeof fetchAssessmentProctorContentFailure>
  | ReturnType<typeof fetchAssessmentProctorContentCancel>
  | ReturnType<typeof postModuleItemActivity>
  | ReturnType<typeof postModuleItemActivityCancel>
