import { select, put, cancelled, takeLatest, call } from 'redux-saga/effects'
import { showAlertMessage } from 'web/providers/AlertsProvider'
import { UserID } from '../../../../common/types/user'
import { apiCall, cancelable } from '../../../../common/utils'
import { AppState } from '../../../store'
import {
  fetchUserProgramTestimonialFailure,
  fetchUserProgramTestimonialSuccess,
  submitUserProgramTestimonial,
  submitUserProgramTestimonialFailure,
  submitUserProgramTestimonialSuccess,
  updateUserProgramTestimonial,
  updateUserProgramTestimonialFailure,
  updateUserProgramTestimonialSuccess,
  updateUserProgramTestimonialImage,
  updateUserProgramTestimonialImageFailure,
  updateUserProgramTestimonialImageSuccess,
} from './UserTestimonial.actions'
import {
  FETCH_USER_PROGRAM_TESTIMONIAL,
  FETCH_USER_PROGRAM_TESTIMONIAL_CANCEL,
  SUBMIT_USER_PROGRAM_TESTIMONIAL,
  SUBMIT_USER_PROGRAM_TESTIMONIAL_CANCEL,
  UPDATE_USER_PROGRAM_TESTIMONIAL,
  UPDATE_USER_PROGRAM_TESTIMONIAL_CANCEL,
  UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE,
  UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_CANCEL,
} from './UserTestimonial.types'

export async function getUserProgramTestimonialAPI(
  userId: UserID,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/testimonials`,
    params: {
      signal,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
export async function saveUserProgramTestimonialAPI(
  userId: UserID,
  data: ReturnType<typeof submitUserProgramTestimonial>['payload'],
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/testimonials`,
    params: {
      signal,
      method: 'POST',
      body: JSON.stringify(data),
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
export async function editUserProgramTestimonialAPI(
  userId: UserID,
  data: ReturnType<typeof updateUserProgramTestimonial>['payload'],
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/testimonials/${data.id}`,
    params: {
      signal,
      method: 'PUT',
      body: JSON.stringify(data),
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function editUserProgramTestimonialImageAPI(
  userId: UserID,
  data: ReturnType<typeof updateUserProgramTestimonialImage>['payload'],
  signal: AbortSignal
) {
  const formData = new FormData()
  if (data.attachment) {
    formData.append(
      'attachment',
      data.attachment.file,
      data.attachment.file.name
    )
  }
  const body = formData
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/testimonials/${data.id}/image`,
    params: {
      signal,
      method: 'PUT',
      body,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

function* fetchUserProgramTestimonialHandler() {
  const abortController = new AbortController()
  try {
    const userId: UserID | undefined = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data: Promise<any> = yield call(
      getUserProgramTestimonialAPI,
      userId,
      abortController.signal
    )
    yield put(fetchUserProgramTestimonialSuccess(data))
  } catch (e) {
    yield put(fetchUserProgramTestimonialFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
function* submitUserProgramTestimonialHandler(
  action: ReturnType<typeof submitUserProgramTestimonial>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID | undefined = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data: Promise<any> = yield call(
      saveUserProgramTestimonialAPI,
      userId,
      action.payload,
      abortController.signal
    )
    yield put(
      submitUserProgramTestimonialSuccess(
        Array.isArray(data) && data.length ? data[0] : data
      )
    )
  } catch (e) {
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Unable to submit the testimonial',
      })
    )
    yield put(submitUserProgramTestimonialFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
function* updateUserProgramTestimonialHandler(
  action: ReturnType<typeof updateUserProgramTestimonial>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID | undefined = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data: Promise<any> = yield call(
      editUserProgramTestimonialAPI,
      userId,
      action.payload,
      abortController.signal
    )
    yield put(
      updateUserProgramTestimonialSuccess(
        Array.isArray(data) && data.length ? data[0] : data
      )
    )
  } catch (e) {
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Unable to update the testimonial',
      })
    )
    yield put(updateUserProgramTestimonialFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* updateUserProgramTestimonialImageHandler(
  action: ReturnType<typeof updateUserProgramTestimonialImage>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID | undefined = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data = yield call(
      editUserProgramTestimonialImageAPI,
      userId,
      action.payload,
      abortController.signal
    )
    yield put(updateUserProgramTestimonialImageSuccess(data))
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'Testimonial Submitted',
      })
    )
  } catch (e) {
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Unable to update the testimonial',
      })
    )
    yield put(updateUserProgramTestimonialImageFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* testimonialsMiddleware() {
  yield takeLatest(
    FETCH_USER_PROGRAM_TESTIMONIAL,
    cancelable(
      fetchUserProgramTestimonialHandler,
      FETCH_USER_PROGRAM_TESTIMONIAL_CANCEL
    )
  )
  yield takeLatest(
    SUBMIT_USER_PROGRAM_TESTIMONIAL,
    cancelable(
      submitUserProgramTestimonialHandler,
      SUBMIT_USER_PROGRAM_TESTIMONIAL_CANCEL
    )
  )
  yield takeLatest(
    UPDATE_USER_PROGRAM_TESTIMONIAL,
    cancelable(
      updateUserProgramTestimonialHandler,
      UPDATE_USER_PROGRAM_TESTIMONIAL_CANCEL
    )
  )

  yield takeLatest(
    UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE,
    cancelable(
      updateUserProgramTestimonialImageHandler,
      UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_CANCEL
    )
  )
}

export default ([] as any).concat(testimonialsMiddleware())
