import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import {
  fetchUnlockedModulesSuccess,
  fetchUnlockedModulesFailure,
  fetchUnlockedModules,
} from './FirstUnlockedModule.actions'
import * as APIs from './FirstUnlockedModule.api'
import * as types from './FirstUnlockedModule.types'

function* getUnlockedModules(action: ReturnType<typeof fetchUnlockedModules>) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.fetchFirstUnlockedModules,
      action.payload,
      abortController.signal
    )
    yield put(fetchUnlockedModulesSuccess(data.unlocked_modules))
  } catch (e) {
    yield put(fetchUnlockedModulesFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* firstUnlockedModulesMiddleware() {
  yield takeLatest(
    types.FETCH_FIRST_UNLOCKED_MODULES,
    cancelable(getUnlockedModules, types.FETCH_FIRST_UNLOCKED_MODULES_CANCEL)
  )
}

export default ([] as any).concat(firstUnlockedModulesMiddleware())
