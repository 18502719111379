import {
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  Theme,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles } from '@material-ui/styles'
import cx from 'classnames'
import React, { Fragment, useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import GlaSearch from 'web/containers/GlaSearch'
import { mixpanel } from 'common/utils/mixpanel'
import Logo from '../../../common/images/logo.png'
import {
  DashboardData,
  FreeUserDashboardData,
} from '../../../common/types/dashboard'
import { ProgramID } from '../../../common/types/programs'
import { generateURL, getPageName, isUserAcadOps } from '../../../common/utils'
import { ProgramState } from '../../providers/Dashboard/ProgramsProvider'
import { UserDetailsState } from '../../providers/User/UserDetailsProvider'
import colors from '../../styles/colors'
import LinkButton from '../Utils/LinkButton'
import styles from './Header.module.css'
import PGAcademyPremiumIcon from '../../../common/images/academypremiumtag.svg'
import BrowseCoursesMenu from '../../containers/Header/BrowseCoursesMenu'
import {
  isAcademyInternationalUser,
  isFreeUser,
  hasNoWorkEx,
  isFresher,
  isGraduate,
  glaUtmParams,
  glaSearchPageUrl,
} from '../../../common/utils/custom/user'
import { ProgramSwitch } from '../HeaderUtils'
import {
  useReloadPath,
} from 'web/hooks'

const drawerWidth = 280

const useStyles = makeStyles((theme: Theme) => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
    marginLeft: 'auto',
  },
  pageLinks: {
    marginLeft: theme.spacing(5),
    alignItems: 'center',
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  button: {
    margin: theme.spacing(1),
    minWidth: 'auto',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: colors.grey60,
  },
  label: {
    textTransform: 'capitalize' as 'capitalize',
  },
  badge: {
    top: 12,
    right: -12,
  },
  icon: {
    width: '1em',
    height: '1em',
  },
  logo: {
    maxHeight: 36,
  },
  menuButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  search: {
    marginLeft: 'auto',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  searchDrawerPaper: {
    width: '100%',
  },
}))

export interface GlaHeaderProps {
  dashboardData: DashboardData | undefined
  userAccessType: UserDetailsState['accessType']
  userProfileDetails: UserDetailsState['profile']
  programs: ProgramState
  isStudyAbroadLinkEnabled: boolean
  onProgramChange: (programId: ProgramID) => void
}

const GlaHeader = (props: GlaHeaderProps) => {
  const [drawerToggle, setDrawerToggle] = useState(false)
  const [fresher, setFresher] = useState(false)
  const [graduate, setGraduate] = useState(false)

  const location = useLocation()

  const isAcadOps = isUserAcadOps()

  useEffect(() => {
    const academyUserDetails = props.userProfileDetails
      ? props.userProfileDetails.data
      : null

    if (academyUserDetails) {
      setFresher(isFresher(academyUserDetails))
      setGraduate(isGraduate(academyUserDetails))
    }
  }, [props.userProfileDetails])

  const classes = useStyles()
  const { dashboardData } = props
  const reloadPath = useReloadPath()

  const programsData = props.programs.data

  let careerSupportLink: undefined | string

  const freeUsers = isFreeUser(props.userAccessType)
  const academyInternationalUsers = isAcademyInternationalUser(
    props.userAccessType
  )

  const gl_utm_params = glaUtmParams(
    `${getPageName(location.pathname)}_loggedin_degree_and_certificate_navbar`
  )

  const study_abroad_utm_params = glaUtmParams(
    `${getPageName(location.pathname)}_loggedin_study_abroad_navbar`
  )

  const advancedProgramsLink: string = `${process.env.REACT_APP_WEBSITE_URL}?${gl_utm_params}#explore_domain_courses`

  const degreeProgramsUrl: string = `${process.env.REACT_APP_WEBSITE_URL}academy?${gl_utm_params}#pgp-recommendations-section`

  const studyAbroadLink: string = `${process.env.REACT_APP_WEBSITE_URL}university-arizona-ms-machine-learning-hybrid?${study_abroad_utm_params}`

  if (dashboardData && dashboardData.feature_links) {
    careerSupportLink = dashboardData.feature_links.career_support
  }

  const handleDrawerToggle = () => {
    setDrawerToggle(!drawerToggle)
  }

  const advancedProgramsLinkComp = () => {
    if (freeUsers || academyInternationalUsers) {
      const link = advancedProgramsLink
      const text = 'Degree & University Programs'
      return (
        <Link
          href={link}
          className={cx(
            classes.button,
            classes.label,
            styles.label,
            styles.careerTransformationProgramsContainer
          )}
          rel="noreferrer"
          target="_blank"
          underline="none"
        >
          <Box display="flex">
            <img
              src={PGAcademyPremiumIcon}
              className={styles.pgAcademyPremiumIcon}
              alt={text}
            />
            <Typography>{text}</Typography>
          </Box>
        </Link>
      )
    }
    return null
  }

  const careerBoostComp = () => (
    <Link
      href={careerSupportLink}
      className={cx(classes.button, classes.label, styles.label)}
      rel="noreferrer"
      underline="none"
    >
      CareerBoost
    </Link>
  )

  const careerBoostOrDegreeProgramsComp = () => {
    if (graduate || !hasNoWorkEx()) {
      return advancedProgramsLinkComp()
    }

    if (careerSupportLink) {
      return careerBoostComp()
    }

    return null
  }

  // eslint-disable-next-line no-shadow
  const links = (props: GlaHeaderProps, isDrawer: boolean = false) => (
    <Fragment>
      <ProgramSwitch
        handleDrawerToggle={handleDrawerToggle}
        programsData={programsData}
        onProgramChange={props.onProgramChange}
        isDrawer={isDrawer}
      />
      {isAcadOps ? null : (
        <>
          {props.isStudyAbroadLinkEnabled && (
            <Link
              href={studyAbroadLink}
              className={cx(classes.button, classes.label, styles.label)}
              target="_blank"
              underline="none"
              onClick={() => {
                mixpanel.track('Button Click', {
                  Type: 'study_abroad_link_click',
                })
                if (isDrawer) {
                  handleDrawerToggle()
                }
              }}
            >
              Study Abroad
            </Link>
          )}
          {!isDrawer ? <BrowseCoursesMenu /> : null}
          {careerBoostOrDegreeProgramsComp()}

          {isDrawer ? (
            <>
              <BrowseCoursesMenu isDrawer />
              <Button color="primary" className={styles.browseAllCoursesBtn} disableRipple>
                <Link
                  href={glaSearchPageUrl()}
                  underline="none"
                  className={cx(classes.button, classes.label, styles.label)}
                  onClick={handleDrawerToggle}
                >
                  Browse All Courses
                </Link>
              </Button>
            </>
          ) : null}
        </>
      )}
    </Fragment>
  )

  const drawer = (
    <div className={styles.drawerContainer}>
      <div className={styles.drawerCloseIcon}>
        <CloseOutlined onClick={handleDrawerToggle} />
      </div>
      <div className={styles.drawerLinks}>{links(props, true)}</div>
    </div>
  )

  const GLLogo = (
    <img
      className={classes.logo}
      src={
        (programsData.activeProgramID &&
          programsData.byId[programsData.activeProgramID] &&
          programsData.byId[programsData.activeProgramID]!.olympus_logo) ||
        Logo
      }
      alt="GreatLearning Logo"
    />
  )

  return (
    <div className={styles.container} data-testid="gla-header-navbar">
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>

      <div data-testid="gla-header-gl-logo">
        <NavLink to={"#"} onClick={()=>{reloadPath('DASHBOARD')}}>{GLLogo}</NavLink>
      </div>
      <div className={classes.pageLinks} data-testid="gla-header-home-link">
        {links(props)}
      </div>
      <div className={classes.sectionDesktop}>
        <div
          className={classes.sectionDesktop}
          data-testid="gla-header-search-bar"
        >
          <GlaSearch />
        </div>

        {isAcadOps ? null : (
          <Drawer
            variant="temporary"
            open={drawerToggle}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        )}
      </div>

      <div className={classes.search}>
        <GlaSearch />
      </div>
    </div>
  )
}

export default GlaHeader
