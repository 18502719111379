import React, { Fragment, useEffect, useState } from 'react'
import { Typography, Box } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { stringifyUrl } from 'query-string'
import { FeatureLinksData } from 'common/types/dashboard'
import styles from './AccessBlocked.module.css'
import Button from '../Utils/Button'
import Link from '../Utils/Link'
import {
  AdmissionStatusData,
  ProgramAdmissionData,
} from '../../../common/types/admission'
import { ProgramData, ProgramID } from '../../../common/types/programs'
import { PAYMENT_VIA_OFFER_LETTER_ONLY_FEE_CODES, paymentInfoMessage } from '../../../common/utils/custom/feepayment'
import ErrorContainer from '../Utils/ErrorContainer'
import { generateURL } from '../../../common/utils'
import { updateActiveProgram } from '../../providers/Dashboard/ProgramsProvider'
import GLAStrip from '../Utils/GLAStrip'

interface AccessBlockedProps {
  admissionData: AdmissionStatusData
  programData: ProgramData | null
  updateActiveProgram: typeof updateActiveProgram
  glAcademyId: ProgramID | null
  isCareerSchool: boolean
  featureLinks: FeatureLinksData
  isNewProgramSupportEnabled: boolean
}

const AccessBlocked = (props: AccessBlockedProps) => {
  const [message, setMessage] = useState('')
  const [supportInfo, setSupportInfo] = useState({ number: '', email: '' })
  let supportLink
  if (props.isCareerSchool && props.featureLinks.support_email) {
    supportLink = `mailto:${props.featureLinks.support_email}`
  }

  const userFeeCode = props.admissionData.user_fee_code || '';
  const makePaymentViaOfferLetterOnly = PAYMENT_VIA_OFFER_LETTER_ONLY_FEE_CODES.includes(userFeeCode);

  useEffect(() => {
    if (props.admissionData.payment_status && props.programData) {
      let messageText = ''
      const batchPaymentDetail: ProgramAdmissionData =
        props.admissionData.payment_status[props.programData.id]
      setSupportInfo({
        number: batchPaymentDetail.payment_info.support_phone,
        email: batchPaymentDetail.payment_info.support_email,
      })

      const paymentInstruction = makePaymentViaOfferLetterOnly
             ? "Please make the payment through the offer letter payment link to resume learning."
             : "Please make the payment to resume learning.";

      messageText = paymentInfoMessage(batchPaymentDetail, 'due_installments')
      const finalMessage = `${messageText}. ${paymentInstruction}`;

      setMessage(finalMessage)
    } else if (props.admissionData.access_blocked_reason) {
      if (props.admissionData.access_blocked_reason === 'Deferred')
        setMessage('Your enrollment into the program has been put on hold')
      if (props.admissionData.access_blocked_reason === 'Drop-Out')
        setMessage('Your enrollment into the program has been discontinued')
    }
  }, [])
  return (
    <Fragment>
      <ErrorContainer>
        <div className={styles.message}>
          <Typography variant="h2" className={styles.title} gutterBottom>
            Access blocked
          </Typography>

          <Typography variant="body1" gutterBottom>
            {message}
          </Typography>
          {props.admissionData.payment_status ? (
            <Typography variant="body2" className={styles.helpText}>
              In case of any queries, kindly reach out to us at&nbsp;
              {props.isCareerSchool ? null : <>{supportInfo.number} or&nbsp;</>}
              <Link
                href={`mailto:${supportInfo.email}`}
                className={styles.emailLink}
                target="_blank"
              >
                {supportInfo.email}
              </Link>
              .
            </Typography>
          ) : null}
        </div>
        <Box
          display="flex"
          flexDirection="column"
          textAlign="center"
          padding={1}
        >
          {props.admissionData.payment_status && !makePaymentViaOfferLetterOnly ? (
            <RouterLink
              to={generateURL('FEE_PAYMENT', null)}
              className={styles.emailLink}
            >
              <Button variant="contained" rounded color="primary" size="medium">
                MAKE PAYMENT
              </Button>
            </RouterLink>
          ) : null}
          {props.programData
            ?.disable_program_support ? null : props.isNewProgramSupportEnabled ? (
            <RouterLink
              color="primary"
              className={styles.link}
              to={generateURL('PROGRAM_SUPPORT', null)}
            >
              <Typography color="primary">NEED HELP?</Typography>
            </RouterLink>
          ) : (
            <Link
              href={
                props.isCareerSchool && supportLink
                  ? supportLink
                  : props.admissionData.program_support_link
              }
              color="primary"
              className={styles.link}
            >
              <Typography color="primary">NEED HELP?</Typography>
            </Link>
          )}
        </Box>
      </ErrorContainer>
      {props.glAcademyId !== null && !props.isCareerSchool ? (
        <GLAStrip
          onClick={() => {
            props.updateActiveProgram({
              programId: props.glAcademyId!,
              redirect: true,
            })
          }}
        />
      ) : null}
    </Fragment>
  )
}

export default AccessBlocked
 