import { Box, Dialog, DialogContent, Typography } from '@material-ui/core'
import React, { useState, Fragment } from 'react'
import PhoneInput from 'react-phone-input-2'
import cx from 'classnames'
import Loader from '../Utils/Loader'
import Button from '../Utils/Button'
import './ReferralConsentPhoneInput.css'
import styles from './FreeUserInfoDialog.module.css'
import { SurveyData } from './FreeUserInfoData'
import LoadFiles from '../Utils/LoadFiles'
import { validateMobileNumberWithCountryCode } from 'common/utils'

interface Props {
  isLoading: boolean
  onSubmit: (data: SurveyData | {}) => void
  referralTitle: string | null
  referralPhone: string | null
  workExperience: string
}

interface PhoneInputProps {
  valid: boolean
  value: string
  countryCode: string
  formattedValue: string
}

const ReferralConsentDialog = (props: Props) => {
  const [phoneData, setPhoneData] = useState<PhoneInputProps>({
    valid: true,
    value: props.referralPhone || '',
    countryCode: '',
    formattedValue: '',
  })

  return (
    <Dialog open classes={{ paper: styles.referralDialog }}>
      <DialogContent className={styles.referralDialogContent}>
        {props.isLoading ? (
          <Loader />
        ) : (
          <LoadFiles
            srcs={[
              {
                src:
                  'https://cdn.jsdelivr.net/npm/google-libphonenumber@3.2.14/dist/libphonenumber.min.js',
                type: 'text/javascript',
                mandatory: true,
              },
            ]}
          >
            <Fragment>
              <Typography className={styles.referralDialogTitle}>
                {props.referralTitle}
              </Typography>
              <Box className={styles.referralContentContainer}>
                <Typography className={styles.referralSubTitle}>
                  Want to join them? Get in touch with us to learn more
                </Typography>
                <PhoneInput
                  country={'us'}
                  isValid={() => {
                    return phoneData.valid == null || !!phoneData.valid
                  }}
                  value={props.referralPhone}
                  inputClass={cx(styles.textField, styles.phoneInputField)}
                  specialLabel="Phone Number"
                  inputProps={{
                    id: 'mobile',
                    name: 'mobile',
                    autoFocus: true,
                  }}
                  onFocus={(
                    e: React.ChangeEvent<HTMLInputElement>,
                    country: any
                  ) => {
                    setPhoneData({
                      ...phoneData,
                      countryCode: country.countryCode,
                      formattedValue: e.target.value,
                    })
                  }}
                  onBlur={() => {
                    setPhoneData({
                      ...phoneData,
                      valid:
                        // @ts-ignore
                        window.libphonenumber &&
                        validateMobileNumberWithCountryCode(
                          phoneData.countryCode,
                          phoneData.formattedValue,
                          // @ts-ignore
                          window.libphonenumber
                        ),
                    })
                  }}
                  onChange={(
                    value: string,
                    country: any,
                    e: React.ChangeEvent<HTMLInputElement>,
                    formattedValue: string
                  ) => {
                    setPhoneData({
                      ...phoneData,
                      countryCode: country.countryCode,
                      formattedValue: formattedValue,
                      value: value,
                    })
                  }}
                />
                <Typography display="block">
                  {!!phoneData.value && !phoneData.valid ? (
                    <Typography
                      variant="caption"
                      color="error"
                      align="left"
                      className={styles.referralHelperTextContent}
                    >
                      {"Phone Number is not valid"}
                    </Typography>
                  ) : null}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  textTransform="uppercase"
                  onClick={() => {
                    if (
                      // @ts-ignore
                      window.libphonenumber &&
                      validateMobileNumberWithCountryCode(
                        phoneData.countryCode,
                        phoneData.formattedValue,
                        // @ts-ignore
                        window.libphonenumber
                      )
                    ) {
                      props.onSubmit({
                        pg_consent: 'true',
                        phone: phoneData.value,
                        work_experience: props.workExperience,
                      })
                    } else {
                      setPhoneData({
                        ...phoneData,
                        valid: false,
                      })
                    }
                  }}
                  className={cx(styles.button, styles.referralButton)}
                >
                  CALL ME
                </Button>
                <Button
                  color="primary"
                  textTransform="uppercase"
                  onClick={() =>
                    props.onSubmit({
                      pg_consent: 'false',
                      phone: phoneData.value,
                      work_experience: props.workExperience,
                    })
                  }
                  className={cx(styles.button, styles.referralCancelButton)}
                >
                  NOT RIGHT NOW
                </Button>
              </Box>
            </Fragment>
          </LoadFiles>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ReferralConsentDialog
