import React, { useCallback, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { ErrorIllustrationsPropsType } from 'web/containers/Utils/ErrorIllustrations/ErrorIllustrations'
import { ReactComponent as NoAccessImg } from 'common/images/errorIllustrations/noAccess.svg'
import { ReactComponent as ProgramComplete } from 'common/images/errorIllustrations/programComplete.svg'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'web/store'
import { showGLLoader as showGLLoaderAction } from 'web/providers/Common/Common.actions'
import { useReloadPath } from 'web/hooks'
import styles from './ErrorIllustrations.module.css'
import {
  PageNotFoundIcon,
  ServerErrorIcon,
  UnauthorisedIcon,
  ErrorPageIcon,
  NoInternetIcon,
} from '../Icons/Icons'
import {
  logoutUser,
  UserDetailsState,
} from '../../../providers/User/UserDetailsProvider'
import Button from '../Button'
import Link from '../Link'

interface ErrorIllustrationsProps {
  type: ErrorIllustrationsPropsType
  onLogout: typeof logoutUser
  appSource: UserDetailsState['appSource']
}

const ErrorIllustrations = ({
  type,
  onLogout,
  appSource,
}: ErrorIllustrationsProps) => {
  const dispatch = useDispatch()
  const showGLLoader = useCallback(
    data => dispatch(showGLLoaderAction(data)),
    []
  )
  const reloadPath = useReloadPath()

  const icon = () => {
    switch (type) {
      case '404':
        return <PageNotFoundIcon />
      case '401':
        return <UnauthorisedIcon />
      case '500':
      case 'serverUnreachable':
        return <ServerErrorIcon />
      case 'network':
        return <NoInternetIcon />
      case 'error':
        return <ErrorPageIcon />
      case 'underMaintenance':
        return ''
      case 'serverUnavailable':
        return <ServerErrorIcon />
      case 'noAccess':
        return <NoAccessImg />
      case 'restrict_content':
        return <ProgramComplete />
      default:
        return <PageNotFoundIcon />
    }
  }

  const title = () => {
    switch (type) {
      case '404':
        return `404, Page not found`
      case '401':
        return `401, Unauthorised page`
      case '500':
        return `500, Internal server error`
      case 'network':
        return `No internet connection`
      case 'serverUnreachable':
        return `Server Unreachable`
      case 'error':
        return `Something went wrong`
      case 'underMaintenance':
        return <h1>We’ll be back soon!</h1>
      case 'serverUnavailable':
        return `Server Unavailable`
      case 'noAccess':
        return 'You don’t have permission to view this'
      case 'restrict_content':
        return 'This program is marked as complete'
      default:
        return `Sorry the page you asked couldn't be found`
    }
  }

  const underMaintenanceText = (
    <Typography variant="body1">
      <Typography variant="body1">
        We are busy working to make the experience even more awesome, and be
        back online after the short maintenance break
      </Typography>
      <Typography>
        <br />
      </Typography>
      <Typography variant="body1">
        We apologise for the inconvenience and appreciate your patience. Thank
        you for giving us the opportunity to support you in your learning
        journey!
      </Typography>
    </Typography>
  )
  const NoAccessText = () => {
    const admissionData = useSelector(
      (state: AppState) => state.user.admission.data
    )
    const supportLink = admissionData ? admissionData.program_support_link : ''
    return (
      <Typography variant="body1">
        <Typography variant="body1">
          You currently do not have view access to the content you requested.
        </Typography>
        {supportLink ? (
          <Typography variant="body1">
            If you think you should not see this,{' '}
            <Link
              href={supportLink}
              target="_blank"
              underline="always"
              className={styles.supportLink}
            >
              contact support
            </Link>
          </Typography>
        ) : null}
      </Typography>
    )
  }

  const text = () => {
    switch (type) {
      case '404':
        return `Sorry the page you asked for couldn't be found.`
      case '401':
        return `Please login again to access this page.`
      case '500':
        return `Looks like our servers have gone on learning journey of their own. We'll get them back soon.`
      case 'network':
        return `Please check your connection or try again`
      case 'serverUnreachable':
        return `Our server didn't respond. Try reloading the page`
      case 'error':
        return `Please try again later.`
      case 'underMaintenance':
        return underMaintenanceText
      case 'serverUnavailable':
        return `Server is unavailable at the moment. Please try again in some time.`
      case 'noAccess':
        return <NoAccessText />
      case 'restrict_content':
        return 'As per the program policy, you can no longer view the program course items and related contents, please reach out to the program office if you need further clarifications'
      default:
        return `Sorry the page you asked for couldn't be found.`
    }
  }

  useEffect(() => {
    showGLLoader({ show: false })
  }, [])

  return (
    <div className={styles.container}>
      {icon()}
      <Typography variant="h2" className={styles.title}>
        {title()}
      </Typography>
      <Typography className={styles.subText}>{text()}</Typography>
      {(type === '404' || type === 'restrict_content') &&
      appSource !== 'mobile_app' ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            reloadPath('DASHBOARD')
          }}
        >
          <Typography color="textSecondary" variant="button">
            Visit dashboard
          </Typography>
        </Button>
      ) : null}
      {type === '401' && onLogout ? (
        <Button onClick={() => onLogout()} variant="contained" color="primary">
          <Typography color="textSecondary">LOGIN AGAIN</Typography>
        </Button>
      ) : null}
      {type === 'network' || type === 'serverUnreachable' ? (
        <Button
          onClick={() => reloadPath()}
          variant="contained"
          color="primary"
        >
          <Typography color="textSecondary">RETRY</Typography>
        </Button>
      ) : null}
    </div>
  )
}

export default ErrorIllustrations
