export const ITEM_CONTENT_FETCH = 'ITEM_CONTENT_FETCH'
export const ITEM_CONTENT_FETCH_SUCCESS = 'ITEM_CONTENT_FETCH_SUCCESS'
export const ITEM_CONTENT_FETCH_FAILURE = 'ITEM_CONTENT_FETCH_FAILURE'
export const ITEM_CONTENT_FETCH_CANCEL = 'ITEM_CONTENT_FETCH_CANCEL'

export const ASSESSMENT_PROCTOR_CONTENT_FETCH =
  'ASSESSMENT_PROCTOR_CONTENT_FETCH'
export const ASSESSMENT_PROCTOR_CONTENT_FETCH_SUCCESS =
  'ASSESSMENT_PROCTOR_CONTENT_FETCH_SUCCESS'
export const ASSESSMENT_PROCTOR_CONTENT_FETCH_FAILURE =
  'ASSESSMENT_PROCTOR_CONTENT_FETCH_FAILURE'
export const ASSESSMENT_PROCTOR_CONTENT_FETCH_CANCEL =
  'ASSESSMENT_PROCTOR_CONTENT_FETCH_CANCEL'

export const MODULE_ITEM_ACTIVITY_POST = 'MODULE_ITEM_ACTIVITY_POST'
export const MODULE_ITEM_ACTIVITY_CANCEL = 'MODULE_ITEM_ACTIVITY_CANCEL'
