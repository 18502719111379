import { CourseID } from 'common/types/courses'
import { ModuleID } from 'common/types'
import * as actions from './ExternalUrl.types'
import { ItemID } from '../../../../../common/types/courses/moduleItem'

export interface LinkPageViewPayload {
  courseId: CourseID
  itemId: ItemID
  moduleId: ModuleID
  disableLogAssetAccess?: boolean
}
interface LinkPageViewMetaParams {
  itemId: ItemID
}

export const updateLinkPageView = (
  payload: LinkPageViewPayload,
  meta: LinkPageViewMetaParams
) => ({
  type: actions.UPDATE_LINK_PAGE_VIEW as typeof actions.UPDATE_LINK_PAGE_VIEW,
  payload,
  meta,
})

export const updateLinkPageViewSuccess = (
  payload: any,
  meta: LinkPageViewMetaParams
) => ({
  type: actions.UPDATE_LINK_PAGE_VIEW_SUCCESS as typeof actions.UPDATE_LINK_PAGE_VIEW_SUCCESS,
  payload,
  meta,
})
export const updateLinkPageViewFailure = (
  payload: Error,
  meta: LinkPageViewMetaParams
) => ({
  type: actions.UPDATE_LINK_PAGE_VIEW_FAILURE as typeof actions.UPDATE_LINK_PAGE_VIEW_FAILURE,
  payload,
  meta,
})

export const updateLinkPageViewCancel = (
  payload: any,
  meta: LinkPageViewMetaParams
) => ({
  type: actions.UPDATE_LINK_PAGE_VIEW_CANCEL as typeof actions.UPDATE_LINK_PAGE_VIEW_CANCEL,
  payload,
  meta,
})

export type ExternalUrlActions =
  | ReturnType<typeof updateLinkPageView>
  | ReturnType<typeof updateLinkPageViewSuccess>
  | ReturnType<typeof updateLinkPageViewFailure>
  | ReturnType<typeof updateLinkPageViewCancel>
