export const formattedAmount = (amount: number | string) => {
  const amountInStr = amount.toString()

  let afterPoint = ''
  if (amountInStr.indexOf('.') > 0)
    afterPoint = amountInStr.substring(
      amountInStr.indexOf('.'),
      amountInStr.length
    )
  const rawAmount = Math.floor(parseInt(amountInStr, 0)).toString()

  let lastThree = rawAmount.substring(rawAmount.length - 3)
  const otherNumbers = rawAmount.substring(0, rawAmount.length - 3)
  if (otherNumbers !== '') lastThree = `,${lastThree}`
  const processedAmount =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint
  return processedAmount
}

export const revisedAmount = (amount: number | string, percentage: number | string) => {
  if (!Boolean(percentage)) {
    return formattedAmount(amount)
  }
  let amountInStr = amount.toString()
  let percentageStr = percentage.toString()
  let new_amount: number = Math.round(parseInt(amountInStr, 0) * (100 / parseFloat(percentageStr)))
  return formattedAmount(new_amount)
}
