import { Reducer } from 'redux'
import { FileActionTypes } from './Files.actions'
import {
  ADD_FILE,
  REMOVE_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  BATCH_UPLOAD_FILE,
  BATCH_UPLOAD_FILE_SUCCESS,
  BATCH_UPLOAD_FILE_FAILURE,
  BATCH_UPLOAD_FILE_CANCEL,
} from './Files.types'
import { FileID } from '../../../common/types'

export interface FileInfo {
  id: FileID
  file: File
  upload_id?: number
  error?: Error
}

export interface FileInfoById {
  byId: { [s in FileID]?: FileInfo }
}

export type FilesState = Readonly<{
  data: FileInfoById
  byContent: {
    [id in string]?: {
      data: FileID[]
      loading: boolean
      error: false | Error
    }
  }
}>

const intialStateFiles = {
  data: { byId: {} },
  byContent: {},
}

const initialStateByContent = {
  loading: false,
  error: false as false,
  data: [],
}

const removeFileId = (state: FilesState, fileId: FileID) => {
  const fileIdObject = state.data.byId
  delete fileIdObject[fileId]
  return fileIdObject
}

const filesReducer: Reducer<FilesState, FileActionTypes> = (
  state = intialStateFiles,
  action
): FilesState => {
  switch (action.type) {
    case ADD_FILE: {
      const fileContent = state.byContent[action.payload.dataKey]
      return {
        ...state,
        data: {
          byId: {
            ...state.data.byId,
            [action.payload.fileId]: {
              id: action.payload.fileId,
              file: action.payload.file,
            },
          },
        },
        byContent: {
          ...state.byContent,
          [action.payload.dataKey]: {
            ...initialStateByContent,
            ...fileContent,
            data: [
              ...(fileContent ? fileContent.data : []),
              action.payload.fileId,
            ],
          },
        },
      }
    }

    case REMOVE_FILE: {
      const removedFileIdData = removeFileId(state, action.payload.fileId)
      const fileContent = state.byContent[action.payload.dataKey]
      return {
        ...state,
        data: {
          byId: removedFileIdData,
        },
        byContent: {
          ...state.byContent,
          [action.payload.dataKey]: {
            ...initialStateByContent,
            ...fileContent,
            data:
              state.byContent && fileContent
                ? fileContent.data.filter(id => id !== action.payload.fileId)
                : [],
          },
        },
      }
    }

    case BATCH_UPLOAD_FILE: {
      const fileContent = state.byContent[action.payload.dataKey]
      return {
        ...state,
        byContent: {
          ...state.byContent,
          [action.payload.dataKey]: {
            ...initialStateByContent,
            ...fileContent,
            loading: true,
            error: false,
          },
        },
      }
    }
    case BATCH_UPLOAD_FILE_SUCCESS: {
      const fileContent = state.byContent[action.meta.dataKey]
      return {
        ...state,
        byContent: {
          ...state.byContent,
          [action.meta.dataKey]: {
            ...initialStateByContent,
            ...fileContent,
            loading: false,
            error: false,
          },
        },
      }
    }
    case BATCH_UPLOAD_FILE_FAILURE: {
      const fileContent = state.byContent[action.meta.dataKey]
      return {
        ...state,
        byContent: {
          ...state.byContent,
          [action.meta.dataKey]: {
            ...initialStateByContent,
            ...fileContent,
            loading: false,
            error: action.payload,
          },
        },
      }
    }

    case BATCH_UPLOAD_FILE_CANCEL: {
      const fileContent = state.byContent[action.meta.dataKey]
      return {
        ...state,
        byContent: {
          ...state.byContent,
          [action.meta.dataKey]: {
            ...initialStateByContent,
            ...fileContent,
            loading: false,
          },
        },
      }
    }

    case UPLOAD_FILE_SUCCESS: {
      const updateFileIdObject = state.data.byId[action.meta.fileId]
      if (updateFileIdObject) updateFileIdObject.upload_id = action.payload.id
      return {
        ...state,
        data: {
          byId: {
            ...state.data.byId,
            [action.meta.fileId]: updateFileIdObject,
          },
        },
      }
    }

    case UPLOAD_FILE_FAILURE: {
      const updateFileIdObject = state.data.byId[action.meta.fileId]
      if (updateFileIdObject) updateFileIdObject.error = action.payload
      return {
        ...state,
        data: {
          byId: {
            ...state.data.byId,
            [action.meta.fileId]: updateFileIdObject,
          },
        },
      }
    }

    default: {
      return state
    }
  }
}

export default filesReducer
