import React from 'react'
import styled from 'styled-components'
import { IconButton as MUIIconButton } from '@material-ui/core'

export const IconButton = styled(MUIIconButton)(() => ({
  borderRadius: '8px',
  padding: '9px 10px 9px 10px',
  '& .selected svg': {
    color: '#0054d6',
  },
  '& svg': {
    width: '20px',
    height: '20px',
    color: 'rgb(0, 0, 0)',
  },
  '&:hover': {
    backgroundColor: '#0054d614',
  },
  '&:active': {
    backgroundColor: '#45464f29',
  },
}))
