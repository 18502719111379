import { apiCall } from '../../../../../common/utils'
import {
  DiscussionEntry,
  DiscussionEntryID,
  DiscussionID,
} from '../../../../../common/types/courses/dq'
import { PartialObjectMap } from '../../../../../common/types/utils'
import { BaseUserData, GroupID } from '../../../../../common/types/user'
import { CourseID } from '../../../../../common/types/courses'

export type DiscussionEntryAPIData = DiscussionEntry & {
  replies?: DiscussionEntryAPIData[] | null
}

export interface DiscussionEntryViewAPIData {
  entry_ratings?: PartialObjectMap<DiscussionEntryID, 0 | 1>
  unread_entries?: DiscussionEntryID[] | null
  participants: BaseUserData[]
  view: DiscussionEntryAPIData[]
}

async function getDiscussionEntries({
  courseId,
  discussionId,
}: {
  courseId: CourseID
  discussionId: DiscussionID
}): Promise<DiscussionEntryViewAPIData> {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/discussion_topics/${discussionId}/view`,
    query: {
      include_new_entries: 1,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

async function getDiscussionEntry({
  courseId,
  discussionId,
}: {
  courseId: CourseID
  discussionId: DiscussionID
}) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/discussion_topics/${discussionId}`,
    query: {
      include_new_entries: 1,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

async function postDiscussionEntry({
  courseId,
  discussionId,
  message,
  attachment,
}: {
  courseId: CourseID
  discussionId: DiscussionID
  message: string
  attachment?: File
}): Promise<DiscussionEntry> {
  const formData = new FormData()
  formData.append('message', message)
  if (attachment) {
    formData.append('attachment', attachment, attachment.name)
  }
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/discussion_topics/${discussionId}/entries`,
    params: { method: 'POST', body: formData },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

async function postDiscussionEntryReply({
  courseId,
  discussionId,
  parentDiscussionEntryId,
  message,
  attachment,
}: {
  courseId: CourseID
  discussionId: DiscussionID
  parentDiscussionEntryId: DiscussionEntryID
  message: string
  attachment?: File
}): Promise<DiscussionEntry> {
  const formData = new FormData()
  formData.append('message', message)
  if (attachment) {
    formData.append('attachment', attachment, attachment.name)
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/discussion_topics/${discussionId}/entries/${parentDiscussionEntryId}/replies`,
    params: { method: 'POST', body: formData },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

async function updateDiscussionEntry({
  courseId,
  discussionId,
  message,
  discussionEntryId,
}: {
  courseId: CourseID
  discussionId: DiscussionID
  discussionEntryId: DiscussionEntryID
  message: string
}): Promise<DiscussionEntry> {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/discussion_topics/${discussionId}/entries/${discussionEntryId}`,
    params: { method: 'PUT', body: JSON.stringify({ message }) },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

async function updateDiscussionEntryRating({
  courseId,
  discussionId,
  discussionEntryId,
  rating,
}: {
  courseId: CourseID
  discussionId: DiscussionID
  discussionEntryId: DiscussionEntryID
  rating: 1 | 0
}): Promise<DiscussionEntry> {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/discussion_topics/${discussionId}/entries/${discussionEntryId}/rating`,
    params: { method: 'POST', body: JSON.stringify({ rating }) },
  })
  if (response.ok) {
    if (response.status === 204) {
      return null
    }
    return response.json()
  }
  throw response
}

async function markEntryAsRead({
  courseId,
  discussionId,
  discussionEntryId,
}: {
  courseId: CourseID
  discussionId: DiscussionID
  discussionEntryId: DiscussionEntryID
}) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/discussion_topics/${discussionId}/entries/${discussionEntryId}/read`,
    params: { method: 'PUT' },
  })
  if (response.ok) {
    if (response.status === 204) {
      return null
    }
    return response.json()
  }
  throw response
}

async function markTopicAsRead({
  courseId,
  groupId,
  discussionId,
}: {
  courseId: CourseID | undefined
  groupId: GroupID | undefined
  discussionId: DiscussionID
}) {
  const response = courseId
    ? await apiCall({
        url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/discussion_topics/${discussionId}/read`,
        params: { method: 'PUT' },
      })
    : await apiCall({
        url: `${window.constants.REACT_APP_LMS_API_URL}v1/groups/${groupId}/discussion_topics/${discussionId}/read`,
        params: { method: 'PUT' },
      })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export default {
  getDiscussionEntries,
  postDiscussionEntry,
  postDiscussionEntryReply,
  updateDiscussionEntry,
  updateDiscussionEntryRating,
  markEntryAsRead,
  markTopicAsRead,
  getDiscussionEntry,
}
