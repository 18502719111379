import { Reducer } from 'redux'
import { AssignmentSubmissionActionTypes } from './AssignmentSubmissions.actions'
import * as types from './AssignmentSubmissions.types'
import { AssignmentModuleItemData } from '../../../../../../common/types/courses/assignment'
import { CommentsData } from '../../../../../../common/types'

export type AssignmentSubmissionsState = AssignmentModuleItemData['itemActivity']

export interface SubmissionCommentsState {
  data: CommentsData[]
  error: false | Error | Response
  loading: Boolean
}

const initialStateSubmissionComments: SubmissionCommentsState = {
  data: [],
  error: false,
  loading: false,
}

const initialState: AssignmentSubmissionsState = {
  status: null,
  submission: null,
}

const assignmentSubmissionReducer: Reducer<
  AssignmentSubmissionsState,
  AssignmentSubmissionActionTypes
> = (state = initialState, action): AssignmentSubmissionsState => {
  switch (action.type) {
    case types.SUBMIT_ASSIGNMENT: {
      return {
        ...state,
        status: 'submitting',
      }
    }
    case types.SUBMIT_ASSIGNMENT_SUCCESS: {
      return {
        ...state,
        status: action.payload.workflow_state,
      }
    }
    case types.SUBMIT_ASSIGNMENT_FAILURE: {
      return {
        ...state,
        status: 'error',
      }
    }

    case types.SUBMIT_ASSIGNMENT_CANCEL: {
      return {
        ...state,
        status: 'canceled',
      }
    }

    case types.GET_ASSIGNMENT_SUBMISSION: {
      return {
        ...state,
        status: 'fetching',
      }
    }

    case types.GET_ASSIGNMENT_SUBMISSION_SUCCESS: {
      return {
        ...state,
        status: 'fetched',
        submission: {
          ...state.submission,
          ...action.payload,
          submission_comments: {
            ...initialStateSubmissionComments,
            data: action.payload.submission_comments
              ? action.payload.submission_comments
              : [],
          },
        },
      }
    }

    case types.GET_ASSIGNMENT_SUBMISSION_FAILURE: {
      return {
        ...state,
        status: 'error',
        submission: action.payload,
      }
    }

    case types.GET_SUBMITTED_USER_DETAILS_SUCCESS: {
      const userData = action.payload
      return !state.submission || state.submission instanceof Error
        ? state
        : {
            ...state,
            submission: {
              ...state.submission,
              submittedUserDetails: {
                id: userData.id,
                name: userData.name,
                imageUrl: userData.avatar_url,
              },
            },
          }
    }

    case types.GET_EXTERNAL_TOOL_URL:
      return {
        ...state,
        externalToolUrl: 'fetching',
      }

    case types.GET_EXTERNAL_TOOL_URL_SUCCESS:
    case types.GET_EXTERNAL_TOOL_URL_FAILURE:
      return {
        ...state,
        externalToolUrl: action.payload,
      }

    case types.GET_ASSIGNMENT_META_DATA:
      return {
        ...state,
        is_external_submission: 'fetching',
      }

    case types.GET_ASSIGNMENT_META_DATA_SUCCESS:
      return {
        ...state,
        is_external_submission: action.payload.is_external_submission,
        assignment_custom_type: {
          type: action.payload.assignment_custom_type,
          test_state: action.payload.test_state,
          peer_rated: action.payload.peer_rated,
        },
        coding_lab_assignment_id: action.payload.coding_lab_assignment_id,
        coding_lab_allowed_attempts: action.payload.coding_lab_allowed_attempts,
        coding_lab_type: action.payload.coding_lab_type,
        coding_lab_current_attempt: action.payload.coding_lab_current_attempt,
        gl_lab_access_id: action.payload.gl_lab_access_id,
        on_track_grading_info: action.payload.on_track_data,
        assignment_has_context: action.payload.assignment_has_context,
        assignment_context_id: action.payload.assignment_context_id,
      }
    case types.GET_ASSIGNMENT_META_DATA_FAILURE:
      return {
        ...state,
        is_external_submission: action.payload,
      }

    case types.GET_ASSIGNMENT_AI_MENTOR_USER_DATA:
      return {
        ...state,
        assignment_ai_mentor_data: 'fetching',
      }

    case types.GET_ASSIGNMENT_AI_MENTOR_USER_DATA_SUCCESS:
      return {
        ...state,
        assignment_ai_mentor_data: {
          iframe_url: action.payload.iframe_url,
        },
      }

    case types.GET_ASSIGNMENT_AI_MENTOR_USER_DATA_FAILURE:
      return {
        ...state,
        assignment_ai_mentor_data: new Error('Failed to fetch AI mentor data'),
      }

    default:
      return state
  }
}

export default assignmentSubmissionReducer
