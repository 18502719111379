import { useDispatch } from 'react-redux'
import { stopJupyterLab, getJupyterNotebook } from 'web/providers/JupyterLabs'
import { JupyterLabStopReason } from 'web/providers/JupyterLabs/JupyterLabs.action'
import useIframeFocus from './useIframeFocus'

export const useJupyterLab = (
  embedCode: string,
  jupyter_lab_user_mapping_id: number
) => {
  const dispatch = useDispatch()

  const stopJupyterLabAction = (reason: JupyterLabStopReason) => {
    dispatch(
      stopJupyterLab({
        embedCode,
        lab_stop_reason: reason,
      })
    )
  }

  const getJupyterNotebookAction = () => {
    dispatch(
      getJupyterNotebook({
        embedCode,
      })
    )
  }
  const { isFocused, isCellExecuting } = useIframeFocus(
    jupyter_lab_user_mapping_id,
    'jupyterLabs',
    false,
    false,
    false,
    undefined,
    false,
    false
  )

  return {
    stopJupyterLabAction,
    getJupyterNotebookAction,
    isFocused,
    isCellExecuting,
  }
}

export default useJupyterLab
