import React, { Fragment } from 'react'
import PageTitle from '../../components/Utils/PageTitle'
import ErrorIllustrations from '../../containers/Utils/ErrorIllustrations'

const PageNotFound = () => (
  <Fragment>
    <PageTitle title="Page not found" />
    <ErrorIllustrations type="404" />
  </Fragment>
)

export default PageNotFound
