import { takeLatest, put, call, cancelled } from 'redux-saga/effects'
import {
  cancelable,
  apiCall,
  getUserDetails,
  getActiveProgramBatchId,
} from 'common/utils'
import {
  FETCH_COURSE_COMPLETION_CARDS,
  FETCH_COURSE_COMPLETION_CARDS_CANCEL,
  UPDATE_COURSE_COMPLETION_CARDS,
  UPDATE_COURSE_COMPLETION_CARDS_CANCEL,
} from './CourseCompletionCards.types'
import {
  fetchCourseCompletionCardsSuccess,
  fetchCourseCompletionCardsFailure,
  updateCourseCompletionCards,
  updateCourseCompletionCardsSuccess,
  updateCourseCompletionCardsFailure,
} from './CourseCompletionCards.actions'

export async function fetchCourseCompletionCardsAPI(signal: AbortSignal) {
  const { id } = getUserDetails()
  const pbId = getActiveProgramBatchId()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/course_completion_cards`,
    params: {
      signal,
    },
    query: {
      pb_id: pbId,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function updateCourseCompletionCardsAPI(
  signal: AbortSignal,
  action: ReturnType<typeof updateCourseCompletionCards>
) {
  const { id } = getUserDetails()
  const pbId = getActiveProgramBatchId()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/course_completion_cards/update`,
    params: {
      signal,
      body: JSON.stringify({ course_ids: action.payload }),
      method: 'POST',
    },
    query: {
      pb_id: pbId,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

function* fetchCourseCompletionCardsMiddlewareHandler(): any {
  const abortController = new AbortController()
  try {
    const data = yield call(
      fetchCourseCompletionCardsAPI,
      abortController.signal
    )
    yield put(fetchCourseCompletionCardsSuccess(data))
  } catch (e) {
    yield put(fetchCourseCompletionCardsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* updateCourseCompletionCardsMiddlewareHandler(
  action: ReturnType<typeof updateCourseCompletionCards>
): any {
  const abortController = new AbortController()

  try {
    const data = yield call(
      updateCourseCompletionCardsAPI,
      abortController.signal,
      action
    )
    yield put(updateCourseCompletionCardsSuccess(action.payload))
  } catch (e) {
    yield put(updateCourseCompletionCardsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fetchcourseCompletionCardsMiddleware() {
  yield takeLatest(
    FETCH_COURSE_COMPLETION_CARDS,
    cancelable(
      fetchCourseCompletionCardsMiddlewareHandler,
      FETCH_COURSE_COMPLETION_CARDS_CANCEL
    )
  )
}

export function* updateCourseCompletionCardsMiddleware() {
  yield takeLatest(
    UPDATE_COURSE_COMPLETION_CARDS,
    cancelable(updateCourseCompletionCardsMiddlewareHandler, [
      UPDATE_COURSE_COMPLETION_CARDS_CANCEL,
    ])
  )
}

export default ([] as any).concat(
  fetchcourseCompletionCardsMiddleware(),
  updateCourseCompletionCardsMiddleware()
)
