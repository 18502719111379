import React, { useEffect, useRef } from 'react'
import { Grid, Typography} from '@material-ui/core'
import { updateActiveProgram } from 'web/providers/Dashboard/ProgramsProvider'
import { ProgramID } from 'common/types/programs'
import { generateURL } from 'common/utils'
import { mixpanel } from 'common/utils/mixpanel'
import MailIcon from '@material-ui/icons/Mail'
import styles from './CareerSchoolSnackBar.module.css'
import LinkButton from '../Utils/LinkButton'

const CareerSchoolSnackBar = ({
  enrollTrackPbId,
  updateCurrentProgram,
  isTrackPage,
}: {
  enrollTrackPbId: ProgramID
  updateCurrentProgram: typeof updateActiveProgram
  isTrackPage: boolean
}) => {

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const root = document.documentElement
    const mobileAppBannerDiv = ref.current

    if (mobileAppBannerDiv) {
      if (mobileAppBannerDiv.getBoundingClientRect().height) {
        root.style.setProperty(
          '--header-height',
          `${mobileAppBannerDiv.getBoundingClientRect().height + 64}px`
        )
      } else {
        root.style.setProperty('--header-height', `96px`)
      }
    } else root.style.setProperty('--header-height', '64px')
  }, [])


  const EnrollNowUrl =
    // eslint-disable-next-line no-nested-ternary
    enrollTrackPbId > 0
      ? isTrackPage
        ? generateURL('FEE_PAYMENT', null)
        : generateURL('FEE_PAYMENT', null, {
            pb_id: enrollTrackPbId,
          })
      : generateURL('DASHBOARD', null)

  const UpgradeClick = (event: any) => {
    if (enrollTrackPbId > 0 && !isTrackPage) {
      event.stopPropagation()
      updateCurrentProgram({ programId: enrollTrackPbId })
    }
    mixpanel.track('CS_Snackbar_EnrollClick', {})
  }

  return (
    <div ref={ref} className={styles.csContainer}>
      <Grid container>
        <Grid item lg={10} md={10} xs={12} sm={12}>
          <Typography className={styles.careerSchoolQueryHeader}>
            ✨ You are on a free trial to have complete access to CareerSchool
            {'  '}
            <LinkButton
              to={EnrollNowUrl}
              size="small"
              color="primary"
              variant="contained"
              onClick={UpgradeClick}
            >
              <Typography className={styles.enrollButtonHeaderText}>
                ENROLL NOW
              </Typography>
            </LinkButton>
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} xs={12} sm={12}>
          <div className={styles.contactUs}>
            <Typography className={styles.getHelp}>Get Help</Typography>
            <a
              href="mailto:careerschool-support@greatlearning.in"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.helpUsIcons}
            >
              <MailIcon />
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default CareerSchoolSnackBar
