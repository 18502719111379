import { takeLeading, select, put, call } from 'redux-saga/effects'
import * as types from './UserOnboarding.types'
import * as APIs from './UserOnboarding.api'
import { userDetailsSelectors } from '../UserDetailsProvider'
import * as actions from './UserOnboarding.actions'
import { showAlertMessage } from '../../AlertsProvider'

function* userOnboardingDataFetchHandler() {
  try {
    const userId = yield select(userDetailsSelectors.getUserID())
    if (userId) {
      const data = yield call(APIs.fetchUserOnboardingDataAPI, {
        userId,
      })
      yield put(actions.fetchUserOnboardingDetailsSuccess(data))
    } else {
      throw new Error('User data not available')
    }
  } catch (e) {
    yield put(actions.fetchUserOnboardingDetailsFailure(e))
  }
}

function* preferencesQuestionsFetchHandler() {
  try {
    const userId = yield select(userDetailsSelectors.getUserID())
    if (userId) {
      const data = yield call(APIs.fetchPreferencesQuestionsAPI, { userId })
      yield put(actions.fetchPreferencesQuestionsSuccess(data))
    } else {
      throw new Error('User data not available')
    }
  } catch (e) {
    yield put(actions.fetchUserOnboardingDetailsFailure(e))
  }
}

function* excelerateOnboardingDataFetchHandler() {
  try {
    const userId = yield select(userDetailsSelectors.getUserID())
    if (userId) {
      const data = yield call(APIs.fetchExcelerateOnboardingDataAPI, { userId })
      yield put(actions.fetchExcelerateOnboardingDataSuccess(data))
    } else {
      throw new Error('Failed to fetch onboarding question details')
    }
  } catch (e) {
    yield put(actions.fetchUserOnboardingDetailsFailure(e))
  }
}

function* userOnboardingDataUpdateHandler(
  action: ReturnType<typeof actions.updateUserOnboardingDetails>
) {
  try {
    const userId = yield select(userDetailsSelectors.getUserID())
    if (userId) {
      const data = yield call(APIs.updateUserOnboardingDataAPI, {
        userId,
        ...action.payload,
      })
      yield put(
        actions.updateUserOnboardingDetailsSuccess({
          ...action.payload,
          ...data,
        })
      )
    } else {
      throw new Error('Failed to update onboarding details')
    }
  } catch (e) {
    yield put(actions.updateUserOnboardingDetailsFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Failed to update your information',
      })
    )
  }
}

function* userOnboardingMiddleware() {
  yield takeLeading(
    types.USER_ONBOARDING_DETAILS_FETCH,
    userOnboardingDataFetchHandler
  )
  yield takeLeading(
    types.PREFERENCES_QUESTIONS_FETCH,
    preferencesQuestionsFetchHandler
  )
  yield takeLeading(
    types.EXCELERATE_ONBOARDING_DATA_FETCH,
    excelerateOnboardingDataFetchHandler
  )
  yield takeLeading(
    types.USER_ONBOARDING_DETAILS_UPDATE,
    userOnboardingDataUpdateHandler
  )
}

export default ([] as any).concat(userOnboardingMiddleware())
