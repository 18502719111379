import {
  FETCH_TRANSACTION_STATUS,
  USER_TRANSACTION_STATUS_SUCCESS,
  USER_TRANSACTION_STATUS_FAILURE,
} from './PaymentStatus.types'

import { TransactionStatusData } from '../../../../common/types/user'

export const fetchTransactonStatus = (
  txn_id: string | null | undefined | string[]
) => ({
  type: FETCH_TRANSACTION_STATUS as typeof FETCH_TRANSACTION_STATUS,
  txn_id,
})

export const UserTransactionStatusSuccess = (
  payload: TransactionStatusData
) => ({
  type: USER_TRANSACTION_STATUS_SUCCESS as typeof USER_TRANSACTION_STATUS_SUCCESS,
  payload,
})

export const UserTransactionStatusFailure = (payload: Error) => ({
  type: USER_TRANSACTION_STATUS_FAILURE as typeof USER_TRANSACTION_STATUS_FAILURE,
  payload,
})

export type PaymentStatusActions =
  | ReturnType<typeof fetchTransactonStatus>
  | ReturnType<typeof UserTransactionStatusSuccess>
  | ReturnType<typeof UserTransactionStatusFailure>
