import { apiCall, getUserDetails } from 'common/utils'
import {
  fetchFaqs,
  addNewFaq,
  editFaq,
  deleteFaq,
} from './ProgramSupportFaqs.actions'

export async function fetchFaqsAPI(
  action: ReturnType<typeof fetchFaqs>,
  programId: number,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/program_support/faqs/${programId}/search`,
    params: {
      signal,
    },
    query: {
      ...action.payload,
      student_id: id,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function fetchNinjaFaqsAPI(
  action: ReturnType<typeof fetchFaqs>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/program_support/faqs/search`,
    params: {
      signal,
    },
    query: {
      ...action.payload,
      student_id: id,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function fetchIndividualFaqsAndRecoomendationAPI(
  faqId: number,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/program_support/faqs/${faqId}`,
    params: {
      signal,
    },
    query: {
      student_id: id,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function addNewFaqAPI(
  action: ReturnType<typeof addNewFaq>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const body = JSON.stringify({
    ...action.payload,
    userId: id,
  })
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/program_support/faqs/`,
    params: {
      signal,
      method: 'POST',
      body,
    },
    query: {
      student_id: id,
    },
    auth: true,
    excludeProgramId: true,
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function editFaqAPI(
  action: ReturnType<typeof editFaq>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const faqId = action.payload.id

  if (action.payload.id) {
    // eslint-disable-next-line no-param-reassign
    delete action.payload.id
  }
  const body = JSON.stringify({
    ...action.payload,
    userId: id,
  })
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/program_support/faqs/${faqId}`,
    params: {
      signal,
      method: 'PUT',
      body,
    },
    query: {
      student_id: id,
    },
    auth: true,
    excludeProgramId: true,
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function deleteFaqAPI(
  action: ReturnType<typeof deleteFaq>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const faqId = action.payload

  const body = JSON.stringify({
    userId: id,
  })
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/program_support/faqs/${faqId}`,
    params: {
      signal,
      method: 'DELETE',
      body,
    },
    query: {
      student_id: id,
    },
    auth: true,
    excludeProgramId: true,
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}
