import * as types from './ExternalLabs.types'

export interface ExternalLabPayload {
  ExternalLabId?: string
  subscriptionId?: string
  queryParams?: any
}

export const startExternalLabs = (payload: ExternalLabPayload) => ({
  type: types.START_EXTERNAL_LABS as typeof types.START_EXTERNAL_LABS,
  payload,
})

export const startExternalLabsSuccess = (payload: any) => ({
  type: types.START_EXTERNAL_LABS_SUCCESS as typeof types.START_EXTERNAL_LABS_SUCCESS,
  payload,
})

export const startExternalLabsFailure = (payload: Error) => ({
  type: types.START_EXTERNAL_LABS_FAILURE as typeof types.START_EXTERNAL_LABS_FAILURE,
  payload,
})

export const startExternalLabsCancel = (payload: any) => ({
  type: types.START_EXTERNAL_LABS_CANCEL as typeof types.START_EXTERNAL_LABS_CANCEL,
  payload,
})

export const stopExternalLabs = (payload: ExternalLabPayload) => ({
  type: types.STOP_EXTERNAL_LABS as typeof types.STOP_EXTERNAL_LABS,
  payload,
})

export const stopExternalLabsSuccess = (payload: any) => ({
  type: types.STOP_EXTERNAL_LABS_SUCCESS as typeof types.STOP_EXTERNAL_LABS_SUCCESS,
  payload,
})

export const stopExternalLabsFailure = (payload: Error) => ({
  type: types.STOP_EXTERNAL_LABS_FAILURE as typeof types.STOP_EXTERNAL_LABS_FAILURE,
  payload,
})

export const stopExternalLabsCancel = (payload: any) => ({
  type: types.STOP_EXTERNAL_LABS_CANCEL as typeof types.STOP_EXTERNAL_LABS_CANCEL,
  payload,
})

export const pingExternalLabs = (payload: ExternalLabPayload) => ({
  type: types.PING_EXTERNAL_LABS_STATUS as typeof types.PING_EXTERNAL_LABS_STATUS,
  payload,
})

export const pingExternalLabsSuccess = (payload: any) => ({
  type: types.PING_EXTERNAL_LABS_STATUS_SUCCESS as typeof types.PING_EXTERNAL_LABS_STATUS_SUCCESS,
  payload,
})

export const pingExternalLabsFailure = (payload: Error) => ({
  type: types.PING_EXTERNAL_LABS_STATUS_FAILURE as typeof types.PING_EXTERNAL_LABS_STATUS_FAILURE,
  payload,
})

export const pingExternalLabsCancel = (payload: any) => ({
  type: types.PING_EXTERNAL_LABS_STATUS_CANCEL as typeof types.PING_EXTERNAL_LABS_STATUS_CANCEL,
  payload,
})

export const launchExternalLabs = (payload: ExternalLabPayload) => ({
  type: types.LAUNCH_EXTERNAL_LABS as typeof types.LAUNCH_EXTERNAL_LABS,
  payload,
})

export const launchExternalLabsSuccess = (payload: any) => ({
  type: types.LAUNCH_EXTERNAL_LABS_SUCCESS as typeof types.LAUNCH_EXTERNAL_LABS_SUCCESS,
  payload,
})

export const launchExternalLabsFailure = (payload: Error) => ({
  type: types.LAUNCH_EXTERNAL_LABS_FAILURE as typeof types.LAUNCH_EXTERNAL_LABS_FAILURE,
  payload,
})

export const launchExternalLabsCancel = (payload: any) => ({
  type: types.LAUNCH_EXTERNAL_LABS_CANCEL as typeof types.LAUNCH_EXTERNAL_LABS_CANCEL,
  payload,
})

export const validExternalLabs = (payload: any) => ({
  type: types.VALID_EXTERNAL_LABS as typeof types.VALID_EXTERNAL_LABS,
  payload,
})

export const validExternalLabsSuccess = (payload: any) => ({
  type: types.VALID_EXTERNAL_LABS_SUCCESS as typeof types.VALID_EXTERNAL_LABS_SUCCESS,
  payload,
})

export const validExternalLabsFailure = (payload: any) => ({
  type: types.VALID_EXTERNAL_LABS_FAILURE as typeof types.VALID_EXTERNAL_LABS_FAILURE,
  payload,
})

export const validExternalLabsCancel = (payload: any) => ({
  type: types.VALID_EXTERNAL_LABS_CANCEL as typeof types.VALID_EXTERNAL_LABS_CANCEL,
  payload,
})

export type ExternalLabsActionTypes =
  | ReturnType<typeof startExternalLabs>
  | ReturnType<typeof startExternalLabsSuccess>
  | ReturnType<typeof startExternalLabsFailure>
  | ReturnType<typeof startExternalLabsCancel>
  | ReturnType<typeof stopExternalLabs>
  | ReturnType<typeof stopExternalLabsSuccess>
  | ReturnType<typeof stopExternalLabsFailure>
  | ReturnType<typeof stopExternalLabsCancel>
  | ReturnType<typeof pingExternalLabs>
  | ReturnType<typeof pingExternalLabsSuccess>
  | ReturnType<typeof pingExternalLabsFailure>
  | ReturnType<typeof pingExternalLabsCancel>
  | ReturnType<typeof launchExternalLabs>
  | ReturnType<typeof launchExternalLabsSuccess>
  | ReturnType<typeof launchExternalLabsFailure>
  | ReturnType<typeof launchExternalLabsCancel>
  | ReturnType<typeof validExternalLabs>
  | ReturnType<typeof validExternalLabsSuccess>
  | ReturnType<typeof validExternalLabsFailure>
  | ReturnType<typeof validExternalLabsCancel>
