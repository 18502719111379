import React from 'react'
import { useScript } from '../Hooks'
import Loader from '../Loader'
import ErrorIllustrations from '../../../containers/Utils/ErrorIllustrations'
import {
  ScriptFileProps,
  LinkFileProps,
} from '../../../../common/types/loadfiles'

interface LoadFilesProps {
  srcs: (ScriptFileProps | LinkFileProps)[]
  renderChildren?: boolean
  children: any
}


const LoadFiles = (props: LoadFilesProps) => {
  const { loading, error } = useScript(props.srcs)

  if (loading && !props.renderChildren) return <Loader />
  if (error) return <ErrorIllustrations type="error" />
  return props.children
}

export default LoadFiles
