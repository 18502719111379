import * as types from './UserOnboarding.types'
import {
  ExcelerateProfileData,
  PreferencesQuestions,
  UserOnboardingQuestionAnswer,
  UserIdentityDetails,
  UserOnboardingAPIData,
  ExcelerateOnboardingData,
  ExtraDetailsForUniversity,
  USUniversityDetails,
} from '../../../../common/types/user'
import { UserOnboardingState } from './UserOnboarding.reducer'

export const fetchUserOnboardingDetails = () => ({
  type: types.USER_ONBOARDING_DETAILS_FETCH as typeof types.USER_ONBOARDING_DETAILS_FETCH,
})

export const fetchUserOnboardingDetailsSuccess = (
  payload: UserOnboardingAPIData
) => ({
  type: types.USER_ONBOARDING_DETAILS_FETCH_SUCCESS as typeof types.USER_ONBOARDING_DETAILS_FETCH_SUCCESS,
  payload,
})

export const fetchUserOnboardingDetailsFailure = (payload: Error) => ({
  type: types.USER_ONBOARDING_DETAILS_FETCH_FAILURE as typeof types.USER_ONBOARDING_DETAILS_FETCH_FAILURE,
  payload,
})

export const fetchPreferencesQuestions = () => ({
  type: types.PREFERENCES_QUESTIONS_FETCH as typeof types.PREFERENCES_QUESTIONS_FETCH,
})

export const fetchPreferencesQuestionsSuccess = (payload: {
  questions: PreferencesQuestions[]
}) => ({
  type: types.PREFERENCES_QUESTIONS_FETCH_SUCCESS as typeof types.PREFERENCES_QUESTIONS_FETCH_SUCCESS,
  payload,
})

export const fetchPreferencesQuestionsFailure = (payload: Error) => ({
  type: types.PREFERENCES_QUESTIONS_FETCH_FAILURE as typeof types.PREFERENCES_QUESTIONS_FETCH_FAILURE,
  payload,
})

export const fetchExcelerateOnboardingData = () => ({
  type: types.EXCELERATE_ONBOARDING_DATA_FETCH as typeof types.EXCELERATE_ONBOARDING_DATA_FETCH,
})

export const fetchExcelerateOnboardingDataSuccess = (
  payload: ExcelerateOnboardingData
) => ({
  type: types.EXCELERATE_ONBOARDING_DATA_FETCH_SUCCESS as typeof types.EXCELERATE_ONBOARDING_DATA_FETCH_SUCCESS,
  payload,
})

export const fetchExcelerateOnboardingDataFailure = (payload: Error) => ({
  type: types.EXCELERATE_ONBOARDING_DATA_FETCH_FAILURE as typeof types.EXCELERATE_ONBOARDING_DATA_FETCH_FAILURE,
  payload,
})

type UserOnboardingPayload =
  | { policies_accepted: boolean }
  | { placement_policy_accepted: boolean }
  | { ip_protection_accepted: boolean; ip_digital_signature: string }
  | { linkedin_auth_opted_out: boolean }
  | { linkedin_public_profile_url: string }
  | {
      has_user_seen_testimonials_consent: boolean
      testimonials_consent_accepted: boolean
    }
  | {
      identity_source: string
      identity_source_proof: File
      identity_source_proof_password?: string
      identification_submitted: boolean
    }
  | { identity_details: UserIdentityDetails; identification_submitted: boolean }
  | { identification_completed: boolean }
  | { user_image: string; image_captured: boolean }
  | {
      show_excelerate_profile_questions: boolean
      excelerate_profile_details: ExcelerateProfileData
    }
  | {
      preference_answers: UserOnboardingQuestionAnswer[]
      show_preferences_question: boolean
    }
  | {
      enrollment_details: ExtraDetailsForUniversity
      ask_extra_details_for_university: boolean
    }
  | {
      participant_responsibility_accepted: boolean
      participant_responsibility_digital_signature: string
    }
  | {
      trademark_and_copyright_accepted: boolean
    }
  | {
      is_us_university_step_completed: boolean
      us_university_details: USUniversityDetails
    }
export const updateUserOnboardingDetails = (
  payload: UserOnboardingPayload
) => ({
  type: types.USER_ONBOARDING_DETAILS_UPDATE as typeof types.USER_ONBOARDING_DETAILS_UPDATE,
  payload,
})

export const updateUserOnboardingDetailsSuccess = (
  payload: UserOnboardingPayload
) => ({
  type: types.USER_ONBOARDING_DETAILS_UPDATE_SUCCESS as typeof types.USER_ONBOARDING_DETAILS_UPDATE_SUCCESS,
  payload,
})

export const updateUserOnboardingDetailsFailure = (payload: Error) => ({
  type: types.USER_ONBOARDING_DETAILS_UPDATE_FAILURE as typeof types.USER_ONBOARDING_DETAILS_UPDATE_FAILURE,
  payload,
})

export const updateUserOnboardingPage = (
  payload: UserOnboardingState['data']['currentPage']
) => ({
  type: types.USER_ONBOARDING_PAGE_UPDATE as typeof types.USER_ONBOARDING_PAGE_UPDATE,
  payload,
})
export const updateUserOnboardingRedirectUrl = (payload: { url: string }) => ({
  type: types.USER_ONBOARDING_REDIRECT_URL as typeof types.USER_ONBOARDING_REDIRECT_URL,
  payload,
})

export type UserOnboardingActionsType =
  | ReturnType<typeof fetchUserOnboardingDetails>
  | ReturnType<typeof fetchUserOnboardingDetailsSuccess>
  | ReturnType<typeof fetchUserOnboardingDetailsFailure>
  | ReturnType<typeof fetchPreferencesQuestions>
  | ReturnType<typeof fetchPreferencesQuestionsSuccess>
  | ReturnType<typeof fetchPreferencesQuestionsFailure>
  | ReturnType<typeof fetchExcelerateOnboardingData>
  | ReturnType<typeof fetchExcelerateOnboardingDataSuccess>
  | ReturnType<typeof fetchExcelerateOnboardingDataFailure>
  | ReturnType<typeof updateUserOnboardingDetails>
  | ReturnType<typeof updateUserOnboardingDetailsSuccess>
  | ReturnType<typeof updateUserOnboardingDetailsFailure>
  | ReturnType<typeof updateUserOnboardingPage>
  | ReturnType<typeof updateUserOnboardingRedirectUrl>
