import { FslDepartmentData } from 'common/types/learnAndDev/department'
import { Reducer } from 'redux'
import { DepartmentsActions } from './Departments.actions'
import {
  FETCH_ALL_DEPARTMENTS_CONTENT,
  FETCH_ALL_DEPARTMENTS_CONTENT_CANCEL,
  FETCH_ALL_DEPARTMENTS_CONTENT_FAILURE,
  FETCH_ALL_DEPARTMENTS_CONTENT_SUCCESS,
} from './Departments.types'

export interface FslDepartmentState {
  data: FslDepartmentData[]
  loading: boolean
  error: false | Error | Response
}

const initialState: FslDepartmentState = {
  data: [],
  loading: false,
  error: false,
}

const fslDepartmentsReducer: Reducer<FslDepartmentState, DepartmentsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_ALL_DEPARTMENTS_CONTENT:
      return { ...state, loading: true, error: false }

    case FETCH_ALL_DEPARTMENTS_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.departments,
      }

    case FETCH_ALL_DEPARTMENTS_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case FETCH_ALL_DEPARTMENTS_CONTENT_CANCEL:
      return {
        ...state,
        loading: false,
        error: false,
      }

    default:
      return state
  }
}

export default fslDepartmentsReducer
