import { QAData } from 'common/types/zoomout'
import { Reducer } from 'redux'
import { ZoomoutInternalActions, UPDATE_USER_INTERFACE } from '../Internal'
import { ZoomoutQAActions } from './QA.actions'
import {
  DELETE_QA_ACTION_SUCCESS,
  DELETE_QUESTION_ANSWER_SUCCESS,
  FETCH_QA_ENTRIES_SUCCESS,
  SUBMIT_QA_ACTION_SUCCESS,
  SUBMIT_QUESTION_ANSWER,
  SUBMIT_QUESTION_ANSWER_FAILURE,
  SUBMIT_QUESTION_ANSWER_SUCCESS,
  UPDATE_QUESTION_ANSWER,
  UPDATE_QUESTION_ANSWER_SUCCESS,
  UPDATE_UNREAD_COUNT,
} from './QA.types'

export interface ZoomoutQAState {
  loading: boolean
  error: false | Error
  data?: {
    [x in number]: QAData
  }
  unreadCount: number
}

const initialState: ZoomoutQAState = {
  loading: false,
  error: false,
  unreadCount: 0,
}

const normalizeQuestionsData = (
  currData: any,
  stateData: ZoomoutQAState['data']
) =>
  currData.reduce((acc: any, qa: QAData) => {
    const key = qa.id
    if (qa.parent_id) {
      acc[qa.parent_id] = {
        ...(acc[qa.parent_id] ? acc[qa.parent_id] : {}),
        answerIds:
          !acc[qa.parent_id].answerIds ||
          (acc[qa.parent_id].answerIds &&
            !acc[qa.parent_id].answerIds.includes(qa.id))
            ? [
                ...(acc[qa.parent_id].answerIds
                  ? acc[qa.parent_id].answerIds
                  : []),
                qa.id,
              ]
            : acc[qa.parent_id].answerIds,
      }
    }
    acc[key] = { ...(acc[key] ? acc[key] : {}), ...qa }

    return acc
  }, stateData)

const deleteQuestionAnswerEntry = (
  entryId: number,
  parentId: number | null,
  stateData: ZoomoutQAState['data']
) => {
  const newStateData: ZoomoutQAState['data'] = { ...stateData }
  if (newStateData && entryId in newStateData) {
    if ('answerIds' in newStateData[entryId]) {
      newStateData[entryId].answerIds!.forEach(answerId => {
        delete newStateData[answerId]
      })
    }
    delete newStateData[entryId]
  }
  if (parentId && parentId in newStateData) {
    newStateData[parentId] = {
      ...newStateData[parentId],
      answerIds:
        'answerIds' in newStateData[parentId]
          ? newStateData[parentId].answerIds!.filter(id => id !== entryId)
          : undefined,
    }
  }
  return newStateData
}

const zoomoutQAReducer: Reducer<
  ZoomoutQAState,
  ZoomoutQAActions | ZoomoutInternalActions
> = (state = initialState, action): ZoomoutQAState => {
  switch (action.type) {
    case FETCH_QA_ENTRIES_SUCCESS:
      return {
        ...state,
        data: normalizeQuestionsData(
          action.payload.questions,
          state.data || {}
        ),
      }

    case SUBMIT_QUESTION_ANSWER:
    case UPDATE_QUESTION_ANSWER:
      return {
        ...state,
        loading: true,
      }

    case SUBMIT_QUESTION_ANSWER_SUCCESS:
    case UPDATE_QUESTION_ANSWER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: normalizeQuestionsData(action.payload.data, state.data || {}),
      }

    case DELETE_QUESTION_ANSWER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: deleteQuestionAnswerEntry(
          action.payload.entryId,
          action.payload.parentId,
          state.data || {}
        ),
      }

    case SUBMIT_QA_ACTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.zoomout_qa_entry_id]: {
            ...((state.data
              ? state.data[action.payload.zoomout_qa_entry_id]
              : {}) as QAData),
            selfAction: action.meta
              ? action.meta.selfAction
              : (state.data
                  ? state.data[action.payload.zoomout_qa_entry_id]
                  : {}
                ).selfAction,
            actionCount:
              state.data &&
              state.data[action.payload.zoomout_qa_entry_id] &&
              state.data[action.payload.zoomout_qa_entry_id].actionCount
                ? state.data[action.payload.zoomout_qa_entry_id].actionCount! +
                  1
                : 1,
          },
        },
      }

    case DELETE_QA_ACTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.zoomout_qa_entry_id]: {
            ...((state.data
              ? state.data[action.payload.zoomout_qa_entry_id]
              : {}) as QAData),
            selfAction: action.meta
              ? action.meta.selfAction
              : (state.data
                  ? state.data[action.payload.zoomout_qa_entry_id]
                  : {}
                ).selfAction,
            actionCount:
              state.data &&
              state.data[action.payload.zoomout_qa_entry_id] &&
              state.data[action.payload.zoomout_qa_entry_id].actionCount
                ? state.data[action.payload.zoomout_qa_entry_id].actionCount! -
                  1
                : 0,
          },
        },
      }

    case SUBMIT_QUESTION_ANSWER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case UPDATE_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: state.unreadCount + action.payload.count,
      }

    case UPDATE_USER_INTERFACE:
      return {
        ...state,
        unreadCount: action.payload.drawer === 'qa' ? 0 : state.unreadCount,
      }

    default:
      return state
  }
}

export default zoomoutQAReducer
