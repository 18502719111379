import React from 'react'
import styled from 'styled-components'
import { Typography as MUITypography } from '@material-ui/core'

export const Typography = styled(MUITypography)(() => ({
  fontFamily: 'Inter',
  '&.body1': {
    fontSize: '16px',
    fontWeight: 400,
    color: '#45464f',
    letterSpacing: '0px',
    lineHeight: '24px',
  },
  '&.body2': {
    fontSize: '14px',
    fontWeight: 400,
    color: '#45464f',
    letterSpacing: '0px',
    lineHeight: '20px',
  },
  '&.caption': {
    fontSize: '12px',
    fontWeight: 400,
    color: '#45464f',
    letterSpacing: '-0.2px',
    lineHeight: '16px',
  },
  '&.h5': {
    fontSize: '18px',
    fontWeight: 600,
    color: '#1a1b1e',
    letterSpacing: '-0.4px',
    lineHeight: '24px',
  },
  '&.overline': {
    fontSize: '10px',
    fontWeight: '600',
    color: '#1a1b1e',
    letterSpacing: '1.2px',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  '&.subtitle2': {
    fontSize: '14px',
    fontWeight: '500',
    color: '#1a1b1e',
    letterSpacing: '0px',
    lineHeight: '24px',
  },
  '&.button': {
    '&.md': {
      color: '#45464f',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.4px',
      lineHeight: '20px',
    },
  },
  '&.selected': {
    color: 'rgb(0, 84, 214)',
  },
}))
