import { apiCall, getUserDetails } from '../../../../../../common/utils'
import {
  QuizID,
  QuizSubmissionID,
  QuizAttemptNo,
  QuestionID,
  QuizSubmissionEventType,
} from '../../../../../../common/types/courses/quiz'
import { CourseID } from '../../../../../../common/types/courses'

export async function getQuizSubmissionsAPI(
  data: { quizId: QuizID; courseId: CourseID },
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${data.courseId}/quizzes/${data.quizId}/submissions`,
    params: { signal },
  })
  if (response.ok) {
    const responseBody = await response.json()
    return responseBody
  }
  throw response
}

export async function completeQuizSubmissionAPI(data: {
  quizId: QuizID
  courseId: CourseID
  submissionId: QuizSubmissionID
  query: {
    validation_token: string
    attempt: QuizAttemptNo
  }
}) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${data.courseId}/quizzes/${data.quizId}/submissions/${data.submissionId}/complete`,
    query: data.query,
    params: { method: 'POST' },
  })
  if (response.ok) {
    return response.json()
  }
  if (response.status === 400 || response.status === 403) {
    const res = await response.json()
    if ('message' in res) {
      throw new Error(res.message)
    }
  }
  throw response
}

export async function startQuizSubmissionAPI(data: {
  quizId: QuizID
  courseId: CourseID
}) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${data.courseId}/quizzes/${data.quizId}/submissions`,
    params: { method: 'POST' },
  })
  if (response.ok) {
    return response.json()
  }
  if (response.status === 409 || response.status === 400) {
    const res = await response.json()
    if ('message' in res) {
      throw new Error(res.message)
    }
  }
  throw response
}

export async function trackQuizSubmissionEventsAPI(
  quiz_submission_id: QuizSubmissionID,
  question_id: QuestionID | null,
  event_type: QuizSubmissionEventType,
  in_focus: boolean,
  attempt: QuizAttemptNo,
  meta?: object
) {
  const { id: user_id } = getUserDetails()
  const body = JSON.stringify({
    user_id,
    event_type,
    in_focus,
    attempt,
    meta,
  })
  const res = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/quiz_submissions/${quiz_submission_id}/questions/${question_id}/track`,
    params: {
      method: 'POST',
      body,
    },
  })

  if (res.ok) return res.json()
  throw res
}
