import { UserID } from 'common/types/user'
import { apiCall } from 'common/utils'
import {
  fetchMeetingDetailsAPIParams,
  fetchUnknownPeerDetailsAPIParams,
  savePlainTextMessageAPIParams,
} from './Zoomout.actions'

export const ZOOMOUT_API_ENDPOINT = `${process.env.REACT_APP_ASPIRE_API_URL}v1/zoomout`

export const fetchMeetingDetailsAPI = async (
  params: fetchMeetingDetailsAPIParams,
  lmsUserId: UserID,
  signal: AbortSignal
) => {
  const { channel, authBypassToken } = params
  const response = await apiCall({
    url: `${ZOOMOUT_API_ENDPOINT}/init`,
    query: { channel, user_id: lmsUserId, authBypassToken },
    params: {
      signal,
      method: 'GET',
    },
  })
  if (response.status === 200) return response.json()
  throw await response.json()
}

export const fetchUnknownPeerDetailsAPI = async (
  params: fetchUnknownPeerDetailsAPIParams,
  channel: string,
  user_id: number,
  signal: AbortSignal
) => {
  const { unknownPeerUID } = params
  const response = await apiCall({
    url: `${ZOOMOUT_API_ENDPOINT}/peer_details`,
    query: {
      channel,
      uid: unknownPeerUID,
      user_id,
    },
    params: {
      signal,
      method: 'GET',
    },
  })
  if (response.status === 200) return response.json()
  throw await response.json()
}

export const savePlainTextMessageAPI = async (
  params: savePlainTextMessageAPIParams,
  channel: string,
  mentoredGroupSessionId: number,
  lmsUserId: UserID,
  signal: AbortSignal
) => {
  const body = JSON.stringify({
    user_id: lmsUserId,
    message: params.message,
    channel,
    mgsId: mentoredGroupSessionId,
    senderUid: params.senderUid,
    timestamp: new Date().toISOString(),
  })
  const response = await apiCall({
    url: `${ZOOMOUT_API_ENDPOINT}/chats`,
    params: {
      signal,
      method: 'POST',
      body,
    },
    query: {
      user_id: lmsUserId,
    },
  })
  if (response.status === 200) return response.json()
  throw await response.json()
}

export const fetchAttendanceAPI = async (
  uid: string,
  mentoredGroupSessionId: number,
  lmsUserId: UserID,
  signal: AbortSignal
) => {
  const response = await apiCall({
    url: `${process.env.REACT_APP_LTI_API_URL}v1/zoomout_attendance`,
    params: {
      signal,
    },
    query: {
      user_id: lmsUserId,
      mgsId: mentoredGroupSessionId,
      uid,
    },
  })
  if (response.status === 200) return response.json()
  throw await response.json()
}
