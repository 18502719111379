// Init api actions
export const FETCH_MEETING_DETAILS = 'FETCH_MEETING_DETAILS'
export const FETCH_MEETING_DETAILS_SUCCESS = 'FETCH_MEETING_DETAILS_SUCCESS'
export const FETCH_MEETING_DETAILS_FAILURE = 'FETCH_MEETING_DETAILS_FAILURE'
export const FETCH_MEETING_DETAILS_CANCEL = 'FETCH_MEETING_DETAILS_CANCEL'

// unknown peer meta api
export const FETCH_UNKNOWN_PEER_DETAILS = 'FETCH_UNKNOWN_PEER_DETAILS'
export const FETCH_UNKNOWN_PEER_DETAILS_SUCCESS =
  'FETCH_UNKNOWN_PEER_DETAILS_SUCCESS'
export const FETCH_UNKNOWN_PEER_DETAILS_CANCEL =
  'FETCH_UNKNOWN_PEER_DETAILS_CANCEL'

// get_screen_client api
export const FETCH_SCREEN_SHARING_INFO = 'FETCH_SCREEN_SHARING_INFO'

// save chat messages
export const SAVE_PLAIN_TEXT_MESSAGE = 'SAVE_PLAIN_TEXT_MESSAGE'
export const SAVE_PLAIN_TEXT_MESSAGE_SUCCESS = 'SAVE_PLAIN_TEXT_MESSAGE_SUCCESS'
export const SAVE_PLAIN_TEXT_MESSAGE_FAILURE = 'SAVE_PLAIN_TEXT_MESSAGE_FAILURE'
export const SAVE_PLAIN_TEXT_MESSAGE_CANCEL = 'SAVE_PLAIN_TEXT_MESSAGE_CANCEL'

// fetch attendance
export const FETCH_ATTENDANCE = 'FETCH_ATTENDANCE'
export const FETCH_ATTENDANCE_SUCCESS = 'FETCH_ATTENDANCE_SUCCESS'
export const FETCH_ATTENDANCE_FAILURE = 'FETCH_ATTENDANCE_FAILURE'
export const FETCH_ATTENDANCE_CANCEL = 'FETCH_ATTENDANCE_CANCEL'
