import { call, cancelled, put, takeEvery, select } from 'redux-saga/effects'
import { fetchProctorContentSuccess } from 'web/providers/ProctoringProvider/Proctoring.actions'
import { mixpanel } from 'common/utils/mixpanel'
import { cancelable } from '../../../../../common/utils'
import {
  fetchItemContent,
  fetchItemContentFailure,
  fetchItemContentSuccess,
  fetchAssessmentProctorContent,
  fetchAssessmentProctorContentFailure,
  fetchAssessmentProctorContentSuccess,
  postModuleItemActivity,
} from './ItemContent.actions'

import { getAssignmentMetaData } from '../Assignment/AssignmentSubmissionsProvider'
import {
  ITEM_CONTENT_FETCH,
  ITEM_CONTENT_FETCH_CANCEL,
  ASSESSMENT_PROCTOR_CONTENT_FETCH,
  ASSESSMENT_PROCTOR_CONTENT_FETCH_CANCEL,
  MODULE_ITEM_ACTIVITY_POST,
  MODULE_ITEM_ACTIVITY_CANCEL,
} from './ItemContent.types'
import { ModuleItemData } from '../../../../../common/types/courses/moduleItem'
import { AppState } from '../../../../store'
import {
  getItemContentAPI,
  getAssessmentProctorContentAPI,
  postModuleItemActivityAPI,
} from './ItemContent.api'

function* getItemContentHandler(action: ReturnType<typeof fetchItemContent>) {
  const abortController = new AbortController()
  try {
    let include: string[] = []
    const item: ModuleItemData | undefined = yield select(
      (state: AppState) => state.moduleItems.data.byId[action.meta.itemId]
    )
    if (item && item.type === 'File') include = ['temp_preview_url']

    const data = yield call(
      getItemContentAPI,
      action,
      include,
      abortController.signal
    )
    yield put(fetchItemContentSuccess(data, action.meta))
    if (item && item.type === 'Assignment') {
      yield put(
        fetchAssessmentProctorContent(
          { assessment_id: data.id, assessment_type: item.type },
          action.meta
        )
      )
      if (
        data &&
        data.id &&
        data.course_id &&
        !(item.itemActivity && 'is_external_submission' in item.itemActivity)
      ) {
        yield put(
          getAssignmentMetaData({
            contentId: data.id,
            courseId: data.course_id,
            itemId: item.id,
          })
        )
      }
    }
  } catch (e) {
    yield put(fetchItemContentFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* getAssessmentProctorContentHandler(
  action: ReturnType<typeof fetchAssessmentProctorContent>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      getAssessmentProctorContentAPI,
      action,
      abortController.signal
    )
    yield put(fetchAssessmentProctorContentSuccess(data, action.meta))
    yield put(fetchProctorContentSuccess(data, action.meta))
  } catch (e) {
    yield put(fetchAssessmentProctorContentFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* postModuleItemActivityHandler(
  action: ReturnType<typeof postModuleItemActivity>
) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      postModuleItemActivityAPI,
      action,
      abortController.signal
    )
  } catch (e) {
    mixpanel.track('Module Item Activity Track error', {
      error_message: `${e.message}_${e.name}`,
      error_action: 'module_item_activity',
    })
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* itemContentMiddleware() {
  yield takeEvery(
    ITEM_CONTENT_FETCH,
    cancelable(getItemContentHandler, ITEM_CONTENT_FETCH_CANCEL)
  )

  yield takeEvery(
    ASSESSMENT_PROCTOR_CONTENT_FETCH,
    cancelable(
      getAssessmentProctorContentHandler,
      ASSESSMENT_PROCTOR_CONTENT_FETCH_CANCEL
    )
  )

  yield takeEvery(
    MODULE_ITEM_ACTIVITY_POST,
    cancelable(postModuleItemActivityHandler, MODULE_ITEM_ACTIVITY_CANCEL)
  )
}

export default ([] as any).concat(itemContentMiddleware())
