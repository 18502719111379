import { Box, Popover, makeStyles } from '@material-ui/core'
import cx from 'classnames'
import { Typography } from 'common/elements'
import { useMedia } from 'common/elements/hooks'
import { isJSFunction } from 'common/utils'
import { ChevronDown } from 'lucide-react'
import React from 'react'
import { useSwitchBatch } from './useSwitchBatch'

const useBatchTriggerCTAStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#757680',
    borderRadius: '8px',
    padding: '5px 14px 5px 14px',
    maxWidth: 136,
    cursor: 'pointer',
    justifyContent: 'center',
    '&.large': {
      padding: '11px 24px 11px 24px',
      width: '100%',
      maxWidth: '100%',
    },
    '& svg': {
      width: '16px',
      height: '16px',
      flexShrink: 0,
      flexBasis: 'auto',
      marginLeft: '8px',
    },
    '& p': {
      display: 'flex',
      color: '#0054d6 !important',
      overflow: 'hidden',
      flexGrow: 0,
      userSelect: 'none',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      wordWrap: 'break-word',
    },
    '&:hover': {
      backgroundColor: '#0054d629',
    },
    '&:active': {
      backgroundColor: '#00184929',
    },
  },
})

const useBatchCTAStyles = makeStyles({
  container: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    padding: '16px',
    cursor: 'pointer',
    '& .description': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical',
    },
    '&:hover': {
      backgroundColor: '#f4f3f7',
    },
    '&.active': {
      backgroundColor: '#0054d614',
    },
  },
  selected: { color: '#0054d6 !important' },
})

function BatchCTA({ batch, onPress }: { batch: any; onPress: () => void }) {
  const styles = useBatchCTAStyles()
  return (
    <Box
      onClick={() => {
        if (isJSFunction(onPress)) {
          onPress()
        }
        batch.onPress()
      }}
      className={cx(styles.container, { active: batch.isSelected })}
    >
      <Box display="flex" flexDirection="column" flex={1}>
        <Typography
          className={cx(
            { [styles.selected]: batch.isSelected },
            'subtitle2 description'
          )}
        >
          {batch.displayName}
        </Typography>
        <Typography className={cx('caption description')}>
          {batch.description}
        </Typography>
      </Box>
    </Box>
  )
}

const BatchTrigger = ({
  title,
  onClick,
}: {
  title: string
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}) => {
  const styles = useBatchTriggerCTAStyles()
  const media = useMedia()

  return (
    <Box
      className={cx(styles.container, { large: media.lg })}
      onClick={onClick}
    >
      <Typography color="black" className={cx('button md')}>
        {title}
      </Typography>
      <ChevronDown flex="none" color="black" />
    </Box>
  )
}

export function SwitchBatch() {
  const { switchableBatches, selectedBatch } = useSwitchBatch()
  const media = useMedia()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {switchableBatches.length > 1 && (
        <>
          <BatchTrigger
            title={selectedBatch.shortHandDisplayName}
            onClick={handleClick}
          />
          <Popover
            style={{ marginTop: '20px' }}
            transitionDuration={0}
            elevation={1}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              maxHeight={400}
              padding={2}
              gridGap={8}
              overflow="hidden"
              width={media.gtLg ? 328 : 300}
            >
              <Box marginX={2}>
                <Typography className={cx('overline')} color="#001849">
                  Switch Program
                </Typography>
              </Box>
              <Box style={{ overflowY: 'scroll' }}>
                {switchableBatches.map(batch => (
                  <BatchCTA
                    key={batch.pbId}
                    batch={batch}
                    onPress={() => setAnchorEl(null)}
                  />
                ))}
              </Box>
            </Box>
          </Popover>
        </>
      )}
    </>
  )
}
