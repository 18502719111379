export const SUBMIT_RESET_PASSWORD = 'SUBMIT_RESET_PASSWORD'
export const SUBMIT_RESET_PASSWORD_SUCCESS = 'SUBMIT_RESET_PASSWORD_SUCCESS'
export const SUBMIT_RESET_PASSWORD_FAILURE = 'SUBMIT_RESET_PASSWORD_FAILURE'
export const SUBMIT_RESET_PASSWORD_CANCEL = 'SUBMIT_RESET_PASSWORD_CANCEL'
export const CLEAR_SUBMITTED_STATUS = 'CLEAR_SUBMITTED_STATUS'

export const FETCH_TIME_ZONES = 'FETCH_TIME_ZONES'
export const FETCH_TIME_ZONES_SUCCESS = 'FETCH_TIME_ZONES_SUCCESS'
export const FETCH_TIME_ZONES_FAILURE = 'FETCH_TIME_ZONES_FAILURE'
export const FETCH_TIME_ZONES_CANCEL = 'FETCH_TIME_ZONES_CANCEL'

export const SUBMIT_TIME_ZONE = 'SUBMIT_TIME_ZONE'
export const SUBMIT_TIME_ZONE_SUCCESS = 'SUBMIT_TIME_ZONE_SUCCESS'
export const SUBMIT_TIME_ZONE_FAILURE = 'SUBMIT_TIME_ZONE_FAILURE'
export const SUBMIT_TIME_ZONE_CANCEL = 'SUBMIT_TIME_ZONE_CANCEL'

export const SUBMIT_PERSONAL_DETAILS = 'SUBMIT_PERSONAL_DETAILS'
export const SUBMIT_PERSONAL_DETAILS_SUCCESS = 'SUBMIT_PERSONAL_DETAILS_SUCCESS'
export const SUBMIT_PERSONAL_DETAILS_FAILURE = 'SUBMIT_PERSONAL_DETAILS_FAILURE'
export const SUBMIT_PERSONAL_DETAILS_CANCEL = 'SUBMIT_PERSONAL_DETAILS_CANCEL'

export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE'
export const UPDATE_PROFILE_IMAGE_SUCCESS = 'UPDATE_PROFILE_IMAGE_SUCCESS'
export const UPDATE_PROFILE_IMAGE_FAILURE = 'UPDATE_PROFILE_IMAGE_FAILURE'
export const UPDATE_PROFILE_IMAGE_CANCEL = 'UPDATE_PROFILE_IMAGE_CANCEL'

export const TRIGGER_MOBILE_OTP = 'TRIGGER_MOBILE_OTP'
export const TRIGGER_MOBILE_OTP_SUCCESS = 'TRIGGER_MOBILE_OTP_SUCCESS'
export const TRIGGER_MOBILE_OTP_FAILURE = 'TRIGGER_MOBILE_OTP_FAILURE'
export const TRIGGER_MOBILE_OTP_CANCEL = 'TRIGGER_MOBILE_OTP_CANCEL'

export const SUBMIT_MOBILE_OTP = 'SUBMIT_MOBILE_OTP'
export const SUBMIT_MOBILE_OTP_SUCCESS = 'SUBMIT_MOBILE_OTP_SUCCESS'
export const SUBMIT_MOBILE_OTP_FAILURE = 'SUBMIT_MOBILE_OTP_FAILURE'
export const SUBMIT_MOBILE_OTP_CANCEL = 'SUBMIT_MOBILE_OTP_CANCEL'

export const UPDATE_PROFESSIONAL_DETAILS = 'UPDATE_PROFESSIONAL_DETAILS'
export const ALUMNI_MESSAGE_SWITCH = 'ALUMNI_MESSAGE_SWITCH'
export const UPDATE_PROFESSIONAL_DETAILS_SUCCESS =
  'UPDATE_PROFESSIONAL_DETAILS_SUCCESS'
export const UPDATE_PROFESSIONAL_DETAILS_FAILURE =
  'UPDATE_PROFESSIONAL_DETAILS_FAILURE'
export const UPDATE_PROFESSIONAL_DETAILS_CANCEL =
  'UPDATE_PROFESSIONAL_DETAILS_CANCEL'
