import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import { GlaCertificatePaymentDetail } from 'common/types/programs'
import * as APIs from './GlaCertificatePaymentProvider.api'
import * as types from './GlaCertificatePaymentProvider.types'
import {
  fetchGlaCertificatePayment,
  fetchGlaCertificatePaymentSuccess,
  fetchGlaCertificatePaymentFailure,
} from './GlaCertificatePaymentProvider.actions'

function* fetchGlaCertificatePaymentHandler(
  action: ReturnType<typeof fetchGlaCertificatePayment>
) {
  const abortController = new AbortController()
  try {
    const data: GlaCertificatePaymentDetail = yield call(
      APIs.fetchGlaCertificatePaymentAPI,
      action,
      abortController.signal
    )
    yield put(
      fetchGlaCertificatePaymentSuccess(data, {
        course_id: action.payload.course_id,
      })
    )
  } catch (e) {
    yield put(
      fetchGlaCertificatePaymentFailure(e, {
        course_id: action.payload.course_id,
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* glaCertificatePaymentMiddleware() {
  yield takeLatest(
    types.FETCH_GLA_CERTIFICATE_PAYMENT,
    cancelable(
      fetchGlaCertificatePaymentHandler,
      types.FETCH_GLA_CERTIFICATE_PAYMENT_CANCEL
    )
  )
}
export default ([] as any).concat(glaCertificatePaymentMiddleware())
