import {
  FETCH_USER_GROUPS,
  FETCH_USER_GROUPS_SUCCESS,
  FETCH_USER_GROUPS_FAILURE,
  FETCH_USER_GROUPS_CANCEL,
  FETCH_GROUP_MEMBERS,
  FETCH_GROUP_MEMBERS_SUCCESS,
  FETCH_GROUP_MEMBERS_FAILURE,
  FETCH_GROUP_MEMBERS_CANCEL,
  FETCH_USER_COURSE_GROUPS,
  FETCH_USER_COURSE_GROUPS_SUCCESS,
  FETCH_USER_COURSE_GROUPS_FAILURE,
  FETCH_USER_COURSE_GROUPS_CANCEL,
  FETCH_GROUP_FILES,
  FETCH_GROUP_FILES_SUCCESS,
  FETCH_GROUP_FILES_FAILURE,
  FETCH_GROUP_FILES_CANCEL,
  UPLOAD_GROUP_FILES,
  UPLOAD_GROUP_FILES_SUCCESS,
  UPLOAD_GROUP_FILES_FAILURE,
  UPLOAD_GROUP_FILES_CANCEL,
  FETCH_GROUP_FILES_QUOTA,
  FETCH_GROUP_FILES_QUOTA_SUCCESS,
  FETCH_GROUP_FILES_QUOTA_FAILURE,
  FETCH_GROUP_FILES_QUOTA_CANCEL,
  DELETE_GROUP_FILE,
  DELETE_GROUP_FILE_SUCCESS,
  DELETE_GROUP_FILE_FAILURE,
  DELETE_GROUP_FILE_CANCEL,
} from './UserGroups.types'
import { UserID, GroupCategoryID, GroupID } from '../../../../common/types/user'
import { CourseID } from '../../../../common/types/courses'
import { FileID } from '../../../../common/types'

export interface FetchUserGroupsParams {
  userId: UserID
}

export interface FetchUserCourseGroupsPayload {
  courseId: CourseID
}
export interface FetchUserCourseGroupsMeta {
  groupCategoryId?: GroupCategoryID
  courseId: CourseID
}

export interface FetchGroupContentMeta {
  groupId: GroupID
}

export interface FetchGroupMembersPayload {
  groupId: GroupID
}

export interface FetchUploadFilesPayload {
  groupId: GroupID
  url: string
  dataKey: string
}

export interface FetchGroupFilesPayload {
  groupId: GroupID
  page: number
}

export interface DeleteGroupFilePayload {
  fileId: FileID
}

export const fetchUserGroups = () => ({
  type: FETCH_USER_GROUPS as typeof FETCH_USER_GROUPS,
})
export const fetchUserGroupsSuccess = (payload: any) => ({
  type: FETCH_USER_GROUPS_SUCCESS as typeof FETCH_USER_GROUPS_SUCCESS,
  payload,
})
export const fetchUserGroupsFailure = (payload: Error) => ({
  type: FETCH_USER_GROUPS_FAILURE as typeof FETCH_USER_GROUPS_FAILURE,
  payload,
})
export const fetchUserGroupsCancel = (payload: any) => ({
  type: FETCH_USER_GROUPS_CANCEL as typeof FETCH_USER_GROUPS_CANCEL,
  payload,
})

export const fetchUserCourseGroups = (
  payload: FetchUserCourseGroupsPayload,
  meta: FetchUserCourseGroupsMeta
) => ({
  type: FETCH_USER_COURSE_GROUPS as typeof FETCH_USER_COURSE_GROUPS,
  payload,
  meta,
})
export const fetchUserCourseGroupsSuccess = (
  payload: any,
  meta: FetchUserCourseGroupsPayload
) => ({
  type: FETCH_USER_COURSE_GROUPS_SUCCESS as typeof FETCH_USER_COURSE_GROUPS_SUCCESS,
  payload,
  meta,
})
export const fetchUserCourseGroupsFailure = (
  payload: Error,
  meta: FetchUserCourseGroupsPayload
) => ({
  type: FETCH_USER_COURSE_GROUPS_FAILURE as typeof FETCH_USER_COURSE_GROUPS_FAILURE,
  payload,
  meta,
})
export const fetchUserCourseGroupsCancel = (
  payload: any,
  meta: FetchUserCourseGroupsPayload
) => ({
  type: FETCH_USER_COURSE_GROUPS_CANCEL as typeof FETCH_USER_COURSE_GROUPS_CANCEL,
  payload,
  meta,
})

export const fetchGroupMembers = (
  payload: FetchGroupMembersPayload,
  meta: FetchGroupContentMeta
) => ({
  type: FETCH_GROUP_MEMBERS as typeof FETCH_GROUP_MEMBERS,
  payload,
  meta,
})
export const fetchGroupMembersSuccess = (
  payload: any,
  meta: FetchGroupContentMeta
) => ({
  type: FETCH_GROUP_MEMBERS_SUCCESS as typeof FETCH_GROUP_MEMBERS_SUCCESS,
  payload,
  meta,
})
export const fetchGroupMembersFailure = (
  payload: Error,
  meta: FetchGroupContentMeta
) => ({
  type: FETCH_GROUP_MEMBERS_FAILURE as typeof FETCH_GROUP_MEMBERS_FAILURE,
  payload,
  meta,
})
export const fetchGroupMembersCancel = (
  payload: any,
  meta: FetchGroupContentMeta
) => ({
  type: FETCH_GROUP_MEMBERS_CANCEL as typeof FETCH_GROUP_MEMBERS_CANCEL,
  payload,
  meta,
})

export const fetchGroupFiles = (
  payload: FetchGroupFilesPayload,
  meta: FetchGroupContentMeta
) => ({
  type: FETCH_GROUP_FILES as typeof FETCH_GROUP_FILES,
  payload,
  meta,
})
export const fetchGroupFilesSuccess = (
  payload: {
    data: any
    hasMoreData: boolean
    page: number
  },
  meta: FetchGroupContentMeta
) => ({
  type: FETCH_GROUP_FILES_SUCCESS as typeof FETCH_GROUP_FILES_SUCCESS,
  payload,
  meta,
})
export const fetchGroupFilesFailure = (
  payload: Error,
  meta: FetchGroupContentMeta
) => ({
  type: FETCH_GROUP_FILES_FAILURE as typeof FETCH_GROUP_FILES_FAILURE,
  payload,
  meta,
})
export const fetchGroupFilesCancel = (
  payload: any,
  meta: FetchGroupContentMeta
) => ({
  type: FETCH_GROUP_FILES_CANCEL as typeof FETCH_GROUP_FILES_CANCEL,
  payload,
  meta,
})

export const uploadGroupFiles = (
  payload: FetchUploadFilesPayload,
  meta: FetchGroupContentMeta
) => ({
  type: UPLOAD_GROUP_FILES as typeof UPLOAD_GROUP_FILES,
  payload,
  meta,
})
export const uploadGroupFilesSuccess = (
  payload: any,
  meta: FetchGroupContentMeta
) => ({
  type: UPLOAD_GROUP_FILES_SUCCESS as typeof UPLOAD_GROUP_FILES_SUCCESS,
  payload,
  meta,
})
export const uploadGroupFilesFailure = (
  payload: Error,
  meta: FetchGroupContentMeta
) => ({
  type: UPLOAD_GROUP_FILES_FAILURE as typeof UPLOAD_GROUP_FILES_FAILURE,
  payload,
  meta,
})
export const uploadGroupFilesCancel = (
  payload: any,
  meta: FetchGroupContentMeta
) => ({
  type: UPLOAD_GROUP_FILES_CANCEL as typeof UPLOAD_GROUP_FILES_CANCEL,
  payload,
  meta,
})

export const fetchGroupFilesQuota = (
  payload: FetchGroupContentMeta,
  meta: FetchGroupContentMeta
) => ({
  type: FETCH_GROUP_FILES_QUOTA as typeof FETCH_GROUP_FILES_QUOTA,
  payload,
  meta,
})
export const fetchGroupFilesQuotaSuccess = (
  payload: any,
  meta: FetchGroupContentMeta
) => ({
  type: FETCH_GROUP_FILES_QUOTA_SUCCESS as typeof FETCH_GROUP_FILES_QUOTA_SUCCESS,
  payload,
  meta,
})
export const fetchGroupFilesQuotaFailure = (
  payload: Error,
  meta: FetchGroupContentMeta
) => ({
  type: FETCH_GROUP_FILES_QUOTA_FAILURE as typeof FETCH_GROUP_FILES_QUOTA_FAILURE,
  payload,
  meta,
})
export const fetchGroupFilesQuotaCancel = (
  payload: any,
  meta: FetchGroupContentMeta
) => ({
  type: FETCH_GROUP_FILES_QUOTA_CANCEL as typeof FETCH_GROUP_FILES_QUOTA_CANCEL,
  payload,
  meta,
})

export const deleteGroupFile = (
  payload: DeleteGroupFilePayload,
  meta: FetchGroupContentMeta
) => ({
  type: DELETE_GROUP_FILE as typeof DELETE_GROUP_FILE,
  payload,
  meta,
})
export const deleteGroupFileSuccess = (
  payload: any,
  meta: FetchGroupContentMeta & DeleteGroupFilePayload
) => ({
  type: DELETE_GROUP_FILE_SUCCESS as typeof DELETE_GROUP_FILE_SUCCESS,
  payload,
  meta,
})
export const deleteGroupFileFailure = (
  payload: Error,
  meta: FetchGroupContentMeta
) => ({
  type: DELETE_GROUP_FILE_FAILURE as typeof DELETE_GROUP_FILE_FAILURE,
  payload,
  meta,
})
export const deleteGroupFileCancel = (
  payload: any,
  meta: FetchGroupContentMeta
) => ({
  type: DELETE_GROUP_FILE_CANCEL as typeof DELETE_GROUP_FILE_CANCEL,
  payload,
  meta,
})

export type UserGroupsActions =
  | ReturnType<typeof fetchUserGroups>
  | ReturnType<typeof fetchUserGroupsSuccess>
  | ReturnType<typeof fetchUserGroupsFailure>
  | ReturnType<typeof fetchUserGroupsCancel>
  | ReturnType<typeof fetchUserCourseGroups>
  | ReturnType<typeof fetchUserCourseGroupsSuccess>
  | ReturnType<typeof fetchUserCourseGroupsFailure>
  | ReturnType<typeof fetchUserCourseGroupsCancel>
  | ReturnType<typeof fetchGroupMembers>
  | ReturnType<typeof fetchGroupMembersSuccess>
  | ReturnType<typeof fetchGroupMembersFailure>
  | ReturnType<typeof fetchGroupMembersCancel>
  | ReturnType<typeof fetchGroupFiles>
  | ReturnType<typeof fetchGroupFilesSuccess>
  | ReturnType<typeof fetchGroupFilesFailure>
  | ReturnType<typeof fetchGroupFilesCancel>
  | ReturnType<typeof uploadGroupFiles>
  | ReturnType<typeof uploadGroupFilesSuccess>
  | ReturnType<typeof uploadGroupFilesFailure>
  | ReturnType<typeof uploadGroupFilesCancel>
  | ReturnType<typeof fetchGroupFilesQuota>
  | ReturnType<typeof fetchGroupFilesQuotaSuccess>
  | ReturnType<typeof fetchGroupFilesQuotaFailure>
  | ReturnType<typeof fetchGroupFilesQuotaCancel>
  | ReturnType<typeof deleteGroupFile>
  | ReturnType<typeof deleteGroupFileSuccess>
  | ReturnType<typeof deleteGroupFileFailure>
  | ReturnType<typeof deleteGroupFileCancel>
