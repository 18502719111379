import { call, cancelled, put, select, takeLeading } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import {
  fetchPrivateCertificatesContent,
  fetchPrivateCertificatesContentSuccess,
  fetchPrivateCertificatesContentFailure,
} from './PrivateCertificate.actions'
import { getPrivateCertificatesContentAPI } from './PrivateCertificate.api'
import { UserID } from '../../../common/types/user'
import { AppState } from '../../store'
import {
  FETCH_PRIVATE_CERTIFICATES_CONTENT,
  FETCH_PRIVATE_CERTIFICATES_CONTENT_CANCEL,
} from './PrivateCertificate.types'

function* fetchPrivateCertificatesContentHandler(
  action: ReturnType<typeof fetchPrivateCertificatesContent>
) {
  // it will stop old response and take new response
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }

    const data = yield call(
      getPrivateCertificatesContentAPI,
      action,
      userId,
      abortController.signal
    )
    yield put(fetchPrivateCertificatesContentSuccess(data))
  } catch (e) {
    yield put(fetchPrivateCertificatesContentFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* privateCertificatesMiddleware() {
  yield takeLeading(
    FETCH_PRIVATE_CERTIFICATES_CONTENT,
    cancelable(
      fetchPrivateCertificatesContentHandler,
      FETCH_PRIVATE_CERTIFICATES_CONTENT_CANCEL
    )
  )
}

export default ([] as any).concat(privateCertificatesMiddleware())
