import {
  FETCH_SNACKBAR_DETAILS,
  FETCH_SNACKBAR_DETAILS_CANCEL,
  FETCH_SNACKBAR_DETAILS_FAILURE,
  FETCH_SNACKBAR_DETAILS_SUCCESS,
} from './Snackbar.types'

export const fetchSnackbarDetails = () => ({
  type: FETCH_SNACKBAR_DETAILS as typeof FETCH_SNACKBAR_DETAILS,
})

export const fetchSnackbarDetailsSuccess = (payload: any) => ({
  type: FETCH_SNACKBAR_DETAILS_SUCCESS as typeof FETCH_SNACKBAR_DETAILS_SUCCESS,
  payload,
})

export const fetchSnackbarDetailsFailure = (payload: any) => ({
  type: FETCH_SNACKBAR_DETAILS_FAILURE as typeof FETCH_SNACKBAR_DETAILS_FAILURE,
  payload,
})

export const fetchSnackbarDetailsCancel = (payload: Error) => ({
  type: FETCH_SNACKBAR_DETAILS_CANCEL as typeof FETCH_SNACKBAR_DETAILS_CANCEL,
  payload,
})

export type SnackbarActions =
  | ReturnType<typeof fetchSnackbarDetails>
  | ReturnType<typeof fetchSnackbarDetailsSuccess>
  | ReturnType<typeof fetchSnackbarDetailsFailure>
  | ReturnType<typeof fetchSnackbarDetailsCancel>
