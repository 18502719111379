export const UPDATE_FEEDBACK_DATA = 'UPDATE_FEEDBACK_DATA'
export const FEEDBACK_POPUP_STATUS = 'FEEDBACK_POPUP_STATUS'
export const SHOW_FEEDBACK_POPUP = 'SHOW_FEEDBACK_POPUP'
export const REMOVE_FEEDBACK_POPUP = 'REMOVE_FEEDBACK_POPUP'

export const FETCH_FEEDBACK_CONTENT = 'FETCH_FEEDBACK_CONTENT'
export const FETCH_FEEDBACK_CONTENT_SUCCESS = 'FETCH_FEEDBACK_CONTENT_SUCCESS'
export const FETCH_FEEDBACK_CONTENT_FAILURE = 'FETCH_FEEDBACK_CONTENT_FAILURE'
export const FETCH_FEEDBACK_CONTENT_CANCEL = 'FETCH_FEEDBACK_CONTENT_CANCEL'

export const SUBMIT_COURSE_FEEDBACK = 'SUBMIT_COURSE_FEEDBACK'
export const SUBMIT_COURSE_FEEDBACK_SUCCESS = 'SUBMIT_COURSE_FEEDBACK_SUCCESS'
export const SUBMIT_COURSE_FEEDBACK_FAILURE = 'SUBMIT_COURSE_FEEDBACK_FAILURE'
export const SUBMIT_COURSE_FEEDBACK_CANCEL = 'SUBMIT_COURSE_FEEDBACK_CANCEL'

export const MARK_MISSED_RESIDENCY_SESSION = 'MARK_MISSED_RESIDENCY_SESSION'
export const MARK_MISSED_RESIDENCY_SESSION_SUCCESS =
  'MARK_MISSED_RESIDENCY_SESSION_SUCCESS'
export const MARK_MISSED_RESIDENCY_SESSION_FAILURE =
  'MARK_MISSED_RESIDENCY_SESSION_FAILURE'
export const MARK_MISSED_RESIDENCY_SESSION_CANCEL =
  'MARK_MISSED_RESIDENCY_SESSION_CANCEL'

export const SUBMIT_PROGRAM_FEEDBACK = 'SUBMIT_PROGRAM_FEEDBACK'
export const SUBMIT_PROGRAM_FEEDBACK_SUCCESS = 'SUBMIT_PROGRAM_FEEDBACK_SUCCESS'
export const SUBMIT_PROGRAM_FEEDBACK_FAILURE = 'SUBMIT_PROGRAM_FEEDBACK_FAILURE'
export const SUBMIT_PROGRAM_FEEDBACK_CANCEL = 'SUBMIT_PROGRAM_FEEDBACK_CANCEL'

export const SUBMIT_CAPSTONE_LEGACY_FEEDBACK = 'SUBMIT_CAPSTONE_LEGACY_FEEDBACK'
export const SUBMIT_CAPSTONE_LEGACY_FEEDBACK_SUCCESS =
  'SUBMIT_CAPSTONE_LEGACY_FEEDBACK_SUCCESS'
export const SUBMIT_CAPSTONE_LEGACY_FEEDBACK_FAILURE =
  'SUBMIT_CAPSTONE_LEGACY_FEEDBACK_FAILURE'
export const SUBMIT_CAPSTONE_LEGACY_FEEDBACK_CANCEL =
  'SUBMIT_CAPSTONE_LEGACY_FEEDBACK_CANCEL'

export const SUBMIT_CAPSTONE_SURVEY_FEEDBACK = 'SUBMIT_CAPSTONE_SURVEY_FEEDBACK'
export const SUBMIT_CAPSTONE_SURVEY_FEEDBACK_SUCCESS =
  'SUBMIT_CAPSTONE_SURVEY_FEEDBACK_SUCCESS'
export const SUBMIT_CAPSTONE_SURVEY_FEEDBACK_FAILURE =
  'SUBMIT_CAPSTONE_SURVEY_FEEDBACK_FAILURE'
export const SUBMIT_CAPSTONE_SURVEY_FEEDBACK_CANCEL =
  'SUBMIT_CAPSTONE_SURVEY_FEEDBACK_CANCEL'

export const CLEAR_FEEDBACK_SUBMITTED_STATUS = 'CLEAR_FEEDBACK_SUBMITTED_STATUS'

export const SKIP_SESSION_FEEDBACK = 'SKIP_SESSION_FEEDBACK'
export const SKIP_SESSION_FEEDBACK_SUCCESS = 'SKIP_SESSION_FEEDBACK_SUCCESS'
export const SKIP_SESSION_FEEDBACK_FAILURE = 'SKIP_SESSION_FEEDBACK_FAILURE'
export const SKIP_SESSION_FEEDBACK_CANCEL = 'SKIP_SESSION_FEEDBACK_CANCEL'
