import { UserID } from 'common/types/user'
import {
  IZoomoutPollQuestion,
  ZoomoutPollWorkflowState,
} from 'common/types/zoomout'
import { apiCall } from 'common/utils'

export const ZOOMOUT_POLLS_API_ENDPOINT = `${process.env.REACT_APP_ASPIRE_API_URL}v1/zoomout/polls`

export const fetchZoomoutPollAPI = async (
  lmsUserId: UserID,
  mgsId: number,
  signal: AbortSignal
) => {
  const response = await apiCall({
    url: `${ZOOMOUT_POLLS_API_ENDPOINT}`,
    query: { mgsId, user_id: lmsUserId },
    params: {
      signal,
      method: 'GET',
    },
  })
  if (response.status === 200) return response.json()
  throw await response.json()
}

export const fetchZoomoutPollByIdAPI = async (
  pollId: number,
  lmsUserId: UserID,
  mgsId: number,
  signal: AbortSignal
) => {
  const response = await apiCall({
    url: `${ZOOMOUT_POLLS_API_ENDPOINT}/${pollId}`,
    query: { mgsId, user_id: lmsUserId },
    params: {
      signal,
      method: 'GET',
    },
  })
  if (response.status === 200) return response.json()
  throw await response.json()
}

export const saveOptionPreferenceAPI = async (
  option_ids: number[],
  question_id: number,
  user_id: UserID
) => {
  const apiPayload = {
    option_ids,
    question_id,
    user_id,
  }

  const response = await apiCall({
    url: `${ZOOMOUT_POLLS_API_ENDPOINT}/response`,
    params: {
      method: 'POST',
      body: JSON.stringify(apiPayload),
    },
    query: {
      user_id,
    },
  })
  if (response.status === 200) return response.json()
  throw await response.json()
}

export const updatePollStatusAPI = async (
  pollId: number,
  status: ZoomoutPollWorkflowState,
  mgsId: number,
  user_id: UserID
) => {
  const payload = {
    mgsId,
    status,
  }
  const response = await apiCall({
    url: `${ZOOMOUT_POLLS_API_ENDPOINT}/status/${pollId}`,
    params: {
      method: 'POST',
      body: JSON.stringify(payload),
    },
    query: {
      user_id,
    },
  })
  if (response.status === 200) return response.json()
  throw await response.json()
}

export const createNewPollAPI = async (
  mentoredGroupSessionId: number,
  user_id: UserID,
  questions: {
    title: string
    options: {
      title: string
    }[]
  }[]
) => {
  const payload = {
    title: '', // currently we don't ask user for poll title
    user_id,
    mgsId: mentoredGroupSessionId,
    questions,
  }

  const response = await apiCall({
    url: `${ZOOMOUT_POLLS_API_ENDPOINT}`,
    params: {
      method: 'POST',
      body: JSON.stringify(payload),
    },
    query: {
      user_id,
    },
  })
  if (response.status === 200) return response.json()
  throw await response.json()
}
