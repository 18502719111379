import {
  takeEvery,
  put,
  select,
  cancelled,
  call,
  takeLatest,
} from 'redux-saga/effects'
import { UserID } from 'aws-sdk/clients/personalizeruntime'
import { AppState } from 'web/store'
import { showGLLoader } from 'web/providers/Common/Common.actions'
import qs from 'query-string'
import {
  apiCall,
  cancelable,
  getActiveProgramBatchId,
} from '../../../../common/utils'
import {
  updateActiveProgram,
  updateProgramDetails,
  fetchCommunitySsoTokenSuccess,
  fetchCommunitySsoTokenFailure,
  updateUserConsent,
  updateUserConsentSuccess,
  updateUserConsentFailure,
  fetchMouthShutSuccess,
  getBatchesSuccess,
  getBatchesFailure,
} from './Programs.actions'
import {
  UPDATE_PROGRAM_DETAILS,
  UPDATE_ACTIVE_PROGRAM,
  FETCH_COMMUNITY_SSO_TOKEN,
  FETCH_PROGRAM_CONTENT_CANCEL,
  UPDATE_USER_CONSENT,
  UPDATE_USER_CONSENT_CANCEL,
  GET_BATCHES,
} from './Programs.types'
import {
  EnrolledProgramsData,
  ProgramID,
} from '../../../../common/types/programs'
import { getAllProgramIds } from './Programs.selectors'

function* updateActiveProgramInStorage(
  action: ReturnType<typeof updateActiveProgram>
) {
  yield put(showGLLoader({ show: true }))
  const prevPbid = localStorage.getItem('pb_id')
  if (prevPbid?.toString() !== action.payload.programId.toString()) {
    sessionStorage.setItem('pb_id', action.payload.programId.toString())
    localStorage.setItem('pb_id', action.payload.programId.toString())
    if (action.payload.redirect) {
      window.history.replaceState(
        {},
        '',
        `/learner_dashboard?pb_id=${action.payload.programId.toString()}`
      )
      window.location.reload()
    }
  }
  yield
}

function* updateProgramInfo(action: ReturnType<typeof updateProgramDetails>) {
  const {
    enrolledPrograms,
    overrideSelected,
    isNewUIPreferred,
    isExcelerateCoursePage
  } = action.payload
  let activeProgramID: ProgramID = -1
  const sessionPbId = sessionStorage.getItem('pb_id')
  const localPbId = localStorage.getItem('pb_id')

  const enrolledProgramIds: ProgramID[] | null = yield select(
    getAllProgramIds()
  )
  if (enrolledProgramIds) {
    const isSessionPbIdValid: boolean = sessionPbId
      ? enrolledProgramIds.includes(parseInt(sessionPbId, 10))
      : false
    const isLocalPbIdValid: boolean = localPbId
      ? enrolledProgramIds.includes(parseInt(localPbId, 10))
      : false

    if (overrideSelected || (!isSessionPbIdValid && !isLocalPbIdValid)) {
      enrolledPrograms.forEach((program: EnrolledProgramsData) => {
        if (program.selected) activeProgramID = program.program_group_id
      })
    } else if (sessionPbId) {
      activeProgramID = parseInt(sessionPbId, 10)
    } else {
      activeProgramID = parseInt(
        localPbId || enrolledProgramIds[0].toString(),
        10
      )
    }
    if (isNewUIPreferred !== undefined && isNewUIPreferred !== null) {
      const selectedBatch = enrolledPrograms.find(
        program => program.program_group_id === activeProgramID
      )
      if (selectedBatch?.is_new_ui_enabled_for_batch && isNewUIPreferred && !isExcelerateCoursePage) {
        const urlParams = qs.parse(window.location.search)
        urlParams.pb_id = activeProgramID.toString()
        window.location.href = `${window.location.pathname}?${qs.stringify(
          urlParams
        )}`
        return
      }
    }

    yield put(updateActiveProgram({ programId: activeProgramID }))
  }
}

async function fetchCommunityTokenSSoAPI(userId: UserID, signal: AbortSignal) {
  const programBatchId = getActiveProgramBatchId()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/community/batches/${programBatchId}/details`,
    params: {
      signal,
    },
    query: {
      student_id: userId,
    },
    excludeProgramId: true,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

async function updateUserConsentAPI(
  userId: UserID,
  type: string,
  signal: AbortSignal
) {
  const body = JSON.stringify({
    type,
  })
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/consent`,
    params: {
      signal,
      method: 'POST',
      body,
    },
    excludeProgramId: true,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

async function getBatchesAPI(
  userId: UserID,
  isMasquerading: boolean,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/batches`,
    params: {
      signal,
      method: 'GET',
    },
    query: isMasquerading
      ? {
          masquerading: isMasquerading,
        }
      : {},
    excludeProgramId: true,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

function* getBatchesHandler(): any {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const isMasquerading = yield select(
      (state: AppState) => state.user.details.masquerading
    )
    const data = yield call(
      getBatchesAPI,
      userId,
      isMasquerading,
      abortController.signal
    )
    yield put(getBatchesSuccess(data))
  } catch (e) {
    yield put(getBatchesFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchCommunityTokenSSoHandler(): any {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data = yield call(
      fetchCommunityTokenSSoAPI,
      userId,
      abortController.signal
    )
    yield put(fetchCommunitySsoTokenSuccess(data))
    yield put(fetchMouthShutSuccess(data))
  } catch (e) {
    yield put(fetchCommunitySsoTokenFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* updateUserConsentHandler(
  action: ReturnType<typeof updateUserConsent>
): any {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data = yield call(
      updateUserConsentAPI,
      userId,
      action.payload,
      abortController.signal
    )
    yield put(updateUserConsentSuccess(data))
  } catch (e) {
    yield put(updateUserConsentFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* programsMiddleware() {
  yield takeLatest(UPDATE_ACTIVE_PROGRAM, updateActiveProgramInStorage)
  yield takeLatest(GET_BATCHES, getBatchesHandler)
  yield takeEvery(UPDATE_PROGRAM_DETAILS, updateProgramInfo)
  yield takeEvery(
    FETCH_COMMUNITY_SSO_TOKEN,
    cancelable(fetchCommunityTokenSSoHandler, [FETCH_PROGRAM_CONTENT_CANCEL])
  )
  yield takeEvery(
    UPDATE_USER_CONSENT,
    cancelable(updateUserConsentHandler, [UPDATE_USER_CONSENT_CANCEL])
  )
}

export default ([] as any).concat(programsMiddleware())
