import { useRef, useEffect } from 'react'

const useInterval = (callback: () => void, freq: number | null): void => {
  const intervalRef = useRef<NodeJS.Timeout>()
  const savedCallback = useRef<() => void>()
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  })

  // Set up the interval.
  // eslint-disable-next-line consistent-return

  useEffect(() => {
    function tick() {
      if (
        typeof savedCallback !== 'undefined' &&
        typeof savedCallback.current !== 'undefined'
      ) {
        savedCallback.current()
      }
    }

    if (freq !== null) {
      intervalRef.current = setInterval(tick, freq)
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [freq])
}
export default useInterval
