export const MIT_PROGRAMS = {
  'MIT-ADSP': 'MIT-ADSP',
  'MIT-NCAI-Intl': 'MIT-NCAI-Intl',
  'mit-idss-dsml-intl': 'mit-idss-dsml-intl',
};

export const CODING_LAB_LOADING_MESSAGES = [
  {
    mainMessage: 'Creating cloud lab, this could take upto 5 minutes...',
    title: 'No Incognito Mode',
    description:
      'Do not use the lab in incognito mode else certain operations will not work.',
  },
  {
    mainMessage: 'Creating cloud lab, this could take upto 5 minutes...',
    title: 'Stable internet connection',
    description: 'Please ensure you have a stable internet connection.',
  },
  {
    mainMessage: 'Creating cloud lab, this could take upto 5 minutes...',
    title: 'Available ports',
    description:
      'Available ports for running frontend and backend applications : 3005 and 8080',
  },
]

export const glaCertificateHighLights = [
  'Stand out to recruiters',
  'Share on professional channels',
  'Globally recognized',
]

export const premiumCourseHighLights: Partial<Record<string, string>> = {
  'ai_mentoring': 'Resolve doubts with AI Mentor',
  'guided_projects': 'Guided Projects for hands-on learning',
  'ai_assistance' : 'Coding Exercises with AI hints',
  'mock_interviews': 'Personalized Mock Interviews',
}

export const GlaNoOfCourses: string = '1000+'

export const AI_FEATURES = {
  mock_interview: "MOCK_INTERVIEW",
  teacher: "AI_TEACHER",
  playground: "AI_PLAYGROUND",
}

export const AI_FEATURE_TYPES = Object.values(AI_FEATURES)
