import React from 'react'

import CircularLoader, { Props as CircularLoaderProps } from './CircularLoader'
import LoaderBase from './LoaderBase'
import { Box } from '@material-ui/core'
import Lottie from 'react-lottie'
import AiLoader from '../../../../common/lotties/AiLoaderWithoutBg.json'

export const AILoader = (
  <Lottie
    options={{
      animationData: AiLoader,
      loop: true,
    }}
  />
)

type LoaderTypes = 'circle' | 'ai' | ''

export type Props = {
  style: object
  type: LoaderTypes
} & CircularLoaderProps

const Loader = ({ type, ...props }: Props) => {
  switch (type) {
    case 'circle':
      return <CircularLoader {...props} />
    case 'ai':
      return (
        <LoaderBase {...props}>
          <Box width={48} height={48}>
            {AILoader}
          </Box>
        </LoaderBase>
      )
    default:
      return <CircularLoader {...props} />
  }
}

Loader.defaultProps = {
  type: '',
  style: {},
}

export default Loader
