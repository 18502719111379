export const FETCH_ASSESSMENT_SCHEDULE_DETAILS =
  'FETCH_ASSESSMENT_SCHEDULE_DETAILS'
export const FETCH_ASSESSMENT_SCHEDULE_DETAILS_SUCCESS =
  'FETCH_ASSESSMENT_SCHEDULE_DETAILS_SUCCESS'
export const FETCH_ASSESSMENT_SCHEDULE_DETAILS_FAILURE =
  'FETCH_ASSESSMENT_SCHEDULE_DETAILS_FAILURE'
export const FETCH_ASSESSMENT_SCHEDULE_DETAILS_CANCEL =
  'FETCH_ASSESSMENT_SCHEDULE_DETAILS_CANCEL'

export const FETCH_INVIGILATION_DETAILS = 'FETCH_INVIGILATION_DETAILS'
export const FETCH_INVIGILATION_DETAILS_SUCCESS =
  'FETCH_INVIGILATION_DETAILS_SUCCESS'
export const FETCH_INVIGILATION_DETAILS_FAILURE =
  'FETCH_INVIGILATION_DETAILS_FAILURE'
export const FETCH_INVIGILATION_DETAILS_CANCEL =
  'FETCH_INVIGILATION_DETAILS_CANCEL'

export const UPDATE_INVIGILATION_MESSAGES = 'UPDATE_INVIGILATION_MESSAGES'
export const UPDATE_INVIGILATION_MESSAGES_SUCCESS =
  'UPDATE_INVIGILATION_MESSAGES_SUCCESS'
export const UPDATE_INVIGILATION_MESSAGES_FAILURE =
  'UPDATE_INVIGILATION_MESSAGES_FAILURE'
export const UPDATE_INVIGILATION_MESSAGES_CANCEL =
  'UPDATE_INVIGILATION_MESSAGES_CANCEL'

export const APPROVE_OR_REJECT_ONBOARDING_PHOTO =
  'APPROVE_OR_REJECT_ONBOARDING_PHOTO'
export const APPROVE_OR_REJECT_ONBOARDING_PHOTO_FAILURE =
  'APPROVE_OR_REJECT_ONBOARDING_PHOTO_FAILURE'
export const APPROVE_OR_REJECT_ONBOARDING_PHOTO_SUCCESS =
  'APPROVE_OR_REJECT_ONBOARDING_PHOTO_SUCCESS'
export const APPROVE_OR_REJECT_ONBOARDING_PHOTO_CANCEL =
  'APPROVE_OR_REJECT_ONBOARDING_PHOTO_CANCEL'
