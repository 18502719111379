import {
  SupportTicketConversationSubmitParams,
  SupportTicketFeedbackSubmitParams,
  SupportTicketId,
  SupportTicketSubmitParams,
} from 'common/types/programSupport'
import {
  select,
  put,
  cancelled,
  takeLatest,
  call,
  take,
} from 'redux-saga/effects'
import { showAlertMessage } from 'web/providers/AlertsProvider'
import { postQuestion } from 'web/providers/CommunityProvider'
import {
  POST_QUESTION_FAILURE,
  POST_QUESTION_SUCCESS,
} from 'web/providers/CommunityProvider/Community.types'
import { history } from 'web/store/history'
import { UserID } from '../../../../common/types/user'
import {
  apiCall,
  cancelable,
  generateURL,
  getCookie,
  getUserDetails,
} from '../../../../common/utils'
import { AppState } from '../../../store'
import {
  fetchUserProgramSupportTicketFailure,
  fetchUserProgramSupportTicketSuccess,
  submitUserProgramSupportTicketFailure,
  submitUserProgramSupportTicketSuccess,
  submitUserProgramSupportTicket,
  fetchProgramSupportTicketDetails,
  fetchProgramSupportTicketDetailsSuccess,
  fetchProgramSupportTicketDetailsFailure,
  submitUserProgramSupportTicketConversation,
  submitUserProgramSupportTicketConversationSuccess,
  submitUserProgramSupportTicketConversationFailure,
  reopenUserProgramSupportTicket,
  reopenUserProgramSupportTicketSuccess,
  reopenUserProgramSupportTicketFailure,
  submitUserProgramSupportTicketFeeback,
  submitUserProgramSupportTicketFeebackSuccess,
  submitUserProgramSupportTicketFeebackFailure,
} from './UserSupportTickets.actions'
import {
  FETCH_USER_PROGRAM_SUPPORT_TICKETS,
  FETCH_USER_PROGRAM_SUPPORT_TICKETS_CANCEL,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKETS,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_CANCEL,
  FETCH_PROGRAM_SUPPORT_TICKET_DETAILS,
  FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_CANCEL,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_CANCEL,
  REOPEN_USER_PROGRAM_SUPPORT_TICKET,
  REOPEN_USER_PROGRAM_SUPPORT_TICKET_CANCEL,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_CANCEL,
} from './UserSupportTickets.types'

export async function getUserProgramSupportTicketsAPI(
  userId: UserID,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/program_support/get_tickets`,
    params: {
      signal,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getProgramSupportTicketDetailAPI(
  ticketId: SupportTicketId,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/program_support/ticket/${ticketId}`,
    params: {
      signal,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function submitUserProgramSupportTicketsAPI(
  userId: UserID,
  data: SupportTicketSubmitParams,
  signal: AbortSignal
) {
  let url = `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/program_support/create_ticket_student`
  let body
  if (data.community_question_id) {
    url = `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/program_support/create_ticket_student_community`
    body = JSON.stringify(data)
  } else {
    const formData = new FormData()

    Object.entries(data).map(entry => {
      if (
        entry[0] == 'attachments' &&
        data.attachments &&
        data.attachments.length
      ) {
        data.attachments.map(attachment => {
          formData.append('attachment', attachment.file, attachment.file.name)
        })
      } else {
        formData.append(entry[0], entry[1])
      }
    })

    body = formData
  }
  const response = await apiCall({
    url,
    params: {
      signal,
      method: 'POST',
      body,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function submitUserProgramSupportTicketConversationAPI(
  data: SupportTicketConversationSubmitParams,
  signal: AbortSignal
) {
  const { id: userId } = getUserDetails()
  const url = `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/program_support/ticket/${data.ticketId}/create_ticket_conversation`

  const formData = new FormData()

  Object.entries(data).map(entry => {
    if (
      entry[0] == 'attachments' &&
      data.attachments &&
      data.attachments.length
    ) {
      data.attachments.map(attachment => {
        formData.append('attachment', attachment.file, attachment.file.name)
      })
    } else {
      formData.append(entry[0], entry[1])
    }
  })

  const response = await apiCall({
    url,
    params: {
      signal,
      method: 'POST',
      body: formData,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function reopenUserProgramSupportTicketAPI(
  data: SupportTicketConversationSubmitParams,
  signal: AbortSignal
) {
  const { id: userId } = getUserDetails()
  const url = `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/program_support/ticket/${data.ticketId}/open`

  const formData = new FormData()

  Object.entries(data).map(entry => {
    if (
      entry[0] == 'attachments' &&
      data.attachments &&
      data.attachments.length
    ) {
      data.attachments.map(attachment => {
        formData.append('attachment', attachment.file, attachment.file.name)
      })
    } else {
      formData.append(entry[0], entry[1])
    }
  })

  const response = await apiCall({
    url,
    params: {
      signal,
      method: 'POST',
      body: formData,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function submitUserProgramSupportTicketFeedbackAPI(
  data: SupportTicketFeedbackSubmitParams,
  signal: AbortSignal
) {
  const { id: userId } = getUserDetails()
  const url = `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/program_support/ticket/${data.ticketId}/feedback`

  const body = JSON.stringify({
    feedback_data: {
      ...data,
    },
  })
  const response = await apiCall({
    url,
    params: { signal, method: 'POST', body },
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

function* fetchUserProgramSupportTicketsHandler() {
  const abortController = new AbortController()
  try {
    const userId: UserID | undefined = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data: Promise<any> = yield call(
      getUserProgramSupportTicketsAPI,
      userId,
      abortController.signal
    )
    yield put(fetchUserProgramSupportTicketSuccess(data))
  } catch (e) {
    yield put(fetchUserProgramSupportTicketFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchProgramSupportTicketDetailsHandler(
  action: ReturnType<typeof fetchProgramSupportTicketDetails>
) {
  const abortController = new AbortController()
  try {
    const data: Promise<any> = yield call(
      getProgramSupportTicketDetailAPI,
      action.payload,
      abortController.signal
    )
    yield put(fetchProgramSupportTicketDetailsSuccess(data))
  } catch (e) {
    const payload = { error: e, ticketId: action.payload }
    yield put(fetchProgramSupportTicketDetailsFailure(payload))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitUserProgramTestimonialHandler(
  action: ReturnType<typeof submitUserProgramSupportTicket>
) {
  const abortController = new AbortController()
  try {
    let community_question_id = null
    let community_relative_url = null
    if (action.payload.is_community_question_category) {
      yield put(
        postQuestion({
          title: action.payload.title,
          description: action.payload.description,
          topicIds: action.payload.topicIds ? action.payload.topicIds : [],
          attachments: action.payload.attachments
            ? action.payload.attachments
            : [],
        })
      )
      const res = yield take([POST_QUESTION_SUCCESS, POST_QUESTION_FAILURE])
      if (res.type === POST_QUESTION_FAILURE) {
        throw new Error('Posting to community failed')
      }
      const communityQuestionData = res.payload
      community_question_id = communityQuestionData._id
      community_relative_url = communityQuestionData.relativeUrl
    }

    const userId: UserID | undefined = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }

    let apiParams: SupportTicketSubmitParams = {
      ...action.payload,
    }

    if (community_question_id && community_question_id.length) {
      apiParams = {
        ...apiParams,
        community_question_id,
      }
      delete apiParams.attachments
    }

    const data: Promise<any> = yield call(
      submitUserProgramSupportTicketsAPI,
      userId,
      apiParams,
      abortController.signal
    )

    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'Question posted successfully',
      })
    )
    if (!action.payload.is_community_question_category) {
      yield put(submitUserProgramSupportTicketSuccess(data))
    }

    if (community_question_id && community_question_id.length) {
      if (community_relative_url) {
        window.location.href = `${
          process.env.REACT_APP_COMMUNITY_V2_URL
        }${community_relative_url.replace(/\/*/, '')}`
      }
    } else {
      const sendGoBackRequestForMobileApp = getCookie('app_version') !== ''
      // @ts-ignore
      if (sendGoBackRequestForMobileApp && window.ReactNativeWebView) {
        // @ts-ignore
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'GoToProgramSupport',
            payload: {},
          })
        )
      } else {
        history.push(generateURL('PROGRAM_SUPPORT', null))
      }
    }
  } catch (e: any) {
    console.log('Program Support', e)
    let errorMessage = ''
    if (e.status === 400) {
      errorMessage = 'Some file types are not allowed or something went wrong. Please reach out to program office'
    } else {
      errorMessage = 'Oops! Something went wrong, please try again'
    }

    yield put(
      showAlertMessage({
        variant: 'error',
        message: errorMessage
      })
    )
   
    yield put(submitUserProgramSupportTicketFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitUserProgramRequestConversationHandler(
  action: ReturnType<typeof submitUserProgramSupportTicketConversation>
) {
  const abortController = new AbortController()
  try {
    const data: Promise<any> = yield call(
      submitUserProgramSupportTicketConversationAPI,
      action.payload,
      abortController.signal
    )

    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'Comment posted successfully',
      })
    )
    yield put(submitUserProgramSupportTicketConversationSuccess(data))
  } catch (e: any) {
    console.log('Program Support', e)
    let errorMessage = ''
    if (e.status === 400) {
      errorMessage =
        'Some file types are not allowed or something went wrong. Please reach out to program office'
    } else {
      errorMessage = 'Oops! Something went wrong, please try again'
    }

    yield put(
      showAlertMessage({
        variant: 'error',
        message: errorMessage,
      })
    )
    const payload = { error: e, ticketId: action.payload.ticketId }
   
    yield put(submitUserProgramSupportTicketConversationFailure(payload))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* reopenUserProgramRequestHandler(
  action: ReturnType<typeof reopenUserProgramSupportTicket>
) {
  const abortController = new AbortController()
  try {
    const data: Promise<any> = yield call(
      reopenUserProgramSupportTicketAPI,
      action.payload,
      abortController.signal
    )

    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'Comment posted successfully',
      })
    )
    yield put(reopenUserProgramSupportTicketSuccess(data))
  } catch (e) {
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Oops! Something went wrong, please try again',
      })
    )
    const payload = { error: e, ticketId: action.payload.ticketId }

    yield put(reopenUserProgramSupportTicketFailure(payload))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitUserProgramRequestFeedbackHandler(
  action: ReturnType<typeof submitUserProgramSupportTicketFeeback>
) {
  const abortController = new AbortController()
  try {
    const data: Promise<any> = yield call(
      submitUserProgramSupportTicketFeedbackAPI,
      action.payload,
      abortController.signal
    )

    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'Feedback given successfully',
      })
    )
    yield put(submitUserProgramSupportTicketFeebackSuccess(data))
  } catch (e) {
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Oops! Something went wrong, please try again',
      })
    )
    const payload = { error: e, ticketId: action.payload.ticketId }

    yield put(submitUserProgramSupportTicketFeebackFailure(payload))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* supportTicketsMiddleware() {
  yield takeLatest(
    FETCH_USER_PROGRAM_SUPPORT_TICKETS,
    cancelable(
      fetchUserProgramSupportTicketsHandler,
      FETCH_USER_PROGRAM_SUPPORT_TICKETS_CANCEL
    )
  )

  yield takeLatest(
    SUBMIT_USER_PROGRAM_SUPPORT_TICKETS,
    cancelable(
      submitUserProgramTestimonialHandler,
      SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_CANCEL
    )
  )

  yield takeLatest(
    FETCH_PROGRAM_SUPPORT_TICKET_DETAILS,
    cancelable(
      fetchProgramSupportTicketDetailsHandler,
      FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_CANCEL
    )
  )

  yield takeLatest(
    SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION,
    cancelable(
      submitUserProgramRequestConversationHandler,
      SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_CANCEL
    )
  )

  yield takeLatest(
    REOPEN_USER_PROGRAM_SUPPORT_TICKET,
    cancelable(
      reopenUserProgramRequestHandler,
      REOPEN_USER_PROGRAM_SUPPORT_TICKET_CANCEL
    )
  )

  yield takeLatest(
    SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK,
    cancelable(
      submitUserProgramRequestFeedbackHandler,
      SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_CANCEL
    )
  )
}

export default ([] as any).concat(supportTicketsMiddleware())
