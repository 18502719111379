import { Reducer } from 'redux'
import { NewCourseItemActions } from './NewCourseItem.actions'
import {
  FETCH_NEW_COURSE_ITEM,
  FETCH_NEW_COURSE_ITEM_FAILURE,
  FETCH_NEW_COURSE_ITEM_SUCCESS,
} from './NewCourseItem.types'

interface NewCourseItemData {
  course_id: number
  course_name: string
  release_date: string | Date
}

export interface NewCourseItemState {
  data: NewCourseItemData[]
  loading: Boolean
  error: false | Error | Response
}

const initialState: NewCourseItemState = {
  data: [],
  loading: true,
  error: false,
}

const newCourseItemReducer: Reducer<
  NewCourseItemState,
  NewCourseItemActions
> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NEW_COURSE_ITEM_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      }
    case FETCH_NEW_COURSE_ITEM_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload,
      }
    case FETCH_NEW_COURSE_ITEM:
    default:
      return state
  }
}
export default newCourseItemReducer
