import { Reducer } from 'redux'
import {
  CompletedAssignmentsState,
  CompletedQuizzesState,
} from 'common/types/programSupport'
import { CompletedAssessmentsTypes } from './CompletedAssessments.actions'
import * as types from './CompletedAssessments.types'

const initialQuizzesStateModules: CompletedQuizzesState = {
  data: [],
  loading: false,
  error: false,
}

const initialAssignmentsStateModules: CompletedAssignmentsState = {
  data: [],
  loading: false,
  error: false,
}

export interface CompletedAssessmentsState {
  quizzes: CompletedQuizzesState
  assignments: CompletedAssignmentsState
}

const initialStateModules: CompletedAssessmentsState = {
  quizzes: initialQuizzesStateModules,
  assignments: initialAssignmentsStateModules,
}

export const completedAssessmentsReducer: Reducer<
  CompletedAssessmentsState,
  CompletedAssessmentsTypes
> = (state = initialStateModules, action): CompletedAssessmentsState => {
  switch (action.type) {
    case types.FETCH_COMPLETED_QUIZZES:
      return {
        ...state,
        quizzes: {
          ...state.quizzes,
          loading: true,
        },
      }

    case types.FETCH_COMPLETED_QUIZZES_FAILURE:
      return {
        ...state,
        quizzes: {
          ...state.quizzes,
          loading: false,
          error: action.payload,
        },
      }

    case types.FETCH_COMPLETED_QUIZZES_SUCCESS: {
      return {
        ...state,
        quizzes: {
          data: action.payload,
          loading: false,
          error: false,
        },
      }
    }

    case types.FETCH_COMPLETED_ASSIGNMENTS:
      return {
        ...state,
        assignments: {
          ...state.assignments,
          loading: true,
        },
      }

    case types.FETCH_COMPLETED_ASSIGNMENTS_FAILURE:
      return {
        ...state,
        assignments: {
          ...state.assignments,
          loading: false,
          error: action.payload,
        },
      }

    case types.FETCH_COMPLETED_ASSIGNMENTS_SUCCESS: {
      return {
        ...state,
        assignments: {
          data: action.payload,
          loading: false,
          error: false,
        },
      }
    }

    default:
      return state
  }
}
