import {
  CLAS_WORKFLOW_STATES,
  SERVICE_TASK_STATES,
} from 'common/utils/custom/codingLab'
import { Reducer } from 'redux'
import { CodingLabsActionTypes } from './CodingLabs.action'
import * as types from './CodingLabs.types'

export interface CodingLabsState {
  data: {
    submission: {
      coding_lab_url: string | null
      service_task_state: number | null
      workflow_state: number
      submission_id: number | null
      time_left: number
      service_setup_state: string | null
      due_at: string | null
    }
    assignment: {
      title: string | null
      description: string | null
      github_repo: string | null
      branch_name: string | null
      is_access_to_lms_assignment: boolean
      lms_assignment_id: number | null
      course_id: number | null
      test_cases: any
      inactivityTimeout: number
      promptTimeout: number
      doIdleCheck: boolean
    }
  }
  loading: boolean
  error: false | Error | Response
}

const initialStateModules: CodingLabsState = {
  data: {
    submission: {
      coding_lab_url: null,
      service_task_state: null,
      workflow_state: 0,
      submission_id: 0,
      time_left: 0,
      service_setup_state: null,
      due_at: null,
    },
    assignment: {
      title: null,
      description: null,
      github_repo: null,
      is_access_to_lms_assignment: false,
      branch_name: null,
      lms_assignment_id: null,
      course_id: null,
      test_cases: {},
      inactivityTimeout: 0,
      promptTimeout: 0,
      doIdleCheck: false,
    },
  },
  loading: false,
  error: false,
}

export const codingLabsReducer: Reducer<
  CodingLabsState,
  CodingLabsActionTypes
> = (state = initialStateModules, action): CodingLabsState => {
  switch (action.type) {
    case types.START_CODING_LAB:
    case types.STOP_CODING_LAB:
    case types.GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA:
    case types.SUBMIT_CODING_LAB_ASSIGNMENT:
      return {
        ...state,
        loading: true,
      }

    case types.GET_CODING_LAB_STATUS_FAILURE:
    case types.START_CODING_LAB_FAILURE:
    case types.STOP_CODING_LAB_FAILURE:
    case types.GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_FAILURE:
    case types.SUBMIT_CODING_LAB_ASSIGNMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case types.GET_CODING_LAB_STATUS_CANCEL:
    case types.START_CODING_LAB_CANCEL:
    case types.STOP_CODING_LAB_CANCEL:
    case types.GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_CANCEL:
    case types.SUBMIT_CODING_LAB_ASSIGNMENT_CANCEL:
      return {
        ...state,
        loading: false,
      }

    case types.GET_CODING_LAB_STATUS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          submission: action.payload,
        },
        loading: false,
        error: false,
      }
    }

    case types.GET_CODING_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          assignment: action.payload,
        },
        loading: false,
        error: false,
      }
    }
    case types.SUBMIT_CODING_LAB_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          submission: {
            ...state.data.submission,
            workflow_state: CLAS_WORKFLOW_STATES.evaluation_pending,
          },
        },
        loading: false,
        error: false,
      }
    case types.START_CODING_LAB_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          submission: {
            ...state.data.submission,
            service_task_state: SERVICE_TASK_STATES.START_ENQUEUED,
            workflow_state: CLAS_WORKFLOW_STATES.lab_created,
          },
        },
        loading: false,
        error: false,
      }
    }
    case types.STOP_CODING_LAB_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          submission: {
            ...state.data.submission,
            service_task_state: SERVICE_TASK_STATES.STOP_ENQUEUED,
          },
        },
        loading: false,
        error: false,
      }
    }

    default:
      return state
  }
}
