import { put, cancelled, takeLeading, call, select } from 'redux-saga/effects'
import { showAlertMessage } from 'web/providers/AlertsProvider'
import {
  FETCH_PROGRAM_LEADERBOARD,
  FETCH_PROGRAM_LEADERBOARD_CANCEL,
  FETCH_COURSE_LEADERBOARD,
  FETCH_COURSE_LEADERBOARD_CANCEL,
  USER_LEADERBOARD_ANONYMITY_UPDATE,
  USER_LEADERBOARD_ANONYMITY_UPDATE_CANCEL,
} from './Leaderboard.types'
import {
  getProgramLeaderboardAPI,
  getCourseLeaderboardAPI,
  updateUserLeaderboardAnonimityAPI,
} from './Leaderboard.api'
import {
  fetchProgramLeaderboardSuccess,
  fetchProgramLeaderboardFailure,
  fetchCourseLeaderboardSuccess,
  fetchCourseLeaderboardFailure,
  fetchCourseLeaderboard,
  userLeaderboardAnonimityUpdate,
  userLeaderboardAnonimityUpdateSuccess,
  userLeaderboardAnonimityUpdateFailure,
} from './Leaderboard.actions'
import { cancelable } from '../../../../common/utils'
import { fetchProgramLeaderboard } from '.'
import { UserID } from '../../../../common/types/user'
import { AppState } from '../../../store'
import { mixpanel } from '../../../../common/utils/mixpanel'

function* fetchProgramLeaderboardHandler(
  action: ReturnType<typeof fetchProgramLeaderboard>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data = yield call(
      getProgramLeaderboardAPI,
      action,
      userId,
      abortController.signal
    )
    yield put(fetchProgramLeaderboardSuccess(data, action.meta))
  } catch (e) {
    yield put(fetchProgramLeaderboardFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
function* fetchCourseLeaderboardHandler(
  action: ReturnType<typeof fetchCourseLeaderboard>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data = yield call(
      getCourseLeaderboardAPI,
      action,
      userId,
      abortController.signal
    )
    yield put(fetchCourseLeaderboardSuccess(data, action.meta))
  } catch (e) {
    yield put(fetchCourseLeaderboardFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* updateUserLeaderboardAnonimityHandler(
  action: ReturnType<typeof userLeaderboardAnonimityUpdate>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data = yield call(
      updateUserLeaderboardAnonimityAPI,
      action,
      userId,
      abortController.signal
    )
    mixpanel.track('Learner Leaderboard Anonymity', {
      leaderboard_anonymity: action.payload.leader_board_anonymity,
    })

    if (action.payload.refresh_leaderboard) {
      yield put(
        fetchProgramLeaderboard(
          { id: action.meta.program_group_id },
          { byLeaderboardType: 'program', id: action.meta.program_group_id }
        )
      )
    }

    if (action.payload.leader_board_anonymity === true) {
      yield put(
        showAlertMessage({
          variant: 'success',
          message: 'You are now anonymous on the leaderboard',
        })
      )
    } else {
      yield put(
        showAlertMessage({
          variant: 'success',
          message: 'You are now visible on the leaderboard',
        })
      )
    }

    yield put(
      userLeaderboardAnonimityUpdateSuccess(data, {
        program_group_id: action.meta.program_group_id,
      })
    )
  } catch (e) {
    yield put(
      userLeaderboardAnonimityUpdateFailure(e, {
        program_group_id: action.meta.program_group_id,
      })
    )
    if (e instanceof Error) {
      yield put(
        showAlertMessage({
          variant: 'error',
          message: 'An error occured while going anonymous',
        })
      )
    }
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* leaderboardMiddleware() {
  yield takeLeading(
    FETCH_PROGRAM_LEADERBOARD,
    cancelable(fetchProgramLeaderboardHandler, FETCH_PROGRAM_LEADERBOARD_CANCEL)
  )
  yield takeLeading(
    FETCH_COURSE_LEADERBOARD,
    cancelable(fetchCourseLeaderboardHandler, FETCH_COURSE_LEADERBOARD_CANCEL)
  )
  yield takeLeading(
    USER_LEADERBOARD_ANONYMITY_UPDATE,
    cancelable(
      updateUserLeaderboardAnonimityHandler,
      USER_LEADERBOARD_ANONYMITY_UPDATE_CANCEL
    )
  )
}

export default ([] as any).concat(leaderboardMiddleware())
