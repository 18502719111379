import { Reducer } from 'redux'
import * as types from './QuizSubmissions.types'
import { QuizSubmissionsActionTypes } from './QuizSubmissions.actions'
import {
  QuizQAActionTypes,
  quizQAActionTypes,
  quizQAReducer,
} from '../QuizQAProvider'
import { pick } from '../../../../../../common/utils'
import {
  QuizModuleItemData,
  QuizAttemptNo,
  QuizSubmissionData,
} from '../../../../../../common/types/courses/quiz'
import { SubmissionCommentsState } from '../../Assignment/AssignmentSubmissionsProvider'

export type QuizSubmissionsState = Exclude<
  QuizModuleItemData['itemActivity'],
  undefined
>['submissions']

export type QuizSubmissionCommentState = {
  submission_comments: SubmissionCommentsState
}

const initialSubmissionCommentState: SubmissionCommentsState = {
  data: [],
  error: false,
  loading: false,
}

export const initialSubmissionState: QuizSubmissionCommentState = {
  submission_comments: initialSubmissionCommentState,
}

const quizSubmissionsReducer: Reducer<
  QuizSubmissionsState,
  QuizSubmissionsActionTypes | QuizQAActionTypes
> = (state = null, action): QuizSubmissionsState => {
  switch (action.type) {
    case types.QUIZ_SUBMISSION_START_SUCCESS:
      return {
        ...state,
        [action.payload.attempt]: {
          ...action.payload,
        },
      }

    case types.QUIZ_SUBMISSIONS_FETCH_SUCCESS: {
      const submissions: Record<
        QuizAttemptNo,
        QuizSubmissionData
      > = action.payload.reduce(
        (
          acc: Record<QuizAttemptNo, QuizSubmissionData>,
          item: QuizSubmissionData
        ) => {
          acc[item.attempt] = item
          return acc
        },
        {}
      )
      return {
        // ...(state instanceof Error ? {} : state),
        ...submissions,
      }
    }
    case types.QUIZ_SUBMISSIONS_FETCH_FAILURE:
      return action.payload

    case types.QUIZ_SUBMISSION_COMPLETE_SUCCESS:
      return {
        ...state,
        [action.payload.attempt]: {
          ...action.payload,
        },
      }

    case quizQAActionTypes.QUIZ_SUBMISSION_QA_FETCH:
    case quizQAActionTypes.QUIZ_SUBMISSION_QA_FETCH_FAILURE:
    case quizQAActionTypes.QUIZ_SUBMISSION_QA_FETCH_SUCCESS:
    case quizQAActionTypes.QUIZ_QUESTION_FLAG:
    case quizQAActionTypes.QUIZ_QUESTION_UNFLAG:
    case quizQAActionTypes.QUIZ_SUBMISSION_ANSWERS_SAVE_SUCCESS:
    case quizQAActionTypes.QUIZ_SUBMISSION_ANSWERS_SAVE:
    case quizQAActionTypes.GET_FILE_DETAILS:
    case quizQAActionTypes.GET_FILE_DETAILS_SUCCESS:
    case quizQAActionTypes.GET_FILE_DETAILS_FAILURE:
    case quizQAActionTypes.GET_DYNAMIC_QUESTION_ANSWERS_SUCCESS:
      return !state || state instanceof Error || !state[action.meta.attemptNo]
        ? state
        : {
            ...state,
            [action.meta.attemptNo]: {
              ...state[action.meta.attemptNo]!,
              ...quizQAReducer(
                pick(state[action.meta.attemptNo]!, ['answers', 'questions']),
                action
              ),
            },
          }
    default:
      return state
  }
}

export const quizSubmissionCommentReducer: Reducer<
  QuizSubmissionCommentState,
  QuizSubmissionsActionTypes
> = (state = initialSubmissionState, action): QuizSubmissionCommentState => {
  switch (action.type) {
    case types.GET_QUIZ_SUBMISSION_COMMENTS:
      return {
        submission_comments: {
          ...state.submission_comments,
          loading: true,
        },
      }
    case types.GET_QUIZ_SUBMISSION_COMMENTS_SUCCESS:
      return {
        submission_comments: {
          data:
            action.payload && action.payload.submission_comments
              ? action.payload.submission_comments
              : [],
          error: false,
          loading: false,
        },
      }
    case types.GET_QUIZ_SUBMISSION_COMMENTS_FAILURE:
      return {
        submission_comments: {
          ...state.submission_comments,
          error: action.payload,
          loading: false,
        },
      }
    case types.GET_QUIZ_SUBMISSION_COMMENTS_CANCEL:
      return {
        submission_comments: {
          ...state.submission_comments,
          loading: false,
        },
      }
    default:
      return state
  }
}

export default quizSubmissionsReducer
