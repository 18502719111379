import {
  SUBMIT_COMMENTS,
  SUBMIT_COMMENTS_SUCCESS,
  SUBMIT_COMMENTS_FAILURE,
  SUBMIT_COMMENTS_CANCEL,
} from './SubmissionComments.types'
import { FileInfo } from '../../FilesProvider'
import { ContentID } from '../../../../common/types/courses/content'
import { ItemID } from '../../../../common/types/courses/moduleItem'
import { CourseID } from '../../../../common/types/courses'
import { UserID } from '../../../../common/types/user'

interface SubmitCommentsParams {
  filesList: FileInfo[]
  comments: string
  contentId: ContentID
  itemId: ItemID
  courseId: CourseID
  groupComment: Boolean
  url: string
  userId: UserID
  dataKey: string
}

interface SubmitCommentsMeta {
  itemId: ItemID
}

export const submitComments = (
  payload: SubmitCommentsParams,
  meta: SubmitCommentsMeta
) => ({
  type: SUBMIT_COMMENTS as typeof SUBMIT_COMMENTS,
  payload,
  meta,
})

export const submitCommentsSuccess = (
  payload: any,
  meta: SubmitCommentsMeta
) => ({
  type: SUBMIT_COMMENTS_SUCCESS as typeof SUBMIT_COMMENTS_SUCCESS,
  payload,
  meta,
})

export const submitCommentsFailure = (
  payload: Error,
  meta: SubmitCommentsMeta
) => ({
  type: SUBMIT_COMMENTS_FAILURE as typeof SUBMIT_COMMENTS_FAILURE,
  payload,
  meta,
})

export const submitCommentsCancel = (
  payload: any,
  meta: SubmitCommentsMeta
) => ({
  type: SUBMIT_COMMENTS_CANCEL as typeof SUBMIT_COMMENTS_CANCEL,
  payload,
  meta,
})

export type SubmissionCommentsTypes =
  | ReturnType<typeof submitComments>
  | ReturnType<typeof submitCommentsSuccess>
  | ReturnType<typeof submitCommentsFailure>
  | ReturnType<typeof submitCommentsCancel>
