import {
  FETCH_SPECIALISATION_COURSES,
  FETCH_SPECIALISATION_COURSES_SUCCESS,
  FETCH_SPECIALISATION_COURSES_FAILURE,
  FETCH_SPECIALISATION_COURSES_CANCEL,
} from './SpecializationCourses.types'

interface SpecializationCoursesPayload {}

export const fetchSpecializationCourses = (
  payload: SpecializationCoursesPayload
) => ({
  type: FETCH_SPECIALISATION_COURSES as typeof FETCH_SPECIALISATION_COURSES,
  payload,
})
export const fetchSpecializationCoursesSuccess = (payload: any) => ({
  type: FETCH_SPECIALISATION_COURSES_SUCCESS as typeof FETCH_SPECIALISATION_COURSES_SUCCESS,
  payload,
})
export const fetchSpecializationCoursesFailure = (payload: Error) => ({
  type: FETCH_SPECIALISATION_COURSES_FAILURE as typeof FETCH_SPECIALISATION_COURSES_FAILURE,
  payload,
})
export const fetchSpecializationCoursesCancel = (payload: any) => ({
  type: FETCH_SPECIALISATION_COURSES_CANCEL as typeof FETCH_SPECIALISATION_COURSES_CANCEL,
  payload,
})

export type SpecializationCoursesActions =
  | ReturnType<typeof fetchSpecializationCourses>
  | ReturnType<typeof fetchSpecializationCoursesSuccess>
  | ReturnType<typeof fetchSpecializationCoursesFailure>
  | ReturnType<typeof fetchSpecializationCoursesCancel>
