import { AllProgramsData } from 'common/types/programs'
import { Reducer } from 'redux'
import { AllProgramsActions } from './AllPrograms.actions'
import {
  FETCH_ALL_PROGRAMS,
  FETCH_ALL_PROGRAMS_SUCCESS,
  FETCH_ALL_PROGRAMS_FAILURE,
  FETCH_ALL_PROGRAMS_CANCEL,
} from './AllPrograms.types'

export interface AllProgramsState {
  data: AllProgramsData
  loading: boolean
  loaded: boolean
  error: false | Error | Response
}

const initialState: AllProgramsState = {
  data: {
    programs: {},
    program_categories: [],
    program_partners: {},
    domain_to_program_category_code_map: {},
  },
  loading: false,
  loaded: false,
  error: false as false,
}

export const allProgramsReducer: Reducer<
  AllProgramsState,
  AllProgramsActions
> = (state = initialState, action: any): AllProgramsState => {
  switch (action.type) {
    case FETCH_ALL_PROGRAMS:
      return { ...state, loading: true, loaded: false, error: false }
    case FETCH_ALL_PROGRAMS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
        error: false,
      }
    }
    case FETCH_ALL_PROGRAMS_FAILURE:
      return { ...state, loading: false, loaded: true, error: action.payload }
    case FETCH_ALL_PROGRAMS_CANCEL:
      return {
        ...state,
        loading: false,
        loaded: false,
      }
    default:
      return state
  }
}
