import {
  isAnswerValid,
  dynamicQuestionParsing,
} from 'common/utils/custom/courses/quiz'
import { combineReducers, Reducer } from 'redux'
import {
  QuizActiveAttemptActiveMode,
  QuizActiveAttemptStartingMode,
  QuizModuleItemData,
  QuizQuestionData,
  QuizQuestionID,
  DynamicQuestionData,
} from '../../../../../../common/types/courses/quiz'
import { QuizQAActionTypes, quizQAActionTypes } from '../QuizQAProvider'
import {
  QuizSubmissionsActionTypes,
  quizSubmissionsReducer,
  quizSubmissionCommentReducer,
  quizSubmissionTypes,
} from '../QuizSubmissionsProvider'
import { QuizActivityActionTypes } from './QuizActivity.actions'
import * as types from './QuizActivity.types'

type ActiveAttemptState = Exclude<
  QuizModuleItemData['itemActivity'],
  undefined
>['activeAttempt']

const activeAttemptReducer: Reducer<
  ActiveAttemptState,
  QuizSubmissionsActionTypes | QuizActivityActionTypes | QuizQAActionTypes
> = (state = null, action): ActiveAttemptState => {
  switch (action.type) {
    case quizSubmissionTypes.QUIZ_SUBMISSION_START:
      return {
        ...state,
        activeQuestionId: null,
        attemptNo: null,
        orderOfQuestions: null,
        timeRemaining: null,
        startType: 'CREATE',
        status: 'starting',
      }

    case quizSubmissionTypes.QUIZ_SUBMISSION_START_SUCCESS:
      return {
        ...state,
        attemptNo: action.payload.attempt,
      } as QuizActiveAttemptStartingMode

    case quizSubmissionTypes.QUIZ_SUBMISSION_START_FAILURE:
      return {
        ...state,
        status: 'failed',
      } as QuizActiveAttemptStartingMode

    case quizSubmissionTypes.QUIZ_SUBMISSION_RESUME:
      return {
        activeQuestionId: null,
        timeRemaining: null,
        orderOfQuestions: null,
        attemptNo: null,
        startType: 'RESUME',
        status: 'starting',
      } as QuizActiveAttemptStartingMode

    case quizSubmissionTypes.QUIZ_SUBMISSION_RESUME_SUCCESS:
      return {
        activeQuestionId: null,
        timeRemaining: null,
        orderOfQuestions: null,
        startType: 'RESUME',
        attemptNo: action.payload.attemptNo,
        status: 'starting',
      } as QuizActiveAttemptStartingMode

    case quizSubmissionTypes.QUIZ_SUBMISSION_RESUME_FAILURE:
      return {
        status: 'inactive',
      }

    case types.GET_QUIZ_SUBMISSION_SERVER_TIME_SUCCESS:
      return {
        ...state,
        timeRemaining: action.payload.time_left,
      } as QuizActiveAttemptActiveMode

    case quizSubmissionTypes.QUIZ_SUBMISSION_RESET:
      return {
        status: 'inactive',
      }

    case quizQAActionTypes.QUIZ_SUBMISSION_QA_FETCH:
      return {
        ...state,
        orderOfQuestions: null,
        activeQuestionId: null,
      } as QuizActiveAttemptStartingMode

    case quizQAActionTypes.QUIZ_SUBMISSION_QA_FETCH_SUCCESS: {
      if (!state || state.status !== 'starting') {
        return state
      }
      const flaggedQuestionsArray: QuizQuestionID[] = []
      const answeredQuestionsArray: QuizQuestionID[] = []
      const orderOfQuestions = action.payload
        .sort(
          (quesA: QuizQuestionData, quesB: QuizQuestionData) =>
            quesA.position - quesB.position
        )
        .map(i => {
          if (i.flagged) flaggedQuestionsArray.push(i.id)
          if (isAnswerValid(i.answer)) answeredQuestionsArray.push(i.id)
          return i.id
        })

      return {
        ...state,
        activeQuestionId: orderOfQuestions[0],
        status: 'active',
        orderOfQuestions,
        flaggedQuestions: flaggedQuestionsArray,
        answeredQuestions: answeredQuestionsArray,
        dynamicQuestions: {},
      } as QuizActiveAttemptActiveMode
    }

    case quizQAActionTypes.QUIZ_QUESTION_FLAG:
      return {
        ...state,
        flaggedQuestions: [
          ...(state as QuizActiveAttemptActiveMode).flaggedQuestions,
          action.meta.questionId,
        ],
      } as QuizActiveAttemptActiveMode

    case quizQAActionTypes.QUIZ_QUESTION_UNFLAG: {
      let flaggedQuestionIdArray = (state as QuizActiveAttemptActiveMode)
        .flaggedQuestions
      flaggedQuestionIdArray = flaggedQuestionIdArray.filter(
        id => id !== action.meta.questionId
      )
      return {
        ...state,
        flaggedQuestions: flaggedQuestionIdArray,
      } as QuizActiveAttemptActiveMode
    }

    case quizQAActionTypes.QUIZ_SUBMISSION_ANSWERS_SAVE: {
      let { answeredQuestions } = state as QuizActiveAttemptActiveMode
      // If the submission is reset, then return
      if (!Array.isArray(answeredQuestions)) {
        return null
      }
      if (isAnswerValid(action.payload.answer || null)) {
        if (!answeredQuestions.includes(action.meta.questionId))
          answeredQuestions.push(action.meta.questionId)
      } else
        answeredQuestions = answeredQuestions.filter(
          id => id !== action.meta.questionId
        )
      return {
        ...state,
        answeredQuestions,
      } as QuizActiveAttemptActiveMode
    }

    case quizSubmissionTypes.QUIZ_SUBMISSION_COMPLETE:
      return {
        ...state,
        status: 'submitting',
      } as QuizActiveAttemptActiveMode

    case quizSubmissionTypes.QUIZ_SUBMISSION_COMPLETE_SUCCESS:
      return {
        ...state,
        status: 'submitted',
      } as QuizActiveAttemptActiveMode

    case quizSubmissionTypes.QUIZ_SUBMISSION_COMPLETE_FAILURE:
    case quizQAActionTypes.QUIZ_SUBMISSION_ANSWERS_SAVE_FAILURE:
      return {
        ...state,
        status: action.payload.message === '401' ? 'unauthenticated' : 'failed',
      } as QuizActiveAttemptActiveMode

    case types.QUIZ_UPDATE_ACTIVE_QUESTION:
      return {
        ...state,
        activeQuestionId: action.payload.id,
      } as QuizActiveAttemptActiveMode

    case types.QUIZ_SUBMISSION_DYNAMIC_QA_FETCH_SUCCESS: {
      const currData: DynamicQuestionData = dynamicQuestionParsing(
        action.payload.chat_data
      )
      const currQuestionNumber = (
        (state as QuizActiveAttemptActiveMode).dynamicQuestions[
          action.meta.questionId
        ]?.quesData || []
      ).length
      return {
        ...state,
        dynamicQuestions: {
          ...(state as QuizActiveAttemptActiveMode).dynamicQuestions,
          [action.meta.questionId]: {
            ...((state as QuizActiveAttemptActiveMode).dynamicQuestions[
              action.meta.questionId
            ] || {}),
            threadId: action.payload.thread_id,
            modelId: action.payload.model_id,
            inputMode: action.payload.input_mode,
            quesData: [
              ...((state as QuizActiveAttemptActiveMode).dynamicQuestions[
                action.meta.questionId
              ]?.quesData || []),
              { ...currData, chat_id: action.payload.chat_id },
            ],
            ansData: action.meta.answer
              ? [
                  ...((state as QuizActiveAttemptActiveMode).dynamicQuestions[
                    action.meta.questionId
                  ]?.ansData || []),
                  action.meta.answer,
                ]
              : [
                  ...((state as QuizActiveAttemptActiveMode).dynamicQuestions[
                    action.meta.questionId
                  ]?.ansData || []),
                ],
            currQuestionNumber,
          },
        },
      } as QuizActiveAttemptActiveMode
    }

    case types.QUIZ_SUBMISSION_DYNAMIC_QA_LIST_FETCH_SUCCESS: {
      return {
        ...state,
        dynamicQuestions: {
          ...((state as QuizActiveAttemptActiveMode).dynamicQuestions || {}),
          [action.meta.questionId]: {
            ...(((state as QuizActiveAttemptActiveMode).dynamicQuestions || {})[
              action.meta.questionId
            ] || {}),
            quesData: action.payload.quesData,
            ansData: action.payload.ansData,
            currQuestionNumber: 0,
          },
        },
      } as QuizActiveAttemptActiveMode
    }

    case types.QUIZ_SUBMISSION_DYNAMIC_QA_UPDATE_QUESTION_INDEX:
      return {
        ...state,
        dynamicQuestions: {
          ...((state as QuizActiveAttemptActiveMode).dynamicQuestions || {}),
          [action.meta.questionId]: {
            ...(((state as QuizActiveAttemptActiveMode).dynamicQuestions || {})[
              action.meta.questionId
            ] || {}),
            currQuestionNumber: action.payload.index,
          },
        },
      } as QuizActiveAttemptActiveMode

    default:
      return state
  }
}

export default combineReducers<QuizModuleItemData['itemActivity']>({
  activeAttempt: activeAttemptReducer,
  submissions: quizSubmissionsReducer,
  submission: quizSubmissionCommentReducer,
})
