import { ResourceModuleItemData } from 'common/types/courses/moduleItem'
import { ProgramData } from 'common/types/programs'
import { DashboardData } from 'common/types/dashboard'
import {
  AcademyCourseData,
  AcademyUserCourseData,
  AcademySessionData,
  CourseGrades,
  CourseID,
  PremiumCoursesData,
  NewPremiumCoursesData,
  SummerCoursesData,
  CourseData,
  AcademyClassData,
  ActiveExcelerateCourseData,
} from '../../../types/courses'

export const grades: Partial<Record<CourseGrades, string>> = {
  E: 'Excellent',
  C: 'Completed',
  IC: 'Incomplete',
}

export const enterpriseDomainOrder = [
  'IT & Software',
  'Data Science',
  'Machine Learning',
  'Artificial Intelligence',
  'Cyber Security',
  'Cloud Computing',
  'Big Data',
  'Digital Marketing',
]

export const domesticDomainOrder = [
  'ChatGPT and Generative AI',
  'Artificial Intelligence',
  'Machine Learning',
  'Data Science',
  'Digital Marketing',
  'Cyber Security',
  'Cloud Computing',
  'Management',
  'UI/UX Design',
  'IT & Software',
  'Big Data',
  'Sales and Business Development',
  'Courses in Hindi',
  'Interview Preparation',
  'Masterclasses',
  'For College',
  'For School',
  'Webinars',
]

export const domainImageMapping: Partial<Record<string, string>> = {
  'IT & Software': 'it-and-software',
  'Data Science': 'data-science',
  'Artificial Intelligence': 'aiml',
  'Machine Learning': 'ml',
  'Digital Marketing': 'digital-marketing',
  'ChatGPT and Generative AI': 'generative-ai',
  'Management': 'marketing-and-finance',
  'Cyber Security': 'cyber-security',
  'Cloud Computing': 'cloud',
  'Big Data': 'bigdata',
  'Sales and Business Development': 'sales-and-business-development',
  'Interview Preparation': 'interview-preparation',
  'UI/UX Design': 'design-video-editing',
  'Masterclasses': 'masterclasses',
  'Courses in Hindi': 'hindi-courses',
  'For College': 'for-college',
  'For School': 'for-school',
  'Webinars': 'webinars',
  'PG Preview': 'webinars',
  'GL recommends': 'gl-recommends',
  'Design Thinking': 'design-video-editing',
  'Case Studies and Webinars': 'casestudies',
  'Courses with Spanish Subtitles': 'courses-with-spanish-subtitles',
}

export const internationalDomainOrder = [
  'Management',
  'ChatGPT and Generative AI',
  'Artificial Intelligence',
  'Machine Learning',
  'Cyber Security',
  'Data Science',
  'Cloud Computing',
  'Digital Marketing',
  'Design Thinking',
  'Courses with Spanish Subtitles',
  'IT & Software',
  'GL recommends',
  'Case Studies and Webinars',
]

export const getGradeText = (grade: CourseGrades) => {
  return grade in grades ? grades[grade] : grade
}

const contextIdPrefix = 'course_'
export const getCourseContextCode = (courseId: CourseID) =>
  `${contextIdPrefix}${courseId}`

export const getCourseIDFromContextCode = (contextId: string): CourseID =>
  contextId.replace(contextIdPrefix, '')

export const getCoursesByDomain = (
  courses: (AcademyCourseData | PremiumCoursesData)[]
) => {
  const coursesByDomain = courses.reduce(
    (
      acc: Record<string, (AcademyCourseData | PremiumCoursesData)[]>,
      courseData: AcademyCourseData | PremiumCoursesData
    ) => {
      if ('domain_name' in courseData && courseData.domain_name) {
        acc[courseData.domain_name] = [
          ...(acc[courseData.domain_name] || []),
          courseData,
        ]
      }
      return acc
    },
    {}
  )

  Object.keys(coursesByDomain).forEach(domain => {
    coursesByDomain[domain].sort((a, b) => a.display_order - b.display_order)
  })

  return coursesByDomain
}

export const isAcademyCourse = (
  courseData: AcademyUserCourseData
): courseData is AcademyCourseData =>
  courseData.course_type === 'Course' ||
  courseData.course_type === 'PG Free Preview'

export const isAcademySession = (
  courseData: AcademyUserCourseData
): courseData is AcademySessionData =>
  courseData.course_type === 'Live Masterclass' ||
  courseData.course_type === 'Live Session' ||
  courseData.course_type === 'Small Talk'

export const isAcademyClass = (
  courseData: AcademyUserCourseData
): courseData is AcademyClassData =>
  courseData.course_type === 'College Subjects' ||
  courseData.course_type === 'Job Preparation'

export const isUpcomingAcademySession = (
  courseData: AcademySessionData | AcademyClassData
): boolean => courseData.session_type === 'Upcoming'

export const isPremium = (
  courseData: AcademyUserCourseData
): courseData is PremiumCoursesData =>
  courseData.course_type === 'Premium Course'

export const isNewPremium = (
  courseData: AcademyUserCourseData
): courseData is NewPremiumCoursesData =>
  courseData.course_type === 'Premium Course' && 'fee_details' in courseData

export const isSummerCourse = (
  courseData: AcademyUserCourseData
): courseData is SummerCoursesData =>
  courseData.course_type === 'Remote Internship Course'

export const isAcademyUserCourse = (
  courseData: CourseData
): courseData is AcademyUserCourseData => 'course_type' in courseData

export const isExcelerateCourse = (
  courseData: CourseData
): courseData is ActiveExcelerateCourseData =>
  !!('excelerate_course' in courseData && courseData.excelerate_course)

export const glaCourseProgramCodeUtmMappings: { [key: string]: string[] } = {
  'pg-program-data-science-and-business-analytics-course': ['chat', 'drift_1'],
  'pg-program-data-science-online-course': ['facebook', 'lla2_feb2_1'],
  'advanced-certification-full-stack-software-development-iit-roorkee': [
    'facebook',
    'lae-offered_1',
  ],
  'pg-program-management-executive': ['chat', 'drift_1'],
  'pg-program-cloud-computing-course': ['facebook', 'offered-lac-1_1'],
  'mba-data-science-digital-marketing-jain': ['facebook', 'ltv_lae_1_1'],
  'pg-program-strategic-digital-marketing-course': ['chat', 'drift_1'],

  // In search api program_code is coming instead of course_program_code so added program codes below
  // only 2 codes are added because for other 5 both program_code and course_program_code are same
  // So this mapping now works with both course_program_code and program_code
  'pg-program-dsba': ['chat', 'drift_1'],
  'pg-program-cloud-computing': ['facebook', 'offered-lac-1_1'],
}

export const fetchAdditionalParamsForDownload = (
  data: ResourceModuleItemData,
  programData: ProgramData | null
) => {
  if (
    data.preview_url &&
    data.itemContent &&
    data.itemContent['content-type'] === 'application/pdf' &&
    data.itemContent.encrypted_header
  ) {
    return {
      params: {
        headers: {
          encrypted_header: data.itemContent.encrypted_header,
          translation_enabled: programData && programData.translation_enabled,
          translation_language: programData && programData.translation_language,
        },
      },
    }
  }
  return undefined
}

export const intlCourseCodeToStatsMapping: { [key: string]: string[] } = {
  // 'course_code': ['rating', 'total_ratings', 'total_learners']
  'azure-course': ['4.65', '1232', '15676'],
  'introduction-to-financial-management1': ['4.69', '1009', '20344'],
  'data-analytics-using-excel1': ['4.88', '1678', '17456'],
  'introduction-to-machine-learning2': ['4.87', '1455', '19000'],
  'introduction-to-cyber-security1': ['4.71', '1899', '20000'],
  'cloud-computing-foundations': ['4.66', '1345', '22999'],
  'foundation-of-artificial-intelligence': ['4.81', '1787', '15200'],
  'introduction-to-nosql': ['4.75', '1934', '16456'],
  'introduction-to-design-thinking': ['4.61', '1212', '21101'],
  'analisis-de-datos-usando-excel': ['4.65', '987', '1243'],
  'tutorial-mysql': ['4.69', '965', '1454'],
  'fundamentos-de-ciencia-de-datos': ['4.42', '1016', '1467'],
  'programacion-java': ['4.47', '946', '1352'],
  'fundamentos-de-python-para-principiantes': ['4.56', '1076', '1534'],
  'ingenieria-de-caracteristicas-para-el-aprendizaje-automatico': [
    '4.52',
    '986',
    '1398',
  ],
  'how-to-prepare-for-gre': ['4.52', '986', '1241'],
  'toefl-prep-course': ['4.31', '783', '1357'],
}

export const getTrialCourseIds = (data: DashboardData | undefined) => {
  const trialDetailsCurriculum =
    data &&
    'trial_details' in data &&
    'program_curriculum' in data.trial_details
      ? data.trial_details.program_curriculum
      : []
  const trialCourseIds: string[] = []

  trialDetailsCurriculum.forEach(curriculum => {
    if (curriculum.courses) {
      curriculum.courses.forEach(course => {
        if (course.lms_course_id)
          trialCourseIds.push(course.lms_course_id.toString())
      })
    }
  })
  return trialCourseIds
}
