import { apiCall } from '../../../common/utils'
import { UserID } from '../../../common/types/user'
import {
  initiateProctoring,
  onboardingPayload,
  updateProctoringDetails,
} from './Proctoring.actions'

export async function initiateProctoringAPI(
  user_id: UserID,
  action: ReturnType<typeof initiateProctoring>,
  signal: AbortSignal
) {
  const body = JSON.stringify({
    submission_uid: action.payload.submission_uid,
  })

  const query: { user_id: UserID; assessment_type?: string } = {
    user_id,
  }

  if (action.payload.is_coding_lab_assignment) {
    query.assessment_type = 'Assignment'
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/proctoring/assessments/${action.payload.assessment_id}/start_or_resume`,
    params: { signal, method: 'POST', body },
    query,
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function updateProctoringDetailsAPI(
  user_id: UserID,
  action: ReturnType<typeof updateProctoringDetails>,
  signal: AbortSignal
) {
  const body = JSON.stringify({
    submission_uid: action.payload.submission_uid,
    student_uid: action.payload.student_uid,
    status: action.payload.status,
  })

  const query: any = {
    user_id,
  }

  if (action.payload.assessment_type) {
    query.assessment_type = action.payload.assessment_type
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/proctoring/assessments/${action.payload.assessment_id}/update_details`,
    params: { signal, method: 'POST', body },
    query,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function updateUserProctorInfoAPI(
  data: {
    user_id: UserID
    attempt_id: number
    assessment_id: number
    count: number
    assessment_type?: string
  },
  signal: AbortSignal
) {
  const body = JSON.stringify({
    violations: data.count,
  })
  const query: { user_id: UserID; assessment_type?: string } = {
    user_id: data.user_id,
  }
  if (data.assessment_type) {
    query.assessment_type = data.assessment_type
  }
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/proctoring/assessments/${data.assessment_id}/attempts/${data.attempt_id}`,
    params: { signal, method: 'PUT', body },
    query,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function endProctoringAPI(
  data: {
    user_id: UserID
    attempt_id: number
    assessment_id: number
    completion_type: string
    assessment_type?: string // make assessment_type optional
  },
  signal: AbortSignal
) {
  const { user_id, completion_type, assessment_type } = data
  const body = JSON.stringify({
    completion_type,
  })
  const query: any = {
    user_id,
  }
  if (assessment_type) {
    query.assessment_type = assessment_type
  }
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/proctoring/assessments/${data.assessment_id}/attempts/${data.attempt_id}/complete`,
    params: { signal, method: 'PUT', body },
    query,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function submitOnboardingPhotoAPI(
  data: onboardingPayload,
  signal: AbortSignal
) {
  const formData = new FormData()
  formData.append('image', data.image)

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/user_images/${data.user_id}/context_type/${data.context_type}/context_id/${data.context_id}`,
    params: { signal, method: 'POST', body: formData },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
