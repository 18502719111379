import { UserID } from 'common/types/user'
import {
  FETCH_PROGRAM_LEADERBOARD,
  FETCH_PROGRAM_LEADERBOARD_SUCCESS,
  FETCH_PROGRAM_LEADERBOARD_FAILURE,
  FETCH_PROGRAM_LEADERBOARD_CANCEL,
  FETCH_COURSE_LEADERBOARD,
  FETCH_COURSE_LEADERBOARD_SUCCESS,
  FETCH_COURSE_LEADERBOARD_FAILURE,
  FETCH_COURSE_LEADERBOARD_CANCEL,
  USER_LEADERBOARD_ANONYMITY_UPDATE,
  USER_LEADERBOARD_ANONYMITY_UPDATE_SUCCESS,
  USER_LEADERBOARD_ANONYMITY_UPDATE_FAILURE,
  USER_LEADERBOARD_ANONYMITY_UPDATE_CANCEL,
} from './Leaderboard.types'
import { ByLeaderboardType } from '../../../../common/types/leaderboard'

interface LeaderboardParams {
  id: string | number
}
interface LeaderboardMeta {
  byLeaderboardType: ByLeaderboardType
  id: string | number
}

interface UserLeaderBoardAnonimityMeta {
  program_group_id: string | number
}

interface UserLeaderBoardAnonimityPayload {
  leader_board_anonymity: boolean
  refresh_leaderboard?: boolean
}

export const fetchProgramLeaderboard = (
  payload: LeaderboardParams,
  meta: LeaderboardMeta
) => ({
  type: FETCH_PROGRAM_LEADERBOARD as typeof FETCH_PROGRAM_LEADERBOARD,
  payload,
  meta,
})
export const fetchProgramLeaderboardSuccess = (
  payload: any,
  meta: LeaderboardMeta
) => ({
  type: FETCH_PROGRAM_LEADERBOARD_SUCCESS as typeof FETCH_PROGRAM_LEADERBOARD_SUCCESS,
  payload,
  meta,
})
export const fetchProgramLeaderboardFailure = (
  payload: Error,
  meta: LeaderboardMeta
) => ({
  type: FETCH_PROGRAM_LEADERBOARD_FAILURE as typeof FETCH_PROGRAM_LEADERBOARD_FAILURE,
  payload,
  meta,
})
export const fetchProgramLeaderboardCancel = () => ({
  type: FETCH_PROGRAM_LEADERBOARD_CANCEL as typeof FETCH_PROGRAM_LEADERBOARD_CANCEL,
})

export const fetchCourseLeaderboard = (
  payload: LeaderboardParams,
  meta: LeaderboardMeta
) => ({
  type: FETCH_COURSE_LEADERBOARD as typeof FETCH_COURSE_LEADERBOARD,
  payload,
  meta,
})
export const fetchCourseLeaderboardSuccess = (
  payload: any,
  meta: LeaderboardMeta
) => ({
  type: FETCH_COURSE_LEADERBOARD_SUCCESS as typeof FETCH_COURSE_LEADERBOARD_SUCCESS,
  payload,
  meta,
})
export const fetchCourseLeaderboardFailure = (
  payload: Error,
  meta: LeaderboardMeta
) => ({
  type: FETCH_COURSE_LEADERBOARD_FAILURE as typeof FETCH_COURSE_LEADERBOARD_FAILURE,
  payload,
  meta,
})
export const fetchCourseLeaderboardCancel = () => ({
  type: FETCH_COURSE_LEADERBOARD_CANCEL as typeof FETCH_COURSE_LEADERBOARD_CANCEL,
})

export const userLeaderboardAnonimityUpdate = (
  payload: UserLeaderBoardAnonimityPayload,
  meta: UserLeaderBoardAnonimityMeta
) => ({
  type: USER_LEADERBOARD_ANONYMITY_UPDATE as typeof USER_LEADERBOARD_ANONYMITY_UPDATE,
  payload,
  meta,
})

export const userLeaderboardAnonimityUpdateSuccess = (
  payload: any,
  meta: UserLeaderBoardAnonimityMeta
) => ({
  type: USER_LEADERBOARD_ANONYMITY_UPDATE_SUCCESS as typeof USER_LEADERBOARD_ANONYMITY_UPDATE_SUCCESS,
  payload,
  meta,
})

export const userLeaderboardAnonimityUpdateFailure = (
  payload: Error,
  meta: UserLeaderBoardAnonimityMeta
) => ({
  type: USER_LEADERBOARD_ANONYMITY_UPDATE_FAILURE as typeof USER_LEADERBOARD_ANONYMITY_UPDATE_FAILURE,
  payload,
  meta,
})

export const userLeaderboardAnonimityUpdateCancel = () => ({
  type: USER_LEADERBOARD_ANONYMITY_UPDATE_CANCEL as typeof USER_LEADERBOARD_ANONYMITY_UPDATE_CANCEL,
})

export type LeaderboardActions =
  | ReturnType<typeof fetchProgramLeaderboard>
  | ReturnType<typeof fetchProgramLeaderboardSuccess>
  | ReturnType<typeof fetchProgramLeaderboardFailure>
  | ReturnType<typeof fetchProgramLeaderboardCancel>
  | ReturnType<typeof fetchCourseLeaderboard>
  | ReturnType<typeof fetchCourseLeaderboardSuccess>
  | ReturnType<typeof fetchCourseLeaderboardFailure>
  | ReturnType<typeof fetchCourseLeaderboardCancel>
  | ReturnType<typeof userLeaderboardAnonimityUpdate>
  | ReturnType<typeof userLeaderboardAnonimityUpdateSuccess>
  | ReturnType<typeof userLeaderboardAnonimityUpdateFailure>
  | ReturnType<typeof userLeaderboardAnonimityUpdateCancel>
