import {
  FETCH_ACTIVITY_CONTENT,
  FETCH_ACTIVITY_CONTENT_SUCCESS,
  FETCH_ACTIVITY_CONTENT_FAILURE,
  FETCH_ACTIVITY_CONTENT_CANCEL,
  FETCH_RESIDENCY_CONTENT,
  FETCH_RESIDENCY_CONTENT_SUCCESS,
  FETCH_RESIDENCY_CONTENT_FAILURE,
  FETCH_RESIDENCY_CONTENT_CANCEL,
  UPDATE_CURRENT_ACTIVE_TAB,
} from './Activity.types'
import { ActivityTab } from '../../../components/Activity'

export interface ResidencyContentPayload {
  residencyId: number
}

export const fetchActivityContent = () => ({
  type: FETCH_ACTIVITY_CONTENT as typeof FETCH_ACTIVITY_CONTENT,
})

export const fetchActivityContentSuccess = (payload: any) => ({
  type: FETCH_ACTIVITY_CONTENT_SUCCESS as typeof FETCH_ACTIVITY_CONTENT_SUCCESS,
  payload,
})

export const fetchActivityContentFailure = (payload: Error) => ({
  type: FETCH_ACTIVITY_CONTENT_FAILURE as typeof FETCH_ACTIVITY_CONTENT_FAILURE,
  payload,
})

export const fetchActivityContentCancel = () => ({
  type: FETCH_ACTIVITY_CONTENT_CANCEL as typeof FETCH_ACTIVITY_CONTENT_CANCEL,
})

export const fetchResidencyContent = (payload: ResidencyContentPayload) => ({
  type: FETCH_RESIDENCY_CONTENT as typeof FETCH_RESIDENCY_CONTENT,
  payload,
})

export const fetchResidencyContentSuccess = (
  payload: any,
  meta: ResidencyContentPayload
) => ({
  type: FETCH_RESIDENCY_CONTENT_SUCCESS as typeof FETCH_RESIDENCY_CONTENT_SUCCESS,
  payload,
  meta,
})

export const fetchResidencyContentFailure = (payload: Error) => ({
  type: FETCH_RESIDENCY_CONTENT_FAILURE as typeof FETCH_RESIDENCY_CONTENT_FAILURE,
  payload,
})

export const fetchResidencyContentCancel = () => ({
  type: FETCH_RESIDENCY_CONTENT_CANCEL as typeof FETCH_RESIDENCY_CONTENT_CANCEL,
})

export const updateCurrentActiveTab = (payload: {
  activeTab: ActivityTab
}) => ({
  type: UPDATE_CURRENT_ACTIVE_TAB as typeof UPDATE_CURRENT_ACTIVE_TAB,
  payload,
})

export type ActivityActions =
  | ReturnType<typeof fetchActivityContent>
  | ReturnType<typeof fetchActivityContentSuccess>
  | ReturnType<typeof fetchActivityContentFailure>
  | ReturnType<typeof fetchActivityContentCancel>
  | ReturnType<typeof fetchResidencyContent>
  | ReturnType<typeof fetchResidencyContentSuccess>
  | ReturnType<typeof fetchResidencyContentFailure>
  | ReturnType<typeof fetchResidencyContentCancel>
  | ReturnType<typeof updateCurrentActiveTab>
