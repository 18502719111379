export const COURSE_LIST_FETCH = 'COURSE_LIST_FETCH'
export const COURSE_LIST_FETCH_SUCCESS = 'COURSE_LIST_FETCH_SUCCESS'
export const COURSE_LIST_FETCH_FAILURE = 'COURSE_LIST_FETCH_FAILURE'
export const COURSE_LIST_FETCH_CANCEL = 'COURSE_LIST_FETCH_CANCEL'

export const COURSE_DETAILS_FETCH = 'COURSE_DETAILS_FETCH'
export const COURSE_DETAILS_FETCH_SUCCESS = 'COURSE_DETAILS_FETCH_SUCCESS'
export const COURSE_DETAILS_FETCH_FAILURE = 'COURSE_DETAILS_FETCH_FAILURE'
export const COURSE_DETAILS_FETCH_CANCEL = 'COURSE_DETAILS_FETCH_CANCEL'

export const COURSE_TABS_FETCH = 'COURSE_TABS_FETCH'
export const COURSE_TABS_FETCH_SUCCESS = 'COURSE_TABS_FETCH_SUCCESS'
export const COURSE_TABS_FETCH_FAILURE = 'COURSE_TABS_FETCH_FAILURE'

export const COURSE_RATING_SUBMIT = 'COURSE_RATING_SUBMIT'
export const COURSE_RATING_SUBMIT_SUCCESS = 'COURSE_RATING_SUBMIT_SUCCESS'
export const COURSE_RATING_SUBMIT_FAILURE = 'COURSE_RATING_SUBMIT_FAILURE'
