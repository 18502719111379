import React from 'react'
import { Badge, IconButton } from '@material-ui/core'
import { AnnouncementIcon } from '../Icons'

interface Props {
  className?: string
  count: number
}

const Notification = (props: Props) => {
  return (
    <IconButton className={props.className}>
      <Badge badgeContent={props.count}>
        <AnnouncementIcon />
      </Badge>
    </IconButton>
  )
}

export default Notification
