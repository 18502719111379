import {
  LikeMetaData,
  PeerReviewData,
  PeerReviewID,
} from 'common/types/peerReview'
import {
  DiscussionCommentData,
  DiscussionEntry,
  DiscussionEntryID,
  DiscussionID,
} from 'common/types/courses/dq'
import { CourseID } from 'common/types/courses'
import { AssignmentID } from 'common/types/courses/assignment'
import {
  FETCH_PEER_REVIEWS,
  FETCH_PEER_REVIEWS_FAILURE,
  FETCH_PEER_REVIEWS_SUCCESS,
  FETCH_PEER_REVIEWS_CANCEL,
  FETCH_PEER_REVIEWS_DQ,
  FETCH_PEER_REVIEWS_DQ_CANCEL,
  FETCH_PEER_REVIEWS_DQ_FAILURE,
  FETCH_PEER_REVIEWS_DQ_SUCCESS,
  FETCH_PEER_REVIEWS_DQ_COMMENTS,
  FETCH_PEER_REVIEWS_DQ_COMMENTS_SUCCESS,
  FETCH_PEER_REVIEWS_DQ_COMMENTS_FAILURE,
  FETCH_PEER_REVIEWS_DQ_COMMENTS_CANCEL,
  UPDATE_PEER_REVIEWS_COMMENT_RATING,
  UPDATE_PEER_REVIEWS_COMMENT_RATING_SUCCESS,
  UPDATE_PEER_REVIEWS_COMMENT_RATING_FAILURE,
  UPDATE_PEER_REVIEWS_COMMENT_RATING_CANCEL,
  POST_PEER_REVIEW_COMMENT,
  POST_PEER_REVIEW_COMMENT_SUCCESS,
  POST_PEER_REVIEW_COMMENT_FAILURE,
  POST_PEER_REVIEW_COMMENT_CANCEL,
  POST_PEER_REVIEW_COMMENT_REPLY,
  POST_PEER_REVIEW_COMMENT_REPLY_SUCCESS,
  POST_PEER_REVIEW_COMMENT_REPLY_CANCEL,
  POST_PEER_REVIEW_COMMENT_REPLY_FAILURE,
  UPDATE_PEER_REVIEW_COMMENT,
  UPDATE_PEER_REVIEW_COMMENT_SUCCESS,
  UPDATE_PEER_REVIEW_COMMENT_CANCEL,
  UPDATE_PEER_REVIEW_COMMENT_FAILURE,
  REACT_TO_PEER_REVIEW,
  REACT_TO_PEER_REVIEW_SUCCESS,
  REACT_TO_PEER_REVIEW_CANCEL,
  REACT_TO_PEER_REVIEW_FAILURE,
  FETCH_PEER_REVIEW_REACTION_DATA,
  FETCH_PEER_REVIEW_REACTION_DATA_SUCCESS,
  FETCH_PEER_REVIEW_REACTION_DATA_FAILURE,
  FETCH_PEER_REVIEW_REACTION_DATA_CANCEL,
  MARK_COMMENT_AS_READ,
  MARK_COMMENT_AS_READ_SUCCESS,
  MARK_COMMENT_AS_READ_FAILURE,
  MARK_COMMENT_AS_READ_CANCEL,
  FETCH_PEER_REVIEW_FILTERS,
  FETCH_PEER_REVIEW_FILTERS_FAILURE,
  FETCH_PEER_REVIEW_FILTERS_CANCEL,
  FETCH_PEER_REVIEW_FILTERS_SUCCESS,
} from './PeerReviews.types'

export const fetchPeerReviews = (
  page: number,
  assignment_id?: AssignmentID
) => ({
  type: FETCH_PEER_REVIEWS as typeof FETCH_PEER_REVIEWS,
  page,
  assignment_id,
})
export const fetchPeerReviewsSuccess = (payload: PeerReviewData) => ({
  type: FETCH_PEER_REVIEWS_SUCCESS as typeof FETCH_PEER_REVIEWS_SUCCESS,
  payload,
})
export const fetchPeerReviewsFailure = (payload: Error) => ({
  type: FETCH_PEER_REVIEWS_FAILURE as typeof FETCH_PEER_REVIEWS_FAILURE,
  payload,
})
export const fetchPeerReviewsCancel = () => ({
  type: FETCH_PEER_REVIEWS_CANCEL as typeof FETCH_PEER_REVIEWS_CANCEL,
})

export const fetchPeerReviewsDQ = (
  topicId: DiscussionID,
  courseId: CourseID,
  peerReviewId: PeerReviewID
) => ({
  type: FETCH_PEER_REVIEWS_DQ as typeof FETCH_PEER_REVIEWS_DQ,
  topicId,
  courseId,
  peerReviewId,
})
export const fetchPeerReviewsDQSuccess = (payload: {
  commentsData: DiscussionCommentData
  likesMetaData: LikeMetaData
}) => ({
  type: FETCH_PEER_REVIEWS_DQ_SUCCESS as typeof FETCH_PEER_REVIEWS_DQ_SUCCESS,
  payload,
})
export const fetchPeerReviewsDQFailure = (payload: Error) => ({
  type: FETCH_PEER_REVIEWS_DQ_FAILURE as typeof FETCH_PEER_REVIEWS_DQ_FAILURE,
  payload,
})
export const fetchPeerReviewsDQCancel = () => ({
  type: FETCH_PEER_REVIEWS_DQ_CANCEL as typeof FETCH_PEER_REVIEWS_DQ_CANCEL,
})

export const postPeerReviewCommentReply = (payload: {
  topicId: DiscussionID
  courseId: CourseID
  message: string
  parentId: DiscussionEntryID
}) => ({
  type: POST_PEER_REVIEW_COMMENT_REPLY as typeof POST_PEER_REVIEW_COMMENT_REPLY,
  payload,
})
export const postPeerReviewCommentReplySuccess = (payload: any) => ({
  type: POST_PEER_REVIEW_COMMENT_REPLY_SUCCESS as typeof POST_PEER_REVIEW_COMMENT_REPLY_SUCCESS,
  payload,
})
export const postPeerReviewCommentReplyFailure = (payload: Error) => ({
  type: POST_PEER_REVIEW_COMMENT_REPLY_FAILURE as typeof POST_PEER_REVIEW_COMMENT_REPLY_FAILURE,
  payload,
})
export const postPeerReviewCommentReplyCancel = () => ({
  type: POST_PEER_REVIEW_COMMENT_REPLY_CANCEL as typeof POST_PEER_REVIEW_COMMENT_REPLY_CANCEL,
})

export const postPeerReviewComment = (payload: {
  topicId: DiscussionID
  courseId: CourseID
  message: string
}) => ({
  type: POST_PEER_REVIEW_COMMENT as typeof POST_PEER_REVIEW_COMMENT,
  payload,
})
export const postPeerReviewCommentSuccess = (payload: any) => ({
  type: POST_PEER_REVIEW_COMMENT_SUCCESS as typeof POST_PEER_REVIEW_COMMENT_SUCCESS,
  payload,
})
export const postPeerReviewCommentFailure = (payload: Error) => ({
  type: POST_PEER_REVIEW_COMMENT_FAILURE as typeof POST_PEER_REVIEW_COMMENT_FAILURE,
  payload,
})
export const postPeerReviewCommentCancel = () => ({
  type: POST_PEER_REVIEW_COMMENT_CANCEL as typeof POST_PEER_REVIEW_COMMENT_CANCEL,
})

export const updatePeerReviewComment = (payload: {
  topicId: DiscussionID
  courseId: CourseID
  message: string
  entryId: DiscussionEntryID
}) => ({
  type: UPDATE_PEER_REVIEW_COMMENT as typeof UPDATE_PEER_REVIEW_COMMENT,
  payload,
})
export const updatePeerReviewCommentSuccess = (payload: any) => ({
  type: UPDATE_PEER_REVIEW_COMMENT_SUCCESS as typeof UPDATE_PEER_REVIEW_COMMENT_SUCCESS,
  payload,
})
export const updatePeerReviewCommentFailure = (payload: Error) => ({
  type: UPDATE_PEER_REVIEW_COMMENT_FAILURE as typeof UPDATE_PEER_REVIEW_COMMENT_FAILURE,
  payload,
})
export const updatePeerReviewCommentCancel = () => ({
  type: UPDATE_PEER_REVIEW_COMMENT_CANCEL as typeof UPDATE_PEER_REVIEW_COMMENT_CANCEL,
})

export const fetchPeerReviewsDQComments = (
  topicId: DiscussionID,
  courseId: CourseID
) => ({
  type: FETCH_PEER_REVIEWS_DQ_COMMENTS as typeof FETCH_PEER_REVIEWS_DQ_COMMENTS,
  topicId,
  courseId,
})
export const fetchPeerReviewsDQCommentsSuccess = (payload: any) => ({
  type: FETCH_PEER_REVIEWS_DQ_COMMENTS_SUCCESS as typeof FETCH_PEER_REVIEWS_DQ_COMMENTS_SUCCESS,
  payload,
})
export const fetchPeerReviewsDQCommentsFailure = (payload: Error) => ({
  type: FETCH_PEER_REVIEWS_DQ_COMMENTS_FAILURE as typeof FETCH_PEER_REVIEWS_DQ_COMMENTS_FAILURE,
  payload,
})
export const fetchPeerReviewsDQCommentsCancel = () => ({
  type: FETCH_PEER_REVIEWS_DQ_COMMENTS_CANCEL as typeof FETCH_PEER_REVIEWS_DQ_COMMENTS_CANCEL,
})

export const updatePeerReviewCommentRating = (
  topicId: DiscussionID,
  courseId: CourseID,
  discussionEntry: DiscussionEntry,
  rating: 1 | 0
) => ({
  type: UPDATE_PEER_REVIEWS_COMMENT_RATING as typeof UPDATE_PEER_REVIEWS_COMMENT_RATING,
  topicId,
  courseId,
  discussionEntry,
  rating,
})
export const updatePeerReviewCommentRatingSuccess = (payload: {
  discussionEntry: DiscussionEntry
  rating: 1 | 0
}) => ({
  type: UPDATE_PEER_REVIEWS_COMMENT_RATING_SUCCESS as typeof UPDATE_PEER_REVIEWS_COMMENT_RATING_SUCCESS,
  payload,
})
export const updatePeerReviewCommentRatingFailure = (payload: Error) => ({
  type: UPDATE_PEER_REVIEWS_COMMENT_RATING_FAILURE as typeof UPDATE_PEER_REVIEWS_COMMENT_RATING_FAILURE,
  payload,
})
export const updatePeerReviewCommentRatingCancel = () => ({
  type: UPDATE_PEER_REVIEWS_COMMENT_RATING_CANCEL as typeof UPDATE_PEER_REVIEWS_COMMENT_RATING_CANCEL,
})

export const reactToPeerReview = (
  peerReviewId: PeerReviewID,
  isLiked: boolean
) => ({
  type: REACT_TO_PEER_REVIEW as typeof REACT_TO_PEER_REVIEW,
  peerReviewId,
  isLiked,
})
export const reactToPeerReviewSuccess = (
  peerReviewId: PeerReviewID,
  isLiked: boolean,
  likesMetaData: any
) => ({
  type: REACT_TO_PEER_REVIEW_SUCCESS as typeof REACT_TO_PEER_REVIEW_SUCCESS,
  peerReviewId,
  isLiked,
  likesMetaData,
})
export const reactToPeerReviewFailure = (payload: Error) => ({
  type: REACT_TO_PEER_REVIEW_FAILURE as typeof REACT_TO_PEER_REVIEW_FAILURE,
  payload,
})
export const reactToPeerReviewCancel = () => ({
  type: REACT_TO_PEER_REVIEW_CANCEL as typeof REACT_TO_PEER_REVIEW_CANCEL,
})

export const fetchPeerReviewReactionData = (peerReviewId: PeerReviewID) => ({
  type: FETCH_PEER_REVIEW_REACTION_DATA as typeof FETCH_PEER_REVIEW_REACTION_DATA,
  peerReviewId,
})
export const fetchPeerReviewReactionDataSuccess = (payload: any) => ({
  type: FETCH_PEER_REVIEW_REACTION_DATA_SUCCESS as typeof FETCH_PEER_REVIEW_REACTION_DATA_SUCCESS,
  payload,
})
export const fetchPeerReviewReactionDataFailure = (payload: Error) => ({
  type: FETCH_PEER_REVIEW_REACTION_DATA_FAILURE as typeof FETCH_PEER_REVIEW_REACTION_DATA_FAILURE,
  payload,
})
export const fetchPeerReviewReactionDataCancel = () => ({
  type: FETCH_PEER_REVIEW_REACTION_DATA_CANCEL as typeof FETCH_PEER_REVIEW_REACTION_DATA_CANCEL,
})

export const markCommentAsRead = (payload: {
  courseId: CourseID
  discussionTopicId: DiscussionID
  discussionEntryId: DiscussionEntryID
}) => ({
  type: MARK_COMMENT_AS_READ as typeof MARK_COMMENT_AS_READ,
  payload,
})
export const markCommentAsReadSuccess = (
  discussionEntryId: DiscussionEntryID
) => ({
  type: MARK_COMMENT_AS_READ_SUCCESS as typeof MARK_COMMENT_AS_READ_SUCCESS,
  discussionEntryId,
})
export const markCommentAsReadFailure = (payload: Error) => ({
  type: MARK_COMMENT_AS_READ_FAILURE as typeof MARK_COMMENT_AS_READ_FAILURE,
  payload,
})
export const markCommentAsReadCancel = () => ({
  type: MARK_COMMENT_AS_READ_CANCEL as typeof MARK_COMMENT_AS_READ_CANCEL,
})

export const fetchPeerReviewFilters = () => ({
  type: FETCH_PEER_REVIEW_FILTERS as typeof FETCH_PEER_REVIEW_FILTERS,
})
export const fetchPeerReviewFiltersSuccess = (payload: any) => ({
  type: FETCH_PEER_REVIEW_FILTERS_SUCCESS as typeof FETCH_PEER_REVIEW_FILTERS_SUCCESS,
  payload,
})
export const fetchPeerReviewFiltersFailure = () => ({
  type: FETCH_PEER_REVIEW_FILTERS_FAILURE as typeof FETCH_PEER_REVIEW_FILTERS_FAILURE,
})
export const fetchPeerReviewFiltersCancel = () => ({
  type: FETCH_PEER_REVIEW_FILTERS_CANCEL as typeof FETCH_PEER_REVIEW_FILTERS_CANCEL,
})

export type PeerReviewActions =
  | ReturnType<typeof fetchPeerReviews>
  | ReturnType<typeof fetchPeerReviewsSuccess>
  | ReturnType<typeof fetchPeerReviewsFailure>
  | ReturnType<typeof fetchPeerReviewsCancel>
  | ReturnType<typeof fetchPeerReviewsDQ>
  | ReturnType<typeof fetchPeerReviewsDQSuccess>
  | ReturnType<typeof fetchPeerReviewsDQFailure>
  | ReturnType<typeof fetchPeerReviewsDQCancel>
  | ReturnType<typeof fetchPeerReviewsDQComments>
  | ReturnType<typeof fetchPeerReviewsDQCommentsSuccess>
  | ReturnType<typeof fetchPeerReviewsDQCommentsFailure>
  | ReturnType<typeof fetchPeerReviewsDQCommentsCancel>
  | ReturnType<typeof updatePeerReviewCommentRating>
  | ReturnType<typeof updatePeerReviewCommentRatingSuccess>
  | ReturnType<typeof updatePeerReviewCommentRatingFailure>
  | ReturnType<typeof updatePeerReviewCommentRatingCancel>
  | ReturnType<typeof postPeerReviewComment>
  | ReturnType<typeof postPeerReviewCommentSuccess>
  | ReturnType<typeof postPeerReviewCommentFailure>
  | ReturnType<typeof postPeerReviewCommentCancel>
  | ReturnType<typeof postPeerReviewCommentReply>
  | ReturnType<typeof postPeerReviewCommentReplySuccess>
  | ReturnType<typeof postPeerReviewCommentReplyFailure>
  | ReturnType<typeof postPeerReviewCommentReplyCancel>
  | ReturnType<typeof reactToPeerReview>
  | ReturnType<typeof reactToPeerReviewSuccess>
  | ReturnType<typeof fetchPeerReviewReactionData>
  | ReturnType<typeof fetchPeerReviewReactionData>
  | ReturnType<typeof fetchPeerReviewReactionDataSuccess>
  | ReturnType<typeof fetchPeerReviewReactionDataCancel>
  | ReturnType<typeof fetchPeerReviewReactionDataFailure>
  | ReturnType<typeof markCommentAsRead>
  | ReturnType<typeof markCommentAsReadSuccess>
  | ReturnType<typeof markCommentAsReadFailure>
  | ReturnType<typeof markCommentAsReadCancel>
  | ReturnType<typeof fetchPeerReviewFilters>
  | ReturnType<typeof fetchPeerReviewFiltersSuccess>
  | ReturnType<typeof fetchPeerReviewFiltersFailure>
  | ReturnType<typeof fetchPeerReviewFiltersCancel>
