import {
  HackathonData,
  HackathonID,
  HackathonProblemFile,
  HackathonProblemFileCategory,
  HackathonPerformanceData,
  HackathonPerformanceSubmissionsData,
} from 'common/types/hackathons'
import { isDateBeforeToday } from 'common/utils/time'
import { Reducer } from 'redux'
import { HackathonsActionTypes } from './Hackathons.actions'
import {
  ADD_MEMBERS_HACKATHON_TEAM,
  ADD_MEMBERS_HACKATHON_TEAM_FAILURE,
  ADD_MEMBERS_HACKATHON_TEAM_SUCCESS,
  FETCH_HACKATHON_DETAILS,
  FETCH_HACKATHON_DETAILS_CANCEL,
  FETCH_HACKATHON_DETAILS_FAILURE,
  FETCH_HACKATHON_DETAILS_SUCCESS,
  FETCH_HACKATHON_LEADERBOARD_FAILURE,
  FETCH_HACKATHON_LEADERBOARD_SUCCESS,
  FETCH_HACKATHON_LIST,
  FETCH_HACKATHON_LIST_CANCEL,
  FETCH_HACKATHON_LIST_FAILURE,
  FETCH_HACKATHON_LIST_SUCCESS,
  FETCH_HACKATHON_TEAM_PERFORMANCE_FAILURE,
  FETCH_HACKATHON_TEAM_PERFORMANCE_SUCCESS,
  REGISTER_HACKATHON_TEAM,
  REGISTER_HACKATHON_TEAM_FAILURE,
  REGISTER_HACKATHON_TEAM_SUCCESS,
  SUBMIT_HACKATHON_SOLUTION,
  SUBMIT_HACKATHON_SOLUTION_FAILURE,
  SUBMIT_HACKATHON_SOLUTION_SUCCESS,
} from './Hackathons.types'

interface HackathonStateData {
  byId: { [s in HackathonID]?: HackathonData }
  completedIds: HackathonID[]
  activeIds: HackathonID[]
  hasAllHackathons: boolean
}

export interface HackathonsState {
  data: HackathonStateData
  loading: boolean
  error: false | Error | Response
  byHackathon: {
    [s in HackathonID]?: {
      loading: boolean
      error: false | Error | Response
    }
  }
}

const initialStateHackathons: HackathonsState = {
  data: {
    byId: {},
    completedIds: [],
    activeIds: [],
    hasAllHackathons: false,
  },
  loading: false,
  error: false,
  byHackathon: {},
}

const normalizeHackathonData = (data: any): HackathonStateData => {
  if (data.status === 'success') {
    const hackathonData = data.data
    if (hackathonData && hackathonData.length) {
      const completedIds: HackathonStateData['completedIds'] = []
      const activeIds: HackathonStateData['activeIds'] = []
      const hackathonDataById: HackathonStateData['byId'] = hackathonData.reduce(
        (acc: Record<HackathonID, HackathonData>, hackathon: HackathonData) => {
          acc[hackathon.id] = hackathon
          if (isDateBeforeToday(hackathon.ends_at)) {
            completedIds.push(hackathon.id)
          } else {
            activeIds.push(hackathon.id)
          }
          return acc
        },
        {} as Record<HackathonID, HackathonData>
      )
      return {
        byId: hackathonDataById,
        completedIds,
        activeIds,
        hasAllHackathons: true,
      }
    }
    return {
      ...initialStateHackathons.data,
      hasAllHackathons: true,
    }
  }
  return initialStateHackathons.data
}

const normalizedProblemFiles = (
  data: any
): null | Record<HackathonProblemFileCategory, HackathonProblemFile[]> => {
  if (data.length) {
    return data.reduce(
      (
        acc: Record<HackathonProblemFileCategory, HackathonProblemFile[]>,
        file: HackathonProblemFile
      ) => {
        if (acc[file.category]) acc[file.category].push(file)
        else acc[file.category] = [file]
        return acc
      },
      {} as Record<HackathonProblemFileCategory, HackathonProblemFile[]>
    )
  }
  return null
}

const normalizePerformanceData = (data: any): HackathonPerformanceData => {
  const res: HackathonPerformanceData = {
    team_performance: data.team_performance,
  }
  if (data.submissions.length) {
    res.submissions = data.submissions.map(
      (row: HackathonPerformanceSubmissionsData, index: number) => ({
        ...row,
        submissionCount: index + 1,
      })
    )
  }
  return res
}

const evaluationCriteria = (
  data: any
): {
  criteria_code: HackathonData['criteria_code']
  criteria_name: HackathonData['criteria_name']
} => {
  switch (data.criteria_code) {
    case 'MAE':
      return {
        criteria_code: 'MAE',
        criteria_name: 'Least MAE',
      }
    case 'RMSE':
      return {
        criteria_code: 'RMSE',
        criteria_name: 'Least RMSE',
      }
    case 'MPS':
      return {
        criteria_code: 'MPS',
        criteria_name: 'Highest MPS',
      }
    case 'F1_Score':
      return {
        criteria_code: 'F1_Score',
        criteria_name: 'Best F1_Score',
      }
    case 'AS':
    default:
      return {
        criteria_code: 'Accuracy',
        criteria_name: 'Highest Accuracy',
      }
  }
}

const hackathonsReducer: Reducer<HackathonsState, HackathonsActionTypes> = (
  state = initialStateHackathons,
  action
): HackathonsState => {
  switch (action.type) {
    case FETCH_HACKATHON_LIST:
      return {
        ...state,
        loading: true,
      }

    case FETCH_HACKATHON_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: normalizeHackathonData(action.payload),
      }
    }

    case FETCH_HACKATHON_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case FETCH_HACKATHON_LIST_CANCEL:
      return {
        ...state,
        loading: false,
      }

    case FETCH_HACKATHON_DETAILS:
      return {
        ...state,
        byHackathon: {
          ...state.byHackathon,
          [action.meta.hackathonId]: {
            loading: true,
            error: false,
          },
        },
      }
    case FETCH_HACKATHON_DETAILS_SUCCESS: {
      const res = normalizedProblemFiles(action.payload.hackathon_files)
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.hackathonId]: {
              ...action.payload.data,
              ...(res ? { problemFiles: res } : {}),
              ...(Object.keys(action.payload.team_details).length
                ? { teamDetails: action.payload.team_details }
                : {}),
              ...evaluationCriteria(action.payload.data),
            },
          },
        },
        byHackathon: {
          ...state.byHackathon,
          [action.meta.hackathonId]: {
            loading: false,
            error: false,
          },
        },
      }
    }
    case FETCH_HACKATHON_DETAILS_FAILURE:
      return {
        ...state,
        byHackathon: {
          ...state.byHackathon,
          [action.meta.hackathonId]: {
            loading: false,
            error: action.payload,
          },
        },
      }
    case FETCH_HACKATHON_DETAILS_CANCEL:
      return {
        ...state,
        byHackathon: {
          ...state.byHackathon,
          [action.meta.hackathonId]: {
            loading: false,
            error: false,
          },
        },
      }

    case REGISTER_HACKATHON_TEAM:
    case ADD_MEMBERS_HACKATHON_TEAM:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.hackathonId]: {
              ...(state.data.byId[action.meta.hackathonId] as HackathonData),
              itemActivity: {
                ...state.data.byId[action.meta.hackathonId]!.itemActivity,
                status: 'registering',
              },
            },
          },
        },
      }

    case REGISTER_HACKATHON_TEAM_SUCCESS:
    case ADD_MEMBERS_HACKATHON_TEAM_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.hackathonId]: {
              ...(state.data.byId[action.meta.hackathonId] as HackathonData),
              itemActivity: {
                ...state.data.byId[action.meta.hackathonId]!.itemActivity,
                status: 'registered',
              },
            },
          },
        },
      }

    case REGISTER_HACKATHON_TEAM_FAILURE:
    case ADD_MEMBERS_HACKATHON_TEAM_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.hackathonId]: {
              ...(state.data.byId[action.meta.hackathonId] as HackathonData),
              itemActivity: {
                ...state.data.byId[action.meta.hackathonId]!.itemActivity,
                status: 'error',
              },
            },
          },
        },
      }

    case SUBMIT_HACKATHON_SOLUTION:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.hackathonId]: {
              ...(state.data.byId[action.meta.hackathonId] as HackathonData),
              itemActivity: {
                ...state.data.byId[action.meta.hackathonId]!.itemActivity,
                status: 'submitting',
              },
            },
          },
        },
      }

    case SUBMIT_HACKATHON_SOLUTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.hackathonId]: {
              ...(state.data.byId[action.meta.hackathonId] as HackathonData),
              itemActivity: {
                ...state.data.byId[action.meta.hackathonId]!.itemActivity,
                status: 'submitted',
                submissionData: action.payload.data,
              },
            },
          },
        },
      }

    case SUBMIT_HACKATHON_SOLUTION_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.hackathonId]: {
              ...(state.data.byId[action.meta.hackathonId] as HackathonData),
              itemActivity: {
                ...state.data.byId[action.meta.hackathonId]!.itemActivity,
                status: 'error',
              },
            },
          },
        },
      }

    case FETCH_HACKATHON_LEADERBOARD_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.hackathonId]: {
              ...(state.data.byId[action.meta.hackathonId] as HackathonData),
              itemActivity: {
                ...state.data.byId[action.meta.hackathonId]!.itemActivity,
                leaderboard: action.payload.leader_board,
              },
            },
          },
        },
      }

    case FETCH_HACKATHON_LEADERBOARD_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.hackathonId]: {
              ...(state.data.byId[action.meta.hackathonId] as HackathonData),
              itemActivity: {
                ...state.data.byId[action.meta.hackathonId]!.itemActivity,
                leaderboard: action.payload,
              },
            },
          },
        },
      }
    case FETCH_HACKATHON_TEAM_PERFORMANCE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.hackathonId]: {
              ...(state.data.byId[action.meta.hackathonId] as HackathonData),
              itemActivity: {
                ...state.data.byId[action.meta.hackathonId]!.itemActivity,
                performance: normalizePerformanceData(action.payload),
              },
            },
          },
        },
      }

    case FETCH_HACKATHON_TEAM_PERFORMANCE_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.hackathonId]: {
              ...(state.data.byId[action.meta.hackathonId] as HackathonData),
              itemActivity: {
                ...state.data.byId[action.meta.hackathonId]!.itemActivity,
                performance: action.payload,
              },
            },
          },
        },
      }

    default:
      return state
  }
}
export default hackathonsReducer
