import { useState, useEffect, useRef } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from 'react-redux'
import useInterval from 'web/components/Utils/Hooks/useInterval'
import { sendCodingLabProctorEvent } from 'web/providers/CodingLabs/CodingLabs.action'

export const useIdleTimerDetection = (
  lab_type: string = 'CODING_LAB',
  timeout: number,
  promptTimeout: number,
  isPromptedHandler: (prompted: boolean) => void,
  proctored: boolean,
  is_coding_lab_basic_proctoring_enabled: boolean,
  lab_id: number,
  isLabFocused: boolean,
  isCellExecuting?: boolean
) => {
  const dispatch = useDispatch()
  const [timerOpen, setTimerOpen] = useState(false)
  const [remaining, setRemaining] = useState(0)
  const [userActivityState, setUserActivityState] = useState<'Active' | 'Idle'>(
    'Active'
  )
  const resetRef = useRef<() => void>(() => {})
  const onPrompt = async () => {
    const eventName = 'idle'
    const data = {} // replace with actual data if needed

    if (lab_type === 'CODING_LAB') {
      dispatch(
        sendCodingLabProctorEvent({
          codingLabAssignmentId: lab_id,
          event_name: eventName,
          data,
          is_focused: isLabFocused,
        })
      )

      if (proctored || is_coding_lab_basic_proctoring_enabled) {
        resetRef.current() // Resetting the timer
        isPromptedHandler(false)
        return
      }
    }

    setTimerOpen(true)
    setRemaining(promptTimeout / 1000)
  }
  const logCurrentTime = (activityState: string) => {
    const currentTime = new Date()
    const formattedTime = `${currentTime.getHours()}:${currentTime.getMinutes()}:${currentTime.getSeconds()}`
    // console.log(
    //   `🚀 ~ ${activityState} at ${formattedTime} timeout in seconds is ${timeout /
    //     1000} promptTimeout in seconds is ${promptTimeout / 1000}`
    // )
  }

  const handleOnIdle = () => {
    setUserActivityState('Idle')
    setRemaining(0)
    logCurrentTime('Idle')
  }

  const handleOnActive = () => {
    if (timerOpen) return
    setUserActivityState('Active')
    setRemaining(0)
    logCurrentTime('Active')
  }

  const handleOnAction = () => {
    if (timerOpen) return
    resetRef.current() // Resetting the timer
    logCurrentTime('Active')
  }

  const { getRemainingTime, isPrompted, reset } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    onPrompt,
    crossTab: true,
    promptBeforeIdle: promptTimeout,
  })

  resetRef.current = reset

  useInterval(() => {
    if (
      isPrompted() &&
      !(proctored || is_coding_lab_basic_proctoring_enabled)
    ) {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
      isPromptedHandler(true)
    }
  }, 1000)

  // Simplified to ensure `remaining` is always based on actual remaining time
  useEffect(() => {
    if (lab_type === 'CODING_LAB') {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }
  }, [lab_type, userActivityState, getRemainingTime])

  useEffect(() => {
    if (lab_type === 'JUPYTER_LAB' && isCellExecuting) {
      if (!isCellExecuting) return
      const remainingTime = Math.ceil(getRemainingTime() / 1000)
      resetRef.current()
      setRemaining(remainingTime)
    }
  }, [lab_type, isCellExecuting, getRemainingTime()])

  return {
    timerOpen,
    remaining,
    handleOnIdle,
    handleOnActive,
    handleOnAction,
    onPrompt,
    getRemainingTime,
    isPrompted,
    setTimerOpen,
    setRemaining,
    userActivityState,
    reset,
  }
}

export default useIdleTimerDetection
