import React from 'react'
import AgoraRTC, {
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  IAgoraRTCClient,
} from 'agora-rtc-sdk-ng'
import { AgoraRTCEventHandlers } from 'common/types/agora-rtc-ng-sdk-additional'
import { isMobileBrowser } from 'common/utils'
import { mixpanel } from 'common/utils/mixpanel'
import { getPeerIdentification, getZoomoutEventId } from 'common/utils/zoomout'
import {
  createPeer,
  removePeer,
  updateCallStage,
  updateDevice,
  updateNetworkQuality,
  updatePeer,
} from 'web/providers/Zoomout/Internal/Internal.actions'
import { CALL_STAGES } from 'web/providers/Zoomout/Internal/Internal.reducer'
import rootStore from 'web/store'
import { PARTICIPANT_ROLES } from 'common/types/zoomout'
import { showAlertMessage } from 'web/providers/AlertsProvider'
import { getMicrophones, getCameras, getSpeakers } from '../methods/getDevices'
import pushZoomoutEvent from '../pushZoomoutEvent'
import zoomoutSettings from '../settings'

const attachMainRTCListeners = (client: IAgoraRTCClient) => {
  client.on('connection-state-change', (curr, prev) => {
    let callStage = CALL_STAGES.STANDBY_BEFORE_CALL

    if (prev === 'DISCONNECTED' && curr === 'DISCONNECTED') {
      callStage = CALL_STAGES.STANDBY_BEFORE_CALL
    } else if (prev === 'DISCONNECTED' && curr === 'CONNECTING') {
      callStage = CALL_STAGES.CONNECTING_FIRST_TIME
    } else if (curr === 'CONNECTED') {
      if (prev === 'RECONNECTING') {
        pushZoomoutEvent({
          eventID: getZoomoutEventId('SESSION_JOIN'),
          extraInfo: { type: 'reconnected', isMobile: isMobileBrowser() },
          byUID: -1,
        })
      }
      callStage = CALL_STAGES.LIVE_IN_CALL
    } else if (curr === 'RECONNECTING') {
      callStage = CALL_STAGES.RECONNECTING_IN_CALL
    } else if (curr === 'DISCONNECTED' && prev === 'DISCONNECTING') {
      callStage = CALL_STAGES.CALL_ENDED
    }

    rootStore.dispatch(updateCallStage(callStage))
  })

  client.on('user-joined', peer => {
    rootStore.dispatch(createPeer({ uid: parseInt(peer.uid as string, 10) }))
  })

  client.on('user-left', (peer, reason) => {
    // Don't remove peer if they have merely switched to audience mode
    // https://agoraio-community.github.io/AgoraWebSDK-NG/api/en/interfaces/iagorartcclient.html#event_user_left

    // In live mode in v4.9.3 of the SDK the reason specified by the SDK is not always correct
    // check this with agora team

    // NOTE: screen sharing clients don't login into rtm
    if (zoomoutSettings.config.mode === 'live') {
      if (reason === 'BecomeAudience') return

      // TODO: following is a HACK, as the reason specified by the SDK when calling setClientRole("audience") is "Quit" instead of "BecomeAudience". Which is inconsistent from the documentation. once the issue is fixed in the SDK please remove the following if block
      // which prevents non-screen sharing tiles from being removed when setClientRole("audience") is called

      // if the peer is not a screen sharing client don't remove him
      if (!getPeerIdentification(parseInt(peer.uid as string, 10)).isScreen)
        return
    }

    rootStore.dispatch(
      removePeer({
        uid: parseInt(peer.uid as string, 10),
      })
    )
  })

  client.on('user-published', async (peer, mediaType) => {
    if (mediaType === 'audio') {
      try {
        const audioTrack = await client.subscribe(peer, 'audio')
        audioTrack.play()
        rootStore.dispatch(
          updatePeer({
            uid: parseInt(peer.uid as string, 10),
            audio: 'PUBLISHED_AND_SUBSCRIBED',
          })
        )
      } catch (err) {
        const error = err as any

        mixpanel.track('Zoomout Errors', {
          feature: 'User-Published',
          code: error.code,
          message: error.message,
        })
      }
    } else {
      rootStore.dispatch(
        updatePeer({
          uid: parseInt(peer.uid as string, 10),
          video: 'PUBLISHED_AND_NOT_SUBSCRIBED',
        })
      )
    }
  })

  client.on('user-unpublished', (peer, mediaType) => {
    if (mediaType === 'audio') {
      rootStore.dispatch(
        updatePeer({
          uid: parseInt(peer.uid as string, 10),
          audio: 'NOT_PUBLISHED',
        })
      )
    } else {
      rootStore.dispatch(
        updatePeer({
          uid: parseInt(peer.uid as string, 10),
          video: 'NOT_PUBLISHED',
        })
      )
    }
  })

  client.on('network-quality', stats => {
    rootStore.dispatch(
      updateNetworkQuality(
        stats.uplinkNetworkQuality,
        stats.downlinkNetworkQuality
      )
    )
    const { role = PARTICIPANT_ROLES.LEARNER } =
      rootStore.getState().zoomout.external.joiningDetails || {}

    if (role === PARTICIPANT_ROLES.LEARNER) {
      if (
        window.zoomout &&
        window.zoomout.mainRtcClient &&
        stats.downlinkNetworkQuality > 2
      ) {
        const networkStats = window.zoomout.mainRtcClient.getRTCStats()
        mixpanel.track('Zoomout Errors', {
          feature: 'Network Quality',
          code: 'NETWORK_QUALITY_LOW',
          message: JSON.stringify({ networkStats, quality: stats }),
        })
      }
    } else if (
      window.zoomout &&
      window.zoomout.mainRtcClient &&
      stats.uplinkNetworkQuality > 2
    ) {
      const networkStats = window.zoomout.mainRtcClient.getRTCStats()
      mixpanel.track('Zoomout Errors', {
        feature: 'Network Quality',
        code: 'NETWORK_QUALITY_LOW',
        message: JSON.stringify({ networkStats, quality: stats }),
      })
    }
  })

  // https://docs.agora.io/en/Video/API%20Reference/web_ng/interfaces/iagorartcclient.html#event_exception
  client.on('exception', event => {
    const networkStats =
      window.zoomout && window.zoomout.mainRtcClient
        ? window.zoomout.mainRtcClient.getRTCStats()
        : null
    mixpanel.track('Zoomout Errors', {
      feature: 'Agora SDK Exception Event',
      code: event.code,
      message: event.msg,
      uid: event.uid,
      networkStats: JSON.stringify({ networkStats }),
    })
  })
}

export const initializeMicGlobalEventHandlers = (
  microphone: IMicrophoneAudioTrack
) => {
  // the following callback is only triggered if your mic was on and you plugged in/out a mic device
  AgoraRTC.onMicrophoneChanged = async info => {
    const { default: selectedMic, all } = await getMicrophones()

    try {
      let finalDevice: MediaDeviceInfo | null = null

      if (info.state === 'ACTIVE') {
        if (microphone.enabled) {
          rootStore.dispatch(
            showAlertMessage({
              variant: 'warning',
              message: `Switching mic to ${info.device.label}`,
            })
          )
        }
        await microphone.setDevice(info.device.deviceId)
        finalDevice = info.device
      } else if (info.state === 'INACTIVE' && selectedMic) {
        await microphone.setDevice(selectedMic.deviceId)
        finalDevice = selectedMic
        rootStore.dispatch(
          showAlertMessage({
            variant: 'warning',
            message: `
                Mic ${info.device.label} was unplugged. Switching to ${selectedMic.label}
              `,
          })
        )
      }

      if (finalDevice) {
        rootStore.dispatch(updateDevice('microphone', finalDevice, all))
      } else {
        throw new Error("Couldn't find a mic device")
      }
    } catch (err) {
      const error = err as any
      mixpanel.track('Zoomout Errors', {
        feature: 'Automatic Microphone Change',
        code: error.code,
        message: error.message,
      })
    }
  }
}

export const initializeCameraGlobalEventHandlers = (
  camera: ICameraVideoTrack
) => {
  // the following callback is only triggered if your camera was on and you plugged in/out a mic device
  AgoraRTC.onCameraChanged = async info => {
    const { default: selectedCamera, all } = await getCameras()

    try {
      let finalDevice: MediaDeviceInfo | null = null
      if (info.state === 'ACTIVE') {
        if (camera.enabled) {
          rootStore.dispatch(
            showAlertMessage({
              variant: 'warning',
              message: `Switching camera to ${info.device.label}`,
            })
          )
        }
        await camera.setDevice(info.device.deviceId)
        finalDevice = info.device
      } else if (info.state === 'INACTIVE' && selectedCamera) {
        await camera.setDevice(selectedCamera.deviceId)
        rootStore.dispatch(
          showAlertMessage({
            variant: 'warning',
            message: `Camera ${info.device.label} was unplugged. Switching to ${selectedCamera.label}`,
          })
        )
        finalDevice = selectedCamera
      }

      if (finalDevice) {
        rootStore.dispatch(updateDevice('camera', finalDevice, all))
      } else {
        throw new Error("Couldn't find a camera device")
      }
    } catch (err) {
      const error = err as any
      mixpanel.track('Zoomout Errors', {
        feature: 'Automatic Camera Change',
        code: error.code,
        message: error.message,
      })
    }
  }
}

export const initializeSpeakerGlobalEventHandlers = () => {
  AgoraRTC.onPlaybackDeviceChanged = async info => {
    const { default: selectedSpeaker, all } = await getSpeakers()
    rootStore.dispatch(updateDevice('speaker', info.device, all))

    try {
      let finalDevice: MediaDeviceInfo | null = null
      if (info.state === 'ACTIVE') {
        rootStore.dispatch(updateDevice('speaker', info.device, all))
        finalDevice = info.device
      } else if (info.state === 'INACTIVE' && selectedSpeaker) {
        rootStore.dispatch(updateDevice('camera', selectedSpeaker, all))
        finalDevice = selectedSpeaker
      }

      if (finalDevice) {
        rootStore.dispatch(updateDevice('speaker', finalDevice, all))
      } else {
        throw new Error("Couldn't find a speaker device")
      }
    } catch (err) {
      const error = err as any

      mixpanel.track('Zoomout Errors', {
        feature: 'Automatic Speaker Change',
        code: error.code,
        message: error.message,
      })
    }
  }
}

export default attachMainRTCListeners
