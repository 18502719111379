import {
  FETCH_EPORTFOLIO,
  FETCH_EPORTFOLIO_SUCCESS,
  FETCH_EPORTFOLIO_FAILURE,
  TOGGLE_PROJECT_VISIBILITY,
  TOGGLE_PROJECT_VISIBILITY_SUCCESS,
  TOGGLE_PROJECT_VISIBILITY_FAILURE,
  UPDATE_PROJECT_LEARNINGS,
  UPDATE_PROJECT_LEARNINGS_SUCCESS,
  UPDATE_PROJECT_LEARNINGS_FAILURE,
  FETCH_USER_BY_EPORTFOLIO_USERNAME
} from './Eportfolio.types'

import { EportfolioData } from '../../../common/types/eportfolio'

export const fetchEportfolio = () => ({
  type: FETCH_EPORTFOLIO as typeof FETCH_EPORTFOLIO,
})

export const fetchEportfolioSuccess = (payload: EportfolioData) => ({
  type: FETCH_EPORTFOLIO_SUCCESS as typeof FETCH_EPORTFOLIO_SUCCESS,
  payload,
})

export const fetchEportfolioFailure = (payload: Error) => ({
  type: FETCH_EPORTFOLIO_FAILURE as typeof FETCH_EPORTFOLIO_FAILURE,
  payload,
})

export const toggleProjectVisibility = (
  projectId: string | number,
  visibility: boolean
) => ({
  type: TOGGLE_PROJECT_VISIBILITY as typeof TOGGLE_PROJECT_VISIBILITY,
  projectId,
  visibility,
})

export const toggleProjectVisibilitySuccess = (
  projectId: string | number,
) => ({
  type: TOGGLE_PROJECT_VISIBILITY_SUCCESS as typeof TOGGLE_PROJECT_VISIBILITY_SUCCESS,
  projectId,
})

export const toggleProjectVisibilityFailure = (payload: Error) => ({
  type: TOGGLE_PROJECT_VISIBILITY_FAILURE as typeof TOGGLE_PROJECT_VISIBILITY_FAILURE,
  payload,
})

export const updateProjectLearnings = (
  projectId: string | number,
  learnings: string
) => ({
  type: UPDATE_PROJECT_LEARNINGS as typeof UPDATE_PROJECT_LEARNINGS,
  projectId,
  learnings,
})

export const updateProjectLearningsSuccess = (projectId: string | number, learnings: string) => ({
  type: UPDATE_PROJECT_LEARNINGS_SUCCESS as typeof UPDATE_PROJECT_LEARNINGS_SUCCESS,
  projectId,
  learnings
})

export const updateProjectLearningsFailure = (payload: Error) => ({
  type: UPDATE_PROJECT_LEARNINGS_FAILURE as typeof UPDATE_PROJECT_LEARNINGS_FAILURE,
  payload,
})

export const fetchUserByEportfolioUserName = (username: string) => ({
  type: FETCH_USER_BY_EPORTFOLIO_USERNAME as typeof FETCH_USER_BY_EPORTFOLIO_USERNAME,
  username,
})


export type EportfolioActionTypes =
  | ReturnType<typeof fetchEportfolio>
  | ReturnType<typeof fetchEportfolioSuccess>
  | ReturnType<typeof fetchEportfolioFailure>
  | ReturnType<typeof toggleProjectVisibility>
  | ReturnType<typeof toggleProjectVisibilitySuccess>
  | ReturnType<typeof toggleProjectVisibilityFailure>
  | ReturnType<typeof fetchUserByEportfolioUserName>
  | ReturnType<typeof updateProjectLearnings>
  | ReturnType<typeof updateProjectLearningsSuccess> 
  | ReturnType<typeof updateProjectLearningsFailure>
