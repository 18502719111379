import {
  all,
  call,
  cancelled,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects'
import { apiCall, cancelable } from '../../../../../../common/utils'
import { AppState } from '../../../../../store'
import {
  batchRemoveFile,
  batchUploadFile,
  FilesState,
} from '../../../../FilesProvider'
import {
  BATCH_UPLOAD_FILE_FAILURE,
  BATCH_UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
} from '../../../../FilesProvider/Files.types'
import {
  getAssignmentSubmission,
  getAssignmentSubmissionFailure,
  getAssignmentSubmissionSuccess,
  getSubmittedUserDetails,
  getSubmittedUserDetailsFailure,
  getSubmittedUserDetailsSuccess,
  submitAssignment,
  submitAssignmentFailure,
  submitAssignmentSuccess,
  submitAssignmentCancel,
  getExternalToolUrl,
  getExternalToolUrlSuccess,
  getExternalToolUrlFailure,
  getAssignmentMetaData,
  getAssignmentMetaDataSuccess,
  getAssignmentMetaDataFailure,
  getAssignmentAiMentorUserData,
  getAssignmentAiMentorUserDataSuccess,
  getAssignmentAiMentorUserDataFailure,
} from './AssignmentSubmissions.actions'
import {
  GET_ASSIGNMENT_SUBMISSION,
  GET_ASSIGNMENT_SUBMISSION_CANCEL,
  GET_SUBMITTED_USER_DETAILS,
  GET_SUBMITTED_USER_DETAILS_CANCEL,
  SUBMIT_ASSIGNMENT,
  SUBMIT_ASSIGNMENT_CANCEL,
  GET_EXTERNAL_TOOL_URL,
  GET_EXTERNAL_TOOL_URL_CANCEL,
  GET_ASSIGNMENT_META_DATA,
  GET_ASSIGNMENT_META_DATA_CANCEL,
  GET_ASSIGNMENT_AI_MENTOR_USER_DATA,
  GET_ASSIGNMENT_AI_MENTOR_USER_DATA_CANCEL,
} from './AssignmentSubmissions.types'
import { FileID } from '../../../../../../common/types'
import { UserID } from '../../../../../../common/types/user'
import { showAlertMessage } from '../../../../AlertsProvider'
import {
  submitAssignmentAPI,
  getExternalToolAPI,
  getAssignmentMetaDataAPI,
  getAssignmentAiMentorDataAPI,
} from './AssignmentSubmissions.api'

function* submitAssignmentHandler(action: ReturnType<typeof submitAssignment>) {
  const abortController = new AbortController()
  try {
    let data = null
    const fileUploadIds: number[] = []
    if (action.payload.filesList.length) {
      const filesState: FilesState = yield select(state => state.files)

      const fileContent = filesState.byContent[action.payload.dataKey]
      const fileIdArray: FileID[] | undefined = fileContent
        ? fileContent.data
        : undefined

      if (fileIdArray)
        fileIdArray.forEach((fileId: FileID) => {
          const file = filesState.data.byId[fileId]
          if (file && file.upload_id) fileUploadIds.push(file.upload_id)
        })
    }
    if (fileUploadIds.length || action.payload.submissionType !== 'none') {
      data = yield call(
        submitAssignmentAPI,
        action,
        fileUploadIds,
        abortController.signal
      )
    }
    yield put(submitAssignmentSuccess(data, action.meta))
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'Assignment Submitted Successfully',
      })
    )
    yield put(getAssignmentSubmission(action.payload, action.meta))
    yield put(batchRemoveFile(action.payload))
  } catch (e) {
    yield put(submitAssignmentFailure(e, action.meta))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Assignment Submission Failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitFilesForAssignmentHandler(
  action: ReturnType<typeof submitAssignment>
) {
  const abortController = new AbortController()
  try {
    if (action.payload.filesList.length) {
      yield put(batchUploadFile(action.payload))
      yield take(BATCH_UPLOAD_FILE_SUCCESS)
    }
    yield submitAssignmentHandler(action)
  } catch (e) {
    yield put(submitAssignmentFailure(e, action.meta))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Assignment Submission Failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
      yield put(submitAssignmentCancel({}, action.meta))
    }
  }
}

export async function getAssignmentSubmissionAPI(
  action: ReturnType<typeof getAssignmentSubmission>,
  userId: UserID,
  signal: AbortSignal
) {
  const { courseId, contentId } = action.payload
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/assignments/${contentId}/submissions/${userId}`,
    params: {
      signal,
    },
    query: {
      include: ['submission_comments', 'rubric_assessment'],
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

function* getAssignmentSubmissionHandler(
  action: ReturnType<typeof getAssignmentSubmission>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data = yield call(
      getAssignmentSubmissionAPI,
      action,
      userId,
      abortController.signal
    )
    yield put(getAssignmentSubmissionSuccess(data, action.meta))
    if (data.user_id) {
      yield put(getSubmittedUserDetails({ userId: data.user_id }, action.meta))
    }
  } catch (e) {
    yield put(getAssignmentSubmissionFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export async function getSubmittedUserDetailsAPI(
  action: ReturnType<typeof getSubmittedUserDetails>,
  signal: AbortSignal
) {
  const { userId } = action.payload
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/users/${userId}`,
    params: {
      signal,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

function* getSubmittedUserDetailsHandler(
  action: ReturnType<typeof getSubmittedUserDetails>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      getSubmittedUserDetailsAPI,
      action,
      abortController.signal
    )
    yield put(getSubmittedUserDetailsSuccess(data, action.meta))
  } catch (e) {
    yield put(getSubmittedUserDetailsFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* getExternalToolUrlHandler(
  action: ReturnType<typeof getExternalToolUrl>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(getExternalToolAPI, action, abortController.signal)
    yield put(getExternalToolUrlSuccess(data, action.meta))
  } catch (e) {
    yield put(getExternalToolUrlFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* getAssignmentMetaDataHandler(
  action: ReturnType<typeof getAssignmentMetaData>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      getAssignmentMetaDataAPI,
      action,
      abortController.signal
    )
    yield put(getAssignmentMetaDataSuccess(data, action.meta))
  } catch (error) {
    yield put(getAssignmentMetaDataFailure(error, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* getAssignmentAiMentorDataHandler(
  action: ReturnType<typeof getAssignmentAiMentorUserData>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      getAssignmentAiMentorDataAPI,
      action,
      abortController.signal
    )
    yield put(getAssignmentAiMentorUserDataSuccess(data, action.meta))
  } catch (error) {
    yield put(getAssignmentAiMentorUserDataFailure(error, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* submitAssignmentMiddleware() {
  yield takeEvery(
    SUBMIT_ASSIGNMENT,
    cancelable(submitFilesForAssignmentHandler, [
      BATCH_UPLOAD_FILE_FAILURE,
      UPLOAD_FILE_FAILURE,
      SUBMIT_ASSIGNMENT_CANCEL,
    ])
  )
}

export function* getAssignmentSubmissionMiddleware() {
  yield takeEvery(
    GET_ASSIGNMENT_SUBMISSION,
    cancelable(getAssignmentSubmissionHandler, [
      GET_ASSIGNMENT_SUBMISSION_CANCEL,
    ])
  )
}

export function* getSubmittedUserDetailsMiddleware() {
  yield takeEvery(
    GET_SUBMITTED_USER_DETAILS,
    cancelable(getSubmittedUserDetailsHandler, [
      GET_SUBMITTED_USER_DETAILS_CANCEL,
    ])
  )
}

export function* getExternalToolUrlMiddleware() {
  yield takeEvery(
    GET_EXTERNAL_TOOL_URL,
    cancelable(getExternalToolUrlHandler, GET_EXTERNAL_TOOL_URL_CANCEL)
  )
}

export function* getAssignmentMetaDataMiddleware() {
  yield takeEvery(
    GET_ASSIGNMENT_META_DATA,
    cancelable(getAssignmentMetaDataHandler, GET_ASSIGNMENT_META_DATA_CANCEL)
  )
}

export function* getAssignmentAiMentorUserDataMiddleware() {
  yield takeEvery(
    GET_ASSIGNMENT_AI_MENTOR_USER_DATA,
    cancelable(getAssignmentAiMentorDataHandler, [
      GET_ASSIGNMENT_AI_MENTOR_USER_DATA_CANCEL,
    ])
  )
}

export default ([] as any).concat(
  submitAssignmentMiddleware(),
  getAssignmentSubmissionMiddleware(),
  getSubmittedUserDetailsMiddleware(),
  getExternalToolUrlMiddleware(),
  getAssignmentMetaDataMiddleware(),
  getAssignmentAiMentorUserDataMiddleware()
)
