import { NetworkQuality } from 'agora-rtc-sdk-ng'
import {
  PlainTextMessage,
  RaiseHandMessage,
} from 'common/utils/custom/Zoomout/rtm/messages'
import {
  CALL_STAGES,
  remoteTrackStates,
  ZoomoutInternalState,
} from './Internal.reducer'
import {
  INIT_INTERNAL_STATE,
  UPDATE_MICROPHONE_AND_SPEAKER,
  UPDATE_CAMERA,
  UPDATE_SCREEN_SHARING,
  SET_CAMERA,
  SET_MICROPHONE,
  UPDATE_DEVICE,
  UPDATE_USER_INTERFACE,
  UPDATE_CALL_STAGE,
  CREATE_PEER,
  UPDATE_PEER,
  REMOVE_PEER,
  ADD_PLAIN_TEXT_MESSAGE,
  UPDATE_NETWORK_QUALITY,
  UPDATE_LEAVE_MEETING_REASON,
  UPDATE_PLAIN_TEXT_MESSAGES,
  UPDATE_RAISE_HAND_MESSAGES,
} from './Internal.types'

export const initInternalState = (payload: {
  localDevices: ZoomoutInternalState['localDevices']
  supportsDualStream: ZoomoutInternalState['supportsDualStream']
}) => ({
  type: INIT_INTERNAL_STATE as typeof INIT_INTERNAL_STATE,
  payload,
})

export const updateMicrophoneAndSpeaker = (payload: {
  track: ZoomoutInternalState['localTracksDetails']['microphone']
  devices?: {
    currentMic: ZoomoutInternalState['localDevices']['current']['microphone']
    allMics: ZoomoutInternalState['localDevices']['all']['microphone']
    currentSpeaker: ZoomoutInternalState['localDevices']['current']['speaker']
    allSpeakers: ZoomoutInternalState['localDevices']['all']['speaker']
  }
}) => ({
  type: UPDATE_MICROPHONE_AND_SPEAKER as typeof UPDATE_MICROPHONE_AND_SPEAKER,
  payload,
})

export const updateCamera = (payload: {
  track: ZoomoutInternalState['localTracksDetails']['camera']
  devices?: {
    current: ZoomoutInternalState['localDevices']['current']['camera']
    all: ZoomoutInternalState['localDevices']['all']['camera']
  }
}) => ({
  type: UPDATE_CAMERA as typeof UPDATE_CAMERA,
  payload,
})

export const updateScreenSharing = (payload: {
  videoTrack?: ZoomoutInternalState['localTracksDetails']['screenVideo']
  audioTrack?: ZoomoutInternalState['localTracksDetails']['screenAudio']
}) => ({
  type: UPDATE_SCREEN_SHARING as typeof UPDATE_SCREEN_SHARING,
  payload,
})

export const setCameraStateTo = (payload: boolean) => ({
  type: SET_CAMERA as typeof SET_CAMERA,
  payload,
})

export const setMicStateTo = (payload: boolean) => ({
  type: SET_MICROPHONE as typeof SET_MICROPHONE,
  payload,
})

export const updateDevice = (
  deviceType: keyof ZoomoutInternalState['localDevices']['current'],
  selectedDevice: MediaDeviceInfo | null,
  allDevices?: MediaDeviceInfo[]
) => ({
  type: UPDATE_DEVICE as typeof UPDATE_DEVICE,
  payload: {
    deviceType,
    selectedDevice,
    allDevices,
  },
})

export const updateUserInterface = (
  payload: Partial<ZoomoutInternalState['userInterface']>
) => ({
  type: UPDATE_USER_INTERFACE as typeof UPDATE_USER_INTERFACE,
  payload,
})

export const updateCallStage = (payload: CALL_STAGES) => ({
  type: UPDATE_CALL_STAGE as typeof UPDATE_CALL_STAGE,
  payload,
})

export const createPeer = (payload: { uid: number; showAlert?: boolean }) => ({
  type: CREATE_PEER as typeof CREATE_PEER,
  payload,
})

export const updatePeer = (payload: {
  uid: number
  audio?: remoteTrackStates
  video?: remoteTrackStates
}) => ({
  type: UPDATE_PEER as typeof UPDATE_PEER,
  payload,
})

export const removePeer = (payload: { uid: number; showAlert?: boolean }) => ({
  type: REMOVE_PEER as typeof REMOVE_PEER,
  payload,
})

export const addPlainTextMessage = (
  message: PlainTextMessage,
  isOwnMessage = false
) => ({
  type: ADD_PLAIN_TEXT_MESSAGE as typeof ADD_PLAIN_TEXT_MESSAGE,
  payload: {
    message,
    isOwnMessage,
  },
})

export const updateNetworkQuality = (
  uplink: NetworkQuality['uplinkNetworkQuality'],
  downlink: NetworkQuality['downlinkNetworkQuality']
) => ({
  type: UPDATE_NETWORK_QUALITY as typeof UPDATE_NETWORK_QUALITY,
  payload: {
    uplink,
    downlink,
  },
})

export const updateLeaveMeetingReason = (reason: string) => ({
  type: UPDATE_LEAVE_MEETING_REASON as typeof UPDATE_LEAVE_MEETING_REASON,
  payload: reason,
})

export const updatePlainTextMessages = (payload: {
  messages: PlainTextMessage[]
}) => ({
  type: UPDATE_PLAIN_TEXT_MESSAGES as typeof UPDATE_PLAIN_TEXT_MESSAGES,
  payload,
})

export const updateRaiseHandMessages = (payload: RaiseHandMessage[]) => ({
  type: UPDATE_RAISE_HAND_MESSAGES as typeof UPDATE_RAISE_HAND_MESSAGES,
  payload,
})

export type ZoomoutInternalActions =
  | ReturnType<typeof initInternalState>
  | ReturnType<typeof updateMicrophoneAndSpeaker>
  | ReturnType<typeof updateCamera>
  | ReturnType<typeof updateScreenSharing>
  | ReturnType<typeof setCameraStateTo>
  | ReturnType<typeof setMicStateTo>
  | ReturnType<typeof updateCallStage>
  | ReturnType<typeof updateDevice>
  | ReturnType<typeof updateUserInterface>
  | ReturnType<typeof createPeer>
  | ReturnType<typeof updatePeer>
  | ReturnType<typeof removePeer>
  | ReturnType<typeof addPlainTextMessage>
  | ReturnType<typeof updateNetworkQuality>
  | ReturnType<typeof updateLeaveMeetingReason>
  | ReturnType<typeof updatePlainTextMessages>
  | ReturnType<typeof updateRaiseHandMessages>
