import {
  SHOW_FEEDBACK_POPUP,
  REMOVE_FEEDBACK_POPUP,
  FETCH_FEEDBACK_CONTENT,
  FETCH_FEEDBACK_CONTENT_SUCCESS,
  FETCH_FEEDBACK_CONTENT_CANCEL,
  FETCH_FEEDBACK_CONTENT_FAILURE,
  SUBMIT_COURSE_FEEDBACK,
  SUBMIT_COURSE_FEEDBACK_SUCCESS,
  SUBMIT_COURSE_FEEDBACK_FAILURE,
  SUBMIT_COURSE_FEEDBACK_CANCEL,
  CLEAR_FEEDBACK_SUBMITTED_STATUS,
  SUBMIT_PROGRAM_FEEDBACK,
  SUBMIT_PROGRAM_FEEDBACK_SUCCESS,
  SUBMIT_PROGRAM_FEEDBACK_FAILURE,
  SUBMIT_PROGRAM_FEEDBACK_CANCEL,
  SUBMIT_CAPSTONE_LEGACY_FEEDBACK,
  SUBMIT_CAPSTONE_LEGACY_FEEDBACK_SUCCESS,
  SUBMIT_CAPSTONE_LEGACY_FEEDBACK_FAILURE,
  SUBMIT_CAPSTONE_LEGACY_FEEDBACK_CANCEL,
  SUBMIT_CAPSTONE_SURVEY_FEEDBACK,
  SUBMIT_CAPSTONE_SURVEY_FEEDBACK_SUCCESS,
  SUBMIT_CAPSTONE_SURVEY_FEEDBACK_FAILURE,
  SUBMIT_CAPSTONE_SURVEY_FEEDBACK_CANCEL,
  MARK_MISSED_RESIDENCY_SESSION,
  MARK_MISSED_RESIDENCY_SESSION_SUCCESS,
  MARK_MISSED_RESIDENCY_SESSION_FAILURE,
  MARK_MISSED_RESIDENCY_SESSION_CANCEL,
  UPDATE_FEEDBACK_DATA,
  FEEDBACK_POPUP_STATUS,
  SKIP_SESSION_FEEDBACK,
  SKIP_SESSION_FEEDBACK_CANCEL,
  SKIP_SESSION_FEEDBACK_FAILURE,
  SKIP_SESSION_FEEDBACK_SUCCESS,
} from './Feedback.types'
import {
  ProgramFeedbackAnswers,
  CapstoneFeedbackAnswers,
} from '../../components/Feedback'

interface FeedbackPayload {
  feedback_pending: boolean
  feedback_type?: string
  feedback_url?: string
  disable_skip?: boolean
}

interface FeedbackContentPayload {
  url: string
}

interface SubmitCourseFeedbackPayload {
  starValue: number
  optionsSelected: { string: string } | {}
  comments: { [s: string]: string | null }
}

interface CapstoneLegacyFeedbackPayload {
  optionsSelected: { [n in number]: string | number }
  comments: string
  feedbackType: string
}

export const updateFeedbackData = (payload: FeedbackPayload) => ({
  type: UPDATE_FEEDBACK_DATA as typeof UPDATE_FEEDBACK_DATA,
  payload,
})

export const feedbackPopupStatus = () => ({
  type: FEEDBACK_POPUP_STATUS as typeof FEEDBACK_POPUP_STATUS,
})
export const showFeedbackPopup = () => ({
  type: SHOW_FEEDBACK_POPUP as typeof SHOW_FEEDBACK_POPUP,
})

export const removeFeedbackPopup = () => ({
  type: REMOVE_FEEDBACK_POPUP as typeof REMOVE_FEEDBACK_POPUP,
})

export const skipSessionFeedback = (mentoredGroupSessionId: number) => ({
  type: SKIP_SESSION_FEEDBACK as typeof SKIP_SESSION_FEEDBACK,
  mentoredGroupSessionId,
})

export const skipSessionFeedbackSuccess = () => ({
  type: SKIP_SESSION_FEEDBACK_SUCCESS as typeof SKIP_SESSION_FEEDBACK_SUCCESS,
})

export const skipSessionFeedbackFailure = (payload: Error) => ({
  type: SKIP_SESSION_FEEDBACK_FAILURE as typeof SKIP_SESSION_FEEDBACK_FAILURE,
  payload,
})

export const skipSessionFeedbackCancel = () => ({
  type: SKIP_SESSION_FEEDBACK_CANCEL as typeof SKIP_SESSION_FEEDBACK_CANCEL,
})

export const fetchFeedbackContent = (payload: FeedbackContentPayload) => ({
  type: FETCH_FEEDBACK_CONTENT as typeof FETCH_FEEDBACK_CONTENT,
  payload,
})
export const fetchFeedbackContentSuccess = (payload: any) => ({
  type: FETCH_FEEDBACK_CONTENT_SUCCESS as typeof FETCH_FEEDBACK_CONTENT_SUCCESS,
  payload,
})
export const fetchFeedbackContentFailure = (payload: Error) => ({
  type: FETCH_FEEDBACK_CONTENT_FAILURE as typeof FETCH_FEEDBACK_CONTENT_FAILURE,
  payload,
})
export const fetchFeedbackContentCancel = (payload: any) => ({
  type: FETCH_FEEDBACK_CONTENT_CANCEL as typeof FETCH_FEEDBACK_CONTENT_CANCEL,
  payload,
})

export const submitCourseFeedback = (payload: SubmitCourseFeedbackPayload) => ({
  type: SUBMIT_COURSE_FEEDBACK as typeof SUBMIT_COURSE_FEEDBACK,
  payload,
})
export const submitCourseFeedbackSuccess = (payload: any) => ({
  type: SUBMIT_COURSE_FEEDBACK_SUCCESS as typeof SUBMIT_COURSE_FEEDBACK_SUCCESS,
  payload,
})
export const submitCourseFeedbackFailure = (payload: Error) => ({
  type: SUBMIT_COURSE_FEEDBACK_FAILURE as typeof SUBMIT_COURSE_FEEDBACK_FAILURE,
  payload,
})
export const submitCourseFeedbackCancel = (payload: any) => ({
  type: SUBMIT_COURSE_FEEDBACK_CANCEL as typeof SUBMIT_COURSE_FEEDBACK_CANCEL,
  payload,
})

export const markMissedResidencySession = () => ({
  type: MARK_MISSED_RESIDENCY_SESSION as typeof MARK_MISSED_RESIDENCY_SESSION,
})
export const markMissedResidencySessionSuccess = (payload: any) => ({
  type: MARK_MISSED_RESIDENCY_SESSION_SUCCESS as typeof MARK_MISSED_RESIDENCY_SESSION_SUCCESS,
  payload,
})
export const markMissedResidencySessionFailure = (payload: Error) => ({
  type: MARK_MISSED_RESIDENCY_SESSION_FAILURE as typeof MARK_MISSED_RESIDENCY_SESSION_FAILURE,
  payload,
})
export const markMissedResidencySessionCancel = (payload: any) => ({
  type: MARK_MISSED_RESIDENCY_SESSION_CANCEL as typeof MARK_MISSED_RESIDENCY_SESSION_CANCEL,
  payload,
})

export const submitProgramFeedback = (payload: ProgramFeedbackAnswers) => ({
  type: SUBMIT_PROGRAM_FEEDBACK as typeof SUBMIT_PROGRAM_FEEDBACK,
  payload,
})
export const submitProgramFeedbackSuccess = (payload: any) => ({
  type: SUBMIT_PROGRAM_FEEDBACK_SUCCESS as typeof SUBMIT_PROGRAM_FEEDBACK_SUCCESS,
  payload,
})
export const submitProgramFeedbackFailure = (payload: Error) => ({
  type: SUBMIT_PROGRAM_FEEDBACK_FAILURE as typeof SUBMIT_PROGRAM_FEEDBACK_FAILURE,
  payload,
})
export const submitProgramFeedbackCancel = (payload: any) => ({
  type: SUBMIT_PROGRAM_FEEDBACK_CANCEL as typeof SUBMIT_PROGRAM_FEEDBACK_CANCEL,
  payload,
})

export const submitCapstoneLegacyFeedback = (
  payload: CapstoneLegacyFeedbackPayload
) => ({
  type: SUBMIT_CAPSTONE_LEGACY_FEEDBACK as typeof SUBMIT_CAPSTONE_LEGACY_FEEDBACK,
  payload,
})
export const submitCapstoneLegacyFeedbackSuccess = (payload: any) => ({
  type: SUBMIT_CAPSTONE_LEGACY_FEEDBACK_SUCCESS as typeof SUBMIT_CAPSTONE_LEGACY_FEEDBACK_SUCCESS,
  payload,
})
export const submitCapstoneLegacyFeedbackFailure = (payload: Error) => ({
  type: SUBMIT_CAPSTONE_LEGACY_FEEDBACK_FAILURE as typeof SUBMIT_CAPSTONE_LEGACY_FEEDBACK_FAILURE,
  payload,
})
export const submitCapstoneLegacyFeedbackCancel = (payload: any) => ({
  type: SUBMIT_CAPSTONE_LEGACY_FEEDBACK_CANCEL as typeof SUBMIT_CAPSTONE_LEGACY_FEEDBACK_CANCEL,
  payload,
})

export const submitCapstoneSurveyFeedback = (
  payload: CapstoneFeedbackAnswers
) => ({
  type: SUBMIT_CAPSTONE_SURVEY_FEEDBACK as typeof SUBMIT_CAPSTONE_SURVEY_FEEDBACK,
  payload,
})
export const submitCapstoneSurveyFeedbackSuccess = (payload: any) => ({
  type: SUBMIT_CAPSTONE_SURVEY_FEEDBACK_SUCCESS as typeof SUBMIT_CAPSTONE_SURVEY_FEEDBACK_SUCCESS,
  payload,
})
export const submitCapstoneSurveyFeedbackFailure = (payload: Error) => ({
  type: SUBMIT_CAPSTONE_SURVEY_FEEDBACK_FAILURE as typeof SUBMIT_CAPSTONE_SURVEY_FEEDBACK_FAILURE,
  payload,
})
export const submitCapstoneSurveyFeedbackCancel = (payload: any) => ({
  type: SUBMIT_CAPSTONE_SURVEY_FEEDBACK_CANCEL as typeof SUBMIT_CAPSTONE_SURVEY_FEEDBACK_CANCEL,
  payload,
})

export const clearFeedbackSubmittedStatus = () => ({
  type: CLEAR_FEEDBACK_SUBMITTED_STATUS as typeof CLEAR_FEEDBACK_SUBMITTED_STATUS,
})

export type FeedbackActionTypes =
  | ReturnType<typeof updateFeedbackData>
  | ReturnType<typeof feedbackPopupStatus>
  | ReturnType<typeof showFeedbackPopup>
  | ReturnType<typeof removeFeedbackPopup>
  | ReturnType<typeof fetchFeedbackContent>
  | ReturnType<typeof fetchFeedbackContentSuccess>
  | ReturnType<typeof fetchFeedbackContentFailure>
  | ReturnType<typeof fetchFeedbackContentCancel>
  | ReturnType<typeof submitCourseFeedback>
  | ReturnType<typeof submitCourseFeedbackSuccess>
  | ReturnType<typeof submitCourseFeedbackFailure>
  | ReturnType<typeof submitCourseFeedbackCancel>
  | ReturnType<typeof markMissedResidencySession>
  | ReturnType<typeof markMissedResidencySessionSuccess>
  | ReturnType<typeof markMissedResidencySessionFailure>
  | ReturnType<typeof markMissedResidencySessionCancel>
  | ReturnType<typeof submitProgramFeedback>
  | ReturnType<typeof submitProgramFeedbackSuccess>
  | ReturnType<typeof submitProgramFeedbackFailure>
  | ReturnType<typeof submitProgramFeedbackCancel>
  | ReturnType<typeof submitCapstoneLegacyFeedback>
  | ReturnType<typeof submitCapstoneLegacyFeedbackSuccess>
  | ReturnType<typeof submitCapstoneLegacyFeedbackFailure>
  | ReturnType<typeof submitCapstoneLegacyFeedbackCancel>
  | ReturnType<typeof submitCapstoneSurveyFeedback>
  | ReturnType<typeof submitCapstoneSurveyFeedbackSuccess>
  | ReturnType<typeof submitCapstoneSurveyFeedbackFailure>
  | ReturnType<typeof submitCapstoneSurveyFeedbackCancel>
  | ReturnType<typeof clearFeedbackSubmittedStatus>
  | ReturnType<typeof skipSessionFeedback>
  | ReturnType<typeof skipSessionFeedbackSuccess>
  | ReturnType<typeof skipSessionFeedbackFailure>
  | ReturnType<typeof skipSessionFeedbackCancel>
