export const QUIZ_SUBMISSION_START = 'QUIZ_SUBMISSION_START'
export const QUIZ_SUBMISSION_START_SUCCESS = 'QUIZ_SUBMISSION_START_SUCCESS'
export const QUIZ_SUBMISSION_START_FAILURE = 'QUIZ_SUBMISSION_START_FAILURE'

export const QUIZ_SUBMISSION_RESUME = 'QUIZ_SUBMISSION_RESUME'
export const QUIZ_SUBMISSION_RESUME_SUCCESS = 'QUIZ_SUBMISSION_RESUME_SUCCESS'
export const QUIZ_SUBMISSION_RESUME_FAILURE = 'QUIZ_SUBMISSION_RESUME_FAILURE'

export const QUIZ_SUBMISSION_RESET = 'QUIZ_SUBMISSION_RESET'

export const QUIZ_SUBMISSION_COMPLETE = 'QUIZ_SUBMISSION_COMPLETE'
export const QUIZ_SUBMISSION_COMPLETE_SUCCESS =
  'QUIZ_SUBMISSION_COMPLETE_SUCCESS'
export const QUIZ_SUBMISSION_COMPLETE_FAILURE =
  'QUIZ_SUBMISSION_COMPLETE_FAILURE'

export const QUIZ_SUBMISSIONS_FETCH = 'QUIZ_SUBMISSIONS_FETCH'
export const QUIZ_SUBMISSIONS_FETCH_SUCCESS = 'QUIZ_SUBMISSIONS_FETCH_SUCCESS'
export const QUIZ_SUBMISSIONS_FETCH_FAILURE = 'QUIZ_SUBMISSIONS_FETCH_FAILURE'
export const QUIZ_SUBMISSIONS_FETCH_CANCEL = 'QUIZ_SUBMISSIONS_FETCH_CANCEL'

export const QUIZ_SUBMISSION_CLEAR_FILES = 'QUIZ_SUBMISSION_CLEAR_FILES'

export const GET_QUIZ_SUBMISSION_COMMENTS = 'GET_QUIZ_SUBMISSION_COMMENTS'
export const GET_QUIZ_SUBMISSION_COMMENTS_SUCCESS =
  'GET_QUIZ_SUBMISSION_COMMENTS_SUCCESS'
export const GET_QUIZ_SUBMISSION_COMMENTS_FAILURE =
  'GET_QUIZ_SUBMISSION_COMMENTS_FAILURE'
export const GET_QUIZ_SUBMISSION_COMMENTS_CANCEL =
  'GET_QUIZ_SUBMISSION_COMMENTS_CANCEL'

export const TRACK_QUIZ_SUBMISSION_EVENT = 'TRACK_QUIZ_SUBMISSION_EVENT'
export const TRACK_QUIZ_SUBMISSION_EVENT_FAILURE =
  'TRACK_QUIZ_SUBMISSION_EVENT_FAILURE'
