export const CREATE_NOTE = 'CREATE_NOTE'
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS'
export const CREATE_NOTE_FAILURE = 'CREATE_NOTE_FAILURE'
export const CREATE_NOTE_CANCEL = 'CREATE_NOTE_CANCEL'

export const FETCH_NOTE = 'FETCH_NOTE'
export const FETCH_NOTE_SUCCESS = 'FETCH_NOTE_SUCCESS'
export const FETCH_NOTE_FAILURE = 'FETCH_NOTE_FAILURE'
export const FETCH_NOTE_CANCEL = 'FETCH_NOTE_CANCEL'

export const UPDATE_NOTE = 'UPDATE_NOTE'
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS'
export const UPDATE_NOTE_FAILURE = 'UPDATE_NOTE_FAILURE'
export const UPDATE_NOTE_CANCEL = 'UPDATE_NOTE_CANCEL'

export const DELETE_NOTE = 'DELETE_NOTE'
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS'
export const DELETE_NOTE_FAILURE = 'DELETE_NOTE_FAILURE'
export const DELETE_NOTE_CANCEL = 'DELETE_NOTE_CANCEL'
