import { apiCall, getUserDetails } from '../../../../../common/utils'

async function fetchVideoDetailsAPI({
  url,
  courseId,
  is_iphone,
}: {
  url: string
  courseId?: number | string
  is_iphone?:boolean | string
}) {
  const user_id = getUserDetails().id
  const response = await apiCall({
    url,
    query: { user_id, course_id: courseId, is_iphone: is_iphone },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export default { fetchVideoDetailsAPI }
