import { CourseID } from 'common/types/courses'
import { ExcelerateCareerPrepApiData } from 'common/types/excelerate/careerPrep'
import {
  FETCH_EXCELERATE_CAREER_PREP_DETAILS,
  FETCH_EXCELERATE_CAREER_PREP_DETAILS_CANCEL,
  FETCH_EXCELERATE_CAREER_PREP_DETAILS_FAILURE,
  FETCH_EXCELERATE_CAREER_PREP_DETAILS_SUCCESS,
  UPDATE_EXCELERATE_CAREER_PREP_ACTIVE_STATUS,
  SUBMIT_EXCELERATE_FEEDBACK,
  SUBMIT_EXCELERATE_FEEDBACK_SUCCESS,
  SUBMIT_EXCELERATE_FEEDBACK_FAILURE,
  SUBMIT_EXCELERATE_FEEDBACK_CANCEL,
} from './CareerPrep.types'

interface ExcelerateCareerPrepParams {
  courseId?: CourseID
  includeLatestFeaturesInfo?: boolean
}

export const fetchExcelerateCareerPrepDetails = (
  payload: ExcelerateCareerPrepParams | null = null
) => ({
  type: FETCH_EXCELERATE_CAREER_PREP_DETAILS as typeof FETCH_EXCELERATE_CAREER_PREP_DETAILS,
  payload,
})

export const fetchExcelerateCareerPrepDetailsSuccess = (
  payload: ExcelerateCareerPrepApiData
) => ({
  type: FETCH_EXCELERATE_CAREER_PREP_DETAILS_SUCCESS as typeof FETCH_EXCELERATE_CAREER_PREP_DETAILS_SUCCESS,
  payload,
})

export const fetchExcelerateCareerPrepDetailsFailure = (payload: Error) => ({
  type: FETCH_EXCELERATE_CAREER_PREP_DETAILS_FAILURE as typeof FETCH_EXCELERATE_CAREER_PREP_DETAILS_FAILURE,
  payload,
})

export const fetchExcelerateCareerPrepDetailsCancel = (payload: any) => ({
  type: FETCH_EXCELERATE_CAREER_PREP_DETAILS_CANCEL as typeof FETCH_EXCELERATE_CAREER_PREP_DETAILS_CANCEL,
  payload,
})

export const updateExcelerateCareerPrepActiveStatus = (payload: {
  active: boolean
}) => ({
  type: UPDATE_EXCELERATE_CAREER_PREP_ACTIVE_STATUS as typeof UPDATE_EXCELERATE_CAREER_PREP_ACTIVE_STATUS,
  payload,
})

interface SubmitExcelerateFeedbackPayload {
  course_id: CourseID
  rating: number | null
  options: string[]
  comments: string
}

export const submitExcelerateFeedback = (
  payload: SubmitExcelerateFeedbackPayload
) => ({
  type: SUBMIT_EXCELERATE_FEEDBACK as typeof SUBMIT_EXCELERATE_FEEDBACK,
  payload,
})

export const submitExcelerateFeedbackSuccess = () => ({
  type: SUBMIT_EXCELERATE_FEEDBACK_SUCCESS as typeof SUBMIT_EXCELERATE_FEEDBACK_SUCCESS,
})

export const submitExcelerateFeedbackFailure = (payload: Error) => ({
  type: SUBMIT_EXCELERATE_FEEDBACK_FAILURE as typeof SUBMIT_EXCELERATE_FEEDBACK_FAILURE,
  payload,
})

export const submitExcelerateFeedbackCancel = () => ({
  type: SUBMIT_EXCELERATE_FEEDBACK_CANCEL as typeof SUBMIT_EXCELERATE_FEEDBACK_CANCEL,
})

export type ExcelerateCareerPrepActions =
  | ReturnType<typeof fetchExcelerateCareerPrepDetails>
  | ReturnType<typeof fetchExcelerateCareerPrepDetailsSuccess>
  | ReturnType<typeof fetchExcelerateCareerPrepDetailsFailure>
  | ReturnType<typeof fetchExcelerateCareerPrepDetailsCancel>
  | ReturnType<typeof updateExcelerateCareerPrepActiveStatus>
  | ReturnType<typeof submitExcelerateFeedback>
  | ReturnType<typeof submitExcelerateFeedbackSuccess>
  | ReturnType<typeof submitExcelerateFeedbackFailure>
  | ReturnType<typeof submitExcelerateFeedbackCancel>
