import {
  AssessmentScheduleDetails,
  AssessmentScheduleID,
  InvigilationDetails,
} from 'common/types/invigilation'
import { Reducer } from 'redux'
import { InvigilationActionTypes } from '.'
import {
  APPROVE_OR_REJECT_ONBOARDING_PHOTO,
  APPROVE_OR_REJECT_ONBOARDING_PHOTO_FAILURE,
  APPROVE_OR_REJECT_ONBOARDING_PHOTO_SUCCESS,
  FETCH_ASSESSMENT_SCHEDULE_DETAILS,
  FETCH_ASSESSMENT_SCHEDULE_DETAILS_CANCEL,
  FETCH_ASSESSMENT_SCHEDULE_DETAILS_FAILURE,
  FETCH_ASSESSMENT_SCHEDULE_DETAILS_SUCCESS,
  FETCH_INVIGILATION_DETAILS,
  FETCH_INVIGILATION_DETAILS_CANCEL,
  FETCH_INVIGILATION_DETAILS_FAILURE,
  FETCH_INVIGILATION_DETAILS_SUCCESS,
} from './Invigilation.types'

interface InvigilationStateData {
  byId: { [s in AssessmentScheduleID]?: AssessmentScheduleDetails }
}

export interface InvigilationState {
  data: InvigilationStateData
  loading: boolean
  error: false | Error | Response
}

const initialStateInvigilation: InvigilationState = {
  data: {
    byId: {},
  },
  loading: false,
  error: false,
}

const invigilationReducer: Reducer<
  InvigilationState,
  InvigilationActionTypes
> = (state = initialStateInvigilation, action): InvigilationState => {
  switch (action.type) {
    case FETCH_ASSESSMENT_SCHEDULE_DETAILS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_ASSESSMENT_SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.payload.schedule_id]: {
              ...state.data.byId[action.payload.schedule_id],
              ...action.payload,
            },
          },
        },
      }
    case FETCH_ASSESSMENT_SCHEDULE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case FETCH_ASSESSMENT_SCHEDULE_DETAILS_CANCEL:
      return {
        ...state,
        loading: false,
      }

    case FETCH_INVIGILATION_DETAILS:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.assessmentScheduleId]: {
              ...(state.data.byId[
                action.meta.assessmentScheduleId
              ] as AssessmentScheduleDetails),
              ...(state.data.byId[action.meta.assessmentScheduleId] &&
              state.data.byId[action.meta.assessmentScheduleId]!
                .invigilation_details
                ? {}
                : { invigilation_details: 'fetching' }),
            },
          },
        },
      }
    case FETCH_INVIGILATION_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.assessmentScheduleId]: {
              ...(state.data.byId[
                action.meta.assessmentScheduleId
              ] as AssessmentScheduleDetails),
              invigilation_details: { ...action.payload },
            },
          },
        },
      }
    case FETCH_INVIGILATION_DETAILS_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.assessmentScheduleId]: {
              ...(state.data.byId[
                action.meta.assessmentScheduleId
              ] as AssessmentScheduleDetails),
              invigilation_details: action.payload,
            },
          },
        },
      }
    case APPROVE_OR_REJECT_ONBOARDING_PHOTO_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.assessmentScheduleId]: {
              ...(state.data.byId[
                action.meta.assessmentScheduleId
              ] as AssessmentScheduleDetails),
              invigilation_details: {
                ...(state.data.byId[action.meta.assessmentScheduleId]!
                  .invigilation_details as InvigilationDetails),
                onboarding: {
                  loading: false,
                  error: false,
                  ...action.payload.onboarding_data,
                },
              },
            },
          },
        },
      }
    case APPROVE_OR_REJECT_ONBOARDING_PHOTO:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.assessmentScheduleId]: {
              ...(state.data.byId[
                action.meta.assessmentScheduleId
              ] as AssessmentScheduleDetails),
              invigilation_details: {
                ...(state.data.byId[action.meta.assessmentScheduleId]!
                  .invigilation_details as InvigilationDetails),
                onboarding: {
                  ...(state.data.byId[action.meta.assessmentScheduleId]!
                    .invigilation_details as InvigilationDetails).onboarding,
                  error: false,
                  loading: true,
                },
              },
            },
          },
        },
      }
    case APPROVE_OR_REJECT_ONBOARDING_PHOTO_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.assessmentScheduleId]: {
              ...(state.data.byId[
                action.meta.assessmentScheduleId
              ] as AssessmentScheduleDetails),
              invigilation_details: {
                ...(state.data.byId[action.meta.assessmentScheduleId]!
                  .invigilation_details as InvigilationDetails),
                onboarding: {
                  ...(state.data.byId[action.meta.assessmentScheduleId]!
                    .invigilation_details as InvigilationDetails).onboarding,
                  error: action.payload,
                  loading: false,
                },
              },
            },
          },
        },
      }
    case FETCH_INVIGILATION_DETAILS_CANCEL:
    default:
      return state
  }
}

export default invigilationReducer
