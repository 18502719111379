import { Typography } from '@material-ui/core'
import React, { MouseEventHandler } from 'react'
import { NavLink } from 'react-router-dom'
import { CourseID } from '../../../common/types/courses'
import { generateURL } from '../../../common/utils'
import Button from '../Utils/Button'
import Dialog, { DialogContent } from '../Utils/Dialog'
import {
  InductionPrimaryIcon,
  InductionSecondaryIcon,
} from '../Utils/Icons/Icons'
import styles from './InductionDialog.module.css'

interface InductionDialogProps {
  onClose: MouseEventHandler
  courseId: CourseID
  courseInprogress: boolean
}

const InductionDialog = ({
  onClose,
  courseId,
  courseInprogress,
}: InductionDialogProps) => {
  return (
    <Dialog open onClose={onClose} disableBackdropClick>
      <DialogContent className={styles.dialogContent}>
        {!courseInprogress ? (
          <InductionPrimaryIcon className={styles.icon} />
        ) : (
          <InductionSecondaryIcon className={styles.icon} />
        )}
        <Typography variant="h2" className={styles.title}>
          {!courseInprogress
            ? `Get prepared for your learning journey`
            : `Complete Orientation Course`}
        </Typography>
        <Typography>
          {!courseInprogress
            ? `Our short orientation module will help you get ready for the learning journey you are about to embark on. 
                It gives you an idea of what to expect and how you can prepare yourself to get the most out of this program. `
            : `Looks like you’ve not completed the Welcome and Orientation course yet. Complete it now to get the most out of this program.`}
        </Typography>
        <NavLink to={generateURL('COURSE', { path: { courseId } })}>
          <Button
            variant="contained"
            color="primary"
            textTransform="uppercase"
            className={styles.start}
            onClick={onClose}
          >
            {!courseInprogress ? `Get Started` : `Resume`}
          </Button>
        </NavLink>
      </DialogContent>
    </Dialog>
  )
}

export default InductionDialog
