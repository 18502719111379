import { UserID } from 'common/types/user'
import { apiCall } from 'common/utils'

export async function getCurrentTimeAPI({ userId }: { userId: UserID }) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/date_time/utc`,
    query: { user_id: userId },
    excludeProgramId: true,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
