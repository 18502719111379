import { call, put, takeLatest, select } from 'redux-saga/effects'
import { cancelable } from '../../../../common/utils'
import {
  fetchUserPayment,
  fetchUserPaymentSuccess,
  makeFeePayment,
  fetchUserPaymentFailure,
} from './Payments.actions'

import * as APIs from './Payments.api'
import * as types from './Payments.types'
import { UserPaymentsData } from '../../../../common/types/user'
import { paymentSelectors } from '.'

function* fetchUserPaymentHandler(action: ReturnType<typeof fetchUserPayment>) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      APIs.getUserPaymentAPI,
      action,
      abortController.signal
    )

    yield put(fetchUserPaymentSuccess(data))
  } catch (e) {
    yield put(fetchUserPaymentFailure(e))
  }
}

function* makeFeePaymentHandler(action: ReturnType<typeof makeFeePayment>) {
  const abortController = new AbortController()
  const paymentTransaction: UserPaymentsData['payment_transaction'] = yield select(
    paymentSelectors.getPaymentTransaction()
  )

  if (action.isInternational && paymentTransaction.currency_code?.toLowerCase() === 'usd' && !action.degreeProgram) {
    try {
      yield call(
        APIs.intMakeFeePaymentAPI,
        paymentTransaction,
        action,
        abortController.signal
      )
    } catch (e) {
      yield put(fetchUserPaymentFailure(e))
    }
  } else {
    try {
      const data = yield call(
        APIs.makeFeePaymentAPI,
        paymentTransaction,
        action,
        abortController.signal
      )
      if (action.is_razorpay) {
        action.closePayNow()
      }
      yield call(APIs.paymentGatewayAPI, data, abortController.signal)
    } catch (e) {
      yield put(fetchUserPaymentFailure(e))
    }
  }
}

export function* fetchUserPaymentsMiddleware() {
  yield takeLatest(
    types.FETCH_USER_PAYMENT,
    cancelable(fetchUserPaymentHandler, types.FETCH_USER_PAYMENT)
  )
}

export function* makeFeePaymentsMiddleware() {
  yield takeLatest(
    types.MAKE_FEE_PAYMENT,
    cancelable(makeFeePaymentHandler, types.MAKE_FEE_PAYMENT)
  )
}

export default ([] as any).concat([
  fetchUserPaymentsMiddleware(),
  makeFeePaymentsMiddleware(),
])
