import { ProgramData } from 'common/types/programs'
import { apiCall, getUserDetails } from 'common/utils'
import {
  QuizSubmissionData,
  QuizAnswerData,
  QuizModuleItemData,
  QuizContentData,
  QuizSubmissionEventKey,
  QuizSubmissionEventType,
  DynamicQuestionData,
} from '../../../types/courses/quiz'

/**
 * Check if user has valid number of attempts for this quiz
 */
export const hasValidAttemptsAllowed = (allowedAttempts: number | null) =>
  typeof allowedAttempts === 'number' &&
  (allowedAttempts === -1 || allowedAttempts >= 1)

/**
 * Check if user has valid number of attempts remaining for this quiz
 */
export const hasValidAttemptsRemaining = (
  allowedAttempts: number,
  submissions: QuizSubmissionData[]
) =>
  allowedAttempts === -1 ||
  submissions.filter(sub =>
    ['complete', 'pending_review'].includes(sub.workflow_state)
  ).length < allowedAttempts ||
  submissions.sort((sortA, sortB) => sortB.attempt - sortA.attempt)[0]
    .attempts_left !== 0

export const calcAttemptsRemaining = (
  allowedAttempts: number,
  submissions: QuizSubmissionData[]
) => {
  if (allowedAttempts === -1) {
    return null
  }
  if (submissions.length === 0) {
    return allowedAttempts
  }
  return submissions.sort((sortA, sortB) => sortB.attempt - sortA.attempt)[0]
    .attempts_left
}

export const calcTotalAttempts = (
  allowedAttempts: number,
  submissions: QuizSubmissionData[]
) => {
  if (allowedAttempts === -1) {
    return null
  }
  if (submissions.length === 0) {
    return allowedAttempts
  }
  const extraAttempts = submissions.sort(
    (sortA, sortB) => sortB.attempt - sortA.attempt
  )[0].extra_attempts
  if (extraAttempts) {
    return allowedAttempts + extraAttempts
  }
  return allowedAttempts
}

export const isAnswerValid = (answer: QuizAnswerData['answer']) => {
  if (Array.isArray(answer)) {
    return answer.length > 0
  }
  if (typeof answer === 'number') {
    return true
  }
  return !!answer
}

export const isQuestionAnswered = (
  answer: QuizAnswerData | undefined | null
) => {
  if (!answer) {
    return false
  }
  return isAnswerValid(answer.answer)
}

export const getQuizType = (
  itemContent: QuizContentData | QuizModuleItemData['contentMeta']
) =>
  itemContent &&
  (itemContent.quiz_type === 'graded_survey' ||
    itemContent.quiz_type === 'survey')
    ? 'survey'
    : 'quiz'

export const isGlaCertificateItem = (
  academyUser: boolean,
  itemData: QuizModuleItemData
): boolean => {
  const quizType =
    (itemData.itemContent && getQuizType(itemData.itemContent)) ||
    (itemData.contentMeta && getQuizType(itemData.contentMeta))
  return !!(
    academyUser &&
    quizType === 'survey' &&
    itemData.title.toLowerCase() === 'claim your course certificate'
  )
}

export const quizTypes = (text_mapping: ProgramData['text_mapping']) => ({
  assignment: text_mapping.graded_quiz,
  practice_quiz: text_mapping.practice_quiz,
  survey: 'Survey',
  graded_survey: 'Graded Survey',
})

export const QuizSubmissionEvent: {
  [key in QuizSubmissionEventKey]: QuizSubmissionEventType
} = {
  START: 'start',
  RESUME: 'resume',
  QUESTION_VIEW: 'question_view',
  COPY: 'copy',
  CUT: 'cut',
  PASTE: 'paste',
  LAB_SWITCH: 'lab_switch',
  PROCTORING_PAUSED: 'proctoring_paused',
  PROCTORING_RESUMED: 'proctoring_resumed',
  SUBMIT: 'submit',
  NETWORK_CHANGE: 'network_change',
}

// Enabling for all the programs. Need to refactor the code when we remove this method completely
export const useNewQuizUIEnabled = (): boolean => true

export const audioTranscribe = async (params: {
  audio_answer: Blob
}): Promise<{ data: { transcribed_text: string } }> => {
  const formData = new FormData()
  formData.append('audio_answer', params.audio_answer, 'audio/wav')
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/dynamic_quiz/transcribe`,
    params: {
      method: 'POST',
      body: formData,
    },
    auth: true,
    query: {
      user_id: id,
    },
  })
  return response.json()
}

export const dynamicQuestionParsing = (message: string) => {
  let chatMessage = {}
  try {
    chatMessage = JSON.parse(
      message
        .replace(/```json\n?|```/g, '')
        .replaceAll('\n', '')
        .toString()
    )
  } catch (e) {
    chatMessage = {
      question: message,
      coding_language: null,
      question_sequence: 'new',
      previous_coding_submission: null,
      question_type: 'theory',
    }
    console.log('error while parsing question data', e)
  }
  return chatMessage as DynamicQuestionData
}

export const dynamicAnswerSorting = (
  rawData: {
    ai_model_role: 'assistant' | 'user'
    message: string
    message_id: string
  }[]
) => {
  const quesData: DynamicQuestionData[] = []
  const ansData: string[] = []
  const data = rawData.slice(
    rawData.findIndex(obj => obj.ai_model_role === 'assistant'),
    rawData.length
  )
  data.forEach(chat => {
    if (chat.ai_model_role === 'user') {
      ansData.push(chat.message)
    } else {
      quesData.push({
        ...dynamicQuestionParsing(chat.message),
        chat_id: chat.message_id,
      } as DynamicQuestionData)
    }
  })
  return { quesData, ansData }
}

export const dynamicQuizReport = async (params: {
  submission_id: number
  attempt: number
}): Promise<any[]> => {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/dynamic_quiz/report`,
    auth: true,
    query: {
      user_id: id,
      ...params,
    },
  })
  return response.json()
}

export const dynamicQuizNinjaReport = async (params: {
  submission_id: number
  attempt: number
  question_id: number
}): Promise<any[]> => {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/dynamic_quiz/ninja_report`,
    auth: true,
    query: {
      user_id: id,
      ...params,
    },
    excludeProgramId: true,
  })
  return response.json()
}

export const dynamicQuizExecuteCode = async (params: {
  language: string
  code: string
}): Promise<any> => {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/dynamic_quiz/code_execute`,
    auth: true,
    query: {
      user_id: id,
      ...params,
    },
    params: {
      method: 'POST',
      body: JSON.stringify(params),
    },
  })
  return response.json()
}
