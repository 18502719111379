import {
  SUBMIT_RESET_PASSWORD,
  SUBMIT_RESET_PASSWORD_SUCCESS,
  SUBMIT_RESET_PASSWORD_FAILURE,
  SUBMIT_RESET_PASSWORD_CANCEL,
  CLEAR_SUBMITTED_STATUS,
  SUBMIT_TIME_ZONE,
  SUBMIT_TIME_ZONE_SUCCESS,
  SUBMIT_TIME_ZONE_FAILURE,
  SUBMIT_TIME_ZONE_CANCEL,
  FETCH_TIME_ZONES,
  FETCH_TIME_ZONES_SUCCESS,
  FETCH_TIME_ZONES_FAILURE,
  FETCH_TIME_ZONES_CANCEL,
  SUBMIT_PERSONAL_DETAILS_SUCCESS,
  SUBMIT_PERSONAL_DETAILS,
  SUBMIT_PERSONAL_DETAILS_FAILURE,
  SUBMIT_PERSONAL_DETAILS_CANCEL,
  UPDATE_PROFILE_IMAGE,
  UPDATE_PROFILE_IMAGE_SUCCESS,
  UPDATE_PROFILE_IMAGE_FAILURE,
  UPDATE_PROFILE_IMAGE_CANCEL,
  TRIGGER_MOBILE_OTP,
  TRIGGER_MOBILE_OTP_CANCEL,
  TRIGGER_MOBILE_OTP_FAILURE,
  TRIGGER_MOBILE_OTP_SUCCESS,
  SUBMIT_MOBILE_OTP,
  SUBMIT_MOBILE_OTP_CANCEL,
  SUBMIT_MOBILE_OTP_FAILURE,
  SUBMIT_MOBILE_OTP_SUCCESS,
  UPDATE_PROFESSIONAL_DETAILS,
  ALUMNI_MESSAGE_SWITCH,
  UPDATE_PROFESSIONAL_DETAILS_SUCCESS,
  UPDATE_PROFESSIONAL_DETAILS_FAILURE,
  UPDATE_PROFESSIONAL_DETAILS_CANCEL,
} from './UserSettings.types'
import { FileID } from '../../../../common/types'
import { UserSettingsState } from './UserSettings.reducer'

interface SubmitPersonalDetailsPayload {
  fullName: string
  email: string
  mobile: string
  timeZone: string
}
interface UpdateProfileImagePayload {
  profileImageId: FileID | undefined
  dataKey: string
  url: string
  folderPath: string
}

interface SubmitResetPasswordPayload {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

interface SubmitTimezoneChangePayload {
  timeZone: string
}
interface TriggerMobileOTPPayload {
  mobile: string
}
interface SubmitMobileOTPPayload {
  mobile: string
  mobileOTP: string
}

export const updateProfessionalDetailsSuccess = (payload: {
  loading: boolean
  linkedIn_url: string
}) => ({
  type: UPDATE_PROFESSIONAL_DETAILS_SUCCESS as typeof UPDATE_PROFESSIONAL_DETAILS_SUCCESS,
  payload,
})

export const updateProfessionalDetailsFailure = () => ({
  type: UPDATE_PROFESSIONAL_DETAILS_FAILURE as typeof UPDATE_PROFESSIONAL_DETAILS_FAILURE,
})
export const updateProfessionalDetailsCancel = () => ({
  type: UPDATE_PROFESSIONAL_DETAILS_CANCEL as typeof UPDATE_PROFESSIONAL_DETAILS_CANCEL,
})
export const submitPersonalDetails = (
  payload: Partial<SubmitPersonalDetailsPayload>
) => ({
  type: SUBMIT_PERSONAL_DETAILS as typeof SUBMIT_PERSONAL_DETAILS,
  payload,
})
export const submitPersonalDetailsSuccess = (payload: {
  initialData: Partial<SubmitPersonalDetailsPayload>
  [s: string]: any
}) => ({
  type: SUBMIT_PERSONAL_DETAILS_SUCCESS as typeof SUBMIT_PERSONAL_DETAILS_SUCCESS,
  payload,
})
export const submitPersonalDetailsFailure = (
  payload: Exclude<UserSettingsState['personalDetails']['error'], false>
) => ({
  type: SUBMIT_PERSONAL_DETAILS_FAILURE as typeof SUBMIT_PERSONAL_DETAILS_FAILURE,
  payload,
})
export const submitPersonalDetailsCancel = (payload: any) => ({
  type: SUBMIT_PERSONAL_DETAILS_CANCEL as typeof SUBMIT_PERSONAL_DETAILS_CANCEL,
  payload,
})

export const updateProfileImage = (payload: UpdateProfileImagePayload) => ({
  type: UPDATE_PROFILE_IMAGE as typeof UPDATE_PROFILE_IMAGE,
  payload,
})
export const updateProfileImageSuccess = (payload: any) => ({
  type: UPDATE_PROFILE_IMAGE_SUCCESS as typeof UPDATE_PROFILE_IMAGE_SUCCESS,
  payload,
})
export const updateProfileImageFailure = (payload: Error) => ({
  type: UPDATE_PROFILE_IMAGE_FAILURE as typeof UPDATE_PROFILE_IMAGE_FAILURE,
  payload,
})
export const updateProfileImageCancel = (payload: any) => ({
  type: UPDATE_PROFILE_IMAGE_CANCEL as typeof UPDATE_PROFILE_IMAGE_CANCEL,
  payload,
})

export const submitResetPassword = (payload: SubmitResetPasswordPayload) => ({
  type: SUBMIT_RESET_PASSWORD as typeof SUBMIT_RESET_PASSWORD,
  payload,
})
export const submitResetPasswordSuccess = (payload: any) => ({
  type: SUBMIT_RESET_PASSWORD_SUCCESS as typeof SUBMIT_RESET_PASSWORD_SUCCESS,
  payload,
})
export const submitResetPasswordFailure = (payload: Error) => ({
  type: SUBMIT_RESET_PASSWORD_FAILURE as typeof SUBMIT_RESET_PASSWORD_FAILURE,
  payload,
})
export const submitResetPasswordCancel = (payload: any) => ({
  type: SUBMIT_RESET_PASSWORD_CANCEL as typeof SUBMIT_RESET_PASSWORD_CANCEL,
  payload,
})
export const clearSubmittedStatus = () => ({
  type: CLEAR_SUBMITTED_STATUS as typeof CLEAR_SUBMITTED_STATUS,
})
export const fetchTimeZones = () => ({
  type: FETCH_TIME_ZONES as typeof FETCH_TIME_ZONES,
})
export const fetchTimeZonesSuccess = (payload: any) => ({
  type: FETCH_TIME_ZONES_SUCCESS as typeof FETCH_TIME_ZONES_SUCCESS,
  payload,
})
export const fetchTimeZonesFailure = (payload: Error) => ({
  type: FETCH_TIME_ZONES_FAILURE as typeof FETCH_TIME_ZONES_FAILURE,
  payload,
})
export const fetchTimeZonesCancel = (payload: any) => ({
  type: FETCH_TIME_ZONES_CANCEL as typeof FETCH_TIME_ZONES_CANCEL,
  payload,
})

export const submitTimeZone = (payload: SubmitTimezoneChangePayload) => ({
  type: SUBMIT_TIME_ZONE as typeof SUBMIT_TIME_ZONE,
  payload,
})
export const submitTimeZoneSuccess = (payload: any) => ({
  type: SUBMIT_TIME_ZONE_SUCCESS as typeof SUBMIT_TIME_ZONE_SUCCESS,
  payload,
})
export const submitTimeZoneFailure = (payload: Error) => ({
  type: SUBMIT_TIME_ZONE_FAILURE as typeof SUBMIT_TIME_ZONE_FAILURE,
  payload,
})
export const submitTimeZoneCancel = (payload: any) => ({
  type: SUBMIT_TIME_ZONE_CANCEL as typeof SUBMIT_TIME_ZONE_CANCEL,
  payload,
})

export const triggerMobileOTP = (payload: TriggerMobileOTPPayload) => ({
  type: TRIGGER_MOBILE_OTP as typeof TRIGGER_MOBILE_OTP,
  payload,
})
export const triggerMobileOTPSuccess = () => ({
  type: TRIGGER_MOBILE_OTP_SUCCESS as typeof TRIGGER_MOBILE_OTP_SUCCESS,
})
export const triggerMobileOTPFailure = (payload: Error) => ({
  type: TRIGGER_MOBILE_OTP_FAILURE as typeof TRIGGER_MOBILE_OTP_FAILURE,
  payload,
})
export const triggerMobileOTPCancel = (payload: any) => ({
  type: TRIGGER_MOBILE_OTP_CANCEL as typeof TRIGGER_MOBILE_OTP_CANCEL,
  payload,
})

export const submitMobileOTP = (payload: SubmitMobileOTPPayload) => ({
  type: SUBMIT_MOBILE_OTP as typeof SUBMIT_MOBILE_OTP,
  payload,
})
export const submitMobileOTPSuccess = () => ({
  type: SUBMIT_MOBILE_OTP_SUCCESS as typeof SUBMIT_MOBILE_OTP_SUCCESS,
})
export const submitMobileOTPFailure = (payload: Error) => ({
  type: SUBMIT_MOBILE_OTP_FAILURE as typeof SUBMIT_MOBILE_OTP_FAILURE,
  payload,
})
export const submitMobileOTPCancel = (payload: any) => ({
  type: SUBMIT_MOBILE_OTP_CANCEL as typeof SUBMIT_MOBILE_OTP_CANCEL,
  payload,
})

export const updateProfessionalDetails = (payload: any) => ({
  type: UPDATE_PROFESSIONAL_DETAILS as typeof UPDATE_PROFESSIONAL_DETAILS,
  payload,
})

export const alumniMessageSwitch = (state: boolean) => ({
  type: ALUMNI_MESSAGE_SWITCH as typeof ALUMNI_MESSAGE_SWITCH,
  state,
})

export type UserSettingsAction =
  | ReturnType<typeof submitPersonalDetails>
  | ReturnType<typeof submitPersonalDetailsSuccess>
  | ReturnType<typeof submitPersonalDetailsFailure>
  | ReturnType<typeof submitPersonalDetailsCancel>
  | ReturnType<typeof clearSubmittedStatus>
  | ReturnType<typeof updateProfileImage>
  | ReturnType<typeof updateProfileImageSuccess>
  | ReturnType<typeof updateProfileImageFailure>
  | ReturnType<typeof updateProfileImageCancel>
  | ReturnType<typeof submitResetPassword>
  | ReturnType<typeof submitResetPasswordSuccess>
  | ReturnType<typeof submitResetPasswordFailure>
  | ReturnType<typeof submitResetPasswordCancel>
  | ReturnType<typeof fetchTimeZones>
  | ReturnType<typeof fetchTimeZonesSuccess>
  | ReturnType<typeof fetchTimeZonesFailure>
  | ReturnType<typeof fetchTimeZonesCancel>
  | ReturnType<typeof submitTimeZone>
  | ReturnType<typeof submitTimeZoneSuccess>
  | ReturnType<typeof submitTimeZoneFailure>
  | ReturnType<typeof submitTimeZoneCancel>
  | ReturnType<typeof triggerMobileOTP>
  | ReturnType<typeof triggerMobileOTPSuccess>
  | ReturnType<typeof triggerMobileOTPFailure>
  | ReturnType<typeof triggerMobileOTPCancel>
  | ReturnType<typeof submitMobileOTP>
  | ReturnType<typeof submitMobileOTPSuccess>
  | ReturnType<typeof submitMobileOTPFailure>
  | ReturnType<typeof submitMobileOTPCancel>
  | ReturnType<typeof updateProfessionalDetails>
  | ReturnType<typeof alumniMessageSwitch>
  | ReturnType<typeof updateProfessionalDetailsSuccess>
  | ReturnType<typeof updateProfessionalDetailsFailure>
  | ReturnType<typeof updateProfessionalDetailsCancel>
