import {
  put,
  cancelled,
  takeEvery,
  call,
  select,
  take,
} from 'redux-saga/effects'
import {
  cancelable,
  communitySsoToken,
  getUserDetails,
  isJwtTokenExpired,
} from 'common/utils'
import { ProgramData } from 'common/types/programs'
import {
  getCommunityGroupIdByProgramId,
  glCommunityAlumniGroupBaseUrl,
  communityAlumniGroupMapping,
} from 'common/utils/custom/community'
import { timeDifferenceFromNowInDays } from 'common/utils/time'
import { AppState } from 'web/store'
import {
  FETCH_SIMILAR_QUESTIONS,
  FETCH_SIMILAR_QUESTIONS_CANCEL,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_CANCEL,
  FETCH_TOPICS,
  FETCH_TOPICS_CANCEL,
  POST_QUESTION,
  POST_QUESTION_CANCEL,
  POST_QUESTION_FAILURE,
  POST_QUESTION_SUCCESS,
  POST_ANSWER,
  POST_ANSWER_CANCEL,
  DELETE_QUESTION,
  DELETE_ANSWER,
  DELETE_ANSWER_CANCEL,
  DELETE_QUESTION_CANCEL,
  UPDATE_ANSWER,
  UPDATE_ANSWER_CANCEL,
  UPDATE_QUESTION,
  UPDATE_QUESTION_CANCEL,
  FETCH_NEW_QUESTIONS,
  FETCH_NEW_QUESTIONS_CANCEL,
  RENEW_COMMUNITY_JWT_TOKEN,
  RENEW_COMMUNITY_JWT_TOKEN_CANCEL,
  UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS,
  FETCH_USER_QUESTIONS,
  FETCH_USER_QUESTIONS_CANCEL,
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_CANCEL,
  POST_QUESTION_UNDER_VIDEO,
  POST_QUESTION_UNDER_VIDEO_CANCEL,
  FETCH_VIDEO_QUESTIONS_IDS,
  FETCH_VIDEO_QUESTIONS_IDS_CANCEL,
  FETCH_PRE_ASKED_SIMILAR_QUESTIONS_CANCEL,
  FETCH_PRE_ASKED_SIMILAR_QUESTIONS,
  ADD_NEW_TOPIC_TO_QUESTION_CANCEL,
  ADD_NEW_TOPIC_TO_QUESTION,
  FETCH_TRENDING_QUESTIONS_CANCEL,
  FETCH_TRENDING_QUESTIONS,
  FETCH_TRENDING_ARTICLES_CANCEL,
  FETCH_TRENDING_ARTICLES,
  FETCH_GROUP_FEEDS,
  FETCH_GROUP_FEEDS_CANCEL,
  FETCH_GROUP_DETAILS,
  FETCH_GROUP_DETAILS_CANCEL,
  FETCH_ALUMNI_GROUP_MEMBERS,
  FETCH_ALUMNI_GROUP_MEMBERS_CANCEL,
} from './Community.types'
import { fetchSimilarQuestions, fetchNotifications } from '.'
import {
  fetchSimilarQuestionsFailure,
  fetchSimilarQuestionsSuccess,
  fetchNotificationsFailure,
  fetchNotificationsSuccess,
  fetchTopics,
  fetchTopicsFailure,
  fetchTopicsSuccess,
  postQuestion,
  postQuestionSuccess,
  postQuestionFailure,
  postAnswer,
  postAnswerSuccess,
  postAnswerFailure,
  deleteQuestion,
  deleteAnswer,
  deleteAnswerFailure,
  deleteAnswerSuccess,
  deleteQuestionFailure,
  deleteQuestionSuccess,
  updateAnswer,
  updateAnswerFailure,
  updateAnswerSuccess,
  updateQuestion,
  updateQuestionFailure,
  updateQuestionSuccess,
  fetchNewQuestionsFailure,
  fetchNewQuestionsSuccess,
  updateCommunityJwtTokenSuccess,
  renewCommunityJwtToken,
  renewCommunityJwtTokenFailure,
  fetchUserQuestionsFailure,
  fetchUserQuestionsSuccess,
  fetchUserQuestions,
  fetchUserDetailsFailure,
  fetchUserDetailsSuccess,
  fetchUserDetails,
  postQuestionUnderVideo,
  postQuestionUnderVideoSuccess,
  postQuestionUnderVideoFailure,
  fetchVideoQuestionIdsSuccess,
  fetchVideoQuestionIdsFailure,
  fetchPreAskedSimilarQuestions,
  fetchPreAskedSimilarQuestionsSuccess,
  fetchPreAskedSimilarQuestionsFailure,
  addNewTopicToQuestion,
  addNewTopicToQuestionSuccess,
  addNewTopicToQuestionFailure,
  fetchTrendingQuestions,
  fetchTrendingQuestionsSuccess,
  fetchTrendingQuestionsFailure,
  fetchTrendingArticles,
  fetchTrendingArticlesSuccess,
  fetchTrendingArticlesFailure,
  fetchGroupFeeds,
  fetchGroupFeedsSuccess,
  fetchGroupFeedsFailure,
  fetchGroupDetails,
  fetchGroupDetailsSuccess,
  fetchGroupDetailsFailure,
  fetchAlumniGroupMembers,
  fetchAlumniGroupMembersSuccess,
  fetchAlumniGroupMembersFailure,
  insertNewQuestionIntoFeeds,
} from './Community.actions'
import {
  fetchNewQuestionsAPI,
  fetchNotificationsAPI,
  fetchSimilarQuestionsAPI,
  fetchTopicsAPI,
  postQuestionAPI,
  postAnswerAPI,
  deleteQuestionAPI,
  updateQuestionAPI,
  updateAnswerAPI,
  fetchUserQuestionsAPI,
  fetchUserDetailsAPI,
  postQuestionVideoMappingAPI,
  getVideoQuestionIdsAPI,
  createTopicAPI,
  fetchTrendingQuestionsApi,
  uploadAttachmentAPI,
  fetchTrendingArticlesApi,
  fetchAlumniGroupFeedsApi,
  fetchGroupDetailsApi,
  fetchAlumniGroupMembersApi,
  generateCommunityV2TokenAPI,
} from './Community.api'
import { programSelectors } from '../Dashboard/ProgramsProvider'
import { showAlertMessage } from '../AlertsProvider'
import { fetchCommunitySsoToken } from '../Dashboard/ProgramsProvider/Programs.actions'

function* fetchSimilarQuestionsHandler(
  action: ReturnType<typeof fetchSimilarQuestions>
) {
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const currentTitleResponse = yield call(
      fetchSimilarQuestionsAPI,
      action.payload.keywords[0]
    )
    const courseTitleResponse = yield call(
      fetchSimilarQuestionsAPI,
      action.payload.keywords[1]
    )
    yield put(
      fetchSimilarQuestionsSuccess(
        currentTitleResponse.concat(courseTitleResponse)
      )
    )
  } catch (e) {
    yield put(fetchSimilarQuestionsFailure(e))
  }
}

function* fetchNotificationsHandler(
  action: ReturnType<typeof fetchNotifications>
) {
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const data = yield call(fetchNotificationsAPI, action)
    yield put(fetchNotificationsSuccess(data.count))
  } catch (e) {
    yield put(fetchNotificationsFailure(e))
  }
}

function* fetchTopicsHandler(action: ReturnType<typeof fetchTopics>) {
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const data = yield call(fetchTopicsAPI, action.payload)
    yield put(fetchTopicsSuccess(data))
  } catch (e) {
    yield put(fetchTopicsFailure(e))
  }
}

function* postQuestionHandler(action: ReturnType<typeof postQuestion>) {
  try {
    const activeProgram: ProgramData | null = yield select(
      programSelectors.getActiveProgramDetails()
    )

    let communityGroupId = ''

    if (action.payload && action.payload.source === 'GL_CONNECT') {
      communityGroupId = communityAlumniGroupMapping.id
    } else {
      communityGroupId = getCommunityGroupIdByProgramId(
        activeProgram?.program_id || 0
      )
    }

    let attachmentIds: string[] = []
    if (action.payload.attachments && action.payload.attachments.length) {
      yield put(renewCommunityJwtToken())
      yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
      const attachmentFiles = action.payload.attachments.map(
        attachment => attachment.file
      )
      const attachments: any[] = yield call(
        uploadAttachmentAPI,
        attachmentFiles
      )
      if (!!attachments && attachments.length > 0) {
        attachmentIds = attachments
          .filter(data => data.uploaded)
          .map(data => data.id)
      }
    }
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const data: any = yield call(
      postQuestionAPI,
      action,
      communityGroupId,
      attachmentIds
    )
    yield put(postQuestionSuccess(data))
    if (action.payload && action.payload.source === 'GL_CONNECT') {
      yield put(insertNewQuestionIntoFeeds(data))
      yield put(
        showAlertMessage({
          variant: 'success',
          message: 'Question posted successfully',
        })
      )
      const win = window.open(glCommunityAlumniGroupBaseUrl(), '_blank')
      // eslint-disable-next-line no-unused-expressions
      win && win.focus()
    }
  } catch (e) {
    yield put(postQuestionFailure(e))
    if (action.payload && action.payload.source === 'GL_CONNECT') {
      yield put(
        showAlertMessage({
          variant: 'error',
          message: 'Sorry something went wrong!',
        })
      )
    }
  }
}

function* postAnswerHandler(action: ReturnType<typeof postAnswer>) {
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const data = yield call(postAnswerAPI, action)
    yield put(postAnswerSuccess(data))
  } catch (e) {
    yield put(postAnswerFailure(e))
  }
}

function* deleteQuestionHandler(action: ReturnType<typeof deleteQuestion>) {
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const data = yield call(deleteQuestionAPI, action)
    yield put(deleteQuestionSuccess(data))
  } catch (e) {
    yield put(deleteQuestionFailure(e))
  }
}

function* deleteAnswerHandler(action: ReturnType<typeof deleteAnswer>) {
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const data = yield call(deleteQuestionAPI, action)
    yield put(deleteAnswerSuccess(data))
  } catch (e) {
    yield put(deleteAnswerFailure(e))
  }
}

function* updateQuestionHandler(action: ReturnType<typeof updateQuestion>) {
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const data = yield call(updateQuestionAPI, action)
    yield put(updateQuestionSuccess(data))
  } catch (e) {
    yield put(updateQuestionFailure(e))
  }
}

function* updateAnswerHandler(action: ReturnType<typeof updateAnswer>) {
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const data = yield call(updateAnswerAPI, action)
    yield put(updateAnswerSuccess(data))
  } catch (e) {
    yield put(updateAnswerFailure(e))
  }
}

function* newQuestionsHandler(action: ReturnType<typeof updateQuestion>) {
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const newQuestionsData = yield call(fetchNewQuestionsAPI)
    if (newQuestionsData) {
      const newQuestions = newQuestionsData.filter((question: any) => {
        const timeDifference = timeDifferenceFromNowInDays(question.createdAt)
        return timeDifference < 7
      })
      yield put(fetchNewQuestionsSuccess(newQuestions.length))
    }
  } catch (e) {
    yield put(fetchNewQuestionsFailure(e))
  }
}

function* renewCommunityTokenHandler(
  action: ReturnType<typeof renewCommunityJwtToken>
) {
  try {
    let communityJwtToken: string = yield select(
      (state: AppState) => state.community.data.communityJwtToken
    )
    const communityJwtSsoToken = communitySsoToken()
    const { auth } = getUserDetails()

    if (
      communitySsoToken() &&
      isJwtTokenExpired(communityJwtToken) &&
      auth.isAuthenticated
    ) {
      if (isJwtTokenExpired(communityJwtSsoToken)) {
        yield put(fetchCommunitySsoToken())
      }
      const tokenResult = yield call(generateCommunityV2TokenAPI)
      communityJwtToken = tokenResult
    }

    yield put(updateCommunityJwtTokenSuccess(communityJwtToken))
  } catch (e) {
    yield put(renewCommunityJwtTokenFailure(e))
  }
}

function* fetchUserQuestionsHandler(
  action: ReturnType<typeof fetchUserQuestions>
) {
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const userQuestionsData = yield call(fetchUserQuestionsAPI, {
      id: action.payload.userId,
    })
    if (userQuestionsData.length) {
      yield put(fetchUserQuestionsSuccess(userQuestionsData))
    }
  } catch (e) {
    yield put(fetchUserQuestionsFailure(e))
  }
}

function* fetchUserDetailsHandler(action: ReturnType<typeof fetchUserDetails>) {
  try {
    const userId: string = yield select(
      (state: AppState) => state.user.details.id
    )
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const usersData = yield call(fetchUserDetailsAPI, {
      id: userId,
    })

    yield put(
      fetchUserDetailsSuccess({
        usersData,
      })
    )
  } catch (e) {
    yield put(fetchUserDetailsFailure(e))
  }
}

function* postQuestionUnderVideoHandler(
  action: ReturnType<typeof postQuestionUnderVideo>
) {
  const abortController = new AbortController()
  try {
    yield put(
      postQuestion({
        title: action.payload.title,
        topicIds: action.payload.topicIds,
      })
    )
    const res = yield take([POST_QUESTION_SUCCESS, POST_QUESTION_FAILURE])
    if (res.type === POST_QUESTION_FAILURE) {
      throw new Error('Posting to community failed')
    }
    const communityQuestionData = res.payload
    // update question id our database with video mapping
    const userId: string = yield select(
      (state: AppState) => state.user.details.id
    )

    yield call(
      postQuestionVideoMappingAPI,
      {
        userId,
        videoId: action.payload.videoId,
        questionId: communityQuestionData._id,
      },
      abortController.signal
    )
    yield put(
      postQuestionUnderVideoSuccess({
        videoId: action.payload.videoId,
        communityQuestionData,
      })
    )
  } catch (e) {
    yield put(postQuestionUnderVideoFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Something went wrong',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchVideoQuestionIdsHandler(
  action: ReturnType<typeof postQuestionUnderVideo>
) {
  const abortController = new AbortController()
  try {
    const userId: string = yield select(
      (state: AppState) => state.user.details.id
    )
    const questionIds = yield call(
      getVideoQuestionIdsAPI,
      { userId, videoId: action.payload.videoId },
      abortController.signal
    )
    yield put(
      fetchVideoQuestionIdsSuccess({
        videoId: action.payload.videoId,
        questionIds,
      })
    )
  } catch (e) {
    yield put(fetchVideoQuestionIdsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchPreAskedSimilarQuestionsHandler(
  action: ReturnType<typeof fetchPreAskedSimilarQuestions>
) {
  const abortController = new AbortController()
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const data = yield call(
      fetchSimilarQuestionsAPI,
      action.payload,
      abortController.signal
    )
    yield put(fetchPreAskedSimilarQuestionsSuccess(data))
  } catch (e) {
    yield put(fetchPreAskedSimilarQuestionsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* addNewTopicToQuestionHandler(
  action: ReturnType<typeof addNewTopicToQuestion>
) {
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const data = yield call(createTopicAPI, { name: action.payload })
    yield put(addNewTopicToQuestionSuccess({ id: data._id, name: data.name }))
  } catch (e) {
    yield put(addNewTopicToQuestionFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Something went wrong',
      })
    )
  }
}

function* fetchTrendingQuestionsHandler(
  action: ReturnType<typeof fetchTrendingQuestions>
) {
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const data = yield call(fetchTrendingQuestionsApi)
    yield put(fetchTrendingQuestionsSuccess(data))
  } catch (e) {
    yield put(fetchTrendingQuestionsFailure(e))
  }
}

function* fetchTrendingArticlesHandler(
  action: ReturnType<typeof fetchTrendingArticles>
) {
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const data = yield call(fetchTrendingArticlesApi)
    yield put(fetchTrendingArticlesSuccess(data))
  } catch (e) {
    yield put(fetchTrendingArticlesFailure(e))
  }
}

function* fetchGroupFeedsHandler(action: ReturnType<typeof fetchGroupFeeds>) {
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const data = yield call(fetchAlumniGroupFeedsApi)
    yield put(fetchGroupFeedsSuccess(data))
  } catch (e) {
    yield put(fetchGroupFeedsFailure(e))
  }
}

function* fetchGroupDetailsHandler(
  action: ReturnType<typeof fetchGroupDetails>
) {
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const data = yield call(fetchGroupDetailsApi)

    yield put(fetchGroupDetailsSuccess(data))
  } catch (e) {
    yield put(fetchGroupDetailsFailure(e))
  }
}

function* fetchAlumniGroupMembersHandler(
  action: ReturnType<typeof fetchAlumniGroupMembers>
) {
  try {
    yield put(renewCommunityJwtToken())
    yield take(UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS)
    const data = yield call(fetchAlumniGroupMembersApi)
    yield put(fetchAlumniGroupMembersSuccess(data))
  } catch (e) {
    yield put(fetchAlumniGroupMembersFailure(e))
  }
}

export function* communityMiddleware() {
  yield takeEvery(
    FETCH_SIMILAR_QUESTIONS,
    cancelable(fetchSimilarQuestionsHandler, [FETCH_SIMILAR_QUESTIONS_CANCEL])
  )
  yield takeEvery(
    FETCH_NOTIFICATIONS,
    cancelable(fetchNotificationsHandler, [FETCH_NOTIFICATIONS_CANCEL])
  )
  yield takeEvery(
    FETCH_TOPICS,
    cancelable(fetchTopicsHandler, [FETCH_TOPICS_CANCEL])
  )
  yield takeEvery(
    POST_QUESTION,
    cancelable(postQuestionHandler, [POST_QUESTION_CANCEL])
  )
  yield takeEvery(
    POST_ANSWER,
    cancelable(postAnswerHandler, [POST_ANSWER_CANCEL])
  )
  yield takeEvery(
    DELETE_QUESTION,
    cancelable(deleteQuestionHandler, [DELETE_QUESTION_CANCEL])
  )
  yield takeEvery(
    DELETE_ANSWER,
    cancelable(deleteAnswerHandler, [DELETE_ANSWER_CANCEL])
  )
  yield takeEvery(
    UPDATE_QUESTION,
    cancelable(updateQuestionHandler, [UPDATE_QUESTION_CANCEL])
  )
  yield takeEvery(
    UPDATE_ANSWER,
    cancelable(updateAnswerHandler, [UPDATE_ANSWER_CANCEL])
  )
  yield takeEvery(
    FETCH_NEW_QUESTIONS,
    cancelable(newQuestionsHandler, [FETCH_NEW_QUESTIONS_CANCEL])
  )

  yield takeEvery(
    RENEW_COMMUNITY_JWT_TOKEN,
    cancelable(renewCommunityTokenHandler, [RENEW_COMMUNITY_JWT_TOKEN_CANCEL])
  )

  yield takeEvery(
    FETCH_USER_QUESTIONS,
    cancelable(fetchUserQuestionsHandler, [FETCH_USER_QUESTIONS_CANCEL])
  )

  yield takeEvery(
    FETCH_USER_DETAILS,
    cancelable(fetchUserDetailsHandler, [FETCH_USER_DETAILS_CANCEL])
  )

  yield takeEvery(
    POST_QUESTION_UNDER_VIDEO,
    cancelable(postQuestionUnderVideoHandler, [
      POST_QUESTION_UNDER_VIDEO_CANCEL,
    ])
  )

  yield takeEvery(
    FETCH_VIDEO_QUESTIONS_IDS,
    cancelable(fetchVideoQuestionIdsHandler, [FETCH_VIDEO_QUESTIONS_IDS_CANCEL])
  )

  yield takeEvery(
    FETCH_PRE_ASKED_SIMILAR_QUESTIONS,
    cancelable(fetchPreAskedSimilarQuestionsHandler, [
      FETCH_PRE_ASKED_SIMILAR_QUESTIONS_CANCEL,
    ])
  )

  yield takeEvery(
    ADD_NEW_TOPIC_TO_QUESTION,
    cancelable(addNewTopicToQuestionHandler, [ADD_NEW_TOPIC_TO_QUESTION_CANCEL])
  )

  yield takeEvery(
    FETCH_TRENDING_QUESTIONS,
    cancelable(fetchTrendingQuestionsHandler, [FETCH_TRENDING_QUESTIONS_CANCEL])
  )

  yield takeEvery(
    FETCH_TRENDING_ARTICLES,
    cancelable(fetchTrendingArticlesHandler, [FETCH_TRENDING_ARTICLES_CANCEL])
  )
  yield takeEvery(
    FETCH_GROUP_FEEDS,
    cancelable(fetchGroupFeedsHandler, [FETCH_GROUP_FEEDS_CANCEL])
  )

  yield takeEvery(
    FETCH_GROUP_DETAILS,
    cancelable(fetchGroupDetailsHandler, [FETCH_GROUP_DETAILS_CANCEL])
  )

  yield takeEvery(
    FETCH_ALUMNI_GROUP_MEMBERS,
    cancelable(fetchAlumniGroupMembersHandler, [
      FETCH_ALUMNI_GROUP_MEMBERS_CANCEL,
    ])
  )
}

export default ([] as any).concat(communityMiddleware())
