import React, { useEffect, useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Paper,
  InputBase,
  IconButton,
  Box,
  Typography,
  ClickAwayListener,
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { CourseID } from 'common/types/courses'
import { ContentID, ContentTypes } from 'common/types/courses/content'
import { ReactComponent as CourseIcon } from 'common/images/courseIcon.svg'
import { mixpanel } from 'common/utils/mixpanel'
import { ProgramData } from 'common/types/programs'
import styles from './GlobalSearch.module.css'
import {
  debounce,
  apiCall,
  getUserDetails,
  generateURL,
} from '../../../common/utils'
import { ModuleItemIcon } from '../Utils/Icons/CustomIcons'
import Loader from '../Utils/Loader'
import { CoursesState } from '../../providers/Courses/CoursesProvider'

interface ResultItem {
  category: string
  context_id: CourseID
  id: ContentID
  content_id?: ContentID
  module_item_id?: ContentID
  title?: string
  name?: string
  url?: string
}
interface Props {
  enrolledCourses: CourseID[]
  programName: string | undefined
  coursesData: CoursesState['data']['byId']
  handleSearchToggle?: () => void
  activeProgramData: ProgramData | null
}
interface ResultProps {
  item: ResultItem
  contentType: ContentTypes
}

const GlobalSearch = (props: Props) => {
  const searchRef = useRef<HTMLInputElement>(null)
  const [searchValue, setSearchValue] = useState('')
  const [searchResults, updateSearchResults] = useState<any>({})
  const [showResultsBox, setShowResultsBox] = useState(false)
  const [showLoader, setShowLoader] = useState(false)

  const { id } = getUserDetails()
  const abortController = new AbortController()
  const handleSearchClick = () => {
    setShowResultsBox(true)
    setSearchValue(searchRef.current !== null ? searchRef.current!.value : '')
    mixpanel.track('Global Search', {})
  }

  const handleKeypress = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      handleSearchClick()
    }
    e.stopPropagation()
  }

  const getSearchResults = async (keyword: string) => {
    setShowLoader(true)
    const response = await apiCall({
      url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/search`,
      params: {
        signal: abortController.signal,
      },
      query: {
        student_id: id,
        keyword,
        course_ids: props.enrolledCourses,
      },
    })
    if (response.ok) {
      const results = await response.json()
      updateSearchResults(results)
      let resultsLength = 0
      if (Object.keys(results).length) {
        Object.keys(results).forEach((contentType: string) => {
          resultsLength += results[contentType].length
        })
      }
      mixpanel.track('Global Search results', {
        keyword,
        numberOfResults: resultsLength,
        courseIdsLength: props.enrolledCourses.length,
      })
    }
    setShowLoader(false)
  }

  useEffect(() => {
    if (searchValue.length >= 3) {
      getSearchResults(searchValue)
    } else {
      updateSearchResults({})
    }
  }, [searchValue])

  const ResultItem = (resultProp: ResultProps) => {
    const { item } = resultProp
    return (
      <NavLink
        to={
          item.url
            ? { pathname: item.url }
            : generateURL('CONTENT', {
                path: {
                  courseId: item.context_id,
                  contentId: item.content_id || item.id,
                  contentType: resultProp.contentType,
                },
                search: {
                  module_item_id: item.module_item_id,
                },
              })
        }
        target={item.url ? '_blank' : undefined}
        onClick={() =>
          mixpanel.track('GlobalSearchResultClick', {
            contentType: resultProp.contentType,
          })
        }
      >
        <Typography className={styles.title}>{item.title}</Typography>
        <Typography className={styles.courseTitle}>
          {props!.coursesData[item.context_id]!.course_name || ''}
        </Typography>
      </NavLink>
    )
  }

  const ResultBox = () =>
    Object.keys(searchResults).length ? (
      <>
        {Object.keys(searchResults).map((contentType: string) => {
          if (searchResults[contentType].length) {
            switch (contentType) {
              case 'Video':
                return (
                  <div>
                    <div className={styles.contentHeader}>
                      <ModuleItemIcon
                        circular
                        coloured
                        className={styles.moduleItemIcon}
                        type="Video"
                      />
                      <Typography className={styles.contentType}>
                        Videos
                      </Typography>
                    </div>

                    {searchResults[contentType].map((item: any) => {
                      return <ResultItem contentType="pages" item={item} />
                    })}
                  </div>
                )
              case 'WikiPage':
                return (
                  <div>
                    <div className={styles.contentHeader}>
                      <ModuleItemIcon
                        circular
                        coloured
                        className={styles.moduleItemIcon}
                        type="Page"
                      />
                      <Typography className={styles.contentType}>
                        Articles
                      </Typography>
                    </div>

                    {searchResults[contentType].map((item: any) => {
                      return <ResultItem contentType="pages" item={item} />
                    })}
                  </div>
                )
              case 'Attachment':
                return (
                  <div>
                    <div className={styles.contentHeader}>
                      <ModuleItemIcon
                        circular
                        coloured
                        className={styles.moduleItemIcon}
                        type="File"
                      />
                      <Typography className={styles.contentType}>
                        Files
                      </Typography>
                    </div>
                    {searchResults[contentType].map((item: any) => {
                      return <ResultItem contentType="files" item={item} />
                    })}
                  </div>
                )
              case 'Assignment':
                return (
                  <div>
                    <div className={styles.contentHeader}>
                      <ModuleItemIcon
                        circular
                        coloured
                        className={styles.moduleItemIcon}
                        type="Assignment"
                      />
                      <Typography className={styles.contentType}>
                        Assignments
                      </Typography>
                    </div>
                    {searchResults[contentType].map((item: any) => {
                      return (
                        <ResultItem contentType="assignments" item={item} />
                      )
                    })}
                  </div>
                )
              case 'Quizzes::Quiz':
                return (
                  <div>
                    <div className={styles.contentHeader}>
                      <ModuleItemIcon
                        circular
                        coloured
                        className={styles.moduleItemIcon}
                        type="Quiz"
                      />
                      <Typography className={styles.contentType}>
                        {props.activeProgramData
                          ? props.activeProgramData.text_mapping.quizzes
                          : 'Quizzes'}
                      </Typography>
                    </div>
                    {searchResults[contentType].map((item: any) => {
                      return <ResultItem contentType="quizzes" item={item} />
                    })}
                  </div>
                )
              case 'DiscussionTopic':
                return (
                  <div>
                    <div className={styles.contentHeader}>
                      <ModuleItemIcon
                        circular
                        coloured
                        className={styles.moduleItemIcon}
                        type="Discussion"
                      />
                      <Typography className={styles.contentType}>
                        Discussions Topics
                      </Typography>
                    </div>
                    {searchResults[contentType].map((item: any) => {
                      return (
                        <ResultItem
                          contentType="discussion_topics"
                          item={item}
                        />
                      )
                    })}
                  </div>
                )
              case 'Course':
                return (
                  <div>
                    <div className={styles.contentHeader}>
                      <CourseIcon className={styles.courseIcon} />
                      <Typography className={styles.contentType}>
                        Courses
                      </Typography>
                    </div>
                    {searchResults[contentType].map((item: any) => {
                      return (
                        <NavLink
                          to={generateURL('COURSE', {
                            path: {
                              courseId: item.id,
                            },
                          })}
                          onClick={() =>
                            mixpanel.track('GlobalSearchResultClick', {
                              contentType: 'Course',
                            })
                          }
                        >
                          <Typography key={item.id} className={styles.title}>
                            {item.name}
                          </Typography>
                        </NavLink>
                      )
                    })}
                  </div>
                )
              case 'ExternalUrl':
                return (
                  <div>
                    <div className={styles.contentHeader}>
                      <ModuleItemIcon
                        circular
                        coloured
                        className={styles.moduleItemIcon}
                        type="ExternalUrl"
                      />
                      <Typography className={styles.contentType}>
                        External Links
                      </Typography>
                    </div>
                    {searchResults[contentType].map((item: any) => {
                      return <ResultItem contentType="pages" item={item} />
                    })}
                  </div>
                )
              default:
                return null
            }
          }
        })}
      </>
    ) : (
      <Typography>No Results Found</Typography>
    )

  const renderSearchResults = () => (
    <Box
      className={styles.resultBox}
      onClick={() => {
        setShowResultsBox(false)
        if (searchRef.current !== null) {
          searchRef.current!.value = ''
        }
        if (props.handleSearchToggle) {
          props.handleSearchToggle()
        }
        mixpanel.track('Global Search Result', {})
      }}
    >
      {showLoader ? <Loader /> : <ResultBox />}
    </Box>
  )

  return (
    <ClickAwayListener onClickAway={() => setShowResultsBox(false)}>
      <div className={styles.topContainer}>
        <Paper component="div" elevation={0} className={styles.searchBox}>
          <IconButton aria-label="search" className={styles.searchIcon}>
            <Search />
          </IconButton>
          <InputBase
            placeholder="Search any topic"
            inputProps={{ 'aria-label': 'search' }}
            inputRef={searchRef}
            fullWidth
            onChange={debounce(handleSearchClick, 500)}
            onFocus={handleSearchClick}
            onKeyDown={handleKeypress}
          />
        </Paper>
        {showResultsBox && searchValue.length >= 3 ? (
          <div className={styles.resultsContainer}>{renderSearchResults()}</div>
        ) : null}
      </div>
    </ClickAwayListener>
  )
}

export default GlobalSearch
