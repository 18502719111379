import { ProgramsBanners } from 'common/types/programs'
import {
  FETCH_PROGRAMS_BANNERS,
  FETCH_PROGRAMS_BANNERS_SUCCESS,
  FETCH_PROGRAMS_BANNERS_FAILURE,
  FETCH_PROGRAMS_BANNERS_CANCEL,
} from './ProgramBanners.types'

export const fetchProgramsBanners = () => ({
  type: FETCH_PROGRAMS_BANNERS as typeof FETCH_PROGRAMS_BANNERS,
})

export const fetchProgramsBannersSuccess = (payload: ProgramsBanners) => ({
  type: FETCH_PROGRAMS_BANNERS_SUCCESS as typeof FETCH_PROGRAMS_BANNERS_SUCCESS,
  payload,
})

export const fetchProgramsBannersFailure = (payload: Error) => ({
  type: FETCH_PROGRAMS_BANNERS_FAILURE as typeof FETCH_PROGRAMS_BANNERS_FAILURE,
  payload,
})

export const fetchProgramsBannersCancel = () => ({
  type: FETCH_PROGRAMS_BANNERS_CANCEL as typeof FETCH_PROGRAMS_BANNERS_CANCEL,
})

export type ProgramBannersActions =
  | ReturnType<typeof fetchProgramsBanners>
  | ReturnType<typeof fetchProgramsBannersSuccess>
  | ReturnType<typeof fetchProgramsBannersFailure>
  | ReturnType<typeof fetchProgramsBannersCancel>
