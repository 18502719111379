import {
  ItemID,
  ModuleItemData,
} from '../../../../common/types/courses/moduleItem'
import { AppState } from '../../../store'

export const getItemData = <T extends ModuleItemData>(itemId: ItemID) => (
  state: AppState
): T | null => {
  const itemObj = state.moduleItems.data.byId[itemId]
  if (itemObj) {
    return itemObj as T
  }
  return null
}

export const getItemActivity = <T extends ModuleItemData>(itemId: ItemID) => (
  state: AppState
): T['itemActivity'] | null => {
  const itemData = getItemData<T>(itemId)(state)
  if (itemData && 'itemActivity' in itemData) {
    return itemData.itemActivity
  }
  return null
}

export function getItemDataAndActivity(moduleItemId: string, state: AppState) {
  return {
    itemData: state.moduleItems.data.byId[Number(moduleItemId)],
    itemActivity:
      state.moduleItems.data.byId[Number(moduleItemId)]?.itemActivity,
  }
}
