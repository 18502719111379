import { UserID } from 'common/types/user'
import { cancelable } from 'common/utils'
import { mixpanel } from 'common/utils/mixpanel'
import {
  call,
  cancelled,
  put,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects'
import { showAlertMessage } from 'web/providers/AlertsProvider'
import { AppState } from 'web/store'
import { updatePlainTextMessages } from '../Internal'
import { fetchAllPolls } from '../Polls/Polls.actions'
import {
  fetchAttendanceFailure,
  fetchAttendanceSuccess,
  fetchMeetingDetails,
  fetchMeetingDetailsAPIParams,
  fetchMeetingDetailsFailure,
  fetchMeetingDetailsSuccess,
  fetchUnknownPeerDetails,
  fetchUnknownPeerDetailsAPIParams,
  fetchUnknownPeerDetailsSuccess,
  savePlainTextMessage,
  savePlainTextMessageFailure,
  savePlainTextMessageSuccess,
  fetchAttendance,
} from './Zoomout.actions'
import {
  fetchMeetingDetailsAPI,
  fetchUnknownPeerDetailsAPI,
  savePlainTextMessageAPI,
  fetchAttendanceAPI,
} from './Zoomout.api'
import {
  FETCH_ATTENDANCE,
  FETCH_ATTENDANCE_CANCEL,
  FETCH_MEETING_DETAILS,
  FETCH_MEETING_DETAILS_CANCEL,
  FETCH_UNKNOWN_PEER_DETAILS,
  FETCH_UNKNOWN_PEER_DETAILS_CANCEL,
  SAVE_PLAIN_TEXT_MESSAGE,
  SAVE_PLAIN_TEXT_MESSAGE_CANCEL,
} from './Zoomout.types'
import { fetchQaEntriesSuccess } from '../QA'

export function* fetchMeetingDetailsHandler(
  action: ReturnType<typeof fetchMeetingDetails>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )

    const { channel, authBypassToken } = action.payload

    if (!userId) throw new Error('User ID Unavailable')
    if (!channel) throw new Error('Meeting ID is Invalid')

    const params: fetchMeetingDetailsAPIParams = {
      channel,
      authBypassToken,
    }

    const data = yield call(
      fetchMeetingDetailsAPI,
      params,
      userId,
      abortController.signal
    )
    if (data.data && data.data.chats)
      yield put(updatePlainTextMessages({ messages: data.data.chats }))

    yield put(fetchMeetingDetailsSuccess(data, action.payload))
    yield put(fetchAllPolls())
    yield put(fetchQaEntriesSuccess({ questions: data.data.questions }))
  } catch (error) {
    yield put(fetchMeetingDetailsFailure(error, action.payload))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fetchUnknownPeerDetailsHandler(
  action: ReturnType<typeof fetchUnknownPeerDetails>
) {
  const abortController = new AbortController()
  const { unknownPeerUID } = action.payload
  try {
    const channel: string = yield select(
      (state: AppState) => (state.zoomout.external.joiningDetails || {}).channel
    )

    const selfUserId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )

    if (!unknownPeerUID) throw new Error(`Peer Doesn't have a UID`)
    if (!channel) throw new Error('Meeting ID is Invalid')

    const params: fetchUnknownPeerDetailsAPIParams = {
      unknownPeerUID,
    }

    const data: any = yield call(
      fetchUnknownPeerDetailsAPI,
      params,
      channel,
      selfUserId,
      abortController.signal
    )
    yield put(fetchUnknownPeerDetailsSuccess(data, action.payload))
  } catch (error) {
    mixpanel.track('Zoomout Errors', {
      feature: 'Fetch Unknown Peer Details',
      code: error.code,
      message: error.message,
    })
    yield put(
      showAlertMessage({
        variant: 'error',
        message: `Cannot identify peer with id: ${unknownPeerUID}`,
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* savePlainTextMessageHandler(
  action: ReturnType<typeof savePlainTextMessage>
) {
  const abortController = new AbortController()

  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    const mentoredGroupSessionId: number = yield select(
      (state: AppState) => state.zoomout.external.mentoredGroupSessionId
    )
    const channel: string = yield select(
      (state: AppState) => (state.zoomout.external.joiningDetails || {}).channel
    )
    if (!mentoredGroupSessionId || !channel)
      throw new Error('Meeting ID is Invalid')

    const data = yield call(
      savePlainTextMessageAPI,
      action.payload,
      channel,
      mentoredGroupSessionId,
      userId,
      abortController.signal
    )

    yield put(savePlainTextMessageSuccess(data))
  } catch (error) {
    yield put(savePlainTextMessageFailure(error))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fetchAttendanceHandler(
  action: ReturnType<typeof fetchAttendance>
) {
  const abortController = new AbortController()

  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    const mentoredGroupSessionId: number = yield select(
      (state: AppState) => state.zoomout.external.mentoredGroupSessionId
    )
    const selfUid: string = yield select(
      (state: AppState) => state.zoomout.external.joiningDetails!.main.uid
    )
    if (!mentoredGroupSessionId || !selfUid)
      throw new Error('Meeting ID is Invalid')

    const data = yield call(
      fetchAttendanceAPI,
      selfUid,
      mentoredGroupSessionId,
      userId,
      abortController.signal
    )

    yield put(fetchAttendanceSuccess(data))
  } catch (error) {
    yield put(fetchAttendanceFailure(error))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* ZoomoutExternalMiddleware() {
  yield takeLeading(
    FETCH_MEETING_DETAILS,
    cancelable(fetchMeetingDetailsHandler, FETCH_MEETING_DETAILS_CANCEL)
  )
  yield takeEvery(
    FETCH_UNKNOWN_PEER_DETAILS,
    cancelable(
      fetchUnknownPeerDetailsHandler,
      FETCH_UNKNOWN_PEER_DETAILS_CANCEL
    )
  )
  yield takeEvery(
    SAVE_PLAIN_TEXT_MESSAGE,
    cancelable(savePlainTextMessageHandler, SAVE_PLAIN_TEXT_MESSAGE_CANCEL)
  )
  yield takeEvery(
    FETCH_ATTENDANCE,
    cancelable(fetchAttendanceHandler, FETCH_ATTENDANCE_CANCEL)
  )
}

export default ([] as any).concat(ZoomoutExternalMiddleware())
