import React from 'react'
import { connect } from 'react-redux'
import { programSelectors } from 'web/providers/Dashboard/ProgramsProvider'
import { ProgramData } from 'common/types/programs'
import ProfileMenuComponent from '../../../components/Header/ProfileMenu'
import {
  fetchUserProfileDetails,
  UserDetailsState,
  logoutUser,
} from '../../../providers/User/UserDetailsProvider'
import { AppState } from '../../../store'
import { DashboardData } from '../../../../common/types/dashboard'

interface DispatchProps {
  userDetails: UserDetailsState
  logoutUser: typeof logoutUser
}

interface StateProps {
  userDetails: UserDetailsState
  dashboardData: DashboardData | undefined
  programData: ProgramData | null
}

type Props = StateProps & DispatchProps

const ProfileMenu = (props: Props) => {
  if (!props.userDetails.profile || !props.userDetails.profile.data) {
    return null
  }

  return (
    <ProfileMenuComponent
      dashboardData={props.dashboardData}
      userProfileDetails={props.userDetails.profile.data}
      userAccessType={props.userDetails.accessType}
      onLogout={props.logoutUser}
      programData={props.programData}
    />
  )
}

const mapStateToProps = (state: AppState): StateProps => ({
  dashboardData: state.dashboard.data,
  userDetails: state.user.details,
  programData: programSelectors.getActiveProgramDetails()(state),
})

export default connect(mapStateToProps, {
  fetchUserProfileDetails,
  logoutUser,
})(ProfileMenu)
