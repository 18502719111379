import { GLAUserCareerPathDetails } from 'common/types/careerPath'
import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import {
  fetchCareerPathContent,
  fetchCareerPathContentFailure,
  fetchCareerPathContentSuccess,
} from './CareerPath.actions'
import * as APIs from './CareerPath.api'
import * as types from './CareerPath.types'

function* fetchCareerPathContentHandler(
  action: ReturnType<typeof fetchCareerPathContent>
) {
  const abortController = new AbortController()
  try {
    const data: GLAUserCareerPathDetails = yield call(
      APIs.fetchCareerPathContentAPI,
      action,
      abortController.signal
    )
    yield put(fetchCareerPathContentSuccess(data))
  } catch (e) {
    yield put(fetchCareerPathContentFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchCareerPathContentMiddleware() {
  yield takeLatest(
    types.FETCH_CAREER_PATH_CONTENT,
    cancelable(
      fetchCareerPathContentHandler,
      types.FETCH_CAREER_PATH_CONTENT_CANCEL
    )
  )
}
export default ([] as any).concat(fetchCareerPathContentMiddleware())
