import { Reducer } from 'redux'
import { GlLabsActionTypes } from './GlLabs.action'
import * as types from './GlLabs.types'

export interface GlLabsState {
  data: {
    credentials: {
      azure_user_principal_name: string
      password: string
      title: string
    }
  }
  loading: boolean
  error: false | Error | Response
}

const initialStateModules: GlLabsState = {
  data: {
    credentials: {
      azure_user_principal_name: '',
      password: '',
      title: '',
    },
  },
  loading: false,
  error: false,
}

export const GlLabsReducer: Reducer<GlLabsState, GlLabsActionTypes> = (
  state = initialStateModules,
  action
): GlLabsState => {
  switch (action.type) {
    case types.GET_GL_LAB_ASSIGNMENT_AND_SUBMISSION_DATA:
      return {
        ...state,
        loading: true,
      }

    case types.GET_GL_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case types.GET_GL_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_CANCEL:
      return {
        ...state,
        loading: false,
      }

    case types.GET_GL_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          credentials: action.payload,
        },
        loading: false,
        error: false,
      }
    }

    default:
      return state
  }
}
