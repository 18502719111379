import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { fetchCourseList } from 'web/providers/Courses/CoursesProvider'
import { showGLLoader } from 'web/providers/Common/Common.actions'
import {
  apiCall,
  cancelable,
  getUserDetails,
  isActiveBatchGLAorFSL,
} from '../../../../common/utils'
import {
  fetchActivityContent,
  fetchActivityContentSuccess,
} from '../ActivityProvider'
import {
  fetchProgramContent,
  fetchProgramContentSuccess,
} from '../ProgramsProvider'
import {
  fetchDashboardContentFailure,
  fetchDashboardContentSuccess,
} from './Dashboard.actions'
import {
  FETCH_DASHBOARD_CONTENT,
  FETCH_SCHOOL_DASHBOARD_CONTENT,
  FETCH_DASHBOARD_CONTENT_CANCEL,
  FETCH_SCHOOL_DASHBOARD_CONTENT_CANCEL,
} from './Dashboard.types'
import { updateUserDetails } from '../../User/UserDetailsProvider'

export async function getDashboardContentAPI(signal: AbortSignal) {
  const { id } = getUserDetails()

  // required to distinguish web app
  const query: Record<string, any> = {
    source: 'react-webapp',
    include_completed_items: true,
    skip_career_paths: true,
  }

  if (isActiveBatchGLAorFSL()) {
    query.skip_courses = true
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/users/${id}/dashboard`,
    params: { signal },
    query,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getSchoolDashboardContentAPI(signal: AbortSignal) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${process.env.REACT_APP_ASPIRE_API_URL}v2/users/${id}/dashboard`,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

function* fetchDashboardContentHandler() {
  const abortController = new AbortController()
  try {
    yield put(fetchActivityContent())
    yield put(fetchProgramContent())
    if (isActiveBatchGLAorFSL()) {
      yield put(fetchCourseList({ popular_only: true }))
    }
    const dashboardData = yield call(
      getDashboardContentAPI,
      abortController.signal
    )
    yield put(
      updateUserDetails({
        accessType: dashboardData.user_access_type,
      })
    )
    yield put(fetchDashboardContentSuccess(dashboardData))
    yield put(fetchProgramContentSuccess(dashboardData))

    yield put(fetchActivityContentSuccess(dashboardData))
    yield put(showGLLoader({ show: false }))
  } catch (e) {
    yield put(fetchDashboardContentFailure(e))
    yield put(showGLLoader({ show: false }))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchSchoolDashboardContentHandler() {
  const abortController = new AbortController()
  try {
    const dashboardData = yield call(
      getSchoolDashboardContentAPI,
      abortController.signal
    )

    yield put(
      updateUserDetails({
        accessType: dashboardData.user_access_type,
      })
    )
    yield put(fetchDashboardContentSuccess(dashboardData))
    yield put(fetchActivityContentSuccess(dashboardData))
  } catch (e) {
    yield put(fetchDashboardContentFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fetchDashboardContentMiddleware() {
  yield takeLatest(
    FETCH_DASHBOARD_CONTENT,
    cancelable(fetchDashboardContentHandler, FETCH_DASHBOARD_CONTENT_CANCEL)
  )
}

export function* fetchSchoolDashboardContentMiddleware() {
  yield takeLatest(
    FETCH_SCHOOL_DASHBOARD_CONTENT,
    cancelable(fetchSchoolDashboardContentHandler, [
      FETCH_SCHOOL_DASHBOARD_CONTENT_CANCEL,
    ])
  )
}

export default ([] as any).concat(
  fetchDashboardContentMiddleware(),
  fetchSchoolDashboardContentMiddleware()
)
