import { apiCall } from 'common/utils'
import { updateOlympusLeadData } from './GlaNewOnboardingForm.actions'

export async function updateOlympusLeadDataAPI(
  action: ReturnType<typeof updateOlympusLeadData>,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/gla_new_onboarding_form_create_lead`,
    params: { method: 'POST', signal, body: JSON.stringify(action.payload) },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
