import { call, cancelled, put, takeLatest, select } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import { UserID } from 'common/types/user'
import { AppState } from 'web/store'
import {
  fetchNewCourseItemFailure,
  fetchNewCourseItemSuccess,
} from './NewCourseItem.actions'
import {
  FETCH_NEW_COURSE_ITEM,
  FETCH_NEW_COURSE_ITEM_CANCEL,
} from './NewCourseItem.types'
import { fetchNewCourseItemAPI } from './NewCourseItem.api'

function* newCourseItemHandler() {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )

    const data = yield call(
      fetchNewCourseItemAPI,
      userId,
      abortController.signal
    )
    yield put(fetchNewCourseItemSuccess(data))
  } catch (e) {
    const error = e instanceof Error ? e : new Error(e.message)
    yield put(fetchNewCourseItemFailure(error))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* newCourseItemMiddleware() {
  yield takeLatest(
    FETCH_NEW_COURSE_ITEM,
    cancelable(newCourseItemHandler, FETCH_NEW_COURSE_ITEM_CANCEL)
  )
}

export default ([] as any).concat(newCourseItemMiddleware())
