import { X } from 'lucide-react'
import React from 'react'
import { AppLogo } from 'web/components/AppLogo/AppLogo'
import {
  AppBar,
  Box,
  Drawer,
  DrawerProps,
  Toolbar,
  makeStyles,
} from '@material-ui/core'
import cx from 'classnames'
import { IconButton } from 'common/elements'
import { More } from './more'

const useDrawerStyles = makeStyles({
  container: {
    '& .MuiBackdrop-root': {
      background: 'rgba(26, 27, 30, 0.16)',
    },
  },
  appBar: {
    backgroundColor: 'white',
  },
})

export function DashboardDrawer(
  props: DrawerProps & { isAccessBlocked: boolean }
) {
  const styles = useDrawerStyles()
  return (
    <Drawer
      className={cx(styles.container)}
      PaperProps={{ elevation: 0, style: { width: 328 } }}
      {...props}
    >
      <AppBar elevation={0} className={cx(styles.appBar)} position="relative">
        <Toolbar style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Box
            display="flex"
            flex={1}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <AppLogo />
            <IconButton disableRipple onClick={props.onClose}>
              <X />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <More
        onMenuPress={props.onClose}
        isAccessBlocked={props.isAccessBlocked}
      />
    </Drawer>
  )
}
