import * as types from './Discussion.types'

import { DiscussionEntryViewAPIData } from './Discussion.api'
import { CourseID } from '../../../../../common/types/courses'
import {
  DiscussionID,
  DiscussionEntryID,
  DiscussionEntry,
} from '../../../../../common/types/courses/dq'
import { ItemID } from '../../../../../common/types/courses/moduleItem'

interface DiscussionEntriesMetaParams {
  courseId: CourseID
  discussionId: DiscussionID
  itemId: ItemID
}

type DiscussionEntryMetaParams = DiscussionEntriesMetaParams & {
  discussionEntryId: DiscussionEntryID
}

export const getDiscussionEntries = (
  payload: null,
  meta: DiscussionEntriesMetaParams
) => ({
  type: types.DISCUSSION_ENTRIES_FETCH as typeof types.DISCUSSION_ENTRIES_FETCH,
  payload,
  meta,
})

export const getDiscussionEntriesSuccess = (
  payload: DiscussionEntryViewAPIData,
  meta: DiscussionEntriesMetaParams
) => ({
  type: types.DISCUSSION_ENTRIES_FETCH_SUCCESS as typeof types.DISCUSSION_ENTRIES_FETCH_SUCCESS,
  payload,
  meta,
})

export const getDiscussionEntriesFailure = (
  payload: Error,
  meta: DiscussionEntriesMetaParams
) => ({
  type: types.DISCUSSION_ENTRIES_FETCH_FAILURE as typeof types.DISCUSSION_ENTRIES_FETCH_FAILURE,
  payload,
  meta,
})

interface DiscussionEntryPostMetaParams extends DiscussionEntriesMetaParams {
  parentId: DiscussionEntryID
  tempId: DiscussionEntryID
}

export const postDiscussionEntry = (
  payload: { message: string; attachment?: File },
  meta: DiscussionEntryPostMetaParams
) => ({
  type: types.DISCUSSION_ENTRY_POST as typeof types.DISCUSSION_ENTRY_POST,
  payload,
  meta,
})

export const postDiscussionEntrySuccess = (
  payload: DiscussionEntry,
  meta: DiscussionEntryPostMetaParams & { discussionEntryId: DiscussionEntryID }
) => ({
  type: types.DISCUSSION_ENTRY_POST_SUCCESS as typeof types.DISCUSSION_ENTRY_POST_SUCCESS,
  payload,
  meta,
})

export const postDiscussionEntryFailure = (
  payload: Error,
  meta: DiscussionEntryPostMetaParams
) => ({
  type: types.DISCUSSION_ENTRY_POST_FAILURE as typeof types.DISCUSSION_ENTRY_POST_FAILURE,
  payload,
  meta,
})

interface DiscussionEntryUpdateMetaParams extends DiscussionEntryMetaParams {
  tempId: DiscussionEntryID
}

export const updateDiscussionEntry = (
  payload: { message: string },
  meta: DiscussionEntryUpdateMetaParams
) => ({
  type: types.DISCUSSION_ENTRY_UPDATE as typeof types.DISCUSSION_ENTRY_UPDATE,
  payload,
  meta,
})

export const updateDiscussionEntrySuccess = (
  payload: DiscussionEntry,
  meta: DiscussionEntryUpdateMetaParams
) => ({
  type: types.DISCUSSION_ENTRY_UPDATE_SUCCESS as typeof types.DISCUSSION_ENTRY_UPDATE_SUCCESS,
  payload,
  meta,
})

export const updateDiscussionEntryFailure = (
  payload: Error,
  meta: DiscussionEntryUpdateMetaParams
) => ({
  type: types.DISCUSSION_ENTRY_UPDATE_FAILURE as typeof types.DISCUSSION_ENTRY_UPDATE_FAILURE,
  payload,
  meta,
})

export const updateDiscussionEntryRating = (
  payload: 1 | 0,
  meta: DiscussionEntryMetaParams
) => ({
  type: types.DISCUSSION_ENTRY_RATING_UPDATE as typeof types.DISCUSSION_ENTRY_RATING_UPDATE,
  payload,
  meta,
})

export const updateDiscussionEntryRatingSuccess = (
  payload: 0 | 1,
  meta: DiscussionEntryMetaParams
) => ({
  type: types.DISCUSSION_ENTRY_RATING_UPDATE_SUCCESS as typeof types.DISCUSSION_ENTRY_RATING_UPDATE_SUCCESS,
  payload,
  meta,
})

export const updateDiscussionEntryRatingFailure = (
  payload: 0 | 1,
  meta: DiscussionEntryMetaParams
) => ({
  type: types.DISCUSSION_ENTRY_RATING_UPDATE_FAILURE as typeof types.DISCUSSION_ENTRY_RATING_UPDATE_FAILURE,
  payload,
  meta,
})

export const markDiscussionEntryAsRead = (
  payload: null,
  meta: DiscussionEntryMetaParams
) => ({
  type: types.DISCUSSION_ENTRY_MARK_AS_READ as typeof types.DISCUSSION_ENTRY_MARK_AS_READ,
  payload,
  meta,
})

export const markDiscussionEntryAsReadSuccess = (
  payload: null,
  meta: DiscussionEntryMetaParams
) => ({
  type: types.DISCUSSION_ENTRY_MARK_AS_READ_SUCCESS as typeof types.DISCUSSION_ENTRY_MARK_AS_READ_SUCCESS,
  payload,
  meta,
})

export const markDiscussionEntryAsReadFailure = (
  payload: Error,
  meta: DiscussionEntryMetaParams
) => ({
  type: types.DISCUSSION_ENTRY_MARK_AS_READ_FAILURE as typeof types.DISCUSSION_ENTRY_MARK_AS_READ_FAILURE,
  payload,
  meta,
})

export type DiscussionActionTypes =
  | ReturnType<typeof getDiscussionEntries>
  | ReturnType<typeof getDiscussionEntriesSuccess>
  | ReturnType<typeof getDiscussionEntriesFailure>
  | ReturnType<typeof postDiscussionEntry>
  | ReturnType<typeof postDiscussionEntrySuccess>
  | ReturnType<typeof postDiscussionEntryFailure>
  | ReturnType<typeof updateDiscussionEntry>
  | ReturnType<typeof updateDiscussionEntrySuccess>
  | ReturnType<typeof updateDiscussionEntryFailure>
  | ReturnType<typeof updateDiscussionEntryRating>
  | ReturnType<typeof updateDiscussionEntryRatingFailure>
  | ReturnType<typeof updateDiscussionEntryRatingSuccess>
  | ReturnType<typeof markDiscussionEntryAsRead>
  | ReturnType<typeof markDiscussionEntryAsReadFailure>
  | ReturnType<typeof markDiscussionEntryAsReadSuccess>
