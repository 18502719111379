import { cancelable } from 'common/utils'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
  fetchCareerSchoolDetails,
  fetchCareerSchoolDetailsSuccess,
  fetchCareerSchoolDetailsFailure,
} from './CareerSchool.action'
import {
  fetchCareerSchoolDetailsAPI,
  updateCareerSchoolOnboardingAPI,
  updateCareerSchoolJobOnboardedAPI
} from './CareerSchool.api'
import {
  FETCH_CAREER_SCHOOL_DETAILS,
  FETCH_CAREER_SCHOOL_DETAILS_CANCEL,
  UPDATE_CAREER_SCHOOL_ONBOARDING,
  UPDATE_CAREER_SCHOOL_JOB_ONBOARDED
} from './CareerSchool.types'

function* fetchCareerSchoolDetailsMiddleware(
  action: ReturnType<typeof fetchCareerSchoolDetails>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      fetchCareerSchoolDetailsAPI,
      action,
      abortController.signal
    )
    yield put(fetchCareerSchoolDetailsSuccess(data))
  } catch (error) {
    yield put(fetchCareerSchoolDetailsFailure(error))
  }
}
function* updateCareerSchoolOnboardingMiddleware() {
  const abortController = new AbortController()
  try {
    yield call(updateCareerSchoolOnboardingAPI, abortController.signal)
  } catch {}
}

function* updateCareerSchoolJobOnboardingMiddleware() {
  const abortController = new AbortController()
  try {
    yield call(updateCareerSchoolJobOnboardedAPI, abortController.signal)
  } catch {}
}

export function* careerSchoolMiddleware() {
  yield takeEvery(
    FETCH_CAREER_SCHOOL_DETAILS,
    cancelable(
      fetchCareerSchoolDetailsMiddleware,
      FETCH_CAREER_SCHOOL_DETAILS_CANCEL
    )
  )
  yield takeEvery(
    UPDATE_CAREER_SCHOOL_ONBOARDING,
    updateCareerSchoolOnboardingMiddleware
  )
  yield takeEvery(
    UPDATE_CAREER_SCHOOL_JOB_ONBOARDED,
    updateCareerSchoolJobOnboardingMiddleware
  )
}
export default ([] as any).concat(careerSchoolMiddleware())
