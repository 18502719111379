import { apiCall, getUserDetails } from '../../../common/utils'
import {
  createOpenAiUserToken,
  deleteOpenAiUserToken,
  fetchOpenAiUserToken,
} from './OpenAiProxyProvider.actions'

export const createOpenAiUserTokenAPI = async (
  action: ReturnType<typeof createOpenAiUserToken>,
  signal: AbortSignal
) => {
  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/openai_token`,
    params: { signal, method: 'POST' },
  })

  if (response.ok) {
    return response.json()
  }

  throw response
}

export const deleteOpenAiUserTokenAPI = async (
  action: ReturnType<typeof deleteOpenAiUserToken>,
  signal: AbortSignal
) => {
  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/openai_token`,
    params: { signal, method: 'DELETE' },
  })

  if (response.ok) {
    return response.json()
  }

  throw response
}

export const fetchOpenAiUserTokenAPI = async (
  action: ReturnType<typeof fetchOpenAiUserToken>,
  signal: AbortSignal
) => {
  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/openai_token`,
    params: { signal },
  })

  if (response.ok) {
    return response.json()
  }

  throw response
}
