export const FETCH_EXCELERATE_TEST_DETAILS = 'FETCH_EXCELERATE_TEST_DETAILS'
export const FETCH_EXCELERATE_TEST_DETAILS_SUCCESS =
  'FETCH_EXCELERATE_ASSESSMENT_TEST_SUCCESS'
export const FETCH_EXCELERATE_TEST_DETAILS_FAILURE =
  'FETCH_EXCELERATE_TEST_DETAILS_FAILURE'
export const FETCH_EXCELERATE_TEST_DETAILS_CANCEL =
  'FETCH_EXCELERATE_TEST_DETAILS_CANCEL'

export const EXCELERATE_QUIZ_TEST_SUBMISSION = 'EXCELERATE_QUIZ_TEST_SUBMISSION'
export const EXCELERATE_QUIZ_TEST_SUBMISSION_SUCCESS =
  'FETCH_EXCELERATE_ASSESSMENT_TEST_SUCCESS'
export const EXCELERATE_QUIZ_TEST_SUBMISSION_FAILURE =
  'EXCELERATE_QUIZ_TEST_SUBMISSION_FAILURE'
export const EXCELERATE_QUIZ_TEST_SUBMISSION_CANCEL =
  'EXCELERATE_QUIZ_TEST_SUBMISSION_CANCEL'
