export const RUNTIMES_FETCH = 'RUNTIMES_FETCH'
export const RUNTIMES_FETCH_SUCCESS = 'RUNTIMES_FETCH_SUCCESS'
export const RUNTIMES_FETCH_FAILURE = 'RUNTIMES_FETCH_FAILURE'
export const RUNTIMES_FETCH_CANCEL = 'RUNTIMES_FETCH_CANCEL'

export const CODE_EXECUTE = 'CODE_EXECUTE'
export const CODE_EXECUTE_SUCCESS = 'CODE_EXECUTE_SUCCESS'
export const CODE_EXECUTE_FAILURE = 'CODE_EXECUTE_FAILURE'
export const CODE_EXECUTE_CANCEL = 'CODE_EXECUTE_CANCEL'

export const QUESTION_RUN_CODE = 'QUESTION_RUN_CODE'
export const QUESTION_RUN_CODE_SUCCESS = 'QUESTION_RUN_CODE_SUCCESS'
export const QUESTION_RUN_CODE_CANCEL = 'QUESTION_RUN_CODE_CANCEL'
export const QUESTION_RUN_CODE_FAILURE = 'QUESTION_RUN_CODE_FAILURE'

export const QUESTION_TEST_CODE = 'QUESTION_TEST_CODE'
export const QUESTION_TEST_CODE_SUCCESS = 'QUESTION_TEST_CODE_SUCCESS'
export const QUESTION_TEST_CODE_CANCEL = 'QUESTION_TEST_CODE_CANCEL'
export const QUESTION_TEST_CODE_FAILURE = 'CODE_EVAL_QUESTION_TEST_CODE_FAILURE'

export const QUESTION_TEST_SQL_CODE = 'QUESTION_TEST_SQL_CODE'
export const QUESTION_TEST_SQL_CODE_SUCCESS = 'QUESTION_TEST_SQL_CODE_SUCCESS'
export const QUESTION_TEST_SQL_CODE_CANCEL = 'QUESTION_TEST_SQL_CODE_CANCEL'
export const QUESTION_TEST_SQL_CODE_FAILURE = 'CODE_EVAL_QUESTION_TEST_SQL_CODE_FAILURE'

export const QUESTION_RUN_SQL_CODE = 'QUESTION_RUN_SQL_CODE'
export const QUESTION_RUN_SQL_CODE_SUCCESS = 'QUESTION_RUN_SQL_CODE_SUCCESS'
export const QUESTION_RUN_SQL_CODE_CANCEL = 'QUESTION_RUN_SQL_CODE_CANCEL'
export const QUESTION_RUN_SQL_CODE_FAILURE = 'QUESTION_RUN_SQL_CODE_FAILURE'

export const QUESTION_GET_AI_CODE_SUGGESTIONS =
  'QUESTION_GET_AI_CODE_SUGGESTIONS'
export const QUESTION_GET_AI_CODE_SUGGESTIONS_SUCCESS =
  'QUESTION_GET_AI_CODE_SUGGESTIONS_SUCCESS'
export const QUESTION_GET_AI_CODE_SUGGESTIONS_CANCEL =
  'QUESTION_GET_AI_CODE_SUGGESTIONS_CANCEL'
export const QUESTION_GET_AI_CODE_SUGGESTIONS_FAILURE =
  'QUESTION_GET_AI_CODE_SUGGESTIONS_FAILURE'

export const SQL_QUESTION_GET_AI_CODE_SUGGESTIONS =
  'SQL_QUESTION_GET_AI_CODE_SUGGESTIONS'
export const SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_SUCCESS =
  'SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_SUCCESS'
export const SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_CANCEL =
  'SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_CANCEL'
export const SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_FAILURE =
  'SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_FAILURE'

export const QUESTION_GET_AI_CODE_HINTS = 'QUESTION_GET_AI_CODE_HINTS'
export const QUESTION_GET_AI_CODE_HINTS_SUCCESS =
  'QUESTION_GET_AI_CODE_HINTS_SUCCESS'
export const QUESTION_GET_AI_CODE_HINTS_CANCEL =
  'QUESTION_GET_AI_CODE_HINTS_CANCEL'
export const QUESTION_GET_AI_CODE_HINTS_FAILURE =
  'QUESTION_GET_AI_CODE_HINTS_FAILURE'

export const UPDATE_QUESTION_STDIN = 'UPDATE_QUESTION_STDIN'
export const UPDATE_QUESTION_ARGS = 'UPDATE_QUESTION_ARGS'

export const LIST_PLAYGROUNDS = 'LIST_PLAYGROUNDS'
export const LIST_PLAYGROUNDS_SUCCESS = 'LIST_PLAYGROUNDS_SUCCESS'
export const LIST_PLAYGROUNDS_CANCEL = 'LIST_PLAYGROUNDS_CANCEL'
export const LIST_PLAYGROUNDS_FAILURE = 'LIST_PLAYGROUNDS_FAILURE'

export const CREATE_PLAYGROUND = 'CREATE_PLAYGROUND'
export const CREATE_PLAYGROUND_SUCCESS = 'CREATE_PLAYGROUND_SUCCESS'
export const CREATE_PLAYGROUND_CANCEL = 'CREATE_PLAYGROUND_CANCEL'
export const CREATE_PLAYGROUND_FAILURE = 'CREATE_PLAYGROUND_FAILURE'

export const GET_PLAYGROUND = 'GET_PLAYGROUND'
export const GET_PLAYGROUND_SUCCESS = 'GET_PLAYGROUND_SUCCESS'
export const GET_PLAYGROUND_CANCEL = 'GET_PLAYGROUND_CANCEL'
export const GET_PLAYGROUND_FAILURE = 'GET_PLAYGROUND_FAILURE'

export const UPDATE_PLAYGROUND = 'UPDATE_PLAYGROUND'
export const UPDATE_PLAYGROUND_SUCCESS = 'UPDATE_PLAYGROUND_SUCCESS'
export const UPDATE_PLAYGROUND_CANCEL = 'UPDATE_PLAYGROUND_CANCEL'
export const UPDATE_PLAYGROUND_FAILURE = 'UPDATE_PLAYGROUND_FAILURE'

export const DELETE_PLAYGROUND = 'DELETE_PLAYGROUND'
export const DELETE_PLAYGROUND_SUCCESS = 'DELETE_PLAYGROUND_SUCCESS'
export const DELETE_PLAYGROUND_CANCEL = 'DELETE_PLAYGROUND_CANCEL'
export const DELETE_PLAYGROUND_FAILURE = 'DELETE_PLAYGROUND_FAILURE'

export const RENAME_PLAYGROUND = 'RENAME_PLAYGROUND'
export const RENAME_PLAYGROUND_SUCCESS = 'RENAME_PLAYGROUND_SUCCESS'
export const RENAME_PLAYGROUND_CANCEL = 'RENAME_PLAYGROUND_CANCEL'
export const RENAME_PLAYGROUND_FAILURE = 'RENAME_PLAYGROUND_FAILURE'

export const UPDATE_PLAYGROUND_STDIN = 'UPDATE_PLAYGROUND_STDIN'
export const UPDATE_PLAYGROUND_ARGS = 'UPDATE_PLAYGROUND_ARGS'
