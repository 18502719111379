import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects'
import { history } from 'web/store/history'
import { AppState } from 'web/store'

import { ProgramData } from 'common/types/programs'
import {
  startExternalLabs,
  startExternalLabsSuccess,
  startExternalLabsFailure,
  stopExternalLabs,
  stopExternalLabsSuccess,
  stopExternalLabsFailure,
  pingExternalLabs,
  pingExternalLabsSuccess,
  pingExternalLabsFailure,
  launchExternalLabs,
  launchExternalLabsSuccess,
  launchExternalLabsFailure,
  validExternalLabs,
  validExternalLabsSuccess,
  validExternalLabsFailure,
} from './ExternalLabs.action'
import { cancelable, generateURL } from '../../../common/utils'
import {
  START_EXTERNAL_LABS,
  START_EXTERNAL_LABS_CANCEL,
  STOP_EXTERNAL_LABS,
  STOP_EXTERNAL_LABS_CANCEL,
  PING_EXTERNAL_LABS_STATUS,
  PING_EXTERNAL_LABS_STATUS_CANCEL,
  LAUNCH_EXTERNAL_LABS,
  LAUNCH_EXTERNAL_LABS_CANCEL,
  VALID_EXTERNAL_LABS,
  VALID_EXTERNAL_LABS_CANCEL,
} from './ExternalLabs.types'
import {
  startExternalLabAPI,
  stopExternalLabAPI,
  pingExternalLabsAPI,
  pingExternalLabAPI,
  validExternalLabsAPI,
} from './ExternalLabs.api'

function* getExternalLabsStatusHandler(
  action: ReturnType<typeof pingExternalLabs>
) {
  const abortController = new AbortController()
  try {
    const status = yield call(
      pingExternalLabsAPI,
      action,
      abortController.signal
    )
    yield put(pingExternalLabsSuccess(status))
  } catch (e) {
    yield put(pingExternalLabsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* startExternalLabsHandler(
  action: ReturnType<typeof startExternalLabs>
) {
  const abortController = new AbortController()
  try {
    const status = yield call(
      startExternalLabAPI,
      action,
      abortController.signal
    )

    yield put(startExternalLabsSuccess(status))
  } catch (e) {
    yield put(startExternalLabsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* stopExternalLabsHandler(action: ReturnType<typeof stopExternalLabs>) {
  const abortController = new AbortController()
  try {
    const status = yield call(
      stopExternalLabAPI,
      action,
      abortController.signal
    )
    yield put(stopExternalLabsSuccess(status))
  } catch (e) {
    yield put(stopExternalLabsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* launchExternalLabsHandler(
  action: ReturnType<typeof launchExternalLabs>
) {
  const abortController = new AbortController()
  try {
    const status = yield call(
      pingExternalLabAPI,
      action,
      abortController.signal
    )
    yield put(launchExternalLabsSuccess(status))
  } catch (e) {
    yield put(launchExternalLabsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* validExternalLabHandler(
  action: ReturnType<typeof validExternalLabs>
) {
  const abortController = new AbortController()
  try {
    const status = yield call(
      validExternalLabsAPI,
      action,
      abortController.signal
    )
    yield put(validExternalLabsSuccess(status))
  } catch (e) {
    yield put(validExternalLabsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* ExternalLabsMiddleware() {
  yield takeLatest(
    PING_EXTERNAL_LABS_STATUS,
    cancelable(getExternalLabsStatusHandler, PING_EXTERNAL_LABS_STATUS_CANCEL)
  )

  yield takeLatest(
    START_EXTERNAL_LABS,
    cancelable(startExternalLabsHandler, START_EXTERNAL_LABS_CANCEL)
  )

  yield takeLatest(
    STOP_EXTERNAL_LABS,
    cancelable(stopExternalLabsHandler, STOP_EXTERNAL_LABS_CANCEL)
  )

  yield takeLatest(
    LAUNCH_EXTERNAL_LABS,
    cancelable(launchExternalLabsHandler, LAUNCH_EXTERNAL_LABS_CANCEL)
  )

  yield takeLatest(
    VALID_EXTERNAL_LABS,
    cancelable(validExternalLabHandler, VALID_EXTERNAL_LABS_CANCEL)
  )
}
export default ([] as any).concat(ExternalLabsMiddleware())
