import {
  Avatar,
  Button,
  Link,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import cx from 'classnames'
import React, { Fragment, useCallback, useState } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { isTrialUserDashboardData } from 'common/types/dashboard'
import { ProgramData } from 'common/types/programs'
import routes from 'web/routes'
import { useDispatch } from 'react-redux'
import { showGLLoader } from 'web/providers/Common/Common.actions'
import {
  useIsNewUIEnabledForBatch,
  useReloadPath,
  useSwitchToNewUI,
} from 'web/hooks'
import {
  addParamsToUrl,
  generateURL,
  isProduction,
  isUserAcadOps,
} from '../../../../common/utils'
import { DashboardState } from '../../../providers/Dashboard/DashboardProvider'
import { UserDetailsState } from '../../../providers/User/UserDetailsProvider'
import SwitchToOldUiFeedback from '../../SwitchToOldUiFeedback/SwitchToOldUiFeedback'
import styles from './ProfileMenu.module.css'
import {
  isAcademyUser,
  isAcademyInternationalUser,
} from '../../../../common/utils/custom/user'

interface ProfileProps {
  onProfileClick: Function
  userProfileDetails: Exclude<UserDetailsState['profile'], undefined>['data']
  userAccessType: UserDetailsState['accessType']
  onLogout: Function
  dashboardData: DashboardState['data']
  programData: ProgramData | null
}

const ProfileMenu = (props: ProfileProps) => {
  const dispatch = useDispatch()
  const updateGLLoader = useCallback(data => dispatch(showGLLoader(data)), [
    dispatch,
  ])

  const { dashboardData } = props
  const featureLinks = dashboardData ? dashboardData.feature_links : null
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [switchToOldUi, setSwitchToOldUi] = useState<boolean>(false)
  const isNewUIEnabledForBatch = useIsNewUIEnabledForBatch()
  const { switchToNewUI } = useSwitchToNewUI()
  const reloadPath = useReloadPath()

  const isAcadOps = isUserAcadOps()
  function handleClick(event: React.MouseEvent<HTMLElement>) {
    props.onProfileClick()
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const userImage = () => {
    if (
      props.userProfileDetails &&
      'avatar_url' in props.userProfileDetails &&
      props.userProfileDetails.avatar_url !== null
    ) {
      return props.userProfileDetails.avatar_url
    }
    return undefined
  }

  const userName = () => {
    if (props.userProfileDetails && 'name' in props.userProfileDetails)
      return props.userProfileDetails.name
    return 'User'
  }

  const userEmail = () => {
    if (props.userProfileDetails) return props.userProfileDetails.primary_email
    return null
  }
  const history = useHistory()
  const handleEportFolioClick = () => {
    history.push('/eportfolio')
    handleClose()
  }

  const academyUser = isAcademyUser(props.userAccessType)
  const basicUser = props.userAccessType === 'basic'
  const academyInternationalUser = isAcademyInternationalUser(
    props.userAccessType
  )
  const disableDashboard = !!(
    props.programData && props.programData.disable_dashboard
  )
  const isCareerSchool = !!(
    props.programData && props.programData.program_type === 'career_track'
  )
  const showTestimonials =
    basicUser &&
    props.programData &&
    !props.programData.digital_campus_program &&
    !isCareerSchool &&
    !props.programData.disable_testimonials &&
    !props.programData.stanford_sdt_program

  const isCorporateUser =
    props.dashboardData &&
    'corporate_info' in props.dashboardData &&
    props.dashboardData.corporate_info &&
    props.dashboardData.corporate_info.company_logo_full_path
      ? {
          companyName: props.dashboardData.corporate_info.company_name,
          companyLogo:
            props.dashboardData.corporate_info.company_logo_full_path,
        }
      : false

  const trialUser: boolean = props.userAccessType === 'trial'
  const enrolledTrialUser: boolean =
    props.dashboardData &&
    isTrialUserDashboardData(props.dashboardData) &&
    props.dashboardData.trial_details
      ? props.dashboardData.trial_details.app_status === 'enrolled'
      : false

  const enabledFreemiumUsers =
    props.userAccessType === 'freemium' &&
    dashboardData &&
    (('program_fee_paid' in dashboardData && dashboardData.program_fee_paid) ||
      ('trial_remaining_days' in dashboardData &&
        dashboardData.trial_remaining_days > 0))

  return (
    <Fragment>
      <Button
        className={cx(
          styles.profileMenuButton,
          isCorporateUser ? styles.isCorporateUser : null
        )}
        data-testid="profile-menu-items"
        aria-owns={anchorEl ? 'profile-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {isCorporateUser ? (
          <img
            src={isCorporateUser.companyLogo}
            alt={isCorporateUser.companyName || 'Enterprise'}
            className={styles.companyLogo}
          />
        ) : null}
        <Avatar
          src={userImage()}
          className={styles.avatar}
          data-testid="profile-image"
        />
      </Button>
      <Menu
        id="profile-menu"
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <MenuItem className={styles.userInfo}>
          <Avatar
            src={userImage()}
            className={styles.avatar}
            data-testid="profile-image"
          />
          <div className={styles.userDetails}>
            <Typography align="inherit" variant="h5" data-testid="profile-name">
              {userName()}
            </Typography>
            <Typography
              align="inherit"
              variant="subtitle1"
              data-testid="profile-email"
            >
              {userEmail()}
            </Typography>
          </div>
        </MenuItem>
        {(academyUser || academyInternationalUser) && !isAcadOps ? (
          <div>
            <MenuItem
              component={RouterLink}
              to={"#"} 
              onClick={()=>{reloadPath('COURSES'); handleClose()}}
              className={styles.menuItem}
            >
              <Typography variant="body2" color="textPrimary">
                My Courses
              </Typography>
            </MenuItem>
          </div>
        ) : null}
        {featureLinks && !isAcadOps ? (
          <div>
            {featureLinks.pre_enrolment_path && !isCareerSchool ? (
              <MenuItem
                component={Link}
                href={featureLinks.pre_enrolment_path}
                target="_blank"
                rel="noreferrer"
                underline="none"
                className={styles.menuItem}
              >
                <Typography variant="body2" color="textPrimary">
                  Preparatory courses
                </Typography>
              </MenuItem>
            ) : null}
            {featureLinks.eportfolio && !trialUser && !isCareerSchool ? (
              <MenuItem onClick={handleEportFolioClick}>
                <Typography variant="body2" color="textPrimary">
                  My ePortfolio
                </Typography>
              </MenuItem>
            ) : null}
            {featureLinks.fee_payment && !trialUser && !isCareerSchool ? (
              <MenuItem
                component={RouterLink}
                to={generateURL('FEE_PAYMENT', null)}
                onClick={handleClose}
                className={styles.menuItem}
              >
                <Typography variant="body2" color="textPrimary">
                  Fee Payment
                </Typography>
              </MenuItem>
            ) : null}
            {featureLinks.certificate_transcript &&
            !trialUser &&
            (props.userAccessType === 'basic' || enabledFreemiumUsers) &&
            !isCareerSchool ? (
              <MenuItem
                component={Link}
                href={routes.PRIVATE_CERTIFICATE}
                rel="noreferrer"
                underline="none"
                className={styles.menuItem}
              >
                <Typography variant="body2" color="textPrimary">
                  Certificates and Transcripts
                </Typography>
              </MenuItem>
            ) : null}
            {featureLinks.grade_sheet && !trialUser && !isCareerSchool ? (
              <MenuItem
                component={Link}
                href={routes.PRIVATE_CERTIFICATE}
                rel="noreferrer"
                underline="none"
                className={styles.menuItem}
              >
                <Typography variant="body2" color="textPrimary">
                  Grade Sheet
                </Typography>
              </MenuItem>
            ) : null}
            {featureLinks.certificates_only && !trialUser && !isCareerSchool ? (
              <MenuItem
                component={Link}
                href={routes.PRIVATE_CERTIFICATE}
                rel="noreferrer"
                underline="none"
                className={styles.menuItem}
                data-testid="profile-menu-certificates"
              >
                <Typography variant="body2" color="textPrimary">
                  Certificates
                </Typography>
              </MenuItem>
            ) : null}
            {featureLinks.feed && !trialUser && !isCareerSchool ? (
              <MenuItem
                component={Link}
                href={featureLinks.feed}
                rel="noreferrer"
                underline="none"
                className={styles.menuItem}
              >
                <Typography variant="body2" color="textPrimary">
                  #mygreatlearning
                </Typography>
              </MenuItem>
            ) : null}
            {featureLinks.refer_earn &&
            (!trialUser || enrolledTrialUser) &&
            !isCareerSchool ? (
              <MenuItem
                component={Link}
                href={addParamsToUrl(featureLinks.refer_earn, {
                  s: 'lms-dropdown',
                })}
                rel="noreferrer"
                underline="none"
                className={styles.menuItem}
              >
                <Typography variant="body2" color="textPrimary">
                  Refer and Earn
                </Typography>
              </MenuItem>
            ) : null}
            {/* Removing Old LMS dashboard switch  */}
            {/* {featureLinks.switch_old_ui_path && !isCareerSchool ? (
              <MenuItem
                onClick={() => {
                  setSwitchToOldUi(true)
                  handleClose()
                }}
                className={cx(styles.menuItem, styles.oldUi)}
              >
                <Typography variant="body2" color="textPrimary">
                  Switch to old UI
                </Typography>
              </MenuItem>
            ) : null} */}
          </div>
        ) : null}
        {showTestimonials && !isAcadOps && (
          <MenuItem
            component={RouterLink}
            to={generateURL('TESTIMONIALS', null)}
            onClick={handleClose}
            className={styles.menuItem}
          >
            <Typography variant="body2" color="textPrimary">
              Share your Testimonial
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          component={RouterLink}
          to={generateURL('PROFILE_SETTINGS', null)}
          onClick={handleClose}
          className={styles.menuItem}
          data-testid="profile-menu-settings"
        >
          <Typography variant="body2" color="textPrimary">
            Settings
          </Typography>
        </MenuItem>
        {!isProduction() && (
          <>
            {isNewUIEnabledForBatch && (
              <MenuItem
                onClick={() => {
                  switchToNewUI()
                }}
                className={styles.menuItem}
                data-testid="profile-menu-switch-to-new-ui"
              >
                <Typography variant="body2">Switch to new ui</Typography>
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                updateGLLoader({ show: true })
                document.cookie = 'is_old_app_bar_preferred=false;path=/'
                reloadPath()
              }}
              className={styles.menuItem}
              data-testid="profile-menu-switch-to-new-app-bar"
            >
              <Typography variant="body2">Switch to new app bar</Typography>
            </MenuItem>
          </>
        )}
        {!disableDashboard ? (
          <MenuItem
            onClick={() => {
              props.onLogout()
              handleClose()
            }}
            className={styles.menuItem}
            data-testid="profile-menu-logout"
          >
            <Typography variant="body2">Logout</Typography>
          </MenuItem>
        ) : null}
      </Menu>
      {/* {switchToOldUi && dashboardData && (
        <SwitchToOldUiFeedback
          link={dashboardData.feature_links.switch_old_ui_path}
          onClose={() => setSwitchToOldUi(false)}
        />
      )} */}
    </Fragment>
  )
}

ProfileMenu.defaultProps = {
  onProfileClick: () => null,
}

export default ProfileMenu
