import { joinURL, apiCall } from '../../../../common/utils'
import { UserID } from '../../../../common/types/user'
import { EngagementEventPayload } from './UserDetails.actions'

export async function userLogout() {
  const response = await apiCall({
    url: joinURL(
      window.constants.REACT_APP_LMS_API_URL!,
      '/login/oauth2/token'
    ),
    params: { method: 'DELETE', credentials: 'omit' },
  })

  if (response.ok) {
    return
  }
  throw response
}

export async function userLTILogout({ userId }: { userId: UserID | null }) {
  return apiCall({
    url: joinURL(
      window.constants.REACT_APP_LTI_URL!,
      '/user_auth/invalidate_token'
    ),
    query: { user_id: userId },
    params: { method: 'POST' },
  })
}

export async function resendEmailLinkAPI({
  userId,
  signal,
}: {
  userId: UserID
  signal: AbortSignal
}) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/gl/users/${userId}/resend_confirmation`,
    params: { signal, method: 'POST' },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getCommunicationChannelDetailsAPI({
  userId,
  signal,
}: {
  userId: UserID
  signal: AbortSignal
}) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/users/${userId}/communication_channels`,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getUserProfileDetailsAPI({
  userId,
  signal,
  include_feature_detail,
  include_user_ui_pref,
}: {
  userId: UserID
  signal: AbortSignal
  include_feature_detail?: boolean
  include_user_ui_pref?: boolean
}) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/profile`,
    query: { include_feature_detail, include_user_ui_pref },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function sendPreEngagementEvent(payload: EngagementEventPayload) {
  const body = JSON.stringify(payload)
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/get_started/report_event`,
    params: { method: 'POST', body },
  })
  if (response.ok) {
    return 'Event sent successfully'
  }
  throw response
}
