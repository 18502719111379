import * as types from './Announcements.types'
import { CourseID } from '../../../common/types/courses'
import { GroupID } from '../../../common/types/user'
import {
  AnnouncementData,
  AnnouncementID,
} from '../../../common/types/announcements'

interface AnnouncementMetaParams {
  announcementId: AnnouncementID
}

interface AnnouncementOpenParams {
  groupId: GroupID | undefined
}

interface AnnouncementsFetchPayload {
  courseId?: CourseID
  page: number
}

interface GroupAnnouncementsFetchPayload {
  groupId: GroupID
  page: number
}

export interface FetchGroupIdMeta {
  groupId: GroupID
}

export const fetchAnnouncements = (payload: AnnouncementsFetchPayload) => ({
  type: types.ANNOUNCEMENTS_FETCH as typeof types.ANNOUNCEMENTS_FETCH,
  payload,
})

export const fetchAnnouncementsFailure = (payload: Error) => ({
  type: types.ANNOUNCEMENTS_FETCH_FAILURE as typeof types.ANNOUNCEMENTS_FETCH_FAILURE,
  payload,
})

export const fetchAnnouncementsSuccess = (payload: {
  data: AnnouncementData[]
  courseId?: CourseID
  hasMore: boolean
}) => ({
  type: types.ANNOUNCEMENTS_FETCH_SUCCESS as typeof types.ANNOUNCEMENTS_FETCH_SUCCESS,
  payload,
})

export const fetchAnnouncementsCancel = () => ({
  type: types.ANNOUNCEMENTS_FETCH_CANCEL as typeof types.ANNOUNCEMENTS_FETCH_CANCEL,
})

export const fetchGroupAnnouncements = (
  payload: GroupAnnouncementsFetchPayload
) => ({
  type: types.GROUP_ANNOUNCEMENTS_FETCH as typeof types.GROUP_ANNOUNCEMENTS_FETCH,
  payload,
})

export const fetchGroupAnnouncementsFailure = (payload: Error) => ({
  type: types.GROUP_ANNOUNCEMENTS_FETCH_FAILURE as typeof types.GROUP_ANNOUNCEMENTS_FETCH_FAILURE,
  payload,
})

export const fetchGroupAnnouncementsSuccess = (
  payload: {
    data: AnnouncementData[]
    groupId: GroupID
    hasMore: boolean
  },
  meta: FetchGroupIdMeta
) => ({
  type: types.GROUP_ANNOUNCEMENTS_FETCH_SUCCESS as typeof types.GROUP_ANNOUNCEMENTS_FETCH_SUCCESS,
  payload,
  meta,
})

export const fetchGroupAnnouncementsCancel = () => ({
  type: types.GROUP_ANNOUNCEMENTS_FETCH_CANCEL as typeof types.GROUP_ANNOUNCEMENTS_FETCH_CANCEL,
})

type AnnouncementMarkAsReadMetaParams =
  | {
      courseId: CourseID
    }
  | { groupId: GroupID }

export const markAnnouncementAsRead = (
  payload: null,
  meta: AnnouncementMarkAsReadMetaParams & AnnouncementMetaParams
) => ({
  type: types.ANNOUNCEMENT_MARK_AS_READ as typeof types.ANNOUNCEMENT_MARK_AS_READ,
  payload,
  meta,
})

export const markAnnouncementAsReadSuccess = (
  payload: null,
  meta: AnnouncementMetaParams
) => ({
  type: types.ANNOUNCEMENT_MARK_AS_READ_SUCCESS as typeof types.ANNOUNCEMENT_MARK_AS_READ_SUCCESS,
  payload,
  meta,
})

export const markAnnouncementAsReadFailure = (
  payload: null,
  meta: AnnouncementMetaParams
) => ({
  type: types.ANNOUNCEMENT_MARK_AS_READ_FAILURE as typeof types.ANNOUNCEMENT_MARK_AS_READ_FAILURE,
  payload,
  meta,
})

export const announcementOpen = (
  payload: AnnouncementMetaParams & AnnouncementOpenParams
) => ({
  type: types.ANNOUNCEMENT_OPEN as typeof types.ANNOUNCEMENT_OPEN,
  payload,
})

export type AnnouncementActions =
  | ReturnType<typeof fetchAnnouncements>
  | ReturnType<typeof fetchAnnouncementsSuccess>
  | ReturnType<typeof fetchAnnouncementsFailure>
  | ReturnType<typeof fetchAnnouncementsCancel>
  | ReturnType<typeof fetchGroupAnnouncements>
  | ReturnType<typeof fetchGroupAnnouncementsSuccess>
  | ReturnType<typeof fetchGroupAnnouncementsFailure>
  | ReturnType<typeof fetchGroupAnnouncementsCancel>
  | ReturnType<typeof markAnnouncementAsRead>
  | ReturnType<typeof markAnnouncementAsReadSuccess>
  | ReturnType<typeof markAnnouncementAsReadFailure>
  | ReturnType<typeof announcementOpen>
