import { UserID } from 'common/types/user'
import { apiCall } from 'common/utils'
import { fetchLearnersEngagementScoreParams } from './Engagement.actions'

export const ZOOMOUT_API_ENDPOINT = `${process.env.REACT_APP_LTI_API_URL}v1/sessions`

export const fetchLearnersEngagementScoreAPI = async (
  params: fetchLearnersEngagementScoreParams,
  lmsUserId: UserID,
  signal: AbortSignal
) => {
  const { mentoredGroupSessionId } = params
  const response = await apiCall({
    url: `${ZOOMOUT_API_ENDPOINT}/${mentoredGroupSessionId}/engagement_details`,
    query: { user_id: lmsUserId },
    params: {
      signal,
      method: 'GET',
    },
    excludeProgramId: true,
  })
  if (response.status === 200) return response.json()
  throw await response.json()
}
