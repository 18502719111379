import { createMuiTheme } from '@material-ui/core/styles'
import theme from '../styles/theme'

export default createMuiTheme({
  ...theme,
  typography: {
    fontFamily: 'Inter',
    h1: { fontSize: '2.125rem', fontWeight: 600 },
    h2: { fontSize: '1.75rem', fontWeight: 400 },
    h3: { fontSize: '1.5rem', fontWeight: 500, lineHeight: 1.2 },
    h4: { fontSize: '1.25rem', fontWeight: 600 },
    h5: { fontSize: '1rem', fontWeight: 700 },
    h6: { fontSize: '1rem', fontWeight: 600 },
    subtitle1: { fontSize: '1rem', fontWeight: 500 },
    subtitle2: { fontSize: '0.875rem', fontWeight: 500 },
    body1: { fontSize: '1rem', fontWeight: 400 },
    body2: { fontSize: '0.875rem', fontWeight: 400 },
    button: { fontSize: '0.875rem', fontWeight: 600 },
    caption: { fontSize: '0.875rem', fontWeight: 400 },
    overline: { fontSize: '1.1875rem', fontWeight: 400 },
  },
  overrides: {
    MuiBadge: {
      badge: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.text.secondary,
      },
    },
    MuiButton: {
      root: {
        padding: '6px 24px',
      },
      text: {
        padding: '6px 24px',
      },
    },
    MuiTouchRipple: {
      child: { backgroundColor: 'var(--grey-70)' },
    },
    MuiGridListTile: {
      tile: {
        width: '40%',
        padding: 0,
      },
    },
    MuiSnackbar: {
      anchorOriginTopRight: {
        '@media (min-width: 600px)': {
          top: 'calc(var(--header-height) + 24px)',
        },
      },
      anchorOriginTopCenter: {
        '@media (min-width: 600px)': {
          top: 'calc(var(--header-height))',
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: 'var(--white)',
      },
    },
    MuiGrid: {
      item: {
        flexDirection: 'row',
      },
    },
    MuiCard: {
      root: {
        height: '100%',
      },
    },
    MuiBreadcrumbs: {
      separator: {
        color: theme.palette.text.primary,
      },
    },
    MuiSelect: {
      select: {
        color: theme.palette.text.primary,
      },
    },
    MuiRadio: {
      root: {
        color: 'var(--grey-40)',
      },
    },
    MuiCheckbox: {
      root: {
        color: 'var(--grey-40)',
      },
    },
    MuiInputLabel: {
      root: {
        color: theme.palette.text.primary,
      },
    },
    MuiAvatar: {
      root: {
        border: '1px solid var(--grey-20)',
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        maxHeight: 'calc(100% - (var(--header-height) + var(--footer-height)))',
      },
    },
    MuiTableCell: {
      head: {
        color: 'var(--black)',
        fontSize: '0.875rem',
        fontWeight: 600,
      },
    },
  },
})
