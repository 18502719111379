import { Reducer } from 'redux'
import { SHOW_OFFLINE_POPUP, REMOVE_OFFLINE_POPUP } from './NetworkDetector.types'
import { NetworkDetectorActionTypes } from './NetworkDetector.actions'

export interface NetworkState {
  isOffline: boolean | undefined
}

const intialState: NetworkState = {
  isOffline: undefined,
}

const networkDetectorReducer: Reducer<NetworkState, NetworkDetectorActionTypes> = (
  state = intialState,
  action
): NetworkState => {
  switch (action.type) {
    case SHOW_OFFLINE_POPUP:
      return {
        ...state,
        isOffline: true,
      }

    case REMOVE_OFFLINE_POPUP:
      return {
        ...state,
        isOffline: false,
      }

    default:
      return state
  }
}
export default networkDetectorReducer
