export const ANNOUNCEMENTS_FETCH = 'ANNOUNCEMENTS_FETCH'
export const ANNOUNCEMENTS_FETCH_FAILURE = 'ANNOUNCEMENTS_FETCH_FAILURE'
export const ANNOUNCEMENTS_FETCH_SUCCESS = 'ANNOUNCEMENTS_FETCH_SUCCESS'
export const ANNOUNCEMENTS_FETCH_CANCEL = 'ANNOUNCEMENTS_FETCH_CANCEL'

export const GROUP_ANNOUNCEMENTS_FETCH = 'GROUP_ANNOUNCEMENTS_FETCH'
export const GROUP_ANNOUNCEMENTS_FETCH_FAILURE = 'GROUP_ANNOUNCEMENTS_FETCH_FAILURE'
export const GROUP_ANNOUNCEMENTS_FETCH_SUCCESS = 'GROUP_ANNOUNCEMENTS_FETCH_SUCCESS'
export const GROUP_ANNOUNCEMENTS_FETCH_CANCEL = 'GROUP_ANNOUNCEMENTS_FETCH_CANCEL'

export const ANNOUNCEMENT_MARK_AS_READ = 'ANNOUNCEMENT_MARK_AS_READ'
export const ANNOUNCEMENT_MARK_AS_READ_SUCCESS =
  'ANNOUNCEMENT_MARK_AS_READ_SUCCESS'
export const ANNOUNCEMENT_MARK_AS_READ_FAILURE =
  'ANNOUNCEMENT_MARK_AS_READ_FAILURE'

export const ANNOUNCEMENT_OPEN = 'ANNOUNCEMENT_OPEN'
