import { Reducer } from 'redux'
import { ProgramActions } from 'web/providers/Dashboard/ProgramsProvider/Programs.actions'
import { UPDATE_ACTIVE_PROGRAM } from 'web/providers/Dashboard/ProgramsProvider'
import {
  FETCH_TRANSACTION_STATUS,
  USER_TRANSACTION_STATUS_SUCCESS,
  USER_TRANSACTION_STATUS_FAILURE,
} from './PaymentStatus.types'
import { PaymentStatusActions } from './PaymentStatus.actions'

import { TransactionStatusData } from '../../../../common/types/user'

export interface PaymentStatusState {
  transactionStatusData?: TransactionStatusData
  loading: boolean
  error: false | Error | Response
}
const initialState: PaymentStatusState = {
  loading: false,
  error: false,
}
const paymentsStatusReducer: Reducer<
  PaymentStatusState,
  | PaymentStatusActions
  | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
> = (state = initialState, action): PaymentStatusState => {
  switch (action.type) {
    case FETCH_TRANSACTION_STATUS:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case USER_TRANSACTION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        transactionStatusData: action.payload,
      }
    case USER_TRANSACTION_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UPDATE_ACTIVE_PROGRAM:
      return initialState
    default:
      return state
  }
}
export default paymentsStatusReducer
