import { apiCall, getUserDetails } from '../../../../common/utils'
import { fetchCareerPathContent } from './CareerPath.actions'

export async function fetchCareerPathContentAPI(
  action: ReturnType<typeof fetchCareerPathContent>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/career_paths`,
    params: { signal },
    query: { user_id: id },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
