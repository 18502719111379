import { Reducer } from 'redux'
import RBILogo from 'common/images/RBI-Logo.png'
import { isGlcaBatch } from 'common/utils/custom/user'
import { ProgramActions } from '.'
import {
  FETCH_PROGRAM_CONTENT,
  FETCH_PROGRAM_CONTENT_SUCCESS,
  FETCH_PROGRAM_CONTENT_FAILURE,
  FETCH_PROGRAM_CONTENT_CANCEL,
  UPDATE_PROGRAM_DETAILS,
  UPDATE_ACTIVE_PROGRAM,
  FETCH_COMMUNITY_SSO_TOKEN,
  FETCH_COMMUNITY_SSO_TOKEN_SUCCESS,
  FETCH_COMMUNITY_SSO_TOKEN_FAILURE,
  FETCH_COMMUNITY_SSO_TOKEN_CANCEL,
  UPDATE_USER_CONSENT,
  UPDATE_USER_CONSENT_SUCCESS,
  UPDATE_USER_CONSENT_FAILURE,
  UPDATE_USER_CONSENT_CANCEL,
  FETCH_MOUTHSHUT_SUCCESS,
  GET_BATCHES_SUCCESS,
} from './Programs.types'
import {
  ProgramsData,
  EnrolledProgramsData,
} from '../../../../common/types/programs'

export interface ProgramState {
  data: ProgramsData
  loading: Boolean
  error: false | Error | Response
}

const programsDataInitialState: ProgramsData = {
  byId: {},
  enrolledProgramIDs: [],
  glAcademyID: null,
  careerSchoolProgramId: null,
  glcaAssessmentProgramId: null,
  activeProgramID: null,
  isActiveCommunityBatch: false,
  communitySsoToken: null,
  isNewProgramSupportEnabled: true,
  readCommunityTips: false,
  isOtherCategoriesForNewSq: false,
  askMouthShutReview: false,
  batches: [],
}

const initialState: ProgramState = {
  data: programsDataInitialState,
  loading: false,
  error: false as false,
}

const normalizeProgramsData = (
  stateData: ProgramState['data'],
  enrolledPrograms: EnrolledProgramsData[]
): Partial<ProgramsData> => {
  if (enrolledPrograms.length) {
    const enrolledProgramIDs: ProgramsData['enrolledProgramIDs'] = []
    let glAcademyID = null
    let careerSchoolProgramId = null
    let glcaAssessmentProgramId = null
    const byId = enrolledPrograms.reduce(
      (acc: ProgramsData['byId'], program: EnrolledProgramsData) => {
        if (program.access_type === 'free') {
          glAcademyID = program.program_group_id
        }

        if (program.program_type === 'career_track') {
          careerSchoolProgramId = program.program_group_id
        }

        if (isGlcaBatch(program.program_code)) {
          glcaAssessmentProgramId = program.program_group_id
        }

        enrolledProgramIDs.push(program.program_group_id)

        acc[program.program_group_id] = {
          ...program,
          id: program.program_group_id,
          name: program.program_name,
          group: program.program_group_name,
          display_code: program.program_display_code,
          display_short_name: program.program_display_short_name,
          hide_grades_for_learners: program.hide_grades_for_learners,
          olympus_logo:
            program.program_id === 3383 ? RBILogo : program.olympus_logo,
        }
        return acc
      },
      {}
    )
    return {
      byId,
      enrolledProgramIDs,
      glAcademyID,
      careerSchoolProgramId,
      glcaAssessmentProgramId,
    }
  }
  return stateData
}

const programReducer: Reducer<ProgramState, ProgramActions> = (
  state = initialState,
  action
): ProgramState => {
  switch (action.type) {
    case UPDATE_PROGRAM_DETAILS:
      return {
        ...state,
        data: {
          ...state.data,
          ...normalizeProgramsData(state.data, action.payload.enrolledPrograms),
        },
      }
    case GET_BATCHES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          batches: action.payload.batches,
        },
      }

    case UPDATE_ACTIVE_PROGRAM:
      return {
        ...state,
        data: {
          ...state.data,
          activeProgramID: action.payload.programId,
        },
      }

    case FETCH_COMMUNITY_SSO_TOKEN:
    case FETCH_PROGRAM_CONTENT:
      return { ...state, loading: true, error: false }

    case FETCH_PROGRAM_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            ...(state.data.byId[action.payload.batch.id]
              ? {
                  [action.payload.batch.id]: {
                    ...state.data.byId[action.payload.batch.id]!,
                    name: action.payload.program_name,
                    group: action.payload.batch.name,
                  },
                }
              : null),
          },
        },
      }

    case FETCH_COMMUNITY_SSO_TOKEN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          isActiveCommunityBatch: action.payload.isActiveCommunityBatch,
          communitySsoToken: action.payload.communitySsoToken,
          isNewProgramSupportEnabled: action.payload.isNewProgramSupportEnabled,
          readCommunityTips: action.payload.hasReadCommunityTips,
          isOtherCategoriesForNewSq: action.payload.isOtherCategoriesForNewSq,
          isB2BBatch: action.payload.isB2BBatch,
        },
      }

    case UPDATE_USER_CONSENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          readCommunityTips: action.payload.hasReadCommunityTips,
        },
      }

    case UPDATE_USER_CONSENT_FAILURE:
    case FETCH_COMMUNITY_SSO_TOKEN_FAILURE:
    case FETCH_PROGRAM_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case UPDATE_USER_CONSENT_CANCEL:
    case FETCH_COMMUNITY_SSO_TOKEN_CANCEL:
    case FETCH_PROGRAM_CONTENT_CANCEL:
      return {
        ...state,
        loading: false,
      }
    case FETCH_MOUTHSHUT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          askMouthShutReview: action.payload.askForMouthShutReview,
        },
      }

    default:
      return state
  }
}
export default programReducer
