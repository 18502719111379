import {
  FETCH_CAREER_SCHOOL_DETAILS,
  FETCH_CAREER_SCHOOL_DETAILS_CANCEL,
  FETCH_CAREER_SCHOOL_DETAILS_FAILURE,
  FETCH_CAREER_SCHOOL_DETAILS_SUCCESS,
  UPDATE_CAREER_SCHOOL_ONBOARDING,
  UPDATE_CAREER_SCHOOL_ONBOARDING_COMPLETED,
  UPDATE_CAREER_SCHOOL_JOB_ONBOARDED,
  UPDATE_CAREER_SCHOOL_USER_INFO_COLLECTED,
} from './CareerSchool.types'

export const fetchCareerSchoolDetails = () => ({
  type: FETCH_CAREER_SCHOOL_DETAILS as typeof FETCH_CAREER_SCHOOL_DETAILS,
})
export const fetchCareerSchoolDetailsSuccess = (payload: any) => ({
  type: FETCH_CAREER_SCHOOL_DETAILS_SUCCESS as typeof FETCH_CAREER_SCHOOL_DETAILS_SUCCESS,
  payload,
})
export const fetchCareerSchoolDetailsFailure = (payload: any) => ({
  type: FETCH_CAREER_SCHOOL_DETAILS_FAILURE as typeof FETCH_CAREER_SCHOOL_DETAILS_FAILURE,
  payload,
})
export const fetchCareerSchoolDetailsCancel = () => ({
  type: FETCH_CAREER_SCHOOL_DETAILS_CANCEL as typeof FETCH_CAREER_SCHOOL_DETAILS_CANCEL,
})
export const updateCareerSchoolOnboarding = () => ({
  type: UPDATE_CAREER_SCHOOL_ONBOARDING as typeof UPDATE_CAREER_SCHOOL_ONBOARDING,
})
export const updateCareerSchoolOnboardingCompleted = () => ({
  type: UPDATE_CAREER_SCHOOL_ONBOARDING_COMPLETED as typeof UPDATE_CAREER_SCHOOL_ONBOARDING_COMPLETED,
})
export const updateCareerSchoolJobOnboarded = () => ({
  type: UPDATE_CAREER_SCHOOL_JOB_ONBOARDED as typeof UPDATE_CAREER_SCHOOL_JOB_ONBOARDED,
})
export const updateCareerSchoolUserInfoCollected = () => ({
  type: UPDATE_CAREER_SCHOOL_USER_INFO_COLLECTED as typeof UPDATE_CAREER_SCHOOL_USER_INFO_COLLECTED,
})

export type CareerSchoolActionTypes =
  | ReturnType<typeof fetchCareerSchoolDetails>
  | ReturnType<typeof fetchCareerSchoolDetailsFailure>
  | ReturnType<typeof fetchCareerSchoolDetailsSuccess>
  | ReturnType<typeof updateCareerSchoolOnboarding>
  | ReturnType<typeof updateCareerSchoolOnboardingCompleted>
  | ReturnType<typeof updateCareerSchoolJobOnboarded>
  | ReturnType<typeof updateCareerSchoolUserInfoCollected>
