import { call, cancelled, put, select, takeLeading } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import { AppState } from 'web/store'
import {
  fetchAllDepartmentsContent,
  fetchAllDepartmentsContentFailure,
  fetchAllDepartmentsContentSuccess,
} from '.'
import {
  FETCH_ALL_DEPARTMENTS_CONTENT,
  FETCH_ALL_DEPARTMENTS_CONTENT_CANCEL,
} from './Departments.types'
import { UserID } from '../../../../common/types/user'
import { fetchAllDepartmentsContentAPI } from './Departments.api'

function* fetchAllDepartmentsContentHandler(
  action: ReturnType<typeof fetchAllDepartmentsContent>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('userId unavilable')
    }
    const data = yield call(
      fetchAllDepartmentsContentAPI,
      action,
      userId,
      abortController.signal
    )
    yield put(fetchAllDepartmentsContentSuccess(data))
  } catch (e) {
    yield put(fetchAllDepartmentsContentFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fslDepartmentsMiddleware() {
  yield takeLeading(
    FETCH_ALL_DEPARTMENTS_CONTENT,
    cancelable(
      fetchAllDepartmentsContentHandler,
      FETCH_ALL_DEPARTMENTS_CONTENT_CANCEL
    )
  )
}

export default ([] as any).concat(fslDepartmentsMiddleware())
