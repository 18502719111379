import { Reducer } from 'redux'
import { AlertsData } from '../../../common/types/alerts'
import { SHOW_ALERT_MESSAGE, REMOVE_ALERT_MESSAGE } from './Alerts.types'
import { AlertsActionTypes } from './Alerts.actions'

export interface AlertsState {
  data?: AlertsData
  isOpen: boolean
}

const intialState: AlertsState = {
  isOpen: false,
}

const alertsReducer: Reducer<AlertsState, AlertsActionTypes> = (
  state = intialState,
  action
): AlertsState => {
  switch (action.type) {
    case SHOW_ALERT_MESSAGE:
      return {
        ...state,
        data: action.payload,
        isOpen: true,
      }

    case REMOVE_ALERT_MESSAGE:
      return {
        ...state,
        isOpen: false,
      }

    default:
      return state
  }
}
export default alertsReducer
