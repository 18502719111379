import { FileID } from 'common/types'
import { UserID } from 'common/types/user'
import {
  QuizSubmissionID,
  QuizQuestionID,
  QuizAnswerData,
  QuizAttemptNo,
} from '../../../../../../common/types/courses/quiz'
import {
  apiCall,
  CustomError,
  getUserDetails,
} from '../../../../../../common/utils'

export async function getQuizQuestionsAndAnswersAPI(data: {
  submissionId: QuizSubmissionID
  query: {
    validation_token: string
    quiz_submission_attempt: QuizAttemptNo
    student_id: UserID | null
  }
}) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/quiz_submissions/${data.submissionId}/details`,
    query: {
      ...data.query,
      student_id: id,
    },
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function saveQuizAnswersAPI(data: {
  attempt: QuizAttemptNo
  validation_token: string
  questions_response_not_expected: boolean
  quiz_questions: QuizAnswerData[]
  submissionId: QuizSubmissionID
}) {
  const { submissionId, quiz_questions, ...query } = data
  const body = JSON.stringify({
    quiz_questions,
  })
  try{
    const response = await apiCall({
      url: `${window.constants.REACT_APP_LMS_API_URL}v1/quiz_submissions/${submissionId}/questions`,
      query,
      params: { body, method: 'POST' },
    })

    if (response.ok) {
      return response.json()
    }

    if (response.status === 400 || response.status === 403) {
      const res = await response.json()
      if ('message' in res) {
        throw new CustomError('Handled Errors', new Error(res.message))
      }
    }

    if (response.status === 401) {
      throw new CustomError('User unauthenticated', new Error('401'))
    }

    throw response
  } catch (err) {
    throw err  
  }
}

export async function flagQuizQuestionAPI(data: {
  submissionId: QuizSubmissionID
  questionId: QuizQuestionID
  flag: boolean
  query: {
    attempt: QuizAttemptNo
    validation_token: string
  }
}) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/quiz_submissions/${
      data.submissionId
    }/questions/${data.questionId}/${data.flag ? 'flag' : 'unflag'}`,
    query: data.query,
    params: { method: 'PUT' },
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getFileDetailsAPI(data: { fileId: FileID }) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/files/${data.fileId}`,
  })

  if (response.ok) {
    return response.json()
  }
  if (response.status === 400) {
    const res = await response.json()
    if ('message' in res) {
      throw new CustomError(res.message, data)
    }
  }
  throw response
}

export async function getVideoSubmissionSignedUrl(data: { url: string }) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: data.url,
    query: { user_id: id },
  })

  if (response.ok) {
    return response.json()
  }

  throw response
}
