import { Reducer } from 'redux'
import { CourseProgressActions } from './CourseProgress.actions'
import {
  FETCH_COURSE_PROGRESS_CONTENT,
  FETCH_COURSE_PROGRESS_CONTENT_SUCCESS,
  FETCH_COURSE_PROGRESS_CONTENT_FAILURE,
  FETCH_COURSE_PROGRESS_CONTENT_CANCEL,
} from './CourseProgress.types'
import { CourseProgressData } from '../../../../common/types/dashboard'
import { ProgramActions, UPDATE_ACTIVE_PROGRAM } from '../ProgramsProvider'

export interface CourseProgressState {
  data?: CourseProgressData[]
  loading: boolean
  error: false | Error | Response
}

const initialState: CourseProgressState = {
  loading: false,
  error: false as false,
}

const courseProgressReducer: Reducer<
  CourseProgressState,
  | CourseProgressActions
  | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COURSE_PROGRESS_CONTENT:
      return { ...state, loading: true, error: false }
    case FETCH_COURSE_PROGRESS_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case FETCH_COURSE_PROGRESS_CONTENT_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case FETCH_COURSE_PROGRESS_CONTENT_CANCEL:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_ACTIVE_PROGRAM:
      return initialState
    default:
      return state
  }
}
export default courseProgressReducer
