import React from 'react'
import { connect } from 'react-redux'
import { logoutUser } from 'web/providers/User/UserDetailsProvider'
import NavigationComponent from '../../../components/LearnAndDev/Navigation'

interface NavigationProps {
  logoutUser: typeof logoutUser
}

const Navigation = (props: NavigationProps) => {
  return <NavigationComponent onLogout={props.logoutUser} />
}

export default connect(
  null,
  { logoutUser }
)(Navigation)
