import * as types from './Utils.types'

export const fetchCurrentTime = () => ({
  type: types.FETCH_CURRENT_TIME as typeof types.FETCH_CURRENT_TIME,
})

export const fetchCurrentTimeSuccess = (payload: any) => ({
  type: types.FETCH_CURRENT_TIME_SUCCESS as typeof types.FETCH_CURRENT_TIME_SUCCESS,
  payload,
})

export const removeCurrentTime = () => ({
  type: types.REMOVE_CURRENT_TIME as typeof types.REMOVE_CURRENT_TIME,
})

export type UtilsActions =
  | ReturnType<typeof fetchCurrentTime>
  | ReturnType<typeof fetchCurrentTimeSuccess>
  | ReturnType<typeof removeCurrentTime>
