import { fetchCertificatesContent } from '.'
import { apiCall } from '../../../../common/utils'
import { UserID } from '../../../../common/types/user'

export async function getCertificatesContentAPI(
  action: ReturnType<typeof fetchCertificatesContent>,
  userId: UserID,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/certificates`,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
