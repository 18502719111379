import {
  CREATE_NOTE,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAILURE,
  CREATE_NOTE_CANCEL,
  FETCH_NOTE,
  FETCH_NOTE_CANCEL,
  FETCH_NOTE_FAILURE,
  FETCH_NOTE_SUCCESS,
  UPDATE_NOTE,
  UPDATE_NOTE_CANCEL,
  UPDATE_NOTE_FAILURE,
  UPDATE_NOTE_SUCCESS,
  DELETE_NOTE,
  DELETE_NOTE_CANCEL,
  DELETE_NOTE_FAILURE,
  DELETE_NOTE_SUCCESS,
} from './Notes.types'
import { ItemID } from '../../../../common/types/courses/moduleItem'
import { CourseID } from '../../../../common/types/courses'
import { ModuleID } from '../../../../common/types'
import { UserID } from '../../../../common/types/user'
import { Note, NoteID } from '../../../../common/types/courses/notes'
import { ContentID } from '../../../../common/types/courses/content'

interface createNoteParams {
  video_id: ContentID | null
  module_id: ModuleID | null
  course_id: CourseID | null
  module_item_id: ItemID | null
  note: Note
}

interface updateNoteParams {
  description: string
  note_id: NoteID | null
}

interface deleteNoteParams {
  note_id: NoteID | null
}

interface fetchNoteParams {
  module_id?: ModuleID | null
  course_id?: CourseID | null
  module_item_id?: ItemID | null
  video_id?: ContentID | null
}

export const createNote = (payload: createNoteParams) => ({
  type: CREATE_NOTE as typeof CREATE_NOTE,
  payload,
})

export const createNoteSuccess = (payload: any) => ({
  type: CREATE_NOTE_SUCCESS as typeof CREATE_NOTE_SUCCESS,
  payload,
})

export const createNoteFailure = (payload: Error) => ({
  type: CREATE_NOTE_FAILURE as typeof CREATE_NOTE_FAILURE,
  payload,
})

export const createNoteCancel = (payload: any) => ({
  type: CREATE_NOTE_CANCEL as typeof CREATE_NOTE_CANCEL,
  payload,
})

export const fetchNote = (payload: fetchNoteParams) => ({
  type: FETCH_NOTE as typeof FETCH_NOTE,
  payload,
})

export const fetchNoteSuccess = (payload: any) => ({
  type: FETCH_NOTE_SUCCESS as typeof FETCH_NOTE_SUCCESS,
  payload,
})

export const fetchNoteFailure = (payload: Error) => ({
  type: FETCH_NOTE_FAILURE as typeof FETCH_NOTE_FAILURE,
  payload,
})

export const fetchNoteCancel = (payload: any) => ({
  type: FETCH_NOTE_CANCEL as typeof FETCH_NOTE_CANCEL,
  payload,
})

export const updateNote = (payload: updateNoteParams) => ({
  type: UPDATE_NOTE as typeof UPDATE_NOTE,
  payload,
})

export const updateNoteSuccess = (payload: any) => ({
  type: UPDATE_NOTE_SUCCESS as typeof UPDATE_NOTE_SUCCESS,
  payload,
})

export const updateNoteFailure = (payload: Error) => ({
  type: UPDATE_NOTE_FAILURE as typeof UPDATE_NOTE_FAILURE,
  payload,
})

export const updateNoteCancel = (payload: any) => ({
  type: UPDATE_NOTE_CANCEL as typeof UPDATE_NOTE_CANCEL,
  payload,
})

export const deleteNote = (payload: deleteNoteParams) => ({
  type: DELETE_NOTE as typeof DELETE_NOTE,
  payload,
})

export const deleteNoteSuccess = (payload: any) => ({
  type: DELETE_NOTE_SUCCESS as typeof DELETE_NOTE_SUCCESS,
  payload,
})

export const deleteNoteFailure = (payload: Error) => ({
  type: DELETE_NOTE_FAILURE as typeof DELETE_NOTE_FAILURE,
  payload,
})

export const deleteNoteCancel = (payload: any) => ({
  type: DELETE_NOTE_CANCEL as typeof DELETE_NOTE_CANCEL,
  payload,
})

export type NoteTypes =
  | ReturnType<typeof createNote>
  | ReturnType<typeof createNoteSuccess>
  | ReturnType<typeof createNoteFailure>
  | ReturnType<typeof createNoteCancel>
  | ReturnType<typeof fetchNote>
  | ReturnType<typeof fetchNoteSuccess>
  | ReturnType<typeof fetchNoteFailure>
  | ReturnType<typeof fetchNoteCancel>
  | ReturnType<typeof updateNote>
  | ReturnType<typeof updateNoteSuccess>
  | ReturnType<typeof updateNoteFailure>
  | ReturnType<typeof updateNoteCancel>
  | ReturnType<typeof deleteNote>
  | ReturnType<typeof deleteNoteSuccess>
  | ReturnType<typeof deleteNoteFailure>
  | ReturnType<typeof deleteNoteCancel>
