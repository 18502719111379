import { pickIntoArray, generateURL, getActiveProgramBatchId } from '..'
import { AnnouncementsState } from '../../../web/providers/AnnouncementsProvider'
import { AnnouncementData, AnnouncementID } from '../../types/announcements'
import { CourseID } from '../../types/courses'
import { GroupID } from '../../types/user'

export const selectAnnouncements = (
  announcements: AnnouncementsState['data'],
  id?: CourseID | GroupID
): AnnouncementData[] | null => {
  if (!id) {
    // eslint-disable-next-line no-param-reassign
    id = 'ALL_COURSES'
  }
  const currentAnnouncements = announcements.idMapping[id]
  if (currentAnnouncements) {
    return pickIntoArray(announcements.byId, currentAnnouncements)
  }
  return null
}

export const getNotificationsUrl = (urlPostfix?: string) => {
  return `/notifications${urlPostfix || ''}`
}

export const getCourseNotificationsUrl = (courseId: CourseID) => {
  const pbId = getActiveProgramBatchId()
  const queryParams = `?filters=${btoa(
    JSON.stringify({ courseIds: [courseId], pbId })
  )}&tab=course&pb_id=${pbId}`
  return getNotificationsUrl(queryParams)
}

export const getGroupNotificationsUrl = (groupId: GroupID) => {
  const pbId = getActiveProgramBatchId()
  const queryParams = `?filters=${btoa(
    JSON.stringify({ groupIds: [groupId], pbId })
  )}&tab=group&pb_id=${pbId}`
  return getNotificationsUrl(queryParams)
}

export const getAnnouncementsURL = (courseId?: CourseID) => {
  if (courseId) {
    return generateURL('COURSE_ANNOUNCEMENTS', {
      path: { courseId },
      state: { courseId },
    })
  }
  return generateURL('ANNOUNCEMENTS', null)
}

export const getGroupAnnouncementsURL = (groupId: GroupID) => {
  return generateURL('GROUP_ANNOUNCEMENTS', {
    path: { groupId },
    state: { groupId },
  })
}

export const getCourseAnnouncementURL = (
  courseId: CourseID,
  announcementId: AnnouncementID
) => {
  return generateURL('COURSE_ANNOUNCEMENT', {
    path: { courseId, announcementId },
    state: { courseId, announcementId },
  })
}
export const getGroupAnnouncementURL = (
  groupId: GroupID,
  announcementId: AnnouncementID
) => {
  return generateURL('GROUP_ANNOUNCEMENT', {
    path: { groupId, announcementId },
    state: { groupId, announcementId },
  })
}
