import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { apiCall, cancelable, getUserDetails } from '../../../../common/utils'
import {
  fetchCourseProgressContentFailure,
  fetchCourseProgressContentSuccess,
} from './CourseProgress.actions'
import {
  FETCH_COURSE_PROGRESS_CONTENT,
  FETCH_COURSE_PROGRESS_CONTENT_CANCEL,
} from './CourseProgress.types'

export async function getCourseProgressContentAPI(signal: AbortSignal) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/progress`,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

function* fetchCourseProgressContentHandler() {
  const abortController = new AbortController()
  try {
    const data = yield call(getCourseProgressContentAPI, abortController.signal)
    yield put(fetchCourseProgressContentSuccess(data))
  } catch (e) {
    yield put(fetchCourseProgressContentFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fetchCourseProgressContentMiddleware() {
  yield takeLatest(
    FETCH_COURSE_PROGRESS_CONTENT,
    cancelable(
      fetchCourseProgressContentHandler,
      FETCH_COURSE_PROGRESS_CONTENT_CANCEL
    )
  )
}
export default ([] as any).concat(fetchCourseProgressContentMiddleware())
