import { apiCall } from '../../../common/utils'
import { CourseID } from '../../../common/types/courses'
import { ContentID } from '../../../common/types/courses/content'
import { ModuleItemSequence } from '../../../common/types/courses/moduleItem'

export const getModuleItemSequence = async ({
  courseId,
  asset_id,
  asset_type,
}: {
  courseId: CourseID
  asset_id: ContentID
  asset_type:
    | 'File'
    | 'Page'
    | 'Discussion'
    | 'Assignment'
    | 'Quiz'
    | 'ModuleItem'
}) => {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/module_item_sequence`,
    query: {
      asset_id,
      asset_type,
    },
  })
  if (response.ok) {
    const responseData: ModuleItemSequence = await response.json()
    if (responseData.items && responseData.items.length > 0) {
      const sequenceAsset = responseData.items[0].current
      if (sequenceAsset) {
        return sequenceAsset
      }
    }
    return null
  }
  throw response
}
