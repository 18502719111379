import { Reducer } from 'redux'
import { ProgramSupportFaqs } from 'common/types/programSupport'
import { ProgramSupportFaqsTypes } from './ProgramSupportFaqs.actions'
import * as types from './ProgramSupportFaqs.types'

export interface ProgramSupportFaqsState {
  data: ProgramSupportFaqs[]
  loading: boolean
  error: false | Error | Response
}

const initialStateModules: ProgramSupportFaqsState = {
  data: [],
  loading: false,
  error: false,
}

export const programSupportFaqsReducer: Reducer<
  ProgramSupportFaqsState,
  ProgramSupportFaqsTypes
> = (state = initialStateModules, action): ProgramSupportFaqsState => {
  switch (action.type) {
    case types.FETCH_FAQS:
    case types.ADD_NEW_FAQ:
    case types.EDIT_FAQ:
    case types.DELETE_FAQ:
      return {
        ...state,
        loading: true,
      }

    case types.FETCH_FAQS_FAILURE:
    case types.ADD_NEW_FAQ_FAILURE:
    case types.EDIT_FAQ_FAILURE:
    case types.DELETE_FAQ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case types.FETCH_FAQS_CANCEL:
    case types.ADD_NEW_FAQ_CANCEL:
    case types.EDIT_FAQ_CANCEL:
    case types.DELETE_FAQ_CANCEL:
      return {
        ...state,
        loading: false,
      }

    case types.FETCH_FAQS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      }
    }

    case types.ADD_NEW_FAQ_SUCCESS: {
      const updatedData = [action.payload, ...state.data]
      return {
        ...state,
        data: updatedData,
        loading: false,
        error: false,
      }
    }

    case types.EDIT_FAQ_SUCCESS: {
      const updatedData = state.data.filter(obj => {
        return obj.id !== action.payload.id
      })
      updatedData.push(action.payload)
      return {
        ...state,
        data: updatedData,
        loading: false,
        error: false,
      }
    }

    case types.DELETE_FAQ_SUCCESS: {
      const updatedData = state.data.filter(obj => {
        return obj.id !== action.payload.id
      })
      return {
        ...state,
        data: updatedData,
        loading: false,
        error: false,
      }
    }

    default:
      return state
  }
}
