import { call, cancelled, put, takeLatest, select } from 'redux-saga/effects'
import { cancelable } from '../../../../common/utils'
import {
  fetchCities,
  fetchCitiesSuccess,
  fetchCitiesFailure,
  fetchColleges,
  fetchCollegesSuccess,
  fetchCollegesFailure,
  fetchUniversities,
  fetchUniversitiesSuccess,
  fetchUniversitiesFailure,
} from './FreeUserInfoDialog.actions'
import * as APIs from './FreeUserInfoDialog.api'
import * as types from './FreeUserInfoDialog.types'

function* fetchCitiesHandler(action: ReturnType<typeof fetchCities>) {
  const abortController = new AbortController()
  try {
    const data = yield call(APIs.getCitiesAPI, action, abortController.signal)
    yield put(fetchCitiesSuccess(data))
  } catch (e) {
    yield put(fetchCitiesFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchCollegesHandler(action: ReturnType<typeof fetchColleges>) {
  const abortController = new AbortController()
  try {
    const data = yield call(APIs.getCollegesAPI, action, abortController.signal)
    yield put(fetchCollegesSuccess(data))
  } catch (e) {
    yield put(fetchCollegesFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchUniversitiesHandler(
  action: ReturnType<typeof fetchUniversities>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.getUniversitiesAPI,
      action,
      abortController.signal
    )
    yield put(fetchUniversitiesSuccess(data))
  } catch (e) {
    yield put(fetchUniversitiesFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* freeUserInfoDialogMiddleware() {
  yield takeLatest(
    types.FETCH_CITIES,
    cancelable(fetchCitiesHandler, types.FETCH_CITIES_CANCEL)
  )

  yield takeLatest(
    types.FETCH_COLLEGES,
    cancelable(fetchCollegesHandler, types.FETCH_COLLEGES_CANCEL)
  )

  yield takeLatest(
    types.FETCH_UNIVERSITIES,
    cancelable(fetchUniversitiesHandler, types.FETCH_UNIVERSITIES_CANCEL)
  )
}
export default ([] as any).concat(freeUserInfoDialogMiddleware())
