import React, { ReactNode } from 'react'
import { Box } from '@material-ui/core'
import styles from './ErrorContainer.module.css'
import { CircleErrorIcon } from '../Icons/CircleIcons/CircleIcons'

interface ErrorContainerProps {
  children: ReactNode
}

const ErrorContainer = (props: ErrorContainerProps) => (
  <Box
    className={styles.container}
    boxShadow={1}
    display="flex"
    alignItems="center"
  >
    <div className={styles.iconContainer}>
      <CircleErrorIcon
        containerClassName={styles.errorContainer}
        iconClassName={styles.errorIcon}
      />
    </div>
    {props.children}
  </Box>
)

export default ErrorContainer
