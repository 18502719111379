import React, { useEffect } from 'react'
import CSSBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider, StylesProvider } from '@material-ui/styles'
import { Provider } from 'react-redux'
import { Provider as RollbarProvider } from '@rollbar/react'
import { Configuration as RollbarConfiguration } from 'rollbar'
import { Router, withRouter } from 'react-router-dom'
import { history } from './store/history'
import store from './store'
import Main from './Main'
import MUITheme from '../common/utils/theme'
import './App.css'
import {
  getCSSString,
  convertToCSSVariablesFormat,
} from '../common/utils/styles'
import { flatten, setAppUrls, isProduction } from '../common/utils'
import theme from './styles/theme'
import colors from './styles/colors'
import MasqueradeHandler from './containers/MasqueradeHandler'
import Alerts from './containers/Utils/Alerts'
import UserPrerequisites from './containers/UserPrerequisites'
import ErrorBoundary from './components/Utils/ErrorBoundary'
import UnSupportedBrowser from './pages/UnSupportedBrowser'
import LoadFiles from './components/Utils/LoadFiles'
import { GlLoader } from './components/GlLoader'

const rollbarConfig: RollbarConfiguration = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  addErrorContext: true,
  enabled: isProduction(),
  ignoredMessages: [/.*loading (css )?chunk \d+ failed.*/i],
  payload: {
    client: {
      javascript: {
        code_version: process.env.REACT_APP_BUILD_NUMBER,
        source_map_enabled: true,
      },
    },
    environment: process.env.REACT_APP_ENV,
  },
  // autoInstrument: {
  //   contentSecurityPolicy: true, // enables telemetry tracking, enabled by default
  //   errorOnContentSecurityPolicy: true, // send a Rollbar error message, disabled by default
  // },
}

function appendCSSVariables() {
  const style = document.createElement('style')
  const StyleVariables = flatten({ ...colors, ...theme }, '', '-')
  const CSSVariables = convertToCSSVariablesFormat(StyleVariables, '-')
  const CSSString = getCSSString(':root', CSSVariables)
  style.innerHTML = CSSString
  document.head.appendChild(style)
}
appendCSSVariables()

// API Url constants
setAppUrls()

const ScrollToTop = withRouter(({ location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
})

const App = () => {
  const isIE = () => {
    const ua = navigator.userAgent
    /* MSIE used to detect old browsers and Trident used to newer ones */
    const is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1
    return is_ie
  }
  useEffect(() => {
    // set local state variable for extension detection
    // localStorage.setItem("_intercept_mediastream_", '612953347')
    // for enabling IANA timezone names for browsers with no support(firefox<52)
    try {
      new Date().toLocaleTimeString('en-US', { timeZone: 'Asia/Kolkata' })
    } catch (error) {
      const script = document.createElement('script')
      script.src =
        'https://unpkg.com/date-time-format-timezone@latest/build/browserified/date-time-format-timezone-complete-min.js'
      document.body.appendChild(script)
    }
  }, [])

  if (isIE()) {
    return <UnSupportedBrowser />
  }

  if ('constants' in window)
    // Weglot is initialized in the Main component, it is loaded here as when not loaded in Top Level Component, Weglot is not initialized on the page load
    return (
      <React.StrictMode>
        <StylesProvider injectFirst>
          <ThemeProvider theme={MUITheme}>
            <CSSBaseline />
            <RollbarProvider config={rollbarConfig}>
              <Provider store={store}>
                <ErrorBoundary>
                  <Router history={history}>
                    <ScrollToTop />
                    <Alerts />
                    <UserPrerequisites>
                      <LoadFiles
                        srcs={[
                          {
                            src: `${process.env.REACT_APP_CLOUDFRONT_LTI_URL}javascript/weglotv2.min.js`,
                            async: true,
                            defer: true,
                            type: 'text/javascript',
                            mandatory: !(
                              process.env.NODE_ENV === 'development'
                            ),
                          },
                        ]}
                      >
                        <GlLoader />
                        <Main />
                      </LoadFiles>
                      <MasqueradeHandler />
                    </UserPrerequisites>
                  </Router>
                </ErrorBoundary>
              </Provider>
            </RollbarProvider>
          </ThemeProvider>
        </StylesProvider>
      </React.StrictMode>
    )
  return null
}

export default App
