import { getOutcomeDetails } from './Outcomes.actions'

import { apiCall } from '../../../../../common/utils'

export async function getOutcomeDetailsAPI(
  action: ReturnType<typeof getOutcomeDetails>,
  signal: AbortSignal
) {
  const { rubricOutcomeId } = action.payload
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/outcomes/${rubricOutcomeId}`,
    params: {
      signal,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
