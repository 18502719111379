import {
  Button,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Divider,
  Box,
} from '@material-ui/core'
import { ExpandMore, ExpandLess, ChevronRight } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import React, { useState, useEffect, Fragment } from 'react'
import cx from 'classnames'
import {
  isAcademyInternationalUser,
  isAcademyUser,
  hasNoWorkEx,
  isCorporateUser,
  glaUtmParams,
  glaPgUrl,
  glaSearchPageUrl,
} from 'common/utils/custom/user'
import {
  domesticDomainOrder,
  enterpriseDomainOrder,
  getCoursesByDomain,
  internationalDomainOrder,
  isAcademyCourse,
  isNewPremium,
} from 'common/utils/custom/courses'
import { getPageName } from 'common/utils'
import {
  AcademyCourseData,
  AcademyUserCourseData,
  CourseID,
  PremiumCoursesData,
} from 'common/types/courses'
import { mixpanel } from 'common/utils/mixpanel'
import { useLocation } from 'react-router-dom'
import { AllProgramData, AllProgramsData } from 'common/types/programs'
import styles from './BrowseCoursesMenu.module.css'
import Link from '../../Utils/Link'
import { UserDetailsState } from '../../../providers/User/UserDetailsProvider'
import { CoursesState } from '../../../providers/Courses/CoursesProvider'
import PGAcademyPremiumIcon from '../../../../common/images/academypremiumtag.svg'
import { ReactComponent as FreeCourseIcon } from '../../../../common/images/icons/freeUsers/freeCourseIcon.svg'
import { ReactComponent as PremiumCourseIcon } from '../../../../common/images/icons/freeUsers/premiumCourseIcon.svg'

interface BrowseCourseMenuProps {
  userAccessType: UserDetailsState['accessType']
  coursesData: CoursesState['data']
  isDrawer: boolean
  allPrograms: AllProgramsData
}

interface BrowseCourseProps {
  coursesByDomain: { [s in string]: AcademyCourseData[] }
  premiumCoursesByDomain: { [s in string]: PremiumCoursesData[] }
  availableProgramsByDomain: { [s in string]: AllProgramData[] }
  domainOrder: string[]
  noWorkEx: boolean
  allPrograms: AllProgramsData
}

const onProgramClick = (program_name: string) => {
  mixpanel.track('BrowseCourse - AdvProgClick', {
    program_clicked: program_name,
  })
}

const programUrl = (programData: AllProgramData, path: string) => {
  return `${glaPgUrl(
    programData.landing_page_url,
    programData.program_page_url
  )}?${glaUtmParams(
    `${getPageName(path)}_loggedin_browse_course_menu`,
    programData.course_program_code
  )}`
}

const MobileBrowseCourseMenu = ({
  coursesByDomain,
  premiumCoursesByDomain,
  availableProgramsByDomain,
  domainOrder,
  noWorkEx,
}: BrowseCourseProps) => {
  const location = useLocation()

  const StyledExpansionPanel = withStyles({
    root: {
      boxShadow: 'none',
      '&:before': {
        backgroundColor: 'transparent',
      },
      '&.Mui-expanded': {
        margin: 0,
        backgroundColor: 'var(--grey-20)',
      },
    },
  })(ExpansionPanel)

  const StyledExpansionPanelSummary = withStyles({
    root: {
      padding: '8px',
      minHeight: '16px',
      '&.Mui-expanded': {
        minHeight: '16px',
        borderBottom: '1px solid var(--grey-60)',
      },
    },
    content: {
      margin: 0,
      '&.Mui-expanded': {
        margin: 0,
      },
    },
    expandIcon: {
      padding: '0 4px',
    },
  })(ExpansionPanelSummary)

  const StyledExpansionPanelDetails = withStyles({
    root: {
      padding: '8px',
      flexDirection: 'column',
    },
  })(ExpansionPanelDetails)

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <>
      {domainOrder.map((domainName: string) => {
        return (
          <StyledExpansionPanel
            expanded={expanded === domainName}
            onChange={handleChange(domainName)}
            key={domainName}
          >
            <StyledExpansionPanelSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={styles.collapsibleDomainName}>
                {domainName}
              </Typography>
            </StyledExpansionPanelSummary>
            <StyledExpansionPanelDetails>
              {coursesByDomain[domainName] &&
                coursesByDomain[domainName].length ? (
                <>
                  <Typography className={styles.courseCategoryType}>
                    Free Courses
                  </Typography>
                  {coursesByDomain[domainName].map(
                    (courseData: AcademyCourseData) => (
                      <Link
                        href={courseData.website_course_url}
                        rel="noreferrer"
                        underline="none"
                        className={cx(
                          styles.mobileCourseName,
                          styles.courseName
                        )}
                        key={courseData.course_id}
                      >
                        <Typography variant="caption">
                          {courseData.course_name}
                        </Typography>
                      </Link>
                    )
                  )}
                </>
              ) : null}
              {premiumCoursesByDomain[domainName] &&
                premiumCoursesByDomain[domainName].length ? (
                <>
                  <Typography className={styles.courseCategoryType}>
                    Premium Courses
                  </Typography>
                  {premiumCoursesByDomain[domainName].map(
                    (courseData: PremiumCoursesData) => (
                      <Link
                        href={courseData.website_course_url}
                        rel="noreferrer"
                        underline="none"
                        className={cx(
                          styles.mobileCourseName,
                          styles.courseName
                        )}
                        key={courseData.course_id}
                      >
                        <Typography variant="caption">
                          {courseData.course_name}
                        </Typography>
                      </Link>
                    )
                  )}
                </>
              ) : null}
              {!noWorkEx &&
                availableProgramsByDomain &&
                availableProgramsByDomain[domainName] &&
                availableProgramsByDomain[domainName].length ? (
                <>
                  <Box display="flex">
                    <img
                      src={PGAcademyPremiumIcon}
                      className={styles.pgAcademyPremiumIcon}
                      alt="Academy Premium Icon"
                    />
                    <Typography className={styles.courseCategoryType}>
                      Degree & University Programs
                    </Typography>
                  </Box>
                  {availableProgramsByDomain[domainName].map(program => {
                    return (
                      <Link
                        href={programUrl(program, location.pathname)}
                        rel="noreferrer"
                        underline="none"
                        className={cx(
                          styles.mobileCourseName,
                          styles.courseName
                        )}
                        key={program.program_id}
                        target="_blank"
                        onClick={() =>
                          onProgramClick(
                            program.program_name,
                            program.program_category_code
                          )
                        }
                      >
                        <Typography variant="caption">
                          {program.program_name}
                        </Typography>
                      </Link>
                    )
                  })}
                </>
              ) : null}
            </StyledExpansionPanelDetails>
          </StyledExpansionPanel>
        )
      })}
    </>
  )
}

const DesktopBrowseCourseMenu = ({
  coursesByDomain,
  premiumCoursesByDomain,
  availableProgramsByDomain,
  domainOrder,
  noWorkEx,
  allPrograms,
}: BrowseCourseProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedDomain, setSelectedDomain] = useState(domainOrder[0])
  const location = useLocation()

  const handleClickOrMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
    setSelectedDomain(domainOrder[0])
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDomainMouseEnter = (domain: string) => {
    setSelectedDomain(domain)
  }

  return (
    <>
      <Button
        aria-owns="browse-courses-menu"
        aria-haspopup="true"
        onClick={handleClickOrMouseEnter}
        onMouseEnter={handleClickOrMouseEnter}
        className={styles.browseCoursesButton}
      >
        <Typography>Browse Courses</Typography>
        {anchorEl ? (
          <ExpandLess fontSize="small" />
        ) : (
          <ExpandMore fontSize="small" />
        )}
      </Button>
      <Popover
        id="browse-courses-menu"
        onClose={handleClose}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
      >
        <div
          role="presentation"
          className={styles.popoverBrowseMenu}
          onMouseLeave={handleClose}
          data-testid="browse-courses-menu-popover"
        >
          <List
            component="nav"
            aria-label="course categories"
            className={styles.courseList}
          >
            <ListSubheader className={styles.headerItem}>
              <Typography variant="h5">Course Categories</Typography>
            </ListSubheader>
            {domainOrder.map((domainName: string) => (
              <ListItem
                button
                onMouseEnter={() => handleDomainMouseEnter(domainName)}
                className={styles.listItem}
                selected={domainName === selectedDomain}
                key={domainName}
              >
                <ListItemText>
                  <Typography variant="subtitle2">{domainName}</Typography>
                </ListItemText>
                <ChevronRight fontSize="small" />
              </ListItem>
            ))}
            <ListItem>
              <Button variant="contained" color="primary">
                <Link
                  href={glaSearchPageUrl()}
                  underline="none"
                  onClick={handleClose}
                >
                  <Typography color="textSecondary" variant="body2">
                    Browse All Courses
                  </Typography>
                </Link>
              </Button>
            </ListItem>
          </List>

          {coursesByDomain[selectedDomain] &&
            coursesByDomain[selectedDomain].length || premiumCoursesByDomain[selectedDomain] &&
            premiumCoursesByDomain[selectedDomain].length ? (
            <List
              component="nav"
              aria-label="courses"
              className={styles.courseList}
            >

              {premiumCoursesByDomain[selectedDomain] && premiumCoursesByDomain[selectedDomain].length ?
                <Fragment>
                  <ListSubheader className={cx(styles.headerItem, styles.flex)}>
                    <div className={styles.iconWrapper}>
                      <PremiumCourseIcon />
                    </div>
                    <Typography variant="h5">Premium Course</Typography>
                  </ListSubheader>
                  <Divider className={styles.dividerFreeCourse} variant="middle" component="li" />
                  {premiumCoursesByDomain[selectedDomain].map(
                    (courseData: PremiumCoursesData) => (
                      <ListItem
                        button
                        className={styles.listItem}
                        key={courseData.course_id}
                      >
                        <ListItemText>
                          <Link
                            href={courseData.website_course_url}
                            rel="noreferrer"
                            underline="none"
                          >
                            <Typography variant="subtitle2">
                              {courseData.course_name}
                            </Typography>
                          </Link>
                        </ListItemText>
                      </ListItem>
                    )
                  )}
                </Fragment>
                : null}

              {coursesByDomain[selectedDomain] && coursesByDomain[selectedDomain].length ?
                <Fragment>
                  <ListSubheader className={cx(styles.headerItem, styles.flex)}>
                    <div className={styles.iconWrapperFree}>
                      <FreeCourseIcon />
                    </div>
                    <Typography variant="h5">Free Courses</Typography>
                  </ListSubheader>
                  <Divider className={styles.dividerFreeCourse} variant="middle" component="li" />
                  {coursesByDomain[selectedDomain].map(
                    (courseData: AcademyCourseData) => (
                      <ListItem
                        button
                        className={styles.listItem}
                        key={courseData.course_id}
                      >
                        <ListItemText>
                          <Link
                            href={courseData.website_course_url}
                            rel="noreferrer"
                            underline="none"
                          >
                            <Typography variant="subtitle2">
                              {courseData.course_name}
                            </Typography>
                          </Link>
                        </ListItemText>
                      </ListItem>
                    )
                  )}
                </Fragment>
                : null}
            </List>
          ) : null}

          {!noWorkEx &&
            availableProgramsByDomain &&
            availableProgramsByDomain[selectedDomain] &&
            availableProgramsByDomain[selectedDomain].length ? (
            <List
              component="nav"
              aria-label="career transformation programs"
              className={cx(styles.courseList, styles.programsList)}
            >
              <ListSubheader
                className={cx(styles.headerItem, styles.programHeaderItem)}
              >
                <Box display="flex">
                  <img
                    src={PGAcademyPremiumIcon}
                    className={styles.pgAcademyPremiumIcon}
                    alt="Academy Premium Icon"
                  />
                  <Typography variant="h5">
                    Degree & University Programs
                  </Typography>
                </Box>
              </ListSubheader>
              {availableProgramsByDomain[selectedDomain].map(program => {
                const programPartner =
                  program &&
                    program.partners &&
                    program.partners.length &&
                    allPrograms.program_partners &&
                    allPrograms.program_partners[program.partners[0]]
                    ? allPrograms.program_partners[program.partners[0]]
                    : null
                return (
                  <ListItem
                    button
                    className={cx(styles.listItem, styles.programListItem)}
                    key={program.program_id}
                  >
                    <ListItemText className={styles.programCard}>
                      <Link
                        href={programUrl(program, location.pathname)}
                        rel="noreferrer"
                        underline="none"
                        target="_blank"
                        onClick={() =>
                          onProgramClick(
                            program.program_name,
                            program.program_category_code
                          )
                        }
                      >
                        <Box display="flex" flexWrap="wrap">
                          {programPartner ? (
                            <img
                              src={programPartner.partner_image}
                              alt={programPartner.partner_image_text}
                              className={styles.programPartnerImg}
                            />
                          ) : null}
                        </Box>
                        <Typography variant="subtitle2">
                          {program.program_name}
                        </Typography>
                        <Typography className={styles.programInfoText}>
                          {`${program.duration}${program.mode ? ` | ${program.mode}` : ''
                            }${program.working_days
                              ? ` | ${program.working_days}`
                              : ''
                            }`}
                        </Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                )
              })}
            </List>
          ) : null}
        </div>
      </Popover>
    </>
  )
}

const BrowseCoursesMenu = (props: BrowseCourseMenuProps) => {
  const [coursesByDomain, setCoursesByDomain] = useState({})
  const [premiumCoursesByDomain, setPremiumCoursesByDomain] = useState({})
  const [availableDomains, setAvailableDomains] = useState<string[]>(
    domesticDomainOrder
  )
  const [noWorkEx, setNoWorkEx] = useState(true)
  const [availableProgramsByDomain, setAvailableProgramsByDomain] = useState({})

  useEffect(() => {
    const courses: AcademyCourseData[] = []
    const premiumCourses: PremiumCoursesData[] = []
    const isInternational = isAcademyInternationalUser(props.userAccessType)
    const isCorporate = isCorporateUser(props.userAccessType)
    const tempNoWorkEx = hasNoWorkEx()
    const tempAvailableProgramsByDomain: Record<string, AllProgramData[]> = {}

    props.coursesData.upcomingIds.forEach((cId: CourseID) => {
      if (props.coursesData.byId[cId]) {
        const courseData = props.coursesData.byId[cId] as AcademyUserCourseData
        if (courseData && isNewPremium(courseData)) {
          premiumCourses.push({ ...courseData })
        } else if (courseData && isAcademyCourse(courseData)) {
          courses.push({ ...courseData })
        }
      }
    })

    const tempCoursesByDomain = getCoursesByDomain(courses)
    const tempPremiumCoursesByDomain = getCoursesByDomain(premiumCourses)
    // eslint-disable-next-line no-nested-ternary
    const domainOrder = isCorporate
      ? enterpriseDomainOrder
      : isInternational
        ? internationalDomainOrder
        : domesticDomainOrder

    if (
      props.allPrograms.program_categories &&
      Object.keys(props.allPrograms.program_categories).length &&
      props.allPrograms.domain_to_program_category_code_map
    ) {
      domainOrder.forEach(domain => {
        if (props.allPrograms.domain_to_program_category_code_map[domain]) {
          const category = props.allPrograms.program_categories.find(
            program_category =>
              program_category.code ===
              props.allPrograms.domain_to_program_category_code_map[domain]
          )
          if (category) {
            tempAvailableProgramsByDomain[domain] = category.programs
              .map(
                program_detail_id =>
                  props.allPrograms.programs[program_detail_id]
              )
              .filter(Boolean)
          }
        }
      })
    }

    const tempAvailableDomains = domainOrder.filter(
      domain =>
        (tempCoursesByDomain[domain] && tempCoursesByDomain[domain].length) ||
        (tempNoWorkEx &&
          tempPremiumCoursesByDomain[domain] &&
          tempPremiumCoursesByDomain[domain].length) ||
        (!tempNoWorkEx &&
          tempAvailableProgramsByDomain[domain] &&
          tempAvailableProgramsByDomain[domain].length)
    )

    setAvailableDomains(tempAvailableDomains)
    setNoWorkEx(tempNoWorkEx)
    setCoursesByDomain(getCoursesByDomain(courses))
    setPremiumCoursesByDomain(getCoursesByDomain(premiumCourses))
    setAvailableProgramsByDomain(tempAvailableProgramsByDomain)
  }, [props.coursesData, props.userAccessType, props.allPrograms])

  if (
    !(
      isAcademyUser(props.userAccessType) ||
      isAcademyInternationalUser(props.userAccessType)
    )
  ) {
    return null
  }

  if (
    !(
      Object.keys(coursesByDomain).length ||
      Object.keys(premiumCoursesByDomain).length ||
      Object.keys(availableProgramsByDomain).length
    )
  ) {
    return null
  }

  return props.isDrawer ? (
    <MobileBrowseCourseMenu
      coursesByDomain={coursesByDomain}
      premiumCoursesByDomain={premiumCoursesByDomain}
      availableProgramsByDomain={availableProgramsByDomain}
      domainOrder={availableDomains}
      noWorkEx={noWorkEx}
      allPrograms={props.allPrograms}
    />
  ) : (
    <DesktopBrowseCourseMenu
      coursesByDomain={coursesByDomain}
      premiumCoursesByDomain={premiumCoursesByDomain}
      availableProgramsByDomain={availableProgramsByDomain}
      domainOrder={availableDomains}
      noWorkEx={noWorkEx}
      allPrograms={props.allPrograms}
    />
  )
}

const defaultProps = {
  coursesByDomain: {},
  premiumCoursesByDomain: {},
  availableProgramsByDomain: {},
}

DesktopBrowseCourseMenu.defaultProps = defaultProps
MobileBrowseCourseMenu.defaultProps = defaultProps

export default BrowseCoursesMenu
