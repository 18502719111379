export interface AlumniPortalData {
  records: AlumniUserData[]
  records_total_count: number
  gl_graduation_filter: []
  programs_filter: []
  current_country_filter: []
  current_company_filter: []
  current_designation_filter: []
  current_domain_filter: []
  experience_filter: []
  job_location_filter: []
  user_program: string
  user_work_exp: string | null
  user_city: string | null
  user_domain: string | null
  linkedin_profile_url: string
  quota_exceeded_user_ids: number[]
  is_domain_filter_applied: boolean
  is_location_filter_applied: boolean
  is_same_program_filter_applied: boolean
  is_same_batch_filter_applied: boolean
}

export interface AlumniUserData {
  user_name: string
  program_name: string
  current_designation: string
  current_company: string
  job_location: string
  linkedin_profile_url: string | null
  year_of_gl_graduation: string
  program_group_id: number
  avatar_url: string
  curated_work_experience: string
  eud_id: number
  stop_message: boolean
  lms_user_id: number
}

export interface ApplicableFilters {
  programsFilter: string[]
  yearOfGraduationFilter: string[]
  companiesFilter: string[]
  designationsFilter: string[]
  domainsFilter: string[]
  experienceFilter: string[]
  jobLocationFilter: string[]
  countriesFilter: string[]
  sameProgram: boolean
  sameBatch: boolean
  sameCity: boolean
}

export interface SendMessageToPeersPayload {
  toUsers: {}
  content: string
  subject: string
}

export enum UserKind {
  pgActive = 0,
  pgAlumni = 1,
  pgPotential = 2,
}
