/* eslint-disable no-nested-ternary */
import { Box } from '@material-ui/core'
import { CourseID } from 'common/types/courses'
import {
  AllProgramsData,
  IDegreeRecommendations,
  IPgpRecommendations,
} from 'common/types/programs'
import { AcademyUserProfileData } from 'common/types/user'
import {
  getLocalStorageItemWithExpiry,
  setLocalStorageItemWithExpiry,
} from 'common/utils'
import {
  isAcademyInternationalUser,
  isCollegeStudent,
  isExperience1To5,
  isExperienceMoreThan1Years,
  isExperienceMoreThan5,
  isFreeUser,
  isFresher,
  isGraduate,
  isSchoolStudent,
} from 'common/utils/custom/user'
import { mixpanel } from 'common/utils/mixpanel'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import AcademyPgpRecommendationDialog from 'web/components/Dashboard/AcademyPgpRecommendationDialog'
import { fetchAllPrograms } from 'web/providers/AllProgramsProvider'
import { fetchDashboardContent } from '../../providers/Dashboard/DashboardProvider'
import { DashboardState } from 'web/providers/Dashboard/DashboardProvider'
import { CoursesState } from '../../providers/Courses/CoursesProvider/Courses.reducer'
import {
  fetchGlaCertificatePayment,
  GlaCertificatePaymentState,
} from 'web/providers/GlaCertificatePaymentProvider'
import { UserDetailsState } from 'web/providers/User/UserDetailsProvider'
import { AppState } from 'web/store'
import {
  AcademyUserCourseData,
} from '../../../common/types/courses'
import { isNewPremium } from 'common/utils/custom/courses'

interface StateProps {
  dashboardData: DashboardState['data']
  userDetails: UserDetailsState
  courseData: CoursesState['data']
  allProgramsData: AllProgramsData
  glaCertificatePaymentDetail: GlaCertificatePaymentState
  fetchGlaCertificatePayment: typeof fetchGlaCertificatePayment
  fetchAllPrograms: typeof fetchAllPrograms
  fetchDashboardContent: typeof fetchDashboardContent
}

type Props = StateProps

const AcademyPgpRecommendationPopupCont = (props: Props) => {
  const [
    showAcademyPgpRecommendationDialog,
    setAcademyPgpRecommendationDialog,
  ] = useState(false)
  const storageForAcademyBanner = getLocalStorageItemWithExpiry(
    'PgpRecommendationPopupCookie'
  )

  const academyInternationalUsers = isAcademyInternationalUser(
    props.userDetails.accessType
  )
  const [pgData, setPgData] = useState<IPgpRecommendations>({})
  const [degreeData, setDegreeData] = useState<IDegreeRecommendations>({})
  const [showPg, setShowPg] = useState(false)
  const [showDegree, setShowDegree] = useState(false)
  const pgLength = Object.keys(pgData).length
  const degreeLength = Object.keys(degreeData).length
  const referalPopupIndex = getLocalStorageItemWithExpiry('ReferalPopupIndex')
  const expiryHourForPopupCookies = 48 * 60 * 60 * 1000
  const currentTimeForPopupCookies = new Date().getTime()
  const currentTimeWithExpiryForPopupCookies =
    currentTimeForPopupCookies + expiryHourForPopupCookies

  const pathName = window.location.pathname
  const extractCourseIdRegex = /\/courses\/(\d+)/
  const matchPathName = pathName.match(extractCourseIdRegex)
  const courseId: CourseID = matchPathName ? Number(matchPathName[1]) : ''

  useEffect(() => {
    if (courseId) {
      props.fetchGlaCertificatePayment({ course_id: courseId })
      props.fetchAllPrograms()
    }
  }, [])

  useEffect(() => {
    if (!props.courseData) {
      props.fetchDashboardContent()
    }
  }, [props.courseData])


  useEffect(() => {
    const freeUsers: boolean = isFreeUser(props.userDetails.accessType)

    const academyUsers = freeUsers || academyInternationalUsers

    const courseDataById = props.courseData && props.courseData.byId[courseId] as AcademyUserCourseData
  
    const courseIsNewPremium = courseDataById && isNewPremium(courseDataById)

    const isCertificatePaid =
      courseId &&
      props.glaCertificatePaymentDetail &&
      props.glaCertificatePaymentDetail[courseId] &&
      props.glaCertificatePaymentDetail[courseId]?.data &&
      'is_paid' in props.glaCertificatePaymentDetail[courseId]?.data &&
      props.glaCertificatePaymentDetail[courseId]?.data?.is_paid


    if (
      props.dashboardData &&
      'is_user_info_collected' in props.dashboardData &&
      props.dashboardData.is_user_info_collected &&
      'user_info_collected_at' in props.dashboardData &&
      moment().diff(
        moment(props.dashboardData.user_info_collected_at),
        'minutes'
      ) > 10 &&
      !storageForAcademyBanner &&
      academyUsers &&
      props.allProgramsData &&
      props.allProgramsData.pgp_recommendations &&
      props.userDetails &&
      props.userDetails.profile &&
      props.userDetails.appSource !== 'mobile_app' &&
      sessionStorage.getItem('ineligible_cb_user') !== 'true'
    ) {
      const schoolStudent = isSchoolStudent(
        props.userDetails.profile.data as AcademyUserProfileData
      )
      const newPgData: IPgpRecommendations = {}
      const newDegreeData: IDegreeRecommendations = {}
      Object.entries(props.allProgramsData.pgp_recommendations).forEach(
        ([id, recommendation]) => {
          if (recommendation?.is_degree === false) {
            newPgData[id] = recommendation
          } else {
            newDegreeData[id] = recommendation
          }
        }
      )
      setPgData(newPgData)
      setDegreeData(newDegreeData)
      if (!schoolStudent && (isCertificatePaid || !courseId) && !courseIsNewPremium) {
        setAcademyPgpRecommendationDialog(true)
      }
    }
  }, [
    props.allProgramsData,
    props.dashboardData,
    props.userDetails,
    props.glaCertificatePaymentDetail,
    props.courseData,
  ])

  useEffect(() => {
    const freeUsers = isFreeUser(props.userDetails.accessType)
    const academyUsers = freeUsers || academyInternationalUsers
    let recommendation_len = 0
    let show_pg_recommendation = false

    if (
      (pgLength || degreeLength) &&
      academyUsers &&
      props.userDetails.profile
    ) {
      const fresher = isFresher(
        props.userDetails.profile.data as AcademyUserProfileData
      )
      const graduate = isGraduate(
        props.userDetails.profile.data as AcademyUserProfileData
      )
      const collegeStudent = isCollegeStudent(
        props.userDetails.profile.data as AcademyUserProfileData
      )
      const workExp1To5 = isExperience1To5(
        props.userDetails.profile.data as AcademyUserProfileData
      )
      const workExp5AndAbove = isExperienceMoreThan5(
        props.userDetails.profile.data as AcademyUserProfileData
      )

      if (fresher || graduate || collegeStudent) {
        recommendation_len = degreeLength ? pgLength * 5 : pgLength
      } else if (workExp1To5) {
        recommendation_len = degreeLength ? pgLength * 4 : pgLength
      } else if (workExp5AndAbove) {
        recommendation_len = pgLength ? degreeLength * 5 : degreeLength
      }

      if (showAcademyPgpRecommendationDialog) {
        mixpanel.track('ShowPgpRecommendationPopup', { page: 'Olympus' })
        let recommendation_index = referalPopupIndex?.value
          ? (parseInt(referalPopupIndex.value, 10) + 1) %
            (recommendation_len + 1)
          : 1
        if (recommendation_index === 0) {
          setLocalStorageItemWithExpiry('ReferalPopupIndex', 1)
          recommendation_index = 1
        } else {
          setLocalStorageItemWithExpiry(
            'ReferalPopupIndex',
            recommendation_index
          )
        }

        if (fresher || graduate || collegeStudent) {
          show_pg_recommendation =
            (pgLength !== 0 && recommendation_index % 5 === 0) ||
            degreeLength === 0
        } else if (workExp1To5) {
          show_pg_recommendation =
            (pgLength !== 0 && recommendation_index % 4 === 0) ||
            degreeLength === 0
        } else if (workExp5AndAbove) {
          show_pg_recommendation =
            pgLength !== 0 &&
            (degreeLength === 0 || recommendation_index % 5 !== 0)
        }
        setShowPg(show_pg_recommendation)
        setShowDegree(!show_pg_recommendation)
      }
    }
  }, [pgLength, degreeLength, showAcademyPgpRecommendationDialog])

  return (
    <Box>
      {showAcademyPgpRecommendationDialog &&
      (pgLength || degreeLength) &&
      (showPg || showDegree) ? (
        <AcademyPgpRecommendationDialog
          onClose={() => {
            setAcademyPgpRecommendationDialog(false)
            const expiryHour = 6 * 60 * 60 * 1000
            const currentTime = new Date().getTime()
            const currentTimeWithExpiry = currentTime + expiryHour
            setLocalStorageItemWithExpiry(
              'PgpRecommendationPopupCookie',
              true,
              currentTimeWithExpiry
            )
          }}
          cookieExpiry={currentTimeWithExpiryForPopupCookies}
          pgpRecommendation={pgData}
          degreeRecommendation={degreeData}
          allProgramsData={props.allProgramsData}
          showPgProgram={showPg}
          isInternationalUser={academyInternationalUsers}
          moreThanOneYearsExperience={
            props.userDetails.profile
              ? isExperienceMoreThan1Years(
                  props.userDetails.profile.data as AcademyUserProfileData
                )
              : false
          }
        />
      ) : null}
    </Box>
  )
}
const mapStateToProps = (state: AppState) => ({
  courseData: state.courses.data,
  dashboardData: state.dashboard.data,
  userDetails: state.user.details,
  allProgramsData: state.allPrograms.data,
  glaCertificatePaymentDetail: state.glaCertificatePaymentDetail,
})

export default connect(mapStateToProps, {
  fetchGlaCertificatePayment,
  fetchAllPrograms,
  fetchDashboardContent,
})(AcademyPgpRecommendationPopupCont)
