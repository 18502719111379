import { apiCall, getUserDetails } from '../../../../common/utils'
import {
  fetchCities,
  fetchColleges,
  fetchUniversities,
} from './FreeUserInfoDialog.actions'

export async function getCitiesAPI(
  action: ReturnType<typeof fetchCities>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/cities`,
    params: { signal },
    query: { user_id: id },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getCollegesAPI(
  action: ReturnType<typeof fetchColleges>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/colleges`,
    params: { signal },
    query: {
      user_id: id,
      university_id: action.payload.university_id,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getUniversitiesAPI(
  action: ReturnType<typeof fetchUniversities>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/universities`,
    params: { signal },
    query: { user_id: id },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
