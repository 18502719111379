import { takeEvery } from 'redux-saga/effects'
import { mixpanel } from '../../../common/utils/mixpanel'
import {
  CustomError,
  isNetworkError,
  removeParamFromUrl,
} from '../../../common/utils'

function errorsHandler(action: any) {
  if (action && action.payload) {
    if (action.payload instanceof Error) {
      mixpanel.track(
        (function eventName() {
          if (isNetworkError(action.payload.message))
            return 'LMS - Network Errors'
          if (
            action.payload.message.includes('Invalid access token') ||
            action.payload.message.includes('Unauthorized')
          )
            return 'LMS - Unauthorized'
          return 'LMS - Errors'
        })(),
        {
          error_message: action.payload.message,
          error_action: action.type,
          ...(action.options ? action.options : {}),
        }
      )
    } else if (action.payload instanceof Response) {
      mixpanel.track(
        action.payload.statusText.includes('Unauthorized')
          ? 'LMS - Unauthorized'
          : 'LMS - Errors',
        {
          error_message: action.payload.statusText,
          error_action: action.type,
          ...(action.options ? action.options : {}),
          request_url: removeParamFromUrl('access_token', action.payload.url),
        }
      )
    } else if (action.payload instanceof CustomError) {
      mixpanel.track('LMS - Validation Errors', {
        error_message: action.payload.message,
        error_action: action.type,
        error: action.payload.error,
      })
    }
  }
}

function* errorsMiddleware() {
  yield takeEvery('*', errorsHandler)
}

export default ([] as any).concat([errorsMiddleware()])
