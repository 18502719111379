import { Reducer } from 'redux'
import { NotesData } from '../../../../common/types/courses/notes'
import { NoteTypes } from '.'
import {
  CREATE_NOTE,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAILURE,
  CREATE_NOTE_CANCEL,
  FETCH_NOTE,
  FETCH_NOTE_CANCEL,
  FETCH_NOTE_FAILURE,
  FETCH_NOTE_SUCCESS,
  UPDATE_NOTE,
  UPDATE_NOTE_CANCEL,
  UPDATE_NOTE_FAILURE,
  UPDATE_NOTE_SUCCESS,
  DELETE_NOTE,
  DELETE_NOTE_CANCEL,
  DELETE_NOTE_FAILURE,
  DELETE_NOTE_SUCCESS,
} from './Notes.types'

export interface NotesState {
  data: NotesData[]
  loading: boolean
  error: false | Error | Response
}

const initialStateModules: NotesState = {
  data: [],
  loading: false,
  error: false,
}

export const notesReducer: Reducer<NotesState, NoteTypes> = (
  state = initialStateModules,
  action
): NotesState => {
  switch (action.type) {
    case FETCH_NOTE:
      return {
        ...state,
        loading: true,
      }

    case FETCH_NOTE_SUCCESS:
      const result = [...action.payload].sort(function(a, b) {
        return a.id - b.id
      })
      return {
        ...state,
        data: result,
        loading: false,
        error: false,
      }

    case FETCH_NOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case FETCH_NOTE_CANCEL:
      return {
        ...state,
        loading: false,
      }

    case CREATE_NOTE:
      return {
        ...state,
        loading: true,
      }

    case CREATE_NOTE_SUCCESS:
      const createResult: NotesData[] = [...state.data, action.payload]
      return {
        ...state,
        data: createResult,
        loading: false,
        error: false,
      }

    case CREATE_NOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CREATE_NOTE_CANCEL:
      return {
        ...state,
        loading: false,
      }

    case UPDATE_NOTE:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      }

    case UPDATE_NOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case UPDATE_NOTE_CANCEL:
      return {
        ...state,
        loading: false,
      }

    case DELETE_NOTE:
      return {
        ...state,
        loading: true,
      }

    case DELETE_NOTE_SUCCESS:
      const deleteResult = state.data.filter((note: any) => {
        return note.id !== action.payload
      })
      return {
        ...state,
        data: deleteResult,
        loading: false,
        error: false,
      }

    case DELETE_NOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case DELETE_NOTE_CANCEL:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
