export const MODULES_FETCH = 'MODULES_FETCH'
export const MODULES_FETCH_SUCCESS = 'MODULES_FETCH_SUCCESS'
export const MODULES_FETCH_FAILURE = 'MODULES_FETCH_FAILURE'
export const MODULES_FETCH_CANCEL = 'MODULES_FETCH_CANCEL'

export const UPDATE_MODULE_DATA = 'UPDATE_MODULE_DATA'

export const FETCH_COURSE_ASSET_ACCESS = 'FETCH_COURSE_ASSET_ACCESS'
export const FETCH_COURSE_ASSET_ACCESS_SUCCESS =
  'FETCH_COURSE_ASSET_ACCESS_SUCCESS'
export const FETCH_COURSE_ASSET_ACCESS_FAILURE =
  'FETCH_COURSE_ASSET_ACCESS_FAILURE'
export const FETCH_COURSE_ASSET_ACCESS_CANCEL =
  'FETCH_COURSE_ASSET_ACCESS_CANCEL'
