import { Box, BoxProps, CircularProgress, makeStyles } from '@material-ui/core'
import cx from 'classnames'
import React from 'react'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
    borderRadius: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 50,
    backgroundColor: 'white',
  },
})

export function ContentLoader(props: BoxProps) {
  const styles = useStyles()
  return (
    <Box className={cx(styles.container)} {...props}>
      <CircularProgress />
    </Box>
  )
}
