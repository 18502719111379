import {
  call,
  cancelled,
  put,
  select,
  takeEvery,
  takeLatest,
  take,
} from 'redux-saga/effects'
import { AnnouncementData } from '../../../common/types/announcements'
import { BatchData } from '../../../common/types/dashboard'
import { cancelable } from '../../../common/utils'
import { getCourseIDFromContextCode } from '../../../common/utils/custom/courses'
import { discussionAPIs } from '../Courses/ModuleItemsProvider/Discussion'
import * as actions from './Announcements.actions'
import * as APIs from './Announcements.api'
import * as selectors from './Announcements.selectors'
import * as types from './Announcements.types'
import { CoursesState } from '../Courses/CoursesProvider'
import { AppState } from '../../store'
import {
  fetchDashboardContent,
  dashboardTypes,
  dashboardSelectors,
} from '../Dashboard/DashboardProvider'

const PAGE_SIZE = 20

function* announcementsFetchHandler({
  payload,
}: ReturnType<typeof actions.fetchAnnouncements>) {
  const abortController = new AbortController()
  try {
    const courses: CoursesState = yield select(
      (state: AppState) => state.courses
    )

    if (!courses.data.hasAllCourses) {
      if (courses.loading === false) {
        yield put(fetchDashboardContent())
      }
      yield take(dashboardTypes.FETCH_DASHBOARD_CONTENT_SUCCESS)
    }

    let courseIds = null
    if (payload.courseId) {
      courseIds = [payload.courseId]
    } else {
      courseIds = yield select(selectors.getAnnouncementCourseIds())
    }

    const announcements: AnnouncementData[] | null = yield select(
      selectors.getAnnouncements(payload.courseId)
    )

    const currentBatchDetails: BatchData | null = yield select(
      dashboardSelectors.getActiveProgramBatchDetails()
    )

    const enrolled_at =
      currentBatchDetails &&
      currentBatchDetails.is_engagement_batch &&
      currentBatchDetails.enrolled_at
        ? currentBatchDetails.enrolled_at
        : '2012-01-01'

    let data: AnnouncementData[] = []
    let hasMore = true
    if (
      courseIds.length &&
      (!announcements || announcements.length < payload.page * PAGE_SIZE)
    ) {
      data = yield call(APIs.getAllAnnouncements, {
        signal: abortController.signal,
        courseIds,
        per_page: PAGE_SIZE,
        page: payload.page,
        enrolled_at,
      })
      hasMore = Boolean(data && data.length > 0)
    }

    yield put(
      actions.fetchAnnouncementsSuccess({
        data,
        courseId: payload.courseId,
        hasMore,
      })
    )
  } catch (e) {
    yield put(actions.fetchAnnouncementsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* groupAnnouncementsFetchHandler({
  payload,
}: ReturnType<typeof actions.fetchGroupAnnouncements>) {
  const abortController = new AbortController()
  try {
    const announcements: AnnouncementData[] | null = yield select(
      selectors.getAnnouncements(payload.groupId)
    )

    let data: AnnouncementData[] = []
    let hasMore = true
    if (!announcements || announcements.length < payload.page * PAGE_SIZE) {
      data = yield call(APIs.getGroupAnnouncements, {
        signal: abortController.signal,
        groupId: payload.groupId,
        per_page: PAGE_SIZE,
        page: payload.page,
      })
      hasMore = Boolean(data && data.length > 0)
    }

    yield put(
      actions.fetchGroupAnnouncementsSuccess(
        {
          data,
          groupId: payload.groupId,
          hasMore,
        },
        { groupId: payload.groupId }
      )
    )
  } catch (e) {
    yield put(actions.fetchGroupAnnouncementsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* announcementsMarkAsReadHandler({
  meta,
}: ReturnType<typeof actions.markAnnouncementAsRead>) {
  try {
    yield call(discussionAPIs.markTopicAsRead, {
      courseId: 'courseId' in meta ? meta.courseId : undefined,
      groupId: 'groupId' in meta ? meta.groupId : undefined,
      discussionId: meta.announcementId,
    })
    yield put(actions.markAnnouncementAsReadSuccess(null, meta))
  } catch (e) {
    yield put(actions.markAnnouncementAsReadSuccess(null, meta))
  }
}

function* announcementOpenHandler({
  payload,
}: ReturnType<typeof actions.announcementOpen>) {
  try {
    const announcement: AnnouncementData | null = yield select(
      selectors.getAnnouncement({ announcementId: payload.announcementId })
    )
    if (announcement && announcement.read_state === 'unread') {
      if (announcement.context_code) {
        const courseId = getCourseIDFromContextCode(announcement.context_code)
        yield put(
          actions.markAnnouncementAsRead(null, {
            announcementId: announcement.id,
            courseId,
          })
        )
      } else if (payload.groupId) {
        yield put(
          actions.markAnnouncementAsRead(null, {
            announcementId: announcement.id,
            groupId: payload.groupId,
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
}

function* announcementsMiddleware() {
  yield takeLatest(
    types.ANNOUNCEMENTS_FETCH,
    cancelable(announcementsFetchHandler, [types.ANNOUNCEMENTS_FETCH_CANCEL])
  )
  yield takeLatest(
    types.GROUP_ANNOUNCEMENTS_FETCH,
    cancelable(groupAnnouncementsFetchHandler, [
      types.GROUP_ANNOUNCEMENTS_FETCH_CANCEL,
    ])
  )
  yield takeEvery(
    types.ANNOUNCEMENT_MARK_AS_READ,
    announcementsMarkAsReadHandler
  )
  yield takeEvery(types.ANNOUNCEMENT_OPEN, announcementOpenHandler)
}

export default ([] as any).concat(announcementsMiddleware())
