import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../store'
import {
  AlertsState,
  removeAlertMessage,
} from '../../../providers/AlertsProvider'
import Alert from '../../../components/Utils/Alert'
import ErrorBoundary from '../../../components/Utils/ErrorBoundary'

const Alerts = () => {
  const dispatch = useDispatch()
  const alerts: AlertsState = useSelector((state: AppState) => state.alerts)
  const onAlertClose = () => dispatch(removeAlertMessage())

  if (alerts.data)
    return (
      <ErrorBoundary>
        <Alert
          {...alerts.data}
          onClose={onAlertClose}
          open={alerts.isOpen}
        ></Alert>
      </ErrorBoundary>
    )
  return null
}

export default Alerts
