export const FETCH_USER_PROGRAM_SUPPORT_TICKETS =
  'FETCH_USER_PROGRAM_SUPPORT_TICKETS'
export const FETCH_USER_PROGRAM_SUPPORT_TICKETS_SUCCESS =
  'FETCH_USER_PROGRAM_SUPPORT_TICKETS_SUCCESS'
export const FETCH_USER_PROGRAM_SUPPORT_TICKETS_FAILURE =
  'FETCH_USER_PROGRAM_SUPPORT_TICKETS_FAILURE'
export const FETCH_USER_PROGRAM_SUPPORT_TICKETS_CANCEL =
  'FETCH_USER_PROGRAM_SUPPORT_TICKETS_CANCEL'

export const SUBMIT_USER_PROGRAM_SUPPORT_TICKETS =
  'SUBMIT_USER_PROGRAM_SUPPORT_TICKETS'
export const SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_SUCCESS =
  'SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_SUCCESS'
export const SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_FAILURE =
  'SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_FAILURE'
export const SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_CANCEL =
  'SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_CANCEL'

export const FETCH_PROGRAM_SUPPORT_TICKET_DETAILS =
  'FETCH_PROGRAM_SUPPORT_TICKET_DETAILS'
export const FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_SUCCESS =
  'FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_SUCCESS'
export const FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_FAILURE =
  'FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_FAILURE'
export const FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_CANCEL =
  'FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_CANCEL'

export const SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION =
  'SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION'
export const SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_SUCCESS =
  'SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_SUCCESS'
export const SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_FAILURE =
  'SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_FAILURE'
export const SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_CANCEL =
  'SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_CANCEL'

export const REOPEN_USER_PROGRAM_SUPPORT_TICKET =
  'REOPEN_USER_PROGRAM_SUPPORT_TICKET'
export const REOPEN_USER_PROGRAM_SUPPORT_TICKET_SUCCESS =
  'REOPEN_USER_PROGRAM_SUPPORT_TICKET_SUCCESS'
export const REOPEN_USER_PROGRAM_SUPPORT_TICKET_FAILURE =
  'REOPEN_USER_PROGRAM_SUPPORT_TICKET_FAILURE'
export const REOPEN_USER_PROGRAM_SUPPORT_TICKET_CANCEL =
  'REOPEN_USER_PROGRAM_SUPPORT_TICKET_CANCEL'

export const SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK =
  'SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK'
export const SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_SUCCESS =
  'SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_SUCCESS'
export const SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_FAILURE =
  'SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_FAILURE'
export const SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_CANCEL =
  'SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_CANCEL'
