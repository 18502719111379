import moment, { duration } from 'moment'
import { getDisplayNumber } from './index'
import { getUserProfileData } from '../../web/providers/User/UserDetailsProvider/UserDetails.selectors'
import store from '../../web/store'

export const getDate = (val: string | number) => {
  try {
    if (val) {
      return new Date(val)
    }
  } catch (e) {
    //
  }
  return val
}

export const readableDateWithoutTime = (date: string) => {
  return getDate(date).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
}

export const getCurrentTimeZone = (): string | undefined => {
  const userProfileData = getUserProfileData()(store.getState())
  if (userProfileData && userProfileData.time_zone) {
    return userProfileData.time_zone
  }
  return undefined
}

export const getCurrentTimeZoneAbbreviation = (): string | null => {
  const userProfileData = getUserProfileData()(store.getState())
  if (userProfileData && userProfileData.time_zone) {
    return moment()
      .tz(userProfileData.time_zone)
      .zoneAbbr()
  }
  return null
}

export const timeFormatter = (opts: Intl.DateTimeFormatOptions = {}) =>
  Intl.DateTimeFormat('en-US', {
    hour12: true,
    timeZone: getCurrentTimeZone(),
    ...opts,
  })

export const readableDate = (
  date: number | string,
  options: Intl.DateTimeFormatOptions = {},
  showYear: boolean = false
) => {
  const dateObj = getDate(date)
  if (!(dateObj instanceof Date)) {
    return date
  }
  const isCurrentYear = dateObj.getFullYear() === new Date().getFullYear()
  return timeFormatter({
    year: !isCurrentYear || showYear ? 'numeric' : undefined,
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    ...options,
  }).format(dateObj)
}

export const readableShortDate = (
  date: number | string,
  options: Intl.DateTimeFormatOptions = {},
  showYear: boolean = false,
  showWeekday: boolean = true
) => {
  const dateObj = getDate(date)
  if (!(dateObj instanceof Date)) {
    return date
  }
  const isCurrentYear = dateObj.getFullYear() === new Date().getFullYear()
  return timeFormatter({
    year: !isCurrentYear || showYear ? 'numeric' : undefined,
    day: '2-digit',
    month: 'short',
    weekday: showWeekday ? 'short' : undefined,
    ...options,
  }).format(dateObj)
}

export const readableTime = (
  date: number | string,
  options: Intl.DateTimeFormatOptions = {}
) => {
  const dateObj = getDate(date)
  if (!(dateObj instanceof Date)) {
    return date
  }
  return timeFormatter({
    hour: 'numeric',
    minute: 'numeric',
    ...options,
  }).format(dateObj)
}

const DATE_FORMAT_TYPES = [
  'seconds',
  'minutes',
  'hours',
  'days',
  'weeks',
] as const
type DateTypes = typeof DATE_FORMAT_TYPES[number]
const timeStringMapping: Record<
  'long' | 'medium' | 'short',
  Record<DateTypes, string>
> = {
  long: {
    weeks: 'weeks',
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds',
  },
  medium: {
    weeks: 'week(s)',
    days: 'day(s)',
    hours: 'hr(s)',
    minutes: 'min(s)',
    seconds: 'sec(s)',
  },
  short: { weeks: 'w', days: 'd', hours: 'h', minutes: 'm', seconds: 's' },
}

type timeConverterOptions = {
  minType?: DateTypes
  maxType?: DateTypes
  noOfItems?: number
  includeZeroValue?: boolean
  zeroPrefixed?: boolean
}

export const timeConverter = (
  timeInSeconds: number,
  {
    maxType = 'weeks',
    minType = 'seconds',
    noOfItems = 3,
    includeZeroValue = false,
    zeroPrefixed = false,
  }: timeConverterOptions = {}
) => {
  let duration = timeInSeconds

  const validFormats: DateTypes[] = DATE_FORMAT_TYPES.slice(
    DATE_FORMAT_TYPES.indexOf(minType),
    DATE_FORMAT_TYPES.indexOf(maxType) + 1
  )

  const time: { type: DateTypes; value: number }[] = []
  if (validFormats.includes('weeks')) {
    const weeks = Math.floor(duration / (7 * 24 * 60 * 60))
    if (weeks > 0 || includeZeroValue) {
      time.push({ type: 'weeks', value: weeks })
      duration -= weeks * (7 * 24 * 60 * 60)
    }
  }

  if (validFormats.includes('days')) {
    const days = Math.floor(duration / (24 * 60 * 60))
    if (days > 0 || includeZeroValue) {
      time.push({ type: 'days', value: days })
      duration -= days * (24 * 60 * 60)
    }
  }

  if (validFormats.includes('hours')) {
    const hours = Math.floor(duration / (60 * 60))
    if (hours > 0 || includeZeroValue) {
      time.push({ type: 'hours', value: hours })
      duration -= hours * (60 * 60)
    }
  }

  if (validFormats.includes('minutes')) {
    const minutes = Math.floor(duration / 60)
    if (minutes > 0 || includeZeroValue) {
      time.push({ type: 'minutes', value: minutes })
      duration -= minutes * 60
    }
  }

  if (validFormats.includes('seconds')) {
    const seconds = Math.floor(duration)
    if (seconds > 0 || includeZeroValue)
      time.push({ type: 'seconds', value: seconds })
  }
  return time.slice(0, noOfItems).map(({ type, value }) => {
    let res = getDisplayNumber(value as number)
    if (zeroPrefixed) {
      res = res.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
      })
    }
    return { type, value: res }
  })
}

export const secondsToText = (
  timeInSeconds: number,
  {
    format = 'short',
    space = false,
    ...options
  }: {
    format?: keyof typeof timeStringMapping
    space?: boolean
  } & timeConverterOptions
): string => {
  return timeConverter(timeInSeconds, options)
    .map(({ type, value }) => {
      return `${value}${space ? ' ' : ''}${timeStringMapping[format][type]}`
    })
    .join(' ')
}

export const getDurationString = (durationInSeconds: number): string => {
  const isNegative = durationInSeconds < 0
  const duration = Math.abs(durationInSeconds)
  let hours: string | number = Math.trunc(duration / 3600)
  let minutes: string | number = Math.trunc((duration % 3600) / 60)
  let seconds: string | number = Math.trunc(duration % 60)
  hours = hours < 10 ? `0${hours}` : hours
  minutes = minutes < 10 ? `0${minutes}` : minutes
  seconds = seconds < 10 ? `0${seconds}` : seconds
  return `${isNegative ? '-' : ''}${hours}:${minutes}:${seconds}`
}

export const getDurationStringMinutes = (durationInSeconds: number): string => {
  if (Number.isNaN(durationInSeconds)) return ''

  const isNegative = durationInSeconds < 0
  const duration = Math.abs(durationInSeconds)
  let hours: string | number = Math.trunc(duration / 3600)
  let minutes: string | number = Math.trunc((duration % 3600) / 60)
  let seconds: string | number = Math.trunc(duration % 60)
  hours = hours < 10 ? `0${hours}` : hours
  minutes = minutes < 10 ? `0${minutes}` : minutes
  seconds = seconds < 10 ? `0${seconds}` : seconds
  return durationInSeconds >= 3600
    ? `${isNegative ? '-' : ''}${hours}:${minutes}:${seconds}`
    : `${isNegative ? '-' : ''}${minutes}:${seconds}`
}

export const convertDurationStringToSeconds = (durationString: string) => {
  const split = durationString.split(':') // split it at the colons
  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  return Number(split[0]) * 60 * 60 + Number(split[1]) * 60 + Number(split[2])
}

export const isDateToday = (dateVal: string | number) => {
  return (
    new Date(dateVal).setUTCHours(0, 0, 0, 0) === new Date(dateVal).getTime()
  )
}

export const isDateBeforeToday = (dateVal: string | number) => {
  let date = new Date(dateVal).getTime()
  if (isDateToday(dateVal)) {
    date = new Date(date).setUTCHours(23, 59, 59, 999)
  }
  return date < Date.now()
}

export const getTimeDiff = (timestamp: number) => {
  const currentTime = Math.round(new Date().getTime() / 1000)
  return timestamp - currentTime
}

export const sortByTimeAsc = (
  timeX?: number | string,
  timeY?: number | string
) =>
  new Date(timeX || Number.MAX_SAFE_INTEGER).getTime() -
  new Date(timeY || Number.MAX_SAFE_INTEGER).getTime()

export const isTimeBefore24hrs = (timestamp: number) => {
  const tsYesterday = new Date().getTime() - 24 * 3600 * 1000
  return timestamp < tsYesterday
}

export const isTimeInFuture = (dateVal: string | number) => {
  const date = new Date(dateVal).getTime()
  return date > Date.now()
}
export const isCurrentDateToday = (dateVal: string | number) => {
  const days = moment().diff(moment(dateVal), 'days')
  return days === 0
}

export const timeDifferenceFromNow = (dateVal: string | number) => {
  if (isTimeInFuture(dateVal)) {
    if (isCurrentDateToday(dateVal)) {
      const hours = moment().diff(moment(dateVal), 'hours') * -1
      return `${hours === 1 ? '1 hour' : `${hours} hours`} to go`
    }
    const days = moment().diff(moment(dateVal), 'days') * -1
    return `${days === 1 ? '1 day' : `${days} days`} to go`
  }

  if (!isCurrentDateToday(dateVal)) {
    const days = moment().diff(moment(dateVal), 'days')
    return `${days === 1 || days === 0 ? '1 day' : `${days} days`} ago`
  }

  const hours = moment().diff(moment(dateVal), 'hours')
  return `${hours === 1 ? '1 hour' : `${hours} hours`} ago`
}

export const timeDifferenceFromNowInDays = (dateVal: string | number) => {
  const days = moment().diff(moment(dateVal), 'days')
  return days
}

export const getDiffrenceOfTwoTimeInSeconds = (
  start_date_time: string,
  end_date_time: string
): any => {
  const start_date = moment(start_date_time)
  const end_date = moment(end_date_time)
  const duration1 = duration(end_date.diff(start_date))

  return duration1.asSeconds()
}

export const secsRemainedToday = () => {
  const d = new Date()
  const h = d.getHours()
  const m = d.getMinutes()
  const s = d.getSeconds()
  return 24 * 60 * 60 - h * 60 * 60 - m * 60 - s
}

export const timeAgoInWords = (pastDate: string | Date) => {
  const now = moment(Date.now())
  const diff = moment(now).diff(pastDate)

  const durationTime = moment.duration(diff)

  const years = durationTime.years()
  const months = durationTime.months()
  const days = durationTime.days()
  const hours = durationTime.hours()
  const minutes = durationTime.minutes()

  if (years > 0) {
    return `${years} years ago`
  }
  if (months > 0) {
    return `${months} months ago`
  }
  if (days > 0) {
    return `${days} days ago`
  }
  if (hours > 0) {
    return `${hours} hours ago`
  }
  if (minutes > 0) {
    return `${minutes} minutes ago`
  }

  return 'less than a minute ago'
}

export const formatSecondsToHMS = (seconds: number) => {
  const hrs = Math.floor(seconds / 3600)
  const mins = Math.floor((seconds % 3600) / 60)
  const secs = seconds % 60
  return `${hrs}h:${mins < 10 ? '0' : ''}${mins}m:${
    secs < 10 ? '0' : ''
  }${secs}s`
}
