import { put, cancelled, takeEvery, call, select } from 'redux-saga/effects'
import { cancelable, generateURL } from 'common/utils'
import { ProgramData } from 'common/types/programs'
import { history } from 'web/store/history'
import {
  FETCH_FAQS,
  FETCH_NINJA_FAQS,
  FETCH_FAQS_CANCEL,
  FETCH_INDIVIDUAL_FAQ_AND_RECOMMENDATIONS,
  FETCH_INDIVIDUAL_FAQ_AND_RECOMMENDATIONS_CANCEL,
  ADD_NEW_FAQ,
  ADD_NEW_FAQ_CANCEL,
  EDIT_FAQ,
  EDIT_FAQ_CANCEL,
  DELETE_FAQ,
  DELETE_FAQ_CANCEL,
} from './ProgramSupportFaqs.types'
import {
  addNewFaq,
  fetchFaqs,
  fetchFaqsFailure,
  fetchFaqsSuccess,
  fetchIndividualFaqAndRecommendations,
  addNewFaqSuccess,
  addNewFaqFailure,
  editFaq,
  editFaqFailure,
  editFaqSuccess,
  deleteFaq,
  deleteFaqFailure,
  deleteFaqSuccess,
} from './ProgramSupportFaqs.actions'
import {
  fetchFaqsAPI,
  fetchNinjaFaqsAPI,
  fetchIndividualFaqsAndRecoomendationAPI,
  addNewFaqAPI,
  editFaqAPI,
  deleteFaqAPI,
} from './ProgramSupportFaqs.api'
import { programSelectors } from '../Dashboard/ProgramsProvider'
import { showAlertMessage } from '../AlertsProvider'

function* fetchFaqsHandler(action: ReturnType<typeof fetchFaqs>) {
  const abortController = new AbortController()
  try {
    const activeProgram: ProgramData | null = yield select(
      programSelectors.getActiveProgramDetails()
    )
    const programId =
      activeProgram && activeProgram.program_id ? activeProgram.program_id : 0
    const response = yield call(
      fetchFaqsAPI,
      action,
      programId,
      abortController.signal
    )

    yield put(fetchFaqsSuccess(response))
  } catch (e) {
    yield put(fetchFaqsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchNinjaFaqsHandler(action: ReturnType<typeof fetchFaqs>) {
  const abortController = new AbortController()
  try {
    const response = yield call(
      fetchNinjaFaqsAPI,
      action,
      abortController.signal
    )

    yield put(fetchFaqsSuccess(response))
  } catch (e) {
    yield put(fetchFaqsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchIndividualFaqAndRecommendationsHandler(
  action: ReturnType<typeof fetchIndividualFaqAndRecommendations>
) {
  const abortController = new AbortController()
  try {
    const response = yield call(
      fetchIndividualFaqsAndRecoomendationAPI,
      action.payload.faqId,
      abortController.signal
    )

    yield put(fetchFaqsSuccess(response))
  } catch (e) {
    yield put(fetchFaqsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* addNewFaqHandler(action: ReturnType<typeof addNewFaq>) {
  const abortController = new AbortController()
  try {
    const response = yield call(addNewFaqAPI, action, abortController.signal)

    yield put(addNewFaqSuccess(response))
    history.push(
      generateURL('PROGRAM_SUPPORT_FAQ', {
        path: {
          faqId: response.id,
          categoryId: 0,
        },
      })
    )
  } catch (e) {
    yield put(addNewFaqFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message:
          'Oops! please check all required details are filled correctly and check permissions to add the FAQs',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* editFaqHandler(action: ReturnType<typeof editFaq>) {
  const abortController = new AbortController()
  try {
    const response = yield call(editFaqAPI, action, abortController.signal)

    yield put(editFaqSuccess(response))

    history.push(
      generateURL('PROGRAM_SUPPORT_FAQ', {
        path: {
          faqId: response.id,
          categoryId: 0,
        },
      })
    )
  } catch (e) {
    yield put(editFaqFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message:
          'Oops! please check all required details are filled correctly and check permissions to edit the FAQ',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* deleteFaqHandler(action: ReturnType<typeof deleteFaq>) {
  const abortController = new AbortController()
  try {
    const response = yield call(deleteFaqAPI, action, abortController.signal)

    yield put(deleteFaqSuccess(response))

    history.push(generateURL('PROGRAM_SUPPORT_FAQ_SEARCH', null))
  } catch (e) {
    yield put(deleteFaqFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message:
          'Oops! please check all required details are filled correctly and check permissions to delete the FAQ',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* programSupportFaqsMiddleware() {
  yield takeEvery(FETCH_FAQS, cancelable(fetchFaqsHandler, [FETCH_FAQS_CANCEL]))
  yield takeEvery(
    FETCH_NINJA_FAQS,
    cancelable(fetchNinjaFaqsHandler, [FETCH_FAQS_CANCEL])
  )
  yield takeEvery(
    FETCH_INDIVIDUAL_FAQ_AND_RECOMMENDATIONS,
    cancelable(fetchIndividualFaqAndRecommendationsHandler, [
      FETCH_INDIVIDUAL_FAQ_AND_RECOMMENDATIONS_CANCEL,
    ])
  )

  yield takeEvery(
    ADD_NEW_FAQ,
    cancelable(addNewFaqHandler, [ADD_NEW_FAQ_CANCEL])
  )
  yield takeEvery(EDIT_FAQ, cancelable(editFaqHandler, [EDIT_FAQ_CANCEL]))
  yield takeEvery(DELETE_FAQ, cancelable(deleteFaqHandler, [DELETE_FAQ_CANCEL]))
}

export default ([] as any).concat(programSupportFaqsMiddleware())
