import {
  submitResetPassword,
  submitTimeZone,
  updateProfessionalDetails,
  alumniMessageSwitch,
} from '.'
import { ObjectWithStringValues } from '../../../../common/types/utils'
import {
  apiCall,
  CustomError,
  getUserDetails,
  objectToFormData,
} from '../../../../common/utils'
import {
  submitMobileOTP,
  submitPersonalDetails,
  triggerMobileOTP,
} from './UserSettings.actions'

export const submitPersonalDetailsAPI = async (
  action: ReturnType<typeof submitPersonalDetails>,
  signal: AbortSignal
) => {
  const formData = new FormData()
  if (action.payload.fullName !== undefined) {
    formData.append('full_name', action.payload.fullName)
  }
  if (action.payload.email !== undefined) {
    formData.append('email', action.payload.email)
  }
  if (action.payload.mobile !== undefined) {
    formData.append('mobile', action.payload.mobile)
  }
  if (action.payload.timeZone !== undefined) {
    formData.append('time_zone', action.payload.timeZone)
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/gl/update_profile_info`,
    params: { signal, method: 'PUT', body: formData },
  })

  if (response.ok) {
    return response.json()
  }

  if (response.status === 400) {
    const errorObj: ObjectWithStringValues = {}
    try {
      const data = await response.json()
      if (data.errors) {
        data.errors.forEach((error: object) => {
          Object.entries(error).forEach(
            ([errorKey, errorVal]: [string, string]) => {
              errorObj[errorKey] = errorVal
            }
          )
        })
      }
    } catch (e) {
      //
    }
    if (Object.keys(errorObj).length) {
      throw new CustomError('Profile details update failed', errorObj)
    }
  }
  throw response
}

export const fetchUserAvatarsAPI = async (
  signal: AbortSignal,
  fileUploadId: number
) => {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/users/self/avatars`,
    params: { signal },
  })
  if (response.ok) {
    const data = await response.json()
    if (data.length) {
      const avatarRecord = data.find(
        (avatar: any) => avatar.id === fileUploadId
      )
      if (avatarRecord) {
        return avatarRecord.token
      }
      throw new Error('Avatar record not found')
    }
  }
  throw response
}
export const updateProfileImageAPI = async (
  token: string,
  signal: AbortSignal
) => {
  const body = {
    user: {
      avatar: {
        token,
      },
    },
  }
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/users/self`,
    params: { signal, method: 'PUT', body: JSON.stringify(body) },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
export const submitResetPasswordAPI = async (
  action: ReturnType<typeof submitResetPassword>,
  loginId: string,
  signal: AbortSignal
) => {
  const formData = new FormData()
  formData.append('login_id', loginId)
  formData.append('old_password', action.payload.oldPassword)
  formData.append('password', action.payload.newPassword)
  formData.append('password_confirmation', action.payload.confirmPassword)

  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/gl/update_password`,
    params: { signal, method: 'PUT', body: formData },
  })
  if (response.ok) {
    return response.json()
  }
  if (response.status === 400) {
    const data = await response.json()
    if ('message' in data) {
      throw new CustomError(data.message, data)
    }
  }
  throw response
}

export const fetchTimeZonesAPI = async (signal: AbortSignal) => {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/gl/time_zones`,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export const submitTimeZoneAPI = async (
  action: ReturnType<typeof submitTimeZone>,
  signal: AbortSignal
) => {
  const formData = new FormData()
  formData.append('time_zone', action.payload.timeZone)

  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/gl/time_zones`,
    params: { method: 'PUT', body: formData, signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export const triggerMobileOTPAPI = async (
  action: ReturnType<typeof triggerMobileOTP>,
  signal: AbortSignal
) => {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/send_mobile_otp`,
    query: {
      user_id: id,
    },
    params: {
      body: objectToFormData({
        mobile: action.payload.mobile,
        lms_user_id: id,
        triggered_from: 'aspire_app',
        domain_name: window.location.hostname,
      }),
      method: 'POST',
      signal,
    },
  })
  if (response.ok) {
    const data = await response.json()
    if (data.success) return data
    throw new CustomError(data.msg, data)
  }
  throw response
}

export const submitMobileOTPAPI = async (
  action: ReturnType<typeof submitMobileOTP>,
  signal: AbortSignal
) => {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/verify_mobile_otp`,
    query: {
      user_id: id,
    },
    params: {
      body: objectToFormData({
        mobile_otp: action.payload.mobileOTP,
        lms_user_id: id,
        mobile_verified_from: 'aspire_app',
        mobile: action.payload.mobile,
      }),
      method: 'POST',
      signal,
    },
  })
  if (response.ok) {
    const data = await response.json()
    if (data.success) return data
    throw new CustomError(data.msg, data)
  }
  throw response
}

export async function updateProfessionalDetailAPI(
  action: ReturnType<typeof updateProfessionalDetails>,
  payload: any,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const professionalDetailParams = {
    linkedin_url: payload.linkedin_url,
    experience: payload.total_experience,
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/linkedin_profile`,
    params: {
      method: 'PUT',
      signal,
      body: JSON.stringify(professionalDetailParams),
    },
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function alumniOptOutAPI(
  action: ReturnType<typeof alumniMessageSwitch>,
  state: boolean,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const AlumniOptOutParams = JSON.stringify({
    state,
  })

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/alumni_portal_message_toggle`,
    params: {
      method: 'PUT',
      signal,
      body: AlumniOptOutParams,
    },
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}
