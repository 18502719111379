import { FslLeaderData } from 'common/types/learnAndDev/leaderboard'
import { Reducer } from 'redux'
import { LeaderboardActions } from './Leaderboard.actions'
import {
  FETCH_LEADERBOARD_CONTENT,
  FETCH_LEADERBOARD_CONTENT_CANCEL,
  FETCH_LEADERBOARD_CONTENT_FAILURE,
  FETCH_LEADERBOARD_CONTENT_SUCCESS,
} from './Leaderboard.types'

export interface FslLeaderboardState {
  data: FslLeaderData[]
  totalData: number
  loading: boolean
  error: false | Error | Response
}

const initialState: FslLeaderboardState = {
  data: [],
  totalData: 0,
  loading: false,
  error: false,
}

const fslLeaderboardReducer: Reducer<
  FslLeaderboardState,
  LeaderboardActions
> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEADERBOARD_CONTENT:
      return { ...state, loading: true, error: false }

    case FETCH_LEADERBOARD_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.leader_board_data,
        totalData: action.payload.total_leaderboard_data_list,
      }

    case FETCH_LEADERBOARD_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case FETCH_LEADERBOARD_CONTENT_CANCEL:
      return {
        ...state,
        loading: false,
        error: false,
      }

    default:
      return state
  }
}

export default fslLeaderboardReducer
