import { Box } from '@material-ui/core'
import React from 'react'
import { useIsNewUIEnabled } from 'web/hooks'
import {
  Activities,
  Announcements,
  Community,
  Connect,
  Courses,
  Excelerate,
  Gradebook,
  Hackathons,
  Home,
  Leaderboard,
  PeerReviews,
  Support,
  Visa,
} from './more-cta'
import { SwitchBatch } from './switch-batch'

export function More({
  onMenuPress,
  isAccessBlocked,
}: {
  onMenuPress: () => void
  isAccessBlocked: boolean
}) {
  const isNewUIEnabled = useIsNewUIEnabled()

  return (
    <Box display="flex" flexDirection="column" padding={2} gridGap={16}>
      <SwitchBatch />
      <Box
        display="flex"
        flexDirection="column"
        gridGap={8}
        onClick={onMenuPress}
      >
        {!isAccessBlocked && (
          <>
            <Home />
            {isNewUIEnabled && <Activities />}
            <Courses />
            <Excelerate />
            <PeerReviews />
            <Announcements />
            <Gradebook />
            <Leaderboard />
            <Connect />
            <Community />
            <Hackathons />
            <Visa />
          </>
        )}
        <Support />
      </Box>
    </Box>
  )
}
