export const COURSE_MENTORSHIP_RECORDINGS_FETCH =
  'COURSE_MENTORSHIP_RECORDINGS_FETCH'
export const COURSE_MENTORSHIP_RECORDINGS_FETCH_SUCCESS =
  'COURSE_MENTORSHIP_RECORDINGS_FETCH_SUCCESS'
export const COURSE_MENTORSHIP_RECORDINGS_FETCH_FAILURE =
  'COURSE_MENTORSHIP_RECORDINGS_FETCH_FAILURE'

export const SESSION_MENTORSHIP_RECORDINGS_FETCH =
  'SESSION_MENTORSHIP_RECORDINGS_FETCH'
export const SESSION_MENTORSHIP_RECORDINGS_FETCH_SUCCESS =
  'SESSION_MENTORSHIP_RECORDINGS_FETCH_SUCCESS'
export const SESSION_MENTORSHIP_RECORDINGS_FETCH_FAILURE =
  'SESSION_MENTORSHIP_RECORDINGS_FETCH_FAILURE'

export const MENTORSHIP_RECORDING_DETAIL_FETCH =
  'MENTORSHIP_RECORDING_DETAIL_FETCH'
export const MENTORSHIP_RECORDING_DETAIL_SUCCESS =
  'MENTORSHIP_RECORDING_DETAIL_FETCH_SUCCESS'
export const MENTORSHIP_RECORDING_DETAIL_FAILURE =
  'MENTORSHIP_RECORDING_DETAIL_FAILURE'

export const UPDATE_MENTORSHIP_RECORDING_VIDEO_WATCHED_PERCENTAGE =
  'UPDATE_MENTORSHIP_RECORDING_VIDEO_WATCHED_PERCENTAGE'
