import {
  call,
  cancelled,
  put,
  select,
  takeLatest,
  delay,
  takeEvery,
} from 'redux-saga/effects'
import { UserID } from '../../../common/types/user'
import { cancelable, isNetworkError } from '../../../common/utils'
import { userDetailsSelectors } from '../User/UserDetailsProvider'
import {
  endProctoringFailure,
  endProctoringSuccess,
  initiateProctoring,
  initiateProctoringFailure,
  initiateProctoringSuccess,
  updateUserProctorInfoFailure,
  updateUserProctorInfoSuccess,
  endProctoring,
  updateUserProctorInfo,
  updateProctoringDetails,
  updateProctoringDetailsSuccess,
  updateProctoringDetailsFailure,
  submitOnboardingPhoto,
  submitOnboardingPhotoFailure,
  submitOnboardingPhotoSuccess,
  pauseProctoring,
  continueProctoring,
} from './Proctoring.actions'
import {
  endProctoringAPI,
  initiateProctoringAPI,
  updateUserProctorInfoAPI,
  updateProctoringDetailsAPI,
  submitOnboardingPhotoAPI,
} from './Proctoring.api'
import {
  getProctorAttemptId,
  getProctorAssessmentId,
  getCurrentProctorCount,
} from './Proctoring.selectors'
import * as types from './Proctoring.types'
import { proctorCodingLabAPI } from '../CodingLabs/CodingLabs.api'

function* initiateProctorHandler(
  action: ReturnType<typeof initiateProctoring>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID | null = yield select(userDetailsSelectors.getUserID())
    if (!userId) {
      throw new Error('User ID Unavailable')
    }

    const data = yield call(
      initiateProctoringAPI,
      userId,
      action,
      abortController.signal
    )

    yield put(
      initiateProctoringSuccess(data, {
        type: action.payload.is_coding_lab_assignment ? 'Assignment' : 'Quiz',
      })
    )
  } catch (e) {
    yield put(initiateProctoringFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* updateProctoringDetailsHandler(
  action: ReturnType<typeof updateProctoringDetails>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID | null = yield select(userDetailsSelectors.getUserID())
    if (!userId) {
      throw new Error('User ID Unavailable')
    }

    const data = yield call(
      updateProctoringDetailsAPI,
      userId,
      action,
      abortController.signal
    )

    yield put(updateProctoringDetailsSuccess(data))
  } catch (e) {
    yield put(updateProctoringDetailsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* getProctorInfo() {
  const user_id: UserID | null = yield select(userDetailsSelectors.getUserID())
  const attempt_id: number | null = yield select(getProctorAttemptId())
  const assessment_id: number | null = yield select(getProctorAssessmentId())
  if (!user_id) {
    throw new Error('User ID Unavailable')
  }

  if (!attempt_id || !assessment_id) {
    throw new Error('Proctoring data unavailable')
  }
  return {
    user_id,
    attempt_id,
    assessment_id,
  }
}

function* updateUserProctorInfoHandler(
  action: ReturnType<typeof updateUserProctorInfo>
) {
  const abortController = new AbortController()
  try {
    const params = yield getProctorInfo()
    params.count = yield select(getCurrentProctorCount())
    if (action.payload.assessment_type) {
      params.assessment_type = action.payload.assessment_type
    }
    const data = yield call(
      updateUserProctorInfoAPI,
      params,
      abortController.signal
    )
    yield put(updateUserProctorInfoSuccess(data))
  } catch (e) {
    yield put(updateUserProctorInfoFailure(e))
    if (isNetworkError(e.toString())) {
      yield delay(10000)
      yield put(updateUserProctorInfo({}))
    }
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
function* endProctoringHandler(action: ReturnType<typeof endProctoring>) {
  const abortController = new AbortController()
  try {
    const params = yield getProctorInfo()
    params.completion_type = action.payload.completionType

    if (action.payload.assessment_type) {
      params.assessment_type = action.payload.assessment_type
    }

    const data = yield call(endProctoringAPI, params, abortController.signal)
    yield put(endProctoringSuccess(data))
  } catch (e) {
    yield put(endProctoringFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
function* submitOnboardingPhotoHandler(
  action: ReturnType<typeof submitOnboardingPhoto>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      submitOnboardingPhotoAPI,
      action.payload,
      abortController.signal
    )
    yield put(submitOnboardingPhotoSuccess(data))
  } catch (e) {
    yield put(submitOnboardingPhotoFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* pauseProctoringHandler(action: ReturnType<typeof pauseProctoring>) {
  const abortController = new AbortController()
  if (
    action.payload &&
    action.payload.type === 'coding_lab' &&
    action.payload.codingLabAssignmentId
  ) {
    try {
      yield call(
        proctorCodingLabAPI,
        action.payload.codingLabAssignmentId,
        'proctoring_paused',
        {},
        action.payload.is_focused || false
      )
    } catch (e) {
      console.error(`Error in pauseProctoringHandler: ${e}`)
    } finally {
      if (cancelled()) {
        abortController.abort()
      }
    }
  }
}

function* continueProctoringHandler(
  action: ReturnType<typeof continueProctoring>
) {
  if (
    action.payload &&
    action.payload.type === 'coding_lab' &&
    action.payload.codingLabAssignmentId
  ) {
    const abortController = new AbortController()
    try {
      yield call(
        proctorCodingLabAPI,
        action.payload.codingLabAssignmentId,
        'proctoring_resumed',
        {},
        action.payload.is_focused || false
      )
    } catch (e) {
      console.error(`Error in continueProctoringHandler: ${e}`)
    } finally {
      if (cancelled()) {
        abortController.abort()
      }
    }
  }
}

export function* initiateProctorMiddleware() {
  yield takeLatest(
    types.INITIATE_PROCTORING,
    cancelable(initiateProctorHandler, types.INITIATE_PROCTORING_CANCEL)
  )
  yield takeLatest(
    types.UPDATE_PROCTORING_DETAILS,
    cancelable(
      updateProctoringDetailsHandler,
      types.UPDATE_PROCTORING_DETAILS_CANCEL
    )
  )
}

export function* updateUserProctorInfoMiddleware() {
  yield takeEvery(
    types.UPDATE_USER_PROCTOR_INFO,
    cancelable(updateUserProctorInfoHandler, [
      types.UPDATE_USER_PROCTOR_INFO_CANCEL,
      types.UPDATE_USER_PROCTOR_INFO,
    ])
  )
}

export function* endProctoringMiddleware() {
  yield takeLatest(
    types.END_PROCTORING,
    cancelable(endProctoringHandler, types.END_PROCTORING_CANCEL)
  )
}

export function* submitOnboardingPhotoMiddleware() {
  yield takeEvery(
    types.SUBMIT_ONBOARDING_PHOTO,
    cancelable(
      submitOnboardingPhotoHandler,
      types.SUBMIT_ONBOARDING_PHOTO_CANCEL
    )
  )
}

// pause_proctoring and resume_proctoring
export function* pauseProctoringMiddleware() {
  yield takeLatest(
    types.PAUSE_PROCTORING,
    cancelable(pauseProctoringHandler, types.PAUSE_PROCTORING_CANCEL)
  )
}

export function* continueProctoringMiddleware() {
  yield takeLatest(
    types.CONTINUE_PROCTORING,
    cancelable(continueProctoringHandler, types.CONTINUE_PROCTORING_CANCEL)
  )
}

export default ([] as any).concat(
  initiateProctorMiddleware(),
  updateUserProctorInfoMiddleware(),
  endProctoringMiddleware(),
  submitOnboardingPhotoMiddleware(),
  pauseProctoringMiddleware(),
  continueProctoringMiddleware()
)
