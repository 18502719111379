import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import {
  fetchProgramCategorySuccess,
  fetchProgramCategoryFailure,
} from './ProgramCategory.actions'
import * as APIs from './ProgramCategory.api'
import * as types from './ProgramCategory.types'

function* getProgramCategory() {
  const abortController = new AbortController()
  try {
    const data = yield call(APIs.fetchProgramCategory, abortController.signal)
    yield put(fetchProgramCategorySuccess(data))
  } catch (e) {
    yield put(fetchProgramCategoryFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* programCategoryMiddleware() {
  yield takeLatest(
    types.FETCH_PROGRAM_CATEGORY_DETAILS,
    cancelable(getProgramCategory, types.FETCH_PROGRAM_CATEGORY_DETAILS_CANCEL)
  )
}

export default ([] as any).concat(programCategoryMiddleware())
