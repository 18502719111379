import { call, cancelled, put, takeLatest, select } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import { ExcelerateCareerPrepApiData } from 'common/types/excelerate/careerPrep'
import {
  fetchExcelerateCareerPrepDetails,
  fetchExcelerateCareerPrepDetailsFailure,
  fetchExcelerateCareerPrepDetailsSuccess,
  submitExcelerateFeedback,
  submitExcelerateFeedbackSuccess,
  submitExcelerateFeedbackFailure,
} from './CareerPrep.actions'
import { UserID } from '../../../../common/types/user'
import { showAlertMessage } from '../../AlertsProvider'
import {
  fetchExcelerateCareerPrepDetailsAPI,
  submitExcelerateFeedbackAPI,
} from './CareerPrep.api'
import { userDetailsSelectors } from '../../User/UserDetailsProvider'
import {
  FETCH_EXCELERATE_CAREER_PREP_DETAILS,
  FETCH_EXCELERATE_CAREER_PREP_DETAILS_CANCEL,
  SUBMIT_EXCELERATE_FEEDBACK,
  SUBMIT_EXCELERATE_FEEDBACK_CANCEL,
} from './CareerPrep.types'

function* fetchExcelerateCareerPrepDetailsHandler(
  action: ReturnType<typeof fetchExcelerateCareerPrepDetails>
) {
  const abortController = new AbortController()
  try {
    const data: ExcelerateCareerPrepApiData = yield call(
      fetchExcelerateCareerPrepDetailsAPI,
      action,
      abortController.signal
    )
    yield put(fetchExcelerateCareerPrepDetailsSuccess(data))
  } catch (error) {
    yield put(fetchExcelerateCareerPrepDetailsFailure(error))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitExcelerateFeedbackHandler(
  action: ReturnType<typeof submitExcelerateFeedback>
) {
  const abortController = new AbortController()
  const userId: UserID | null = yield select(userDetailsSelectors.getUserID())
  try {
    const resultObject: any = {
      user_id: userId,
      course_id: action.payload.course_id,
      rating: action.payload.rating,
      options: action.payload.options,
      comments: action.payload.comments,
    }

    const data = yield call(
      submitExcelerateFeedbackAPI,
      resultObject,
      abortController.signal
    )

    yield put(submitExcelerateFeedbackSuccess())
    yield put(
      showAlertMessage({
        variant: 'success',
        message: data.message,
      })
    )
  } catch (e) {
    yield put(submitExcelerateFeedbackFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Feedback submission failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fetchExcelerateMiddleware() {
  yield takeLatest(
    FETCH_EXCELERATE_CAREER_PREP_DETAILS,
    cancelable(
      fetchExcelerateCareerPrepDetailsHandler,
      FETCH_EXCELERATE_CAREER_PREP_DETAILS_CANCEL
    )
  )
}

export function* submitExcelerateFeedbackMiddleware() {
  yield takeLatest(
    SUBMIT_EXCELERATE_FEEDBACK,
    cancelable(
      submitExcelerateFeedbackHandler,
      SUBMIT_EXCELERATE_FEEDBACK_CANCEL
    )
  )
}

export default ([] as any).concat(
  fetchExcelerateMiddleware(),
  submitExcelerateFeedbackMiddleware()
)
