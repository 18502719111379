import { Reducer } from 'redux'
import { UserSettingsAction } from '.'
import { TimeZonesData } from '../../../../common/types/settings'
import * as types from './UserSettings.types'
import { CustomError } from '../../../../common/utils'

export interface UserSettingsState {
  personalDetails: {
    loading: boolean
    error:
      | false
      | Error
      | Response
      | CustomError<{ user?: string; user_email?: string; time_zone?: string }>
    status?: 'submitted' | null
    image?: {
      loading: boolean
      error: false | Error | Response
    }
    mobileOTP?:
      | {
          trigger: {
            loading: boolean
            error: false | Error | Response | CustomError<{}>
          }
        }
      | {
          submit: {
            loading: boolean
            error: false | Error | Response | CustomError<{}>
          }
        }
  }
  resetPassword: {
    loading: boolean
    error: false | Error | Response
  }
  timeZone: {
    data?: {
      timeZones?: TimeZonesData[]
    }
    loading: boolean
    error: false | Error | Response
  }
}

const initialSate = {
  personalDetails: { loading: false, error: false as false },
  resetPassword: {
    loading: false,
    error: false as false,
  },
  timeZone: { loading: false, error: false as false },
}
const UserSettingsReducer: Reducer<UserSettingsState, UserSettingsAction> = (
  state = initialSate,
  action
): UserSettingsState => {
  switch (action.type) {
    case types.SUBMIT_PERSONAL_DETAILS:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          loading: true,
          error: false,
        },
      }
    case types.UPDATE_PROFESSIONAL_DETAILS:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          loading: true,
        },
      }
    case types.UPDATE_PROFESSIONAL_DETAILS_SUCCESS:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          loading: false,
        },
      }
    case types.UPDATE_PROFESSIONAL_DETAILS_FAILURE:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          loading: false,
        },
      }
    case types.UPDATE_PROFESSIONAL_DETAILS_CANCEL:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          loading: false,
        },
      }
    case types.SUBMIT_PERSONAL_DETAILS_SUCCESS:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          loading: false,
          status: 'submitted',
        },
      }

    case types.SUBMIT_PERSONAL_DETAILS_FAILURE:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          loading: false,
          error: action.payload,
        },
      }
    case types.SUBMIT_PERSONAL_DETAILS_CANCEL:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          loading: false,
        },
      }
    case types.CLEAR_SUBMITTED_STATUS:
      return {
        ...state,
        personalDetails: initialSate.personalDetails,
      }

    case types.UPDATE_PROFILE_IMAGE:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          image: {
            ...state.personalDetails.image,
            loading: true,
            error: false,
          },
        },
      }

    case types.UPDATE_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          image: {
            ...state.personalDetails.image,
            loading: false,
            error: false,
          },
        },
      }

    case types.UPDATE_PROFILE_IMAGE_FAILURE:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          image: {
            ...state.personalDetails.image,
            loading: false,
            error: action.payload,
          },
        },
      }
    case types.UPDATE_PROFILE_IMAGE_CANCEL:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          image: {
            ...state.personalDetails.image,
            loading: false,
            error: false,
          },
        },
      }
    case types.TRIGGER_MOBILE_OTP:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          mobileOTP: {
            trigger: {
              loading: true,
              error: false,
            },
          },
        },
      }

    case types.TRIGGER_MOBILE_OTP_SUCCESS:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          mobileOTP: {
            trigger: {
              loading: false,
              error: false,
            },
          },
        },
      }

    case types.TRIGGER_MOBILE_OTP_FAILURE:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          mobileOTP: {
            trigger: {
              loading: false,
              error: action.payload,
            },
          },
        },
      }
    case types.TRIGGER_MOBILE_OTP_CANCEL:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          mobileOTP: {
            trigger: {
              loading: false,
              error: false,
            },
          },
        },
      }
    case types.SUBMIT_MOBILE_OTP:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          mobileOTP: {
            submit: {
              loading: true,
              error: false,
            },
          },
        },
      }

    case types.SUBMIT_MOBILE_OTP_SUCCESS:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          mobileOTP: {
            submit: {
              loading: false,
              error: false,
            },
          },
        },
      }

    case types.SUBMIT_MOBILE_OTP_FAILURE:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          mobileOTP: {
            submit: {
              loading: false,
              error: action.payload,
            },
          },
        },
      }
    case types.SUBMIT_MOBILE_OTP_CANCEL:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          mobileOTP: {
            submit: {
              loading: false,
              error: false,
            },
          },
        },
      }

    case types.SUBMIT_RESET_PASSWORD:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: true,
          error: false,
        },
      }

    case types.SUBMIT_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
        },
      }

    case types.SUBMIT_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
          error: action.payload,
        },
      }
    case types.SUBMIT_RESET_PASSWORD_CANCEL:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
        },
      }

    case types.FETCH_TIME_ZONES: {
      return {
        ...state,
        timeZone: {
          ...state.timeZone,
          loading: true,
          error: false,
        },
      }
    }
    case types.FETCH_TIME_ZONES_SUCCESS: {
      return {
        ...state,
        timeZone: {
          ...state.timeZone,
          loading: false,
          data: {
            ...state.timeZone.data,
            timeZones: action.payload,
          },
        },
      }
    }
    case types.FETCH_TIME_ZONES_FAILURE: {
      return {
        ...state,
        timeZone: {
          ...state.timeZone,
          loading: false,
          error: action.payload,
        },
      }
    }
    case types.FETCH_TIME_ZONES_CANCEL: {
      return {
        ...state,
        timeZone: {
          ...state.timeZone,
          loading: false,
        },
      }
    }

    case types.SUBMIT_TIME_ZONE:
      return {
        ...state,
        timeZone: {
          ...state.timeZone,
          loading: true,
          error: false,
        },
      }

    case types.SUBMIT_TIME_ZONE_SUCCESS:
      return {
        ...state,
        timeZone: {
          ...state.timeZone,
          loading: false,
          data: {
            ...state.timeZone.data,
          },
        },
      }

    case types.SUBMIT_TIME_ZONE_FAILURE:
      return {
        ...state,
        timeZone: {
          ...state.timeZone,
          loading: false,
          error: action.payload,
        },
      }
    case types.SUBMIT_TIME_ZONE_CANCEL:
      return {
        ...state,
        timeZone: {
          ...state.timeZone,
          loading: false,
        },
      }
    default:
      return state
  }
}

export default UserSettingsReducer
