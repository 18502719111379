import React, { Fragment, useEffect, useRef, useState } from 'react'
import { matchPath, RouteComponentProps, withRouter } from 'react-router-dom'
import useProgramSwitch from 'common/utils/custom/ProgramSwitch'
import { FeatureLinksData } from 'common/types/dashboard'
import { Typography } from '@material-ui/core'
import { parse } from 'query-string'
import { useIsNewUIEnabled, useReloadPath } from 'web/hooks'
import { UserNotificationsState } from 'web/providers/User/UserNotificationsProvider'
import { ProgramData, ProgramID } from '../../../common/types/programs'
import { generateURL, isNetworkError } from '../../../common/utils'
import { mixpanel } from '../../../common/utils/mixpanel'
import AccessBlocked from '../../components/AccessBlocked'
import { updateActiveProgram } from '../../providers/Dashboard/ProgramsProvider'
import { feedbackPopupStatus } from '../../providers/FeedbackProvider'
import { UserAdmissionState } from '../../providers/User/UserAdmissionProvider'
import { UserDetailsState } from '../../providers/User/UserDetailsProvider'
import {
  UserOnboardingState,
  updateUserOnboardingRedirectUrl,
} from '../../providers/User/UserOnboardingProvider'
import routes from '../../routes'

interface Props extends RouteComponentProps {
  children: React.ReactNode
  admission: UserAdmissionState
  onboarding: UserOnboardingState
  userDetails: UserDetailsState
  notifications: UserNotificationsState
  programData: ProgramData | null
  feedbackPopupStatus: typeof feedbackPopupStatus
  updateActiveProgram: typeof updateActiveProgram
  updateUserOnboardingRedirectUrl: typeof updateUserOnboardingRedirectUrl
  glAcademyId: ProgramID | null
  enrolledPrograms: ProgramID[] | null
  isCareerSchool: boolean
  featureLinks: FeatureLinksData
  isNewProgramSupportEnabled: boolean
}
const AppContentsHandler = (props: Props) => {
  const uniqueBuildId = useRef('')
  const needsReload = useRef(false)

  const [currentChildren, setCurrentChildren] = useState<
    'all' | 'accessBlocked'
  >('all')

  useEffect(() => {
    setInterval(() => {
      if (!needsReload.current) {
        fetch(`${process.env.PUBLIC_URL}/metadata.json`)
          .then(res => res.json())
          .then((data: { uniqueId: string }) => {
            if (data.uniqueId) {
              if (uniqueBuildId.current) {
                if (uniqueBuildId.current !== data.uniqueId) {
                  needsReload.current = true
                }
              } else {
                uniqueBuildId.current = data.uniqueId
              }
            }
          })
          .catch((error: Error) => {
            mixpanel.track(
              isNetworkError(error.message)
                ? 'LMS - Network Errors'
                : 'LMS - Errors',
              {
                error_message: error.message,
                error_action: 'Check for app update',
              }
            )
          })
      }
    }, 300000)
  }, [])

  const isNewUIEnabled = useIsNewUIEnabled()
  const reloadPath = useReloadPath()

  const [foundExtension, setFoundExtension] = useState<boolean>(false)
  useEffect(() => {
    // check fox extension variable
    if (
      matchPath(props.location.pathname, { path: routes.CONTENT, exact: true })
    ) {
      // for cocoCut, stream recorder download hsl
      // const storageKeyValue: any = localStorage.getItem(
      //   '_intercept_mediastream_'
      // )
      const targetElement: any = document.getElementById('_intercepter_')

      // for firefox extension stream recorder
      // const extensionElement = document.querySelector('live-recorder')
      setFoundExtension(!!targetElement)
    }

    if (!foundExtension) {
      if (needsReload.current && props.location.pathname !== routes.AUTH) {
        window.location.reload()
      }

      if (isWhitelistedPathname(props.location.pathname)) {
        setCurrentChildren('all')
      } else if (props.admission.data && props.admission.data.access_blocked) {
        setCurrentChildren('accessBlocked')
      } else {
        setCurrentChildren('all')

        if (props.userDetails.appSource !== 'mobile_app') {
          if (
            props.onboarding &&
            props.onboarding.data.currentPage &&
            props.programData
          ) {
            if (props.location.pathname !== routes.ONBOARDING) {
              props.updateUserOnboardingRedirectUrl({
                url: props.location.pathname + props.location.search,
              })
            } else if (props.location.search.includes('redirect_to')) {
              const searchParams = parse(props.location.search)
              if (
                'redirect_to' in searchParams &&
                typeof searchParams.redirect_to === 'string'
              )
                props.updateUserOnboardingRedirectUrl({
                  url: searchParams.redirect_to,
                })
            }
            if (
              isNewUIEnabled &&
              props.location.pathname !== routes.ONBOARDING &&
              props.onboarding.data.currentPage.page === 'linkedin' &&
              props.onboarding.data.content?.linkedin_notification_active
            ) {
            } else {
              props.history.push(generateURL('ONBOARDING', null))
            }
          } else if (
            props.admission.data &&
            props.admission.data.feedback_info &&
            props.admission.data.feedback_info.feedback_pending &&
            !props.admission.loading &&
            props.location.pathname !== routes.FEEDBACK
          ) {
            if (
              isNewUIEnabled &&
              props.admission.data.feedback_info.feedback_type &&
              [
                'mentored_session_feedback',
                'residency_course_feedback',
              ].includes(props.admission.data.feedback_info.feedback_type)
            ) {
              reloadPath('DASHBOARD')
            } else props.feedbackPopupStatus()
          }
        }
      }
    }
  }, [props.location.pathname])

  useProgramSwitch(props)

  useEffect(() => {
    if (
      !isWhitelistedPathname(props.location.pathname) &&
      props.userDetails.appSource !== 'mobile_app' &&
      isNewUIEnabled &&
      !props.notifications.loading &&
      props.notifications.data?.overdue?.length
    ) {
      reloadPath('DASHBOARD')
    }
  }, [props.notifications, props.location, props.userDetails, isNewUIEnabled])

  const isWhitelistedPathname = (pathname: string) => {
    return (
      pathname.includes(routes.FEE_PAYMENT) ||
      pathname.includes(routes.PROGRAM_SUPPORT_CATEGORIES) ||
      pathname.includes(routes.PROGRAM_SUPPORT_CATEGORY) ||
      pathname.includes(routes.PROGRAM_SUPPORT_FAQ) ||
      pathname.includes(routes.ONBOARDING) ||
      pathname.includes(routes.FEEDBACK)
    )
  }

  if (foundExtension) {
    mixpanel.track('Extension Detected', {
      error_message: 'This learner tried to download the video',
    })
    return (
      // error component
      <Typography align="center" variant="h4">
        Downloading content from this website is a punishable offense.Refer T&C.
        Please remove extension and reload the page
      </Typography>
    )
  }

  if (currentChildren === 'all') {
    return <Fragment>{props.children}</Fragment>
  }

  if (props.admission.data && currentChildren === 'accessBlocked') {
    if (isNewUIEnabled) {
      reloadPath('DASHBOARD')
      return null
    }
    return (
      <AccessBlocked
        admissionData={props.admission.data}
        programData={props.programData}
        updateActiveProgram={props.updateActiveProgram}
        glAcademyId={props.glAcademyId}
        isCareerSchool={props.isCareerSchool}
        featureLinks={props.featureLinks}
        isNewProgramSupportEnabled={props.isNewProgramSupportEnabled}
      />
    )
  }

  return null
}
export default withRouter(AppContentsHandler)
