import { Reducer } from 'redux'
import { FreeUserInfoDialogActions } from './FreeUserInfoDialog.actions'
import {
  FETCH_CITIES,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAILURE,
  FETCH_CITIES_CANCEL,
  FETCH_COLLEGES,
  FETCH_COLLEGES_SUCCESS,
  FETCH_COLLEGES_FAILURE,
  FETCH_COLLEGES_CANCEL,
  RESET_COLLEGES,
  FETCH_UNIVERSITIES,
  FETCH_UNIVERSITIES_SUCCESS,
  FETCH_UNIVERSITIES_FAILURE,
  FETCH_UNIVERSITIES_CANCEL,
} from './FreeUserInfoDialog.types'
import {
  CollegeData,
  UniversityData,
  CityData,
} from '../../../../common/types/dashboard'

export interface CityState {
  data: CityData[]
  loading: boolean
  error: false | Error | Response
}

export interface CollegeState {
  data: CollegeData[]
  loading: boolean
  error: false | Error | Response
}

export interface UniversityState {
  data: UniversityData[]
  loading: boolean
  error: false | Error | Response
}

const initialState = {
  data: [],
  loading: false,
  error: false as false,
}

export const cityReducer: Reducer<CityState, FreeUserInfoDialogActions> = (
  state = initialState,
  action: any
): CityState => {
  switch (action.type) {
    case FETCH_CITIES:
      return { ...state, loading: true, error: false }
    case FETCH_CITIES_SUCCESS: {
      return {
        ...state,
        data: action.payload.cities,
        loading: false,
        error: false,
      }
    }
    case FETCH_CITIES_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case FETCH_CITIES_CANCEL:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}

const collegeDataHandler = (colleges: [string, number][]): CollegeData[] =>
  colleges && colleges.length
    ? colleges.map(([name, id]) => ({ name, id }))
    : []

export const collegeReducer: Reducer<
  CollegeState,
  FreeUserInfoDialogActions
> = (state = initialState, action: any): CollegeState => {
  switch (action.type) {
    case FETCH_COLLEGES:
      return { ...state, loading: true, error: false }
    case FETCH_COLLEGES_SUCCESS: {
      return {
        ...state,
        data: collegeDataHandler(action.payload.colleges),
        loading: false,
        error: false,
      }
    }
    case FETCH_COLLEGES_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case FETCH_COLLEGES_CANCEL:
      return {
        ...state,
        loading: false,
      }
    case RESET_COLLEGES:
      return {
        ...state,
        data: initialState.data,
      }

    default:
      return state
  }
}

export const universityReducer: Reducer<
  UniversityState,
  FreeUserInfoDialogActions
> = (state = initialState, action: any): UniversityState => {
  switch (action.type) {
    case FETCH_UNIVERSITIES:
      return { ...state, loading: true, error: false }
    case FETCH_UNIVERSITIES_SUCCESS: {
      return {
        ...state,
        data: action.payload.universities,
        loading: false,
        error: false,
      }
    }
    case FETCH_UNIVERSITIES_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case FETCH_UNIVERSITIES_CANCEL:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}

export default cityReducer
