import { CourseID } from 'common/types/courses'
import { CourseCompletionCardsApiData } from '../../../../common/types/courseCompletionCards'

import {
  FETCH_COURSE_COMPLETION_CARDS,
  FETCH_COURSE_COMPLETION_CARDS_SUCCESS,
  FETCH_COURSE_COMPLETION_CARDS_FAILURE,
  FETCH_COURSE_COMPLETION_CARDS_CANCEL,
  UPDATE_COURSE_COMPLETION_CARDS,
  UPDATE_COURSE_COMPLETION_CARDS_SUCCESS,
  UPDATE_COURSE_COMPLETION_CARDS_FAILURE,
  UPDATE_COURSE_COMPLETION_CARDS_CANCEL,
} from './CourseCompletionCards.types'

export const fetchCourseCompletionCards = () => ({
  type: FETCH_COURSE_COMPLETION_CARDS as typeof FETCH_COURSE_COMPLETION_CARDS,
})
export const fetchCourseCompletionCardsSuccess = (
  payload: CourseCompletionCardsApiData
) => ({
  type: FETCH_COURSE_COMPLETION_CARDS_SUCCESS as typeof FETCH_COURSE_COMPLETION_CARDS_SUCCESS,
  payload,
})
export const fetchCourseCompletionCardsFailure = (payload: any) => ({
  type: FETCH_COURSE_COMPLETION_CARDS_FAILURE as typeof FETCH_COURSE_COMPLETION_CARDS_FAILURE,
  payload,
})
export const fetchCourseCompletionCardsCancel = (payload: any) => ({
  type: FETCH_COURSE_COMPLETION_CARDS_CANCEL as typeof FETCH_COURSE_COMPLETION_CARDS_CANCEL,
  payload,
})

export const updateCourseCompletionCards = (payload: CourseID[]) => ({
  type: UPDATE_COURSE_COMPLETION_CARDS as typeof UPDATE_COURSE_COMPLETION_CARDS,
  payload,
})
export const updateCourseCompletionCardsSuccess = (payload: CourseID[]) => ({
  type: UPDATE_COURSE_COMPLETION_CARDS_SUCCESS as typeof UPDATE_COURSE_COMPLETION_CARDS_SUCCESS,
  payload,
})
export const updateCourseCompletionCardsFailure = (payload: any) => ({
  type: FETCH_COURSE_COMPLETION_CARDS_FAILURE as typeof UPDATE_COURSE_COMPLETION_CARDS_FAILURE,
  payload,
})
export const updateCourseCompletionCardsCancel = (payload: any) => ({
  type: UPDATE_COURSE_COMPLETION_CARDS_CANCEL as typeof UPDATE_COURSE_COMPLETION_CARDS_CANCEL,
  payload,
})

export type CourseCompletionCardsActions =
  | ReturnType<typeof fetchCourseCompletionCards>
  | ReturnType<typeof fetchCourseCompletionCardsSuccess>
  | ReturnType<typeof fetchCourseCompletionCardsFailure>
  | ReturnType<typeof fetchCourseCompletionCardsCancel>
  | ReturnType<typeof updateCourseCompletionCards>
  | ReturnType<typeof updateCourseCompletionCardsSuccess>
  | ReturnType<typeof updateCourseCompletionCardsFailure>
  | ReturnType<typeof updateCourseCompletionCardsCancel>
