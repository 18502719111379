export const SUBMIT_ASSIGNMENT = 'SUBMIT_ASSIGNMENT'
export const SUBMIT_ASSIGNMENT_SUCCESS = 'SUBMIT_ASSIGNMENT_SUCCESS'
export const SUBMIT_ASSIGNMENT_FAILURE = 'SUBMIT_ASSIGNMENT_FAILURE'
export const SUBMIT_ASSIGNMENT_CANCEL = 'SUBMIT_ASSIGNMENT_CANCEL'

export const GET_ASSIGNMENT_SUBMISSION = 'GET_ASSIGNMENT_SUBMISSION'
export const GET_ASSIGNMENT_SUBMISSION_SUCCESS =
  'GET_ASSIGNMENT_SUBMISSION_SUCCESS'
export const GET_ASSIGNMENT_SUBMISSION_FAILURE =
  'GET_ASSIGNMENT_SUBMISSION_FAILURE'
export const GET_ASSIGNMENT_SUBMISSION_CANCEL =
  'GET_ASSIGNMENT_SUBMISSION_CANCEL'

export const GET_SUBMITTED_USER_DETAILS = 'GET_SUBMITTED_USER_DETAILS'
export const GET_SUBMITTED_USER_DETAILS_SUCCESS =
  'GET_SUBMITTED_USER_DETAILS_SUCCESS'
export const GET_SUBMITTED_USER_DETAILS_FAILURE =
  'GET_SUBMITTED_USER_DETAILS_FAILURE'
export const GET_SUBMITTED_USER_DETAILS_CANCEL =
  'GET_SUBMITTED_USER_DETAILS_CANCEL'

export const GET_EXTERNAL_TOOL_URL = 'GET_EXTERNAL_TOOL_URL'
export const GET_EXTERNAL_TOOL_URL_SUCCESS = 'GET_EXTERNAL_TOOL_URL_SUCCESS'
export const GET_EXTERNAL_TOOL_URL_FAILURE = 'GET_EXTERNAL_TOOL_URL_FAILURE'
export const GET_EXTERNAL_TOOL_URL_CANCEL = 'GET_EXTERNAL_TOOL_URL_CANCEL'

export const GET_ASSIGNMENT_META_DATA = 'GET_ASSIGNMENT_META_DATA'
export const GET_ASSIGNMENT_META_DATA_SUCCESS =
  'GET_ASSIGNMENT_META_DATA_SUCCESS'
export const GET_ASSIGNMENT_META_DATA_FAILURE =
  'GET_ASSIGNMENT_META_DATA_FAILURE'
export const GET_ASSIGNMENT_META_DATA_CANCEL = 'GET_ASSIGNMENT_META_DATA_CANCEL'

export const GET_ASSIGNMENT_AI_MENTOR_USER_DATA =
  'GET_ASSIGNMENT_AI_MENTOR_USER_DATA'
export const GET_ASSIGNMENT_AI_MENTOR_USER_DATA_SUCCESS =
  'GET_ASSIGNMENT_AI_MENTOR_USER_DATA_SUCCESS'
export const GET_ASSIGNMENT_AI_MENTOR_USER_DATA_FAILURE =
  'GET_ASSIGNMENT_AI_MENTOR_USER_DATA_FAILURE'
export const GET_ASSIGNMENT_AI_MENTOR_USER_DATA_CANCEL =
  'GET_ASSIGNMENT_AI_MENTOR_USER_DATA_CANCEL'
