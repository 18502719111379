import React from 'react'
import { Typography } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import styles from './GLAStrip.module.css'
import Button from '../Button'

interface Props extends RouteComponentProps {
  onClick: () => void
}

const GLAStrip = (props: Props) => (
  <div className={styles.glaStrip}>
    <Typography>
      Click{' '}
      <Button
        variant="text"
        color="secondary"
        onClick={() => {
          props.onClick()
        }}
        className={styles.button}
      >
        <Typography>here</Typography>
      </Button>{' '}
      to access GL Academy
    </Typography>
  </div>
)

export default withRouter(GLAStrip)
