import { CourseID } from 'common/types/courses'
import { Reducer } from 'redux'
import {
  CardData,
  CourseCompletionCardsData,
} from '../../../../common/types/courseCompletionCards'
import { CourseCompletionCardsActions } from './CourseCompletionCards.actions'
import {
  FETCH_COURSE_COMPLETION_CARDS,
  FETCH_COURSE_COMPLETION_CARDS_SUCCESS,
  FETCH_COURSE_COMPLETION_CARDS_FAILURE,
  FETCH_COURSE_COMPLETION_CARDS_CANCEL,
  UPDATE_COURSE_COMPLETION_CARDS,
  UPDATE_COURSE_COMPLETION_CARDS_SUCCESS,
  UPDATE_COURSE_COMPLETION_CARDS_FAILURE,
  UPDATE_COURSE_COMPLETION_CARDS_CANCEL,
} from './CourseCompletionCards.types'

export interface CourseCompletionCardsState {
  data?: CourseCompletionCardsData
  loading: boolean
  error: false | Error | Response
}

const initialState: CourseCompletionCardsState = {
  loading: false,
  error: false as false,
}

const cardsDataHandler = (cards: CardData[]): CourseCompletionCardsData => {
  const courseCards: CourseCompletionCardsData = {
    byId: {},
  }

  cards.forEach((c: CardData) => {
    courseCards.byId[c.course_id] = c
  })

  return courseCards
}

const updatedCardsDataHandler = (
  state: CourseCompletionCardsState,
  courseIds: CourseID[]
) => {
  const cardsData: CourseCompletionCardsData['byId'] = state.data!.byId

  courseIds.forEach((courseId: CourseID) => {
    if (cardsData[courseId]) cardsData[courseId]!.has_viewed = true
  })

  return cardsData
}

export const courseCompletionCardsReducer: Reducer<
  CourseCompletionCardsState,
  CourseCompletionCardsActions
> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COURSE_COMPLETION_CARDS:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case FETCH_COURSE_COMPLETION_CARDS_SUCCESS: {
      const processedData = cardsDataHandler(action.payload.cards)
      return {
        ...state,
        data: processedData,
        loading: false,
        error: false,
      }
    }

    case FETCH_COURSE_COMPLETION_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case FETCH_COURSE_COMPLETION_CARDS_CANCEL:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_COURSE_COMPLETION_CARDS: {
      return {
        ...state,
        loading: true,
        error: false,
      }
    }
    case UPDATE_COURSE_COMPLETION_CARDS_SUCCESS: {
      const updatedCardsData = updatedCardsDataHandler(state, action.payload)
      return {
        ...state,
        data: { byId: updatedCardsData },
        loading: false,
        error: false,
      }
    }
    case UPDATE_COURSE_COMPLETION_CARDS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }
    case UPDATE_COURSE_COMPLETION_CARDS_CANCEL: {
      return {
        ...state,
        loading: false,
      }
    }

    default:
      return state
  }
}

export default courseCompletionCardsReducer
