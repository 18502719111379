import axios from 'axios'
import store from 'web/store'
import {
  CustomError,
  apiCall,
  getUserDetails,
  objectToFormData,
} from '../../../../common/utils'
import {
  fetchUserPayment,
  makeFeePayment,
  updateRazorPayStatus,
} from './Payments.actions'

import { UserPaymentsData } from '../../../../common/types/user'

export async function getUserPaymentAPI(
  action: ReturnType<typeof fetchUserPayment>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/user_payments`,
    params: { signal },
    query: { user_id: id },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getStateInfoApi(
  pincode: string,
  autoDetect: number,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/taxes/state_info`,
    params: { signal },
    query: { pincode, auto_detect: autoDetect },
  })
  if (response.ok) {
    return response.json()
  }
  if (response.status === 400) {
    return response.json()
  }
  throw response
}

export async function getUserCoordinatesApi(signal: AbortSignal) {
  // return {
  //   location: {
  //     lat: '17.7307648',
  //     lng: '83.2405504',
  //   },
  // }

  const response = await apiCall({
    url: `https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBj-RwXyguQJgpt-G05uogLuCOpTd5VCiY`,
    params: {
      method: 'POST',
      signal,
    },
    auth: false,
    excludeProgramId: true
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getLocationDetailsApi(
  lat_long: string,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/location`,
    params: {
      signal,
    },
    query: { lat_long },
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function makeFeePaymentAPI(
  paymentTransaction: UserPaymentsData['payment_transaction'],
  action: ReturnType<typeof makeFeePayment>,
  signal: AbortSignal
) {
  const {
    program_fee_code,
    batch_code,
    name,
    email,
    tax_type,
    service_tax,
    central_goods_and_service_tax,
    state_goods_and_service_tax,
    integrated_goods_and_service_tax,
    program_id,
    learning_center_id,
    product_info,
    program_group_id,
    lms_user_id,
    state_id,
    pincode,
  } = paymentTransaction

  let userPhoneNumber = action.paymentGatewayInfo.userPhone
  if (userPhoneNumber && userPhoneNumber.includes('+91')) {
    userPhoneNumber = userPhoneNumber.replace('+91', '')
  }

  const paymentTransactionData = {
    payment_data: JSON.stringify({
      program_fee_code,
      batch_code,
      name,
      email,
      phone: userPhoneNumber,
      tax_type,
      service_tax,
      central_goods_and_service_tax,
      state_goods_and_service_tax,
      integrated_goods_and_service_tax,
      central_goods_and_service_tax_amount:
        action.paymentGatewayInfo.CGSTAmount,
      state_goods_and_service_tax_amount: action.paymentGatewayInfo.SGSTAmount,
      integrated_goods_and_service_tax_amount:
        action.paymentGatewayInfo.IGSTAmount,
      amount: action.paymentGatewayInfo.totalAmount,
      total_amount: action.paymentGatewayInfo.payableAmount,
      program_id,
      learning_center_id,
      product_info,
      installment_options: action.paymentGatewayInfo.installmentName,
      program_group_id,
      lms_user_id,
      state_id,
      pincode,
      full_payment_discount_eligible:
        action.paymentGatewayInfo.full_payment_discount_eligible,
      discount_code: action.paymentGatewayInfo.discount_code,
      amount_before_discount: action.paymentGatewayInfo.amount_before_discount,
      source: 'react_web_app',
      pg_name:
        !action.paymentMethod || !action.is_razorpay ? 'payu' : 'razorpay', // if paymentMethod is true then it will be razorpay when we have two CTA's
    }),
  }

  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_URL}fee_payment/create_transaction`,
    query: { user_id: id },
    params: {
      signal,
      method: 'POST',
      body: objectToFormData(paymentTransactionData),
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function paymentGatewayAPI(
  gatewayData: Record<string, any>,
  signal: AbortSignal
) {
  if (gatewayData.pg_name == 'payu') {
    const url = `${process.env.REACT_APP_PAYUBIZ_URL}`
    const form = document.createElement('form')
    form.method = 'POST'
    form.action = url

    Object.keys(gatewayData).forEach(key => {
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = key
      hiddenField.value = gatewayData[key]

      form.appendChild(hiddenField)
    })
    document.body.appendChild(form)
    form.submit()
  } else if (gatewayData.pg_name == 'razorpay') {
    const options = {
      key: gatewayData.key,
      amount: gatewayData.amount,
      currency: 'INR',
      name: gatewayData.inst_name,
      order_id: gatewayData.pg_service_id,
      async handler(response: {
        razorpay_payment_id: string
        razorpay_order_id: string
        razorpay_signature: string
      }) {
        let payment_data
        let transaction_id: string
        transaction_id = gatewayData.transaction_id
        payment_data = {
          transaction_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
        }
        const data = {
          payment_data: JSON.stringify(payment_data),
        }
        store.dispatch(updateRazorPayStatus(true))
        const result: any = await axios.post(
          `${window.constants.REACT_APP_LTI_URL}api/v1/store_razorpay_payment`,
          data
        )
        if (result.status === false) {
          return (window.location.href = `${window.constants.REACT_APP_LTI_URL}fee_payment/failure?txn_id=${transaction_id}`)
        }
        return (window.location.href = `${window.constants.REACT_APP_LTI_URL}fee_payment/success?txn_id=${transaction_id}`)
      },
      prefill: {
        name: gatewayData.name,
        email: gatewayData.email,
        contact: gatewayData.phone,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#196AE5',
      },
    }
    if ('Razorpay' in window) {
      const rzp1 = (window as any).Razorpay(options)
      rzp1.open()
    } else {
      throw new Error()
    }
  }
}

export async function intMakeFeePaymentAPI(
  paymentTransaction: UserPaymentsData['payment_transaction'],
  action: ReturnType<typeof makeFeePayment>,
  signal: AbortSignal
) {
  const program_code = paymentTransaction.product_info.replace(/\@.*/, '')
  const installment_name =
    action.paymentGatewayInfo.installmentName === 'Others'
      ? ''
      : action.paymentGatewayInfo.installmentName

  const {
    program_fee_code,
    batch_code,
    name,
    email,
    program_id,
    learning_center_id,
    product_info,
    program_group_id,
    lms_user_id,
    currency_code,
    is_paypal,
    state_id,
    pincode,
  } = paymentTransaction

  const paymentTransactionData = {
    fee_code: program_fee_code,
    batch_code,
    name,
    email,
    amount: action.paymentGatewayInfo.totalAmount,
    total_amount: action.paymentGatewayInfo.payableAmount,
    program_id,
    learning_center_id,
    product_info,
    installment_options: action.paymentGatewayInfo.installmentName,
    program_group_id,
    lms_user_id,
    state_id,
    pincode,
    full_payment_discount_eligible: false,
    currency: currency_code.toLowerCase(),
    description: `${installment_name} Payment from ${name} for ${program_code}`,
    receipt_desc: `Received ${installment_name} Payment from ${name} for ${program_code}`,
    source: 'react_web_app',
    program_code,
    international_gst: action.paymentGatewayInfo.intlGstValue,
  }

  if (!is_paypal || !action.paymentMethod) {
    const enabled =
      process.env.REACT_APP_ENV !== 'production'
        ? true
        : [679, 3545, 23, 4237].includes(paymentTransactionData.program_id)
    await apiCall({
      url: enabled
        ? `${process.env.REACT_APP_SHINE_BACKEND_URL}payment_transactions/initiate_stripe_checkout`
        : `${window.constants.REACT_APP_LTI_URL}initiate_stripe_checkout`,
      params: {
        signal,
        method: 'POST',
        body: objectToFormData({
          ...paymentTransactionData,
          program_fee_code: paymentTransactionData.fee_code,
          currency_code: paymentTransactionData.currency,
          pg_name: 'stripe',
        }),
      },
    })
      .then(response => response.json())
      .then(jsondata => {
        if ('Stripe' in window) {
          // @ts-ignore
          const stripe = window.Stripe(`${process.env.REACT_APP_STRIPE_KEY}`)
          const session_id = jsondata.session.id
          stripe.redirectToCheckout({ sessionId: session_id })
        } else {
          throw new Error()
        }
      })
  } else {
    await apiCall({
      url: `${window.constants.REACT_APP_LTI_URL}initiate_checkout`,
      params: {
        signal,
        method: 'POST',
        body: objectToFormData(paymentTransactionData),
      },
    })
      .then(response => response.json())
      .then(jsondata => {
        const { transaction_id, status } = jsondata

        if (status === 'error') {
          throw new Error()
        } else {
          window.location.href = `${window.constants.REACT_APP_LTI_URL}payment_mode?txn_id=${transaction_id}`
        }
      })
  }
}
