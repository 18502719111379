export const FETCH_OPENAI_USER_TOKEN = 'FETCH_OPENAI_USER_TOKEN'
export const FETCH_OPENAI_USER_TOKEN_SUCCESS = 'FETCH_OPENAI_USER_TOKEN_SUCCESS'
export const FETCH_OPENAI_USER_TOKEN_FAILURE = 'FETCH_OPENAI_USER_TOKEN_FAILURE'
export const FETCH_OPENAI_USER_TOKEN_CANCEL = 'FETCH_OPENAI_USER_TOKEN_CANCEL'

export const CREATE_OPENAI_USER_TOKEN = 'CREATE_OPENAI_USER_TOKEN'
export const CREATE_OPENAI_USER_TOKEN_SUCCESS =
  'CREATE_OPENAI_USER_TOKEN_SUCCESS'
export const CREATE_OPENAI_USER_TOKEN_FAILURE =
  'CREATE_OPENAI_USER_TOKEN_FAILURE'
export const CREATE_OPENAI_USER_TOKEN_CANCEL = 'CREATE_OPENAI_USER_TOKEN_CANCEL'

export const DELETE_OPENAI_USER_TOKEN = 'DELETE_OPENAI_USER_TOKEN'
export const DELETE_OPENAI_USER_TOKEN_SUCCESS =
  'DELETE_OPENAI_USER_TOKEN_SUCCESS'
export const DELETE_OPENAI_USER_TOKEN_FAILURE =
  'DELETE_OPENAI_USER_TOKEN_FAILURE'
export const DELETE_OPENAI_USER_TOKEN_CANCEL = 'DELETE_OPENAI_USER_TOKEN_CANCEL'
