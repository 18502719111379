import { Box, BoxProps, Link, makeStyles } from '@material-ui/core'
import cx from 'classnames'
import { Button, Typography } from 'common/elements'
import React, { ReactElement, ReactNode } from 'react'
import { useOlympusDetails } from 'web/hooks'
import { CenteredLayout } from 'web/layouts/centered-layout'
import { useMedia } from 'common/elements/hooks'
import { AppBrand } from './app-brand'

const useStyles = makeStyles({
  cta: {
    color: '#1a1b1e',
  },
})

export function ChildrensWithSeparator({
  children,
  separator,
  ...props
}: BoxProps & { separator: ReactElement | ReactNode }) {
  const childrens = Array.isArray(children)
    ? children.filter(child => !!child)
    : [children]
  return (
    <Box {...props}>
      {childrens.map((child, index) => {
        return (
          child && (
            <>
              {child}
              {index !== childrens.length - 1 && separator}
            </>
          )
        )
      })}
    </Box>
  )
}

export function GLFooterOld({ ...props }) {
  const { policies } = useOlympusDetails() || { policies: {} }
  const styles = useStyles()
  const media = useMedia()

  // function navigateToTerms() {
  //   if (policies?.terms_of_use?.content && policies?.terms_of_use?.url) {
  //     window.location.href = policies.terms_of_use.url
  //   } else if (policies?.terms_of_use?.url) {
  //     window.open(policies?.terms_of_use?.url, '_blank')
  //   }
  // }

  // function navigateToPrivacy() {
  //   if (policies?.privacy_policy?.content && policies?.privacy_policy?.url) {
  //     window.location.href = policies.privacy_policy.url
  //   } else {
  //     window.open(policies?.privacy_policy?.url, '_blank')
  //   }
  // }

  return (
    <Box
      display="flex"
      bgcolor="#e9e7ec"
      {...((policies?.privacy_policy || policies?.terms_of_use) && media.md
        ? { minHeight: 'auto' }
        : { minHeight: 56 })}
      paddingX={2}
      paddingY={1}
      alignItems="center"
      {...props}
    >
      <CenteredLayout marginTop={0} flex={1}>
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          flexDirection={media.md ? 'column' : 'row'}
          gridGap={8}
        >
          <Box display="flex" flex={1} alignItems="center">
            <AppBrand style={{ textAlign: media.gtMd ? 'left' : 'center' }} />
          </Box>
          <Box display="flex" alignItems="center" gridGap={8}>
            {policies?.privacy_policy && (
              <Link
                href={policies?.privacy_policy?.url}
                target={policies?.privacy_policy?.content ? '_self' : '_blank'}
              >
                <Button className={cx(styles.cta)}>Privacy</Button>
              </Link>
            )}
            {policies?.terms_of_use && (
              <Link
                href={policies?.terms_of_use?.url}
                target={policies?.terms_of_use?.content ? '_self' : '_blank'}
              >
                <Button className={cx(styles.cta)}>Terms</Button>
              </Link>
            )}
          </Box>
        </Box>
      </CenteredLayout>
    </Box>
  )
}

export function GLFooterNew({ ...props }) {
  const { policies } = useOlympusDetails() || { policies: {} }
  const styles = useStyles()
  const media = useMedia()

  // function navigateToTerms() {
  //   if (policies?.terms_of_use?.content && policies?.terms_of_use?.url) {
  //     window.location.href = policies.terms_of_use.url
  //   } else if (policies?.terms_of_use?.url) {
  //     window.open(policies?.terms_of_use?.url, '_blank')
  //   }
  // }

  // function navigateToPrivacy() {
  //   if (policies?.privacy_policy?.content && policies?.privacy_policy?.url) {
  //     window.location.href = policies.privacy_policy.url
  //   } else {
  //     window.open(policies?.privacy_policy?.url, '_blank')
  //   }
  // }

  return (
    <Box
      display="flex"
      bgcolor="#f2f4f7"
      {...((policies?.privacy_policy || policies?.terms_of_use) && media.md
        ? { minHeight: 'auto' }
        : { minHeight: 56 })}
      paddingX={2}
      paddingY={1}
      alignItems="center"
      borderTop="1px solid #ebebef"
      {...props}
    >
      <Box
        display="flex"
        flex={1}
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
      >
        <ChildrensWithSeparator
          separator={
            media.tablet ? null : (
              <Typography className={cx('caption')}>·</Typography>
            )
          }
          display="flex"
          flexDirection={media.tablet ? 'column' : 'row'}
          justifyContent="center"
          alignItems="center"
          gridGap={media.tablet ? 4 : 8}
        >
          <Box display="flex" flex={1} alignItems="center">
            <AppBrand style={{ textAlign: media.gtMd ? 'left' : 'center' }} />
          </Box>
          {(policies?.privacy_policy || policies?.terms_of_use) && (
            <ChildrensWithSeparator
              separator={<Typography className={cx('caption')}>·</Typography>}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              gridGap={8}
            >
              {policies?.privacy_policy && (
                <Link
                  href={policies?.privacy_policy?.url}
                  target={
                    policies?.privacy_policy?.content ? '_self' : '_blank'
                  }
                >
                  <Typography className={cx('caption')}>Privacy</Typography>
                </Link>
              )}
              {policies?.terms_of_use && (
                <Link
                  href={policies?.terms_of_use?.url}
                  target={policies?.terms_of_use?.content ? '_self' : '_blank'}
                >
                  <Typography className={cx('caption')}>Terms</Typography>
                </Link>
              )}
            </ChildrensWithSeparator>
          )}
        </ChildrensWithSeparator>
      </Box>
    </Box>
  )
}

export function GLFooter(props) {
   return  <GLFooterNew {...props} />
}
