import {
  FETCH_OVERDUE_NOTIFICATIONS,
  FETCH_OVERDUE_NOTIFICATIONS_SUCCESS,
  FETCH_OVERDUE_NOTIFICATIONS_FAILURE,
  FETCH_OVERDUE_NOTIFICATIONS_CANCEL,
} from './UserNotifications.types'

export const fetchOverdueNotifications = (payload?: boolean) => ({
  type: FETCH_OVERDUE_NOTIFICATIONS as typeof FETCH_OVERDUE_NOTIFICATIONS,
  payload,
})
export const fetchOverdueNotificationsSuccess = (payload: any) => ({
  type: FETCH_OVERDUE_NOTIFICATIONS_SUCCESS as typeof FETCH_OVERDUE_NOTIFICATIONS_SUCCESS,
  payload,
})
export const fetchOverdueNotificationsFailure = (payload: Error) => ({
  type: FETCH_OVERDUE_NOTIFICATIONS_FAILURE as typeof FETCH_OVERDUE_NOTIFICATIONS_FAILURE,
  payload,
})
export const fetchOverdueNotificationsCancel = (payload: any) => ({
  type: FETCH_OVERDUE_NOTIFICATIONS_CANCEL as typeof FETCH_OVERDUE_NOTIFICATIONS_CANCEL,
  payload,
})

export type NotificationsActions =
  | ReturnType<typeof fetchOverdueNotifications>
  | ReturnType<typeof fetchOverdueNotificationsSuccess>
  | ReturnType<typeof fetchOverdueNotificationsFailure>
  | ReturnType<typeof fetchOverdueNotificationsCancel>
