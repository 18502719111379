import React from 'react'
import LinkMaterial, { LinkProps } from '@material-ui/core/Link'
import cx from 'classnames'
import styles from './Link.module.css'

const Link = ({ href, className, children, ...props }: LinkProps) => (
  <LinkMaterial
    href={href}
    className={cx(styles.link, className)}
    rel="noreferrer noopener"
    underline="none"
    {...props}
  >
    {children}
  </LinkMaterial>
)
export default Link
