import { Reducer } from 'redux'
import { ModuleItemData } from '../../../../common/types/courses/moduleItem'
import { SubmissionCommentsTypes } from '.'
import {
  SUBMIT_COMMENTS,
  SUBMIT_COMMENTS_SUCCESS,
  SUBMIT_COMMENTS_FAILURE,
  SUBMIT_COMMENTS_CANCEL,
} from './SubmissionComments.types'

const submissionCommentsReducer: Reducer<
  ModuleItemData['itemActivity']['submission'],
  SubmissionCommentsTypes
> = (state = null, action) => {
  switch (action.type) {
    case SUBMIT_COMMENTS:
      return {
        ...state,
        submission_comments: {
          ...state.submission_comments,
          loading: true,
          error: false,
        },
      }

    case SUBMIT_COMMENTS_SUCCESS:
      return {
        ...state,
        submission_comments: {
          ...state.submission_comments,
          loading: false,
        },
      }

    case SUBMIT_COMMENTS_FAILURE:
      return {
        ...state,
        submission_comments: {
          ...state.submission_comments,
          loading: false,
          error: action.payload,
        },
      }

    case SUBMIT_COMMENTS_CANCEL:
      return {
        ...state,
        submission_comments: {
          ...state.submission_comments,
          loading: false,
          // error: action.payload,
        },
      }

    default:
      return state
  }
}
export default submissionCommentsReducer
