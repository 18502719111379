import { CourseID } from 'common/types/courses'
import {
  FETCH_COURSE_QUIZZES,
  FETCH_COURSE_QUIZZES_SUCCESS,
  FETCH_COURSE_QUIZZES_FAILURE,
  FETCH_COURSE_QUIZZES_CANCEL,
} from './Quiz.types'

interface CourseQuizzesPayload {
  courseId: CourseID
}

export const fetchCourseQuizzes = (payload: CourseQuizzesPayload) => ({
  type: FETCH_COURSE_QUIZZES as typeof FETCH_COURSE_QUIZZES,
  payload,
})

export const fetchCourseQuizzesSuccess = (payload: any) => ({
  type: FETCH_COURSE_QUIZZES_SUCCESS as typeof FETCH_COURSE_QUIZZES_SUCCESS,
  payload,
})

export const fetchCourseQuizzesFailure = (payload: Error) => ({
  type: FETCH_COURSE_QUIZZES_FAILURE as typeof FETCH_COURSE_QUIZZES_FAILURE,
  payload,
})

export const fetchCourseQuizzesCancel = () => ({
  type: FETCH_COURSE_QUIZZES_CANCEL as typeof FETCH_COURSE_QUIZZES_CANCEL,
})

export type CourseQuizzesActions =
  | ReturnType<typeof fetchCourseQuizzes>
  | ReturnType<typeof fetchCourseQuizzesSuccess>
  | ReturnType<typeof fetchCourseQuizzesFailure>
  | ReturnType<typeof fetchCourseQuizzesCancel>
