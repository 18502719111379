import { AppState } from '../../store'
import {
  AnnouncementID,
  AnnouncementData,
} from '../../../common/types/announcements'
import { CourseID } from '../../../common/types/courses'
import { GroupID } from '../../../common/types/user'
import { selectAnnouncements } from '../../../common/utils/custom/announcements'

export const getAnnouncement = ({
  announcementId,
}: {
  announcementId: AnnouncementID
}) => (state: AppState): AnnouncementData | null => {
  const announcement = state.announcements.data.byId[announcementId]
  if (announcement) {
    return announcement
  }
  return null
}

export const getAnnouncements = (id?: CourseID | GroupID) => (
  state: AppState
) => selectAnnouncements(state.announcements.data, id)

export const getAnnouncementCourseIds = () => (state: AppState) => [
  ...(state.courses.data.completedIds || []),
  ...(state.courses.data.activeIds || []),
  ...(state.courses.data.failedIds || []),
]
