import { Action } from 'redux'
import {
  all,
  call,
  cancelled,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects'
import { mixpanel } from 'common/utils/mixpanel'
import { removeFile, FileInfo } from '.'
import { FileID } from '../../../common/types'
import { cancelable } from '../../../common/utils'
import {
  batchRemoveFile,
  batchRemoveFileFailure,
  batchRemoveFileSuccess,
  batchUploadFile,
  batchUploadFileFailure,
  batchUploadFileSuccess,
  uploadFile,
  uploadFileFailure,
  uploadFileSuccess,
  batchUploadFileCancel,
} from './Files.actions'
import {
  BATCH_REMOVE_FILE,
  BATCH_REMOVE_FILE_CANCEL,
  BATCH_UPLOAD_FILE,
  BATCH_UPLOAD_FILE_CANCEL,
  UPLOAD_FILE,
  UPLOAD_FILE_CANCEL,
  UPLOAD_FILE_FAILURE,
} from './Files.types'
import { showAlertMessage } from '../AlertsProvider'
import { FilesState } from './Files.reducer'
import { uploadFileAPI, initialFileUploadAPIHandler } from './Files.api'
import { AppState } from '../../store'

function* uploadFileHandler(action: ReturnType<typeof uploadFile>) {
  const abortController = new AbortController()
  const { fileId } = action.payload
  const fileState: FileInfo = yield select(
    (state: AppState) => state.files.data.byId[fileId]
  )
  const { file } = fileState
  let eventsInterval: null | NodeJS.Timeout = null
  let intervalCount = 0
  try {
    let initialUploadData = yield call(
      initialFileUploadAPIHandler,
      action,
      file,
      abortController.signal
    )
    initialUploadData = initialUploadData.attachments
      ? initialUploadData.attachments[0] || initialUploadData
      : initialUploadData
    if (initialUploadData.upload_url) {
      eventsInterval = setInterval(
        (function mixpanelEvent() {
          intervalCount += 1
          if (intervalCount <= 60)
            mixpanel.track('LMS - FileUploading', {
              key: initialUploadData.upload_params.key,
              fileSize: file.size,
              fileName: file.name,
              fileType: file.type,
            })
          return mixpanelEvent
        })(),
        30000
      )
    }
    const uploadedFileDetails = yield call(
      uploadFileAPI,
      initialUploadData,
      file,
      abortController.signal
    )
    if (eventsInterval) clearInterval(eventsInterval)
    mixpanel.track('LMS - FileUploaded', {
      key: initialUploadData.upload_params.key,
      fileSize: file.size,
      fileName: file.name,
      fileType: file.type,
      intervals: intervalCount,
    })
    yield put(uploadFileSuccess(uploadedFileDetails, { fileId }))
  } catch (e) {
    if (eventsInterval) clearInterval(eventsInterval)
    yield put(
      uploadFileFailure(
        e,
        { fileId },
        {
          fileSize: file.size,
          fileType: file.type,
          url: action.payload.url,
          time: new Date().toTimeString(),
        }
      )
    )
    yield put(
      showAlertMessage({ variant: 'error', message: 'File upload(s) failed' })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* batchUploadFileHandler(action: ReturnType<typeof batchUploadFile>) {
  const abortController = new AbortController()
  try {
    const { dataKey } = action.payload
    const filesState: FilesState = yield select(
      (state: AppState) => state.files
    )
    const itemFiles = filesState.byContent[dataKey]
    const files = itemFiles ? itemFiles.data : []
    const filesInfo = filesState.data.byId
    if (files) {
      yield all(
        ([] as any[]).concat(
          ...files
            .map((fileId: FileID) => {
              const fileInfo = filesInfo[fileId]
              if (fileInfo && !fileInfo.upload_id) {
                return [
                  put(
                    uploadFile({
                      fileId,
                      ...action.payload,
                    })
                  ),
                  take(
                    (ac: Action) =>
                      ac.type === 'UPLOAD_FILE_SUCCESS' &&
                      (ac as ReturnType<typeof uploadFileSuccess>).meta
                        .fileId === fileId
                  ),
                ]
              }
              return null
            })
            .filter(Boolean)
        )
      )
      yield put(batchUploadFileSuccess({ dataKey: action.payload.dataKey }))
    } else throw new Error('Unable to find files')
  } catch (e) {
    yield put(batchUploadFileFailure(e, { dataKey: action.payload.dataKey }))
    yield put(
      showAlertMessage({ variant: 'error', message: 'File upload(s) failed' })
    )
  } finally {
    if (cancelled()) {
      yield put(
        batchUploadFileCancel({
          dataKey: action.payload.dataKey,
        })
      )
      abortController.abort()
    }
  }
}

function* batchRemoveFileHandler(action: ReturnType<typeof batchRemoveFile>) {
  const abortController = new AbortController()
  try {
    const { dataKey } = action.payload
    const filesState: FilesState = yield select(
      (state: AppState) => state.files
    )
    const itemFiles = filesState.byContent[dataKey]
    const files = itemFiles ? itemFiles.data : []
    const filesInfo = filesState.data.byId
    if (files) {
      yield all(
        ([] as any[]).concat(
          ...files
            .map((fileId: FileID) => {
              if (filesInfo[fileId]) {
                return put(removeFile({ ...action.payload, fileId }))
              }
              return null
            })
            .filter(Boolean)
        )
      )
      yield put(batchRemoveFileSuccess({ dataKey: action.payload.dataKey }))
    } else throw new Error('Unable to find files')
  } catch (e) {
    yield put(batchRemoveFileFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* uploadFileMiddleware() {
  yield takeEvery(
    UPLOAD_FILE,
    cancelable(uploadFileHandler, UPLOAD_FILE_CANCEL)
  )
}

export function* batchUploadFileMiddleware() {
  yield takeEvery(
    BATCH_UPLOAD_FILE,
    cancelable(batchUploadFileHandler, [
      BATCH_UPLOAD_FILE_CANCEL,
      UPLOAD_FILE_FAILURE,
    ])
  )
}

export function* batchRemoveFileMiddleware() {
  yield takeEvery(
    BATCH_REMOVE_FILE,
    cancelable(batchRemoveFileHandler, BATCH_REMOVE_FILE_CANCEL)
  )
}

export default ([] as any).concat(
  uploadFileMiddleware(),
  batchUploadFileMiddleware(),
  batchRemoveFileMiddleware()
)
