import {
  FETCH_ALL_POLLS,
  FETCH_ALL_POLLS_FAILURE,
  FETCH_ALL_POLLS_SUCCESS,
  FETCH_POLL_BY_ID,
  FETCH_POLL_BY_ID_FAILURE,
  FETCH_POLL_BY_ID_SUCCESS,
  UPDATE_OPTION_PREFERENCE,
  UPDATE_POLL_UNREAD_COUNT,
} from './Polls.types'

export const fetchPollById = (pollId: number) => ({
  type: FETCH_POLL_BY_ID as typeof FETCH_POLL_BY_ID,
  payload: pollId,
})

export const fetchPollByIdSuccess = (meta: any, pollId: number) => ({
  type: FETCH_POLL_BY_ID_SUCCESS as typeof FETCH_POLL_BY_ID_SUCCESS,
  meta,
  payload: pollId,
})

export const fetchPollByIdFailure = (payload: Error) => ({
  type: FETCH_POLL_BY_ID_FAILURE as typeof FETCH_POLL_BY_ID_FAILURE,
  payload,
})

export const fetchAllPolls = () => ({
  type: FETCH_ALL_POLLS as typeof FETCH_ALL_POLLS,
})

export const fetchAllPollsSuccess = (
  meta: any // api response
) => ({
  type: FETCH_ALL_POLLS_SUCCESS as typeof FETCH_ALL_POLLS_SUCCESS,
  meta,
})

export const fetchAllPollsFailure = (payload: Error) => ({
  type: FETCH_ALL_POLLS_FAILURE as typeof FETCH_ALL_POLLS_FAILURE,
  payload,
})

interface OptionPreference {
  lmsUserId: number
  pollId: number
  questionId: number
  selectedOptions: number[]
}

export const updateOptionPreference = (payload: OptionPreference) => ({
  type: UPDATE_OPTION_PREFERENCE as typeof UPDATE_OPTION_PREFERENCE,
  payload,
})

export const updatePollUnReadCount = (unreadCount: number) => ({
  type: UPDATE_POLL_UNREAD_COUNT as typeof UPDATE_POLL_UNREAD_COUNT,
  payload: unreadCount,
})

export type ZoomoutPollsActions =
  | ReturnType<typeof fetchAllPolls>
  | ReturnType<typeof fetchPollById>
  | ReturnType<typeof fetchPollByIdSuccess>
  | ReturnType<typeof fetchAllPollsSuccess>
  | ReturnType<typeof updateOptionPreference>
  | ReturnType<typeof updatePollUnReadCount>
