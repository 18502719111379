import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import {
  fetchCourseAssignments,
  fetchCourseAssignmentsSuccess,
  fetchCourseAssignmentsFailure,
} from './Assignment.actions'
import * as APIs from './Assignment.api'
import * as types from './Assignment.types'

function* getCourseAssignments(
  action: ReturnType<typeof fetchCourseAssignments>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.getCourseAssignmentsAPI,
      action,
      abortController.signal
    )
    yield put(fetchCourseAssignmentsSuccess(data))
  } catch (e) {
    yield put(fetchCourseAssignmentsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* courseAssignmentsMiddleware() {
  yield takeLatest(
    types.FETCH_COURSE_ASSIGNMENTS,
    cancelable(getCourseAssignments, types.FETCH_COURSE_ASSIGNMENTS_CANCEL)
  )
}

export default ([] as any).concat(courseAssignmentsMiddleware())
