import { UserID } from 'common/types/user'
import { apiCall } from 'common/utils'

export async function fetchNewCourseItemAPI(
  user_id: UserID,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/new_course_items/users/${user_id}`,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
