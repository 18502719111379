import { Reducer } from 'redux'
import { CourseID } from 'common/types/courses'
import { ExcelerateCareerPrepApiData } from 'common/types/excelerate/careerPrep'
import { ExcelerateCareerPrepActions } from './CareerPrep.actions'
import {
  FETCH_EXCELERATE_CAREER_PREP_DETAILS,
  FETCH_EXCELERATE_CAREER_PREP_DETAILS_CANCEL,
  FETCH_EXCELERATE_CAREER_PREP_DETAILS_FAILURE,
  FETCH_EXCELERATE_CAREER_PREP_DETAILS_SUCCESS,
  UPDATE_EXCELERATE_CAREER_PREP_ACTIVE_STATUS,
  SUBMIT_EXCELERATE_FEEDBACK,
  SUBMIT_EXCELERATE_FEEDBACK_SUCCESS,
  SUBMIT_EXCELERATE_FEEDBACK_FAILURE,
  SUBMIT_EXCELERATE_FEEDBACK_CANCEL,
} from './CareerPrep.types'

export interface ExcelerateCareerPrepStateData
  extends ExcelerateCareerPrepApiData {
  activeCourseId: CourseID
}

export interface ExcelerateCareerPrepState {
  data: ExcelerateCareerPrepStateData | null
  active: boolean
  loading: boolean
  loaded: boolean
  feedback_status: 'submitting' | 'submitted' | 'error' | null
  error: boolean | Error
}

const initialState: ExcelerateCareerPrepState = {
  data: null,
  active: false,
  loading: false,
  loaded: false,
  feedback_status: null,
  error: false,
}

const normalizeData = (apiData: ExcelerateCareerPrepApiData) => {
  const data: any = {
    ...apiData,
    activeCourseId: apiData.activeCourse.course_id,
  }
  return data
}

export const excelerateCareerPrepReducer: Reducer<
  ExcelerateCareerPrepState,
  ExcelerateCareerPrepActions
> = (state = initialState, action): ExcelerateCareerPrepState => {
  switch (action.type) {
    case FETCH_EXCELERATE_CAREER_PREP_DETAILS: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      }
    }
    case FETCH_EXCELERATE_CAREER_PREP_DETAILS_SUCCESS: {
      return {
        ...state,
        data: normalizeData(action.payload),
        loading: false,
        loaded: true,
        error: false,
      }
    }
    case FETCH_EXCELERATE_CAREER_PREP_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      }
    }
    case FETCH_EXCELERATE_CAREER_PREP_DETAILS_CANCEL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false,
      }
    }
    case UPDATE_EXCELERATE_CAREER_PREP_ACTIVE_STATUS: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case SUBMIT_EXCELERATE_FEEDBACK: {
      return {
        ...state,
        feedback_status: 'submitting',
      }
    }

    case SUBMIT_EXCELERATE_FEEDBACK_SUCCESS: {
      return {
        ...state,
        data: {
          ...(state.data as ExcelerateCareerPrepStateData),
          feedbackShared: true,
        },
        feedback_status: 'submitted',
      }
    }

    case SUBMIT_EXCELERATE_FEEDBACK_FAILURE: {
      return {
        ...state,
        data: {
          ...(state.data as ExcelerateCareerPrepStateData),
          feedbackShared: false,
        },
        feedback_status: 'error',
      }
    }

    case SUBMIT_EXCELERATE_FEEDBACK_CANCEL: {
      return {
        ...state,
        feedback_status: null,
      }
    }

    default:
      return state
  }
}

export default excelerateCareerPrepReducer
