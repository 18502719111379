export const USER_PROFILE_DETAILS_FETCH = 'USER_PROFILE_DETAILS_FETCH'
export const USER_PROFILE_DETAILS_FETCH_SUCCESS =
  'USER_PROFILE_DETAILS_FETCH_SUCCESS'
export const USER_PROFILE_DETAILS_FETCH_FAILURE =
  'USER_PROFILE_DETAILS_FETCH_FAILURE'
export const USER_PROFILE_DETAILS_FETCH_CANCEL =
  'USER_PROFILE_DETAILS_FETCH_CANCEL'

export const FETCH_COMMUNICATION_CHANNEL_DETAILS =
  'FETCH_COMMUNICATION_CHANNEL_DETAILS'
export const FETCH_COMMUNICATION_CHANNEL_DETAILS_SUCCESS =
  'FETCH_COMMUNICATION_CHANNEL_DETAILS_SUCCESS'
export const FETCH_COMMUNICATION_CHANNEL_DETAILS_FAILURE =
  'FETCH_COMMUNICATION_CHANNEL_DETAILS_FAILURE'
export const FETCH_COMMUNICATION_CHANNEL_DETAILS_CANCEL =
  'FETCH_COMMUNICATION_CHANNEL_DETAILS_CANCEL'

export const UPDATE_EMAIL_VERIFIED_STATUS = 'UPDATE_EMAIL_VERIFIED_STATUS'

export const RESEND_EMAIL_LINK = 'RESEND_EMAIL_LINK'
export const RESEND_EMAIL_LINK_SUCCESS = 'RESEND_EMAIL_LINK_SUCCESS'
export const RESEND_EMAIL_LINK_FAILURE = 'RESEND_EMAIL_LINK_FAILURE'
export const RESEND_EMAIL_LINK_CANCEL = 'RESEND_EMAIL_LINK_CANCEL'

export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS'

export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE'

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'

export const UPDATE_PREBATCH_ENGAGEMENT_SCORE =
  'UPDATE_PREBATCH_ENGAGEMENT_SCORE'
