import { Reducer } from 'redux'
import { JupyterLabsActionTypes } from './JupyterLabs.action'
import * as types from './JupyterLabs.types'

export interface JupyterLabsState {
  status: {
    jupyter_lab_user_mapping_id: number
    time_left: number | null
    message?: string | null
    workflow_state: number
    signed_url: string
    is_custom_notebook_upload: boolean
    time_to_start_lab: number
    max_time_to_start_lab: number
  }
  details: {
    inactivity_timeout_ms: number
    prompt_timeout_ms: number
    problem_statement: string
    machine_config: string
    domain: string
    domain_name: string
    token: string
  }
  download_notebook: {
    loading: boolean
    error: false | Error | Response
  }
  start_lab: {
    workflow_state: number
  }
  loading: boolean
  error: false | Error | Response
}

const initialStateModules: JupyterLabsState = {
  status: {
    jupyter_lab_user_mapping_id: 0,
    time_left: 0,
    message: null,
    workflow_state: 0,
    signed_url: '',
    is_custom_notebook_upload: false,
    time_to_start_lab: 0,
    max_time_to_start_lab: 0,
  },
  details: {
    inactivity_timeout_ms: 0,
    prompt_timeout_ms: 0,
    problem_statement: '',
    machine_config: '',
    domain: '',
    domain_name: '',
    token: '',
  },
  download_notebook: {
    loading: false,
    error: false,
  },
  start_lab: {
    workflow_state: 0,
  },
  loading: false,
  error: false,
}

export const jupyterLabsReducer: Reducer<
  JupyterLabsState,
  JupyterLabsActionTypes
> = (state = initialStateModules, action): JupyterLabsState => {
  switch (action.type) {
    case types.START_JUPYTER_LAB:
    case types.STOP_JUPYTER_LAB:
      return {
        ...state,
        loading: true,
      }
    case types.GET_JUPYTER_NOTEBOOK:
      return {
        ...state,
        download_notebook: {
          loading: true,
          error: false,
        },
      }
    case types.GET_JUPYTER_LAB_STATUS_FAILURE:
    case types.START_JUPYTER_LAB_FAILURE:
    case types.STOP_JUPYTER_LAB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case types.GET_JUPYTER_NOTEBOOK_FAILURE:
      return {
        ...state,
        download_notebook: {
          loading: false,
          error: action.payload,
        },
      }
    case types.GET_JUPYTER_LAB_DETAILS_SUCCESS:
    case types.GET_JUPYTER_LAB_DETAILS_CANCEL:
      return {
        ...state,
        details: action.payload,
      }
    case types.GET_JUPYTER_LAB_STATUS_CANCEL:
    case types.START_JUPYTER_LAB_CANCEL:
    case types.STOP_JUPYTER_LAB_CANCEL:
      return {
        ...state,
        loading: false,
      }
    case types.GET_JUPYTER_NOTEBOOK_SUCCESS:
    case types.GET_JUPYTER_NOTEBOOK_CANCEL:
      return {
        ...state,
        download_notebook: {
          loading: false,
          error: false,
        },
      }
    case types.GET_JUPYTER_LAB_STATUS_SUCCESS: {
      return {
        ...state,
        status: action.payload,
        loading: false,
        error: false,
      }
    }
    case types.START_JUPYTER_LAB_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          workflow_state: action.payload.workflow_state,
        },
        start_lab: {
          workflow_state: action.payload.workflow_state,
        },
        loading: false,
        error: false,
      }

    default:
      return state
  }
}
