import {
  call,
  cancelled,
  put,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { QuizSubmissionData } from 'common/types/courses/quiz'
import { select } from 'redux-saga-test-plan/matchers'
import { useDispatch } from 'react-redux'
import { mixpanel } from 'common/utils/mixpanel'
import {
  fetchRuntimes,
  fetchRuntimesAPI,
  fetchRuntimesSuccess,
  fetchRuntimesFailure,
  RUNTIMES_FETCH,
  RUNTIMES_FETCH_CANCEL,
  codeExecute,
  codeExecuteSuccess,
  codeExecutionFailure,
  codeExecuteAPI,
  questionRunCode,
  questionRunCodeSuccess,
  questionRunCodeFailure,
  questionRunCodeCancel,
  questionTestCode,
  questionTestCodeSuccess,
  questionTestCodeFailure,
  questionTestCodeCancel,
} from './index'
import {
  CodingPlatformRuntimes,
  CodeExecuteSuccess,
  TestCodeResults,
  listPlaygrounds,
  listPlaygroundsFailure,
  listPlaygroundsSuccess,
  getPlayground,
  getPlaygroundSuccess,
  getPlaygroundFailure,
  updatePlayground,
  updatePlaygroundSuccess,
  updatePlaygroundFailure,
  deletePlayground,
  deletePlaygroundSuccess,
  deletePlaygroundFailure,
  createPlayground,
  createPlaygroundSuccess,
  createPlaygroundFailure,
  renamePlayground,
  renamePlaygroundFailure,
  renamePlaygroundSuccess,
  questionGetAiCodeSuggestions,
  QuestionGetAiCodeSuggestionsResponse,
  questionGetAiCodeSuggestionsSuccess,
  questionGetAiCodeSuggestionsFailure,
  questionGetAiCodeHints,
  questionGetAiCodeHintsFailure,
  questionGetAiCodeHintsSuccess,
  QuestionGetAiHintsResponse,
  questionRunSqlCode,
  questionRunSqlCodeSuccess,
  questionRunSqlCodeFailure,
  CodeExecuteSqlSuccess,
  questiontestSqlCode,
  questiontestSqlCodeSuccess,
  questiontestSqlCodeFailure,
  sqlQuestionGetAiCodeSuggestionsSuccess,
  sqlQuestionGetAiCodeSuggestions,
  sqlQuestionGetAiCodeSuggestionsFailure,
  CodeEvalQuestionTestCodeParams,
  QuestionTestCodePayload,
  CodeExecutionPayload,
  CodeEvalQuestionRunCodeParams,
  CodeEvalQuestionGetAiCodeSuggestionsPayload,
} from './CodingPlatform.action'
import { apiCall, cancelable } from '../../../common/utils'
import {
  CODE_EXECUTE,
  CODE_EXECUTE_CANCEL,
  LIST_PLAYGROUNDS,
  LIST_PLAYGROUNDS_CANCEL,
  GET_PLAYGROUND,
  GET_PLAYGROUND_CANCEL,
  UPDATE_PLAYGROUND,
  UPDATE_PLAYGROUND_CANCEL,
  QUESTION_RUN_CODE,
  QUESTION_TEST_CODE,
  QUESTION_TEST_CODE_CANCEL,
  DELETE_PLAYGROUND,
  DELETE_PLAYGROUND_CANCEL,
  CREATE_PLAYGROUND,
  CREATE_PLAYGROUND_CANCEL,
  RENAME_PLAYGROUND,
  RENAME_PLAYGROUND_CANCEL,
  QUESTION_GET_AI_CODE_SUGGESTIONS,
  QUESTION_GET_AI_CODE_SUGGESTIONS_CANCEL,
  QUESTION_GET_AI_CODE_HINTS,
  QUESTION_GET_AI_CODE_HINTS_CANCEL,
  QUESTION_RUN_SQL_CODE,
  QUESTION_TEST_SQL_CODE,
  QUESTION_TEST_SQL_CODE_CANCEL,
  SQL_QUESTION_GET_AI_CODE_SUGGESTIONS,
  SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_CANCEL,
} from './CodingPlatform.types'
import {
  listPlaygroundsAPI,
  questionRunCodeAPI,
  questionTestCodeAPI,
  getPlaygroundAPI,
  getPlaygroundDataFromS3,
  getPlaygroundUpdateUrlAPI,
  uploadPlaygroundToS3,
  updatePlaygroundAPI,
  deletePlaygroundAPI,
  createPlaygroundsAPI,
  renamePlaygroundAPI,
  getAiCodeSuggestionsAPI,
  getAiCodeHintsAPI,
  questionTestCodeAPIForAiCodeSuggestions,
  questionRunSqlCodeAPI,
  questiontestSqlCodeAPI,
  sqlQuestionTestCodeAPIForAiCodeSuggestions,
} from './CodingPlatform.api'
import { quizSubmissionSelectors } from '../Courses/ModuleItemsProvider/Quiz/QuizSubmissionsProvider'
import { CodingPlaygroundsState } from './CodingPlatform.reducer'
import { showAlertMessage } from '../AlertsProvider'

// Define a generic type for the action function
type ActionCreator<TParams extends any[]> = (...args: TParams) => any

interface DelayedActionParams<TParams extends any[]> {
  delayMs?: number
  action: ActionCreator<TParams>
  params: TParams
}

const getErrorAndPayload = async (err: Response | Error) => {
  const error = err instanceof Response ? await err.json() : err
  const payload =
    err instanceof Response
      ? {
          response: error,
          status: err.status,
          statusText: err.statusText,
          url: err.url,
        }
      : { error }

  return { error, payload }
}

function* fetchRuntimesHandler(action: ReturnType<typeof fetchRuntimes>) {
  const abortController = new AbortController()
  try {
    const runtimes: CodingPlatformRuntimes = yield call(
      fetchRuntimesAPI,
      action,
      abortController.signal
    )
    yield put(fetchRuntimesSuccess(runtimes))
  } catch (e) {
    yield put(fetchRuntimesFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* codeExecutionHandler(action: ReturnType<typeof codeExecute>) {
  const abortController = new AbortController()
  try {
    const codeExecutionResponse: CodeExecuteSuccess = yield call(
      codeExecuteAPI,
      action,
      abortController.signal
    )

    yield put(
      codeExecuteSuccess(
        { playground_id: action.meta.playground_id },
        codeExecutionResponse
      )
    )
  } catch (e) {
    yield put(
      codeExecutionFailure({ playground_id: action.meta.playground_id }, e)
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* delayedAction<TParams extends any[]>({
  delayMs = 3000,
  action,
  params,
}: DelayedActionParams<TParams>) {
  // Delay for the specified duration
  yield new Promise(resolve => setTimeout(resolve, delayMs))

  // After the delay, dispatch the action
  yield put(action(...params))
}

function* questionRunCodeHandler(action: ReturnType<typeof questionRunCode>) {
  const abortController = new AbortController()
  try {
    const data = {
      ceQuestionId: action.meta.ceQuestionId,
      quiz_id: action.meta.quizId,
    }
    const questionRunCodeResponse: CodeExecuteSuccess = yield call(
      questionRunCodeAPI,
      data,
      action,
      abortController.signal
    )

    yield put(questionRunCodeSuccess(action.meta, questionRunCodeResponse))

    if (questionRunCodeResponse && questionRunCodeResponse.is_queued) {
      yield delayedAction({
        delayMs: 3000,
        action: questionRunCode,
        params: [
          action.meta as CodeEvalQuestionTestCodeParams,
          {
            ...action.payload,
            job_id: questionRunCodeResponse.data?.jobId,
            job_name: questionRunCodeResponse.data?.jobName,
            job_position: questionRunCodeResponse?.jobPosition,
          } as CodeExecutionPayload,
        ],
      })
    }
  } catch (err) {
    const { error, payload } = yield call(getErrorAndPayload, err)
    yield put(questionRunCodeFailure(action.meta, error.message))
    mixpanel.track('CodeEValError: Question Run Code Failure', {
      ...payload,
      meta: action.meta,
    })
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* questionTestCodeHandler(action: ReturnType<typeof questionTestCode>) {
  const abortController = new AbortController()
  try {
    const submission: QuizSubmissionData | null = yield select(
      quizSubmissionSelectors.getQuizSubmission({
        itemId: action.meta.itemId,
        attempt: action.meta.attemptNo,
      })
    )
    if (!submission) {
      return
    }
    const data = {
      submissionId: submission.id,
      validation_token: submission.validation_token,
      attempt: action.meta.attemptNo,
    }
    const questionTestCodeResponse: TestCodeResults = yield call(
      questionTestCodeAPI,
      data,
      action,
      abortController.signal
    )

    if (questionTestCodeResponse && questionTestCodeResponse.is_queued) {
      yield delayedAction({
        delayMs: 3000,
        action: questionTestCode,
        params: [
          action.meta as CodeEvalQuestionTestCodeParams,
          {
            ...action.payload,
            job_id: questionTestCodeResponse.data?.jobId,
            job_name: questionTestCodeResponse.data?.jobName,
            job_position: questionTestCodeResponse?.jobPosition,
          } as QuestionTestCodePayload,
        ],
      })
    }
    yield put(questionTestCodeSuccess(action.meta, questionTestCodeResponse))
  } catch (e) {
    const { error, payload } = yield call(getErrorAndPayload, e)
    yield put(questionTestCodeFailure(action.meta, error.message))
    mixpanel.track('CodeEValError: Question Test Code Failure', {
      ...payload,
      meta: action.meta,
    })
  } finally {
    if (cancelled()) abortController.abort()
  }
}

function* questiontestSqlCodeHandler(
  action: ReturnType<typeof questiontestSqlCode>
) {
  const abortController = new AbortController()
  try {
    const submission: QuizSubmissionData | null = yield select(
      quizSubmissionSelectors.getQuizSubmission({
        itemId: action.meta.itemId,
        attempt: action.meta.attemptNo,
      })
    )
    if (!submission) {
      return
    }
    const data = {
      submissionId: submission.id,
      validation_token: submission.validation_token,
      attempt: action.meta.attemptNo,
    }
    const questiontestSqlCodeResponse: CodeExecuteSqlSuccess = yield call(
      questiontestSqlCodeAPI,
      data,
      action,
      abortController.signal
    )

    if (questiontestSqlCodeResponse && questiontestSqlCodeResponse.is_queued) {
      yield delayedAction({
        delayMs: 3000,
        action: questiontestSqlCode,
        params: [
          action.meta as CodeEvalQuestionTestCodeParams,
          {
            ...action.payload,
            job_id: questiontestSqlCodeResponse.data?.jobId,
            job_name: questiontestSqlCodeResponse.data?.jobName,
            job_position: questiontestSqlCodeResponse?.jobPosition,
          } as QuestionTestCodePayload,
        ],
      })
    }

    yield put(
      questiontestSqlCodeSuccess(action.meta, questiontestSqlCodeResponse)
    )
  } catch (e) {
    const { error, payload } = yield call(getErrorAndPayload, e)
    yield put(questiontestSqlCodeFailure(action.meta, error.message))
    mixpanel.track('CodeEValError: Question Test SQL Code Failure', {
      ...payload,
      meta: action.meta,
    })
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* questionRunSqlCodeHandler(
  action: ReturnType<typeof questionRunSqlCode>
) {
  const abortController = new AbortController()
  try {
    const data = {
      ceQuestionId: action.meta.ceQuestionId,
    }
    const questionRunSQLCodeResponse: CodeExecuteSqlSuccess = yield call(
      questionRunSqlCodeAPI,
      data,
      action,
      abortController.signal
    )
    yield put(
      questionRunSqlCodeSuccess(action.meta, questionRunSQLCodeResponse)
    )

    if (questionRunSQLCodeResponse && questionRunSQLCodeResponse.is_queued) {
      yield delayedAction({
        delayMs: 3000,
        action: questionRunSqlCode,
        params: [
          action.meta as CodeEvalQuestionRunCodeParams,
          {
            ...action.payload,
            job_id: questionRunSQLCodeResponse.data?.jobId,
            job_name: questionRunSQLCodeResponse.data?.jobName,
            job_position: questionRunSQLCodeResponse?.jobPosition,
          } as CodeExecutionPayload,
        ],
      })
    }
  } catch (e) {
    const { error, payload } = yield call(getErrorAndPayload, e)
    yield put(questionRunSqlCodeFailure(action.meta, error.message))
    mixpanel.track('CodeEValError: Question Run SQL Code Failure', {
      ...payload,
      meta: action.meta,
    })
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* questionGetAiCodeSuggestionsHandler(
  action: ReturnType<typeof questionGetAiCodeSuggestions>
) {
  const abortController = new AbortController()
  try {
    const submission: QuizSubmissionData | null = yield select(
      quizSubmissionSelectors.getQuizSubmission({
        itemId: action.meta.itemId,
        attempt: action.meta.attemptNo,
      })
    )
    if (!submission) {
      return
    }
    const data = {
      submissionId: submission.id,
      validation_token: submission.validation_token,
      attempt: action.meta.attemptNo,
    }

    // Call questionTestCodeHandler
    const questionTestCodeResponse: TestCodeResults = yield call(
      questionTestCodeAPIForAiCodeSuggestions,
      data,
      action,
      abortController.signal
    )

    if (questionTestCodeResponse && questionTestCodeResponse.is_queued) {
      yield delayedAction({
        delayMs: 3000,
        action: questionGetAiCodeSuggestions,
        params: [
          action.meta as CodeEvalQuestionTestCodeParams,
          {
            ...action.payload,
            job_id: questionTestCodeResponse.data?.jobId,
            job_name: questionTestCodeResponse.data?.jobName,
            job_position: questionTestCodeResponse?.jobPosition,
          } as CodeEvalQuestionGetAiCodeSuggestionsPayload,
        ],
      })
    }

    yield put(questionTestCodeSuccess(action.meta, questionTestCodeResponse))
    if (questionTestCodeResponse && questionTestCodeResponse.is_queued) {
      return
    }

    let allPassed = false
    if (
      action.payload &&
      action.payload.questionLanguage === 'prompt-engineering'
    ) {
      allPassed = !!(
        questionTestCodeResponse.content &&
        questionTestCodeResponse.content.evaluation &&
        questionTestCodeResponse.content.evaluation.overallScore >= 8
      )
    } else {
      const testCaseRes = Object.values(
        questionTestCodeResponse.public_test_cases_execution_res || []
      )
      allPassed =
        questionTestCodeResponse.public_test_cases_execution_res &&
        testCaseRes.length > 0 &&
        testCaseRes.every((testCase: any) => testCase.status === 'passed')
    }

    if (allPassed) {
      // Set questionGetAiCodeSuggestionResponse as "Hint: Your code looks correct, please check the Test Cases tab for results"
      const questionGetAiCodeSuggestionsResponse: QuestionGetAiCodeSuggestionsResponse = {
        content:
          action.payload.questionLanguage === 'prompt-engineering'
            ? 'Well done on crafting a good prompt for this scenario. Review it if you wish to improve it further.'
            : 'Hint: Your code looks correct, please check the Test Cases tab for results',
      }
      yield put(
        questionGetAiCodeSuggestionsSuccess(
          action.meta,
          questionGetAiCodeSuggestionsResponse
        )
      )
    } else {
      if (action.payload && action.payload.showStaticHint) {
        const questionGetAiCodeSuggestionsResponse: QuestionGetAiCodeSuggestionsResponse = {
          content:
            'You have not written any new code. Please refer to the hints next to the problem statement section for guidance',
        }
        yield put(
          questionGetAiCodeSuggestionsSuccess(
            action.meta,
            questionGetAiCodeSuggestionsResponse
          )
        )
        return
      }

      if (
        action.payload.questionLanguage === 'prompt-engineering' &&
        questionTestCodeResponse.content &&
        questionTestCodeResponse.content.evaluation
      ) {
        const { hint } = questionTestCodeResponse.content.evaluation
        yield put(
          questionGetAiCodeSuggestionsSuccess(action.meta, {
            content: hint || '',
          })
        )
      } else {
        // Call getAiCodeSuggestionsAPI
        const questionGetAiCodeSuggestionsResponse: QuestionGetAiCodeSuggestionsResponse = yield call(
          getAiCodeSuggestionsAPI,
          data,
          action,
          abortController.signal
        )

        yield put(
          questionGetAiCodeSuggestionsSuccess(
            action.meta,
            questionGetAiCodeSuggestionsResponse
          )
        )
      }
    }
  } catch (e) {
    const { error, payload } = yield call(getErrorAndPayload, e)
    // error.status is coming from the api response for this ai code suggestions
    const errorMsg = error.message || error.status || 'Something went wrong.'
    yield put(questionGetAiCodeSuggestionsFailure(action.meta, errorMsg))

    mixpanel.track('CodeEValError: Question Get AI Code Suggestions Failure', {
      ...payload,
      meta: action.meta,
    })

    yield put(
      showAlertMessage({
        message: 'Sorry something went wrong while fetching suggestions',
        variant: 'error',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* sqlQuestionGetAiCodeSuggestionsHandler(
  action: ReturnType<typeof sqlQuestionGetAiCodeSuggestions>
) {
  const abortController = new AbortController()
  try {
    const submission: QuizSubmissionData | null = yield select(
      quizSubmissionSelectors.getQuizSubmission({
        itemId: action.meta.itemId,
        attempt: action.meta.attemptNo,
      })
    )
    if (!submission) {
      return
    }
    const data = {
      submissionId: submission.id,
      validation_token: submission.validation_token,
      attempt: action.meta.attemptNo,
    }

    // Call sqlQuestionTestCodeHandler
    const sqlQuestionTestCodeResponse: CodeExecuteSqlSuccess = yield call(
      sqlQuestionTestCodeAPIForAiCodeSuggestions,
      data,
      action,
      abortController.signal
    )

    if (sqlQuestionTestCodeResponse && sqlQuestionTestCodeResponse.is_queued) {
      yield delayedAction({
        delayMs: 3000,
        action: sqlQuestionGetAiCodeSuggestions,
        params: [
          action.meta as CodeEvalQuestionTestCodeParams,
          {
            ...action.payload,
            job_id: sqlQuestionTestCodeResponse.data?.jobId,
            job_name: sqlQuestionTestCodeResponse.data?.jobName,
            job_position: sqlQuestionTestCodeResponse?.jobPosition,
          } as CodeEvalQuestionGetAiCodeSuggestionsPayload,
        ],
      })
    }

    if (sqlQuestionTestCodeResponse && sqlQuestionTestCodeResponse.is_queued) {
      return
    }

    yield put(
      questiontestSqlCodeSuccess(action.meta, sqlQuestionTestCodeResponse)
    )

    const allPassed =
      sqlQuestionTestCodeResponse && sqlQuestionTestCodeResponse.error === null

    if (allPassed) {
      // Set questionGetAiCodeSuggestionsResponse as "Hint: Your code looks correct, please check the Test Cases tab for for query output"
      const questionGetAiCodeSuggestionsResponse: any = {
        content:
          'Hint: Your SQL Queries looks correct, please check the Test Cases tab for query output',
      }
      yield put(
        sqlQuestionGetAiCodeSuggestionsSuccess(
          action.meta,
          questionGetAiCodeSuggestionsResponse
        )
      )
    } else {
      const questionGetAiCodeSuggestionsResponse = yield call(
        getAiCodeSuggestionsAPI,
        data,
        action,
        abortController.signal
      )

      yield put(
        sqlQuestionGetAiCodeSuggestionsSuccess(
          action.meta,
          questionGetAiCodeSuggestionsResponse
        )
      )
    }
  } catch (e) {
    const { error, payload } = yield call(getErrorAndPayload, e)
    // error.status is coming from the api response for this ai code suggestions
    const errorMsg = error.message || error.status || 'Something went wrong.'
    yield put(sqlQuestionGetAiCodeSuggestionsFailure(action.meta, errorMsg))

    mixpanel.track(
      'CodeEValError: SQL Question Get AI Code Suggestions Failure',
      {
        ...payload,
        meta: action.meta,
      }
    )
    yield put(
      showAlertMessage({
        message: 'Sorry something went wrong while fetching suggestions',
        variant: 'error',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* questionGetAiCodeHintsHandler(
  action: ReturnType<typeof questionGetAiCodeHints>
) {
  const abortController = new AbortController()
  try {
    const submission: QuizSubmissionData | null = yield select(
      quizSubmissionSelectors.getQuizSubmission({
        itemId: action.meta.itemId,
        attempt: action.meta.attemptNo,
      })
    )
    if (!submission) {
      return
    }
    const data = {
      submissionId: submission.id,
      validation_token: submission.validation_token,
      attempt: action.meta.attemptNo,
    }
    const questionGetAiCodeHintsResponse: QuestionGetAiHintsResponse = yield call(
      getAiCodeHintsAPI,
      data,
      action,
      abortController.signal
    )

    yield put(
      questionGetAiCodeHintsSuccess(action.meta, questionGetAiCodeHintsResponse)
    )
  } catch (e) {
    yield put(questionGetAiCodeHintsFailure(action.meta, e))
    yield put(
      showAlertMessage({
        message: 'Sorry something went wrong while fetching hints',
        variant: 'error',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* listPlaygroundsHandler(action: ReturnType<typeof listPlaygrounds>) {
  const abortController = new AbortController()
  try {
    const data = {
      language: action.meta.language,
    }

    const listPlaygroundsResponse: CodingPlaygroundsState = yield call(
      listPlaygroundsAPI,
      data,
      action,
      abortController.signal
    )

    yield put(listPlaygroundsSuccess(action.meta, listPlaygroundsResponse))
  } catch (e) {
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Error occurred while fetching files',
      })
    )
    yield put(listPlaygroundsFailure(action.meta, e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* createPlaygroundHandler(action: ReturnType<typeof createPlayground>) {
  const abortController = new AbortController()
  try {
    const createPlaygroundResponse = yield call(
      createPlaygroundsAPI,
      action,
      abortController.signal
    )
    yield put(createPlaygroundSuccess(action.meta, createPlaygroundResponse))
    yield put(listPlaygrounds({ language: action.meta.language }, {}))
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'File created',
      })
    )
  } catch (error) {
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Error occurred while creating file',
      })
    )
    yield put(createPlaygroundFailure(action.meta, error))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
function* getPlaygroundHandler(action: ReturnType<typeof getPlayground>) {
  const abortController = new AbortController()
  try {
    const playgroundS3SignedUrlRes: { signedUrl: string } = yield call(
      getPlaygroundAPI,
      action,
      abortController.signal
    )

    if (playgroundS3SignedUrlRes && playgroundS3SignedUrlRes.signedUrl) {
      const data = { signedUrl: playgroundS3SignedUrlRes.signedUrl }

      const playgroundRes: string = yield call(
        getPlaygroundDataFromS3,
        data,
        action,
        abortController.signal
      )
      if (playgroundRes) {
        yield put(getPlaygroundSuccess(action.meta, playgroundRes))
      } else {
        yield put(
          showAlertMessage({
            variant: 'error',
            message: 'Error occurred while fetching file data',
          })
        )
      }
    } else {
      yield put(
        showAlertMessage({
          variant: 'error',
          message: 'Error occurred while fetching file url',
        })
      )
    }
  } catch (e) {
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Error occurred while fetching file url',
      })
    )
    yield put(getPlaygroundFailure(action.meta, e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* updatePlaygroundHandler(action: ReturnType<typeof updatePlayground>) {
  const abortController = new AbortController()
  try {
    if (action.meta.uploadToS3) {
      const playgroundS3UploadSignedUrlRes: { uploadUrl: string } = yield call(
        getPlaygroundUpdateUrlAPI,
        action,
        abortController.signal
      )

      if (
        playgroundS3UploadSignedUrlRes &&
        playgroundS3UploadSignedUrlRes.uploadUrl
      ) {
        const data = { uploadUrl: playgroundS3UploadSignedUrlRes.uploadUrl }
        const uploadPlaygroundTos3Res: string = yield call(
          uploadPlaygroundToS3,
          data,
          action,
          abortController.signal
        )

        const updatePlaygroundData: string = yield call(
          updatePlaygroundAPI,
          action,
          abortController.signal
        )

        yield put(updatePlaygroundSuccess(action.meta, action.payload))
        yield put(
          showAlertMessage({
            variant: 'success',
            message: 'File saved',
          })
        )
        yield put(listPlaygrounds({ language: action.meta.language }, {}))
      } else {
        yield put(
          showAlertMessage({
            variant: 'error',
            message: 'Error occurred while saving file data',
          })
        )
      }
    } else {
      yield put(updatePlaygroundSuccess(action.meta, action.payload))
    }
  } catch (error) {
    yield put(updatePlaygroundFailure(action.meta, error))

    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Error occurred while saving file data',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* deletePlaygroundHandler(action: ReturnType<typeof deletePlayground>) {
  const abortController = new AbortController()
  try {
    const deletePlaygroundsResponse = yield call(
      deletePlaygroundAPI,
      action,
      abortController.signal
    )

    yield put(deletePlaygroundSuccess(action.meta, deletePlaygroundsResponse))
    yield put(listPlaygrounds({ language: action.meta.language }, {}))
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'File deleted',
      })
    )
  } catch (error) {
    yield put(deletePlaygroundFailure(action.meta, error))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Error occurred while deleting file',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* renamePlaygroundHandler(action: ReturnType<typeof renamePlayground>) {
  const abortController = new AbortController()
  try {
    const renamePlaygroundsResponse = yield call(
      renamePlaygroundAPI,
      action,
      abortController.signal
    )

    yield put(renamePlaygroundSuccess(action.meta, renamePlaygroundsResponse))
    yield put(listPlaygrounds({ language: action.meta.language }, {}))
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'File renamed',
      })
    )
  } catch (error) {
    yield put(renamePlaygroundFailure(action.meta, error))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Error occurred while renaming file',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* codingPlatformMiddleware() {
  yield takeLatest(
    RUNTIMES_FETCH,
    cancelable(fetchRuntimesHandler, RUNTIMES_FETCH_CANCEL)
  )
  yield takeLeading(
    CODE_EXECUTE,
    cancelable(codeExecutionHandler, CODE_EXECUTE_CANCEL)
  )

  yield takeEvery(
    QUESTION_RUN_CODE,
    cancelable(questionRunCodeHandler, QUESTION_RUN_CODE)
  )

  yield takeEvery(
    QUESTION_TEST_CODE,
    cancelable(questionTestCodeHandler, QUESTION_TEST_CODE_CANCEL)
  )

  yield takeEvery(
    QUESTION_TEST_SQL_CODE,
    cancelable(questiontestSqlCodeHandler, QUESTION_TEST_SQL_CODE_CANCEL)
  )

  yield takeEvery(
    QUESTION_RUN_SQL_CODE,
    cancelable(questionRunSqlCodeHandler, QUESTION_RUN_SQL_CODE)
  )

  yield takeLatest(
    LIST_PLAYGROUNDS,
    cancelable(listPlaygroundsHandler, LIST_PLAYGROUNDS_CANCEL)
  )

  yield takeEvery(
    GET_PLAYGROUND,
    cancelable(getPlaygroundHandler, GET_PLAYGROUND_CANCEL)
  )

  yield takeLatest(
    UPDATE_PLAYGROUND,
    cancelable(updatePlaygroundHandler, UPDATE_PLAYGROUND_CANCEL)
  )

  yield takeEvery(
    DELETE_PLAYGROUND,
    cancelable(deletePlaygroundHandler, DELETE_PLAYGROUND_CANCEL)
  )

  yield takeEvery(
    CREATE_PLAYGROUND,
    cancelable(createPlaygroundHandler, CREATE_PLAYGROUND_CANCEL)
  )

  yield takeEvery(
    RENAME_PLAYGROUND,
    cancelable(renamePlaygroundHandler, RENAME_PLAYGROUND_CANCEL)
  )

  yield takeEvery(
    QUESTION_GET_AI_CODE_SUGGESTIONS,
    cancelable(
      questionGetAiCodeSuggestionsHandler,
      QUESTION_GET_AI_CODE_SUGGESTIONS_CANCEL
    )
  )

  yield takeEvery(
    SQL_QUESTION_GET_AI_CODE_SUGGESTIONS,
    cancelable(
      sqlQuestionGetAiCodeSuggestionsHandler,
      SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_CANCEL
    )
  )

  yield takeEvery(
    QUESTION_GET_AI_CODE_HINTS,
    cancelable(questionGetAiCodeHintsHandler, QUESTION_GET_AI_CODE_HINTS_CANCEL)
  )
}
export default ([] as any).concat(codingPlatformMiddleware())
