import React, { useState } from 'react'
import { IconButton, Typography, Box } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Button from '../../Utils/Button'
import SnackbarStrip from '../SnackbarStrip'
import styles from '../Header.module.css'
import { updateUserOnboardingPage } from '../../../providers/User/UserOnboardingProvider'
import { generateURL } from '../../../../common/utils'

interface LinkedInReminderProps extends RouteComponentProps {
  updateUserOnboardingPage: typeof updateUserOnboardingPage
}

const LinkedInReminder = (props: LinkedInReminderProps) => {
  const [open, setOpen] = useState(true)

  const onClose = () => {
    localStorage.setItem('linkedInReminder', new Date().toDateString())
    setOpen(false)
  }

  let canOptOut = false
  const linkedinOnboardingSkipDate = localStorage.getItem('linkedinOnboarding')
  if (linkedinOnboardingSkipDate) {
    const timeSinceSkip = Date.now() - Number(linkedinOnboardingSkipDate)
    /**
     * Show LinkedIn onboarding step to user and make him mandatorily choose an option after 14 days
     * 14 Days === 1209600000 ms
     */
    if (timeSinceSkip > 1209600000) {
      canOptOut = true
    }
  }

  return (
    <SnackbarStrip
      message={
        <span id="client-snackbar">
          <Box display="flex">
            <Typography>
              Grow your professional network. Please enter your LinkedIn details
            </Typography>
            <Button
              onClick={() => {
                props.updateUserOnboardingPage({
                  page: 'linkedin',
                  canOptOut,
                })
                props.history.push(generateURL('ONBOARDING', null))
              }}
              className={styles.innerLink}
              color="primary"
            >
              Add LinkedIn details
            </Button>
          </Box>
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <Close />
        </IconButton>,
      ]}
      open={open}
    />
  )
}
export default withRouter(LinkedInReminder)
