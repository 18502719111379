import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'

interface LinkButtonProps
  extends ButtonProps,
    Pick<
      NavLinkProps,
      'target' | 'to' | 'activeClassName' | 'isActive' | 'replace'
    > {}

const AdapterLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  (props, ref) => <NavLink innerRef={ref as any} {...props} />
)

const LinkButton = (props: LinkButtonProps) => (
  <Button {...props} component={AdapterLink as any} />
)

export default LinkButton
