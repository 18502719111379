import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import * as APIs from './CoursePercentageCompleted.api'
import * as types from './CoursePercentageCompleted.types'

import {
  fetchCoursePercentageCompleted,
  fetchCoursePercentageCompletedSuccess,
  fetchCoursePercentageCompletedFailure,
} from './CoursePercentageCompleted.actions'

function* fetchCoursePercentageCompletedHandler(
  action: ReturnType<typeof fetchCoursePercentageCompleted>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.getCoursePercentageCompletedAPI,
      action,
      abortController.signal
    )
    yield put(fetchCoursePercentageCompletedSuccess(data))
  } catch (e) {
    yield put(fetchCoursePercentageCompletedFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* coursePercentageCompletedMiddleware() {
  yield takeLatest(
    types.FETCH_COURSE_PERCENTAGE_COMPLETED,
    cancelable(
      fetchCoursePercentageCompletedHandler,
      types.FETCH_COURSE_PERCENTAGE_COMPLETED_CANCEL
    )
  )
}
export default ([] as any).concat(coursePercentageCompletedMiddleware())
