import { apiCall } from 'common/utils'
import { IUserGiftCourse } from 'common/types/dashboard'
import {
  fetchLearnAndDevCareerPathMetrics,
  fetchLearnAndDevDashboardContent,
} from './Dashboard.actions'
import { UserID } from '../../../../common/types/user'
import { fetchLearnAndDevDepartmentMetrics } from '.'

export async function getLearnAndDevDashboardContentAPI(
  action: ReturnType<typeof fetchLearnAndDevDashboardContent>,
  userId: UserID,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/learn_and_dev_users/${userId}/dashboard`,
    query: {
      department_id: action.payload.departmentIds,
      start_date: action.payload.startDate,
      end_date: action.payload.endDate,
    },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getLearnAndDevDepartmentMetricsAPI(
  action: ReturnType<typeof fetchLearnAndDevDepartmentMetrics>,
  userId: UserID,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/learn_and_dev_users/${userId}/department_metrics`,
    query: {
      department_id: action.payload.departmentIds,
      start_date: action.payload.startDate,
      end_date: action.payload.endDate,
    },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getLearnAndDevCareerPathMetricsAPI(
  action: ReturnType<typeof fetchLearnAndDevCareerPathMetrics>,
  userId: UserID,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/learn_and_dev_users/${userId}/career_path_metrics`,
    query: {
      department_id: action.payload.departmentIds,
      start_date: action.payload.startDate,
      end_date: action.payload.endDate,
    },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export const updateSendGiftData = async (
  userId: number,
  userDetail: IUserGiftCourse,
  signal: AbortSignal
): Promise<any> => {
  const url = userDetail.is_international_user
    ? `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/generate_gift_course_url`
    : `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userId}/send_gift`

  const response = await apiCall({
    url,
    params: {
      signal,
      method: 'POST',
      body: JSON.stringify(userDetail),
    },
    auth: true,
  })

  if (response.ok) {
    return response.json()
  }

  return response.text().then(text => {
    throw new Error(text)
  })
}
