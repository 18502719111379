export const INITIATE_PROCTORING = 'INITIATE_PROCTORING'
export const INITIATE_PROCTORING_SUCCESS = 'INITIATE_PROCTORING_SUCCESS'
export const INITIATE_PROCTORING_FAILURE = 'INITIATE_PROCTORING_FAILURE'
export const INITIATE_PROCTORING_CANCEL = 'INITIATE_PROCTORING_CANCEL'

export const START_PROCTORING = 'START_PROCTORING'

export const UPDATE_USER_PROCTOR_INFO = 'UPDATE_USER_PROCTOR_INFO'
export const UPDATE_USER_PROCTOR_INFO_SUCCESS =
  'UPDATE_USER_PROCTOR_INFO_SUCCESS'
export const UPDATE_USER_PROCTOR_INFO_FAILURE =
  'UPDATE_USER_PROCTOR_INFO_FAILURE'
export const UPDATE_USER_PROCTOR_INFO_CANCEL = 'UPDATE_USER_PROCTOR_INFO_CANCEL'

export const STOP_PROCTORING = 'STOP_PROCTORING'
export const PAUSE_PROCTORING = 'PAUSE_PROCTORING'
export const PAUSE_PROCTORING_CANCEL = 'PAUSE_PROCTORING_CANCEL'
export const CONTINUE_PROCTORING = 'CONTINUE_PROCTORING'
export const CONTINUE_PROCTORING_CANCEL = 'CONTINUE_PROCTORING_CANCEL'
export const SET_FORCE_SUBMIT = 'SET_FORCE_SUBMIT'
export const SET_STREAM_INFO = 'SET_STREAM_INFO'

export const END_PROCTORING = 'END_PROCTORING'
export const END_PROCTORING_SUCCESS = 'END_PROCTORING_SUCCESS'
export const END_PROCTORING_FAILURE = 'END_PROCTORING_FAILURE'
export const END_PROCTORING_CANCEL = 'END_PROCTORING_CANCEL'

export const UPDATE_PROCTORING_DETAILS = 'UPDATE_PROCTORING_DETAILS'
export const UPDATE_PROCTORING_DETAILS_SUCCESS =
  'UPDATE_PROCTORING_DETAILS_SUCCESS'
export const UPDATE_PROCTORING_DETAILS_FAILURE =
  'UPDATE_PROCTORING_DETAILS_FAILURE'
export const UPDATE_PROCTORING_DETAILS_CANCEL =
  'UPDATE_PROCTORING_DETAILS_CANCEL'

export const SUBMIT_ONBOARDING_PHOTO = 'SUBMIT_ONBOARDING_PHOTO'
export const SUBMIT_ONBOARDING_PHOTO_SUCCESS = 'SUBMIT_ONBOARDING_PHOTO_SUCCESS'
export const SUBMIT_ONBOARDING_PHOTO_FAILURE = 'SUBMIT_ONBOARDING_PHOTO_FAILURE'
export const SUBMIT_ONBOARDING_PHOTO_CANCEL = 'SUBMIT_ONBOARDING_PHOTO_CANCEL'
export const UPDATE_EXAM_ONBOARDING = 'UPDATE_EXAM_ONBOARDING'

export const PROCTOR_CONTENT_FETCH_SUCCESS = 'PROCTOR_CONTENT_FETCH_SUCCESS'
