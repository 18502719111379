export const USER_ONBOARDING_DETAILS_FETCH = 'USER_ONBOARDING_DETAILS_FETCH'
export const USER_ONBOARDING_DETAILS_FETCH_SUCCESS =
  'USER_ONBOARDING_DETAILS_FETCH_SUCCESS'
export const USER_ONBOARDING_DETAILS_FETCH_FAILURE =
  'USER_ONBOARDING_DETAILS_FETCH_FAILURE'

export const PREFERENCES_QUESTIONS_FETCH = 'PREFERENCES_QUESTIONS_FETCH'
export const PREFERENCES_QUESTIONS_FETCH_SUCCESS =
  'PREFERENCES_QUESTIONS_FETCH_SUCCESS'
export const PREFERENCES_QUESTIONS_FETCH_FAILURE =
  'PREFERENCES_QUESTIONS_FETCH_FAILURE'

export const EXCELERATE_ONBOARDING_DATA_FETCH =
  'EXCELERATE_ONBOARDING_DATA_FETCH'
export const EXCELERATE_ONBOARDING_DATA_FETCH_SUCCESS =
  'EXCELERATE_ONBOARDING_DATA_FETCH_SUCCESS'
export const EXCELERATE_ONBOARDING_DATA_FETCH_FAILURE =
  'EXCELERATE_ONBOARDING_DATA_FETCH_FAILURE'

export const USER_ONBOARDING_DETAILS_UPDATE = 'USER_ONBOARDING_DETAILS_UPDATE'
export const USER_ONBOARDING_DETAILS_UPDATE_SUCCESS =
  'USER_ONBOARDING_DETAILS_UPDATE_SUCCESS'
export const USER_ONBOARDING_DETAILS_UPDATE_FAILURE =
  'USER_ONBOARDING_DETAILS_UPDATE_FAILURE'

export const USER_ONBOARDING_PAGE_UPDATE = 'USER_ONBOARDING_PAGE_UPDATE'
export const USER_ONBOARDING_REDIRECT_URL = 'USER_ONBOARDING_REDIRECT_URL'
