import React from 'react'
import { Button, Typography, Box } from '@material-ui/core'
import leftArrow from 'common/images/icons/alumni/left-arrow.svg'
import roundLeftArrow from 'common/images/icons/left-arrow-grey.svg'
import styles from './NewFreeOnboardingFormDialog.module.css'

interface Props {
  isMobile: boolean
  onBackBtn: () => void
  onSkipAction: () => void
  onSubmit: () => void
  isDisabled: boolean
  backButton: boolean
  skipButton: boolean
  nextButtonText: string
}
const NewFormBtns = (props: Props) => {
  return props.isMobile ? (
    <Box className={styles.skipBackBtnWrapper}>
      <Box>
        {props.backButton ? (
          <Button
            variant="text"
            color="primary"
            className={styles.mobileBackBtn}
            onClick={props.onBackBtn}
          >
            <img src={roundLeftArrow} alt="back" />
          </Button>
        ) : null}
      </Box>
      {props.skipButton ? (
        <Button
          variant="text"
          color="primary"
          className={styles.mobileSkip}
          onClick={props.onSkipAction}
        >
          Skip
        </Button>
      ) : null}
    </Box>
  ) : (
    <Box className={styles.buttonWrapper}>
      <Box>
        {props.backButton ? (
          <Button
            variant="text"
            className={styles.showOnlyDesktop}
            color="primary"
            onClick={props.onBackBtn}
          >
            <img src={leftArrow} alt="back" /> <Typography>Back</Typography>
          </Button>
        ) : null}
      </Box>
      <Box className={styles.skipSubmitWrapper}>
        {props.skipButton ? (
          <Button
            className={styles.showOnlyDesktop}
            variant="text"
            color="primary"
            onClick={props.onSkipAction}
          >
            Skip
          </Button>
        ) : null}
        <Button
          variant="contained"
          color="primary"
          className={styles.submitBtn}
          disabled={props.isDisabled}
          onClick={props.onSubmit}
        >
          {props.nextButtonText}
        </Button>
      </Box>
    </Box>
  )
}

NewFormBtns.defaultProps = {
  isMobile: false,
}

export default NewFormBtns
