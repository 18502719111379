import { CLEAR_PAGE_DATA, UPDATE_PAGE_DATA } from './PageData.types'
import { PageInfo } from '../../../common/types/pages'

export const clearPageData = () => ({
  type: CLEAR_PAGE_DATA as typeof CLEAR_PAGE_DATA,
})

export const updatePageData = (
  payload: PageInfo,
  meta?: {
    clearBeforeUpdate?: boolean
  }
) => ({
  type: UPDATE_PAGE_DATA as typeof UPDATE_PAGE_DATA,
  payload,
  meta: {
    ...meta,
    clearBeforeUpdate: false,
  },
})

export type PageDataActionTypes =
  | ReturnType<typeof clearPageData>
  | ReturnType<typeof updatePageData>
