import { isExcelerateCourse } from 'common/utils/custom/courses'
import { AppState } from '../../../store'
import {
  ActiveExcelerateCourseData,
  CourseID,
} from '../../../../common/types/courses'

export const getCoursesDataByID = () => (state: AppState) =>
  state.courses.data.byId

export const getAllCourseIDs = () => (state: AppState): CourseID[] => {
  return Object.keys(getCoursesDataByID()(state))
}

export const excelerateModuleCompletionTimeline = (courseId: CourseID) => (
  state: AppState
): ActiveExcelerateCourseData['module_completion_dates'] | undefined => {
  const courseData = state.courses.data.byId[courseId]

  return courseData && isExcelerateCourse(courseData)
    ? courseData.module_completion_dates
    : undefined
}
