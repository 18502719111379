import {
  CameraVideoTrackInitConfig,
  ClientConfig,
  ClientRole,
  ClientRoleOptions,
  EncryptionMode,
  LowStreamParameter,
  MicrophoneAudioTrackInitConfig,
  ScreenVideoTrackInitConfig,
} from 'agora-rtc-sdk-ng'
import { ICustomStreamProperties, UILayout } from 'common/types/zoomout'

export type ZoomoutDefaultsType = {
  config: Omit<ClientConfig, 'clientRoleOptions'> & {
    encryptionMode: EncryptionMode
    audienceRoleOptions: ClientRoleOptions
  }
  layout: UILayout
  microphone: MicrophoneAudioTrackInitConfig & ICustomStreamProperties
  camera: CameraVideoTrackInitConfig & ICustomStreamProperties
  screen: ScreenVideoTrackInitConfig & ICustomStreamProperties
  lowQualityCamera: LowStreamParameter
  // lowQualityScreen: LowStreamParameter
}

/**
 * CALCULATION FOR LOW STREAM:
 */

const getPerUserLowStream = (
  threshold: number,
  aspectRatio: number, // 16/9 or 4/3 i.e width/height
  maxConcurrentSubscriptions: number
) => {
  const height = Math.sqrt(
    threshold / (maxConcurrentSubscriptions * aspectRatio)
  )
  const width = aspectRatio * height

  return { height: Math.floor(height), width: Math.floor(width) }
}

/**
 * 240 -> height
 * 426 -> width
 */
const HDThresholdLowStream = getPerUserLowStream(1280 * 720, 16 / 9, 9)

const ZoomoutDefaults: ZoomoutDefaultsType = {
  config: {
    mode: 'live',
    codec: 'vp8', // cloud recording bot doesn't support vp9 yet
    role: 'audience' as ClientRole,
    encryptionMode: 'aes-256-gcm',
    audienceRoleOptions: {
      level: 2,
    },
  },
  layout: UILayout.gallery,
  microphone: {
    isEnabled: false,
    ANS: true, // google meet uses ANS
    AGC: true, // agora recommended to enable this as disabling it was leading to low audio issues
    AEC: true, // google meet uses echo cancellation
    encoderConfig: 'speech_standard',
  },
  camera: {
    isEnabled: false,
    cameraId: undefined,
    facingMode: 'user',
    optimizationMode: 'motion',
    encoderConfig: '720p',
  },
  screen: {
    isEnabled: false,
    optimizationMode: 'detail',
    screenSourceType: 'screen',
    encoderConfig: {
      width: {
        max: 1920,
      },
      height: {
        max: 1080,
      },
      frameRate: {
        max: 30,
      },
    },
  },
  lowQualityCamera: {
    framerate: {
      max: 30,
    },
    bitrate: 220,
    width: {
      max: HDThresholdLowStream.width,
    },
    height: {
      max: HDThresholdLowStream.height,
    },
  },
}

export default ZoomoutDefaults
