import { AppState } from '../../../store'
import {
  ProgramID,
  ProgramsData,
  ProgramData,
} from '../../../../common/types/programs'

export const getProgramId = () => (state: AppState): ProgramID | null => {
  const programsData: ProgramsData | undefined = state.programs.data
  if (programsData && programsData.activeProgramID) {
    return programsData.activeProgramID
  }
  return null
}

export const getSiblingBatchId = () => (state: AppState): number => {
  return state.user.payments.transactions.paymentsInfo?.sibling_program_group_id || 0
}

export const getActiveProgramDetails = () => (
  state: AppState
): ProgramData | null => {
  const programsData: ProgramsData | undefined = state.programs.data
  if (
    programsData &&
    programsData.activeProgramID &&
    programsData.byId[programsData.activeProgramID]
  ) {
    return programsData.byId[programsData.activeProgramID]!
  }
  return null
}

export const digitalCampusUser = () => (state: AppState): boolean => {
  const programsData: ProgramsData | undefined = state.programs.data
  if (programsData && programsData.byId) {
    const programs = Object.keys(programsData.byId).filter(
      program_id =>
        programsData.byId[program_id] &&
        programsData.byId[program_id]!.digital_campus_program
    )
    return programs.length > 0
  }
  return false
}

export const digitalCampusUserWithoutTimezoneException = () => (
  state: AppState
): boolean => {
  const programsData: ProgramsData | undefined = state.programs.data
  if (programsData && programsData.byId) {
    const programsWithoutException = Object.keys(programsData.byId).filter(
      program_id =>
        programsData.byId[program_id] &&
        programsData.byId[program_id]!.digital_campus_program &&
        programsData.byId[program_id]!.digital_campus_id &&
        ![2603, 359, 1043].includes(
          programsData.byId[program_id]!.digital_campus_id!
        ) // enable timezone change for Hindustan campus & others
    )
    return programsWithoutException.length > 0
  }
  return false
}

export const isDigitalCampusProgram = () => (state: AppState): boolean => {
  const programsData: ProgramsData | undefined = state.programs.data
  return !!(
    !!programsData &&
    !!programsData.activeProgramID &&
    programsData.byId?.[programsData.activeProgramID]?.digital_campus_program
  )
}

export const onlyDigitalCampusUser = () => (state: AppState): boolean => {
  const programsData: ProgramsData | undefined = state.programs.data
  if (programsData && programsData.byId) {
    const programs = Object.keys(programsData.byId)

    const allProgramsHaveDigitalCampus = programs.every(
      program_id =>
        programsData.byId[program_id] &&
        programsData.byId[program_id]!.digital_campus_program
    )

    return allProgramsHaveDigitalCampus
  }
  return false
}

export const getGlAcademyId = () => (state: AppState): ProgramID | null => {
  const programsData: ProgramsData | undefined = state.programs.data
  if (programsData) return programsData.glAcademyID
  return null
}

export const getAllProgramIds = () => (state: AppState): ProgramID[] | null => {
  const programsData: ProgramsData | undefined = state.programs.data
  if (programsData) return programsData.enrolledProgramIDs
  return null
}

export const isDegreeProgram = () => (state: AppState): boolean => {
  const activeProgram = getActiveProgramDetails()(state)
  if (activeProgram && activeProgram.degree_program_code) return true
  return false
}

export const isInternationalProgram = () => (state: AppState): boolean => {
  const activeProgram = getActiveProgramDetails()(state)
  if (activeProgram && activeProgram.is_international) return true
  return false
}

export const shouldHideGrade = () => (state: AppState): boolean => {
  const activeProgram = getActiveProgramDetails()(state)
  if (activeProgram && activeProgram.hide_grades_for_learners) return true
  return false
}

export const getTranslationLanguage = () => (state: AppState): string => {
  const activeProgram = getActiveProgramDetails()(state)
  if (activeProgram && activeProgram.translation_enabled) {
    return activeProgram.translation_language || 'en'
  }
  return 'en'
}
