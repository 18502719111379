import {
  FETCH_PRIVATE_CERTIFICATES_CONTENT,
  FETCH_PRIVATE_CERTIFICATES_CONTENT_SUCCESS,
  FETCH_PRIVATE_CERTIFICATES_CONTENT_FAILURE,
  FETCH_PRIVATE_CERTIFICATES_CONTENT_CANCEL,
} from './PrivateCertificate.types'

export const fetchPrivateCertificatesContent = () => ({
  type: FETCH_PRIVATE_CERTIFICATES_CONTENT as typeof FETCH_PRIVATE_CERTIFICATES_CONTENT,
})

export const fetchPrivateCertificatesContentSuccess = (payload: any) => ({
  type: FETCH_PRIVATE_CERTIFICATES_CONTENT_SUCCESS as typeof FETCH_PRIVATE_CERTIFICATES_CONTENT_SUCCESS,
  payload,
})

export const fetchPrivateCertificatesContentFailure = (payload: Error) => ({
  type: FETCH_PRIVATE_CERTIFICATES_CONTENT_FAILURE as typeof FETCH_PRIVATE_CERTIFICATES_CONTENT_FAILURE,
  payload,
})

export const fetchPrivateCertificatesContentCancel = () => ({
  type: FETCH_PRIVATE_CERTIFICATES_CONTENT_CANCEL as typeof FETCH_PRIVATE_CERTIFICATES_CONTENT_CANCEL,
})

export type PrivateCertificatesActions =
  | ReturnType<typeof fetchPrivateCertificatesContent>
  | ReturnType<typeof fetchPrivateCertificatesContentSuccess>
  | ReturnType<typeof fetchPrivateCertificatesContentFailure>
  | ReturnType<typeof fetchPrivateCertificatesContentCancel>
