import { AllProgramsData, AllProgramsApiParam } from 'common/types/programs'
import {
  FETCH_ALL_PROGRAMS,
  FETCH_ALL_PROGRAMS_SUCCESS,
  FETCH_ALL_PROGRAMS_FAILURE,
  FETCH_ALL_PROGRAMS_CANCEL,
} from './AllPrograms.types'

export const fetchAllPrograms = (
  payload: AllProgramsApiParam | null = null
) => ({
  type: FETCH_ALL_PROGRAMS as typeof FETCH_ALL_PROGRAMS,
  payload,
})

export const fetchAllProgramsSuccess = (payload: AllProgramsData) => ({
  type: FETCH_ALL_PROGRAMS_SUCCESS as typeof FETCH_ALL_PROGRAMS_SUCCESS,
  payload,
})

export const fetchAllProgramsFailure = (payload: Error) => ({
  type: FETCH_ALL_PROGRAMS_FAILURE as typeof FETCH_ALL_PROGRAMS_FAILURE,
  payload,
})

export const fetchAllProgramsCancel = () => ({
  type: FETCH_ALL_PROGRAMS_CANCEL as typeof FETCH_ALL_PROGRAMS_CANCEL,
})

export type AllProgramsActions =
  | ReturnType<typeof fetchAllPrograms>
  | ReturnType<typeof fetchAllProgramsSuccess>
  | ReturnType<typeof fetchAllProgramsFailure>
  | ReturnType<typeof fetchAllProgramsCancel>
