import { call, put, takeLatest } from 'redux-saga/effects'
import {
  fetchEportfolio,
  fetchEportfolioSuccess,
  fetchEportfolioFailure,
  toggleProjectVisibility,
  updateProjectLearnings,
  toggleProjectVisibilityFailure,
  updateProjectLearningsFailure,
  fetchUserByEportfolioUserName,
  toggleProjectVisibilitySuccess,
  updateProjectLearningsSuccess,
} from './Eportfolio.actions'

import * as EportfolioAPIs from './Eportfolio.api'
import * as EportfolioTypes from './Eportfolio.types'

import { showAlertMessage } from '../AlertsProvider'

function* fetchEportfolioHandler(action: ReturnType<typeof fetchEportfolio>) {
  const abortController = new AbortController()
  try {
    // @ts-ignore
    const data = yield call(
      EportfolioAPIs.getEportfolioAPI,
      action,
      abortController.signal
    )
    yield put(fetchEportfolioSuccess(data))
  } catch (e) {
    yield put(fetchEportfolioFailure(e as Error))
  }
}

function* toggleProjectVisibilityHandler(
  action: ReturnType<typeof toggleProjectVisibility>
) {
  const abortController = new AbortController()
  try {
    yield put(
      showAlertMessage({
        message: 'Please wait...',
        variant: 'info',
        closeOnTimeout: false,
      })
    )

    // @ts-ignore
    const data = yield call(
      EportfolioAPIs.toggleProjectVisibilityAPI,
      action,
      action.projectId,
      action.visibility,
      abortController.signal
    )

    yield put(toggleProjectVisibilitySuccess(action.projectId))

    yield put(
      showAlertMessage({
        message: 'Updated the visibility successfully',
        variant: 'success',
        closeOnTimeout: false,
      })
    )
  } catch (e) {
    yield put(toggleProjectVisibilityFailure(e as Error))
    yield put(
      showAlertMessage({
        message: 'Sorry something went wrong!',
        variant: 'error',
        closeOnTimeout: false,
      })
    )
  }
}

function* updateProjectLearningsHandler(
  action: ReturnType<typeof updateProjectLearnings>
) {
  const abortController = new AbortController()
  try {
    yield put(
      showAlertMessage({
        message: 'Please wait...',
        variant: 'info',
        closeOnTimeout: false,
      })
    )

    // @ts-ignore
    const data = yield call(
      EportfolioAPIs.updateProjectLearningsAPI,
      action,
      action.projectId,
      action.learnings,
      abortController.signal
    )

    yield put(updateProjectLearningsSuccess(action.projectId, action.learnings))

    yield put(
      showAlertMessage({
        message: 'Updated the project successfully',
        variant: 'success',
        closeOnTimeout: false,
      })
    )
  } catch (e) {
    yield put(updateProjectLearningsFailure(e as Error))
    yield put(
      showAlertMessage({
        message: 'Sorry something went wrong!',
        variant: 'error',
        closeOnTimeout: false,
      })
    )
  }
}

function* fetchUserByEportfolioUserNameHandler(
  action: ReturnType<typeof fetchUserByEportfolioUserName>
) {
  const abortController = new AbortController()
  try {
    // @ts-ignore
    const data = yield call(
      EportfolioAPIs.getUserDetailsByUsername,
      action,
      action.username,
      abortController.signal
    )

    if(data) {
      yield put(fetchEportfolioSuccess(data))
    } else {
      yield put(fetchEportfolioFailure(data))
    }
  } catch (e) {
    yield put(fetchEportfolioFailure(e as Error))
  }
}

export function* fetchEportfolioMiddleware() {
  yield takeLatest(EportfolioTypes.FETCH_EPORTFOLIO, fetchEportfolioHandler)
}

export function* toggleProjectVisibilityMiddleware() {
  yield takeLatest(
    EportfolioTypes.TOGGLE_PROJECT_VISIBILITY,
    toggleProjectVisibilityHandler
  )
}

export function* updateProjectLearningsMiddleware() {
  yield takeLatest(
    EportfolioTypes.UPDATE_PROJECT_LEARNINGS,
    updateProjectLearningsHandler
  )
}

export function* fetchUserByEportfolioUserNameMiddleware() {
  yield takeLatest(
    EportfolioTypes.FETCH_USER_BY_EPORTFOLIO_USERNAME,
    fetchUserByEportfolioUserNameHandler
  )
}

export default ([] as any).concat(
  fetchEportfolioMiddleware(),
  toggleProjectVisibilityMiddleware(),
  updateProjectLearningsMiddleware(),
  fetchUserByEportfolioUserNameMiddleware()
)
