import { IAgoraJoinOptions } from 'common/types/agora-rtc-ng-sdk-additional'
import { PARTICIPANT_ROLES } from 'common/types/zoomout'
import { isMobileBrowser } from 'common/utils'
import { mixpanel } from 'common/utils/mixpanel'
import { SOFT_AGORA_HOST_LIMIT, getZoomoutEventId } from 'common/utils/zoomout'
import { createPeer } from 'web/providers/Zoomout/Internal/Internal.actions'
import { CALL_STAGES } from 'web/providers/Zoomout/Internal/Internal.reducer'
import rootStore from 'web/store'
import { showAlertMessage } from 'web/providers/AlertsProvider'
import pushZoomoutEvent from '../pushZoomoutEvent'
import { StopCamera } from './toggleCamera'
import { StopMic } from './toggleMicrophone'
import zoomoutSettings from '../settings'

const DEFAULT_UI_TRIAL_MINUTES = 60

export const isItUITrialTime = ({
  softStartTime,
  now,
  UITrialMinutes = DEFAULT_UI_TRIAL_MINUTES,
}: {
  softStartTime: Date
  now: Date
  UITrialMinutes?: number
}) => {
  if (now >= softStartTime) return false

  const UITrialStartTime = new Date(softStartTime)
  UITrialStartTime.setMinutes(UITrialStartTime.getMinutes() - UITrialMinutes)

  if (now < UITrialStartTime) return false

  return true
}

const JoinRTM = async ({ uid, rtmToken }: IAgoraJoinOptions) => {
  if (!window.zoomout) throw new Error('Zoomout is not initialized')
  if (!rtmToken) throw new Error('Invalid Credentials')

  // RTM needs a string uid, and uid is NOT optional
  await window.zoomout.rtmClient.login({
    uid: uid!.toString(),
    token: rtmToken,
  })

  try {
    await window.zoomout.rtmChannel.join()
    const memberUIDS = await window.zoomout.rtmChannel.getMembers()
    memberUIDS.forEach(memberUid => {
      const parsedMemberUid = parseInt(memberUid, 10)
      if (parsedMemberUid !== uid) {
        rootStore.dispatch(createPeer({ uid: parsedMemberUid }))
      }
    })
  } catch (error) {
    try {
      // logout from rtm so that the user can retry
      await window.zoomout.rtmClient.logout()
    } catch (e) {
      // reload page as a last resort if rtm logout fails
      window.location.reload()
    }
    throw error
  }
}

const JoinRTC = async ({
  appId,
  channel,
  rtcToken,
  uid,
}: IAgoraJoinOptions) => {
  if (!window.zoomout) throw new Error('Zoomout is not initialized')
  if (!window.zoomout.mainRtcClient)
    throw new Error('Main RTC client is not initialized')

  try {
    const assigned_uid = await window.zoomout.mainRtcClient.join(
      appId,
      channel,
      rtcToken,
      uid
    )
    mixpanel.track('Zoomout Joining Data', {
      uid,
    })
  } catch (error) {
    // logout from rtm so that the user can retry
    try {
      await window.zoomout.rtmChannel.leave()
      await window.zoomout.rtmClient.logout()
    } catch (e) {
      // reload page as a last resort if rtm logout fails
      window.location.reload()
    }

    // forward throw the main error
    throw error
  }
}

const PublishStreams = async () => {
  // publish only those streams which the user had configured to be enabled before the call
  if (!window.zoomout) throw new Error('Zoomout is not initialized')
  if (!window.zoomout.mainRtcClient)
    throw new Error('Main RTC client is not initialized')

  const {
    microphone,
    camera,
  } = rootStore.getState().zoomout.internal.localTracksDetails

  let micPromise = Promise.resolve()
  let cameraPromise = Promise.resolve()
  // remoteUsers array isn't populated immediately
  // introducing a max 5 second delay for joining when the user tries to join in with their mic/camera on
  if ((microphone && microphone.isEnabled) || (camera && camera.isEnabled)) {
    await new Promise(resolve => {
      const timer = setTimeout(() => {
        resolve()
      }, 5000)

      if (
        window.zoomout &&
        window.zoomout.mainRtcClient.remoteUsers.length > 0
      ) {
        clearInterval(timer)
        resolve()
      }
    })
  }

  // when there are too many people in the call don't publish the streams
  if (window.zoomout.mainRtcClient.remoteUsers.length > SOFT_AGORA_HOST_LIMIT) {
    const devices = []
    if (microphone && microphone.isEnabled) {
      await StopMic({
        isCameraPublished: !!(camera && camera.isEnabled),
        micTrack: microphone.track,
      })
      devices.push('mic')
    }
    if (camera && camera.isEnabled) {
      await StopCamera({
        isMicPublished: false,
        cameraTrack: camera.track,
      })
      devices.push('camera')
    }

    if (devices.length > 0) {
      rootStore.dispatch(
        showAlertMessage({
          variant: 'warning',
          message: `Your ${devices.join(' and ')} ${
            devices.length === 1 ? 'has' : 'have'
          } been turned off due to the size of the call.`,
        })
      )
    }
    return {
      micPublished: false,
      cameraPublished: false,
    }
  }

  if (microphone && microphone.isEnabled) {
    // check if role is already host
    if (zoomoutSettings.config.mode === 'live') {
      await window.zoomout.mainRtcClient.setClientRole('host')
    }
    micPromise = window.zoomout.mainRtcClient.publish(microphone.track)
  }
  if (camera && camera.isEnabled) {
    // check if role is already host
    if (zoomoutSettings.config.mode === 'live') {
      await window.zoomout.mainRtcClient.setClientRole('host')
    }
    cameraPromise = window.zoomout.mainRtcClient.publish(camera.track)
  }

  await Promise.all([micPromise, cameraPromise])

  return {
    micPublished: Boolean(microphone && microphone.isEnabled),
    cameraPublished: Boolean(camera && camera.isEnabled),
  }
}

const installUITrialTimer = ({
  softStartTime,
  UITrialMinutes,
}: {
  softStartTime: Date
  UITrialMinutes?: number
}) => {
  const { role = PARTICIPANT_ROLES.LEARNER } =
    rootStore.getState().zoomout.external.joiningDetails || {}

  const now = new Date()
  if (
    role !== PARTICIPANT_ROLES.LEARNER &&
    isItUITrialTime({ now, softStartTime, UITrialMinutes })
  ) {
    const DURATION_TILL_SOFT_START_TIME =
      softStartTime.getTime() - now.getTime()
    // reload the UI on reaching softStartTime
    setTimeout(() => {
      const state = rootStore.getState().zoomout.internal
      const isInCall =
        state.callStage === CALL_STAGES.LIVE_IN_CALL ||
        state.callStage === CALL_STAGES.RECONNECTING_IN_CALL
      if (isInCall) window.location.reload()
    }, DURATION_TILL_SOFT_START_TIME)
  }
}

const JoinMeeting = async (joinInfo: IAgoraJoinOptions) => {
  try {
    const {
      hardEndTime = new Date(),
      softStartTime = new Date(),
      UITrialMinutes,
    } = rootStore.getState().zoomout.external.timingDetails || {}

    // dont allow to join if hardEndTime has passed
    if (hardEndTime <= new Date()) window.location.reload()
    installUITrialTimer({ softStartTime, UITrialMinutes })

    // First join rtm
    await JoinRTM(joinInfo)
    // then ONLY JOIN RTC
    await JoinRTC(joinInfo)

    await pushZoomoutEvent({
      eventID: getZoomoutEventId('SESSION_JOIN'),
      extraInfo: { isMobile: isMobileBrowser() },
    })

    const { micPublished, cameraPublished } = await PublishStreams()

    // wait till the SessionJoin promise has resolved before pushing mic and camera states
    Promise.all([
      pushZoomoutEvent({
        eventID: micPublished
          ? getZoomoutEventId('UNMUTE_MIC')
          : getZoomoutEventId('MUTE_MIC'),
      }),
      pushZoomoutEvent({
        eventID: cameraPublished
          ? getZoomoutEventId('UNMUTE_CAMERA')
          : getZoomoutEventId('MUTE_CAMERA'),
      }),
    ])

    return true
  } catch (err) {
    const error = err as any

    mixpanel.track('Zoomout Errors', {
      feature: 'Join Meeting',
      code: error.code,
      message: error.message,
    })
    return false
  }
}

export default JoinMeeting
