import { SHOW_ALERT_MESSAGE, REMOVE_ALERT_MESSAGE } from './Alerts.types'
import { AlertsData } from '../../../common/types/alerts'

export const showAlertMessage = (payload: AlertsData) => ({
  type: SHOW_ALERT_MESSAGE as typeof SHOW_ALERT_MESSAGE,
  payload,
})

export const removeAlertMessage = () => ({
  type: REMOVE_ALERT_MESSAGE as typeof REMOVE_ALERT_MESSAGE,
})

export type AlertsActionTypes =
  | ReturnType<typeof showAlertMessage>
  | ReturnType<typeof removeAlertMessage>
