import React, { useEffect, useState, useRef } from 'react'
import {
  Paper,
  InputBase,
  IconButton,
  Box,
  Typography,
  ClickAwayListener,
  useMediaQuery,
  Drawer,
  Theme,
  useTheme,
  InputAdornment,
} from '@material-ui/core'
import { CloseOutlined, Search as SearchIcon } from '@material-ui/icons'
import { glaSearchPageUrl } from 'common/utils/custom/user'
import styles from './GlaSearch.module.css'

const GlaSearch = () => {
  const theme: Theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true })

  const searchRef = useRef<HTMLInputElement>(null)
  const [showResultsBox, setShowResultsBox] = useState(false)
  const [showSearchDrawer, setShowSearchDrawer] = useState(false)

  const handleSearchClick = () => {
    setShowResultsBox(true)
    const searchValueTemp = (searchRef.current && searchRef.current.value) || ''
    if (searchValueTemp && searchValueTemp.trim().length >= 2) {
      const redirectionUrl = glaSearchPageUrl(searchValueTemp)
      window.location.href = redirectionUrl
    }
  }

  const handleClose = () => {
    if (searchRef.current !== null) {
      searchRef.current!.value = ''
    }
    setShowResultsBox(false)
    setShowSearchDrawer(false)
  }

  const handleKeypress = (e: React.KeyboardEvent) => {
    setShowResultsBox(true)
    if (e.keyCode === 13) {
      e.preventDefault()
      handleSearchClick()
    }
  }

  useEffect(() => {
    if (showSearchDrawer && searchRef.current) {
      searchRef.current.focus()
    }
  }, [showSearchDrawer])

  const renderSearchResults = () => {
    return (
      <Box className={styles.resultBox} data-testid="gla-search-content">
        <div className={styles.pgProgramHeadingWrapper}>
          <Typography>Enter 2 characters & hit enter to search</Typography>
        </div>
      </Box>
    )
  }

  const renderSearch = () => (
    <div className={styles.topContainer}>
      <Paper component="div" elevation={0} className={styles.searchBox}>
        <InputBase
          placeholder="Try Data Science"
          inputProps={{ 'aria-label': 'search' }}
          inputRef={searchRef}
          fullWidth
          onKeyDown={handleKeypress}
          startAdornment={
            <InputAdornment position="start" className={styles.inputSearchIcon}>
              <SearchIcon />
            </InputAdornment>
          }
        />
      </Paper>
      {showResultsBox ? renderSearchResults() : null}
    </div>
  )

  const desktopSearch = () => (
    <ClickAwayListener onClickAway={() => handleClose()}>
      <div>{renderSearch()}</div>
    </ClickAwayListener>
  )
  const mobileSearch = () => (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={() => setShowSearchDrawer(true)}
        className={styles.search}
      >
        <SearchIcon />
      </IconButton>
      <Drawer
        variant="temporary"
        open={showSearchDrawer}
        classes={{
          paper: styles.searchDrawer,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <div className={styles.drawerContainer}>
          <div className={styles.drawerCloseIcon}>
            <CloseOutlined onClick={() => handleClose()} />
          </div>
          {renderSearch()}
        </div>
      </Drawer>
    </>
  )

  return isDesktop ? desktopSearch() : mobileSearch()
}

export default GlaSearch
