/* eslint-disable no-nested-ternary */
import { Box } from '@material-ui/core'
import {
  isFreeUser,
  isAcademyInternationalUser,
} from 'common/utils/custom/user'
import { mixpanel } from 'common/utils/mixpanel'
import { generateURL } from 'common/utils'
import {
  fetchGlaCertificatePayment,
  GlaCertificatePaymentState,
} from 'web/providers/GlaCertificatePaymentProvider'
import {
  useLocation,
  withRouter,
  RouteComponentProps,
  matchPath,
  useHistory,
} from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { CourseID } from 'common/types/courses'
import { connect } from 'react-redux'
import AcademyCertificatePaymentDialog from 'web/components/Dashboard/AcademyCertificatePaymentDialog'
import AcademyPremiumCoursePopup from 'web/components/Dashboard/AcademyPremiumCoursePopup'
import { UserDetailsState } from 'web/providers/User/UserDetailsProvider'
import { AppState } from 'web/store'
import { CoursesState } from '../../providers/Courses/CoursesProvider/Courses.reducer'
import routes from '../../routes'
import { fetchDashboardContent } from '../../providers/Dashboard/DashboardProvider'
import { isNewPremium } from '../../../common/utils/custom/courses/index'
import { ModuleItemsState } from '../../providers/Courses/ModuleItemsProvider'
import { AcademyUserCourseData } from '../../../common/types/courses'

interface StateProps {
  userDetails: UserDetailsState
  courseData: CoursesState['data']
  moduleItems: ModuleItemsState
  glaCertificatePaymentDetail: GlaCertificatePaymentState
  fetchGlaCertificatePayment: typeof fetchGlaCertificatePayment
  fetchDashboardContent: typeof fetchDashboardContent
}

interface PaymentCookieData {
  frequency: number
  show_payment_popup: boolean
  expires_at: number
}

type AcademyCertificatePaymentPopupProps = StateProps & RouteComponentProps

const AcademyCertificatePaymentPopupCont = (
  props: AcademyCertificatePaymentPopupProps
) => {
  const matchedPathNameCourse = matchPath(props.location.pathname, {
    path: routes.COURSE,
    exact: true,
  })

  const matchedPathNameCourseContent = matchPath(props.location.pathname, {
    path: routes.CONTENT,
    exact: true,
  })

  const location = useLocation()
  const history = useHistory()
  const [showPopup, setShowPopup] = useState(false)
  const [trafficSource, setTrafficSource] = useState('')
  const [showPopUpByParam, setPopUpByParam] = useState(false)

  useEffect(() => {
    if (!props.courseData) {
      props.fetchDashboardContent()
    }
  }, [props.courseData])

  const queryParams = new URLSearchParams(location.search)
  let moduleIsLocked = false
  if (queryParams.get('module_item_id')) {
    const moduleItemId = Number(queryParams.get('module_item_id'))
    moduleIsLocked =
      (props.moduleItems.data.byId[moduleItemId] &&
        props.moduleItems.data.byId[moduleItemId].lockInfo.locked) ||
      false
  }
  const courseId: CourseID = matchedPathNameCourse
    ? (matchedPathNameCourse.params as Record<string, string>).courseId
    : matchedPathNameCourseContent
    ? (matchedPathNameCourseContent.params as Record<string, string>).courseId
    : ''

  const cookieName = 'payment_popup_details'
  let courseCookieData: { [key: string]: PaymentCookieData } = {}
  const localStorageData = localStorage.getItem(cookieName)
  if (localStorageData) {
    try {
      courseCookieData = JSON.parse(localStorageData)
    } catch (e) {
      console.error('Error parsing courses cookie:', e)
    }
  }

  const dialogClose = () => {
    setShowPopup(false)
    setPopUpByParam(false)
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.get('payment_source')) {
      queryParams.delete('payment_source')
      history.replace({
        search: queryParams.toString(),
      })
    }

    if (courseCookieData[courseId]) {
      courseCookieData[courseId].show_payment_popup = false
      localStorage.setItem(cookieName, JSON.stringify(courseCookieData))
    }
    if (showPopUpByParam) {
      history.push(
        generateURL('COURSE', {
          path: { courseId },
        })
      )
    }
  }

  const managePaymentCookie = (lms_course_id: string) => {
    const currenTime = new Date().getTime()
    const oneDayLater = currenTime + 24 * 60 * 60 * 1000
    const queryParams = new URLSearchParams(location.search)

    if (!courseCookieData[lms_course_id]) {
      courseCookieData[lms_course_id] = {
        frequency: 1,
        show_payment_popup: true,
        expires_at: oneDayLater,
      }
    } else if (
      currenTime >= courseCookieData[lms_course_id].expires_at ||
      queryParams.get('payment_source')
    ) {
      courseCookieData[lms_course_id].frequency += 1
      courseCookieData[lms_course_id].show_payment_popup = true
      courseCookieData[lms_course_id].expires_at = oneDayLater
    } else {
      courseCookieData[lms_course_id].show_payment_popup = false
      setShowPopup(false)
    }
    localStorage.setItem(cookieName, JSON.stringify(courseCookieData))
  }

  useEffect(() => {
    if (courseId && !matchedPathNameCourseContent) {
      managePaymentCookie(courseId.toString())
    }
  }, [courseId])

  const courseDataById =
    props.courseData &&
    (props.courseData.byId[courseId] as AcademyUserCourseData)
  const premiumFeatures =
    courseDataById && 'premium_features' in courseDataById
      ? courseDataById.premium_features
      : []
  useEffect(() => {
    if (courseDataById && isNewPremium(courseDataById) && moduleIsLocked) {
      setPopUpByParam(moduleIsLocked || false)
    }
    const freeUsers =
      isFreeUser(props.userDetails.accessType) ||
      isAcademyInternationalUser(props.userDetails.accessType)
    if (
      freeUsers &&
      courseId &&
      !(
        props.glaCertificatePaymentDetail[courseId] &&
        props.glaCertificatePaymentDetail[courseId].loading
      ) &&
      (courseCookieData[courseId]?.show_payment_popup || moduleIsLocked)
    ) {
      props.fetchGlaCertificatePayment({ course_id: courseId })
    }
  }, [location, props.userDetails.accessType, courseId, moduleIsLocked])

  useEffect(() => {
    if (
      (courseCookieData[courseId]?.show_payment_popup || showPopUpByParam) &&
      courseId &&
      !showPopup &&
      props.glaCertificatePaymentDetail[courseId]?.data?.certificate_exist &&
      !props.glaCertificatePaymentDetail[courseId].data.is_paid
    ) {
      const queryParams = new URLSearchParams(location.search)
      let paymentSource = ''
      if (courseCookieData[courseId]?.frequency) {
        paymentSource = `paid_certificate_otp_automated_popup_${courseCookieData[courseId].frequency}`
      } else {
        paymentSource = queryParams.get('payment_source') || ''
      }

      setTrafficSource(paymentSource)
      setShowPopup(true)
      mixpanel.track('ShowGlaCertificatePaymentPopUp', {
        page: 'Olympus',
        frequency: courseCookieData[courseId]?.frequency,
        courseId,
      })
    }
  }, [location, props.glaCertificatePaymentDetail, courseId])
  return (
    <Box>
      {showPopup &&
      courseId &&
      courseDataById &&
      !courseDataById.completed &&
      props.glaCertificatePaymentDetail[courseId]?.data?.certificate_exist ? (
        isNewPremium(courseDataById) ? (
          <AcademyPremiumCoursePopup
            certificatePaymentDetails={
              props.glaCertificatePaymentDetail[courseId].data
            }
            trafficSource={trafficSource}
            moduleSection={showPopUpByParam}
            premiumFeatures={premiumFeatures}
            onClose={dialogClose}
          />
        ) : (
          <AcademyCertificatePaymentDialog
            certificatePaymentDetails={
              props.glaCertificatePaymentDetail[courseId].data
            }
            premiumFeatures={premiumFeatures}
            trafficSource={trafficSource}
            onClose={dialogClose}
          />
        )
      ) : null}
    </Box>
  )
}
const mapStateToProps = (state: AppState) => ({
  courseData: state.courses.data,
  userDetails: state.user.details,
  glaCertificatePaymentDetail: state.glaCertificatePaymentDetail,
  moduleItems: state.moduleItems,
})

export default withRouter(
  connect(mapStateToProps, {
    fetchGlaCertificatePayment,
    fetchDashboardContent,
  })(AcademyCertificatePaymentPopupCont)
)
