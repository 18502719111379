import React from 'react'
import { connect } from 'react-redux'
import ErrorIllustrationsComponent from '../../../components/Utils/ErrorIllustrations'
import {
  logoutUser,
  UserDetailsState,
} from '../../../providers/User/UserDetailsProvider'
import { AppState } from '../../../store'

export type ErrorIllustrationsPropsType =
  | '404'
  | '401'
  | '500'
  | 'network'
  | 'error'
  | 'serverUnreachable'
  | 'underMaintenance'
  | 'serverUnavailable'
  | 'noAccess'
  | 'restrict_content'

interface ErrorIllustrationsProps {
  type: ErrorIllustrationsPropsType
  logoutUser: typeof logoutUser
  appSource: UserDetailsState['appSource']
}

const ErrorIllustrations = (props: ErrorIllustrationsProps) => {
  return (
    <ErrorIllustrationsComponent
      type={props.type}
      onLogout={props.logoutUser}
      appSource={props.appSource}
    />
  )
}

const mapStateToProps = (state: AppState) => ({
  appSource: state.user.details.appSource,
})
export default connect(mapStateToProps, { logoutUser })(ErrorIllustrations)
