import React from 'react'
import { useOlympusDetails } from 'web/hooks'
import cx from 'classnames'
import { Typography } from 'common/elements'
import { TypographyProps } from '@material-ui/core'

export function AppBrand(props: TypographyProps) {
  const olympusDetails = useOlympusDetails()

  return (
    <Typography className={cx('caption')} textAlign="center" {...props}>
      {olympusDetails?.copyright}
    </Typography>
  )
}
