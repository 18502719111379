import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import {
  apiCall,
  cancelable,
  getActiveProgramBatchId,
  getUserDetails,
} from '../../../../common/utils'
import {
  FETCH_SNACKBAR_DETAILS,
  FETCH_SNACKBAR_DETAILS_CANCEL,
} from './Snackbar.types'
import {
  fetchSnackbarDetailsFailure,
  fetchSnackbarDetailsSuccess,
} from './Snackbar.actions'

export async function fetchSnackbarDetailsAPI(signal: AbortSignal) {
  const { id } = getUserDetails()
  const pbId = getActiveProgramBatchId()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/snackbar_details`,
    params: { signal },
    query: { pb_id: pbId },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

function* fetchSnackbarDetailsHandler() {
  const abortController = new AbortController()
  try {
    const snackbarDetails = yield call(
      fetchSnackbarDetailsAPI,
      abortController.signal
    )

    yield put(fetchSnackbarDetailsSuccess(snackbarDetails))
  } catch (e) {
    yield put(fetchSnackbarDetailsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* snackbarMiddleware() {
  yield takeLatest(
    FETCH_SNACKBAR_DETAILS,
    cancelable(fetchSnackbarDetailsHandler, FETCH_SNACKBAR_DETAILS_CANCEL)
  )
}

export default ([] as any).concat(snackbarMiddleware())
