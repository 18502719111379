import { LocalFileData } from 'common/types'
import * as types from './JupyterLabs.types'

export type JupyterLabStopReason = 'auto_time_expired' | 'manual' | 'inactivity'

export interface JupyterLabPayload {
  embedCode: string
  queryParams?: any
  file?: LocalFileData
  lab_stop_reason?: JupyterLabStopReason
}

export interface GetJupyterNotebookPayload extends JupyterLabPayload {
  embedCode: string
}

export const getJupyterLabStatus = (payload: JupyterLabPayload) => ({
  type: types.GET_JUPYTER_LAB_STATUS as typeof types.GET_JUPYTER_LAB_STATUS,
  payload,
})

export const getJupyterLabStatusSuccess = (payload: any) => ({
  type: types.GET_JUPYTER_LAB_STATUS_SUCCESS as typeof types.GET_JUPYTER_LAB_STATUS_SUCCESS,
  payload,
})

export const getJupyterLabStatusFailure = (payload: Error) => ({
  type: types.GET_JUPYTER_LAB_STATUS_FAILURE as typeof types.GET_JUPYTER_LAB_STATUS_FAILURE,
  payload,
})

export const getJupyterLabStatusCancel = (payload: any) => ({
  type: types.GET_JUPYTER_LAB_STATUS_CANCEL as typeof types.GET_JUPYTER_LAB_STATUS_CANCEL,
  payload,
})

// Add actions for JUPYTER_LAB_DETAILS
export const getJupyterLabDetails = (payload: JupyterLabPayload) => ({
  type: types.GET_JUPYTER_LAB_DETAILS as typeof types.GET_JUPYTER_LAB_DETAILS,
  payload,
})

export const getJupyterLabDetailsSuccess = (payload: any) => ({
  type: types.GET_JUPYTER_LAB_DETAILS_SUCCESS as typeof types.GET_JUPYTER_LAB_DETAILS_SUCCESS,
  payload,
})

export const getJupyterLabDetailsFailure = (payload: Error) => ({
  type: types.GET_JUPYTER_LAB_DETAILS_FAILURE as typeof types.GET_JUPYTER_LAB_DETAILS_FAILURE,
  payload,
})

export const getJupyterLabDetailsCancel = (payload: any) => ({
  type: types.GET_JUPYTER_LAB_DETAILS_CANCEL as typeof types.GET_JUPYTER_LAB_DETAILS_CANCEL,
  payload,
})

export const startJupyterLab = (payload: JupyterLabPayload) => ({
  type: types.START_JUPYTER_LAB as typeof types.START_JUPYTER_LAB,
  payload,
})

export const startJupyterLabSuccess = (payload: any) => ({
  type: types.START_JUPYTER_LAB_SUCCESS as typeof types.START_JUPYTER_LAB_SUCCESS,
  payload,
})

export const startJupyterLabFailure = (payload: Error) => ({
  type: types.START_JUPYTER_LAB_FAILURE as typeof types.START_JUPYTER_LAB_FAILURE,
  payload,
})

export const startJupyterLabCancel = (payload: any) => ({
  type: types.START_JUPYTER_LAB_CANCEL as typeof types.START_JUPYTER_LAB_CANCEL,
  payload,
})

export const stopJupyterLab = (payload: JupyterLabPayload) => ({
  type: types.STOP_JUPYTER_LAB as typeof types.STOP_JUPYTER_LAB,
  payload,
})

export const stopJupyterLabSuccess = (payload: any) => ({
  type: types.STOP_JUPYTER_LAB_SUCCESS as typeof types.STOP_JUPYTER_LAB_SUCCESS,
  payload,
})

export const stopJupyterLabFailure = (payload: Error) => ({
  type: types.STOP_JUPYTER_LAB_FAILURE as typeof types.STOP_JUPYTER_LAB_FAILURE,
  payload,
})

export const stopJupyterLabCancel = (payload: any) => ({
  type: types.STOP_JUPYTER_LAB_CANCEL as typeof types.STOP_JUPYTER_LAB_CANCEL,
  payload,
})

export const getJupyterNotebook = (payload: GetJupyterNotebookPayload) => ({
  type: types.GET_JUPYTER_NOTEBOOK as typeof types.GET_JUPYTER_NOTEBOOK,
  payload,
})

export const getJupyterNotebookSuccess = (payload: any) => ({
  type: types.GET_JUPYTER_NOTEBOOK_SUCCESS as typeof types.GET_JUPYTER_NOTEBOOK_SUCCESS,
  payload,
})

export const getJupyterNotebookFailure = (payload: Error) => ({
  type: types.GET_JUPYTER_NOTEBOOK_FAILURE as typeof types.GET_JUPYTER_NOTEBOOK_FAILURE,
  payload,
})

export const getJupyterNotebookCancel = (payload: any) => ({
  type: types.GET_JUPYTER_NOTEBOOK_CANCEL as typeof types.GET_JUPYTER_NOTEBOOK_CANCEL,
  payload,
})

export type JupyterLabsActionTypes =
  | ReturnType<typeof getJupyterLabStatus>
  | ReturnType<typeof getJupyterLabStatusSuccess>
  | ReturnType<typeof getJupyterLabStatusFailure>
  | ReturnType<typeof getJupyterLabStatusCancel>
  | ReturnType<typeof startJupyterLab>
  | ReturnType<typeof startJupyterLabSuccess>
  | ReturnType<typeof startJupyterLabFailure>
  | ReturnType<typeof startJupyterLabCancel>
  | ReturnType<typeof stopJupyterLab>
  | ReturnType<typeof stopJupyterLabSuccess>
  | ReturnType<typeof stopJupyterLabFailure>
  | ReturnType<typeof stopJupyterLabCancel>
  | ReturnType<typeof getJupyterNotebook>
  | ReturnType<typeof getJupyterNotebookSuccess>
  | ReturnType<typeof getJupyterNotebookFailure>
  | ReturnType<typeof getJupyterNotebookCancel>
  | ReturnType<typeof getJupyterLabDetails>
  | ReturnType<typeof getJupyterLabDetailsSuccess>
  | ReturnType<typeof getJupyterLabDetailsFailure>
  | ReturnType<typeof getJupyterLabDetailsCancel>
