import {
  MessageCategory,
  QAData,
  ZoomoutCoreMessage,
} from 'common/types/zoomout'

export class PlainTextMessage implements ZoomoutCoreMessage {
  sender: number

  receivers?: number[]

  category: MessageCategory

  content: string

  createdAt: Date

  constructor(parameters: {
    sender: number
    receivers?: number[]
    content: string
    timestamp?: Date
  }) {
    this.sender = parameters.sender
    this.receivers = parameters.receivers
    this.category = MessageCategory.PLAIN_TEXT_MESSAGE
    this.content = parameters.content
    this.createdAt = parameters.timestamp || new Date()
  }
}

export enum REMOTE_OVERRIDE_COMMANDS {
  MUTE_MIC_AUDIO,
  UNMUTE_MIC_AUDIO,

  MUTE_CAMERA_VIDEO,
  UNMUTE_CAMERA_VIDEO,

  MUTE_SCREEN_VIDEO,
  UNMUTE_SCREEN_VIDEO,

  LOWER_HAND,

  KICK_OUT,
  END_MEETING,
}

export class RemoteOverrideMessage implements ZoomoutCoreMessage {
  sender: number

  receivers?: number[]

  category: MessageCategory

  content: REMOTE_OVERRIDE_COMMANDS

  createdAt: Date

  constructor(parameters: {
    sender: number
    receivers?: number[]
    content: REMOTE_OVERRIDE_COMMANDS
    timestamp?: Date
  }) {
    this.sender = parameters.sender
    this.receivers = parameters.receivers
    this.content = parameters.content

    this.category = MessageCategory.REMOTE_OVERRIDE
    this.createdAt = parameters.timestamp || new Date()
  }
}

export class PollContentMessage implements ZoomoutCoreMessage {
  sender: number

  category: MessageCategory

  content: number // poll id

  createdAt: Date

  constructor(parameters: {
    sender: number
    content: number
    timestamp?: Date
  }) {
    this.sender = parameters.sender
    this.category = MessageCategory.POLL_CONTENT_MESSAGE
    this.createdAt = parameters.timestamp || new Date()
    this.content = parameters.content
  }
}

export interface PollVote {
  lmsUserId: number // self lms user id
  id: number // poll id
  question: {
    id: number // question id
    selectedOptionIds: number[] // option id for selected options
  }
}
export class PollVoteMessage implements ZoomoutCoreMessage {
  sender: number

  category: MessageCategory

  content: PollVote

  createdAt: Date

  constructor(parameters: {
    sender: number
    content: PollVote
    timestamp?: Date
  }) {
    this.sender = parameters.sender
    this.category = MessageCategory.POLL_VOTE_MESSAGE
    this.createdAt = parameters.timestamp || new Date()
    this.content = parameters.content
  }
}

export enum RAISE_HAND_STATES {
  RAISED,
  LOWERED,
}
export class RaiseHandMessage implements ZoomoutCoreMessage {
  sender: number

  receivers?: number[]

  category: MessageCategory

  content: RAISE_HAND_STATES

  createdAt: Date

  constructor(parameters: {
    sender: number
    receivers?: number[]
    content: RAISE_HAND_STATES
    timestamp?: Date
  }) {
    this.sender = parameters.sender
    this.receivers = parameters.receivers
    this.category = MessageCategory.RAISE_HAND
    this.createdAt = parameters.timestamp || new Date()
    this.content = parameters.content
  }
}

export enum QA_MESSAGE_TYPES {
  ENTRY_ADDITION,
  ENTRY_UPDATE,
  ENTRY_DELETION,
  ACTION_ADDITION,
  ACTION_DELETION,
}

export class QAMessage implements ZoomoutCoreMessage {
  sender: number

  category: MessageCategory

  content: QA_MESSAGE_TYPES

  payload: any

  createdAt: Date

  constructor(parameters: {
    sender: number
    content: QA_MESSAGE_TYPES
    payload: any
    timestamp?: Date
  }) {
    this.sender = parameters.sender
    this.category = MessageCategory.QA_MESSAGE
    this.createdAt = parameters.timestamp || new Date()
    this.content = parameters.content
    this.payload = parameters.payload
  }
}
