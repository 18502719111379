import { cancelable } from 'common/utils'
import { call, cancelled, put, select, takeEvery } from 'redux-saga/effects'
import { AppState } from 'web/store'
import { UserID } from 'common/types/user'

import {
  fetchLearnersEngagementScoreFailure,
  fetchLearnersEngagementScoreParams,
  fetchLearnersEngagementScoreSuccess,
} from './Engagement.actions'
import { fetchLearnersEngagementScoreAPI } from './Engagement.api'
import {
  FETCH_LEARNERS_ENGAGEMENT_SCORE,
  FETCH_LEARNERS_ENGAGEMENT_SCORE_CANCEL,
} from './Engagement.types'

export function* fetchLearnersEngagementHandler() {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    const mentoredGroupSessionId: number = yield select(
      (state: AppState) => state.zoomout.external.mentoredGroupSessionId || {}
    )
    if (!userId) throw new Error('User ID Unavailable')
    if (!mentoredGroupSessionId) throw new Error('Meeting ID is Invalid')

    const params: fetchLearnersEngagementScoreParams = {
      mentoredGroupSessionId,
    }

    const data = yield call(
      fetchLearnersEngagementScoreAPI,
      params,
      userId,
      abortController.signal
    )

    yield put(fetchLearnersEngagementScoreSuccess(data))
  } catch (error) {
    yield put(fetchLearnersEngagementScoreFailure(error))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* EngagementMiddleware() {
  yield takeEvery(
    FETCH_LEARNERS_ENGAGEMENT_SCORE,
    cancelable(
      fetchLearnersEngagementHandler,
      FETCH_LEARNERS_ENGAGEMENT_SCORE_CANCEL
    )
  )
}

export default ([] as any).concat(EngagementMiddleware())
