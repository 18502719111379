import {
  Box,
  Divider,
  Popover,
  makeStyles,
  Avatar,
  Link as MuiLink,
} from '@material-ui/core'
import cx from 'classnames'
import { Typography } from 'common/elements'
import { getCookie, isAUrl, isProduction } from 'common/utils'
import {
  Banknote,
  CreditCard,
  FileEdit,
  FolderOpen,
  Hash,
  LineChart,
  LogOut,
  Newspaper,
  RefreshCw,
  Settings,
  Sprout,
} from 'lucide-react'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  useIsNewUIEnabled,
  useIsNewUIEnabledForBatch,
  useReloadPath,
  useSwitchToNewUI,
} from 'web/hooks'
import { showGLLoader } from 'web/providers/Common/Common.actions'
import { logoutUser } from '../../providers/User/UserDetailsProvider'
import { useSwitchBatch } from './useSwitchBatch'
import { generateURL } from '../../../common/utils'

export enum TargetEnum {
  SELF = '_self',
  BLANK = '_blank',
}

const useMenuItemStyles = makeStyles({
  container: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    padding: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f3f7',
    },
    '&:active': {
      backgroundColor: '#0054d614',
    },
  },
  button: {
    color: '#000000 !important',
    '&:hover': {
      color: '#000000',
    },
    '&:visited': {
      color: '#000000',
    },
  },
})

export function ProfileAvatar({
  size = 40,
}: {
  onPress?: () => void
  size: number
}) {
  const profileDetails = useSelector(state => state.user.details.profile?.data)
  const isValidUrlFlag = isAUrl(profileDetails?.avatar_url)

  return profileDetails?.avatar_url ? (
    <Avatar
      style={{ width: size, height: size, cursor: 'pointer' }}
      src={
        isValidUrlFlag
          ? profileDetails?.avatar_url
          : `${window.constants.REACT_APP_MAGNA_IMAGE_HOST}/images/avatar.png`
      }
    />
  ) : (
    <></>
  )
}

function MenuItem({ menu, onPress }) {
  const styles = useMenuItemStyles()
  return (
    <Box
      key={menu.title}
      gridGap={8}
      className={styles.container}
      onClick={() => {
        if (typeof onPress === 'function') {
          onPress()
        }
        if (typeof menu.onPress === 'function') {
          menu.onPress()
        }
      }}
    >
      {React.createElement(menu.icon, {
        width: 20,
        height: 20,
      })}
      <Typography variant="body2">{menu.title}</Typography>
    </Box>
  )
}
function MenuItemWithLink({ menu, onPress }) {
  const styles = useMenuItemStyles()
  return menu?.to ? (
    <>
      {typeof menu?.to === 'string' && menu?.to?.includes('http') ? (
        <MuiLink
          href={menu.to}
          target={menu.target}
          rel="noreferrer"
          underline="none"
          className={styles.button}
        >
          <MenuItem menu={menu} onPress={onPress} />
        </MuiLink>
      ) : (
        <Link
          to={menu.to}
          target={menu.target}
          rel="noreferrer"
          className={styles.button}
        >
          <MenuItem menu={menu} onPress={onPress} />
        </Link>
      )}
    </>
  ) : (
    <MenuItem menu={menu} onPress={onPress} />
  )
}

export function ProfileMenu({ isAccessBlocked }: { isAccessBlocked: boolean }) {
  const dispatch = useDispatch()
  const logout = useCallback(() => dispatch(logoutUser()), [dispatch])
  const updateGLLoader = useCallback(data => dispatch(showGLLoader(data)), [
    dispatch,
  ])

  const profileDetails = useSelector(state => state.user.details.profile?.data)

  const isNewUIEnabledForBatch = useIsNewUIEnabledForBatch()
  const isNewUIEnabled = useIsNewUIEnabled()
  const featureLinks = profileDetails?.features?.feature_link
  const { selectedBatch } = useSwitchBatch()
  const { switchToNewUI } = useSwitchToNewUI()
  const reloadPath = useReloadPath()
  const history = useHistory()
  const styles = useMenuItemStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const developerUiPreference = getCookie('developer_ui_preference')

  const menusWithAccessBlock = useMemo(() => {
    return [
      {
        title: 'Preparatory courses',
        to: featureLinks?.pre_enrolment_dashboard?.url,
        icon: Sprout,
        target: TargetEnum.BLANK,
      },
      {
        title: 'My ePortfolio',
        to: featureLinks?.eportfolio?.url && generateURL('EPORTFOLIO', null),
        icon: FolderOpen,
      },
      {
        title: 'Fee Payment',
        to: featureLinks?.fee_payment?.url && generateURL('FEE_PAYMENT', null),
        icon: CreditCard,
      },
      {
        title: 'Certificates and Transcripts',
        to:
          featureLinks?.certificate_transcript?.url &&
          generateURL('PRIVATE_CERTIFICATE', null),
        icon: LineChart,
      },
      {
        title: 'Grade Sheet',
        to:
          featureLinks?.grade_sheet?.url &&
          generateURL('PRIVATE_CERTIFICATE', null),
        icon: LineChart,
      },
      {
        title: 'Certificates',
        to:
          featureLinks?.certificates_only?.url &&
          generateURL('PRIVATE_CERTIFICATE', null),
        icon: LineChart,
      },
      {
        title: 'MyGreatLearning',
        to: featureLinks?.feed?.url,
        icon: Hash,
      },
      {
        title: 'Industry Articles',
        to: featureLinks?.industry_articles?.url,
        icon: Newspaper,
      },
      {
        title: 'Refer & Earn',
        to: featureLinks?.refer_and_earn?.url,
        icon: Banknote,
      },
      ...(featureLinks?.testimonials?.url
        ? [
            {
              title: 'Testimonials',
              to: generateURL('TESTIMONIALS', null),
              icon: FileEdit,
            },
          ]
        : []),
      {
        title: 'Settings',
        to: generateURL('PROFILE_SETTINGS', null),
        icon: Settings,
      },
    ]
  }, [featureLinks])
  const menusWithoutAccessBlock = useMemo(() => {
    return [
      ...(!isNewUIEnabled &&
      isNewUIEnabledForBatch &&
      developerUiPreference === ''
        ? [
            {
              title: 'Switch to new UI',
              onPress: switchToNewUI,
              icon: RefreshCw,
            },
          ]
        : []),
      ...(!isProduction()
        ? [
            ...(!isNewUIEnabled
              ? [
                  {
                    title: 'Switch to old app bar',
                    onPress: () => {
                      updateGLLoader({ show: true })
                      document.cookie = 'is_old_app_bar_preferred=true;path=/'
                      reloadPath()
                    },
                    icon: RefreshCw,
                  },
                ]
              : []),
          ]
        : []),
      ...(!featureLinks || featureLinks?.learner_dashboard_page?.url
        ? [
            {
              title: 'Logout',
              icon: LogOut,
              onPress: logout,
            },
          ]
        : []),
    ]
  }, [
    featureLinks,
    isNewUIEnabled,
    isNewUIEnabledForBatch,
    developerUiPreference,
  ])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box onClick={handleClick} display="flex" borderRadius={8}>
        <ProfileAvatar />
      </Box>
      <Popover
        style={{ marginTop: '20px', marginRight: '20px' }}
        transitionDuration={0}
        elevation={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          overflow="hidden"
          display="flex"
          flexDirection="column"
          maxHeight={500}
          borderRadius={8}
          padding={2}
          gridGap={8}
          width={328}
        >
          {profileDetails && (
            <Box display="flex" flex={1} gridGap={16}>
              <ProfileAvatar size={48} />
              <Box display="flex" flexDirection="column" gridGap={8} flex={1}>
                <Typography
                  className={cx('h5')}
                  style={{ wordBreak: 'break-all' }}
                >
                  {profileDetails.name}
                </Typography>
                <Typography
                  style={{
                    display: 'inline-block',
                    whiteSpace: 'pre-line',
                    wordBreak: 'break-all',
                  }}
                  className={cx('caption')}
                >
                  {profileDetails.primary_email}
                </Typography>
                {/* <Typography
                  className={cx('overline')}
                  style={{ wordBreak: 'break-all' }}
                >
                  {selectedBatch.displayName}
                </Typography> */}
              </Box>
            </Box>
          )}
          <Divider style={{ backgroundColor: '#c5c6d0' }} />
          <Box overflow="auto">
            {!isAccessBlocked &&
              menusWithAccessBlock.map(menu => {
                return (
                  (menu.to || menu.onPress) && (
                    <MenuItemWithLink
                      menu={menu}
                      onPress={() => setAnchorEl(null)}
                    />
                  )
                )
              })}
            {menusWithoutAccessBlock.map(menu => {
              return (
                (menu.to || menu.onPress) && (
                  <MenuItemWithLink
                    menu={menu}
                    onPress={() => setAnchorEl(null)}
                  />
                )
              )
            })}
          </Box>
        </Box>
      </Popover>
    </>
  )
}
