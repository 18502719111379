import {
  FETCH_ATTENDANCE,
  FETCH_ATTENDANCE_FAILURE,
  FETCH_ATTENDANCE_SUCCESS,
  FETCH_MEETING_DETAILS,
  FETCH_MEETING_DETAILS_FAILURE,
  FETCH_MEETING_DETAILS_SUCCESS,
  FETCH_SCREEN_SHARING_INFO,
  FETCH_UNKNOWN_PEER_DETAILS,
  FETCH_UNKNOWN_PEER_DETAILS_SUCCESS,
  SAVE_PLAIN_TEXT_MESSAGE,
  SAVE_PLAIN_TEXT_MESSAGE_FAILURE,
  SAVE_PLAIN_TEXT_MESSAGE_SUCCESS,
} from './Zoomout.types'

/**
 * This action triggers the /init api call to Zoomout Backend
 * @param payload
 */
export const fetchMeetingDetails = (payload: fetchMeetingDetailsAPIParams) => ({
  type: FETCH_MEETING_DETAILS as typeof FETCH_MEETING_DETAILS,
  payload,
})

export const fetchMeetingDetailsSuccess = (
  meta: any,
  payload: fetchMeetingDetailsAPIParams
) => ({
  type: FETCH_MEETING_DETAILS_SUCCESS as typeof FETCH_MEETING_DETAILS_SUCCESS,
  meta,
  payload,
})

export const fetchMeetingDetailsFailure = (
  meta: any,
  payload: fetchMeetingDetailsAPIParams
) => ({
  type: FETCH_MEETING_DETAILS_FAILURE as typeof FETCH_MEETING_DETAILS_FAILURE,
  meta,
  payload,
})

// peer_details api
export interface fetchUnknownPeerDetailsAPIParams {
  unknownPeerUID: number
}

export const fetchUnknownPeerDetails = (
  payload: fetchUnknownPeerDetailsAPIParams
) => ({
  type: FETCH_UNKNOWN_PEER_DETAILS as typeof FETCH_UNKNOWN_PEER_DETAILS,
  payload,
})

export const fetchUnknownPeerDetailsSuccess = (
  meta: any,
  payload: fetchUnknownPeerDetailsAPIParams
) => ({
  type: FETCH_UNKNOWN_PEER_DETAILS_SUCCESS as typeof FETCH_UNKNOWN_PEER_DETAILS_SUCCESS,
  meta,
  payload,
})

export interface fetchMeetingDetailsAPIParams {
  channel: string
  authBypassToken: string | null
}

// dump the screen client api response
export const fetchScreenSharingInfo = (payload: any) => ({
  type: FETCH_SCREEN_SHARING_INFO as typeof FETCH_SCREEN_SHARING_INFO,
  payload,
})

// save chat messages

export interface savePlainTextMessageAPIParams {
  senderUid: number
  message: string
}

export const savePlainTextMessage = (
  payload: savePlainTextMessageAPIParams
) => ({
  type: SAVE_PLAIN_TEXT_MESSAGE as typeof SAVE_PLAIN_TEXT_MESSAGE,
  payload,
})

export const savePlainTextMessageSuccess = (payload: any) => ({
  type: SAVE_PLAIN_TEXT_MESSAGE_SUCCESS as typeof SAVE_PLAIN_TEXT_MESSAGE_SUCCESS,
  payload,
})

export const savePlainTextMessageFailure = (payload: Error) => ({
  type: SAVE_PLAIN_TEXT_MESSAGE_FAILURE as typeof SAVE_PLAIN_TEXT_MESSAGE_FAILURE,
  payload,
})

export const fetchAttendance = () => ({
  type: FETCH_ATTENDANCE as typeof FETCH_ATTENDANCE,
})
export const fetchAttendanceSuccess = (payload: any) => ({
  type: FETCH_ATTENDANCE_SUCCESS as typeof FETCH_ATTENDANCE_SUCCESS,
  payload,
})
export const fetchAttendanceFailure = (payload: Error) => ({
  type: FETCH_ATTENDANCE_FAILURE as typeof FETCH_ATTENDANCE_FAILURE,
  payload,
})

export type ZoomoutActions =
  | ReturnType<typeof fetchMeetingDetails>
  | ReturnType<typeof fetchMeetingDetailsSuccess>
  | ReturnType<typeof fetchMeetingDetailsFailure>
  | ReturnType<typeof fetchUnknownPeerDetails>
  | ReturnType<typeof fetchUnknownPeerDetailsSuccess>
  | ReturnType<typeof fetchScreenSharingInfo>
  | ReturnType<typeof savePlainTextMessage>
  | ReturnType<typeof savePlainTextMessageSuccess>
  | ReturnType<typeof savePlainTextMessageFailure>
  | ReturnType<typeof fetchAttendance>
  | ReturnType<typeof fetchAttendanceSuccess>
  | ReturnType<typeof fetchAttendanceFailure>
