import { takeLatest, put, call, select } from 'redux-saga/effects'
import * as types from './MentorshipRecordings.types'
import {
  fetchCourseMentorshipRecordings,
  fetchCourseMentorshipRecordingsFailure,
  fetchCourseMentorshipRecordingsSuccess,
  fetchSessionMentorshipRecordings,
  fetchSessionMentorshipRecordingsSuccess,
  fetchSessionMentorshipRecordingsFailure,
  fetchMentorshipRecordingDetail,
  fetchMentorshipRecordingDetailSuccess,
  fetchMentorshipRecordingDetailFailure,
} from './MentorshipRecordings.actions'
import { userDetailsSelectors } from '../../User/UserDetailsProvider'
import { UserID } from '../../../../common/types/user'
import * as APIs from './MentorshipRecordings.api'
import { CustomError } from '../../../../common/utils'
import { showAlertMessage } from '../../AlertsProvider'

function* getSessionMentorshipRecordingsHandler({
  meta,
}: ReturnType<typeof fetchSessionMentorshipRecordings>) {
  const userId: UserID | null = yield select(userDetailsSelectors.getUserID())
  try {
    if (!userId) {
      throw new Error('Unable to fetch recordings for this session')
    }
    const data = yield call(APIs.getSessionMentorshipRecordingAPI, {
      sessionId: meta.sessionId,
      userId,
    })
    yield put(fetchSessionMentorshipRecordingsSuccess(data, meta))
  } catch (e) {
    yield put(fetchSessionMentorshipRecordingsFailure(e, meta))
  }
}

function* getCourseMentorshipRecordingsHandler({
  meta,
}: ReturnType<typeof fetchCourseMentorshipRecordings>) {
  const userId: UserID | null = yield select(userDetailsSelectors.getUserID())
  try {
    if (!userId) {
      throw new Error('Unable to fetch course recordings')
    }
    const data = yield call(APIs.getCourseMentorshipRecordingsAPI, {
      courseId: meta.courseId,
      userId,
    })
    yield put(fetchCourseMentorshipRecordingsSuccess(data, meta))
  } catch (e) {
    yield put(fetchCourseMentorshipRecordingsFailure(e, meta))
  }
}

function* getMentorshipRecordingDetailsHandler({
  meta,
}: ReturnType<typeof fetchMentorshipRecordingDetail>) {
  const userId: UserID | null = yield select(userDetailsSelectors.getUserID())
  try {
    if (!userId) {
      throw new Error('Unable to fetch recording')
    }
    const data = yield call(APIs.getMentorshipRecordingDetailAPI, {
      recordingId: meta.recordingId,
      userId,
    })
    yield put(fetchMentorshipRecordingDetailSuccess(data, meta))
  } catch (e) {
    if (e instanceof CustomError) {
      yield put(
        showAlertMessage({
          variant: 'error',
          message:
            'The recording that you are trying to access is not available',
        })
      )

      yield put(
        fetchMentorshipRecordingDetailFailure(
          e instanceof CustomError ? e.error : e,
          meta
        )
      )
    }
  }
}

export function* getRecordingsMiddleware() {
  yield takeLatest(
    types.COURSE_MENTORSHIP_RECORDINGS_FETCH,
    getCourseMentorshipRecordingsHandler
  )
  yield takeLatest(
    types.SESSION_MENTORSHIP_RECORDINGS_FETCH,
    getSessionMentorshipRecordingsHandler
  )
  yield takeLatest(
    types.MENTORSHIP_RECORDING_DETAIL_FETCH,
    getMentorshipRecordingDetailsHandler
  )
}

export default ([] as any).concat(getRecordingsMiddleware())
