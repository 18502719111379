import { Reducer } from 'redux'
import { CourseID } from '../../../../common/types/courses'
import {
  GroupCategoryID,
  GroupData,
  GroupID,
  UserDetailsData,
  UserID,
} from '../../../../common/types/user'
import { UserGroupsActions, deleteGroupFileSuccess } from './UserGroups.actions'
import {
  FETCH_GROUP_FILES_FAILURE,
  FETCH_GROUP_FILES_SUCCESS,
  FETCH_GROUP_MEMBERS,
  FETCH_GROUP_MEMBERS_CANCEL,
  FETCH_GROUP_MEMBERS_FAILURE,
  FETCH_GROUP_MEMBERS_SUCCESS,
  FETCH_USER_COURSE_GROUPS,
  FETCH_USER_COURSE_GROUPS_CANCEL,
  FETCH_USER_COURSE_GROUPS_FAILURE,
  FETCH_USER_COURSE_GROUPS_SUCCESS,
  FETCH_USER_GROUPS,
  FETCH_USER_GROUPS_SUCCESS,
  FETCH_USER_GROUPS_FAILURE,
  FETCH_USER_GROUPS_CANCEL,
  FETCH_GROUP_FILES_QUOTA_SUCCESS,
  FETCH_GROUP_FILES_QUOTA_FAILURE,
  DELETE_GROUP_FILE_SUCCESS,
} from './UserGroups.types'

export interface UserGroupsData {
  byId: { [s in GroupID]?: GroupData }
  byUserId: { [u in UserID]?: UserDetailsData }
  byGroupCategoryId: { [s in GroupCategoryID]?: GroupID }
}

export interface UserGroupsState {
  data: UserGroupsData
  byGroupId: {
    [s in GroupID]?: {
      loading: boolean
      error: false | Error | Response
    }
  }
  byCourseId: {
    [c in CourseID]?: {
      data: GroupID[]
      loading: boolean
      error: false | Error | Response
    }
  }

  loading: boolean
  error: false | Error | Response
}

const initialState: UserGroupsState = {
  data: {
    byId: {},
    byUserId: {},
    byGroupCategoryId: {},
  },
  byCourseId: {},
  byGroupId: {},
  loading: false,
  error: false,
}

const initialCourseState = {
  data: [],
  error: false as false,
  loading: false,
}

const getNormalizedData = (state: UserGroupsState, data: object[]) => {
  const resultObj: UserGroupsData['byId'] = { ...state.data.byId }
  if (data)
    data.forEach((groupObj: any) => {
      resultObj[groupObj.id] = { ...resultObj[groupObj.id], ...groupObj }
    })
  return resultObj
}
const getNormalizedGroupCategoryData = (
  state: UserGroupsState,
  data: object[]
) => {
  const resultObj: UserGroupsData['byGroupCategoryId'] = {
    ...state.data.byGroupCategoryId,
  }
  if (data)
    data.forEach((groupObj: any) => {
      resultObj[groupObj.group_category_id] = groupObj.id
    })
  return resultObj
}

const getNormalizedDataCourseData = (data: object[]) => {
  if (data) return data.map((groupObj: any) => groupObj.id)
  return []
}

const getNormalizedGroupCourseData = (data: object[]) => {
  const resultObj: UserGroupsState['byCourseId'] = {}
  if (data)
    data.forEach((groupObj: any) => {
      const courseData = resultObj[groupObj.course_id]
      resultObj[groupObj.course_id] = {
        data: [...(courseData ? courseData.data : []), groupObj.id],
        loading: false,
        error: false,
      }
    })

  return resultObj
}

const getNormalizedGroupData = (state: UserGroupsState, data: object[]) => {
  const userDataObject: UserGroupsData['byUserId'] = { ...state.data.byUserId }

  const userIdArray: UserID[] = data.map((userInfo: any) => {
    const userDetails: UserDetailsData = {
      id: userInfo.id,
      name: userInfo.name,
      imageUrl: userInfo.avatar_url,
    }
    userDataObject[userInfo.id] = userDetails
    return userInfo.id
  })
  return { userIdArray, userDataObject }
}

const getGroupFileDataAfterFileDelete = (
  stateFileData: GroupData['files'],
  action: ReturnType<typeof deleteGroupFileSuccess>
): GroupData['files'] => {
  if (stateFileData instanceof Error) return stateFileData

  const data = stateFileData!.data.filter(
    file => file.id !== action.meta.fileId
  )
  return {
    data,
    hasMoreData: stateFileData!.hasMoreData,
  }
}

const userGroupsReducer: Reducer<UserGroupsState, UserGroupsActions> = (
  state = initialState,
  action
): UserGroupsState => {
  switch (action.type) {
    case FETCH_USER_COURSE_GROUPS:
      return {
        ...state,
        byCourseId: {
          ...state.byCourseId,
          [action.meta.courseId]: {
            ...initialCourseState,
            ...state.byCourseId[action.meta.courseId],
            loading: true,
            error: false,
          },
        },
      }

    case FETCH_USER_COURSE_GROUPS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          byId: getNormalizedData(state, action.payload),
          byGroupCategoryId: getNormalizedGroupCategoryData(
            state,
            action.payload
          ),
        },
        byCourseId: {
          ...state.byCourseId,
          [action.meta.courseId]: {
            ...initialCourseState,
            ...state.byCourseId[action.meta.courseId],
            data: getNormalizedDataCourseData(action.payload),
            loading: false,
          },
        },
      }

    case FETCH_USER_COURSE_GROUPS_FAILURE:
      return {
        ...state,
        byCourseId: {
          ...state.byCourseId,
          [action.meta.courseId]: {
            ...initialCourseState,
            ...state.byCourseId[action.meta.courseId],
            loading: false,
            error: action.payload,
          },
        },
      }

    case FETCH_USER_COURSE_GROUPS_CANCEL:
      return {
        ...state,
        byCourseId: {
          ...state.byCourseId,
          [action.meta.courseId]: {
            ...initialCourseState,
            ...state.byCourseId[action.meta.courseId],
            loading: false,
          },
        },
      }

    case FETCH_GROUP_MEMBERS:
      return {
        ...state,
        byGroupId: {
          ...state.byGroupId,
          [action.meta.groupId]: {
            ...state.byGroupId[action.meta.groupId],
            loading: true,
            error: false,
          },
        },
      }

    case FETCH_GROUP_MEMBERS_SUCCESS: {
      const resultData = getNormalizedGroupData(state, action.payload)
      return {
        ...state,
        data: {
          ...state.data,
          byUserId: resultData.userDataObject,
          byId: {
            ...state.data.byId,
            [action.meta.groupId]: {
              ...(state.data.byId[action.meta.groupId] as GroupData),
              members: resultData.userIdArray,
            },
          },
        },
        byGroupId: {
          ...state.byGroupId,
          [action.meta.groupId]: {
            ...state.byGroupId[action.meta.groupId],
            loading: false,
            error: false,
          },
        },
      }
    }
    case FETCH_GROUP_MEMBERS_FAILURE:
      return {
        ...state,
        byGroupId: {
          ...state.byGroupId,
          [action.meta.groupId]: {
            ...state.byGroupId[action.meta.groupId],
            loading: false,
            error: action.payload,
          },
        },
      }

    case FETCH_GROUP_MEMBERS_CANCEL:
      return {
        ...state,
        byGroupId: {
          ...state.byGroupId,
          [action.meta.groupId]: {
            ...state.byGroupId[action.meta.groupId],
            loading: false,
            error: false,
          },
        },
      }

    case FETCH_GROUP_FILES_SUCCESS: {
      const groupData = state.data.byId[action.meta.groupId]
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.groupId]: {
              ...(groupData as GroupData),
              files: {
                data: [
                  ...(groupData &&
                  groupData.files &&
                  !(groupData.files instanceof Error) &&
                  action.payload.page !== 1
                    ? groupData.files.data
                    : []),
                  ...action.payload.data,
                ],
                hasMoreData: action.payload.hasMoreData,
              },
            },
          },
        },
      }
    }
    case FETCH_GROUP_FILES_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.groupId]: {
              ...(state.data.byId[action.meta.groupId] as GroupData),
              files: action.payload,
            },
          },
        },
      }

    case FETCH_GROUP_FILES_QUOTA_SUCCESS:
    case FETCH_GROUP_FILES_QUOTA_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.groupId]: {
              ...(state.data.byId[action.meta.groupId] as GroupData),
              quotaInfo: action.payload,
            },
          },
        },
      }

    case FETCH_USER_GROUPS:
      return {
        ...state,
        loading: true,
      }

    case FETCH_USER_GROUPS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          byId: getNormalizedData(state, action.payload),
          byGroupCategoryId: getNormalizedGroupCategoryData(
            state,
            action.payload
          ),
        },
        byCourseId: getNormalizedGroupCourseData(action.payload),
        loading: false,
      }

    case FETCH_USER_GROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case FETCH_USER_GROUPS_CANCEL:
      return {
        ...state,
        loading: false,
      }

    case DELETE_GROUP_FILE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.groupId]: {
              ...(state.data.byId[action.meta.groupId] as GroupData),
              files: getGroupFileDataAfterFileDelete(
                state.data.byId[action.meta.groupId]!.files,
                action
              ),
            },
          },
        },
      }

    default:
      return state
  }
}

export default userGroupsReducer
