export const FETCH_SIMILAR_QUESTIONS = 'FETCH_SIMILAR_QUESTIONS'
export const FETCH_SIMILAR_QUESTIONS_SUCCESS = 'FETCH_SIMILAR_QUESTIONS_SUCCESS'
export const FETCH_SIMILAR_QUESTIONS_FAILURE = 'FETCH_SIMILAR_QUESTIONS_FAILURE'
export const FETCH_SIMILAR_QUESTIONS_CANCEL = 'FETCH_SIMILAR_QUESTIONS_CANCEL'

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS'
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS'
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE'
export const FETCH_NOTIFICATIONS_CANCEL = 'FETCH_NOTIFICATIONS_CANCEL'

export const FETCH_TOPICS = 'FETCH_TOPICS'
export const FETCH_TOPICS_SUCCESS = 'FETCH_TOPICS_SUCCESS'
export const FETCH_TOPICS_FAILURE = 'FETCH_TOPICS_FAILURE'
export const FETCH_TOPICS_CANCEL = 'FETCH_TOPICS_CANCEL'

export const POST_QUESTION = 'POST_QUESTION'
export const POST_QUESTION_SUCCESS = 'POST_QUESTION_SUCCESS'
export const POST_QUESTION_FAILURE = 'POST_QUESTION_FAILURE'
export const POST_QUESTION_CANCEL = 'POST_QUESTION_CANCEL'

export const POST_ANSWER = 'POST_ANSWER'
export const POST_ANSWER_SUCCESS = 'POST_ANSWER_SUCCESS'
export const POST_ANSWER_FAILURE = 'POST_ANSWER_FAILURE'
export const POST_ANSWER_CANCEL = 'POST_ANSWER_CANCEL'

export const DELETE_QUESTION = 'DELETE_QUESTION'
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS'
export const DELETE_QUESTION_FAILURE = 'DELETE_QUESTION_FAILURE'
export const DELETE_QUESTION_CANCEL = 'DELETE_QUESTION_CANCEL'

export const DELETE_ANSWER = 'DELETE_ANSWER'
export const DELETE_ANSWER_SUCCESS = 'DELETE_ANSWER_SUCCESS'
export const DELETE_ANSWER_FAILURE = 'DELETE_ANSWER_FAILURE'
export const DELETE_ANSWER_CANCEL = 'DELETE_ANSWER_CANCEL'

export const UPDATE_QUESTION = 'UPDATE_QUESTION'
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS'
export const UPDATE_QUESTION_FAILURE = 'UPDATE_QUESTION_FAILURE'
export const UPDATE_QUESTION_CANCEL = 'UPDATE_QUESTION_CANCEL'

export const UPDATE_ANSWER = 'UPDATE_ANSWER'
export const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_SUCCESS'
export const UPDATE_ANSWER_FAILURE = 'UPDATE_ANSWER_FAILURE'
export const UPDATE_ANSWER_CANCEL = 'UPDATE_ANSWER_CANCEL'

export const FETCH_NEW_QUESTIONS = 'FETCH_NEW_QUESTIONS'
export const FETCH_NEW_QUESTIONS_SUCCESS = 'FETCH_NEW_QUESTIONS_SUCCESS'
export const FETCH_NEW_QUESTIONS_FAILURE = 'FETCH_NEW_QUESTIONS_FAILURE'
export const FETCH_NEW_QUESTIONS_CANCEL = 'FETCH_NEW_QUESTIONS_CANCEL'

export const UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS =
  'UPDATE_COMMUNITY_JWT_TOKEN_SUCCESS'
export const RENEW_COMMUNITY_JWT_TOKEN = 'RENEW_COMMUNITY_JWT_TOKEN'
export const RENEW_COMMUNITY_JWT_TOKEN_CANCEL =
  'RENEW_COMMUNITY_JWT_TOKEN_CANCEL'
export const RENEW_COMMUNITY_JWT_TOKEN_FAILURE =
  'RENEW_COMMUNITY_JWT_TOKEN_FAILURE'

export const FETCH_USER_QUESTIONS = 'FETCH_USER_QUESTIONS'
export const FETCH_USER_QUESTIONS_SUCCESS = 'FETCH_USER_QUESTIONS_SUCCESS'
export const FETCH_USER_QUESTIONS_FAILURE = 'FETCH_USER_QUESTIONS_FAILURE'
export const FETCH_USER_QUESTIONS_CANCEL = 'FETCH_USER_QUESTIONS_CANCEL'

export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS'
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS'
export const FETCH_USER_DETAILS_FAILURE = 'FETCH_USER_DETAILS_FAILURE'
export const FETCH_USER_DETAILS_CANCEL = 'FETCH_USER_DETAILS_CANCEL'

export const POST_QUESTION_UNDER_VIDEO = 'POST_QUESTION_UNDER_VIDEO'
export const POST_QUESTION_UNDER_VIDEO_SUCCESS =
  'POST_QUESTION_UNDER_VIDEO_SUCCESS'
export const POST_QUESTION_UNDER_VIDEO_FAILURE =
  'POST_QUESTION_UNDER_VIDEO_FAILURE'
export const POST_QUESTION_UNDER_VIDEO_CANCEL =
  'POST_QUESTION_UNDER_VIDEO_CANCEL'

export const FETCH_VIDEO_QUESTIONS_IDS = 'FETCH_VIDEO_QUESTIONS_IDS'
export const FETCH_VIDEO_QUESTIONS_IDS_SUCCESS =
  'FETCH_VIDEO_QUESTIONS_IDS_SUCCESS'
export const FETCH_VIDEO_QUESTIONS_IDS_FAILURE =
  'FETCH_VIDEO_QUESTIONS_IDS_FAILURE'
export const FETCH_VIDEO_QUESTIONS_IDS_CANCEL =
  'FETCH_VIDEO_QUESTIONS_IDS_CANCEL'

export const FETCH_PRE_ASKED_SIMILAR_QUESTIONS =
  'FETCH_PRE_ASKED_SIMILAR_QUESTIONS'
export const FETCH_PRE_ASKED_SIMILAR_QUESTIONS_SUCCESS =
  'FETCH_PRE_ASKED_SIMILAR_QUESTIONS_SUCCESS'
export const FETCH_PRE_ASKED_SIMILAR_QUESTIONS_FAILURE =
  'FETCH_PRE_ASKED_SIMILAR_QUESTIONS_FAILURE'
export const FETCH_PRE_ASKED_SIMILAR_QUESTIONS_CANCEL =
  'FETCH_PRE_ASKED_SIMILAR_QUESTIONS_CANCEL'

export const ADD_NEW_TOPIC_TO_QUESTION = 'ADD_NEW_TOPIC_TO_QUESTION'
export const ADD_NEW_TOPIC_TO_QUESTION_SUCCESS =
  'ADD_NEW_TOPIC_TO_QUESTION_SUCCESS'
export const ADD_NEW_TOPIC_TO_QUESTION_FAILURE =
  'ADD_NEW_TOPIC_TO_QUESTION_FAILURE'
export const ADD_NEW_TOPIC_TO_QUESTION_CANCEL =
  'ADD_NEW_TOPIC_TO_QUESTION_CANCEL'
export const RESET_ADD_NEW_TOPIC_TO_QUESTION = 'RESET_ADD_NEW_TOPIC_TO_QUESTION'

export const FETCH_TRENDING_QUESTIONS = 'FETCH_TRENDING_QUESTIONS'
export const FETCH_TRENDING_QUESTIONS_SUCCESS =
  'FETCH_TRENDING_QUESTIONS_SUCCESS'
export const FETCH_TRENDING_QUESTIONS_FAILURE =
  'FETCH_TRENDING_QUESTIONS_FAILURE'
export const FETCH_TRENDING_QUESTIONS_CANCEL = 'FETCH_TRENDING_QUESTIONS_CANCEL'

export const FETCH_TRENDING_ARTICLES = 'FETCH_TRENDING_ARTICLES'
export const FETCH_TRENDING_ARTICLES_SUCCESS = 'FETCH_TRENDING_ARTICLES_SUCCESS'
export const FETCH_TRENDING_ARTICLES_FAILURE = 'FETCH_TRENDING_ARTICLES_FAILURE'
export const FETCH_TRENDING_ARTICLES_CANCEL = 'FETCH_TRENDING_ARTICLES_CANCEL'

export const FETCH_GROUP_FEEDS = 'FETCH_GROUP_FEEDS'
export const FETCH_GROUP_FEEDS_SUCCESS = 'FETCH_GROUP_FEEDS_SUCCESS'
export const FETCH_GROUP_FEEDS_FAILURE = 'FETCH_GROUP_FEEDS_FAILURE'
export const FETCH_GROUP_FEEDS_CANCEL = 'FETCH_GROUP_FEEDS_CANCEL'

export const FETCH_GROUP_DETAILS = 'FETCH_GROUP_DETAILS'
export const FETCH_GROUP_DETAILS_SUCCESS = 'FETCH_GROUP_DETAILS_SUCCESS'
export const FETCH_GROUP_DETAILS_FAILURE = 'FETCH_GROUP_DETAILS_FAILURE'
export const FETCH_GROUP_DETAILS_CANCEL = 'FETCH_GROUP_DETAILS_CANCEL'

export const INSERT_NEW_QUESTION_INTO_FEEDS = 'INSERT_NEW_QUESTION_INTO_FEEDS'

export const FETCH_ALUMNI_GROUP_MEMBERS = 'FETCH_ALUMNI_GROUP_MEMBERS'
export const FETCH_ALUMNI_GROUP_MEMBERS_SUCCESS =
  'FETCH_ALUMNI_GROUP_MEMBERS_SUCCESS'
export const FETCH_ALUMNI_GROUP_MEMBERS_FAILURE =
  'FETCH_ALUMNI_GROUP_MEMBERS_FAILURE'
export const FETCH_ALUMNI_GROUP_MEMBERS_CANCEL =
  'FETCH_ALUMNI_GROUP_MEMBERS_CANCEL'
