import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import routes, { RouteNames } from '../../../routes'

interface FallbackHandlerProps extends RouteComponentProps {
  fallback?: React.ComponentType<any>
  redirect?: RouteNames
}

const FallbackHandlerProps = ({
  fallback: FallbackComponent,
  redirect: RedirectRoute,
  ...props
}: FallbackHandlerProps) =>
  FallbackComponent !== undefined ? (
    <FallbackComponent />
  ) : (
    <Redirect
      to={
        RedirectRoute
          ? routes[RedirectRoute]
          : {
              pathname: '/auth',
              state: { from: props.location },
            }
      }
    />
  )

export default FallbackHandlerProps
