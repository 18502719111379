import { apiCall, getUserDetails } from '../../../../common/utils'

import { fetchCoursePercentageCompleted } from './CoursePercentageCompleted.actions'

export async function getCoursePercentageCompletedAPI(
  action: ReturnType<typeof fetchCoursePercentageCompleted>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const body = JSON.stringify({
    course_ids: action.payload.courseIds,
  })
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/progress_percentage`,
    params: { signal, method: 'POST', body },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
