import { Typography, Box, Grid } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import { programSelectors } from 'web/providers/Dashboard/ProgramsProvider'
import { useSelector } from 'react-redux'
import { skipSessionFeedback } from 'web/providers/FeedbackProvider/Feedback.actions'
import { useShouldDisableFeedbackMandate } from 'common/utils/custom/partnerOrganisations/settings'
import Loader from 'web/components/Utils/Loader'
import {
  capitalizeFirstLetter,
  generateURL,
  readableText,
} from '../../../../common/utils'
import {
  FeedbackState,
  removeFeedbackPopup,
} from '../../../providers/FeedbackProvider'
import Button from '../../Utils/Button'
import Dialog, { DialogContent, DialogTitle } from '../../Utils/Dialog'
import { FeedbackIcon } from '../../Utils/Icons/Icons'
import LinkButton from '../../Utils/LinkButton'
import {
  ProgramFeedbackContent,
  CapstoneSurveyFeedbackContent,
  CourseFeedbackContent,
} from '../../../../common/types/feedback'
import styles from './FeedbackDialog.module.css'
import { readableDate } from '../../../../common/utils/time'

interface FeedbackDialogProps {
  feedback: FeedbackState
  removeFeedbackPopup: typeof removeFeedbackPopup
  skipSessionFeedback: typeof skipSessionFeedback
}

const FeedbackDialog = (props: FeedbackDialogProps) => {
  const [optional, setOptional] = useState(false)
  const [subText, setSubText] = useState<JSX.Element | string>('')
  const [titleText, setTitleText] = useState('')

  const disableFeedbackMandate = useShouldDisableFeedbackMandate()

  useEffect(() => {
    if (props.feedback.data && props.feedback.data.feedback_type) {
      switch (props.feedback.data.feedback_type) {
        case 'residency_course_feedback':
          setTitleText(readableText(props.feedback.data.feedback_type))
          setSubText(
            'We hope your residency was productive. Please let us know how it went, so we can continue to deliver impactful learning experiences.It’ll take only 2 minutes.'
          )
          break
        case 'mentored_session_feedback': {
          const feedbackContent: CourseFeedbackContent = props.feedback.data
            .content as CourseFeedbackContent
          const text = feedbackContent ? (
            <Grid container>
              <Typography variant="body1">
                Please share your feedback for the{' '}
                {feedbackContent.session_type}
              </Typography>
              <Grid item container xs={12}>
                <Grid item className={styles.dialogItem}>
                  Mentor
                </Grid>
                <Grid item>:</Grid>
                <Grid item className={styles.dialogData}>
                  {feedbackContent.facilitator_name || ''}
                </Grid>
              </Grid>
              {feedbackContent.session_start_time ? (
                <Grid item container xs={12}>
                  <Grid item className={styles.dialogItem}>
                    Date
                  </Grid>
                  <Grid item>:</Grid>
                  <Grid item className={styles.dialogData}>
                    {readableDate(feedbackContent.session_start_time)}
                  </Grid>
                </Grid>
              ) : null}
              <Grid item container xs={12}>
                <Grid item className={styles.dialogItem}>
                  Topic
                </Grid>
                <Grid item>:</Grid>
                <Grid item className={styles.dialogData}>
                  {feedbackContent.topic || ''}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ''
          )

          setTitleText(
            readableText(
              feedbackContent && feedbackContent.session_type
                ? feedbackContent.session_type
                : props.feedback.data.feedback_type
            )
          )
          setSubText(text)
          break
        }
        case 'program_feedback':
          setTitleText(readableText(props.feedback.data.feedback_type))
          setOptional(!props.feedback.data.disable_skip)
          setSubText(
            (props.feedback.data.content as ProgramFeedbackContent)
              ? (props.feedback.data.content as ProgramFeedbackContent)
                  .description
              : ''
          )
          break
        case 'cap_legacy_feedback':
          setTitleText('capstone feedback')
          setSubText(
            'Participate in a brief feedback survey to let us know how we can improve your experience'
          )
          break
        case 'cap_survey_based_feedback':
          setTitleText('capstone feedback')
          if (props.feedback.data.content as CapstoneSurveyFeedbackContent) {
            setSubText(
              (props.feedback.data.content as CapstoneSurveyFeedbackContent)
                .description || ''
            )
            setOptional(
              (props.feedback.data.content as CapstoneSurveyFeedbackContent)
                .allow_to_skip || false
            )
          }
          break
        default:
          setSubText(
            'Participate in a brief feedback survey to let us know how we can improve your experience'
          )
      }
    }
  }, [props.feedback.data])

  const onClickLater = () => {
    props.removeFeedbackPopup()
    if (props.feedback.data && props.feedback.data.feedback_url)
      localStorage.setItem(
        props.feedback.data.feedback_url,
        new Date().toDateString()
      )
  }

  const skipFeedback = () => {
    if (props.feedback.data && props.feedback.data.mgs_id) {
      props.skipSessionFeedback(props.feedback.data.mgs_id)
    }
  }

  return (
    <Dialog open={props.feedback.isOpen} classes={{ paper: styles.container }}>
      <DialogTitle>
        <FeedbackIcon className={styles.icon} />
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        {props.feedback.loading ? (
          <Loader />
        ) : (
          <>
            {props.feedback.data && props.feedback.data.feedback_type ? (
              <Fragment>
                <Typography variant="h5" color="textPrimary" gutterBottom>
                  {capitalizeFirstLetter(titleText)}
                </Typography>
                <Box padding={1}>
                  {typeof subText === 'string' ? (
                    <Typography variant="body1" align="center" gutterBottom>
                      {subText}
                    </Typography>
                  ) : (
                    subText
                  )}
                </Box>
              </Fragment>
            ) : null}
            <LinkButton
              variant="contained"
              color="primary"
              className={styles.start}
              to={generateURL('FEEDBACK', null)}
            >
              <Typography color="textSecondary" variant="body2">
                Start
              </Typography>
            </LinkButton>
            {optional ? (
              <Button
                onClick={onClickLater}
                className={styles.later}
                size="medium"
              >
                I will do it later
              </Button>
            ) : null}
            {disableFeedbackMandate &&
            props.feedback.data?.feedback_type ===
              'mentored_session_feedback' ? (
              <Button
                onClick={skipFeedback}
                className={styles.later}
                size="medium"
              >
                Skip
              </Button>
            ) : null}
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default FeedbackDialog
