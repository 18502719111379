import {
  FETCH_GRADEBOOK_CONTENT,
  FETCH_GRADEBOOK_CONTENT_SUCCESS,
  FETCH_GRADEBOOK_CONTENT_FAILURE,
  FETCH_GRADEBOOK_CONTENT_CANCEL,
} from './Gradebook.types'

export const fetchGradebookContent = () => ({
  type: FETCH_GRADEBOOK_CONTENT as typeof FETCH_GRADEBOOK_CONTENT,
})
export const fetchGradebookContentSuccess = (payload: any) => ({
  type: FETCH_GRADEBOOK_CONTENT_SUCCESS as typeof FETCH_GRADEBOOK_CONTENT_SUCCESS,
  payload,
})
export const fetchGradebookContentFailure = (payload: Error) => ({
  type: FETCH_GRADEBOOK_CONTENT_FAILURE as typeof FETCH_GRADEBOOK_CONTENT_FAILURE,
  payload,
})
export const fetchGradebookContentCancel = () => ({
  type: FETCH_GRADEBOOK_CONTENT_CANCEL as typeof FETCH_GRADEBOOK_CONTENT_CANCEL,
})

export type GradebookActions =
  | ReturnType<typeof fetchGradebookContent>
  | ReturnType<typeof fetchGradebookContentSuccess>
  | ReturnType<typeof fetchGradebookContentFailure>
  | ReturnType<typeof fetchGradebookContentCancel>
