import { Reducer } from 'redux'
import { CertificatesData } from 'common/types/certificate'
import { DashboardActions } from '../../Dashboard/DashboardProvider'
import { CertificatesActions } from './Certificates.actions'
import {
  FETCH_CERTIFICATES_CONTENT,
  FETCH_CERTIFICATES_CONTENT_CANCEL,
  FETCH_CERTIFICATES_CONTENT_FAILURE,
  FETCH_CERTIFICATES_CONTENT_SUCCESS,
} from './Certificates.types'

export interface CertificatesState {
  data?: CertificatesData
  loading: boolean
  error: false | Error | Response
}

const initialState: CertificatesState = {
  loading: false,
  error: false,
}

const certificatesReducer: Reducer<
  CertificatesState,
  DashboardActions | CertificatesActions
> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CERTIFICATES_CONTENT:
      return { ...state, loading: true, error: false }

    case FETCH_CERTIFICATES_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          certificates: action.payload.certificates,
        },
      }

    case FETCH_CERTIFICATES_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case FETCH_CERTIFICATES_CONTENT_CANCEL:
      return {
        ...state,
        loading: false,
        error: false,
        loaded: false,
      }

    default:
      return state
  }
}

export default certificatesReducer
