import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  RadioGroup,
} from '@material-ui/core'
import React, { useState } from 'react'
import styles from './NewFreeOnboardingFormDialog.module.css'
import {
  newOnboardingFormOptions,
  NewOnboardingFormTypes,
} from './FreeUserInfoData'
import NewOnboardingFormRadioOptions from './NewOnboardingFormRadioOptions'
import NewFormBtns from './NewFormBtns'

interface Props {
  onSkip: (currentStep: number) => void
  selectedSection: NewOnboardingFormTypes
  onNewFormSubmit: (
    data: Record<string, string>,
    closePopup: boolean,
    currentStep: number,
    finalStep: boolean
  ) => void
}

const NewFreeOnboardingFormDialog = (props: Props) => {
  const [surveyData, setSurveyData] = useState<Record<string, string>>({})
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [disabledNextBtn, updateDisabledNextBtn] = useState(true)
  const [currentStepOptions, setCurrentStepOptions] = useState(
    newOnboardingFormOptions[props.selectedSection][currentStep]
  )

  const handleBackBtn = () => {
    const newCurrentStep = currentStep - 1
    setCurrentStep(newCurrentStep)
    setCurrentStepOptions(
      newOnboardingFormOptions[props.selectedSection][newCurrentStep]
    )
    updateDisabledNextBtn(false)
  }

  const checkBtnToEnable = (
    surveyDetails: Record<string, string>,
    subSection: string[]
  ) => {
    let isBtnDisabled = false
    for (let i = 0; i < subSection.length; i++) {
      if (!surveyDetails[subSection[i]]) {
        isBtnDisabled = true
        break
      }
    }
    updateDisabledNextBtn(isBtnDisabled)
  }
  const handleOnSubmit = () => {
    if (!currentStepOptions.closePopup) {
      const newCurrentStep = currentStep + 1
      setCurrentStep(newCurrentStep)
      const currentOption =
        newOnboardingFormOptions[props.selectedSection][newCurrentStep]
      setCurrentStepOptions(currentOption)
      checkBtnToEnable(surveyData, Object.keys(currentOption.subSections))
    }
    props.onNewFormSubmit(
      surveyData,
      currentStepOptions.closePopup,
      currentStep,
      currentStepOptions.closePopup
    )
  }

  const handleOnSkip = () => {
    props.onSkip(currentStep)
  }

  const handleSelectedValue = (selectedType: string, selectedValue: string) => {
    const newSurveyData = {
      ...surveyData,
      [selectedType]: selectedValue,
    }
    setSurveyData(newSurveyData)

    checkBtnToEnable(newSurveyData, Object.keys(currentStepOptions.subSections))
  }

  return (
    <Dialog open classes={{ paper: styles.dialog }}>
      <DialogContent className={styles.dialogContent}>
        <NewFormBtns
          isMobile
          backButton={currentStepOptions.backButton}
          skipButton={currentStepOptions.skipButton}
          nextButtonText={currentStepOptions.nextButtonText}
          onBackBtn={handleBackBtn}
          onSkipAction={handleOnSkip}
          onSubmit={handleOnSubmit}
          isDisabled={disabledNextBtn}
        />
        <Box className={styles.detailsContentWrapper}>
          <Typography
            variant="h3"
            className={`${styles.heading} ${
              styles[currentStepOptions.headingClass]
            }`}
          >
            {currentStepOptions.heading}
          </Typography>
          {Object.entries(currentStepOptions.subSections).map(
            ([sectionKey, subSection]) => (
              <Box className={styles.contentWrapper}>
                <Typography className={styles.preferredProgram}>
                  {subSection.subHeading}
                </Typography>
                <RadioGroup
                  className={styles.radioGroupWrapper}
                  name={sectionKey}
                  row
                >
                  <NewOnboardingFormRadioOptions
                    radioBtnCss={subSection.radioBtnCss}
                    optionsData={subSection.options}
                    selectedValue={surveyData[sectionKey]}
                    handleSelectedValue={selectedValue => {
                      handleSelectedValue(sectionKey, selectedValue)
                    }}
                  />
                </RadioGroup>
              </Box>
            )
          )}
        </Box>
        <NewFormBtns
          backButton={currentStepOptions.backButton}
          skipButton={currentStepOptions.skipButton}
          nextButtonText={currentStepOptions.nextButtonText}
          onBackBtn={handleBackBtn}
          onSkipAction={handleOnSkip}
          onSubmit={handleOnSubmit}
          isDisabled={disabledNextBtn}
        />
      </DialogContent>
    </Dialog>
  )
}
export default NewFreeOnboardingFormDialog
