import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  updateCareerSupportLink,
  updateUserInfoCollected,
  DashboardState,
} from 'web/providers/Dashboard/DashboardProvider'
import { history } from 'web/store/history'
import {
  glaUtmParams,
  isAcademyInternationalUser,
  isSchoolStudent,
} from 'common/utils/custom/user'
import AcademyIneligibleUserDialog from 'web/components/Dashboard/AcademyIneligibleUserDialog'
// import { matchPath, useLocation } from 'react-router-dom'
import routes from 'web/routes'
import {
  apiCall,
  generateURL,
  // getFinancialImpactReportCourseId,
  objectToFormData,
} from 'common/utils'
import { AcademyUserProfileData, UserID } from 'common/types/user'
import { mixpanel } from 'common/utils/mixpanel'
import { AppState } from 'web/store'

import FreeUserInfoDialog, {
  SurveyData,
} from 'web/components/FreeUserInfoDialog'
import {
  fetchUserProfileDetails,
  userDetailsSelectors,
  UserDetailsState,
} from 'web/providers/User/UserDetailsProvider'
import { showAlertMessage } from 'web/providers/AlertsProvider'
import { updateGiftCourseConsentRequired } from 'web/providers/Dashboard/DashboardProvider/Dashboard.actions'
import ReferralConsentDialog from 'web/components/FreeUserInfoDialog/ReferralConsentDialog'
import NewFreeOnboardingFormDialog from 'web/components/FreeUserInfoDialog/NewFreeOnboardingFormDialog'
import StudyAbroadRecommendationDialog from 'web/components/FreeUserInfoDialog/StudyAbroadRecommendationDialog'
import CertificateProgramDialog from 'web/components/FreeUserInfoDialog/CertificateProgramDialog'
import moment from 'moment'
import {
  studyAbroadPreferredPrograms,
  studyAbroadToeflConsent,
  studyAbroadEstimatedBudget,
  study_abroad_consent_options,
  interestedDomain,
} from 'web/components/FreeUserInfoDialog/FreeUserInfoData'
import { updateOlympusLeadData } from 'web/providers/GlaNewOnBoardingFormProvider'
import { StudyAbroadLeadData } from 'common/types/glaNewOnboardingForm'
import {
  CityState,
  fetchCities,
  UniversityState,
  fetchUniversities,
  CollegeState,
  fetchColleges,
  resetColleges,
} from 'web/providers/Dashboard/FreeUserInfoDialogProvider'
import AsyncDOM from 'web/components/Utils/AsyncDOM'
import { fetchAllPrograms } from 'web/providers/AllProgramsProvider'
import { AllProgramsData } from 'common/types/programs'

interface StateProps {
  cities: CityState
  universties: UniversityState
  colleges: CollegeState
  userDetails: UserDetailsState
  dashboardData: DashboardState['data']
  userId: UserID | null
  allProgramsData: AllProgramsData
}

interface DispatchProps {
  showAlertMessage: typeof showAlertMessage
  updateUserInfoCollected: typeof updateUserInfoCollected
  updateGiftCourseConsentRequired: typeof updateGiftCourseConsentRequired
  updateCareerSupportLink: typeof updateCareerSupportLink
  fetchCities: typeof fetchCities
  fetchUniversities: typeof fetchUniversities
  fetchColleges: typeof fetchColleges
  resetColleges: typeof resetColleges
  fetchUserProfileDetails: typeof fetchUserProfileDetails
  updateOlympusLeadData: typeof updateOlympusLeadData
  fetchAllPrograms: typeof fetchAllPrograms
}

type Props = StateProps & DispatchProps

const FreeUserInfo = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showDialog, updateShowDialog] = useState(false)
  const [showCBIneligibleUserDialog, setShowCBIneligibleUserDialog] = useState(
    false
  )
  const [fromCareerPlusBanner, updateFromCareerPlusBanner] = useState(false)
  const [isInternationalUser, updateIsInternationUser] = useState(false)
  const [workExperience, updateWorkExperience] = useState('')
  const [studyAbroadConsent, setStudyAbroadConsent] = useState('')
  const [showStudyAbroadDialog, setShowStudyAbroadDialog] = useState(false)
  const [
    showCertificateProgramDialog,
    setShowCertificateProgramDialog,
  ] = useState(false)
  const [showNewOnboardingForm, updateShowNewOnboardingForm] = useState(false)
  const [newOnboardingFormSection, updateOnboardingFormSection] = useState(
    'study_abroad'
  )
  // const location = useLocation()
  // let isPaymentFlow = false

  // const hideDialog = (): boolean => {
  //   // check financial impact report course
  //   let match = matchPath(location.pathname, {
  //     path: routes.COURSE,
  //     exact: true,
  //   })

  //   if (match) {
  //     // eslint-disable-next-line prefer-destructuring
  //     const courseId = (match.params as Record<string, string>).courseId

  //     if (courseId) {
  //       const courseCookieData = localStorage.getItem('payment_popup_details')

  //       if (courseCookieData) {
  //         const parsedData = JSON.parse(courseCookieData)
  //         isPaymentFlow = parsedData[courseId]?.show_payment_popup == true
  //       }
  //     }

  //     if (courseId && courseId === getFinancialImpactReportCourseId()) {
  //       return true
  //     }
  //   }

  //   // check referral_rewards page
  //   match = matchPath(location.pathname, {
  //     path: routes.REFERRALS_AND_REWARDS,
  //   })

  //   if (match) {
  //     return true
  //   }

  //   // check for community accept invite page
  //   match = matchPath(location.pathname, {
  //     path: routes.COMMUNITY_ACCEPT_INVITE,
  //   })

  //   if (match) {
  //     return true
  //   }

  //   match = matchPath(location.pathname, {
  //     path: routes.COMMUNITY_ACCEPT_REFERRAL,
  //   })

  //   if (match) {
  //     return true
  //   }

  //   return false
  // }

  const handleStatesAfterFormSubmission = () => {
    updateShowNewOnboardingForm(false)
    props.updateUserInfoCollected({
      is_user_info_collected: true,
      user_info_collected_at: moment().toString(),
    })
    props.showAlertMessage({
      variant: 'success',
      message: 'Submitted Successfully',
    })
    window.parent.postMessage('enrollment_form_submitted', '*')
  }

  const submitFormSubmission = () => {
    setIsLoading(false)
    updateShowDialog(false)
    handleStatesAfterFormSubmission()
  }

  // useEffect(() => {
  //   const hide = hideDialog()
  //   if (hide) {
  //     updateShowDialog(false)
  //   }
  //   if (props.dashboardData) {
  //     updateFromCareerPlusBanner(
  //       sessionStorage.getItem('from_career_plus_banner') === 'true'
  //     )
  //     if (
  //       !isPaymentFlow &&
  //       (('is_user_info_collected' in props.dashboardData &&
  //         !props.dashboardData.is_user_info_collected) ||
  //         ('user_info_collected_at' in props.dashboardData &&
  //           moment().diff(
  //             moment(props.dashboardData.user_info_collected_at),
  //             'months'
  //           ) >= 6) ||
  //         ('gift_course_consent_required' in props.dashboardData &&
  //           !!props.dashboardData.gift_course_consent_required)) &&
  //       !showDialog &&
  //       props.userDetails.profile &&
  //       props.userDetails.profile.data &&
  //       !isSchoolStudent(props.userDetails.profile.data) &&
  //       !hide
  //     ) {
  //       updateShowDialog(true)
  //     }
  //   }
  // }, [
  //   props.dashboardData,
  //   location,
  //   props.userDetails.profile && props.userDetails.profile.data,
  // ])

  useEffect(() => {
    if (props.userDetails.accessType) {
      updateIsInternationUser(
        isAcademyInternationalUser(props.userDetails.accessType)
      )
    }
    if (props.userDetails.profile && props.userDetails.profile.data) {
      const profileData = props.userDetails.profile
        .data as AcademyUserProfileData
      const workEx = profileData.work_experience || ''
      updateWorkExperience(workEx)
    }
  }, [props.userDetails.accessType])

  useEffect(() => {
    if (showDialog) {
      const params: any = {}
      let eventName = 'LMS - GLA-OnboardingFormInitiate'
      if (fromCareerPlusBanner) {
        eventName = 'LMS - CB-OnboardingFormInitiate'
        params.user_work_experience = workExperience
      }
      mixpanel.track(eventName, params)
      if (
        !(
          isInternationalUser ||
          props.cities.data.length ||
          props.cities.loading
        )
      ) {
        props.fetchCities()
      }
      if (
        props.dashboardData &&
        'user_info_collected_at' in props.dashboardData &&
        moment().diff(
          moment(props.dashboardData.user_info_collected_at),
          'months'
        ) >= 6
      ) {
        mixpanel.track('LMS - GLA-OnboardingFormForOldUser', {
          type: 'Enrollment Form Opened',
        })
      }
    }
  }, [showDialog])

  useEffect(() => {
    if (sessionStorage.getItem('ineligible_cb_user') === 'true') {
      setShowCBIneligibleUserDialog(true)
    }
  }, [])

  useEffect(() => {
    if (isLoading && showDialog) {
      if (props.allProgramsData && props.allProgramsData.onboarding_form) {
        setIsLoading(false)
        updateShowDialog(false)
        if (props.allProgramsData.onboarding_form.show_study_abroad) {
          mixpanel.track('LMS - OnboardingFormIntervention', {
            type: 'StudyAbroad',
          })
          setShowStudyAbroadDialog(true)
        } else if (props.allProgramsData.onboarding_form.program) {
          const programDetailId = props.allProgramsData.onboarding_form.program
          if (
            props.allProgramsData.programs &&
            props.allProgramsData.programs[programDetailId]
          ) {
            mixpanel.track('LMS - OnboardingFormIntervention', {
              type: 'CertificateProgram',
            })
            setShowCertificateProgramDialog(true)
          } else {
            submitFormSubmission()
          }
        } else {
          submitFormSubmission()
        }
      } else {
        submitFormSubmission()
      }
    }
  }, [props.allProgramsData && props.allProgramsData.onboarding_form])

  const onError = (message: string) => {
    setIsLoading(false)
    props.showAlertMessage({
      variant: 'error',
      message,
    })
  }

  const onSubmit = (data: {} | SurveyData) => {
    if (props.userId && Object.values(data).length) {
      const mp_name = fromCareerPlusBanner
        ? 'LMS - CB-SurveySubmit'
        : 'Button Click'

      const mp_params: any = {}

      if ('year_of_study' in data && data.year_of_study) {
        mp_params.year_of_study = data.year_of_study
        const current_year = new Date().getFullYear()
        if (data.year_of_study == `Before ${current_year - 3}`) {
          data.year_of_study = (current_year - 4).toString()
        } else if (data.year_of_study == `After ${current_year + 3}`) {
          data.year_of_study = (current_year + 4).toString()
        }
      }

      if ('interested_domain' in data && data.interested_domain) {
        data.interested_domain =
          interestedDomain[data.interested_domain as string]
      }

      if (
        fromCareerPlusBanner &&
        'work_experience' in data &&
        data.work_experience
      ) {
        mp_params.user_work_experience = data.work_experience
      }

      if ('pg_consent' in data) {
        props.updateGiftCourseConsentRequired(false)
      }

      if (
        'pg_consent' in data &&
        'career_option' in data &&
        data.career_option
      ) {
        data.career_option =
          data.career_option.toString().toLocaleLowerCase() === 'yes'
            ? 'Looking to switch career'
            : 'Looking to upskill'
      }

      const selectedStudyAbroadConsent =
        ('study_abroad_consent' in data &&
          (data.study_abroad_consent as string)) ||
        ''

      mp_params.type = 'Submit'
      mp_params.position = 'Onboarding form'
      mp_params.studyAbroadConsent = selectedStudyAbroadConsent

      mixpanel.track(mp_name, mp_params)
      if (
        props.dashboardData &&
        'user_info_collected_at' in props.dashboardData &&
        moment().diff(
          moment(props.dashboardData.user_info_collected_at),
          'months'
        ) >= 6
      ) {
        mixpanel.track('LMS - GLA-OnboardingFormForOldUser', {
          type: 'Enrollment Form Submitted',
        })
      }

      setIsLoading(true)
      apiCall({
        url: `${process.env.REACT_APP_LTI_API_URL}v1/users/${
          props.userId
        }/add_gl_academy_user_details${
          fromCareerPlusBanner ? '?enrol_to_career_plus=true' : ''
        }`,
        params: {
          method: 'POST',
          body: objectToFormData(data),
        },
      })
        .then(res => res.json())
        .then(res => {
          if (res.user_info_added) {
            props.fetchAllPrograms({ is_onboarding_form: true })
            props.fetchUserProfileDetails()
            // if (
            //   isPositiveStudyAbroadConsent &&
            //   props.userDetails.profile &&
            //   props.userDetails.profile.data &&
            //   props.userDetails.profile.data.phone
            // ) {
            //   updateShowNewOnboardingForm(true)
            //   updateOnboardingFormSection('study_abroad')
            //   setStudyAbroadConsent(selectedStudyAbroadConsent)
            // } else {
            //   handleStatesAfterFormSubmission()
            // }
          } else {
            onError('Submission failed. Please try again')
          }
        })
        .catch(error => {
          onError('Submission failed. Please try again')
        })
    }
  }

  const onSkip = (currentStep: number) => {
    mixpanel.track('Button Click', {
      position: `OnboardingFormLeadFlow ${newOnboardingFormSection} Step ${currentStep}`,
      type: 'Skip',
    })
    handleStatesAfterFormSubmission()
  }

  const newFormSubmit = (
    formData: Record<string, string>,
    closePopup: boolean,
    currentStep: number,
    finalStep: boolean
  ) => {
    if (
      props.userDetails.profile &&
      props.userDetails.profile.data &&
      props.userId &&
      formData.page_identifier
    ) {
      const gl_query_string = glaUtmParams(
        'dashboard_loggedin_new_onboarding_form'
      )
      const {
        name,
        phone,
        primary_email: email,
      } = props.userDetails.profile.data

      const studyAbroadParams = {
        page_identifier: studyAbroadPreferredPrograms[formData.page_identifier],
        program_duration: study_abroad_consent_options[studyAbroadConsent],
        comparision_parameter:
          ('toefl_consent' in formData &&
            studyAbroadToeflConsent[formData.toefl_consent]) ||
          '',
        motivation:
          ('estimated_budget' in formData &&
            formData.estimated_budget &&
            studyAbroadEstimatedBudget[formData.estimated_budget]) ||
          '',
      }

      const payload: StudyAbroadLeadData = {
        user_id: props.userId,
        final_step: finalStep,
        gl_query_string,
        lead_source: 'gla_new_onboarding_form',
        modal_form_action: 'gla_onboarding_form_lead',
        is_known_user: true,
        lead_from_contact_partial: false,
        candidate_current_time: Date().toString(),
        name,
        email,
        mobile_code: '',
        country: '',
        phone,
        experience: workExperience,
        course_page_identifier:
          studyAbroadPreferredPrograms[formData.page_identifier],
        ...studyAbroadParams,
      }

      props.updateOlympusLeadData(payload)
      mixpanel.track('Button Click', {
        position: `OnboardingFormLeadFlow ${newOnboardingFormSection} Step ${currentStep}`,
        type: 'Submit',
        ...studyAbroadParams,
      })
    }

    if (closePopup) {
      handleStatesAfterFormSubmission()
    }
  }

  if (showCBIneligibleUserDialog) {
    return (
      <AcademyIneligibleUserDialog
        onClose={() => {
          setShowCBIneligibleUserDialog(false)
          sessionStorage.removeItem('ineligible_cb_user')
          history.push(generateURL('DASHBOARD', null))
        }}
        userId={props.userId}
      />
    )
  }

  const onRecommendationDialogBtnClick = (type: string) => {
    mixpanel.track('Button Click', {
      type,
      position: 'Onboarding Form Intervention',
    })
    handleStatesAfterFormSubmission()
    setShowStudyAbroadDialog(false)
    setShowCertificateProgramDialog(false)
  }
  if (showStudyAbroadDialog) {
    return (
      <StudyAbroadRecommendationDialog
        onClick={onRecommendationDialogBtnClick}
      />
    )
  }
  if (showCertificateProgramDialog) {
    return (
      <CertificateProgramDialog
        allProgramData={props.allProgramsData}
        isIntl={isInternationalUser}
        onClick={onRecommendationDialogBtnClick}
      />
    )
  }

  if (showNewOnboardingForm) {
    return (
      <NewFreeOnboardingFormDialog
        selectedSection={newOnboardingFormSection}
        onSkip={onSkip}
        onNewFormSubmit={newFormSubmit}
      />
    )
  }

  if (showDialog) {
    const isReferralGiftCourse =
      !!props.dashboardData &&
      'gift_course_consent_required' in props.dashboardData &&
      !!props.dashboardData.gift_course_consent_required

    const referralTitle =
      !!props.dashboardData &&
      'referral_title' in props.dashboardData &&
      !!props.dashboardData.referral_title
        ? props.dashboardData.referral_title
        : null

    const referralPhoneNumber =
      !!props.dashboardData &&
      'referral_phone' in props.dashboardData &&
      !!props.dashboardData.referral_phone
        ? props.dashboardData.referral_phone
        : null

    return (
      <AsyncDOM
        data={isInternationalUser || !!props.cities.data}
        loading={props.cities.loading}
        error={!!props.cities.error}
      >
        <AsyncDOM.Content>
          {(isInternationalUser || !referralPhoneNumber) &&
          isReferralGiftCourse ? (
            <ReferralConsentDialog
              isLoading={isLoading}
              onSubmit={onSubmit}
              referralTitle={referralTitle}
              referralPhone={referralPhoneNumber}
              workExperience={workExperience}
            />
          ) : (
            <FreeUserInfoDialog
              isLoading={isLoading}
              onSubmit={onSubmit}
              universitiesData={props.universties.data}
              cityData={props.cities.data}
              isInternationalUser={isInternationalUser}
              collegesData={props.colleges.data}
              fetchColleges={props.fetchColleges}
              fetchUniversities={props.fetchUniversities}
              fromCareerPlusBanner={fromCareerPlusBanner}
              resetColleges={props.resetColleges}
              workExperience={workExperience}
              userProfileData={
                props.userDetails.profile && props.userDetails.profile.data
              }
              isReferralGiftCourse={isReferralGiftCourse}
              isFormReinitiated={
                !!(
                  props.dashboardData &&
                  'user_info_collected_at' in props.dashboardData &&
                  moment().diff(
                    moment(props.dashboardData.user_info_collected_at),
                    'months'
                  ) >= 6
                )
              }
            />
          )}
        </AsyncDOM.Content>
      </AsyncDOM>
    )
  }
  return null
}

const mapStateToProps = (state: AppState) => ({
  dashboardData: state.dashboard.data,
  userId: userDetailsSelectors.getUserID()(state),
  userDetails: state.user.details,
  cities: state.cities,
  universties: state.universities,
  colleges: state.colleges,
  allProgramsData: state.allPrograms.data,
})

export default connect(mapStateToProps, {
  fetchCities,
  fetchUniversities,
  showAlertMessage,
  updateUserInfoCollected,
  updateGiftCourseConsentRequired,
  updateCareerSupportLink,
  fetchColleges,
  resetColleges,
  fetchUserProfileDetails,
  updateOlympusLeadData,
  fetchAllPrograms,
})(FreeUserInfo)
