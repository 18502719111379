import { call, cancelled, put, takeLatest } from 'redux-saga/effects'

import { mixpanel } from 'common/utils/mixpanel'
import {
  startQuizCodingLabs,
  getQuizCodingLabStatus,
  getQuizCodingLabStatusSuccess,
} from './QuizCodingLabs.action'
import {
  START_QUIZ_CODING_LABS,
  GET_QUIZ_CODING_LAB_STATUS,
} from './QuizCodingLabs.types'
import {
  startCodingLabsForQuizAPI,
  getQuizCodingLabStatusAPI,
} from './QuizCodingLabs.api'

function* startQuizCodingLabsHandler(
  action: ReturnType<typeof startQuizCodingLabs>
) {
  const abortController = new AbortController()
  try {
    yield call(startCodingLabsForQuizAPI, action, abortController.signal)
  } catch (e) {
    mixpanel.track('Quiz Coding Labs Start Failed', {
      quiz_id: action.payload.quizID,
    })
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* getQuizCodingLabStatusHandler(
  action: ReturnType<typeof getQuizCodingLabStatus>
) {
  const abortController = new AbortController()
  try {
    const res = yield call(
      getQuizCodingLabStatusAPI,
      action,
      abortController.signal
    )
    yield put(getQuizCodingLabStatusSuccess({ ...res, ...action.payload }))
  } catch (e) {
    mixpanel.track('Quiz Coding Labs Status Failed', {
      quiz_id: action.payload.quiz_id,
      coding_lab_id: action.payload.coding_lab_id,
    })
  } finally {
    if (cancelled()) abortController.abort()
  }
}

export function* quizCodingLabsMiddleware() {
  yield takeLatest(START_QUIZ_CODING_LABS, startQuizCodingLabsHandler)

  yield takeLatest(GET_QUIZ_CODING_LAB_STATUS, getQuizCodingLabStatusHandler)
}
export default ([] as any).concat(quizCodingLabsMiddleware())
