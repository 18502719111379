import colors from './colors'

export default {
  palette: {
    primary: {
      light: colors.primary50,
      main: colors.primary60,
      dark: colors.primary70,
    },
    secondary: {
      light: colors.secondary50,
      main: colors.secondary60,
      dark: colors.secondary70,
    },
    error: {
      light: colors.red50,
      main: colors.red60,
      dark: colors.red70,
    },
    text: {
      primary: colors.black,
      secondary: colors.white,
    },
  },
}
