import { Box, Button, IconButton, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateMouthShutEvent } from 'web/providers/User/UserConsentProvider/UserConsent.actions'
import { Close } from '@material-ui/icons'
import { mixpanel } from 'common/utils/mixpanel'
import styles from './MouthShut.module.css'
import SnackbarStrip from '../Header/SnackbarStrip'

interface MouthShutProps {
  isInternationalUser: boolean
  askForMouthShutReview: boolean
}
const COOKIE_EXPIRY_TIME_IN_SEC = 86400
const MouthShutReview = (props: MouthShutProps) => {
  const dispatch = useDispatch()
  const [foundCookie, setFoundCookie] = useState(true)

  const setCookies = () => {
    const { document } = window
    // 24hrs in second
    const expiryInSeconds = COOKIE_EXPIRY_TIME_IN_SEC
    document.cookie = `tasty_cookie=eat; max-age=${expiryInSeconds}`
  }
  const checkCookies = () => {
    const { document } = window
    const cookiesArray = document.cookie.split(';')
    const isExpired = cookiesArray.filter(
      cookieValue => cookieValue.trim() === 'tasty_cookie=eat'
    )

    return !(isExpired.length > 0)
  }
  const basicCheck = props.askForMouthShutReview && foundCookie

  const showMouthShut = basicCheck && !props.isInternationalUser

  useEffect(() => {
    const mouthShutCookieFound = checkCookies()
    setFoundCookie(mouthShutCookieFound)
  }, [])

  const [open, setOpen] = useState(true)

  const addMixPanel = (msg: string, event: string) => {
    mixpanel.track(msg, { event_name: event })
  }

  const onClose = () => {
    dispatch(updateMouthShutEvent({ event: 'close' }))
    setOpen(false)
    setCookies()
    addMixPanel('learner close mouthshut snackbar', 'mouthshut_close')
  }
  return showMouthShut ? (
    <>
      <SnackbarStrip
        className={styles.mouthShutContainer}
        message={
          <span id="client-snackbar">
            <Box display="flex">
              <img
                className={styles.mouthShutImage}
                src={`${process.env.REACT_APP_CLOUDFRONT_LTI_URL}lti-assets/ms_logo.png`}
                alt="mouthshut"
              />
              <Typography className={styles.mouthShutText}>
                Review your Great Learning program experience on mouthshut.com
              </Typography>
              <a
                href="https://www.mouthshut.com/websites/Great-Learning-reviews-925918401/GreatLearning"
                target="_newtab"
                className={styles.mouthShutLink}
                onClick={() => {
                  dispatch(updateMouthShutEvent({ event: 'click' }))
                  setOpen(false)
                  setCookies()
                  addMixPanel(
                    'learner clicked review button',
                    'mouthshut_click'
                  )
                }}
              >
                <Button variant="contained">Write A Review</Button>
              </a>
            </Box>
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
            className={styles.closeIcon}
          >
            <Close />
          </IconButton>,
        ]}
        open={open}
      />
    </>
  ) : null
}

export default MouthShutReview
