import { put, cancelled, takeEvery, call } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import {
  FETCH_COMPLETED_QUIZZES,
  FETCH_COMPLETED_QUIZZES_CANCEL,
  FETCH_COMPLETED_ASSIGNMENTS,
  FETCH_COMPLETED_ASSIGNMENTS_CANCEL,
} from './CompletedAssessments.types'
import {
  fetchCompletedQuizzesSuccess,
  fetchCompletedQuizzesFailure,
  fetchCompletedAssignmentsSuccess,
  fetchCompletedAssignmentsFailure,
} from './CompletedAssessments.actions'
import {
  fetchCompletedQuizzesAPI,
  fetchCompletedAssignmentsAPI,
} from './CompletedAssessments.api'

function* fetchCompletedQuizzesHandler() {
  const abortController = new AbortController()
  try {
    const response = yield call(
      fetchCompletedQuizzesAPI,
      abortController.signal
    )

    yield put(fetchCompletedQuizzesSuccess(response))
  } catch (e) {
    yield put(fetchCompletedQuizzesFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* fetchCompletedAssignmentsHandler() {
  const abortController = new AbortController()
  try {
    const response = yield call(
      fetchCompletedAssignmentsAPI,
      abortController.signal
    )

    yield put(fetchCompletedAssignmentsSuccess(response))
  } catch (e) {
    yield put(fetchCompletedAssignmentsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* completedAssessmentsMiddleware() {
  yield takeEvery(
    FETCH_COMPLETED_QUIZZES,
    cancelable(fetchCompletedQuizzesHandler, [FETCH_COMPLETED_QUIZZES_CANCEL])
  )

  yield takeEvery(
    FETCH_COMPLETED_ASSIGNMENTS,
    cancelable(fetchCompletedAssignmentsHandler, [
      FETCH_COMPLETED_ASSIGNMENTS_CANCEL,
    ])
  )
}

export default ([] as any).concat(completedAssessmentsMiddleware())
