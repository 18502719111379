import { LocationDescriptorObject } from 'history'
import {
  parse as parseBase,
  parseUrl as parseUrlBase,
  stringify as stringifyBase,
  StringifyOptions,
} from 'query-string'
import { generatePath, matchPath } from 'react-router-dom'
import { call, race, take } from 'redux-saga/effects'
import URL from 'url'
import { ComponentType } from 'react'
import { ProgramID } from 'common/types/programs'
import { DownloadFileAdditionalParams } from 'common/types'
import { programSelectors } from 'web/providers/Dashboard/ProgramsProvider'
import ClipboardJS from 'clipboard'
import routes, { publicRoutes, RouteNames, Routes } from '../../web/routes'
import store from '../../web/store'
import { Page } from '../types/pages'
import {
  InvalidValues,
  NestedObjectWithStringValues,
  ObjectWithStringValues,
  ValidValue,
} from '../types/utils'
import { mixpanel } from './mixpanel'

/**
 * Used to flatten a nested object with string values
 *
 * @param obj - The object to be flattened
 * @param prefix - If a prefix is needed for the first level in the object
 * @param separator - The separator to be used to join the keys
 *
 * @example
 * flatten({ a: { b: { c: 'd', e: 'f'}}},'','.')
 * // returns {"a.b.c": "d","a.b.e": "f" }
 */
export const flatten = (
  obj: NestedObjectWithStringValues,
  prefix: string = '',
  separator: string = '.'
): ObjectWithStringValues =>
  Object.keys(obj).reduce(
    (prev, element) =>
      obj[element] &&
      typeof obj[element] === 'object' &&
      !Array.isArray(obj[element])
        ? {
            ...prev,
            ...flatten(
              obj[element] as NestedObjectWithStringValues,
              `${prefix}${element}${separator}`,
              separator
            ),
          }
        : { ...prev, ...{ [`${prefix}${element}`]: obj[element] } },
    {}
  )

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const capitalizeFirstLetterOfWord = (str: string) => {
  if (str === null || str === undefined || str === '') return ''
  const splitStr = str.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

export const readableText = (text: string) => {
  try {
    return text.replace(/_/g, ' ')
  } catch {
    return text
  }
}

export const getAPIPaginationParams = ({
  availableCount,
  totalCount,
  pageSize,
}: {
  availableCount: number
  totalCount: number
  pageSize: number
}) =>
  Array.from({ length: Math.ceil(totalCount / pageSize) })
    .map((val, idx) => ({ page: idx + 1, per_page: pageSize }))
    .filter(pageObj => pageObj.page * pageObj.per_page > availableCount)

export const isAUrl = (url: string) => {
  const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
  return regexp.test(url)
}

export const isEmail = (val: string) => {
  const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regexp.test(val)
}

export const isMobileNumber = (val: string) => {
  return /^[\d\s+\-()]+$/g.test(val)
}

// Load the js for libphonenumber library first in your container to use this. for reference check Onboarding.tsx
export const validateMobileNumberWithCountryCode = (
  country: string,
  mobileString: string,
  libphonenumber: any
) => {
  const phoneUtil: any = libphonenumber.PhoneNumberUtil.getInstance()
  try {
    return phoneUtil.isValidNumberForRegion(
      phoneUtil.parseAndKeepRawInput(mobileString),
      country
    )
  } catch {
    return false
  }
}

export const getTimezoneOffset = () => {
  const date = new Date().toString().match(/([-\+][0-9]+)\s/)

  if (date && date.length > 1) {
    const timezonestring = date[1]
    return `${timezonestring.substring(0, 3)}:${timezonestring.substring(
      3,
      timezonestring.length
    )}`
  }

  return ''
}

export const groupBy = (items: any, key: any) => {
  return items.reduce(
    (result: any, item: any) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  )
}
export const maybePluralize = (noun: string, count: number, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`

export const isMobileOrTablet = () => {
  const na = navigator.userAgent || navigator.vendor
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      na
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      na.substr(0, 4)
    )
  )
}

export const getOSAndVersion = () => {
  const nAgt = navigator.userAgent || navigator.vendor

  let os = null
  let osVersion = 0

  const clientStrings = [
    { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
    { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
    { s: 'Windows Vista', r: /Windows NT 6.0/ },
    { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
    { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
    { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
    { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
    { s: 'Windows 98', r: /(Windows 98|Win98)/ },
    { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
    { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: 'Windows CE', r: /Windows CE/ },
    { s: 'Windows 3.11', r: /Win16/ },
    { s: 'Android', r: /Android/ },
    { s: 'Open BSD', r: /OpenBSD/ },
    { s: 'Sun OS', r: /SunOS/ },
    { s: 'Chrome OS', r: /CrOS/ },
    { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
    { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
    { s: 'Mac OS X', r: /Mac OS X/ },
    { s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: 'QNX', r: /QNX/ },
    { s: 'UNIX', r: /UNIX/ },
    { s: 'BeOS', r: /BeOS/ },
    { s: 'OS/2', r: /OS\/2/ },
    {
      s: 'Search Bot',
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
    },
  ]
  clientStrings.some(id => {
    if (id.r.test(nAgt)) {
      os = id.s
      // eslint-disable-next-line prefer-destructuring
      osVersion = /Windows (.*)/.exec(os)
        ? parseFloat(/Windows (.*)/.exec(os)![1])
        : 0
      os = id.s && /Windows/.test(id.s) ? 'Windows' : id.s
      return true
    }
    return false
  })

  return { os, osVersion }
}

export const fileExtension = (fname: string) =>
  fname.slice(fname.lastIndexOf('.') + 1)

export const BooleanFilter = <T>(x: T): x is ValidValue<T> => Boolean(x)

export const cancelable = (saga: any, cancelAction: string | string[]) =>
  function* util(...args: any[]) {
    let cancelActions: any[] = []
    cancelActions = cancelActions
      .concat(cancelAction)
      .map((action: string) => take(action))
    yield race([call(saga, ...args), ...cancelActions])
  }

/**
 *
 * @param time - Time in milliseconds
 *
 * @example
 * await sleep(1000)
 *
 * // To await for 1000 milliseconds
 */
export const sleep = (time: number) => new Promise(res => setTimeout(res, time))

const urlFormattingObj: StringifyOptions = { arrayFormat: 'bracket' }

export const stringify = (params: any) =>
  stringifyBase(params, urlFormattingObj)

export const parse = (params: any) => parseBase(params, urlFormattingObj)

export const parseUrl = (url: string) => parseUrlBase(url, urlFormattingObj)

export const getDisplayNumber = (num: number) => {
  if (num) {
    return Number.isInteger(num) ? num : num.toFixed(2)
  }
  return num
}

export const pickIntoArray = <T, K extends keyof T = keyof T>(
  obj: T,
  list: K[]
) => {
  return list.map(id => obj[id]).filter(BooleanFilter)
}

export const pick = <T, K extends keyof T>(obj: T, list: K[]): Pick<T, K> => {
  return list.reduce((acc, idx) => {
    acc[idx] = obj[idx]
    return acc
  }, {} as Pick<T, K>)
}

export const hasError = <T>(item: T | Error): item is Error => {
  return item instanceof Error
}

export const convertArrayToObjectMap = <
  T extends { [k in string | number]: any },
  K extends keyof T
>(
  data: T[],
  selector: K
): Record<T[K], T> =>
  data.reduce((accumulator, item) => {
    accumulator[`${item[selector]}` as T[K]] = item
    return accumulator
  }, {} as Record<T[K], T>)

export const isValid = <T>(item: T): item is Exclude<T, InvalidValues> =>
  !!item && !hasError(item)

export const isValidNumber = (x: string | number | null | undefined) => {
  return Number(x) == x
}

export const isStringified = (val: any) => {
  try {
    JSON.parse(val)
    return true
  } catch (e) {
    return false
  }
}
export const randomInteger = () => Math.trunc(Math.random() * 100)

export const throttle = (func: Function, limit: number) => {
  let lastFunc: number
  let lastRan: number
  return function throttledFunction(...args: any[]) {
    if (!lastRan) {
      func(...args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = window.setTimeout(function throttledCallback() {
        if (Date.now() - lastRan >= limit) {
          func(...args)
          lastRan = Date.now()
        }
      }, limit - (Date.now() - lastRan))
    }
  }
}

export const debounce = (
  fn: Function,
  time: number
): ((...args: any[]) => void) => {
  let timeout: any

  return function debounceFunction(this: any, ...args: any[]) {
    const functionCall = () => fn.apply(this, args)

    clearTimeout(timeout)
    timeout = setTimeout(functionCall, time)
  }
}

export const isElementInsideContainerViewport = (
  child: Element,
  parent: Element
) => {
  const childPos = child.getBoundingClientRect()
  const parentPos = parent.getBoundingClientRect()
  return childPos.top < parentPos.bottom && childPos.bottom > parentPos.top
}

export const joinURL = (baseUrl: string, urlPath: string) => {
  return URL.resolve(baseUrl, urlPath)
}

export const addParamsToUrl = (url: string, paramsObj: object) => {
  const urlObj = parseUrl(url)
  return `${urlObj.url}?${stringify({ ...urlObj.query, ...paramsObj })}`
}

export const removeParamFromUrl = (key: string, sourceURL: string): string => {
  let rtn = sourceURL.split('?')[0]
  let param
  let params_arr = []
  const queryString =
    sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : ''
  if (queryString !== '') {
    params_arr = queryString.split('&')
    for (let i = params_arr.length - 1; i >= 0; i -= 1) {
      ;[param] = params_arr[i].split('=')
      if (param === key) {
        params_arr.splice(i, 1)
      }
    }
    rtn = `${rtn}?${params_arr.join('&')}`
  }
  return rtn
}

export const getUserDetails = () => store.getState().user.details

export const communitySsoToken = () =>
  store.getState().programs.data.communitySsoToken ||
  store.getState().user?.connectConsent?.user_consent?.community_token ||
  ''

export const getActiveProgramBatchId = () =>
  store.getState().programs.data.activeProgramID || -1

export const getActiveProgramId = () => {
  let programId = -1
  const pbId = store.getState().programs.data.activeProgramID || -1
  if (pbId > 0) {
    const program = store.getState().programs.data.byId[pbId]
    programId = program ? program.program_id : -1
  }
  return programId
}

export const getAppSource = () => store.getState().user.details.appSource

export const getCommunityJwtToken = () =>
  store.getState().community.data.communityJwtToken

export const parseJwtToken = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export const isJwtTokenExpired = (token: string) => {
  try {
    const decodedJwt = parseJwtToken(token)
    return decodedJwt.exp * 1000 < Date.now()
  } catch (e) {
    return true
  }
}

export const apiCall = async <T = any>({
  url,
  query = null,
  params = {},
  auth = true,
  excludeProgramId = false,
  communityToken = false,
}: {
  url: string
  query?: { [k in string]: any } | null
  params?: RequestInit & { headers?: Record<string, string> }
  auth?: boolean
  excludeProgramId?: boolean
  communityToken?: boolean
}) => {
  const urlQueryParams: { [k: string]: any } = { ...query }
  const {
    headers = {},
    method = 'GET',
    body = undefined,
    ...restParams
  } = params

  if (body && isStringified(body)) {
    headers['Content-Type'] = headers['Content-Type'] || 'application/json'
  }

  if (auth) {
    const { auth } = getUserDetails()
    if (!auth.isAuthenticated) {
      throw new Error('User is not authenticated')
    }
    // urlQueryParams.access_token = auth.accessToken
    headers.Authorization =
      headers.Authorization || `Bearer ${auth.accessToken}`
  }

  // @ts-ignore
  if ('SafeExamBrowser' in window && window.SafeExamBrowser) {
    // @ts-ignore
    headers['x-safeexambrowser-requesthash'] =
      // @ts-ignore
      JSON.stringify(window.SafeExamBrowser.security.browserExamKey)
    headers['x-safeexambrowser-configkeyhash'] =
      // @ts-ignore
      JSON.stringify(window.SafeExamBrowser.security.configKey)
    headers['x-safeexambrowser-pageurl'] = window.location.href
  }

  if (communityToken) {
    const { auth } = getUserDetails()
    const communityJwtToken = getCommunityJwtToken()
    if (!auth.isAuthenticated) {
      throw new Error('User is not authenticated')
    }

    headers.Authorization = `Bearer ${communityJwtToken}`
  }

  if (!excludeProgramId) {
    const programBatchId = getActiveProgramBatchId()
    if (programBatchId) {
      urlQueryParams.pb_id = programBatchId
    }
  }

  const requestUrl =
    Object.keys(urlQueryParams).length > 0
      ? addParamsToUrl(url, urlQueryParams)
      : url

  const test_iac_value_from_local_storage = localStorage.getItem('iac-prod')
  if (test_iac_value_from_local_storage) {
    headers['X-USE-IAC'] = 'true'
  }
  return fetch(requestUrl, {
    method,
    headers,
    cache: 'no-store',
    body,
    ...restParams,
  })
}

/** moved it down so that apiCall can be utilised  */
/** Downloads a file given its URL and file name */
export const downloadFile = async ({
  url,
  displayName,
  isAuthRequired = false,
  additionalParams = {},
  fileName,
}: {
  url: string
  displayName: string
  isAuthRequired?: boolean
  additionalParams?: DownloadFileAdditionalParams
  fileName?: string
}) => {
  let fileReq
  if (isAuthRequired) {
    fileReq = await apiCall({ url, ...additionalParams })
  } else {
    fileReq = await fetch(url, {
      headers: additionalParams.params
        ? additionalParams.params.headers
        : undefined,
    })
  }
  if (fileReq.ok) {
    const fileBlob = await fileReq.blob()
    const blobUrl = window.URL.createObjectURL(fileBlob)
    const anchorElement = document.createElement('a')
    anchorElement.href = blobUrl
    anchorElement.download = fileName || displayName
    document.body.appendChild(anchorElement)
    anchorElement.click()
    setTimeout(() => {
      // window.URL.revokeObjectURL(blobUrl)
      anchorElement.remove()
    })
  } else {
    throw new Error(`Unable to download file: ${fileName || displayName}`)
  }
}

export const mockFile = (name: string, size: number, mimeType: string) => {
  function range(count: number) {
    let output = ''
    for (let i = 0; i < count; i += 1) {
      output += 'a'
    }
    return output
  }
  const blob: any = new Blob([range(size)], { type: mimeType })
  blob.lastModifiedDate = new Date()
  blob.name = name

  return blob
}

export const generateURL = <
  K extends Page['page'],
  T extends Extract<Page, { page: K }>
>(
  type: K,
  params: T['location'],
  options?: { from?: string; pb_id?: ProgramID }
): LocationDescriptorObject => {
  let pathName
  if (type === 'MISC') {
    pathName = generatePath(
      (params as Extract<Page, { page: 'MISC' }>['location']).pathName,
      params && 'path' in params ? params.path! : undefined
    )
  } else {
    pathName = generatePath(
      routes[type as Exclude<K, 'MISC'>],
      params && 'path' in params ? params.path! : undefined
    )
  }
  let searchParams
  let stateParams

  if (params) {
    searchParams = 'search' in params ? params.search : undefined
    stateParams = 'state' in params ? params.state : undefined
  }

  const programBatchId =
    options && options.pb_id ? options.pb_id : getActiveProgramBatchId()

  return {
    pathname: pathName,
    search: stringify({ pb_id: programBatchId, ...searchParams }),
    state: {
      ...stateParams,
      from: options && options.from ? options.from : window.location.href,
      pb_id: programBatchId,
    },
  }
}

export const getCurrentHistoryState = () =>
  window.history.state && window.history.state.state
    ? window.history.state.state
    : {}

export const getCurrentReferrer = (): string => {
  const historyObj = getCurrentHistoryState()
  if (historyObj && historyObj.from) {
    return historyObj.from
  }
  return document.referrer
}

export const getCurrencySymbol = (currencyType: string) => {
  switch (currencyType) {
    case 'usd':
      return '$'
    case 'inr':
      return 'Rs'
    default:
      return 'Rs'
  }
}

export const isBlob = (value: any): value is Blob =>
  value &&
  typeof value.size === 'number' &&
  typeof value.type === 'string' &&
  typeof value.slice === 'function'

export const isFile = (value: any): value is File =>
  typeof value.name === 'string' &&
  (typeof value.lastModifiedDate === 'object' ||
    typeof value.lastModified === 'number') &&
  isBlob(value)

export const objectToFormData = (
  obj: Record<string, any>,
  cfg: {
    nullsAsUndefineds?: boolean
    indices?: boolean
  } = {},
  fd?: FormData,
  pre?: string
): FormData => {
  const formData = fd || new FormData()

  if (obj === undefined) {
    return formData
  }
  if (obj === null) {
    if (!cfg.nullsAsUndefineds) {
      formData.append(pre || '', '')
    }
  } else if (Array.isArray(obj)) {
    if (!obj.length) {
      const key = `${pre}[]`

      formData.append(key, '')
    } else {
      obj.forEach((value, index) => {
        const key = `${pre}[${cfg.indices ? index : ''}]`

        objectToFormData(value, cfg, formData, key)
      })
    }
  } else if (obj instanceof Date) {
    formData.append(pre || '', obj.toISOString())
  } else if (typeof obj === 'object' && !isFile(obj) && !isBlob(obj)) {
    Object.keys(obj).forEach(prop => {
      const value = obj[prop]

      if (Array.isArray(value)) {
        while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
          // eslint-disable-next-line no-param-reassign
          prop = prop.substring(0, prop.length - 2)
        }
      }

      const key = pre ? `${pre}[${prop}]` : prop

      objectToFormData(value, cfg, formData, key)
    })
  } else {
    formData.append(pre || '', obj)
  }

  return formData
}

export class CustomError<A> {
  name = 'CustomError'

  message: string

  error: A

  constructor(message: string, error: A) {
    this.message = message
    this.error = error
  }
}

export const getPercentage = (num: number, dem: number): number => {
  if (num && dem) return (num / dem) * 100
  return 0
}

export const getMinimizedValue = (
  value: number,
  power: number,
  decimal: number = 2
): string => (value / 10 ** power).toFixed(decimal)

export const getMedian = (arr: number[]) => {
  const mid = Math.floor(arr.length / 2)
  const nums = [...arr].sort((a, b) => a - b)
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2
}

export const readableNumber = (num: number, toFixed: number = 1): number => {
  if (Number.isInteger(num)) return num
  return Number(num.toFixed(toFixed))
}

export const isNetworkError = (errorMessage: string) =>
  errorMessage.toLowerCase().includes('failed to fetch') ||
  errorMessage
    .toLowerCase()
    .includes('networkerror when attempting to fetch resource') ||
  errorMessage
    .toLowerCase()
    .includes('the internet connection appears to be offline')

export const sortByAlphabeticalOrder = (x: string, y: string) => {
  if (x < y) return -1
  if (x > y) return 1
  return 0
}

export const checkNetworkConnection = async (url?: string) => {
  try {
    await fetch(url || 'https://www.google.com/', {
      mode: 'no-cors',
    })
    return true
  } catch (e) {
    return false
  }
}

export const triggerCameraAndMicrophoneAccess = async (
  videoOptions?: boolean | MediaTrackConstraints,
  audioOptions?: boolean | MediaTrackConstraints
) => {
  try {
    const res = await navigator.mediaDevices.getUserMedia({
      video: videoOptions !== undefined ? videoOptions : true,
      audio: audioOptions !== undefined ? audioOptions : true,
    })
    return res
  } catch (e) {
    mixpanel.track('Webcam Error', {
      error_message: `${e.message}_${e.name}`,
      error_action: 'resource access',
    })
    return false
  }
}

export const checkIfCameraAndMicrophoneEnabled = async () => {
  try {
    const camera = await navigator.permissions.query({
      name: 'camera',
    })
    const microphone = await navigator.permissions.query({
      name: 'microphone',
    })
    return { camera: camera.state, microphone: microphone.state }
  } catch (e) {
    return false
  }
}

const TEST_TEXT = 'test'

const createAndUseTextArea = (text: string) => {
  const textarea = document.createElement('textarea')
  textarea.value = text
  document.body.appendChild(textarea)
  textarea.select()
  document.execCommand('copy')
  document.execCommand('paste')
  document.body.removeChild(textarea)
}

const handleClipboardJS = (text: string) => {
  try {
    const clipboard = new ClipboardJS('.btn', {
      text() {
        return text
      },
    })

    clipboard.on('success', function(ev) {
      ev.clearSelection()
    })

    clipboard.on('error', function(ev) {
      console.error('Action:', ev.action)
      console.error('Trigger:', ev.trigger)
    })
    return true
  } catch (error) {
    console.error('Error triggering clipboard access:', error)
    return false
  }
}

type Browser = 'Edge' | 'Chrome' | 'Safari' | 'Firefox' | 'Unknown'

export function getBrowser(): Browser {
  const ua = navigator.userAgent
  if (ua.includes('Edg')) {
    return 'Edge'
  }
  if (ua.includes('Chrome')) {
    return 'Chrome'
  }
  if (ua.includes('Safari')) {
    return 'Safari'
  }
  if (ua.includes('Firefox')) {
    return 'Firefox'
  }
  return 'Unknown'
}

const handleClipboardAccess = async () => {
  const browser = getBrowser()

  switch (browser) {
    case 'Chrome':
    case 'Edge':
      if (
        navigator.clipboard &&
        typeof navigator.clipboard.readText === 'function'
      ) {
        await navigator.clipboard.writeText(TEST_TEXT)
        await navigator.clipboard.readText()
      } else {
        createAndUseTextArea(TEST_TEXT)
      }
      break
    case 'Firefox':
      createAndUseTextArea(TEST_TEXT)
      break
    case 'Safari':
      handleClipboardJS(TEST_TEXT)
      break
    case 'Unknown':
      if (
        navigator.clipboard &&
        typeof navigator.clipboard.readText === 'function'
      ) {
        await navigator.clipboard.writeText(TEST_TEXT)
        await navigator.clipboard.readText()
      } else {
        createAndUseTextArea(TEST_TEXT)
        if (!handleClipboardJS(TEST_TEXT)) {
          throw new Error('Clipboard access failed')
        }
      }
      break
    default:
      throw new Error('Unsupported browser')
  }
}

export const triggerClipboardAccess = async () => {
  try {
    await handleClipboardAccess()
    return true
  } catch (e) {
    console.error('Error triggering clipboard access:', e)
    return false
  }
}

export const checkIfClipboardEnabled = async () => {
  try {
    await handleClipboardAccess()
    return true
  } catch (e) {
    console.error('Error checking clipboard access:', e)
    return false
  }
}

export const getBrowserAndVersion = () => {
  const ua = navigator.userAgent
  let tem
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return `IE ${tem[1] || ''}`
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
    if (tem != null)
      return tem
        .slice(1)
        .join(' ')
        .replace('OPR', 'Opera')
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  tem = ua.match(/version\/(\d+)/i)
  if (tem != null) M.splice(1, 1, tem[1])
  return M.join(' ')
}

// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
export const isIOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}
export const isIphone = (): boolean => {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera
  // Check for iPhone
  if (/iPhone/i.test(userAgent)) {
    return true
  }
  return false
}

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob
  // A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date()
  b.name = fileName

  // Cast to a File() type
  return <File>theBlob
}

export const retryChunkLoad = (
  fn: any
): Promise<{ default: ComponentType<any> }> => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: Error) => {
        reject(error)
        window.location.reload()
      })
  })
}
export const getDuplicateValuesFromArray = (
  arr: (string | number)[]
): (string | number)[] =>
  arr.filter((ele, index, array) => array.indexOf(ele) !== index)

export const isProduction = () => process.env.REACT_APP_ENV === 'production'

export const getEncodedCloudfrontURL = (url: string) => {
  if (isProduction()) {
    return `${process.env.REACT_APP_CLOUDFRONT_LTI_URL}${encodeURIComponent(
      url.replace(`${process.env.REACT_APP_CLOUDFRONT_LTI_URL}`, '')
    )}`
  }
  return url
}

export const copyTextToClipboard = (text: string): boolean => {
  const span = document.createElement('span')
  span.textContent = text
  // Preserve consecutive spaces and newlines
  span.style.whiteSpace = 'pre'
  // Add the <span> to the page
  document.body.appendChild(span)

  // Make a selection object representing the range of text selected by the user
  const selection = window.getSelection()
  const range = window.document.createRange()
  let success = false
  if (selection) {
    selection.removeAllRanges()
    range.selectNode(span)
    selection.addRange(range)
    // Copy text to the clipboard
    try {
      success = window.document.execCommand('copy')
    } catch (err) {
      console.log('error', err)
    }
    // Cleanup
    selection.removeAllRanges()
  }
  window.document.body.removeChild(span)
  return success
}

export const stringMatchAgainstArrayOfStrings = (
  str: string,
  substringsArray: string[]
) => substringsArray.every(substring => str.includes(substring))

export const symmetricArrayDiff = (
  arr1: (number | string)[],
  arr2: (number | string)[]
) =>
  arr1
    .filter(x => !arr2.includes(x))
    .concat(arr2.filter(x => !arr1.includes(x)))

export const getTimezoneAbbreviation = (tzAbbr: string) => {
  if (tzAbbr[0] === '+' || tzAbbr[0] === '-') return `GMT${tzAbbr}`
  return tzAbbr
}

// reference on generating unique id the below function is good enough for our use space, do not use it for security.
// https://stackoverflow.com/questions/38228180/is-generating-and-concatenating-3-math-random-values-more-random-than-1-math-r
// eg:- "9b2d6db6-a1e0"
export const getUniqueID = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  return `${s4() + s4()}-${s4()}`
}

export const stringToHslColor = (str: string, s: number, l: number) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  const h = hash % 360
  return `hsl(${h}, ${s}%, ${l}%)`
}

export const range = (start: number, stop: number, step: number = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

export const isMobileBrowser = () => {
  let check = false
  ;(function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor)
  return check
}

export const formattedNumber = (
  value: number,
  decimalPoints: number = 1,
  isIntl = false
) => {
  if (!value) {
    return value
  }

  const thousand = 1000
  const prefix = value < 0 ? '-' : ''
  const numberValue = Math.abs(value)
  let formattedValue: string = numberValue.toString()

  const formatNumber = (divisor: number) =>
    (numberValue / divisor).toFixed(decimalPoints).toString()

  if (isIntl) {
    const million = 1000 * thousand
    if (numberValue >= million) {
      formattedValue = `${formatNumber(million)}M+`
    } else if (numberValue >= thousand) {
      formattedValue = `${formatNumber(thousand)}K+`
    }
  } else {
    const lakh = 100 * thousand
    const cr = 100 * lakh

    if (numberValue >= cr) {
      formattedValue = `${formatNumber(cr)}Cr+`
    } else if (numberValue >= lakh) {
      formattedValue = `${formatNumber(lakh)}L+`
    } else if (numberValue >= thousand) {
      formattedValue = `${formatNumber(thousand)}K+`
    }
  }

  return `${prefix}${formattedValue}`
}

export const getCareerPlusCourseId = () => (isProduction() ? '41635' : '14177')

export const fileToBase64String = (file: File) =>
  new Promise(
    (
      resolve: (value: string | ArrayBuffer | null) => void,
      reject: (value: ProgressEvent<FileReader>) => void
    ) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    }
  )

export const getLocalStorageItemWithExpiry = (key: string) => {
  const itemStr = localStorage.getItem(key)
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const currentTime = new Date().getTime()
  // compare the expiry time of the item with the current time
  if (currentTime > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key)
    return null
  }
  return item
}

export const setCookie = (
  cname: string,
  cvalue: string,
  exdays: number,
  domain?: boolean
) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = `expires=${d.toUTCString()}`
  if (domain) {
    const domainName = window.location.hostname
      .split('.')
      .slice(-2)
      .join('.')
    document.cookie = `${cname}=${encodeURIComponent(
      cvalue
    )};${expires};domain=${domainName};path=/`
  } else {
    document.cookie = `${cname}=${encodeURIComponent(cvalue)};${expires};path=/`
  }
}

export const linkedinGetPopUpCookie = () => {
  return getCookie('skip_linkedin_pop') == 'true'
}

export const linkedinSetPopUpCookie = () => {
  setCookie('skip_linkedin_pop', 'true', 1, true)
}

export const clickedLinkedinBtn = (type: string | null) => {
  if (type === 'set') {
    return sessionStorage.setItem('clickedLinkedinIn', 'true')
  }
  if (type === 'get') {
    return sessionStorage.getItem('clickedLinkedinIn')
  }
  return sessionStorage.removeItem('clickedLinkedinIn')
}

export const linkedinErrorMessageMapping = (message: string) => {
  if (message === 'user_cancelled_login') {
    return 'To connect your Linkedin Profile, please login to you Linkedin account'
  }
  if (message === 'user_cancelled_authorize') {
    return 'You cancelled sharing of your LinkedIn profile. You can still grant access or click Skip to continue.'
  }
  return 'Some error observed, please try again'
}

export const getCookie = (cname: string) => {
  if (navigator.cookieEnabled) {
    const name = `${cname}=`
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return decodeURIComponent(c.substring(name.length, c.length))
      }
    }
  }
  return ''
}

export const setLocalStorageItemWithExpiry = (
  key: string,
  value: any,
  expiration: number = 0 // if no value is passed, it will look into local storage for key for previous expiry else set as 24 hours
) => {
  let expiry = expiration
  if (!expiry) {
    const itemWithExpiry = getLocalStorageItemWithExpiry(key)
    if (itemWithExpiry) {
      // eslint-disable-next-line prefer-destructuring
      expiry = itemWithExpiry.expiry
    } else {
      const oneDay = 24 * 60 * 60 * 1000
      const currentTime = new Date().getTime()
      expiry = currentTime + oneDay
    }
  }

  const item = {
    value,
    expiry,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export const convertArrayArgsToString = (array_args: (string | number)[]) => {
  let string = ''
  array_args.map(val => {
    const numVal = Number(val)
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(numVal)) {
      string += ` ${numVal}`
    } else {
      // eslint-disable-next-line no-useless-escape
      string += ` ${val.toString().replace(/\'/g, '"')}`
    }
  })
  return string
}

export const replaceFileNameFromOutput = (output: string) => {
  const htmlPattern = /<[a-z][\s\S]*>\w+[a-z/]*/i
  const isHtmlData = htmlPattern.test(output)
  const fileNameRegex = isHtmlData
    ? /piston\/jobs\/\w/gm
    : /piston\/jobs\/\w|\S+\//gm
  return output.replace(fileNameRegex, '')
}

export const millisecondsToSeconds = (millis: number) => {
  return ((millis % 60000) / 1000).toFixed(0)
}
export function uuidv4() {
  // Math.random() RNG is not very robust don't use for very extreme
  // collisions can happen once in a long while
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0
    // eslint-disable-next-line no-bitwise
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export const browserResourceGuideLinks = () => {
  const ua = navigator.userAgent
  if (ua.includes('Edg'))
    return 'https://www.windowscentral.com/how-manage-site-permissions-new-microsoft-edge#set_site_permissions_edge_chromium'
  if (ua.includes('Chrome'))
    return 'https://support.google.com/chrome/answer/2693767'
  if (ua.includes('Safari'))
    return 'https://support.apple.com/en-in/guide/safari/ibrwe2159f50/mac'
  if (ua.includes('Firefox'))
    return 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions'
  return ''
}

export const getValidUrl = (url = '') => {
  let newUrl = window.decodeURIComponent(url)
  newUrl = newUrl.trim().replace(/\s/g, '')

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`
  }

  return newUrl
}

export const unescapeHTML = (string: string) => {
  const elt = document.createElement('textarea')
  elt.innerHTML = string
  return elt.innerText
}

export const singularOrPluralText = (
  count: number,
  text: string,
  pluralSuffix = 's',
  pluralFallback?: string
) => {
  if (count > 1 || count === 0) {
    return pluralFallback || `${count} ${text}${pluralSuffix}`
  }
  return `${count} ${text}`
}
export const removePDFEvents = () => {
  if ('PDFViewerApplication' in window) {
    // @ts-ignore
    window.PDFViewerApplication.unbindWindowEvents()
  }
}

export const getPageName = (path: string) => {
  if (path.includes('dashboard')) {
    return 'dashboard'
  }

  if (path.includes('courses')) {
    return 'course_details'
  }

  return 'olympus'
}

const acadOpsUserId = () => (isProduction() ? 6496597 : 343819)

export const getFinancialImpactReportCourseId = () =>
  isProduction() ? '88104' : '15856'

export const isUserAcadOps = () => {
  const { id } = getUserDetails()
  if (id == acadOpsUserId()) {
    return true
  }
  return false
}

export const setAppUrls = () => {
  const ownDomains = [
    process.env.REACT_APP_LMS_URL,
    process.env.REACT_APP_EPORTFOLIO_PUBLIC_URL,
    process.env.REACT_APP_WEBSITE_URL,
  ]
  const windowLocationToUse =
    !ownDomains.includes(`${window.location.origin}/`) &&
    process.env.NODE_ENV !== 'development'

  if (windowLocationToUse) {
    const { origin } = window.location
    const subdomainLength = origin.split('.')[0].length
    const ltiDomain = `${origin.slice(0, subdomainLength)}1${origin.slice(
      subdomainLength
    )}`
    const elevateUrl = process.env.REACT_APP_ELEVATE_URL || ''
    const elevateSubdomain = elevateUrl.split('.')[0]
    const elevateDomain = `${elevateUrl.slice(
      0,
      elevateSubdomain.length
    )}${origin.slice(subdomainLength)}`

    window.constants = {
      REACT_APP_LMS_API_URL: `${origin}/api/`,
      REACT_APP_LTI_API_URL: `${ltiDomain}/api/`,
      REACT_APP_LMS_URL: `${origin}/`,
      REACT_APP_LTI_URL: `${ltiDomain}/`,
      REACT_APP_ELEVATE_URL: `${elevateDomain}/`,
      REACT_APP_ELEVATE_API_URL: `${elevateDomain}/api/`,
      DEFAULT_PAGE_TITLE: 'Olympus LMS',
      REACT_APP_MAGNA_IMAGE_HOST: process.env.REACT_APP_MAGNA_IMAGE_HOST,
    }
  } else {
    // @ts-ignore
    window.constants = {
      REACT_APP_LMS_API_URL: process.env.REACT_APP_LMS_API_URL!,
      REACT_APP_LTI_API_URL: process.env.REACT_APP_LTI_API_URL!,
      REACT_APP_LMS_URL: process.env.REACT_APP_LMS_URL!,
      REACT_APP_LTI_URL: process.env.REACT_APP_LTI_URL!,
      REACT_APP_ELEVATE_URL: process.env.REACT_APP_ELEVATE_URL!,
      REACT_APP_ELEVATE_API_URL: process.env.REACT_APP_ELEVATE_API_URL!,
      DEFAULT_PAGE_TITLE: 'Great Learning',
      REACT_APP_MAGNA_IMAGE_HOST: process.env.REACT_APP_MAGNA_IMAGE_HOST,
    }
  }
}

export const isPublicRoute = <K extends RouteNames>(
  path: Routes[K]
): boolean => {
  const eportfolioHostUrl = `https://${window.location.host}/`

  if (
    eportfolioHostUrl === `${process.env.REACT_APP_EPORTFOLIO_PUBLIC_URL}` ||
    eportfolioHostUrl ===
      `${process.env.REACT_APP_EPORTFOLIO_PARTNER_PUBLIC_URL}`
  ) {
    return true
  }

  const filteredPublicRoutes = Object.entries(publicRoutes).filter(
    r => r[1] !== '/:username'
  )
  const route = Object.entries(filteredPublicRoutes).find(
    /* eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars */
    ([routeName, routeUrl]) => matchPath(path, { path: routeUrl, exact: true })
  )

  return !!route
}

export const isActiveBatchGLAorFSL = (userAccessType: string = ''): boolean => {
  if (!userAccessType) {
    const state = store.getState()
    const activeProgram = programSelectors.getActiveProgramDetails()(state)
    userAccessType = (activeProgram && activeProgram.access_type) || ''
  }

  return ['free', 'corporate', 'free_international'].includes(userAccessType)
}

export function getCodingLabURLParams(search: string) {
  const params = new URLSearchParams(search)
  return {
    preview: params.get('preview') === 'true',
    courseId: Number(params.get('course_id')),
    assignmentId: params.get('assignment_id'),
    moduleItemId: params.get('module_item_id'),
  }
}

export function isJSFunction(fn: any): fn is 'function' {
  return typeof fn === 'function'
}
