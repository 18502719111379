import {
  call,
  delay,
  put,
  select,
  takeEvery,
  take,
  all,
} from 'redux-saga/effects'
import { FileID } from 'common/types'
import { showOfflinePopup } from 'web/providers/NetworkDetectorProvider'
import {
  QuizAnswerData,
  QuizSubmissionData,
  QuizQuestionData,
  EssayQuizQuestion,
} from '../../../../../../common/types/courses/quiz'
import {
  BooleanFilter,
  cancelable,
  pick,
  capitalizeFirstLetter,
  CustomError,
  isNetworkError,
  downloadFile,
  getUserDetails,
} from '../../../../../../common/utils'
import { quizSubmissionSelectors } from '../QuizSubmissionsProvider'
import * as actions from './QuizQA.actions'
import * as quizSubmissionActions from '../QuizSubmissionsProvider/QuizSubmissions.actions'
import {
  flagQuizQuestionAPI,
  getQuizQuestionsAndAnswersAPI,
  saveQuizAnswersAPI,
  getFileDetailsAPI,
} from './QuizQA.api'
import * as types from './QuizQA.types'
import { showAlertMessage } from '../../../../AlertsProvider'
import { fetchModuleItem } from '../..'
import { stopProctoring } from '../../../../ProctoringProvider'
import {
  batchUploadFile,
  fileTypes,
  FilesState,
} from '../../../../FilesProvider'
import { dynamicQuizQuestionThreadData } from '../QuizActivityProvider/QuizActivity.api'

/** QUIZ SUBMISSION CREATE */
function* getQuizQuestionsAndAnswersHandler(
  action: ReturnType<typeof actions.fetchQuizSubmissionQuestionsAndAnswers>
) {
  try {
    const submission: QuizSubmissionData | null = yield select(
      quizSubmissionSelectors.getQuizSubmission({
        itemId: action.meta.itemId,
        attempt: action.meta.attemptNo,
      })
    )
    if (!submission) {
      return
    }

    const { id } = getUserDetails()
    const data = yield call(getQuizQuestionsAndAnswersAPI, {
      submissionId: submission.id,
      query: {
        quiz_submission_attempt: submission.attempt,
        validation_token: submission.validation_token,
        student_id: id,
      },
    })
    if (data.quiz_submission_questions) {
      let dynamicAnswers = []
      if (Array.isArray(data.quiz_submission_questions)) {
        dynamicAnswers = yield all(
          data.quiz_submission_questions
            .filter(
              (question: QuizQuestionData) =>
                question?.question_type === 'essay_question' &&
                question?.essay_question_submission_type === 'dynamic_question'
            )
            .map((question: EssayQuizQuestion & QuizAnswerData) => {
              const answer =
                question.answer && typeof question.answer === 'string'
                  ? JSON.parse(question.answer)
                  : null
              if (answer)
                return call(dynamicQuizQuestionThreadData, {
                  embed_code: question.dynamic_question_embed_code!,
                  thread_id: answer.thread_id,
                  question_id: question.id,
                  model_id: answer.model_id,
                })
            })
        )
      }
      yield put(
        actions.fetchQuizSubmissionQuestionsAndAnswersSuccess(
          data.quiz_submission_questions,
          action.meta
        )
      )
      yield put(
        actions.getDynamicQuestionAnswersSuccess(dynamicAnswers, action.meta)
      )
    } else throw data
  } catch (e) {
    if ('errors' in e) {
      yield put(
        actions.fetchQuizSubmissionQuestionsAndAnswersFailure(
          new Error(e.errors[0].message),
          action.meta
        )
      )
    } else if ('status' in e) {
      yield put(
        actions.fetchQuizSubmissionQuestionsAndAnswersFailure(
          new Error(e.message),
          action.meta
        )
      )
    } else {
      yield put(
        actions.fetchQuizSubmissionQuestionsAndAnswersFailure(e, action.meta)
      )
    }
  }
}

function* saveQuizAnswersHandler(
  action: ReturnType<typeof actions.saveQuizAnswers>
) {
  const { itemId, attemptNo } = action.meta

  const submission: QuizSubmissionData | null = yield select(
    quizSubmissionSelectors.getActiveQuizSubmission(itemId)
  )
  if (
    !submission ||
    !submission.answers ||
    submission.answers instanceof Error
  ) {
    return
  }

  const answers: QuizAnswerData[] = Object.values(submission.answers)
    .filter(BooleanFilter)
    .filter(
      i =>
        (i.answer !== null ||
          (i.answer === null && 'attachments' in i && i.attachments)) &&
        (!i.syncWithServer || action.payload.onQuizComplete) // Either post questions which are not synced to server yet or send all questions just before quiz complete
    )
    .map(answer => pick(answer, ['answer', 'id', 'flagged']) as QuizAnswerData)

  try {
    if (answers.length > 0) {
      const data = {
        attempt: attemptNo,
        validation_token: submission.validation_token,
        quiz_questions: answers,
        submissionId: submission.id,
        questions_response_not_expected: true,
      }
      yield call(saveQuizAnswersAPI, data)
    }
    yield put(actions.saveQuizAnswersSuccess({ answers }, action.meta))
  } catch (e) {
    // Check for network error and then retry after sometime
    if (isNetworkError(e.toString())) {
      yield put(showOfflinePopup())
      yield delay(10000)
      yield put(actions.saveQuizAnswers(action.payload, action.meta))
      return
    }

    if (
      e instanceof CustomError &&
      e.error.message !== 'quiz submission is already complete' &&
      e.error.message !== '401'
    ) {
      if (e.error.message === 'quiz is locked') {
        yield put(
          fetchModuleItem(
            { include: ['content_details'], ...action.meta },
            action.meta
          )
        )
      }
    }
    if (e instanceof CustomError || action.payload.onQuizComplete) {
      yield put(quizSubmissionActions.fetchQuizSubmissions(null, action.meta))
      yield put(stopProctoring())
    }

    // Display alert message and raise failed action with error
    const error_message = e instanceof CustomError ? e.message : e.toString()
    const error_stack = e instanceof CustomError ? e.error.stack : e.stack

    yield put(
      showAlertMessage({
        variant: 'error',
        message: capitalizeFirstLetter(error_message),
      })
    )

    yield put(
      actions.saveQuizAnswersFailure(
        e instanceof CustomError ? e.error : e,
        action.meta,
        {
          answers,
          attemptNo,
          validation_token: submission.validation_token,
          submissionId: submission.id,
          onQuizComplete: action.payload.onQuizComplete,
          error_stack,
        }
      )
    )
  }
}

function* uploadFileHandler(
  action: ReturnType<typeof actions.quizQuestionFileUpload>
) {
  let fileUploadId: number | undefined

  if (action.payload.files.length) {
    yield put(batchUploadFile(action.payload))
    yield take(fileTypes.BATCH_UPLOAD_FILE_SUCCESS)
    const filesState: FilesState = yield select(state => state.files)

    const fileContent = filesState.byContent[action.payload.dataKey]
    const fileIdArray: FileID[] | undefined = fileContent
      ? fileContent.data
      : undefined

    if (fileIdArray && fileIdArray[0]) {
      const file = filesState.data.byId[fileIdArray[0]]
      if (file && file.upload_id) fileUploadId = file.upload_id
    }
  }
  yield put(
    actions.saveQuizAnswers(
      {
        answer: fileUploadId,
        onQuizComplete: false,
      },
      action.meta
    )
  )
}

function* flagQuestionHandler(
  action:
    | ReturnType<typeof actions.flagQuizQuestion>
    | ReturnType<typeof actions.unFlagQuizQuestion>
) {
  try {
    const activeSubmission: QuizSubmissionData | null = yield select(
      quizSubmissionSelectors.getActiveQuizSubmission(action.meta.itemId)
    )
    if (!activeSubmission) {
      return
    }
    yield call(flagQuizQuestionAPI, {
      query: {
        attempt: action.meta.attemptNo,
        validation_token: activeSubmission.validation_token,
      },
      questionId: action.meta.questionId,
      flag: action.type === types.QUIZ_QUESTION_FLAG,
      submissionId: activeSubmission.id,
    })
  } catch (e) {
    console.log("Unable to update question's flag status")
  }
}

function* getFileDetailsHandler(
  action: ReturnType<typeof actions.getFileDetails>
) {
  try {
    const data = yield call(getFileDetailsAPI, {
      fileId: action.payload.fileId,
    })

    if (data) {
      yield put(actions.getFileDetailsSuccess(data, action.meta))
      if (data.url && data.display_name) {
        downloadFile({ url: data.url, displayName: data.display_name })
      }
    }
  } catch (e) {
    yield put(actions.getFileDetailsFailure(e, action.meta))
    yield put(
      showAlertMessage({
        variant: 'error',
        message:
          e instanceof CustomError
            ? e.message.split(';').join('<br/>')
            : 'Unable To Fetch File Details ...',
        closeOnTimeout: false,
      })
    )
  }
}

export function* quizQAMiddleware() {
  yield takeEvery(
    types.QUIZ_SUBMISSION_ANSWERS_SAVE,
    cancelable(saveQuizAnswersHandler, [types.QUIZ_SUBMISSION_ANSWERS_SAVE])
  )
  yield takeEvery(
    types.QUIZ_SUBMISSION_QA_FETCH,
    getQuizQuestionsAndAnswersHandler
  )
  yield takeEvery(
    [types.QUIZ_QUESTION_FLAG, types.QUIZ_QUESTION_UNFLAG],
    flagQuestionHandler
  )
  yield takeEvery(types.QUIZ_QUESTION_FILE_UPLOAD, uploadFileHandler)
  yield takeEvery(types.GET_FILE_DETAILS, getFileDetailsHandler)
}

export default ([] as any).concat(quizQAMiddleware())
