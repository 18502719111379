export const FETCH_ACTIVITY_CONTENT = 'FETCH_ACTIVITY_CONTENT'
export const FETCH_ACTIVITY_CONTENT_SUCCESS = 'FETCH_ACTIVITY_CONTENT_SUCCESS'
export const FETCH_ACTIVITY_CONTENT_FAILURE = 'FETCH_ACTIVITY_CONTENT_FAILURE'
export const FETCH_ACTIVITY_CONTENT_CANCEL = 'FETCH_ACTIVITY_CONTENT_CANCEL'

export const FETCH_RESIDENCY_CONTENT = 'FETCH_RESIDENCY_CONTENT'
export const FETCH_RESIDENCY_CONTENT_SUCCESS = 'FETCH_RESIDENCY_CONTENT_SUCCESS'
export const FETCH_RESIDENCY_CONTENT_FAILURE = 'FETCH_RESIDENCY_CONTENT_FAILURE'
export const FETCH_RESIDENCY_CONTENT_CANCEL = 'FETCH_RESIDENCY_CONTENT_CANCEL'

export const UPDATE_CURRENT_ACTIVE_TAB = 'UPDATE_CURRENT_ACTIVE_TAB'
