import AgoraRTC, { IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng'
import {
  setMicStateTo,
  updateMicrophoneAndSpeaker,
  updateUserInterface,
} from 'web/providers/Zoomout/Internal/Internal.actions'
import { CALL_STAGES } from 'web/providers/Zoomout/Internal/Internal.reducer'
import store from 'web/store'
import { HARD_AGORA_HOST_LIMIT, getZoomoutEventId } from 'common/utils/zoomout'
import { mixpanel } from 'common/utils/mixpanel'
import { showAlertMessage } from 'web/providers/AlertsProvider'
import zoomoutSettings, { ZoomoutDefaultsType } from '../settings'
import pushZoomoutEvent from '../pushZoomoutEvent'
import { initializeMicGlobalEventHandlers } from '../rtc/eventHandlers'
import { getMicrophones, getSpeakers } from './getDevices'

export interface IMicControlParams {
  micTrack: IMicrophoneAudioTrack
  isInCall: boolean
  isCameraPublished: boolean
}

export const createMicrophoneTrack = async (
  config: ZoomoutDefaultsType['microphone']
) => {
  const microphone = await AgoraRTC.createMicrophoneAudioTrack(config)
  if (!microphone) return null

  await microphone.setEnabled(true)
  initializeMicGlobalEventHandlers(microphone)
  return {
    track: microphone,
    isEnabled: config.isEnabled,
    encoderConfig: config.encoderConfig,
  }
}

export const StartMic = async (params: IMicControlParams) => {
  try {
    if (!window.zoomout) throw new Error('Zoomout is not initialized')
    if (
      window.zoomout.mainRtcClient.remoteUsers.length > HARD_AGORA_HOST_LIMIT
    ) {
      const message = `Your mic can't be enabled right now due to large size of the call.`
      store.dispatch(
        showAlertMessage({
          variant: 'error',
          message,
        })
      )
      throw new Error(message)
    }

    const { micTrack, isInCall } = params
    if (zoomoutSettings.config.mode === 'live') {
      await window.zoomout.mainRtcClient.setClientRole('host')
    }

    await micTrack.setEnabled(true)

    if (
      isInCall &&
      !window.zoomout.mainRtcClient.localTracks.includes(micTrack)
    ) {
      await window.zoomout.mainRtcClient.publish(micTrack)
    }

    pushZoomoutEvent({ eventID: getZoomoutEventId('UNMUTE_MIC') })
    store.dispatch(setMicStateTo(true))
  } catch (err) {
    const error = err as any

    mixpanel.track('Zoomout Errors', {
      feature: 'Start Mic',
      code: error.code,
      message: error.message,
      params,
    })
  }
}

export const StopMic = async (
  params: Omit<IMicControlParams, 'isInCall'>
): Promise<void> => {
  try {
    if (!window.zoomout) throw new Error('Zoomout not initialized')

    const { isCameraPublished, micTrack } = params
    await micTrack.setEnabled(false)

    // just in case micTrack is still not unpublished
    if (window.zoomout.mainRtcClient.localTracks.includes(micTrack)) {
      await window.zoomout.mainRtcClient.unpublish(micTrack)
    }

    // before switching to audience mode, ensure that the user is not publishing the camera
    if (zoomoutSettings.config.mode === 'live' && !isCameraPublished) {
      await window.zoomout.mainRtcClient.setClientRole(
        'audience',
        zoomoutSettings.config.audienceRoleOptions
      )
    }

    pushZoomoutEvent({ eventID: getZoomoutEventId('MUTE_MIC') })
    store.dispatch(setMicStateTo(false))
  } catch (err) {
    const error = err as any

    mixpanel.track('Zoomout Errors', {
      feature: 'Stop Mic',
      code: error.code,
      message: error.message,
      params,
    })
  }
}

export const initializeMicTrack = async () => {
  try {
    const microphoneDevices = await getMicrophones(false)

    if (!microphoneDevices.default) throw new Error()

    const micTrack = await createMicrophoneTrack({
      ...zoomoutSettings.microphone,
      microphoneId: microphoneDevices.default.deviceId,
    })

    if (!micTrack) throw new Error('Permission denied / not found')

    const speakerDevices = await getSpeakers(false)
    store.dispatch(
      updateMicrophoneAndSpeaker({
        track: micTrack,
        devices: {
          currentMic: microphoneDevices.default,
          allMics: microphoneDevices.all,
          currentSpeaker: speakerDevices.default,
          allSpeakers: speakerDevices.all,
        },
      })
    )
    return micTrack
  } catch (err) {
    store.dispatch(updateUserInterface({ modal: 'micPermission' }))
    throw new Error('Permission denied / mic not found')
  }
}

const toggleMicrophone = async (): Promise<boolean> => {
  const state = store.getState().zoomout.internal
  let { microphone } = state.localTracksDetails
  const isCameraPublished =
    (state.localTracksDetails.camera || {}).isEnabled || false

  if (!microphone) {
    microphone = await initializeMicTrack()
  }

  const params = {
    micTrack: microphone.track,
    isCameraPublished,
    isInCall:
      state.callStage === CALL_STAGES.LIVE_IN_CALL ||
      state.callStage === CALL_STAGES.RECONNECTING_IN_CALL,
  }

  let stateAfterToggle: boolean

  if (microphone.isEnabled) {
    await StopMic(params)
    stateAfterToggle = false
  } else {
    await StartMic(params)
    stateAfterToggle = true
  }

  return stateAfterToggle
}

export default toggleMicrophone
