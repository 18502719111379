import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import cx from 'classnames'
import styles from './HeaderBase.module.css'

interface HeaderBaseProps {
  className?: string
  children: React.ReactNode
}

const HeaderBase = (props: HeaderBaseProps) => {
  return (
    <AppBar
      elevation={0}
      className={cx(styles.container, props.className)}
      data-testid="header"
      color="inherit"
    >
      {props.children}
    </AppBar>
  )
}

export default HeaderBase
