import {
  SUBMIT_ASSIGNMENT,
  SUBMIT_ASSIGNMENT_FAILURE,
  SUBMIT_ASSIGNMENT_SUCCESS,
  SUBMIT_ASSIGNMENT_CANCEL,
  GET_ASSIGNMENT_SUBMISSION,
  GET_ASSIGNMENT_SUBMISSION_SUCCESS,
  GET_ASSIGNMENT_SUBMISSION_FAILURE,
  GET_ASSIGNMENT_SUBMISSION_CANCEL,
  GET_SUBMITTED_USER_DETAILS,
  GET_SUBMITTED_USER_DETAILS_FAILURE,
  GET_SUBMITTED_USER_DETAILS_SUCCESS,
  GET_SUBMITTED_USER_DETAILS_CANCEL,
  GET_EXTERNAL_TOOL_URL,
  GET_EXTERNAL_TOOL_URL_SUCCESS,
  GET_EXTERNAL_TOOL_URL_FAILURE,
  GET_ASSIGNMENT_META_DATA,
  GET_ASSIGNMENT_META_DATA_SUCCESS,
  GET_ASSIGNMENT_META_DATA_FAILURE,
  GET_ASSIGNMENT_AI_MENTOR_USER_DATA,
  GET_ASSIGNMENT_AI_MENTOR_USER_DATA_SUCCESS,
  GET_ASSIGNMENT_AI_MENTOR_USER_DATA_FAILURE,
} from './AssignmentSubmissions.types'
import { FileInfo } from '../../../../FilesProvider'
import { ItemID } from '../../../../../../common/types/courses/moduleItem'
import { ContentID } from '../../../../../../common/types/courses/content'
import { CourseID } from '../../../../../../common/types/courses'
import { UserID } from '../../../../../../common/types/user'
import { AssignmentSubmissionTypes } from '../../../../../../common/types/courses/assignment'

interface AssignmentActivityMetaParams {
  itemId: ItemID
}

export interface SubmitAssignmentParams {
  itemId: ItemID
  filesList: FileInfo[]
  websiteUrl: string
  contentId: ContentID
  courseId: CourseID
  submissionType: AssignmentSubmissionTypes
  textEntry: string
  comments: string
  url: string
  dataKey: string
}

export interface GetAssignmentSubmissionParams {
  courseId: CourseID
  contentId: ContentID
  itemId: ItemID
}

export interface IsExternalSubmissionParams {
  itemId: ItemID
  courseId: CourseID
  contentId: ContentID
}

export interface SubmittedUserDetailsParams {
  userId: UserID
}

export const submitAssignment = (
  payload: SubmitAssignmentParams,
  meta: AssignmentActivityMetaParams
) => ({
  type: SUBMIT_ASSIGNMENT as typeof SUBMIT_ASSIGNMENT,
  payload,
  meta,
})

export const submitAssignmentFailure = (
  payload: Error,
  meta: AssignmentActivityMetaParams
) => ({
  type: SUBMIT_ASSIGNMENT_FAILURE as typeof SUBMIT_ASSIGNMENT_FAILURE,
  payload,
  meta,
})

export const submitAssignmentSuccess = (
  payload: any,
  meta: AssignmentActivityMetaParams
) => ({
  type: SUBMIT_ASSIGNMENT_SUCCESS as typeof SUBMIT_ASSIGNMENT_SUCCESS,
  payload,
  meta,
})

export const submitAssignmentCancel = (
  payload: any,
  meta: AssignmentActivityMetaParams
) => ({
  type: SUBMIT_ASSIGNMENT_CANCEL as typeof SUBMIT_ASSIGNMENT_CANCEL,
  payload,
  meta,
})

export const getAssignmentSubmission = (
  payload: GetAssignmentSubmissionParams,
  meta: AssignmentActivityMetaParams
) => ({
  type: GET_ASSIGNMENT_SUBMISSION as typeof GET_ASSIGNMENT_SUBMISSION,
  payload,
  meta,
})

export const getAssignmentSubmissionFailure = (
  payload: Error,
  meta: AssignmentActivityMetaParams
) => ({
  type: GET_ASSIGNMENT_SUBMISSION_FAILURE as typeof GET_ASSIGNMENT_SUBMISSION_FAILURE,
  payload,
  meta,
})

export const getAssignmentSubmissionSuccess = (
  payload: any,
  meta: AssignmentActivityMetaParams
) => ({
  type: GET_ASSIGNMENT_SUBMISSION_SUCCESS as typeof GET_ASSIGNMENT_SUBMISSION_SUCCESS,
  payload,
  meta,
})

export const getAssignmentSubmissionCancel = (
  payload: any,
  meta: AssignmentActivityMetaParams
) => ({
  type: GET_ASSIGNMENT_SUBMISSION_CANCEL as typeof GET_ASSIGNMENT_SUBMISSION_CANCEL,
  payload,
  meta,
})

export const getSubmittedUserDetails = (
  payload: SubmittedUserDetailsParams,
  meta: AssignmentActivityMetaParams
) => ({
  type: GET_SUBMITTED_USER_DETAILS as typeof GET_SUBMITTED_USER_DETAILS,
  payload,
  meta,
})

export const getSubmittedUserDetailsFailure = (
  payload: Error,
  meta: AssignmentActivityMetaParams
) => ({
  type: GET_SUBMITTED_USER_DETAILS_FAILURE as typeof GET_SUBMITTED_USER_DETAILS_FAILURE,
  payload,
  meta,
})

export const getSubmittedUserDetailsSuccess = (
  payload: any,
  meta: AssignmentActivityMetaParams
) => ({
  type: GET_SUBMITTED_USER_DETAILS_SUCCESS as typeof GET_SUBMITTED_USER_DETAILS_SUCCESS,
  payload,
  meta,
})

export const getSubmittedUserDetailsCancel = (
  payload: any,
  meta: AssignmentActivityMetaParams
) => ({
  type: GET_SUBMITTED_USER_DETAILS_CANCEL as typeof GET_SUBMITTED_USER_DETAILS_CANCEL,
  payload,
  meta,
})

export const getExternalToolUrl = (
  payload: { url: string },
  meta: AssignmentActivityMetaParams
) => ({
  type: GET_EXTERNAL_TOOL_URL as typeof GET_EXTERNAL_TOOL_URL,
  payload,
  meta,
})
export const getExternalToolUrlSuccess = (
  payload: any,
  meta: AssignmentActivityMetaParams
) => ({
  type: GET_EXTERNAL_TOOL_URL_SUCCESS as typeof GET_EXTERNAL_TOOL_URL_SUCCESS,
  payload,
  meta,
})
export const getExternalToolUrlFailure = (
  payload: Error,
  meta: AssignmentActivityMetaParams
) => ({
  type: GET_EXTERNAL_TOOL_URL_FAILURE as typeof GET_EXTERNAL_TOOL_URL_FAILURE,
  payload,
  meta,
})

export const getAssignmentMetaData = (meta: IsExternalSubmissionParams) => ({
  type: GET_ASSIGNMENT_META_DATA as typeof GET_ASSIGNMENT_META_DATA,
  meta,
})

export const getAssignmentMetaDataSuccess = (
  payload: any,
  meta: IsExternalSubmissionParams
) => ({
  type: GET_ASSIGNMENT_META_DATA_SUCCESS as typeof GET_ASSIGNMENT_META_DATA_SUCCESS,
  payload,
  meta,
})

export const getAssignmentMetaDataFailure = (
  payload: Error,
  meta: IsExternalSubmissionParams
) => ({
  type: GET_ASSIGNMENT_META_DATA_FAILURE as typeof GET_ASSIGNMENT_META_DATA_FAILURE,
  payload,
  meta,
})

export const getAssignmentAiMentorUserData = (
  meta: IsExternalSubmissionParams
) => ({
  type: GET_ASSIGNMENT_AI_MENTOR_USER_DATA as typeof GET_ASSIGNMENT_AI_MENTOR_USER_DATA,
  meta,
})

export const getAssignmentAiMentorUserDataSuccess = (
  payload: any,
  meta: IsExternalSubmissionParams
) => {
  return {
    type: GET_ASSIGNMENT_AI_MENTOR_USER_DATA_SUCCESS as typeof GET_ASSIGNMENT_AI_MENTOR_USER_DATA_SUCCESS,
    payload,
    meta,
  }
}

export const getAssignmentAiMentorUserDataFailure = (
  payload: Error,
  meta: IsExternalSubmissionParams
) => ({
  type: GET_ASSIGNMENT_AI_MENTOR_USER_DATA_FAILURE as typeof GET_ASSIGNMENT_AI_MENTOR_USER_DATA_FAILURE,
  payload,
  meta,
})

export type AssignmentSubmissionActionTypes =
  | ReturnType<typeof submitAssignment>
  | ReturnType<typeof submitAssignmentSuccess>
  | ReturnType<typeof submitAssignmentFailure>
  | ReturnType<typeof submitAssignmentCancel>
  | ReturnType<typeof getAssignmentSubmission>
  | ReturnType<typeof getAssignmentSubmissionSuccess>
  | ReturnType<typeof getAssignmentSubmissionFailure>
  | ReturnType<typeof getAssignmentSubmissionCancel>
  | ReturnType<typeof getSubmittedUserDetails>
  | ReturnType<typeof getSubmittedUserDetailsSuccess>
  | ReturnType<typeof getSubmittedUserDetailsFailure>
  | ReturnType<typeof getSubmittedUserDetailsCancel>
  | ReturnType<typeof getExternalToolUrl>
  | ReturnType<typeof getExternalToolUrlSuccess>
  | ReturnType<typeof getExternalToolUrlFailure>
  | ReturnType<typeof getAssignmentMetaData>
  | ReturnType<typeof getAssignmentMetaDataSuccess>
  | ReturnType<typeof getAssignmentMetaDataFailure>
  | ReturnType<typeof getAssignmentAiMentorUserData>
  | ReturnType<typeof getAssignmentAiMentorUserDataSuccess>
  | ReturnType<typeof getAssignmentAiMentorUserDataFailure>
