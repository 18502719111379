import React from 'react'
import Button from '../Utils/Button'
import styles from './MasqueradeBar.module.css'

interface Props {
  user?: {
    imageUrl?: string | null
    name?: string | null
  }
  loading: boolean
  onStopMasquerading: () => void
}

const MasqueradeBar = (props: Props) => {
  return (
    <div className={styles.container}>
      {props.user && (
        <div className={styles.userDataContainer}>
          {props.user.name && (
            <div>
              Masquerading as <b>{props.user.name}</b>
            </div>
          )}
        </div>
      )}
      <div className={styles.stopMasqueradeButtonContainer}>
        <Button
          variant="contained"
          color="secondary"
          rounded={false}
          size="small"
          className={styles.stopMasqueradeButton}
          disabled={props.loading}
          onClick={props.onStopMasquerading}
        >
          {props.loading ? `Please wait...` : `Stop Masquerading`}
        </Button>
      </div>
    </div>
  )
}

export default MasqueradeBar
