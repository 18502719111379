import { CourseID } from 'common/types/courses'
import {
  GET_CURRICULUM_AI_MENTOR_USER_DATA,
  GET_CURRICULUM_AI_MENTOR_USER_DATA_FAILURE,
  GET_CURRICULUM_AI_MENTOR_USER_DATA_SUCCESS,
} from './CurriculumAiMentor.types'

export interface GetCurriculumAiMentorUserDataParams {
  batchId: string | number
  courseId?: CourseID
}

export const getCurriculumAiMentorUserData = (
  meta: GetCurriculumAiMentorUserDataParams
) => {
  return {
    type: GET_CURRICULUM_AI_MENTOR_USER_DATA as typeof GET_CURRICULUM_AI_MENTOR_USER_DATA,
    meta,
  }
}

export const getCurriculumAiMentorUserDataSuccess = (
  payload: any,
  meta: GetCurriculumAiMentorUserDataParams
) => {
  return {
    type: GET_CURRICULUM_AI_MENTOR_USER_DATA_SUCCESS as typeof GET_CURRICULUM_AI_MENTOR_USER_DATA_SUCCESS,
    payload,
    meta,
  }
}

export const getCurriculumAiMentorUserDataFailure = (
  payload: Error,
  meta: GetCurriculumAiMentorUserDataParams
) => {
  return {
    type: GET_CURRICULUM_AI_MENTOR_USER_DATA_FAILURE as typeof GET_CURRICULUM_AI_MENTOR_USER_DATA_FAILURE,
    payload,
    meta,
  }
}

export type CurriculumAiMentorUserDataAction = ReturnType<
  | typeof getCurriculumAiMentorUserData
  | typeof getCurriculumAiMentorUserDataSuccess
  | typeof getCurriculumAiMentorUserDataFailure
>
