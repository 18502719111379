import { call, cancelled, put, select, takeLeading } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import { AppState } from 'web/store'
import {
  fetchLeaderboardContent,
  fetchLeaderboardContentFailure,
  fetchLeaderboardContentSuccess,
} from '.'
import {
  FETCH_LEADERBOARD_CONTENT,
  FETCH_LEADERBOARD_CONTENT_CANCEL,
} from './Leaderboard.types'
import { UserID } from '../../../../common/types/user'
import { getFslLeaderboardContentAPI } from './Leaderboard.api'

function* fetchLeaderboardContentHandler(
  action: ReturnType<typeof fetchLeaderboardContent>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('userId unavilable')
    }
    const data = yield call(
      getFslLeaderboardContentAPI,
      action,
      userId,
      abortController.signal
    )
    yield put(fetchLeaderboardContentSuccess(data))
  } catch (e) {
    yield put(fetchLeaderboardContentFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fslLeaderboardMiddleware() {
  yield takeLeading(
    FETCH_LEADERBOARD_CONTENT,
    cancelable(fetchLeaderboardContentHandler, FETCH_LEADERBOARD_CONTENT_CANCEL)
  )
}

export default ([] as any).concat(fslLeaderboardMiddleware())
