import { call, cancelled, put, takeEvery } from 'redux-saga/effects'
import { cancelable } from '../../../../../common/utils'

import {
  getOutcomeDetails,
  getOutcomeDetailsSuccess,
  getOutcomeDetailsFailure,
} from './Outcomes.actions'

import {
  GET_OUTCOME_DETAILS,
  GET_OUTCOME_DETAILS_CANCEL,
} from './Outcomes.types'

import { getOutcomeDetailsAPI } from './Outcomes.api'

function* getOutcomeDetailsHandler(
  action: ReturnType<typeof getOutcomeDetails>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      getOutcomeDetailsAPI,
      action,
      abortController.signal
    )
    yield put(getOutcomeDetailsSuccess(data, action.meta))
  } catch (e) {
    yield put(getOutcomeDetailsFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* getOuctomeDetailsMiddleware() {
  yield takeEvery(
    GET_OUTCOME_DETAILS,
    cancelable(getOutcomeDetailsHandler, [GET_OUTCOME_DETAILS_CANCEL])
  )
}

export default ([] as any).concat(getOuctomeDetailsMiddleware())
