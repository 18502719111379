import {
  call,
  cancelled,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { programSelectors } from 'web/providers/Dashboard/ProgramsProvider'
import { UserDetailsState, userDetailsSelectors } from '.'
import { AppState } from '../../../store'
import { showAlertMessage } from '../../AlertsProvider'
import { updateUserOnboardingPage } from '../UserOnboardingProvider'
import {
  fetchCommunicationChannelDetails,
  fetchCommunicationChannelDetailsFailure,
  fetchCommunicationChannelDetailsSuccess,
  fetchUserProfileDetailsFailure,
  fetchUserProfileDetailsSuccess,
  logoutUserFailure,
  logoutUserSuccess,
  resendEmailLinkFailure,
  resendEmailLinkSuccess,
  updateEmailVerfiedStatus,
  sendEngagementEvent,
} from './UserDetails.actions'
import * as APIs from './UserDetails.api'
import * as types from './UserDetails.types'

function* getUserProfileDetails(action) {
  const abortController = new AbortController()
  try {
    const userId: UserDetailsState['id'] = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data = yield call(APIs.getUserProfileDetailsAPI, {
      userId,
      signal: abortController.signal,
      include_feature_detail: action?.payload?.include_feature_detail,
      include_user_ui_pref: true,
    })
    yield put(fetchUserProfileDetailsSuccess(data))
    const profileDetails: UserDetailsState['profile'] = yield select(
      (state: AppState) => state.user.details.profile
    )
    const isDigitalCampusUser = yield select((state: AppState) =>
      programSelectors.digitalCampusUser()(state)
    )
    if (profileDetails && profileDetails.data) {
      if (!profileDetails.data.emailVerified)
        yield put(fetchCommunicationChannelDetails())

      if (
        isDigitalCampusUser &&
        (!profileDetails.data.phone || !profileDetails.data.time_zone)
      ) {
        yield put(updateUserOnboardingPage({ page: 'profile' }))
      }
    }
  } catch (e) {
    yield put(fetchUserProfileDetailsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* getCommunicationChannelDetails() {
  const abortController = new AbortController()
  try {
    const userId: UserDetailsState['id'] = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data = yield call(APIs.getCommunicationChannelDetailsAPI, {
      userId,
      signal: abortController.signal,
    })
    yield put(fetchCommunicationChannelDetailsSuccess(data))
    yield put(updateEmailVerfiedStatus())
  } catch (e) {
    yield put(fetchCommunicationChannelDetailsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* resendEmailLink() {
  const abortController = new AbortController()
  try {
    const userId: UserDetailsState['id'] = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data = yield call(APIs.resendEmailLinkAPI, {
      userId,
      signal: abortController.signal,
    })
    yield put(resendEmailLinkSuccess(data))
    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'Resent link successfully',
      })
    )
  } catch (e) {
    yield put(resendEmailLinkFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Resent link failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* userLogoutHandler() {
  try {
    const userId: UserDetailsState['id'] = yield select(
      userDetailsSelectors.getUserID()
    )

    /* call userLTILogout API first as if the LMS userLogout api gets executed first and LTI cache does not
     have the access token entry the LTI logout api gives 401 */
    yield call(APIs.userLTILogout, { userId })
    yield call(APIs.userLogout)
    yield put(logoutUserSuccess())
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = `${window.constants.REACT_APP_LTI_URL}user_auth/logout_user_from_website`
  } catch (e) {
    yield put(logoutUserFailure(e))
    // yield put(
    //   showAlertMessage({ variant: 'error', message: 'Unable to logout' })
    // )
    window.location.href = `${window.constants.REACT_APP_LMS_URL}login`
  }
}

function* sendPreBatchEngagementEvent(
  action: ReturnType<typeof sendEngagementEvent>
) {
  try {
    yield call(APIs.sendPreEngagementEvent, action.payload)
  } catch (e) {}
}

function* userDetailsMiddleware() {
  yield takeLeading(types.USER_LOGOUT, userLogoutHandler)
  yield takeLatest(types.RESEND_EMAIL_LINK, resendEmailLink)
  yield takeLatest(
    types.FETCH_COMMUNICATION_CHANNEL_DETAILS,
    getCommunicationChannelDetails
  )

  yield takeLatest(types.USER_PROFILE_DETAILS_FETCH, getUserProfileDetails)
  yield takeLatest(
    types.UPDATE_PREBATCH_ENGAGEMENT_SCORE,
    sendPreBatchEngagementEvent
  )
}

export default ([] as any).concat(userDetailsMiddleware())
