import * as types from './ReferralsAndRewards.types'
import { RewardID } from '../../../common/types/referrralAndRewards'

export interface claimRewardPayload {
  rewardId: RewardID
}

export const fetchReferralsAndRewards = () => ({
  type: types.FETCH_REFERRALS_REWARDS_DETAILS as typeof types.FETCH_REFERRALS_REWARDS_DETAILS,
})

export const fetchReferralsAndRewardsFailure = (payload: Error) => ({
  type: types.FETCH_REFERRALS_REWARDS_DETAILS_FAILURE as typeof types.FETCH_REFERRALS_REWARDS_DETAILS_FAILURE,
  payload,
})

export const fetchReferralsAndRewardsSuccess = (payload: any) => {
  return {
    type: types.FETCH_REFERRALS_REWARDS_DETAILS_SUCCESS as typeof types.FETCH_REFERRALS_REWARDS_DETAILS_SUCCESS,
    payload,
  }
}

export const fetchReferralsAndRewardsCancel = () => ({
  type: types.FETCH_REFERRALS_REWARDS_DETAILS_CANCEL as typeof types.FETCH_REFERRALS_REWARDS_DETAILS_CANCEL,
})

export const claimReward = (payload: claimRewardPayload) => ({
  type: types.CLAIM_REWARD as typeof types.CLAIM_REWARD,
  payload,
})
export const claimRewardSuccess = (payload: any) => ({
  type: types.CLAIM_REWARD_SUCCESS as typeof types.CLAIM_REWARD_SUCCESS,
  payload,
})
export const claimRewardFailure = (payload: Error) => ({
  type: types.CLAIM_REWARD_FAILURE as typeof types.CLAIM_REWARD_FAILURE,
  payload,
})

export const claimRewardCancel = () => ({
  type: types.CLAIM_REWARD_CANCEL as typeof types.CLAIM_REWARD_CANCEL,
})
