import { QuestionArgs, QuestionStdin } from 'common/types/codingPlatform'
import { ItemID } from 'common/types/courses/moduleItem'
import { CodingPlaygroundsState } from './CodingPlatform.reducer'
import {
  UPDATE_QUESTION_ARGS,
  UPDATE_QUESTION_STDIN,
  LIST_PLAYGROUNDS,
  LIST_PLAYGROUNDS_SUCCESS,
  LIST_PLAYGROUNDS_CANCEL,
  LIST_PLAYGROUNDS_FAILURE,
  CREATE_PLAYGROUND,
  CREATE_PLAYGROUND_CANCEL,
  CREATE_PLAYGROUND_FAILURE,
  CREATE_PLAYGROUND_SUCCESS,
  DELETE_PLAYGROUND,
  DELETE_PLAYGROUND_CANCEL,
  DELETE_PLAYGROUND_FAILURE,
  DELETE_PLAYGROUND_SUCCESS,
  GET_PLAYGROUND,
  GET_PLAYGROUND_CANCEL,
  GET_PLAYGROUND_FAILURE,
  GET_PLAYGROUND_SUCCESS,
  UPDATE_PLAYGROUND,
  UPDATE_PLAYGROUND_CANCEL,
  UPDATE_PLAYGROUND_FAILURE,
  UPDATE_PLAYGROUND_SUCCESS,
  RENAME_PLAYGROUND,
  RENAME_PLAYGROUND_SUCCESS,
  RENAME_PLAYGROUND_FAILURE,
  RENAME_PLAYGROUND_CANCEL,
  UPDATE_PLAYGROUND_ARGS,
  UPDATE_PLAYGROUND_STDIN,
  QUESTION_RUN_SQL_CODE_SUCCESS,
  QUESTION_RUN_SQL_CODE,
  QUESTION_RUN_SQL_CODE_FAILURE,
  QUESTION_RUN_SQL_CODE_CANCEL,
  QUESTION_TEST_SQL_CODE_CANCEL,
  QUESTION_TEST_SQL_CODE,
  QUESTION_TEST_SQL_CODE_SUCCESS,
  QUESTION_TEST_SQL_CODE_FAILURE,
  SQL_QUESTION_GET_AI_CODE_SUGGESTIONS,
  SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_CANCEL,
  SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_FAILURE,
  SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_SUCCESS,
} from './CodingPlatform.types'
import {
  CODE_EXECUTE,
  CODE_EXECUTE_CANCEL,
  CODE_EXECUTE_FAILURE,
  CODE_EXECUTE_SUCCESS,
  RUNTIMES_FETCH,
  RUNTIMES_FETCH_CANCEL,
  RUNTIMES_FETCH_FAILURE,
  RUNTIMES_FETCH_SUCCESS,
  QUESTION_RUN_CODE,
  QUESTION_RUN_CODE_CANCEL,
  QUESTION_RUN_CODE_FAILURE,
  QUESTION_RUN_CODE_SUCCESS,
  QUESTION_TEST_CODE,
  QUESTION_TEST_CODE_CANCEL,
  QUESTION_TEST_CODE_FAILURE,
  QUESTION_TEST_CODE_SUCCESS,
  QUESTION_GET_AI_CODE_SUGGESTIONS,
  QUESTION_GET_AI_CODE_SUGGESTIONS_CANCEL,
  QUESTION_GET_AI_CODE_SUGGESTIONS_FAILURE,
  QUESTION_GET_AI_CODE_SUGGESTIONS_SUCCESS,
  QUESTION_GET_AI_CODE_HINTS,
  QUESTION_GET_AI_CODE_HINTS_CANCEL,
  QUESTION_GET_AI_CODE_HINTS_FAILURE,
  QUESTION_GET_AI_CODE_HINTS_SUCCESS,
} from './index'

export interface CodingPlatformRuntime {
  language: string
  version: string
  defaultEditorValue: string
  run_timeout: number
  run_memory_limit: number
}

type CodeExecutionFiles = {
  content: string
}
export interface CodeExecutionPayload {
  language: string
  args: (string | number)[]
  files: CodeExecutionFiles[]
  stdin: string
  job_id?: string
  job_name?: string
  job_position?: number
}

export interface QuestionTestCodePayload {
  code: string
  job_id?: string
  job_name?: string
  job_position?: number
}

export type CodeResponseStage = {
  stdout: string
  stderr: string
  code: number
  signal: string | null
  output: string
  reason?: string
  formattedOutput?: any[]
}

export type  PrivateCodeResponseRunStage = {
  code: number
  signal: string | null
}

type CodeResponseRunStage = CodeResponseStage
type CodeResponseBuildStage = CodeResponseStage
export interface CodeExecuteSuccess {
  run: CodeResponseRunStage
  compile?: CodeResponseBuildStage
  language: string
  version: string
  is_queued?: boolean
  jobPosition?: number
  data?: {
    jobId: string
    jobName: string
  }
}

export type QueryResponse = {
  query: string
  message: string
  response?: any[]
}

export interface QueryError {
  error_type: string
  query: string
  message: string
  error_phase?: string
}

export interface QueryResponseProps {
  error: QueryError | null
  query_response: QueryResponse[]
  total_queries?: number
}

export interface CodeExecuteSqlSuccess {
  error: QueryError | null
  query_response: QueryResponse[]
  is_queued?: boolean
  jobPosition?: number
  data?: {
    jobId: string
    jobName: string
  }
}

export type CodingLanguage = string

export type CodeEvalQuestionId = string

export type CodingPlaygroundId = string | number

export interface CodeEvalQuestionRunCodeParams {
  ceQuestionId: CodeEvalQuestionId
  quizId: ItemID
}

export interface CodeEvalQuestionTestCodeParams {
  ceQuestionId: CodeEvalQuestionId
  itemId: ItemID
  attemptNo: number
}

export interface CodeEvalQuestionGetAiCodeSuggestionsParams {
  ceQuestionId: CodeEvalQuestionId
  itemId: ItemID
  attemptNo: number
}

export interface CodeEvalQuestionGetAiCodeSuggestionsPayload {
  code: string
  showStaticHint?: boolean
  get_hint?: boolean
  job_id?: string
  job_name?: string
  job_position?: number
  questionLanguage?: string
}

export interface CodeEvalQuestionGetAiHintsParams {
  ceQuestionId: CodeEvalQuestionId
  itemId: ItemID
  attemptNo: number
}

export interface CodeEvalQuestionGetAiHintsPayload {
  code: string
  test_cases_execution_res: TestCodeResults
  job_id?: string
  job_name?: string
}

export type TestCaseStatus = 'passed' | 'failed' | 'cancelled'
export interface PublicTestCaseExecRes {
  run?: CodeResponseRunStage
  compile?: CodeResponseBuildStage
  status: TestCaseStatus
  test_case_name: string
  expected_output: string
  args?: string[]
  function_call?: string
  stdin?: string
  code_inject?: string
  reason?: string
}

export interface PrivateTestCaseExecRes {
  run?: PrivateCodeResponseRunStage
  status: TestCaseStatus
  weightage_percentage: number
  test_case_name: string
}

export interface PromptRubricsEvaluationResponse {
  evaluation: {
    overallScore: number
    evaluationFeedback: string
    hint: string
  }
  error: {
    message: string
    code: string
    details: string
  }
  run_output: string
}
export interface TestCodeResults {
  public_test_cases_execution_res: Record<string, PublicTestCaseExecRes>
  private_test_cases_execution_res: Record<string, PrivateTestCaseExecRes>
  content?: PromptRubricsEvaluationResponse
  is_queued?: boolean
  jobPosition?: number
  data?: {
    jobId: string
    jobName: string
  }
}

export interface QuestionGetAiCodeSuggestionsResponse {
  content: string
}

export interface QuestionGetAiHintsResponse {
  content: string
}

export type CodingPlatformRuntimes = {
  [key in CodingLanguage]: CodingPlatformRuntime
}

export const fetchRuntimes = () => ({
  type: RUNTIMES_FETCH as typeof RUNTIMES_FETCH,
})

export const fetchRuntimesSuccess = (payload: CodingPlatformRuntimes) => ({
  type: RUNTIMES_FETCH_SUCCESS as typeof RUNTIMES_FETCH_SUCCESS,
  payload,
})

export const fetchRuntimesFailure = (payload: Error) => ({
  type: RUNTIMES_FETCH_FAILURE as typeof RUNTIMES_FETCH_FAILURE,
  payload,
})

export const fetchRuntimesCancel = () => ({
  type: RUNTIMES_FETCH_CANCEL as typeof RUNTIMES_FETCH_CANCEL,
})

export const codeExecute = (
  meta: { playground_id: CodingPlaygroundId },
  payload: CodeExecutionPayload
) => ({
  type: CODE_EXECUTE as typeof CODE_EXECUTE,
  meta,
  payload,
})

export const codeExecuteSuccess = (
  meta: { playground_id: CodingPlaygroundId },
  payload: CodeExecuteSuccess
) => ({
  type: CODE_EXECUTE_SUCCESS as typeof CODE_EXECUTE_SUCCESS,
  meta,
  payload,
})

export const codeExecutionFailure = (
  meta: { playground_id: CodingPlaygroundId },
  payload: Error
) => ({
  type: CODE_EXECUTE_FAILURE as typeof CODE_EXECUTE_FAILURE,
  meta,
  payload,
})

export const codeExecutionCancel = () => ({
  type: CODE_EXECUTE_CANCEL as typeof CODE_EXECUTE_CANCEL,
})

export const questionRunCode = (
  meta: CodeEvalQuestionRunCodeParams,
  payload: CodeExecutionPayload
) => ({
  type: QUESTION_RUN_CODE as typeof QUESTION_RUN_CODE,
  meta,
  payload,
})

export const questionRunCodeSuccess = (
  meta: CodeEvalQuestionRunCodeParams,
  payload: CodeExecuteSuccess
) => ({
  type: QUESTION_RUN_CODE_SUCCESS as typeof QUESTION_RUN_CODE_SUCCESS,
  meta,
  payload,
})

export const questionRunCodeFailure = (
  meta: CodeEvalQuestionRunCodeParams,
  payload: Error
) => ({
  type: QUESTION_RUN_CODE_FAILURE as typeof QUESTION_RUN_CODE_FAILURE,
  meta,
  payload,
})

export const questionRunCodeCancel = () => ({
  type: QUESTION_RUN_CODE_CANCEL as typeof QUESTION_RUN_CODE_CANCEL,
})

export const questionTestCode = (
  meta: CodeEvalQuestionTestCodeParams,
  payload: QuestionTestCodePayload
) => ({
  type: QUESTION_TEST_CODE as typeof QUESTION_TEST_CODE,
  meta,
  payload,
})

export const questionTestCodeSuccess = (
  meta: CodeEvalQuestionTestCodeParams,
  payload: TestCodeResults
) => ({
  type: QUESTION_TEST_CODE_SUCCESS as typeof QUESTION_TEST_CODE_SUCCESS,
  meta,
  payload,
})

export const questionTestCodeFailure = (
  meta: CodeEvalQuestionTestCodeParams,
  payload: Error
) => ({
  type: QUESTION_TEST_CODE_FAILURE as typeof QUESTION_TEST_CODE_FAILURE,
  meta,
  payload,
})

export const questionRunSqlCode = (
  meta: CodeEvalQuestionRunCodeParams,
  payload: CodeExecutionPayload
) => ({
  type: QUESTION_RUN_SQL_CODE as typeof QUESTION_RUN_SQL_CODE,
  meta,
  payload,
})

export const questionRunSqlCodeSuccess = (
  meta: CodeEvalQuestionRunCodeParams,
  payload: CodeExecuteSqlSuccess
) => ({
  type: QUESTION_RUN_SQL_CODE_SUCCESS as typeof QUESTION_RUN_SQL_CODE_SUCCESS,
  meta,
  payload,
})

export const questionRunSqlCodeFailure = (
  meta: CodeEvalQuestionRunCodeParams,
  payload: Error
) => ({
  type: QUESTION_RUN_SQL_CODE_FAILURE as typeof QUESTION_RUN_SQL_CODE_FAILURE,
  meta,
  payload,
})

export const questionRunSQLCodeCancel = () => ({
  type: QUESTION_RUN_SQL_CODE_CANCEL as typeof QUESTION_RUN_SQL_CODE_CANCEL,
})

export const questiontestSqlCodeCancel = () => ({
  type: QUESTION_TEST_SQL_CODE_CANCEL as typeof QUESTION_TEST_SQL_CODE_CANCEL,
})

export const questiontestSqlCode = (
  meta: CodeEvalQuestionTestCodeParams,
  payload: QuestionTestCodePayload
) => ({
  type: QUESTION_TEST_SQL_CODE as typeof QUESTION_TEST_SQL_CODE,
  meta,
  payload,
})

export const questiontestSqlCodeSuccess = (
  meta: CodeEvalQuestionTestCodeParams,
  payload: CodeExecuteSqlSuccess
) => ({
  type: QUESTION_TEST_SQL_CODE_SUCCESS as typeof QUESTION_TEST_SQL_CODE_SUCCESS,
  meta,
  payload,
})

export const questiontestSqlCodeFailure = (
  meta: CodeEvalQuestionTestCodeParams,
  payload: Error
) => ({
  type: QUESTION_TEST_SQL_CODE_FAILURE as typeof QUESTION_TEST_SQL_CODE_FAILURE,
  meta,
  payload,
})

export const questionTestCodeCancel = () => ({
  type: QUESTION_TEST_CODE_CANCEL as typeof QUESTION_TEST_CODE_CANCEL,
})

export const updateQuestionStdin = (
  meta: { ceQuestionId: CodeEvalQuestionId },
  payload: { questionStdin: QuestionStdin }
) => ({
  type: UPDATE_QUESTION_STDIN as typeof UPDATE_QUESTION_STDIN,
  meta,
  payload,
})

export const updateQuestionArgs = (
  meta: { ceQuestionId: CodeEvalQuestionId },
  payload: { questionArgs: QuestionArgs }
) => ({
  type: UPDATE_QUESTION_ARGS as typeof UPDATE_QUESTION_ARGS,
  meta,
  payload,
})

export interface ListPlaygroundsMeta {
  language: string
}
export interface ListPlaygroundsPayload {}

export const listPlaygrounds = (
  meta: ListPlaygroundsMeta,
  payload: ListPlaygroundsPayload
) => ({
  type: LIST_PLAYGROUNDS as typeof LIST_PLAYGROUNDS,
  meta,
  payload,
})

export const listPlaygroundsSuccess = (
  meta: ListPlaygroundsMeta,
  payload: CodingPlaygroundsState
) => ({
  type: LIST_PLAYGROUNDS_SUCCESS as typeof LIST_PLAYGROUNDS_SUCCESS,
  meta,
  payload,
})

export const listPlaygroundsFailure = (
  meta: ListPlaygroundsMeta,
  payload: Error
) => ({
  type: LIST_PLAYGROUNDS_FAILURE as typeof LIST_PLAYGROUNDS_FAILURE,
  meta,
  payload,
})

export const listPlaygroundsCancel = () => ({
  type: LIST_PLAYGROUNDS_CANCEL as typeof LIST_PLAYGROUNDS_CANCEL,
})

export interface CreatePlaygroundMeta {
  language: string
  fileName: string
}
export interface CreatePlaygroundPayload {}

export const createPlayground = (
  meta: CreatePlaygroundMeta,
  payload: CreatePlaygroundPayload
) => ({
  type: CREATE_PLAYGROUND as typeof CREATE_PLAYGROUND,
  meta,
  payload,
})

export const createPlaygroundSuccess = (
  meta: CreatePlaygroundMeta,
  payload: any
) => ({
  type: CREATE_PLAYGROUND_SUCCESS as typeof CREATE_PLAYGROUND_SUCCESS,
  meta,
  payload,
})

export const createPlaygroundFailure = (
  meta: CreatePlaygroundMeta,
  payload: Error
) => ({
  type: CREATE_PLAYGROUND_FAILURE as typeof CREATE_PLAYGROUND_FAILURE,
  meta,
  payload,
})

export const createPlaygroundCancel = () => ({
  type: CREATE_PLAYGROUND_CANCEL as typeof CREATE_PLAYGROUND_CANCEL,
})

export interface GetPlaygroundMeta {
  playground_id: CodingPlaygroundId
}
export interface GetPlaygroundPayload {}

export const getPlayground = (
  meta: GetPlaygroundMeta,
  payload: GetPlaygroundPayload
) => ({
  type: GET_PLAYGROUND as typeof GET_PLAYGROUND,
  meta,
  payload,
})

export const getPlaygroundSuccess = (
  meta: GetPlaygroundMeta,
  payload: any
) => ({
  type: GET_PLAYGROUND_SUCCESS as typeof GET_PLAYGROUND_SUCCESS,
  meta,
  payload,
})

export const getPlaygroundFailure = (
  meta: GetPlaygroundMeta,
  payload: Error
) => ({
  type: GET_PLAYGROUND_FAILURE as typeof GET_PLAYGROUND_FAILURE,
  meta,
  payload,
})

export const getPlaygroundCancel = () => ({
  type: GET_PLAYGROUND_CANCEL as typeof GET_PLAYGROUND_CANCEL,
})

export interface UpdatePlaygroundMeta {
  playground_id: CodingPlaygroundId
  uploadToS3: boolean
  language: string
}
export interface UpdatePlaygroundPayload {
  data: string
}

export const updatePlayground = (
  meta: UpdatePlaygroundMeta,
  payload: UpdatePlaygroundPayload
) => ({
  type: UPDATE_PLAYGROUND as typeof UPDATE_PLAYGROUND,
  meta,
  payload,
})

export const updatePlaygroundSuccess = (
  meta: UpdatePlaygroundMeta,
  payload: UpdatePlaygroundPayload
) => ({
  type: UPDATE_PLAYGROUND_SUCCESS as typeof UPDATE_PLAYGROUND_SUCCESS,
  meta,
  payload,
})

export const updatePlaygroundFailure = (
  meta: UpdatePlaygroundMeta,
  payload: Error
) => ({
  type: UPDATE_PLAYGROUND_FAILURE as typeof UPDATE_PLAYGROUND_FAILURE,
  meta,
  payload,
})

export const updatePlaygroundCancel = () => ({
  type: UPDATE_PLAYGROUND_CANCEL as typeof UPDATE_PLAYGROUND_CANCEL,
})

export interface DeletePlaygroundMeta {
  playground_id: CodingPlaygroundId
  language: string
}
export interface DeletePlaygroundPayload {}

export const deletePlayground = (
  meta: DeletePlaygroundMeta,
  payload: DeletePlaygroundPayload
) => ({
  type: DELETE_PLAYGROUND as typeof DELETE_PLAYGROUND,
  meta,
  payload,
})

export const deletePlaygroundSuccess = (
  meta: DeletePlaygroundMeta,
  payload: any
) => ({
  type: DELETE_PLAYGROUND_SUCCESS as typeof DELETE_PLAYGROUND_SUCCESS,
  meta,
  payload,
})

export const deletePlaygroundFailure = (
  meta: DeletePlaygroundMeta,
  payload: Error
) => ({
  type: DELETE_PLAYGROUND_FAILURE as typeof DELETE_PLAYGROUND_FAILURE,
  meta,
  payload,
})

export const deletePlaygroundCancel = () => ({
  type: DELETE_PLAYGROUND_CANCEL as typeof DELETE_PLAYGROUND_CANCEL,
})

export interface RenamePlaygroundMeta {
  playground_id: CodingPlaygroundId
  language: string
}
export interface RenamePlaygroundPayload {
  new_file_name: string
}

export const renamePlayground = (
  meta: RenamePlaygroundMeta,
  payload: RenamePlaygroundPayload
) => ({
  type: RENAME_PLAYGROUND as typeof RENAME_PLAYGROUND,
  meta,
  payload,
})

export const renamePlaygroundSuccess = (
  meta: RenamePlaygroundMeta,
  payload: any
) => ({
  type: RENAME_PLAYGROUND_SUCCESS as typeof RENAME_PLAYGROUND_SUCCESS,
  meta,
  payload,
})

export const renamePlaygroundFailure = (
  meta: RenamePlaygroundMeta,
  payload: Error
) => ({
  type: RENAME_PLAYGROUND_FAILURE as typeof RENAME_PLAYGROUND_FAILURE,
  meta,
  payload,
})

export const renamePlaygroundCancel = () => ({
  type: RENAME_PLAYGROUND_CANCEL as typeof RENAME_PLAYGROUND_CANCEL,
})

export const updatePlaygroundStdin = (
  meta: { playground_id: CodingPlaygroundId },
  payload: { playgroundStdin: QuestionStdin }
) => ({
  type: UPDATE_PLAYGROUND_STDIN as typeof UPDATE_PLAYGROUND_STDIN,
  meta,
  payload,
})

export const updatePlaygroundArgs = (
  meta: { playground_id: CodingPlaygroundId },
  payload: { questionArgs: QuestionArgs }
) => ({
  type: UPDATE_PLAYGROUND_ARGS as typeof UPDATE_PLAYGROUND_ARGS,
  meta,
  payload,
})

export const questionGetAiCodeSuggestions = (
  meta: CodeEvalQuestionGetAiCodeSuggestionsParams,
  payload: CodeEvalQuestionGetAiCodeSuggestionsPayload
) => ({
  type: QUESTION_GET_AI_CODE_SUGGESTIONS as typeof QUESTION_GET_AI_CODE_SUGGESTIONS,
  meta,
  payload,
})

export const questionGetAiCodeSuggestionsSuccess = (
  meta: CodeEvalQuestionGetAiCodeSuggestionsParams,
  payload: QuestionGetAiCodeSuggestionsResponse
) => ({
  type: QUESTION_GET_AI_CODE_SUGGESTIONS_SUCCESS as typeof QUESTION_GET_AI_CODE_SUGGESTIONS_SUCCESS,
  meta,
  payload,
})

export const questionGetAiCodeSuggestionsFailure = (
  meta: CodeEvalQuestionGetAiCodeSuggestionsParams,
  payload: Error
) => ({
  type: QUESTION_GET_AI_CODE_SUGGESTIONS_FAILURE as typeof QUESTION_GET_AI_CODE_SUGGESTIONS_FAILURE,
  meta,
  payload,
})

export const questionGetAiCodeSuggestionsCancel = () => ({
  type: QUESTION_GET_AI_CODE_SUGGESTIONS_CANCEL as typeof QUESTION_GET_AI_CODE_SUGGESTIONS_CANCEL,
})

export const sqlQuestionGetAiCodeSuggestions = (
  meta: CodeEvalQuestionGetAiCodeSuggestionsParams,
  payload: CodeEvalQuestionGetAiCodeSuggestionsPayload
) => ({
  type: SQL_QUESTION_GET_AI_CODE_SUGGESTIONS as typeof SQL_QUESTION_GET_AI_CODE_SUGGESTIONS,
  meta,
  payload,
})

export const sqlQuestionGetAiCodeSuggestionsSuccess = (
  meta: CodeEvalQuestionGetAiCodeSuggestionsParams,
  payload: CodeExecuteSqlSuccess
) => ({
  type: SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_SUCCESS as typeof SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_SUCCESS,
  meta,
  payload,
})

export const sqlQuestionGetAiCodeSuggestionsFailure = (
  meta: CodeEvalQuestionGetAiCodeSuggestionsParams,
  payload: Error
) => ({
  type: SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_FAILURE as typeof SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_FAILURE,
  meta,
  payload,
})

export const sqlQuestionGetAiCodeSuggestionsCancel = () => ({
  type: SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_CANCEL as typeof SQL_QUESTION_GET_AI_CODE_SUGGESTIONS_CANCEL,
})

export const questionGetAiCodeHints = (
  meta: CodeEvalQuestionGetAiHintsParams,
  payload: CodeEvalQuestionGetAiHintsPayload
) => ({
  type: QUESTION_GET_AI_CODE_HINTS as typeof QUESTION_GET_AI_CODE_HINTS,
  meta,
  payload,
})

export const questionGetAiCodeHintsSuccess = (
  meta: CodeEvalQuestionGetAiHintsParams,
  payload: QuestionGetAiHintsResponse
) => ({
  type: QUESTION_GET_AI_CODE_HINTS_SUCCESS as typeof QUESTION_GET_AI_CODE_HINTS_SUCCESS,
  meta,
  payload,
})

export const questionGetAiCodeHintsFailure = (
  meta: CodeEvalQuestionGetAiHintsParams,
  payload: Error
) => ({
  type: QUESTION_GET_AI_CODE_HINTS_FAILURE as typeof QUESTION_GET_AI_CODE_HINTS_FAILURE,
  meta,
  payload,
})

export const questionGetAiCodeHintsCancel = () => ({
  type: QUESTION_GET_AI_CODE_HINTS_CANCEL as typeof QUESTION_GET_AI_CODE_HINTS_CANCEL,
})

export type CodingPlatformActionTypes =
  | ReturnType<typeof fetchRuntimes>
  | ReturnType<typeof fetchRuntimesSuccess>
  | ReturnType<typeof fetchRuntimesFailure>
  | ReturnType<typeof fetchRuntimesCancel>
  | ReturnType<typeof codeExecute>
  | ReturnType<typeof codeExecuteSuccess>
  | ReturnType<typeof codeExecutionFailure>
  | ReturnType<typeof codeExecutionCancel>
  | ReturnType<typeof questionRunCode>
  | ReturnType<typeof questionRunCodeSuccess>
  | ReturnType<typeof questionRunCodeFailure>
  | ReturnType<typeof questionRunCodeCancel>
  | ReturnType<typeof questionTestCode>
  | ReturnType<typeof questionTestCodeSuccess>
  | ReturnType<typeof questionTestCodeFailure>
  | ReturnType<typeof questionTestCodeCancel>
  | ReturnType<typeof questionRunSqlCode>
  | ReturnType<typeof questionRunSqlCodeSuccess>
  | ReturnType<typeof questionRunSqlCodeFailure>
  | ReturnType<typeof questionRunSQLCodeCancel>
  | ReturnType<typeof questiontestSqlCode>
  | ReturnType<typeof questiontestSqlCodeSuccess>
  | ReturnType<typeof questiontestSqlCodeFailure>
  | ReturnType<typeof questiontestSqlCodeCancel>
  | ReturnType<typeof updateQuestionStdin>
  | ReturnType<typeof updateQuestionArgs>
  | ReturnType<typeof listPlaygrounds>
  | ReturnType<typeof listPlaygroundsSuccess>
  | ReturnType<typeof listPlaygroundsFailure>
  | ReturnType<typeof listPlaygroundsCancel>
  | ReturnType<typeof createPlayground>
  | ReturnType<typeof createPlaygroundSuccess>
  | ReturnType<typeof createPlaygroundFailure>
  | ReturnType<typeof createPlaygroundCancel>
  | ReturnType<typeof getPlayground>
  | ReturnType<typeof getPlaygroundSuccess>
  | ReturnType<typeof getPlaygroundFailure>
  | ReturnType<typeof getPlaygroundCancel>
  | ReturnType<typeof updatePlayground>
  | ReturnType<typeof updatePlaygroundSuccess>
  | ReturnType<typeof updatePlaygroundFailure>
  | ReturnType<typeof updatePlaygroundCancel>
  | ReturnType<typeof deletePlayground>
  | ReturnType<typeof deletePlaygroundSuccess>
  | ReturnType<typeof deletePlaygroundFailure>
  | ReturnType<typeof deletePlaygroundCancel>
  | ReturnType<typeof renamePlayground>
  | ReturnType<typeof renamePlaygroundSuccess>
  | ReturnType<typeof renamePlaygroundFailure>
  | ReturnType<typeof renamePlaygroundCancel>
  | ReturnType<typeof updatePlaygroundStdin>
  | ReturnType<typeof updatePlaygroundArgs>
  | ReturnType<typeof questionGetAiCodeSuggestions>
  | ReturnType<typeof questionGetAiCodeSuggestionsSuccess>
  | ReturnType<typeof questionGetAiCodeSuggestionsFailure>
  | ReturnType<typeof questionGetAiCodeSuggestionsCancel>
  | ReturnType<typeof questionGetAiCodeHints>
  | ReturnType<typeof questionGetAiCodeHintsSuccess>
  | ReturnType<typeof questionGetAiCodeHintsFailure>
  | ReturnType<typeof questionGetAiCodeHintsCancel>
  | ReturnType<typeof sqlQuestionGetAiCodeSuggestions>
  | ReturnType<typeof sqlQuestionGetAiCodeSuggestionsSuccess>
  | ReturnType<typeof sqlQuestionGetAiCodeSuggestionsFailure>
  | ReturnType<typeof sqlQuestionGetAiCodeSuggestionsCancel>
