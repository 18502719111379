import { apiCall, getUserDetails } from '../../../common/utils'
import { UnlockedModulesFetchParams } from './FirstUnlockedModule.actions'

export async function fetchFirstUnlockedModules(
  payload: UnlockedModulesFetchParams,
  signal: AbortSignal
) {
  const { id: userId } = getUserDetails()
  const { course_ids } = payload

  const response = await apiCall({
    url: `${process.env.REACT_APP_LTI_API_URL}v1/courses/first_unlocked_modules`,
    query: {
      user_id: userId,
      course_ids,
    },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
