import { FormControlLabel, Radio } from '@material-ui/core'
import React, { Fragment } from 'react'
import cx from 'classnames'
import styles from './NewFreeOnboardingFormDialog.module.css'
import { ReactComponent as CheckBoxOutlineBlankIcon } from '../../../common/images/icons/alumni/outlineTick.svg'

interface optionsData {
  [key: string]: string
}
interface Props {
  optionsData: optionsData
  radioBtnCss: string
  selectedValue: string
  handleSelectedValue: (value: string) => void
}

const NewOnboardingFormRadioOptions = (props: Props) => {
  return (
    <Fragment>
      {Object.entries(props.optionsData).map(
        ([key, value]: [string, string]) => (
          <Fragment>
            <FormControlLabel
              className={cx(
                props.selectedValue === key
                  ? styles.radioButtonWithBackground
                  : styles.radioButton,
                styles[props.radioBtnCss]
              )}
              onChange={(event, checked) => {
                if (checked) {
                  props.handleSelectedValue(key)
                }
              }}
              value={key}
              control={
                <Radio
                  color="primary"
                  checked={props.selectedValue === key}
                  checkedIcon={<CheckBoxOutlineBlankIcon />}
                  className={styles.radio}
                />
              }
              label={key}
            />
          </Fragment>
        )
      )}
    </Fragment>
  )
}
export default NewOnboardingFormRadioOptions
