import { Reducer } from 'redux'
import { IZoomoutPoll } from 'common/types/zoomout'
import { ZoomoutPollsActions } from './Polls.actions'
import {
  FETCH_ALL_POLLS,
  FETCH_ALL_POLLS_SUCCESS,
  FETCH_POLL_BY_ID,
  FETCH_POLL_BY_ID_SUCCESS,
  UPDATE_OPTION_PREFERENCE,
  UPDATE_POLL_UNREAD_COUNT,
} from './Polls.types'

const normalisedData = (polls: any) => {
  const parsedPolls = Object.values(polls).reduce((acc: any, curr: any) => {
    acc[curr.id] = {
      ...curr,
      created_at: new Date(curr.created_at),
      updated_at: new Date(curr.updated_at),
      title: curr.title.length > 0 ? curr.title : undefined,
      mentored_group_session_id: undefined,
      questions: Object.keys(curr.questions).map(qid => ({
        ...curr.questions[qid],
        options: Object.values(curr.questions[qid].options),
      })),
    }
    return acc
  }, {}) as Record<number, IZoomoutPoll>

  return parsedPolls
}

export interface ZoomoutPollsState {
  isEnabled: boolean
  unreadCount: number
  allPolls: Record<number, IZoomoutPoll>
}

const initialState: ZoomoutPollsState = {
  isEnabled: true,
  unreadCount: 0,
  allPolls: {},
}

const zoomoutReducer: Reducer<ZoomoutPollsState, ZoomoutPollsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_ALL_POLLS_SUCCESS: {
      const { polls } = action.meta
      const allPolls = normalisedData(polls)

      return {
        ...state,
        allPolls,
      }
    }

    case FETCH_POLL_BY_ID_SUCCESS: {
      const { poll } = action.meta
      const pollId = action.payload
      const updatedData = normalisedData(poll)

      const newState = { ...state }
      delete newState.allPolls[pollId]

      return {
        ...newState,
        allPolls: {
          ...newState.allPolls,
          ...updatedData,
        },
      }
    }
    case UPDATE_OPTION_PREFERENCE: {
      const {
        lmsUserId,
        pollId,
        questionId,
        selectedOptions: newSelectedOptions,
      } = action.payload

      return {
        ...state,
        allPolls: {
          ...state.allPolls,
          [pollId]: {
            ...state.allPolls[pollId],
            questions: state.allPolls[pollId].questions.map(q => {
              if (q.id === questionId) {
                const newOptions = q.options.map(op => {
                  const idx = op.selectedBy.indexOf(lmsUserId)
                  const shouldSelect = newSelectedOptions.includes(op.id)
                  const alreadySelected = idx >= 0

                  if (shouldSelect && !alreadySelected) {
                    op.selectedBy.push(lmsUserId)
                  } else if (!shouldSelect && alreadySelected) {
                    op.selectedBy.splice(idx, 1)
                  }
                  return op
                })
                return {
                  ...q,
                  options: newOptions,
                }
              }
              return q
            }),
          },
        },
      }
    }
    case UPDATE_POLL_UNREAD_COUNT: {
      return {
        ...state,
        unreadCount: action.payload || 0,
      }
    }

    case FETCH_ALL_POLLS:
    case FETCH_POLL_BY_ID:
    default:
      return state
  }
}

export default zoomoutReducer
