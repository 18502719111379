import {
  AppBar,
  AppBarProps,
  Box,
  Toolbar,
  makeStyles,
} from '@material-ui/core'
import { X } from 'lucide-react'
import cx from 'classnames'
import { IconButton, Typography } from 'common/elements'
import React from 'react'

export type CrossAppBarProps = AppBarProps & {
  title?: string
  onCross?: () => void
}

const useStyles = makeStyles({
  container: {
    '& .MuiBackdrop-root': {
      background: 'rgba(26, 27, 30, 0.16)',
    },
  },
  appBar: {
    backgroundColor: 'white',
  },
})

export function CrossAppBar({ title, onCross, ...props }: CrossAppBarProps) {
  const styles = useStyles()
  return (
    <AppBar elevation={0} className={cx(styles.appBar)} {...props}>
      <Toolbar
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 8,
          paddingBottom: 8,
          height: 64,
        }}
      >
        <IconButton disableRipple onClick={onCross}>
          <X />
        </IconButton>
        <Box marginLeft={2} />
        <Typography className={cx('h5')}>{title}</Typography>
      </Toolbar>
    </AppBar>
  )
}
