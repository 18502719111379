import { apiCall, getUserDetails } from 'common/utils'
import { fetchProgramsBanners } from './ProgramBanners.actions'

export async function fetchProgramsBannersAPI(
  action: ReturnType<typeof fetchProgramsBanners>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/programs/banners`,
    params: { signal },
    query: { user_id: id },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
