import { Box } from '@material-ui/core'
import classNames from 'classnames'
import { Typography } from 'common/elements'
import React from 'react'

export function NoDataFound() {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <img
        alt=""
        width={156}
        height={102}
        src="https://d2snoxd05jt0e9.cloudfront.net/images/no-data-found.svg"
      />
      <Typography
        className={classNames('subtitle2')}
        style={{ textAlign: 'center' }}
      >
        No Data Found
      </Typography>
    </Box>
  )
}
