import { call, put, takeLatest } from 'redux-saga/effects'
import {
  fetchUserEnrolledDetails,
  fetchUserEnrolledDetailsSuccess,
  sendMessageToPeers,
  sendMessageToPeerSuccess,
  sendMessageLimitReached,
  fetchUserEnrolledDetailsFailure,
} from './AlumniPortal.actions'
import { showAlertMessage } from '../AlertsProvider'

import * as APIs from './AlumniProvider.api'
import * as types from './AlumniPortal.types'

function* fetchEnrolledUserInfoHandler(
  action: ReturnType<typeof fetchUserEnrolledDetails>
) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      APIs.getEnrolledUserDetailsAPI,
      action,
      action.filters,
      action.currentPage,
      abortController.signal
    )

    yield put(fetchUserEnrolledDetailsSuccess(data))
  } catch (e) {
    yield put(fetchUserEnrolledDetailsFailure(e))
  }
}

function* sendMessageToPeerHandler(
  action: ReturnType<typeof sendMessageToPeers>
) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      APIs.sendMailToPeersAPI,
      action,
      action.payload,
      abortController.signal
    )

    yield put(
      showAlertMessage({
        message:
          'Your message has been successfully sent to selected alumni. <br/> When they reply, you will receive the response in your email',
        variant: 'success',
        closeOnTimeout: false,
      })
    )
    yield put(sendMessageToPeerSuccess())
  } catch (e) {
    yield put(
      showAlertMessage({
        message: 'Sorry something went wrong!',
        variant: 'error',
      })
    )
  }
}

function* sendMessageLimitReachedHandler(
  action: ReturnType<typeof sendMessageLimitReached>
) {
  yield put(
    showAlertMessage({
      message: 'You can only have 5 recipients at once',
      variant: 'error',
    })
  )
}

export function* fetchEnrolledUserMiddleware() {
  yield takeLatest(
    types.FETCH_ENROLLED_USER_DETAILS,
    fetchEnrolledUserInfoHandler
  )
}
export function* sendMailToUsersMiddleware() {
  yield takeLatest(types.SEND_MESSAGE_TO_PEERS, sendMessageToPeerHandler)
}

export function* sendMessageLimitReachedMiddleWare() {
  yield takeLatest(
    types.SEND_MESSAGE_LIMIT_REACHED,
    sendMessageLimitReachedHandler
  )
}

export default ([] as any).concat([
  fetchEnrolledUserMiddleware(),
  sendMailToUsersMiddleware(),
  sendMessageLimitReachedMiddleWare(),
])
