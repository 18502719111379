import { takeEvery, call, put, cancelled } from 'redux-saga/effects'
import * as types from './WikiPage.types'
import * as actions from './WikiPage.actions'
import APIs from './WikiPage.apis'
import { cancelable } from '../../../../../common/utils'

function* fetchVideoDetailsHandler(
  action: ReturnType<typeof actions.fetchVideoDetails>
) {
  const abortController = new AbortController()
  try {
    const result = yield call(APIs.fetchVideoDetailsAPI, {
      url: action.payload.url,
      courseId: action.payload.courseId,
      is_iphone: action.payload.is_iphone,
    })
    yield put(
      actions.fetchVideoDetailsSuccess(
        result.live_session_info ? result.live_session_info : result, // vimeo video handling
        action.meta
      )
    )
  } catch (e) {
    yield put(actions.fetchVideoDetailsFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* wikiPageMiddleware() {
  yield takeEvery(
    types.FETCH_VIDEO_DETAILS,
    cancelable(fetchVideoDetailsHandler, types.FETCH_VIDEO_DETAILS_CANCEL)
  )
}

export default ([] as any).concat(wikiPageMiddleware())
