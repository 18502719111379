import { apiCall, getUserDetails } from '../../../common/utils'
import { uploadFile } from '.'

export async function initialFileUploadAPI(
  url: string,
  file: File,
  signal: AbortSignal,
  bodyObj?: any
) {
  const body = JSON.stringify({
    name: file.name,
    size: file.size,
    content_type: file.type,
    on_duplicate: 'rename',
    ...bodyObj,
  })
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}${url}`,
    params: {
      signal,
      method: 'POST',
      body,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function initialFileUploadAPIHandler(
  action: ReturnType<typeof uploadFile>,
  file: File,
  signal: AbortSignal
) {
  const body = {
    ...(action.payload.folderPath
      ? { parent_folder_path: action.payload.folderPath }
      : {}),
  }
  return initialFileUploadAPI(action.payload.url, file, signal, body)
}

export async function uploadFileAPI(
  initialUploadData: any,
  file: File,
  signal: AbortSignal
) {
  if (initialUploadData.upload_url) {
    const formDataContent = initialUploadData.upload_params
    const formData = new FormData()
    Object.keys(formDataContent).forEach(key =>
      formData.append(key, formDataContent[key])
    )
    formData.append('file', file)
    const response = await apiCall({
      url: initialUploadData.upload_url,
      params: {
        signal,
        body: formData,
        method: 'post',
      },
      auth: false,
      excludeProgramId: true,
    })
    if (response.ok) {
      return response.json()
    }
    throw response
  }
  throw new Error('file upload failed')
}

export async function uploadFaqAssetAPI(file: File, signal: AbortSignal) {
  const formData = new FormData()
  const { id } = getUserDetails()

  formData.append('attachment', file)
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/program_support/faqs/assets`,
    params: {
      signal,
      body: formData,
      method: 'post',
    },
    query: {
      student_id: id,
    },
    auth: true,
    excludeProgramId: true,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
