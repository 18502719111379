import { apiCall, getUserDetails } from 'common/utils'

export async function fetchCompletedQuizzesAPI(signal: AbortSignal) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/quizzes/completed`,
    params: {
      signal,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function fetchCompletedAssignmentsAPI(signal: AbortSignal) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/assignments/completed`,
    params: {
      signal,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
