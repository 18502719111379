import {
  FETCH_USER_PAYMENT_HISTORY,
  USER_PAYMENT_HISTORY_SUCCESS,
  USER_PAYMENT_HISTORY_FAILURE,
  FETCH_USER_PAYMENT_RECEIPT,
} from './PaymentHistory.types'
import { UserPaymentTransactionData } from '../../../../common/types/user'

export const fetchUserPaymentHistory = () => ({
  type: FETCH_USER_PAYMENT_HISTORY as typeof FETCH_USER_PAYMENT_HISTORY,
})

export const fetchUserPaymentReceipt = (
  transaction_id: string,
  user_name: string
) => ({
  type: FETCH_USER_PAYMENT_RECEIPT as typeof FETCH_USER_PAYMENT_RECEIPT,
  transaction_id,
  user_name,
})

export const UserPaymentHistorySuccess = (
  payload: UserPaymentTransactionData[]
) => ({
  type: USER_PAYMENT_HISTORY_SUCCESS as typeof USER_PAYMENT_HISTORY_SUCCESS,
  payload,
})

export const UserPaymentHistoryFailure = (payload: Error) => ({
  type: USER_PAYMENT_HISTORY_FAILURE as typeof USER_PAYMENT_HISTORY_FAILURE,
  payload,
})

export type PaymentHistoryActions =
  | ReturnType<typeof fetchUserPaymentHistory>
  | ReturnType<typeof UserPaymentHistorySuccess>
  | ReturnType<typeof UserPaymentHistoryFailure>
  | ReturnType<typeof fetchUserPaymentReceipt>
