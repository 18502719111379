import { UserID } from 'common/types/user'
import { cancelable } from 'common/utils'
import {
  QAMessage,
  QA_MESSAGE_TYPES,
} from 'common/utils/custom/Zoomout/rtm/messages'
import {
  select,
  put,
  cancelled,
  takeEvery,
  call,
  take,
} from 'redux-saga/effects'
import { showAlertMessage } from 'web/providers/AlertsProvider'
import {
  postQuestion as postQuestionToCommunity,
  communityTypes,
  postAnswer as postAnswerToCommunity,
  deleteAnswer as deleteAnswerToCommunity,
  deleteQuestion as deleteQuestionToCommunity,
  updateAnswer as updateAnswerToCommunity,
  updateQuestion as updateQuestionToCommunity,
} from 'web/providers/CommunityProvider'
import { AppState } from 'web/store'
import {
  submitQuestionAnswer,
  submitQuestionAnswerSuccess,
  submitQuestionAnswerFailure,
  fetchAnswers,
  fetchAnswersFailure,
  fetchAnswersSuccess,
  submitQaAction,
  submitQaActionSuccess,
  submitQaActionFailure,
  deleteQaAction,
  deleteQaActionFailure,
  deleteQaActionSuccess,
  deleteQuestionAnswer,
  deleteQuestionAnswerSuccess,
  deleteQuestionAnswerFailure,
  updateQuestionAnswer,
  updateQuestionAnswerSuccess,
  updateQuestionAnswerFailure,
} from './QA.actions'
import {
  submitQuestionAnswerAPI,
  fetchAnswersAPI,
  submitQaActionAPI,
  deleteQaActionAPI,
  deleteQuestionAnswerAPI,
  updateQuestionAnswerAPI,
} from './QA.api'
import {
  DELETE_QA_ACTION,
  DELETE_QA_ACTION_CANCEL,
  DELETE_QUESTION_ANSWER,
  DELETE_QUESTION_ANSWER_CANCEL,
  FETCH_ANSWERS,
  FETCH_ANSWERS_CANCEL,
  SUBMIT_QA_ACTION,
  SUBMIT_QA_ACTION_CANCEL,
  SUBMIT_QUESTION_ANSWER,
  SUBMIT_QUESTION_ANSWER_CANCEL,
  UPDATE_QUESTION_ANSWER,
  UPDATE_QUESTION_ANSWER_CANCEL,
} from './QA.types'

export function* submitQuestionAnswerHandler(
  action: ReturnType<typeof submitQuestionAnswer>
) {
  const abortController = new AbortController()

  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    const mentoredGroupSessionId: number = yield select(
      (state: AppState) => state.zoomout.external.mentoredGroupSessionId
    )
    if (!mentoredGroupSessionId) throw new Error('Meeting ID is Invalid')

    const params = {
      ...action.payload,
      lmsUserId: userId,
      mentoredGroupSessionId,
    }
    let communityId: null | string = null
    if (params.topicIds) {
      yield put(
        postQuestionToCommunity({
          title: params.message,
          topicIds: params.topicIds,
        })
      )
      const res = yield take([
        communityTypes.POST_QUESTION_SUCCESS,
        communityTypes.POST_QUESTION_FAILURE,
      ])
      if (res.type === communityTypes.POST_QUESTION_FAILURE) {
        throw new Error('Posting to community failed')
      }
      // eslint-disable-next-line no-underscore-dangle
      communityId = res.payload._id
    } else if (params.communityId) {
      yield put(
        postAnswerToCommunity({
          content: params.message,
          questionId: params.communityId,
        })
      )
      const res = yield take([
        communityTypes.POST_ANSWER_SUCCESS,
        communityTypes.POST_ANSWER_FAILURE,
      ])
      if (res.type === communityTypes.POST_ANSWER_FAILURE) {
        throw new Error('Posting to community failed')
      }
      // eslint-disable-next-line no-underscore-dangle
      communityId = res.payload._id
    }
    const data = yield call(
      submitQuestionAnswerAPI,
      { ...params, communityId },
      abortController.signal
    )

    yield put(submitQuestionAnswerSuccess(data))

    // send through RTM
    const selfUid: number = yield select(
      (state: AppState) => state.zoomout.external.joiningDetails!.main.uid
    )
    if (window.zoomout) {
      const payload = new QAMessage({
        sender: selfUid,
        content: QA_MESSAGE_TYPES.ENTRY_ADDITION,
        payload: data,
      })

      window.zoomout.rtmChannel.sendMessage({
        text: JSON.stringify(payload),
      })
    }
  } catch (error) {
    yield put(submitQuestionAnswerFailure(error))
    if (error.message.includes('Posting to community failed')) {
      yield put(
        showAlertMessage({
          message: 'Unable to post in community',
          variant: 'error',
        })
      )
    }
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* deleteQuestionAnswerHandler(
  action: ReturnType<typeof deleteQuestionAnswer>
) {
  const abortController = new AbortController()

  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    const mentoredGroupSessionId: number = yield select(
      (state: AppState) => state.zoomout.external.mentoredGroupSessionId
    )
    if (!mentoredGroupSessionId) throw new Error('Meeting ID is Invalid')

    const params = {
      ...action.payload,
      lmsUserId: userId,
      mentoredGroupSessionId,
    }

    const data = yield call(
      deleteQuestionAnswerAPI,
      params,
      abortController.signal
    )
    yield put(deleteQuestionAnswerSuccess(action.payload))

    // send through RTM
    const selfUid: number = yield select(
      (state: AppState) => state.zoomout.external.joiningDetails!.main.uid
    )
    if (window.zoomout) {
      const payload = new QAMessage({
        sender: selfUid,
        content: QA_MESSAGE_TYPES.ENTRY_DELETION,
        payload: action.payload,
      })

      window.zoomout.rtmChannel.sendMessage({
        text: JSON.stringify(payload),
      })
    }

    if (action.payload.communityId) {
      if (action.payload.parentId) {
        yield put(deleteAnswerToCommunity({ id: action.payload.communityId }))
      } else {
        yield put(deleteQuestionToCommunity({ id: action.payload.communityId }))
      }
    }
  } catch (error) {
    yield put(deleteQuestionAnswerFailure(error))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* updateQuestionAnswerHandler(
  action: ReturnType<typeof updateQuestionAnswer>
) {
  const abortController = new AbortController()

  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    const mentoredGroupSessionId: number = yield select(
      (state: AppState) => state.zoomout.external.mentoredGroupSessionId
    )
    if (!mentoredGroupSessionId) throw new Error('Meeting ID is Invalid')

    const params = {
      ...action.payload,
      lmsUserId: userId,
      mentoredGroupSessionId,
    }

    const data = yield call(
      updateQuestionAnswerAPI,
      params,
      abortController.signal
    )
    yield put(updateQuestionAnswerSuccess(data))

    // send through RTM
    const selfUid: number = yield select(
      (state: AppState) => state.zoomout.external.joiningDetails!.main.uid
    )
    if (window.zoomout) {
      const payload = new QAMessage({
        sender: selfUid,
        content: QA_MESSAGE_TYPES.ENTRY_UPDATE,
        payload: data,
      })

      window.zoomout.rtmChannel.sendMessage({
        text: JSON.stringify(payload),
      })
    }

    if (action.payload.communityId) {
      if (action.payload.parentId) {
        yield put(
          updateAnswerToCommunity({
            id: action.payload.communityId,
            content: action.payload.message,
          })
        )
      } else {
        yield put(
          updateQuestionToCommunity({
            id: action.payload.communityId,
            title: action.payload.message,
          })
        )
      }
    }
  } catch (error) {
    yield put(updateQuestionAnswerFailure(error))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fetchAnswersHandler(action: ReturnType<typeof fetchAnswers>) {
  const abortController = new AbortController()

  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    const mentoredGroupSessionId: number = yield select(
      (state: AppState) => state.zoomout.external.mentoredGroupSessionId
    )
    if (!mentoredGroupSessionId) throw new Error('Meeting ID is Invalid')

    const params = {
      ...action.payload,
      lmsUserId: userId,
      mentoredGroupSessionId,
    }

    const data = yield call(fetchAnswersAPI, params, abortController.signal)

    yield put(fetchAnswersSuccess(data, action.meta))
  } catch (error) {
    yield put(fetchAnswersFailure(error))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
export function* submitQaActionHandler(
  action: ReturnType<typeof submitQaAction>
) {
  const abortController = new AbortController()

  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    const mentoredGroupSessionId: number = yield select(
      (state: AppState) => state.zoomout.external.mentoredGroupSessionId
    )
    if (!mentoredGroupSessionId) throw new Error('Meeting ID is Invalid')

    const params = {
      ...action.payload,
      lmsUserId: userId,
      mentoredGroupSessionId,
    }

    const data = yield call(submitQaActionAPI, params, abortController.signal)
    yield put(submitQaActionSuccess(data.data, { selfAction: true }))

    // send through RTM
    const selfUid: number = yield select(
      (state: AppState) => state.zoomout.external.joiningDetails!.main.uid
    )
    if (window.zoomout) {
      const payload = new QAMessage({
        sender: selfUid,
        content: QA_MESSAGE_TYPES.ACTION_ADDITION,
        payload: data.data,
      })

      window.zoomout.rtmChannel.sendMessage({
        text: JSON.stringify(payload),
      })
    }
  } catch (error) {
    yield put(submitQaActionFailure(error))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* deleteQaActionHandler(
  action: ReturnType<typeof deleteQaAction>
) {
  const abortController = new AbortController()

  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    const mentoredGroupSessionId: number = yield select(
      (state: AppState) => state.zoomout.external.mentoredGroupSessionId
    )
    if (!mentoredGroupSessionId) throw new Error('Meeting ID is Invalid')

    const params = {
      ...action.payload,
      lmsUserId: userId,
      mentoredGroupSessionId,
    }

    const data = yield call(deleteQaActionAPI, params, abortController.signal)
    yield put(deleteQaActionSuccess(data.data, { selfAction: false }))

    // send through RTM
    const selfUid: number = yield select(
      (state: AppState) => state.zoomout.external.joiningDetails!.main.uid
    )
    if (window.zoomout) {
      const payload = new QAMessage({
        sender: selfUid,
        content: QA_MESSAGE_TYPES.ACTION_DELETION,
        payload: data.data,
      })

      window.zoomout.rtmChannel.sendMessage({
        text: JSON.stringify(payload),
      })
    }
  } catch (error) {
    yield put(deleteQaActionFailure(error))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* ZoomoutQAMiddleware() {
  yield takeEvery(
    SUBMIT_QUESTION_ANSWER,
    cancelable(submitQuestionAnswerHandler, SUBMIT_QUESTION_ANSWER_CANCEL)
  )
  yield takeEvery(
    DELETE_QUESTION_ANSWER,
    cancelable(deleteQuestionAnswerHandler, DELETE_QUESTION_ANSWER_CANCEL)
  )
  yield takeEvery(
    UPDATE_QUESTION_ANSWER,
    cancelable(updateQuestionAnswerHandler, UPDATE_QUESTION_ANSWER_CANCEL)
  )
  yield takeEvery(
    FETCH_ANSWERS,
    cancelable(fetchAnswersHandler, FETCH_ANSWERS_CANCEL)
  )
  yield takeEvery(
    SUBMIT_QA_ACTION,
    cancelable(submitQaActionHandler, SUBMIT_QA_ACTION_CANCEL)
  )
  yield takeEvery(
    DELETE_QA_ACTION,
    cancelable(deleteQaActionHandler, DELETE_QA_ACTION_CANCEL)
  )
}

export default ([] as any).concat(ZoomoutQAMiddleware())
