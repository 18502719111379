import { ModuleItemData } from '../../../types/courses/moduleItem'
import { ContentTypes } from '../../../types/courses/content'

export const contentTypeMapping: {
  [k in ContentTypes]: ModuleItemData['type']
} = {
  assignments: 'Assignment',
  files: 'File',
  pages: 'Page',
  quizzes: 'Quiz',
  discussion_topics: 'Discussion',
}
