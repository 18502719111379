import {
  apiCall,
  objectToFormData,
  getUserDetails,
} from '../../../common/utils'
import {
  fetchUserEnrolledDetails,
  sendMessageToPeers,
} from './AlumniPortal.actions'
import { SendMessageToPeersPayload } from '../../../common/types/alumni'

export async function getEnrolledUserDetailsAPI(
  action: ReturnType<typeof fetchUserEnrolledDetails>,
  filters: any,
  currentPage: number,
  signal: AbortSignal
) {
  const esc = encodeURIComponent

  const queryParams = Object.keys(filters)
    .filter(k => filters[k].length > 0 || typeof filters[k] === 'boolean')
    .map(k => `${esc(k)}=${esc(filters[k])}`)
    .join('&')

  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/gl-connect/enrolled_users_details?${queryParams}`,
    params: { signal },
    query: { current_page: currentPage, user_id: id },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function sendMailToPeersAPI(
  action: ReturnType<typeof sendMessageToPeers>,
  payload: SendMessageToPeersPayload,
  signal: AbortSignal
) {
  const { id } = getUserDetails()

  const sendMailParams = {
    to_users: Object.keys(payload.toUsers),
    content: payload.content,
    subject: payload.subject,
    user_id: id,
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/gl-connect/send_mail_to_peers`,
    params: { method: 'POST', signal, body: objectToFormData(sendMailParams) },
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}
