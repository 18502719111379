import { apiCall, getUserDetails } from 'common/utils'
import { ProctoredEventType } from 'common/types/codingLabs'
import {
  getCodingLabStatus,
  startCodingLab,
  stopCodingLab,
  getCodingLabAssignmentAndSubmissionData,
  submitCodingLabAssignment,
} from './CodingLabs.action'

export async function getCodingLabStatusAPI(
  action: ReturnType<typeof getCodingLabStatus>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { codingLabAssignmentId, queryParams } = action.payload

  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/fsd_platform/coding_labs/${codingLabAssignmentId}/users/${id}/service/status`,
    params: { signal },
    query: queryParams,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function startCodingLabAPI(
  action: ReturnType<typeof startCodingLab>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { codingLabAssignmentId, queryParams } = action.payload

  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/fsd_platform/coding_labs/${codingLabAssignmentId}/users/${id}/service/start`,
    params: {
      method: 'PUT',
      signal,
    },
    query: queryParams,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function stopCodingLabAPI(
  action: ReturnType<typeof stopCodingLab>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { codingLabAssignmentId, queryParams } = action.payload

  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/fsd_platform/coding_labs/${codingLabAssignmentId}/users/${id}/service/stop`,
    params: {
      method: 'PUT',
      signal,
    },
    query: queryParams,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function submitCodingLabAssignmentAPI(
  action: ReturnType<typeof submitCodingLabAssignment>,
  signal: AbortSignal
) {
  const {
    codingLabAssignmentId,
    submissionId,
    completionType: submissionType = null,
  } = action.payload
  const { id } = getUserDetails()

  let url = `${process.env.REACT_APP_LTI_URL}coding_labs/${codingLabAssignmentId}/submissions/${submissionId}/submit?user_id=${id}`

  if (submissionType !== null) {
    url += `&submission_type=${submissionType}`
  }

  const response = await apiCall({
    url,
    params: {
      method: 'POST',
      signal,
    },
  })

  if (response.ok) {
    return response.json()
  }
  throw response

  // Return a success response for testing
  // return {
  //   ok: true,
  //   json: () => ({ message: 'Success' }),
  // }
}

export async function getCodingLabAssignmentDataAPI(
  action: ReturnType<typeof getCodingLabAssignmentAndSubmissionData>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { codingLabAssignmentId } = action.payload

  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/fsd_platform/coding_labs/${codingLabAssignmentId}/users/${id}/assignment_details`,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

// post https://<st>elevate.<iac-mygreatlearning.net>/api/v1/fsd_platform/coding_labs/281/users/89588/proctor?pb_id=282
export async function proctorCodingLabAPI(
  codingLabAssignmentId: number,
  event_type: ProctoredEventType,
  event_data: any,
  focus: boolean
) {
  const { id } = getUserDetails()
  const body = JSON.stringify({
    event_type,
    event_data,
    focus,
  })
  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/fsd_platform/coding_labs/${codingLabAssignmentId}/users/${id}/proctor`,
    params: {
      method: 'POST',
      body,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
