// fetch entries
export const FETCH_QA_ENTRIES_SUCCESS = 'FETCH_QA_ENTRIES_SUCCESS'

// submit question answer
export const SUBMIT_QUESTION_ANSWER = 'SUBMIT_QUESTION_ANSWER'
export const SUBMIT_QUESTION_ANSWER_SUCCESS = 'SUBMIT_QUESTION_ANSWER_SUCCESS'
export const SUBMIT_QUESTION_ANSWER_FAILURE = 'SUBMIT_QUESTION_ANSWER_FAILURE'
export const SUBMIT_QUESTION_ANSWER_CANCEL = 'SUBMIT_QUESTION_ANSWER_CANCEL'

// delete question answer
export const DELETE_QUESTION_ANSWER = 'DELETE_QUESTION_ANSWER'
export const DELETE_QUESTION_ANSWER_SUCCESS = 'DELETE_QUESTION_ANSWER_SUCCESS'
export const DELETE_QUESTION_ANSWER_FAILURE = 'DELETE_QUESTION_ANSWER_FAILURE'
export const DELETE_QUESTION_ANSWER_CANCEL = 'DELETE_QUESTION_ANSWER_CANCEL'

// update question answer
export const UPDATE_QUESTION_ANSWER = 'UPDATE_QUESTION_ANSWER'
export const UPDATE_QUESTION_ANSWER_SUCCESS = 'UPDATE_QUESTION_ANSWER_SUCCESS'
export const UPDATE_QUESTION_ANSWER_FAILURE = 'UPDATE_QUESTION_ANSWER_FAILURE'
export const UPDATE_QUESTION_ANSWER_CANCEL = 'UPDATE_QUESTION_ANSWER_CANCEL'

// fetch answers
export const FETCH_ANSWERS = 'FETCH_ANSWERS'
export const FETCH_ANSWERS_SUCCESS = 'FETCH_ANSWERS_SUCCESS'
export const FETCH_ANSWERS_FAILURE = 'FETCH_ANSWERS_FAILURE'
export const FETCH_ANSWERS_CANCEL = 'FETCH_ANSWERS_CANCEL'

// submit qa action
export const SUBMIT_QA_ACTION = 'SUBMIT_QA_ACTION'
export const SUBMIT_QA_ACTION_SUCCESS = 'SUBMIT_QA_ACTION_SUCCESS'
export const SUBMIT_QA_ACTION_FAILURE = 'SUBMIT_QA_ACTION_FAILURE'
export const SUBMIT_QA_ACTION_CANCEL = 'SUBMIT_QA_ACTION_CANCEL'

// delete qa action
export const DELETE_QA_ACTION = 'DELETE_QA_ACTION'
export const DELETE_QA_ACTION_SUCCESS = 'DELETE_QA_ACTION_SUCCESS'
export const DELETE_QA_ACTION_FAILURE = 'DELETE_QA_ACTION_FAILURE'
export const DELETE_QA_ACTION_CANCEL = 'DELETE_QA_ACTION_CANCEL'

// update unread count
export const UPDATE_UNREAD_COUNT = 'UPDATE_UNREAD_COUNT'
