import AgoraRTC, { ICameraVideoTrack } from 'agora-rtc-sdk-ng'
import {
  setCameraStateTo,
  updateCamera,
  updateUserInterface,
} from 'web/providers/Zoomout/Internal/Internal.actions'
import { CALL_STAGES } from 'web/providers/Zoomout/Internal/Internal.reducer'
import store from 'web/store'
import {
  HARD_AGORA_HOST_LIMIT,
  getCameraConfig,
  getZoomoutEventId,
} from 'common/utils/zoomout'
import { mixpanel } from 'common/utils/mixpanel'
import { showAlertMessage } from 'web/providers/AlertsProvider'
import zoomoutSettings, { ZoomoutDefaultsType } from '../settings'
import pushZoomoutEvent from '../pushZoomoutEvent'
import { initializeCameraGlobalEventHandlers } from '../rtc/eventHandlers'
import { getCameras } from './getDevices'

export interface ICameraControlParams {
  cameraTrack: ICameraVideoTrack
  isInCall: boolean
  isMicPublished: boolean
}

export const createCameraTrack = async (
  config: ZoomoutDefaultsType['camera']
) => {
  const camera = await AgoraRTC.createCameraVideoTrack(config)
  if (!camera) return null

  await camera.setEnabled(zoomoutSettings.camera.isEnabled)
  initializeCameraGlobalEventHandlers(camera)
  return {
    track: camera,
    isEnabled: config.isEnabled,
    encoderConfig: config.encoderConfig,
  }
}

export const StartCamera = async (params: ICameraControlParams) => {
  try {
    if (!window.zoomout) throw new Error('Zoomout is not initialized')
    if (
      window.zoomout.mainRtcClient.remoteUsers.length > HARD_AGORA_HOST_LIMIT
    ) {
      const message = `Your camera can't be enabled right now due to large size of the call.`
      store.dispatch(
        showAlertMessage({
          variant: 'error',
          message,
        })
      )
      throw new Error(message)
    }
    const { cameraTrack, isInCall } = params

    if (zoomoutSettings.config.mode === 'live') {
      await window.zoomout.mainRtcClient.setClientRole('host')
    }
    await cameraTrack.setEnabled(true)

    if (
      isInCall &&
      !window.zoomout.mainRtcClient.localTracks.includes(cameraTrack)
    ) {
      await window.zoomout.mainRtcClient.publish(cameraTrack)
    }
    pushZoomoutEvent({ eventID: getZoomoutEventId('UNMUTE_CAMERA') })
    store.dispatch(setCameraStateTo(true))
  } catch (err) {
    const error = err as any

    mixpanel.track('Zoomout Errors', {
      feature: 'Start Camera',
      code: error.code,
      message: error.message,
    })
  }
}

export const StopCamera = async (
  params: Omit<ICameraControlParams, 'isInCall'>
): Promise<void> => {
  try {
    if (!window.zoomout) throw new Error('Zoomout is not initialized')

    const { cameraTrack, isMicPublished } = params
    await cameraTrack.setEnabled(false) // setEnabled(false) automatically unpublishes.

    // just in case cameraTrack is still not unpublished
    if (window.zoomout.mainRtcClient.localTracks.includes(cameraTrack)) {
      await window.zoomout.mainRtcClient.unpublish(cameraTrack)
    }

    // before switching to audience mode, ensure that the user is not publishing the mic
    if (zoomoutSettings.config.mode === 'live' && !isMicPublished) {
      await window.zoomout.mainRtcClient.setClientRole(
        'audience',
        zoomoutSettings.config.audienceRoleOptions
      )
    }

    pushZoomoutEvent({ eventID: getZoomoutEventId('MUTE_CAMERA') })
    store.dispatch(setCameraStateTo(false))
  } catch (err) {
    const error = err as any

    mixpanel.track('Zoomout Errors', {
      feature: 'Stop Camera',
      code: error.code,
      message: error.message,
    })
  }
}

export const initializeCameraTrack = async () => {
  try {
    const { supportsDualStream } = store.getState().zoomout.internal
    const cameraDevices = await getCameras()
    if (!cameraDevices.default) throw new Error('No default Camera found')

    const cameraConfig = getCameraConfig(
      supportsDualStream,
      cameraDevices.default.deviceId
    )
    const cameraTrack = await createCameraTrack(cameraConfig)

    if (!cameraTrack) throw new Error('Permission denied / not found')
    store.dispatch(
      updateCamera({
        track: cameraTrack,
        devices: {
          current: cameraDevices.default,
          all: cameraDevices.all,
        },
      })
    )
    return cameraTrack
  } catch (err) {
    store.dispatch(updateUserInterface({ modal: 'cameraPermission' }))
    throw new Error('Permission denied / camera not found')
  }
}

const toggleCamera = async (): Promise<boolean> => {
  const state = store.getState().zoomout.internal
  let { camera } = state.localTracksDetails
  const isMicPublished =
    (state.localTracksDetails.microphone || {}).isEnabled || false

  if (!camera) {
    camera = await initializeCameraTrack()
  }

  const params = {
    cameraTrack: camera.track,
    isMicPublished,
    isInCall:
      state.callStage === CALL_STAGES.LIVE_IN_CALL ||
      state.callStage === CALL_STAGES.RECONNECTING_IN_CALL,
  }

  let stateAfterToggle: boolean

  if (camera.isEnabled) {
    await StopCamera(params)
    stateAfterToggle = false
  } else {
    await StartCamera(params)
    stateAfterToggle = true
  }

  return stateAfterToggle
}

export default toggleCamera
