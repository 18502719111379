import { QuizID } from 'common/types/courses/quiz'
import * as types from './QuizCodingLabs.types'

export type LabStopReason = 'inactivity' | 'manual'
export interface QuizCodingLabStatusPayload {
  coding_lab_id: number
  quiz_id: QuizID
}

export interface QuizCodingLabsPayload {
  quizID: QuizID
}

export const startQuizCodingLabs = (payload: QuizCodingLabsPayload) => ({
  type: types.START_QUIZ_CODING_LABS as typeof types.START_QUIZ_CODING_LABS,
  payload,
})

export const getQuizCodingLabStatus = (
  payload: QuizCodingLabStatusPayload
) => ({
  type: types.GET_QUIZ_CODING_LAB_STATUS as typeof types.GET_QUIZ_CODING_LAB_STATUS,
  payload,
})

export const getQuizCodingLabStatusSuccess = (payload: any) => ({
  type: types.GET_QUIZ_CODING_LAB_STATUS_SUCCESS as typeof types.GET_QUIZ_CODING_LAB_STATUS_SUCCESS,
  payload,
})

export type QuizCodingLabsActionTypes =
  | ReturnType<typeof startQuizCodingLabs>
  | ReturnType<typeof getQuizCodingLabStatus>
  | ReturnType<typeof getQuizCodingLabStatusSuccess>
