import { put, cancelled, takeLatest, call } from 'redux-saga/effects'
import { getUserDetails, apiCall, cancelable } from '../../../../common/utils'
import {
  fetchGradebookContentSuccess,
  fetchGradebookContentFailure,
} from './Gradebook.actions'
import {
  FETCH_GRADEBOOK_CONTENT,
  FETCH_GRADEBOOK_CONTENT_CANCEL,
} from './Gradebook.types'

export async function getGradebookContentAPI(signal: AbortSignal) {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/users/${id}/gradebook`,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

function* fetchGradebookContentHandler() {
  const abortController = new AbortController()
  try {
    const data = yield call(getGradebookContentAPI, abortController.signal)
    yield put(fetchGradebookContentSuccess(data))
  } catch (e) {
    yield put(fetchGradebookContentFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fetchGradebookContentMiddleware() {
  yield takeLatest(
    FETCH_GRADEBOOK_CONTENT,
    cancelable(fetchGradebookContentHandler, FETCH_GRADEBOOK_CONTENT_CANCEL)
  )
}
export default ([] as any).concat(fetchGradebookContentMiddleware())
