import { apiCall, getUserDetails } from '../../../../common/utils'
import { fetchResidencyContent } from './Activity.actions'

export async function getResidencyContentAPI(
  action: ReturnType<typeof fetchResidencyContent>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { residencyId } = action.payload
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/residencies/${residencyId}/details`,
    params: { signal },
    query: { user_id: id, format: 'json' },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
