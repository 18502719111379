import { Reducer } from 'redux'
import {
  DigitalExcelerateTestData,
  DigitalExcelerateTestId,
} from 'common/types/excelerate/tests'
import { ProgramActions } from 'web/providers/Dashboard/ProgramsProvider/Programs.actions'
import { UPDATE_ACTIVE_PROGRAM } from 'web/providers/Dashboard/ProgramsProvider'
import { DigitalExcelerateTestsActionTypes } from './Tests.actions'
import {
  FETCH_EXCELERATE_TEST_DETAILS,
  FETCH_EXCELERATE_TEST_DETAILS_CANCEL,
  FETCH_EXCELERATE_TEST_DETAILS_FAILURE,
  FETCH_EXCELERATE_TEST_DETAILS_SUCCESS,
} from './Tests.types'

export interface DigitalExcelerateTestsState {
  byId: {
    [key in DigitalExcelerateTestId]?: {
      data?: DigitalExcelerateTestData
      loading: Boolean
      error: false | Error | Response
    }
  }
}

const initialState: DigitalExcelerateTestsState = { byId: {} }

export const excelerateTestsReducer: Reducer<
  DigitalExcelerateTestsState,
  | DigitalExcelerateTestsActionTypes
  | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
> = (state = initialState, action): DigitalExcelerateTestsState => {
  switch (action.type) {
    case FETCH_EXCELERATE_TEST_DETAILS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.testId]: {
            loading: true,
            error: false,
          },
        },
      }
    case FETCH_EXCELERATE_TEST_DETAILS_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.testId]: {
            data: action.payload,
            loading: false,
            error: false,
          },
        },
      }
    }
    case FETCH_EXCELERATE_TEST_DETAILS_FAILURE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.testId]: {
            loading: false,
            error: action.payload,
          },
        },
      }
    case FETCH_EXCELERATE_TEST_DETAILS_CANCEL:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.testId]: {
            loading: false,
            error: false,
          },
        },
      }

    case UPDATE_ACTIVE_PROGRAM:
      return initialState

    default:
      return state
  }
}

export default excelerateTestsReducer
