import { SHOW_OFFLINE_POPUP, REMOVE_OFFLINE_POPUP } from './NetworkDetector.types'

export const showOfflinePopup = () => ({
  type: SHOW_OFFLINE_POPUP as typeof SHOW_OFFLINE_POPUP,
})

export const removeOfflinePopup = () => ({
  type: REMOVE_OFFLINE_POPUP as typeof REMOVE_OFFLINE_POPUP,
})

export type NetworkDetectorActionTypes =
  | ReturnType<typeof showOfflinePopup>
  | ReturnType<typeof removeOfflinePopup>
