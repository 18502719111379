import {
  FETCH_NEW_COURSE_ITEM,
  FETCH_NEW_COURSE_ITEM_FAILURE,
  FETCH_NEW_COURSE_ITEM_SUCCESS,
} from './NewCourseItem.types'

export const fetchNewCourseItem = () => ({
  type: FETCH_NEW_COURSE_ITEM as typeof FETCH_NEW_COURSE_ITEM,
})

export const fetchNewCourseItemSuccess = (payload: any) => ({
  type: FETCH_NEW_COURSE_ITEM_SUCCESS as typeof FETCH_NEW_COURSE_ITEM_SUCCESS,
  payload,
})

export const fetchNewCourseItemFailure = (payload: Error) => ({
  type: FETCH_NEW_COURSE_ITEM_FAILURE as typeof FETCH_NEW_COURSE_ITEM_FAILURE,
  payload,
})

export type NewCourseItemActions =
  | ReturnType<typeof fetchNewCourseItem>
  | ReturnType<typeof fetchNewCourseItemSuccess>
  | ReturnType<typeof fetchNewCourseItemFailure>
