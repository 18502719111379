import { apiCall, getActiveProgramBatchId, getUserDetails } from 'common/utils'
import {
  getJupyterLabStatus,
  startJupyterLab,
  stopJupyterLab,
  getJupyterNotebook,
  getJupyterLabDetails,
} from './JupyterLabs.action'
import axios from 'axios'

export async function getJupyterLabStatusAPI(
  action: ReturnType<typeof getJupyterLabStatus>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const programBatchId = getActiveProgramBatchId()

  const { embedCode, queryParams } = action.payload

  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/jupyter/notebook/${embedCode}/status?user_id=${id}&pb_id=${programBatchId}`,
    params: { signal },
    query: queryParams,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function startJupyterLabAPI(
  action: ReturnType<typeof startJupyterLab>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { embedCode, queryParams, file } = action.payload

  let body = null
  if (file) {
    body = new FormData()
    body.append('notebook_data', file.file, file.file.name)
  }

  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/jupyter/notebook/${embedCode}/start?user_id=${id}`,

    params: {
      method: 'POST',
      signal,
      body,
    },
    query: queryParams,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getJupyterLabDetailsAPI(
  action: ReturnType<typeof getJupyterLabDetails>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const programBatchId = getActiveProgramBatchId()

  const { embedCode, queryParams } = action.payload

  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/jupyter/notebook/${embedCode}/details?user_id=${id}&pb_id=${programBatchId}`,
    params: { signal },
    query: queryParams,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function stopJupyterLabAPI(
  action: ReturnType<typeof stopJupyterLab>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { embedCode } = action.payload

  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/jupyter/notebook/${embedCode}/stop?user_id=${id}`,
    params: {
      method: 'PUT',
      signal,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getJupyterNotebookAPI(
  action: ReturnType<typeof getJupyterNotebook>,
  signal: AbortSignal
) {
  const { embedCode } = action.payload

  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/jupyter/notebook/${embedCode}?user_id=${id}`,
    params: { signal },
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}
