import { call, put, takeLatest, select } from 'redux-saga/effects'
import { showAlertMessage } from 'web/providers/AlertsProvider'
import {
  fetchUserPaymentHistory,
  UserPaymentHistorySuccess,
  UserPaymentHistoryFailure,
  fetchUserPaymentReceipt,
} from './PaymentHistory.actions'
import { programSelectors } from '../../Dashboard/ProgramsProvider'
import { ProgramID } from '../../../../common/types/programs'

import * as APIs from './PaymentHistory.api'
import * as types from './PaymentHistory.types'

function* fetchPaymentHistoryHandler(
  action: ReturnType<typeof fetchUserPaymentHistory>
) {
  const abortController = new AbortController()

  const programId: ProgramID = yield select(programSelectors.getProgramId())
  const siblingBatchId: number = yield select(programSelectors.getSiblingBatchId())
  try {
    const data = yield call(
      APIs.getUserPaymentHistoryAPI,
      action,
      programId,
      siblingBatchId,
      abortController.signal
    )

    yield put(UserPaymentHistorySuccess(data))
  } catch (e) {
    yield put(UserPaymentHistoryFailure(e))
  }
}

function* fetchPaymentReceiptsHandler(
  action: ReturnType<typeof fetchUserPaymentReceipt>
) {
  const abortController = new AbortController()

  try {
    yield put(
      showAlertMessage({
        message: 'Please wait...',
        variant: 'info',
        closeOnTimeout: false,
      })
    )

    const data = yield call(
      APIs.getUserPaymentReceiptAPI,
      action,
      abortController.signal
    )

    yield put(
      showAlertMessage({
        variant: 'success',
        message: 'Payment receipt downloaded',
      })
    )
  } catch (e) {
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Unable to download Payment Receipt',
      })
    )
  }
}

export function* fetchPaymentHistoryMiddleware() {
  yield takeLatest(types.FETCH_USER_PAYMENT_HISTORY, fetchPaymentHistoryHandler)
}

export function* fetchPaymentReceiptsMiddleware() {
  yield takeLatest(
    types.FETCH_USER_PAYMENT_RECEIPT,
    fetchPaymentReceiptsHandler
  )
}
export default ([] as any).concat([
  fetchPaymentHistoryMiddleware(),
  fetchPaymentReceiptsMiddleware(),
])
