import {
  FETCH_ADMISSION_STATUS,
  FETCH_ADMISSION_STATUS_SUCCESS,
  FETCH_ADMISSION_STATUS_FAILURE,
  FETCH_ADMISSION_STATUS_CANCEL,
} from './UserAdmission.types'

export const fetchAdmissionStatus = () => ({
  type: FETCH_ADMISSION_STATUS as typeof FETCH_ADMISSION_STATUS,
})
export const fetchAdmissionStatusSuccess = (payload: any) => ({
  type: FETCH_ADMISSION_STATUS_SUCCESS as typeof FETCH_ADMISSION_STATUS_SUCCESS,
  payload,
})
export const fetchAdmissionStatusFailure = (payload: Error) => ({
  type: FETCH_ADMISSION_STATUS_FAILURE as typeof FETCH_ADMISSION_STATUS_FAILURE,
  payload,
})
export const fetchAdmissionStatusCancel = (payload: any) => ({
  type: FETCH_ADMISSION_STATUS_CANCEL as typeof FETCH_ADMISSION_STATUS_CANCEL,
  payload,
})

export type UserAdmissionActions =
  | ReturnType<typeof fetchAdmissionStatus>
  | ReturnType<typeof fetchAdmissionStatusSuccess>
  | ReturnType<typeof fetchAdmissionStatusFailure>
  | ReturnType<typeof fetchAdmissionStatusCancel>
