import { apiCall } from 'common/utils'
import { fetchLeaderboardContent } from '.'
import { UserID } from '../../../../common/types/user'

export async function getFslLeaderboardContentAPI(
  action: ReturnType<typeof fetchLeaderboardContent>,
  userId: UserID,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/learn_and_dev_users/${userId}/leaderboard`,
    query: {
      department_id: action.payload.departmentIds,
      sort_by: action.payload.sortBy,
      limit: action.payload.limit,
      name: action.payload.searchValue,
    },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
