import React, { MouseEventHandler } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { Backdrop } from '@material-ui/core'
import Dialog, { DialogTitle, DialogContent, DialogActions } from '../Dialog'
import styles from './ConfirmationDialog.module.css'
import Button from '../Button'

interface DialogContent {
  title: string
  body: string | React.ReactNode
  yesOption?: string
  noOption?: string
}

export interface ConfirmationDialogProps {
  dialogContent: DialogContent
  onClickNo?: MouseEventHandler
  onClickYes: MouseEventHandler
  disablePortal?: boolean
  btnStyle?: {}
  showBackdrop?: boolean
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const backdropProps = props.showBackdrop
    ? {
        BackdropComponent: Backdrop,
        BackdropProps: {
          style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
        },
      }
    : {}
  return (
    <Dialog
      open
      onClose={props.onClickNo}
      disablePortal={props.disablePortal || false}
      data-testid="confirmation-dialog-popup"
      {...backdropProps}
    >
      <DialogTitle>
        <Typography variant="h3">{props.dialogContent.title}</Typography>
      </DialogTitle>
      <DialogContent>
        {typeof props.dialogContent.body === 'string' ? (
          <Typography variant="subtitle1">
            {props.dialogContent.body}
          </Typography>
        ) : (
          props.dialogContent.body
        )}
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          {props.onClickNo ? (
            <Button
              className={styles.dialogButton}
              onClick={props.onClickNo}
              size="medium"
              color="default"
            >
              <Typography variant="subtitle2">
                {props.dialogContent.noOption || 'No'}
              </Typography>
            </Button>
          ) : null}
          <Button
            className={styles.dialogButton}
            onClick={props.onClickYes}
            size="large"
            color="primary"
            style={props.btnStyle ? props.btnStyle : undefined}
            // style={{backgroundColor: '#e51941'}}
            variant="contained"
            data-testid="confirmation-dialog-yes-button"
          >
            <Typography variant="subtitle2">
              {props.dialogContent.yesOption || 'Yes'}
            </Typography>
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
