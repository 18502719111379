import {
  FETCH_CITIES,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAILURE,
  FETCH_CITIES_CANCEL,
  FETCH_COLLEGES,
  FETCH_COLLEGES_SUCCESS,
  FETCH_COLLEGES_FAILURE,
  FETCH_COLLEGES_CANCEL,
  FETCH_UNIVERSITIES,
  FETCH_UNIVERSITIES_SUCCESS,
  FETCH_UNIVERSITIES_FAILURE,
  FETCH_UNIVERSITIES_CANCEL,
  RESET_COLLEGES,
} from './FreeUserInfoDialog.types'

interface CollegePayload {
  university_id?: number | null
}

export const fetchCities = () => ({
  type: FETCH_CITIES as typeof FETCH_CITIES,
})

export const fetchCitiesSuccess = (payload: any) => ({
  type: FETCH_CITIES_SUCCESS as typeof FETCH_CITIES_SUCCESS,
  payload,
})

export const fetchCitiesFailure = (payload: Error) => ({
  type: FETCH_CITIES_FAILURE as typeof FETCH_CITIES_FAILURE,
  payload,
})

export const fetchCitiesCancel = () => ({
  type: FETCH_CITIES_CANCEL as typeof FETCH_CITIES_CANCEL,
})

export const fetchColleges = (payload: CollegePayload) => ({
  type: FETCH_COLLEGES as typeof FETCH_COLLEGES,
  payload,
})

export const fetchCollegesSuccess = (payload: any) => ({
  type: FETCH_COLLEGES_SUCCESS as typeof FETCH_COLLEGES_SUCCESS,
  payload,
})

export const fetchCollegesFailure = (payload: Error) => ({
  type: FETCH_COLLEGES_FAILURE as typeof FETCH_COLLEGES_FAILURE,
  payload,
})

export const fetchCollegesCancel = () => ({
  type: FETCH_COLLEGES_CANCEL as typeof FETCH_COLLEGES_CANCEL,
})

export const resetColleges = () => ({
  type: RESET_COLLEGES as typeof RESET_COLLEGES,
})

export const fetchUniversities = () => ({
  type: FETCH_UNIVERSITIES as typeof FETCH_UNIVERSITIES,
})

export const fetchUniversitiesSuccess = (payload: any) => ({
  type: FETCH_UNIVERSITIES_SUCCESS as typeof FETCH_UNIVERSITIES_SUCCESS,
  payload,
})

export const fetchUniversitiesFailure = (payload: Error) => ({
  type: FETCH_UNIVERSITIES_FAILURE as typeof FETCH_UNIVERSITIES_FAILURE,
  payload,
})

export const fetchUniversitiesCancel = () => ({
  type: FETCH_UNIVERSITIES_CANCEL as typeof FETCH_UNIVERSITIES_CANCEL,
})

export type FreeUserInfoDialogActions =
  | ReturnType<typeof fetchCities>
  | ReturnType<typeof fetchCitiesSuccess>
  | ReturnType<typeof fetchCitiesFailure>
  | ReturnType<typeof fetchCitiesCancel>
  | ReturnType<typeof fetchColleges>
  | ReturnType<typeof fetchCollegesSuccess>
  | ReturnType<typeof fetchCollegesFailure>
  | ReturnType<typeof fetchCollegesCancel>
  | ReturnType<typeof resetColleges>
  | ReturnType<typeof fetchUniversities>
  | ReturnType<typeof fetchUniversitiesSuccess>
  | ReturnType<typeof fetchUniversitiesFailure>
  | ReturnType<typeof fetchUniversitiesCancel>
