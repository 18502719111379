import {
  put,
  cancelled,
  take,
  takeEvery,
  call,
  select,
  all,
} from 'redux-saga/effects'
import {
  CREATE_NOTE,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAILURE,
  CREATE_NOTE_CANCEL,
  FETCH_NOTE,
  FETCH_NOTE_CANCEL,
  FETCH_NOTE_FAILURE,
  FETCH_NOTE_SUCCESS,
  UPDATE_NOTE,
  UPDATE_NOTE_CANCEL,
  UPDATE_NOTE_FAILURE,
  UPDATE_NOTE_SUCCESS,
  DELETE_NOTE,
  DELETE_NOTE_CANCEL,
  DELETE_NOTE_FAILURE,
  DELETE_NOTE_SUCCESS,
} from './Notes.types'
import { cancelable, apiCall, getUserDetails } from '../../../../common/utils'
import { createNote, fetchNote, updateNote, deleteNote } from '.'
import {
  createNoteFailure,
  createNoteSuccess,
  createNoteCancel,
  fetchNoteFailure,
  fetchNoteSuccess,
  fetchNoteCancel,
  updateNoteFailure,
  updateNoteSuccess,
  updateNoteCancel,
  deleteNoteFailure,
  deleteNoteSuccess,
  deleteNoteCancel,
} from './Notes.actions'
import { UserDetailsState } from '../../User/UserDetailsProvider'
import { showAlertMessage } from '../../AlertsProvider'

export async function fetchNoteAPI(
  action: ReturnType<typeof fetchNote>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const query = {
    student_id: id,
    ...action.payload,
  }
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/notes`,
    params: {
      signal,
    },
    query,
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function createNoteAPI(
  action: ReturnType<typeof createNote>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const body = JSON.stringify(action.payload)
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/notes`,
    params: { signal, method: 'POST', body },
    query: {
      student_id: id,
    },
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function updateNoteAPI(
  action: ReturnType<typeof updateNote>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { note_id, description } = action.payload
  const body = JSON.stringify({
    description,
  })
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/notes/${note_id}`,
    params: { signal, method: 'POST', body },
    query: {
      student_id: id,
    },
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function deleteNoteAPI(
  action: ReturnType<typeof deleteNote>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { note_id } = action.payload
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/notes/${note_id}`,
    params: { signal, method: 'DELETE' },
    query: {
      student_id: id,
    },
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

function* fetchNoteHandler(action: ReturnType<typeof fetchNote>) {
  const abortController = new AbortController()
  try {
    const data = yield call(fetchNoteAPI, action, abortController.signal)
    yield put(fetchNoteSuccess(data))
  } catch (e) {
    yield put(fetchNoteFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* createNoteHandler(action: ReturnType<typeof createNote>) {
  const abortController = new AbortController()
  try {
    const data = yield call(createNoteAPI, action, abortController.signal)
    if (data.length) {
      yield put(createNoteSuccess(data[0]))
      yield put(
        showAlertMessage({
          variant: 'success',
          message: 'Note added successfully',
        })
      )
    }
  } catch (e) {
    yield put(createNoteFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Note creation failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* updateNoteHandler(action: ReturnType<typeof updateNote>) {
  const abortController = new AbortController()
  try {
    const data = yield call(updateNoteAPI, action, abortController.signal)
    if (!data.length) {
      const notes = yield select(state => state.notes.data)
      const updatedNotes = notes.map((note: any) => {
        if (note.id === action.payload.note_id) {
          note.description = action.payload.description
        }
        return note
      })
      yield put(updateNoteSuccess(updatedNotes))
      yield put(
        showAlertMessage({
          variant: 'success',
          message: 'Note updated successfully',
        })
      )
    }
  } catch (e) {
    yield put(updateNoteFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Note update failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* deleteNoteHandler(action: ReturnType<typeof deleteNote>) {
  const abortController = new AbortController()
  try {
    const data = yield call(deleteNoteAPI, action, abortController.signal)
    if (!data.length) {
      yield put(deleteNoteSuccess(action.payload.note_id))
      yield put(
        showAlertMessage({
          variant: 'success',
          message: 'Note deleted successfully',
        })
      )
    }
  } catch (e) {
    yield put(deleteNoteFailure(e))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Note delete failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fetchNoteMiddleware() {
  yield takeEvery(FETCH_NOTE, cancelable(fetchNoteHandler, [FETCH_NOTE_CANCEL]))
}

export function* createNoteMiddleware() {
  yield takeEvery(
    CREATE_NOTE,
    cancelable(createNoteHandler, [CREATE_NOTE_CANCEL])
  )
}

export function* updateNoteMiddleware() {
  yield takeEvery(
    UPDATE_NOTE,
    cancelable(updateNoteHandler, [UPDATE_NOTE_CANCEL])
  )
}

export function* deleteNoteMiddleware() {
  yield takeEvery(
    DELETE_NOTE,
    cancelable(deleteNoteHandler, [DELETE_NOTE_CANCEL])
  )
}

export default ([] as any).concat(
  fetchNoteMiddleware(),
  createNoteMiddleware(),
  updateNoteMiddleware(),
  deleteNoteMiddleware()
)
