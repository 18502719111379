import { Box, IconButton, Typography } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import React, { useState } from 'react'
import SnackbarStrip from '../SnackbarStrip'

const OnboardingVerificationPending = () => {
  const [open, setOpen] = useState(true)
  const onClose = () => {
    localStorage.setItem(
      'verificationPendingReminder',
      new Date().toDateString()
    )
    setOpen(false)
  }
  return (
    <SnackbarStrip
      message={
        <span id="client-snackbar">
          <Box display="flex">
            <Typography>
              Your profile is submitted for review. Our team will verify your
              details in the next 24-48 hours.
            </Typography>
          </Box>
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <Close />
        </IconButton>,
      ]}
      open={open}
    />
  )
}
export default OnboardingVerificationPending
