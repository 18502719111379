import { Reducer } from 'redux'
import {
  ProgramActions,
  UPDATE_ACTIVE_PROGRAM,
} from 'web/providers/Dashboard/ProgramsProvider'
import { TaxInfo } from 'common/types/payment'
import {
  FETCH_USER_PAYMENT,
  FETCH_USER_PAYMENT_SUCCESS,
  FETCH_USER_PAYMENT_FAILURE,
  UPDATE_RAZORPAY_STATUS,
  UPDATE_TAX_INFO,
} from './Payments.types'
import { PaymentsActions } from './Payments.actions'
import { UserPaymentsData } from '../../../../common/types/user'

export interface PaymentsState {
  paymentsInfo?: UserPaymentsData
  taxInfo?: TaxInfo
  loading: boolean
  error: false | Error
  load_status: boolean
}

const initialState: PaymentsState = {
  loading: false,
  error: false,
  load_status: false,
}

const paymentsReducer: Reducer<
  PaymentsState,
  PaymentsActions | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
> = (state = initialState, action): PaymentsState => {
  switch (action.type) {
    case FETCH_USER_PAYMENT:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case FETCH_USER_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentsInfo: {
          ...state.paymentsInfo,
          ...action.payload,
        },
        loading: false,
        error: false,
      }
    case FETCH_USER_PAYMENT_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      }
    case UPDATE_ACTIVE_PROGRAM:
      return initialState
    case UPDATE_RAZORPAY_STATUS:
      return {
        ...state,
        load_status: action.payload,
      }
    case UPDATE_TAX_INFO:
      return {
        ...state,
        taxInfo: action.payload,
      }
    default:
      return state
  }
}
export default paymentsReducer
