import { Page } from 'common/types/pages'
import { ProgramID } from 'common/types/programs'
import { apiCall, generateURL, getCookie } from 'common/utils'
import {
  isAcademyInternationalUser,
  isFreeUser,
} from 'common/utils/custom/user'
import { mixpanel } from 'common/utils/mixpanel'
import moment from 'moment'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showGLLoader } from 'web/providers/Common/Common.actions'
import { programSelectors } from 'web/providers/Dashboard/ProgramsProvider'
import qs from 'query-string'

export enum ProgramTextMappingKey {
  gradebook = 'gradebook',
  grade = 'grade',
  gradedQuiz = 'gradedQuiz',
}

export function useProfileDetails() {
  const profileDetails = useSelector(state => state.user.details.profile?.data)
  return profileDetails
}

export function useOlympusDetails() {
  const profileDetails = useProfileDetails()
  return profileDetails?.olympus_details
}

export function useFeatureLinksData() {
  const profileDetails = useProfileDetails()
  const featureLinks = profileDetails?.features?.feature_link

  return featureLinks
}

export function useDisabledFeatures() {
  const profileDetails = useProfileDetails()

  return profileDetails?.features?.disabled_features || []
}

export function useIsNewUIEnabledForBatch() {
  const profileDetails = useSelector(state => state.user.details.profile?.data)

  return (
    !!profileDetails?.features?.disabled_features &&
    !profileDetails?.features?.disabled_features.includes('new_ui')
  )
}

export function useIsNewUIEnabled() {
  const isNewUIEnabledForBatch = useIsNewUIEnabledForBatch()
  const profileDetails = useProfileDetails()
  const isNewUIPreferred =
    profileDetails?.features?.value_constraints?.is_new_ui_preferred
  const developerUiPreference = getCookie('developer_ui_preference')
  const currentDate = moment().tz(profileDetails?.time_zone)

  const deprecateOldUiDate = moment(profileDetails?.deprecate_old_ui_date).tz(
    profileDetails?.time_zone
  )

  if (developerUiPreference !== '') {
    return developerUiPreference === 'new'
  }

  return (
    isNewUIEnabledForBatch &&
    (currentDate.isBefore(deprecateOldUiDate) ? isNewUIPreferred === 1 : true)
  )
}

export function useIsOldAppBarPreferred() {
  return getCookie('is_old_app_bar_preferred') === 'true'
}

export function useShowNewNavigationBars() {
  const userDetails = useSelector(state => state.user.details)
  const { activeProgramID, byId } = useSelector(state => state.programs.data)
  const selectedBatch = byId?.[activeProgramID]

  const userAccessType = userDetails.accessType || selectedBatch?.access_type
  const isOldAppBarPreferred = useIsOldAppBarPreferred()
  const isNewUIEnabled = useIsNewUIEnabled()
  const isDigitalCampusUser = useSelector(state =>
    programSelectors.isDigitalCampusProgram()(state)
  )

  return (
    userAccessType !== 'freemium' &&
    userAccessType !== 'trial' &&
    userAccessType !== 'demo' &&
    userAccessType !== 'corporate' &&
    !isDigitalCampusUser &&
    !isFreeUser(userAccessType) &&
    !isAcademyInternationalUser(userAccessType) &&
    (isNewUIEnabled || !isOldAppBarPreferred)
  )
}

export function useIsExecutingInNativeApp() {
  return getCookie('access_from_mobile') === 'true'
}

export function useSelectedBatch() {
  const batches = useSelector(state => state.programs.data.batches)
  const activeProgramID = useSelector(
    state => state.programs.data.activeProgramID
  )
  const selectedBatch =
    batches.find(b => b.program_group_id == activeProgramID) ||
    batches.find(b => b.selected) ||
    {}

  return selectedBatch
}

export function useProgramTextMapping(key: ProgramTextMappingKey) {
  const selectedBatch = useSelectedBatch()
  switch (key) {
    case ProgramTextMappingKey.gradebook:
      return selectedBatch?.text_mapping?.[key] || 'Gradebook'
    case ProgramTextMappingKey.grade:
      return selectedBatch?.text_mapping?.[key] || 'Grade'
    case ProgramTextMappingKey.gradedQuiz: {
      const gradedQuizText = selectedBatch?.text_mapping?.[key]
      if (gradedQuizText.includes('Mandatory')) {
        return gradedQuizText
      }
      return 'Graded Assessment'
    }
    default:
      return 'Gradebook'
  }
}

export function useReloadPath() {
  const selectedBatch = useSelectedBatch()
  const activeProgramGroupId =
    sessionStorage.getItem('pb_id') ||
    selectedBatch?.program_group_id ||
    localStorage.getItem('pb_id')

  return <K extends Page['page'], T extends Extract<Page, { page: K }>>(
    type?: K,
    params?: T['location'],
    options?: { from?: string; pb_id?: ProgramID }
  ) => {
    let path
    if (type) {
      const location = generateURL(type, params === undefined ? null : params, {
        pb_id: activeProgramGroupId,
        ...options,
      })
      if (location.pathname) {
        path = `${location.pathname}?${location.search}`
      }
    } else {
      const urlParams = qs.parse(window.location.search)
      urlParams.pb_id = activeProgramGroupId
      path = `${window.location.pathname}?${qs.stringify(urlParams)}`
    }

    if (path) {
      window.location.href = path
    }
  }
}

export function useSwitchToNewUI() {
  const dispatch = useDispatch()
  const updateGLLoader = useCallback(data => dispatch(showGLLoader(data)), [
    dispatch,
  ])
  const userDetails = useSelector(state => state.user.details)
  const reloadPath = useReloadPath()

  const switchToNewUI = useCallback(async () => {
    try {
      updateGLLoader({ show: true })
      mixpanel.track('LMSPV - SwitchToNewUI_Click', {})

      const response = await apiCall({
        url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${userDetails.id}/ui_preference`,
        params: {
          method: 'PUT',
          body: JSON.stringify({ is_new_ui_preferred: 1 }),
        },
      })

      const result = await response.json()

      if (result && result.status === 'success') {
        reloadPath('DASHBOARD')
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('switch UI - api error', err)
    }
  }, [updateGLLoader, userDetails])

  return { switchToNewUI }
}
