/* eslint-disable no-param-reassign */
import {
  all,
  call,
  cancelled,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'
import { isExcelerateCourse } from 'common/utils/custom/courses'
import { CourseData } from 'common/types/courses'
import { ModuleID } from '../../../../common/types'
import { UserID } from '../../../../common/types/user'
import { cancelable, getAPIPaginationParams } from '../../../../common/utils'
import { AppState } from '../../../store'
import {
  BulkModuleItemsInsertParams,
  fetchModuleItems,
  moduleItemsAccessData,
  moduleItemsBulkInsert,
} from '../ModuleItemsProvider'
import { MODULE_ITEMS_FETCH_SUCCESS } from '../ModuleItemsProvider/ModuleItems.types'
import {
  fetchCourseAssetAccess,
  fetchCourseAssetAccessFailure,
  fetchCourseAssetAccessSuccess,
  fetchModules,
  fetchModulesFailure,
  fetchModulesSuccess,
} from './Modules.actions'
import { getCourseAssetAccessAPI, getModulesAPI } from './Modules.api'
import {
  FETCH_COURSE_ASSET_ACCESS,
  FETCH_COURSE_ASSET_ACCESS_CANCEL,
  MODULES_FETCH,
  MODULES_FETCH_CANCEL,
} from './Modules.types'
import {
  DashboardState,
  fetchDashboardContent,
} from '../../Dashboard/DashboardProvider'

const PAGE_SIZE = 50
function* getModulesHandler(action: ReturnType<typeof fetchModules>) {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data: any[] = yield call(
      getModulesAPI,
      { courseId: action.meta.courseId, userId },
      abortController.signal
    )
    const courseModulesList = yield select(
      (state: AppState) => state.modules.data.byCourse[action.meta.courseId]
    )
    const fetchAssetAccessData = courseModulesList === undefined

    if (data instanceof Array) {
      /** Accumulate all module Items grouped by module */
      const moduleItems: BulkModuleItemsInsertParams = data.reduce(
        (accumulator: BulkModuleItemsInsertParams, moduleData: any) => {
          accumulator[moduleData.id] = {
            moduleData,
            items: moduleData.items || [],
            count: moduleData.items_count || 0,
          }
          return accumulator
        },
        {}
      )

      const dashboardData: DashboardState['data'] = yield select(
        (state: AppState) => state.dashboard.data
      )

      const courseData: CourseData = yield select(
        (state: AppState) => state.courses.data.byId[action.meta.courseId]
      )

      if (
        (dashboardData &&
          dashboardData.orientation &&
          dashboardData.orientation.pending &&
          dashboardData.orientation.course_id === action.meta.courseId) ||
        (courseData && isExcelerateCourse(courseData))
      ) {
        let totalRequiredItemsToComplete = 0
        let totalCompletedItems = 0
        data.forEach((moduleData: any) => {
          let totalRequiredItems = 0
          let totalRequiredItemsCompleted = 0
          if (moduleData.items && moduleData.items.length) {
            totalRequiredItemsCompleted = moduleData.items.reduce(
              (acc: number, item: any) => {
                if (item.completion_requirement) {
                  totalRequiredItems += 1
                  if (item.completion_requirement.completed) return acc + 1
                }
                return acc + 0
              },
              0
            )
          }
          moduleData.itemsCompleted = totalRequiredItemsCompleted
          moduleData.itemsRequiredToComplete = totalRequiredItems

          totalRequiredItemsToComplete += totalRequiredItems
          totalCompletedItems += totalRequiredItemsCompleted
        })

        if (
          dashboardData &&
          dashboardData.orientation &&
          dashboardData.orientation.pending &&
          dashboardData.orientation.course_id === action.meta.courseId &&
          totalRequiredItemsToComplete === totalCompletedItems
        ) {
          yield put(fetchDashboardContent())
        }
      }

      /** Delete all module items from moduleData  */
      data.forEach((d, i) => {
        delete data[i].items
      })

      /** Push Module data in Redux state */
      yield put(fetchModulesSuccess(data, action.meta))

      /** Push Module items data in Redux state */
      yield put(moduleItemsBulkInsert(moduleItems))

      /** Aggregate all modules whose module items are not available */
      const moduleItemsMissingList: {
        id: ModuleID
        totalCount: number
        availableCount: number
      }[] = []
      Object.entries(moduleItems).forEach(([moduleId, moduleObj]) => {
        if (moduleObj.count > 0 && moduleObj.items.length !== moduleObj.count) {
          moduleItemsMissingList.push({
            id: moduleId,
            totalCount: moduleObj.count,
            availableCount: moduleObj.items.length,
          })
        }
      })

      /** Trigger data fetch for modules which have a module items count(>0) but missing in response  */
      yield all(
        ([] as any[]).concat(
          ...moduleItemsMissingList.map(moduleObj => {
            const pagesData = getAPIPaginationParams({
              availableCount: moduleObj.availableCount,
              pageSize: PAGE_SIZE,
              totalCount: moduleObj.totalCount,
            })
            return pagesData.map(pageData =>
              put(
                fetchModuleItems(
                  {
                    courseId: action.meta.courseId,
                    moduleId: moduleObj.id,
                    page: pageData.page,
                    per_page: pageData.per_page,
                    userId,
                    include: ['content_details'],
                  },
                  { courseId: action.meta.courseId, moduleId: moduleObj.id }
                )
              )
            )
          })
        )
      )

      yield all(
        ([] as any[]).concat(
          ...moduleItemsMissingList.map(obj => take(MODULE_ITEMS_FETCH_SUCCESS))
        )
      )

      if (fetchAssetAccessData) {
        yield put(fetchCourseAssetAccess(action.payload, action.meta))
      }
    } else {
      throw data
    }
  } catch (e) {
    if ('errors' in e)
      yield put(
        fetchModulesFailure(new Error(e.errors[0].message), action.meta)
      )
    else yield put(fetchModulesFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* getCourseAssetAccessHandler(
  action: ReturnType<typeof fetchCourseAssetAccess>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data = yield call(
      getCourseAssetAccessAPI,
      { courseId: action.meta.courseId, userId },
      abortController.signal
    )
    yield put(fetchCourseAssetAccessSuccess(data, action.meta))
    yield put(moduleItemsAccessData(data))
  } catch (e) {
    yield put(fetchCourseAssetAccessFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* getModulesMiddleware() {
  yield takeLatest(
    MODULES_FETCH,
    cancelable(getModulesHandler, MODULES_FETCH_CANCEL)
  )
  yield takeLatest(
    FETCH_COURSE_ASSET_ACCESS,
    cancelable(getCourseAssetAccessHandler, FETCH_COURSE_ASSET_ACCESS_CANCEL)
  )
}

export default ([] as any).concat(getModulesMiddleware())
