import { Category } from 'common/types/programSupport'

export const CATEGORIES: Category[] = [
  {
    id: 10,
    title: 'Fee related enquiries',
    subCategories: [
      {
        description: 'Payment not reflecting in Olympus',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Access blocked',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Make payment',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'I need help with something else',
        isCommunityQuestionCategory: false,
        tips: null,
      },
    ],
  },
  {
    id: 11,
    title: 'Olympus Issues',
    subCategories: [
      {
        description: 'Something is not working in Olympus App or Website',
        isCommunityQuestionCategory: false,
        tips: null,
        truncatedText: 'Something is not working',
      },
      {
        description: 'Access blocked',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'I need help with something else',
        isCommunityQuestionCategory: false,
        tips: null,
      },
    ],
  },
  {
    id: 12,
    title: 'Career Services',
    subCategories: [
      {
        description: 'Excelerate',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Career Prep',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'I need help with something else',
        isCommunityQuestionCategory: false,
        tips: null,
      },
    ],
  },

  {
    id: 13,
    title: 'Projects',
    subCategories: [
      {
        description: 'When will I receive the solution?',
        isCommunityQuestionCategory: false,
        tips: null,
        showCourse: true
      },
      {
        description: 'I need an explanation of the feedback & sample solution',
        isCommunityQuestionCategory: false,
        tips: 'Mention the project name and the section',
        truncatedText: 'I need further explanation',
        isFaq: true,
        isAutoReply: true,
        showCourse: true
      },
      {
        description: 'I need help with project re-evaluation',
        isCommunityQuestionCategory: false,
        tips: 'Mention the project name and the section',
        truncatedText: 'I need detailed feedback',
        isFaq: true,
        showCourse: true,
      },
      {
        description: 'I am facing technical challenges with Olympus',
        isCommunityQuestionCategory: false,
        tips: null,
        showCourse: true
      },
      {
        description:
          'I need further explanation of the problem statement/dataset',
        isCommunityQuestionCategory: false,
        tips: "Please follow the tips in 'Help us help you' section",
        isFaq: true,
        showCourse: true,
      },
      {
        description: 'I want to extend my submission deadline',
        isCommunityQuestionCategory: false,
        tips: 'Requests are considered only in the case of emergencies',
        isAutoExtension: true,
      },
      {
        description: 'Error/stuck with code',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
        showCourse: true,
      },
      {
        description: 'I have a conceptual doubt',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
        showCourse: true,
      },
      {
        description: 'I need help with something else',
        isCommunityQuestionCategory: false,
        tips: null,
        isFaq: true,
      },
    ],
  },
  {
    id: 14,
    title: 'Learning Material',
    subCategories: [
      {
        description: 'Something incorrect in the content',
        isCommunityQuestionCategory: false,
        tips:
          'Please share the url of the content (video/readable/link) that you are referring to',
        isFaq: true,
        showCourse: true,
      },
      {
        description: 'I want to understand a topic better',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
        truncatedText: 'I want to understand a topic',
        showCourse: true,
      },
      {
        description: 'I am looking for reference material on a topic',
        isCommunityQuestionCategory: false,
        tips:
          'Please ensure that you have checked the additional content shared on Olympus',
        truncatedText: 'I am looking for references',
        isFaq: true,
        showCourse: true,
      },
      {
        description:
          'I am facing challenges with software/library installation used in the course',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
        showCourse: true,
      },
      {
        description: 'Error/stuck with code',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
        showCourse: true,
      },
      {
        description: 'I have a conceptual doubt',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
        showCourse: true,
      },
      {
        description: 'I need help with something else',
        isCommunityQuestionCategory: false,
        tips: null,
        isFaq: true,
      },
    ],
  },
  {
    id: 15,
    title: 'Live Session',
    subCategories: [
      {
        description: 'Cannot find session recordings on Olympus',
        isCommunityQuestionCategory: false,
        tips: 'Please share the details of the session.',
        truncatedText: 'Cannot find session recordings',
      },
      {
        description: 'Cannot find session material',
        isCommunityQuestionCategory: false,
        tips: 'Please share the details of the session',
        showCourse: true,
      },
      {
        description: 'Need help with my schedule',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'I need help with something else',
        isCommunityQuestionCategory: false,
        tips: null,
      },
    ],
  },
  {
    id: 16,
    title: 'Quizzes',
    subCategories: [
      {
        description: 'Error/stuck with code',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
        showCourse: true
      },
      {
        description: 'I have a conceptual doubt',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
        showCourse: true
      },
      {
        description: 'Quiz question is unclear',
        isCommunityQuestionCategory: false,
        tips:
          'Please let us know exactly which quiz and question you are skeptical about',
          showCourse: true
      },
      {
        description: 'Need more explanation for the right answer',
        isCommunityQuestionCategory: false,
        tips: 'Please let us know the exact quiz question',
        isAutoReply: true,
        showCourse: true
      },
      {
        description: 'I want to request an extension for the deadline',
        isCommunityQuestionCategory: false,
        isAutoExtension: true,
        tips:
          'Please note that these requests are considered only in the case of emergencies',
      },
      {
        description: 'I am facing technical issues while attempting the quiz',
        isCommunityQuestionCategory: false,
        tips: null,
        showCourse: true
      },
      {
        description: 'I need help with something else',
        isCommunityQuestionCategory: false,
        tips: null,
        isFaq: true,
      },
    ],
  },
  {
    id: 17,
    title: 'Others',
    subCategories: [
      {
        description: 'Hackathon',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Graduation/Certificate Related',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Inquire about other programs',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Error/stuck with code',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
      },
      {
        description:
          'I am facing challenges with software/library installation used in the course',
        isCommunityQuestionCategory: true,
        tips: null,
      },
      {
        description: 'I have a conceptual doubt',
        isCommunityQuestionCategory: true,
        tips: null,
      },
      {
        description: 'Delivery Schedule',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Referral bonus',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'I need help with something else',
        isCommunityQuestionCategory: false,
        tips: null,
      },
    ],
  },
  {
    id: 18,
    title: 'Feedback',
    subCategories: [
      {
        description: 'Share a Testimonial',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Content Feedback',
        isCommunityQuestionCategory: false,
        tips: 'Please share the url of the content you are referring to',
        showCourse: true
      },
      {
        description: 'Program Feedback',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Program Office Feedback',
        isCommunityQuestionCategory: false,
        tips: null,
      },
    ],
  },
]

export const OTHER_CATEGORIES: Category[] = [
  {
    id: 10,
    title: 'Fee related enquiries',
    subCategories: [
      {
        description: 'Payment not reflecting in Olympus',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Access blocked',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Make payment',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'I need help with something else',
        isCommunityQuestionCategory: false,
        tips: null,
      },
    ],
  },
  {
    id: 11,
    title: 'Olympus Issues',
    subCategories: [
      {
        description: 'Something is not working in Olympus App or Website',
        isCommunityQuestionCategory: false,
        tips: null,
        truncatedText: 'Something is not working',
      },
      {
        description: 'Access blocked',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'I need help with something else',
        isCommunityQuestionCategory: false,
        tips: null,
      },
    ],
  },
  {
    id: 13,
    title: 'Projects',
    subCategories: [
      {
        description: 'When will I receive the solution?',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'I need an explanation of the feedback & sample solution',
        isCommunityQuestionCategory: false,
        tips: 'Mention the project name and the section',
        truncatedText: 'I need further explanation',
        isFaq: true,
        isAutoReply: true,
      },
      {
        description: 'I need help with project re-evaluation',
        isCommunityQuestionCategory: false,
        tips: 'Mention the project name and the section',
        truncatedText: 'I need detailed feedback',
        isFaq: true,
      },
      {
        description: 'I am facing technical challenges with Olympus',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description:
          'I need further explanation of the problem statement/dataset',
        isCommunityQuestionCategory: false,
        tips: "Please follow the tips in 'Help us help you' section",
        isFaq: true,
      },
      {
        description: 'I want to extend my submission deadline',
        isCommunityQuestionCategory: false,
        tips: 'Requests are considered only in the case of emergencies',
      },
      {
        description: 'Error/stuck with code',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
      },
      {
        description: 'I have a conceptual doubt',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
      },
      {
        description: 'I need help with something else',
        isCommunityQuestionCategory: false,
        tips: null,
        isFaq: true,
      },
    ],
  },
  {
    id: 14,
    title: 'Learning Material',
    subCategories: [
      {
        description: 'Something incorrect in the content',
        isCommunityQuestionCategory: false,
        tips:
          'Please share the url of the content (video/readable/link) that you are referring to',
        isFaq: true,
      },
      {
        description:
          'I want to understand a topic in the video or other material',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
        truncatedText: 'I want to understand a topic',
      },
      {
        description: 'I am looking for reference material on a topic',
        isCommunityQuestionCategory: false,
        tips:
          'Please ensure that you have checked the additional content shared on Olympus',
        truncatedText: 'I am looking for references',
        isFaq: true,
      },
      {
        description:
          'I am facing challenges with software/library installation used in the course',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
      },
      {
        description: 'Error/stuck with code',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
      },
      {
        description: 'I have a conceptual doubt',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
      },
      {
        description: 'I need help with something else',
        isCommunityQuestionCategory: false,
        tips: null,
        isFaq: true,
      },
    ],
  },
  {
    id: 15,
    title: 'Live Session',
    subCategories: [
      {
        description: 'Cannot find session recordings on Olympus',
        isCommunityQuestionCategory: false,
        tips: 'Please share the details of the session.',
        truncatedText: 'Cannot find session recordings',
      },
      {
        description: 'Cannot find session material',
        isCommunityQuestionCategory: false,
        tips: 'Please share the details of the session',
      },
      {
        description: 'Need help with my schedule',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'I need help with something else',
        isCommunityQuestionCategory: false,
        tips: null,
      },
    ],
  },
  {
    id: 16,
    title: 'Course assessment',
    subCategories: [
      {
        description: 'Error/stuck with code',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
      },
      {
        description: 'I have a conceptual doubt',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
      },
      {
        description: 'Course assessment question is unclear',
        isCommunityQuestionCategory: false,
        tips:
          'Please let us know exactly which quiz and question you are skeptical about',
      },
      {
        description: 'Need more explanation for the right answer',
        isCommunityQuestionCategory: false,
        tips: 'Please let us know the exact course assessment question',
        isAutoReply: true,
      },
      {
        description: 'I want request an extension for the deadline',
        isCommunityQuestionCategory: false,
        tips:
          'Please note that these requests are considered only in the case of emergencies',
      },
      {
        description:
          'I am facing technical issues while attempting the course assessment',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'I need help with something else',
        isCommunityQuestionCategory: false,
        tips: null,
        isFaq: true,
      },
    ],
  },
  {
    id: 17,
    title: 'Others',
    subCategories: [
      {
        description: 'Hackathon',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Certificate Related',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Inquire about other programs',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Error/stuck with code',
        isCommunityQuestionCategory: true,
        tips: "Please follow the tips in 'Help us help you' section",
      },
      {
        description:
          'I am facing challenges with software/library installation used in the course',
        isCommunityQuestionCategory: true,
        tips: null,
      },
      {
        description: 'I have a conceptual doubt',
        isCommunityQuestionCategory: true,
        tips: null,
      },
      {
        description: 'Delivery Schedule',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Referral bonus',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'I need help with something else',
        isCommunityQuestionCategory: false,
        tips: null,
      },
    ],
  },
  {
    id: 18,
    title: 'Feedback',
    subCategories: [
      {
        description: 'Share a Testimonial',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Content Feedback',
        isCommunityQuestionCategory: false,
        tips: 'Please share the url of the content you are referring to',
      },
      {
        description: 'Program Feedback',
        isCommunityQuestionCategory: false,
        tips: null,
      },
      {
        description: 'Program Office Feedback',
        isCommunityQuestionCategory: false,
        tips: null,
      },
    ],
  },
]

export const FAQ_CONTEXT_TYPES = ['ACADEMIC', 'TECHONCALL']

export const EXTENSION_REASONS = [
  'Health Issues',
  'Personal Commitments',
  'Professional Commitments',
  'Others',
]

export const CATEGORY_ID_TO_NAME_MAPPING = {
  '10': 'Fee related enquiries',
  '11': 'Olympus Issues',
  '12': 'Career Services',
  '13': 'Projects',
  '14': 'Learning Material',
  '15': 'Live Session',
  '16': 'Quizzes',
  '17': 'Others',
  '18': 'Feedback',
  '1': 'Learning Content',
  '9': 'Academic Query',
  '2': 'Assessments/Labs',
  '3': 'Capstone Project',
  '4': 'Payments',
  '5': 'Career Support',
  '7': 'Referrals',
  '8': 'Olympus Technical Issue',
  '6': 'Others',
}

export const B2B_DISABLE_CATEGORIES_IDS = {
  10: 'Fee related enquiries',
  12: 'Career Services',
}

export const ALLOWED_HTML_TAGS = [
  'b',
  'p',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'pre',
  'ul',
  'ol',
  'li',
  'code',
  'br',
  'hr',
  'i',
  'u',
  'strike',
  'a',
]

export const FORBID_ATTRS = [
  'style',
  'onload',
  'onerror',
  'onmouseover',
  'onmouseout',
  'expression',
]
