import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { showGLLoader as showGLLoaderAction } from 'web/providers/Common/Common.actions'

const NinjaLayout = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch()
  const showGLLoader = useCallback(
    data => dispatch(showGLLoaderAction(data)),
    []
  )

  useEffect(() => {
    showGLLoader({ show: false })
  }, [])

  return <>{children}</>
}

export default NinjaLayout
