export const COLOR_GRADIENTS = [
  'linear-gradient(133deg, #fdeb71, #f8d800 100%)',
  'linear-gradient(133deg, #abdcff, #0396ff 100%)',
  'linear-gradient(133deg, #feb692, #ea5455 100%)',
  'linear-gradient(133deg, #ce9ffc, #7367f0 100%)',
  'linear-gradient(133deg, #90f7ec, #32ccbc 99%)',
  'linear-gradient(133deg, #fff6b7, #f6416c 100%)',
  'linear-gradient(133deg, #81fbb8, #28c76f 99%)',
  'linear-gradient(133deg, #e2b0ff, #9f44d3 100%)',
  'linear-gradient(133deg, #f97794, #623aa2 99%)',
  'linear-gradient(133deg, #fccf31, #f55555 100%)',
  'linear-gradient(133deg, #f761a1, #8c1bab 98%)',
  'linear-gradient(133deg, #43cbff, #9708cc 100%)',
  'linear-gradient(133deg, #5efce8, #736efe 100%)',
  'linear-gradient(133deg, #fad7a1, #e96d71 100%)',
  'linear-gradient(133deg, #ffd26f, #3677ff 99%)',
  'linear-gradient(133deg, #a0fe65, #fa016d 100%)',
  'linear-gradient(133deg, #ffdb01, #0e197d 100%)',
  'linear-gradient(133deg, #fec163, #de4313 100%)',
  'linear-gradient(133deg, #92ffc0, #002661 100%)',
  'linear-gradient(133deg, #eead92, #6018dc 100%)',
  'linear-gradient(133deg, #f05f57, #360940 100%)',
  'linear-gradient(133deg, #f6ceec, #d939cd 100%)',
  'linear-gradient(133deg, #52e5e7, #130cb7 100%)',
  'linear-gradient(133deg, #f1ca74, #a64db6 100%)',
  'linear-gradient(133deg, #e8d07a, #5312d6 100%)',
  'linear-gradient(133deg, #eece13, #b210ff 100%)',
  'linear-gradient(133deg, #79f1a4, #0e5cad 100%)',
  'linear-gradient(133deg, #fdd819, #e80505 100%)',
  'linear-gradient(133deg, #fff3b0, #ca26ff 100%)',
  'linear-gradient(133deg, #fff5c3, #9452a5 100%)',
  'linear-gradient(133deg, #2afadf, #4c83ff 100%)',
  'linear-gradient(133deg, #fff886, #f072b6 100%)',
  'linear-gradient(133deg, #97abff, #123597 100%)',
  'linear-gradient(133deg, #f5cbff, #c346c2 98%)',
  'linear-gradient(133deg, #fff720, #3cd500 99%)',
  'linear-gradient(133deg, #ff6fd8, #3813c2 98%)',
  'linear-gradient(133deg, #ee9ae5, #5961f9 97%)',
  'linear-gradient(133deg, #ffd3a5, #fd6585 100%)',
  'linear-gradient(133deg, #c2ffd8, #465efb 99%)',
  'linear-gradient(133deg, #fd6585, #0d25b9 100%)',
  'linear-gradient(133deg, #fd6e6a, #ffc600 98%)',
  'linear-gradient(133deg, #65fdf0, #1d6fa3 100%)',
  'linear-gradient(133deg, #6b73ff, #000dff 98%)',
  'linear-gradient(133deg, #ff7af5, #513162 100%)',
  'linear-gradient(133deg, #f0ff00, #58cffb 99%)',
  'linear-gradient(133deg, #ffe985, #fa742b 99%)',
  'linear-gradient(133deg, #ffa6b7, #1e2ad2 100%)',
  'linear-gradient(133deg, #ffaa85, #b3315f 98%)',
  'linear-gradient(133deg, #72edf2, #5151e5 98%)',
  'linear-gradient(133deg, #ff9d6c, #bb4e75 97%)',
  'linear-gradient(133deg, #f6d242, #ff52e5 100%)',
  'linear-gradient(133deg, #69ff97, #00e4ff 98%)',
  'linear-gradient(133deg, #3b2667, #bc78ec 100%)',
  'linear-gradient(133deg, #70f570, #49c628 100%)',
  'linear-gradient(133deg, #3c8ce7, #00eaff 100%)',
  'linear-gradient(133deg, #fab2ff 4%, #1904e5 100%)',
  'linear-gradient(133deg, #81ffef, #f067b4 100%)',
  'linear-gradient(133deg, #ffa8a8 2%, #fcff00 100%)',
  'linear-gradient(133deg, #ffcf71, #2376dd 100%)',
  'linear-gradient(133deg, #ff96f9, #c32bac 100%)',
] as const

export type COLOR_GRADIENT_TYPES = typeof COLOR_GRADIENTS[number]

export const getRandomColorGradient = () =>
  COLOR_GRADIENTS[Math.floor(Math.random() * COLOR_GRADIENTS.length)]
