export const FETCH_HACKATHON_LIST = 'FETCH_HACKATHON_LIST'
export const FETCH_HACKATHON_LIST_SUCCESS = 'FETCH_HACKATHON_LIST_SUCCESS'
export const FETCH_HACKATHON_LIST_FAILURE = 'FETCH_HACKATHON_LIST_FAILURE'
export const FETCH_HACKATHON_LIST_CANCEL = 'FETCH_HACKATHON_LIST_CANCEL'

export const FETCH_HACKATHON_DETAILS = 'FETCH_HACKATHON_DETAILS'
export const FETCH_HACKATHON_DETAILS_SUCCESS = 'FETCH_HACKATHON_DETAILS_SUCCESS'
export const FETCH_HACKATHON_DETAILS_FAILURE = 'FETCH_HACKATHON_DETAILS_FAILURE'
export const FETCH_HACKATHON_DETAILS_CANCEL = 'FETCH_HACKATHON_DETAILS_CANCEL'

export const REGISTER_HACKATHON_TEAM = 'REGISTER_HACKATHON_TEAM'
export const REGISTER_HACKATHON_TEAM_SUCCESS = 'REGISTER_HACKATHON_TEAM_SUCCESS'
export const REGISTER_HACKATHON_TEAM_FAILURE = 'REGISTER_HACKATHON_TEAM_FAILURE'
export const REGISTER_HACKATHON_TEAM_CANCEL = 'REGISTER_HACKATHON_TEAM_CANCEL'

export const ADD_MEMBERS_HACKATHON_TEAM = 'ADD_MEMBERS_HACKATHON_TEAM'
export const ADD_MEMBERS_HACKATHON_TEAM_SUCCESS =
  'ADD_MEMBERS_HACKATHON_TEAM_SUCCESS'
export const ADD_MEMBERS_HACKATHON_TEAM_FAILURE =
  'ADD_MEMBERS_HACKATHON_TEAM_FAILURE'
export const ADD_MEMBERS_HACKATHON_TEAM_CANCEL =
  'ADD_MEMBERS_HACKATHON_TEAM_CANCEL'

export const SUBMIT_HACKATHON_SOLUTION = 'SUBMIT_HACKATHON_SOLUTION'
export const SUBMIT_HACKATHON_SOLUTION_SUCCESS =
  'SUBMIT_HACKATHON_SOLUTION_SUCCESS'
export const SUBMIT_HACKATHON_SOLUTION_FAILURE =
  'SUBMIT_HACKATHON_SOLUTION_FAILURE'
export const SUBMIT_HACKATHON_SOLUTION_CANCEL =
  'SUBMIT_HACKATHON_SOLUTION_CANCEL'

export const FETCH_HACKATHON_LEADERBOARD = 'FETCH_HACKATHON_LEADERBOARD'
export const FETCH_HACKATHON_LEADERBOARD_SUCCESS =
  'FETCH_HACKATHON_LEADERBOARD_SUCCESS'
export const FETCH_HACKATHON_LEADERBOARD_FAILURE =
  'FETCH_HACKATHON_LEADERBOARD_FAILURE'
export const FETCH_HACKATHON_LEADERBOARD_CANCEL =
  'FETCH_HACKATHON_LEADERBOARD_CANCEL'

export const FETCH_HACKATHON_TEAM_PERFORMANCE =
  'FETCH_HACKATHON_TEAM_PERFORMANCE'
export const FETCH_HACKATHON_TEAM_PERFORMANCE_SUCCESS =
  'FETCH_HACKATHON_TEAM_PERFORMANCE_SUCCESS'
export const FETCH_HACKATHON_TEAM_PERFORMANCE_FAILURE =
  'FETCH_HACKATHON_TEAM_PERFORMANCE_FAILURE'
export const FETCH_HACKATHON_TEAM_PERFORMANCE_CANCEL =
  'FETCH_HACKATHON_TEAM_PERFORMANCE_CANCEL'
