export const FETCH_ALL_POLLS = 'FETCH_ALL_POLLS'
export const FETCH_ALL_POLLS_SUCCESS = 'FETCH_ALL_POLLS_SUCCESS'
export const FETCH_ALL_POLLS_FAILURE = 'FETCH_ALL_POLLS_FAILURE'
export const FETCH_ALL_POLLS_CANCEL = 'FETCH_ALL_POLLS_CANCEL'

export const FETCH_POLL_BY_ID = 'FETCH_POLL_BY_ID'
export const FETCH_POLL_BY_ID_SUCCESS = 'FETCH_POLL_BY_ID_SUCCESS'
export const FETCH_POLL_BY_ID_FAILURE = 'FETCH_POLL_BY_ID_FAILURE'
export const FETCH_POLL_BY_ID_CANCEL = 'FETCH_POLL_BY_ID_CANCEL'

export const UPDATE_OPTION_PREFERENCE = 'UPDATE_OPTION_PREFERENCE'
export const UPDATE_POLL_UNREAD_COUNT = 'UPDATE_POLL_UNREAD_COUNT '
