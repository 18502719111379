import {
  FETCH_COURSE_PROGRESS_CONTENT,
  FETCH_COURSE_PROGRESS_CONTENT_FAILURE,
  FETCH_COURSE_PROGRESS_CONTENT_SUCCESS,
  FETCH_COURSE_PROGRESS_CONTENT_CANCEL,
} from './CourseProgress.types'

export const fetchCourseProgressContent = () => ({
  type: FETCH_COURSE_PROGRESS_CONTENT as typeof FETCH_COURSE_PROGRESS_CONTENT,
})
export const fetchCourseProgressContentSuccess = (payload: any) => ({
  type: FETCH_COURSE_PROGRESS_CONTENT_SUCCESS as typeof FETCH_COURSE_PROGRESS_CONTENT_SUCCESS,
  payload,
})
export const fetchCourseProgressContentFailure = (payload: Error) => ({
  type: FETCH_COURSE_PROGRESS_CONTENT_FAILURE as typeof FETCH_COURSE_PROGRESS_CONTENT_FAILURE,
  payload,
})
export const fetchCourseProgressContentCancel = () => ({
  type: FETCH_COURSE_PROGRESS_CONTENT_CANCEL as typeof FETCH_COURSE_PROGRESS_CONTENT_CANCEL,
})

export type CourseProgressActions =
  | ReturnType<typeof fetchCourseProgressContent>
  | ReturnType<typeof fetchCourseProgressContentSuccess>
  | ReturnType<typeof fetchCourseProgressContentFailure>
  | ReturnType<typeof fetchCourseProgressContentCancel>
