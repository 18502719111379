import React, { useEffect, useState } from 'react'
import { IconButton, Typography, Box } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { generateURL } from 'common/utils'
import { ProgramAdmissionData } from '../../../../common/types/admission'
import { paymentInfoMessage } from '../../../../common/utils/custom/feepayment'
import Button from '../../Utils/Button'
import SnackbarStrip from '../SnackbarStrip'
import styles from '../Header.module.css'
import { ProgramState } from '../../../providers/Dashboard/ProgramsProvider'

interface UpcomingPaymentReminderProps {
  programAdmissionData: ProgramAdmissionData
  activeProgramId: ProgramState['data']['activeProgramID']
}

const UpcomingPaymentReminder = (props: UpcomingPaymentReminderProps) => {
  const [message, setMessage] = useState('')
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const messageText = paymentInfoMessage(
      props.programAdmissionData,
      'upcoming_installments'
    )
    setMessage(`${messageText}.`)
    setOpen(true)
  }, [])

  const onClose = () => {
    localStorage.setItem(
      `paymentReminder-${props.activeProgramId}`,
      new Date().toDateString()
    )
    setOpen(false)
  }

  return (
    <SnackbarStrip
      message={
        <span id="client-snackbar">
          <Box display="flex">
            <Typography> {message}</Typography>
            <Link to={generateURL('FEE_PAYMENT', null)} className={styles.link}>
              <Button color="primary" className={styles.innerLink}>
                Make Payment
              </Button>
            </Link>
          </Box>
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <Close />
        </IconButton>,
      ]}
      open={open}
    />
  )
}
export default UpcomingPaymentReminder
