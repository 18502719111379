import { CourseFeedbackContent } from 'common/types/feedback'
import { UserID } from 'common/types/user'
import {
  IPeerInfo,
  PARTICIPANT_ROLES,
  ZoomoutApiErrors,
  ZoomoutApiStatuses,
} from 'common/types/zoomout'
import { uuidv4 } from 'common/utils'
import { getRandomColorGradient } from 'common/utils/colorGradients'
import { isItUITrialTime } from 'common/utils/custom/Zoomout/methods/joinMeeting'
import { Reducer } from 'redux'
import { ZoomoutActions } from './Zoomout.actions'
import {
  FETCH_MEETING_DETAILS,
  FETCH_MEETING_DETAILS_FAILURE,
  FETCH_MEETING_DETAILS_SUCCESS,
  FETCH_SCREEN_SHARING_INFO,
  FETCH_UNKNOWN_PEER_DETAILS_SUCCESS,
  FETCH_UNKNOWN_PEER_DETAILS,
  FETCH_ATTENDANCE_SUCCESS,
} from './Zoomout.types'

const getRole = (role: string) => {
  switch (role) {
    case 'mentor':
      return PARTICIPANT_ROLES.MENTOR
    case 'pm':
      return PARTICIPANT_ROLES.PROGRAM_MANAGER
    case 'learner':
      return PARTICIPANT_ROLES.LEARNER
    default:
      return PARTICIPANT_ROLES.LEARNER
  }
}

export interface ZoomoutExternalState {
  sessionId: string
  apiStatus: ZoomoutApiStatuses
  isRecordingEnabled: boolean
  joiningDetails?: {
    role: PARTICIPANT_ROLES
    channel: string
    appId: string
    encryptionSecret: string
    main: {
      uid: number
      rtcToken: string
      rtmToken: string
      tokenExpiresAt: Date
    }
    screen?: {
      uid: number
      rtcToken: string
      tokenExpiresAt: Date
    }
  }
  meetingTopic?: string
  mentoredGroupSessionId?: number
  timingDetails?: {
    UITrialMinutes: number
    softStartTime: Date
    hardStartTime: Date
    softEndTime: Date
    hardEndTime: Date
  }
  peerDetails: Record<number, IPeerInfo>
  error?: {
    type: ZoomoutApiErrors
    description?: string
    joinBufferMinutes?: number
    startTime?: Date
    endTime?: Date
  }
  eventMapping: Record<string, number>
  feedbackData?: CourseFeedbackContent
}

export const initialState: ZoomoutExternalState = {
  sessionId: '',
  apiStatus: 'loading',
  isRecordingEnabled: true,
  peerDetails: {},
  eventMapping: {},
}

const normalizedData = (
  rawData: any,
  channel: string
): ZoomoutExternalState => {
  const {
    data: {
      joiningData: {
        role,
        appId,
        isRecordingEnabled,
        encryptionSecret,
        mentoredGroupSessionId,
        topic: meetingTopic,
        main,
        timing: {
          UITrialMinutes,
          softStartTime,
          hardStartTime,
          softEndTime,
          hardEndTime,
        },
      },
      peerDetails,
      eventMapping,
    },
  } = rawData

  const parsedRole = getRole(role)
  // 0: {id: 1, event_name: "MUTE_MIC_BROADCAST"}
  return {
    sessionId: uuidv4(),
    joiningDetails: {
      role: parsedRole,
      channel,
      appId,
      encryptionSecret,
      main,
    },
    isRecordingEnabled:
      parsedRole !== PARTICIPANT_ROLES.LEARNER &&
      isItUITrialTime({
        softStartTime: new Date(softStartTime),
        now: new Date(),
        UITrialMinutes,
      })
        ? false
        : isRecordingEnabled,
    mentoredGroupSessionId,
    apiStatus: 'success',
    meetingTopic,
    timingDetails: {
      UITrialMinutes,
      softStartTime: new Date(softStartTime),
      hardStartTime: new Date(hardStartTime),
      hardEndTime: new Date(hardEndTime),
      softEndTime: new Date(softEndTime),
    },
    peerDetails: peerDetails.reduce(
      (
        acc: Required<ZoomoutExternalState>['peerDetails'],
        peer: Omit<IPeerInfo, 'lmsUserId' | 'isScreen'> & {
          lms_user_id: UserID
          is_screen: boolean
        }
      ) => ({
        ...acc,
        [peer.uid]: {
          uid: peer.uid,
          isSelf: peer.uid === main.uid,
          lmsUserId: peer.lms_user_id,
          name: (peer.name as string).trim(),
          role: peer.role,
          profilePictureUrl: peer.profilePictureUrl,
          isScreen: peer.is_screen,
          color: getRandomColorGradient(),
        },
      }),
      {}
    ),
    eventMapping: eventMapping.reduce(
      (m: Record<string, number>, val: { id: number; event_name: string }) => ({
        ...m,
        [val.event_name]: val.id,
      }),
      {}
    ),
  }
}

const zoomoutReducer: Reducer<ZoomoutExternalState, ZoomoutActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_MEETING_DETAILS_SUCCESS: {
      return normalizedData(action.meta, action.payload.channel)
    }
    case FETCH_MEETING_DETAILS_FAILURE: {
      return {
        sessionId: uuidv4(),
        apiStatus: 'error',
        isRecordingEnabled: false,
        peerDetails: {},
        error: {
          type: action.meta.type || 'unknownError',
          description: action.meta.topic || action.meta.description,
          joinBufferMinutes: action.meta.joinBufferMinutes,
          startTime: new Date(action.meta.startTime),
          endTime: new Date(action.meta.endTime),
        },
        eventMapping: {},
      }
    }
    case FETCH_SCREEN_SHARING_INFO: {
      const {
        data: {
          uid,
          screenRtcToken: rtcToken,
          screenTokenExpiresAt: tokenExpiresAt,
        },
      } = action.payload

      const newState = { ...state }

      if (newState.joiningDetails)
        newState.joiningDetails = {
          ...newState.joiningDetails,
          screen: {
            uid,
            rtcToken,
            tokenExpiresAt,
          },
        }
      return newState
    }
    case FETCH_UNKNOWN_PEER_DETAILS_SUCCESS: {
      const {
        data: { 0: unknownPeer },
      }: {
        data: (Omit<IPeerInfo, 'lmsUserId' | 'isScreen'> & {
          lms_user_id: UserID
          is_screen: boolean
        })[]
      } = action.meta

      const isSelf =
        unknownPeer.uid === ((state.joiningDetails || {}).main || {}).uid

      return {
        ...state,
        peerDetails: {
          ...state.peerDetails,
          [unknownPeer.uid]: {
            uid: unknownPeer.uid,
            lmsUserId: unknownPeer.lms_user_id,
            isSelf,
            name: unknownPeer.name,
            role: unknownPeer.role,
            profilePictureUrl: unknownPeer.profilePictureUrl,
            isScreen: unknownPeer.is_screen,
            color: getRandomColorGradient(),
          },
        },
      }
    }

    case FETCH_ATTENDANCE_SUCCESS:
      return {
        ...state,
        feedbackData: Object.keys(action.payload).length > 0
          ? action.payload
          : undefined,
      }

    case FETCH_MEETING_DETAILS:
    case FETCH_UNKNOWN_PEER_DETAILS:
    default:
      return state
  }
}

export default zoomoutReducer
