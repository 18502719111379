import {
  SupportTicketConversationSubmitParams,
  SupportTicketFeedbackSubmitParams,
  SupportTicketId,
  SupportTicketSubmitActionParams,
} from 'common/types/programSupport'
import {
  FETCH_USER_PROGRAM_SUPPORT_TICKETS,
  FETCH_USER_PROGRAM_SUPPORT_TICKETS_SUCCESS,
  FETCH_USER_PROGRAM_SUPPORT_TICKETS_FAILURE,
  FETCH_USER_PROGRAM_SUPPORT_TICKETS_CANCEL,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKETS,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_SUCCESS,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_FAILURE,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_CANCEL,
  FETCH_PROGRAM_SUPPORT_TICKET_DETAILS,
  FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_SUCCESS,
  FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_FAILURE,
  FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_CANCEL,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_SUCCESS,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_FAILURE,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_CANCEL,
  REOPEN_USER_PROGRAM_SUPPORT_TICKET,
  REOPEN_USER_PROGRAM_SUPPORT_TICKET_SUCCESS,
  REOPEN_USER_PROGRAM_SUPPORT_TICKET_FAILURE,
  REOPEN_USER_PROGRAM_SUPPORT_TICKET_CANCEL,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_SUCCESS,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_FAILURE,
  SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_CANCEL,
} from './UserSupportTickets.types'

export const fetchUserProgramSupportTickets = () => ({
  type: FETCH_USER_PROGRAM_SUPPORT_TICKETS as typeof FETCH_USER_PROGRAM_SUPPORT_TICKETS,
})
export const fetchUserProgramSupportTicketSuccess = (payload: any) => ({
  type: FETCH_USER_PROGRAM_SUPPORT_TICKETS_SUCCESS as typeof FETCH_USER_PROGRAM_SUPPORT_TICKETS_SUCCESS,
  payload,
})
export const fetchUserProgramSupportTicketFailure = (payload: Error) => ({
  type: FETCH_USER_PROGRAM_SUPPORT_TICKETS_FAILURE as typeof FETCH_USER_PROGRAM_SUPPORT_TICKETS_FAILURE,
  payload,
})
export const fetchUserProgramSupportTicketCancel = (payload: any) => ({
  type: FETCH_USER_PROGRAM_SUPPORT_TICKETS_CANCEL as typeof FETCH_USER_PROGRAM_SUPPORT_TICKETS_CANCEL,
  payload,
})

export const submitUserProgramSupportTicket = (
  payload: SupportTicketSubmitActionParams
) => ({
  type: SUBMIT_USER_PROGRAM_SUPPORT_TICKETS as typeof SUBMIT_USER_PROGRAM_SUPPORT_TICKETS,
  payload,
})
export const submitUserProgramSupportTicketSuccess = (payload: any) => ({
  type: SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_SUCCESS as typeof SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_SUCCESS,
  payload,
})
export const submitUserProgramSupportTicketFailure = (payload: Error) => ({
  type: SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_FAILURE as typeof SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_FAILURE,
  payload,
})
export const submitUserProgramSupportTicketCancel = (payload: any) => ({
  type: SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_CANCEL as typeof SUBMIT_USER_PROGRAM_SUPPORT_TICKETS_CANCEL,
  payload,
})

export const fetchProgramSupportTicketDetails = (payload: SupportTicketId) => ({
  type: FETCH_PROGRAM_SUPPORT_TICKET_DETAILS as typeof FETCH_PROGRAM_SUPPORT_TICKET_DETAILS,
  payload,
})
export const fetchProgramSupportTicketDetailsSuccess = (payload: any) => ({
  type: FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_SUCCESS as typeof FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_SUCCESS,
  payload,
})
export const fetchProgramSupportTicketDetailsFailure = (payload: {
  error: Error
  ticketId: SupportTicketId
}) => ({
  type: FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_FAILURE as typeof FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_FAILURE,
  payload,
})
export const fetchProgramSupportTicketDetailsCancel = (payload: any) => ({
  type: FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_CANCEL as typeof FETCH_PROGRAM_SUPPORT_TICKET_DETAILS_CANCEL,
  payload,
})

export const submitUserProgramSupportTicketConversation = (
  payload: SupportTicketConversationSubmitParams
) => ({
  type: SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION as typeof SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION,
  payload,
})
export const submitUserProgramSupportTicketConversationSuccess = (
  payload: any
) => ({
  type: SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_SUCCESS as typeof SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_SUCCESS,
  payload,
})
export const submitUserProgramSupportTicketConversationFailure = (
  payload: Error
) => ({
  type: SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_FAILURE as typeof SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_FAILURE,
  payload,
})
export const submitUserProgramSupportTicketConversationCancel = (
  payload: any
) => ({
  type: SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_CANCEL as typeof SUBMIT_USER_PROGRAM_SUPPORT_TICKET_CONVERSATION_CANCEL,
  payload,
})

export const reopenUserProgramSupportTicket = (
  payload: SupportTicketConversationSubmitParams
) => ({
  type: REOPEN_USER_PROGRAM_SUPPORT_TICKET as typeof REOPEN_USER_PROGRAM_SUPPORT_TICKET,
  payload,
})
export const reopenUserProgramSupportTicketSuccess = (payload: any) => ({
  type: REOPEN_USER_PROGRAM_SUPPORT_TICKET_SUCCESS as typeof REOPEN_USER_PROGRAM_SUPPORT_TICKET_SUCCESS,
  payload,
})
export const reopenUserProgramSupportTicketFailure = (payload: Error) => ({
  type: REOPEN_USER_PROGRAM_SUPPORT_TICKET_FAILURE as typeof REOPEN_USER_PROGRAM_SUPPORT_TICKET_FAILURE,
  payload,
})
export const reopenUserProgramSupportTicketCancel = (payload: any) => ({
  type: REOPEN_USER_PROGRAM_SUPPORT_TICKET_CANCEL as typeof REOPEN_USER_PROGRAM_SUPPORT_TICKET_CANCEL,
  payload,
})

export const submitUserProgramSupportTicketFeeback = (
  payload: SupportTicketFeedbackSubmitParams
) => ({
  type: SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK as typeof SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK,
  payload,
})
export const submitUserProgramSupportTicketFeebackSuccess = (payload: any) => ({
  type: SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_SUCCESS as typeof SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_SUCCESS,
  payload,
})
export const submitUserProgramSupportTicketFeebackFailure = (
  payload: Error
) => ({
  type: SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_FAILURE as typeof SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_FAILURE,
  payload,
})
export const submitUserProgramSupportTicketFeebackCancel = (payload: any) => ({
  type: SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_CANCEL as typeof SUBMIT_USER_PROGRAM_SUPPORT_TICKET_FEEDBACK_CANCEL,
  payload,
})

export type UserSupportTicketActions =
  | ReturnType<typeof fetchUserProgramSupportTickets>
  | ReturnType<typeof fetchUserProgramSupportTicketSuccess>
  | ReturnType<typeof fetchUserProgramSupportTicketFailure>
  | ReturnType<typeof fetchUserProgramSupportTicketCancel>
  | ReturnType<typeof submitUserProgramSupportTicket>
  | ReturnType<typeof submitUserProgramSupportTicketSuccess>
  | ReturnType<typeof submitUserProgramSupportTicketFailure>
  | ReturnType<typeof submitUserProgramSupportTicketCancel>
  | ReturnType<typeof fetchProgramSupportTicketDetails>
  | ReturnType<typeof fetchProgramSupportTicketDetailsSuccess>
  | ReturnType<typeof fetchProgramSupportTicketDetailsFailure>
  | ReturnType<typeof fetchProgramSupportTicketDetailsCancel>
  | ReturnType<typeof submitUserProgramSupportTicketConversation>
  | ReturnType<typeof submitUserProgramSupportTicketConversationSuccess>
  | ReturnType<typeof submitUserProgramSupportTicketConversationFailure>
  | ReturnType<typeof submitUserProgramSupportTicketConversationCancel>
  | ReturnType<typeof reopenUserProgramSupportTicket>
  | ReturnType<typeof reopenUserProgramSupportTicketSuccess>
  | ReturnType<typeof reopenUserProgramSupportTicketFailure>
  | ReturnType<typeof reopenUserProgramSupportTicketCancel>
  | ReturnType<typeof submitUserProgramSupportTicketFeeback>
  | ReturnType<typeof submitUserProgramSupportTicketFeebackSuccess>
  | ReturnType<typeof submitUserProgramSupportTicketFeebackFailure>
  | ReturnType<typeof submitUserProgramSupportTicketFeebackCancel>
