import React, { Fragment, ReactNode } from 'react'
import Header from '../containers/Header'
import Footer from '../containers/Footer'
import PageBase from '../components/PageBase'
import StudentBaseLayout from './studentBase'

const StudentLayout = ({ children }: { children: ReactNode }) => (
  <Fragment>
    <Header />
    <PageBase>
      <StudentBaseLayout>{children}</StudentBaseLayout>
    </PageBase>
    <Footer />
  </Fragment>
)

export default StudentLayout
