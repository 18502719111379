import { select, put, cancelled, takeLatest, call } from 'redux-saga/effects'
import { UserID } from '../../../../common/types/user'

import { apiCall, cancelable } from '../../../../common/utils'

import { AppState } from '../../../store'

import {
  fetchAdmissionStatusSuccess,
  fetchAdmissionStatusFailure,
} from './UserAdmission.actions'

import {
  FETCH_ADMISSION_STATUS,
  FETCH_ADMISSION_STATUS_CANCEL,
} from './UserAdmission.types'
import { updateFeedbackData } from '../../FeedbackProvider'

export async function getAdmissionStatusAPI(
  userId: UserID,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/user_admission_status/check`,
    params: {
      signal,
    },
    query: {
      user_id: userId,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

function* fetchAdmissionStatusHandler() {
  const abortController = new AbortController()
  try {
    const userId = yield select((state: AppState) => state.user.details.id)
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data = yield call(
      getAdmissionStatusAPI,
      userId,
      abortController.signal
    )
    yield put(fetchAdmissionStatusSuccess(data))
    if (data.feedback_info) yield put(updateFeedbackData(data.feedback_info))
  } catch (e) {
    yield put(fetchAdmissionStatusFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* admissionStatusMiddleware() {
  yield takeLatest(
    FETCH_ADMISSION_STATUS,
    cancelable(fetchAdmissionStatusHandler, [FETCH_ADMISSION_STATUS_CANCEL])
  )
}

export default ([] as any).concat(admissionStatusMiddleware())
