import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import {
  fetchCourseQuizzes,
  fetchCourseQuizzesSuccess,
  fetchCourseQuizzesFailure,
} from './Quiz.actions'
import * as APIs from './Quiz.api'
import * as types from './Quiz.types'

function* getCourseQuizzes(action: ReturnType<typeof fetchCourseQuizzes>) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.getCourseQuizzesAPI,
      action,
      abortController.signal
    )
    yield put(fetchCourseQuizzesSuccess(data))
  } catch (e) {
    yield put(fetchCourseQuizzesFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* courseQuizzesMiddleware() {
  yield takeLatest(
    types.FETCH_COURSE_QUIZZES,
    cancelable(getCourseQuizzes, types.FETCH_COURSE_QUIZZES_CANCEL)
  )
}

export default ([] as any).concat(courseQuizzesMiddleware())
