export const INIT_INTERNAL_STATE = 'INIT_INTERNAL_STATE'
export const UPDATE_MICROPHONE_AND_SPEAKER = 'UPDATE_MICROPHONE_AND_SPEAKER'
export const UPDATE_CAMERA = 'UPDATE_CAMERA'
export const UPDATE_SCREEN_SHARING = 'UPDATE_SCREEN_SHARING'
export const SET_CAMERA = 'SET_CAMERA'
export const SET_MICROPHONE = 'SET_MICROPHONE'
export const UPDATE_CALL_STAGE = 'UPDATE_CALL_STAGE'
export const UPDATE_DEVICE = 'UPDATE_DEVICE'
export const UPDATE_USER_INTERFACE = 'UPDATE_UI_LAYOUT'
export const CREATE_PEER = 'CREATE_PEER'
export const UPDATE_PEER = 'UPDATE_PEER'
export const REMOVE_PEER = 'REMOVE_PEER'
export const ADD_PLAIN_TEXT_MESSAGE = 'ADD_PLAIN_TEXT_MESSAGE'
export const UPDATE_NETWORK_QUALITY = 'UPDATE_NETWORK_QUALITY'
export const UPDATE_LEAVE_MEETING_REASON = 'UPDATE_LEAVE_MEETING_REASON'
export const UPDATE_PLAIN_TEXT_MESSAGES = 'UPDATE_PLAIN_TEXT_MESSAGES'
export const UPDATE_RAISE_HAND_MESSAGES = 'UPDATE_RAISE_HAND_MESSAGES'
