import {
  ADD_FILE,
  REMOVE_FILE,
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  BATCH_UPLOAD_FILE,
  BATCH_UPLOAD_FILE_SUCCESS,
  BATCH_UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_CANCEL,
  BATCH_UPLOAD_FILE_CANCEL,
  BATCH_REMOVE_FILE,
  BATCH_REMOVE_FILE_SUCCESS,
  BATCH_REMOVE_FILE_FAILURE,
  BATCH_REMOVE_FILE_CANCEL,
} from './Files.types'
import { FileID } from '../../../common/types'
import { ItemID } from '../../../common/types/courses/moduleItem'

interface FilePayload {
  fileId: FileID
  dataKey: string
}
interface AddFilePayload extends FilePayload {
  file: File
}
interface RemoveFilePayload extends FilePayload {}
interface UploadFilePayload extends FilePayload {
  url: string
  folderPath?: string
}
interface UploadFilePayloadSuccess {
  id: number
}
interface BatchUploadFilePayload {
  url: string
  dataKey: string
}
interface BatchUploadFileMeta {
  dataKey: string
}
interface FileMetaParams {
  fileId: FileID
}
interface BatchRemoveFilePayload {
  dataKey: string
}

export const addFile = (payload: AddFilePayload) => ({
  type: ADD_FILE as typeof ADD_FILE,
  payload,
})
export const removeFile = (payload: RemoveFilePayload) => ({
  type: REMOVE_FILE as typeof REMOVE_FILE,
  payload,
})

export const uploadFile = (payload: UploadFilePayload) => ({
  type: UPLOAD_FILE as typeof UPLOAD_FILE,
  payload,
})
export const uploadFileSuccess = (
  payload: UploadFilePayloadSuccess,
  meta: FileMetaParams
) => ({
  type: UPLOAD_FILE_SUCCESS as typeof UPLOAD_FILE_SUCCESS,
  payload,
  meta,
})
export const uploadFileFailure = (
  payload: Error,
  meta: FileMetaParams,
  options: { [s in string]: any }
) => ({
  type: UPLOAD_FILE_FAILURE as typeof UPLOAD_FILE_FAILURE,
  payload,
  meta,
  options,
})
export const uploadFileCancel = (payload: UploadFilePayload) => ({
  type: UPLOAD_FILE_CANCEL as typeof UPLOAD_FILE_CANCEL,
  payload,
})
export const batchUploadFile = (payload: BatchUploadFilePayload) => ({
  type: BATCH_UPLOAD_FILE as typeof BATCH_UPLOAD_FILE,
  payload,
})
export const batchUploadFileSuccess = (meta: BatchUploadFileMeta) => ({
  type: BATCH_UPLOAD_FILE_SUCCESS as typeof BATCH_UPLOAD_FILE_SUCCESS,
  meta,
})
export const batchUploadFileFailure = (
  payload: Error,
  meta: BatchUploadFileMeta
) => ({
  type: BATCH_UPLOAD_FILE_FAILURE as typeof BATCH_UPLOAD_FILE_FAILURE,
  payload,
  meta,
})
export const batchUploadFileCancel = (meta: BatchUploadFileMeta) => ({
  type: BATCH_UPLOAD_FILE_CANCEL as typeof BATCH_UPLOAD_FILE_CANCEL,
  meta,
})

export const batchRemoveFile = (payload: BatchRemoveFilePayload) => ({
  type: BATCH_REMOVE_FILE as typeof BATCH_REMOVE_FILE,
  payload,
})
export const batchRemoveFileSuccess = (payload: { dataKey: string }) => ({
  type: BATCH_REMOVE_FILE_SUCCESS as typeof BATCH_REMOVE_FILE_SUCCESS,
  payload,
})
export const batchRemoveFileFailure = (payload: Error) => ({
  type: BATCH_REMOVE_FILE_FAILURE as typeof BATCH_REMOVE_FILE_FAILURE,
  payload,
})
export const batchRemoveFileCancel = (payload: UploadFilePayload) => ({
  type: BATCH_REMOVE_FILE_CANCEL as typeof BATCH_REMOVE_FILE_CANCEL,
  payload,
})

export type FileActionTypes =
  | ReturnType<typeof addFile>
  | ReturnType<typeof removeFile>
  | ReturnType<typeof uploadFile>
  | ReturnType<typeof uploadFileSuccess>
  | ReturnType<typeof uploadFileFailure>
  | ReturnType<typeof uploadFileCancel>
  | ReturnType<typeof batchUploadFile>
  | ReturnType<typeof batchUploadFileSuccess>
  | ReturnType<typeof batchUploadFileFailure>
  | ReturnType<typeof batchUploadFileCancel>
  | ReturnType<typeof batchRemoveFile>
  | ReturnType<typeof batchRemoveFileSuccess>
  | ReturnType<typeof batchRemoveFileFailure>
  | ReturnType<typeof batchRemoveFileCancel>
