import { UserID } from 'common/types/user'
import { IPeerInfo, MessageCategory, UILayout } from 'common/types/zoomout'
import { sendSelfHandState } from 'common/utils/custom/Zoomout/methods/toggleRaiseHand'
import {
  RaiseHandMessage,
  RAISE_HAND_STATES,
} from 'common/utils/custom/Zoomout/rtm/messages'
import { mixpanel } from 'common/utils/mixpanel'
import { updateGallerySlots } from 'common/utils/zoomout'
import { call, cancelled, put, select, takeEvery } from 'redux-saga/effects'
import { showAlertMessage } from 'web/providers/AlertsProvider'
import { AppState } from 'web/store'
import { ZoomoutInternalState } from '.'
import {
  fetchUnknownPeerDetailsAPIParams,
  fetchUnknownPeerDetailsSuccess,
} from '../External/Zoomout.actions'
import { fetchUnknownPeerDetailsAPI } from '../External/Zoomout.api'
import { ZoomoutExternalState } from '../External/Zoomout.reducer'
import {
  createPeer,
  removePeer,
  updateRaiseHandMessages,
  updateUserInterface,
} from './Internal.actions'
import { CALL_STAGES } from './Internal.reducer'
import { CREATE_PEER, REMOVE_PEER } from './Internal.types'

export function* handleUserJoined(action: ReturnType<typeof createPeer>) {
  const { uid: unknownPeerUID, showAlert } = action.payload
  const peerDetails: ZoomoutExternalState['peerDetails'] = yield select(
    (state: AppState) => state.zoomout.external.peerDetails
  )
  const selfUserId: UserID = yield select(
    (state: AppState) => state.user.details.id
  )

  let name = 'Olympus User'
  let isScreen = false
  const abortController = new AbortController()

  if (peerDetails && peerDetails[unknownPeerUID]) {
    ;({ name, isScreen } = peerDetails[unknownPeerUID])
  } else {
    try {
      const channel: string = yield select(
        (state: AppState) =>
          (state.zoomout.external.joiningDetails || {}).channel
      )

      const params: fetchUnknownPeerDetailsAPIParams = {
        unknownPeerUID,
      }

      const data = yield call(
        fetchUnknownPeerDetailsAPI,
        params,
        channel,
        selfUserId,
        abortController.signal
      )
      yield put(fetchUnknownPeerDetailsSuccess(data, { unknownPeerUID }))

      const updatedPeerDetails: Required<
        ZoomoutExternalState
      >['peerDetails'] = yield select(
        (state: AppState) => state.zoomout.external.peerDetails
      )
      ;({ name, isScreen } = updatedPeerDetails[unknownPeerUID])
    } catch (error) {
      mixpanel.track('Zoomout Errors', {
        feature: 'Fetch Unknown Peer Details API',
        code: error.code,
        message: error.message,
      })
    } finally {
      if (cancelled()) {
        abortController.abort()
      }
    }
  }

  sendSelfHandState(unknownPeerUID)

  const selfScreenUID: number | undefined = yield select(
    (state: AppState) =>
      ((state.zoomout.external.joiningDetails || {}).screen || {}).uid
  )

  const joinedAt: number = yield select(
    (state: AppState) => state.zoomout.internal.joinedAt
  )

  const now = Date.now()

  // only show the alert message if the peer has joined more than
  // 30 seconds later than the current user themselves
  if (showAlert && (now - joinedAt) / 1000 > 30) {
    // playAudio('join')
    let message = `${name} has joined the session.`
    if (isScreen) {
      message =
        selfScreenUID === unknownPeerUID
          ? 'You are presenting'
          : `${name} is presenting their screen`
    }

    yield put(
      showAlertMessage({
        variant: 'info',
        message,
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      })
    )
  }

  const currentVideosPerPage: number = yield select(
    (state: AppState) => state.zoomout.internal.userInterface.videosPerPage
  )
  const currentRemotePeerCount: number = yield select(
    (state: AppState) =>
      Object.keys(state.zoomout.internal.remotePeerState).length
  )

  const { requiredPages: newTotalPages } = updateGallerySlots(
    currentVideosPerPage,
    // + 1 => self main client
    currentRemotePeerCount + 1
  )

  const newUIConfig: Partial<ZoomoutInternalState['userInterface']> = {}

  if (isScreen) {
    newUIConfig.layout = UILayout.speaker
    newUIConfig.pinnedUserUID = unknownPeerUID
  }
  newUIConfig.totalPages = newTotalPages
  yield put(updateUserInterface(newUIConfig))
}

export function* handleUserLeft(action: ReturnType<typeof removePeer>) {
  const { uid: outgoingPeerUID, showAlert } = action.payload

  const [
    peerDetails,
    selfScreenUID,
    pinnedUserUID,
    currentGalleryPage,
    totalGalleryPages,
    currentVideosPerPage,
    currentRemotePeerCount,
    callStage,
    isHandRaised,
  ]: [
    IPeerInfo,
    number | undefined,
    number | null,
    number,
    number,
    number,
    number,
    CALL_STAGES,
    boolean | undefined
  ] = yield select((state: AppState) => [
    (state.zoomout.external.peerDetails || {})[outgoingPeerUID],
    ((state.zoomout.external.joiningDetails || {}).screen || {}).uid,
    state.zoomout.internal.userInterface.pinnedUserUID,
    state.zoomout.internal.userInterface.currentPage,
    state.zoomout.internal.userInterface.totalPages,
    state.zoomout.internal.userInterface.videosPerPage,
    Object.keys(state.zoomout.internal.remotePeerState).length,
    state.zoomout.internal.callStage,
    state.zoomout.internal.messages[MessageCategory.RAISE_HAND].isHandRaised[
      outgoingPeerUID
    ],
  ])

  /**
   * Doc: https://docs.agora.io/en/Video/API%20Reference/web_ng/interfaces/iagorartcclient.html#event_user_left
   *
   * reason can have the following values:
   * "Quit": The user calls leave and leaves the channel.
   * "ServerTimeOut": The user has dropped offline.
   * "BecomeAudience": The client role is switched from host to audience.
   */
  if (callStage === CALL_STAGES.LIVE_IN_CALL && showAlert) {
    if (peerDetails.isScreen) {
      yield put(
        showAlertMessage({
          variant: 'info',
          message:
            selfScreenUID === outgoingPeerUID
              ? 'You have stopped presenting'
              : `${peerDetails.name} has stopped presenting`,
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        })
      )
    } else {
      yield put(
        showAlertMessage({
          variant: 'info',
          message: `${peerDetails.name} has left the session`,
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        })
      )
    }
  }

  const { requiredPages: newTotalPages } = updateGallerySlots(
    currentVideosPerPage,
    // + 1 => self main client
    currentRemotePeerCount + 1
  )

  const newUIConfig: Partial<ZoomoutInternalState['userInterface']> = {
    totalPages: newTotalPages,
  }

  if (pinnedUserUID === outgoingPeerUID) {
    newUIConfig.pinnedUserUID = null
    newUIConfig.layout = UILayout.gallery
  }

  if (
    currentGalleryPage >= totalGalleryPages &&
    newTotalPages < totalGalleryPages
  ) {
    newUIConfig.currentPage = currentGalleryPage - 1
  }

  if (isHandRaised) {
    const automaticLowerHandMessage = new RaiseHandMessage({
      sender: outgoingPeerUID,
      content: RAISE_HAND_STATES.LOWERED,
    })
    yield put(updateRaiseHandMessages([automaticLowerHandMessage]))
  }
  yield put(updateUserInterface(newUIConfig))
}

export function* InternalMiddleware() {
  yield takeEvery(CREATE_PEER, handleUserJoined)
  yield takeEvery(REMOVE_PEER, handleUserLeft)
}

export default ([] as any).concat(InternalMiddleware())
