export const publicRoutes = {
  COURSE_COMPLETION_CARDS: '/course_completion_cards/:hash_key',
  PUBLIC_EPORTFOLIO_WEBSITE: '/eportfolio/:username',
  PUBLIC_EPORTFOLIO: '/:username',
}

const routes = {
  ROOT: '/',
  LOGIN: '/login',
  LEARNER_DASHBOARD: '/learner_dashboard',
  ACTIVITIES: '/activities',
  NOTIFICATIONS: '/notifications',
  COURSES: '/courses',
  COURSE: '/courses/:courseId(\\d+)',
  ALL_NOTES: '/courses/:courseId(\\d+)/notes',
  CLASSROOM_RECORDING_ITEM: '/courses/:courseId(\\d+)/classroom_recordings',
  MENTORSHIP_RECORDING_SESSION_ITEM: '/mentorship_recordings/:id(\\d+)',
  QUIZ_RUNNER: '/courses/:courseId(\\d+)/modules/items/:itemId(\\d+)/take',
  QUIZ_ATTEMPT:
    '/courses/:courseId(\\d+)/modules/items/:itemId(\\d+)/attempts/:attempt',
  QUIZ_ATTEMPT_DYNAMIC_QUESTION:
    '/quiz_submissions/:quizSubmissionId(\\d+)/attempts/:attempt/questions/:questionId(\\d+)',
  MODULE_ITEM: '/courses/:courseId(\\d+)/modules/items/:itemId(\\d+)',
  CONTENT:
    '/courses/:courseId(\\d+)/:contentType(files|assignments|quizzes|pages|discussion_topics)/:contentId',
  CONTENT_AI_MENTOR:
    '/courses/:courseId(\\d+)/:contentType(files|assignments|quizzes|pages|discussion_topics)/:contentId/ai_mentor',
  CURRICULUM_AI_MENTOR:
    '/batches/:batchId(\\d+)/courses/:courseId(\\d+)?/ai_mentor',
  DASHBOARD: '/dashboard',
  AUTH: '/auth',
  PROFILE_SETTINGS: '/profile/settings',
  GRADEBOOK: '/gradebook',
  SCORECARD: '/scorecard',
  ANNOUNCEMENTS: '/announcements',
  COURSE_ANNOUNCEMENTS: '/courses/:courseId(\\d+)/announcements',
  COURSE_ANNOUNCEMENT:
    '/courses/:courseId(\\d+)/announcements/:announcementId(\\d+)',
  GROUP_ANNOUNCEMENTS: '/groups/:groupId(\\d+)/announcements',
  GROUP_ANNOUNCEMENT:
    '/groups/:groupId(\\d+)/announcements/:announcementId(\\d+)',
  ONBOARDING: '/onboarding',
  FEEDBACK: '/user_feedbacks',
  ABOUT: '/about',
  COMMUNITY_GUIDELINES: '/community_guidelines',
  COMMUNITY_SEND_INVITE: '/community/send_invite',
  COMMUNITY_ACCEPT_INVITE: '/community/accept_invite',
  COMMUNITY_ACCEPT_REFERRAL: '/community/accept_referral',
  TESTIMONIALS: '/testimonials',
  TESTIMONIAL_GUIDELINES: '/testimonial_guidelines',
  LEADERBOARD: '/leaderboard',
  EPORTFOLIO: '/eportfolio',
  FEE_PAYMENT: '/fee_payment',
  FEE_PAYMENT_STATUS: '/fee_payment/:status_type(success|failure|cancel)',
  LIVE_SESSIONS: '/live_sessions',
  COURSE_CERTIFICATE: '/courses/:courseId(\\d+)/certificate',
  CERTIFICATES: '/certificates',
  COURSE_CATALOG: '/course_catalog',
  HACKATHONS: '/hackathons',
  HACKATHON: '/hackathons/:hackathonId(\\d+)',
  REFERRALS_AND_REWARDS: '/referrals_and_rewards',
  ATTENDANCE_DETAILS: '/attendance_details',
  DIGITAL_EXCELERATE_TESTS: '/digital-excelerate/tests/:testId',
  GL_CONNECT: '/connect',
  EXCELERATE_CAREER_PREP: '/excelerate/career_prep',
  EXCELERATE_CAREER_PREP_COURSE:
    '/excelerate/career_prep/courses/:courseId(\\d+)',
  EXCELERATE_VALUE_PROP: '/excelerate/how-it-works',
  INVIGILATION: '/assessment_schedules/:assessmentScheduleId/proctor',
  PROCTOR_INVIGILATION: '/proctor_invigilation',
  SCHOOL_TRACK: '/career_tracks/:trackId(\\d+)',
  CODING_PLAYGROUND: '/coding_playground',
  ZOOMOUT: '/meetings/:channel',
  IPEVO_INSTRUCTIONS: '/ipevo-instructions',
  LEARN_AND_DEV_DASHBOARD: '/learn_and_dev/dashboard',
  LEARN_AND_DEV_LEADERBOARD: '/learn_and_dev/leaderboard',
  TRY_CALCULATOR: '/try/calculator',
  ENROLLMENT_FORM: '/enrollmentform',
  PROGRAM_SUPPORT: '/program_support',
  PROGRAM_SUPPORT_TICKET_DETAIL: '/program_support/requests/:ticketId(\\d+)',
  PROGRAM_SUPPORT_CATEGORIES: '/program_support/categories',
  PROGRAM_SUPPORT_CATEGORY: '/program_support/categories/:categoryId(\\d+)',
  PROGRAM_SUPPORT_FAQ:
    '/program_support/category/:categoryId(\\d+)/faq/:faqId(\\d+)',
  PROGRAM_SUPPORT_FAQ_SEARCH: '/program_support/faqs/search',
  PROGRAM_SUPPORT_FAQ_EDIT:
    '/program_support/category/:categoryId(\\d+)/faq/:faqId(\\d+)/edit',
  PROGRAM_SUPPORT_FAQ_NEW: '/program_support/faqs/new',
  CODING_LAB_ASSIGNMENT: '/coding_labs/:codingLabAssignmentId(\\d+)',
  GL_LAB_ASSIGNMENT: '/gl_labs/:glLabAssignmentId(\\d+)',
  JUPYTER_LAB: '/jupyter_labs/:embedCode([_a-zA-Z0-9]+)',
  PRIVATE_CERTIFICATE: '/certificates/private',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS: '/terms',
  EXTERNAL_LAB: '/external_labs/:externalLabId',
  PEER_REVIEW: '/peer_reviews',
  GLA_BROWSE_SCREEN: '/browse',
  ...publicRoutes,
}

export const getRouteName = <K extends RouteNames>(
  path: Routes[K]
): RouteNames | null => {
  const route = Object.entries(routes).find(
    /* eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars */
    ([routeName, routeUrl]) => routeUrl === path
  )
  if (route) {
    return route[0] as RouteNames
  }
  return null
}

export type Routes = typeof routes
export type RouteNames = keyof Routes
export default routes
