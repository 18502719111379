import { Reducer } from 'redux'
import { TestimonialData } from 'common/types/testimonials'
import {
  ProgramActions,
  UPDATE_ACTIVE_PROGRAM,
} from 'web/providers/Dashboard/ProgramsProvider'
import { UserTestimonialActions } from './UserTestimonial.actions'
import {
  FETCH_USER_PROGRAM_TESTIMONIAL,
  FETCH_USER_PROGRAM_TESTIMONIAL_SUCCESS,
  FETCH_USER_PROGRAM_TESTIMONIAL_FAILURE,
  FETCH_USER_PROGRAM_TESTIMONIAL_CANCEL,
  SUBMIT_USER_PROGRAM_TESTIMONIAL,
  UPDATE_USER_PROGRAM_TESTIMONIAL,
  SUBMIT_USER_PROGRAM_TESTIMONIAL_CANCEL,
  SUBMIT_USER_PROGRAM_TESTIMONIAL_FAILURE,
  SUBMIT_USER_PROGRAM_TESTIMONIAL_SUCCESS,
  UPDATE_USER_PROGRAM_TESTIMONIAL_CANCEL,
  UPDATE_USER_PROGRAM_TESTIMONIAL_FAILURE,
  UPDATE_USER_PROGRAM_TESTIMONIAL_SUCCESS,
  UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE,
  UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_CANCEL,
  UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_FAILURE,
  UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_SUCCESS,
  CLEAR_USER_PROGRAM_TESTIMONIAL,
} from './UserTestimonial.types'

export interface UserTestimonialState {
  data?: TestimonialData
  loading: boolean
  error: false | Error | Response
  hasSubmitted: boolean
}

const initialState: UserTestimonialState = {
  loading: false,
  error: false,
  hasSubmitted: false,
}

const userTestimonialReducer: Reducer<
  UserTestimonialState,
  | UserTestimonialActions
  | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
> = (state = initialState, action): UserTestimonialState => {
  switch (action.type) {
    case FETCH_USER_PROGRAM_TESTIMONIAL:
    case SUBMIT_USER_PROGRAM_TESTIMONIAL:
    case UPDATE_USER_PROGRAM_TESTIMONIAL:
    case UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE:
      return {
        ...state,
        loading: true,
        error: false,
        hasSubmitted: false,
      }

    case FETCH_USER_PROGRAM_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case SUBMIT_USER_PROGRAM_TESTIMONIAL_SUCCESS:
    case UPDATE_USER_PROGRAM_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload,
          ...state.data,
        },
        loading: false,
        hasSubmitted: false,
      }
    case UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_SUCCESS:
      if (state.data)
        return {
          ...state,
          loading: false,
          hasSubmitted: true,
        }
      return state
    case FETCH_USER_PROGRAM_TESTIMONIAL_FAILURE:
    case SUBMIT_USER_PROGRAM_TESTIMONIAL_FAILURE:
    case UPDATE_USER_PROGRAM_TESTIMONIAL_FAILURE:
    case UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case FETCH_USER_PROGRAM_TESTIMONIAL_CANCEL:
    case SUBMIT_USER_PROGRAM_TESTIMONIAL_CANCEL:
    case UPDATE_USER_PROGRAM_TESTIMONIAL_CANCEL:
    case UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_CANCEL:
      return {
        ...state,
        loading: false,
      }
    case CLEAR_USER_PROGRAM_TESTIMONIAL:
    case UPDATE_ACTIVE_PROGRAM:
      return initialState
    default:
      return state
  }
}
export default userTestimonialReducer
