export const FETCH_USER_PROGRAM_TESTIMONIAL = 'FETCH_USER_PROGRAM_TESTIMONIAL'
export const FETCH_USER_PROGRAM_TESTIMONIAL_SUCCESS =
  'FETCH_USER_PROGRAM_TESTIMONIAL_SUCCESS'
export const FETCH_USER_PROGRAM_TESTIMONIAL_FAILURE =
  'FETCH_USER_PROGRAM_TESTIMONIAL_FAILURE'
export const FETCH_USER_PROGRAM_TESTIMONIAL_CANCEL =
  'FETCH_USER_PROGRAM_TESTIMONIAL_CANCEL'

export const SUBMIT_USER_PROGRAM_TESTIMONIAL = 'SUBMIT_USER_PROGRAM_TESTIMONIAL'
export const SUBMIT_USER_PROGRAM_TESTIMONIAL_SUCCESS =
  'SUBMIT_USER_PROGRAM_TESTIMONIAL_SUCCESS'
export const SUBMIT_USER_PROGRAM_TESTIMONIAL_FAILURE =
  'SUBMIT_USER_PROGRAM_TESTIMONIAL_FAILURE'
export const SUBMIT_USER_PROGRAM_TESTIMONIAL_CANCEL =
  'SUBMIT_USER_PROGRAM_TESTIMONIAL_CANCEL'

export const UPDATE_USER_PROGRAM_TESTIMONIAL = 'UPDATE_USER_PROGRAM_TESTIMONIAL'
export const UPDATE_USER_PROGRAM_TESTIMONIAL_SUCCESS =
  'UPDATE_USER_PROGRAM_TESTIMONIAL_SUCCESS'
export const UPDATE_USER_PROGRAM_TESTIMONIAL_FAILURE =
  'UPDATE_USER_PROGRAM_TESTIMONIAL_FAILURE'
export const UPDATE_USER_PROGRAM_TESTIMONIAL_CANCEL =
  'UPDATE_USER_PROGRAM_TESTIMONIAL_CANCEL'

export const CLEAR_USER_PROGRAM_TESTIMONIAL = 'CLEAR_USER_PROGRAM_TESTIMONIAL'

export const UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE =
  'UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE'
export const UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_SUCCESS =
  'UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_SUCCESS'
export const UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_FAILURE =
  'UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_FAILURE'
export const UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_CANCEL =
  'UPDATE_USER_PROGRAM_TESTIMONIAL_IMAGE_CANCEL'
