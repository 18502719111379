import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import routes from 'web/routes'
import { isUserAcadOps } from 'common/utils'
import { useShowNewNavigationBars } from 'web/hooks'
import FooterComponent from '../../components/Footer'
import { programSelectors } from '../../providers/Dashboard/ProgramsProvider'
import { DashboardState } from '../../providers/Dashboard/DashboardProvider'
import { AppState } from '../../store'
import { UserDetailsState } from '../../providers/User/UserDetailsProvider'
import { ProctoringState } from '../../providers/ProctoringProvider'
import { ProgramData } from '../../../common/types/programs'
import { GLFooter } from './gl-footer'

interface StateProps {
  dashboardData: DashboardState['data']
  userDetails: UserDetailsState
  program: ProgramData | null
  proctoring: ProctoringState
  isNewProgramSupportEnabled: boolean
}
type Props = StateProps & RouteComponentProps

const Footer = (props: Props) => {
  const isAcadOps = isUserAcadOps()

  const showNewNavigationBars = useShowNewNavigationBars()

  if (
    props.userDetails.appSource === 'mobile_app' ||
    props.proctoring.data.enabled ||
    props.location.pathname.includes(routes.CODING_PLAYGROUND) ||
    isAcadOps
  )
    return null

  const programSupportUrl =
    props.dashboardData && props.dashboardData.feature_links
      ? props.dashboardData.feature_links.program_support
      : undefined

  return showNewNavigationBars ? (
    <GLFooter />
  ) : (
    <FooterComponent
      programData={props.program}
      programSupportUrl={programSupportUrl}
      userAccessType={props.userDetails.accessType}
      isNewProgramSupportEnabled={props.isNewProgramSupportEnabled}
    />
  )
}

const mapStateToProps = (state: AppState): StateProps => ({
  dashboardData: state.dashboard.data,
  userDetails: state.user.details,
  program: programSelectors.getActiveProgramDetails()(state),
  proctoring: state.proctoring,
  isNewProgramSupportEnabled: state.programs.data.isNewProgramSupportEnabled,
})
export default withRouter(connect(mapStateToProps)(Footer))
