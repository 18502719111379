import { apiCall } from 'common/utils'
import { fetchAllDepartmentsContent } from '.'
import { UserID } from '../../../../common/types/user'

export async function fetchAllDepartmentsContentAPI(
  action: ReturnType<typeof fetchAllDepartmentsContent>,
  userId: UserID,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/learn_and_dev_users/${userId}/departments`,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
