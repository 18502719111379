import { useEffect } from 'react'

interface Props {
  title: string | null
}

const PageTitle = (props: Props) => {
  const DEFAULT_PAGE_TITLE =
    'constants' in window
      ? window.constants.DEFAULT_PAGE_TITLE
      : 'Great Learning'

  useEffect(() => {
    document.title = props.title
      ? `${props.title} - ${DEFAULT_PAGE_TITLE}`
      : DEFAULT_PAGE_TITLE
  }, [props.title])

  useEffect(() => {
    return () => {
      document.title = DEFAULT_PAGE_TITLE
    }
  }, [])

  return null
}

export default PageTitle
