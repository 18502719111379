import {
  FETCH_ATTENDANCE_CONTENT,
  FETCH_ATTENDANCE_CONTENT_FAILURE,
  FETCH_ATTENDANCE_CONTENT_SUCCESS,
  FETCH_ATTENDANCE_CONTENT_CANCEL,
} from './Attendance.types'

export const fetchAttendanceContent = () => ({
  type: FETCH_ATTENDANCE_CONTENT as typeof FETCH_ATTENDANCE_CONTENT,
})
export const fetchAttendanceContentSuccess = (payload: any) => ({
  type: FETCH_ATTENDANCE_CONTENT_SUCCESS as typeof FETCH_ATTENDANCE_CONTENT_SUCCESS,
  payload,
})
export const fetchAttendanceContentFailure = (payload: Error) => ({
  type: FETCH_ATTENDANCE_CONTENT_FAILURE as typeof FETCH_ATTENDANCE_CONTENT_FAILURE,
  payload,
})
export const fetchAttendanceContentCancel = () => ({
  type: FETCH_ATTENDANCE_CONTENT_CANCEL as typeof FETCH_ATTENDANCE_CONTENT_CANCEL,
})

export type AttendanceActions =
  | ReturnType<typeof fetchAttendanceContent>
  | ReturnType<typeof fetchAttendanceContentSuccess>
  | ReturnType<typeof fetchAttendanceContentFailure>
  | ReturnType<typeof fetchAttendanceContentCancel>
