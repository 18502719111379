import { AppState } from '../../../store'
import {
  UserID,
  UserProfileData,
  UserAccessType,
} from '../../../../common/types/user'

export const getUserID = () => (state: AppState): UserID | null => {
  const userId = state.user.details.id
  if (userId) {
    return userId
  }
  return null
}

export const getUserProfile = () => (state: AppState) => {
  const userProfile = state.user.details.profile
  if (userProfile) {
    return userProfile
  }
  return null
}

export const getUserProfileData = () => (
  state: AppState
): UserProfileData | null => {
  const userProfile = getUserProfile()(state)
  if (userProfile && userProfile.data) {
    return userProfile.data
  }
  return null
}

export const getUserAccessType = () => (
  state: AppState
): UserAccessType | null => state.user.details.accessType || null

