import {
  OlympusLeadData,
  StudyAbroadLeadData,
} from 'common/types/glaNewOnboardingForm'
import {
  UPDATE_OLYMPUS_LEAD_DATA,
  UPDATE_OLYMPUS_LEAD_DATA_CANCEL,
} from './GlaNewOnboardingForm.types'

export const updateOlympusLeadData = (
  payload: StudyAbroadLeadData | OlympusLeadData
) => ({
  type: UPDATE_OLYMPUS_LEAD_DATA as typeof UPDATE_OLYMPUS_LEAD_DATA,
  payload,
})

export const updateOlympusLeadDataCancel = () => ({
  type: UPDATE_OLYMPUS_LEAD_DATA_CANCEL as typeof UPDATE_OLYMPUS_LEAD_DATA_CANCEL,
})

export type GlaOnBoardingFormActions =
  | ReturnType<typeof updateOlympusLeadData>
  | ReturnType<typeof updateOlympusLeadDataCancel>
