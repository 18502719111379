import {
  fetchProgramLeaderboard,
  fetchCourseLeaderboard,
  userLeaderboardAnonimityUpdate,
} from '.'
import { apiCall, objectToFormData } from '../../../../common/utils'
import { UserID } from '../../../../common/types/user'

export async function getProgramLeaderboardAPI(
  action: ReturnType<typeof fetchProgramLeaderboard>,
  userId: UserID,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v2/program_groups/${action.payload.id}/student_scores`,
    query: { user_id: userId },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
export async function getCourseLeaderboardAPI(
  action: ReturnType<typeof fetchCourseLeaderboard>,
  userId: UserID,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/course/${action.payload.id}/student_scores`,
    query: { user_id: userId },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function updateUserLeaderboardAnonimityAPI(
  action: ReturnType<typeof userLeaderboardAnonimityUpdate>,
  user_id: UserID,
  signal: AbortSignal
) {
  const body = objectToFormData({
    leader_board_anonymity: action.payload.leader_board_anonymity,
  })
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LTI_API_URL}v1/program_groups/${action.meta.program_group_id}/update_user_leader_board_anonymity`,
    params: { signal, method: 'POST', body },
    query: { user_id },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
