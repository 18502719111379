import { apiCall, getUserDetails } from 'common/utils'
import { getCurriculumAiMentorUserData } from './CurriculumAiMentor.action'

export async function getCurriculumAiMentorUserDataAPI(
  action: ReturnType<typeof getCurriculumAiMentorUserData>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/ai/curriculum_context`,
    params: {
      signal,
    },
    query: {
      user_id: id,
      pb_id: action.meta.batchId,
      course_id: action.meta.courseId,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
