export const QUIZ_SUBMISSION_QA_FETCH = 'QUIZ_SUBMISSION_QA_FETCH'
export const QUIZ_SUBMISSION_QA_FETCH_SUCCESS =
  'QUIZ_SUBMISSION_QA_FETCH_SUCCESS'
export const QUIZ_SUBMISSION_QA_FETCH_FAILURE =
  'QUIZ_SUBMISSION_QA_FETCH_FAILURE'

export const QUIZ_QUESTION_UPDATE_ANSWER = 'QUIZ_QUESTION_UPDATE_ANSWER'
export const QUIZ_QUESTION_FLAG = 'QUIZ_QUESTION_FLAG'
export const QUIZ_QUESTION_UNFLAG = 'QUIZ_QUESTION_UNFLAG'

export const QUIZ_SUBMISSION_ANSWERS_SAVE = 'QUIZ_SUBMISSION_ANSWERS_SAVE'
export const QUIZ_SUBMISSION_ANSWERS_SAVE_SUCCESS =
  'QUIZ_SUBMISSION_ANSWERS_SAVE_SUCCESS'
export const QUIZ_SUBMISSION_ANSWERS_SAVE_FAILURE =
  'QUIZ_SUBMISSION_ANSWERS_SAVE_FAILURE'

export const QUIZ_QUESTION_FILE_UPLOAD = 'QUIZ_QUESTION_FILE_UPLOAD'
export const GET_FILE_DETAILS = 'GET_FILE_DETAILS'
export const GET_FILE_DETAILS_SUCCESS = 'GET_FILE_DETAILS_SUCCESS'
export const GET_FILE_DETAILS_FAILURE = 'GET_FILE_DETAILS_FAILURE'

export const GET_DYNAMIC_QUESTION_ANSWERS_SUCCESS =
  'GET_DYNAMIC_QUESTION_ANSWERS_SUCCESS'
