import { useMediaQuery } from '@material-ui/core'

export function useMedia() {
  const xxs = useMediaQuery('(max-width:0px)')
  const gtXXs = useMediaQuery('(min-width:1px)')

  const xs = useMediaQuery('(max-width:359px)')
  const gtXs = useMediaQuery('(min-width:360px)')

  const sm = useMediaQuery('(max-width:599px)')
  const gtSm = useMediaQuery('(min-width:600px)')

  const md = useMediaQuery('(max-width:767px)')
  const gtMd = useMediaQuery('(min-width:768px)')

  const tablet = useMediaQuery('(max-width:1023px)')
  const gtTablet = useMediaQuery('(min-width:1024px)')

  const lg = useMediaQuery('(max-width:1279px)')
  const gtLg = useMediaQuery('(min-width:1280px)')

  const xl = useMediaQuery('(max-width:1535px)')
  const gtXl = useMediaQuery('(min-width:1536px)')

  const xxl = useMediaQuery('(max-width:1919px)')
  const gtXXl = useMediaQuery('(min-width:1920px)')

  return {
    xxs,
    gtXXs,
    xs,
    gtXs,
    sm,
    gtSm,
    md,
    gtMd,
    tablet,
    gtTablet,
    lg,
    gtLg,
    xl,
    gtXl,
    xxl,
    gtXXl,
  }
}

export function useBreakpoint(props) {
  const media = useMedia()
  const response = { gt: undefined, lt: undefined }

  if (media.gtXXl && props.gtXXl) {
    response.gt = props.gtXXl
  } else if (media.gtXl && props.gtXl) {
    response.gt = props.gtXl
  } else if (media.gtLg && props.gtLg) {
    response.gt = props.gtLg
  } else if (media.gtTablet && props.gtTablet) {
    response.gt = props.gtTablet
  } else if (media.gtMd && props.gtMd) {
    response.gt = props.gtMd
  } else if (media.gtSm && props.gtSm) {
    response.gt = props.gtSm
  } else if (media.gtXs && props.gtXs) {
    response.gt = props.gtXs
  } else if (media.gtXXs && props.gtXXs) {
    response.gt = props.gtXXs
  }

  if (media.xxs && props.xxs) {
    response.lt = props.xxs
  } else if (media.xs && props.xs) {
    response.lt = props.xs
  } else if (media.sm && props.sm) {
    response.lt = props.sm
  } else if (media.md && props.md) {
    response.lt = props.md
  } else if (media.tablet && props.tablet) {
    response.lt = props.tablet
  } else if (media.lg && props.lg) {
    response.lt = props.lg
  } else if (media.xl && props.xl) {
    response.lt = props.xl
  } else if (media.xxl && props.xxl) {
    response.lt = props.xxl
  }

  return response
}
