import { ModuleID } from '../../../../common/types'
import { CourseID } from '../../../../common/types/courses'
import { UserID } from '../../../../common/types/user'
import { apiCall, objectToFormData } from '../../../../common/utils'
import { ItemID } from '../../../../common/types/courses/moduleItem'
import { ContentID } from '../../../../common/types/courses/content'

export async function getModuleItemsAPI(
  {
    courseId,
    moduleId,
    page,
    include,
    per_page,
    userId,
  }: {
    courseId: CourseID
    moduleId: ModuleID
    include?: string[]
    page?: number
    per_page?: number
    userId: UserID
  },
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/courses/${courseId}/modules/${moduleId}/items`,
    query: { page, per_page, include, student_id: userId },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
export async function getModuleItemAPI(
  {
    courseId,
    moduleId,
    itemId,
    include,
    disableLogAssetAccess
  }: {
    courseId: CourseID
    moduleId: ModuleID
    itemId: ItemID
    include?: string[]
    disableLogAssetAccess?: boolean
  },
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/modules/${moduleId}/items/${itemId}`,
    query: {
      include,
      ...(disableLogAssetAccess && { disable_log_asset_access: '1' })
    },
    params: { signal },
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}
export async function markItemAsReadAPI(
  {
    courseId,
    moduleId,
    itemId,
  }: {
    courseId: CourseID
    moduleId: ModuleID
    itemId: ItemID
  },
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/modules/${moduleId}/items/${itemId}/mark_read`,
    params: { signal, method: 'POST' },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getContentFeedbackQAAPI(
  {
    courseId,
    contentType,
    contentId,
  }: {
    courseId: CourseID
    contentType: string
    contentId: ContentID
  },
  userId: UserID,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/content_feedback/details`,
    params: { signal },
    query: {
      user_id: userId,
      course_id: courseId,
      content_type: contentType,
      content_id: contentId,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export const submitFeedbackAPI = async (
  bodyObject: any,
  signal: AbortSignal
) => {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/content_feedback/submit`,
    params: { signal, method: 'POST', body: JSON.stringify(bodyObject) },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
