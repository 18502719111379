import { call, cancelled, put, takeLatest, select } from 'redux-saga/effects'
import { cancelable } from '../../../../common/utils'
import {
  fetchResidencyContent,
  fetchResidencyContentFailure,
  fetchResidencyContentSuccess,
} from './Activity.actions'
import * as APIs from './Activity.api'
import * as types from './Activity.types'

function* fetchResidencyContentHandler(
  action: ReturnType<typeof fetchResidencyContent>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      APIs.getResidencyContentAPI,
      action,
      abortController.signal
    )
    yield put(fetchResidencyContentSuccess(data, { ...action.payload }))
  } catch (e) {
    yield put(fetchResidencyContentFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* fetchResidencyContentMiddleware() {
  yield takeLatest(
    types.FETCH_RESIDENCY_CONTENT,
    cancelable(
      fetchResidencyContentHandler,
      types.FETCH_RESIDENCY_CONTENT_CANCEL
    )
  )
}
export default ([] as any).concat(fetchResidencyContentMiddleware())
