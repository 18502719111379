import * as types from './UpcomingWebinars.types'

export const fetchUpcomingWebinars = () => {
  return {
    type: types.FETCH_UPCOMING_WEBINARS_DETAILS as typeof types.FETCH_UPCOMING_WEBINARS_DETAILS,
  }
}

export const fetchUpcomingWebinarsFailure = (payload: Error) => ({
  type: types.FETCH_UPCOMING_WEBINARS_DETAILS_FAILURE as typeof types.FETCH_UPCOMING_WEBINARS_DETAILS_FAILURE,
  payload,
})

export const fetchUpcomingWebinarsSuccess = (payload: any) => {
  return {
    type: types.FETCH_UPCOMING_WEBINARS_DETAILS_SUCCESS as typeof types.FETCH_UPCOMING_WEBINARS_DETAILS_SUCCESS,
    payload,
  }
}

export const fetchUpcomingWebinarsCancel = () => ({
  type: types.FETCH_UPCOMING_WEBINARS_DETAILS_CANCEL as typeof types.FETCH_UPCOMING_WEBINARS_DETAILS_CANCEL,
})

export type UpcomingWebinarsActions =
  | ReturnType<typeof fetchUpcomingWebinars>
  | ReturnType<typeof fetchUpcomingWebinarsSuccess>
  | ReturnType<typeof fetchUpcomingWebinarsFailure>
  | ReturnType<typeof fetchUpcomingWebinarsCancel>
