import { apiCall } from '../../../../common/utils'
import { CourseID } from '../../../../common/types/courses'
import { UserID } from '../../../../common/types/user'

export async function getModulesAPI(
  { courseId, userId }: { courseId: CourseID; userId: UserID },
  signal: AbortSignal
) {
  const query = {
    include: ['items', 'content_details'],
    student_id: userId,
    per_page: 50,
    use_new_flow: true,
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/courses/${courseId}/modules`,
    query,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getCourseAssetAccessAPI(
  { courseId, userId }: { courseId: CourseID; userId: UserID },
  signal: AbortSignal
) {
  const query = {
    user_id: userId,
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/courses/${courseId}/asset_access`,
    query,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
