import {
  FETCH_ALL_DEPARTMENTS_CONTENT,
  FETCH_ALL_DEPARTMENTS_CONTENT_CANCEL,
  FETCH_ALL_DEPARTMENTS_CONTENT_FAILURE,
  FETCH_ALL_DEPARTMENTS_CONTENT_SUCCESS,
} from './Departments.types'

export const fetchAllDepartmentsContent = () => ({
  type: FETCH_ALL_DEPARTMENTS_CONTENT as typeof FETCH_ALL_DEPARTMENTS_CONTENT,
})

export const fetchAllDepartmentsContentSuccess = (payload: any) => ({
  type: FETCH_ALL_DEPARTMENTS_CONTENT_SUCCESS as typeof FETCH_ALL_DEPARTMENTS_CONTENT_SUCCESS,
  payload,
})

export const fetchAllDepartmentsContentFailure = (payload: Error) => ({
  type: FETCH_ALL_DEPARTMENTS_CONTENT_FAILURE as typeof FETCH_ALL_DEPARTMENTS_CONTENT_FAILURE,
  payload,
})

export const fetchAllDepartmentsContentCancel = () => ({
  type: FETCH_ALL_DEPARTMENTS_CONTENT_CANCEL as typeof FETCH_ALL_DEPARTMENTS_CONTENT_CANCEL,
})

export type DepartmentsActions =
  | ReturnType<typeof fetchAllDepartmentsContent>
  | ReturnType<typeof fetchAllDepartmentsContentSuccess>
  | ReturnType<typeof fetchAllDepartmentsContentFailure>
  | ReturnType<typeof fetchAllDepartmentsContentCancel>
