import { ModuleData } from 'common/types/courses/moduleItem'
import {
  FETCH_COURSE_ASSET_ACCESS,
  FETCH_COURSE_ASSET_ACCESS_CANCEL,
  FETCH_COURSE_ASSET_ACCESS_FAILURE,
  FETCH_COURSE_ASSET_ACCESS_SUCCESS,
  MODULES_FETCH,
  MODULES_FETCH_CANCEL,
  MODULES_FETCH_FAILURE,
  MODULES_FETCH_SUCCESS,
  UPDATE_MODULE_DATA,
} from './Modules.types'
import { CourseID } from '../../../../common/types/courses'

interface ModulesFetchMetaParams {
  courseId: CourseID
}

export interface ModulesFetchParams {
  courseId: CourseID
}

// Course Modules
export const fetchModules = (
  payload: ModulesFetchParams,
  meta: ModulesFetchMetaParams
) => ({
  type: MODULES_FETCH as typeof MODULES_FETCH,
  payload,
  meta,
})

export const fetchModulesFailure = (
  payload: Error,
  meta: ModulesFetchMetaParams
) => ({
  type: MODULES_FETCH_FAILURE as typeof MODULES_FETCH_FAILURE,
  payload,
  meta,
})

export const fetchModulesSuccess = (
  payload: any,
  meta: ModulesFetchMetaParams
) => ({
  type: MODULES_FETCH_SUCCESS as typeof MODULES_FETCH_SUCCESS,
  payload,
  meta,
})

export const fetchModulesCancel = (
  payload: any,
  meta: ModulesFetchMetaParams
) => ({
  type: MODULES_FETCH_CANCEL as typeof MODULES_FETCH_CANCEL,
  payload,
  meta,
})

export const updateModuleData = (payload: ModuleData) => ({
  type: UPDATE_MODULE_DATA as typeof UPDATE_MODULE_DATA,
  payload,
})

export const fetchCourseAssetAccess = (
  payload: ModulesFetchParams,
  meta: ModulesFetchMetaParams
) => ({
  type: FETCH_COURSE_ASSET_ACCESS as typeof FETCH_COURSE_ASSET_ACCESS,
  payload,
  meta,
})

export const fetchCourseAssetAccessFailure = (
  payload: Error,
  meta: ModulesFetchMetaParams
) => ({
  type: FETCH_COURSE_ASSET_ACCESS_FAILURE as typeof FETCH_COURSE_ASSET_ACCESS_FAILURE,
  payload,
  meta,
})

export const fetchCourseAssetAccessSuccess = (
  payload: any,
  meta: ModulesFetchMetaParams
) => ({
  type: FETCH_COURSE_ASSET_ACCESS_SUCCESS as typeof FETCH_COURSE_ASSET_ACCESS_SUCCESS,
  payload,
  meta,
})

export const fetchCourseAssetAccessCancel = (
  payload: any,
  meta: ModulesFetchMetaParams
) => ({
  type: FETCH_COURSE_ASSET_ACCESS_CANCEL as typeof FETCH_COURSE_ASSET_ACCESS_CANCEL,
  payload,
  meta,
})

export type ModulesActionTypes =
  | ReturnType<typeof fetchModules>
  | ReturnType<typeof fetchModulesSuccess>
  | ReturnType<typeof fetchModulesFailure>
  | ReturnType<typeof fetchModulesCancel>
  | ReturnType<typeof updateModuleData>
  | ReturnType<typeof fetchCourseAssetAccess>
  | ReturnType<typeof fetchCourseAssetAccessSuccess>
  | ReturnType<typeof fetchCourseAssetAccessFailure>
  | ReturnType<typeof fetchCourseAssetAccessCancel>
