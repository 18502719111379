import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  Button,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { mixpanel } from 'common/utils/mixpanel'
import styles from './AcademyIneligibleUserDialog.module.css'

interface Props {
  onClose: () => void
  userId: number | null
}

const AcademyIneligibleUserDialog = (props: Props) => {
  useEffect(() => {
    mixpanel.track('CB_IneligibleUser_Popup_Shown', {user_id: props.userId})
  }, [])

  return (
    <Dialog open disableBackdropClick classes={{ paper: styles.dialog }}>
      <DialogContent className={styles.dialogContent}>
        <Box className={styles.popupData}>
          <Typography>Dear Learner,</Typography>
          <Typography className={styles.marginTop}>
            CareerBoost is currently enabled for college students passing out in
            2021 and graduates/post-graduates passing out in 2020 only. We are
            working on bringing this feature to other learners as well.
          </Typography>
          <Typography className={styles.marginTop} variant="h5">
          Meanwhile, you can enroll to over 1,000+ free courses on Great Learning Academy
          and get certificates to prepare for and crack your dream job.
          </Typography>
          <Button
            className={styles.exploreBtn}
            color="primary"
            variant="contained"
            onClick={() => {
              props.onClose()
              mixpanel.track('CB_IneligibleUser_ExploreCoursesClick', {
                page: 'Olympus',
              })
            }}
          >
            Explore Courses
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AcademyIneligibleUserDialog
