import React from 'react'
import { Container, ContainerProps } from '@material-ui/core'
import cx from 'classnames'
import { useSelector } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import routes from 'web/routes'
import { DashboardData } from 'common/types/dashboard'
import styles from './PageBase.module.css'
import { UserDetailsState } from '../../providers/User/UserDetailsProvider'
import { AppState } from '../../store'
import { ProctoringState } from '../../providers/ProctoringProvider'

interface PageBase extends RouteComponentProps {
  className?: string
  children: React.ReactNode
  containerMaxWidth?: ContainerProps['maxWidth']
}

const PageBase = (props: PageBase) => {
  const userDetails: UserDetailsState = useSelector(
    (state: AppState) => state.user.details
  )
  const proctoring: ProctoringState = useSelector(
    (state: AppState) => state.proctoring
  )
  const dashboardData: DashboardData | undefined = useSelector(
    (state: AppState) => state.dashboard.data
  )

  return (
    <Container
      id="main"
      component="main"
      maxWidth={'containerMaxWidth' in props ? props.containerMaxWidth : 'lg'}
      className={cx(
        styles.container,
        props.className,
        userDetails.appSource === 'mobile_app' && styles.mobileAppContainer,
        proctoring.data.enabled && styles.proctoring,
        dashboardData &&
          'trial_details' in dashboardData &&
          dashboardData.trial_details &&
          'v3_data' in dashboardData.trial_details &&
          styles.trailDemoContainer,
        props.location.pathname.includes(routes.CODING_PLAYGROUND) &&
          styles.coding_playground
      )}
      data-testid="page-content"
    >
      {props.children}
    </Container>
  )
}

export default withRouter(PageBase)
