import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import {
  Link as InternalLink,
  matchPath,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState } from 'web/store'
import { generateURL } from '../../../common/utils'
import routes from '../../routes'
import FooterBase from '../FooterBase'
import Link from '../Utils/Link'
import styles from './Footer.module.css'
import { UserDetailsState } from '../../providers/User/UserDetailsProvider'
import { ProgramData } from '../../../common/types/programs'
import {
  isAcademyInternationalUser,
  isAcademyUser,
  isCorporateUser,
} from '../../../common/utils/custom/user'

interface Props extends RouteComponentProps {
  programData: ProgramData | null
  programSupportUrl: string | undefined
  userAccessType: UserDetailsState['accessType']
  isNewProgramSupportEnabled: boolean
}

const Footer = (props: Props) => {
  const corporateUser = isCorporateUser(props.userAccessType)
  const freeSupportEmail = corporateUser
    ? 'business@greatlearning.in'
    : 'academy@mygreatlearning.com'
  const ipFooter = (path: string, programData: ProgramData | null) => {
    if (
      matchPath(path, { path: routes.COURSE, exact: true }) ||
      matchPath(path, { path: routes.MODULE_ITEM, exact: true }) ||
      matchPath(path, { path: routes.CONTENT, exact: true }) ||
      matchPath(path, {
        path: routes.CLASSROOM_RECORDING_ITEM,
        exact: true,
      }) ||
      matchPath(path, {
        path: routes.MENTORSHIP_RECORDING_SESSION_ITEM,
        exact: true,
      })
    ) {
      return (
        <div className={styles.ipContainer}>
          <Typography variant="body2">
            Proprietary content.
            {programData && programData.domain_name
              ? ''
              : '©Great Learning.'}{' '}
            All Rights Reserved. Unauthorized use or distribution prohibited.
          </Typography>
        </div>
      )
    }
    return null
  }

  const hideFooter = props.location.pathname === routes.ENROLLMENT_FORM

  const {
    privacy_content: privacyPolicyContent,
    terms_content: termsContent,
  } = useSelector(
    (state: AppState) =>
      state.user.onboarding.data.content || {
        privacy_content: null,
        terms_content: null,
      }
  )

  return (
    <FooterBase className={`${hideFooter ? styles.hideFooter : null}`}>
      {ipFooter(props.location.pathname, props.programData)}
      <Grid container className={styles.container}>
        <Grid item md={8} sm={6} xs={12} className={styles.gridItem}>
          <Typography variant="body2">
            {(props.programData && props.programData.copyright) ||
              `&copy; ${new Date().getFullYear()} All rights reserved`}
          </Typography>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Grid
            container
            className={styles.footerLinks}
            data-testid="footer-links"
          >
            {props.programData && props.programData.privacy_policy ? (
              <Grid item className={styles.gridItem}>
                {privacyPolicyContent ? (
                  <InternalLink
                    className={styles.footerLink}
                    to={generateURL('PRIVACY_POLICY', null)}
                  >
                    <Typography variant="body2">Privacy</Typography>
                  </InternalLink>
                ) : (
                  <Link
                    className={styles.footerLink}
                    target="_blank"
                    href={`${process.env.REACT_APP_WEBSITE_URL}privacy-policy${
                      props.programData.translation_enabled &&
                      props.programData.translation_language
                        ? `?utm_content=${props.programData.translation_language}`
                        : ''
                    }`}
                  >
                    <Typography variant="body2">Privacy</Typography>
                  </Link>
                )}
              </Grid>
            ) : null}
            {props.programData && props.programData.terms ? (
              <Grid item className={styles.gridItem}>
                {termsContent ? (
                  <InternalLink
                    className={styles.footerLink}
                    to={generateURL('TERMS', null)}
                  >
                    <Typography variant="body2">Terms of use</Typography>
                  </InternalLink>
                ) : (
                  <Link
                    target="_blank"
                    className={styles.footerLink}
                    href={`${process.env.REACT_APP_WEBSITE_URL}terms${
                      props.programData.translation_enabled &&
                      props.programData.translation_language
                        ? `?utm_content=${props.programData.translation_language}`
                        : ''
                    }`}
                  >
                    <Typography variant="body2">Terms of service</Typography>
                  </Link>
                )}
              </Grid>
            ) : null}
            <Grid item className={styles.gridItem}>
              {(function helpCta() {
                if (props.programData?.disable_program_support) return null
                if (
                  isAcademyUser(props.userAccessType) ||
                  isAcademyInternationalUser(props.userAccessType)
                )
                  return (
                    <Link
                      href={`mailto:${freeSupportEmail}`}
                      className={styles.footerLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography variant="body2" color="primary">
                        {freeSupportEmail}
                      </Typography>
                    </Link>
                  )
                if (props.isNewProgramSupportEnabled)
                  return (
                    <InternalLink
                      className={styles.footerLink}
                      to={generateURL('PROGRAM_SUPPORT', null)}
                    >
                      <Typography variant="body2">Help</Typography>
                    </InternalLink>
                  )
                if (props.programSupportUrl && props.userAccessType === 'basic')
                  return (
                    <Link
                      className={styles.footerLink}
                      href={props.programSupportUrl}
                    >
                      <Typography variant="body2">Help</Typography>
                    </Link>
                  )
                return null
              })()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FooterBase>
  )
}

export default withRouter(Footer)
