import { apiCall, getUserDetails, objectToFormData } from 'common/utils'
import { fetchExcelerateCareerPrepDetails } from './CareerPrep.actions'

export async function fetchExcelerateCareerPrepDetailsAPI(
  action: ReturnType<typeof fetchExcelerateCareerPrepDetails>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const query: Record<string, any> = {
    user_id: id,
  }
  if (action.payload) {
    if (action.payload.courseId) {
      query.course_id = action.payload.courseId
    }
    if (action.payload.includeLatestFeaturesInfo) {
      query.include_feature_status_info =
        action.payload.includeLatestFeaturesInfo
      query.include_additional_info = true
    }
  }
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/excelerate/career_prep_details`,
    params: {
      signal,
    },
    query,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export const submitExcelerateFeedbackAPI = async (
  bodyObject: any,
  signal: AbortSignal
) => {
  const query: Record<string, any> = {
    user_id: bodyObject.user_id,
  }
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/excelerate/submit_excelerate_feedback`,
    params: { signal, method: 'POST', body: JSON.stringify(bodyObject) },
    query,
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
