import React, { useEffect, useState } from 'react'
import { Typography, IconButton, Box } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import styles from '../Header.module.css'
import Button from '../../Utils/Button'
import SnackbarStrip from '../SnackbarStrip'
import {
  resendEmailLink,
  UserDetailsState,
} from '../../../providers/User/UserDetailsProvider'

interface EmailVerificationReminderProps {
  resendEmailLink: typeof resendEmailLink
  profileDetails: UserDetailsState['profile']
}

const EmailVerificationReminder = (props: EmailVerificationReminderProps) => {
  const [open, setOpen] = useState(false)

  const onClose = () => {
    localStorage.setItem('emailReminder', new Date().toDateString())
    setOpen(false)
  }

  useEffect(() => {
    if (
      props.profileDetails &&
      props.profileDetails.data &&
      props.profileDetails.data.resentLinkSuccess
    ) {
      setOpen(false)
    } else if (props.profileDetails && props.profileDetails.data) {
      const emailReminder = localStorage.getItem('emailReminder')
      if (emailReminder === null || emailReminder < new Date().toDateString()) {
        setOpen(true)
      }
    }
  }, [props.profileDetails])

  const email =
    props.profileDetails &&
    props.profileDetails.data &&
    props.profileDetails.data.primary_email
      ? props.profileDetails.data.primary_email
      : null

  return (
    <SnackbarStrip
      message={
        <span id="client-snackbar">
          <Box display="flex">
            <Typography>
              We&apos;ve sent a verification link to
              {email ? ` - ${email}` : ''}. Verify to resume email
              communications from us.
            </Typography>
            <Button
              onClick={props.resendEmailLink}
              className={styles.innerLink}
              color="primary"
            >
              Re-send verification link
            </Button>
          </Box>
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <Close />
        </IconButton>,
      ]}
      open={open}
    />
  )
}
export default EmailVerificationReminder
