import { Reducer } from 'redux'

import {
  ProgramActions,
  UPDATE_ACTIVE_PROGRAM,
} from 'web/providers/Dashboard/ProgramsProvider'
import {
  FETCH_USER_PAYMENT_HISTORY,
  USER_PAYMENT_HISTORY_SUCCESS,
  USER_PAYMENT_HISTORY_FAILURE,
} from './PaymentHistory.types'
import { PaymentHistoryActions } from './PaymentHistory.actions'
import { UserPaymentTransactionData } from '../../../../common/types/user'

export interface PaymentHistoryState {
  userPayments?: UserPaymentTransactionData[]
  loading: boolean
  error: false | Error | Response
}
const initialState: PaymentHistoryState = {
  loading: false,
  error: false,
}
const paymentsHistoryReducer: Reducer<
  PaymentHistoryState,
  | PaymentHistoryActions
  | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
> = (state = initialState, action): PaymentHistoryState => {
  switch (action.type) {
    case FETCH_USER_PAYMENT_HISTORY:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case USER_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        userPayments: action.payload,
      }
    case USER_PAYMENT_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UPDATE_ACTIVE_PROGRAM:
      return initialState
    default:
      return state
  }
}
export default paymentsHistoryReducer
