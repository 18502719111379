import { takeLatest, call, put, cancelled, select } from 'redux-saga/effects'
import {
  FETCH_FEEDBACK_CONTENT,
  FETCH_FEEDBACK_CONTENT_CANCEL,
  SUBMIT_COURSE_FEEDBACK,
  SUBMIT_COURSE_FEEDBACK_CANCEL,
  SUBMIT_PROGRAM_FEEDBACK,
  SUBMIT_PROGRAM_FEEDBACK_CANCEL,
  SUBMIT_CAPSTONE_LEGACY_FEEDBACK,
  SUBMIT_CAPSTONE_LEGACY_FEEDBACK_CANCEL,
  SUBMIT_CAPSTONE_SURVEY_FEEDBACK_CANCEL,
  SUBMIT_CAPSTONE_SURVEY_FEEDBACK,
  MARK_MISSED_RESIDENCY_SESSION,
  FEEDBACK_POPUP_STATUS,
  SKIP_SESSION_FEEDBACK,
} from './Feedback.types'
import { cancelable } from '../../../common/utils'
import {
  fetchFeedbackContent,
  submitCourseFeedback,
  showFeedbackPopup,
} from '.'
import {
  fetchFeedbackContentAPI,
  submitFeedbackAPI,
  markMissedResidencySessionAPI,
  skipSessionFeedbackAPI,
} from './Feedback.api'
import {
  fetchFeedbackContentSuccess,
  submitCourseFeedbackSuccess,
  submitCourseFeedbackFailure,
  submitProgramFeedback,
  submitProgramFeedbackSuccess,
  submitProgramFeedbackFailure,
  submitCapstoneLegacyFeedback,
  submitCapstoneLegacyFeedbackFailure,
  submitCapstoneLegacyFeedbackSuccess,
  submitCapstoneSurveyFeedback,
  submitCapstoneSurveyFeedbackFailure,
  submitCapstoneSurveyFeedbackSuccess,
  markMissedResidencySessionSuccess,
  markMissedResidencySessionFailure,
  fetchFeedbackContentFailure,
  skipSessionFeedback,
  removeFeedbackPopup,
  skipSessionFeedbackSuccess,
  skipSessionFeedbackFailure,
} from './Feedback.actions'
import { userDetailsSelectors } from '../User/UserDetailsProvider'
import { programSelectors } from '../Dashboard/ProgramsProvider'
import { AppSourceType, UserID } from '../../../common/types/user'
import { showAlertMessage } from '../AlertsProvider'
import { getFeedbackContent, getFeedbackData } from './Feedback.selectors'
import {
  ProgramFeedbackContent,
  QuestionContent,
  AnswerOption,
  FeedbackData,
  CapstoneLegacyFeedbackContent,
  CapstoneFeedbackOption,
  CourseFeedbackContent,
  CapstoneSurveyFeedbackContent,
  CapstoneSurveyFeedbackQuestion,
  Option,
  StringOptions,
  SubQuestion,
} from '../../../common/types/feedback'
import { ProgramID } from '../../../common/types/programs'
import { AppState } from '../../store'
import { getZoomoutFeedbackContent } from '../Zoomout/External'
import { fetchAdmissionStatus } from '../User/UserAdmissionProvider'

function* feedbackPopupStatusHandler() {
  const feedbackData: FeedbackData | undefined = yield select(getFeedbackData())
  const appSource: AppSourceType | null = yield select(
    (state: AppState) => state.user.details.appSource
  )
  if (
    appSource !== 'mobile_app' &&
    feedbackData &&
    feedbackData.feedback_pending &&
    feedbackData.feedback_url
  ) {
    const feedbackUrlTime = localStorage.getItem(feedbackData.feedback_url)
    if (!feedbackUrlTime || feedbackUrlTime < new Date().toDateString()) {
      yield put(showFeedbackPopup())
    }
  }
}

function* fetchFeedbackContentHandler(
  action: ReturnType<typeof fetchFeedbackContent>
) {
  const abortController = new AbortController()
  const userId: UserID | null = yield select(userDetailsSelectors.getUserID())
  try {
    const data = yield call(
      fetchFeedbackContentAPI,
      userId,
      action,
      abortController.signal
    )
    yield put(fetchFeedbackContentSuccess(data))
  } catch (e) {
    yield put(fetchFeedbackContentFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* skipFeedbackHandler(action: ReturnType<typeof skipSessionFeedback>) {
  const abortController = new AbortController()

  try {
    yield call(skipSessionFeedbackAPI, action, abortController.signal)
    yield put(skipSessionFeedbackSuccess())

    yield put(
      showAlertMessage({
        message: 'Skipped feedback successfully',
        variant: 'success',
      })
    )

    yield put(fetchAdmissionStatus())
  } catch (e) {
    yield put(
      showAlertMessage({
        message: 'Skipping feedback failed!',
        variant: 'error',
      })
    )
    yield put(skipSessionFeedbackFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* markMissedResidencySessionHandler() {
  const abortController = new AbortController()
  const userId: UserID | null = yield select(userDetailsSelectors.getUserID())
  const feedbackContent: CourseFeedbackContent | undefined = yield select(
    getFeedbackContent()
  )
  try {
    if (feedbackContent) {
      const {
        feedback_type,
        feedback_for_type1,
        feedback_for_id1,
        feedback_for_type2,
        feedback_for_id2,
        feedback_for_type3,
        feedback_for_id3,
      } = feedbackContent
      const resultObj = {
        feedback_type,
        feedback_for_type1,
        feedback_for_id1,
        feedback_for_type2,
        feedback_for_id2,
        feedback_for_type3,
        feedback_for_id3,
        lms_user_id: userId,
        user_id: userId,
      }
      const data = yield call(
        markMissedResidencySessionAPI,
        resultObj,
        abortController.signal
      )
      yield put(markMissedResidencySessionSuccess(data))
    } else throw new Error('Insufficient data')
  } catch (e) {
    yield put(markMissedResidencySessionFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitCourseFeedbackHandler(
  action: ReturnType<typeof submitCourseFeedback>
) {
  const abortController = new AbortController()
  const userId: UserID | null = yield select(userDetailsSelectors.getUserID())
  let feedbackContent: CourseFeedbackContent | undefined = yield select(
    getFeedbackContent()
  )
  if (!feedbackContent)
    // incase zoomout feedback is to be submitted
    feedbackContent = yield select(getZoomoutFeedbackContent())
  try {
    if (feedbackContent) {
      const {
        feedback_type,
        feedback_for_type1,
        feedback_for_id1,
        feedback_for_type2,
        feedback_for_id2,
        feedback_for_type3,
        feedback_for_id3,
      } = feedbackContent
      const resultObject = {
        feedback_type,
        feedback_for_type1,
        feedback_for_id1,
        feedback_for_type2,
        feedback_for_id2,
        feedback_for_type3,
        feedback_for_id3,
        star_rating: action.payload.starValue,
        ...action.payload.optionsSelected,
        ...action.payload.comments,
        user_id: userId,
        lms_user_id: userId,
      }
      const data = yield call(
        submitFeedbackAPI,
        resultObject,
        abortController.signal
      )
      yield put(submitCourseFeedbackSuccess(data))
      if (data.status === 'error') {
        yield put(
          showAlertMessage({ variant: 'error', message: data.message[0] })
        )
      }
    } else throw new Error('Insufficient data')
  } catch (e) {
    yield put(submitCourseFeedbackFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitCapstoneSurveyFeedbackHandler(
  action: ReturnType<typeof submitCapstoneSurveyFeedback>
) {
  const abortController = new AbortController()
  const userId: UserID | null = yield select(userDetailsSelectors.getUserID())
  const feedbackData: FeedbackData | undefined = yield select(
    (state: AppState) => state.feedback.data
  )
  const feedbackContent:
    | CapstoneSurveyFeedbackContent
    | undefined = yield select(getFeedbackContent())
  try {
    if (feedbackContent && feedbackData && userId) {
      const resultObject: any = {
        cap_survey_id: feedbackContent.cap_survey_id,
        is_guru_survey: feedbackContent.is_guru_survey,
        feedback_type: feedbackData.feedback_type,
        lms_user_id: userId,
        user_id: userId,
      }
      const questionObj: any = {}
      feedbackContent.questions.forEach(
        (question: CapstoneSurveyFeedbackQuestion) => {
          questionObj[question.id] = {}
          if (question.question_type === 'multiple_answer_question') {
            questionObj[question.id] = {
              ...action.payload[question.id],
            }
            ;(question.options as Option[]).forEach(
              (option: Option, index: number) => {
                if (!questionObj[question.id][index + 1])
                  questionObj[question.id][index + 1] = 0
              }
            )
          } else if (
            question.question_type === 'true_false_question' ||
            question.question_type === 'mentor_ratings_question'
          ) {
            const tempObj = { ...action.payload[question.id] }
            if (question.question_type === 'mentor_ratings_question') {
              questionObj[question.id].rating = tempObj.rating
              delete tempObj.rating
            } else {
              questionObj[question.id].option = tempObj.option
              delete tempObj.option
            }
            if (
              Object.keys(question.options).includes('positive_option') &&
              Object.keys(question.options).includes('negative_option')
            ) {
              if (question.options) {
                const negativeOptions = (question.options as StringOptions)
                  .negative_option
                const positiveOptions = (question.options as StringOptions)
                  .positive_option
                questionObj[question.id].negative_option = {}
                questionObj[question.id].positive_option = {}
                if (
                  questionObj[question.id].rating < 5 ||
                  questionObj[question.id].option === 'false'
                ) {
                  if (negativeOptions)
                    negativeOptions.forEach((option: Option, index: number) => {
                      if (!tempObj[index + 1])
                        questionObj[question.id].negative_option[index + 1] = 0
                      else
                        questionObj[question.id].negative_option[index + 1] =
                          tempObj[index + 1]
                    })
                  if (positiveOptions)
                    positiveOptions.forEach((option: Option, index: number) => {
                      questionObj[question.id].positive_option[index + 1] = 0
                    })
                } else if (
                  questionObj[question.id].rating === 5 ||
                  questionObj[question.id].option === 'true'
                ) {
                  if (negativeOptions)
                    negativeOptions.forEach((option: Option, index: number) => {
                      questionObj[question.id].negative_option[index + 1] = 0
                    })
                  if (positiveOptions)
                    positiveOptions.forEach((option: Option, index: number) => {
                      if (!tempObj[index + 1])
                        questionObj[question.id].positive_option[index + 1] = 0
                      else
                        questionObj[question.id].positive_option[index + 1] =
                          tempObj[index + 1]
                    })
                }
              }
            }
          } else if (
            question.question_type === 'multiple_long_answer_question'
          ) {
            question.sub_questions.forEach((subQues: SubQuestion) => {
              questionObj[question.id][subQues.id] = {
                answer: action.payload[question.id][subQues.id],
              }
            })
          } else {
            questionObj[question.id] = { ...action.payload[question.id] }
          }
          if (
            !questionObj[question.id].other_comment &&
            question.enable_other_comments
          ) {
            questionObj[question.id].other_comment = ''
          }
        }
      )
      resultObject.question = questionObj
      const data = yield call(
        submitFeedbackAPI,
        resultObject,
        abortController.signal
      )
      yield put(submitCapstoneSurveyFeedbackSuccess(data))
      if (data.status === 'error') {
        yield put(
          showAlertMessage({ variant: 'error', message: data.message[0] })
        )
      }
    } else throw new Error('Insufficient data')
  } catch (e) {
    yield put(submitCapstoneSurveyFeedbackFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitCapstoneLegacyFeedbackHandler(
  action: ReturnType<typeof submitCapstoneLegacyFeedback>
) {
  const abortController = new AbortController()
  const userId: UserID | null = yield select(userDetailsSelectors.getUserID())
  const feedbackData: FeedbackData | undefined = yield select(
    (state: AppState) => state.feedback.data
  )
  const feedbackContent:
    | CapstoneLegacyFeedbackContent
    | undefined = yield select(getFeedbackContent())
  try {
    if (feedbackContent && feedbackData && userId) {
      const resultObject: any = {
        cap_project_feedback: {
          cap_project_session_id: feedbackContent.cap_project_session_id,
          cap_project_group_id: feedbackContent.cap_project_group_id,
          lms_user_id: userId,
          term: feedbackContent.term,
          started_at: feedbackContent.started_at,
          is_positive: action.payload.feedbackType === 'positive',
          other_comments: action.payload.comments,
        },
        cap_feedback_session_id: feedbackContent.cap_feedback_session_id,
        cap_group_id: feedbackContent.cap_group_id,
        user_id: userId,
        feedback_type: feedbackData.feedback_type,
      }
      if (action.payload.feedbackType === 'positive') {
        resultObject.positive_options = {
          ...action.payload.optionsSelected,
        }
        resultObject.negative_options = {}
        feedbackContent.positive_feedback_options.forEach(
          (option: CapstoneFeedbackOption, index: number) => {
            if (!resultObject.positive_options[index + 1]) {
              resultObject.positive_options[index + 1] = 0
            }
          }
        )
        feedbackContent.negative_feedback_options.forEach(
          (option: CapstoneFeedbackOption, index: number) => {
            resultObject.negative_options[index + 1] = 0
          }
        )
      } else {
        resultObject.negative_options = {
          ...action.payload.optionsSelected,
        }
        resultObject.positive_options = {}
        feedbackContent.negative_feedback_options.forEach(
          (option: CapstoneFeedbackOption, index: number) => {
            if (!resultObject.positive_options[index + 1]) {
              resultObject.positive_options[index + 1] = 0
            }
          }
        )
        feedbackContent.positive_feedback_options.forEach(
          (option: CapstoneFeedbackOption, index: number) => {
            resultObject.positive_options[index + 1] = 0
          }
        )
      }
      const data = yield call(
        submitFeedbackAPI,
        resultObject,
        abortController.signal
      )
      yield put(submitCapstoneLegacyFeedbackSuccess(data))
      if (data.status === 'error') {
        yield put(
          showAlertMessage({ variant: 'error', message: data.message[0] })
        )
      }
    } else throw new Error('Insufficient data')
  } catch (e) {
    yield put(submitCapstoneLegacyFeedbackFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
function* submitProgramFeedbackHandler(
  action: ReturnType<typeof submitProgramFeedback>
) {
  const abortController = new AbortController()
  const programId: ProgramID | null = yield select(
    programSelectors.getProgramId()
  )
  const userId: UserID | null = yield select(userDetailsSelectors.getUserID())
  const feedbackData: FeedbackData | undefined = yield select(
    (state: AppState) => state.feedback.data
  )
  const feedbackContent: ProgramFeedbackContent | undefined = yield select(
    getFeedbackContent()
  )
  try {
    if (feedbackContent && feedbackData && userId) {
      const resultObject: any = {
        user_id: userId,
        program_group_feedback_category_id: feedbackContent.id,
        program_group_id: programId,
        lms_user_id: userId,
        feedback_type: feedbackData.feedback_type,
      }
      feedbackContent.questions.forEach((question: QuestionContent) => {
        if (
          question.question_type === 'multiple_answers_question' ||
          question.question_type === 'multiple_choice_question'
        ) {
          resultObject[`question_${question.id}`] = {
            ...action.payload[question.id],
          }
          question.options.forEach((option: AnswerOption, index: number) => {
            if (!resultObject[`question_${question.id}`][index + 1]) {
              resultObject[`question_${question.id}`][index + 1] = 0
            }
          })
          if (
            !resultObject[`question_${question.id}`].other_comment &&
            question.enable_other_comments
          ) {
            resultObject[`question_${question.id}`].other_comment = ''
          }
        } else if (
          question.question_type === 'multiple_smileys' ||
          question.question_type === 'multiple_checkbox' ||
          question.question_type === 'multiple_short_answer'
        ) {
          resultObject[`question_${question.id}`] = {
            sub_question: {
              ...action.payload[question.id],
            },
          }
          if (question.question_type === 'multiple_short_answer') {
            question.options.forEach((option: Option) => {
              if (!action.payload[question.id][option.id])
                resultObject[`question_${question.id}`].sub_question[
                  option.id
                ] = ''
            })
          }
        } else {
          resultObject[`question_${question.id}`] = {
            ...action.payload[question.id],
          }
        }
      })

      const data = yield call(
        submitFeedbackAPI,
        resultObject,
        abortController.signal
      )
      yield put(submitProgramFeedbackSuccess(data))
      if (data.status === 'error') {
        yield put(
          showAlertMessage({ variant: 'error', message: data.message[0] })
        )
      }
    } else throw new Error('Insufficient data')
  } catch (e) {
    yield put(submitProgramFeedbackFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* feedbackPopupStatusMiddleware() {
  yield takeLatest(FEEDBACK_POPUP_STATUS, feedbackPopupStatusHandler)
}

export function* skipFeedbackMiddleware() {
  yield takeLatest(SKIP_SESSION_FEEDBACK, skipFeedbackHandler)
}

export function* fetchFeedbackContentMiddleware() {
  yield takeLatest(
    FETCH_FEEDBACK_CONTENT,
    cancelable(fetchFeedbackContentHandler, FETCH_FEEDBACK_CONTENT_CANCEL)
  )
}

export function* submitCourseFeedbackMiddleware() {
  yield takeLatest(
    SUBMIT_COURSE_FEEDBACK,
    cancelable(submitCourseFeedbackHandler, SUBMIT_COURSE_FEEDBACK_CANCEL)
  )
}
export function* markMissedResidencySessionMiddleware() {
  yield takeLatest(
    MARK_MISSED_RESIDENCY_SESSION,
    cancelable(markMissedResidencySessionHandler, SUBMIT_COURSE_FEEDBACK_CANCEL)
  )
}
export function* submitProgramFeedbackMiddleware() {
  yield takeLatest(
    SUBMIT_PROGRAM_FEEDBACK,
    cancelable(submitProgramFeedbackHandler, SUBMIT_PROGRAM_FEEDBACK_CANCEL)
  )
}
export function* submitCapstoneLegacyFeedbackMiddleware() {
  yield takeLatest(
    SUBMIT_CAPSTONE_LEGACY_FEEDBACK,
    cancelable(
      submitCapstoneLegacyFeedbackHandler,
      SUBMIT_CAPSTONE_LEGACY_FEEDBACK_CANCEL
    )
  )
}
export function* submitCapstoneSurveyFeedbackMiddleware() {
  yield takeLatest(
    SUBMIT_CAPSTONE_SURVEY_FEEDBACK,
    cancelable(
      submitCapstoneSurveyFeedbackHandler,
      SUBMIT_CAPSTONE_SURVEY_FEEDBACK_CANCEL
    )
  )
}

export default ([] as any).concat([
  feedbackPopupStatusMiddleware(),
  fetchFeedbackContentMiddleware(),
  submitCourseFeedbackMiddleware(),
  markMissedResidencySessionMiddleware(),
  submitProgramFeedbackMiddleware(),
  submitCapstoneLegacyFeedbackMiddleware(),
  submitCapstoneSurveyFeedbackMiddleware(),
  skipFeedbackMiddleware(),
])
