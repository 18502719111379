import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { history } from 'web/store/history'

import {
  getJupyterLabStatus,
  getJupyterLabStatusSuccess,
  getJupyterLabStatusFailure,
  startJupyterLabFailure,
  startJupyterLabSuccess,
  startJupyterLab,
  stopJupyterLab,
  stopJupyterLabFailure,
  stopJupyterLabSuccess,
  getJupyterNotebook,
  getJupyterNotebookFailure,
  getJupyterNotebookSuccess,
  getJupyterLabDetailsFailure,
  getJupyterLabDetailsSuccess,
  getJupyterLabDetails,
} from './JupyterLabs.action'
import { cancelable, generateURL } from '../../../common/utils'
import {
  GET_JUPYTER_LAB_STATUS,
  GET_JUPYTER_LAB_STATUS_CANCEL,
  START_JUPYTER_LAB,
  START_JUPYTER_LAB_CANCEL,
  STOP_JUPYTER_LAB,
  STOP_JUPYTER_LAB_CANCEL,
  GET_JUPYTER_NOTEBOOK,
  GET_JUPYTER_NOTEBOOK_CANCEL,
  GET_JUPYTER_LAB_DETAILS,
  GET_JUPYTER_LAB_DETAILS_CANCEL,
} from './JupyterLabs.types'
import {
  getJupyterLabStatusAPI,
  startJupyterLabAPI,
  stopJupyterLabAPI,
  getJupyterNotebookAPI,
  getJupyterLabDetailsAPI,
} from './JupyterLabs.api'
import { showAlertMessage } from '../AlertsProvider'

function* getJupyterLabsStatusHandler(
  action: ReturnType<typeof getJupyterLabStatus>
) {
  const abortController = new AbortController()
  try {
    const status = yield call(
      getJupyterLabStatusAPI,
      action,
      abortController.signal
    )
    yield put(getJupyterLabStatusSuccess(status))
  } catch (e) {
    yield put(getJupyterLabStatusFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* startJupyterLabHandler(action: ReturnType<typeof startJupyterLab>) {
  const abortController = new AbortController()
  try {
    const status = yield call(
      startJupyterLabAPI,
      action,
      abortController.signal
    )
    if (status.error) {
      yield put(startJupyterLabFailure(status.error))
    } else {
      yield put(startJupyterLabSuccess(status))
    }
  } catch (e) {
    yield put(startJupyterLabFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* getJupyterLabDetailsHandler(
  action: ReturnType<typeof getJupyterLabDetails>
) {
  const abortController = new AbortController()
  try {
    const status = yield call(
      getJupyterLabDetailsAPI,
      action,
      abortController.signal
    )

    yield put(getJupyterLabDetailsSuccess(status))
  } catch (e) {
    yield put(getJupyterLabDetailsFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* stopJupyterLabHandler(action: ReturnType<typeof stopJupyterLab>) {
  const abortController = new AbortController()
  try {
    const status = yield call(stopJupyterLabAPI, action, abortController.signal)

    if (status.error) {
      yield put(stopJupyterLabFailure(status.error))
    } else {
      yield put(stopJupyterLabSuccess(status))
      history.push(generateURL('DASHBOARD', null))
    }
    const stopReasons = {
      inactivity: 'Lab Paused due to inactivity',
      manual: 'Lab Paused successfully',
      auto_time_expired: 'Lab Paused due to time limit exhausted',
    }

    let message = 'Lab Paused' // Default message

    if (
      action.payload.lab_stop_reason &&
      Object.prototype.hasOwnProperty.call(
        stopReasons,
        action.payload.lab_stop_reason
      )
    ) {
      message = stopReasons[action.payload.lab_stop_reason]
    }

    yield put(
      showAlertMessage({
        message,
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        closeOnTimeout: false,
      })
    )
  } catch (e) {
    yield put(stopJupyterLabFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* getJupyterNotebookNotebookHandler(
  action: ReturnType<typeof getJupyterNotebook>
) {
  const abortController = new AbortController()
  try {
    const status = yield call(
      getJupyterNotebookAPI,
      action,
      abortController.signal
    )

    if (status.signedUrl) {
      window.open(status.signedUrl, '_blank')
    }

    yield put(getJupyterNotebookSuccess(status))
  } catch (e) {
    yield put(getJupyterNotebookFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* jupyterLabsMiddleware() {
  yield takeLatest(
    GET_JUPYTER_LAB_STATUS,
    cancelable(getJupyterLabsStatusHandler, GET_JUPYTER_LAB_STATUS_CANCEL)
  )

  yield takeLatest(
    START_JUPYTER_LAB,
    cancelable(startJupyterLabHandler, START_JUPYTER_LAB_CANCEL)
  )

  yield takeLatest(
    GET_JUPYTER_LAB_DETAILS,
    cancelable(getJupyterLabDetailsHandler, GET_JUPYTER_LAB_DETAILS_CANCEL)
  )

  yield takeLatest(
    STOP_JUPYTER_LAB,
    cancelable(stopJupyterLabHandler, STOP_JUPYTER_LAB_CANCEL)
  )

  yield takeLatest(
    GET_JUPYTER_NOTEBOOK,
    cancelable(getJupyterNotebookNotebookHandler, GET_JUPYTER_NOTEBOOK_CANCEL)
  )
}
export default ([] as any).concat(jupyterLabsMiddleware())
