import { apiCall, getUserDetails, isProduction } from '../../../common/utils'

export async function fetchUpcomingWebinars(signal: AbortSignal) {
  const { id: userId, accessType } = getUserDetails()
  const response = await apiCall({
    url: `${process.env.REACT_APP_WEBSITE_URL}api/v1/webinars/upcoming${
      isProduction() ? '' : '?skip_staging_auth=1'
    }`,
    query: {
      user_id: userId,
      is_intl: accessType === 'free_international',
    },
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
