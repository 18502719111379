import { QuizAttemptNo, QuizSubmissionID } from 'common/types/courses/quiz'
import { apiCall, getUserDetails } from '../../../common/utils'
import {
  CodeEvalQuestionId,
  questionGetAiCodeSuggestions,
  questionGetAiCodeHints,
  questionRunSqlCode,
  questiontestSqlCode,
} from './CodingPlatform.action'
import {
  fetchRuntimes,
  codeExecute,
  questionRunCode,
  questionTestCode,
  listPlaygrounds,
  getPlayground,
  updatePlayground,
  deletePlayground,
  createPlayground,
  renamePlayground,
} from './index'

export async function fetchRuntimesAPI(
  action: ReturnType<typeof fetchRuntimes>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const query = {
    user_id: id,
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/coding/runtimes`,
    query,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function codeExecuteAPI(
  action: ReturnType<typeof codeExecute>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const query = {
    user_id: id,
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/coding/execute`,
    query,
    params: { signal, method: 'POST', body: JSON.stringify(action.payload) },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function questionRunCodeAPI(
  data: {
    ceQuestionId: CodeEvalQuestionId
  },
  action: ReturnType<typeof questionRunCode>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const query = {
    user_id: id,
    ceQuestionId: data.ceQuestionId,
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/coding/execute`,
    query,
    params: { signal, method: 'POST', body: JSON.stringify(action.payload) },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function questionTestCodeAPI(
  data: {
    submissionId: QuizSubmissionID
    validation_token: string
    attempt: QuizAttemptNo
  },
  action: ReturnType<typeof questionTestCode>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const query = {
    user_id: id,
    validation_token: data.validation_token,
    quiz_submission_attempt: data.attempt,
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/coding/quiz_submissions/${data.submissionId}/ce_questions/${action.meta.ceQuestionId}/test`,
    query,
    params: { signal, method: 'POST', body: JSON.stringify(action.payload) },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function questiontestSqlCodeAPI(
  data: {
    submissionId: QuizSubmissionID
    validation_token: string
    attempt: QuizAttemptNo
  },
  action: ReturnType<typeof questiontestSqlCode>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const query = {
    user_id: id,
    validation_token: data.validation_token,
    quiz_submission_attempt: data.attempt,
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/coding/quiz_submissions/${data.submissionId}/ce_questions/${action.meta.ceQuestionId}/test_sql`,
    query,
    params: { signal, method: 'POST', body: JSON.stringify(action.payload) },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function questionRunSqlCodeAPI(
  data: {
    ceQuestionId: CodeEvalQuestionId
  },
  action: ReturnType<typeof questionRunSqlCode>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const query = {
    user_id: id,
    ceQuestionId: data.ceQuestionId,
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/coding/execute_sql`,
    query,
    params: { signal, method: 'POST', body: JSON.stringify(action.payload) },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function questionTestCodeAPIForAiCodeSuggestions(
  data: {
    submissionId: QuizSubmissionID
    validation_token: string
    attempt: QuizAttemptNo
  },
  action: ReturnType<typeof questionGetAiCodeSuggestions>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const query = {
    user_id: id,
    validation_token: data.validation_token,
    quiz_submission_attempt: data.attempt,
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/coding/quiz_submissions/${data.submissionId}/ce_questions/${action.meta.ceQuestionId}/test`,
    query,
    params: { signal, method: 'POST', body: JSON.stringify(action.payload) },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function sqlQuestionTestCodeAPIForAiCodeSuggestions(
  data: {
    submissionId: QuizSubmissionID
    validation_token: string
    attempt: QuizAttemptNo
  },
  action: ReturnType<typeof questionGetAiCodeSuggestions>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const query = {
    user_id: id,
    validation_token: data.validation_token,
    quiz_submission_attempt: data.attempt,
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/coding/quiz_submissions/${data.submissionId}/ce_questions/${action.meta.ceQuestionId}/test_sql`,
    query,
    params: { signal, method: 'POST', body: JSON.stringify(action.payload) },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getAiCodeSuggestionsAPI(
  data: {
    submissionId: QuizSubmissionID
    validation_token: string
    attempt: QuizAttemptNo
  },
  action: ReturnType<typeof questionGetAiCodeSuggestions>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const query = {
    user_id: id,
    validation_token: data.validation_token,
    quiz_submission_attempt: data.attempt,
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/coding/quiz_submissions/${data.submissionId}/ce_questions/${action.meta.ceQuestionId}/get_ai_code_suggestions`,
    query,
    params: { signal, method: 'POST', body: JSON.stringify(action.payload) },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getAiCodeHintsAPI(
  data: {
    submissionId: QuizSubmissionID
    validation_token: string
    attempt: QuizAttemptNo
  },
  action: ReturnType<typeof questionGetAiCodeHints>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const query = {
    user_id: id,
    validation_token: data.validation_token,
    quiz_submission_attempt: data.attempt,
  }

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/coding/quiz_submissions/${data.submissionId}/ce_questions/${action.meta.ceQuestionId}/get_ai_hints`,
    query,
    params: { signal, method: 'POST', body: JSON.stringify(action.payload) },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function listPlaygroundsAPI(
  data: {
    language: string
  },
  action: ReturnType<typeof listPlaygrounds>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const query = {
    language: data.language,
  }
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/coding/playgrounds`,
    query,
    params: { signal, method: 'GET' },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function createPlaygroundsAPI(
  action: ReturnType<typeof createPlayground>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const query = {
    language: action.meta.language,
    fileName: action.meta.fileName,
  }
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/coding/playgrounds/new`,
    query,
    params: { signal, method: 'POST' },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getPlaygroundAPI(
  action: ReturnType<typeof getPlayground>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/coding/playgrounds/${action.meta.playground_id}`,
    params: { signal, method: 'GET' },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getPlaygroundDataFromS3(
  data: {
    signedUrl: string
  },
  action: ReturnType<typeof getPlayground>,
  signal: AbortSignal
) {
  const response: any = await apiCall({
    url: data.signedUrl,
    params: { signal, method: 'GET' },
    excludeProgramId: true,
    auth: false,
  })
  if (response.ok) {
    return response.text()
  }
  throw response
}

export async function getPlaygroundUpdateUrlAPI(
  action: ReturnType<typeof updatePlayground>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/coding/playgrounds/${action.meta.playground_id}/get_signed_url`,
    params: { signal, method: 'GET' },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function uploadPlaygroundToS3(
  data: {
    uploadUrl: string
  },
  action: ReturnType<typeof updatePlayground>,
  signal: AbortSignal
) {
  const body = action.payload.data
  const response: any = await apiCall({
    url: data.uploadUrl,
    params: { signal, method: 'PUT', body },
    excludeProgramId: true,
    auth: false,
  })
  if (response.ok) {
    return response.text()
  }
  throw response
}

export async function updatePlaygroundAPI(
  action: ReturnType<typeof updatePlayground>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const query = {
    user_id: id,
  }
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/coding/playgrounds/${action.meta.playground_id}`,
    query,
    params: { signal, method: 'PUT' },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function deletePlaygroundAPI(
  action: ReturnType<typeof deletePlayground>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/coding/playgrounds/${action.meta.playground_id}`,
    params: { signal, method: 'DELETE' },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function renamePlaygroundAPI(
  action: ReturnType<typeof renamePlayground>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/users/${id}/coding/playgrounds/${action.meta.playground_id}`,
    params: { signal, method: 'PUT', body: JSON.stringify(action.payload) },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
