export const MODULE_ITEM_FETCH = 'MODULE_ITEM_FETCH'
export const MODULE_ITEM_FETCH_FAILURE = 'MODULE_ITEM_FETCH_FAILURE'
export const MODULE_ITEM_FETCH_CANCEL = 'MODULE_ITEM_FETCH_CANCEL'
export const MODULE_ITEM_FETCH_SUCCESS = 'MODULE_ITEM_FETCH_SUCCESS'

export const MODULE_ITEMS_FETCH = 'MODULE_ITEMS_FETCH'
export const MODULE_ITEMS_FETCH_SUCCESS = 'MODULE_ITEMS_FETCH_SUCCESS'
export const MODULE_ITEMS_FETCH_FAILURE = 'MODULE_ITEMS_FETCH_FAILURE'
export const MODULE_ITEMS_FETCH_CANCEL = 'MODULE_ITEMS_FETCH_CANCEL'

export const MODULE_ITEMS_BULK_INSERT = 'MODULE_ITEMS_BULK_INSERT'
export const MODULE_ITEMS_ACCESS_DATA = 'MODULE_ITEMS_ACCESS_DATA'

export const MARK_ITEM_AS_READ = 'MARK_ITEM_AS_READ'
export const MARK_ITEM_AS_READ_FAILURE = 'MARK_ITEM_AS_READ_FAILURE'
export const MARK_ITEM_AS_READ_CANCEL = 'MARK_ITEM_AS_READ_CANCEL'
export const MARK_ITEM_AS_READ_SUCCESS = 'MARK_ITEM_AS_READ_SUCCESS'

export const FETCH_FEEDBACK_QA = 'FETCH_FEEDBACK_QA'
export const FETCH_FEEDBACK_QA_SUCCESS = 'FETCH_FEEDBACK_QA_SUCCESS'
export const FETCH_FEEDBACK_QA_FAILURE = 'FETCH_FEEDBACK_QA_FAILURE'
export const FETCH_FEEDBACK_QA_CANCEL = 'FETCH_FEEDBACK_QA_CANCEL'

export const SUBMIT_CONTENT_FEEDBACK = 'SUBMIT_CONTENT_FEEDBACK'
export const SUBMIT_CONTENT_FEEDBACK_SUCCESS = 'SUBMIT_CONTENT_FEEDBACK_SUCCESS'
export const SUBMIT_CONTENT_FEEDBACK_FAILURE = 'SUBMIT_CONTENT_FEEDBACK_FAILURE'
export const SUBMIT_CONTENT_FEEDBACK_CANCEL = 'SUBMIT_CONTENT_FEEDBACK_CANCEL'
