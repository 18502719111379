import { Reducer } from 'redux'
import { ActivityActions } from './Activity.actions'
import {
  FETCH_ACTIVITY_CONTENT,
  FETCH_ACTIVITY_CONTENT_SUCCESS,
  FETCH_ACTIVITY_CONTENT_FAILURE,
  FETCH_ACTIVITY_CONTENT_CANCEL,
  FETCH_RESIDENCY_CONTENT,
  FETCH_RESIDENCY_CONTENT_SUCCESS,
  FETCH_RESIDENCY_CONTENT_FAILURE,
  FETCH_RESIDENCY_CONTENT_CANCEL,
  UPDATE_CURRENT_ACTIVE_TAB,
} from './Activity.types'
import {
  ResidencyId,
  ResidencyData,
  ActivityData,
} from '../../../../common/types/dashboard'
import { ActivityTab } from '../../../components/Activity'
import { ProgramActions, UPDATE_ACTIVE_PROGRAM } from '../ProgramsProvider'

export interface ResidencyState {
  data?: {
    [s in ResidencyId]: ResidencyData[]
  }
  loading: boolean
  error: false | Error | Response
}

export interface ActivityState {
  data?: {
    ongoing?: ActivityData[]
    completed?: ActivityData[]
    upcoming?: ActivityData[]
    residencyData?: ResidencyState
    currentActiveTab?: ActivityTab | null
  }
  loading: boolean
  error: false | Error | Response
}

const initialState: ActivityState = {
  loading: false,
  error: false as false,
}

const activityReducer: Reducer<
  ActivityState,
  ActivityActions | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
> = (state = initialState, action): ActivityState => {
  switch (action.type) {
    case FETCH_ACTIVITY_CONTENT:
      return { ...state, loading: true, error: false }
    case FETCH_ACTIVITY_CONTENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          completed: action.payload.completed_feed_items,
          ongoing: action.payload.ongoing_feed_items,
          upcoming: action.payload.upcoming_feed_items,
        },
      }
    }
    case FETCH_ACTIVITY_CONTENT_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case FETCH_ACTIVITY_CONTENT_CANCEL:
      return {
        ...state,
        loading: false,
      }

    case FETCH_RESIDENCY_CONTENT:
      return {
        ...state,
        data: {
          ...state.data,
          residencyData: {
            ...(state.data ? state.data.residencyData : null),
            loading: true,
            error: false as false,
          },
        },
      }

    case FETCH_RESIDENCY_CONTENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          residencyData: {
            ...(state.data ? state.data.residencyData : null),
            loading: false,
            error: false as false,
            data: {
              ...(state.data && state.data.residencyData
                ? state.data.residencyData.data
                : null),
              [action.meta.residencyId]: [...action.payload.residency_details],
            },
          },
        },
      }

    case FETCH_RESIDENCY_CONTENT_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          residencyData: {
            ...(state.data ? state.data.residencyData : null),
            loading: false,
            error: action.payload,
          },
        },
      }

    case FETCH_RESIDENCY_CONTENT_CANCEL:
      return {
        ...state,
        data: {
          ...state.data,
          residencyData: {
            ...(state.data ? state.data.residencyData : null),
            loading: false,
            error: false as false,
          },
        },
      }

    case UPDATE_CURRENT_ACTIVE_TAB:
      return {
        ...state,
        data: {
          ...state.data,
          currentActiveTab: action.payload.activeTab,
        },
      }
    case UPDATE_ACTIVE_PROGRAM:
      return initialState

    default:
      return state
  }
}
export default activityReducer
