import * as types from './GlLabs.types'

export interface GlLabsPayload {
  glLabAssignmentId: number
  queryParams?: any
}

export const getGlLabAssignmentAndSubmissionData = (
  payload: GlLabsPayload
) => ({
  type: types.GET_GL_LAB_ASSIGNMENT_AND_SUBMISSION_DATA as typeof types.GET_GL_LAB_ASSIGNMENT_AND_SUBMISSION_DATA,
  payload,
})

export const getGlLabAssignmentAndSubmissionDataSuccess = (payload: any) => ({
  type: types.GET_GL_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_SUCCESS as typeof types.GET_GL_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_SUCCESS,
  payload,
})

export const getGlLabAssignmentAndSubmissionDataFailure = (payload: Error) => ({
  type: types.GET_GL_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_FAILURE as typeof types.GET_GL_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_FAILURE,
  payload,
})

export const getGlLabAssignmentAndSubmissionDataCancel = (payload: any) => ({
  type: types.GET_GL_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_CANCEL as typeof types.GET_GL_LAB_ASSIGNMENT_AND_SUBMISSION_DATA_CANCEL,
  payload,
})

export type CodingLabsActionTypes =
  | ReturnType<typeof getGlLabAssignmentAndSubmissionData>
  | ReturnType<typeof getGlLabAssignmentAndSubmissionDataSuccess>
  | ReturnType<typeof getGlLabAssignmentAndSubmissionDataFailure>
  | ReturnType<typeof getGlLabAssignmentAndSubmissionDataCancel>
