import { ProctoringData } from '../../../common/types/proctoring'
import { AppState } from '../../store'

export const getProctorAttemptId = () => (state: AppState): number | null => {
  const proctorData: ProctoringData = state.proctoring.data
  if (proctorData.options) {
    return proctorData.options.id
  }
  return null
}

export const getProctorAssessmentId = () => (
  state: AppState
): string | number | null => {
  const proctorData: ProctoringData = state.proctoring.data
  if (proctorData.options) {
    return proctorData.options.assessment_id
  }
  return null
}

export const getCurrentProctorCount = () => (state: AppState): number => {
  return state.proctoring.data.currentProctorCount
}
