import React from 'react'
import cx from 'classnames'
// eslint-disable-next-line import/no-unresolved
import { FontWeightProperty, TextTransformProperty } from 'csstype'
import ButtonBase, {
  ButtonProps as BaseButtonProps,
} from '@material-ui/core/Button'
import styles from './Button.module.css'

export interface ButtonProps extends BaseButtonProps {
  rounded: boolean
  fontWeight: FontWeightProperty
  textTransform: TextTransformProperty
}

const Button = ({
  className,
  rounded,
  fontWeight,
  textTransform,
  ...props
}: ButtonProps) => (
  <ButtonBase
    style={{ fontWeight, textTransform }}
    className={cx(className, rounded && styles.rounded)}
    {...props}
  />
)

Button.defaultProps = {
  rounded: false,
  fontWeight: 400,
  textTransform: 'none',
}

export default Button
