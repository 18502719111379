import { apiCall, getUserDetails } from '../../../../common/utils'

import { fetchCourseAssignments } from './Assignment.actions'

export async function getCourseAssignmentsAPI(
  action: ReturnType<typeof fetchCourseAssignments>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { courseId } = action.payload

  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/courses/${courseId}/assignments`,
    params: { signal },
    query: {
      user_id: id,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
