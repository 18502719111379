import { takeLatest, call, put, select } from 'redux-saga/effects'
import * as types from './PageData.types'
import { updatePageData } from './PageData.actions'
import * as API from './PageData.api'
import { contentTypeMapping } from '../../../common/utils/custom/courses/content'
import * as selectors from './PageData.selectors'
import {
  ModuleItemSequenceAsset,
  ItemID,
} from '../../../common/types/courses/moduleItem'
import {
  AssignmentID,
  AssignmentContentData,
} from '../../../common/types/courses/assignment'
import { CourseID } from '../../../common/types/courses'
import { assignmentAPIs } from '../Courses/ModuleItemsProvider/Assignment'
import { history } from '../../store/history'
import { generateURL, getCurrentReferrer } from '../../../common/utils'

function* handleSpecialAssignmentRedirection({
  assignmentId,
  courseId,
  itemId,
}: {
  assignmentId: AssignmentID
  courseId: CourseID
  itemId?: ItemID
}) {
  const data: AssignmentContentData | null = yield call(
    assignmentAPIs.getAssignmentContentData,
    {
      courseId,
      assignmentId,
    }
  )
  if (data) {
    const currentReferrer = getCurrentReferrer()
    if (data.quiz_id) {
      const urlObj = generateURL(
        'CONTENT',
        {
          path: { contentType: 'quizzes', courseId, contentId: data.quiz_id },
          search: { module_item_id: itemId },
        },
        { from: currentReferrer }
      )
      history.replace(urlObj)
      return true
    }
    if (data.discussion_topic) {
      const urlObj = generateURL(
        'CONTENT',
        {
          path: {
            contentType: 'discussion_topics',
            courseId,
            contentId: data.discussion_topic.id,
          },
          search: { module_item_id: itemId },
        },
        { from: currentReferrer }
      )
      history.replace(urlObj)
      return true
    }
  }
  return false
  // history.push('/404')
}

function* pageDataHandler(action: ReturnType<typeof updatePageData>) {
  try {
    const data = action.payload
    const pageData = yield select(selectors.getPageData())
    switch (data.page) {
      case 'MODULE_ITEM':
      case 'QUIZ_RUNNER':
      case 'QUIZ_ATTEMPT': {
        if (
          pageData &&
          (!pageData.moduleId || pageData.itemId != data.itemId) &&
          !pageData.contentNotFound
        ) {
          const sequenceData: ModuleItemSequenceAsset = yield call(
            API.getModuleItemSequence,
            {
              courseId: data.courseId,
              asset_id: data.itemId,
              asset_type: 'ModuleItem',
            }
          )
          if (sequenceData)
            yield put(
              updatePageData({
                ...data,
                moduleId: sequenceData.module_id,
                type: sequenceData.type,
              })
            )
          else {
            yield put(
              updatePageData({
                ...data,
                contentNotFound: true,
              })
            )
          }
        }
        return
      }
      case 'CONTENT': {
        if (
          pageData &&
          (!data.itemId ||
            pageData.contentId != data.contentId ||
            pageData.contentType != data.contentType) &&
          !pageData.contentNotFound
        ) {
          const sequenceData: ModuleItemSequenceAsset | null = yield call(
            API.getModuleItemSequence,
            {
              courseId: data.courseId,
              asset_id: data.contentId,
              asset_type: contentTypeMapping[data.contentType] as any,
            }
          )
          if (sequenceData) {
            yield put(
              updatePageData({
                ...data,
                moduleId: sequenceData.module_id,
                type: sequenceData.type,
                itemId: sequenceData.id,
              })
            )
            return
          }
          let result = false
          if (data.contentType === 'assignments') {
            result = yield handleSpecialAssignmentRedirection({
              assignmentId: data.contentId,
              courseId: data.courseId,
            })
          }
          if (!result) {
            yield put(
              updatePageData({
                ...data,
                contentNotFound: true,
              })
            )
          }
        }
        return
      }
      default:
        return
    }
  } catch (e) {
    console.log(e)
  }
}

function* pageDataMiddleware() {
  yield takeLatest(types.UPDATE_PAGE_DATA, pageDataHandler)
}

export default ([] as any).concat(pageDataMiddleware())
