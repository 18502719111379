export const FETCH_PEER_REVIEWS = 'FETCH_PEER_REVIEWS'
export const FETCH_PEER_REVIEWS_SUCCESS = 'FETCH_PEER_REVIEWS_SUCCESS'
export const FETCH_PEER_REVIEWS_FAILURE = 'FETCH_PEER_REVIEWS_FAILURE'
export const FETCH_PEER_REVIEWS_CANCEL = 'FETCH_PEER_REVIEWS_CANCEL'

export const FETCH_PEER_REVIEWS_DQ = 'FETCH_PEER_REVIEWS_DQ'
export const FETCH_PEER_REVIEWS_DQ_SUCCESS = 'FETCH_PEER_REVIEWS_DQ_SUCCESS'
export const FETCH_PEER_REVIEWS_DQ_FAILURE = 'FETCH_PEER_REVIEWS_DQ_FAILURE'
export const FETCH_PEER_REVIEWS_DQ_CANCEL = 'FETCH_PEER_REVIEWS_DQ_CANCEL'

export const POST_PEER_REVIEW_COMMENT = 'POST_PEER_REVIEW_COMMENT'
export const POST_PEER_REVIEW_COMMENT_SUCCESS =
  'POST_PEER_REVIEW_COMMENT_SUCCESS'
export const POST_PEER_REVIEW_COMMENT_FAILURE =
  'POST_PEER_REVIEW_COMMENT_FAILURE'
export const POST_PEER_REVIEW_COMMENT_CANCEL = 'POST_PEER_REVIEW_COMMENT_CANCEL'

export const POST_PEER_REVIEW_COMMENT_REPLY = 'POST_PEER_REVIEW_COMMENT_REPLY'
export const POST_PEER_REVIEW_COMMENT_REPLY_SUCCESS =
  'POST_PEER_REVIEW_COMMENT_REPLY_SUCCESS'
export const POST_PEER_REVIEW_COMMENT_REPLY_FAILURE =
  'POST_PEER_REVIEW_COMMENT_REPLY_FAILURE'
export const POST_PEER_REVIEW_COMMENT_REPLY_CANCEL =
  'POST_PEER_REVIEW_COMMENT_REPLY_CANCEL'

export const UPDATE_PEER_REVIEW_COMMENT = 'UPDATE_PEER_REVIEW_COMMENT'
export const UPDATE_PEER_REVIEW_COMMENT_SUCCESS =
  'UPDATE_PEER_REVIEW_COMMENT_SUCCESS'
export const UPDATE_PEER_REVIEW_COMMENT_FAILURE =
  'UPDATE_PEER_REVIEW_COMMENT_FAILURE'
export const UPDATE_PEER_REVIEW_COMMENT_CANCEL =
  'UPDATE_PEER_REVIEW_COMMENT_CANCEL'

export const FETCH_PEER_REVIEWS_DQ_COMMENTS = 'FETCH_PEER_REVIEWS_DQ_COMMENTS'
export const FETCH_PEER_REVIEWS_DQ_COMMENTS_SUCCESS =
  'FETCH_PEER_REVIEWS_DQ_COMMENTS_SUCCESS'
export const FETCH_PEER_REVIEWS_DQ_COMMENTS_FAILURE =
  'FETCH_PEER_REVIEWS_DQ_COMMENTS_FAILURE'
export const FETCH_PEER_REVIEWS_DQ_COMMENTS_CANCEL =
  'FETCH_PEER_REVIEWS_DQ_COMMENTS_CANCEL'

export const UPDATE_PEER_REVIEWS_COMMENT_RATING =
  'UPDATE_PEER_REVIEWS_COMMENT_RATING'
export const UPDATE_PEER_REVIEWS_COMMENT_RATING_SUCCESS =
  'UPDATE_PEER_REVIEWS_COMMENT_RATING_SUCCESS'
export const UPDATE_PEER_REVIEWS_COMMENT_RATING_FAILURE =
  'UPDATE_PEER_REVIEWS_COMMENT_RATING_FAILURE'
export const UPDATE_PEER_REVIEWS_COMMENT_RATING_CANCEL =
  'UPDATE_PEER_REVIEWS_COMMENT_RATING_CANCEL'

export const REACT_TO_PEER_REVIEW = 'REACT_TO_PEER_REVIEW'
export const REACT_TO_PEER_REVIEW_SUCCESS = 'REACT_TO_PEER_REVIEW_SUCCESS'
export const REACT_TO_PEER_REVIEW_FAILURE = 'REACT_TO_PEER_REVIEW_FAILURE'
export const REACT_TO_PEER_REVIEW_CANCEL = 'REACT_TO_PEER_REVIEW_CANCEL'

export const FETCH_PEER_REVIEW_REACTION_DATA = 'FETCH_PEER_REVIEW_REACTION_DATA'
export const FETCH_PEER_REVIEW_REACTION_DATA_SUCCESS =
  'FETCH_PEER_REVIEW_REACTION_DATA_SUCCESS'
export const FETCH_PEER_REVIEW_REACTION_DATA_FAILURE =
  'FETCH_PEER_REVIEW_REACTION_DATA_FAILURE'
export const FETCH_PEER_REVIEW_REACTION_DATA_CANCEL =
  'FETCH_PEER_REVIEW_REACTION_DATA_CANCEL'

export const MARK_COMMENT_AS_READ = 'MARK_COMMENT_AS_READ'
export const MARK_COMMENT_AS_READ_SUCCESS = 'MARK_COMMENT_AS_READ_SUCCESS'
export const MARK_COMMENT_AS_READ_FAILURE = 'MARK_COMMENT_AS_READ_FAILURE'
export const MARK_COMMENT_AS_READ_CANCEL = 'MARK_COMMENT_AS_READ_CANCEL'

export const FETCH_PEER_REVIEW_FILTERS = 'FETCH_PEER_REVIEW_FILTERS'
export const FETCH_PEER_REVIEW_FILTERS_SUCCESS =
  'FETCH_PEER_REVIEW_FILTERS_SUCCESS'
export const FETCH_PEER_REVIEW_FILTERS_FAILURE =
  'FETCH_PEER_REVIEW_FILTERS_FAILURE'
export const FETCH_PEER_REVIEW_FILTERS_CANCEL =
  'FETCH_PEER_REVIEW_FILTERS_CANCEL'
