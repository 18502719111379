import { Reducer } from 'redux'
import {
  MentorshipRecordingSessionItemData,
  MentorshipRecordingSessionItemID,
  MentorSessionID,
} from '../../../../common/types/courses/mentorshipRecording'
import * as types from './MentorshipRecordings.types'
import { RecordingsActionTypes } from './MentorshipRecordings.actions'
import { CourseID } from '../../../../common/types/courses'

export interface MentorshipRecordingsState {
  data: {
    byId: {
      [s in MentorshipRecordingSessionItemID]?: MentorshipRecordingSessionItemData
    }
    byCourse: {
      [c in CourseID]?: MentorSessionID[]
    }
    bySession: {
      [c in MentorSessionID]?: MentorshipRecordingSessionItemID[]
    }
  }
  byCourse: {
    [s in CourseID]?: {
      completed: boolean
      loading: boolean
      error: false | Error | Response
    }
  }
  bySession: {
    [s in MentorSessionID]?: {
      completed: boolean
      loading: boolean
      error: false | Error | Response
    }
  }
  byRecording: {
    [s in MentorshipRecordingSessionItemID]?: {
      completed: boolean
      loading: boolean
      error: false | Error | Response
    }
  }
}

const initialState: MentorshipRecordingsState = {
  data: { byId: {}, byCourse: {}, bySession: {} },
  byCourse: {},
  bySession: {},
  byRecording: {},
}

function isRecordingsUpdated(
  currData: MentorshipRecordingSessionItemID[],
  newData: MentorshipRecordingSessionItemID[]
) {
  // if both are empty then return false
  if (!currData.length && !newData.length) return false

  // if both are of same length then check for individual items.
  if (currData.length == newData.length) {
    const currDataSorted = currData.sort()
    const newDataSorted = newData.sort()
    for (let i = 0; i < currDataSorted.length; ++i) {
      if (currDataSorted[i] != newDataSorted[i]) return true
    }
  }

  return true
}

const normalizeCourseData = (
  data: MentorshipRecordingSessionItemData[],
  currentData: MentorshipRecordingsState['data'],
  courseId: CourseID
): MentorshipRecordingsState['data'] => {
  const courseRecordingsMapping: MentorshipRecordingsState['data']['byCourse'] = {
    ...currentData.byCourse,
  }
  const sessionRecordingsMapping: MentorshipRecordingsState['data']['bySession'] = {
    ...currentData.bySession,
  }
  const recordingData: MentorshipRecordingsState['data']['byId'] = {
    ...currentData.byId,
  }

  const currentCourseSessIds: MentorSessionID[] =
    courseRecordingsMapping[courseId] || []

  const newSessionIds: MentorSessionID[] = []
  const newRecIds: MentorshipRecordingSessionItemID[] = []

  data.forEach(rec => {
    if (!newSessionIds.includes(rec.mentored_group_session_id)) {
      newSessionIds.push(rec.mentored_group_session_id)
    }
    newRecIds.push(rec.mentored_session_recording_id)

    recordingData[rec.mentored_session_recording_id] = rec
    const sessId = rec.mentored_group_session_id
    const recId = rec.mentored_session_recording_id
    if (!sessionRecordingsMapping[sessId]) {
      sessionRecordingsMapping[sessId] = []
    }
    if (!sessionRecordingsMapping[sessId]!.includes(recId))
      sessionRecordingsMapping[sessId]!.push(recId)
  })

  courseRecordingsMapping[courseId] = newSessionIds

  const currentCourseRecordingIds: MentorshipRecordingSessionItemID[] = []
  currentCourseSessIds.forEach(sessId => {
    currentCourseRecordingIds.push(...sessionRecordingsMapping[sessId])
  })

  if (isRecordingsUpdated(currentCourseRecordingIds, newRecIds)) {
    // remove data only if old data is present
    if (currentCourseRecordingIds.length) {
      currentCourseRecordingIds.forEach(recId => {
        if (!newRecIds.includes(recId)) {
          // remove the recId from session recording mappings
          const sessionId = recordingData[recId]!.mentored_group_session_id
          sessionRecordingsMapping[sessionId]!.splice(
            sessionRecordingsMapping[sessionId]!.indexOf(recId),
            1
          )
          // delete the recording data
          delete recordingData[recId]
        }
      })
    }
  }

  return {
    byId: recordingData,
    byCourse: courseRecordingsMapping,
    bySession: sessionRecordingsMapping,
  }
}

const normalizeSessionData = (
  data: MentorshipRecordingSessionItemData[],
  currentData: MentorshipRecordingsState['data'],
  sessId: MentorSessionID
): MentorshipRecordingsState['data'] => {
  const courseRecordingsMapping: MentorshipRecordingsState['data']['byCourse'] = {
    ...currentData.byCourse,
  }
  const sessionRecordingsMapping: MentorshipRecordingsState['data']['bySession'] = {
    ...currentData.bySession,
  }
  const recordingData: MentorshipRecordingsState['data']['byId'] = {
    ...currentData.byId,
  }

  const newRecIds = data.map(rec => {
    // add the updated recordings to the new recordings
    recordingData[rec.mentored_session_recording_id] = rec

    // return recording ids to the newRecIds array
    return rec.mentored_session_recording_id
  })
  const sessionRecordingIds: MentorshipRecordingSessionItemID[] =
    sessionRecordingsMapping[sessId] || []

  if (isRecordingsUpdated(sessionRecordingIds, newRecIds)) {
    // if there are old recordings remove them if not present in new recordings
    if (sessionRecordingIds.length) {
      // remove the old recordings
      sessionRecordingIds.forEach(recId => {
        if (!newRecIds.includes(recId)) {
          delete recordingData[recId]
        }
      })
    }
  }
  // update the session
  sessionRecordingsMapping[sessId] = newRecIds

  return {
    byId: recordingData,
    byCourse: courseRecordingsMapping,
    bySession: sessionRecordingsMapping,
  }
}

const RecordingsReducer: Reducer<
  MentorshipRecordingsState,
  RecordingsActionTypes
> = (state = initialState, action): MentorshipRecordingsState => {
  switch (action.type) {
    case types.COURSE_MENTORSHIP_RECORDINGS_FETCH:
      return {
        ...state,
        byCourse: {
          ...state.byCourse,
          [action.meta.courseId]: {
            completed: false,
            loading: true,
            error: false,
          },
        },
      }
    case types.COURSE_MENTORSHIP_RECORDINGS_FETCH_SUCCESS: {
      return {
        ...state,
        data: {
          ...normalizeCourseData(
            action.payload,
            state.data,
            action.meta.courseId
          ),
        },
        byCourse: {
          ...state.byCourse,
          [action.meta.courseId]: {
            completed: true,
            loading: false,
            error: false,
          },
        },
      }
    }
    case types.COURSE_MENTORSHIP_RECORDINGS_FETCH_FAILURE:
      return {
        ...state,
        byCourse: {
          ...state.byCourse,
          [action.meta.courseId]: {
            completed: false,
            loading: false,
            error: action.payload,
          },
        },
      }

    case types.SESSION_MENTORSHIP_RECORDINGS_FETCH:
      return {
        ...state,
        bySession: {
          ...state.bySession,
          [action.meta.sessionId]: {
            completed: false,
            loading: true,
            error: false,
          },
        },
      }

    case types.SESSION_MENTORSHIP_RECORDINGS_FETCH_SUCCESS: {
      return {
        ...state,
        data: {
          ...normalizeSessionData(
            action.payload,
            state.data,
            action.meta.sessionId
          ),
        },
        bySession: {
          ...state.bySession,
          [action.meta.sessionId]: {
            completed: true,
            loading: false,
            error: false,
          },
        },
      }
    }

    case types.SESSION_MENTORSHIP_RECORDINGS_FETCH_FAILURE: {
      return {
        ...state,
        bySession: {
          ...state.bySession,
          [action.meta.sessionId]: {
            completed: false,
            loading: false,
            error: action.payload,
          },
        },
      }
    }

    case types.MENTORSHIP_RECORDING_DETAIL_FETCH: {
      return {
        ...state,
        byRecording: {
          ...state.byRecording,
          [action.meta.recordingId]: {
            completed: false,
            loading: true,
            error: false,
          },
        },
      }
    }

    case types.MENTORSHIP_RECORDING_DETAIL_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.recordingId]: {
              ...state.data.byId[action.meta.recordingId]!,
              ...action.payload,
            },
          },
        },
        byRecording: {
          ...state.byRecording,
          [action.meta.recordingId]: {
            completed: true,
            loading: false,
            error: false,
          },
        },
      }
    }

    case types.MENTORSHIP_RECORDING_DETAIL_FAILURE: {
      return {
        ...state,
        byRecording: {
          ...state.byRecording,
          [action.meta.recordingId]: {
            completed: false,
            loading: false,
            error: action.payload,
          },
        },
      }
    }

    case types.UPDATE_MENTORSHIP_RECORDING_VIDEO_WATCHED_PERCENTAGE: {
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [action.meta.recordingId]: {
              ...state.data.byId[action.meta.recordingId]!,
              percentage_watched: action.payload.percentage,
            },
          },
        },
      }
    }

    default:
      return state
  }
}

export default RecordingsReducer
