import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Link,
  useMediaQuery,
} from '@material-ui/core'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { mixpanel } from 'common/utils/mixpanel'
import { glaUtmParams } from 'common/utils/custom/user'
import {
  getLocalStorageItemWithExpiry,
  getPageName,
  setLocalStorageItemWithExpiry,
} from 'common/utils'
import {
  AllProgramData,
  AllProgramsData,
  IDegreeRecommendations,
  IPgpRecommendations,
} from 'common/types/programs'
import ChevronIcon from 'common/images/chevron_right.svg'
import glcaPopupImage from 'common/images/glca_popup_image.png'
import { MIT_PROGRAMS } from 'common/constants'
import * as DOMPurify from 'dompurify'
import {
  ALLOWED_HTML_TAGS,
  FORBID_ATTRS,
} from 'common/utils/custom/programSupport'
import RawHTML from '../Utils/RawHTML'
import styles from './AcademyPgpRecommendationDialog.module.css'

interface Props {
  onClose: () => void
  allProgramsData: AllProgramsData
  pgpRecommendation: IPgpRecommendations
  degreeRecommendation: IDegreeRecommendations
  showPgProgram: boolean
  isInternationalUser: boolean
  moreThanOneYearsExperience: boolean
  cookieExpiry: number
}

interface PopupCookies {
  [key: string]: string;
}

const AcademyPgpReconnendationDialog = (props: Props) => {
  const location = useLocation()
  const isMobile = useMediaQuery('(max-width: 768px)', { noSsr: true })
  let program: AllProgramData | Record<string, never> = {}
  const pgReferalPopupIndex = getLocalStorageItemWithExpiry(
    'PgReferalPopupIndex'
  )
  const degreeReferalPopupIndex = getLocalStorageItemWithExpiry(
    'DegreeReferalPopupIndex'
  )

  const getProgramFromRecommendation = (
    recommendation: IPgpRecommendations | IDegreeRecommendations,
    popupIndex: PopupCookies,
    localStorageKey: string
  ): AllProgramData | Record<string, never> => {
    if (!recommendation || !Object.keys(recommendation).length) return {}

    const recommendationIndex =
      (parseInt(popupIndex?.value, 10) + 1) %
        Object.keys(recommendation).length || 0

    setLocalStorageItemWithExpiry(
      localStorageKey,
      recommendationIndex,
      props.cookieExpiry
    )

    const sortedProgramIds = Object.entries(recommendation)
      .sort(
        ([, bannerData1], [, bannerData2]) =>
          bannerData1.order - bannerData2.order
      )
      .map(([programId]) => programId);

    return props.allProgramsData.programs[sortedProgramIds[recommendationIndex]];
  }

  if (props.showPgProgram) {
    if (props.pgpRecommendation) {
      program = getProgramFromRecommendation(
        props.pgpRecommendation,
        pgReferalPopupIndex,
        'PgReferalPopupIndex'
      )
    }

    if (
      program &&
      props.isInternationalUser &&
      props.moreThanOneYearsExperience
    ) {
      const mitAdspProgram = Object.values(props.allProgramsData.programs).find(
        p => p.olympus_code === MIT_PROGRAMS['MIT-ADSP']
      )
      if (mitAdspProgram) {
        program = mitAdspProgram
      }
    }
  } else if (props.degreeRecommendation) {
    program = getProgramFromRecommendation(
      props.degreeRecommendation,
      degreeReferalPopupIndex,
      'DegreeReferalPopupIndex'
    )
  }

  const gl_query_string =
    program &&
    glaUtmParams(
      `${getPageName(location.pathname)}_loggedin_recommendation_popup`,
      program.course_program_code
    )

  const programPartner =
    program &&
    program.partners &&
    program.partners.length &&
    props.allProgramsData.program_partners &&
    Object.keys(props.allProgramsData.program_partners).length &&
    props.allProgramsData.program_partners[program.partners[0]]
      ? props.allProgramsData.program_partners[program.partners[0]]
      : null

  const getLinkUrl = (pgm: AllProgramData | Record<string, never>) => {
    const pageUrl = pgm.landing_page_url || pgm.program_page_url
    return pageUrl ? `${pageUrl.split('?')[0]}?${gl_query_string}` : ''
  }

  const popupImage = programPartner && programPartner.gla_partner_popup_image
  const mobPopupImage = programPartner && programPartner.card_image
  const popupSubHeadingText =
    program?.popup_subheading ||
    'with the program best suited for your career growth'

  return program ? (
    <Dialog
      open
      fullScreen={isMobile}
      onClose={props.onClose}
      classes={{ paper: styles.dialog }}
    >
      <DialogContent
        className={styles.academyReferalDialogContent}
        data-testid="academy-pgp-recommendation-dialog-popup"
      >
        <Box className={styles.dialogSegregationCont}>
          <Box
            className={styles.popupCardCont}
            style={
              isMobile
                ? {
                    backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, -0.4) 0%, rgba(0, 0, 0, 0.24) 100%), url('${mobPopupImage}')`,
                  }
                : {
                    backgroundImage: `url('${
                      program.olympus_code &&
                      program.olympus_code.includes('GLCA')
                        ? glcaPopupImage
                        : popupImage
                    }')`,
                  }
            }
          ></Box>
          <Box className={styles.popupDataCont}>
            <Box class={styles.popupChipCont}>
              <Typography className={styles.popupChip}>Paid</Typography>
            </Box>
            <Box className={styles.closePopupDiv}>
              <IconButton
                className={styles.closePopup}
                onClick={props.onClose}
                data-testid="academy-pgp-recommendation-dialog-close-button"
              >
                &times;
              </IconButton>
            </Box>
            <Box className={styles.popupData}>
              <Box className={styles.popupPartnerImageCont}>
                {programPartner && programPartner.partner_image && (
                  <img
                    src={programPartner.partner_image}
                    className={styles.popupPartnerImage}
                    alt="Partner"
                  />
                )}
              </Box>
              <Typography className={styles.popupHeading}>
                {program.gla_program_popup_text}
              </Typography>
              <Typography className={styles.popupSubHeading}>
                {popupSubHeadingText}
              </Typography>
              <Box className={styles.nameCont}>
                {program && program.gla_program_popup_details ? (
                  <RawHTML
                    className={styles.popup_program_details}
                    html={DOMPurify.sanitize(
                      program.gla_program_popup_details,
                      {
                        ALLOWED_TAGS: ALLOWED_HTML_TAGS,
                        FORBID_ATTR: FORBID_ATTRS,
                      }
                    )}
                  />
                ) : (
                  <>
                    <Typography className={styles.partnerName}>
                      {programPartner
                        ? (
                            programPartner.partner ||
                            programPartner.partner_image_text
                          ).toUpperCase()
                        : null}
                    </Typography>
                    <Typography className={styles.programName}>
                      {program.program_name}
                    </Typography>
                  </>
                )}
              </Box>
              <Box className={styles.popupCtaCont}>
                <Box className={styles.browseContainer}>
                  <Link
                    className={styles.browseProgramButton}
                    target="_blank"
                    href={getLinkUrl(program)}
                    onClick={() => {
                      mixpanel.track('pgpRecommendationPopupBrowseProgram', {
                        page: 'Olympus',
                        programName: program.program_name,
                      })
                      props.onClose()
                    }}
                  >
                    EXPLORE NOW
                    <span>
                      <img src={ChevronIcon} alt="" />
                    </span>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  ) : null
}

export default AcademyPgpReconnendationDialog
