import { apiCall, getUserDetails } from 'common/utils'
import { ProctoredEventType } from 'common/types/codingLabs'
import {
  startQuizCodingLabs,
  getQuizCodingLabStatus,
} from './QuizCodingLabs.action'

export async function startCodingLabsForQuizAPI(
  action: ReturnType<typeof startQuizCodingLabs>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { quizID } = action.payload

  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/fsd_platform/quiz_coding_labs/${quizID}/users/${id}/service/start`,
    params: {
      method: 'PUT',
      signal,
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getQuizCodingLabStatusAPI(
  action: ReturnType<typeof getQuizCodingLabStatus>,
  signal: AbortSignal
) {
  const { id } = getUserDetails()
  const { coding_lab_id } = action.payload

  const response = await apiCall({
    url: `${process.env.REACT_APP_ELEVATE_API_URL}v1/fsd_platform/coding_labs/${coding_lab_id}/users/${id}/service/status`,
    params: { signal },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}
