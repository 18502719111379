import { cancelable } from 'common/utils'
import { call, cancelled, put, takeEvery } from 'redux-saga/effects'
import {
  getCurriculumAiMentorUserData,
  getCurriculumAiMentorUserDataFailure,
  getCurriculumAiMentorUserDataSuccess,
} from './CurriculumAiMentor.action'
import {
  GET_CURRICULUM_AI_MENTOR_USER_DATA,
  GET_CURRICULUM_AI_MENTOR_USER_DATA_CANCEL,
} from './CurriculumAiMentor.types'
import { getCurriculumAiMentorUserDataAPI } from './CurriculumAiMentor.api'

function* getCurriculumAiMentorUserDataHandler(
  action: ReturnType<typeof getCurriculumAiMentorUserData>
) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      getCurriculumAiMentorUserDataAPI,
      action,
      abortController.signal
    )
    yield put(getCurriculumAiMentorUserDataSuccess(data, action.meta))
  } catch (error) {
    yield put(getCurriculumAiMentorUserDataFailure(error, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* getCurriculumAiMentorUserDataMiddleware() {
  yield takeEvery(
    GET_CURRICULUM_AI_MENTOR_USER_DATA,
    cancelable(getCurriculumAiMentorUserDataHandler, [
      GET_CURRICULUM_AI_MENTOR_USER_DATA_CANCEL,
    ])
  )
}

export default ([] as any).concat(getCurriculumAiMentorUserDataMiddleware())
