import { BatchData } from 'common/types/programs'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSelectedBatch } from 'web/hooks'
import { showGLLoader as showGLLoaderAction } from 'web/providers/Common/Common.actions'
import {
  getBatches as getBatchesAction,
  programSelectors,
  updateActiveProgram,
} from 'web/providers/Dashboard/ProgramsProvider'
import { AppState } from 'web/store'

export function useSwitchBatch() {
  const programData = useSelector(programSelectors.getActiveProgramDetails())
  const enrolledPrograms = useSelector(programSelectors.getAllProgramIds())
  const batches = useSelector<AppState, BatchData[]>(
    state => state.programs.data.batches
  )

  const dispatch = useDispatch()
  const showGLLoader = useCallback(
    data => dispatch(showGLLoaderAction(data)),
    []
  )
  const getBatches = useCallback(() => {
    dispatch(getBatchesAction())
  }, [])

  const selectedBatch = useSelectedBatch()
  const switchableBatches = useMemo(() => {
    if (batches != null && batches !== undefined && batches.length) {
      return batches.map((batch: BatchData) => ({
        pbId: batch.program_group_id,
        displayName:
          batch.program_type === 'semester_program'
            ? `${batch.semester_key_display_name} ${batch.semester_number}`
            : `${batch.program_display_code} (${batch.program_group_name})`,
        description:
          batch.program_type === 'semester_program'
            ? batch.degree_program_display_code
            : batch.program_name,
        onPress: () => switchBatch(batch.program_group_id),
        isSelected: selectedBatch.program_group_id == batch.program_group_id,
        shortHandDisplayName: `${batch.program_display_code}`,
      }))
    }
    return []
  }, [batches, selectedBatch])

  useEffect(() => {
    getBatches()
  }, [])

  async function switchBatch(pbId: any) {
    const selectedBatchLocal = batches.find(
      (batch: { program_group_id: any }) => batch.program_group_id == pbId
    )
    if (selectedBatchLocal != null && selectedBatchLocal !== undefined) {
      showGLLoader({ show: true })
      if (
        pbId &&
        programData &&
        pbId !== programData.id &&
        enrolledPrograms &&
        enrolledPrograms.includes(pbId)
      ) {
        dispatch(updateActiveProgram({ programId: pbId, redirect: true }))
      }
    }
  }

  return {
    switchBatch,
    switchableBatches,
    selectedBatch: {
      pbId: selectedBatch.program_group_id,
      displayName: `${selectedBatch.program_display_code} (${selectedBatch.program_group_name})`,
      onPress: () => switchBatch(selectedBatch.program_group_id),
      shortHandDisplayName: `${selectedBatch.program_display_code}`,
    },
  }
}
