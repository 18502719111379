import { CourseID } from 'common/types/courses'
import {
  FETCH_COURSE_ASSIGNMENTS,
  FETCH_COURSE_ASSIGNMENTS_SUCCESS,
  FETCH_COURSE_ASSIGNMENTS_FAILURE,
  FETCH_COURSE_ASSIGNMENTS_CANCEL,
} from './Assignment.types'

interface CourseAssignmentsPayload {
  courseId: CourseID
}

export const fetchCourseAssignments = (payload: CourseAssignmentsPayload) => ({
  type: FETCH_COURSE_ASSIGNMENTS as typeof FETCH_COURSE_ASSIGNMENTS,
  payload,
})

export const fetchCourseAssignmentsSuccess = (payload: any) => ({
  type: FETCH_COURSE_ASSIGNMENTS_SUCCESS as typeof FETCH_COURSE_ASSIGNMENTS_SUCCESS,
  payload,
})

export const fetchCourseAssignmentsFailure = (payload: Error) => ({
  type: FETCH_COURSE_ASSIGNMENTS_FAILURE as typeof FETCH_COURSE_ASSIGNMENTS_FAILURE,
  payload,
})

export const fetchCourseAssignmentsCancel = () => ({
  type: FETCH_COURSE_ASSIGNMENTS_CANCEL as typeof FETCH_COURSE_ASSIGNMENTS_CANCEL,
})

export type CourseAssignmentsActions =
  | ReturnType<typeof fetchCourseAssignments>
  | ReturnType<typeof fetchCourseAssignmentsSuccess>
  | ReturnType<typeof fetchCourseAssignmentsFailure>
  | ReturnType<typeof fetchCourseAssignmentsCancel>
