import React, { useState, useEffect } from 'react'
import { mixpanel } from '../../../../common/utils/mixpanel'
import ErrorIllustrations from '../../../containers/Utils/ErrorIllustrations'
import { checkNetworkConnection } from '../../../../common/utils'

const NetworkDetector = (props: { error: Error }) => {
  const [isDisconnected, updateIsDisconnected] = useState(true)

  useEffect(() => {
    checkNetworkConnection().then((connection: boolean) => {
      if (connection) {
        updateIsDisconnected(false)
        mixpanel.track('LMS - Server Unreachable', {
          error_message: props.error.message,
          location_url: window.location.href,
        })
      } else if (!isDisconnected) updateIsDisconnected(true)
    })
  }, [])

  if (isDisconnected) return <ErrorIllustrations type="network" />
  return <ErrorIllustrations type="serverUnreachable" />
}

export default NetworkDetector
