import { ContentID } from 'common/types/courses/content'
import { TribeClient } from '@tribeplatform/gql-client'
import { apiCall, communitySsoToken, getCommunityJwtToken } from 'common/utils'
import {
  communityAlumniGroupMapping,
  getCommunityPostTypeId,
} from 'common/utils/custom/community'
import {
  MemberListFilterByOperator,
  PostListFilterByEnum,
  PostListOrderByEnum,
  PostMappingFieldInput,
  PostMappingTypeEnum,
  SearchEntityType,
  Image,
  PostListFilterByOperator,
  SpaceMemberListOrderByEnum,
} from '@tribeplatform/gql-client/types'
import moment from 'moment'
import {
  ICommunityInviteAcceptRequest,
  ICommunityInviteRequest,
} from 'common/types/communityReferral'
import {
  deleteQuestion,
  postAnswer,
  postQuestion,
  updateAnswer,
  updateQuestion,
} from './Community.actions'
import { fetchNotifications } from '.'

const TRIBE_GRAPHQL_URL = 'https://app.tribe.so/graphql'
const NEW_TRIBE_GRAPHQL_URL = 'https://api.bettermode.com'

const DEFAULT_USER_IMAGE = `${process.env.REACT_APP_LMS_URL}/images/messages/avatar-50.png`

export async function fetchSimilarQuestionsAPI(keyword: string) {
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })
  const result = await client.search(
    {
      input: {
        query: keyword.replace(/[^a-zA-Z ]/g, ''),
      },
    },
    {
      in: { collection: { space: 'basic' } },
    },
    accessToken
  )
  const modifiedResult = result.hits.filter(
    data => data.entityType == SearchEntityType.post
  )
  return modifiedResult?.[0]?.hits || []
}

export async function fetchNotificationsAPI(
  action: ReturnType<typeof fetchNotifications>
) {
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })
  const result = await client.notifications.count(accessToken)
  return { count: result }
}

export async function fetchTopicsAPI(params: {}) {
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })
  const result = await client.tags.list({ limit: 500 }, 'basic', accessToken)
  return result.nodes || []
}

export async function postQuestionAPI(
  params: ReturnType<typeof postQuestion>,
  communityGroupId: string,
  attachmentIds: string[] = []
) {
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })

  const mappingFields = [
    {
      key: 'title',
      value: `"${params.payload.title.replace(/\n/g, ' ')}"`,
      type: PostMappingTypeEnum.text,
    },
  ]

  let contentValue: string | null = null

  if (params.payload.description) {
    contentValue = `"<p>${params.payload.description.replace(
      /\n/g,
      '<br>'
    ).replace(/"/g, '\\"')}</p>"`
  }

  if (attachmentIds && attachmentIds.length > 0) {
    const attachmentFields = attachmentIds.map(
      id =>
        `<attachment data-type=\\"attachment\\" data-id=\\"${id}\\"></attachment>`
    )
    contentValue = contentValue
      ? `"<p>${params.payload.description}</p>${attachmentFields.join(
          '<br>'
        )}"`.replace(/\n/g, '<br>')
      : `"${attachmentFields.join('<br>')}"`
  }

  if (contentValue) {
    mappingFields.push({
      key: 'content',
      value: contentValue,
      type: PostMappingTypeEnum.html,
    })
  }

  const result = await client.posts.create(
    {
      spaceId: communityGroupId,
      input: {
        tagIds: params.payload.topicIds,
        mappingFields,
        postTypeId: getCommunityPostTypeId('post'),
        publish: true,
      },
    },
    'basic',
    accessToken
  )
  return {
    title: result.title,
    updatedAt: result.updatedAt,
    createdAt: result.createdAt,
    id: result.id,
    _id: result.id,
    topics:
      result.tags?.map(tag => ({
        id: tag.id,
        name: tag.title,
      })) || [],
    relativeUrl: result.relativeUrl,
    user: {
      profile: {
        name: result.owner?.member?.name,
        picture: result.owner?.member?.profilePicture || DEFAULT_USER_IMAGE,
      },
    },
  }
}

export async function uploadAttachmentAPI(attachments: File[]) {
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
    accessToken,
  })
  const uploadRequests = attachments.map(attachment => {
    const extension = attachment.name.split('.').pop() || ''
    return {
      contentType: attachment.type,
      file: attachment,
      extension,
      name: attachment.name,
      size: attachment.size,
    }
  })
  const result = await client.media.uploadFiles(uploadRequests)
  return (
    result?.map(response => ({
      uploaded: response && !!response.url,
      url: response.url || '',
      id: response.id || '',
      fileName: response.name || '',
    })) || []
  )
}

export async function postAnswerAPI(params: ReturnType<typeof postAnswer>) {
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })
  const result = await client.posts.reply(
    params.payload.questionId,
    {
      input: {
        mappingFields: [
          {
            key: 'content',
            type: PostMappingTypeEnum.html,
            value: `"${params.payload.content}"`,
          },
        ],
        postTypeId: getCommunityPostTypeId('reply'),
        publish: true,
      },
    },
    'basic',
    accessToken
  )
  return {
    title: result.title,
    updatedAt: result.updatedAt,
    createdAt: result.createdAt,
    id: result.id,
    _id: result.id,
    topics:
      result.tags?.map(tag => ({
        id: tag.id,
        name: tag.title,
      })) || [],
    user: {
      profile: {
        name: result.owner?.member?.name,
        picture: result.owner?.member?.profilePicture || DEFAULT_USER_IMAGE,
      },
    },
  }
}

export async function deleteQuestionAPI(
  action: ReturnType<typeof deleteQuestion>
) {
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })
  const result = await client.posts.delete(
    { id: action.payload.id },
    'basic',
    accessToken
  )
  return result
}

export async function updateQuestionAPI(
  action: ReturnType<typeof updateQuestion>
) {
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })
  const result = await client.posts.update(
    {
      id: action.payload.id,
      input: {
        mappingFields: [
          {
            key: 'title',
            value: `"${action.payload.title}"`,
            type: PostMappingTypeEnum.text,
          },
        ],
      },
    },
    'basic',
    accessToken
  )
  return {
    title: result.title,
    updatedAt: result.updatedAt,
    id: result.id,
    topics:
      result.tags?.map(tag => ({
        id: tag.id,
        name: tag.title,
      })) || [],
    user: {
      profile: {
        name: result.owner?.member?.name,
        picture: result.owner?.member?.profilePicture || DEFAULT_USER_IMAGE,
      },
    },
  }
}

export async function updateAnswerAPI(action: ReturnType<typeof updateAnswer>) {
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })
  const result = await client.posts.update(
    {
      id: action.payload.id,
      input: {
        mappingFields: [
          {
            key: 'content',
            type: PostMappingTypeEnum.html,
            value: `"${action.payload.content}"`,
          },
        ],
      },
    },
    'basic',
    accessToken
  )
  return {
    title: result.title,
    updatedAt: result.updatedAt,
    createdAt: result.createdAt,
    id: result.id,
    _id: result.id,
    topics:
      result.tags?.map(tag => ({
        id: tag.id,
        name: tag.title,
      })) || [],
    user: {
      profile: {
        name: result.owner?.member?.name,
        picture: result.owner?.member?.profilePicture || DEFAULT_USER_IMAGE,
      },
    },
  }
}

export async function fetchNewQuestionsAPI() {
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })
  const result = await client.posts.list(
    {
      limit: 50,
      postTypeIds: [getCommunityPostTypeId('post')],
      orderBy: PostListOrderByEnum.createdAt,
    },
    'basic',
    accessToken
  )
  return (
    result.nodes?.map(data => ({
      createdAt: data.createdAt,
      description: data.description,
      id: data.id,
    })) || {}
  )
}

export async function generateCommunityV2TokenAPI() {
  try {
    const client = new TribeClient({
      graphqlUrl: TRIBE_GRAPHQL_URL,
    })
    const response = await client.getTokens(
      {
        networkDomain: process.env.REACT_APP_COMMUNITY_V2_URL?.replace(
          /https:\/\//,
          ''
        ).replace(/\/$/, ''),
        ssoToken: communitySsoToken(),
      },
      'basic'
    )
    return response.accessToken
  } catch (err) {
    throw err
  }
}

export async function fetchUserQuestionsAPI(params: { id: string }) {
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })
  const result = await client.posts.byMember(
    params.id,
    { limit: 50 },
    'basic',
    accessToken
  )
  return result.nodes?.map(data => {
    const objVal: any = {
      title: data.title,
      relativeUrl: data.relativeUrl,
      topics:
        data.tags?.map(tag => ({
          id: tag.id,
          name: tag.title,
        })) || [],
      createdAt: data.createdAt || null,
    }
    objVal._id = data.id
    return objVal
  })
}

export async function fetchUserDetailsAPI(params: { id: string }) {
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })
  const response = await client.members.list(
    {
      limit: 1,
      filterBy: [
        {
          key: 'externalId',
          value: params.id.toString(),
          operator: MemberListFilterByOperator.equals,
        },
      ],
    },
    { profilePicture: { onImage: 'basic' }, badges: 'all' },
    accessToken
  )

  const modifiedResult = response.edges?.map(data => {
    const objVal: any = {
      profile: {
        picture: (data.node.profilePicture as Image)?.url || DEFAULT_USER_IMAGE,
        badge: {
          type:
            !!data.node.badges && data.node.badges?.length > 0
              ? data.node.badges[0].badge?.name
              : '',
          text:
            !!data.node.badges && data.node.badges?.length > 0
              ? data.node.badges[0].badge?.name
              : '',
        },
        createdAt: data.node.createdAt,
      },
    }
    objVal._id = data.node.id
    return objVal
  })
  const result =
    modifiedResult && modifiedResult.length > 0 ? modifiedResult[0] : {}
  if (result._id) {
    result.profile.score = await getMemberScore(result._id)
  }
  return result
}

async function getMemberScore(id: string) {
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })
  const query = [
    `select 2*count(post) + 2*count(reply) + count(react) as Score where actor = '${id}' and space_type in ('GROUP','BROADCAST') limit 60`,
  ]
  const response = await client.report.analytics(
    { queries: query },
    'basic',
    accessToken
  )

  return response?.[0]?.records?.[0]?.payload?.[0]?.value ?? 0
}

export async function postQuestionVideoMappingAPI(
  params: { userId: string; videoId: ContentID; questionId: string },
  signal: AbortSignal
) {
  const body = JSON.stringify({
    community_content_id: params.questionId.toString(),
  })
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/community/users/${params.userId}/page/${params.videoId}/question`,
    params: {
      signal,
      method: 'POST',
      body,
    },
    auth: true,
    excludeProgramId: true,
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getVideoQuestionIdsAPI(
  params: { userId: string; videoId: ContentID },
  signal: AbortSignal
) {
  const body = JSON.stringify({
    community_question_id: params.videoId,
  })
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/community/users/${params.userId}/page/${params.videoId}/question`,
    params: {
      signal,
      method: 'GET',
    },
    auth: true,
    excludeProgramId: true,
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function createTopicAPI(params: { name: string }) {
  const accessToken = getCommunityJwtToken()
  const graphqlUrl = NEW_TRIBE_GRAPHQL_URL

  const query = `
  mutation CreateTag {
    createTag(input: { title: "${params.name}" }) {
        id
        slug
        spaceId
        title
    }
}`
  const variables = {
    input: { title: params.name },
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  }

  const response = await fetch(graphqlUrl, requestOptions)
  const responseData = await response.json()

  return { _id: responseData.data.createTag.id, name: params.name }
}

export async function fetchTrendingQuestionsApi() {
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })
  const result = await client.posts.list(
    {
      limit: 2,
      postTypeIds: [getCommunityPostTypeId('post')],
      orderBy: PostListOrderByEnum.publishedAt,
      filterBy: [
        {
          key: PostListFilterByEnum.publishedAt,
          keyString: 'tagIds',
          operator: PostListFilterByOperator.contains,
          value: '"OSNE9JtAjfbYNVNdOkdif"', // question of the day tag id
        },
        {
          key: PostListFilterByEnum.publishedAt,
          keyString: 'publishedAt',
          operator: PostListFilterByOperator.lte,
          value: '0',
        },
      ],
    },
    'basic',
    accessToken
  )
  return result?.nodes?.map(data => ({
    _id: data.id,
    id: data.id,
    title: data.title,
    content: data.description,
    relativeUrl: data.relativeUrl,
    createdAt: data.createdAt,
    topics: data.tags?.map(tag => tag.title) || [],
  }))
}

export async function fetchTrendingArticlesApi() {
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })
  const result = await client.posts.list(
    {
      limit: 2,
      postTypeIds: [getCommunityPostTypeId('post')],
      orderBy: PostListOrderByEnum.positiveReactionsCount,
    },
    'basic',
    accessToken
  )
  return result?.nodes?.map(data => ({
    _id: data.id,
    id: data.id,
    title: data.title,
    content: data.description,
    relativeUrl: data.relativeUrl,
  }))
}

export async function fetchAlumniGroupFeedsApi() {
  const alumniGroupId = communityAlumniGroupMapping.id
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })
  const result = await client.posts.list(
    {
      limit: 10,
      orderBy: PostListOrderByEnum.lastActivityAt,
      spaceIds: [alumniGroupId],
    },
    {
      owner: { member: { profilePicture: { onImage: 'basic' } } },
      tags: 'basic',
    },
    accessToken
  )
  return result.nodes?.map(data => ({
    type: 'Post',
    post: {
      title: data.title,
      updatedAt: data.lastActivityAt,
      id: data.id,
      topics:
        data.tags?.map(tag => ({
          id: tag.id,
          name: tag.title,
        })) || [],
      relativeUrl: data.relativeUrl,
      user: {
        profile: {
          name: data.owner?.member?.name,
          picture:
            (data.owner?.member?.profilePicture as Image)?.url ||
            DEFAULT_USER_IMAGE,
        },
      },
    },
  }))
}

export async function fetchGroupDetailsApi() {
  const alumniGroupId = communityAlumniGroupMapping.id
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })

  const result = await client.spaces.get(
    { id: alumniGroupId },
    'default',
    accessToken
  )

  return (
    result.highlightedTags?.map(data => ({
      id: data.id,
      name: data.title,
    })) || []
  )
}

export async function fetchAlumniGroupMembersApi() {
  const alumniGroupId = communityAlumniGroupMapping.id
  const accessToken = getCommunityJwtToken()
  const client = new TribeClient({
    graphqlUrl: TRIBE_GRAPHQL_URL,
  })

  const result = await client.spaceMembers.listMembers(
    {
      limit: 10,
      spaceId: alumniGroupId,
      orderBy: SpaceMemberListOrderByEnum.UPDATED_AT,
    },
    { member: { profilePicture: { onImage: 'basic' } } },
    accessToken
  )

  return result?.edges?.map(data => ({
    id: data.node?.member?.id,
    profile: {
      name: data.node?.member?.name,
      picture:
        (data.node.member?.profilePicture as Image)?.url || DEFAULT_USER_IMAGE,
      username: data.node.member?.username,
      title: data.node.member?.tagline,
      score: data.node.member?.score,
      url: data.node.member?.url,
    },
  }))
}

export async function sendCommunityInvite(
  communityRequest: ICommunityInviteRequest,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/community/users/${communityRequest.sender_id}/send_invite`,
    params: {
      signal,
      method: 'POST',
      body: JSON.stringify(communityRequest),
    },
    auth: true,
    excludeProgramId: false,
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function acceptCommunityInvite(
  userId: number,
  communityRequest: ICommunityInviteAcceptRequest,
  signal: AbortSignal
) {
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/community/users/${userId}/accept_invite`,
    params: {
      signal,
      method: 'POST',
      body: JSON.stringify(communityRequest),
    },
    auth: true,
    excludeProgramId: false,
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}

export async function getCommunityInvite(
  userId: number,
  inviteToken: string,
  signal: AbortSignal
) {
  let apiUrl = `${window.constants.REACT_APP_ELEVATE_API_URL}v1/community/users/${userId}/invite`
  if (inviteToken) {
    apiUrl += `?invite_token=${inviteToken}`
  }
  const response = await apiCall({
    url: apiUrl,
    params: {
      signal,
      method: 'GET',
    },
    auth: true,
    excludeProgramId: false,
  })

  if (response.ok) {
    return response.json()
  }
  throw response
}
