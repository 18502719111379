import {
  LearnAndDevCareerPathMetricsAPIData,
  fslDashboardAPIData,
  LearnAndDevDepartmentMetricsAPIData,
} from 'common/types/learnAndDev/dashboard'
import {
  FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS,
  FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_CANCEL,
  FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_FAILURE,
  FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_SUCCESS,
  FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT,
  FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_CANCEL,
  FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_FAILURE,
  FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_SUCCESS,
  FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS,
  FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_CANCEL,
  FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_FAILURE,
  FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_SUCCESS,
} from './Dashboard.types'

interface LearnAndDevDashboardApiPayload {
  departmentIds: number[]
  startDate: string
  endDate: string
}

interface LearnAndDevDeparmentMetricsApiPayload {
  departmentIds: number[]
  startDate: string
  endDate: string
}

export const fetchLearnAndDevDashboardContent = (
  payload: LearnAndDevDashboardApiPayload
) => ({
  type: FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT as typeof FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT,
  payload,
})

export const fetchLearnAndDevDashboardContentSuccess = (
  payload: fslDashboardAPIData
) => ({
  type: FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_SUCCESS as typeof FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_SUCCESS,
  payload,
})

export const fetchLearnAndDevDashboardContentFailure = (payload: Error) => ({
  type: FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_FAILURE as typeof FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_FAILURE,
  payload,
})

export const fetchLearnAndDevDashboardContentCancel = () => ({
  type: FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_CANCEL as typeof FETCH_LEARN_AND_DEV_DASHBOARD_CONTENT_CANCEL,
})

export const fetchLearnAndDevDepartmentMetrics = (
  payload: LearnAndDevDeparmentMetricsApiPayload
) => ({
  type: FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS as typeof FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS,
  payload,
})

export const fetchLearnAndDevDepartmentMetricsSuccess = (
  payload: LearnAndDevDepartmentMetricsAPIData
) => ({
  type: FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_SUCCESS as typeof FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_SUCCESS,
  payload,
})

export const fetchLearnAndDevDepartmentMetricsFailure = (payload: Error) => ({
  type: FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_FAILURE as typeof FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_FAILURE,
  payload,
})

export const fetchLearnAndDevDepartmentMetricsCancel = () => ({
  type: FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_CANCEL as typeof FETCH_LEARN_AND_DEV_DEPARTMENT_METRICS_CANCEL,
})

export const fetchLearnAndDevCareerPathMetrics = (
  payload: LearnAndDevDashboardApiPayload
) => ({
  type: FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS as typeof FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS,
  payload,
})

export const fetchLearnAndDevCareerPathMetricsSuccess = (
  payload: LearnAndDevCareerPathMetricsAPIData
) => ({
  type: FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_SUCCESS as typeof FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_SUCCESS,
  payload,
})

export const fetchLearnAndDevCareerPathMetricsFailure = (payload: Error) => ({
  type: FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_FAILURE as typeof FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_FAILURE,
  payload,
})

export const fetchLearnAndDevCareerPathMetricsCancel = () => ({
  type: FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_CANCEL as typeof FETCH_LEARN_AND_DEV_CAREER_PATH_METRICS_CANCEL,
})

export type DashboardActions =
  | ReturnType<typeof fetchLearnAndDevDashboardContent>
  | ReturnType<typeof fetchLearnAndDevDashboardContentSuccess>
  | ReturnType<typeof fetchLearnAndDevDashboardContentFailure>
  | ReturnType<typeof fetchLearnAndDevDashboardContentCancel>
  | ReturnType<typeof fetchLearnAndDevDepartmentMetrics>
  | ReturnType<typeof fetchLearnAndDevDepartmentMetricsSuccess>
  | ReturnType<typeof fetchLearnAndDevDepartmentMetricsFailure>
  | ReturnType<typeof fetchLearnAndDevDepartmentMetricsCancel>
  | ReturnType<typeof fetchLearnAndDevCareerPathMetrics>
  | ReturnType<typeof fetchLearnAndDevCareerPathMetricsSuccess>
  | ReturnType<typeof fetchLearnAndDevCareerPathMetricsFailure>
  | ReturnType<typeof fetchLearnAndDevCareerPathMetricsCancel>
