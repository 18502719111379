import { Reducer } from 'redux'
import * as types from './Assignment.types'
import { CourseAssignmentsActions } from './Assignment.actions'
import { CourseAssignmentState } from '../../../../common/types/programSupport'

const initialState: CourseAssignmentState = {
  data: [],
  loading: false,
  error: false as false,
}

export const courseAssignmentsReducer: Reducer<
  CourseAssignmentState,
  CourseAssignmentsActions
> = (state = initialState, action: any): CourseAssignmentState => {
  switch (action.type) {
    case types.FETCH_COURSE_ASSIGNMENTS:
      return { ...state, loading: true, error: false }
    case types.FETCH_COURSE_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      }
    case types.FETCH_COURSE_ASSIGNMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case types.FETCH_COURSE_ASSIGNMENTS_CANCEL:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
