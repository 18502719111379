export const FETCH_DASHBOARD_CONTENT = 'FETCH_DASHBOARD_CONTENT'
export const FETCH_SCHOOL_DASHBOARD_CONTENT = 'FETCH_SCHOOL_DASHBOARD_CONTENT'
export const FETCH_SCHOOL_DASHBOARD_CONTENT_CANCEL =
  'FETCH_SCHOOL_DASHBOARD_CONTENT_CANCEL'
export const FETCH_DASHBOARD_CONTENT_SUCCESS = 'FETCH_DASHBOARD_CONTENT_SUCCESS'
export const FETCH_DASHBOARD_CONTENT_FAILURE = 'FETCH_DASHBOARD_CONTENT_FAILURE'
export const FETCH_DASHBOARD_CONTENT_CANCEL = 'FETCH_DASHBOARD_CONTENT_CANCEL'
export const UPDATE_USER_INFO_COLLECTED = 'UPDATE_USER_INFO_COLLECTED'
export const UPDATE_CAREER_SUPPORT_LINK = 'UPDATE_CAREER_SUPPORT_LINK'
export const UPDATE_CAREER_SCHOOL_USER_INFO_COLLECTED =
  'UPDATE_CAREER_SCHOOL_USER_INFO_COLLECTED'
export const UPDATE_GIFT_COURSE_CONSENT_REQUIRED =
  'UPDATE_GIFT_COURSE_CONSENT_REQUIRED'
