import { Box } from '@material-ui/core'
import { useBreakpoint } from 'common/elements/hooks'
import { generateURL } from 'common/utils'
import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  useFeatureLinksData,
  useIsNewUIEnabled,
  useOlympusDetails,
  useReloadPath,
} from 'web/hooks'

export function AppLogo() {
  const olympusDetails = useOlympusDetails()
  const isNewUIEnabled = useIsNewUIEnabled()
  const history = useHistory()
  const { gt } = useBreakpoint({ gtLg: 32, gtXXs: 28 })
  const featureLinks = useFeatureLinksData()
  const reloadPath = useReloadPath()

  return gt && olympusDetails?.logo ? (
    <Box
      display="flex"
      flexGrow={0}
      flexShrink={0}
      {...(featureLinks?.learner_dashboard_page?.url && {
        style: {
          cursor: 'pointer',
        },
        onClick: () => {
          if (isNewUIEnabled) {
            reloadPath('LEARNER_DASHBOARD')
          } else {
            history.push(generateURL('LEARNER_DASHBOARD', null))
          }
        },
      })}
      height={gt}
    >
      <img alt="app-logo" style={{ height: gt }} src={olympusDetails?.logo} />
    </Box>
  ) : (
    <></>
  )
}
