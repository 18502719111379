import {
  AssessmentScheduleID,
  InvigilationMessage,
} from 'common/types/invigilation'
import {
  FETCH_ASSESSMENT_SCHEDULE_DETAILS,
  FETCH_ASSESSMENT_SCHEDULE_DETAILS_FAILURE,
  FETCH_ASSESSMENT_SCHEDULE_DETAILS_SUCCESS,
  FETCH_ASSESSMENT_SCHEDULE_DETAILS_CANCEL,
  FETCH_INVIGILATION_DETAILS,
  FETCH_INVIGILATION_DETAILS_CANCEL,
  FETCH_INVIGILATION_DETAILS_FAILURE,
  FETCH_INVIGILATION_DETAILS_SUCCESS,
  UPDATE_INVIGILATION_MESSAGES,
  UPDATE_INVIGILATION_MESSAGES_FAILURE,
  UPDATE_INVIGILATION_MESSAGES_SUCCESS,
  UPDATE_INVIGILATION_MESSAGES_CANCEL,
  APPROVE_OR_REJECT_ONBOARDING_PHOTO,
  APPROVE_OR_REJECT_ONBOARDING_PHOTO_CANCEL,
  APPROVE_OR_REJECT_ONBOARDING_PHOTO_FAILURE,
  APPROVE_OR_REJECT_ONBOARDING_PHOTO_SUCCESS,
} from './Invigilation.types'

type AssessmentScheduleDetailsMeta =
  | {
      assessmentScheduleId: AssessmentScheduleID
    }
  | {
      proctorKey: string
    }

export interface InvigilationDetailsMeta {
  assessmentScheduleId: AssessmentScheduleID
}
export interface OnboardingActionPayload {
  id: number
  action: string
}
interface InvigilationMessagesMeta {
  assessmentScheduleId: AssessmentScheduleID
  source: string
}

export const fetchAssessmentScheduleDetails = (
  meta: AssessmentScheduleDetailsMeta
) => ({
  type: FETCH_ASSESSMENT_SCHEDULE_DETAILS as typeof FETCH_ASSESSMENT_SCHEDULE_DETAILS,
  meta,
})

export const fetchAssessmentScheduleDetailsFailure = (
  payload: Error,
  meta: AssessmentScheduleDetailsMeta
) => ({
  type: FETCH_ASSESSMENT_SCHEDULE_DETAILS_FAILURE as typeof FETCH_ASSESSMENT_SCHEDULE_DETAILS_FAILURE,
  payload,
  meta,
})

export const fetchAssessmentScheduleDetailsSuccess = (
  payload: any,
  meta: AssessmentScheduleDetailsMeta
) => ({
  type: FETCH_ASSESSMENT_SCHEDULE_DETAILS_SUCCESS as typeof FETCH_ASSESSMENT_SCHEDULE_DETAILS_SUCCESS,
  payload,
  meta,
})

export const fetchAssessmentScheduleDetailsCancel = (
  payload: any,
  meta: AssessmentScheduleDetailsMeta
) => ({
  type: FETCH_ASSESSMENT_SCHEDULE_DETAILS_CANCEL as typeof FETCH_ASSESSMENT_SCHEDULE_DETAILS_CANCEL,
  payload,
  meta,
})

export const fetchInvigilationDetails = (meta: InvigilationDetailsMeta) => ({
  type: FETCH_INVIGILATION_DETAILS as typeof FETCH_INVIGILATION_DETAILS,
  meta,
})

export const fetchInvigilationDetailsFailure = (
  payload: Error,
  meta: InvigilationDetailsMeta
) => ({
  type: FETCH_INVIGILATION_DETAILS_FAILURE as typeof FETCH_INVIGILATION_DETAILS_FAILURE,
  payload,
  meta,
})

export const fetchInvigilationDetailsSuccess = (
  payload: any,
  meta: InvigilationDetailsMeta
) => ({
  type: FETCH_INVIGILATION_DETAILS_SUCCESS as typeof FETCH_INVIGILATION_DETAILS_SUCCESS,
  payload,
  meta,
})

export const fetchInvigilationDetailsCancel = (
  payload: any,
  meta: InvigilationDetailsMeta
) => ({
  type: FETCH_INVIGILATION_DETAILS_CANCEL as typeof FETCH_INVIGILATION_DETAILS_CANCEL,
  payload,
  meta,
})

export const updateInvigilationMessages = (
  payload: InvigilationMessage,
  meta: InvigilationMessagesMeta
) => ({
  type: UPDATE_INVIGILATION_MESSAGES as typeof UPDATE_INVIGILATION_MESSAGES,
  payload,
  meta,
})

export const updateInvigilationMessagesFailure = (
  payload: Error,
  meta: InvigilationMessagesMeta
) => ({
  type: UPDATE_INVIGILATION_MESSAGES_FAILURE as typeof UPDATE_INVIGILATION_MESSAGES_FAILURE,
  payload,
  meta,
})

export const updateInvigilationMessagesSuccess = (
  payload: any,
  meta: InvigilationMessagesMeta
) => ({
  type: UPDATE_INVIGILATION_MESSAGES_SUCCESS as typeof UPDATE_INVIGILATION_MESSAGES_SUCCESS,
  payload,
  meta,
})

export const updateInvigilationMessagesCancel = (
  payload: any,
  meta: InvigilationMessagesMeta
) => ({
  type: UPDATE_INVIGILATION_MESSAGES_CANCEL as typeof UPDATE_INVIGILATION_MESSAGES_CANCEL,
  payload,
  meta,
})

export const approveOrRejectOnboardingPhoto = (
  payload: OnboardingActionPayload,
  meta: InvigilationDetailsMeta
) => ({
  type: APPROVE_OR_REJECT_ONBOARDING_PHOTO as typeof APPROVE_OR_REJECT_ONBOARDING_PHOTO,
  payload,
  meta,
})

export const approveOrRejectOnboardingPhotoFailure = (
  payload: Error,
  meta: InvigilationDetailsMeta
) => ({
  type: APPROVE_OR_REJECT_ONBOARDING_PHOTO_FAILURE as typeof APPROVE_OR_REJECT_ONBOARDING_PHOTO_FAILURE,
  payload,
  meta,
})

export const approveOrRejectOnboardingPhotoSuccess = (
  payload: any,
  meta: InvigilationDetailsMeta
) => ({
  type: APPROVE_OR_REJECT_ONBOARDING_PHOTO_SUCCESS as typeof APPROVE_OR_REJECT_ONBOARDING_PHOTO_SUCCESS,
  payload,
  meta,
})
export const approveOrRejectOnboardingPhotoCancel = (
  payload: any,
  meta: InvigilationDetailsMeta
) => ({
  type: APPROVE_OR_REJECT_ONBOARDING_PHOTO_CANCEL as typeof APPROVE_OR_REJECT_ONBOARDING_PHOTO_CANCEL,
  payload,
  meta,
})

export type InvigilationActionTypes =
  | ReturnType<typeof fetchAssessmentScheduleDetails>
  | ReturnType<typeof fetchAssessmentScheduleDetailsSuccess>
  | ReturnType<typeof fetchAssessmentScheduleDetailsFailure>
  | ReturnType<typeof fetchAssessmentScheduleDetailsCancel>
  | ReturnType<typeof fetchInvigilationDetails>
  | ReturnType<typeof fetchInvigilationDetailsSuccess>
  | ReturnType<typeof fetchInvigilationDetailsFailure>
  | ReturnType<typeof fetchInvigilationDetailsCancel>
  | ReturnType<typeof updateInvigilationMessages>
  | ReturnType<typeof updateInvigilationMessagesSuccess>
  | ReturnType<typeof updateInvigilationMessagesFailure>
  | ReturnType<typeof updateInvigilationMessagesCancel>
  | ReturnType<typeof approveOrRejectOnboardingPhoto>
  | ReturnType<typeof approveOrRejectOnboardingPhotoFailure>
  | ReturnType<typeof approveOrRejectOnboardingPhotoSuccess>
  | ReturnType<typeof approveOrRejectOnboardingPhotoCancel>
