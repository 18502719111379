import { AppState } from '../../../../../store'
import { moduleItemSelectors } from '../..'
import { ItemID } from '../../../../../../common/types/courses/moduleItem'
import {
  QuizSubmissionData,
  QuizAttemptNo,
  QuizModuleItemData,
} from '../../../../../../common/types/courses/quiz'

export const getQuizItemActivity = (itemId: ItemID) => (state: AppState) =>
  moduleItemSelectors.getItemActivity<QuizModuleItemData>(itemId)(state)

export const getQuizActiveAttempt = (itemId: ItemID) => (state: AppState) => {
  const itemActivity = getQuizItemActivity(itemId)(state)
  if (itemActivity && itemActivity.activeAttempt) {
    return itemActivity.activeAttempt
  }
  return null
}
export const getQuizActiveAttemptNo = (itemId: ItemID) => (state: AppState) => {
  const activeAttempt = getQuizActiveAttempt(itemId)(state)
  if (activeAttempt && 'attemptNo' in activeAttempt) {
    return activeAttempt.attemptNo
  }
  return null
}
export const getQuizSubmissions = (itemId: ItemID) => (state: AppState) => {
  const itemActivity = getQuizItemActivity(itemId)(state)
  if (
    itemActivity &&
    itemActivity.submissions &&
    !(itemActivity.submissions instanceof Error)
  ) {
    return itemActivity.submissions
  }
  return null
}

export const getQuizSubmission = ({
  attempt,
  itemId,
}: {
  itemId: ItemID
  attempt: QuizAttemptNo
}) => (state: AppState): QuizSubmissionData | null => {
  const submissions = getQuizSubmissions(itemId)(state)
  if (submissions && attempt && submissions[attempt]) {
    return submissions[attempt]!
  }
  return null
}

export const getActiveQuizSubmission = (itemId: ItemID) => (
  state: AppState
): QuizSubmissionData | null => {
  const activeAttemptNo = getQuizActiveAttemptNo(itemId)(state)
  if (activeAttemptNo) {
    return getQuizSubmission({ itemId, attempt: activeAttemptNo })(state)
  }
  return null
}
