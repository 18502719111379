import {
  takeLeading,
  take,
  put,
  call,
  cancelled,
  select,
  takeEvery,
  all,
  takeLatest,
} from 'redux-saga/effects'
import { FilesState } from 'web/providers/FilesProvider/Files.reducer'
import { batchRemoveFile } from 'web/providers/FilesProvider'
import { mixpanel } from 'common/utils/mixpanel'
import { UserID } from 'common/types/user'
import { AppState } from 'web/store'
import { END_PROCTORING_SUCCESS } from 'web/providers/ProctoringProvider/Proctoring.types'
import { fetchCurrentTime } from 'web/providers/UtilsProvider'
import * as actions from './QuizSubmissions.actions'
import * as selectors from './QuizSubmissions.selectors'
import * as types from './QuizSubmissions.types'
import {
  apiCall,
  cancelable,
  capitalizeFirstLetter,
  CustomError,
  isNetworkError,
} from '../../../../../../common/utils'
import {
  quizQAMiddleware,
  quizQAActionTypes,
  saveQuizAnswers,
  fetchQuizSubmissionQuestionsAndAnswers,
} from '../QuizQAProvider'
import {
  getQuizSubmissionsAPI,
  completeQuizSubmissionAPI,
  startQuizSubmissionAPI,
  trackQuizSubmissionEventsAPI,
} from './QuizSubmissions.api'
import {
  QuizSubmissionData,
  QuizAttemptNo,
  QuizID,
  QuizActiveAttempt,
  QuizSubmissionEventType,
} from '../../../../../../common/types/courses/quiz'
import { ItemID } from '../../../../../../common/types/courses/moduleItem'
import { CourseID } from '../../../../../../common/types/courses'
import { PartialObjectMap } from '../../../../../../common/types/utils'
import { showAlertMessage } from '../../../../AlertsProvider'
import { fetchModuleItem } from '../..'
import {
  proctoringTypes,
  initiateProctoring,
  stopProctoring,
  endProctoring,
} from '../../../../ProctoringProvider'
import { fetchAssessmentProctorContent } from '../../ItemContent/ItemContent.actions'
import { ASSESSMENT_PROCTOR_CONTENT_FETCH_SUCCESS } from '../../ItemContent/ItemContent.types'

function* quizSubmissionDataFetchHandler(data: {
  activeSubmission: QuizSubmissionData
  itemId: ItemID
  courseId: CourseID
  basic_proctoring: boolean
  event_type: QuizSubmissionEventType
  skipTracking: boolean
}) {
  const {
    activeSubmission: submissionData,
    itemId,
    courseId,
    basic_proctoring,
    event_type,
    skipTracking,
  } = data
  const meta = {
    itemId,
    courseId,
    quizId: submissionData.quiz_id,
    attemptNo: submissionData.attempt,
  }
  if (basic_proctoring && !skipTracking) {
    yield put(
      actions.trackQuizSubmissionEvent({
        quiz_submission_id: submissionData.id,
        question_id: -1, // No active question while starting the quiz
        event_type,
        in_focus: true,
        attempt: submissionData.attempt,
      })
    )
  }
  yield put(fetchQuizSubmissionQuestionsAndAnswers(null, meta))
}

function* handleProctoredQuizInitiation(
  quizId: QuizID,
  activeSubmission: QuizSubmissionData,
  anomalyDetection: boolean,
  openBook: boolean,
  mediaStream?: MediaStream
) {
  yield put(
    initiateProctoring({
      assessment_id: quizId,
      submission_uid: `${activeSubmission.submission_id}_${activeSubmission.attempt}`,
      mediaStream,
      startedAt: activeSubmission.started_at,
      anomalyDetection,
      openBook,
    })
  )

  const res = yield take([
    proctoringTypes.INITIATE_PROCTORING_SUCCESS,
    proctoringTypes.INITIATE_PROCTORING_FAILURE,
  ])
  if (res.type === proctoringTypes.INITIATE_PROCTORING_FAILURE) {
    throw new Error()
  }
}

function* startQuizSubmissionHandler(
  action: ReturnType<typeof actions.startQuizSubmission>
) {
  try {
    const params = {
      courseId: action.meta.courseId,
      quizId: action.meta.quizId,
    }
    const data = yield call(startQuizSubmissionAPI, params)
    const activeSubmission: QuizSubmissionData = data.quiz_submissions[0]
    if (action.meta.proctored) {
      yield handleProctoredQuizInitiation(
        action.meta.quizId,
        activeSubmission,
        action.meta.anomalyDetection,
        action.meta.openBook,
        action.meta.mediaStream
      )
    }
    yield put(actions.startQuizSubmissionSuccess(activeSubmission, action.meta))
    yield quizSubmissionDataFetchHandler({
      activeSubmission,
      ...action.meta,
      event_type: 'start',
    })
    yield put(actions.clearQuizUploadedFiles(null, action.meta))
  } catch (e) {
    yield put(
      showAlertMessage({
        variant: 'error',
        message:
          e.message === 'a quiz submission already exists'
            ? 'This quiz is already in progress'
            : capitalizeFirstLetter(e.message || ''),
      })
    )
    yield put(actions.startQuizSubmissionFailure(e, action.meta))
    yield put(actions.fetchQuizSubmissions(null, action.meta))
    yield put(
      fetchModuleItem(
        {
          include: ['content_details'],
          ...action.meta,
        },
        action.meta
      )
    )
  }
}

function* resumeHandler(
  action: ReturnType<typeof actions.resumeQuizSubmission>
) {
  try {
    const submissions: PartialObjectMap<
      QuizAttemptNo,
      QuizSubmissionData
    > = yield select(selectors.getQuizSubmissions(action.meta.itemId))

    const activeSubmission = Object.values(submissions).find(sub =>
      Boolean(
        sub &&
          sub.workflow_state === 'untaken' &&
          sub.overdue_and_needs_submission !== true
      )
    )

    if (!activeSubmission) {
      throw new Error('Unable to get any active quiz attempt')
    }
    if (action.meta.proctored) {
      yield handleProctoredQuizInitiation(
        action.meta.quizId,
        activeSubmission,
        action.meta.anomalyDetection,
        action.meta.openBook,
        action.meta.mediaStream
      )
    }
    yield put(
      actions.resumeQuizSubmissionSuccess(
        { attemptNo: activeSubmission.attempt },
        action.meta
      )
    )
    yield quizSubmissionDataFetchHandler({
      activeSubmission,
      ...action.meta,
      event_type: 'resume',
    })
  } catch (e) {
    yield put(actions.resumeQuizSubmissionFailure(new Error(e), action.meta))
  }
}

function* completeQuizSubmissionHandler(
  action: ReturnType<typeof actions.completeQuizSubmission>
) {
  try {
    const activeSubmission: QuizSubmissionData | null = yield select(
      selectors.getActiveQuizSubmission(action.meta.itemId)
    )
    if (!activeSubmission) {
      throw new Error('No active quiz submission')
    }
    if (action.meta.proctored_assessment || action.meta.basic_proctoring)
      yield put(stopProctoring())

    yield put(
      saveQuizAnswers(
        { onQuizComplete: true },
        {
          ...action.meta,
          attemptNo: activeSubmission.attempt,
          questionId: -1,
        }
      )
    )
    const res = yield take([
      quizQAActionTypes.QUIZ_SUBMISSION_ANSWERS_SAVE_SUCCESS,
      quizQAActionTypes.QUIZ_SUBMISSION_ANSWERS_SAVE_FAILURE,
    ])

    if (res.type === quizQAActionTypes.QUIZ_SUBMISSION_ANSWERS_SAVE_FAILURE) {
      throw res.payload
    }
    const params = {
      courseId: action.meta.courseId,
      quizId: action.meta.quizId,
      submissionId: activeSubmission.id,
      query: {
        validation_token: activeSubmission.validation_token,
        attempt: activeSubmission.attempt,
      },
    }
    if (action.meta.proctored_assessment) {
      const activeAttempt: QuizActiveAttempt | null = yield select(
        selectors.getQuizActiveAttempt(action.meta.itemId)
      )
      mixpanel.track('LMS - Quizzes/Proctored-pre-submit', {
        course_id: params.courseId,
        quiz_id: params.quizId,
        submission_id: params.submissionId,
        attempt: activeSubmission.attempt,
        time_remaining_at_30th_sec:
          activeAttempt && 'timeRemaining' in activeAttempt
            ? activeAttempt.timeRemaining
            : 0,
        completion_type: action.meta.completionType,
      })
    }
    const data = yield call(completeQuizSubmissionAPI, params)
    if (action.meta.proctored_assessment) {
      yield put(endProctoring({ completionType: action.meta.completionType }))
      yield take(END_PROCTORING_SUCCESS)
      if ('hj' in window) {
        // @ts-ignore
        window.hj('trigger', 'proctored_quiz_submission')
      }
    }

    yield put(
      fetchAssessmentProctorContent(
        {
          assessment_id: parseInt(action.meta.quizId.toString(), 10),
          assessment_type: 'Quiz',
        },
        { itemId: action.meta.itemId }
      )
    )
    yield take(ASSESSMENT_PROCTOR_CONTENT_FETCH_SUCCESS)

    const submissionData = data.quiz_submissions[0]
    yield put(
      actions.completeQuizSubmissionSuccess(submissionData, action.meta)
    )
    if (!action.meta.preventRefectchSubmissions) {
      yield put(actions.fetchQuizSubmissions(null, action.meta))
    }

    mixpanel.track('LMS - Quizzes/submit', {
      quiz_id: params.quizId,
      submission_id: params.submissionId,
      attempt: activeSubmission.attempt,
      course_id: action.meta.courseId,
      module_item_id: action.meta.itemId,
    })
    if (action.meta.basic_proctoring) {
      yield put(
        actions.trackQuizSubmissionEvent({
          quiz_submission_id: submissionData.id,
          question_id: -1, // No active question while starting the quiz
          event_type: 'submit',
          in_focus: true,
          attempt: submissionData.attempt,
        })
      )
    }
  } catch (e) {
    if (!(e instanceof CustomError))
      yield put(
        showAlertMessage({
          variant: 'error',
          message: isNetworkError(e.message)
            ? 'No internet connectivity'
            : capitalizeFirstLetter(e.message),
        })
      )
    yield put(
      actions.completeQuizSubmissionFailure(
        e instanceof CustomError ? e.error : e,
        action.meta
      )
    )
    yield put(actions.fetchQuizSubmissions(null, action.meta))
  }
}

function* getQuizSubmissionsHandler(
  action: ReturnType<typeof actions.fetchQuizSubmissions>
) {
  const abortController = new AbortController()
  try {
    const params = {
      courseId: action.meta.courseId,
      quizId: action.meta.quizId,
    }
    const data = yield call(
      getQuizSubmissionsAPI,
      params,
      abortController.signal
    )
    if (
      data.quiz_submissions &&
      data.quiz_submissions.length &&
      action.meta.contentId
    ) {
      yield put(
        actions.getQuizSubmissionComments(
          {
            courseId: action.meta.courseId,
            itemId: action.meta.itemId,
            contentId: action.meta.contentId,
          },
          { itemId: action.meta.itemId }
        )
      )
    }
    yield put(
      actions.fetchQuizSubmissionsSuccess(data.quiz_submissions, action.meta)
    )
    yield put(fetchCurrentTime())
  } catch (e) {
    yield put(actions.fetchQuizSubmissionsFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* clearQuizUploadedFilesHandler() {
  const filesState: FilesState = yield select(state => state.files)
  const fileByContent = Object.keys(filesState.byContent).filter(item =>
    item.includes('quiz_')
  )
  if (fileByContent.length) {
    yield all(
      fileByContent.map(item => {
        return put(batchRemoveFile({ dataKey: item }))
      })
    )
  }
}

export async function getQuizSubmissionCommentsAPI(
  action: ReturnType<typeof actions.getQuizSubmissionComments>,
  userId: UserID,
  signal: AbortSignal
) {
  const { courseId, contentId } = action.payload
  const response = await apiCall({
    url: `${window.constants.REACT_APP_LMS_API_URL}v1/courses/${courseId}/assignments/${contentId}/submissions/${userId}`,
    params: {
      signal,
    },
    query: {
      include: ['submission_comments', 'rubric_assessment'],
    },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

function* getQuizSubmissionCommentsHandler(
  action: ReturnType<typeof actions.getQuizSubmissionComments>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data = yield call(
      getQuizSubmissionCommentsAPI,
      action,
      userId,
      abortController.signal
    )
    yield put(actions.getQuizSubmissionCommentsSuccess(data, action.meta))
  } catch (e) {
    yield put(actions.getQuizSubmissionCommentsFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* trackQuizSubmissionEventHandler(
  action: ReturnType<typeof actions.trackQuizSubmissionEvent>
) {
  const abortController = new AbortController()
  try {
    yield call(
      trackQuizSubmissionEventsAPI,
      action.payload.quiz_submission_id,
      action.payload.question_id,
      action.payload.event_type,
      action.payload.in_focus,
      action.payload.attempt,
      action.payload.meta
    )
  } catch (e) {
    yield put(actions.trackQuizSubmissionEventFailure(e, action.payload))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* quizSubmissionsMiddleware() {
  yield takeLeading(
    types.QUIZ_SUBMISSION_START,
    cancelable(startQuizSubmissionHandler, types.QUIZ_SUBMISSIONS_FETCH_FAILURE)
  )
  yield takeLeading(types.QUIZ_SUBMISSION_RESUME, resumeHandler)

  yield takeLeading(
    types.QUIZ_SUBMISSION_COMPLETE,
    completeQuizSubmissionHandler
  )

  yield takeLeading(
    types.QUIZ_SUBMISSIONS_FETCH,
    cancelable(getQuizSubmissionsHandler, [
      types.QUIZ_SUBMISSIONS_FETCH_CANCEL,
      types.QUIZ_SUBMISSION_START,
    ])
  )

  yield takeEvery(
    types.QUIZ_SUBMISSION_CLEAR_FILES,
    clearQuizUploadedFilesHandler
  )

  yield takeEvery(
    types.GET_QUIZ_SUBMISSION_COMMENTS,
    cancelable(
      getQuizSubmissionCommentsHandler,
      types.GET_QUIZ_SUBMISSION_COMMENTS_CANCEL
    )
  )

  yield takeLatest(
    types.TRACK_QUIZ_SUBMISSION_EVENT,
    trackQuizSubmissionEventHandler
  )
}

export default ([] as any).concat(
  quizSubmissionsMiddleware(),
  ...quizQAMiddleware
)
