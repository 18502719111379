import {
  FETCH_DASHBOARD_CONTENT,
  FETCH_SCHOOL_DASHBOARD_CONTENT,
  FETCH_SCHOOL_DASHBOARD_CONTENT_CANCEL,
  FETCH_DASHBOARD_CONTENT_SUCCESS,
  FETCH_DASHBOARD_CONTENT_FAILURE,
  FETCH_DASHBOARD_CONTENT_CANCEL,
  UPDATE_USER_INFO_COLLECTED,
  UPDATE_CAREER_SUPPORT_LINK,
  UPDATE_CAREER_SCHOOL_USER_INFO_COLLECTED,
  UPDATE_GIFT_COURSE_CONSENT_REQUIRED,
} from './Dashboard.types'

import {
  UPDATE_CAREER_SCHOOL_JOB_ONBOARDED,
  UPDATE_CAREER_SCHOOL_ONBOARDING_COMPLETED,
} from '../CareerSchoolDashboard/CareerSchool.types'

export const fetchDashboardContent = () => ({
  type: FETCH_DASHBOARD_CONTENT as typeof FETCH_DASHBOARD_CONTENT,
})
export const fetchSchoolDashboardContent = () => ({
  type: FETCH_SCHOOL_DASHBOARD_CONTENT as typeof FETCH_SCHOOL_DASHBOARD_CONTENT,
})
export const fetchDashboardContentSuccess = (payload: any) => ({
  type: FETCH_DASHBOARD_CONTENT_SUCCESS as typeof FETCH_DASHBOARD_CONTENT_SUCCESS,
  payload,
})
export const fetchDashboardContentFailure = (payload: Error) => ({
  type: FETCH_DASHBOARD_CONTENT_FAILURE as typeof FETCH_DASHBOARD_CONTENT_FAILURE,
  payload,
})
export const fetchDashboardContentCancel = () => ({
  type: FETCH_DASHBOARD_CONTENT_CANCEL as typeof FETCH_DASHBOARD_CONTENT_CANCEL,
})
export const fetchSchoolDashboardContentCancel = () => ({
  type: FETCH_SCHOOL_DASHBOARD_CONTENT_CANCEL as typeof FETCH_SCHOOL_DASHBOARD_CONTENT_CANCEL,
})

export const updateUserInfoCollected = (opts: {
  is_user_info_collected: boolean
  user_info_collected_at: string
}) => ({
  type: UPDATE_USER_INFO_COLLECTED as typeof UPDATE_USER_INFO_COLLECTED,
  payload: { ...opts },
})

export const updateGiftCourseConsentRequired = (
  giftCourseConsentRequired: boolean
) => ({
  type: UPDATE_GIFT_COURSE_CONSENT_REQUIRED as typeof UPDATE_GIFT_COURSE_CONSENT_REQUIRED,
  payload: {
    gift_course_consent_required: giftCourseConsentRequired,
  },
})

export const updateCsUserInfoCollected = (isUserInfoCollected: boolean) => ({
  type: UPDATE_CAREER_SCHOOL_USER_INFO_COLLECTED as typeof UPDATE_CAREER_SCHOOL_USER_INFO_COLLECTED,
  payload: {
    is_user_info_collected: isUserInfoCollected,
  },
})

export const updateCsUserJobOnboardingTrack = (
  schoolJobOnboarded: boolean
) => ({
  type: UPDATE_CAREER_SCHOOL_JOB_ONBOARDED as typeof UPDATE_CAREER_SCHOOL_JOB_ONBOARDED,
  payload: {
    school_job_onboarded: schoolJobOnboarded,
  },
})

export const updateCsUserOnboardingCompletedTrack = (
  schoolOnboardingCompleted: boolean
) => ({
  type: UPDATE_CAREER_SCHOOL_ONBOARDING_COMPLETED as typeof UPDATE_CAREER_SCHOOL_ONBOARDING_COMPLETED,
  payload: {
    school_onboarding_completed: schoolOnboardingCompleted,
  },
})

export const updateCareerSupportLink = (careerSupportLink: string) => ({
  type: UPDATE_CAREER_SUPPORT_LINK as typeof UPDATE_CAREER_SUPPORT_LINK,
  payload: careerSupportLink,
})

export type DashboardActions =
  | ReturnType<typeof fetchDashboardContent>
  | ReturnType<typeof fetchSchoolDashboardContent>
  | ReturnType<typeof fetchDashboardContentSuccess>
  | ReturnType<typeof fetchDashboardContentFailure>
  | ReturnType<typeof fetchDashboardContentCancel>
  | ReturnType<typeof fetchSchoolDashboardContentCancel>
  | ReturnType<typeof updateUserInfoCollected>
  | ReturnType<typeof updateGiftCourseConsentRequired>
  | ReturnType<typeof updateCareerSupportLink>
  | ReturnType<typeof updateCsUserInfoCollected>
  | ReturnType<typeof updateCsUserJobOnboardingTrack>
  | ReturnType<typeof updateCsUserOnboardingCompletedTrack>
