import { call, put, takeLatest } from 'redux-saga/effects'
import {
  fetchTransactonStatus,
  UserTransactionStatusSuccess,
  UserTransactionStatusFailure,
} from './PaymentStatus.actions'

import * as APIs from './PaymentStatus.api'
import * as types from './PaymentStatus.types'

function* fetchPaymentStatusHandler(
  action: ReturnType<typeof fetchTransactonStatus>
) {
  const abortController = new AbortController()

  try {
    const data = yield call(
      APIs.getUserPaymentStatusAPI,
      action,
      abortController.signal
    )

    yield put(UserTransactionStatusSuccess(data))
  } catch (e) {
    yield put(UserTransactionStatusFailure(e))
  }
}

export function* fetchUserPaymentTransactionMiddleware() {
  yield takeLatest(types.FETCH_TRANSACTION_STATUS, fetchPaymentStatusHandler)
}
export default ([] as any).concat(fetchUserPaymentTransactionMiddleware())
