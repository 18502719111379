import { Reducer } from 'redux'
import {
  FETCH_LEARNERS_ENGAGEMENT_SCORE,
  FETCH_LEARNERS_ENGAGEMENT_SCORE_FAILURE,
  FETCH_LEARNERS_ENGAGEMENT_SCORE_SUCCESS,
} from './Engagement.types'

export interface GeneralMetric {
  score: number
  count: number
}

export interface EngagementState {
  data: {
    totalNoOfParticipants: number
    totalLearnersActiveInSession: number
    totalScore: number
    metricScore: number
    raiseHands: GeneralMetric
    chats: GeneralMetric
    polls: GeneralMetric
    qa: GeneralMetric
    media: {
      mentorAudioScore: number
      mentorPresentationScore: number
      mentorVideoScore: number
      totalScore: number
    }
  }
  loading: boolean
}

export const initialState: EngagementState = {
  data: {
    totalNoOfParticipants: 0,
    totalLearnersActiveInSession: 0,
    totalScore: 0,
    metricScore: 0,
    raiseHands: {
      score: 0,
      count: 0,
    },
    chats: {
      score: 0,
      count: 0,
    },
    polls: {
      score: 0,
      count: 0,
    },
    qa: {
      score: 0,
      count: 0,
    },
    media: {
      mentorAudioScore: 0,
      mentorPresentationScore: 0,
      mentorVideoScore: 0,
      totalScore: 0,
    },
  },
  loading: false,
}

const learnerEngagementScoreReducer: Reducer<EngagementState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_LEARNERS_ENGAGEMENT_SCORE:
      return { ...state, loading: true }
    case FETCH_LEARNERS_ENGAGEMENT_SCORE_SUCCESS:
      const { payload } = action
      if (payload.total_no_of_participants !== 0)
        return {
          ...state,
          data: {
            ...state.data,
            totalNoOfParticipants: payload.total_no_of_participants,
            totalLearnersActiveInSession:
              payload.total_learners_active_in_session,
            totalScore: payload.total_score,
            metricScore: Math.ceil((payload.total_score * 100) / 4),
            raiseHands: { ...payload.raise_hands },
            chats: { ...payload.chats },
            polls: { ...payload.polls },
            qa: { ...payload.qa },
            media: { ...payload.media_score },
          },
          loading: false,
        }
      return {
        ...initialState,
        loading: false,
      }
    case FETCH_LEARNERS_ENGAGEMENT_SCORE_FAILURE:
      return {
        ...initialState,
        loading: false,
      }
    default:
      return state
  }
}

export default learnerEngagementScoreReducer
