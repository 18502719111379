import React, { useEffect, useState } from 'react'
import { Typography, IconButton, Box } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { mixpanel } from 'common/utils/mixpanel'
import Button from '../../Utils/Button'
import SnackbarStrip from '../SnackbarStrip'
import aiTeacherStyles from './AiTeacherSnackbar.module.css'
import AiTeacherIcon from '../../../../common/images/ai_teacher.svg'
import { DashboardBaseData } from '../../../../common/types/dashboard'

interface AiTeacherSnackbarProps {
  aiTeacherItem: Pick<DashboardBaseData, 'ai_teacher_item'>['ai_teacher_item']
}

const AiTeacherSnackbar = ({ aiTeacherItem }: AiTeacherSnackbarProps) => {
  const [open, setOpen] = useState(false)

  const onClose = () => {
    localStorage.setItem('aiTeacherSnackbar', new Date().toDateString())
    setOpen(false)
  }

  const onView = () => {
    mixpanel.track('GL AI Teacher Snackbar Click', { aiTeacherItem })
    onClose()
  }

  useEffect(() => {
    if (aiTeacherItem?.enabled) {
      const aiTeacherSnackbar = localStorage.getItem('aiTeacherSnackbar')
      if (
        aiTeacherSnackbar === null ||
        aiTeacherSnackbar < new Date().toDateString()
      ) {
        setOpen(true)
        mixpanel.track('GL AI Teacher Snackbar Show', { aiTeacherItem })
      }
    }
  }, [aiTeacherItem])

  return (
    <SnackbarStrip
      message={
        <span id="ai-teacher-client-snackbar">
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              src={AiTeacherIcon}
              className={aiTeacherStyles.aiTeacherIcon}
              alt="ai teacher icon"
            />
            <Typography>
              Exclusive Preview! Try AI Teacher now. Recap the concepts taught
              in {aiTeacherItem?.module_item?.title} by AI Teacher
            </Typography>
            <Link
              to={`/courses/${aiTeacherItem.course_id}/modules/items/${aiTeacherItem.module_item?.id}`}
            >
              <Button
                onClick={onView}
                className={aiTeacherStyles.tryNowBtn}
                color="primary"
                variant="contained"
              >
                View Now
              </Button>
            </Link>
          </Box>
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <Close />
        </IconButton>,
      ]}
      open={open}
      className={aiTeacherStyles.aiTeacherSnackbarContainer}
    />
  )
}
export default AiTeacherSnackbar
