import { apiCall, getUserDetails } from 'common/utils'
import {
  QuestionID,
  QuizQuestionID,
  QuizSubmissionID,
} from 'common/types/courses/quiz'
import { dynamicAnswerSorting } from 'common/utils/custom/courses/quiz'

export const dynamicQuizQuestionAnswer = async (params: {
  thread_id: string
  answer: string
  embed_code: string
  model_id: number
}) => {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/dynamic_quiz/question`,
    params: {
      method: 'POST',
      body: JSON.stringify(params),
    },
    query: { user_id: id },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export const dynamicQuizQuestionThread = async (params: {
  thread_name: string
  embed_code: string
  attempt: number
  question_id: QuizQuestionID
  submission_id: QuizSubmissionID
}) => {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/dynamic_quiz/thread`,
    params: {
      method: 'POST',
      body: JSON.stringify(params),
    },
    query: { user_id: id },
  })
  if (response.ok) {
    return response.json()
  }
  throw response
}

export const dynamicQuizQuestionThreadData = async (params: {
  thread_id: string
  embed_code: string
  model_id: number
  question_id?: QuestionID
}) => {
  const { id } = getUserDetails()
  const response = await apiCall({
    url: `${window.constants.REACT_APP_ELEVATE_API_URL}v1/dynamic_quiz`,
    params: {
      method: 'POST',
      body: JSON.stringify({
        thread_id: params.thread_id,
        embed_code: params.embed_code,
        model_id: params.model_id,
      }),
    },
    query: { user_id: id },
  })
  if (response.ok) {
    const jsonRes = await response.json()
    if (params.question_id && Array.isArray(jsonRes))
      return { [params.question_id]: dynamicAnswerSorting(jsonRes) }
    return jsonRes
  }
  throw response
}
