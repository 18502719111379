import {
  takeEvery,
  put,
  call,
  cancelled,
  select,
  takeLatest,
} from 'redux-saga/effects'
import { CourseData } from 'common/types/courses'
import { isExcelerateCourse } from 'common/utils/custom/courses'
import { ModuleData } from 'common/types/courses/moduleItem'
import {
  fetchModuleItemsSuccess,
  fetchModuleItemsFailure,
  fetchModuleItems,
  fetchModuleItem,
  fetchModuleItemSuccess,
  fetchModuleItemFailure,
  markItemAsRead,
  markItemAsReadSuccess,
  markItemAsReadFailure,
  fetchFeedbackQuestionsAndAnswers,
  fetchFeedbackQuestionsAndAnswersFailure,
  fetchFeedbackQuestionsAndAnswersSuccess,
  submitContentFeedback,
  submitContentFeedbackSuccess,
  submitContentFeedbackFailure,
} from './ModuleItems.actions'
import * as types from './ModuleItems.types'
import { cancelable } from '../../../../common/utils'
import { itemContentMiddleware } from './ItemContent'
import { quizSubmissionsMiddleware } from './Quiz/QuizSubmissionsProvider'
import { quizActivityMiddlewares } from './Quiz/QuizActivityProvider'
import discussionMiddleware from './Discussion/Discussion.middlewares'
import { assignmentSubmissionMiddleware } from './Assignment/AssignmentSubmissionsProvider'
import {
  UserDetailsState,
  userDetailsSelectors,
} from '../../User/UserDetailsProvider'
import { AppState } from '../../../store'
import {
  getModuleItemsAPI,
  getModuleItemAPI,
  markItemAsReadAPI,
  getContentFeedbackQAAPI,
  submitFeedbackAPI,
} from './ModuleItems.api'
import { fetchModules } from '../ModulesProvider'
import { UserID } from '../../../../common/types/user'
import { showAlertMessage } from '../../AlertsProvider'
import { updateModuleData } from '../ModulesProvider/Modules.actions'
import { externalUrlMiddleware } from './ExternalUrl'

function* getModuleItemsHandler(action: ReturnType<typeof fetchModuleItems>) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      getModuleItemsAPI,
      { ...action.payload },
      abortController.signal
    )
    if (data instanceof Array) {
      const moduleData: ModuleData = yield select(
        state => state.modules.data.byId[action.meta.moduleId]
      )
      const courseData: CourseData = yield select(
        (state: AppState) => state.courses.data.byId[action.meta.courseId]
      )
      yield put(
        fetchModuleItemsSuccess({ items: data, moduleData }, action.meta)
      )

      // update items required to completed if module items are fetched separately
      if (courseData && isExcelerateCourse(courseData)) {
        let totalRequiredItems = moduleData.itemsRequiredToComplete || 0
        let totalRequiredItemsCompleted = moduleData.itemsCompleted || 0
        if (data.length) {
          totalRequiredItemsCompleted += data.reduce(
            (acc: number, item: any) => {
              if (item.completion_requirement) {
                totalRequiredItems += 1
                if (item.completion_requirement.completed) return acc + 1
              }
              return acc + 0
            },
            0
          )
        }
        moduleData.itemsCompleted = totalRequiredItemsCompleted
        moduleData.itemsRequiredToComplete = totalRequiredItems

        yield put(updateModuleData(moduleData))
      }
    } else throw data
  } catch (e) {
    if ('errors' in e)
      yield put(
        fetchModuleItemsFailure(new Error(e.errors[0].message), action.meta)
      )
    else yield put(fetchModuleItemsFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
function* getModuleItemHandler(action: ReturnType<typeof fetchModuleItem>) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      getModuleItemAPI,
      { ...action.payload },
      abortController.signal
    )
    const moduleData: ModuleData = yield select(
      state => state.modules.data.byId[action.meta.moduleId]
    )
    yield put(fetchModuleItemSuccess({ item: data, moduleData }, action.meta))
  } catch (e) {
    yield put(fetchModuleItemFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}
function* markItemAsReadHandler(action: ReturnType<typeof markItemAsRead>) {
  const abortController = new AbortController()
  try {
    const data = yield call(
      markItemAsReadAPI,
      { ...action.payload },
      abortController.signal
    )
    yield put(markItemAsReadSuccess(data, action.meta))
    yield put(fetchModules(action.payload, action.payload))
  } catch (e) {
    yield put(markItemAsReadFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* getFeedbackQuestionsAndAnswersHandler(
  action: ReturnType<typeof fetchFeedbackQuestionsAndAnswers>
) {
  const abortController = new AbortController()

  try {
    const userId: UserDetailsState['id'] = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }

    const data = yield call(
      getContentFeedbackQAAPI,
      { ...action.payload },
      userId,
      abortController.signal
    )

    yield put(fetchFeedbackQuestionsAndAnswersSuccess(data, action.meta))
  } catch (e) {
    yield put(fetchFeedbackQuestionsAndAnswersFailure(e, action.meta))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

function* submitContentFeedbackHandler(
  action: ReturnType<typeof submitContentFeedback>
) {
  const abortController = new AbortController()
  const userId: UserID | null = yield select(userDetailsSelectors.getUserID())
  try {
    const resultObject: any = {
      user_id: userId,
      context_type: 'Course',
      context_id: action.payload.courseId,
      content_type: action.payload.contentType,
      content_id: action.payload.contentId,
      rating: action.payload.startRating,
      reasons: action.payload.optionsSelected,
      comments: action.payload.comments,
    }

    const data = yield call(
      submitFeedbackAPI,
      resultObject,
      abortController.signal
    )

    yield put(submitContentFeedbackSuccess(data, action.meta))
    yield put(
      showAlertMessage({
        variant: 'success',
        message: data.message,
      })
    )
  } catch (e) {
    yield put(submitContentFeedbackFailure(e, action.meta))
    yield put(
      showAlertMessage({
        variant: 'error',
        message: 'Feedback submission failed',
      })
    )
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* contentFeedbackQAMiddleware() {
  yield takeLatest(
    types.FETCH_FEEDBACK_QA,
    cancelable(getFeedbackQuestionsAndAnswersHandler, [
      types.FETCH_FEEDBACK_QA_CANCEL,
    ])
  )
}

export function* getModuleItemsMiddleware() {
  yield takeEvery(
    types.MODULE_ITEMS_FETCH,
    cancelable(getModuleItemsHandler, types.MODULE_ITEMS_FETCH_CANCEL)
  )
}

export function* getModuleItemMiddleware() {
  yield takeEvery(
    types.MODULE_ITEM_FETCH,
    cancelable(getModuleItemHandler, types.MODULE_ITEM_FETCH_CANCEL)
  )
}

export function* markItemAsReadMiddleware() {
  yield takeEvery(
    types.MARK_ITEM_AS_READ,
    cancelable(markItemAsReadHandler, types.MARK_ITEM_AS_READ_CANCEL)
  )
}

export function* submitContentFeedbackMiddleware() {
  yield takeLatest(
    types.SUBMIT_CONTENT_FEEDBACK,
    cancelable(submitContentFeedbackHandler, types.SUBMIT_CONTENT_FEEDBACK)
  )
}

export default ([] as any).concat(
  getModuleItemsMiddleware(),
  getModuleItemMiddleware(),
  markItemAsReadMiddleware(),
  contentFeedbackQAMiddleware(),
  submitContentFeedbackMiddleware(),
  itemContentMiddleware,
  quizSubmissionsMiddleware,
  assignmentSubmissionMiddleware,
  quizActivityMiddlewares,
  externalUrlMiddleware,
  discussionMiddleware
)
