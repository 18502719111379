import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import React from 'react'
import { GlaCertificatePayment } from 'common/types/programs'
import { mixpanel } from 'common/utils/mixpanel'
import { premiumCourseHighLights } from 'common/constants'
import { getPaymentUrl } from 'common/utils/custom/courses/payment'
import styles from './AcademyPremiumCoursePopup.module.css'
import Button from '../Utils/Button'
import { currencySymbol } from '../../../common/utils/custom/user'

interface Props {
  onClose: () => void
  certificatePaymentDetails: GlaCertificatePayment
  trafficSource: string
  premiumFeatures?: string[]
  moduleSection: boolean
}

const popupDetails = (highLights: string[] | undefined, moduleSection: boolean) => {
  return {
    heading: 'Unlock course to continue learning',
    subTitle1: 'Preview This Course',
    subTitle2Highlights: highLights || [],
    subTitle1ContinueBtn: 'Continue',
    previewButton: moduleSection,
  }
}

const mixPanelEvents = (
  name: string,
  positionName: string,
  typeName: string
) => {
  mixpanel.track(name, {
    Position: positionName,
    Type: typeName,
  })
}
const AcademyPremiumCoursePopup = (props: Props) => {
  const isMobile = useMediaQuery('(max-width: 768px)', { noSsr: true })

  const paymentLink = () => {
    mixPanelEvents('Button Click', 'GlaPaymentPopup', 'Continue Payment')
    const url = props.certificatePaymentDetails.payment_link
    if (url) {
      window.location.href = getPaymentUrl(url, props.trafficSource)
    }

    props.onClose()
  }
  const getImage = (path: string) => {
    return `${process.env.REACT_APP_CLOUDFRONT_LTI_URL}gla/premium-features/${path}.svg`
  }

  const onClose = (close: boolean) => {
    if (close) {
      mixPanelEvents(
        'Button Click',
        'GlaPaymentPopup',
        'Paid Certificate popup close'
      )
    } else {
      mixPanelEvents(
        'Button Click',
        'GlaPaymentPopup',
        'Continue Without Certificate'
      )
    }
    props.onClose()
  }
  const popUpData = popupDetails(props.premiumFeatures, props.moduleSection)
  return (
    <Dialog
      open
      maxWidth="xs"
      fullScreen={isMobile}
      onClose={() => props.onClose()}
      classes={{ paper: styles.dialog }}
    >
      <DialogContent className={styles.academyCertificatePaymentWrapper}>
        <div className={styles.heading}>{popUpData.heading}</div>
        <div className={styles.premiumDetailsWrapper}>
          {popUpData.subTitle2Highlights.filter(object => object).map(object => (
            <div className={styles.pointersWrapper}>
              <img
                className={styles.premiumFeatureImg}
                src={getImage(object)}
                alt={object}
              />
              <span>{premiumCourseHighLights[object]}</span>
            </div>
          ))}
        </div>
        <Typography className={styles.certificateAvailable} variant="h6">
          <b>
            {currencySymbol(props.certificatePaymentDetails.currency_code)}
            {props.certificatePaymentDetails.amount_payable}
          </b>
          <span className={styles.slash}>
            {currencySymbol(props.certificatePaymentDetails.currency_code)}
            {props.certificatePaymentDetails.total_amount}
          </span>{' '}
          <div className={styles.discountWrapper}>
            <span> {props.certificatePaymentDetails.discount_percentage}%</span> off
          </div>
        </Typography>
        {props.certificatePaymentDetails.gst_applicable && (
          <div className={styles.gstText}>
            +18% GST
          </div>
        )}
        <Button
          variant="contained"
          className={styles.continueToPayment}
          color="primary"
          onClick={() => paymentLink()}
        >
          Pay & Unlock Course
        </Button>
        { !props.moduleSection ? 
            <Button
              variant="outlined"
              className={styles.continueToPayment}
              color="primary"
              onClick={() => onClose(true)}
            >
              Preview Course
            </Button> : null }

        <Box className={styles.closePopupDiv}>
          <IconButton
            className={styles.closePopup}
            onClick={() => onClose(true)}
          >
            &times;
          </IconButton>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AcademyPremiumCoursePopup
