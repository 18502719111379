import { range } from 'common/utils'

const latest_area_of_study_items = [
  'Engineering in CS/IT/Electronics/Electrical',
  'Engineering in Mech/Civil/Chemical/Aerospace or similar',
  'BCA',
  'B.Com',
  'B.SC',
  'BBA',
  'BBM/MCA/MBA/Management & Others',
]
const year = new Date().getFullYear()
export const study_abroad_consent_options_positive: Record<string, string> = {
  [`Actively planning to go this year (${year})`]: `${year}`,
  [`Actively planning to go next year (${year + 1})`]: `${year + 1}`,
  'Yes, but maybe later': `${year + 2}`,
}
export const study_abroad_consent_options: Record<string, string> = {
  ...study_abroad_consent_options_positive,
  'Not interested': 'Not interested',
}

const area_of_interest_items = [
  'Data science',
  'Machine Learning',
  'Software development',
  'Artificial intelligence',
  'Digital Marketing',
  'Cloud computing',
  'Cyber security',
  'Management',
  'Arts and Design',
  'Commerce',
  'Job preparation',
  'Sales and Business Development',
  'Other',
]

const area_of_study_items = [
  'Computer Science',
  'Electrical Engineering',
  'Electronics And Communication Engineering',
  'Mechanical Engineering',
  'Chemical Engineering',
  'Civil Engineering',
  'Aerospace Engineering',
  'Architectural Engineering',
  'Environmental Engineering',
  'Geological And Geophysical Engineering',
  'Industrial And Manufacturing Engineering',
  'Materials Engineering And Materials Science',
  'Metallurgical Engineering',
  'Mining And Mineral Engineering',
  'Naval Architecture And Marine Engineering',
  'Petroleum Engineering',
  'Engineering And Industrial Management',
  'Industrial Production Technologies',
  'Computers & Mathematics',
  'Business',
  'Agriculture & Natural Resources',
  'Arts',
  'Biology & Life Science',
  'Communications & Journalism',
  'Education',
  'Health',
  'Humanities & Liberal Arts',
  'Industrial Arts & Consumer Services',
  'Interdisciplinary',
  'Law & Public Policy',
  'Physical Sciences',
  'Psychology & Social Work',
  'Social Science',
  'Other',
]

export const studyAbroadPreferredPrograms: Record<string, string> = {
  'Masters in Germany': 'msc-big-data-germany-hybrid',
  'Masters in US': 'masters-in-business-analytics-hybrid',
  'MBA in US': 'masters-in-business-analytics-hybrid',
  'MBA in Germany': 'mba-germany-hybrid',
  'Not Sure': 'masters-in-business-analytics-hybrid',
}

export const studyAbroadToeflConsent: Record<string, string> = {
  'Yes, I have': 'Yes',
  "No, I haven't": 'Not yet',
}

export const studyAbroadEstimatedBudget: Record<string, string> = {
  'Under 20 Lakhs': 'Less than 20 Lakhs',
  '20-40 Lakhs': 'Between 20-40 Lakhs',
  '40-60 Lakhs': 'Between 40-60 Lakhs',
  '60 Lakhs to 1 Cr': 'Between 60 Lakhs and 1 Cr',
  'Above 1 Cr': 'More than 1Cr',
}

export interface NewOnboardingFormField {
  subHeading: string
  radioBtnCss: string
  options: Record<string, string>
}

interface NewOnboardingFormStep {
  heading: string
  headingClass: string
  subSections: {
    [key: string]: NewOnboardingFormField
  }
  backButton: boolean
  skipButton: boolean
  nextButtonText: string
  closePopup: boolean
}

interface NewOnboardingFormOptions {
  [formType: string]: {
    [step in number]: NewOnboardingFormStep
  }
}

export const newOnboardingFormOptions: NewOnboardingFormOptions = {
  study_abroad: {
    1: {
      heading:
        'Share a few details to get expert counselling for international programs',
      headingClass: 'study_abroad_1-section',
      subSections: {
        page_identifier: {
          subHeading: 'What’s your Preferred Program?',
          radioBtnCss: 'radioBtnHalfWidth',
          options: studyAbroadPreferredPrograms,
        },
        toefl_consent: {
          subHeading: 'Have you written TOEFL or IELTS?',
          radioBtnCss: 'radioBtnHalfWidth',
          options: studyAbroadToeflConsent,
        },
      },
      backButton: false,
      skipButton: true,
      nextButtonText: 'Next',
      closePopup: false,
    },
    2: {
      heading: 'Almost done, just a few more details',
      headingClass: 'study_abroad_2-section',
      subSections: {
        estimated_budget: {
          subHeading: 'What’s your estimated budget?',
          radioBtnCss: 'radioBtnFullWidth',
          options: studyAbroadEstimatedBudget,
        },
      },
      backButton: true,
      skipButton: true,
      nextButtonText: 'Submit',
      closePopup: true,
    },
  },
}

export type NewOnboardingFormTypes = keyof typeof newOnboardingFormOptions

const domain_items = [
  'IT/Software/Programming/Testing/Devops/Product Management',
  'Sales/Business Development/Legal',
  'Digital Marketing/UI UX/Designers/PR/Content Creation',
  'BFSI/Tax/Audit/Insurance/Accounts/CA/Company Secretary',
  'Management/Leadership/Business Owner/Startup Founder/CXO',
  'Data Science/Data Analysis/Business Intelligence',
  'BPO/KPO/Customer Service/ITES',
  'Core Engineering/R&D',
  'Teaching/Educators',
  'Pharma/Medical/Biotech/Healthcare',
  'Social Media Influencers',
  'Hospitality/Travel',
  'Ed-Tech',
  'Admin/Data Entry/Secretary/Front Office',
  'Other',
]

export const interestedDomain: Record<string, string> = {
  'Data Science': 'data-science',
  'AI & ML': 'artificial-intelligence',
  'Cloud Computing': 'cloud-computing',
  'Cyber Security': 'cyber-security',
  Management: 'management',
  'Digital Marketing': 'digital-marketing',
  'Software Development': 'software-engineering',
  Design: 'design',
  'Not sure': 'not-sure',
}
const career_option_items = [
  'Switch domains',
  'New job opportunity',
  'Salary hike',
  'Only exploring free courses',
  'Higher Education',
  'Not sure',
]

const intl_career_option_items = [
  'Switch domains',
  'New job opportunity',
  'Salary hike',
  'Only exploring free courses',
  'Higher Education',
  'Not sure',
]

const highest_qualification_items = [
  'Bachelors/ Undergrad',
  'Masters/ Postgrad',
]

const jobOfferItems = [
  'Have a job and working',
  'Have a Job offer',
  'Don’t have a Job or Job offer',
]

const getNextFiveYears = (): string[] => {
  const current_year = new Date().getFullYear()
  return range(current_year - 1, current_year + 5).map(String)
}

const getGraduationYear = (): string[] => {
  const current_year = new Date().getFullYear()
  const years = [
    `Before ${current_year - 3}`,
    ...range(current_year - 3, current_year + 3).map(String),
  ]
  years.push(`After ${current_year + 3}`)
  return years
}

const getPreviousFourYears = (): string[] => {
  const current_year = new Date().getFullYear()
  return [
    `${current_year - 4} or before`,
    ...range(current_year - 3, current_year).map(String),
  ]
}
export const collegeStudentWorkEx = 'I am currently a College Student'
export const graduateWorkEx = 'I am a Graduate (No Work Ex)'
export const lessThanOneYearWorkEx = 'Fresher (< 1 year Work Ex)'

export const workExpData = {
  work_experience: {
    title: 'Work Experience in years',
    items: [
      collegeStudentWorkEx,
      graduateWorkEx,
      lessThanOneYearWorkEx,
      'Work Ex: 1-2 years',
      'Work Ex: 2-3 years',
      'Work Ex: 3-5 years',
      'Work Ex: 5-8 years',
      'Work Ex: 8-12 years',
      'Work Ex: Above 12 years',
    ],
    isMandatory: true,
  },
}

export const collegeStudentSurveyData = {
  highest_qualification: {
    title: 'What are you Studying/Pursuing?',
    items: highest_qualification_items,
    isMandatory: true,
  },
  year_of_study: {
    title: 'Year of Graduation',
    items: getGraduationYear(),
    isMandatory: true,
  },
  highest_qualification_percentage: {
    title: 'Percentage',
    items: [],
    isMandatory: true,
  },
  university_id: {
    title: 'University*',
    items: [],
    isMandatory: true,
  },
  university_name: {
    title: 'University',
    items: [],
    isMandatory: false,
  },
  college_id: {
    title: 'College*',
    items: [],
    isMandatory: true,
  },
  college_name: {
    title: 'College',
    items: [],
    isMandatory: false,
  },
  area_of_study: {
    title: 'Latest Area of study',
    items: latest_area_of_study_items,
    isMandatory: true,
  },
}

export const intlCollegeStudentSurveyData = {
  year_of_study: { ...collegeStudentSurveyData.year_of_study },
  areas_of_interest: {
    title: 'Area of interest',
    items: area_of_interest_items,
    isMandatory: false,
  },
}
const mRowCountries = [
  'MX',
  'NG',
  'ZA',
  'PH',
  'AE',
  'ID',
  'SG',
  'KE',
  'ET',
  'TH',
  'UAE',
  'JP',
]

export const recentGraduateSurveyData = {
  ...collegeStudentSurveyData,
  career_option: {
    title: "What's your learning goal?",
    items: career_option_items,
    isMandatory: true,
  },
  highest_qualification: {
    ...collegeStudentSurveyData.highest_qualification,
    title: 'What is your highest qualification?',
  },
  year_of_study: {
    ...collegeStudentSurveyData.year_of_study,
    items: getGraduationYear(),
  },
  study_abroad_consent: {
    title: 'Looking to Study abroad?',
    items: Object.keys(study_abroad_consent_options),
    isMandatory: true,
  },
}

export const intlRecentGraduateSurveyData = {
  ...intlCollegeStudentSurveyData,
  year_of_study: {
    ...intlCollegeStudentSurveyData.year_of_study,
    items: getGraduationYear(),
  },
}

export const courseGiftSurveyData = {
  career_option: {
    title: 'Are you looking for career transition?',
    items: ['Yes', 'No'],
    isMandatory: true,
  },
  pg_consent: {
    title: 'I want to know more about Career Growth programs.',
    items: [],
    isMandatory: false,
  },
}

export const professionalSurveyData = {
  interested_domain: {
    title: 'Domain interested in learning ',
    items: Object.keys(interestedDomain),
    isMandatory: true,
  },
  area_of_study: {
    title: 'Latest Area of study',
    items: latest_area_of_study_items,
    isMandatory: true,
  },
  career_option: {
    title: "What's your learning goal?",
    items: career_option_items,
    isMandatory: true,
  },
  domain: {
    title: 'Functional Area/Department',
    items: domain_items,
    isMandatory: true,
  },
  study_abroad_consent: {
    title: 'Looking to Study abroad?',
    items: Object.keys(study_abroad_consent_options),
    isMandatory: true,
  },
}

export const intlProfessionalSurveyData = {
  interested_domain: {
    title: 'Domain interested in learning ',
    items: Object.keys(interestedDomain),
    isMandatory: true,
  },
  areas_of_interest: {
    title: 'Area of Interest',
    items: area_of_interest_items,
    isMandatory: false,
  },
  career_option: {
    ...professionalSurveyData.career_option,
    items: intl_career_option_items,
    isMandatory: false,
  },
}
const intlProfessionalSurveyDataWithStudyAbroadConsent = {
  ...intlProfessionalSurveyData,
  study_abroad_consent: {
    title: 'Looking to Study abroad?',
    items: Object.keys(study_abroad_consent_options),
    isMandatory: true,
  },
}

const {
  career_option: _iPCO,
  ...intlProfessionalSurveyDataWithoutCareerOption
} = intlProfessionalSurveyData

export const professionalLessThan1yearSurveyData = {
  highest_qualification: {
    ...recentGraduateSurveyData.highest_qualification,
  },
  year_of_study: {
    ...recentGraduateSurveyData.year_of_study,
  },
  highest_qualification_percentage: {
    ...recentGraduateSurveyData.highest_qualification_percentage,
  },
  ...professionalSurveyData,
}

export const intlProfessionalLessThan1yearSurveyData = {
  year_of_study: {
    title: 'Year of Graduation',
    items: getGraduationYear(),
    isMandatory: true,
  },
  ...intlProfessionalSurveyDataWithoutCareerOption,
}

export const careerSchoolSurveyData = {
  job_offer: {
    title: 'Do you currently have a Job/Job offer',
    items: jobOfferItems,
    isMandatory: true,
  },
  work_experience: workExpData.work_experience,
  year_of_study: {
    title: 'Year of Graduation',
    items: [...getNextFiveYears(), 'Other'],
    isMandatory: true,
  },

  area_of_study: {
    title: 'Area of study',
    items: area_of_study_items,
    isMandatory: true,
  },
  planning_to_interview: {
    title: 'Planning to Interview When',
    items: [
      'Already applying to Jobs',
      'Next Month',
      'Next 2-5 months',
      'Next 6-12 months',
      'No Plans as of now',
    ],
    isMandatory: true,
  },
}
export type careerSchoolSurveyKeys = keyof typeof careerSchoolSurveyData
export type intlReferralSurveyKeys = 'pg_consent' | 'phone'
export type SurveyKeys =
  | keyof typeof workExpData
  | keyof typeof collegeStudentSurveyData
  | keyof typeof intlCollegeStudentSurveyData
  | keyof typeof recentGraduateSurveyData
  | keyof typeof intlRecentGraduateSurveyData
  | keyof typeof professionalSurveyData
  | keyof typeof intlProfessionalSurveyData
  | keyof typeof professionalLessThan1yearSurveyData
  | keyof typeof intlProfessionalLessThan1yearSurveyData
  | keyof typeof courseGiftSurveyData
  | careerSchoolSurveyKeys
  | intlReferralSurveyKeys

export type SurveyData = {
  [s in SurveyKeys]: string | string[]
}

export type SurveyTypeData =
  | typeof collegeStudentSurveyData
  | typeof intlCollegeStudentSurveyData
  | typeof recentGraduateSurveyData
  | typeof intlRecentGraduateSurveyData
  | typeof professionalSurveyData
  | typeof intlProfessionalSurveyData
  | typeof professionalLessThan1yearSurveyData
  | typeof intlProfessionalLessThan1yearSurveyData

export const getSurveyTypeData = (
  work_ex: string,
  is_international: boolean,
  is_gift: boolean,
  currentCountryCode?: string
): SurveyTypeData => {
  if (is_gift) {
    return courseGiftSurveyData
  }
  switch (work_ex) {
    case collegeStudentWorkEx:
      return is_international
        ? intlCollegeStudentSurveyData
        : collegeStudentSurveyData
    case graduateWorkEx:
      return is_international
        ? intlRecentGraduateSurveyData
        : recentGraduateSurveyData
    case lessThanOneYearWorkEx:
      return is_international
        ? intlProfessionalLessThan1yearSurveyData
        : professionalLessThan1yearSurveyData
    default:
      if (
        currentCountryCode &&
        mRowCountries.includes(currentCountryCode) &&
        is_international
      ) {
        return intlProfessionalSurveyDataWithStudyAbroadConsent
      }
      if (is_international) {
        return intlProfessionalSurveyData
      }
      return professionalSurveyData
  }
}
