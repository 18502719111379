import { GLAUserCareerPathDetails } from 'common/types/careerPath'
import {
  FETCH_CAREER_PATH_CONTENT,
  FETCH_CAREER_PATH_CONTENT_SUCCESS,
  FETCH_CAREER_PATH_CONTENT_FAILURE,
  FETCH_CAREER_PATH_CONTENT_CANCEL,
} from './CareerPath.types'

export const fetchCareerPathContent = () => ({
  type: FETCH_CAREER_PATH_CONTENT as typeof FETCH_CAREER_PATH_CONTENT,
})

export const fetchCareerPathContentSuccess = (
  payload: GLAUserCareerPathDetails
) => ({
  type: FETCH_CAREER_PATH_CONTENT_SUCCESS as typeof FETCH_CAREER_PATH_CONTENT_SUCCESS,
  payload,
})

export const fetchCareerPathContentFailure = (payload: Error) => ({
  type: FETCH_CAREER_PATH_CONTENT_FAILURE as typeof FETCH_CAREER_PATH_CONTENT_FAILURE,
  payload,
})

export const fetchCareerPathContentCancel = () => ({
  type: FETCH_CAREER_PATH_CONTENT_CANCEL as typeof FETCH_CAREER_PATH_CONTENT_CANCEL,
})

export type CareerPathActions =
  | ReturnType<typeof fetchCareerPathContent>
  | ReturnType<typeof fetchCareerPathContentSuccess>
  | ReturnType<typeof fetchCareerPathContentFailure>
  | ReturnType<typeof fetchCareerPathContentCancel>
