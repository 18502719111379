import React, { useState } from 'react'
import { connect } from 'react-redux'
import MasqueradeBar from '../../components/MasqueradeBar'
import { AppState } from '../../store'
import { UserDetailsState } from '../../providers/User/UserDetailsProvider'
import { apiCall } from '../../../common/utils'

interface Props {
  user: UserDetailsState
}

const MasqueradeHandler = (props: Props) => {
  const [stopMasqueradeStatus, updateStopMasqueradeStatus] = useState({
    loading: false,
  })
  if (!props.user.masquerading) {
    return null
  }

  const stopMasquerade = () => {
    updateStopMasqueradeStatus({ loading: true })
    localStorage.clear()
    sessionStorage.clear()
    apiCall({
      url: `${window.constants.REACT_APP_LMS_API_URL}gl/v1/user/${props.user.id}/stop_masquerade`,
    })
      .then(res => res.json())
      .then(() => {
        // window.location.href = res.return_to || '/learner_dashboard?new=0'
        window.location.href = '/'
      })
      .catch(() => {
        window.location.href = '/'
      })
  }

  const userData =
    props.user.profile && props.user.profile.data
      ? props.user.profile.data
      : undefined

  return (
    <MasqueradeBar
      user={userData}
      loading={stopMasqueradeStatus.loading}
      onStopMasquerading={stopMasquerade}
    />
  )
}

const mapStateToProps = (state: AppState) => ({
  user: state.user.details,
})

export default connect(mapStateToProps)(MasqueradeHandler)
