export const FETCH_PROGRAM_CONTENT = 'FETCH_PROGRAM_CONTENT'
export const FETCH_PROGRAM_CONTENT_SUCCESS = 'FETCH_PROGRAM_CONTENT_SUCCESS'
export const FETCH_PROGRAM_CONTENT_FAILURE = 'FETCH_PROGRAM_CONTENT_FAILURE'
export const FETCH_PROGRAM_CONTENT_CANCEL = 'FETCH_PROGRAM_CONTENT_CANCEL'

export const UPDATE_PROGRAM_DETAILS = 'UPDATE_PROGRAM_DETAILS'
export const UPDATE_ACTIVE_PROGRAM = 'UPDATE_ACTIVE_PROGRAM'

export const FETCH_COMMUNITY_SSO_TOKEN = 'FETCH_COMMUNITY_SSO_TOKEN'
export const FETCH_COMMUNITY_SSO_TOKEN_SUCCESS =
  'FETCH_COMMUNITY_SSO_TOKEN_SUCCESS'
export const FETCH_COMMUNITY_SSO_TOKEN_FAILURE =
  'FETCH_COMMUNITY_SSO_TOKEN_FAILURE'
export const FETCH_COMMUNITY_SSO_TOKEN_CANCEL =
  'FETCH_COMMUNITY_SSO_TOKEN_CANCEL'

export const UPDATE_USER_CONSENT = 'UPDATE_USER_CONSENT'
export const UPDATE_USER_CONSENT_SUCCESS = 'UPDATE_USER_CONSENT_SUCCESS'
export const UPDATE_USER_CONSENT_FAILURE = 'UPDATE_USER_CONSENT_FAILURE'
export const UPDATE_USER_CONSENT_CANCEL = 'UPDATE_USER_CONSENT_CANCEL'
export const FETCH_MOUTHSHUT_SUCCESS = 'FETCH_MOUTHSHUT_SUCCESS'

export const GET_BATCHES = 'GET_BATCHES'
export const GET_BATCHES_SUCCESS = 'GET_BATCHES_SUCCESS'
export const GET_BATCHES_FAILURE = 'GET_BATCHES_FAILURE'
