import { Reducer } from 'redux'
import { DashboardActions } from './Dashboard.actions'
import { UnlockedModulesActions } from '../../FirstUnlockedModuleProvider/FirstUnlockedModule.actions'
import {
  FETCH_DASHBOARD_CONTENT,
  FETCH_SCHOOL_DASHBOARD_CONTENT,
  FETCH_DASHBOARD_CONTENT_SUCCESS,
  FETCH_DASHBOARD_CONTENT_FAILURE,
  FETCH_DASHBOARD_CONTENT_CANCEL,
  FETCH_SCHOOL_DASHBOARD_CONTENT_CANCEL,
  UPDATE_USER_INFO_COLLECTED,
  UPDATE_CAREER_SUPPORT_LINK,
  UPDATE_CAREER_SCHOOL_USER_INFO_COLLECTED,
  UPDATE_GIFT_COURSE_CONSENT_REQUIRED,
} from './Dashboard.types'

import {
  UPDATE_CAREER_SCHOOL_JOB_ONBOARDED,
  UPDATE_CAREER_SCHOOL_ONBOARDING_COMPLETED,
} from '../CareerSchoolDashboard/CareerSchool.types'
import {
  FETCH_FIRST_UNLOCKED_MODULES,
  FETCH_FIRST_UNLOCKED_MODULES_CANCEL,
  FETCH_FIRST_UNLOCKED_MODULES_FAILURE,
  FETCH_FIRST_UNLOCKED_MODULES_SUCCESS,
} from '../../FirstUnlockedModuleProvider/FirstUnlockedModule.types'
import {
  DashboardData,
  FreeUserDashboardData,
  isTrialUserDashboardData,
  TrialUserDashboardData,
  CareerSchoolUserDashboardData,
} from '../../../../common/types/dashboard'
import { UPDATE_ACTIVE_PROGRAM, ProgramActions } from '../ProgramsProvider'

export interface DashboardState {
  data?: DashboardData
  loading: Boolean
  error: false | Error | Response
}

const initialState: DashboardState = {
  loading: false,
  error: false as false,
}

const dashboardReducer: Reducer<
  DashboardState,
  | DashboardActions
  | Extract<ProgramActions, { type: 'UPDATE_ACTIVE_PROGRAM' }>
  | UnlockedModulesActions
> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FIRST_UNLOCKED_MODULES:
    case FETCH_DASHBOARD_CONTENT:
      return { ...state, loading: true, error: false }
    case FETCH_SCHOOL_DASHBOARD_CONTENT:
      return { ...state, loading: true, error: false }
    case FETCH_DASHBOARD_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          ...action.payload,
        },
      }

    case FETCH_FIRST_UNLOCKED_MODULES_FAILURE:
    case FETCH_DASHBOARD_CONTENT_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case FETCH_FIRST_UNLOCKED_MODULES_CANCEL:
    case FETCH_DASHBOARD_CONTENT_CANCEL:
      return {
        ...state,
        loading: false,
      }
    case FETCH_SCHOOL_DASHBOARD_CONTENT_CANCEL:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_USER_INFO_COLLECTED:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        } as FreeUserDashboardData,
      }
    case UPDATE_GIFT_COURSE_CONSENT_REQUIRED:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        } as FreeUserDashboardData,
      }
    case UPDATE_CAREER_SCHOOL_USER_INFO_COLLECTED:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        } as CareerSchoolUserDashboardData,
      }

    case UPDATE_CAREER_SCHOOL_JOB_ONBOARDED:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        } as CareerSchoolUserDashboardData,
      }

    case UPDATE_CAREER_SCHOOL_ONBOARDING_COMPLETED:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        } as CareerSchoolUserDashboardData,
      }
    case UPDATE_CAREER_SUPPORT_LINK:
      return {
        ...state,
        data: {
          ...state.data,
          feature_links: {
            ...(state.data && state.data.feature_links),
            career_support: action.payload,
          },
        } as FreeUserDashboardData,
      }
    case UPDATE_ACTIVE_PROGRAM:
      return initialState

    case FETCH_FIRST_UNLOCKED_MODULES_SUCCESS:
      if (state && state.data && isTrialUserDashboardData(state.data)) {
        return {
          ...state,
          data: {
            ...state.data,
            trial_details: {
              ...state.data.trial_details,
              unlocked_modules: action.payload,
            },
          } as TrialUserDashboardData,
        }
      }

      return state

    default:
      return state
  }
}
export default dashboardReducer
