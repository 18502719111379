import { call, cancelled, put, select, takeLeading } from 'redux-saga/effects'
import { cancelable } from 'common/utils'
import {
  fetchCertificatesContent,
  fetchCertificatesContentFailure,
  fetchCertificatesContentSuccess,
} from '.'
import { getCertificatesContentAPI } from './Certificates.api'
import { UserID } from '../../../../common/types/user'
import { AppState } from '../../../store'
import {
  FETCH_CERTIFICATES_CONTENT,
  FETCH_CERTIFICATES_CONTENT_CANCEL,
} from './Certificates.types'

function* fetchCertificatesContentHandler(
  action: ReturnType<typeof fetchCertificatesContent>
) {
  const abortController = new AbortController()
  try {
    const userId: UserID = yield select(
      (state: AppState) => state.user.details.id
    )
    if (!userId) {
      throw new Error('User ID Unavailable')
    }
    const data = yield call(
      getCertificatesContentAPI,
      action,
      userId,
      abortController.signal
    )
    yield put(fetchCertificatesContentSuccess(data))
  } catch (e) {
    yield put(fetchCertificatesContentFailure(e))
  } finally {
    if (cancelled()) {
      abortController.abort()
    }
  }
}

export function* certificatesMiddleware() {
  yield takeLeading(
    FETCH_CERTIFICATES_CONTENT,
    cancelable(
      fetchCertificatesContentHandler,
      FETCH_CERTIFICATES_CONTENT_CANCEL
    )
  )
}

export default ([] as any).concat(certificatesMiddleware())
