import {
  Bell,
  Briefcase,
  Calendar,
  CalendarCheck,
  HeartHandshake,
  HelpCircle,
  Home as HomeIcon,
  LineChart,
  Puzzle,
  RefreshCw,
  Trophy,
  Tv,
  Users,
} from 'lucide-react'
import React, { ReactNode, useState } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import {
  ProgramTextMappingKey,
  useFeatureLinksData,
  useIsNewUIEnabled,
  useProgramTextMapping,
} from 'web/hooks'
import { Box, Chip, makeStyles, Link as MuiLink } from '@material-ui/core'
import cx from 'classnames'
import { Typography } from 'common/elements'
import { generateURL } from 'common/utils'
import { useSelector } from 'react-redux'
import { AppState } from 'web/store'
import { SwitchBatch } from './switch-batch'
import { TargetEnum } from './profile-menu'

const useMoreCTAStyles = makeStyles({
  container: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    padding: '16px',
    cursor: 'pointer',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: '#f4f3f7',
    },
    '&:active': {
      backgroundColor: '#f4f3f7',
    },
    '&.active': {
      backgroundColor: '#0054d614',
    },
  },
  chip: {
    backgroundColor: '#ffdad5',
    height: 24,
    borderRadius: 8,
    '& .MuiChip-label': {
      paddingLeft: 12,
      paddingRight: 12,
      fontFamily: 'Inter',
      fontSize: 10,
      fontWeight: 400,
      letterSpacing: 0.4,
      lineHeight: '16px',
      color: '#410001',
    },
  },
  linkButton: {
    color: '#000000 !important',
    '&:hover': {
      color: '#000000',
    },
    '&:visited': {
      color: '#000000',
    },
  },
})

export function SwitchBatchButton() {
  const [show, toggle] = useState(false)

  return (
    <>
      <SwitchBatch variant="B" open={show} onClose={() => toggle(false)} />
      <MoreCTA
        title="Switch Program"
        icon={<RefreshCw />}
        onPress={() => toggle(true)}
      />
    </>
  )
}

export function Gradebook() {
  const featureLinks = useFeatureLinksData()
  const gradebookText = useProgramTextMapping(ProgramTextMappingKey.gradebook)

  const location = generateURL('GRADEBOOK', null)

  return featureLinks?.gradebook?.url ? (
    <MoreCTAWithLink
      icon={<LineChart />}
      title={gradebookText}
      screenName={location.pathname}
      url={location.pathname || '/'}
    />
  ) : (
    <></>
  )
}

export function Attendance() {
  const location = generateURL('ATTENDANCE_DETAILS', null)

  return (
    <MoreCTAWithLink
      icon={<CalendarCheck />}
      title="Attendance"
      screenName={location.pathname}
      url={location.pathname || '/'}
    />
  )
}

export function Connect() {
  const featureLinks = useFeatureLinksData()
  const location = generateURL('GL_CONNECT', null)

  return featureLinks?.gl_connect?.url ? (
    <MoreCTAWithLink
      icon={<HeartHandshake />}
      title="Connect"
      screenName={location.pathname}
      url={location.pathname || '/'}
    />
  ) : (
    <></>
  )
}

export function Support() {
  const isNewUIEnabled = useIsNewUIEnabled()
  const featureLinks = useFeatureLinksData()
  const location = generateURL('PROGRAM_SUPPORT', null)

  return featureLinks?.program_support?.url ? (
    <MoreCTAWithLink
      icon={<HelpCircle />}
      title="Support"
      screenName={location.pathname}
      url={
        isNewUIEnabled
          ? featureLinks?.program_support?.url
          : location.pathname || '/'
      }
    />
  ) : (
    <></>
  )
}

export function Home() {
  const isNewUIEnabled = useIsNewUIEnabled()
  const featureLinks = useFeatureLinksData()
  const location = generateURL('DASHBOARD', null)

  return featureLinks?.learner_dashboard_page?.url ? (
    <MoreCTAWithLink
      screenName={location.pathname}
      icon={<HomeIcon />}
      title="Dashboard"
      url={
        isNewUIEnabled
          ? `${window.location.origin}${location.pathname}`
          : location.pathname || '/'
      }
    />
  ) : (
    <></>
  )
}

export function Courses() {
  const isNewUIEnabled = useIsNewUIEnabled()
  const featureLinks = useFeatureLinksData()
  const location = generateURL('COURSES', null)

  return featureLinks?.courses_page?.url ? (
    <MoreCTAWithLink
      screenName={location.pathname}
      icon={<Tv />}
      title="Courses"
      url={
        isNewUIEnabled
          ? `${window.location.origin}/courses`
          : location.pathname || '/'
      }
    />
  ) : (
    <></>
  )
}

export function Activities() {
  return (
    <MoreCTAWithLink
      screenName="ActivitiesScreen"
      icon={<Calendar />}
      title="Activities"
      url="/activities"
    />
  )
}

export function Excelerate() {
  const featureLinks = useFeatureLinksData()

  return featureLinks?.excelerate?.url ? (
    <MoreCTAWithLink
      icon={<Briefcase />}
      title="Excelerate"
      url={featureLinks?.excelerate?.url}
    />
  ) : (
    <></>
  )
}

export function PeerReviews() {
  const featureLinks = useFeatureLinksData()
  const location = generateURL('PEER_REVIEW', null)

  return featureLinks?.peer_reviews?.url ? (
    <MoreCTAWithLink
      icon={<Briefcase />}
      title="Gallery"
      screenName={location.pathname}
      url={location.pathname || '/'}
    />
  ) : (
    <></>
  )
}

export function Hackathons() {
  const featureLinks = useFeatureLinksData()
  const location = generateURL('HACKATHONS', null)

  return featureLinks?.hackathons?.url ? (
    <MoreCTAWithLink
      icon={<Puzzle />}
      screenName={location.pathname}
      title="Hackathons"
      url={location.pathname || '/'}
    />
  ) : (
    <></>
  )
}

export function Visa() {
  const featureLinks = useFeatureLinksData()

  return featureLinks?.visa?.url ? (
    <MoreCTAWithLink
      icon={<Users />}
      title="Visa"
      url={featureLinks?.visa?.url}
    />
  ) : (
    <></>
  )
}

export function Community() {
  const featureLinks = useFeatureLinksData()
  const communityNotificationCount = useSelector((state: AppState) =>
    featureLinks?.community?.url ? state.community.data.notifications : 0
  )

  return featureLinks?.community?.url ? (
    <MoreCTAWithLink
      icon={<Users />}
      title="Community"
      badge={
        communityNotificationCount ? `${communityNotificationCount} Unread` : 0
      }
      url={featureLinks?.community?.url}
      target={TargetEnum.BLANK}
    />
  ) : (
    <></>
  )
}

export function Announcements() {
  const featureLinks = useFeatureLinksData()
  const location = generateURL('ANNOUNCEMENTS', null)

  return featureLinks?.announcements?.url ? (
    <MoreCTAWithLink
      icon={<Bell />}
      title="Announcements"
      screenName={location.pathname}
      url={location.pathname || '/'}
    />
  ) : (
    <></>
  )
}

export function Leaderboard() {
  const featureLinks = useFeatureLinksData()
  const history = useHistory()
  const location = generateURL('LEADERBOARD', null)

  return featureLinks?.leaderboard?.url ? (
    <MoreCTAWithLink
      icon={<Trophy />}
      title="Leaderboard"
      screenName={location.pathname}
      onPress={() => {
        history.push(location)
      }}
      url={location.pathname || '/'}
    />
  ) : (
    <></>
  )
}

export function MoreCTA({
  icon,
  title,
  onPress,
  screenName,
  badge,
}: {
  icon: ReactNode
  title: string
  onPress?: () => void
  screenName?: string
  badge?: number | string
}) {
  const styles = useMoreCTAStyles()
  const match = useRouteMatch(screenName || '')

  function onButtonPress() {
    if (typeof onPress === 'function') {
      onPress()
    }
  }

  return (
    <Box
      className={cx(styles.container, { active: match?.isExact })}
      onClick={() => {
        setTimeout(onButtonPress, 250)
      }}
    >
      <Box display="flex" gridGap={8} alignItems="center">
        {React.cloneElement(icon, { width: 20, height: 20, color: '#0054d6' })}
        <Typography className={cx('subtitle1')}>{title}</Typography>
      </Box>
      {!!badge && (
        <Chip
          className={cx(styles.chip)}
          size="small"
          label={badge.toString()}
        />
      )}
    </Box>
  )
}

export function MoreCTAWithLink(props: {
  icon: ReactNode
  title: string
  onPress?: () => void
  screenName?: string
  badge?: number | string
  url: string
  target?: TargetEnum
}) {
  const styles = useMoreCTAStyles()
  return props.url ? (
    <>
      {props.url.includes('http') ? (
        <MuiLink
          href={props.url}
          target={props.target}
          className={cx(styles.linkButton)}
          style={{ textDecoration: 'none' }}
        >
          <MoreCTA {...props} />
        </MuiLink>
      ) : (
        <Link
          to={props.url}
          target={props.target || TargetEnum.SELF}
          className={cx(styles.linkButton)}
        >
          <MoreCTA {...props} />
        </Link>
      )}
    </>
  ) : (
    <MoreCTA {...props} />
  )
}
