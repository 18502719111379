import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Button,
  useMediaQuery,
} from '@material-ui/core'
import React from 'react'
import ChevronIcon from 'common/images/chevron_right.svg'
import studyAbroadImage from 'common/images/study_abroad.png'
import studyAbroadImageMobile from 'common/images/study_abroad_mobile.png'
import styles from './StudyAbroadRecommendationDialog.module.css'

import { ReactComponent as Tick } from '../../../common/images/icons/alumni/blue_check.svg'

interface Props {
  onClick: (arg0: string) => void
}

const programHightLights = [
  'Learn from top universities ',
  'Full-time and hybrid programs',
  'No GRE/GMAT/TOEFL required',
  'Up to 3 years of work visa*',
]
const StudyAbroadRecommendationDialog = (props: Props) => {
  const isMobile = useMediaQuery('(max-width: 557px)', { noSsr: true })
  const onProgramCtaClick = () => {
    props.onClick('Paid')
    window.open(
      `${
        process.env.REACT_APP_WEBSITE_URL
      }/study-abroad/courses?gl_source=new_campaign_workex&gl_campaign=${
        isMobile ? 'olympus_mobile' : 'olympus_desktop'
      }_onboarding_form_loggedin_recommendations`,
      '_blank'
    )
  }
  return (
    <Dialog open classes={{ paper: styles.dialog }}>
      <DialogContent className={styles.dialogContent}>
        <Box className={styles.certificateProgramWrapper}>
          <Typography variant="h3" className={styles.certificateProgramHeading}>
            Study abroad at top universities
          </Typography>
          <Typography
            variant="h3"
            className={styles.certificateProgramHeadLine}
          >
            Explore programs taught by the world’s best.
          </Typography>
          <Box className={styles.certificateProgramCardWrapper}>
            <img
              src={isMobile ? studyAbroadImageMobile : studyAbroadImage}
              alt="program name"
              className={styles.programCardImg}
            />
            <Box className={styles.certificateProgramCardDetails}>
              <Typography variant="h3" className={styles.programName}>
                Study abroad at 1/3rd the cost
              </Typography>
              <Box className={styles.programHighLightsWrapper}>
                {programHightLights.map(function(object, i) {
                  return (
                    <Box className={styles.programHighLightsContents}>
                      <Tick></Tick>
                      <Typography
                        variant="h3"
                        className={styles.programHighLights}
                      >
                        {object}
                      </Typography>
                    </Box>
                  )
                })}
              </Box>
              <Button
                variant="contained"
                className={styles.submitButton}
                onClick={() => onProgramCtaClick()}
                color="primary"
              >
                Explore Programs
              </Button>
            </Box>
          </Box>
        </Box>
        <Button
          className={styles.continueFreeCourseMobile}
          onClick={() => props.onClick('learn-for-free')}
          variant="text"
        >
          Continue to free course <img src={ChevronIcon} alt="" />
        </Button>
      </DialogContent>
      <Button
        className={styles.continueFreeCourse}
        onClick={() => props.onClick('learn-for-free')}
        variant="text"
      >
        Continue to free course <img src={ChevronIcon} alt="" />
      </Button>
    </Dialog>
  )
}
export default StudyAbroadRecommendationDialog
