import React, { Suspense } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { parse, stringify } from 'query-string'
import {
  getActiveProgramBatchId,
  isUserAcadOps,
  retryChunkLoad,
} from 'common/utils'
import { ProgramData } from 'common/types/programs'
import { showAlertMessage } from 'web/providers/AlertsProvider'
import { useShouldDisableGradebook } from 'common/utils/custom/partnerOrganisations/settings'
import PrivateRoute from './components/Utils/PrivateRoute'
import Auth from './containers/Auth'
import PageNotFound from './pages/PageNotFound'
import { UserDetailsState } from './providers/User/UserDetailsProvider'
import routes, { RouteNames } from './routes'
import { AppState } from './store'
import { UserOnboardingState } from './providers/User/UserOnboardingProvider'
import { UserAdmissionState } from './providers/User/UserAdmissionProvider'
import { DashboardState } from './providers/Dashboard/DashboardProvider'
import { history } from './store/history'

import {
  isAcademyUser,
  isAcademyInternationalUser,
  isFreeUser,
} from '../common/utils/custom/user'
import Loader from './components/Utils/Loader'
import { programSelectors } from './providers/Dashboard/ProgramsProvider'

import NinjaLayout from './layouts/ninja'
import StudentLayout from './layouts/student'
import QuizTakerLayout from './layouts/quizTaker'
import StudentBaseLayout from './layouts/studentBase'
import LearnAndDevLayout from './layouts/learnAndDev'

const DynamicQuestionHistory = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "DynamicQuestionHistory" */ './pages/DynamicQuestionHistory'
    )
  )
)
const QuizAttempt = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "QuizAttempt" */ './pages/QuizAttempt/QuizAttempt'
    )
  )
)
const QuizTaker = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "QuizTaker" */ './pages/QuizTaker/QuizTaker')
  )
)
const Dashboard = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "Dashboard" */ './pages/Dashboard')
  )
)
const Onboarding = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "Onboarding" */ './pages/Onboarding')
  )
)
const EnrollmentForm = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "EnrollmentForm" */ './pages/EnrollmentForm')
  )
)
const Invigilation = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "Invigilation" */ './pages/Invigilation')
  )
)
const Feedback = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "Feedback" */ './pages/Feedback')
  )
)
const CommunityGuidelines = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "CommunityGuidelines" */ './pages/CommunityGuidelines'
    )
  )
)
const CommunitySendInvite = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "CommunityReferralInvite" */ './pages/CommunityReferralInvite'
    )
  )
)
const CommunityAcceptInvite = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "CommunityInviteAccept" */ './pages/CommunityInviteAccept'
    )
  )
)
const CommunityAcceptReferral = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "CommunityAcceptReferral" */ './pages/CommunityAcceptReferral'
    )
  )
)
const Testimonials = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "Testimonials" */ './pages/Testimonials')
  )
)
const TestimonialGuidelines = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "TestimonialGuidelines" */ './pages/TestimonialGuidelines'
    )
  )
)
const Gradebook = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "Gradebook" */ './pages/Gradebook')
  )
)
const Leaderboard = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "Leaderboard" */ './pages/Leaderboard')
  )
)
const Eportfolio = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "Eportfolio" */ './pages/Eportfolio')
  )
)
const PublicEportfolio = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "PublicEportfolio" */ './pages/PublicEportfolio'
    )
  )
)
const Announcements = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "Announcements" */ './pages/Announcements')
  )
)
const HackathonList = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "HackathonList" */ './pages/HackathonList')
  )
)
const HackathonDetails = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "HackathonDetails" */ './pages/HackathonDetails'
    )
  )
)
const AccountSettings = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "AccountSettings" */ './pages/AccountSettings')
  )
)
const LiveSessions = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "LiveSessions" */ './pages/LiveSessions')
  )
)
const CourseCatalog = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "CourseCatalog" */ './pages/CourseCatalog')
  )
)
const FeePayment = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "FeePayment" */ './pages/FeePayment')
  )
)
const PaymentStatus = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "PaymentStatus" */ './pages/PaymentStatus')
  )
)
const AlumniPortal = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "AlumniPortal" */ './pages/AlumniPortal')
  )
)
const DigitalExcelerateTests = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "DigitalExcelerateTests" */ './pages/DigitalExcelerateTests'
    )
  )
)
const Zoomout = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "Zoomout" */ './pages/Zoomout')
  )
)

const IpevoInstructions = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "IpevoInstructions" */ './pages/IpevoInstructions'
    )
  )
)
const TryCalculator = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "TryCalculator" */ './pages/Try/Calculator')
  )
)

const ExcelerateCourseDetails = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "ExcelerateCourseDetails" */ './pages/CourseDetails/ExcelerateCourseDetails'
    )
  )
)

const ExcelerateValueProp = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "ExcelerateValueProp" */ './pages/Excelerate/ExcelerateValueProp'
    )
  )
)

const CodingPlayGround = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "CodingPlayground" */ './pages/CodingPlayground'
    )
  )
)

const CareerSchoolDashboard = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "CareerSchoolDashboard" */ './pages/CareerSchoolDashboard'
    )
  )
)
const CareerSchoolLayout = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "careerSchool" */ './layouts/careerSchool')
  )
)

const LearnAndDevDashboard = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "LearnAndDevDashboard" */ './pages/LearnAndDev/Dashboard'
    )
  )
)

const LearnAndDevLeaderboard = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "LearnAndDevLeaderboard" */ './pages/LearnAndDev/Leaderboard'
    )
  )
)

const ProgramSupport = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "ProgramSupport" */ './pages/ProgramSupport')
  )
)

const ProgramSupportTicketDetail = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "ProgramSupportTicketDetail" */ './pages/ProgramSupportTicketDetail'
    )
  )
)

const ProgramSupportCategories = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "ProgramSupportCategories" */ './pages/ProgramSupportCategories'
    )
  )
)

const ProgramSupportCategory = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "ProgramSupportCategory" */ './pages/ProgramSupportCategory'
    )
  )
)

const ProgramSupportFaq = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "ProgramSupportFaq" */ './pages/ProgramSupportFaq'
    )
  )
)

const ProgramSupportFaqSearch = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "ProgramSupportFaqSearch" */ './pages/ProgramSupportFaqSearch'
    )
  )
)

const ProgramSupportNewFaq = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "ProgramSupportFaqNew" */ './pages/ProgramSupportFaqNew'
    )
  )
)

const ProgramSupportEditFaq = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "ProgramSupportFaqEdit" */ './pages/ProgramSupportFaqEdit'
    )
  )
)
const CourseCompletionCards = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "CourseCompletionCards" */ './pages/CourseCompletionCards'
    )
  )
)

const PrivateCertificate = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "PrivateCertificate" */ './pages/PrivateCertificate'
    )
  )
)

const PrivacyPolicy = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "PrivacyPolicy" */ './pages/PrivacyPolicy')
  )
)

const ExternalLab = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "ExternalLab" */ './pages/ExternalLab')
  )
)

const Terms = React.lazy(() =>
  retryChunkLoad(() => import(/* webpackChunkName: "Terms" */ './pages/Terms'))
)

const CodingLabAssignment = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "CodingLabAssignment" */ './pages/CodingLabAssignment'
    )
  )
)

const GlLabAssignment = React.lazy(() =>
  retryChunkLoad(() => import('./pages/GlLabAssignment'))
)

const JupyterLabAssignment = React.lazy(() =>
  retryChunkLoad(() => import('./pages/JupyterLabAssignment'))
)

const AssignmentAiMentor = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "AssignmentAiMentor" */ './pages/AssignmentAiMentor'
    )
  )
)

const PeerReview = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "PeerReview" */ './pages/PeerReview')
  )
)

const CurriculumAiMentor = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "CurriculumAiMentor" */ './pages/CurriculumAiMentor'
    )
  )
)

const CourseContentItem = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "CourseContentItem" */ './pages/CourseContentItem'
    )
  )
)

const ClassroomRecordingItemView = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "ClassroomRecordingItemView" */ './pages/ClassroomRecordingItemView'
    )
  )
)

const CourseCertificate = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "CourseCertificate" */ './pages/CourseCertificate'
    )
  )
)

const CourseContentItemForAcadOps = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "CourseContentItemForAcadOps" */ './pages/CourseContentItemForAcadOps'
    )
  )
)

const CourseDetails = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "CourseDetails" */ './pages/CourseDetails')
  )
)

const CourseList = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "CourseList" */ './pages/CourseList')
  )
)

const Attendance = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "Attendance" */ './pages/Attendance')
  )
)

const MentorshipRecordingSessionItemView = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "MentorshipRecordingSessionItemView" */ './pages/MentorshipRecordingSessionItemView'
    )
  )
)

const Certificates = React.lazy(() =>
  retryChunkLoad(() =>
    import(/* webpackChunkName: "Certificates" */ './pages/Certificates')
  )
)

const ReferralsAndRewards = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "ReferralsAndRewards" */ './pages/ReferralsAndRewards'
    )
  )
)

const CareerSchoolTrack = React.lazy(() =>
  retryChunkLoad(() =>
    import(
      /* webpackChunkName: "CareerSchoolTrack" */ './pages/CareerSchoolTrack'
    )
  )
)

interface Props {
  userDetails: UserDetailsState
  onboarding: UserOnboardingState
  admission: UserAdmissionState
  dashboardData: DashboardState['data']
  programData: ProgramData | null
}

export const Main = React.memo((props: Props) => {
  const { isAuthenticated } = props.userDetails.auth
  const { accessType, learnAndDevUser = false } = props.userDetails
  const { dashboardData } = props
  const dispatch = useDispatch()

  const isAcadOps = isUserAcadOps()

  const onlyBasicUsers = !accessType || accessType === 'basic'

  const coding_playground_enabled =
    props.admission.data && props.admission.data.coding_playground_enabled

  const ninjaUsers = props.programData === null

  const freeCorporateUsers = accessType ? isAcademyUser(accessType) : true
  const freeUsers = accessType ? isFreeUser(accessType) : true

  const freeInternationalUsers = accessType
    ? isAcademyInternationalUser(accessType)
    : true

  const enabledTrialUsers =
    accessType && dashboardData
      ? accessType === 'trial' &&
        'trial_details' in dashboardData &&
        !dashboardData.trial_details.access_blocked
      : true

  const enabledFreemiumUsers =
    accessType && dashboardData
      ? (accessType === 'freemium' &&
          'program_fee_paid' in dashboardData &&
          dashboardData.program_fee_paid) ||
        ('trial_remaining_days' in dashboardData &&
          dashboardData.trial_remaining_days > 0)
      : true

  const isUserFromBatch9027 = getActiveProgramBatchId() === 9027

  const disableCoursesPage =
    props.programData && props.programData.disable_courses

  const disableDashboardPage =
    props.programData && props.programData.disable_dashboard

  const disableGradebook = useShouldDisableGradebook()

  const searchParams = parse(history.location.search)
  if (
    searchParams.pb_id ||
    searchParams.career_plus ||
    searchParams.fs_test_finished
  ) {
    if (searchParams.pb_id) {
      delete searchParams.pb_id
    }

    if (searchParams.career_plus) {
      if (searchParams.career_plus.includes('true')) {
        sessionStorage.setItem('from_career_plus_banner', 'true')
      }
      delete searchParams.career_plus

      if (searchParams.gla_redirection_link) {
        sessionStorage.setItem(
          'gla_redirection_link',
          searchParams.gla_redirection_link as string
        )
        delete searchParams.gla_redirection_link
      }
    }

    if (searchParams.fs_test_finished) {
      if (searchParams.fs_test_finished === 'true') {
        dispatch(
          showAlertMessage({
            variant: 'success',
            message:
              'The test was successfully submitted and will be evaluated shortly.',
          })
        )
      } else {
        dispatch(
          showAlertMessage({
            variant: 'error',
            message: 'An error occurred while submitting the test.',
          })
        )
      }
      delete searchParams.fs_test_finished
    }

    history.replace({
      ...history.location,
      search: stringify(searchParams),
    })
  }
  const careerSchool =
    props.programData && props.programData.program_type === 'career_track'

  const learnAndDevRouteKeys = [
    'LEARN_AND_DEV_DASHBOARD',
    'LEARN_AND_DEV_LEADERBOARD',
  ]

  const renderLearnAndDevRedirects = () =>
    learnAndDevUser
      ? Object.keys(routes)
          .filter(el => !['ROOT', 'AUTH', ...learnAndDevRouteKeys].includes(el))
          .map(key => (
            <Redirect
              exact
              key={key}
              from={routes[key as RouteNames]}
              to={routes.LEARN_AND_DEV_DASHBOARD}
            />
          ))
      : learnAndDevRouteKeys.map(key => (
          <Redirect
            exact
            key={key}
            from={routes[key as RouteNames]}
            to={routes.DASHBOARD}
          />
        ))

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {renderLearnAndDevRedirects()}

        <PrivateRoute
          exact
          path={routes.DASHBOARD}
          component={careerSchool ? CareerSchoolDashboard : Dashboard}
          condition={
            isAuthenticated && !isUserFromBatch9027 && !disableDashboardPage
          }
          {...((isUserFromBatch9027 ||
            (disableDashboardPage && !disableCoursesPage)) && {
            redirect: 'COURSES',
          })}
          layout={careerSchool ? CareerSchoolLayout : StudentLayout}
        />
        <PrivateRoute
          path={routes.LEARN_AND_DEV_DASHBOARD}
          component={LearnAndDevDashboard}
          condition={isAuthenticated && learnAndDevUser && !isAcadOps}
          layout={LearnAndDevLayout}
        />
        <PrivateRoute
          path={routes.LEARN_AND_DEV_LEADERBOARD}
          component={LearnAndDevLeaderboard}
          condition={isAuthenticated && learnAndDevUser && !isAcadOps}
          layout={LearnAndDevLayout}
        />
        <PrivateRoute
          exact
          path={routes.ENROLLMENT_FORM}
          condition={isAuthenticated && freeUsers && !isAcadOps}
          component={EnrollmentForm}
        />
        <PrivateRoute
          exact
          path={routes.COURSES}
          component={CourseList}
          condition={
            isAuthenticated &&
            (onlyBasicUsers ||
              freeCorporateUsers ||
              enabledFreemiumUsers ||
              enabledTrialUsers ||
              freeInternationalUsers) &&
            !careerSchool &&
            !disableCoursesPage &&
            !isAcadOps
          }
          redirect="DASHBOARD"
        />
        <PrivateRoute
          exact
          path={routes.SCHOOL_TRACK}
          component={CareerSchoolTrack}
          condition={isAuthenticated && !!careerSchool && !isAcadOps}
          redirect="DASHBOARD"
        />
        <PrivateRoute
          exact
          path={[
            routes.ANNOUNCEMENTS,
            routes.COURSE_ANNOUNCEMENTS,
            routes.GROUP_ANNOUNCEMENTS,
            routes.COURSE_ANNOUNCEMENT,
            routes.GROUP_ANNOUNCEMENT,
          ]}
          condition={
            isAuthenticated &&
            (onlyBasicUsers || enabledTrialUsers) &&
            !isAcadOps
          }
          redirect="DASHBOARD"
          component={Announcements}
        />
        <PrivateRoute
          exact
          path={routes.CONTENT_AI_MENTOR}
          condition={isAuthenticated}
          component={AssignmentAiMentor}
          layout={StudentBaseLayout}
        />

        <PrivateRoute
          exact
          path={routes.CURRICULUM_AI_MENTOR}
          condition={isAuthenticated}
          component={CurriculumAiMentor}
          layout={StudentBaseLayout}
        />
        <PrivateRoute
          path={routes.QUIZ_RUNNER}
          component={QuizTaker}
          condition={
            isAuthenticated &&
            (onlyBasicUsers ||
              freeCorporateUsers ||
              enabledFreemiumUsers ||
              enabledTrialUsers ||
              freeInternationalUsers)
          }
          redirect="DASHBOARD"
          layout={QuizTakerLayout}
        />
        <PrivateRoute
          path={routes.QUIZ_ATTEMPT}
          component={QuizAttempt}
          condition={
            isAuthenticated &&
            (onlyBasicUsers ||
              freeCorporateUsers ||
              enabledFreemiumUsers ||
              enabledTrialUsers ||
              freeInternationalUsers)
          }
          redirect="DASHBOARD"
          layout={QuizTakerLayout}
        />
        <PrivateRoute
          path={routes.QUIZ_ATTEMPT_DYNAMIC_QUESTION}
          component={DynamicQuestionHistory}
          condition={isAuthenticated}
          layout={NinjaLayout}
        />
        <PrivateRoute
          path={[routes.MODULE_ITEM, routes.CONTENT, routes.ALL_NOTES]}
          component={
            isAcadOps ? CourseContentItemForAcadOps : CourseContentItem
          }
          condition={
            isAuthenticated &&
            (onlyBasicUsers ||
              freeCorporateUsers ||
              enabledFreemiumUsers ||
              enabledTrialUsers ||
              freeInternationalUsers)
          }
          redirect="DASHBOARD"
          layout={
            props.userDetails.appSource === 'seb_browser'
              ? NinjaLayout
              : StudentLayout
          }
        />
        <PrivateRoute
          exact
          path={routes.CLASSROOM_RECORDING_ITEM}
          condition={
            isAuthenticated &&
            (onlyBasicUsers || enabledTrialUsers) &&
            !isAcadOps
          }
          redirect="DASHBOARD"
          component={ClassroomRecordingItemView}
        />
        <PrivateRoute
          exact
          path={routes.MENTORSHIP_RECORDING_SESSION_ITEM}
          condition={
            isAuthenticated &&
            (onlyBasicUsers || enabledTrialUsers) &&
            !isAcadOps
          }
          redirect="DASHBOARD"
          component={MentorshipRecordingSessionItemView}
        />
        <PrivateRoute
          exact
          path={routes.COURSE_CERTIFICATE}
          condition={
            isAuthenticated &&
            (freeCorporateUsers || freeInternationalUsers) &&
            !isAcadOps
          }
          redirect="DASHBOARD"
          component={CourseCertificate}
        />
        <PrivateRoute
          exact
          path={routes.COURSE}
          component={CourseDetails}
          condition={
            isAuthenticated &&
            (onlyBasicUsers ||
              freeCorporateUsers ||
              enabledFreemiumUsers ||
              enabledTrialUsers ||
              freeInternationalUsers) &&
            !isAcadOps
          }
          redirect="DASHBOARD"
        />
        <PrivateRoute
          path={[
            routes.EXCELERATE_CAREER_PREP_COURSE,
            routes.EXCELERATE_CAREER_PREP,
          ]}
          component={ExcelerateCourseDetails}
          condition={isAuthenticated && onlyBasicUsers && !isAcadOps}
          redirect="DASHBOARD"
        />
        <PrivateRoute
          path={[routes.EXCELERATE_VALUE_PROP]}
          component={ExcelerateValueProp}
          condition={isAuthenticated && onlyBasicUsers && !isAcadOps}
          redirect="DASHBOARD"
        />
        <PrivateRoute
          exact
          path={routes.PROFILE_SETTINGS}
          condition={isAuthenticated}
          component={AccountSettings}
          layout={careerSchool ? CareerSchoolLayout : StudentLayout}
        />
        <PrivateRoute
          exact
          path={[routes.FEE_PAYMENT]}
          condition={isAuthenticated}
          component={FeePayment}
        />
        <PrivateRoute
          exact
          path={[routes.FEE_PAYMENT_STATUS]}
          condition={isAuthenticated}
          component={PaymentStatus}
        />
        <PrivateRoute
          exact
          path={[routes.GL_CONNECT]}
          condition={isAuthenticated && !isAcadOps}
          component={AlumniPortal}
        />
        <PrivateRoute
          exact
          path={routes.PEER_REVIEW}
          condition={isAuthenticated}
          component={PeerReview}
        />
        <PrivateRoute
          exact
          path={routes.FEEDBACK}
          condition={isAuthenticated && !isAcadOps}
          component={Feedback}
        />
        <PrivateRoute
          exact
          path={[routes.GRADEBOOK, routes.SCORECARD]}
          condition={
            isAuthenticated &&
            (onlyBasicUsers || enabledTrialUsers) &&
            !isAcadOps &&
            !disableGradebook
          }
          redirect="DASHBOARD"
          component={Gradebook}
        />
        <PrivateRoute
          exact
          path={routes.LEADERBOARD}
          condition={
            isAuthenticated &&
            (onlyBasicUsers || enabledTrialUsers) &&
            !isAcadOps
          }
          redirect="DASHBOARD"
          component={Leaderboard}
        />
        <PrivateRoute
          exact
          path={routes.EPORTFOLIO}
          condition={isAuthenticated && !isAcadOps}
          redirect="DASHBOARD"
          component={Eportfolio}
        />
        <PrivateRoute
          exact
          path={routes.ONBOARDING}
          condition={isAuthenticated && !isAcadOps}
          component={Onboarding}
        />
        <PrivateRoute
          exact
          path={routes.COMMUNITY_GUIDELINES}
          condition={isAuthenticated}
          component={CommunityGuidelines}
        />
        <PrivateRoute
          exact
          path={routes.COMMUNITY_SEND_INVITE}
          condition={isAuthenticated}
          component={CommunitySendInvite}
        />
        <PrivateRoute
          exact
          path={routes.COMMUNITY_ACCEPT_INVITE}
          condition={isAuthenticated}
          component={CommunityAcceptInvite}
        />
        <PrivateRoute
          exact
          path={routes.COMMUNITY_ACCEPT_REFERRAL}
          condition={isAuthenticated}
          component={CommunityAcceptReferral}
        />
        <PrivateRoute
          exact
          path={routes.TESTIMONIALS}
          condition={isAuthenticated}
          component={Testimonials}
        />
        <PrivateRoute
          exact
          path={routes.TESTIMONIAL_GUIDELINES}
          condition={isAuthenticated}
          component={TestimonialGuidelines}
        />
        <PrivateRoute
          exact
          path={routes.PROGRAM_SUPPORT}
          condition={isAuthenticated && onlyBasicUsers}
          component={ProgramSupport}
        />
        <PrivateRoute
          exact
          path={routes.PROGRAM_SUPPORT_TICKET_DETAIL}
          condition={isAuthenticated && onlyBasicUsers}
          component={ProgramSupportTicketDetail}
        />
        <PrivateRoute
          exact
          path={routes.PROGRAM_SUPPORT_CATEGORIES}
          condition={isAuthenticated && onlyBasicUsers}
          component={ProgramSupportCategories}
        />
        <PrivateRoute
          exact
          path={routes.PROGRAM_SUPPORT_CATEGORY}
          condition={isAuthenticated && onlyBasicUsers}
          component={ProgramSupportCategory}
        />
        <PrivateRoute
          exact
          path={routes.PROGRAM_SUPPORT_FAQ}
          condition={isAuthenticated}
          component={ProgramSupportFaq}
          layout={ninjaUsers ? NinjaLayout : StudentLayout}
        />
        <PrivateRoute
          exact
          path={routes.PROGRAM_SUPPORT_FAQ_SEARCH}
          condition={isAuthenticated && ninjaUsers}
          component={ProgramSupportFaqSearch}
          layout={NinjaLayout}
        />
        <PrivateRoute
          exact
          path={routes.PROGRAM_SUPPORT_FAQ_NEW}
          condition={isAuthenticated && ninjaUsers}
          component={ProgramSupportNewFaq}
          layout={NinjaLayout}
        />
        <PrivateRoute
          exact
          path={routes.PROGRAM_SUPPORT_FAQ_EDIT}
          condition={isAuthenticated && ninjaUsers}
          component={ProgramSupportEditFaq}
          layout={NinjaLayout}
        />
        <PrivateRoute
          exact
          path={routes.CODING_LAB_ASSIGNMENT}
          condition={isAuthenticated}
          component={CodingLabAssignment}
          layout={ninjaUsers ? NinjaLayout : StudentLayout}
        />
        <PrivateRoute
          exact
          path={routes.GL_LAB_ASSIGNMENT}
          condition={isAuthenticated}
          component={GlLabAssignment}
          layout={ninjaUsers ? NinjaLayout : StudentLayout}
        />
        <PrivateRoute
          exact
          path={routes.JUPYTER_LAB}
          condition={isAuthenticated}
          component={JupyterLabAssignment}
          layout={StudentBaseLayout}
        />
        <PrivateRoute
          exact
          path={routes.LIVE_SESSIONS}
          condition={isAuthenticated && freeCorporateUsers}
          redirect="DASHBOARD"
          component={LiveSessions}
        />
        <PrivateRoute
          exact
          path={routes.CERTIFICATES}
          condition={
            isAuthenticated && (freeCorporateUsers || freeInternationalUsers)
          }
          redirect="DASHBOARD"
          component={Certificates}
        />
        <PrivateRoute
          exact
          path={routes.COURSE_CATALOG}
          condition={
            isAuthenticated && (freeCorporateUsers || freeInternationalUsers)
          }
          redirect="DASHBOARD"
          component={CourseCatalog}
        />
        <PrivateRoute
          exact
          path={routes.HACKATHONS}
          condition={isAuthenticated}
          redirect="DASHBOARD"
          component={HackathonList}
        />
        <PrivateRoute
          exact
          path={routes.HACKATHON}
          condition={isAuthenticated}
          redirect="DASHBOARD"
          component={HackathonDetails}
        />
        <PrivateRoute
          exact
          path={routes.REFERRALS_AND_REWARDS}
          condition={isAuthenticated && (freeUsers || freeInternationalUsers)}
          redirect="DASHBOARD"
          component={ReferralsAndRewards}
        />
        <PrivateRoute
          exact
          path={routes.ATTENDANCE_DETAILS}
          condition={isAuthenticated && (onlyBasicUsers || enabledTrialUsers)}
          component={Attendance}
        />
        <PrivateRoute
          exact
          path={routes.DIGITAL_EXCELERATE_TESTS}
          condition={isAuthenticated}
          component={DigitalExcelerateTests}
        />
        <PrivateRoute
          exact
          path={[routes.INVIGILATION, routes.PROCTOR_INVIGILATION]}
          condition={isAuthenticated}
          component={Invigilation}
          layout={NinjaLayout}
        />
        <PrivateRoute
          exact
          condition={isAuthenticated}
          path={routes.IPEVO_INSTRUCTIONS}
          component={IpevoInstructions}
          layout={ninjaUsers ? NinjaLayout : StudentBaseLayout}
        />
        <PrivateRoute
          exact
          condition={isAuthenticated}
          path={routes.TRY_CALCULATOR}
          component={TryCalculator}
          layout={ninjaUsers ? NinjaLayout : StudentBaseLayout}
        />
        <PrivateRoute
          exact
          path={routes.ZOOMOUT}
          condition={isAuthenticated}
          component={Zoomout}
          layout={ninjaUsers ? NinjaLayout : StudentBaseLayout}
        />
        <PrivateRoute
          exact
          path={routes.CODING_PLAYGROUND}
          condition={isAuthenticated && coding_playground_enabled}
          component={CodingPlayGround}
          layout={StudentBaseLayout}
          redirect="DASHBOARD"
        />
        <PrivateRoute
          exact
          path={routes.PRIVACY_POLICY}
          condition={isAuthenticated}
          component={PrivacyPolicy}
        />
        <PrivateRoute
          exact
          path={routes.TERMS}
          condition={isAuthenticated}
          component={Terms}
        />
        <PrivateRoute
          exact
          path={routes.COURSE_COMPLETION_CARDS}
          condition
          component={CourseCompletionCards}
          layout={NinjaLayout}
        />

        <PrivateRoute
          exact
          path={routes.PRIVATE_CERTIFICATE}
          condition={isAuthenticated && onlyBasicUsers}
          component={PrivateCertificate}
        />

        <PrivateRoute
          exact
          path={routes.EXTERNAL_LAB}
          condition={isAuthenticated}
          component={ExternalLab}
          layout={StudentLayout}
        />
        <Route exact path={routes.AUTH} component={Auth} />
        <Redirect exact from={routes.ROOT} to={routes.DASHBOARD} />
        <Redirect exact from={routes.LEARNER_DASHBOARD} to={routes.DASHBOARD} />
        <Redirect exact from={routes.LOGIN} to={routes.DASHBOARD} />
        <Redirect from={routes.ABOUT} to={routes.PROFILE_SETTINGS} />

        <PrivateRoute
          exact
          path={routes.ACTIVITIES}
          condition={isAuthenticated}
          component={PageNotFound}
        />

        <PrivateRoute
          exact
          path={routes.NOTIFICATIONS}
          condition={isAuthenticated}
          component={PageNotFound}
        />

        <PrivateRoute
          exact
          path={[routes.PUBLIC_EPORTFOLIO_WEBSITE, routes.PUBLIC_EPORTFOLIO]}
          condition
          component={PublicEportfolio}
          layout={NinjaLayout}
        />

        <Route component={PageNotFound} />
      </Switch>
    </Suspense>
  )
})

const mapStateToProps = (state: AppState) => ({
  userDetails: state.user.details,
  onboarding: state.user.onboarding,
  admission: state.user.admission,
  dashboardData: state.dashboard.data,
  programData: programSelectors.getActiveProgramDetails()(state),
})

export default connect(mapStateToProps)(Main)
